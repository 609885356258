import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { ESettings } from 'cing-app/utils/lookups';
import { get } from '@ember/object';

export default Helper.extend({
  config: service(),

  compute() {
    if (
      get(this.config, `settings.${ESettings.PortalStyle}`) === 'Company Portal'
    ) {
      return get(this.config, 'companyPortalUrl');
    } else if (
      get(this.config, `settings.${ESettings.PortalStyle}`) === 'Auction Portal'
    ) {
      return get(this.config, 'auctionPortalUrl');
    } else {
      return get(this.config, 'investorPortalUrl');
    }
  },
});
