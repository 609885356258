/* Include this mixin in your component if you need to build JSON API filters
Once imported, you can invoke filterExpression(value) method on your property
to build desired JSON API query parameter, for example:
myFilter = { someDataAttribute: this.filterEqual(someValue) };

Appending multiple attributes to be filtered is easy:
myFilter["someDataAttribute,anotherDataAttribute"] = this.filterExpression(searchTerm);

Value passed in can be a single object or array which will have its elements automatically joined as needed
Date types (either as plain Date object or moment object) will be automatically converted to proper server string format.

Supported filter Expressions take one input argument = value unless otherwise noted:
 Equal
 NotEqual
 LessThan
 LessThanOrEqual
 GreaterThan
 GreaterThanOrEqual
 Between    * takes two arguments = minValue, maxValue
 Like
 In         * value should be an array with at least two elements = [value1, value2, ..., valueN]
*/
import { inject as service } from '@ember/service';

import Mixin from '@ember/object/mixin';
import EmberObject from '@ember/object';
import { isArray, A } from '@ember/array';
import { isDate } from 'lodash';
import { assert } from '@ember/debug';

const ExpressionOperators = {
	AND: "AND",
	OR: "OR"
};

const FilterOperators = {
	EQUAL: "eq",
	NOT_EQUAL: "ne",
	LIKE: "like",
	NOT_LIKE: "notlike",
	START_WITH: "startwith",
	END_WITH: "endwith",
	LESS_THAN: "lt",
	GREATER_THAN: "gt",
	LESS_THAN_OR_EQUAL: "le",
	GREATER_THAN_OR_EQUAL: "ge",
	RANGE: "range",
	NULL: "null",
	NOT_NULL: "notnull",
	IN: "in",
	NOT_IN: "notin",
	MASK: "mask",
	MASK_EQUAL: "maskequal",
	NOT_MASK: "notmask",
	FREETEXT: "freetxt",
	CONTAINS: "contns",

	JSON_EQUAL: "jsoneq",
	JSON_NOT_EQUAL: "jsonne",
	JSON_LIKE: "jsonlike",
	JSON_NOT_LIKE: "jsonnotlike",
	JSON_START_WITH: "jsonstartwith",
	JSON_LESS_THAN: "jsonlt",
	JSON_GREATER_THAN: "jsongt",
	JSON_LESS_THAN_OR_EQUAL: "jsonle",
	JSON_GREATER_THAN_OR_EQUAL: "jsonge",
	JSON_RANGE: "jsonrange",
	JSON_NULL: "jsonnull",
	JSON_NOT_NULL: "jsonnotnull",
	JSON_IN: "jsonin",
	JSON_NOT_IN: "jsonnotin",
	JSON_MASK: "jsonmask",
	JSON_MASK_EQUAL: "jsonmaskequal",
	JSON_NOT_MASK: "jsonnotmask",
};

const Expressions = EmberObject.extend({
	operator: ExpressionOperators.AND,
	init() {
		this._super();
		assert('Operator must be set', this.get('operator'));
		this.set('expressions', A());
	},
	add(expr) {
		assert('Expression must be instance of Expression', (expr instanceof Filter || expr instanceof Expressions));
		this.get('expressions').pushObject(expr);
	},
	toJSON() {
		let expressions = [];

		this.get('expressions').forEach((expr) => {
			let exprJSON = expr.toJSON();
			if (exprJSON) {
				expressions.push(expr.toJSON());
			}
		});

		if (expressions.length) {
			return {
				"o": this.get('operator'),
				"expr": expressions
			}
		}
	},
	serialize() {
		return JSON.stringify(this.toJSON() || {});
	}
});

const Filter = EmberObject.extend({
	operator: FilterOperators.EQUAL,
	name: null,
	value: null,

	toJSON() {
		let filterObject = {
			"name": this.get('name'),
			"op": this.get('operator'),
		};

		let operator = this.get('operator');

		// skip value for special operators
		if (operator !== FilterOperators.NULL && operator !== FilterOperators.NOT_NULL) {
			filterObject.val = this.preprocessValue(this.get('value'))
		}

		return filterObject;
	},
	preprocessValue(value) {
		if (typeof (value) === 'string' || value instanceof String) {
			value = value.trim();
		}

		return value;
	}
});

const RangeFilter = Filter.extend({
	operator: FilterOperators.RANGE,
	name: null,
	value1: null,
	value2: null,

	toJSON() {
		let value1 = this.preprocessValue(this.get('value1'));
		let value2 = this.preprocessValue(this.get('value2'));

		return {
			"name": this.get('name'),
			"op": this.get('operator'),
			"val": `${value1};${value2}`
		}
	},
});

const DateRangeFilter = RangeFilter.extend({
	preprocessValue(value) {
		let dateFormat = this.get('dateFormat');

		assert(`Value is not a date: ${value}`, (isDate(value) || moment.isMoment(value)));
		assert("Date format is not set", dateFormat);

		if (!moment.isMoment(value)) {
			value = moment(value);
		}

		return value.format(dateFormat);
	}
});


export {
	Expressions,
	ExpressionOperators,
	FilterOperators,
	Filter,
	RangeFilter,
	DateRangeFilter
}

export default Mixin.create({
	config: service(),

	converter: function (rawValue, onlyDate) {
		const dateFormat = this.get('config.APP.serverDateFormat');
		const dateOnlyFormat = this.get('config.APP.serverDateOnlyFormat');
		// convert plain Date object to moment so that it can be formatted as needed
		if (isDate(rawValue)) {
			rawValue = moment(rawValue);
		}

		if (moment.isMoment(rawValue)) {
			// when UTC kind is desired then use moment.toISOString() instead
			rawValue = rawValue.format(!onlyDate ? dateFormat : dateOnlyFormat);
		}

		if (rawValue === null) {
			rawValue = 'null';
		}

		return rawValue;
	},
	convert: function (value, delimiter, onlyDate) {
		// use default delimiter when not specified
		delimiter = delimiter || ',';
		let self = this;
		return isArray(value) ? value.map(u => self.converter(u, onlyDate)).join(delimiter) : self.converter(value, onlyDate);
	},

	filterEqual(value, onlyDate) {
		return ("eq:" + this.convert(value, onlyDate));
	},
	filterNotEqual(value, onlyDate) {
		return ("ne:" + this.convert(value, onlyDate));
	},
	filterLessThan(value, onlyDate) {
		return ("lt:" + this.convert(value, onlyDate));
	},
	filterLessThanOrEqual(value, onlyDate) {
		return ("le:" + this.convert(value, onlyDate));
	},
	filterGreaterThan(value, onlyDate) {
		return ("gt:" + this.convert(value, onlyDate));
	},
	filterGreaterThanOrEqual(value, onlyDate) {
		return ("ge:" + this.convert(value, onlyDate));
	},
	filterBetween(valueMin, valueMax, onlyDate) {
		return ("range:" + this.convert([valueMin, valueMax], ';', onlyDate));
	},
	filterLike(value) {
		return ("like:" + this.convert(value).trim());
	},
	filterIn(value) {
		return ("in:" + this.convert(value, ';'));
	},
	filterNotIn(value) {
		return ("notin:" + this.convert(value, ';'));
	},
	filterNull(value) {
		return 'null';
	},
	filterNotNull(value) {
		return 'notnull';
	},
	filterMask(value) {
		return ("mask:" + this.convert(value));
	},
	filterFreetext(value) {
		return ("freetxt:" + this.convert(value).trim());
	},
	filterContains(value) {
		return ("contns:" + this.convert(value).trim());
	},
});
