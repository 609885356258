import Transform from '@ember-data/serializer/transform';
import moment from 'moment';
import classic from 'ember-classic-decorator';

const appTimeZone = 'America/Los_Angeles';

@classic
export default class Udate extends Transform {
  deserialize(serialized: any) {
    var type = typeof serialized;

    //let appTimeZone = moment.tz.guess();

    if (type === 'string') {
      //@ts-ignore
      return moment(serialized).tz(appTimeZone).toDate();
    } else if (moment.isMoment(serialized)) {
      return serialized.toDate();
    } else if (serialized === null || serialized === undefined) {
      return serialized;
    } else {
      return null;
    }
  }
  serialize(deserialized: any) {
    if (
      (moment.isMoment(deserialized) || deserialized instanceof Date) &&
      moment(deserialized).isValid()
    ) {
      //return moment.tz(deserialized, 'YYYY-MM-DDTHH:mm:ss', appTimeZone).format('YYYY-MM-DDTHH:mm:ss');
      return moment(deserialized).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      return null;
    }
  }
}
declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    udate: Udate;
  }
}
