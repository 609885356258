import Model, { attr, hasMany, belongsTo, AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import AppBuilder from 'cing-app/pods/appbuilder/service';
import FieldProperty from './field-property';
import FormElement from './form-element';

export default class Field extends Model {
  @attr("number") objectId?: number;
  @attr("string") controlType?: string;
  @attr("string") fieldName?: string;
  @attr("number") sequenceNumber!: number;
  @attr("string") fieldCaption?: string;

  @attr('date') createTime!: Date;
  @attr('date') modifyTime!: Date;

  @belongsTo('appbuilder-link/form-element') formElement?: AsyncBelongsTo<FormElement>;
  @hasMany('appbuilder-link/field-property') fieldProperties?: AsyncHasMany<FieldProperty>;


  static createModel(abLinkId: string, appbuilder: AppBuilder) {
    let model = Field.extend({
      formElement: belongsTo(appbuilder.getAbmModelName('appbuilder-link/form-element', abLinkId)),
      fieldProperties: hasMany(appbuilder.getAbmModelName('appbuilder-link/field-property', abLinkId)),
    });
    return model;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder/field': Field;
  }
}


