import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { task, all } from 'ember-concurrency';
import EmberObject, { defineProperty, action, set } from '@ember/object';
import { validator, buildValidations } from 'ember-cp-validations';
import { ESettings } from 'cing-app/utils/lookups';
import { getOwner } from '@ember/application';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import smartroom from 'cing-app/adapters/smartroom/smartroom';
import { ARGS_SET } from '@glimmer/component/-private/component';
import { tracked } from '@glimmer/tracking';

let PortalSettingsValidations = buildValidations({
  SupportEmail: [validator('format', { type: 'email', allowBlank: true })],
  PortalStyle: validator('presence', true),
});

const PortalSettingsList = Object.keys(ESettings);

const PickrComponents = {
  palette: true,
  preview: true,
  opacity: true,
  hue: true,
  interaction: {
    hex: false,
    hexa: false,
    rgba: false,
    input: true,
    hsva: false,
    clear: false,
    save: true,
  },
};

let PortalSettingsModel = EmberObject.extend(PortalSettingsValidations, {
  store: service(),
  ESettings,
  init() {
    this._super();
  },
  initialize: task(function* () {
    let models = [];
    let settings = yield this.loadSettings.perform();

    for (var a = 0; a < PortalSettingsList.length; a++) {
      let settingName = PortalSettingsList[a];
      let settingKey = ESettings[settingName];
      let modelPropertyName = 'model_' + settingKey;

      let setting = settings.findBy('key', settingKey);

      if (!setting) {
        setting = this.store.createRecord('setting', {
          key: settingKey,
          value: '',
        });
      }

      defineProperty(this, modelPropertyName, undefined, setting);

      models.pushObject(setting);

      defineProperty(this, settingName, alias(modelPropertyName + '.value'));
    }

    this.set('models', models);
  }),
  loadSettings: task(function* () {
    // construct list of settings IDs
    let PortalSettingsListIds = [];
    for (var a = 0; a < PortalSettingsList.length; a++) {
      PortalSettingsListIds.push(ESettings[PortalSettingsList[a]]);
    }

    // get the existing settings from API
    let settingsQuery = Expressions.create();
    settingsQuery.add(
      Filter.create({
        name: 'key',
        operator: FilterOperators.IN,
        value: PortalSettingsListIds,
      })
    );

    let existingSettings = yield this.store.query('setting', {
      condition: settingsQuery.serialize(),
      page: {
        size: 100,
      },
      sort: '-modify-time',
    });

    // get unique list of settings
    let settings = [];

    existingSettings.forEach((setting) => {
      if (!settings.findBy('key', setting.key)) {
        settings.pushObject(setting);
      }
    });

    return settings;
  }),
  save: task(function* () {
    let saveItems = [];
    for (var a = 0; a < this.models.length; a++) {
      saveItems.push(this.saveSetting.perform(this.models[a]));
    }

    yield all(saveItems);
  }),
  saveSetting: task(function* (setting) {
    if (setting.get('hasDirtyAttributes')) {
      yield setting.save();
    }
  })
    .enqueue()
    .maxConcurrency(4),
});

@classic
export default class PortalSettings extends Component {
  PickrComponents = PickrComponents;

  @service store;
  @service session;
  @service config;

  showFormValidations = false;

  @tracked
  imgNotProvided = false;

  init() {
    super.init();

    let settingsModel = PortalSettingsModel.create(
      getOwner(this).ownerInjection()
    );

    this.initTask.perform(settingsModel);
  }

  willDestroyElement() {
    this._super(...arguments);
    for (var a = 0; a < this.model.models.length; a++) {
      this.model.models[a].rollbackAttributes();
    }
  }

  @task(function* (settingsModel) {
    yield settingsModel.initialize.perform();

    let abLinksQuery = Expressions.create();

    abLinksQuery.add(
      Filter.create({
        name: 'ab-case-id',
        operator: FilterOperators.NOT_NULL,
      })
    );

    yield this.set(
      'abLinks',
      this.store.query('appbuilder-link', {
        condition: abLinksQuery.serialize(),
        sort: '-ab-case-id',
        page: {
          size: 1000,
          number: 1,
        },
      })
    );

    this.set('model', settingsModel);

    this.loadAppBuilderProjectsTask.perform();
  })
  initTask;

  @task(function* () {
    yield this.model.save.perform();
    this.context.reloadSettings();
  })
  saveTask;

  @task(function* (settingsModel) {
    let abQuery = Expressions.create({ operator: ExpressionOperators.OR });

    abQuery.add(
      Filter.create({
        name: 'ab-object-name',
        operator: FilterOperators.EQUAL,
        value: 'list-of-smart-rooms',
      })
    );

    abQuery.add(
      Filter.create({
        name: 'ab-object-name',
        operator: FilterOperators.EQUAL,
        value: 'user-profiles',
      })
    );
    abQuery.add(
      Filter.create({
        name: 'ab-object-name',
        operator: FilterOperators.EQUAL,
        value: 'tasks',
      })
    );

    let abLinks = yield this.store.query('appbuilder-link', {
      condition: abQuery.serialize(),
      page: {
        size: 1000,
        number: 1,
      },
    });

    this.set(
      'smartRoomsProjectABLinks',
      abLinks.filterBy('ABObjectName', 'list-of-smart-rooms')
    );
    this.set(
      'userProfilesProjectABLinks',
      abLinks.filterBy('ABObjectName', 'user-profiles')
    );
    this.set(
      'projectManagementABLinks',
      abLinks.filterBy('ABObjectName', 'tasks')
    );
  })
  loadAppBuilderProjectsTask;

  @action
  save() {
    let validations = this.model.validations;
    if (!this.model.PortalSidePanelImage) {
      this.imgNotProvided = true;
    } else {
      if (validations.isValid) {
        this.saveTask.perform();
      } else {
        this.set('showFormValidations', true);
      }
    }
  }

  @action
  onChangeSmartRoomsProjectABLink() {
    if (this.model.SmartRoomsProjectABLink == null) {
      this.model.SmartRoomsProjectABLink = '';
    }
  }

  @action
  onChangeUserProfilesProjectABLink() {
    if (this.model.UserProfilesProjectABLink == null) {
      this.model.UserProfilesProjectABLink = '';
    }
  }

  @action
  onChangeProjectManagementABLink() {
    if (this.model.ProjectManagementABLink == null) {
      this.model.ProjectManagementABLink = '';
    }
  }

  @action
  selectColor(propertyName, hsva, instance) {
    this.set(`model.${propertyName}`, hsva.toHEXA().toString());
    instance.hide();
  }
}
