import { helper as buildHelper } from '@ember/component/helper';
import { DebtorBusinessTypeA } from '../utils/lookups'

export function formatDebtorBusinessA([value, ...rest]) {
  const type = Object
    .keys(DebtorBusinessTypeA)
    .map(key => DebtorBusinessTypeA[key])
    .filter(type => type.id === value)

  return type.length ? type[0].name : 'N/A';
}

export default buildHelper(formatDebtorBusinessA);
