import { helper as buildHelper } from '@ember/component/helper';
import { DebtorTypes } from '../utils/lookups'

const DebtorTypesMap = {}
Object.keys(DebtorTypes).forEach(type => {
  DebtorTypesMap[DebtorTypes[type]] = type
})

export function formatDebtorType([value, ...rest]) {
  return DebtorTypesMap[value] ? DebtorTypesMap[value] : 'N/A';
}

export default buildHelper(formatDebtorType);
