import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { Chapter11SubType } from 'cing-app/utils/lookups';

@classic
export default class Chapter11Subtype extends Component {
  @service('docker-item')
  docker;

  @service
  store;

  Chapter11SubType = Chapter11SubType;

  @computed('record')
  get subtypeDisplay() {
    let propName = this.get('column.propertyName');
    let mask = this.get('record').get(propName);
    let display = [];
    Object.keys(Chapter11SubType).forEach(key => {
      if (mask & Chapter11SubType[key].id) {
        display.pushObject(Chapter11SubType[key].label);
      }
    });
    return display.join(', ');
  }
}
