import { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import Address from './address';
import Email from './email';
import Phone from './phone';
import Case from './case';
import Person from './person';
import Abstract from './abstract';
export default class Attorney extends Abstract {
  @attr('string') firstName!: string;
  @attr('string') lastName!: string;
  @attr('string') middleName!: string;
  // master: DS.belongsTo('attorney', {inverse: 'master'}),
  @attr('boolean') deleted!: boolean;
  @attr('string') lawFirmName?: string;
  @attr('string') lawFirmCity?: string;
  @attr('string') lawFirmPostalCode?: string;
  //@ts-ignore - mixin
  @hasMany('email') emails!: AsyncHasMany<Email>;
  //@ts-ignore - mixin
  @hasMany('phone') phones!: AsyncHasMany<Phone>;
  //@ts-ignore - mixinn
  @hasMany('address') addresses!: AsyncHasMany<Address>;
  //@ts-ignore - mixin
  @hasMany('case') attorneyCases!: AsyncHasMany<Case>;
  //@ts-ignore - mixin
  @hasMany('person') attorneyInLawFirms!: AsyncHasMany<Person>;

  get fullName() {
    return `${this.get('firstName')} ${this.get('middleName')} ${this.get(
      'lastName'
    )}`;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    attorney: Attorney;
  }
}
