import classic from 'ember-classic-decorator';
import StorageObject from 'ember-local-storage/local/object';

@classic
class Storage extends StorageObject { }

// Uncomment if you would like to set initialState
// Storage.reopenClass({
//   initialState() {
//     return {};
//   }
// });

export default Storage;
