import { attr } from '@ember-data/model';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import Abstract from './abstract';

const FaqValidations = buildValidations({
  fromName: validator('presence', true),
  addressFrom: validator('format', { type: 'email' }),
  message: validator('presence', true),
  typeName: validator('presence', true),
});
export default class Enquiry extends Abstract.extend(
  FaqValidations,
  computedEnumMixin('typeName', 'type', { General: 1, Issues: 2 })
) {
  @attr('number') declare type: number;
  @attr('string') declare body?: string;
  @attr('string') declare subject?: string;
  @attr('string') declare addressFrom?: string;

  // local props only
  @attr('string') declare fromName: string;
  @attr('string') declare message?: string;
  @attr('string') declare phone?: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    enquiry: Enquiry;
  }
}
