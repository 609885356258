import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
//@ts-ignore
import { buildValidations } from 'ember-cp-validations';
import Abstract from './abstract';
import Address from './address';
import DebtorProfile from './debtor-profile';

const CDPPropertyValidations = buildValidations({});

export default class CdpProperty extends Abstract.extend(
  CDPPropertyValidations
) {
  @attr('string') declare companyProfileId: string;
  @attr('string') declare mailingAddressId?: string;
  @attr('boolean') declare possesHazard?: boolean;
  @attr('string') declare hazardDescription?: string;
  @attr('string') declare otherReason?: string;
  @attr('boolean') declare needsToBeSecured?: boolean;
  @attr('boolean') declare hasDeteriorableGoods?: boolean;

  @attr('boolean') declare isInsured?: boolean;
  @attr('string') declare insuranceAgency?: string;
  @attr('string') declare insuranceContact?: string;
  @attr('string') declare insurancePhone?: string;

  //@ts-ignore - mixin
  @belongsTo('debtorProfile')
  declare debtorProfile: AsyncBelongsTo<DebtorProfile>;
  //@ts-ignore - mixin
  @belongsTo('address') declare mailingAddress: AsyncBelongsTo<Address>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cdp-property': CdpProperty;
  }
}
