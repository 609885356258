import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import AuthenticatedRouteMixin from 'ember-smartidentity-auth/mixins/auth-mixin';
import classic from 'ember-classic-decorator';

@classic
export default class ProjectTabRoute extends Route.extend(AuthenticatedRouteMixin) {
  @service store;
  @service router;

  model(params, transition) {
    let project = this.modelFor('project').project;

    return project.get('projectTabs').then((projectTabs) => {
      return projectTabs.findBy('id', params.project_tab_id);
    })
  }

  setupController(controller, model) {
    let project = this.modelFor('project').project;

    controller.set('model', project);
    controller.set('selectedTab', model);
  }
}