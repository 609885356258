import Component from '@glimmer/component';
import { get } from '@ember/object';
import { all } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { SYNC_API } from 'cing-app/pods/user-instances/service';

import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import {
  AclEntityType,
  AclPermissionType,
  PartyRoleTypes,
  PartyRole,
  ProjectTabMap,
} from 'cing-app/utils/lookups';
import { UserStatus, NotificationTypes } from 'cing-app/pods/smartroom/service';
import { IsProjectType } from 'cing-app/helpers/is-project-type';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import Project from 'cing-app/models/project';
import ProjectTabModel from 'cing-app/models/project-tab';
import InterestedParty from 'cing-app/models/interested-party';
import InterestedPartyRole from 'cing-app/models/interested-party-role';
import InterestedPartyType from 'cing-app/models/interested-party-type';
import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';
import AclRole from 'cing-app/models/acl-role';
import SmartroomUser from 'cing-app/models/smartroom/user';
import User from 'cing-app/models/user';
import Company from 'cing-app/models/company';
import Investor from 'cing-app/models/investor';
import Case from 'cing-app/models/case';
import Person from 'cing-app/models/person';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import ProjectType from '../../main-dashboard/project-charts/project-type/component';

const SHOW_AS_OPTION_1 = 1;
const SHOW_AS_OPTION_2 = 2;
const SHOW_AS_OPTION_3 = 3;

const SHOW_AS_OPTIONS = [
  {
    value: SHOW_AS_OPTION_1,
    name: 'Last, First Middle',
  },
  {
    value: SHOW_AS_OPTION_2,
    name: 'First Middle Last',
  },
  {
    value: SHOW_AS_OPTION_3,
    name: 'First Last',
  },
];

interface ByProjectContactsArgs {}

export default class ByProjectContacts extends Component<ByProjectContactsArgs> {
  @service store!: Store;
  @service abModels: any;
  @service smartroomCache: any;
  @service smartroom: any;
  @service abStore: any;
  @service session: any;
  @service config: any;
  @service userInstances: any;
  @service('docker-item') docker: any;
  @tracked
  pageSize: number = 40;
  @tracked
  confirmRemoval: InterestedParty | null = null;

  @tracked
  condition?: string;

  @tracked
  sortBy?: string;

  @tracked
  setPortalAccessEnabled: boolean = false;

  @tracked
  setPortalAccessViewerGroup: null | AclRole = null;

  @tracked
  setPortalAccessViewerGroupEnabled: boolean = false;

  @tracked
  showAllParties = false;

  @tracked
  interestedPartyRoles!: InterestedPartyRole[];

  @tracked
  interestedPartyTypes!: InterestedPartyType[];

  @tracked
  projectTypes!: ProjectType[];

  @tracked
  selectedProjectTypes: ProjectType[];

  @tracked
  viewerGroups!: AclRole[];

  @tracked
  setProperties: any;

  SYNC_API = SYNC_API;

  NotificationTypes = NotificationTypes;
  AclEntityType = AclEntityType;
  AclPermissionType = AclPermissionType;
  InterestedParty = InterestedParty;

  @tracked
  includes = [
    'company',
    'person',
    'interested-party-role',
    'interested-party-type',
    'viewer-group',
    'email.user.groups',
    'address',
    'investor.company',
    'project.projectType',
  ];

  @tracked
  selectedItems: InterestedParty[];

  @tracked
  dataSource!: ApiDataSource<InterestedParty>;

  @tracked
  selectedViewerGroups: AclRole[];

  @tracked
  selectedRoles!: InterestedPartyRole[];

  @tracked
  selectedTypes!: InterestedPartyType[];

  @tracked
  searchQuery = '';

  @tracked
  showAsOption = SHOW_AS_OPTIONS[3];

  @tracked
  setPortalAccess: InterestedParty | null = null;

  @tracked
  setRole: InterestedParty | null = null;

  @tracked
  setSRAccess: InterestedParty | null = null;

  get styleNamespace() {
    return podNames['crm-search/by-project-contacts'];
  }

  constructor(owner: any, args: ByProjectContactsArgs) {
    super(owner, args);
    this.showAsOption = SHOW_AS_OPTIONS[0];
    this.selectedItems = [];
    this.initTask.perform();

    this.selectedViewerGroups = [];
    this.selectedRoles = [];
    this.selectedTypes = [];
    this.selectedProjectTypes = [];
  }

  @task
  initTask = taskFor(async () => {
    this.store
      .query('interested-party-role', { page: { size: 1000 }, sort: 'name' })
      .then((partyRoles) => {
        this.interestedPartyRoles = partyRoles.toArray();
      });

    this.store
      .query('interested-party-type', { page: { size: 1000 }, sort: 'name' })
      .then((partyTypes) => {
        this.interestedPartyTypes = partyTypes.toArray();
      });

    this.store
      .query('project-type', { page: { size: 1000 }, sort: 'name' })
      .then((projectTypes) => {
        this.projectTypes = projectTypes.toArray();
      });

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        //@ts-ignore
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        //@ts-ignore
        value: AclPermissionType.ProjectTabRead,
      })
    );

    this.store
      .query('acl-role', {
        page: {
          size: 1000,
        },
        condition: vgQuery.serialize(),
        sort: 'description',
      })
      .then((viewerGroups) => {
        this.viewerGroups = viewerGroups.toArray();
      });

    this.dataSource = new ApiDataSource<InterestedParty>(
      40,
      false,
      'crmSearch.byProjectContacts',
      this.session.authUser.email,
      this.loadDataTask,
      this.pageSize,
      this.columnsUI,
      this.selectedItems,
      {}
    );
  });

  get columnsUI() {
    let columns: DataSourceColumn<InterestedParty>[] = [
      new DataSourceColumn<InterestedParty>({
        id: 'project.name',
        label: 'Project Name',
        getValue: (row) => get(row, 'project.name'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-link-column',
        options: { onClick: this.showProjectDetail },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'project.projectType.name',
        label: 'Project Type',
        getValue: (row) => get(row, 'project.projectType.name'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'person.fullName',
        label: 'Contact Name',
        getValue: (row) => this.nameView(row),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-link-column',
        options: { onClick: this.showAttorneyDetail },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'investor.company.name',
        label: 'Investor',
        getValue: (row) => row,
        minWidth: 200,
        valueComponent: 'crm-search/by-project-contacts/column-investor',
        options: { onClick: this.showInvestorDetail },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'company.name',
        label: 'Company',
        getValue: (row) => get(row, 'company.name'),
        sortingEnabled: true,
        minWidth: 200,
        valueComponent: 'table-link-column',
        options: { onClick: this.showCompanyDetail },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'email.emailAddress',
        label: 'Email',
        getValue: (row) => get(row, 'email.emailAddress'),
        sortingEnabled: true,
        minWidth: 220,
        valueClass: 'text-break',
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'interestedPartyType.name',
        label: 'Affiliation Type',
        getValue: (row) => get(row, 'interestedPartyType.name'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'interestedPartyRole.name',
        label: 'Affiliation Role',
        getValue: (row) => get(row, 'interestedPartyRole.name'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
    ];

    if (get(this.userInstances, 'dealCloudEnabled')) {
      columns.push(
        new DataSourceColumn<InterestedParty>({
          id: 'person.dealCloudId',
          label: 'DC',
          getValue: (row) => get(row, 'person.dealCloudId'),
          minWidth: 25,
          maxWidth: 25,
          valueComponent:
            'project-detail/interested-parties/parties/column-dc-status',
        })
      );
    }

    columns.push(
      new DataSourceColumn<InterestedParty>({
        id: 'email.user.groups',
        label: 'SE User Access',
        getValue: (row) => row,
        minWidth: 100,
        valueComponent:
          'project-detail/interested-parties/parties/column-user-group',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'email.user.groups.name',
        label: 'SE User Group',
        getValue: (row) => row,
        sortingEnabled: true,
        minWidth: 150,
        valueComponent:
          'project-detail/interested-parties/parties/column-user-group-name',
        options: {
          tooltipText:
            'This group defines access to portal level pages and functions',
        },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'portalAccess',
        label: 'SE Project Access',
        getValue: (row) => row,
        sortingEnabled: true,
        minWidth: 100,
        valueComponent:
          'project-detail/interested-parties/parties/column-sraccess',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'viewerGroup.name',
        label: 'SE Project Access Role',
        getValue: (row) => get(row, 'viewerGroup.name'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          tooltipEnabled: true,
          tooltipText:
            'This controls which project level tabs and functions this user has',
        },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'email.user.lastInviteDate',
        label: 'Last SE Notification',
        getValue: (row) => get(row, 'email.user.lastInviteDate'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'email.user.lastAccess',
        label: 'Last SE Access',
        getValue: (row) => get(row, 'email.user.lastAccess'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'createTime',
        label: 'Contact Added',
        getValue: (row) => get(row, 'createTime'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'srFoldersAbbr',
        label: 'Folders',
        getValue: (row) => this.fundSrFolders(row),
        minWidth: 200,
        valueComponent: 'table-text-column',
      })
    );

    return columns;
  }

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<InterestedParty>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      this.condition = this.generateFilter();

      let query: any = {
        condition: this.condition,
        include: this.includes.join(','),
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
      };
      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
        this.sortBy = query['sort'];
      } else {
        this.sortBy = undefined;
      }

      let ips = await this.store.query('interested-party', query);
      let interestedParties = <Paged<InterestedParty>>ips.toArray();
      interestedParties.meta = {
        totalCount: ips.meta['total-count'],
      };

      return interestedParties;
    }
  );

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    if (!this.showAllParties) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'person-id',
          operator: FilterOperators.NOT_NULL,
        })
      );
    }

    if (this.selectedProjectTypes?.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'project.project-type.id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.selectedProjectTypes.mapBy('id'),
        })
      );
    }

    if (this.selectedRoles?.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'role-id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.selectedRoles.mapBy('id'),
        })
      );
    }

    if (this.selectedTypes?.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'type-id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.selectedTypes.mapBy('id'),
        })
      );
    }

    if (this.selectedViewerGroups?.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'viewer-group-id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.selectedViewerGroups.mapBy('id'),
        })
      );
    }

    let searchQuery = this.searchQuery;
    if (searchQuery) {
      let termParts = searchQuery.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            //@ts-ignore
            name: 'person.first-name',
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            //@ts-ignore
            name: 'person.last-name',
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: termParts[termParts.length - 1],
          })
        );

        exprSearch.add(nameExpression);
      }

      [
        'company.name',
        'investor.company.name',
        'party-name',
        'address.city',
        'person.last-name',
        'person.first-name',
        'email.email-address',
        'company.name',
        'project.name',
      ].forEach((propName) => {
        exprSearch.add(
          Filter.create({
            //@ts-ignore
            name: propName,
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: searchQuery,
          })
        );
      });

      expr.add(exprSearch);
    }

    return expr.serialize();
  }

  get include() {
    return this.includes.join(',');
  }

  @task
  updateRoleTask = taskFor(async (party) => {
    // move the reference party to the beginning
    this.selectedItems.removeObject(party);
    this.selectedItems.unshiftObject(party);

    let items = this.selectedItems.toArray();

    // get the role properties from reference item
    let changes = party.getProperties('roleId', 'isImportant', 'roleText');

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      if (item) {
        item.setProperties(changes);
        await item.save();
      }
    }

    this.setRole = null;
  });

  @task
  updatePortalAccessTask = taskFor(async (party) => {
    // move the reference party to the beginning
    this.selectedItems.removeObject(party);
    this.selectedItems.unshiftObject(party);

    let items = this.selectedItems.toArray();

    let itemsToSave = [];

    let setPortalAccessEnabled = this.setPortalAccessEnabled;
    let setPortalAccessViewerGroupEnabled =
      this.setPortalAccessViewerGroupEnabled;
    let setPortalAccessViewerGroup = this.setPortalAccessViewerGroup;

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      if (item) {
        item.portalAccess = setPortalAccessEnabled;

        if (
          setPortalAccessViewerGroupEnabled &&
          setPortalAccessViewerGroup &&
          setPortalAccessViewerGroup.id
        ) {
          item.viewerGroupId = setPortalAccessViewerGroup.id;
          item.viewerGroup = setPortalAccessViewerGroup;
        }
      }

      itemsToSave.push(this.updatePartyRecordTask.perform(item));
    }

    await all(itemsToSave);

    this.setPortalAccessEnabled = false;
    this.setPortalAccessViewerGroup = null;
    this.setPortalAccessViewerGroupEnabled = false;

    this.setPortalAccess = null;
  });

  @task
  removeRecordTask = taskFor(async (party) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      itemsToSave.push(this.removeRecordForPartyTask.perform(items[a]));
    }

    await all(itemsToSave);

    // we need to clear selectedItems upon removing!!!
    this.selectedItems.clear();

    this.confirmRemoval = null;
    this.search();
  });

  @task({ enqueue: true, maxConcurrency: 4 })
  removeRecordForPartyTask = taskFor(async (party) => {
    await party.destroyRecord();
  });

  @task({ enqueue: true, maxConcurrency: 4 })
  updatePartyRecordTask = taskFor(async (party) => {
    await party.save();
  });

  @task
  createNotificationTask = taskFor(async (parties) => {
    let users: { email: string; person: Person }[] = [];

    for (var a = 0; a < parties.length; a++) {
      let person = await get(parties[a], 'person');
      let email = await get(parties[a], 'email');

      if (person && email) {
        let user = await get(email, 'user');

        if (user) {
          users.pushObject(user);
        } else {
          users.pushObject({
            email: email.emailAddress,
            person: person,
          });
        }
      }
    }

    users = users.compact();

    const appearance = {
      label: 'Send Notification',
      icon: '',
      title: 'Send Notification',
      custom: true,
      size: 'large',
    };

    this.docker.invokePopup('send-alert-smex', appearance, {
      users: users,
    });

    this.selectedItems.clear();
  });

  @action
  editInterestedParty(row: InterestedParty) {
    let personName = get(row, 'person.fullName');
    let companyName = get(row, 'company.name');
    let emailAddress = get(row, 'email.emailAddress');
    let investorName = get(row, 'investor.company.name');

    let partyName: string = '';

    if (personName) {
      partyName = personName;
    } else if (companyName) {
      partyName = companyName;
    } else if (emailAddress) {
      partyName = emailAddress;
    } else if (investorName) {
      partyName = investorName;
    }

    let appearance = {
      icon: '',
      title: `Edit contact: ${partyName}`,
      size: 'medium',
      custom: true,
    };
    this.docker.invokePopup('manage-interested-party/add-party', appearance, {
      interestedParty: row,
      project: get(row, 'project'),
    });
  }

  @action
  setRoleAction(party: InterestedParty) {
    if (!this.selectedItems) {
      this.selectedItems = [party];
    }

    this.setRole = party;
  }

  @action
  cancelSetRole() {
    this.setRole = null;
  }

  @action
  doUpdateRole(party: InterestedParty) {
    this.updateRoleTask.perform(party);
  }

  @action
  setPortalAccessAction(party: InterestedParty) {
    this.setPortalAccessEnabled = false;
    this.setPortalAccessViewerGroup = null;
    this.setPortalAccessViewerGroupEnabled = false;

    if (!this.selectedItems) {
      this.selectedItems = [party];
    }

    this.setPortalAccess = party;
  }

  @action
  cancelSetPortalAccess() {
    this.setPortalAccess = null;
  }

  @action
  doUpdatePortalAccess(party: InterestedParty) {
    this.updatePortalAccessTask.perform(party);
  }

  @action
  setSRAccessAction(party: InterestedParty) {
    if (!this.selectedItems) {
      this.selectedItems = [party];
    }

    this.setSRAccess = party;
  }

  @action
  cancelSetSRAccess() {
    this.setSRAccess = null;
  }

  @action
  showAttorneyDetail(row: InterestedParty) {
    this.docker.popupPerson(row.get('person'), row.get('company'));
  }

  @action
  showCompanyDetail(row: InterestedParty) {
    this.docker.popupCompany(row.company);
  }

  @action
  showInvestorDetail(row: InterestedParty) {
    get(row, 'investor').then((investor: Investor) => {
      const appearance = {
        icon: '',
        title: 'Investor detail: ' + investor.get('company.name'),
        custom: true,
      };

      const context = {
        project: get(row, 'project'),
        investor: investor,
      };
      this.docker.invokePopup('investor-detail', appearance, context);
    });
  }

  @action
  filterByAttorney() {
    console.log('FILTER BY ATTORNEY: ', ...arguments);
  }

  @action
  removeRecord(row: InterestedParty) {
    this.confirmRemoval = row;
  }

  @action
  cancelRemoveRecord() {
    this.confirmRemoval = null;
  }

  @action
  doRemoveRecord(row: InterestedParty) {
    this.removeRecordTask.perform(row);
  }

  @action
  search() {
    this.dataSource.refresh();
  }

  @action
  searchByRole(selected: InterestedPartyRole[]) {
    this.selectedRoles = selected;
    this.dataSource.refresh();
  }

  @action
  searchByType(selected: InterestedPartyType[]) {
    this.selectedTypes = selected;
    this.dataSource.refresh();
  }

  @action
  searchByViewerGroup(selected: AclRole[]) {
    this.selectedViewerGroups = selected;
    this.dataSource.refresh();
  }

  @action
  searchByProjectType(selected: ProjectType[]) {
    this.selectedProjectTypes = selected;
    this.dataSource.refresh();
  }

  @action
  viewAs(ip: InterestedParty) {
    window.open(
      `${this.session.portalUrl}view-as?viewAsPortfolio=${get(
        ip,
        'project.id'
      )}&viewAsUser=${encodeURIComponent(
        get(ip, 'email.emailAddress')
      )}&viewAsProfile=${get(ip, 'srProfile')}`
    );
  }

  @action
  createNotification(row: InterestedParty) {
    let items = this.selectedItems.toArray();

    items.removeObject(row);
    items.unshiftObject(row);

    this.createNotificationTask.perform(items);
  }

  @action
  manageIPAccess(row: InterestedParty) {
    this.docker.popupManageAccess(
      row,
      this.AclEntityType.InterestedParty,
      get(row, 'person.fullName') || get(row, 'company.name'),
      [this.AclPermissionType.InterestedPartyRead]
    );
  }

  @action
  nameView(row: InterestedParty) {
    let person = get(row, 'person');

    if (person.get('id')) {
      if (this.showAsOption?.value === SHOW_AS_OPTION_1) {
        return `${person.get('lastName') + ', ' || ''} ${
          person.get('firstName') || ''
        } ${person.get('middleName') || ''}`;
      } else if (this.showAsOption?.value === SHOW_AS_OPTION_2) {
        let middle = '';

        if (person.get('middleName')) {
          middle = `${person.get('middleName')}`;
        }

        return `${person.get('firstName') || ''} ${middle} ${
          person.get('lastName') || ''
        }`;
      } else {
        return `${person.get('firstName') || ''} ${
          person.get('lastName') || ''
        }`;
      }
    } else {
      return '';
    }
  }

  fundSrFolders(row: InterestedParty) {
    if (IsProjectType(['fund', get(row, 'project.projectType.name')])) {
      return row.srFoldersAbbr;
    } else {
      return null;
    }
  }

  @action
  showProjectDetail(row: InterestedParty) {
    const appearance = {
      label: 'Project',
      icon: '',
      title: '<small>Project:</small> ' + get(row, 'project.name'),
      size: 'large',
      status: 'full',
      custom: true,
    };
    this.docker.invokePopup('project-detail', appearance, {
      project: get(row, 'project'),
    });
  }
}
