import Component from '@ember/component';
import { inject } from '@ember/service';
import { computed } from '@ember/object';

import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

import { task } from 'ember-concurrency';

export default Component.extend({
  store: inject(),
  session: inject(),
  init() {
    this._super();
    this.get('initTask').perform();
  },
  initTask: task(function* () {
    let inquiry = this.get('store').createRecord('enquiry', {
      typeName: 'General',
    });
    this.set('model', inquiry);
    this.set('model.addressFrom', this.session.user.email);
    let person = yield this.session.user.get('person');
    if (person) {
      this.set('model.fromName', person.get('fullName'));
    }
    //yield this.get('getFaqs').perform();
  }),
  getFaqs: task(function* () {
    // let faqFilter = Expressions.create();
    // faqFilter.add(Filter.create({
    //   name: "project-id",
    //   operator: FilterOperators.EQUAL,
    //   value: this.get('project.id')
    // }));
    //
    // let faqs = yield this.get('store').query('faq', {
    //   condition: faqFilter.serialize(),
    //   sort: 'order'
    // });
    //
    // this.set('faqs', faqs);
  }),
  sendInquiryTask: task(function* () {
    let model = this.get('model');
    let subject = `${this.get('project.name')}`;
    let bodyParts = [];
    bodyParts.push(`From: ${model.get('fromName')}<br/>`);
    bodyParts.push(`Email: ${model.get('addressFrom')}<br/>`);
    bodyParts.push(`Phone: ${model.get('phone')}<br/>`);
    bodyParts.push(`Message: ${model.get('message')}<br/>`);

    model.setProperties({
      subject,
      body: bodyParts.join(''),
    });

    this.set('showFormErrors', false);
    yield model.save();
    this.set('inquirySent', true);
  }),
  actions: {
    dragStart: function (object) {
      console.log('Drag Start', object);
    },
    sortEndAction: function () {
      let order = 0;
      this.get('faqs').forEach((item) => {
        item.set('order', order++);
        item.save();
      });
    },
    sendInquiry() {
      this.set('showFormErrors', true);
      if (this.get('model.validations.isValid')) {
        this.get('sendInquiryTask').perform();
      }
    },
    refreshFaqs() {
      this.get('getFaqs').perform();
    },
    addFAQItem() {
      this.set('editFAQ', this.get('store').createRecord('faq', {}));
    },
    editFAQItem(item) {
      this.set('editFAQ', item);
    },
  },
});
