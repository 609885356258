import classic from 'ember-classic-decorator';
import Field from '../field/component';
import { action } from '@ember/object';
import { set } from '@ember/object';
@classic
export default class Radio extends Field {
  showFormValidations = null;
  type = 'radio';

  @action
  onChanged(value) {
    set(this, 'value', value);

    if (this.changed) {
      this.changed(value);
    }
  }
}
