import 'reflect-metadata';

export const exportableProp = 'exportable';
export const exportableAttrsListProp = 'exportable-attrs';
export const exportableNestedAttrsListProp = 'nested-exportable-attrs';

export function exportable(...properties: string[]) {
  const decorator: PropertyDecorator = (target, propertyKey) => {
    Reflect.defineMetadata(
      exportableAttrsListProp,
      [
        ...(Reflect.getMetadata(exportableAttrsListProp, target) ?? []),
        propertyKey,
      ],
      target
    );

    Reflect.defineMetadata(
      exportableProp,
      properties.length ? properties : true,
      target,
      propertyKey
    );
  };
  return decorator;
}
