import Component from '@ember/component';
import { action, computed, set, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';

import {
  AclPermissionType,
  AclPermissionTypeEnum,
  PartyType,
} from 'cing-app/utils/lookups';
import { copy } from '@ember/object/internals';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

import {
  InvestorSmartRoomFolders,
  CaseProjectUrlMap,
  ProjectTabMap,
} from 'cing-app/utils/lookups';

import { SYNC_API } from 'cing-app/pods/user-instances/service';

const PartySourceMap = {
  SMARTEXCHANGE: 1,
  SMARTROOM: 2,
  OUTLOOK: 3,
  DEALCLOUD: 4,
  SALESFORCE: 5,
  OTHERCRM: 6,
};

const PartySources = [
  { name: 'SmartExchange', id: PartySourceMap.SMARTEXCHANGE, disabled: false },
  { name: 'SmartRoom', id: PartySourceMap.SMARTROOM, disabled: true },
  { name: 'Outlook', id: PartySourceMap.OUTLOOK, disabled: true },
  { name: 'DealCloud', id: PartySourceMap.DEALCLOUD, disabled: true },
  { name: 'SalesForce', id: PartySourceMap.SALESFORCE, disabled: true },
  { name: 'Other CRM', id: PartySourceMap.OTHERCRM, disabled: true },
];
import classic from 'ember-classic-decorator';

@classic
export default class AddPartyComponent extends Component {
  @service('docker-item') docker;
  @service userInstances;
  @service store;
  @service smartroom;

  @alias('context.interestedParty') model;
  @alias('context.project') project;

  smartRoomId = null;
  smartRoomFolderId = null;
  partyFlow = null;

  PartySourceMap = PartySourceMap;

  init() {
    super.init(...arguments);
    set(this, 'PartySources', copy(PartySources, true));
    this.initTask.perform();
  }

  @task
  *initTask() {
    this.set('partySource', this.PartySources.firstObject);

    let srConnection = this.context.srConnection;

    if (srConnection) {
      let defaultAccessFolders = [];

      try {
        defaultAccessFolders = yield srConnection.allAccessSmartRoomFolders;
      } catch (e) {
        console.error('default access folders could not be loaded');
      }

      this.setProperties({
        smartRoomId: srConnection.SRSiteId,
        smartRoomFolderId: srConnection.SRFolderId,
        defaultAllAccessFolders: defaultAccessFolders,
      });

      set(
        this.PartySources.findBy('id', PartySourceMap.SMARTROOM),
        'disabled',
        false
      );
    }

    if (get(this, 'model.isNew')) {
      if (this.userInstances.dealCloudEnabled) {
        let dcSource = this.PartySources.findBy('id', PartySourceMap.DEALCLOUD);
        set(dcSource, 'disabled', false);
        set(this, 'partySource', dcSource);
      } else {
        set(
          this,
          'partySource',
          this.PartySources.findBy('id', PartySourceMap.SMARTEXCHANGE)
        );
      }
    }

    this.searchTypesTask.perform().then((types) => {
      this.set('interestedPartyTypes', types.toArray());
    });

    this.searchRolesTask.perform().then((roles) => {
      this.set('interestedPartyRoles', roles.toArray());
    });

    this.searchStatusesTask.perform().then((statuses) => {
      this.set('interestedPartyStatuses', statuses.toArray());
    });
  }

  @task({
    restartable: true,
  })
  *searchTypesTask(term) {
    yield timeout(300);

    /*
    if (!term) {
        return null;
    }
    */

    let expr = Expressions.create();

    if (term) {
      expr.add(
        Filter.create({
          name: 'name',
          operator: FilterOperators.LIKE,
          value: term,
        })
      );
    }

    return yield this.get('store').query('interested-party-type', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 1000, number: 1 },
    });
  }

  @task({
    restartable: true,
  })
  *searchRolesTask(term) {
    yield timeout(300);

    /*
    if (!term) {
        return null;
    }
    */

    let expr = Expressions.create();

    if (term) {
      expr.add(
        Filter.create({
          name: 'name',
          operator: FilterOperators.LIKE,
          value: term,
        })
      );
    }

    return yield this.get('store').query('interested-party-role', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 1000, number: 1 },
    });
  }

  @task({
    restartable: true,
  })
  *searchStatusesTask(term) {
    yield timeout(300);

    /*
    if (!term) {
        return null;
    }
    */

    let expr = Expressions.create();

    if (term) {
      expr.add(
        Filter.create({
          name: 'name',
          operator: FilterOperators.LIKE,
          value: term,
        })
      );
    }

    return yield this.get('store').query('interested-party-status', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 1000, number: 1 },
    });
  }

  @task
  *saveTask() {
    /*
    if ((!this.newPerson && !person) && (!this.newCompany && !company) && (!this.newInvestor && !investor)) {
        throw new Error("You must select a contact and/or an investor before saving.")
    }
    */

    if (this.newPerson) {
      yield this.newPerson.save();
      this.model.set('person', this.newPerson);
    }

    if (this.newCompany) {
      yield this.newCompany.save();
      this.model.set('company', this.newCompany);
    }

    if (this.newEmail) {
      yield this.newEmail.save();
      this.model.set('email', this.newEmail);
    }

    yield this.partyFlow.saveTask.perform();

    if (this.context.onCreate) {
      this.context.onCreate();
    } else if (this.context.onUpdate) {
      this.context.onUpdate();
    }
  }

  @task
  *selectPersonTask(person) {
    this.setProperties({
      newPerson: null,
      newEmail: null,
    });

    console.log('PERSON: ', person);

    set(this.model, 'person', person);

    yield this.partyFlow.selectCRMContactTask.perform(person);
  }

  @task
  *selectCRMCompanyTask(company) {
    this.setProperties({
      newCompany: null,
    });

    this.model.setProperties({
      company: null,
      companyId: null,
    });

    console.log('CRM Company: ', company);

    yield this.partyFlow.selectCRMCompanyTask.perform(company);
  }

  @task
  *selectDealCloudContactTask(dcContact) {
    this.setProperties({
      newPerson: null,
      newEmail: null,
    });

    this.model.setProperties({
      person: null,
      personId: null,
      email: null,
      emailId: null,
    });

    let emailAddress = dcContact.email ? dcContact.email.toLowerCase() : null;
    let person = null;

    if (emailAddress) {
      let query = Expressions.create({});
      query.add(
        Filter.create({
          name: 'first-name',
          operator: FilterOperators.EQUAL,
          value: dcContact.firstName,
        })
      );

      query.add(
        Filter.create({
          name: 'last-name',
          operator: FilterOperators.EQUAL,
          value: dcContact.lastName,
        })
      );

      query.add(
        Filter.create({
          name: 'emails.emailAddress',
          operator: FilterOperators.IN,
          value: emailAddress,
        })
      );

      person = (yield this.store.query('person', {
        condition: query.serialize(),
        include: 'emails',
        page: {
          size: 1,
        },
      })).firstObject;
    }

    if (!person) {
      person = this.store.createRecord('person', {
        fullName: dcContact.fullName,
        dealCloudId: dcContact.id,
      });

      person.parseFromFullName(dcContact.fullName);

      this.set('model.person', person);
      this.set('newPerson', person);

      if (emailAddress) {
        let email = this.store.createRecord('email', {
          emailAddress: emailAddress,
          person: person,
        });

        this.set('model.email', email);
        this.set('newEmail', email);
      }
    } else {
      this.set('model.person', person);

      if (emailAddress) {
        let email = person.get('emails').findBy('emailAddress', emailAddress);

        if (email) {
          this.set('model.email', email);
        } else {
          let email = this.store.createRecord('email', {
            emailAddress: emailAddress,
            person: person,
          });

          this.set('model.email', email);
          this.set('newEmail', email);
        }
      }
    }

    let companyName = get(dcContact, 'company.name');

    if (companyName) {
      let companyQuery = Expressions.create({});

      companyQuery.add(
        Filter.create({
          name: 'name',
          operator: FilterOperators.EQUAL,
          value: companyName,
        })
      );

      let company = (yield this.store.query('company', {
        condition: companyQuery.serialize(),
        page: {
          size: 1,
        },
      })).firstObject;

      if (!company) {
        company = this.store.createRecord('company', {
          name: companyName,
        });
      }

      set(this, 'newCompany', company);
    }

    yield this.partyFlow.selectDealCloudContactTask.perform(person);
  }

  @task
  *selectDealCloudCompanyTask(dcCompany) {
    this.setProperties({
      newCompany: null,
    });

    this.model.setProperties({
      company: null,
      companyId: null,
    });

    yield this.partyFlow.selectDealCloudCompanyTask.perform(dcCompany);
  }

  @task
  *selectSmartRoomContactTask(srUser) {
    this.setProperties({
      newPerson: null,
      newEmail: null,
    });

    this.model.setProperties({
      person: null,
      personId: null,
      email: null,
      emailId: null,
    });

    let emailAddress = srUser.email ? srUser.email.toLowerCase() : null;
    let person = null;

    if (emailAddress) {
      let query = Expressions.create({});

      query.add(
        Filter.create({
          name: 'emails.emailAddress',
          operator: FilterOperators.EQUAL,
          value: emailAddress,
        })
      );

      person = (yield this.store.query('person', {
        condition: query.serialize(),
        include: 'emails',
        page: {
          size: 1,
        },
      })).firstObject;
    }

    if (!person) {
      person = this.store.createRecord('person', {
        firstName: srUser.firstName,
        lastName: srUser.lastName,
      });

      this.set('model.person', person);
      this.set('newPerson', person);

      if (emailAddress) {
        let email = this.store.createRecord('email', {
          emailAddress: emailAddress,
          person: person,
        });

        this.set('model.email', email);
        this.set('newEmail', email);
      }
    } else {
      this.set('model.person', person);

      if (emailAddress) {
        let email = person.get('emails').findBy('emailAddress', emailAddress);

        if (email) {
          this.set('model.email', email);
        } else {
          let email = this.store.createRecord('email', {
            emailAddress: emailAddress,
            person: person,
          });

          this.set('model.email', email);
          this.set('newEmail', email);
        }
      }
    }

    yield this.partyFlow.selectSmartRoomContactTask.perform(person);
  }

  @task
  *selectSmartRoomCompanyTask(company) {
    this.setProperties({
      newCompany: null,
    });

    this.model.setProperties({
      company: null,
      companyId: null,
    });

    yield this.partyFlow.selectSmartRoomCompanyTask.perform(company);
  }

  stopPropagation(e) {
    e.stopPropagation();
    return false;
  }

  // registers a sub-component that will be notified on all important events
  @action
  registerFlow(partyFlow) {
    this.set('partyFlow', partyFlow);
  }

  @action
  save() {
    //if (this.get('model.validations.isValid')) {
    this.saveTask.perform();
    //}
  }

  @action
  selectCRMCompany(company) {
    this.saveTask.cancelAll({ resetState: true });
    this.selectCRMCompanyTask.perform(company);
  }

  @action
  viewPerson(person) {
    this.docker.popupPerson(person);
  }

  @action
  viewCompany(company) {
    this.docker.popupCompany(company);
  }

  @action
  selectPerson(person) {
    this.saveTask.cancelAll({ resetState: true });
    this.selectPersonTask.perform(person);
  }

  @action
  selectDealCloudPerson(dcContact) {
    this.saveTask.cancelAll({ resetState: true });
    this.selectDealCloudContactTask.perform(dcContact);
  }

  @action
  selectDealCloudCompany(company) {
    this.saveTask.cancelAll({ resetState: true });
    this.selectDealCloudCompanyTask.perform(company);
  }

  @action
  selectSmartRoomPerson(srUser) {
    this.saveTask.cancelAll({ resetState: true });
    this.selectSmartRoomContactTask.perform(srUser);
  }

  @action
  selectSmartRoomCompany(company) {
    this.saveTask.cancelAll({ resetState: true });
    this.selectSmartRoomCompanyTask.perform(company);
  }

  @action
  hideCreatePartyTypeCreateOnSame(term) {
    let existingOption = this.interestedPartyTypes.findBy('name', term);
    return !existingOption;
  }

  @action
  createPartyType(partyTypeName, options) {
    let partyType = this.store.createRecord('interested-party-type', {
      name: partyTypeName,
    });

    return partyType.save().then(() => {
      this.interestedPartyTypes.pushObject(partyType);
      this.model.set('interestedPartyType', partyType);
    });
  }

  @action
  removePartyType(option) {
    option.destroyRecord().then((result) => {
      this.interestedPartyTypes.removeObject(option);
    });
  }

  @action
  hideCreatePartyRoleCreateOnSame(term) {
    let existingOption = this.interestedPartyRoles.findBy('name', term);
    return !existingOption;
  }

  @action
  createPartyRole(partyRoleName, options) {
    let partyRole = this.store.createRecord('interested-party-role', {
      name: partyRoleName,
    });

    return partyRole.save().then(() => {
      this.interestedPartyRoles.pushObject(partyRole);
      this.model.set('interestedPartyRole', partyRole);
    });
  }

  @action
  removePartyRole(option) {
    option.destroyRecord().then((result) => {
      this.interestedPartyRoles.removeObject(option);
    });
  }

  @action
  hideCreatePartyStatusCreateOnSame(term) {
    let existingOption = this.interestedPartyStatuses.findBy('name', term);
    return !existingOption;
  }

  @action
  createPartyStatus(partyStatusName, options) {
    let partyStatus = this.store.createRecord('interested-party-status', {
      name: partyStatusName,
    });

    return partyStatus.save().then(() => {
      this.interestedPartyStatuses.pushObject(partyStatus);
      this.model.set('interestedPartyStatus', partyStatus);
    });
  }

  @action
  removePartyStatus(option) {
    option.destroyRecord().then((result) => {
      this.interestedPartyStatuses.removeObject(option);
    });
  }

  @action
  onPartySourceChanged(newValue) {
    set(this, 'partySource', newValue);
    this.setProperties({
      newPerson: null,
      newEmail: null,
      newInvestor: null,
      newCompany: null,
      investor: null,
      investorFolder: null,
      investorCategory: null,
      investorFolders: null,
      contactFolders: null,
      availableFolders: null,
    });

    this.model.setProperties({
      person: null,
      personId: null,
      company: null,
      companyId: null,
      email: null,
      emailId: null,
      investor: null,
      investorId: null,
      srProfile: null,
      srAccess: null,
    });

    this.saveTask.cancelAll({ resetState: true });
  }
}
