import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service, inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { later } from '@ember/runloop';
import {
  ReportStatus,
  ActionCRMTypeMap,
  ActionCRMMergeType,
} from 'cing-app/utils/lookups';

@classic
export default class CompanyMerge extends Component {
  @alias('context.companies')
  companies;

  @inject()
  config;

  @inject()
  store;

  @service seActions;

  @task(function* () {
    let masterCompany = this.get('masterCompany');
    let companies = [];

    this.get('companies').forEach((company) => {
      if (company !== masterCompany) {
        companies.push({
          Id: company.get('id'),
          MergeType: ActionCRMMergeType.MergeToMaster,
        });
      }
    });

    yield this.seActions.crmAction(ActionCRMTypeMap.MergeCompanies, null, {
      TargetId: masterCompany.get('id'),
      Sources: companies,
    });

    if (this.context.onComplete) {
      this.context.onComplete();
    }

    if (this.onClose) {
      this.onClose();
    }
  })
  mergeCompanies;

  @task(function* (report) {
    let store = this.get('store');

    let downloadReport = yield store.findRecord(
      'grab-report',
      report.get('id'),
      {
        reload: true,
      }
    );

    let reportStatus = downloadReport.get('status');

    if (downloadReport.get('isProcessing')) {
      later(
        this,
        function () {
          this.send('getMergeCompanyStatus', report);
        },
        1000
      );
    } else if (reportStatus >= ReportStatus.Dropped) {
      throw Error('Merge task did not complete successfully.');
    } else {
      if (this.get('context.onComplete')) {
        this.get('context.onComplete')();
      }

      this.sendAction('onClose');
    }
  })
  mergeCompanyStatusTask;

  @action
  onMainCompanySelected() {}

  @action
  getMergeCompanyStatus(report) {
    this.get('mergeCompanyStatusTask').perform(report);
  }
}
