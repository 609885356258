import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('popup-body')
export default class CenterBody extends Component {
  //classNameBindings: ['padding:padded'],
  padding = false;
}
