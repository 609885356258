import JSONSerializer from 'ember-data/serializers/json';
import classic from 'ember-classic-decorator';

@classic
export default class ExtendedDataMappingConfigSerializer extends JSONSerializer {
  attrs = {
    mapping: { key: 'Mapping' },
    dynamicProperties: { key: 'DynamicProperties' },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'extended-data-mapping-config': ExtendedDataMappingConfigSerializer;
  }
}
