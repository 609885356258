import Model, { attr } from '@ember-data/model';
// import { computed } from '@ember/object';
import { validator, buildValidations } from 'ember-cp-validations';
import { computed } from '@ember/object';
import moment from 'moment';

const EventDetailValidations = buildValidations({
  eventName: validator('presence', true),
  noticeDate: validator('presence', true),
  dueDate: validator('presence', true),
  eventType: validator('presence', true),
  calculationType: validator('presence', true),
  eventStatus: validator('presence', true),
});

export default Model.extend(EventDetailValidations, {
  eventNumber: attr('number'),
  eventId: attr('string'),
  fundId: attr('string'),
  eventName: attr('string'),
  eventCode: attr('string'),
  eventType: attr('string'),
  calculationType: attr('string'),
  percCommitment: attr('number'),
  noticeDate: attr('abdate2'),
  dueDate: attr('abdate2'),
  eventTotalAmount: attr('number'),
  createdDate: attr('abdate2'),
  description: attr('string'),
  eventStatus: attr('string'),
  eventNotes: attr('string'),
  reportNote1: attr('string'),
  reportNote2: attr('string'),
  reportNote3: attr('string'),
  ctd: attr('number'),
  dtd: attr('number'),
  etd: attr('number'),
  gpmftd: attr('number'),
  eiPtd: attr('number'),
  eiRtd: attr('number'),
  dcFundId: attr('number'),

  humanTitle: computed('eventType', 'eventName', function () {
    return `${this.eventType} - ${this.eventName} (${moment(this.noticeDate).format("MM/DD/YYYY")})`;
  })

});

