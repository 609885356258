define("cing-app/helpers/evaluate-function", ["exports", "smex-ui-table/helpers/evaluate-function"], function (_exports, _evaluateFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _evaluateFunction.default;
    }
  });
  Object.defineProperty(_exports, "evaluateFunction", {
    enumerable: true,
    get: function () {
      return _evaluateFunction.evaluateFunction;
    }
  });
});
