import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { timeout, all } from 'ember-concurrency';
import { task } from 'ember-concurrency';

import classic from 'ember-classic-decorator';

import { SYNC_API } from 'cing-app/pods/user-instances/service';
import { next } from '@ember/runloop';

@classic
export default class ImportXlsInvestorsContacts extends Component {
  @service config;
  @service('docker-item') docker;
  @service userInstances;

  init() {
    super.init(...arguments);
    this.initTask.perform();
  }

  @task
  *initTask() {
    if (
      !this.userInstances.dealCloudEnabled ||
      this.userInstances.dealCloudVersion !== SYNC_API.V2
    ) {
      next(() => {
        this.importXLS();
      });
    }
  }

  @action
  importXLS() {
    let appearance = {
      icon: '',
      title: `Import contacts to CRM`,
      size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'manage-interested-party/import-xls-to-crm',
      appearance,
      this.context
    );
    this.onClose();
  }

  @action
  importDealCloud() {
    let appearance = {
      icon: '',
      title: `Import contacts to CRM`,
      size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'manage-interested-party/import-sync-companies-to-crm',
      appearance,
      this.context
    );
    this.onClose();
  }
}
