import Component from '@glimmer/component';
import { DataSourceColumn } from 'smex-ui-table';
import { cached } from 'tracked-toolbox';

interface AcceptTermsColumnArgs {
  column: DataSourceColumn<any>;
  row: any;
}

export default class AcceptTermsColumn extends Component<AcceptTermsColumnArgs> {
  @cached
  get value() {
    return this.args.column.getValue(this.args.row);
  }
}
