import Component from '@glimmer/component';
import { SmartroomFileModel, SmartroomFolderModel, SmartroomRightTypeModel } from 'smex-ui-sr-models';
import { action } from '@ember/object';

interface SmartroomFileManagerMinimumRightConfirmArgs {
  fileFolder: SmartroomFileModel | SmartroomFolderModel;
  right: SmartroomRightTypeModel;
  hideModal: () => void;
}

export default class SmartroomFileManagerMinimumRightConfirm extends Component<SmartroomFileManagerMinimumRightConfirmArgs> {
  @action
  cancel() {
    this.args.fileFolder.updatedGivenRight = this.args.right;
    this.args.hideModal();
  }

  @action
  confirm() {
    this.args.fileFolder.updatedGivenRight = this.args.right;
    this.args.fileFolder.setMinimumRight();
    this.args.hideModal();
  }
}
