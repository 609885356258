define("cing-app/torii-providers/si", ["exports", "ember-smartidentity-auth/torii-providers/si"], function (_exports, _si) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _si.default;
    }
  });
});
