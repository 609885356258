import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { action, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { tracked } from '@glimmer/tracking';

export default class InvestorDetail extends Component {
  @alias('context.model') model;
  @alias('context.project') project;
  @service store;

  @tracked
  showFormErrors = false;

  init() {
    super.init(...arguments);
    this.initTask.perform();
  }

  @task
  *initTask() {
    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: this.project.fundId,
      })
    );

    query.add(
      Filter.create({
        name: 'investor-fund-id',
        operator: FilterOperators.EQUAL,
        value: this.model.invFundId,
      })
    );

    let seInvestor = (yield this.store.query('investor', {
      condition: query.serialize(),
      page: {
        size: 1,
      },
    })).firstObject;

    set(this, 'seInvestor', seInvestor);
  }

  @task
  *saveTask(model) {
    let fundPrefix = this.project.get('investorFundIdPrefix');

    // create investor fund id if not set already
    if (!model.invFundId) {
      model.set('invFundId', fundPrefix + model.fundInvestorNumber);
    }

    // set fund id if not set already
    if (!model.fundId && fundPrefix) {
      let fundId = fundPrefix.endsWith('.')
        ? fundPrefix.slice(0, -1)
        : fundPrefix;
      model.set('fundId', fundId);
    }

    yield model.save();

    if (this.seInvestor) {
      this.seInvestor.setProperties({
        investorNumber: model.fundInvestorNumber,
        investorFundId: model.invFundId,
        commitment: model.commitment,
      });

      yield this.seInvestor.save();

      let company = yield this.seInvestor.get('company');

      if (company) {
        company.set('name', model.investorName);
        yield company.save();
      }
    } else {
      let fund = yield get(this.project, 'fund');

      if (fund) {
        // first check if we have a company that's the sam eas investor name
        let companyQuery = Expressions.create();
        companyQuery.add(
          Filter.create({
            name: 'name',
            operator: FilterOperators.EQUAL,
            value: model.investorName,
          })
        );

        let company = (yield this.store.query('company', {
          condition: companyQuery.serialize(),
          page: {
            size: 1,
          },
        })).firstObject;

        // we found a company with matching name, let see if we have investor record too
        if (company) {
          let investorQuery = Expressions.create({});
          investorQuery.add(
            Filter.create({
              name: 'fund-id',
              operator: FilterOperators.EQUAL,
              value: fund.id,
            })
          );

          investorQuery.add(
            Filter.create({
              name: 'company.id',
              operator: FilterOperators.EQUAL,
              value: company.id,
            })
          );

          let investor = (yield this.store.query('investor', {
            condition: investorQuery.serialize(),
            page: {
              size: 1,
            },
          })).firstObject;

          if (!investor) {
            investor = this.store.createRecord('investor', {
              company: company,
              fund: fund,
            });
          }

          investor.setProperties({
            investorNumber: model.fundInvestorNumber,
            investorFundId: model.invFundId,
            commitment: model.commitment,
          });

          yield investor.save();
        } else {
          company = this.store.createRecord('company', {
            name: model.investorName,
          });

          yield company.save();

          let investor = this.store.createRecord('investor', {
            investorNumber: model.fundInvestorNumber,
            investorFundId: model.invFundId,
            commitment: model.commitment,
            fund: fund,
            company: company,
          });

          yield investor.save();
        }
      }
    }
    if (this.context.onComplete) {
      this.context.onComplete();
    }
  }

  @action
  save() {
    this.showFormErrors = true;

    if (this.model.get('validations.isValid')) {
      this.saveTask.perform(this.get('model'));
    }
  }
}
