import EmberObject, { computed } from '@ember/object';

const FieldTypes = {
  TEXT: 1,
  AMOUNT: 2,
  PERCENTAGE: 3,
}



export default EmberObject.extend({
  enabled: false,
  type: null,
  view: false,
  model: null,
  field: null,
  title: null,
  customTitle: null,

  value: computed('type', 'model', 'field', function () {
    let type = this.get('type');

    let value = this.model.get(this.field);

    switch (type) {
      default:
        value = this.model.get(this.field);
        break;
    }

    return value;
  }),
  label: computed('title', 'customTitle', function () {
    return this.customTitle || this.title;
  })
});