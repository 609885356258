import Component from '@glimmer/component';
import SmartroomRightTypeModel from 'smex-ui-sr-models/addon/models/smartroom/right-type';

interface SecurityRightArgs {
  /**
   * null mean inherit
   */
  right: SmartroomRightTypeModel | null
  isFile?: boolean
}

export default class SecurityRight extends Component<SecurityRightArgs> {

}
