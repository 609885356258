import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
//@ts-ignore
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import ExtendedDataMappingConfigProperty from './extended-data-mapping-config-property';

export default class ExtendedDataMappingConfig extends Fragment {
  @attr('string')
  declare dbName: string;
  @attr('string')
  declare table: string;
  @attr('string')
  declare mapping: string;
  @fragmentArray('extended-data-mapping-config-property')
  declare dynamicProperties: ExtendedDataMappingConfigProperty[];
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'extended-data-mapping-config': ExtendedDataMappingConfig;
  }
}
