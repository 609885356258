import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, set, action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class FormComponent extends Component {
  @service store;
  expanded = false;

  constructor() {
    super(...arguments);
  }

  @task
  *initTask() {
    console.log('XXX');
  }

  @action
  toggleExpanded() {
    if (this.expanded) {
      set(this, 'expanded', false);
    } else {
      set(this, 'expanded', true);
    }
  }

  @action
  uploadDocument() {}
}
