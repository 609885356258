import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import { task } from 'ember-concurrency';

@classic
export default class FindPerson extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  include = 'addresses,emails';

  // start - filter properties
  searchQuery = '';

  // end - filter properties

  @computed
  get columns() {
    let self = this;
    return [
      {
        propertyName: 'fullName',
        className: "column-name",
        component: "api-table/columns/person-lookup",
        onRowSelect: function (record) {
          self.send("selectPerson", record);
        }
      },
      {
        propertyName: 'modifyTime',
        className: "text-right column-datetime",
        component: "api-table/columns/date",
        isHidden: true,
        dateFormat: "MM/DD/YYYY hh:mm a"
      },
    ]
  }

  init() {
    super.init(...arguments);

    this.generateFilter();
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    if (this.get('context.company')) {
      let companyQuery = Expressions.create({ operator: ExpressionOperators.AND })
      companyQuery.add(Filter.create({
        name: 'companies.id',
        operator: FilterOperators.EQUAL,
        value: this.get('context.company.id')
      }));

      expr.add(companyQuery);
    }

    let searchQuery = this.searchQuery;
    if (searchQuery) {
      let searchQueryExpr = Expressions.create({ operator: ExpressionOperators.OR });
      ['first-name', 'middle-name', 'last-name'].forEach((propName) => {
        searchQueryExpr.add(Filter.create({
          name: propName,
          operator: FilterOperators.LIKE,
          value: searchQuery
        }));
      });
      expr.add(searchQueryExpr);
    }

    this.set('filter', expr.serialize());
  }

  @task(function* (person) {
    let expr = Expressions.create();
    expr.add(Filter.create({
      name: 'person-id',
      operator: FilterOperators.EQUAL,
      value: person.get('id')
    }));

    let addresses = yield this.store.query("address", {
      condition: expr.serialize()
    });

    this.set('addresses', addresses);
    this.set('selectedPerson', person);
  })
  selectPersonTask;

  @action
  selectPerson(person) {
    this.get('context.onFound')(person);
    this.sendAction("onClose");

    // this.get('selectPersonTask').perform(person);
  }

  @action
  search() {
    this.generateFilter();
  }

  @action
  confirmSelection() {
    //this.attrs.onClose();
    this.sendAction("onClose")
  }
}
