import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import Project from './project';
import FormElement from './form-element';
import Field from './field';
import AppBuilder from 'cing-app/pods/appbuilder/service';

export default class ObjectModel extends Model {
	@attr('number') projectId!: number;
	@attr('string') objectName!: string;
	@attr('string') objectCaption!: string;
	@attr('string') objectNotes?: string;
	@attr('boolean') inLibrary?: boolean;
	@attr('boolean') inDeleted?: boolean;
	@attr('date') createTime!: Date;
	@attr('date') modifyTime!: Date;

	@belongsTo('appbuilder-link/project') project!: Project;
	@hasMany('appbuilder-link/field') fields!: Field;
	@hasMany('appbuilder-link/form-element') formElements!: FormElement;

	static createModel(abLinkId: string, appbuilder: AppBuilder) {
		let model = ObjectModel.extend({
			project: belongsTo(appbuilder.getAbmModelName('appbuilder-link/project', abLinkId)),
			fields: hasMany(appbuilder.getAbmModelName('appbuilder-link/field', abLinkId)),
			formElements: hasMany(appbuilder.getAbmModelName('appbuilder-link/form-element', abLinkId)),
		});
		return model;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
	export default interface ModelRegistry {
		'appbuilder-link/object': ObjectModel;
	}
}
