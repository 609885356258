import Component from '@glimmer/component';
import { timeout } from "ember-concurrency";
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { Filter, Expressions, FilterOperators, ExpressionOperators } from 'cing-app/mixins/filter-builder';
import { PersonInCompanyFlags } from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import { task, restartableTask } from "ember-concurrency";
import Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';
import Person from 'cing-app/models/person';

const AddressTypes = {
  PERSONAL: 1,
  BUSINESS: 2
};

interface AddBusinessAddressArgs {
  person: Person;
  model: any;
  onSelect: any;
  onRemove: any;
  onClose: any;
}

export default class AddBusinessAddress extends Component<AddBusinessAddressArgs> {
  @service store!: Store;

  @tracked
  showFormValidations: any;

  USStates = USStates;

  PersonInCompanyFlags = PersonInCompanyFlags;

  @restartableTask
  searchCompaniesTask = taskFor(async (term) => {
    await timeout(300);

    if (!term) {
      return null;
    }

    //API call
    let expr = Expressions.create();

    expr.add(Filter.create({
      name: 'name',
      operator: FilterOperators.LIKE,
      value: term
    }));

    expr.add(Filter.create({
      name: 'master-id',
      operator: FilterOperators.NULL,
    }));

    return await this.store.query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 50, number: 1 }
    });
  })

  @task
  addCompanyTask = taskFor(async (newCompanyName) => {
    let company = this.store.createRecord('company', {
      name: newCompanyName
    });
    await company.save();
    this.args.model.company = company;
    this.args.model.companyId = company.id;
  })

  @action
  createCompany(newCompanyName) {
    this.addCompanyTask.perform(newCompanyName)
  }

  @action
  selectCompany(company) {
    this.args.model.company = company;
    this.args.model.companyId = company.id;
  }

  @action
  searchCompanies(term) {
    return this.searchCompaniesTask.perform(term);
  }

  @action
  confirm(model) {
    this.showFormValidations = true;
    if (model.get('validations.isValid')) {
      if (this.args.onSelect) {
        this.args.onSelect(model);
      }
    }
  }
}