import Model, {
  attr,
  hasMany,
  belongsTo,
  AsyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import SyncEmail from './sync-email';
import SyncCompany from './sync-company';

export default class SyncContact extends Model {
  @attr('string') declare firstName?: string;
  @attr('string') declare lastName?: string;
  @attr('string') declare fullName?: string;
  @attr('string') declare email?: string;
  @attr('string') declare officePhone?: string;
  @attr('string') declare homePhone?: string;
  @attr('string') declare mobilePhone?: string;
  @hasMany('sync-company') declare companies: AsyncHasMany<SyncCompany>;
  @hasMany('sync-email') declare emails: AsyncHasMany<SyncEmail>;
  @belongsTo('sync-company') declare company: AsyncBelongsTo<SyncCompany>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sync-contact': SyncContact;
  }
}
