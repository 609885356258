import Bootstrap4Theme from 'ember-models-table/themes/bootstrap4';

export default Bootstrap4Theme.extend({
  //table: 'table table-hover table-sm',
  input: 'form-control form-control-sm',
  buttonDefault: 'btn btn-sm btn-secondary',
  'sort-asc': 'sort-up',
  'sort-desc': 'sort-down',
  'sort-icon-prefix': 'fas',
  'column-visible': 'check-square',
  'column-hidden': 'square',
  'column-icon-prefix': 'far',
  'nav-first': 'angle-double-left',
  'nav-first-prefix': 'fas',
  'nav-prev': 'angle-left',
  'nav-prev-prefix': 'fas',
  'nav-next': 'angle-right',
  'nav-next-prefix': 'fas',
  'nav-last': 'angle-double-right',
  'nav-last-prefix': 'fas',
  'caret': 'caret',
  'expand-row': 'fas fa-plus',
  'expand-all-rows': 'fas fa-plus',
  'collapse-row': 'fas fa-minus',
  'collapse-all-rows': 'fas fa-minus',
  'select-all-rows': 'far fa-check-square',
  'deselect-all-rows': 'far fa-square',
  'select-row': 'check-square',
  'deselect-row': 'square',
  tfooterWrapper: 'table-footer container-fluid clearfix',
  components: {
    'columns-dropdown': 'api-table/columns-dropdown',
    'pagination-simple': 'api-table/pagination-simple',
    'row-sorting-cell': 'api-table/row-sorting-cell',
  }
});

