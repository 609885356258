import Component from '@glimmer/component';

interface ColumnComponentArgs {
  amounts:any;
}

export default class ColumnComponent extends Component<ColumnComponentArgs> {
  get amounts(){
    return this.args.amounts;
  }
 }