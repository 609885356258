import Component from '@glimmer/component';
import { UploadFileInfo } from '../../../../../../../../smex-ui-sr-services';
import { DataSourceColumn } from '../../../../../../../../smex-ui-table';

interface SmartroomUploadProgressDetailProgressColumnArgs {
	column: DataSourceColumn<UploadFileInfo>;
	row: UploadFileInfo;
}

export default class SmartroomUploadProgressDetailProgressColumn extends Component<SmartroomUploadProgressDetailProgressColumnArgs> {

}
