define("cing-app/services/smart-room-folder", ["exports", "smex-ui-sr-services/services/smart-room-folder"], function (_exports, _smartRoomFolder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _smartRoomFolder.default;
    }
  });
});
