import Component from '@ember/component';
import { computed, action, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { EReportStatus, ProjectTabMap } from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
} from 'cing-app/pods/smartroom/service';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import TableTheme from '../abstract-import/table-theme';
import contact from 'cing-app/adapters/ab/investors/contact';

const PARTY_MODES = {
  SIMPLE: 'simple',
  BATCH: 'batch',
};
import classic from 'ember-classic-decorator';

@classic
export default class ImportDcLpComm extends Component {
  @service store;
  @service config;
  @service session;
  @service smartroom;
  @service('docker-item') docker;
  @service fileQueue;
  @service seActions;

  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  searchName = null;
  searchEmail = null;
  currentStep = 1;
  interestedParty = null;
  pageSize = 10;
  importAction = null;
  addUsersToSmartRoom = false;
  SmartRoomUserStatus = UserStatus;
  inviteUsersToSmartRoom = false;
  defaultUserStatus = UserStatus.HOLD;

  @computed
  get themeInstance() {
    return TableTheme.create();
  }

  @alias('context.project') project;

  @alias('context.onComplete') onComplete;

  init() {
    super.init(...arguments);
    this.set('selectedItems', []);
    this.initTask.perform();
  }

  @task
  *initTask() {
    let interestedPartyRoles = yield this.store.query('interested-party-role', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
    this.set('interestedPartyRoles', interestedPartyRoles.toArray());

    let funds = yield this.store.query('deal-cloud-fund', {
      page: {
        size: 100,
      },
    });

    this.set('dealCloudFunds', funds);

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: AclPermissionType.ProjectTabRead,
      })
    );

    let viewerGroups = (yield this.store.query('acl-role', {
      page: {
        size: 1000,
      },
      condition: vgQuery.serialize(),
      sort: 'description',
    })).toArray();

    this.set('viewerGroups', viewerGroups);

    let srConnection = this.context.srConnection;

    if (srConnection) {
      this.setProperties({
        smartRoomId: srConnection.SRSiteId,
        smartRoomFolderId: srConnection.SRFolderId,
        defaultAllAccessFolders: yield srConnection.allAccessSmartRoomFolders,
      });
    }

    let projectFund = yield this.get('context.project.fund');
    this.set('fund', projectFund);

    let projectDealCloudFund = get(this.project, 'dealCloudFund');

    // pre-select the fund that's been associated with the project
    if (projectDealCloudFund) {
      this.selectFund(
        this.dealCloudFunds.findBy('id', projectDealCloudFund.id)
      );
    }
  }

  @task({
    restartable: true,
  })
  *searchInvestorsTask(term) {
    yield timeout(500);

    let filter = {
      name: 'like:' + term,
    };

    if (this.selectedFund) {
      filter['fund-ids'] = 'in:' + this.selectedFund.id;
    }

    let investors = yield this.store.query('deal-cloud-investor', {
      filter,
    });

    return investors;
  }

  @task({
    restartable: true,
  })
  *updateLPFilter() {
    /*
    let filter = {};

    if (this.searchName) {
        filter['full-name'] = 'like:' + this.searchName;
    }

    if (this.searchEmail) {
        filter['email'] = 'like:' + this.searchEmail;
    }

    if (this.selectedCompanies && this.selectedCompanies.length) {
        filter['within'] = 'in:' + this.selectedCompanies.mapBy('id').join(',');
    }


    let contacts = (yield this.store.query('deal-cloud-contact', {
        filter: filter
    })).toArray();


    this.set('contacts', contacts);
    */

    let filter = {};
    //let query = Expressions.create();

    if (this.selectedFund) {
      /*
      query.add(Filter.create({
          name: 'fund-ids',
          operator: FilterOperators.IN,
          value: this.selectedFunds.mapBy('id')
      }));
      */
      filter['fund-ids'] = this.selectedFund.id;
    }

    if (this.selectedInvestors && this.selectedInvestors.length) {
      filter['investor-ids'] = this.selectedInvestors.mapBy('id').join(';');
    }

    if (this.searchName) {
      /*
      query.add(Filter.create({
          name: 'full-name',
          operator: FilterOperators.LIKE,
          value: this.searchName
      }));
      */
      filter['name'] = this.searchName;
    }

    if (this.searchEmail) {
      /*
      query.add(Filter.create({
          name: 'email',
          operator: FilterOperators.LIKE,
          value: this.searchEmail
      }));
      */

      filter['email'] = this.searchEmail;
    }

    //this.set('LPCondition', query.serialize());
    //this.set('filter', filter);

    let lpComs = yield this.store.query('deal-cloud-lp-communication', {
      filter: filter,
      page: {
        size: 1000,
        number: 1,
      },
    });

    let defaultViewerGroup = this.viewerGroups.findBy('description', 'LP');
    let defaultPartyRole = this.interestedPartyRoles.findBy('name', 'Investor');

    lpComs.forEach((lpCom) => {
      if (defaultViewerGroup && !lpCom.viewerGroup) {
        lpCom.set('viewerGroup', defaultViewerGroup.description);
      }

      if (defaultPartyRole && !lpCom.role) {
        lpCom.set('role', defaultPartyRole.name);
      }

      //lpCom.set('company', this.guessDefaultCompany(lpCom));
    });

    this.set('lpCommunications', lpComs);
  }

  guessDefaultCompany(lpCom) {
    let email = lpCom.email;
    let domain = email.toLowerCase().split('@')[1];
    let investorName = lpCom.get('limitedPartner.name');
    let companies = lpCom.get('contact.companyAffiliations');

    if (domain && companies) {
      let matches = [];

      // first try to match by company name and domain
      for (var a = 0; a < companies.length; a++) {
        let companyNameParts = companies[a].name.toLowerCase().split(' ');

        let match = {
          company: companies[a].name,
          count: 0,
          matchParts: [],
        };

        matches.push(match);

        for (var i = 0; i < companyNameParts.length; i++) {
          if (
            companyNameParts[i].length > 2 &&
            domain.indexOf(companyNameParts[i]) > -1
          ) {
            match.count++;
            match.matchParts.push(companyNameParts[i]);
          }
        }
      }

      if (matches.length) {
        matches = matches.sortBy('count');

        let highestMatch = matches[matches.length - 1];

        if (highestMatch && highestMatch.count) {
          return highestMatch.company;
          //return highestMatch.company + "E: (" + highestMatch.count + ")" + " - " + highestMatch.matchParts.join(',');
        }
      }

      let contactName = lpCom.get('contact.name').toLowerCase();

      // next try to match by contact name and company
      for (var a = 0; a < companies.length; a++) {
        let companyNameParts = companies[a].name.toLowerCase().split(' ');

        let match = {
          company: companies[a].name,
          count: 0,
          matchParts: [],
        };

        matches.push(match);

        for (var i = 0; i < companyNameParts.length; i++) {
          if (
            companyNameParts[i].length > 2 &&
            contactName.indexOf(companyNameParts[i]) > -1
          ) {
            match.count++;
            match.matchParts.push(companyNameParts[i]);
          }
        }
      }

      if (matches.length) {
        matches = matches.sortBy('count');

        let highestMatch = matches[matches.length - 1];

        if (highestMatch && highestMatch.count) {
          return highestMatch.company;
          //return highestMatch.company + "C: (" + highestMatch.count + ")" + " - " + highestMatch.matchParts.join(',');
        }
      }
    }

    /*
    if (companies) {
        return companies.firstObject.name;
    }
    */
  }

  @computed
  get companies() {
    return this.store.findAll('deal-cloud-company');
  }

  @computed
  get columns() {
    let self = this;

    let columns = [
      /*
      {
          title: "Fund",
          propertyName: "fund.name",
          sortDisabled: true
      },
      */
      {
        title: 'Inv. #',
        propertyName: 'investorNumber',
        sortDisabled: true,
      },
      {
        title: 'Investor',
        propertyName: 'limitedPartner.name',
        component: 'api-table/columns/html',
        sortDisabled: true,
        className: 'column-investor',
      },
      /*
      {
          title: "Company",
          propertyName: "company",
          component: 'manage-interested-party/import-dc-lp-comm/column-select',
          optionsFrom: 'contact.companyAffiliations',
          sortDisabled: true,
          className: 'column-company'
      },            
      */
      {
        title: 'Contact',
        propertyName: 'contact.name',
        component: 'api-table/columns/html',
        sortDisabled: true,
        className: 'column-contact',
      },
      {
        propertyName: 'email',
        sortDisabled: true,
        className: 'column-email',
      },
      {
        title: 'Role',
        propertyName: 'role',
        sortDisabled: true,
        component: 'manage-interested-party/import-dc-lp-comm/column-select',
        options: this.interestedPartyRoles,
        createModelName: 'interested-party-role',
        className: 'column-role',
      },
      {
        title: 'Access Role',
        propertyName: 'viewerGroup',
        sortDisabled: true,
        component: 'manage-interested-party/import-dc-lp-comm/column-select',
        options: this.viewerGroups,
        optionLabelProperty: 'description',
        className: 'column-viewer-group',
      },
      {
        propertyName: 'communications',
        sortDisabled: true,
        component:
          'manage-interested-party/import-dc-lp-comm/column-communications',
        className: 'column-communications',
      },
      {
        /*
        component: 'select-row-checkbox',
        useFilter: false,
        mayBeHidden: false,
        componentForSortCell: 'select-all-rows-checkbox',
        */

        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
      /*
      {
          title: "",
          component: "manage-interested-party/abstract-import/column-match-status",
      },
      */
    ];

    return columns;
  }

  @task
  *processTask() {
    yield this.addContactsTask.perform();

    if (this.addUsersToSmartRoom) {
      let addedParties = yield this.getAddedPartiesTask.perform();

      yield this.createSmartRoomSecurityProfilesTask.perform(addedParties);

      yield this.createSmartRoomUsersTask.perform(addedParties);
    }

    //let addedParties = yield this.applySecurityRights.perform();
    //yield this.get('addUsersTask').perform(addedParties);

    if (this.get('context.onComplete')) {
      this.get('context.onComplete')();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @task
  *addContactsTask() {
    let importRows = [];

    let userGroupQuery = Expressions.create();
    userGroupQuery.add(
      Filter.create({
        name: 'instance-acl-role-assignments.acl-role.acl-permissions.permission-type-id',
        operator: FilterOperators.NOT_IN,
        value: AclPermissionType.Admin,
      })
    );

    let userGroup = (yield this.store.query('user-group', {
      condition: userGroupQuery.serialize(),
      page: {
        size: 1,
        number: 1,
      },
    })).firstObject;

    for (var a = 0; a < this.selectedItems.length; a++) {
      let item = this.selectedItems[a];
      let rowData = {
        InvestorName: item.get('limitedPartner.name'),
        InvestorNumber: item.get('investorNumber'),
        DealCloudInvestorId: item.get('limitedPartner.id'),
        ContactName: item.get('contact.name'),
        DealCloudContactId: item.get('contact.entityId'),
        UserGroup: userGroup ? userGroup.name : null,
        Communications: item.get('communications').mapBy('name').join(','),
        ViewerGroup: item.get('viewerGroup') ? item.get('viewerGroup') : null,
        Role: item.get('role'),
        //Company: item.get('company'),
        ContactEmail: item.get('email'),
      };

      //console.log("COMMS: ", item.get('communications'))

      importRows.pushObject(rowData);
    }

    let importAction = yield this.seActions.importPartiesStepOne(
      new Blob([JSON.stringify(importRows)], { type: 'application/json' }),
      this.get('context.project.id')
    );

    this.set('addContactsAction', importAction);

    yield this.seActions.importPartiesStepTwo(importAction);
  }

  @task({
    maxConcurrency: 4,
    enqueue: true,
  })
  *createInvestorCategoryTask(party) {
    let emailAddress = (yield party.get('email.emailAddress')).toLowerCase();
    let category = null;
    try {
      category = yield this.smartroom.createCategory(
        this.smartRoomId,
        emailAddress
      );
    } catch (e) {
      // try to match to existing profile since SmartRoom says it already exists
      if (e instanceof CategoryAlreadyExists) {
        let securityProfiles = yield this.store.query('smartroom/category', {
          siteId: this.smartRoomId,
        });

        category = securityProfiles.find((item) => {
          return item.categoryName.toLowerCase() === emailAddress;
        });
      } else {
        throw e;
      }
    }

    // if no category was found, throw an error
    if (!category) {
      throw new Error('Security profile could not be created');
    }

    party.set('srProfile', category.id);
    party.save();

    let investor = yield party.get('investor');

    let investorFolder = yield this.get('store').queryRecord(
      'smartroom/folder',
      {
        siteId: this.smartRoomId,
        id: investor.smartRoomFolderId,
      }
    );

    if (investorFolder) {
      let investorSubFolders = (yield this.get('store').query(
        'smartroom/folder',
        {
          siteId: this.smartRoomId,
          parentId: investorFolder.id,
        }
      )).toArray();

      // apply security rights for all folders in path
      let folderPathItems = investorFolder.pathIds.split('/');

      let folderSecurities = [];

      // apply security for investor folder (view, print, save)
      folderSecurities.push({
        itemId: investorFolder.id,
        rightId: 32,
      });

      // apply security for parent folders (up to SmartRoom root folder)
      folderPathItems.forEach((folderId) => {
        if (folderId) {
          folderSecurities.push({
            itemId: folderId,
            rightId: 32,
          });
        }
      });

      // apply security to investor subfolders
      let partyFolders = party.get('srFolders');

      if (partyFolders && partyFolders.length) {
        if (
          partyFolders[0].toLowerCase() === 'all access' ||
          partyFolders[0].toLowerCase() === 'all'
        ) {
          for (var a = 0; a < investorSubFolders.length; a++) {
            let iFolder = investorSubFolders[a];

            folderSecurities.push({
              itemId: iFolder.id,
              rightId: 5,
            });
          }
        } else {
          for (var a = 0; a < investorSubFolders.length; a++) {
            let iFolder = investorSubFolders[a];

            folderSecurities.push({
              itemId: iFolder.id,
              rightId: partyFolders.includes(iFolder.get('name')) ? 5 : 26,
            });
          }
        }
      }

      yield this.smartroom.setCategorySecurity(
        this.smartRoomId,
        category.id,
        folderSecurities
      );
    }
  }

  @task
  *getAddedPartiesTask() {
    // get list of all added parties
    let addedPartiesQuery = Expressions.create({});
    addedPartiesQuery.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.get('project.id'),
      })
    );
    addedPartiesQuery.add(
      Filter.create({
        name: 'email-id',
        operator: FilterOperators.NOT_NULL,
      })
    );
    addedPartiesQuery.add(
      Filter.create({
        name: 'email.email-address',
        operator: FilterOperators.IN,
        value: this.selectedItems.mapBy('email'),
      })
    );

    // get all added parties
    return (yield this.store.query('interested-party', {
      condition: addedPartiesQuery.serialize(),
      include: 'email,investor,person',
      page: {
        size: 1000,
        number: 1,
      },
    })).toArray();
  }

  @task
  *createSmartRoomSecurityProfilesTask(addedParties) {
    let allTasks = [];

    for (var a = 0; a < addedParties.length; a++) {
      allTasks.push(
        this.createSmartRoomSecurityProfileForContact.perform(addedParties[a])
      );
    }

    yield all(allTasks);
  }

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  *createSmartRoomSecurityProfileForContact(party) {
    let emailAddress = (yield party.get('email.emailAddress')).toLowerCase();
    let category = null;
    try {
      category = yield this.smartroom.createCategory(
        this.smartRoomId,
        emailAddress
      );
    } catch (e) {
      // try to match to existing profile since SmartRoom says it already exists
      if (e instanceof CategoryAlreadyExists) {
        let securityProfiles = yield this.store.query('smartroom/category', {
          siteId: this.smartRoomId,
        });

        category = securityProfiles.find((item) => {
          return item.categoryName.toLowerCase() === emailAddress;
        });
      } else {
        throw e;
      }
    }

    // if no category was found, throw an error
    if (!category) {
      throw new Error('Security profile could not be created');
    }

    party.set('srProfile', category.id);
    party.save();

    let investor = yield party.get('investor');

    let investorFolder = yield this.get('store').queryRecord(
      'smartroom/folder',
      {
        siteId: this.smartRoomId,
        id: investor.smartRoomFolderId,
      }
    );

    if (investorFolder) {
      let investorSubFolders = (yield this.get('store').query(
        'smartroom/folder',
        {
          siteId: this.smartRoomId,
          parentId: investorFolder.id,
        }
      )).toArray();

      // apply security rights for all folders in path
      let folderPathItems = investorFolder.pathIds.split('/');

      let folderSecurities = [];

      // apply security for investor folder (view, print, save)
      folderSecurities.push({
        itemId: investorFolder.id,
        rightId: 32,
      });

      // apply security for parent folders (up to SmartRoom root folder)
      folderPathItems.forEach((folderId) => {
        if (folderId) {
          folderSecurities.push({
            itemId: folderId,
            rightId: 32,
          });
        }
      });

      // apply security to investor subfolders
      let partyFolders = party.get('srFolders');

      if (partyFolders && partyFolders.length) {
        if (
          partyFolders[0].toLowerCase() === 'all access' ||
          partyFolders[0].toLowerCase() === 'all'
        ) {
          for (var a = 0; a < investorSubFolders.length; a++) {
            let iFolder = investorSubFolders[a];

            folderSecurities.push({
              itemId: iFolder.id,
              rightId: 5,
            });
          }

          for (var a = 0; a < this.defaultAllAccessFolders.length; a++) {
            let dFolder = this.defaultAllAccessFolders[a];

            folderSecurities.push({
              itemId: dFolder.id,
              rightId: 5,
            });
          }
        } else {
          for (var a = 0; a < investorSubFolders.length; a++) {
            let iFolder = investorSubFolders[a];

            folderSecurities.push({
              itemId: iFolder.id,
              rightId: partyFolders.includes(iFolder.get('name')) ? 5 : 26,
            });
          }
        }
      }

      yield this.smartroom.setCategorySecurity(
        this.smartRoomId,
        category.id,
        folderSecurities
      );
    }
  }

  @task
  *createSmartRoomUsersTask(addedParties) {
    let usersData = [];

    for (var a = 0; a < addedParties.length; a++) {
      let party = addedParties[a];
      let emailAddress = (yield party.get('email.emailAddress')).toLowerCase();
      let person = yield party.get('person');

      if (party.srProfile && emailAddress) {
        usersData.push({
          categoryId: party.srProfile,
          email: emailAddress,
          firstName: person ? person.firstName : '',
          lastName: person ? person.lastName : '',
          companyName: null,
        });
      }
    }

    // create the users (by default they will have status 'hold' and invites won't be sent)
    yield this.smartroom.addUsers(
      this.smartRoomId,
      usersData,
      this.defaultUserStatus
    );
  }
  @action
  selectInvestors(investors) {
    this.set('selectedInvestors', investors);
    this.updateLPFilter.perform();
  }

  @action
  search() {
    this.updateLPFilter.perform();
  }

  @action
  selectContacts() {
    this.selectContactsTask.perform();
  }

  @action
  searchInvestors(term) {
    return this.searchInvestorsTask.perform(term);
  }

  @action
  selectFund(fund) {
    this.set('selectedFund', fund);
    this.updateLPFilter.perform();
    //this.searchInvestorsTask.perform();
  }

  @action
  process() {
    this.processTask.perform();
  }
}
