import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { PartyRoleTypes } from "cing-app/utils/lookups";

@classic
export default class RetainedProfessionals extends Component {
  @service
  store;

  @service('docker-item')
  docker;

  collapsed = true;

  @action
  toggle() {
    this.toggleProperty('collapsed');
  }

  @action
  showAttorneyDetail(attorney) {
    const appearance = { label: 'Attorney detail', icon: '', title: 'Attorney detail' };
    const context = { personId: attorney.get('id'), companyId: attorney.get('lawFirmId') };
    this.get('docker').invokePopup('contact-detail', appearance, context);
  }

  @action
  showCompanyDetail(company) {
    let appearance = {
      icon: 'group',
      title: 'Company',
      size: 'large'
    };
    this.get('docker').invokePopup('company-detail', appearance, { companyId: company.get('id') });
  }
}
