import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ISearchFilter, ISearchSorting, SortBy } from 'smex-ui-sr-filemanager';
import { SmartroomFileModel, SmartroomFolderModel } from 'smex-ui-sr-models';
import { action } from '@ember/object';

interface SmartroomSearchResultsArgs {
  siteId: string;
  categoryId: string;
  filter: ISearchFilter;
  selectedItems: (SmartroomFileModel | SmartroomFolderModel)[];
  selectionChanged: (items: any[]) => void;
  selectMode: 'folders' | 'files' | 'all';
}

export default class SmartroomSearchResults extends Component<SmartroomSearchResultsArgs> {
  @tracked
  sorting: ISearchSorting = { sortBy: SortBy.name, sortDesc: false };

  @action
  toggleSearchChecked(item: SmartroomFileModel | SmartroomFolderModel) {
    if (this.args.selectedItems.includes(item)) {
      this.args.selectionChanged(this.args.selectedItems.filter(i => i !== item));
    } else {
      this.args.selectionChanged([...this.args.selectedItems, item]);
    }
  }
}
