import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import Project from './project';
import FundType from './fund-type';
import FundStatus from './fund-status';

const FundValidations = buildValidations({
  publicDescription: validator('presence', true),
});

export default class Fund extends Abstract.extend(FundValidations) {
  @attr('string') declare publicDescription: string;

  @attr('string') declare dealCloudId?: string;

  @attr('number') declare projectedCommitment?: number;
  @attr('number') declare minimumCommitment?: number;
  @attr('number') declare totalCommitment?: number;
  @attr('number') declare percentageCommitted?: number;
  @attr('number') declare capitalCalled?: number;
  @attr('number') declare capitalDrawn?: number;
  @attr('number') declare dryPowder?: number;
  @attr('number') declare investedCapital?: number;

  @attr('string') declare targetIRR?: string;
  @attr('string') declare targetMOIC?: string;
  @attr('number') declare targetSize?: number;
  // @attr('number') vintageYear?: number; //not in api

  @attr('string') declare geography?: string;
  @attr('string') declare industryFocus?: string;
  @attr('string') declare fundLifetime?: string;
  @attr('string') declare exits?: string;
  @attr('string') declare investmentPeriod?: string;
  @attr('date') declare closingDate?: Date;
  @attr('date') declare dateStarted?: Date;
  @attr('date') declare lpaDate?: Date;

  @attr('string') declare assetClass?: string;
  @attr('string') declare managementFees?: string;
  @attr('string') declare carriedInterest?: string;
  @attr('string') declare preferredReturn?: string;

  @attr('string') declare abProject?: string;
  @belongsTo('project') declare project: AsyncBelongsTo<Project>;
  @belongsTo('fund-status') declare fundStatus: AsyncBelongsTo<FundStatus>;
  @belongsTo('fund-type') declare fundType: AsyncBelongsTo<FundType>;

  @attr('date') declare amountsAsOfDate?: Date;
  @attr('string') declare strategy?: string;
  @attr('string') declare overview?: string;
  @attr('string') declare netIRR?: string;
  @attr('string') declare grossIRR?: string;
  @attr('number') declare indicatedCommitment?: number;
  @attr('string') declare netMOIC?: string;
  @attr('string') declare grossMOIC?: string;
  @attr('string') declare dpi?: string;

  @attr('string') declare customText1?: string;
  @attr('string') declare customText2?: string;
  @attr('string') declare customText3?: string;
  @attr('string') declare customText4?: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    fund: Fund;
  }
}
