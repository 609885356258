import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import Company from './company';

export default class CompanyAmount extends Abstract {
  @attr('string') declare name?: string;
  @attr('number') declare amount: number;
  @attr('string') declare code?: string;
  @attr('string') declare companyId: string;

  //@ts-ignore - mixin
  @belongsTo('company') declare company: AsyncBelongsTo<Company>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-amount': CompanyAmount;
  }
}
