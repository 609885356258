import classic from 'ember-classic-decorator';
import Model, { attr } from '@ember-data/model';

@classic
export default class _Model extends Model {
  @attr('number')
  width;

  @attr('number')
  height;

  @attr('string')
  component;

  @attr('string')
  title;

  @attr('string')
  icon;

  @attr('boolean')
  header;

  @attr('boolean')
  footer;

  @attr('boolean')
  undocked;

  @attr()
  navlink;
}
