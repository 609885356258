import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import DocketFolder from './docket-folder';
import Docket from './docket';
import Abstract from './abstract';

export default class DocketToFolder extends Abstract {
  @attr('string') declare description?: string;
  @attr('boolean') declare visible?: boolean;
  @attr('boolean') declare primary?: boolean;
  @attr('number') declare source: number;

  @attr('string') declare docketId: string;
  @attr('string') declare docketFolderId: string;
  @attr('string') declare docketConsolidatedTypeToTypeGroupId?: string;

  @belongsTo('docket-folder')
  declare docketFolder: AsyncBelongsTo<DocketFolder>;
  @belongsTo('docket') declare docket: AsyncBelongsTo<Docket>;
  //docketConsolidatedTypeToDocketTypeGroup: DS.belongsTo('docket-consolidated-type-to-docket-type-group'),
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'docket-to-folder': DocketToFolder;
  }
}
