import classic from 'ember-classic-decorator';
import { action, computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import momentjs from 'moment';
import { tagName } from '@ember-decorators/component';
import fetch from 'fetch';
import { task } from 'ember-concurrency';

export function getAgreementSessionProperty(siteId, agreementId) {
  return `access-agreement-${siteId}-${agreementId}`;
}

@tagName('')
@classic
export default class AccessAgreementComponent extends Component {
  @service session;
  @service serverVariables;

  @computed('model.textContent.firstObject.templateText')
  get templateText() {
    let textContent = this.get('model.textContent').find(
      (tc) => String(tc.languageId) === 56
    );
    if (!textContent) {
      textContent = this.get('model.textContent.firstObject');
    }

    if (textContent) {
      let element = document.createElement('div');
      element.innerHTML = textContent.templateText;
      return element.innerText;
    } else {
      return null;
    }
  }

  @computed
  get currentYear() {
    return momentjs().format('YYYY');
  }

  @action
  showTermsOfUse() {
    this.popupService1.open(
      'terms-of-use',
      'terms-of-use',
      'fa fa-info-circle',
      this.i18n.t('dashboard.termsOfUse')
    );
  }

  @action
  showPrivacyPolicy() {
    this.popupService1.open(
      'privacy-policy',
      'privacy-policy',
      'fa fa-info-circle',
      this.i18n.t('dashboard.privacyPolicy')
    );
  }

  @task(function* () {
    //send accept to api
    var textTemplateContentId = this.get(
      'model.textContent.firstObject.textTemplateContentId'
    );

    let acceptUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${this.model.siteId}/accessagreements/${
      this.model.id
    }/accept/${textTemplateContentId}`;

    let response = yield fetch(acceptUrl, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${get(
          this.session,
          'data.authenticated.access_token'
        )}`,
        'content-type': 'application/json; charset=utf-8',
      },
    });

    if (response.ok) {
      if (this.onAccept) {
        window.sessionStorage.setItem(
          getAgreementSessionProperty(this.model.siteId, this.model.id),
          true
        );
        this.onAccept();
      }
    } else {
      throw new Error('Request to server has failed. Please try again later.');
    }
  })
  acceptAgreementTask;

  @task(function* () {
    //send accept to api
    var textTemplateContentId = this.get(
      'model.textContent.firstObject.textTemplateContentId'
    );

    let acceptUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${this.model.siteId}/accessagreements/${
      this.model.id
    }/decline/${textTemplateContentId}`;

    let response = yield fetch(acceptUrl, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${get(
          this.session,
          'data.authenticated.access_token'
        )}`,
        'content-type': 'application/json; charset=utf-8',
      },
    });

    if (response.ok) {
      if (this.onDecline) {
        this.onDecline();
      }
    } else {
      throw new Error('Request to server has failed. Please try again later.');
    }
  })
  declineAgreementTask;

  @action
  accept() {
    this.acceptAgreementTask.perform();
  }

  @action
  decline() {
    this.declineAgreementTask.perform();
  }

  @action
  logout() {
    this.session.invalidate();
  }

  /*

  model(params) {
    var templateTypeId = 1; //login template
    let languages = this.store.peekAll('translation');
    let language = this.tS.getSelectedLanguage(languages);
    return this.store.queryRecord('access-agreement-view', {
      templateTypeId: templateTypeId,
      categoryId: this.get('globals.myInfo.categoryId.id'),
      languageId: language.get('languageId') || 56
    }).then(
      agreement => {
        if (agreement) {
          return agreement;
        } else {
          this.transitionToRoute('room.dashboard');
        }
      },
      () => {
        this.transitionToRoute('room.dashboard');
      }
    );
  }
  */
}
