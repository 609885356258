import SmartroomAdapter from './smartroom';

import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class SiteAdapter extends SmartroomAdapter {
  @service smartroom;
  pluralizedEndpoint = false;

  query(store, type, query) {
    let url = this.buildURL(type.modelName, null, null, 'query', query);

    if (this.sortQueryParams) {
      query = this.sortQueryParams(query);
    }

    return this.smartroom
      .fetchWrapper(url, 'POST', JSON.stringify(query))
      .then((result) => {
        let items = result.items;
        let paging = result.paging;
        let files = [];
        let folders = [];

        for (let item of items) {
          if (item.fileInfo) {
            files.push(item.fileInfo);
            item.fileInfo = item.fileInfo.fileId;
          }
          if (item.folderInfo) {
            folders.push(item.folderInfo);
            item.folderInfo = item.folderInfo.folderId;
          }
        }

        return {
          items: items,
          paging: paging,
          'smartroom/files': files,
          'smartroom/folders': folders,
        };
      });
  }
}
