import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import Abstract from './abstract';
import User from './user';

const modelValidations = buildValidations({
  title: [validator('presence', true)],
});

export default class Tag extends Abstract.extend(modelValidations) {
  @attr('string') declare title: string;
  @attr('string') declare userId?: string;
  @attr('date') declare createTime?: Date;
  @attr('date') declare modifyTime?: Date;

  @belongsTo('user') declare user: AsyncBelongsTo<User>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tag: Tag;
  }
}
