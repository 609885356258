import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
//@ts-ignore;
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';

import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

interface SmartroomUsersArgs {
  smartRoomId: string;
  onSelect: any;
}

export default class SmartroomUsers extends Component<SmartroomUsersArgs> {
  @service('docker-item') docker!: DockerItemService;
  @service declare store: Store;

  @tracked contacts: any;
  @tracked selectedPerson: any;

  constructor(owner: any, args: any) {
    super(owner, args);
  }

  @task({
    restartable: true,
  })
  *searchContactsTask(term: any) {
    yield timeout(500);

    return yield this.store.query('smartroom/user', {
      siteId: this.args.smartRoomId,
      for: 'manageuser',
      searchKey: term ? term : '',
      pageSize: 50,
    });
  }

  @action
  viewPerson(person: any) {
    this.docker.popupPerson(person, person.company);
  }

  @action
  selectPerson(person: any) {
    this.selectedPerson = person;
    console.log('selected smartroom user ', person);
    if (this.args.onSelect) {
      let resource = {
        userId: person.id,
        resourceType: 'SmartRoomUser',
        name: person.name,
        email: person.email,
        company: person.companyName,
      };
      this.args.onSelect(resource);
    }
  }
}
