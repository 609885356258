import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment';
import { action } from '@ember/object';

//@ts-ignore
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AppEventsService from 'cing-app/pods/app-events/service';
import SessionService from 'cing-app/pods/session/service';
import {
	Expressions,
	Filter,
	FilterOperators,
} from 'cing-app/mixins/filter-builder';
//@ts-ignore
import podNames from "ember-component-css/pod-names";
import { classify, dasherize } from "@ember/string";
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import DataLinkModel from 'cing-app/models/data-link-model';
import DataLinkModelColumn from 'cing-app/models/data-link-model-column';
import ExtendedDataMapping from 'cing-app/models/extended-data-mapping';
//@ts-ignore
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import DataLink, { ValueFormat } from 'cing-app/models/data-link';

interface ManageDataLinkModelEditArgs {
	context?: {
		model: DataLinkModel;
		dataLink: DataLink
	};
	onClose?: () => void;
	header?: any;
	footer?: any;
}

type ModelProperty = {
	property: string,
	isFromExtended: boolean,
	belongsTo: string | null,
};

export default class DataLinkDetail extends Component<ManageDataLinkModelEditArgs> {
	@service
	config: any;

	@service
	store!: Store;

	@service
	cookies: any;

	@service
	appEvents!: AppEventsService;

	@service('docker-item')
	docker!: DockerItemService;

	@service
	session!: SessionService;

	@tracked
	error: any;

	@tracked
	editProp?: DataLinkModelColumn;

	@tracked
	showHidden: boolean = false;

	modelLookups!: Map<string, ModelProperty[]>;

	extendedDataMappings!: ExtendedDataMapping[];

	@alias('args.context.model') model!: DataLinkModel;
	@alias('args.context.dataLink') dataLink!: DataLink;

	constructor(owner: any, args: ManageDataLinkModelEditArgs) {
		super(owner, args);
		this.initTask.perform();
	}

	get styleNamespace() {
		return podNames["manage-data-links/detail/models/edit"];
	}

	get modelProperties() {
		let modelClass: any = this.store.modelFor(this.model.modelName);

		let props = this.modelLookups.get(modelClass.modelName)?.sortBy('property');

		if (this.showHidden) {
			return props;
		} else {
			return props?.filter((item) => {
				return (item.property !== 'id' && item.property.indexOf('-id') === -1 && dasherize(item.property).indexOf('-id') === -1);
			})
		}
	}

	@task
	initTask = taskFor(async (): Promise<void> => {
		this.modelLookups = new Map();

		let modelClass: any = this.store.modelFor(this.model.modelName);

		await this.getAllExtendedMappings.perform();
		await this.buildPropertiesForModel.perform(modelClass);

		console.log(this.modelLookups);
	});

	@task
	getAllExtendedMappings = taskFor(async (): Promise<void> => {
		// get the extended data mapping for this model
		let extendedDataMappings = await this.store.query('extended-data-mapping', {
			page: {
				size: 1000,
				number: 1
			},
		});

		this.extendedDataMappings = extendedDataMappings.toArray();
	})

	@task
	buildPropertiesForModel = taskFor(async (modelClass: any): Promise<void> => {
		if (!this.modelLookups.get(modelClass.modelName)) {
			// get all direct properties from the model
			let props: ModelProperty[] = Array.from(modelClass.attributes.keys()).filter(a => a !== 'extendedData').map((property: string) => ({ property, isFromExtended: false, belongsTo: null }));
			props.pushObject({
				property: 'id',
				isFromExtended: false,
				belongsTo: null
			})

			let extendedDataMapping: ExtendedDataMapping | undefined = this.extendedDataMappings.findBy('entityName', classify(modelClass.modelName));

			if (extendedDataMapping) {
				let extendedProperties = extendedDataMapping.config.dynamicProperties.map(dp => ({ property: `extendedData.${dp.propertyName}`, isFromExtended: true, belongsTo: null })) ?? [];

				props = [...props, ...extendedProperties];
			}

			this.modelLookups.set(modelClass.modelName, props);

			for (const [name, descriptor] of modelClass.relationshipsByName) {
				if (descriptor.kind === "belongsTo") {
					props.pushObject({
						property: name,
						isFromExtended: false,
						belongsTo: descriptor.type
					})
					let relationModelClass = this.store.modelFor(descriptor.type);
					await this.buildPropertiesForModel.perform(relationModelClass)
				}
			}

		}
	});

	@action
	update() {
		this.updateTask.perform(this.dataLink);
	}

	@task
	updateTask = taskFor(async (model: DataLink): Promise<DataLink> => {
		await model.save();

		return model;
	});

	@action
	changeProp(propName: 'maxWidth' | 'minWidth' | 'maxHeight', column: DataLinkModelColumn, evt: Event) {
		let text = (<HTMLInputElement>evt.target).value;
		let value = (text ? parseInt(text) : null);
		if (value !== null && Number.isInteger(value)) {
			column[propName] = value;
		} else {
			//@ts-ignore
			column[propName] = null;
		}
	}

	@task
	addPropertyTask = taskFor(async (prop: ModelProperty) => {
		try {
			//@ts-ignore
			let newColumn = this.store.createFragment('data-link-model-column', <DataLinkModelColumn>{
				propertyName: prop.property,
				label: humanize([dasherize(prop.property)]),
				isFromExtended: prop.isFromExtended
			})

			this.model.columns.pushObject(newColumn);
		} catch (e) {
			console.log("E: ", e)
		}
	})

	@action
	removeProperty(column: DataLinkModelColumn) {
		this.model.columns.removeObject(column);
	}

	@action
	editPropertyFormat(column: DataLinkModelColumn) {
		this.editProp = column;
	}

	@action
	updatePropertyFormat(column: DataLinkModelColumn) {
		if (this.editProp) {
			this.editProp = undefined;
		}
	}

	@action
	addProperty() {
		let propertyPath: ModelProperty[] = [];

		for (let a = 0; a < arguments.length; a++) {
			console.log('x: ', arguments[a])
			//@ts-ignore
			if (!(arguments[a] instanceof PointerEvent)) {
				propertyPath.pushObject(arguments[a].property);
			}
		}

		this.addPropertyTask.perform({
			property: propertyPath.join('.'),
			isFromExtended: propertyPath.lastObject?.isFromExtended || false,
			belongsTo: propertyPath.lastObject?.belongsTo || null
		})
	}

	@action
	toggleShowHidden() {
		this.showHidden = !this.showHidden;
	}
}
