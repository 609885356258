define("cing-app/components/file-icon", ["exports", "smex-ui-sr-filemanager/components/file-icon"], function (_exports, _fileIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fileIcon.default;
    }
  });
});
