import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import AppBuilder from 'cing-app/pods/appbuilder/service';
import classic from 'ember-classic-decorator';

@classic
export default class AppbuilderLink extends ApplicationAdapter {
  @service('store')
  store!: Store;
  @service('appbuilder')
  appbuilder!: AppBuilder;

  findRecord(store: any, type: any, id: any, snapshot: any) {
    return super.findRecord(store, type, id, snapshot).then((record: any) => {
      let caseId: string = record.data.attributes['ab-case-id'];
      if (caseId) {
        let include =
          'project.objects,project-template,form-excel-sheets,form.form-elements.search-view-layout,form.form-elements.form-element-format'; //,form.form-elements.answer-options';

        let abCase = this.store.peekRecord(
          this.appbuilder.getAbmModelName('appbuilder-link/case', id),
          caseId
        );

        if (abCase) {
          return record;
        } else {
          return this.store
            .findRecord(
              this.appbuilder.getAbmModelName('appbuilder-link/case', id),
              caseId,
              { include: include }
            )
            .then(() => {
              return record;
            });
        }
      } else {
        return record;
      }
    });
  }
}
