const EEventType = {
  CapitalCall: "Capital Calls",
  Distribution: "Distributions"
}

const EEventCalculationType = {
  EquallyDivided: "Equally Divided",
  ByOwnership: "By Ownership",
  PercentageOfCommitted: "Percentage of Committed",
  EquallyDivided: "Equally Divided",
  DistributionSameAmount: "Distribution Same Amount",
}

export {
  EEventType,
  EEventCalculationType
}