
export default {
    name: 'setup-login-check',
    after: 'setup-server-vars',
    initialize(app) {
        let { container = app } = app;
        let loginCheckService = container.lookup('service:login-check-service');
        loginCheckService.load();
    }
};
