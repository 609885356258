import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class extends Component {
  @tracked selectedValue;

  @action
  onChange(value) {
    this.selectedValue = value;
    this.args.onChange(value);
  }
}