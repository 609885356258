import Component from '@ember/component';
import {
  computed,
  observer,
  get,
  set,
  action
} from '@ember/object';
import { alias } from '@ember/object/computed';

import { inject as service } from '@ember/service';
import { ProjectTabMap } from 'cing-app/utils/lookups';
import classic from 'ember-classic-decorator';

@classic
export default class Distributions extends Component {
  @alias('context.investor') investor;
  @alias('context.abInvestor') abInvestor;
  @alias('context.project') project;
  @service('docker-item') docker;
  @service('store') store;
  @service() abModels;
  included = '';

  @computed('project.abProject')
  get modelName() {
    let abProject = this.get('project.abProject');

    if (abProject) {
      return this.abModels.getModelName(this.project, 'distribution');
    }
  }

  init() {
    super.init(...arguments);

    let projectTabs = this.project.get('projectTabs').then((projectTabs) => {
      let smartRoomTab = projectTabs.findBy('type', ProjectTabMap.SMARTROOM);
      let smartRoomId = smartRoomTab.settings.SmartRoomId;

      if (smartRoomTab) {
        this.setProperties({
          smartRoomId: smartRoomTab.settings.SmartRoomId,
        });
      }
    })

    set(this, 'columns', this.getColumns());

    // this.set('interestedPartyFilter', Ember.A());
    this.generateFilter();
  }

  getColumns() {
    let columns = [
      {
        propertyName: 'invFundId',
        title: 'Inv. Fund Id',
        sortedBy: 'inv-fund-id'
      },
      {
        propertyName: 'fundId',
        title: 'Fund Id',
        sortedBy: 'fund-id',
        isHidden: true
      },
      {
        propertyName: 'investorNumber',
        title: 'Investor Number',
        sortedBy: 'investor-number',
        isHidden: true
      },
      {
        propertyName: 'investorName',
        title: 'Investor Name',
        sortedBy: 'investor-name',
        isHidden: true
      },
      {
        propertyName: 'eventId',
        title: 'Event Id',
        sortedBy: 'event-id',
        isHidden: true
      },
      {
        propertyName: 'dueDate',
        title: 'Due Date',
        component: 'api-table/columns/date',
        sortedBy: 'due-date',
        sortPrecedence: 1,
        sortDirection: 'desc'
      },
      {
        propertyName: 'noticeDate',
        title: 'Notice Date',
        component: 'api-table/columns/date',
        sortedBy: 'notice-date'
      },
      {
        propertyName: 'eventName',
        title: 'Name',
        sortedBy: 'event-name'
      },
      {
        propertyName: 'description',
        title: 'Description',
      },
      {
        propertyName: 'amount',
        title: 'Amount',
        component: "api-table/columns/amount",
        sortedBy: 'amount'
      },
      {
        propertyName: 'prefix',
        title: 'Prefix',
        sortedBy: 'prefix',
        isHidden: true
      },
      {
        propertyName: 'suffix',
        title: 'Suffix',
        sortedBy: 'suffix',
        isHidden: true
      },
      {
        propertyName: 'fileId',
        title: 'File Id',
        sortedBy: 'file-id',
        isHidden: true
      },
      {
        propertyName: 'fileName',
        title: 'File Name',
        sortedBy: 'file-name',
        component: "project-detail/investors/column-srdocument",
        viewFile: this.viewFile
      },
      {
        propertyName: 'current',
        title: 'Current',
        sortedBy: 'current'
      },
      {
        propertyName: 'eventStatus',
        title: 'Status',
        sortedBy: 'event-status'
      },
      {
        propertyName: '',
        component: "project-detail/investors/column-actions",
      },
    ]

    return columns;
  }

  generateFilter() {
    let filter = {
      'inv-fund-id': 'eq:' + this.get('abInvestor.invFundId')
    }

    this.set('filter', filter);
  }

  @action
  viewFile(record) {
    let appearance = {
      icon: '',
      title: `View file ${record.fileName}`,
      size: 'large',
      custom: true
    };

    this.docker.invokePopup('smartroom/file-viewer', appearance, {
      fileId: record.fileId,
      siteId: this.smartRoomId,
    });
  }

  @action
  edit(record) {
    let appearance = {
      icon: '',
      title: `Edit distribution ${record.investorName} - ${record.eventName}`,
      //size: 'large',
      custom: true
    };

    this.docker.invokePopup('ab-management/investors/distribution', appearance, {
      project: this.project,
      model: record
    });
  }

  @action
  add() {
    let appearance = {
      icon: '',
      title: `Add distribution for ${this.abInvestor.investorName}`,
      //size: 'large',
      custom: true
    };

    let record = this.store.createRecord(this.modelName, {
      invFundId: this.abInvestor.invFundId,
      fundId: this.abInvestor.fundId,
      investorNumber: this.abInvestor.fundInvestorNumber,
      investorName: this.abInvestor.investorName
    });

    this.docker.invokePopup('ab-management/investors/distribution', appearance, {
      project: this.project,
      model: record
    });
  }
}