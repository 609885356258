import classic from 'ember-classic-decorator';
import { inject } from '@ember/service';
import Component from "@ember/component";
import EmberObject, { action, computed } from "@ember/object";
import { task, timeout } from 'ember-concurrency';
import RoleKeeper from 'cing-app/mixins/role-keeper';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';

import Bootstrap4Theme from 'ember-models-table/themes/bootstrap4';

let ApiFooterTheme = Bootstrap4Theme.extend({
  table: 'table table-bordered table-hover table-sm',
  buttonDefault: 'btn btn-link',
  paginationInternalWrapper: '',
  paginationWrapperNumeric: '',
  paginationWrapperDefault: '',
  pageSizeWrapper: '',
  components: {
    'columns-dropdown': 'api-table/columns-dropdown'
  }
});


@classic
export default class Document extends Component.extend(RoleKeeper) {
  @inject()
  store;

  @inject()
  config;

  @inject('docker-item')
  docker;

  reloadData = false;
  includes = ['attachments', 'related-dockets', 'docket-notes', 'docket-type'];
  includesForFolder = ['docket', 'docket-folder', 'attachments', 'docket-notes', 'related-dockets', 'docket-consolidated-type', 'docket-type'];
  foldersTree = false;

  @computed('isAdmin')
  get columns() {
    let c = [
      {
        component: "api-table/select-row-checkbox",
        useFilter: false,
        mayBeHidden: true,
        title: "",
        className: "column-checkbox",
        componentForSortCell: "api-table/select-all-rows-checkbox"
      },
      {
        title: "Title",
        component: "case-detail/document/column-title",
        propertyName: "title",
        sortDirection: 'asc',
        sortPrecedence: 0,
        // sortedBy: "title",
        className: "column-title",
      },
      {
        title: "Description",
        component: "case-detail/docket/column-document-name",
        className: "column-docket-text",
      },
      {
        title: "Original filename",
        propertyName: "fileMetadata.OriginalFileName",
        disableSort: true
      },
      {
        title: "Size",
        propertyName: "fileMetadata.FileSize",
        component: "api-table/columns/file-size",
        className: "column-file-size",
        disableSort: true
      },
      {
        title: "Uploaded",
        propertyName: 'fileMetadataDate',
        component: "api-table/columns/date",
        className: "text-right column-date",
        dateFormat: "MM/DD/YYYY",
        //timeZone: "US/Eastern"
        timeZone: "America/New_York",
      },

    ];

    return c;
  }

  init() {
    super.init(...arguments);

    this.initTask.perform();
  }

  @task(function* () {
    // this.set('selectedDocuments', []);
    if (!this.get('presetFilter.dateRange')) {
      this.set('presetFilter.dateRange', { start: null, end: null });
    }

    this.generateFilter();
  })
  initTask;

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(Filter.create({
      name: 'case-id',
      operator: FilterOperators.EQUAL,
      value: this.get('selectedCase.id')
    }));
    expr.add(Filter.create({
      name: 'parent-id',
      operator: FilterOperators.NULL,
    }));

    if (this.get('presetFilter.selectedFolder') === 'unspecified') {
      expr.add(Filter.create({
        name: 'docket-to-folders.docket-id',
        operator: FilterOperators.NULL
      }));
    }

    this.set('filter', this.buildCommonFilter(expr, ''));
  }

  buildCommonFilter(expr, relationship) {
    let preset = this.presetFilter;
    let searchQuery = preset.get('searchQuery');
    if (searchQuery) {
      const fields = [relationship + 'title', relationship + 'description', relationship + 'filed-by-attorney'];
      let searchQueryExpr = Expressions.create({ operator: ExpressionOperators.OR });
      fields.forEach((propName) => {
        searchQueryExpr.add(Filter.create({
          name: propName,
          operator: FilterOperators.LIKE,
          value: searchQuery
        }));
      });
      expr.add(searchQueryExpr);
    }

    const dName = relationship + 'docket-number';
    let docketNumber = preset.get('docketNumber');
    if (docketNumber) {
      let range = docketNumber.split(/\s*(?:-|$)\s*/);
      if (range.length > 1) {
        expr.add(RangeFilter.create({
          name: dName,
          value1: range[0],
          value2: range[1]
        }));
      } else {
        expr.add(Filter.create({
          name: dName,
          operator: FilterOperators.EQUAL,
          value: docketNumber
        }));
      }
    } else {
      expr.add(Filter.create({
        name: dName,
        operator: FilterOperators.LESS_THAN_OR_EQUAL,
        value: -1000000
      }));
    }

    const rName = relationship + 'published-date';
    let dateRange = preset.get('dateRange');
    if (dateRange.start && dateRange.end) {
      expr.add(DateRangeFilter.create({
        dateFormat: this.get('config.APP.serverDateFormat'),
        name: rName,
        value1: dateRange.start,
        value2: dateRange.end
      }));
    }

    return expr.serialize();
  }

  @action
  clearSelection() {
    this.selectedDocuments.clear();
  }

  @action
  selectFolder(folder) {
    this.set('presetFilter.selectedFolder', folder);
    this.send('search');
  }

  @action
  search(force) {
    this.generateFilter();

    if (force) {
      this.set('reloadData', true);
    }
  }

  @action
  onDataUpdated() {
    let searchQuery = this.get('presetFilter.searchQuery');

    if (searchQuery) {
      this.$().unhighlight();
      this.$().highlight(searchQuery);
    }
  }

  @action
  viewDocket(docket) {
    let appearance = {
      icon: '',
      title: docket.get('popupTitle'),
      size: 'extra-large',
      customPopup: true
    };
    this.docker.invokePopup('view-pdf', appearance, {
      docket: docket,
    });
  }
}
