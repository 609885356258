import loadComponents from './components';

export default function () {
  grapesjs.plugins.add('model-property', (editor, opts = {}) => {
    window.editor = editor;

    // Add components
    //loadCommands(editor, options);
    //loadTraits(editor, options);
    loadComponents(editor, opts);
    //loadDevices(editor, options);
    //loadCss(editor, options);
  })
}