import ApplicationRouteMixin from 'ember-smartidentity-auth/mixins/app-mixin';
import { get, set, action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { ESettings } from 'cing-app/utils/lookups';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

const PortalSettingsList = Object.keys(ESettings);

export default class AppRoute extends Route.extend(ApplicationRouteMixin) {
  @service session;
  @service config;
  @service store;

  model() {
    return this.loadSettings.perform();
  }

  @task
  *loadSettings() {
    // construct list of settings IDs
    let PortalSettingsListIds = [];
    for (var a = 0; a < PortalSettingsList.length; a++) {
      PortalSettingsListIds.push(ESettings[PortalSettingsList[a]]);
    }

    // get the existing settings from API
    let settingsQuery = Expressions.create();
    settingsQuery.add(
      Filter.create({
        name: 'key',
        operator: FilterOperators.IN,
        value: PortalSettingsListIds,
      })
    );

    let existingSettings = yield this.store.query('setting', {
      condition: settingsQuery.serialize(),
      page: {
        size: 100,
      },
      sort: '-modify-time',
    });

    // get unique list of settings
    let settings = {};

    existingSettings.forEach((setting) => {
      settings[setting.key] = setting.value;
    });

    set(this.config, 'settings', settings);
  }

  @action
  applicationReloadSettings() {
    this.loadSettings.perform();
  }
}
