import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import Company from './company';

export default class CompanyAttribute extends Abstract {
  @attr('string') declare name?: string;
  @attr('string') declare companyId: string;
  @attr('string') declare value?: string;
  @attr('number') declare type?: number;
  @attr('number') declare order?: number;
  //@ts-ignore - mixin
  @belongsTo('company') declare company: AsyncBelongsTo<Company>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-attribute': CompanyAttribute;
  }
}
