import Component from '@glimmer/component';
//@ts-ignore
import injectScripts from 'ember-inject-scripts';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
interface AlertDetailArgs {
  appearance: any;
  context: any;
}

const multipleScripts = [
  {
    id: 'grapesjs',
    src: '/libraries/grapesjs/grapes.min.js',
    once: true,
  },
  {
    id: 'mjml-browser',
    src: '/libraries/mjml-browser/mjml-browser.js',
    once: true,
  },
];

export default class AlertDetailPopup extends Component<AlertDetailArgs> {
  constructor(owner: unknown, args: AlertDetailArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    await injectScripts(multipleScripts);
  });

  get htmlTemplate() {
    let template = this.args.context.templateData;
    return mjml(template);
  }
  get alertDetail() {
    return this.args.context;
  }
}
