define("cing-app/components/spacer", ["exports", "smex-ui-table/components/spacer"], function (_exports, _spacer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _spacer.default;
    }
  });
});
