import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import DockerItemService from 'cing-app/pods/docker-item/service';
import AbPmService from 'cing-app/pods/ab-pm/service';

interface BulkUpdateMenuArgs {
  abLink: any;
  smartRoomId: any;
  tasks: [];
  onTaskAction: any;
}

export default class BulkUpdateMenu extends Component<BulkUpdateMenuArgs> {
  @service('ab-pm') pmService!: AbPmService;
  @service('docker-item') docker!: DockerItemService;

  @tracked editPopup: any;
  @tracked isDeletingTasks: boolean = false;
  @tracked deleteMessage: string = '';

  @action bulkEdit() {
    let appearance = {
      icon: 'plus',
      title: 'Bulk Edit Tasks',
      custom: true,
    };
    let ctx = {
      abLink: this.args.abLink,
      smartRoomId: this.args.smartRoomId,
      tasks: this.args.tasks,
    };
    this.editPopup = this.docker.invokePopup(
      'ab-pm/bulk-update',
      appearance,
      ctx
    );
  }

  @action bulkDelete() {
    this.isDeletingTasks = true;
  }

  @action bulkMove() {
    alert('Feature is not functional yet!');
    if (this.args.onTaskAction) {
      this.args.onTaskAction('bulk-move', this.args.tasks);
    }
  }

  @action clearSelection() {
    if (this.args.onTaskAction) {
      this.args.onTaskAction('clear-selection');
    }
  }

  @action onDeleteConfirmed() {
    this.deleteTasks.perform();
  }

  @task deleteTasks = taskFor(async () => {
    this.deleteMessage = 'Preparing to delete tasks...';
    for (var i = 0; i < this.args.tasks.length; i++) {
      let task = this.args.tasks[i];

      this.deleteMessage = `Deleting ${task.task}...`;
      await this.pmService.deleteTask(task, this.args.abLink);
      this.deleteMessage = `{task.task} is now deleted`;
    }

    //await this.pmService.recalculateWBS();

    this.deleteMessage = 'Selected tasks are now deleted.';
    setTimeout(() => {
      this.isDeletingTasks = false;
      if (this.args.onTaskAction) {
        this.args.onTaskAction('clear-selection');
      }
    }, 1000);

    if (this.args.onTaskAction) {
      this.args.onTaskAction('bulk-delete', this.args.tasks);
    }
  });
}
