import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  ISearchFilter,
  ReturnType,
  SearchBy,
  SearchIn,
} from 'smex-ui-sr-filemanager';
import { Models, MyInfoResource } from 'smex-ui-sr-models';
import Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import { cached } from 'tracked-toolbox';

interface SmartroomSearchFilterArgs {
  siteId: string;
  categoryId: string;
  filter: ISearchFilter;
  filterChange: (filter: ISearchFilter) => void;
}

export default class SmartroomSearchFilter extends Component<SmartroomSearchFilterArgs> {
  @service('store')
  store!: Store;
  @service('models')
  models!: Models;

  searchBy = SearchBy;
  returnType = ReturnType;
  searchIn = SearchIn;

  @tracked
  isOpened = false;

  @tracked
  filter!: ISearchFilter;

  @tracked
  allTags!: string[];

  constructor(owner: any, args: SmartroomSearchFilterArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @action
  filterChanged() {
    this.filter = {
      ...this.args.filter,
    };
  }

  @cached
  get myInfoResource() {
    return new MyInfoResource(this.models, this.args.siteId);
  }

  @action
  searchInputTrim() {
    if (this.filter.q) {
      this.filter.q = this.filter.q.trim();
    }
  }

  @action
  textChanged(evt: Event) {
    this.filter = {
      ...this.filter,
      q: (<HTMLInputElement>evt.target).value,
    };
    // await timeout(500);
    // this.args.filterChange(this.filter);
  }

  get showMatchAnyWords() {
    if (this.myInfoResource.myInfo) {
      return (
        !this.myInfoResource.myInfo.enableNalyticsSearch ||
        this.filter.searchIn === SearchIn.contents ||
        this.filter.searchIn === SearchIn.namesContents
      );
    }
    return false;
  }

  get showMatchAllWords() {
    if (this.myInfoResource.myInfo) {
      return this.showMatchAnyWords;
    }
    return false;
  }

  get showExactly() {
    if (this.myInfoResource.myInfo) {
      return (
        this.myInfoResource.myInfo.enableNalyticsSearch &&
        (this.filter.searchIn === SearchIn.contents ||
          this.filter.searchIn === SearchIn.namesContents)
      );
    }
    return false;
  }

  get showExclude() {
    if (this.myInfoResource.myInfo) {
      return !this.myInfoResource.myInfo.enableNalyticsSearch;
    }
    return false;
  }

  @action
  submit() {
    this.args.filterChange(this.filter);
  }

  @action
  tagsChanged(tags: string[]) {
    this.args.filterChange({
      ...this.filter,
      tags: tags,
    });
  }

  @action
  changeSearchBy(searchBy: SearchBy) {
    this.args.filterChange({
      ...this.filter,
      searchBy: searchBy,
    });
  }

  @action
  changeTagsMatchAll(tagsMatchAll: boolean) {
    this.args.filterChange({
      ...this.filter,
      tagsMatchAll: tagsMatchAll,
    });
  }

  @action
  clearText() {
    this.args.filterChange({
      ...this.filter,
      q: '',
    });
  }

  @task
  searchTags = taskFor(async (term: string) => {
    if (term) {
      term = term.toLowerCase();
    }

    let tags = await this.store.query(
      this.models.getModelNameSite('smartroom/tag', this.args.siteId),
      {
        pageNo: 1,
        pageSize: 100,
        q: term,
      }
    );
    return tags.map((t) => t.tag);
  });

  @task
  initTask = taskFor(async () => {
    this.allTags = await this.searchTags.perform('');
  });

  @action
  changeReturnType(typeClicked: ReturnType, checked: boolean) {
    let files = [ReturnType.both, ReturnType.file].includes(
      this.filter.returnItemType
    );
    let folders = [ReturnType.both, ReturnType.folder].includes(
      this.filter.returnItemType
    );

    if (typeClicked === ReturnType.file) {
      files = checked;
      if (!files && !folders) {
        folders = true;
      }
    } else {
      folders = checked;
      if (!files && !folders) {
        files = true;
      }
    }

    let returnType =
      files && folders
        ? ReturnType.both
        : files
        ? ReturnType.file
        : ReturnType.folder;

    this.args.filterChange({
      ...this.filter,
      returnItemType: returnType,
    });
  }

  @action
  changeSearchIn(searchInClicked: SearchIn, checked: boolean) {
    let names = [SearchIn.names, SearchIn.namesContents].includes(
      this.filter.searchIn
    );
    let contents = [SearchIn.contents, SearchIn.namesContents].includes(
      this.filter.searchIn
    );

    if (searchInClicked === SearchIn.names) {
      names = checked;
      if (!names && !contents) {
        contents = true;
      }
    } else {
      contents = checked;
      if (!names && !contents) {
        names = true;
      }
    }

    let searchIn =
      names && contents
        ? SearchIn.namesContents
        : names
        ? SearchIn.names
        : SearchIn.contents;

    this.args.filterChange({
      ...this.filter,
      searchIn: searchIn,
    });
  }
}
