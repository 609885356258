import Component from '@ember/component';
import { get, set, action } from '@ember/object';

import { inject as service } from '@ember/service';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import Person from 'cing-app/models/person';

const SHOW_AS_OPTION_1 = 1;
const SHOW_AS_OPTION_2 = 2;
const SHOW_AS_OPTION_3 = 3;

const SHOW_AS_OPTIONS = [
  {
    value: SHOW_AS_OPTION_1,
    name: 'Last, First Middle',
  },
  {
    value: SHOW_AS_OPTION_2,
    name: 'First Middle Last',
  },
  {
    value: SHOW_AS_OPTION_3,
    name: 'First Last',
  },
];
import classic from 'ember-classic-decorator';

@classic
export default class ByContact extends Component {
  searchAndSelect = false;
  @service store;
  @service session;
  @service('docker-item') docker;
  @service config;
  pageSize = 100;
  Person = Person;
  SHOW_AS_OPTIONS = SHOW_AS_OPTIONS;
  searchQuery = '';

  searchOptions = {
    firstName: true,
    lastName: true,
  };

  init() {
    super.init(...arguments);
    set(this, 'showAsOption', SHOW_AS_OPTIONS[0]);
    this.generateFilter();
    set(this, 'columns', this.getColumns());

    if (get(this, 'searchAndSelect')) {
      set(this, 'selectedItems', []);
    }
  }
  getColumns() {
    let columns = [
      {
        propertyName: 'fullName',
        title: 'Contact Name',
        className: 'column-person-name',
        sortedBy: 'last-name',
        sortDirection: 'asc',
        sortPrecedence: 0,
        component: 'crm-search/by-contact/column-name',
      },
      {
        propertyName: 'company.name',
        title: 'Company',
        className: 'column-company-type',
        component: 'crm-search/by-contact/column-company-name',
      },
      {
        propertyName: 'emails',
        title: 'Emails',
        className: 'column-emails',
        sortedBy: 'emails.email-address',
        component: 'crm-search/by-contact/column-emails',
      },
      {
        propertyName: 'user',
        title: 'Account',
        component: 'crm-search/by-contact/column-account',
        sortedBy: 'emails.email-address',
        openUserDetail: this.openUserDetail,
      },
      {
        title: 'User Groups',
        propertyName: 'user.groups',
        component: 'crm-search/by-contact/column-user-groups',
        // sortedBy: "user.groups.name",
        sortedBy: 'user.groups.name',
      },
    ];

    if (get(this, 'searchAndSelect')) {
      columns.push({
        component: 'api-table/select-one',
        useFilter: false,
        title: 'Select',
        className: 'column-select-one',
      });
    }

    return columns;
  }

  generateFilter() {
    const searchQuery = this.searchQuery;

    /*
if (!searchQuery) {
    this.set('filter', null);
    return;
}
*/
    let expr = Expressions.create({ operator: ExpressionOperators.OR });

    if (searchQuery) {
      let termParts = searchQuery.split(' ');

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            name: 'first-name',
            operator: FilterOperators.LIKE,
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            name: 'last-name',
            operator: FilterOperators.LIKE,
            value: termParts[termParts.length - 1],
          })
        );

        expr.add(nameExpression);
      }

      ['first-name', 'last-name', 'emails.email-address'].forEach(
        (propName) => {
          expr.add(
            Filter.create({
              name: propName,
              operator: FilterOperators.LIKE,
              value: searchQuery,
            })
          );
        }
      );
    }

    set(this, 'filter', expr.serialize());
  }

  @action
  onSelectOne(attorney) {
    this.sendAction('onSelect', attorney);
  }

  @action
  search() {
    this.generateFilter();
  }

  @action
  addContact() {
    this.docker.popupEditPerson(this.store.createRecord('person'));
  }

  @action
  openUserDetail(user) {
    user.then((user) => {
      const appearance = {
        label: `Update user: ${user.email}`,
        icon: '',
        title: `Update user: ${user.email}`,
        custom: true,
        size: 'medium',
      };

      let self = this;

      this.docker.invokePopup('manage-users/users/detail', appearance, {
        model: user,
        onUpdate: function () {
          self.set('reloadData', true);
        },
      });
    });
  }
}
