import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { ESettings } from 'cing-app/utils/lookups';
import Service from '@ember/service';
import Store from '@ember-data/store';

import {
  exportableAttrsListProp,
  exportableProp,
} from 'cing-app/utils/exportable';
import 'reflect-metadata';
import { dasherize } from '@ember/string';
import moment from 'moment';
import { pluralize } from 'ember-inflector';

export default class ExportDataService extends Service {
  @service
  store!: Store;

  @service seActions: any;

  _getExportableAttrs(target: any, recursive: Boolean = true): string[] {
    let allAttrs = Reflect.getMetadata(
      exportableAttrsListProp,
      target.prototype
    );

    if (allAttrs) {
      let relationships = target.prototype.constructor.relationshipsByName;

      let exportableAttrs = allAttrs.filter((attr: string) => {
        return relationships.get(attr) === undefined;
      });

      if (recursive) {
        let exportableRelationshipAttrs: string[] = [];

        allAttrs.forEach((attribute: any) => {
          let rel = relationships.get(attribute);

          if (rel && rel.kind === 'belongsTo') {
            let exportableNestedAttrs = Reflect.getMetadata(
              exportableProp,
              target.prototype,
              attribute
            );

            let relModel = this.store.modelFor(rel.type);
            let relAttrs = this._getExportableAttrs(
              relModel,
              exportableNestedAttrs === true ? false : true
            );

            if (exportableNestedAttrs === true) {
              relAttrs = relAttrs.map((attrName) => {
                return `${attribute}.${attrName}`;
              });
            } else {
              let selectedRelAttrs: string[] = [];

              relAttrs.forEach((attrName) => {
                if (exportableNestedAttrs.includes(attrName)) {
                  selectedRelAttrs.pushObject(`${attribute}.${attrName}`);
                }
              });

              relAttrs = selectedRelAttrs;
            }

            exportableRelationshipAttrs.pushObjects(relAttrs);
          }
        });

        exportableAttrs.pushObjects(exportableRelationshipAttrs);
      }

      exportableAttrs = exportableAttrs.map((attr: string) => {
        return dasherize(attr);
      });

      //console.log(exportableAttrs);
      return exportableAttrs;
    } else {
      return [];
    }
  }

  async export(
    entityType: string,
    model: any,
    sortBy: string,
    condition: string
  ) {
    let exportableAttributes = this._getExportableAttrs(model, true);

    let dateString = moment().format('MM-DD-YYYY');

    await this.seActions.exportData(
      entityType,
      dasherize(`${pluralize(entityType)}-${dateString}.xlsx`),
      sortBy ? dasherize(sortBy) : null,
      condition,
      exportableAttributes
    );
  }
}
