import Model, { attr } from '@ember-data/model';

export default Model.extend({
  siteId: attr('number'),
  templateTypeId: attr('number'),
  templateTypeName: attr('string'),
  isDefault: attr('boolean'),
  templateTitle: attr('string'),
  emailOption: attr('number'),
  freeForm: attr('boolean'),
  dateCreated: attr('date'),
  dateModified: attr('date'),
  alertSettingTypeId: attr('number'),
  siteRedirectUrlId: attr('number'),

  customLogo: attr('json'),
  categories: attr('list'),
  textContent: attr('list'),
})