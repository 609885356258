import Component from '@glimmer/component';

interface ColumnComponentArgs {
  attributes:any;
}

export default class ColumnComponent extends Component<ColumnComponentArgs> {
  get attributes(){
    return this.args.attributes;
  }
 }