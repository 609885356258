import { attr } from '@ember-data/model';
import Abstract from './abstract';

export default class EventTypeModel extends Abstract {
  @attr('string') declare name: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'event-type': EventTypeModel;
  }
}
