import Component from '@glimmer/component';
import User from 'cing-app/models/user';

interface ColumnPeopleArgs {
  record: User;
  column: any;
}

export default class ColumnPeople extends Component<ColumnPeopleArgs> {

  get person() {
    return this.args.record[this.args.column.propertyName]
  }
}