
import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency'
//@ts-ignore
import { AddressTypes, PhoneTypes } from 'cing-app/utils/lookups';
//@ts-ignore
import DockerItemService from 'cing-app/pods/docker-item/service';
import Store from '@ember-data/store';
//@ts-ignore
import _SessionService from 'cing-app/pods/session/service';
import { taskFor } from 'ember-concurrency-ts';
import { async } from 'rsvp';
import { tracked } from '@glimmer/tracking';

interface ProfileArgs {
  company: any;
  onClose: any;
}

export default class Profile extends Component<ProfileArgs> {
  @inject('dockerItem') docker!: DockerItemService;
  @inject() store!: Store;
  @inject() session!: _SessionService;

  @tracked
  companyProfile: any;

  @tracked
  hqAddress: any;

  @tracked
  hqPhone: any;

  @tracked
  amounts: any;

  @tracked
  attributes: any;

  @tracked
  company: any;

  constructor(owner: any, args: ProfileArgs) {
    super(owner, args);
    this.company = this.args.company;
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {

    this.companyProfile = await this.company.companyProfile;

    let addresses = await this.company.addresses;

    this.hqAddress = addresses.filterBy('addressTypeId', AddressTypes.Headquarters).get('firstObject');

    let phones = await this.company.phones;

    this.hqPhone = phones.filterBy('typeId', PhoneTypes.Headquarters).get('firstObject');

    this.amounts = await this.company.amounts;

    this.attributes = await this.company.attributes;
  })

  @action
  editCompany(record: any) {
    let self = this;

    this.docker.popupEditCompany(record, {
      onRemove: function () {
        if (self.args.onClose) {
          self.args.onClose();
        }
      }
    });
  }
}