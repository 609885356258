import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';

import AbPmService from 'cing-app/pods/ab-pm/service';

interface TaskNodeArgs {
  abLink: any;
  smartRoomId: any;
  columns: any;
  parentTask: any;
  task: any;
  selectedTask: any;
  onTaskAction: any;
  isAllExpanded: any;
  checkedTasks: [];
}

export default class TaskNode extends Component<TaskNodeArgs> {
  @service('ab-pm') pmService!: AbPmService;

  @tracked columnsMeta: any = {};
  @tracked isExpanded = false;
  @tracked isChecked = false;

  constructor(owner: any, args: any) {
    super(owner, args);

    console.log(`${args.task.task} children = ${args.task.children?.length}`);

    args.task.children = args.task.children || [];
    this.isExpanded = args.isAllExpanded;
    this.isChecked = args.task.isChecked;

    if (!args.task.taskResources) {
      args.task.taskResources = [];
    }

    this.expandAllToggled();
  }

  get children() {
    return this.args.task.children || [];
  }

  get isActive() {
    return this.isSelected || this.isChecked;
  }

  get isSelected() {
    return this.args.task.id == this.args.selectedTask?.id;
  }

  get isParent() {
    return this.args.task.subtasks > 0 || this.args.task.children?.length > 0;
  }

  get attachments() {
    return this.args.task.documents.length;
  }

  @task loadSubtasks = taskFor(async () => {
    if (this.args.task.children.length == 0) {
      await this.pmService.loadTasks(this.args.task, this.args.abLink);
      console.log('children ', this.args.task.children);
    }
  });

  @action checkedTasksUpdated() {
    let t = this.args.checkedTasks.findBy('id', this.args.task.id);
    if (t) {
      //noop
      //the isChecked has already been set
    } else {
      this.isChecked = false;
    }
  }

  @action expandAllToggled() {
    //console.log("expand all toggled:", this.args.isAllExpanded);

    this.args.task.isExpanded = this.args.isAllExpanded;
    if (this.args.task.isExpanded) {
      this.loadSubtasks.perform();
    }
    this.isExpanded = this.args.task.isExpanded;
  }

  @action toggleNode(e: Event) {
    this.args.task.isExpanded = !this.args.task.isExpanded;

    if (this.args.task.isExpanded) {
      this.loadSubtasks.perform();
    }
    e.preventDefault();

    this.isExpanded = this.args.task.isExpanded;
  }

  @action refresh() {
    this.args.task.children.clear();
    this.loadSubtasks.perform();
  }

  @action checkTask(e: Event) {
    this.args.task.isChecked = this.isChecked;
    setTimeout(() => {
      if (this.isChecked) {
        this.triggerAction('check-task', this.args.task);
      } else {
        this.triggerAction('uncheck-task', this.args.task);
      }
    }, 2);
    e.preventDefault();
  }

  @action clickTask() {
    this.args.task.isSelected = true;
    this.triggerAction('select-task', this.args.task);
  }

  @action editTask() {
    this.args.task.isSelected = true;
    this.triggerAction('edit-task', this.args.task);
  }

  @action editResources() {
    this.args.task.isSelected = true;
    this.triggerAction('edit-resources', this.args.task);
  }

  triggerAction(action: any, arg1: any) {
    if (this.args.onTaskAction) {
      this.args.onTaskAction(action, arg1);
    }
  }
}
