import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import {
  PortalPageMap,
  AclPermissionType,
  AclEntityType,
} from 'cing-app/utils/lookups';
import { buildTree } from 'ember-simple-tree/utils/tree';

const TabPermissions = [AclPermissionType.PortalPageRead];

@classic
export default class Menu extends Component {
  @service
  store;

  @service('docker-item')
  docker;

  TabPermissions = TabPermissions;
  AclEntityType = AclEntityType;

  init() {
    super.init();
    this.initTask.perform();
  }

  @task(function* () {
    let pages = (yield this.store.query('portal-page', {
      page: {
        size: 1000,
      },
      sort: 'sort-order',
    })).toArray();
    //let pages = [];
    this.set('pages', pages);

    //let structure = buildTree(pages);

    //this.set('pages', buildTree(pages, {labelKey: 'title'}));
  })
  initTask;

  @task(function* () {
    let pages = this.get('pages');

    for (var a = 0; a < pages.length; a++) {
      pages[a].set('sortOrder', a);
      yield pages[a].save();
    }
  })
  saveTabsOrderTask;

  @action
  tabsSorted() {
    this.saveTabsOrderTask.perform();
  }

  @action
  editPage(page) {
    const appearance = {
      label: 'Edit Portal Page',
      icon: '',
      size: 'large',
      title: `Edit Portal Page: ${page.get('title')}`,
      custom: true,
    };

    let self = this;

    const context = {
      page: page,
      onRemove: function () {
        self.initTask.perform();
      },
      onClose: function () {
        self.initTask.perform();
      },
    };

    this.get('docker').invokePopup('portal-settings/page', appearance, context);
  }

  @action
  addPage() {
    let sortOrder = 1;

    let lastPage = this.get('pages.lastObject');

    if (lastPage && lastPage.sortOrder) {
      sortOrder = lastPage.sortOrder + 1;
    }

    let page = this.store.createRecord('portal-page', {
      sortOrder: sortOrder,
      title: 'New Page ' + sortOrder,
      type: PortalPageMap.TEXT,
      enabled: true,
    });

    this.pages.pushObject(page);

    this.send('editPage', page);
  }

  @action
  manageAccess(page) {
    this.docker.popupManageAccess(page, AclEntityType.PortalPage, page.title, [
      AclPermissionType.PortalPageRead,
    ]);
  }
}
