import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { action, computed, set, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { cached } from 'tracked-toolbox';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

import {
  AclPermissionType,
  AclPermissionTypeEnum,
  PartyType,
} from 'cing-app/utils/lookups';
import { CaseProjectUrlMap, ProjectTabMap } from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
  NotificationTypes,
} from 'cing-app/pods/smartroom/service';
import classic from 'ember-classic-decorator';
import { Models, MyInfoResource, CategoryResource } from 'smex-ui-sr-models';
import Store from '@ember-data/store';

@classic
export default class GenericPartyFlow extends Component {
  @service('docker-item') docker;
  @service('store') store!: Store;
  @service smartroom;
  @alias('project.fund') fund;
  @service('models') models!: Models;
  viewerGroups = null;

  @cached
  get myInfoResource() {
    return new MyInfoResource(this.models, this.smartRoomId);
  }

  @cached
  get categoryResource() {
    return new CategoryResource(
      this.store,
      this.models,
      this.smartRoomId,
      this.model.srProfile
    );
  }

  UserStatus = UserStatus;
  newSmartRoomUserStatus = UserStatus.HOLD;

  init() {
    super.init(...arguments);

    this.initTask.perform();
  }

  @task
  *initTask() {
    // register the flow component to the parent
    if (this.registerFlow) {
      this.registerFlow(this);
    }

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: AclPermissionType.ProjectTabRead,
      })
    );

    set(
      this,
      'viewerGroups',
      yield this.store.query('acl-role', {
        page: {
          size: 1000,
        },
        condition: vgQuery.serialize(),
        sort: 'description',
      })
    );

    set(this, 'smartRoomProfiles', yield this.smartRoomProfilesTask.perform());

    yield this.getSmartRoomUserTask.perform();
  }

  @task
  *selectCRMContactTask(person) {
    if (this.model.srAccess) {
      this.getSmartRoomUserTask.perform();
    }
  }

  @task
  *selectSmartRoomContactTask(person) {
    if (this.model.srAccess) {
      this.getSmartRoomUserTask.perform();
    }
  }

  @task
  *selectDealCloudContactTask(person) {
    if (this.model.srAccess) {
      this.getSmartRoomUserTask.perform();
    }
  }

  // called from parent
  @task
  *selectCRMCompanyTask(company) {
    this.model.setProperties({
      company: company,
      companyId: company ? company.id : null,
    });
  }

  @task
  *selectSmartRoomCompanyTask(company) {
    this.model.setProperties({
      company: company,
      companyId: company ? company.id : null,
    });

    if (!company.id) {
      this.set('newCompany', company);
    }
  }

  @task
  *selectDealCloudCompanyTask(dcCompany) {
    let companyQuery = Expressions.create({});

    companyQuery.add(
      Filter.create({
        name: 'name',
        operator: FilterOperators.EQUAL,
        value: dcCompany.name,
      })
    );

    let company = (yield this.store.query('company', {
      condition: companyQuery.serialize(),
      page: {
        size: 1,
      },
    })).firstObject;

    if (!company) {
      company = this.store.createRecord('company', {
        name: dcCompany.name,
      });

      this.set('newCompany', company);
    } else {
      this.model.setProperties({
        company: company,
        companyId: company ? company.id : null,
      });
    }
  }

  @task
  *smartRoomProfilesTask() {
    if (this.smartRoomId) {
      return (yield this.store.query('smartroom/category', {
        siteId: this.smartRoomId,
      }))
        .toArray()
        .sortBy('categoryName');
    }

    return [];
  }

  @task
  *createSecurityProfileTask() {
    let category = null;
    try {
      category = yield this.smartroom.createCategory(
        this.smartRoomId,
        this.newSecurityProfileName
      );

      this.smartRoomProfiles.pushObject(category);
    } catch (e) {
      // try to match to existing profile since SmartRoom says it already exists
      if (e instanceof CategoryAlreadyExists) {
        let securityProfiles = yield this.store.query('smartroom/category', {
          siteId: this.smartRoomId,
        });

        category = securityProfiles.find((item) => {
          return item.categoryName === this.newSecurityProfileName;
        });
      } else {
        throw e;
      }
    }

    // if no category was found, throw an error
    if (!category) {
      throw new Error('Security profile could not be created');
    }

    this.set('model.srProfile', category.id);

    this.model.save();
    this.set('confirmCreateSecurityProfile', null);

    return category;
  }

  @task
  *createSmartRoomUser() {
    let emailAddress = (yield this.model.get(
      'email.emailAddress'
    )).toLowerCase();

    if (this.model.srProfile && emailAddress) {
      let person = yield this.model.get('person');

      yield this.smartroom.addUserOrUpdateStatus(
        this.smartRoomId,
        {
          email: emailAddress,
          categoryId: this.model.srProfile,
          firstName: person ? person.firstName : null,
          lastName: person ? person.lastName : null,
          roomCompany: get(this.model, 'company.name') || '',
        },
        this.newSmartRoomUserStatus
      );
    }
  }

  @task
  *getSmartRoomUserTask() {
    this.set('smartRoomUser', null);
    this.model.set('srProfile', null);

    let emailAddress = yield this.model.get('email.emailAddress');

    if (this.model.srAccess && this.smartRoomId && emailAddress) {
      let users = yield this.store.query('smartroom/user', {
        siteId: this.smartRoomId,
        searchKey: emailAddress,
      });

      let user = users.find((item) => {
        if (item.email.toLowerCase() === emailAddress.toLowerCase()) {
          return true;
        }
      });

      this.set('smartRoomUser', user);

      if (user) {
        this.set('newSmartRoomUserStatus', user.status);
        let partyCategory = this.smartRoomProfiles.findBy(
          'id',
          this.smartRoomUser.categoryId.toString()
        );
        this.model.set('srProfile', partyCategory.id);

        this.set('newSmartRoomUserStatus', user.status);
      } else {
        let emailAddress = this.model.get('email.emailAddress');

        if (emailAddress) {
          let partyCategory = this.smartRoomProfiles.findBy(
            'categoryName',
            emailAddress
          );

          if (partyCategory) {
            this.model.set('srProfile', partyCategory.id);
          }
        }
      }
    }
  }

  @task
  *saveTask() {
    if (this.newCompany) {
      yield this.newCompany.save();
      this.model.set('company', this.newCompany);
    }

    yield this.model.save();

    if (this.model.srAccess) {
      if (this.smartRoomUser) {
        yield this.smartroom.updateUsers(this.smartRoomId, [
          {
            categoryId: this.model.srProfile,
            masterUserId: this.smartRoomUser.id,
            roomCompany: get(this.model, 'company.name') || '',
          },
        ]);

        let emails = [this.smartRoomUser.email];

        console.log('EMAILS: ', emails);

        yield this.smartroom.updateUsersStatus(
          this.smartRoomId,
          emails,
          this.newSmartRoomUserStatus
        );
      } else if (this.model.srProfile) {
        yield this.createSmartRoomUser.perform();
      }
    }
  }

  @action
  onSmartRoomAccessToggle(value) {
    this.getSmartRoomUserTask.perform();
  }

  @action
  createSecurityProfile() {
    this.createSecurityProfileTask.perform();
  }

  @action
  editCategorySecurity() {
    let category = this.store.peekRecord(
      'smartroom/category',
      this.model.srProfile
    );
    let appearance = {
      icon: '',
      title: `Security Profile: ${category.categoryName}`,
      size: 'large',
      custom: true,
    };
    this.get('docker').invokePopup('smartroom/category-security', appearance, {
      siteId: this.smartRoomId,
      categoryId: category.id,
    });
  }
}
