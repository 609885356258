import Label from 'ember-bootstrap/components/bs-form/element/label';

import classic from 'ember-classic-decorator';

import { computed } from '@ember/object';

@classic
export default class extends Label {
  @computed
  get labelClass() {
    this._labelClass;
  }

  set labelClass(value) {
    this._labelClass = `text-right ${value}`;
    return this._labelClass;
  }
}
