import Model, { AsyncBelongsTo, AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import AppBuilder from 'cing-app/pods/appbuilder/service';
import Case from './case';
import ObjectModel from './object';

export default class Project extends Model {
  @attr('number') caseId!: number;
  @attr('string') name!: string;
  @attr('string') owner!: string;
  @attr('boolean') hasGantt?: boolean;
  @attr('string') slug?: string;
  @attr('date') createTime!: Date;
  @attr('date') modifyTime!: Date;

  //@ts-ignore
  @belongsTo('appbuilder-link/case') case!: AsyncBelongsTo<Case>;
  @hasMany('appbuilder-link/object') objects!: AsyncHasMany<ObjectModel>;

  static createModel(abLinkId: string, appbuilder: AppBuilder) {
    let model = Case.extend({
      case: belongsTo(appbuilder.getAbmModelName('appbuilder-link/case', abLinkId)),
      objects: hasMany(appbuilder.getAbmModelName('appbuilder-link/object', abLinkId)),
    });
    return model;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/project': Project;
  }
}
