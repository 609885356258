import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { AclPermissionType } from 'cing-app/utils/lookups';
import {
  Expressions,
  FilterOperators,
  Filter,
} from 'cing-app/mixins/filter-builder';

import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import { taskFor } from 'ember-concurrency-ts';
import AclPermission from 'cing-app/models/acl-permission';
import AclRole from 'cing-app/models/acl-role';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';

// class GroupPermission {
//   @tracked read = false;
//   @tracked create = false;
//   @tracked edit = false;
//   @tracked delete = false;

//   constructor(t_read: boolean, t_create: boolean, t_edit: boolean, t_delete: boolean) {
//     this.t_read = t_read;
//     this.t_create = t_create;
//     this.t_edit = t_edit;
//     this.t_delete = t_delete;
//   }

//   initializeFromAccessRole(accessRole) {
//     get(accessRole, 'aclPermissions').then((permissions) => {
//       this.read = permissions.findBy('permissionTypeId', this.t_read) ? true : false;
//       this.create = permissions.findBy('permissionTypeId', this.t_create) ? true : false;
//       this.edit = permissions.findBy('permissionTypeId', this.t_edit) ? true : false;
//       this.update = permissions.findBy('permissionTypeId', this.t_update) ? true : false;
//     })
//   }
// }

// class RolePermissions {
//   constructor(accessRole) {
//     this.groups = [
//       new GroupPermission("Portal Page", AclPermissionType.PortalPageRead, AclPermissionType.PortalPageCreate, AclPermissionType.PortalPageUpdate, AclPermissionType.PortalPageDelete),
//       new GroupPermission("Project", AclPermissionType.ProjectRead, AclPermissionType.ProjectCreate, AclPermissionType.ProjectUpdate, AclPermissionType.ProjectDelete),
//       new GroupPermission("Project Tab", AclPermissionType.ProjectTabRead, AclPermissionType.ProjectTabCreate, AclPermissionType.ProjectTabUpdate, AclPermissionType.ProjectTabDelete),
//     ]

//     this.groups.forEach((group) => {
//       group.initializeFromAccessRole(accessRole);
//     })
//   }
// }

interface AccessRolesArgs {
  header: any;
  footer: any;
}
export default class AccessRoles extends Component<AccessRolesArgs> {
  @inject()
  store!: Store;

  @inject('docker-item')
  docker!: DockerItemService;

  @tracked
  permissions!: AclPermission[];

  @tracked
  viewerGroups: AclRole[] | null = null;

  @tracked
  selectedViewerGroup: AclRole | null = null;

  @tracked
  removeViewerGroup: null = null;

  @tracked
  showFormErrors = false;

  get styleNamespace() {
    return podNames['manage-users/access-roles'];
  }

  constructor(owner: any, args: AccessRolesArgs) {
    super(owner, args);

    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    let pQuery = Expressions.create({});
    pQuery.add(
      Filter.create({
        //@ts-ignore
        name: 'permission-type-id',
        operator: FilterOperators.IN,
        //@ts-ignore
        value: [
          AclPermissionType.ProjectTabRead,
          AclPermissionType.ProjectRead,
          AclPermissionType.ProjectUpdate,
          AclPermissionType.PortalPageRead,
          AclPermissionType.Admin,
          AclPermissionType.CompanyRead,
          AclPermissionType.InterestedPartyRead,
          AclPermissionType.InstanceAccess,
          AclPermissionType.FaqRead,
          AclPermissionType.FaqUpdate,
        ],
      })
    );

    let permissions = (
      await this.store.query('acl-permission', {
        page: {
          size: 1000,
        },
        sort: 'permission-type-id',
        condition: pQuery.serialize(),
      })
    ).toArray();

    this.permissions = permissions;

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        //@ts-ignore
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.IN,
        //@ts-ignore
        value: [
          AclPermissionType.ProjectTabRead,
          AclPermissionType.ProjectRead,
          AclPermissionType.ProjectUpdate,
          AclPermissionType.PortalPageRead,
          AclPermissionType.Admin,
          AclPermissionType.CompanyRead,
          AclPermissionType.InterestedPartyRead,
          AclPermissionType.InstanceAccess,
          AclPermissionType.FaqRead,
          AclPermissionType.FaqUpdate,
        ],
      })
    );

    let viewerGroups = (
      await this.store.query('acl-role', {
        page: {
          size: 1000,
        },
        condition: vgQuery.serialize(),
        include: 'acl-permissions',
        sort: 'description',
      })
    ).toArray();

    this.viewerGroups = viewerGroups;
  });

  @task
  saveTask = taskFor(async (model) => {
    await model.save();

    await this.initTask.perform();
    this.selectedViewerGroup = null;
  });

  @task
  removeTask = taskFor(async (model) => {
    await model.destroyRecord();

    this.selectedViewerGroup = null;
    this.removeViewerGroup = null;
    await this.initTask.perform();
  });

  @action
  add() {
    let viewerGroup = this.store.createRecord('acl-role');
    viewerGroup.get('aclPermissions').then((permissions: AclPermission[]) => {
      if (this.permissions.firstObject) {
        permissions.pushObject(this.permissions.firstObject);
      }
    });
    this.selectedViewerGroup = viewerGroup;
  }

  @action
  cancel() {
    if (this.selectedViewerGroup) {
      this.selectedViewerGroup.rollbackAttributes();
      this.selectedViewerGroup
        .hasMany('aclPermissions')
        .reload()
        .then(() => {
          this.selectedViewerGroup = null;
        });
    }
  }

  @action
  save(model: AclRole) {
    this.showFormErrors = true;

    if (model.get('validations.isValid')) {
      model.name = model.name.trim();
      if (model.name) {
        this.saveTask.perform(model);
      }
    }
  }

  @action
  doRemove(model: AclRole) {
    this.removeTask.perform(model);
  }

  // @action
  // selectProjectViewerGroup(viewerGroup) {
  //   this.set('model.DefaultProjectViewerGroup', viewerGroup.id);
  // }
}
