import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import Company from './company';

export default class CompanyInProject extends Abstract {
  @attr('string') declare projectId: string;
  @attr('string') declare companyId: string;
  @attr('number') declare sortOrder?: number;
  //@ts-ignore - mixin
  @belongsTo('company', { inverse: null }) declare company: AsyncBelongsTo<Company>;
}


// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-in-project': CompanyInProject;
  }
}