import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { EventType } from 'cing-app/utils/lookups';

@classic
export default class EventDetail extends Component {
  @inject()
  store;

  @inject('docker-item')
  docker;

  @inject()
  session;

  @alias('context.event')
  event;

  didReceiveAttrs() {
    this.get('context.event.pacerEvents').then((pe) => {
      if (pe && pe.get('docketId')) {
        this.get('store')
          .findRecord('docket', pe.get('docketId'), { reload: true })
          .then((docket) => {
            this.set('docket', docket);
          });
      }
    });
  }

  @action
  showDocket(tab) {
    let document = this.get('docket');
    let appearance = {
      icon: '',
      title: `Docket: #${document.get('docketNumber')}`,
      size: 'medium',
    };
    this.get('docker').invokePopup('manage-docket', appearance, {
      docket: document,
      tab: tab,
    });
  }

  @action
  edit() {
    let event = this.get('event');
    let title = event.get('title');

    if (!title) {
      let eventTypes = Object.keys(EventType);
      title = eventTypes.find((key) => EventType[key] == event.typeId);
    }

    let appearance = {
      icon: '',
      title: `Edit event: ${title}`,
      size: 'large',
      custom: true,
    };

    this.get('docker').invokePopup(
      'project-detail/project-management/form',
      appearance,
      {
        model: event,
        onUpdate: this.context.onUpdate,
        onClose: this.onClose(),
      }
    );
  }

  @action
  delete() {
    let event = this.get('event');
    event.destroyRecord().then(() => {
      if (this.context.onUpdate) {
        this.context.onUpdate();
      }

      if (this.onClose) {
        this.onClose();
      }
      if (this.context.loadData) {
        this.context.loadData.perform();
      }
    });
  }

  @action
  showCase() {
    let record = this.get('event.case');
    let appearance = {
      icon: '',
      title: `Case: ${record.get('name')} / #${record.get('displayNumber')}`,
      size: 'large',
    };
    this.get('docker').invokePopup('case-detail', appearance, {
      recordId: record.get('id'),
    });
  }
}
