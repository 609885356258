import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class JsonbTransform extends Transform {
  deserialize(serialized: any) {
    if (!serialized || typeof serialized !== 'object') {
      return {};
    }

    return serialized;
  }

  serialize(deserialized: any) {
    return deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    jsonb: JsonbTransform;
  }
}
