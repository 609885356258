import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import DockerItemService from 'cing-app/pods/docker-item/service';

import AbPmService from 'cing-app/pods/ab-pm/service';

interface AbPmTaskDocumentsPopupArgs {
  context: {
    abLink: any;
    smartRoomId: any;
    task: any;
    onTaskAction: any;
  };
}

export default class AbPmTaskDocumentsPopup extends Component<AbPmTaskDocumentsPopupArgs> {
  @service store!: Store;

  @service('docker-item') docker!: DockerItemService;

  @service('ab-pm') pmService!: AbPmService;

  constructor(owner: any, args: any) {
    super(owner, args);
  }
}
