import {
  attr,
  hasMany,
  belongsTo,
  AsyncBelongsTo,
  SyncHasMany,
} from '@ember-data/model';
import AppbuilderLinkColumn from './appbuilder-link-column';
import { ILayout } from '../pods/components/layout-viewer/component';
import Case from './appbuilder-link/case';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import AppBuilder from 'cing-app/pods/appbuilder/service';
import Abstract from './abstract';
import Project from './project';
import YupValidations from 'cing-app/validations/yup';
import { string, number } from 'yup';
import { set } from '@ember/object';
import { ConnectionTypes, ABConnectionTypes } from 'cing-app/utils/lookups';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
export interface ILayoutDefinitions {
  [objectName: string]: ILayout;
}

export interface IFormDefinitions {
  [objectName: string]: any;
}

export default class AppbuilderLink extends Abstract {
  @service('store')
  store!: Store;
  @service('appbuilder')
  appbuilder!: AppBuilder;

  @attr('number')
  declare type: number;
  @attr('string')
  declare projectId: string;

  @attr('string')
  declare ABInstanceName: string;
  @attr('string')
  declare ABCaseId: string;
  @attr('string')
  declare ABCaseName: string;
  @attr('string')
  declare ABDisplayName: string;
  @attr('string')
  declare ABObjectName: string;
  @attr('jsonb')
  declare ABAccessMapping: object;
  @attr('jsonb-list')
  declare ABFilters: { name: string; value: any }[];
  @attr('string')
  declare ABType: string;

  @attr('string')
  declare SRSiteId: number;
  @attr('string')
  declare SRName: string;
  @attr('string')
  declare SRSlug: string;
  @attr('boolean')
  declare SRAccessAgreements: boolean;
  @attr('jsonb-list')
  declare SRAllAccessFolders: number[];
  @attr('string')
  declare SRViewMode: string;
  @attr('number')
  declare SRFolderId: number;
  @attr('string')
  declare SRFolderName: string;
  @attr('boolean')
  declare SRIncludeInStats: boolean;

  @attr()
  declare forms?: IFormDefinitions;
  @attr()
  declare views?: ILayoutDefinitions;

  @hasMany('appbuilder-link-column', { async: false })
  declare columns: SyncHasMany<AppbuilderLinkColumn>;

  @belongsTo('project') declare project: AsyncBelongsTo<Project>;

  get idName() {
    return `(${this.ABCaseId}) ${this.ABObjectName}`;
  }

  get ABCase(): Case {
    let c = this.store.peekRecord(
      this.appbuilder.getAbmModelName('appbuilder-link/case', this.id),
      this.ABCaseId
    );
    if (c) {
      return c;
    } else {
      throw new Error('Case is required in appbuilder link');
    }
  }

  _SRIntegration = false;

  get SRIntegration() {
    return this._SRIntegration || Boolean(this.SRSiteId);
  }

  set SRIntegration(value) {
    this._SRIntegration = value;
    // reset all SR related attributes if SR integration is disabled
    if (!value) {
      set(this, 'SRSiteId', null);
      set(this, 'SRName', null);
      set(this, 'SRSlug', null);
      set(this, 'SRAccessAgreements', false);
      set(this, 'SRAllAccessFolders', {});
      set(this, 'SRFolderId', null);
      set(this, 'SRFolderName', null);
      set(this, 'SRIncludeInStats', false);
    }
  }

  get allAccessSmartRoomFolders() {
    if (this.SRAllAccessFolders?.length) {
      return this.getSmartRoomFolders.perform();
    }

    return [];
  }

  @task
  getSmartRoomFolders = taskFor(async () => {
    let folders = [];

    for (var a = 0; a < this.SRAllAccessFolders?.length; a++) {
      folders.push(
        await this.store.queryRecord('smartroom/folder', {
          id: this.SRAllAccessFolders[a],
          siteId: this.SRSiteId,
        })
      );
    }

    return folders;
  });

  // static createModel(abLinkId: string, appbuilder: AppBuilder) {
  //   let model = AppbuilderLink.extend({
  //     columns: hasMany(appbuilder.getAbmModelName('appbuilder-link/case', abLinkId), { async: false }),
  //   });
  //   return model;
  // }

  validations = new YupValidations(this, {
    type: number().required(),
    ABInstanceName: string()
      .nullable()
      .when('type', {
        is: (val: number) => [ConnectionTypes.APPBUILDER].includes(val),
        then: (schema) => schema.required(),
      }),
    ABType: string()
      .nullable()
      .when('type', {
        is: (val: number) => [ConnectionTypes.APPBUILDER].includes(val),
        then: (schema) => schema.required(),
      }),
    ABCaseId: number()
      .nullable()
      .when('type', {
        is: (val: number) => [ConnectionTypes.APPBUILDER].includes(val),
        then: (schema) => schema.required(),
      }),
    SRSiteId: number()
      .nullable()
      .when('type', {
        is: (val: number) =>
          [ConnectionTypes.SMARTROOM].includes(val) || this._SRIntegration,
        then: (schema) => schema.required(),
      }),
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link': AppbuilderLink;
  }
}
