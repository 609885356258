import Component from '@glimmer/component';
import { action } from '@ember/object';
import { FileContainer, Structure } from 'smex-ui-sr-filemanager';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { Models, MyInfoResource, SmartroomMyInfoModel, SmartroomRightTypeModel } from 'smex-ui-sr-models';
import { cached } from 'tracked-toolbox';


interface IArgs {
  file: FileContainer;
  structure: Structure;
  showSetSecurity?: boolean
}

export default class SmartroomFile extends Component<IArgs>{
  @service('models') models!: Models;

  dataActions = [];

  /**
   * Used to show autocorrect right
   */
  @tracked
  targetRight?: SmartroomRightTypeModel;

  @action
  selectUnselect() {
    this.args.file.selectUnselect(!(this.args.file.selected || this.args.file.selectedParent));
  }
  @cached
  get myInfoResource() {
    return new MyInfoResource(this.models, this.models.getModelInfo(this.args.file.model.constructor.modelName).siteId);
  }

  get noRight() {
    return this.args.file.model.getNoRight();
  }

  @action
  changeRight(right: SmartroomRightTypeModel | null) {
    if (right) {
      if (this.args.file.model.hasMinimumRight) {
        this.args.file.model.updatedGivenRight = right;
      } else {
        this.targetRight = right;
      }
    } else {
      this.args.file.model.updatedGivenRight = right;
    }
  }
};
