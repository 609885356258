import smartroomAdapter from './smartroom';
import { inject } from '@ember/service';

export default smartroomAdapter.extend({
  config: inject(),

  urlForQuery(query) {
    let folderId = query.folderId;
    let siteId = query.siteId;

    let host = this.get('host');
    let namespace = this.get('namespace');

    delete query.folderId;
    delete query.siteId;
    return `${host}/${namespace}/sites/${siteId}/folders/${folderId}/files`;
  },
});
