import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('power-select-sm')
export default class ColumnState extends Component {
  init() {
    super.init();
    let selected = this.get('column.options').findBy('abbr', this.get(`record.${this.column.propertyName}`));
    this.set('selected', selected);
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  @action
  onSelect(option) {
    this.set('selected', option);

    if (option) {
      this.set(`record.${this.column.propertyName}`, option.abbr);
    } else {
      this.set(`record.${this.column.propertyName}`, null);
    }
  }
}