define("cing-app/mixins/auth-mixin", ["exports", "ember-smartidentity-auth/mixins/auth-mixin"], function (_exports, _authMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _authMixin.default;
    }
  });
});
