import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import ApiDataSource from 'smex-ui-table';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import moment from 'moment';
import {
  DateRangeFilter,
  Filter,
  ExpressionOperators,
  Expressions,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

import { DailyStat } from '../component';

interface FiltersArgs {
  dataSource: ApiDataSource<DailyStat>;
  onUpdate: any;
  onExport: any;
}

const DateFilterOptions = [
  {
    name: 'Last Upload',
    property: 'last-upload',
  },
  {
    name: 'Last Login',
    property: 'last-login',
  },
  {
    name: 'Last View',
    property: 'last-view',
  },
  {
    name: 'Date Created',
    property: 'date-created',
  },
];

const StatusFilterOptions = ['Active', 'Decommissioned'];

export default class AbDailystats extends Component<FiltersArgs> {
  @service
  store!: Store;

  @tracked
  dataSource!: ApiDataSource<DailyStat>;

  @tracked
  startDate: any;

  @tracked
  endDate: any;

  @tracked
  searchText?: string;

  @tracked
  dateFilter?: object;

  @tracked
  statusFilter?: string;

  DateFilterOptions = DateFilterOptions;
  StatusFilterOptions = StatusFilterOptions;

  constructor(owner: any, args: any) {
    super(owner, args);
    this.dateFilter = DateFilterOptions.firstObject;
    this.statusFilter = StatusFilterOptions[0];
    this.args.onUpdate(this.generateFilter());
  }

  get styleNamespace() {
    return podNames['ab-dailystats/filters'];
  }

  get ranges() {
    const presets = {
      'Last week': [moment().subtract(1, 'weeks'), moment()],
      'Last month': [moment().subtract(1, 'months'), moment()],
      'Last year': [moment().subtract(1, 'years'), moment()],
    };
    return {
      earliest: moment().subtract(1, 'years'),
      latest: moment(),
      presets: presets,
    };
  }

  @action
  setDateRange(start: string | null, end: string | null) {
    this.startDate = start ? moment(start).startOf('day') : null;

    this.endDate = end ? moment(end).endOf('day') : null;

    if (this.args.onUpdate) {
      this.args.onUpdate(this.generateFilter());
    }
  }

  @action
  search(e: Event) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (this.args.onUpdate) {
      this.args.onUpdate(this.generateFilter());
    }
  }

  @action
  clearSearch(e: Event) {
    this.searchText = undefined;

    if (this.args.onUpdate) {
      this.args.onUpdate(this.generateFilter());
    }
  }

  @action
  selectDateFilter(item: any) {
    this.dateFilter = item;

    if (this.args.onUpdate) {
      this.args.onUpdate(this.generateFilter());
    }
  }

  @action
  selectStatusFilter(status: any) {
    this.statusFilter = status;

    if (this.args.onUpdate) {
      this.args.onUpdate(this.generateFilter());
    }
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    if (this.startDate && this.endDate && this.dateFilter) {
      expr.add(
        DateRangeFilter.create({
          dateFormat: 'YYYY-MM-DD HH:mm:ss',
          name: this.dateFilter.property,
          value1: this.startDate,
          value2: this.endDate,
        })
      );
    }

    if (this.statusFilter) {
      expr.add(
        Filter.create({
          name: 'status',
          operator: FilterOperators.EQUAL,
          value: this.statusFilter,
        })
      );
    }

    if (this.searchText) {
      let searchExpr = Expressions.create({ operator: ExpressionOperators.OR });

      let siteId = parseInt(this.searchText, 10);

      if (!isNaN(siteId)) {
        searchExpr.add(
          Filter.create({
            name: 'site-id',
            operator: FilterOperators.EQUAL,
            value: siteId,
          })
        );
      }

      searchExpr.add(
        Filter.create({
          name: 'slug',
          operator: FilterOperators.LIKE,
          value: this.searchText,
        })
      );

      searchExpr.add(
        Filter.create({
          name: 'site-name',
          operator: FilterOperators.LIKE,
          value: this.searchText,
        })
      );

      expr.add(searchExpr);
    }

    return expr.serialize();
  }
}
