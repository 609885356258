import { isEmpty } from '@ember/utils';
import Component from '@ember/component';

export default Component.extend({
  dateStart: null,
  dateEnd: null,

  init() {
    this._super();
    this.rangeComplete();
  },
  didReceiveAttrs() {
    let dateRange = this.get('selectedRange');

    if (dateRange) {
      if (dateRange.start) {
        this.set('dateStart', dateRange.start);
      } else {
        this.set('dateStart', null);
      }

      if (dateRange.end) {
        this.set('dateEnd', dateRange.end);
      } else {
        this.set('dateEnd', null);
      }
    } else {
      this.set('selectedRange', {
        start: null,
        end: null
      });
    }
  },
  didUpdateAttrs() {
    this.didReceiveAttrs();
  },
  rangeComplete: function () {
    let range = this.get('selectedRange');

    if (!isEmpty(range.start) && !isEmpty(range.end)) {
      this.sendAction("onChange", range);

      if (this.get('onRangeSelect')) {
        this.get('onRangeSelect')(range);
      }
    }
  }.observes('selectedRange'),

  actions: {
    setStartDate(startDate) {
      this.set('dateStart', startDate);
      this.set('selectedRange.start', startDate);
      this.rangeComplete();
    },
    setEndDate(endDate) {
      this.set('dateEnd', endDate);
      this.set('selectedRange.end', endDate);
      this.rangeComplete();
    }
  },

});
