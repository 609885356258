import Model, { attr } from '@ember-data/model';
// import { computed } from '@ember/object';
import { validator, buildValidations } from 'ember-cp-validations';

const InvestorValidations = buildValidations({
  investorName: validator('presence', true),
  fundInvestorNumber: validator('presence', true),
  commitment: validator('presence', true),
  invType: validator('presence', true),
});

export default Model.extend(InvestorValidations, {
  invFundId: attr('string', { defaultValue: "" }),
  fundId: attr('string', { defaultValue: "" }),
  fundName: attr('string', { defaultValue: "" }),
  invType: attr('string', { defaultValue: "" }),
  status: attr('string', { defaultValue: "" }),
  investorName: attr('string', { defaultValue: "" }),
  commitment: attr('number', { defaultValue: 0 }),
  fundInvestorNumber: attr('string', { defaultValue: "" }),

  // calculated values
  ownership: attr('number', { defaultValue: 0 }),
  callsTotal: attr('number', { defaultValue: 0 }),
  callsTotalPerc: attr('number', { defaultValue: 0 }),
  callsPrior: attr('number', { defaultValue: 0 }),
  callsPriorPerc: attr('number', { defaultValue: 0 }),
  callCurrent: attr('number', { defaultValue: 0 }),
  callCurrentPerc: attr('number', { defaultValue: 0 }),
  remainingTotal: attr('number', { defaultValue: 0 }),
  remainingPrior: attr('number', { defaultValue: 0 }),
  distTotal: attr('number', { defaultValue: 0 }),
  distTotalPerc: attr('number', { defaultValue: 0 }),
  distPrior: attr('number', { defaultValue: 0 }),
  distPriorPerc: attr('number', { defaultValue: 0 }),
  distCurrent: attr('number', { defaultValue: 0 }),
  distCurrentPerc: attr('number', { defaultValue: 0 }),
  recallableCapital: attr('number', { defaultValue: 0 }),
  currentEqu: attr('number', { defaultValue: 0 }),
  priorEqu: attr('number', { defaultValue: 0 }),
  totalEqu: attr('number', { defaultValue: 0 }),
  totalMgtFee: attr('number', { defaultValue: 0 }),
  priMgtFee: attr('number', { defaultValue: 0 }),
  currMgtFee: attr('number', { defaultValue: 0 }),
  equIntPaidCurrent: attr('number', { defaultValue: 0 }),
  equIntPaidPri: attr('number', { defaultValue: 0 }),
  equIntPaid: attr('number', { defaultValue: 0 }),
  equIntRecCurnt: attr('number', { defaultValue: 0 }),
  equIntRecPri: attr('number', { defaultValue: 0 }),
  totalEquIntRec: attr('number', { defaultValue: 0 }),

});


