import {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { SourceType, DocketRating } from 'cing-app/utils/lookups';
import sanitizeHtml from 'ember-cli-sanitize-html';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import Case from './case';
import { taskFor } from 'ember-concurrency-ts';
import Attorney from './attorney';
import DocketAttachments from 'cing-app/pods/components/api-table/columns/docket-attachments/component';
import DocketFolder from './docket-folder';
import DocketNote from './docket-note';
import DocketType from './docket-type';
import Abstract from './abstract';
export default class Docket extends Abstract.extend(
  computedEnumMixin('source', 'sourceType', SourceType),
  computedEnumMixin('ratingName', 'rating', DocketRating)
) {
  @service config: any;

  @attr('number') declare docketNumber?: number;
  @attr('string') declare docketUrl?: string;
  @attr('string') declare pacerUrl?: string;
  @attr('date') declare filingDate?: Date;
  @attr('number') declare rating?: number;
  @attr('date') declare publishedDate?: Date;
  @attr('string') declare description?: string;
  @attr('string') declare title?: string;
  @attr('string') declare filedByAttorney?: string;
  @attr('boolean') declare highlight?: boolean;
  @attr('number') declare numberOfPages?: number;
  @attr('number') declare numberOfDocuments?: number;
  @attr('string') declare caseId: string;
  @attr('number') declare sourceType?: number;
  @attr('string') declare parentId?: string;
  @attr('string') declare rssTypeId?: string;
  @attr('string') declare fileMetadata?: string;
  @attr('date') declare fileMetadataDate?: Date;
  @attr('string') declare attorneyId?: string;

  @belongsTo('case') declare 'case': AsyncBelongsTo<Case>;
  @belongsTo('attorney') declare attorney: AsyncBelongsTo<Attorney>;
  @hasMany('docketAttachment', { inverse: null })
  declare attachments: AsyncHasMany<DocketAttachments>;
  @hasMany('docket', { inverse: null })
  declare relatedDockets: AsyncHasMany<Docket>;
  @hasMany('docket-folder', { inverse: null })
  declare docketFolders: AsyncHasMany<DocketFolder>;
  @hasMany('docketNote') declare docketNotes: AsyncHasMany<DocketNote>;
  @belongsTo('docketType') declare docketType: AsyncBelongsTo<DocketType>;
  @hasMany('interested-party')
  declare interestedParties: AsyncHasMany<InterestedParty>;

  get number() {
    let number = this.docketNumber;

    if (number && number > 0) {
      return number;
    } else {
      return null;
    }
  }

  get sortedDocketAttachments() {
    return this.get('attachments').sortBy('docketNumber');
  }

  get sortedRelatedDockets() {
    return this.get('relatedDockets').sortBy('docketNumber');
  }

  get sanitizedDesription() {
    if (this.get('description')) {
      return sanitizeHtml(this.get('description'), { allowedTags: [] });
    }
  }

  get publicTitle() {
    if (this.get('description')) {
      return sanitizeHtml(this.get('description'), { allowedTags: [] });
    } else if (this.get('docketType.name')) {
      return this.get('docketType.name');
    }

    return '';
  }

  get shortTitle() {
    let title = this.get('publicTitle');
    const limit = 160 * 3; // approximately 3 lines of text
    if (title.length > limit) {
      return title.substr(0, limit) + '\u2026';
    } else {
      return title;
    }
  }

  get contentURL() {
    if (this.fileMetadata) {
      return `${this.get('config.APP.api.host')}/${this.get(
        'config.APP.api.namespace'
      )}/dockets/content/${this.get('id')}`;
    }
    return null;
  }

  get downloadURL() {
    if (!this.fileMetadata) {
      return `${this.get('config.APP.api.host')}/${this.get(
        'config.APP.api.namespace'
      )}/dockets/download/${this.get('id')}`;
    }
    return null;
  }

  @task
  popupTitle = taskFor(async () => {
    let c = await this.get('case');
    let title = `<small>Case: </small>${c.get('name')} / #${c.get(
      'displayNumber'
    )} <small>Docket: </small>#${this.get('docketNumber')}`;
    let docketTitle = this.get('title');
    if (docketTitle) {
      title += ` ${docketTitle}`;
    }
    return title;
  });
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    docket: Docket;
  }
}
