import { ABColumnFormat } from 'cing-app/models/appbuilder-link-column';
import DS from 'ember-data';

const AbFormat = DS.Transform.extend({
	deserialize(serialized: ABColumnFormat) {
		return new ABColumnFormat(serialized);
	},

	serialize(deserialized: ABColumnFormat) {
		return deserialized;
	}
});

declare module 'ember-data/types/registries/transform' {
	export default interface TransformRegistry {
		//@ts-ignore
		'ab-format': AbFormat;
	}
}

export default AbFormat;
