import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
//@ts-ignore
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { CaseProjectUrlOpenMode } from 'cing-app/utils/lookups';
import DataLinkContextFilter from './data-link-context-filter';
import { task } from 'ember-concurrency';
import ProjectTabSettingsProjectFields from './project-tab-settings-project-fields';
import ProjectTabSettingsFundFields from './project-tab-settings-fund-fields';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';

export default class ProjectTabSettings extends Fragment.extend(
  computedEnumMixin('EUOpenModeName', 'EUOpenMode', CaseProjectUrlOpenMode)
) {
  @service
  store!: Store;

  @attr('number') declare EUOpenMode?: number;
  @attr('string') declare SmartRoomId?: string;
  @attr('string') declare SmartRoomFolderId?: string;
  @attr('boolean', { defaultValue: true })
  declare SmartRoomCheckAccesssAgreement?: boolean;
  @attr('jsona') declare AllAccessSmartRoomFolderIds?: any;
  @attr('string') declare SmartRoomContentViewMode?: string;
  @attr('jsona') declare InquirySections?: any;

  @fragment('project-tab-settings-project-fields', { defaultValue: {} })
  declare ProjectFields: ProjectTabSettingsProjectFields;
  @fragment('project-tab-settings-fund-fields', { defaultValue: {} })
  declare FundFields: ProjectTabSettingsFundFields;

  @attr('string') declare ABLinkId?: string;
  @attr('string') declare connectionId?: string;
  @attr('string') declare dataLinkId?: string;
  @attr('string') declare dataLinkView?: string;
  @fragmentArray('data-link-context-filter')
  declare dataLinkFilters: DataLinkContextFilter[];

  get smartRoom() {
    if (this.SmartRoomId) {
      return this.store.findRecord('smartroom/site', this.SmartRoomId);
    } else {
      return null;
    }
  }

  get smartRoomFolder() {
    if (this.SmartRoomFolderId) {
      return this.store.queryRecord('smartroom/folder', {
        id: this.SmartRoomFolderId,
        siteId: this.SmartRoomId,
      });
    } else {
      return null;
    }
  }

  get allAccessSmartRoomFolders() {
    if (this.AllAccessSmartRoomFolderIds?.length) {
      return this.getSmartRoomFolders.perform();
    }

    return [];
  }

  @task
  getSmartRoomFolders = taskFor(async () => {
    let folders = [];

    for (var a = 0; a < this.AllAccessSmartRoomFolderIds?.length; a++) {
      folders.push(
        await this.store.queryRecord('smartroom/folder', {
          id: this.AllAccessSmartRoomFolderIds[a],
          siteId: this.SmartRoomId,
        })
      );
    }

    return folders;
  });
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-tab-settings': ProjectTabSettings;
  }
}
