import Transform from '@ember-data/serializer/transform';
import moment from 'moment';
import classic from 'ember-classic-decorator';

@classic
export default class RdateTransform extends Transform {
  deserialize(serialized: any) {
    var type = typeof serialized;

    if (type === 'string') {
      return moment(serialized).toDate();
    } else if (moment.isMoment(serialized)) {
      return serialized.toDate();
    } else if (serialized === null || serialized === undefined) {
      return serialized;
    } else {
      return null;
    }
  }
  serialize(deserialized: any) {
    let result = null;

    if (moment.isMoment(deserialized) || deserialized instanceof Date) {
      result = moment(deserialized).toISOString(true);
    }

    return result;
  }
}
declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    rdate: RdateTransform;
  }
}
