import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import FilterBuilder from 'cing-app/mixins/filter-builder';

@classic
export default class DocketAttachments extends Component.extend(FilterBuilder) {
  @computed('record.attachments')
  get attachments() {
    return this.get('record.attachments').sortBy('docketNumber');
  }
  /*
  store: Ember.inject.service(),
  docker: Ember.inject.service('docker-item'),
  tagName: 'a',
  click() {
      let record = this.get('record');
      let appearance = {
          icon: '',
          title: `Case: ${record.get('name')} / #${record.get('number')}`,
          size: 'large'
      };
      this.get('docker').invokePopup('case-detail', appearance, {recordId: record.get('id')});
  }
  */
}

