import { helper } from '@ember/component/helper';
import { FormDefinitions } from 'cing-app/pods/formdefinitions/transform';

export function formDefinitionExists([formDefinitions, objectName]: [FormDefinitions, string]/*, hash*/) {
  if (formDefinitions) {
    return formDefinitions[objectName];
  } else {
    return undefined;
  }
}

export default helper(formDefinitionExists);
