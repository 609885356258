import classic from 'ember-classic-decorator';
import Component from "@ember/component";

@classic
export default class ColumnEmails extends Component {
  init() {
    super.init();

    this.get(`record.${this.get('column.propertyName')}`).then((emails) => {
      let emailAddresses = emails.mapBy('emailAddress');
      this.set('emails', emailAddresses.join(", "));
    })
  }
}