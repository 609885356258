import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import UserInstancesService from 'cing-app/pods/user-instances/service';
import AppBuilderService, {
  ABModelMeta,
} from 'cing-app/pods/appbuilder/service';
import { taskFor } from 'ember-concurrency-ts';
import {
  ApiDataSource,
  DataSourceColumn,
  ERecordCountPosition,
  Paged,
} from 'smex-ui-table';
import AppBuilderLink from 'cing-app/models/appbuilder-link';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';

interface UserProfilesArgs {
  context: {
    appBuilderLinkId: string;
  };
}

interface UserProfile {
  address?: string;
  acceptTerms?: string;
  approveAccess?: string;
  categoryOfInterest?: string;
  city?: string;
  company?: string;
  country?: string;
  email?: string;
  existingContact?: string;
  firstName?: string;
  homePhone?: string;
  howDidYouHearAboutUs?: string;
  industry?: string;
  itemOfInterest?: string;
  lastName?: string;
  mobilePhone?: string;
  postalCode?: string;
  state?: string;
  subCategory?: string;
  userId?: number;
  username?: string;
  dateRegistered?: Date;
}

export default class UserProfiles extends Component<UserProfilesArgs> {
  @service
  store!: Store;

  @service('docker-item')
  docker!: DockerItemService;

  @service
  session!: _SessionService;

  @service
  serverVariables!: any;

  @service
  declare userInstances: UserInstancesService;

  @service('appbuilder')
  appbuilder!: AppBuilderService;

  @tracked
  records = [];

  @tracked
  selectedItems: any[];

  @tracked
  dataSource!: ApiDataSource<UserProfile>;

  @tracked
  errorMsg = '';

  @tracked
  createSmartRoom: boolean = false;

  condition?: string;

  appBuilderLinkId: string = '';

  pageSize = 100;

  abLink!: AppBuilderLink;

  modelMeta!: ABModelMeta;

  ERecordCountPosition = ERecordCountPosition;

  constructor(owner: any, args: any) {
    super(owner, args);

    this.selectedItems = [];

    this.appBuilderLinkId = this.args.context.appBuilderLinkId;

    this.initUserProfiles.perform();
  }

  get styleNamespace() {
    return podNames['ab-dailystats'];
  }

  get columnsUI() {
    let columns: DataSourceColumn<UserProfile>[] = [
      new DataSourceColumn<UserProfile>({
        id: 'id',
        label: 'ID',
        getValue: (row) => get(row, 'id'),
        sortingEnabled: true,
        minWidth: 50,
        maxWidth: 70,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserProfile>({
        id: 'email',
        label: 'email',
        getValue: (row) => get(row, 'email'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserProfile>({
        id: 'first-name',
        label: 'First Name',
        getValue: (row) => get(row, 'firstName'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserProfile>({
        id: 'last-name',
        label: 'Last Name',
        getValue: (row) => get(row, 'lastName'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserProfile>({
        id: 'city',
        label: 'City',
        getValue: (row) => get(row, 'city'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserProfile>({
        id: 'state',
        label: 'State',
        getValue: (row) => get(row, 'state'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserProfile>({
        id: 'country',
        label: 'Country',
        getValue: (row) => get(row, 'country'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserProfile>({
        id: 'accept-terms',
        label: 'Accept Terms',
        getValue: (row) => get(row, 'acceptTerms'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'user-profiles/accept-terms-column',
      }),
      new DataSourceColumn<UserProfile>({
        id: 'approve-access',
        label: 'Approve Access',
        getValue: (row) => get(row, 'approveAccess'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
      }),

      new DataSourceColumn<UserProfile>({
        id: 'date-registered',
        label: 'Date Registered',
        getValue: (row) => get(row, 'dateRegistered'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
    ];
    return columns;
  }

  @task
  initUserProfiles = taskFor(async () => {
    try {
      this.abLink = await this.store.findRecord(
        'appbuilder-link',
        this.appBuilderLinkId
      );
      this.modelMeta = this.appbuilder.getModelMeta(
        this.abLink,
        'user-profiles'
      );

      this.dataSource = new ApiDataSource<UserProfile>(
        25,
        false,
        this.modelMeta.modelPath,
        this.session.authUser.email,
        this.loadDataTask,
        this.pageSize,
        this.columnsUI,
        this.selectedItems,
        {}
      );
      this.dataSource.onRowClick = this.showUserProfileDetail;
      this.dataSource.selectionEnabled = false;
    } catch (error) {
      this.errorMsg = error;
    }
  });

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<UserProfile>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let query: any = {
        condition: this.condition,
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
      };

      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let abList = await this.store.query(this.modelMeta.modelPath, query);
      let userProfiles = <Paged<UserProfile>>abList.toArray();
      userProfiles.meta = {
        totalCount: abList.meta['total-count'],
      };

      console.log(userProfiles);
      return userProfiles;
    }
  );

  @action
  showUserProfileDetail(row: any) {
    const appearance = {
      icon: '',
      title: `User Profile detail: ${row.firstName} ${row.lastName}`,
      custom: true,
      size: 'large',
    };

    const context = {
      userProfile: row,
      appBuilderLink: this.abLink,
    };

    this.docker.invokePopup('user-profiles/detail', appearance, context);
  }
}
