import classic from 'ember-classic-decorator';
import ApplicationAdapter from './application';

@classic
export default class CaseAdapter extends ApplicationAdapter {
  setClaimsAgent(caseId, claimAgentProvider, claimAgentUrl) {
    let url = `${this.get('host')}/${this.get(
      'namespace'
    )}/cases/set-claim-agent`;

    return this.ajax(url, 'post', {
      data: {
        data: {
          type: 'claim-agents',
          attributes: {
            'case-id': caseId,
            'claim-provider': claimAgentProvider,
            'claim-agent-url': claimAgentUrl,
          },
        },
      },
    });
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    case: CaseAdapter;
  }
}
