import classic from 'ember-classic-decorator';
import smartroom from './smartroom';


@classic
export default class RightType extends smartroom {
  primaryKey = 'rightTypeId';
  resultKey = 'rightTypes';

  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    var newPayLoad = [];

    for (var prop in payload) {
      newPayLoad.push(payload[prop]);
    }

    return super.normalizeArrayResponse(store, primaryModelClass, newPayLoad, id, requestType);
  }
}
