import {
  attr,
  hasMany,
  belongsTo,
  AsyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import {
  DebtorAssetsTypes,
  CreditorsTypes,
  LiabilitiesTypes,
  DebtorBusinessTypeA,
  DebtorBusinessTypeB,
  EstimationOfFundsType,
  DebtorTypes,
} from 'cing-app/utils/lookups';
import NaicsCodes from 'cing-app/utils/naics-codes';
//@ts-ignore - mixin
import { validator, buildValidations } from 'ember-cp-validations';
import Abstract from './abstract';
import Address from './address';
import Case from './case';
import CdpAttorneySignature from './cdp-attorney-signature';
import CdpProperty from './cdp-property';
import CdpRelatedCase from './cdp-related-case';
import CdpRepresentativeSignature from './cdp-representative-signature';
import { exportable } from 'cing-app/utils/exportable';

let AssetsShort: any = {};
let AssetsName: any = {};
DebtorAssetsTypes.forEach((item) => {
  AssetsShort[item.short] = item.id;
  AssetsName[item.name] = item.id;
});

let Creditors: any = {};
CreditorsTypes.forEach((item) => {
  Creditors[item.name] = item.id;
});

let LiabilitiesShort: any = {};
let LiabilitiesName: any = {};
LiabilitiesTypes.forEach((item) => {
  LiabilitiesName[item.name] = item.id;
  LiabilitiesShort[item.short] = item.id;
});

let DebtorBusiness: any = {};
for (let k in DebtorBusinessTypeA) {
  let item = DebtorBusinessTypeA[k];
  DebtorBusiness[item.name] = item.id;
}

let DebtorBusinessB: any = {};
for (let k in DebtorBusinessTypeB) {
  let item = DebtorBusinessTypeB[k];
  DebtorBusinessB[item.name] = item.id;
}

let EstimationOfFunds: any = {};
for (let k in EstimationOfFundsType) {
  let item = EstimationOfFundsType[k];
  EstimationOfFunds[item.name] = item.id;
}

let NaicsCodesEnum: any = {};
NaicsCodes.forEach((item) => {
  NaicsCodesEnum[item.name] = item.code;
});

const DebtorProfileValidations = buildValidations({
  caseId: validator('presence', true),
  debtorBusinessA: validator('number', { integer: true }),
  debtorBusinessB: validator('number', { integer: true }),
  reasonFiledInDistrict: validator('number', { integer: true }),
  debtorsEstimationOfFunds: validator('number', { integer: true }),
});

//@ts-ignore - mixin
export default class DebtorProfile extends Abstract.extend(
  DebtorProfileValidations,
  computedEnumMixin('assets', 'assetsId', AssetsShort),
  computedEnumMixin('assetsName', 'assetsId', AssetsName),
  computedEnumMixin('creditors', 'creditorsId', Creditors),
  //@ts-ignore - more than 3 arguments
  computedEnumMixin('liabilities', 'liabilitiesId', LiabilitiesShort),
  computedEnumMixin('liabilitiesName', 'liabilitiesId', LiabilitiesName),
  computedEnumMixin('business', 'debtorBusinessA', DebtorBusiness),

  computedEnumMixin('naicsCodeName', 'naicsCode', NaicsCodesEnum),

  computedEnumMixin(
    'debtorsEstimationOfFundsName',
    'debtorsEstimationOfFunds',
    EstimationOfFunds
  )
) {
  // s2
  @attr('string') declare otherDebtorNames?: string;
  // s3
  @exportable()
  @attr('string')
  declare ein?: string;
  // s4
  @hasMany('address') declare addresses: AsyncHasMany<Address>;
  // s5
  @exportable()
  @attr('string')
  declare website?: string;
  // s6
  @attr('number') declare debtorType: number;
  @attr('string') declare otherDebtorType?: string;
  // s7
  @attr('number') declare debtorBusinessA?: number;
  @attr('number') declare debtorBusinessB?: number;
  @exportable()
  @attr('string')
  declare naicsCode?: string;
  @exportable()
  @attr('string')
  declare industry?: string;
  // s8
  @attr('number') declare chapterType?: number;
  @attr('number') declare chapterSubType?: number;
  // s9
  @attr('boolean') declare priorCaseFiled: boolean;
  // s10
  @attr('boolean') declare pendingCaseFiled: boolean;
  @hasMany('cdpRelatedCase')
  declare cdpRelatedCases: AsyncHasMany<CdpRelatedCase>;
  // s11
  @attr('number') declare reasonFiledInDistrict?: number;
  // s12
  @attr('boolean') declare propertyWithImmediateAttention: boolean;
  @hasMany('cdpProperty') declare cdpProperties: AsyncHasMany<CdpProperty>;
  // s13
  @attr('number') declare debtorsEstimationOfFunds?: number;
  // s14
  @attr('number') declare assetsId?: number;
  // s15
  @attr('number') declare creditorsId?: number;
  // s16
  @attr('number') declare liabilitiesId?: number;
  // s17
  @belongsTo('cdpRepresentativeSignature')
  declare representativeSignature: AsyncBelongsTo<CdpRepresentativeSignature>;
  // s18
  @belongsTo('cdpAttorneySignature')
  declare attorneySignature: AsyncBelongsTo<CdpAttorneySignature>;
  @belongsTo('case') declare 'case': AsyncBelongsTo<Case>;
  @attr('string') declare caseId: string;
  // deleted: DS.attr('boolean'),
  @exportable()
  @attr('string')
  declare description?: string;
  @attr('string') declare leadingEvents?: string;
  @attr('string') declare employees?: string;
  @exportable()
  @attr('string')
  declare keyContactName?: string;
  @exportable()
  @attr('string')
  declare keyContactPhone?: string;
  @attr('string') declare sicCode?: string;

  @exportable()
  @attr('number')
  declare yearFounded?: number;
  @exportable()
  @attr('date')
  declare dateFounded?: Date;
  @exportable()
  @attr('number')
  declare employeeCount?: number;
  @exportable()
  @attr('string')
  declare linkedInUrl?: string;
  @exportable()
  @attr('string')
  declare facebookUrl?: string;
  @exportable()
  @attr('string')
  declare twitterUrl?: string;
  @exportable()
  @attr('string')
  declare crunchBaseUrl?: string;
  @exportable()
  @attr('string')
  declare ownershipType?: string;
  @exportable()
  @attr('string')
  declare sectorName?: string;

  @exportable()
  @attr('string')
  declare logo?: string;
  @exportable()
  @attr('string')
  declare sectorPreferences?: string;
  @exportable()
  @attr('string')
  declare shortDescription?: string;
  @exportable()
  @attr('string')
  declare shortName?: string;
  @exportable()
  @attr('string')
  declare stage?: string;
  @exportable()
  @attr('string')
  declare status?: string;
  @exportable()
  @attr('string')
  declare subSectorPreferences?: string;
  @exportable()
  @attr('string')
  declare subSectors?: string;
  @exportable()
  @attr('string')
  declare subType?: string;
  @exportable()
  @attr('string')
  declare subVertical?: string;
  @exportable()
  @attr('string')
  declare ticker?: string;
  @exportable()
  @attr('string')
  declare vertical?: string;

  get debtorTypeName() {
    let type = this.debtorType;

    let typesList = [];

    if (type & DebtorTypes.Corporation) {
      typesList.push('Corporation');
    }
    if (type & DebtorTypes.Partnership) {
      typesList.push('Partnership');
    }
    if (type & DebtorTypes.Other) {
      typesList.push('Other');
    }

    return typesList.join(', ');
  }

  get logoUrl() {
    let sLogo = this.get('logo') ?? '';
    if (sLogo.length) {
      return sLogo.match(
        /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/gi
      )?.[0];
    }
    return null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'debtor-profile': DebtorProfile;
  }
}
