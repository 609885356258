import { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import Abstract from './abstract';
import CountrySubdivision from './country-subdivision';

export default class Country extends Abstract {
  @attr('string') declare name: string;
  @attr('string') declare numericalCode?: string;
  @attr('string') declare nameUpperCase?: string;
  @attr('string') declare iso2?: string;
  @attr('string') declare iso3?: string;
  @hasMany('country-subdivision')
  declare countrySubdivisions: AsyncHasMany<CountrySubdivision>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    country: Country;
  }
}
