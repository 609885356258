import Model, { belongsTo, attr } from '@ember-data/model';
import File from './file';
import Folder from './folder';

export default class SearchItem extends Model {
  @attr('string') path;
  @attr('number') bytes;
  @attr('string') displayString;
  @attr('string') itemName;
  @attr('date') dateCreated;
  @attr('date') dateModified;


  @belongsTo('smartroom/file', { async: false }) fileInfo;
  @belongsTo('smartroom/folder', { async: false }) folderInfo;
}
