import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency';

import ExportDataService from 'cing-app/pods/export-data/service';

interface ExportEntityArgs {
  entityName: string;
  entity: any;
  condition: string;
  sortBy: string;
}

export default class ExportEntity extends Component<ExportEntityArgs> {
  @service exportData!: ExportDataService;

  @task
  export = taskFor(async () => {
    await this.exportData.export(
      this.args.entityName,
      this.args.entity,
      this.args.sortBy,
      this.args.condition
    );
  });
}
