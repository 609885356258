import ModelProperty, { ModelPropertyBlock } from "./model-property";
import SEParagraph, { SEParagraphBlock } from './se-paragraph';

export default (editor, config = {}) => {
	const domc = editor.DomComponents;
	const bm = editor.BlockManager;

	ModelProperty(domc);

	if (config.properties) {
		config.properties.forEach((property) => {
			ModelPropertyBlock(bm, property);
		})
	}

	bm.add('text', {
		label: 'Text',
		category: 'Basic',
		attributes: { class: 'gjs-fonts gjs-f-text' },
		content: {
			type: 'text',
			content: 'Insert your text here',
			style: { padding: '10px' },
			activeOnRender: 1
		}
	});

	//SEParagraph(domc);
	//SEParagraphBlock(bm);
}
