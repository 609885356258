import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { timeout, task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import UserInstancesService from 'cing-app/pods/user-instances/service';
import AclRole from 'cing-app/models/acl-role';
import User from 'cing-app/models/user';
import UserGroup from 'cing-app/models/user-group';
import AclRoleAssignment from 'cing-app/models/acl-role-assignment';

interface ManageResourceAccessArgs {
  header: any;
  footer: any;
  context: any;
}

const ANONYMOUS_USER_ID = '00000000-0000-0000-0000-000000000000';
export default class ManageResourceAccess extends Component<ManageResourceAccessArgs> {
  // @alias('context.model') model;
  // @alias('context.resource') resource;
  // @alias('context.resourceType') resourceType;
  // @alias('context.resourceName') resourceName;
  // @alias('context.resourcePermissions') resourcePermissions;

  @service store!: Store;
  @service userInstances!: UserInstancesService;

  @tracked
  userGroups: UserGroup[] = [];

  @tracked
  aclRoles: AclRole[] = [];

  @tracked
  addAssignmentRole: AclRole | undefined;

  @tracked
  roleAssignments: AclRoleAssignment[] = [];

  @tracked
  addNewAccess: null | boolean = null;

  @tracked
  confirmRemoveAssignment: AclRoleAssignment;

  @tracked
  addAssignmentPrincipal: User | UserGroup | undefined | null;

  get model() {
    return this.args.context.model;
  }

  get resource() {
    return this.args.context.resource;
  }

  get resourceType() {
    return this.args.context.resourceType;
  }

  get resourceName() {
    return this.args.context.resourceName;
  }

  get resourcePermissions() {
    return this.args.context.resourcePermissions;
  }

  newAssignmentType = 1;

  ANONYMOUS_USER_ID = ANONYMOUS_USER_ID;

  constructor(owner: any, args: ManageResourceAccessArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    let userGroups = await this.store.query('user-group', {
      page: {
        size: 1000,
      },
    });

    this.userGroups = userGroups.toArray();

    let aclRolesQuery = Expressions.create();
    aclRolesQuery.add(
      Filter.create({
        //@ts-ignore
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: this.resourcePermissions,
      })
    );

    let aclRoles = await this.store.query('acl-role', {
      condition: aclRolesQuery.serialize(),
      page: {
        size: 1000,
      },
    });

    this.aclRoles = aclRoles.toArray();

    this.addAssignmentRole = aclRoles.firstObject;

    await this.updateRoleAssignments.perform();
  });

  @task
  updateRoleAssignments = taskFor(async () => {
    this.roleAssignments = [];

    let query = Expressions.create();
    query.add(
      Filter.create({
        //@ts-ignore
        name: 'resource-id',
        operator: FilterOperators.EQUAL,
        value: this.resource.id,
      })
    );
    /*
    query.add(Filter.create({
        name: 'principal-type',
        operator: FilterOperators.EQUAL,
        value: 2
    }));        
    */
    /*
    query.add(
      Filter.create({
        //@ts-ignore
        name: 'principal-id',
        operator: FilterOperators.NOT_NULL,
      })
    );
      */
    this.roleAssignments = (
      await this.store.query('acl-role-assignment', {
        condition: query.serialize(),
        include: 'user,group,acl-role.acl-role-permissions.acl-permission',
        page: {
          size: 1000,
        },
      })
    ).toArray();
  });

  @computed('addAccessTask.isRunning')
  get disableInteraction() {
    return this.addAccessTask.isRunning;
  }

  @task({ restartable: true })
  _searchUsers = taskFor(async (term) => {
    await timeout(400);

    Expressions.create();

    let query = Expressions.create({ operator: ExpressionOperators.OR });

    query.add(
      Filter.create({
        //@ts-ignore
        name: 'email',
        operator: 'like',
        value: term,
      })
    );

    query.add(
      Filter.create({
        //@ts-ignore
        name: 'person.full-name',
        operator: 'like',
        value: term,
      })
    );

    return await this.store.query('user', {
      condition: query.serialize(),
      include: 'person',
      sort: 'email',
    });
  });

  @action
  searchUsers(term: string) {
    return this._searchUsers.perform(term);
  }

  @task
  addAccessTask = taskFor(async () => {
    if (
      (this.addAssignmentRole && this.addAssignmentPrincipal) ||
      (this.newAssignmentType === null && this.addAssignmentRole)
    ) {
      let roleAssignment = this.store.createRecord('acl-role-assignment', {
        principalId:
          this.newAssignmentType === null
            ? ANONYMOUS_USER_ID
            : this.addAssignmentPrincipal.id,
        principalType:
          this.newAssignmentType === null ? 1 : this.newAssignmentType,
        resourceType: this.resourceType,
        resourceId: this.resource.id,
        roleId: this.addAssignmentRole.id,
      });

      await roleAssignment.save();
      await this.updateRoleAssignments.perform();

      //set(this, "addAssignmentGroup", null);
      this.addNewAccess = null;
    }
  });

  @task
  removeAccessTask = taskFor(async (roleAssignment) => {
    await roleAssignment.destroyRecord();
    await this.updateRoleAssignments.perform();
    this.confirmRemoveAssignment = null;
  });

  @action
  addAccess() {
    this.addAccessTask.perform();
  }

  @action
  removeAssignment(roleAssignment: AclRoleAssignment) {
    this.removeAccessTask.perform(roleAssignment);
  }
}
