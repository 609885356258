import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import { ESettings } from 'cing-app/utils/lookups';
import { get, set, computed } from '@ember/object';

const LOGIN_TYPES = ["IFRAME", "POPUP"];
import classic from 'ember-classic-decorator';

@classic
export default class LoginController extends Controller {
  ESettings = ESettings;
  @service session;
  @service config;

  @computed(`config.${ESettings.PortalLoginButtonColor}`)
  get loginButtonColor() {
    return get(this.config, `settings.${ESettings.PortalLoginButtonColor}`) || null;
  }
}
