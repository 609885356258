import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { Service as IntlService } from 'ember-intl';
import { Task } from 'ember-concurrency';

interface IArgs {
  data: any;
  modal: boolean;
  title: string;
  onTryAgain(): void;
}

export default class ApiError extends Component<IArgs> {
  @service intl!: IntlService;

  get message() {
    if (this.args.data) {
      if (typeof this.args.data === 'string') {
        return this.args.data;
      } else if (this.args.data.body && this.args.data.body.errors) {
        let firstError = this.args.data.body.errors[0];
        if (firstError.code) {
          return `${firstError.code}: ${firstError.detail}`;
        } else {
          return firstError.detail;
        }
      } else {
        let payload = this.args.data;
        if (
          this.args.data &&
          this.args.data.payload &&
          this.args.data.payload.errors
        ) {
          payload = this.args.data.payload;
        }

        if (payload.error) {
          payload = payload.error;
        }

        if (payload) {
          let message = payload.message || 'An error occured.';

          // first try to get error from API response
          if (payload.errors && payload.errors.length) {
            let firstError = payload.errors[0];

            if (
              this.args.data.errorContext &&
              this.intl.exists(
                `errors.${this.args.data.errorContext}.${firstError.code}`
              )
            ) {
              message = this.intl.t(
                `errors.${this.args.data.errorContext}.${firstError.code}`
              );
            } else if (this.intl.exists(`errors.${firstError.code}`)) {
              message = this.intl.t(`errors.${firstError.code}`);
            } else {
              if (firstError.code) {
                message = `${firstError.code}: ${firstError.detail}`;
              } else {
                message = firstError.detail;
              }
            }
          }

          return message;
        }
      }
    }
  }
}
