import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { get } from '@ember/object';
import { task } from 'ember-concurrency';
import {
  ProjectTabMap,
  CaseProjectUrlOpenModeMap,
} from 'cing-app/utils/lookups';
import classic from 'ember-classic-decorator';
import { SmartRoomContentViewModes } from 'cing-app/utils/lookups';
import { tracked } from '@glimmer/tracking';

const defaultSRcontentViewMode = 'files';
@classic
export default class SmartRoom extends Component {
  @service store;
  @service session;
  @service serverVariables;
  @service userInstances;

  createExternalUrlTab = true;
  showAllSmartRoomFolders = false;

  SmartRoomContentViewModes = Object.entries(SmartRoomContentViewModes).map(
    ([k, v]) => ({ key: k, value: v })
  );

  @tracked
  selectedSRviewMode;

  @tracked
  allAccessSmartRoomFolders;

  @tracked
  allAccessSmartRoomFoldersError;

  @computed
  get sites() {
    return this.store.findAll('smartroom/site', { reload: true });
  }

  init() {
    super.init(...arguments);
    this.initTask.perform();

    this.tab.on('onSave', this.onSave);
  }

  willDestroyElement() {
    this.tab.off('onSave', this.onSave);
  }

  @task
  *initTask() {
    yield this.session.authenticate('authenticator:torii', 'si', {
      silent: true,
    });

    this.allAccessSmartRoomFolders = null;

    let sites = yield this.store.findAll('smartroom/site', { reload: true });

    this.set('sites', sites);

    try {
      this.allAccessSmartRoomFolders = yield (this.model.settings.allAccessSmartRoomFolders)
    } catch {
      this.allAccessSmartRoomFoldersError = true;
    }

    if (this.model.settings.SmartRoomContentViewMode) {
      this.selectedSRviewMode = this.SmartRoomContentViewModes.find(
        (f) => f.key === this.model.settings.SmartRoomContentViewMode
      );
    } else if (this.model.isNew) {
      this.model.settings.SmartRoomContentViewMode = defaultSRcontentViewMode;
      this.selectedSRviewMode = this.SmartRoomContentViewModes.find(
        (f) => f.key === defaultSRcontentViewMode
      );
    } else {
      this.model.settings.SmartRoomContentViewMode = 'split';
      this.selectedSRviewMode = this.SmartRoomContentViewModes.find(
        (f) => f.key === 'split'
      );
    }
  }

  @action
  searchSites(term) {
    term = term.toLowerCase();

    return this.sites.filter((item) => {
      if (
        item.siteName.toLowerCase().indexOf(term) > -1 ||
        item.slug.toLowerCase().indexOf(term) > -1
      ) {
        return true;
      }
    });
  }

  @action
  onSave(tab, project, onSave) {
    if (this.createExternalUrlTab) {
      let sortOrder = 1;

      project.get('projectTabs').then((projectTabs) => {
        let lastTab = projectTabs.toArray().sortBy('sortOrder').lastObject;

        if (lastTab && lastTab.sortOrder) {
          sortOrder = lastTab.sortOrder + 1;
        }

        let smartRoomTab = this.store.createRecord('project-tab', {
          projectId: project.id,
          enabled: true,
          sortOrder: sortOrder,
          title: 'SmartRoom',
          type: ProjectTabMap.EXTERNAL_URL,
          content:
            this.serverVariables.get('smartroomAppUrl') +
            '#/' +
            this.selectedSmartRoom?.slug,
        });

        smartRoomTab.set(
          'settings.EUOpenMode',
          CaseProjectUrlOpenModeMap.NEW_WINDOW
        );

        smartRoomTab.save().then(() => {
          if (onSave) {
            onSave();
          }
        });
      });
    }
  }

  @action
  onRoomChanged(room) {
    this.set('selectedSmartRoom', room);

    if (room && get(this.model, 'settings.SmartRoomId') !== room.id) {
      this.set('model.settings.SmartRoomId', room.id);
      this.set('model.settings.SmartRoomFolderId', room.topLevelFolderId);
    } else {
      this.set('model.settings.SmartRoomId', null);
    }

    if (this.allAccessSmartRoomFolders) {
      this.allAccessSmartRoomFolders = null;
    }
    this.initTask.perform();

  }

  @action
  onSelectFolder(folder) {
    this.set('selectSmartRoomFolder', null);
    this.set('model.settings.SmartRoomFolderId', folder.id);
  }

  @action
  onSelectAllAccessFolder(folder) {
    //this.set('selectSmartRoomFolder', null);
    let AllAccessSmartRoomFolderIds = this.get(
      'model.settings.AllAccessSmartRoomFolderIds'
    );

    if (!AllAccessSmartRoomFolderIds) {
      AllAccessSmartRoomFolderIds = [];
    }

    AllAccessSmartRoomFolderIds.pushObject(folder.id);

    AllAccessSmartRoomFolderIds = AllAccessSmartRoomFolderIds.uniq();

    this.set(
      'model.settings.AllAccessSmartRoomFolderIds',
      AllAccessSmartRoomFolderIds
    );

    this.set('selectAllAccessSmartRoomFolder', null);

    this.initTask.perform();
  }

  @action
  onChangedSRviewMode(viewMode) {
    this.selectedSRviewMode = viewMode;
    this.set('model.settings.SmartRoomContentViewMode', viewMode.key);
    // this.set('model.SmartRoomContentViewMode', viewMode.key);
  }

  @action
  resetAllAccessFolders() {
    this.set('model.settings.AllAccessSmartRoomFolderIds', []);

    this.set('selectAllAccessSmartRoomFolder', null);

    this.initTask.perform();
  }

  @action
  removeAllAccessFolder(folder) {
    get(this.model, 'settings.AllAccessSmartRoomFolderIds').removeObject(
      folder.id
    );
    this.initTask.perform();
  }
}
