import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set, setProperties } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  DataSourceColumn,
  IDataSource,
  StaticDataSource,
  ApiDataSource,
} from 'smex-ui-table';
import { camelize, capitalize } from '@ember/string';
import { NotFoundError } from '@ember-data/adapter/error';

import { icon } from '@fortawesome/fontawesome-svg-core';
import injectScripts from 'ember-inject-scripts';

import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { next } from '@ember/runloop';
export default class AbProject extends Component {
  @service store;
  @service session;
  @service('docker-item') docker;

  @tracked events;
  @tracked links;
  @tracked gantt;
  @tracked selectedEvent;
  @tracked showTaskMenuElement;

  constructor(owner, args) {
    super(owner, args);

    this.initTask.perform();
  }

  @task
  *initTask() {
    let multipleScripts = [
      {
        id: 'gantt',
        src: '/libraries/gantt/dhtmlxgantt.js',
        once: true,
      },
    ];

    let injectEvents = yield injectScripts(multipleScripts);

    let project = this.args.project;

    let eventsQuery = Expressions.create();
    eventsQuery.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: project.id,
      })
    );
    eventsQuery.add(
      Filter.create({
        name: 'show-in-project-management',
        operator: FilterOperators.EQUAL,
        value: true,
      })
    );

    if (this.events) {
      this.events.forEach((event) => {
        event.rollbackAttributes();
      });
    }

    if (this.links) {
      this.links.forEach((link) => {
        link.rollbackAttributes();
      });
    }

    this.events = (yield this.store.query('event', {
      condition: eventsQuery.serialize(),
      include:
        'assigned-user.person,interested-parties.person,interested-parties.email,interested-parties.company',
      sort: 'sort-order',
      page: {
        size: 1000,
        number: 1,
      },
    })).toArray();

    /*
    events = events.map((a) => {
      if (a.wbs) {
        set(a, 'wbs', a.wbs.replace(/\d+/g, n => +n + 1000000));
      }
      return a;
    })

    console.log("EVENTS: ", events);

    events = events.sortBy('wbs').map((a) => {
      if (a.wbs) {
        set(a, 'wbs', a.wbs.replace(/\d+/g, n => +n - 1000000))
      }

      return a;
    });

    this.events = events;
    */

    this.links = (yield this.store.query('event-link', {
      page: {
        size: 10000,
        number: 1,
      },
    })).toArray();
  }

  transformEventsForGantt(events, links) {
    let eList = [];

    for (var a = 0; a < events.length; a++) {
      let ev = events[a];
      eList.pushObject({
        id: ev.id,
        start_date: ev.dateStart,
        end_date: ev.dateEnd,
        text: ev.title,
        progress: ev.progress,
        parent: ev.parentId,
        editable: true,
        type: 'task',
        wbs: ev.wbs,
      });
    }

    let lList = [];

    for (var a = 0; a < links.length; a++) {
      let ev = links[a];
      lList.pushObject({
        id: ev.id,
        source: ev.sourceId,
        target: ev.targetId,
        type: ev.type,
      });
    }

    return {
      tasks: eList,
      links: lList,
    };
  }

  @task
  *loadDataTask(columns, pageSize, pageNumber, options) {
    let sortColumn = columns.find((col) => {
      return col.sort;
    });

    let query = {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    };

    if (sortColumn) {
      query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${
        sortColumn.id
      }`;
    }

    let rows = yield this.store.query(options.modelName, query);

    let result = rows.toArray();

    result.meta = {
      totalCount: rows.meta['total-count'],
    };

    return result;
  }

  @task
  *getColumns(modelMeta) {
    let modelClass = modelMeta.modelClass;

    let cols = [
      new DataSourceColumn({
        id: 'id',
        label: 'ID',
        getValue: (row) => row.id,
        valueComponent: 'smex-ui-table/text-column',
        minWidth: 40,
        maxWidth: 40,
        sortingEnabled: true,
      }),
    ];

    modelMeta.fields.forEach((metaField) => {
      cols.pushObject(
        new DataSourceColumn({
          id: metaField.property,
          label: capitalize(get(metaField, 'formElement.searchColumnName')),
          getValue: (row) => get(row, metaField.property),
          hidden: get(metaField, 'searchViewLayout.isVisible'),
          valueComponent: 'smex-ui-table/text-column',
          minWidth: get(metaField, 'searchViewLayout.columnWidth')
            ? parseInt(get(metaField, 'searchViewLayout.columnWidth'))
            : 100,
          sortingEnabled: true,
        })
      );
    });

    return cols;
  }

  @action
  setupGanttChart(element) {
    this.gantt = Gantt.getGanttInstance({
      plugins: {
        auto_scheduling: true,
        multiselect: true,
        undo: true,
      },
      container: element,
      config: {
        work_time: true,
        //auto_scheduling_compatibility: true,
        auto_scheduling: true,
        auto_scheduling_strict: true,
        auto_scheduling_initial: true,
        autofit: true,
        autoscroll: true,
        auto_types: true,
        drag_project: true,
        sort: true,
        order_branch: true,
        order_branch_free: true,
        details_on_create: false,
        //row_height: 30,
        external_render: {
          // checks the element is a React element
          isElement: (element) => {
            let check =
              element instanceof Element || element instanceof HTMLDocument;
            return check;
          },
          // renders the React element into the DOM
          renderElement: (element, container) => {
            let target = container.firstChild;

            target.appendChild(element);
          },
        },
      },
      templates: {
        scale_cell_class: function (date) {
          if (date.getDay() == 0 || date.getDay() == 6) {
            return 'weekend';
          }
        },
        timeline_cell_class: function (item, date) {
          if (date.getDay() == 0 || date.getDay() == 6) {
            return 'weekend';
          }
        },
      },
    });

    let self = this;

    this.gantt.config.columns = [
      {
        name: 'wbs',
        label: 'WBS',
        width: 40,
        template: this.gantt.getWBSCode,
        sort: false,
      },
      { name: 'text', label: 'Task', tree: true, width: 170, resize: true },
      { name: 'start_date', label: 'Start', align: 'center', width: 80 },
      { name: 'duration', label: 'Dur.', align: 'center', width: 60 },
      {
        name: 'action',
        label: '',
        tree: false,
        width: 20,
        onrender: (item, node) => {
          let container = document.createElement('div');
          container.style = 'cursor: pointer;';
          let menu = document.createElement('div');
          menu.style = 'position: absolute;';

          container.innerHTML = icon({
            prefix: 'fas',
            iconName: 'ellipsis-v',
            fixedWidth: true,
          }).html;
          container.appendChild(menu);
          container.onclick = function (e) {
            e.preventDefault();
            e.stopPropagation();
            self.showTaskMenu(item, menu);
          };
          return container;
        },
      },
      { name: 'add', width: 40 },
    ];

    this.gantt.sort('wbs', true);

    this.gantt.attachEvent('onTaskClick', function (id) {
      self.viewTask(id);
      return true;
    });

    this.gantt.showLightbox = function (taskId) {
      var task = self.gantt.getTask(taskId);

      self.store
        .findRecord('event', taskId, {
          include: 'assigned-user.person',
        })
        .then(
          (event) => {
            if (!event) {
              let dateStart = self.gantt.getState().min_date;
              let dateEnd = moment(dateStart).add(1, 'day').toDate();

              event = self.store.createRecord('event', {
                title: task.text,
                dateStart: dateStart,
                dateEnd: dateEnd,
                parentId: task.parent || null,
                progress: task.progress,
              });
            }
            self.editEvent(event);
          },
          (error) => {
            alert(error);
          }
        );
    };

    this.gantt.parse(this.transformEventsForGantt(this.events, this.links));

    var dp = this.gantt.createDataProcessor({
      task: {
        create: function (data) {
          return self.event_create(data);
        },
        update: function (data, id) {
          return self.event_update(id, data);
        },
        delete: function (id) {
          return self.event_delete(id);
        },
      },
      link: {
        create: function (data) {
          return self.link_create(data);
        },
        update: function (data, id) {
          return self.link_update(id, data);
        },
        delete: function (id) {
          return self.link_delete(id);
        },
      },
    });

    dp.attachEvent('onAfterUpdate', function (id, action, tid, response) {
      if (action == 'error') {
        alert('There was an error while saving changes');
        self.gantt.clearAll();
        self.initTask.perform().then(() => {
          self.gantt.parse(
            self.transformEventsForGantt(self.events, self.links)
          );
        });
      }
    });
  }
  /*
  id: ev.id,
  start_date: ev.dateStart,
  end_date: ev.dateEnd,
  text: ev.title,
  progress: ev.progress,
  parent: ev.parentId,
  editable: true,
  type: 'task',
  wbs: ev.wbs
  */

  _updateEventWithTaskData(event, task) {
    let dateStart = moment(task.start_date, 'DD-MM-YYYY hh:mm').toDate();
    let dateEnd = moment(task.end_date, 'DD-MM-YYYY hh:mm').toDate();

    setProperties(event, {
      target: task.target,
      title: task.text,
      dateStart: dateStart,
      dateEnd: dateEnd,
      parentId: task.parent || null,
      progress: task.progress,
      wbs: task.$wbs,
    });
  }

  @action
  event_create(data) {
    let ev = this.store.createRecord('event', {
      projectId: this.args.project.id,
      showInProjectManagement: true,
    });

    this._updateEventWithTaskData(ev, data);

    return ev
      .save()
      .then(() => {
        this.events.pushObject(ev);
        let returnValue = {
          tid: ev.id,
          action: 'inserted',
        };

        this.editEvent(ev);

        return returnValue;
      })
      .catch(() => {
        return {
          action: 'error',
        };
      });
  }

  @action
  event_update(id, data) {
    let ev = this.events.findBy('id', id);

    this._updateEventWithTaskData(ev, data);

    return ev
      .save()
      .then((event) => {
        return {
          action: 'updated',
        };
      })
      .catch(() => {
        return {
          action: 'error',
        };
      });
  }

  @action
  event_delete(id) {
    let ev = this.events.findBy('id', id);

    return ev
      .destroyRecord()
      .then((event) => {
        this.events.removeObject(ev);
        return {
          action: 'deleted',
        };
      })
      .catch(() => {
        return {
          action: 'error',
        };
      });
  }

  @action
  link_create(data) {
    let dateStart = this.gantt.getState().min_date;
    let dateEnd = moment(dateStart).add(1, 'day').toDate();

    let ev = this.store.createRecord('event-link', {
      sourceId: data.source,
      targetId: data.target,
      type: data.type,
    });

    return ev
      .save()
      .then(() => {
        this.links.pushObject(ev);
        let returnValue = {
          tid: ev.id,
          action: 'inserted',
        };

        return returnValue;
      })
      .catch(() => {
        return {
          action: 'error',
        };
      });
  }

  @action
  link_update(id, data) {
    let ev = this.links.findBy('id', id);

    setProperties(ev, {
      sourceId: data.source,
      targetId: data.target,
      type: data.type,
    });

    return ev
      .save()
      .then((event) => {
        return {
          action: 'updated',
        };
      })
      .catch(() => {
        return {
          action: 'error',
        };
      });
  }

  @action
  link_delete(id) {
    let ev = this.links.findBy('id', id);

    return ev
      .destroyRecord()
      .then((event) => {
        this.links.removeObject(ev);
        return {
          action: 'deleted',
        };
      })
      .catch(() => {
        return {
          action: 'error',
        };
      });
  }

  @action
  getActions(row) {
    return [
      {
        // disabledMessage: 'Sorry',
        label: 'Edit',
        icon: 'pencil-alt',
        action: (row) => {
          this.editEvent(row);
        },
      },
    ];
  }

  @action
  editRecord(row) {
    let appearance = {
      title: '<small>Edit:</small> ' + row.get('id'),
      size: 'medium',
      custom: true,
    };

    this.docker.invokePopup('project-detail/ab-project/form', appearance, {
      model: row,
      formElements: this.formElements,
    });
  }

  @action
  editEvent(event) {
    let self = this;

    let appearance = {
      title: `<small>Edit Event: </small> ${event.title}`,
      size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'project-detail/project-management/form',
      appearance,
      {
        doNotSaveOnUpdate: true,
        model: event,
        project: this.args.project,
        onDelete(id) {
          self.gantt.deleteTask(id);
        },
        onUpdate(event) {
          let task = self.gantt.getTask(event.id);

          task.text = event.title;
          task.start_date = event.dateStart;
          task.end_date = event.dateEnd;
          task.progress = event.progress;
          task.duration = event.dateEnd
            ? self.gantt.calculateDuration(task)
            : 0;
          self.gantt.updateTask(event.id);
        },
      }
    );
  }

  @action
  addEvent() {
    let event = this.store.createRecord('event', {
      projectId: this.args.project.id,
      project: this.args.project,
    });

    let appearance = {
      title: '<small>New Activity</small> ',
      size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'project-detail/project-management/form',
      appearance,
      {
        model: event,
        project: this.args.project,
      }
    );
  }

  @action
  viewTask(id) {
    set(this, 'selectedEvent', null);

    this.store
      .findRecord('event', id, {
        include: 'assigned-user.person',
      })
      .then((event) => {
        set(this, 'selectedEvent', event);
        next(this.gantt, 'showTask', event.id);
      });
  }

  @action
  showTaskMenu(obj, container) {
    this.showTaskMenuFor = obj;

    set(this, 'showTaskMenuElement', container);
    console.log('OBJ: ', obj);

    /*
    var columns = gantt.getGridColumns();
    var indx = 0;
    var node = e.target || e.srcElement;

    if (node.className.indexOf("gantt_cell") < 0) {
      node = node.parentElement;
    }

    if (node.className.indexOf("gantt_cell") != -1) {
      while (node) {
        node = node.previousElementSibling;
        indx++;
      }
      var columnName = columns[indx - 1].name;

      if (columnName === 'action') {
        console.log("SHOW MENU FOR: ", id, node);
        return false;
      }
    }
    return true;
    */
  }

  @action
  indentTask(task_id) {
    let gantt = this.gantt;

    var prev_id = gantt.getPrevSibling(task_id);
    while (gantt.isSelectedTask(prev_id)) {
      var prev = gantt.getPrevSibling(prev_id);
      if (!prev) break;
      prev_id = prev;
    }
    if (prev_id) {
      var new_parent = gantt.getTask(prev_id);
      gantt.moveTask(
        task_id,
        gantt.getChildren(new_parent.id).length,
        new_parent.id
      );
      new_parent.type = gantt.config.types.project;
      new_parent.$open = true;
      gantt.updateTask(task_id);
      gantt.updateTask(new_parent.id);
      return task_id;
    }
    return null;
  }

  @action
  outdentTask(task_id, initialIndexes, initialSiblings) {
    let gantt = this.gantt;

    var cur_task = gantt.getTask(task_id);
    var old_parent = cur_task.parent;
    if (gantt.isTaskExists(old_parent) && old_parent != gantt.config.root_id) {
      var index = gantt.getTaskIndex(old_parent) + 1;
      var prevSibling = initialSiblings[task_id].first;

      if (gantt.isSelectedTask(prevSibling)) {
        index += initialIndexes[task_id] - initialIndexes[prevSibling];
      }
      gantt.moveTask(task_id, index, gantt.getParent(cur_task.parent));
      if (!gantt.hasChild(old_parent))
        gantt.getTask(old_parent).type = gantt.config.types.task;
      gantt.updateTask(task_id);
      gantt.updateTask(old_parent);
      return task_id;
    }
    return null;
  }

  @action
  comingSoon() {
    alert('Feature is not available at the moment.');
  }

  @action
  batchAction(actionName) {
    let task_id = null;

    let gantt = this.gantt;

    if (gantt.getSelectedTasks().length === 0) {
      task_id = this.showTaskMenuFor.id;
    }

    var cascadeAction = {
      indentTask: true,
      outdentTask: true,
      delTask: true,
    };

    var singularAction = {
      undoTask: true,
      redoTask: true,
    };

    var targetAction = this[actionName];

    if (task_id) {
      gantt.batchUpdate(function () {
        // need to preserve order of items on indent/outdent,
        // remember order before changing anything:
        var indexes = {};
        var siblings = {};
        gantt.ext.undo.saveState(task_id, 'task');
        indexes[task_id] = gantt.getTaskIndex(task_id);
        siblings[task_id] = {
          first: null,
        };

        var currentId = task_id;
        while (
          gantt.isTaskExists(gantt.getPrevSibling(currentId)) &&
          gantt.isSelectedTask(gantt.getPrevSibling(currentId))
        ) {
          currentId = gantt.getPrevSibling(currentId);
        }
        siblings[task_id].first = currentId;

        var updated = {};
        if (cascadeAction[actionName]) {
          if (!updated[gantt.getParent(task_id)]) {
            var updated_id = targetAction(task_id, indexes, siblings);

            updated[updated_id] = true;
          } else {
            updated[task_id] = true;
          }
        } else {
          targetAction(task_id, indexes);
        }
      });
    } else {
      gantt.batchUpdate(function () {
        // need to preserve order of items on indent/outdent,
        // remember order before changing anything:
        var indexes = {};
        var siblings = {};
        gantt.eachSelectedTask(function (task_id) {
          gantt.ext.undo.saveState(task_id, 'task');
          indexes[task_id] = gantt.getTaskIndex(task_id);
          siblings[task_id] = {
            first: null,
          };

          var currentId = task_id;
          while (
            gantt.isTaskExists(gantt.getPrevSibling(currentId)) &&
            gantt.isSelectedTask(gantt.getPrevSibling(currentId))
          ) {
            currentId = gantt.getPrevSibling(currentId);
          }
          siblings[task_id].first = currentId;
        });

        var updated = {};
        gantt.eachSelectedTask(function (task_id) {
          if (cascadeAction[actionName]) {
            if (!updated[gantt.getParent(task_id)]) {
              var updated_id = targetAction(task_id, indexes, siblings);

              updated[updated_id] = true;
            } else {
              updated[task_id] = true;
            }
          } else {
            targetAction(task_id, indexes);
          }
        });
      });
    }
  }
}
