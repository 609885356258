import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import EmberObject, { action, set, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { scheduleOnce } from '@ember/runloop';
import { A } from '@ember/array';
import Component from '@ember/component';
import moment from 'moment';
import { task } from 'ember-concurrency';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';

import { EventType } from 'cing-app/utils/lookups';

@classic
@classNames('d-flex', 'flex-grow-1')
export default class List extends Component.extend(FilterBuilder) {
  @service
  config;

  @service
  store;

  @service('docker-item')
  docker;

  totalPages = 0;
  lastScroll = 0;
  reachedTop = false;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    this.registerEventView(this);
    this.reload();
  }

  reload() {
    this.set('eventGroups', A());
    this.set('events', null);

    this.set('startDate', moment().subtract(3, 'months').startOf('month'));

    //this.getEvents.perform(this.startDate, null);
    this.getEvents.perform();
  }

  @task(function* (startDate, endDate) {
    let eventFilter = Expressions.create();
    eventFilter.add(Filter.create({
      name: 'show-in-calendar',
      operator: FilterOperators.EQUAL,
      value: true
    }));

    let events = yield this.model.hasMany('events').reload({
      adapterOptions: {
        condition: eventFilter.serialize()
      }
    });

    events = events.filterBy('showInCalendar', true).sortBy('dateStart').reverseObjects();

    this.populateEvents(events);
    this.set('totalPages', events.get('meta.total-pages'));
    this.set('isLoading', false);


    this.set('lastScroll', this.$('.scrollable-content').parent().scrollTop());
    this.set('lastHeight', this.$('.scrollable-content').height());

    scheduleOnce('afterRender', this, "fixScroll");
  })
  getEvents;

  populateEvents(fetchedEvents) {
    let events = this.eventGroups;
    if (!isEmpty(fetchedEvents)) {
      let current = null;
      let monthId = null;
      let monthString = null;
      let monthGroup = null;
      let dayString = null;
      let dayGroup = null;

      fetchedEvents.forEach(event => {
        current = moment(event.get('dateStart'));

        // create new month group if it doesn't exist yet
        monthString = current.format('MMMM YYYY');
        monthId = current.format('MM-YYYY');
        monthGroup = events.findBy('label', monthString);
        if (!monthGroup) {
          monthGroup = events.pushObject(EmberObject.create({
            id: monthId,
            label: monthString,
            date: event.get('dateStart'),
            items: A()
          }));
        }

        // create new day group if it doesn't exist yet
        dayString = current.format('ddd MMM D');
        dayGroup = monthGroup.items.findBy('label', dayString);
        if (!dayGroup) {
          dayGroup = monthGroup.items.pushObject(EmberObject.create({
            label: dayString,
            date: event.get('dateStart'),
            items: A()
          }));
        }

        if (!dayGroup.items.includes(event)) {
          dayGroup.items.pushObject(event);
        }
      });

      // sort month groups in ascending order (earliest first)
      events = events.sortBy('date');

      events.forEach(monthGroup => {
        // sort day groups in descending order (latest first)
        monthGroup.set('items', monthGroup.get('items').sortBy('date'));

        monthGroup.get('items').forEach((dayGroup) => {
          dayGroup.set('items', dayGroup.get('items').sortBy('dateStart'));
        });
      });

      this.set('eventGroups', events);
    }
  }

  fixScroll() {
    let newHeight = this.$('.scrollable-content').height();
    let newScroll = (newHeight - this.lastHeight) + this.lastScroll;

    console.log("NEW SCROLL: ", newScroll);

    this.set('scrollToY', newScroll)
  }

  @action
  showJudgeDetail(judge) {
    const appearance = { label: 'Judge detail', icon: '', title: 'Judge detail' };
    const context = { judgeId: judge.get('id') };
    this.docker.invokePopup('judge-detail', appearance, context);
  }

  @action
  fetchEarlierEvents(scrollOffset, event) {
    return;
    if (this.reachedTop) {
      return;
    }

    let directionUp = scrollOffset < this.lastScroll;

    this.set('lastScroll', scrollOffset);

    if (directionUp && scrollOffset < 500 && !this.get('getEvents.isRunning')) {
      let currentStart = this.startDate.clone();

      this.set('startDate', currentStart.clone().subtract(3, 'months').startOf('month'));
      this.getEvents.perform(this.startDate, currentStart.subtract(1, 'months').endOf('month'));
    }
  }
}
