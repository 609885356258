import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from "@ember/component";

@classic
export default class Add extends Component {
  @service
  appEvents;

  @service
  store;

  @action
  doAddToWatchList() {
    let watchList = this.get('store').createRecord('watchList', {
      projectId: this.get('record.id'),
      caseId: this.get('record.caseId')
    });

    watchList.save().then(() => {
      this.sendAction('success', watchList)
      this.get('appEvents').publish('watchlistChanged');
    });

  }
}