import Component from '@glimmer/component';
import { InternalModels } from '../../models';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency'
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import DataLink from 'cing-app/models/data-link';
import DataLinkModel from 'cing-app/models/data-link-model';

import DockerItemService from '../../../../docker-item/service';
import DataLinkView from 'cing-app/models/data-link-view';

interface DataLinkViewsArgs {
	model: DataLink;
	onUpdate?: any
}

export default class Views extends Component<DataLinkViewsArgs> {
	InternalModels = InternalModels;


	constructor(owner: any, args: DataLinkViewsArgs) {
		super(owner, args);
	}

	@tracked selectedModel!: DataLinkModel | null;
	@tracked showAddNewView: boolean = false;
	@tracked newViewType: string = "list";

	@service declare store: Store;
	@service('docker-item') declare docker: DockerItemService;

	@action
	selectNewView(dataLinkModel: DataLinkModel) {
		this.selectedModel = dataLinkModel;
	}

	@action
	addNewView() {
		this.selectedModel = null;
		this.showAddNewView = true;
	}

	@action
	cancelNewView() {
		this.selectedModel = null;
		this.showAddNewView = false;
	}

	@action
	editView(model: DataLinkView) {
		if (model.type === "list") {
			const appearance = {
				label: 'Edit DataLink View',
				icon: '',
				title: 'Edit DataLink View',
				custom: true,
				size: 'large'
			};

			this.docker.invokePopup('manage-data-links/detail/views/edit-list', appearance, {
				model: model,
				dataLink: this.args.model
			});
		} else {
			const appearance = {
				label: 'Edit DataLink Detail View',
				icon: '',
				title: 'Edit DataLink Detail View',
				custom: true,
				size: 'extra-large'
			};

			this.docker.invokePopup('manage-data-links/detail/views/edit-detail', appearance, {
				model: model,
				dataLink: this.args.model
			});
		}
	}

	@task
	addNewViewTask = taskFor(async (): Promise<any> => {
		//@ts-ignore
		let modelFragment = this.store.createFragment('data-link-view', {
			name: this.selectedModel?.name,
			modelName: this.selectedModel?.modelName,
			type: this.newViewType,
		});

		this.args.model.views.pushObject(modelFragment);
		this.showAddNewView = false;
	});

	@action
	removeView(view: DataLinkView) {
		this.args.model.views.removeObject(view);
	}
}