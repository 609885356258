// import Ember from 'ember';
import Model, { attr } from '@ember-data/model';

export default class UserInstance extends Model {
  @attr('string') declare instanceUrl?: string;
  @attr('string') declare instanceId?: string;
  @attr('boolean') declare dealCloudEnabled?: boolean;
  @attr('boolean') declare smartRoomProvisioningEnabled?: boolean;
  @attr('jsonb') declare syncDescription?: any;
  @attr() declare abInstances: any;
  @attr('string') declare enquiryEmail?: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-instance': UserInstance;
  }
}
