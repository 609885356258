import JSONAPISerializer from '@ember-data/serializer/json-api';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import Store from '@ember-data/store';
import Model from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class ApplicationSerializer extends JSONAPISerializer {
  @service() config: any;

  viewAsUrl(sourceUrl: string) {
    let viewAsUser = get(this.config, 'viewAsUser');

    if (viewAsUser) {
      let newUrl = new URL(sourceUrl);
      newUrl.searchParams.append('viewas', viewAsUser);
      return newUrl.toString();
    }

    return sourceUrl;
  }

  normalizeResponse(
    store: Store,
    primaryModelClass: Model,
    payload: any,
    id: string | number,
    requestType: string
  ) {
    // April 11 2018 - workaround for buggy API response
    if (requestType === 'findHasMany') {
      if (payload.data === null) {
        payload = {
          data: [],
        };
      }
    }

    return super.normalizeResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType
    );
    //return this._super(store, primaryModelClass, payload, id, requestType);
  }

  extractRelationship(relationshipModelName: any, relationshipHash: any) {
    //    let data = this._super(...arguments);
    let data: any = super.extractRelationship(
      relationshipModelName,
      relationshipHash
    );

    if (data.links) {
      if (data.links.related) {
        data.links.related = this.viewAsUrl(data.links.related);
      }

      if (data.links.self) {
        data.links.self = this.viewAsUrl(data.links.self);
      }
    }

    return data;
  }

  normalizeFindHasManyResponse(
    store: Store,
    primaryModelClass: Model,
    payload: any,
    id: string | number,
    requestType: string
  ) {
    // workaround for Dusan's buggy API response
    try {
      if (!payload.links.related && payload.links.self) {
        payload.links.related = payload.links.self;
      }
    } catch (e) {
      console.log('Could not normalize findHasMany response.');
    }

    if (payload.links) {
      if (payload.links.related) {
        payload.links.related = this.viewAsUrl(payload.links.related);
      }

      if (payload.links.self) {
        payload.links.self = this.viewAsUrl(payload.links.self);
      }
    }

    return super.normalizeFindHasManyResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType
    );
    //return this._super(...arguments);
  }

  attrs: any = {
    createTime: { serialize: false },
    modifyTime: { serialize: false },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    application: ApplicationSerializer;
  }
}
