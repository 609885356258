import Component from '@ember/component';
import { computed, action, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { EReportStatus, ProjectTabMap } from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
} from 'cing-app/pods/smartroom/service';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import TableTheme from '../abstract-import/table-theme';
import contact from 'cing-app/adapters/ab/investors/contact';

import { SYNC_API } from 'cing-app/pods/user-instances/service';

const PARTY_MODES = {
  SIMPLE: 'simple',
  BATCH: 'batch',
};
import classic from 'ember-classic-decorator';

@classic
export default class ImportSmartRoomContacts extends Component {
  @service store;
  @service config;
  @service session;
  @service smartroom;
  @service seActions;
  @service userInstances;
  @service('docker-item') docker;

  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  searchName = null;
  searchType = 'fullName';
  currentStep = 1;
  pageSize = 10;
  condition = null;
  include = 'company';
  importAction = null;
  addUsersToSmartRoom = false;
  SmartRoomUserStatus = UserStatus;
  inviteUsersToSmartRoom = false;
  defaultUserStatus = UserStatus.HOLD;

  @computed
  get themeInstance() {
    return TableTheme.create();
  }

  @alias('context.project') project;

  @alias('context.onComplete') onComplete;

  init() {
    super.init(...arguments);
    this.set('selectedItems', []);
    this.initTask.perform();
  }

  @task
  *initTask() {
    let interestedPartyRoles = yield this.store.query('interested-party-role', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
    this.set('interestedPartyRoles', interestedPartyRoles.toArray());

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: AclPermissionType.ProjectTabRead,
      })
    );

    let viewerGroups = (yield this.store.query('acl-role', {
      page: {
        size: 1000,
      },
      condition: vgQuery.serialize(),
      sort: 'description',
    })).toArray();

    this.set('viewerGroups', viewerGroups);

    this.searchContactsTask.perform();
  }

  @task({
    restartable: true,
  })
  *searchContactsTask(term) {
    yield timeout(500);

    let query = Expressions.create({ operator: ExpressionOperators.OR });

    let condition = null;

    if (term) {
      query.add(
        Filter.create({
          name: this.searchType,
          operator: FilterOperators.LIKE,
          value: term,
        })
      );
    }

    if (query.expressions.length) {
      set(this, 'condition', query.serialize());
    } else {
      set(this, 'condition', null);
    }

    /*
    let dcContacts = yield this.store.query('sync-contact', {
      condition: query.expressions.length ? query.serialize() : null,
      page: {
        number: 1,
        size: 10000
      }
    });

    this.set('dcContacts', dcContacts);
    */
  }

  @computed
  get columns() {
    let self = this;

    let columns = [
      {
        title: 'Company',
        propertyName: 'company.name',
        component: 'api-table/columns/html',
        className: 'column-company',
      },
      {
        title: 'Name',
        propertyName: 'fullName',
        className: 'column-contact',
      },
      {
        title: 'Email',
        propertyName: 'email',
        component: 'api-table/columns/html',
        className: 'column-email',
      },
      {
        title: 'Office Phone',
        propertyName: 'officePhone',
      },
      {
        title: 'Mobile Phone',
        propertyName: 'mobilePhone',
      },
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
    ];

    return columns;
  }

  @task
  *processTask() {
    yield this.addContactsTask.perform();

    //let addedParties = yield this.applySecurityRights.perform();
    //yield this.get('addUsersTask').perform(addedParties);

    if (this.get('context.onComplete')) {
      this.get('context.onComplete')();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @task
  *addContactsTask() {
    let importRows = [];

    for (var a = 0; a < this.selectedItems.length; a++) {
      let item = this.selectedItems[a];
      let phones = [];

      if (item.officePhone) {
        phones.push(item.officePhone);
      }

      if (item.mobilePhone) {
        phones.push(item.mobilePhone);
      }

      let rowData = {
        DealCloudContactId: item.get('id'),
        ContactName: item.get('fullName'),
        Company: item.get('company.name'),
        ContactEmail: item.get('email'),
        Phones: phones.join(','),
      };

      importRows.pushObject(rowData);
    }

    let importAction = yield this.seActions.importPartiesStepOne(
      new Blob([JSON.stringify(importRows)], { type: 'application/json' }),
      this.get('context.project.id')
    );

    yield this.seActions.importPartiesStepTwo(importAction);
  }

  @action
  search() {
    this.searchContactsTask.perform(this.searchName);
  }

  @action
  selectContacts() {
    this.selectContactsTask.perform();
  }

  @action
  process() {
    this.processTask.perform();
  }
}
