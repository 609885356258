import { helper } from '@ember/component/helper';
import moment from 'moment';

export function formatDate([value, format = 'MM/DD/YYYY']) {
  let result = '';
  if (value) {
    format = format || 'MM/DD/YYYY';
    result = moment(value).format(format);

    console.log(`format date called ${value}  ==> ${result}`);
  }

  return result;
}

export default helper(formatDate);
