import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';

import { EventType } from 'cing-app/utils/lookups';

const checkAllDay = function (checkDate) {
  if (checkDate.hours() === 0 && checkDate.minutes() === 0 && checkDate.seconds() === 0) {
    return true;
  }

  return false;
}

export default Component.extend(FilterBuilder, {
  config: service(),
  store: service(),
  docker: service('docker-item'),

  recentStart: null,
  recentEnd: null,
  projectEvents: A(),

  init() {
    this._super(...arguments);
    this.setProperties({ 'defaultView': 'month', 'defaultDate': moment().startOf('month') });
    this.reloadEvents();
    this.registerEventView(this);
  },
  reload() {
    this.reloadEvents(this.recentStart, this.recentEnd);
  },

  reloadEvents(startMoment, endMoment) {
    this.set('isLoading', true);
    this.setProperties({ 'recentStart': startMoment, 'recentEnd': endMoment });

    this.model.hasMany('events').reload().then((data) => {
      let events = data.filterBy('showInCalendar', true).toArray().map(event => {
        return {
          id: event.get('id'),
          title: event.get('title'),
          start: event.get('dateStart'),
          end: event.get('dateEnd'),
          allDay: checkAllDay(moment(event.get('dateStart'))),
          dataModel: event
        };
      });

      events = events.uniqBy('id').sortBy('start');

      this.set('projectEvents', events);

      this.set('isLoading', false);
    })

    /*

    let expr = Expressions.create({operator: ExpressionOperators.AND});

    expr.add(Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.get('model.id')
    }));

    // handle date range
    expr.add(DateRangeFilter.create({
        dateFormat: this.get('config.APP.serverDateFormat'),
        name: 'dateStart',
        value1: startMoment,
        value2: endMoment
    }));

    let query = {
        condition: expr.serialize(),
        include: 'project,type,pacer-events',
        sort: 'dateStart',
        page: {size: 2000},
    };

    this.store.query('event', query).then(data => {
        let chunk = data.toArray().map(event => {
            return {
                id: event.get('id'),
                title: event.get('title'),
                start: event.get('dateStart'),
                end: event.get('dateEnd'),
                allDay: checkAllDay(moment(event.get('dateStart'))),
                dataModel: event
            };
        });
        let events = this.projectEvents.concat(chunk).uniqBy('id').sortBy('start');

    });
    */
  },


  actions: {
    showEventDetail(event, jsEvent, view) {
      this.sendAction('showEvent', event.dataModel);
    },

    viewHasChanged(view, element) {
      /*
      let {recentStart, recentEnd} = this;
      let actualStart = moment.utc(view.start);
      let actualEnd = moment.utc(view.end);
      if (!recentStart || !recentEnd || actualStart.isBefore(recentStart) || actualEnd.isAfter(recentEnd)) {
          //this.reloadEvents(actualStart, actualEnd);
          this.setProperties({'defaultDate': view.dateProfile.date, 'defaultView': view.name});
      }
      */
    }
  }
});
