import classic from 'ember-classic-decorator';
import { assign } from '@ember/polyfills';
import EmberObject, { get, set, action } from '@ember/object';
import { isEqual } from '@ember/utils';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

@classic
class Appearance extends EmberObject {
  width = 1200;
  height = 500;
  header = true;
  footer = true;
  size = 'medium';
}

@classic
export default class DockerItemService extends Service {
  @service store;
  popupOrder = 0;
  popups = [];

  findPopup(componentName, context, appearance) {
    let contentId = null;

    if (appearance && appearance.id) {
      contentId = appearance.id;
    }

    if (contentId) {
      return this.popups.find((item) => {
        return (get(item, 'appearance.id') === contentId);
      });
    } else {
      return this.popups.find((item) => {
        return (get(item, 'component') === componentName && (!context || isEqual(item.get('context'), context)));
      });
    }
  }

  @action
  invokePopup(component: string, appearance: { icon: string, title: string, size?: string }, context: any, headerComponent?: string, footerComponent?: string) {
    let p = this.findPopup(component, context, appearance);
    if (!p) {
      let a = Appearance.create(appearance);
      p = EmberObject.create({
        component: component,
        context: context,
        headerComponent: headerComponent,
        footerComponent: footerComponent,
        appearance: a,
        undocked: true,
        focused: false
      });
      this.get('popups').pushObject(p);
    }
    else {
      p.set('undocked', true);
    }
    this.focusPopup(p);
    return p;
  }

  @action
  focusPopup(popup) {
    if (!popup.get('focused')) {
      const currentlyFocusedPopup = this.get('popups').findBy('focused', true);

      if (currentlyFocusedPopup) {
        currentlyFocusedPopup.set('focused', false);
      }

      this.incrementProperty('popupOrder');

      popup.set('_order', this.get('popupOrder'));

      popup.set('focused', true);
    }
  }

  @action
  removePopup(popup) {
    this.get('popups').removeObject(popup);
    let lastPopup = (this.get('popups').sortBy('_order')).get('lastObject');

    if (lastPopup) {
      this.focusPopup(lastPopup);
    }
  }

  @action
  restorePopup(popup, focusPopup = true) {
    if (!popup.get('undocked')) {
      popup.set('undocked', true);
      if (focusPopup) {
        this.focusPopup(popup);
      }
    }
  }

  @action
  minimizePopup(popup, focusFirstPopupInStack = true) {
    const otherPopups = this.get('popups').filter(p => p !== popup).sortBy('_order');
    if (otherPopups.length > 0) {
      popup.set('_order', otherPopups[0].get('_order') - 1);
      if (focusFirstPopupInStack) {
        const firstItemInStack = otherPopups[otherPopups.length - 1];
        if (firstItemInStack.get('undocked')) {
          this.focusPopup(firstItemInStack);
        }
      }
    }
    popup.setProperties({ undocked: false, focused: false });

  }

  @action
  minimizeAllPopups() {
    this.get('popups').sortBy('_order').reverse().forEach(popup => {
      this.minimizePopup(popup, false);
    });
  }

  @action
  restoreAllPopups() {
    this.get('popups').sortBy('_order').forEach((popup, index, allPopups) => {
      if (index < allPopups.get('length') - 1) {
        this.restorePopup(popup, false);
      }
      else {
        this.restorePopup(popup);
      }
    });
  }

  @action
  closeAllPopups() {
    this.set('popups', []);
  }

  @action
  /* POPUP SHORTCUTS */
  popupPerson(person, company, context = {}) {
    const appearance = {
      icon: 'user',
      title: '<small>Profile:</small> ' + person.get('fullName'),
      size: 'large'
    };

    if (!company) {
      person.get('company').then((company) => {
        assign(context, {
          personId: person.get('id'),
          company: company
        });

        this.invokePopup('contact-detail', appearance, context);
      })
    } else {
      assign(context, {
        personId: person.get('id'),
        company: company
      });

      this.invokePopup('contact-detail', appearance, context);
    }
  }

  @action
  popupEditPerson(person, context = {}) {
    let title = "";

    if (get(person, 'isNew')) {
      title = "<small>Contact: </small>Add New";
    } else {
      title = `<small>Contact:</small> ${get(person, 'fullName')}`;
    }

    // let self = this;
    let appearance = {
      icon: 'edit',
      title: title,
      size: 'large',
      custom: true
    };

    assign(context, {
      person
    });

    this.invokePopup('contact-detail/form', appearance, context);
  }

  @action
  popupCompany(company, context = {}) {
    const appearance = {
      icon: '',
      title: '<small>Company:</small> ' + company.get('name'),
      size: 'medium',
      custom: true
    };

    assign(context, {
      company
    });

    this.invokePopup('company-detail', appearance, context);
  }

  @action
  popupEditCompany(company, context = {}) {
    let title = "";

    if (company.isNew) {
      title = "<small>Company: </small>Add New";
    } else {
      title = `<small>Company:</small> ${company.get('name')}`;
    }

    const appearance = {
      icon: '',
      title: title,
      size: 'large',
      custom: true
    };

    assign(context, {
      company
    });

    this.invokePopup('manage-company', appearance, context);
  }

  @action
  popupImportContactsToCRM(context = {}) {
    let appearance = {
      icon: '',
      title: `Import contacts to CRM`,
      custom: true
    };

    assign(context);

    this.invokePopup('manage-interested-party/import-to-crm', appearance, context);
  }

  openSearchCrmByContact(tabId: 'search_by_contact' | 'search_by_company', searchQuery?: string) {
    let appearance = {
      icon: 'users',
      title: 'CRM',
      custom: true,
      label: 'CRM',
      size: 'large',
    };

    this.invokePopup('crm-search', appearance, {
      tabId,
      searchQuery
    });
  }

  @action
  popupImportContactsToProjectFromCRM(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Import CRM contacts to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-crm-contacts', appearance, context);
  }

  @action
  popupImportContactFundSearch(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Import Contacts to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-sync-contact-fund-search', appearance, context);
  }

  @action
  popupImportContactsToProjectFromDealCloudLPComm(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Add DealCloud LP Communications to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-dc-lp-comm', appearance, context);
  }

  @action
  popupImportInvestorsFromDealCloud(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Add Investors to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-dc-investors', appearance, context);
  }

  @action
  popupImportContactsToProjectFromDealCloud(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Add DealCloud Contacts to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-dc-contacts', appearance, context);
  }

  @action
  popupImportInvestorsFromExcel(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Add Investors to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-xls-investors', appearance, context);
  }

  @action
  popupImportInvestorsContactsFromExcel(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Add Investor Contacts to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-xls-investors-contacts', appearance, context);
  }

  @action
  popupImportContactsFromSmartRoom(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Add Contacts to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-smartroom-contacts', appearance, context);
  }

  @action
  popupImportContactsFromExcel(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Add Contacts to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/import-xls-contacts', appearance, context);
  }

  @action
  popupDealCloudLPCommSync(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Sync DealCloud LP Communications to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/sync-dc-lp-comm', appearance, context);
  }

  @action
  popupDealCloudContactSync(project, context = {}) {
    let appearance = {
      icon: '',
      title: `Sync DealCloud Contacts to ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      project
    });

    this.invokePopup('manage-interested-party/sync-dc-contacts', appearance, context);
  }

  @action
  popupInviteUsers(smartRoomId, project, parties, context = {}) {
    let appearance = {
      icon: '',
      title: `Invite users to ${project.name}`,
      size: 'medium',
      custom: true
    };

    assign(context, {
      smartRoomId,
      project,
      parties
    });

    this.invokePopup('invite-users', appearance, context);
  }

  @action
  popupSendAlert(smartRoomId, project, parties, context = {}) {
    let appearance = {
      icon: '',
      title: `Send alert for ${project.name}`,
      size: 'large',
      custom: true
    };

    assign(context, {
      smartRoomId,
      project,
      parties
    });

    this.invokePopup('send-alert', appearance, context);
  }

  @action
  popupManageAccess(resource, resourceType, resourceName, resourcePermissions, context = {}) {
    let appearance = {
      icon: '',
      title: `Manage access for: ${resourceName}`,
      size: 'medium',
      custom: true
    };

    assign(context, {
      resource,
      resourceType,
      resourceName,
      resourcePermissions,
    });

    this.invokePopup('manage-users/manage-resource-access', appearance, context);
  }

  @action
  popupUserDetail(user, context = {}) {
    console.log("USER: ", user);

    let appearance = {
      icon: '',
      title: `Manage user: ${get(user, 'email')}`,
      size: 'medium',
      custom: true
    };

    assign(context, {
      model: user,
    });


    this.invokePopup('manage-users/users/detail', appearance, context);
  }

}

declare module '@ember/service' {
  interface Registry {
    'docker-item': DockerItemService;
  }
}