import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { BottomWindowsSuccessOptions } from '../components/bottom-windows/success/component';

export default class BottomWindows extends Service {
	@tracked
	windows: BottomWindowComponent[] = [];

	open(window: BottomWindowComponent) {
		this.windows = [...this.windows, window];
	}

	close(window: BottomWindowComponent) {
		this.windows = this.windows.filter(w => w != window);
	}

	success(message: string, options?: BottomWindowsSuccessOptions) {
		let successWindow = new BottomWindowComponent('bottom-windows/success', {
			messageType: 'success',
			message,
			options
		});
		this.open(successWindow);
	}

	danger(message: string, options?: BottomWindowsSuccessOptions) {
		let errorWindow = new BottomWindowComponent('bottom-windows/success', {
			messageType: 'danger',
			message,
			options
		});
		this.open(errorWindow);
	}
}

export class BottomWindowComponent {
	@tracked
	componentName: string;
	@tracked
	context: any;

	constructor(componentName: string, context: any) {
		this.componentName = componentName;
		this.context = context;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	interface Registry {
		'bottom-windows': BottomWindows;
	}
}
