import Component from '@ember/component';
import { action, computed, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import {
  GenericAlertDocumentLinkType,
  ProjectTabMap,
} from 'cing-app/utils/lookups';
import { UserStatus, NotificationTypes } from 'cing-app/pods/smartroom/service';
import { formatCurrency } from 'cing-app/helpers/format-currency';
import { LineBreaks } from 'cing-app/helpers/linebreaks';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

import moment from 'moment';
import classic from 'ember-classic-decorator';
import { FileContainer } from 'smex-ui-sr-filemanager';
import { SmartroomFileModel } from 'smex-ui-sr-models';

@classic
export default class SendAlert extends Component {
  @alias('context.parties') parties;
  @alias('context.project') project;
  @alias('context.onComplete') onComplete;
  @alias('context.smartRoomId') smartRoomId;

  @service store;
  @service smartroom;
  @service config;
  @service abModels;
  confirmDoSendAlert = false;
  showFormValidations = false;
  showAlertConfirmation = false;
  showAlertSuccess = false;
  viewSelectedFiles = false;
  allSmartRoomUsers = null;
  allRightTypes = null;

  GenericAlertDocumentLinkType = GenericAlertDocumentLinkType;

  init() {
    super.init(...arguments);

    this.set(
      'model',
      this.store.createRecord('smartroom/create-generic-alert', {
        documentLinkType: GenericAlertDocumentLinkType.NONE,
      })
    );

    set(this, 'showAlertSuccess', false);
    set(this, 'selectedIndexContent', []);
    set(this, 'selectedSearchContent', []);
    set(this, 'selectedFiles', []);
  }

  @computed('smartRoomId')
  get alertTemplates() {
    return this.store
      .query('smartroom/genericalert', {
        siteId: this.smartRoomId,
      })
      .then((templates) => {
        return templates.filter((template) => {
          return template.siteId !== -1;
        });
      });
  }

  @computed('model.alertType')
  get events() {
    let modelName = this.abModels.getModelName(
      this.get('project'),
      'event-detail'
    );

    return this.store.query(modelName, {
      page: {
        size: 1000,
        number: 1,
      },
    });
  }

  @computed('parties.[]', 'excludedParties.[]')
  get finalParties() {
    console.log('EXCLUDED PARTIES: ', this.excludedParties);
    console.log('ALL PARTIES: ', this.excludedParties);

    if (this.excludedParties && this.excludedParties.length) {
      return this.parties.concat().removeObjects(this.excludedParties);
    } else {
      return this.parties;
    }
  }

  @computed()
  get senders() {
    return this.store.query('smartroom/sender', {
      siteId: this.smartRoomId,
    });
  }

  @computed('model.alertTemplate.body')
  get showMessageField() {
    let templateBody = get(this.model, 'alertTemplate.body');

    if (templateBody && templateBody.indexOf('{{message}}') !== -1) {
      return true;
    }

    return false;
  }

  @computed('model.event')
  get eventTransactions() {
    let modelName = null;

    let investorFundIds = this.parties.mapBy('investor.investorFundId');

    let uniqueInvestorFundIds = investorFundIds.uniq().join(',');

    if (
      this.model.get('event.eventType') === 'Capital Call' ||
      this.model.get('event.eventType') === 'Capital Calls'
    ) {
      let modelName = this.abModels.getModelName(
        this.get('project'),
        'capital-call'
      );

      let query = {
        'filter[event-id]': 'eq:' + this.model.get('event.eventId'),
        'filter[inv-fund-id]': 'in:' + investorFundIds.uniq().join(';'),
        page: {
          size: 1000,
          number: 1,
        },
      };

      return this.store.query(modelName, query).then((results) => {
        return results.toArray();
      });
    }

    if (
      this.model.get('event.eventType') === 'Distribution' ||
      this.model.get('event.eventType') === 'Distributions'
    ) {
      let modelName = this.abModels.getModelName(
        this.get('project'),
        'distribution'
      );

      let query = {
        'filter[event-id]': 'eq:' + this.model.get('event.eventId'),
        'filter[inv-fund-id]': 'in:' + investorFundIds.uniq().join(';'),
        page: {
          size: 1000,
          number: 1,
        },
      };

      return this.store.query(modelName, query).then((results) => {
        return results.toArray();
      });
      //return this.store.query(modelName, query)
    }

    return [];
  }

  @task
  *getSmartRoomUsersTask() {
    try {
      let srUsers = (yield this.store.query('smartroom/user', {
        siteId: this.smartRoomId,
        pageNo: 1,
        pageSize: 1000,
      })).toArray();
      set(this, 'allSmartRoomUsers', srUsers);
    } catch (e) {
      alert(
        'Could not communicate with linked SmartRoom - you may need to be added as a SmartRoom manager or the room does not exist anymore.'
      );
      set(this, 'allSmartRoomUsers', []);
    }
  }

  @task
  *getRightTypesTask(rightId) {
    set(
      this,
      'allRightTypes',
      (yield this.store.query('smartroom/right-type', {
        siteId: this.smartRoomId,
      })).toArray()
    );
  }

  @task({ restartable: true })
  *prepareAlertTask() {
    if (
      !this.model.includeRecipientsWithNoDocument &&
      (this.model.event || this.selectedFiles.length)
    ) {
      set(
        this,
        'excludedParties',
        yield this.getExcludedRecipientsTask.perform()
      );
    } else {
      set(this, 'excludedParties', []);
    }
  }

  @task({ restartable: true })
  *doSendAlertTask() {
    set(this, 'showAlertSuccess', false);

    if (this.smartRoomId) {
      let templateBody = get(this.model, 'alertTemplate.body');

      let recipients = [];
      let links = [];

      this.finalParties.forEach((party) => {
        recipients.push({
          email: party.get('email.emailAddress'),
          name: party.get('person.fullName'),
        });
      });

      let nameOnlyData = {
        placeholderName: '{{name_only_documents}}',
        text: '',
      };

      let templateData = [
        {
          placeholderName: '{{message}}',
          text: LineBreaks(this.model.message, false),
        },
        nameOnlyData,
      ];

      if (this.model.event) {
        let transactions = yield this.eventTransactions;

        transactions.forEach((tr) => {
          let dLink = this.createDocumentLinkFromTransaction(tr);

          if (dLink) {
            links.push(dLink);
          }
        });

        let eventTemplateData = this.generateEventTemplateData(
          this.model.event
        );
        templateData = templateData.concat(eventTemplateData);
      }

      if (this.selectedFiles) {
        this.selectedFiles.forEach((file) => {
          links.push(this.createDocumentLinkFromFile(file));
        });
      }

      /*
      if (links.length) {
        if (this.model.documentLinkType === GenericAlertDocumentLinkType.NAME_ONLY) {
          nameOnlyData.text = links.mapBy('text').join("<br>");
        }
      }
      */

      let projectTemplateData = this.generateProjectTemplateData(this.project);
      templateData = templateData.concat(projectTemplateData);

      if (templateBody) {
        templateBody = templateBody.replace(
          '{{smartroom_links}}',
          '{{smartroom_links}}{{name_only_documents}}'
        );
      }

      yield this.smartroom.sendGenericAlert(
        this.smartRoomId,
        this.model.template,
        this.model.subject,
        this.model.emailFrom,
        this.model.emailFromAlias,
        templateData,
        recipients,
        links,
        templateBody
      );
    }

    this.prepareAlertTask.cancelAll({ resetState: true });
    this.doSendAlertTask.cancelAll({ resetState: true });

    set(this, 'showAlertConfirmation', false);
    set(this, 'showAlertSuccess', true);

    if (this.context && this.context.onComplete) {
      this.context.onComplete();
    }
  }

  @task
  *getExcludedRecipientsTask() {
    // first mark all parties as excluded!
    let excludedParties = this.parties.concat();
    set(this, 'excludedParties', excludedParties);

    // now get all the relevant data we need to figure out who receives a document
    yield this.getSmartRoomUsersTask.perform();
    yield this.getRightTypesTask.perform();

    // get the transactions for the selected event
    let transactions = yield this.eventTransactions;

    let allChecks = [];

    // check recipients on all transactions
    for (var a = 0; a < transactions.length; a++) {
      allChecks.push(
        this.checkFileRecipientsTask.perform(
          transactions[a].fileId,
          excludedParties
        )
      );
    }

    // check recipients on all attached files
    if (this.selectedFiles) {
      for (var a = 0; a < this.selectedFiles.length; a++) {
        allChecks.push(
          this.checkFileRecipientsTask.perform(
            this.selectedFiles[a].id,
            excludedParties
          )
        );
      }
    }

    // wait for all the checks to finish
    let checkResults = yield all(allChecks);

    for (var a = 0; a < checkResults.length; a++) {
      let checkResult = checkResults[a];

      if (checkResult && checkResult.length) {
        for (var b = 0; b < checkResult.length; b++) {
          excludedParties.removeObject(checkResult[b]);
        }
      }
    }
    return excludedParties;
  }

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  *checkFileRecipientsTask(fileId, allExcludedParties) {
    try {
      let excludedParties = allExcludedParties.concat([]);
      let includedParties = [];

      if (fileId) {
        // get all the security rights for the file
        let fileRights = (yield this.smartroom.getRightsForFile(
          this.smartRoomId,
          fileId
        )).rights;

        // loop through all (currently) excluded parties and check if they have access to this document
        for (var a = 0; a < excludedParties.length; a++) {
          // get the associated party
          let party = excludedParties[a];

          // get party's email
          let partyEmail = get(party, 'email.emailAddress');

          if (partyEmail) {
            // find the smartroom user
            partyEmail = partyEmail.toLowerCase();
            let smartRoomUser = this.allSmartRoomUsers.find((item) => {
              return item.email && item.email.toLowerCase() === partyEmail;
            });

            // smartroom user was found, check if the user is active and has rights to the document
            if (smartRoomUser && smartRoomUser.status === UserStatus.ACTIVE) {
              let categoryRights = fileRights.findBy(
                'categoryId',
                smartRoomUser.categoryId
              );

              if (categoryRights) {
                let effectiveRight = this.allRightTypes.findBy(
                  'id',
                  categoryRights.effectiveRight.toString()
                );

                if (effectiveRight.view) {
                  includedParties.pushObject(party);
                  //excludedParties.removeObject(party);
                }
              }
            }
          }
        }
      }

      return includedParties;
    } catch (e) {
      console.error(
        'Could not check recipients for transaction: ',
        transaction
      );
      console.error(e);
    }
  }

  createDocumentLinkFromTransaction(tr) {
    if (tr.fileId && (tr.fileName || tr.description)) {
      if (this.model.documentLinkType === GenericAlertDocumentLinkType.NONE) {
        return {
          text: tr.fileName || tr.description,
          url: `${this.get('config.investorPortalUrl')}/view-project?project=${
            this.project.id
          }&tab=${ProjectTabMap.SMARTROOM}`,
          value: tr.fileId,
          attachmentType: 'SmartRoomFile',
          attached: this.model.attachDocuments,
          visible: false,
        };
      } else if (
        this.model.documentLinkType ===
        GenericAlertDocumentLinkType.LINK_TO_DOCUMENT
      ) {
        return {
          text: tr.fileName || tr.description,
          url: `${this.get('config.investorPortalUrl')}/view-project?project=${
            this.project.id
          }&tab=${ProjectTabMap.SMARTROOM}&documentId=${tr.fileId}`,
          value: tr.fileId,
          attachmentType: 'SmartRoomFile',
          attached: this.model.attachDocuments,
          visible: true,
        };
      } else if (
        this.model.documentLinkType ===
        GenericAlertDocumentLinkType.LINK_TO_INVESTOR_TAB
      ) {
        return {
          text: tr.fileName || tr.description,
          url: `${this.get('config.investorPortalUrl')}/view-project?project=${
            this.project.id
          }&tab=${ProjectTabMap.INVESTMENT_DETAIL}`,
          value: tr.fileId,
          attachmentType: 'SmartRoomFile',
          attached: this.model.attachDocuments,
          visible: true,
        };
      } else if (
        this.model.documentLinkType ===
        GenericAlertDocumentLinkType.LINK_TO_DOCUMENTS_TAB
      ) {
        return {
          text: tr.fileName || tr.description,
          url: `${this.get('config.investorPortalUrl')}/view-project?project=${
            this.project.id
          }&tab=${ProjectTabMap.SMARTROOM}`,
          value: tr.fileId,
          attachmentType: 'SmartRoomFile',
          attached: this.model.attachDocuments,
          visible: true,
        };
      }
    }
  }

  createDocumentLinkFromFile(file) {
    if (this.model.documentLinkType === GenericAlertDocumentLinkType.NONE) {
      return {
        text: file.name,
        url: `${this.get('config.investorPortalUrl')}/view-project?project=${
          this.project.id
        }&tab=${ProjectTabMap.SMARTROOM}`,
        value: file.id,
        attachmentType: 'SmartRoomFile',
        attached: this.model.attachDocuments,
        visible: false,
      };
    } else if (
      this.model.documentLinkType ===
      GenericAlertDocumentLinkType.LINK_TO_DOCUMENT
    ) {
      return {
        text: file.name,
        url: `${this.get('config.investorPortalUrl')}/view-project?project=${
          this.project.id
        }&tab=${ProjectTabMap.SMARTROOM}&documentId=${file.id}`,
        value: file.id,
        attachmentType: 'SmartRoomFile',
        attached: this.model.attachDocuments,
        visible: true,
      };
    } else if (
      this.model.documentLinkType ===
      GenericAlertDocumentLinkType.LINK_TO_INVESTOR_TAB
    ) {
      return {
        text: file.name,
        url: `${this.get('config.investorPortalUrl')}/view-project?project=${
          this.project.id
        }&tab=${ProjectTabMap.INVESTMENT_DETAIL}`,
        value: file.id,
        attachmentType: 'SmartRoomFile',
        attached: this.model.attachDocuments,
        visible: true,
      };
    } else if (
      this.model.documentLinkType ===
      GenericAlertDocumentLinkType.LINK_TO_DOCUMENTS_TAB
    ) {
      return {
        text: file.name,
        url: `${this.get('config.investorPortalUrl')}/view-project?project=${
          this.project.id
        }&tab=${ProjectTabMap.SMARTROOM}`,
        value: file.id,
        attachmentType: 'SmartRoomFile',
        attached: this.model.attachDocuments,
        visible: true,
      };
    }
  }

  @task
  *loadTemplateTask(template) {
    try {
      // load the corresponding alert template to get 'body'
      let alertTemplate = yield this.store.queryRecord(
        'smartroom/alert-template',
        {
          id: template.id,
          siteId: this.smartRoomId,
        }
      );

      this.model.set('subject', alertTemplate.subject);
      this.model.set('alertTemplate', alertTemplate);
    } catch (e) {
      this.model.set('subject', null);
      this.model.set('alertTemplate', null);
    }
  }

  @task
  *generateTemplatePreviewTask() {
    let firstContact = yield this.finalParties.firstObject.get('person');

    let userName = firstContact ? firstContact.get('fullName') : 'John Doe';

    let previewHtml = yield this.smartroom.genericAlertPreview(
      this.smartRoomId,
      this.model,
      userName
    );

    previewHtml = previewHtml.replace(
      '{{message}}',
      LineBreaks(this.model.message, false)
    );
    previewHtml = previewHtml.replace('{{smartroom_links}}', '');
    previewHtml = previewHtml.replace('{{name_only_documents}}', '');
    previewHtml = previewHtml.replace('{{recipient_name}}', userName);

    if (this.model.get('event')) {
      let event = this.model.get('event');

      let eventTemplateData = this.generateEventTemplateData(event);

      for (var a = 0; a < eventTemplateData.length; a++) {
        previewHtml = previewHtml.replace(
          eventTemplateData[a].placeholderName,
          eventTemplateData[a].text
        );
      }
    }

    let projectTemplateData = this.generateProjectTemplateData(this.project);

    for (var a = 0; a < projectTemplateData.length; a++) {
      previewHtml = previewHtml.replace(
        projectTemplateData[a].placeholderName,
        projectTemplateData[a].text
      );
    }

    this.set('templatePreview', previewHtml);
  }

  generateEventTemplateData(event) {
    let templateData = [];
    let keys = Object.keys(event.toJSON());
    let value = null;
    let lProp = '';
    let templateKey = '';

    keys.forEach((prop) => {
      value = event.get(prop);
      templateKey = `{{event.${prop}}}`;

      lProp = prop.toLowerCase();

      if (lProp.indexOf('amount') > -1) {
        value = formatCurrency([value]);
      } else if (lProp.indexOf('date') > -1) {
        value = moment(value).format('MM/DD/YYYY');
      }

      if (typeof value !== 'object') {
        templateData.push({
          placeholderName: templateKey,
          text: value,
        });
      }
    });

    return templateData;
  }

  generateProjectTemplateData(project) {
    let templateData = [];
    let keys = Object.keys(project.toJSON());
    let value = null;
    let lProp = '';
    let templateKey = '';

    keys.forEach((prop) => {
      value = project.get(prop);
      templateKey = `{{project.${prop}}}`;

      lProp = prop.toLowerCase();

      if (lProp.indexOf('amount') > -1) {
        value = formatCurrency([value]);
      } else if (lProp.indexOf('date') > -1) {
        value = moment(value).format('MM/DD/YYYY');
      }

      if (typeof value !== 'object') {
        templateData.push({
          placeholderName: templateKey,
          text: value,
        });
      }
    });

    return templateData;
  }

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  *savePartyTask(party) {
    yield party.save();
  }

  @action
  doSendAlert(record) {
    this.doSendAlertTask.perform(record);
  }

  @action
  previewTemplate() {
    this.set('showInvitePreview', true);
    this.generateTemplatePreviewTask.perform();
  }

  @action
  onSelectTemplate(template) {
    this.loadTemplateTask.perform(template);
    /*
    try {
        this.model.set('templateText', template.textContent.firstObject.templateText);
    } catch (e) {

    }
    */
  }

  @action
  onSelectSender(sender) {
    this.model.set('emailFrom', sender.email);
    this.model.set('emailFromAlias', sender.name);
  }

  @action
  confirmAlert() {
    this.set('confirmDoSendAlert', false);

    this.set('showFormValidations', true);
    this.set('showAlertConfirmation', true);

    if (this.model.get('validations.isValid')) {
      this.prepareAlertTask.perform();

      //this.set('showAlertConfirmation', true);
    } else {
      this.set('showAlertConfirmation', false);
    }
  }

  @action
  onSelectSmartRoomIndexContent(selectedFiles) {
    let fileModels;

    if (selectedFiles.length && selectedFiles[0] instanceof FileContainer) {
      fileModels = selectedFiles.filterBy('isFile', true).mapBy('model');
    } else {
      fileModels = selectedFiles.toArray();
    }

    this.selectedFiles.pushObjects(fileModels);
    this.selectedIndexContent.clear();

    set(this, 'selectedFiles', this.selectedFiles.uniq());
    set(this, 'selectSmartRoomFiles', false);
  }

  @task
  *onSelectSmartRoomSearchContentTask() {
    for (var a = 0; a < this.selectedSearchContent.length; a++) {
      let searchItem = this.selectedSearchContent[a];
      this.selectedFiles.pushObject(yield get(searchItem, 'fileInfo'));
    }

    this.selectedSearchContent.clear();

    set(this, 'selectedFiles', this.selectedFiles.uniq());
    set(this, 'selectSmartRoomFiles', false);
  }

  @action
  onSelectSmartRoomSearchContent() {
    this.onSelectSmartRoomSearchContentTask.perform();
  }

  @action
  clearSelectedFiles() {
    this.selectedFiles.clear();
  }

  @action
  removeFile(file) {
    this.selectedFiles.removeObject(file);
  }

  @action
  close() {
    if (this.onClose) {
      this.prepareAlertTask.cancelAll({ resetState: true });
      this.doSendAlertTask.cancelAll({ resetState: true });

      set(this, 'showAlertConfirmation', false);
      set(this, 'showAlertSuccess', false);

      this.onClose();
    }
  }
}
