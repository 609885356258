import classic from 'ember-classic-decorator';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { task as taskDecorator } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { defineProperty, computed } from '@ember/object';
import moment from 'moment';

class SectionField {
  field = null;
  defaultTitle = null;
  settings = null;
  model = null;
  format = null;

  constructor(field, defaultTitle, settings, model, format) {
    this.field = field;
    this.defaultTitle = defaultTitle;
    this.settings = settings;
    this.model = model;
    this.format = format;

    defineProperty(this, 'view', alias(`settings.${field}.view`));

    defineProperty(
      this,
      'title',
      computed(
        'field',
        'defaultTitle',
        `settings.${field}.title`,
        this.getTitle
      )
    );
  }

  getTitle() {
    return this.settings[this.field].title || this.defaultTitle;
  }

  @taskDecorator
  *getValueTask() {
    let value = yield this.model.get(this.field);

    if (value === null || value === '' || value === undefined) {
      return '-';
    }

    let format = this.format;

    if (format) {
      let [formatType, formatValue] = this.format;

      if (formatType === 'propertyName') {
        return this.model.get(this.field + '.' + formatValue);
      } else if (formatType === 'date') {
        return moment(this.model.get(this.field)).format(
          formatValue || 'MM/DD/YYYY'
        );
      } else if (formatType === 'suffix') {
        return `${value}${formatValue}`;
      } else if (formatType === 'currency') {
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
        });

        return formatter.format(value);
      }
    }

    return this.model.get(this.field);
  }

  @computed('view', 'field', 'format')
  get value() {
    return this.getValueTask.perform();
  }
}

const FundDescriptions = [
  'FF.publicDescription.view',
  'FF.overview.view',
  'FF.strategy.view',
  'FF.exits.view',
];

@classic
export default class Detail extends Component {
  @inject()
  session;

  @alias('projectTab.settings.ProjectFields')
  PF;

  @alias('projectTab.settings.FundFields')
  FF;

  init() {
    super.init(), this.initTask.perform();
  }

  @task(function* () {
    this.set('fund', yield this.project.get('fund'));

    this.set('projectDetailsFields', [
      new SectionField('name', 'Project Name', this.PF, this.project),
      new SectionField('projectType', 'Project Type', this.PF, this.project, [
        'propertyName',
        'name',
      ]),
      new SectionField('company', 'Company', this.PF, this.project, [
        'propertyName',
        'name',
      ]),
      new SectionField(
        'projectManager',
        'Project Manager',
        this.PF,
        this.project,
        ['propertyName', 'fullName']
      ),
      new SectionField('projectLead', 'Project Lead', this.PF, this.project, [
        'propertyName',
        'fullName',
      ]),
      new SectionField(
        'projectStatus',
        'Project Status',
        this.PF,
        this.project,
        ['propertyName', 'name']
      ),
      new SectionField(
        'projectPriority',
        'Project Priority',
        this.PF,
        this.project,
        ['propertyName', 'name']
      ),
      new SectionField('dateStarted', 'Date Started', this.PF, this.project, [
        'date',
      ]),
      new SectionField(
        'dateCompleted',
        'Date Completed',
        this.PF,
        this.project,
        ['date']
      ),
      new SectionField('rating', 'Rating', this.PF, this.project),
      new SectionField(
        'progressPercentage',
        'Progress %',
        this.PF,
        this.project,
        ['percentage']
      ),
      new SectionField('progress', 'Progress', this.PF, this.project),
      new SectionField('sortOrder', 'Sort Order', this.PF, this.project),
    ]);

    this.set('datesAndMilestonesFields', [
      new SectionField('fundStatus', 'Status', this.FF, this.fund, [
        'propertyName',
        'name',
      ]),
      new SectionField('fundLifetime', 'Fund Lifetime', this.FF, this.fund),
      new SectionField(
        'investmentPeriod',
        'Investment Period',
        this.FF,
        this.fund
      ),
      new SectionField('lpaDate', 'LPA Date', this.FF, this.fund, ['date']),
      new SectionField('dateStarted', 'Starting Date', this.FF, this.fund, [
        'date',
      ]),
      new SectionField('closingDate', 'Closing Date', this.FF, this.fund, [
        'date',
      ]),
      new SectionField('vintageYear', 'Vintage Year', this.FF, this.fund),
    ]);

    this.set('fundDetailsFields', [
      new SectionField('fundType', 'Fund Type', this.FF, this.fund, [
        'propertyName',
        'name',
      ]),
      new SectionField('geography', 'Geography', this.FF, this.fund),
      new SectionField('industryFocus', 'Industry Focus', this.FF, this.fund),
      new SectionField('targetSize', 'Target Size', this.FF, this.fund, [
        'currency',
      ]),
      new SectionField(
        'minimumCommitment',
        'Minimum Commitment',
        this.FF,
        this.fund,
        ['currency']
      ),
      new SectionField(
        'projectedCommitment',
        'Projected Commitment',
        this.FF,
        this.fund,
        ['currency']
      ),
      new SectionField(
        'indicatedCommitment',
        'Indicated Commitment',
        this.FF,
        this.fund,
        ['currency']
      ),
      new SectionField('targetIRR', 'Target IRR', this.FF, this.fund),
      new SectionField('targetMOIC', 'Target MOIC', this.FF, this.fund),
      new SectionField('assetClass', 'Asset Class', this.FF, this.fund),
      new SectionField('managementFees', 'Management Fees', this.FF, this.fund),
      new SectionField(
        'carriedInterest',
        'Carried Interest',
        this.FF,
        this.fund
      ),
      new SectionField(
        'preferredReturn',
        'Preferred Return',
        this.FF,
        this.fund
      ),
      new SectionField('customText1', 'Custom Text 1', this.FF, this.fund),
      new SectionField('customText2', 'Custom Text 2', this.FF, this.fund),
      new SectionField('customText3', 'Custom Text 3', this.FF, this.fund),
      new SectionField('customText4', 'Custom Text 4', this.FF, this.fund),
    ]);

    this.set('financialDetailsFields', [
      new SectionField(
        'totalCommitment',
        'Total Commitment',
        this.FF,
        this.fund,
        ['currency']
      ),
      new SectionField(
        'percentageCommitted',
        'Percentage Committed',
        this.FF,
        this.fund,
        ['suffix', '%']
      ),
      new SectionField('capitalCalled', 'Capital Called', this.FF, this.fund, [
        'currency',
      ]),
      new SectionField(
        'investedCapital',
        'Invested Capital',
        this.FF,
        this.fund,
        ['currency']
      ),
      new SectionField('dryPowder', 'Dry Powder', this.FF, this.fund, [
        'currency',
      ]),
      new SectionField('capitalDrawn', 'Capital Drawn', this.FF, this.fund, [
        'currency',
      ]),
    ]);

    this.set('performanceDetailsFields', [
      new SectionField('netIRR', 'Net IRR', this.FF, this.fund),
      new SectionField('grossIRR', 'Gross IRR', this.FF, this.fund),
      new SectionField('netMOIC', 'Net MOIC', this.FF, this.fund),
      new SectionField('grossMOIC', 'Gross MOIC', this.FF, this.fund),
      new SectionField('dpi', 'DPI', this.FF, this.fund),
      new SectionField(
        'amountsAsOfDate',
        'Amounts As Of Date',
        this.FF,
        this.fund,
        ['date']
      ),
    ]);
  })
  initTask;

  @computed('projectDetailsFields.@each.{view,title}')
  get projectDetails() {
    return this.getFieldGroups(
      this.projectDetailsFields.filterBy('view', true),
      2
    );
  }

  @computed('datesAndMilestonesFields.@each.{view,title}')
  get datesAndMilestones() {
    return this.getFieldGroups(
      this.datesAndMilestonesFields.filterBy('view', true),
      2
    );
  }

  @computed('fundDetailsFields.@each.{view,title}')
  get fundDetails() {
    return this.getFieldGroups(
      this.fundDetailsFields.filterBy('view', true),
      2
    );
  }

  @computed('financialDetailsFields.@each.{view,title}')
  get financialDetails() {
    return this.getFieldGroups(
      this.financialDetailsFields.filterBy('view', true),
      1
    );
  }

  @computed('performanceDetailsFields.@each.{view,title}')
  get performanceDetails() {
    return this.getFieldGroups(
      this.performanceDetailsFields.filterBy('view', true),
      1
    );
  }

  getFieldGroups(fields, numberOfGroups) {
    var i,
      j,
      temparray,
      chunk = Math.ceil(fields.length / numberOfGroups);
    let groups = [];

    for (let i = 0, j = fields.length; i < j; i += chunk) {
      groups.push(fields.slice(i, i + chunk));
    }

    return groups;
  }

  @computed(...FundDescriptions)
  get showFundDescriptions() {
    for (var a = 0; a < FundDescriptions.length; a++) {
      if (this.get(FundDescriptions[a])) {
        return true;
      }
    }

    return false;
  }
}
