import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';
import { set } from '@ember/object';

@classic
@classNames('custom-control custom-control-inline custom-radio')
export default class RadioWithLabel extends Component {
  @action
  onChanged(value) {
    set(this, 'groupValue', value);

    if (this.changed) {
      this.changed(value);
    }
  }
}
