import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
  //@ts-ignore
} from 'cing-app/mixins/filter-builder';
import { all } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import Store from '@ember-data/store';
//@ts-ignore
import DockerItemService from 'cing-app/pods/docker-item/service';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking'; //@ts-ignore
import _SessionService from 'cing-app/pods/session/service';
import { AclEntityType, AclPermissionType } from 'cing-app/utils/lookups';
import Company from 'cing-app/models/company';

interface CompanyDetailArgs {
  context: any;
  onClose: any;
}
export default class CompanyDetail extends Component<CompanyDetailArgs> {
  @service store!: Store;
  @service('docker-item') docker!: DockerItemService;
  @service session!: _SessionService;

  @tracked
  company: any;

  @tracked
  contactsCount: any;

  @tracked
  projectsCount: any;

  @tracked
  locations: any;

  @tracked
  onClose = this.args.onClose;

  constructor(owner: any, args: CompanyDetailArgs) {
    super(owner, args);

    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    // company can be passsed as promise, resolve it if needed now
    this.company = await this.args.context.company;

    let allTasks = [];

    allTasks.push(
      this.store.findRecord('company', this.company.id, {
        include:
          'addresses.state,addresses.country,phones,amounts,attributes,company-profile',
        reload: true,
      })
    );

    // get projects count for the company
    let expP = Expressions.create({ operator: ExpressionOperators.AND });
    expP.add(
      Filter.create({
        name: 'interested-parties.company-id',
        operator: FilterOperators.EQUAL,
        value: this.company.id,
      })
    );

    allTasks.push(
      this.store.query('project', {
        condition: expP.serialize(),
        "page['size']": 1,
      })
    );

    let results = await all(allTasks);
    this.company = results[0];
    let projects = results[1];

    this.locations = this.company.addresses;
    this.projectsCount = projects.get('meta.total-count');
  });

  @action
  editAddress(record: any) {
    let self = this;
    let forCompany = this.company;
    let title = record
      ? `Edit Address for Company: ${forCompany.get('name')}`
      : `Add Address for Company: ${forCompany.get('name')}`;
    let appearance = {
      icon: '',
      title: title,
      size: 'sm',
      custom: true,
    };
    let context = {
      address: record,
      company: forCompany,
      closeOnCreate: true,
      onCreate: function (newAddress: any) {
        self.locations.pushObject(newAddress);
      },
    };

    this.docker.invokePopup('manage-address', appearance, context);
  }

  @action
  registerChild(name: string, instance: string) {
    set(this, name, instance);
  }

  @action
  manageAccess(company: Company) {
    this.docker.popupManageAccess(
      company,
      AclEntityType.Company,
      company.name,
      [AclPermissionType.CompanyRead]
    );
  }
}
