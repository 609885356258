import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject } from '@ember/service';
import Component from "@ember/component";
import { task, all } from "ember-concurrency";
import {
  AclPermissionType,
  AclPermissionTypeEnum,
  ImportGenders
} from 'cing-app/utils/lookups';
import {
  Expressions,
  FilterOperators,
  Filter
} from "cing-app/mixins/filter-builder";
import { USStates } from "cing-app/utils/us-states-lookup";

@classic
export default class AbstractImport extends Component {
  selectedItems = null;

  @inject()
  store;

  init() {
    super.init(...arguments);
    this.set('selectedItems', []);
  }

  @task(function* () {
    let investorTypes = yield this.store.query('investor-type', {
      page: {
        size: 1000
      },
      sort: 'name'
    });
    this.set('investorTypes', investorTypes.toArray());

    let interestedPartyRoles = yield this.store.query('interested-party-role', {
      page: {
        size: 1000
      },
      sort: 'name'
    });
    this.set('interestedPartyRoles', interestedPartyRoles.toArray());

    let interestedPartyTypes = yield this.store.query('interested-party-type', {
      page: {
        size: 1000
      },
      sort: 'name'
    });
    this.set('interestedPartyTypes', interestedPartyTypes.toArray());

    let vgQuery = Expressions.create({});
    vgQuery.add(Filter.create({
      name: 'acl-permissions.permission-type-id',
      operator: FilterOperators.EQUAL,
      value: AclPermissionType.ProjectTabRead
    }));

    let viewerGroups = yield this.store.query('acl-role', {
      page: {
        size: 1000
      },
      condition: vgQuery.serialize(),
      sort: 'description'
    });
    this.set('viewerGroups', viewerGroups.toArray());

    if (this.get('context.project') && this.get('context.project.fundId')) {
      let investorQuery = Expressions.create({});
      investorQuery.add(Filter.create({
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: this.get('context.project.fundId')
      }));
      investorQuery.add(Filter.create({
        name: 'company-id',
        operator: FilterOperators.NOT_NULL,
      }));

      let investors = yield this.store.query('investor', {
        page: {
          size: 1000
        },
        include: 'company',
        condition: investorQuery.serialize(),
        sort: 'company.name'
      });
      this.set('investors', investors.toArray());
    }
  })
  initAbstract;

  @computed('context.project')
  get columns() {
    if (this.get('context.project')) {
      let columns = [
        {
          title: "",
          component: "manage-interested-party/abstract-import/column-actions",
          className: 'column-actions',
          selectedItems: this.selectedItems
        },
        {
          component: "api-table/select-row-checkbox",
          useFilter: false,
          mayBeHidden: true,
          title: "Select",
          className: "column-checkbox",
          componentForSortCell: "api-table/select-all-rows-checkbox"
        },
        {
          title: "",
          component: "manage-interested-party/abstract-import/column-match-status",
        },
        {
          propertyName: 'rowData.InvestorName',
          component: "manage-interested-party/abstract-import/column-investor",
          createModelName: 'investor',
          options: this.investors,
          title: "Investor",
        },
        {
          propertyName: 'rowData.Company',
          //component: "manage-interested-party/abstract-import/column-company",
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Company",
        },
        {
          propertyName: 'rowData.ContactName',
          component: "manage-interested-party/abstract-import/column-person",
          title: "Contact Name",
        },
        {
          propertyName: 'rowData.Type',
          component: "manage-interested-party/abstract-import/column-select",
          createModelName: 'interested-party-type',
          options: this.interestedPartyTypes,
          title: "Type",
        },
        {
          propertyName: 'rowData.Role',
          //component: "manage-interested-party/abstract-import/column-editable",
          options: this.interestedPartyRoles,
          component: "manage-interested-party/abstract-import/column-select",
          createModelName: 'interested-party-role',
          title: "Role",
        },
        {
          propertyName: 'rowData.ContactEmail',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Contact Email",
        },
        {
          propertyName: 'rowData.PortalAccess',
          component: "api-table/columns/toggle",
          title: "Portal Access",
        },
        {
          propertyName: 'rowData.ViewerGroup',
          component: "manage-interested-party/abstract-import/column-view-group",
          createModelName: 'acl-role',
          options: this.viewerGroups,
          title: "Access Role",
        },
        {
          propertyName: 'rowData.SRAccess',
          component: "api-table/columns/toggle",
          title: "SR Access",
        },
        {
          propertyName: 'rowData.SRProfile',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "SR Profile",
        },
        {
          propertyName: 'rowData.CapitalCalls',
          component: "api-table/columns/toggle",
          title: "Capital Calls",
        },
        {
          propertyName: 'rowData.Distributions',
          component: "api-table/columns/toggle",
          title: "Distributions",
        },
        {
          propertyName: 'rowData.Tax',
          component: "api-table/columns/toggle",
          title: "Tax",
        },
        {
          propertyName: 'rowData.Financials',
          component: "api-table/columns/toggle",
          title: "Financials",
        },
        {
          propertyName: 'rowData.Legal',
          component: "api-table/columns/toggle",
          title: "Legal",
        },
        {
          propertyName: 'rowData.OtherDocuments',
          component: "api-table/columns/toggle",
          title: "Other Documents",
        },
        {
          propertyName: 'rowData.Communications',
          component: "api-table/columns/toggle",
          title: "Communications",
        },
        {
          propertyName: 'rowData.InvestorContactId',
          title: "Investor Contact ID",
          component: "manage-interested-party/abstract-import/column-editable",
        },
        {
          propertyName: 'rowData.DealCloudContactId',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "DealCloud Contact ID",
        },
        {
          propertyName: 'rowData.DealCloudInvestorId',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "DealCloud Investor ID",
        },
        {
          propertyName: 'rowData.DealCloudFundId',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "DealCloud Fund ID",
        },
        {
          propertyName: 'rowData.InvestorNumber',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Investor Number",
        },
        {
          propertyName: 'rowData.InvestorType',
          component: "manage-interested-party/abstract-import/column-select",
          createModelName: 'investor-type',
          options: this.investorTypes,
          title: "Investor Type",
        },
        {
          propertyName: 'rowData.Commitment',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Commitment",
        },
        /*
        {
            propertyName: 'rowData.Phones',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Phones",
        },
        {
            propertyName: 'rowData.DateOfBirth',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Date Of Birth",
        },
        {
            propertyName: 'rowData.Gender',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Gender",
        },
        {
            propertyName: 'rowData.BIOPage',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "BIO Page",
        },
        {
            propertyName: 'rowData.Note',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Note",
        },
        {
            propertyName: 'rowData.Address1',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Address 1",
        },
        {
            propertyName: 'rowData.Address2',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Address 2",
        },
        {
            propertyName: 'rowData.Address3',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Address 3",
        },            
        {
            propertyName: 'rowData.City',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "City",
        },
        {
            propertyName: 'rowData.Country',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Country",
        },
        {
            propertyName: 'rowData.State',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "State",
        },
        {
            propertyName: 'rowData.Zip',
            component: "manage-interested-party/abstract-import/column-editable",
            title: "Zip",
        },
        */
        /*
        {
            propertyName: 'importStatus',
            title: "Import Status",
            disableSorting: true,
            component: "manage-interested-party/bulk-import/column-import-status",
        },


        */
      ];

      return columns;
    } else {
      let columns = [
        {
          title: "",
          component: "manage-interested-party/abstract-import/column-actions",
          className: 'column-actions',
          selectedItems: this.selectedItems
        },
        {
          component: "api-table/select-row-checkbox",
          useFilter: false,
          mayBeHidden: true,
          title: "Select",
          className: "column-checkbox",
          componentForSortCell: "api-table/select-all-rows-checkbox"
        },
        {
          title: "",
          component: "manage-interested-party/abstract-import/column-match-status",
        },
        {
          propertyName: 'rowData.Company',
          //component: "manage-interested-party/abstract-import/column-company",
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Company",
        },
        {
          propertyName: 'rowData.ContactName',
          component: "manage-interested-party/abstract-import/column-person",
          title: "Contact Name",
        },
        {
          propertyName: 'rowData.ContactEmail',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Contact Email",
        },
        {
          propertyName: 'rowData.Phones',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Phones",
        },
        {
          propertyName: 'rowData.DateOfBirth',
          component: "manage-interested-party/abstract-import/column-date",
          title: "Date Of Birth",
        },
        {
          propertyName: 'rowData.Gender',
          component: "manage-interested-party/abstract-import/column-gender",
          options: ImportGenders,
          title: "Gender",
        },
        {
          propertyName: 'rowData.BIOPage',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "BIO Page",
        },
        {
          propertyName: 'rowData.Note',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Note",
        },
        {
          propertyName: 'rowData.Address1',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Address 1",
        },
        {
          propertyName: 'rowData.Address2',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Address 2",
        },
        {
          propertyName: 'rowData.Address3',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Address 3",
        },
        {
          propertyName: 'rowData.City',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "City",
        },
        {
          propertyName: 'rowData.Country',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Country",
        },
        {
          propertyName: 'rowData.State',
          component: "manage-interested-party/abstract-import/column-state",
          options: USStates,
          title: "State",
        },
        {
          propertyName: 'rowData.Zip',
          component: "manage-interested-party/abstract-import/column-editable",
          title: "Zip",
        },
        /*
        {
            propertyName: 'importStatus',
            title: "Import Status",
            disableSorting: true,
            component: "manage-interested-party/bulk-import/column-import-status",
        },

        {
            title: "",
            component: "manage-interested-party/bulk-import/column-actions",
            className: 'column-actions',
            selectedItems: this.selectedItems
        },
        {
            component: "api-table/select-row-checkbox",
            useFilter: false,
            mayBeHidden: true,
            title: "Select",
            className: "column-checkbox",
            componentForSortCell: "api-table/select-all-rows-checkbox"
        }
        */
      ];

      return columns;
    }
  }
}
