import Component from '@ember/component';
import { computed, set, get, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
} from 'cing-app/pods/smartroom/service';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import company from 'cing-app/models/company';
import classic from 'ember-classic-decorator';

@classic
export default class SearchCRM extends Component {
  @service('docker-item') docker;
  @service store;

  init() {
    super.init();
  }

  @task({
    restartable: true,
  })
  *searchCompaniesTask(term) {
    yield timeout(300);

    if (!term) {
      return null;
    }

    let expr = Expressions.create();

    expr.add(
      Filter.create({
        name: 'name',
        operator: FilterOperators.LIKE,
        value: term,
      })
    );

    expr.add(
      Filter.create({
        name: 'master-id',
        operator: FilterOperators.NULL,
      })
    );

    return yield this.get('store').query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 50, number: 1 },
    });
  }

  @task({
    restartable: true,
  })
  *searchForCompany(companyName) {
    yield timeout(100);

    if (!companyName) {
      return null;
    }

    let expr = Expressions.create();

    expr.add(
      Filter.create({
        name: 'name',
        operator: FilterOperators.EQUAL,
        value: companyName,
      })
    );

    expr.add(
      Filter.create({
        name: 'master-id',
        operator: FilterOperators.NULL,
      })
    );

    return (yield this.get('store').query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 1, number: 1 },
    })).firstObject;
  }

  @task({
    restartable: true,
  })
  *searchContactsTask(term) {
    yield timeout(500);

    if (!term) {
      return null;
    }

    return yield this.get('store').query('smartroom/user', {
      siteId: this.smartRoomId,
      for: 'manageuser',
      searchKey: term ? term : '',
      pageSize: 50,
    });
  }

  @action
  viewPerson(person) {
    this.docker.popupPerson(person);
  }

  @action
  viewCompany(company) {
    this.docker.popupCompany(company);
  }

  @action
  selectPerson(person) {
    this.set('selectedPerson', person);

    if (this.onSelectPerson) {
      this.onSelectPerson(person);
    }

    if (person.companyName) {
      this.searchForCompany.perform(person.companyName).then((company) => {
        if (company) {
          this.selectCompany(company);
        } else {
          this.selectCompany(
            this.store.createRecord('company', {
              name: person.companyName,
            })
          );
        }
      });
    }
  }

  @action
  selectCompany(company) {
    this.set('selectedCompany', company);

    if (this.onSelectCompany) {
      this.onSelectCompany(company);
    }
  }

  @action
  createCompany() {
    // let self = this;
    let appearance = {
      icon: 'edit',
      title: `<small>Company: </small>Add new`,
      size: 'medium',
      custom: true,
    };

    let newCompany = this.get('store').createRecord('company', {});

    let context = {
      company: newCompany,
      closeOnCreate: true,

      onCreate: (company) => {
        this.selectCompany(company);
      },
    };

    this.get('docker').invokePopup('manage-company', appearance, context);
  }

  @action
  showInvestorDetail(investor) {
    if (investor) {
      const appearance = {
        icon: '',
        title: 'Investor detail: ' + investor.get('company.name'),
        custom: true,
      };

      const context = {
        project: this.project,
        investor: investor,
      };
      this.get('docker').invokePopup('investor-detail', appearance, context);
    } else {
      alert('This contact is not associated with an');
    }
  }
}
