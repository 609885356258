export enum EmailPriority {
  Normal = 0,
  High,
  Urgent
}

export default class Email {
  SenderAccount: string;
  Subject: string;
  ToAddressNames: object;
  Body: string;
  IsHtml: boolean;
  Priority: EmailPriority;
  SplitByRecipients: boolean = true;

  FromName?: string;
  ReplyAddress?: string;
  CcAddress?: string[];
  BccAddress?: string[];

  constructor(SenderAccount: string, Subject: string, ToAddressNames: object, Body: string, IsHtml: boolean = false, Priority: number = EmailPriority.Normal,
    FromName?: string, ReplyAddress?: string, CcAddress?: Array<string>, BccAddress?: Array<string>, SplitByRecipients: boolean = true
  ) {
    // required args
    this.SenderAccount = SenderAccount;
    this.Subject = Subject;
    this.ToAddressNames = ToAddressNames;
    this.Body = Body;
    this.IsHtml = IsHtml;
    this.Priority = Priority;
    this.SplitByRecipients = SplitByRecipients;

    // optional args
    this.FromName = FromName;
    this.ReplyAddress = ReplyAddress;
    this.CcAddress = CcAddress;
    this.BccAddress = BccAddress;
  }
}
