import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import { PortalPage } from 'cing-app/utils/lookups';
//@ts-ignore
import { fragment } from 'ember-data-model-fragments/attributes';
import PortalPageSettings from './portal-page-settings';
import AppbuilderLink from './appbuilder-link';

const PortalPageValidations = buildValidations({
  type: validator('presence', true),
});

export default class PortalPageModel extends Abstract.extend(
  PortalPageValidations,
  computedEnumMixin('typeName', 'type', PortalPage)
) {
  @attr('string') declare parentId?: string;
  @attr('string') declare title?: string;
  @attr('boolean') declare enabled?: boolean;
  @attr('number') declare sortOrder?: number;
  @attr('string') declare slug?: string;
  @attr('number') declare type: number;
  @attr('string') declare content?: string;
  @attr('string') declare dataConnectionId?: string;
  @fragment('portal-page-settings', { defaultValue: {} })
  declare settings: PortalPageSettings;
  @belongsTo('appbuilder-link')
  declare dataConnection: AsyncBelongsTo<AppbuilderLink>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'portal-page': PortalPageModel;
  }
}
