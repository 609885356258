import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { Notice } from 'cing-app/utils/lookups';
import Case from './case';
import Company from './company';
import InterestedPartyType from './interested-party-type';
import InterestedPartyRole from './interested-party-role';
import InterestedPartyStatus from './interested-party-status';
import Person from './person';
import Project from './project';
import Email from './email';
import Address from './address';
import Investor from './investor';
import AclRole from './acl-role';
import { exportable } from 'cing-app/utils/exportable';

export default class InterestedParty extends Abstract.extend(
  computedEnumMixin('noticesName', 'notices', Notice)
) {
  @exportable('name', 'project-type.name')
  @belongsTo('project')
  declare project: AsyncBelongsTo<Project>;

  @exportable('full-name', 'deal-cloud-id')
  @belongsTo('person')
  declare person: AsyncBelongsTo<Person>;

  @exportable('email-address', 'user.last-access')
  @belongsTo('email')
  declare email: AsyncBelongsTo<Email>;

  @exportable()
  @belongsTo('interested-party-type')
  declare interestedPartyType: AsyncBelongsTo<InterestedPartyType>;
  @exportable()
  @belongsTo('interested-party-role')
  declare interestedPartyRole: AsyncBelongsTo<InterestedPartyRole>;
  @exportable()
  @belongsTo('interested-party-status')
  declare interestedPartyStatus: AsyncBelongsTo<InterestedPartyStatus>;

  @attr('string') declare partyName?: string;
  @attr('string') declare caseId?: string;
  @attr('string') declare projectId?: string;
  @attr('string') declare companyId?: string;
  @attr('string') declare personId?: string;
  @attr('string') declare emailId?: string;
  @attr('string') declare addressId?: string;
  @attr('number', { defaultValue: 0 }) declare flags?: number;
  @attr('string') declare typeId?: string;
  @attr('string') declare roleId?: string;
  @attr('string') declare roleText?: string;
  @attr('boolean') declare isImportant?: boolean;
  @attr('boolean') declare isCompany?: boolean;
  // counters
  @attr('number') declare representing: number;
  @attr('number') declare representedBy: number;
  @exportable()
  @attr('date')
  declare createTime?: Date;
  //partyRoleId: DS.attr('number'),
  @attr('number') declare enotice?: number;

  @belongsTo('case') declare 'case': AsyncBelongsTo<Case>;
  @exportable('name')
  @belongsTo('company', { inverse: null })
  declare company: AsyncBelongsTo<Company>;
  declare masterCompany: AsyncBelongsTo<Company>;

  @belongsTo('address') declare address: AsyncBelongsTo<Address>;

  @exportable()
  @attr('boolean')
  declare portalAccess?: boolean;
  @attr('string') declare investorId?: string;
  @attr('string') declare investorContactId?: string;
  @attr('string') declare viewerGroupId?: string;
  @exportable()
  @attr('boolean')
  declare srAccess?: boolean;
  @attr('string') declare srProfile?: string;
  @exportable()
  @attr('jsona')
  declare srFolders: any;

  @exportable('investor-number', 'company.name')
  @belongsTo('investor')
  declare investor: AsyncBelongsTo<Investor>;
  @exportable()
  @belongsTo('acl-role')
  declare viewerGroup: AsyncBelongsTo<AclRole>;

  get srFoldersAbbr() {
    let shortItems: any = [];

    if (this.srFolders && this.srFolders.length) {
      this.srFolders.forEach((f) => {
        shortItems.push(f.slice(0, 3));
      });
    }

    return shortItems.join(', ');
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'interested-party': InterestedParty;
  }
}
