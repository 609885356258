import { helper } from '@ember/component/helper';

function getDuration(duration) {
    if(duration<0 || duration === undefined || duration === null){
        duration = 0;
    }

    var days = Math.floor(duration/(1000*60*60*24));
    duration=duration-days*1000*60*60*24;

    var hours = Math.floor(duration/(1000*60*60));
    duration=duration-hours*1000*60*60;

    var minutes = Math.floor(duration/(1000*60));
    duration=duration-minutes*1000*60;

    var seconds = Math.ceil(duration/(1000));

    var result = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };

    if(days){
        result.days = days;
    }
    if(hours){
        result.hours = hours;
    }
    if(minutes){
        result.minutes = minutes;
    }

    if(seconds){
        result.seconds = seconds;
    }

    return result;
}


function formatDuration(duration) {
    var result = '';
    if(duration.days){
        result += duration.days+'d ';
    }
    if(duration.hours){
        result += duration.hours+'h ';
    }
    if(duration.minutes){
        result += duration.minutes+'m ';
    }

    if(duration.seconds || !result){
        result += duration.seconds+'s';
    }

    return result;
}

function addDurations(duration1, duration2){
    let duration = {
        days: duration1.days + duration2.days,
        hours: duration1.hours + duration2.hours,
        minutes: duration1.minutes + duration2.minutes,
        seconds: duration1.seconds + duration2.seconds
    };

    if(duration.seconds > 59){
        let minutes = Math.floor(duration.seconds / 60);
        duration.seconds += minutes * 60;
        duration.minutes += minutes;
    }

    if(duration.minutes > 59){
        let hours = Math.floor(duration.minutes / 60);
        duration.minutes += hours * 60;
        duration.hours += hours;
    }

    if(duration.hours > 23){
        let days = Math.floor(duration.hours / 24);
        duration.hours += days * 24;
        duration.days += days;
    }

    return duration;
}

function formatHelper(params){
    let duration = getDuration(params[0]);
    return formatDuration(duration);
}

export { 
    addDurations,
    getDuration,
    formatDuration
}

export default helper(formatHelper);
