import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@glimmer/component';
import Store from '@ember-data/store';
//@ts-ignore
import DockerItemService from 'cing-app/pods/docker-item/service';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
  CaseFilters,
  CaseFilterKeys
} from "cing-app/mixins/filter-builder";
import { tracked } from '@glimmer/tracking';
import ENV from "../../../../config/environment";

interface CasesArgs {
  company: any;
}

export default class Cases extends Component<CasesArgs> {
  @inject() store!: Store;
  @inject('docker-item') docker!: DockerItemService;
  @inject() config;

  include = '';

  @tracked
  columns: any;

  @tracked
  company: any;

  @tracked
  extraQueryParams: any;

  @tracked
  condition: any;

  constructor(owner: any, args: CasesArgs) {
    super(owner, args);
    this.columns = this.getColumns();
    this.generateFilter();
    this.extraQueryParams = { 'fields': 'number,display-number,name,date-filed,parent-case-id' };
  }

  /*
  let expC = Expressions.create({operator: ExpressionOperators.AND});
  expC.add(Filter.create({
      name: 'interested-party-company-id',
      operator: FilterOperators.EQUAL,
      value: this.get('company.id')
  }));
  let cases = this.get('store').query('case', {
      condition: expC.serialize(),
      include: 'case',
      page: {
          size: 1000,
          number: 1
      }
  });

  this.set('cases', cases);
  */

  getColumns() {
    let columns = [
      {
        propertyName: 'dateFiled',
        component: "api-table/columns/date",
        // componentForSortCell: 'advanced-case-search/headers/date-filed',
        className: "column-date",
        sortDirection: 'desc',
        sortPrecedence: 0, //@ts-ignore
        dateFormat: ENV.APP.shortDateOnlyFormat
      },
      {
        propertyName: 'name',
        className: "column-name",
        component: "api-table/columns/case"
      },
      {
        propertyName: 'displayNumber',
        title: "Case #",
        isHidden: false,
        className: "column-number",
        component: "api-table/columns/case"
      },
    ];

    return columns;
  }

  generateFilter() {
    let expr = Expressions.create();

    expr.add(Filter.create({
      name: "parent-case-id",
      operator: FilterOperators.NULL
    }));

    expr.add(Filter.create({
      name: 'interested-party-company-id',
      operator: FilterOperators.EQUAL,
      value: this.args.company.id,
    }));
    this.condition = expr.serialize();
  }

  @action
  showCaseDetail(record: any) {
    let appearance = {
      icon: '',
      title: `Case: ${record.get('name')} / #${record.get('displayNumber')}`,
      size: 'large'
    };
    this.docker.invokePopup('case-detail', appearance, { recordId: record.get('id') });
  }
}
