import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DockerItemService from 'cing-app/pods/docker-item/service';
import _SessionService from 'cing-app/pods/session/service';
import Store from '@ember-data/store';
import { ProjectFilters } from 'cing-app/utils/lookups';
import { tracked } from '@glimmer/tracking';
import Project from 'cing-app/models/project';

interface FiltersArgs {
  doSearch: (searchText: string) => void;
  addProject: () => void;
  filterText: string;
}

export default class Filters extends Component<FiltersArgs> {
  @service
  store!: Store;

  @service('docker-item')
  docker!: DockerItemService;

  @service
  session!: _SessionService;

  Project = Project;

  expandAdvanced = false;
  expandCourts = false;
  ProjectFilters = ProjectFilters;

  constructor(owner: any, args: FiltersArgs) {
    super(owner, args);
    this.filterText = this.args.filterText;
  }

  @tracked
  filterText = '';

  @action
  submit(e: Event) {
    e.preventDefault();
    if (this.args.doSearch) {
      this.args.doSearch(this.filterText);
    }
    return false;
  }
}
