import Component from '@ember/component';
import { computed, action, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { EReportStatus, ProjectTabMap } from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
} from 'cing-app/pods/smartroom/service';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import TableTheme from '../abstract-import/table-theme';
import contact from 'cing-app/adapters/ab/investors/contact';
import { isEmpty } from '@ember/utils';

const PARTY_MODES = {
  SIMPLE: 'simple',
  BATCH: 'batch',
};

const SEARCH_TYPES = {
  FUND: 'FUND',
  DEAL: 'DEAL',
  ALL: 'ALL',
};

import classic from 'ember-classic-decorator';

@classic
export default class ImportDcLpComm extends Component {
  @service store;
  @service config;
  @service session;
  @service smartroom;
  @service('docker-item') docker;
  @service fileQueue;
  @service seActions;

  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  searchName = null;
  searchEmail = null;
  selectedItems = null;
  selectedContacts = null;
  currentStep = 1;
  interestedParty = null;
  pageSize = 25;
  importAction = null;
  addUsersToSmartRoom = false;
  SmartRoomUserStatus = UserStatus;
  inviteUsersToSmartRoom = false;
  defaultUserStatus = UserStatus.HOLD;
  SEARCH_TYPES = SEARCH_TYPES;
  searchByDealName = null;

  contactFundSearchType = SEARCH_TYPES.FUND;

  @computed
  get themeInstance() {
    return TableTheme.create();
  }

  @alias('context.project') project;

  @alias('context.onComplete') onComplete;

  init() {
    super.init(...arguments);
    this.set('selectedItems', []);
    this.set('selectedContacts', []);
    this.initTask.perform();
  }

  @task
  *initTask() {
    let srConnection = this.context.srConnection;

    if (srConnection) {
      this.setProperties({
        smartRoomId: srConnection.SRSiteId,
        smartRoomFolderId: srConnection.SRFolderId,
        defaultAllAccessFolders: yield srConnection.allAccessSmartRoomFolders,
      });
    }

    try {
      let funds = yield this.store.query('sync-fund', {
        page: {
          size: 100,
        },
      });

      this.set('dealCloudFunds', funds);
    } catch (e) {
      this.contactFundSearchType = SEARCH_TYPES.ALL;
      this.searchTask.perform();
    }

    let projectFund = yield this.get('context.project.fund');
    this.set('fund', projectFund);

    let projectDealCloudFund = get(this.project, 'dealCloudFund');

    // pre-select the fund that's been associated with the project
    if (projectDealCloudFund && get(projectDealCloudFund, 'id')) {
      this.selectFund(
        this.dealCloudFunds.findBy('id', projectDealCloudFund.id)
      );
    }
  }

  @task({
    restartable: true,
  })
  *searchTask() {
    //let filter = {};
    let query = Expressions.create();

    if (
      this.contactFundSearchType === SEARCH_TYPES.FUND ||
      this.contactFundSearchType === SEARCH_TYPES.DEAL
    ) {
      if (this.contactFundSearchType === SEARCH_TYPES.FUND) {
        query.add(
          Filter.create({
            name: 'Fund',
            operator: FilterOperators.EQUAL,
            value: parseInt(this.selectedFund.id, 10),
          })
        );
      } else if (this.contactFundSearchType === SEARCH_TYPES.DEAL) {
        query.add(
          Filter.create({
            name: 'DealName',
            operator: FilterOperators.LIKE,
            value: this.searchByDealName,
          })
        );
      }

      if (this.searchName) {
        query.add(
          Filter.create({
            name: 'Contact.FullName',
            operator: FilterOperators.LIKE,
            value: this.searchName,
          })
        );
        //filter['name'] = 'like:' + this.searchName;
      }

      if (this.searchEmail) {
        query.add(
          Filter.create({
            name: 'Contact.Email',
            operator: FilterOperators.LIKE,
            value: this.searchEmail,
          })
        );

        //filter['email'] = 'like:' + this.searchEmail;
      }

      //this.set('LPCondition', query.serialize());
      //this.set('filter', filter);

      let searchItems = yield this.store.query(
        'sync-contact-fund-search-item',
        {
          condition: query.serialize(),
          include: 'person,company',
          page: {
            size: 1000,
            number: 1,
          },
        }
      );

      this.set('searchItems', searchItems);
    } else {
      if (this.searchName) {
        query.add(
          Filter.create({
            name: 'fullName',
            operator: FilterOperators.LIKE,
            value: this.searchName,
          })
        );
        //filter['name'] = 'like:' + this.searchName;
      }

      if (this.searchEmail) {
        query.add(
          Filter.create({
            name: 'email',
            operator: FilterOperators.LIKE,
            value: this.searchEmail,
          })
        );

        //filter['email'] = 'like:' + this.searchEmail;
      }

      //this.set('LPCondition', query.serialize());
      //this.set('filter', filter);

      let searchItems = yield this.store.query('sync-contact', {
        condition: query.serialize(),
        include: 'company',
        page: {
          size: 1000,
          number: 1,
        },
      });

      this.set('searchItems', searchItems);
    }
  }

  @computed
  get companies() {
    return this.store.findAll('deal-cloud-company');
  }

  @computed
  get columns() {
    let self = this;

    let columns = [
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
      {
        title: 'Name',
        propertyName: 'contact.fullName',
        component: 'api-table/columns/html',
        sortDisabled: true,
        className: 'column-contact',
      },
      {
        title: 'Company',
        propertyName: 'contact.company.name',
        component: 'api-table/columns/html',
        sortDisabled: true,
        className: 'column-investor',
      },
      /*
      {
          title: "Company",
          propertyName: "company",
          component: 'manage-interested-party/import-dc-lp-comm/column-select',
          optionsFrom: 'contact.companyAffiliations',
          sortDisabled: true,
          className: 'column-company'
      },            
      */
      {
        title: 'Email',
        propertyName: 'contact.email',
        sortDisabled: true,
        className: 'column-email',
      },
      {
        title: 'Mobile Phone',
        propertyName: 'contact.mobilePhone',
        sortDisabled: true,
        className: 'column-email',
      },
      /*
      {
          title: "",
          component: "manage-interested-party/abstract-import/column-match-status",
      },
      */
    ];

    return columns;
  }

  @computed
  get contactsColumns() {
    let self = this;

    let columns = [
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
      {
        title: 'Name',
        propertyName: 'fullName',
        component: 'api-table/columns/html',
        sortDisabled: true,
        className: 'column-contact',
      },
      {
        title: 'Company',
        propertyName: 'company.name',
        component: 'api-table/columns/html',
        sortDisabled: true,
        className: 'column-investor',
      },
      {
        title: 'Email',
        propertyName: 'email',
        sortDisabled: true,
        className: 'column-email',
      },
      {
        title: 'Mobile Phone',
        propertyName: 'mobilePhone',
        sortDisabled: true,
        className: 'column-email',
      },
    ];

    return columns;
  }

  @task
  *processTask() {
    yield this.addContactsTask.perform();

    if (this.addUsersToSmartRoom) {
      let addedParties = yield this.getAddedPartiesTask.perform();

      if (!isEmpty(addedParties)) {
        yield this.createSmartRoomSecurityProfilesTask.perform(addedParties);
        yield this.createSmartRoomUsersTask.perform(addedParties);
      }
    }

    //let addedParties = yield this.applySecurityRights.perform();
    //yield this.get('addUsersTask').perform(addedParties);

    if (this.get('context.onComplete')) {
      this.get('context.onComplete')();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @task
  *addContactsTask() {
    let importRows = [];

    let userGroupQuery = Expressions.create();
    userGroupQuery.add(
      Filter.create({
        name: 'instance-acl-role-assignments.acl-role.acl-permissions.permission-type-id',
        operator: FilterOperators.NOT_IN,
        value: AclPermissionType.Admin,
      })
    );

    let userGroup = (yield this.store.query('user-group', {
      condition: userGroupQuery.serialize(),
      page: {
        size: 1,
        number: 1,
      },
    })).firstObject;

    // process special contact-fund-search-items
    for (var a = 0; a < this.selectedItems.length; a++) {
      let item = this.selectedItems[a];
      let rowData = {
        ContactName: item.get('contact.fullName'),
        DealCloudContactId: item.get('contact.id'),
        UserGroup: userGroup ? userGroup.name : null,
        SRAccess: this.smartRoomId ? true : false,
        Company: item.get('contact.company.name'),
        ContactEmail: item.get('contact.email'),
      };

      importRows.pushObject(rowData);
    }

    // process regular contacts items
    for (var a = 0; a < this.selectedContacts.length; a++) {
      let item = this.selectedContacts[a];
      let rowData = {
        ContactName: item.get('fullName'),
        DealCloudContactId: item.get('id'),
        UserGroup: userGroup ? userGroup.name : null,
        SRAccess: this.smartRoomId ? true : false,
        Company: item.get('company.name'),
        ContactEmail: item.get('email'),
      };

      importRows.pushObject(rowData);
    }

    let importAction = yield this.seActions.importPartiesStepOne(
      new Blob([JSON.stringify(importRows)], { type: 'application/json' }),
      this.get('context.project.id')
    );

    this.set('addContactsAction', importAction);

    yield this.seActions.importPartiesStepTwo(importAction);
  }

  @task
  *getAddedPartiesTask() {
    // get list of all added parties
    let emails = this.selectedItems.mapBy('contact.email').compact().uniq();

    emails = emails.concat(
      this.selectedContacts.mapBy('email').compact().uniq()
    );

    if (emails) {
      let addedPartiesQuery = Expressions.create({});
      addedPartiesQuery.add(
        Filter.create({
          name: 'project-id',
          operator: FilterOperators.EQUAL,
          value: this.get('project.id'),
        })
      );
      addedPartiesQuery.add(
        Filter.create({
          name: 'email-id',
          operator: FilterOperators.NOT_NULL,
        })
      );
      addedPartiesQuery.add(
        Filter.create({
          name: 'email.email-address',
          operator: FilterOperators.IN,
          value: emails,
        })
      );

      // get all added parties
      return (yield this.store.query('interested-party', {
        condition: addedPartiesQuery.serialize(),
        include: 'email,investor,person.company',
        page: {
          size: 1000,
          number: 1,
        },
      })).toArray();
    } else {
      return [];
    }
  }

  @task
  *createSmartRoomSecurityProfilesTask(addedParties) {
    let allTasks = [];

    for (var a = 0; a < addedParties.length; a++) {
      allTasks.push(
        this.createSmartRoomSecurityProfileForContact.perform(addedParties[a])
      );
    }

    yield all(allTasks);
  }

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  *createSmartRoomSecurityProfileForContact(party) {
    let emailAddress = (yield party.get('email.emailAddress')).toLowerCase();
    let category = null;
    try {
      category = yield this.smartroom.createCategory(
        this.smartRoomId,
        emailAddress
      );
    } catch (e) {
      // try to match to existing profile since SmartRoom says it already exists
      if (e instanceof CategoryAlreadyExists) {
        let securityProfiles = yield this.store.query('smartroom/category', {
          siteId: this.smartRoomId,
        });

        category = securityProfiles.find((item) => {
          return item.categoryName.toLowerCase() === emailAddress;
        });
      } else {
        throw e;
      }
    }

    // if no category was found, throw an error
    if (!category) {
      throw new Error('Security profile could not be created');
    }

    party.set('srProfile', category.id);
    party.save();

    /*
    let investor = yield party.get('investor');
  
    let investorFolder = yield this.get('store').queryRecord('smartroom/folder', {
      siteId: this.smartRoomId,
      id: investor.smartRoomFolderId
    });
  
    if (investorFolder) {
      let investorSubFolders = (yield this.get('store').query('smartroom/folder', {
        siteId: this.smartRoomId,
        parentId: investorFolder.id
      })).toArray();
  
      // apply security rights for all folders in path
      let folderPathItems = investorFolder.pathIds.split("/");
  
      let folderSecurities = [];
  
      // apply security for investor folder (view, print, save)
      folderSecurities.push({
        itemId: investorFolder.id,
        rightId: 32
      });
  
      // apply security for parent folders (up to SmartRoom root folder)
      folderPathItems.forEach((folderId) => {
        if (folderId) {
          folderSecurities.push({
            itemId: folderId, rightId: 32
          })
        }
      });
  
      // apply security to investor subfolders
      let partyFolders = party.get('srFolders');
  
      if (partyFolders && partyFolders.length) {
        if (partyFolders[0].toLowerCase() === 'all access' || partyFolders[0].toLowerCase() === 'all') {
          for (var a = 0; a < investorSubFolders.length; a++) {
            let iFolder = investorSubFolders[a];
  
            folderSecurities.push({
              itemId: iFolder.id,
              rightId: 5
            });
          }
  
          for (var a = 0; a < this.defaultAllAccessFolders.length; a++) {
            let dFolder = this.defaultAllAccessFolders[a];
  
            folderSecurities.push({
              itemId: dFolder.id,
              rightId: 5
            })
          }
        } else {
          for (var a = 0; a < investorSubFolders.length; a++) {
            let iFolder = investorSubFolders[a];
  
            folderSecurities.push({
              itemId: iFolder.id,
              rightId: partyFolders.includes(iFolder.get('name')) ? 5 : 26
            });
          }
        }
      }
  
      yield this.smartroom.setCategorySecurity(
        this.smartRoomId,
        category.id,
        folderSecurities
      );
    }
    */
  }

  @task
  *createSmartRoomUsersTask(addedParties) {
    let usersData = [];

    for (var a = 0; a < addedParties.length; a++) {
      let party = addedParties[a];
      let emailAddress = (yield party.get('email.emailAddress')).toLowerCase();
      let person = yield party.get('person');

      if (party.srProfile && emailAddress) {
        usersData.push({
          categoryId: party.srProfile,
          email: emailAddress,
          firstName: person ? person.firstName : '',
          lastName: person ? person.lastName : '',
          companyName: get(party, 'company.name'),
        });
      }
    }

    // create the users (by default they will have status 'hold' and invites won't be sent)
    yield this.smartroom.addUsers(
      this.smartRoomId,
      usersData,
      this.defaultUserStatus
    );
  }
  @action
  selectInvestors(investors) {
    this.set('selectedInvestors', investors);
    this.searchTask.perform();
  }

  @action
  search(e) {
    if (e) {
      e.preventDefault();
    }

    this.onSearchTypeChanged();

    this.searchTask.perform();
  }

  @action
  selectContacts() {
    this.selectContactsTask.perform();
  }

  @action
  selectFund(fund) {
    this.set('selectedFund', fund);
    this.searchTask.perform();
  }

  @action
  process() {
    this.processTask.perform();
  }

  @action
  onSearchTypeChanged() {
    set(this, 'searchItems', []);
    set(this, 'selectedItems', []);
    set(this, 'selectedContacts', []);
  }
}
