define("cing-app/classes/folderContainer", ["exports", "smex-ui-sr-filemanager/classes/folderContainer"], function (_exports, _folderContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BaseFolder", {
    enumerable: true,
    get: function () {
      return _folderContainer.BaseFolder;
    }
  });
  Object.defineProperty(_exports, "EmptyFolder", {
    enumerable: true,
    get: function () {
      return _folderContainer.EmptyFolder;
    }
  });
  Object.defineProperty(_exports, "FolderContainer", {
    enumerable: true,
    get: function () {
      return _folderContainer.FolderContainer;
    }
  });
  Object.defineProperty(_exports, "SubFolder", {
    enumerable: true,
    get: function () {
      return _folderContainer.SubFolder;
    }
  });
  Object.defineProperty(_exports, "TopFolder", {
    enumerable: true,
    get: function () {
      return _folderContainer.TopFolder;
    }
  });
});
