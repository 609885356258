import Component from "@glimmer/component";
import { action } from '@ember/object';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import { inject as service } from "@ember/service";
import { all, task } from "ember-concurrency";
import classic from 'ember-classic-decorator';
import DockerItemService from "cing-app/pods/docker-item/service";
import Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import AclRole from "cing-app/models/acl-role";
import { taskFor } from "ember-concurrency-ts";
import UserGroup from "cing-app/models/user-group";

interface GroupsArgs {
  footer: any;
}

@classic
export default class ManageGroups extends Component {
  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  include = 'instance-acl-role-assignments.acl-role.acl-permissions';
  @service store!: Store;
  @service config: any;
  @service('docker-item') docker!: DockerItemService;

  @tracked
  selectedItems: UserGroup[] = [];

  @tracked
  aclRoles!: AclRole[];

  @tracked
  searchTerm = '';

  @tracked
  selectedRoles: AclRole[] = [];

  @tracked
  filter = '';

  @tracked
  reloadData = false;

  @tracked
  confirmRemoval: null | UserGroup = null;

  get columns() {

    let columns = [
      {
        title: 'User Group Name',
        propertyName: 'name',
      },
      {
        propertyName: 'instanceAclRoleAssignments',
        title: 'User Group Roles',
        disableSorting: true,
        component: 'manage-users/groups/column-roles',
      },
      {
        propertyName: 'createTime',
        title: 'Create Time',
        component: 'api-table/columns/date',
        className: "column-datetime",
        dateFormat: 'YYYY/MM/DD hh:mm a'
      },
      {
        title: '',
        component: 'manage-users/groups/column-actions',
        className: "column-actions",
        editGroup: this.editGroup,
        removeGroup: this.removeGroup
      },
    ];

    return columns;
  }

  constructor(owner: any, args: GroupsArgs) {
    super(owner, args);

    //this.store.query('user-to-person', {});

    // set(this, 'columns', this.getColumns());
    this.generateFilter();

    this.store.query('acl-role', {
      //'filter[type]': 'eq:' + AclRoleType.PerEntity,
      page: {
        size: 1000
      },
      sort: 'description'
    }).then((aclRoles) => {
      if (!this.isDestroyed) {
        this.aclRoles = aclRoles.toArray();
      }
    });
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    let searchTerm = this.searchTerm;
    if (searchTerm.length > 0) {
      expr.add(Filter.create({
        //@ts-ignore
        name: 'name',
        operator: FilterOperators.LIKE,
        //@ts-ignore
        value: searchTerm
      }));
    }

    if (this.selectedRoles && this.selectedRoles.length) {
      expr.add(Filter.create({
        //@ts-ignore
        name: 'instance-acl-role-assignments.role-id',
        operator: FilterOperators.IN,
        //@ts-ignore
        value: this.selectedRoles.mapBy('id')
      }))
    }
    this.filter = expr.serialize();
  }

  @task
  removeRecordTask = taskFor(async (party: UserGroup) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      itemsToSave.push(this.removeRecordForPartyTask.perform(items[a]));
    }

    await all(itemsToSave);

    // we need to clear selectedItems upon removing!!!
    this.selectedItems.clear();

    this.confirmRemoval = null;
    this.reloadData = true;
  })

  @task({
    enqueue: true,
    maxConcurrency: 4
  })
  removeRecordForPartyTask = taskFor(async (party) => {
    await party.destroyRecord();
  })

  @action
  removeGroup(record: UserGroup) {
    this.confirmRemoval = record;
  }

  @action
  cancelRemoveGroup() {
    this.confirmRemoval = null;
  }

  @action
  doRemoveGroup(record: UserGroup) {
    this.removeRecordTask.perform(record);
  }

  @action
  editGroup(group: UserGroup) {
    const appearance = {
      label: `Update permissions for ${group.name}`,
      icon: '',
      title: `Update permissions for ${group.name}`,
      custom: true,
      size: 'medium'
    };


    this.docker.invokePopup('manage-users/groups/detail', appearance, {
      model: group,
      onUpdate: () => {
        this.reloadData = true;
      }
    });
  }

  @action
  addGroup() {
    const appearance = {
      label: 'Add User Group',
      icon: '',
      title: '<small>New User Group</small>',
      custom: true,
      size: 'sm'
    };

    this.docker.invokePopup('manage-users/groups/add', appearance, {
      onUpdate: () => {
        this.reloadData = true;
      }
    });
  }

  // @action
  // addRole() {
  //   set(this, 'confirmAddRole', this.store.createRecord('acl-role', {}));
  // }

  @action
  search(e: Event) {
    e.preventDefault();
    this.generateFilter();
  }

  @action
  searchByRole(selectedRoles: AclRole[]) {
    this.selectedRoles = selectedRoles;
    this.generateFilter();
  }
}