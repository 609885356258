import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment';
import { action } from '@ember/object';

//@ts-ignore
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AppEventsService from 'cing-app/pods/app-events/service';
import SessionService from 'cing-app/pods/session/service';
import {
	Expressions,
	ExpressionOperators,
	Filter,
	FilterOperators,
} from 'cing-app/mixins/filter-builder';
//@ts-ignore
import podNames from "ember-component-css/pod-names";
import { dasherize } from "@ember/string";
import DataLink from 'cing-app/models/data-link';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface ManageDataLinksArgs {
	context?: {
		dataLinkId: number;
	};
	onClose?: () => void;
	header?: any;
	footer?: any;
}

export default class DataLinkDetail extends Component<ManageDataLinksArgs> {
	@service
	config: any;

	@service
	store!: Store;

	@service
	cookies: any;

	@service
	appEvents!: AppEventsService;

	@service('docker-item')
	docker!: DockerItemService;

	@service
	session!: SessionService;

	@alias('args.context.model') model!: DataLink;

	get styleNamespace() {
		return podNames["manage-data-links/detail"];
	}

	constructor(owner: any, args: ManageDataLinksArgs) {
		super(owner, args);
	}


	@task
	updateTask = taskFor(async (model: DataLink): Promise<DataLink> => {
		await model.save();

		return model;
		/*
		try {
			let url = `/sites/${this.globals.currentSite}/redact/search`;
			let result = await this.ajax.post(url, {
				data: {
					fileIds: options.items.filter((i: fileId) => i.fileId).map((f: fileId) => parseInt(f.fileId)) ?? [],
					folderIds: options.items.filter((i: folderId) => i.folderId).map((f: folderId) => parseInt(f.folderId)) ?? [],
					words: options.words,
					patternIds: options.patterns.map(p => p.value) ?? [],
					pageNo: pageIndex + 1,
					pageSize: pageSize,
					sortBy: columns.find(c => c.sort && c.sortingEnabled)?.options?.sortBy,
					sortDesc: columns.find(c => c.sort && c.sortingEnabled)?.sort === 'desc'
				}
			});
			// let fileIds: { fileId: string }[] = result.items.map((item: any) => ({ fileId: item.itemId }));

			let files = result.items.map((r: any) => {
				if (r.fileInfo) {
					let normalized = this.store.normalize('file', r.fileInfo);
					let file = <File><unknown>this.store.push(normalized);
					return <Row>{ item: file, words: r.fileInfo.redactedWords, attachments: r.fileVersionAttachments };
				}
				return undefined;
			}).filter((f: any) => f);

			// let files = await all(fileIds.map(fid => loadFileFolder(fid, options.store)));
			files.meta = {
				totalCount: result.paging.totalCount
			}

			return files;
		}
		catch (err) {
			console.error(err);
			return [];
		}
		*/
	});

	@action
	update(model: DataLink, validations?: object) {
		this.updateTask.perform(model);

	}
}
