import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { CDPCaseTypeEnum } from 'cing-app/utils/lookups';
import Abstract from './abstract';
import Case from './case';
import DebtorProfile from './debtor-profile';

export default class CdpRelatedCase extends Abstract.extend(
  computedEnumMixin('caseType', 'cdpRelatedCaseType', CDPCaseTypeEnum)
) {
  @attr('string') declare caseId?: string;
  @attr('string') declare companyProfileId: string;
  @attr('string') declare debtorId?: string;
  @attr('string') declare caseNumber?: string;
  @attr('string') declare relationship?: string;
  @attr('string') declare relatedDate?: string;
  @attr('number') declare district?: number;
  @attr('number') declare cdpRelatedCaseType?: number;

  //@ts-ignore - mixin
  @belongsTo('case') declare case: AsyncBelongsTo<Case>;
  //@ts-ignore - mixin
  @belongsTo('debtorProfile')
  declare debtorProfile: AsyncBelongsTo<DebtorProfile>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cdp-related-case': CdpRelatedCase;
  }
}
