import Component from '@glimmer/component';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

export interface BottomWindowsSuccessOptions {
	autoClear?: boolean;
}

interface BottomWindowsSuccessArgs {
	context: {
		options: BottomWindowsSuccessOptions,
		messageType: 'success' | 'danger';
		message: string;
	}
	onClose: () => void;
}

export default class BottomWindowsSuccess extends Component<BottomWindowsSuccessArgs> {
	get autoClear() {
		return this.args.context.options?.autoClear ?? true;
	}

	constructor(owner: unknown, args: BottomWindowsSuccessArgs) {
		super(owner, args);

		if (this.autoClear) {
			this.scheduleClose.perform();
		}
	}

	@task
	scheduleClose = taskFor(async () => {
		await timeout(5000);
		this.args.onClose();
	});
}
