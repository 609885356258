import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import AppBuilder, { ABModelMeta } from 'cing-app/pods/appbuilder/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { ILayout, ILayoutProperty } from '../layout-viewer/component';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { set } from '@ember/object';

interface LayoutEditorArgs {
	header: string;
	footer: string;
	onClose: () => void;
	context: {
		object: any;
		ABLink: AppbuilderLink;
	}
}

export default class LayoutEditor extends Component<LayoutEditorArgs> {
	//@ts-ignore
	@service('config')
	config: any;
	@service('store')
	store!: Store;
	@service('appbuilder')
	appbuilder!: AppBuilder;
	@tracked
	modelMeta?: ABModelMeta;
	@tracked
	layout: ILayout;
	@tracked
	editor: any;

	@tracked
	options: any;

	constructor(owner: unknown, args: LayoutEditorArgs) {
		super(owner, args);
		this.layout = (this.args.context.ABLink.views || {})[this.args.context.object.objectName] ?? { properties: [], template: '', style: '' };
		let o = Object.assign({ content_css: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }, this.config.get('editorOptions'));
		o.height = '100%';
		o.content_style = this.layout.style;
		this.options = o;
		this.loadData.perform();
	}

	willDestroy() {
		this.args.context.ABLink.rollbackAttributes();
		super.willDestroy();
	}

	@action
	setStyle(style: string) {
		set(this.layout, 'style', style);
		this.options.content_style = style;
	}

	@action
	editorCreated(editor: any) {
		this.editor = editor;

		this.editor.contentWindow.mceStoreSelect = this.storeSelect;
		this.editor.contentWindow.mceStoreInput = this.storeInput;
	}


	storeInput(input: HTMLInputElement) {
		input.setAttribute('data-value', input.value);
	}

	storeSelect(select: HTMLSelectElement) {
		for (let o of select.options) {
			if (o.value === select.value) {
				o.setAttribute('selected', 'selected');
			} else {
				o.removeAttribute('selected');
			}
		}
	}

	@action
	insertProp(propName: string) {
		let content = `{{model.${propName}}}`;
		this.editor.execCommand('mceInsertContent', false, content);
	}

	@task
	loadData = taskFor(async () => {
		// let abCaseId = this.args.context.projectTab.settings.ABCaseId;
		// let abCase = await this.store.findRecord('appbuilder/case', abCaseId, { include: 'form-excel-sheets,form.form-elements.search-view-layout,form.form-elements.form-element-format,form.form-elements.answer-options', reload: true });

		try {
			// let abCase = await this.args.context.ABLink.ABCase;
			this.modelMeta = this.appbuilder.getModelMeta(this.args.context.ABLink, this.args.context.object.objectName);
		}
		catch (e) {
			console.log("ERROR: ", e);
		}
	})

	@task
	removeLayout = taskFor(async () => {
		//@ts-ignore
		delete this.args.context.ABLink.views[this.args.context.object.objectName];
		this.args.context.ABLink.notifyPropertyChange('views');
		await this.args.context.ABLink.save();
		this.args.onClose();
	});


	@task
	saveLayout = taskFor(async () => {
		await this.saveLayoutDefinitionToProjectTab.perform()
		this.args.onClose();
	});

	@task
	saveLayoutDefinitionToProjectTab = taskFor(async () => {
		if (this.args.context.ABLink.views) {
			//@ts-ignore
			this.args.context.ABLink.views[this.args.context.object.objectName] = this.layout;
			this.args.context.ABLink.notifyPropertyChange('views');
		} else {
			if (this.layout) {
				this.args.context.ABLink.views = {
					[this.args.context.object.objectName]: this.layout
				};
			}
		}

		await this.args.context.ABLink.save();
	});

	@action
	addProperty(prop: ILayoutProperty) {
		if (this.layout) {
			this.layout.properties.pushObject(prop);
		}
	}

	@action
	removeProperty(prop: ILayoutProperty) {
		if (this.layout) {
			this.layout.properties.removeObject(prop);
		}
	}

	// 	@action
	// 	insertSimpleCondition() {
	// 		let content = `
	// 		<div class="card mceNonEditable d-inline-block">
	// 	<div class="card-body">
	// 		<h5 class="card-title">
	// 			Condition
	// 		</h5>
	// 		<span class='mb-3 d-flex flex-row align-items-center'>
	// 			<select class='mceEditable form-control flex-grow-0' name="" id="" onchange="mceStoreSelect(this);">
	// 				<option value='0' selected='selected'>Prop1</option>
	// 				<option value="1">Prop2</option>
	// 				<option value="2">Prop3</option>
	// 			</select>
	// 			<select class='mceEditable form-control flex-grow-0' name="" id="" onchange="mceStoreSelect(this);">
	// 				<option value='=' selected='selected'>=</option>
	// 				<option value='!='>!=</option>
	// 				<option value="&gt;">&gt;</option>
	// 				<option value="&lt;">&lt;</option>
	// 			</select>
	// 			<input class='mceEditable form-control flex-grow-1' oninput="mceStoreInput(this);" type='text' />
	// 		</span>

	// 		True
	// 		<div class="border mb-3 mceEditable"></div>
	// 		False
	// 		<div class="border mceEditable"></div>
	// 	</div>
	// </div>
	// 		`;
	// 		this.editor.execCommand('mceInsertContent', false, content);
	// 	}
}
