import classic from 'ember-classic-decorator';
import ApplicationSerializer from './application';

@classic
export default class ProjectSerializer extends ApplicationSerializer {
  attrs = {
    dataConnections: {
      serialize: false,
    },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    project: ProjectSerializer;
  }
}
