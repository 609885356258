import Model, { attr } from '@ember-data/model';

export default Model.extend({
  invFundId: attr('string'),
  preferredPaymentMethod: attr('string'),
  paymentType: attr('string'),
  accountNumber: attr('string'),
  accountType: attr('string'),
  'routing/abaNumber': attr('string'),
  financialInstitution: attr('string'),
  financialInstitutionAddress: attr('string'),
  beneficiaryName: attr('string'),
  beneficiaryAddress: attr('string'),
  furtherCredit: attr('string'),
  fundingNote: attr('string'),
  swiftCode: attr('string'),
});

