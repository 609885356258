import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
export default class Attorney extends Component {
  @service('docker-item')
  docker;

  @action
  showAttorneyDetail(attorney, lawFirm) {
    const appearance = { label: 'Attorney detail', icon: 'user', size: 'large', title: '<small>Profile:</small> ' + attorney.get('fullName') };
    const context = { attorneyId: attorney.get('id'), lawFirmId: lawFirm.get('id') };
    this.get('docker').invokePopup('contact-detail', appearance, context);
  }
}
