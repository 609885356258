import { attr, belongsTo, AsyncBelongsTo } from '@ember-data/model';
import Abstract from './abstract';
import { validator, buildValidations } from 'ember-cp-validations';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { PhoneTypesEnum } from 'cing-app/utils/lookups';
import Person from 'cing-app/models/person';
import Company from 'cing-app/models/company';

const modelValidations = buildValidations({
  number: [validator('presence', true)],
});

export default class Phone extends Abstract.extend(
  modelValidations,
  computedEnumMixin('type', 'typeId', PhoneTypesEnum)
) {
  @attr('string') declare number: string;
  @attr('string') declare companyId?: string;
  @attr('string') declare addressId?: string;
  @attr('string') declare personId?: string;
  @attr('number') declare typeId?: number;

  @belongsTo('person') declare person: AsyncBelongsTo<Person>;
  @belongsTo('company') declare company: AsyncBelongsTo<Company>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    phone: Phone;
  }
}
