import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import _SessionService from 'cing-app/pods/session/service';
import { action } from '@ember/object';
import { all } from 'ember-concurrency';
import { PersonInCompanyFlags } from 'cing-app/utils/lookups';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from "cing-app/mixins/filter-builder";
import { observer } from "@ember/object";
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { async } from 'rsvp';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';

const SHOW_AS_OPTION_1 = 1;
const SHOW_AS_OPTION_2 = 2;
const SHOW_AS_OPTION_3 = 3;

const SHOW_AS_OPTIONS = [
  {
    value: SHOW_AS_OPTION_1,
    name: "Last, First Middle",
  },
  {
    value: SHOW_AS_OPTION_2,
    name: "First Middle Last",
  },
  {
    value: SHOW_AS_OPTION_3,
    name: "First Last",
  },
];

interface ContactsArgs {
  company: any;
  register: any;
}

export default class Contacts extends Component<ContactsArgs> {
  @service store!: Store;

  @service('docker-item') docker!: DockerItemService;

  @service session!: _SessionService;

  includes = "person,address,person.emails";

  @tracked
  showAsOption: any = null;

  @tracked
  collapsed: boolean = true;

  @tracked
  selectedCompanyTypes: any = null;

  @tracked
  confirmRemoval: any = null

  @tracked
  searchQuery: any = "";

  @tracked
  selectedPeople: any[];

  @tracked
  columns: object[];

  @tracked
  filter: any;

  @tracked
  contactsCount: any

  @tracked
  options: any;

  @tracked
  reloadData: boolean = false;

  constructor(owner: any, args: ContactsArgs) {
    super(owner, args);

    this.showAsOption = SHOW_AS_OPTIONS[0];
    this.options = SHOW_AS_OPTIONS;
    this.columns = this.getColumns();
    this.selectedPeople = [];
    this.generateFilter();
    this.initTask.perform();
  }

  get styleNamespace() {
    return podNames["company-detail/contacts"];
  }

  @task
  // get all attorneys in the law firm
  initTask = taskFor(async () => {
    let allTasks = [];

    let exprC = Expressions.create({ operator: ExpressionOperators.AND });

    let exprCompany = Expressions.create({ operator: ExpressionOperators.OR });

    exprCompany.add(
      Filter.create({
        name: "company-id",
        operator: FilterOperators.EQUAL,
        value: this.args.company.id,
      })
    );
    exprCompany.add(
      Filter.create({
        name: "company.master-id",
        operator: FilterOperators.EQUAL,
        value: this.args.company.id,
      })
    );

    exprC.add(exprCompany);

    exprC.add(
      Filter.create({
        name: "flags",
        operator: FilterOperators.NOT_MASK,
        value: PersonInCompanyFlags.FORMER
      })
    );

    allTasks.push(this.store.query('person-in-company', {
      condition: exprC.serialize(),
      page: {
        size: 1
      }
    }));

    let results = await all(allTasks);
    let contacts = results[0];

    if (contacts) {
      this.contactsCount = contacts.get('meta.total-count');
    }
  })

  getColumns() {
    let columns: any = [
      {
        propertyName: "person.firstName",
        sortedBy: "person.last-name",
        title: "Name",
        sortPrecedence: 0,
        component: "company-detail/contacts/column-name",
        className: "column-first-name",
        openDetail: this.showPersonDetail,
      },
      {
        propertyName: "person.emails",
        title: "Emails",
        className: "column-emails",
        component: "company-detail/contacts/column-emails",
        sortedBy: "person.emails.email-address",
      },
    ];

    if (this.session.hasAdminRole) {
      columns.push({
        title: "",
        component: "company-detail/contacts/column-actions",
        className: "column-actions",
        editContact: this.editContact,
        removeRecord: this.removeRecord,
      });

      columns.push({
        component: "api-table/select-row-checkbox",
        useFilter: false,
        title: "Select",
        className: "column-checkbox",
        componentForSortCell: "api-table/select-all-rows-checkbox",
      });
    };

    return columns;
  }

  generateFilter() {

    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    let exprCompany = Expressions.create({ operator: ExpressionOperators.OR });

    exprCompany.add(
      Filter.create({
        name: "company-id",
        operator: FilterOperators.EQUAL,
        value: this.args.company.id,
      })
    );
    exprCompany.add(
      Filter.create({
        name: "company.master-id",
        operator: FilterOperators.EQUAL,
        value: this.args.company.id,
      })
    );

    expr.add(exprCompany);

    expr.add(
      Filter.create({
        name: "flags",
        operator: FilterOperators.NOT_MASK,
        value: PersonInCompanyFlags.FORMER
      })
    );

    const searchQuery = this.searchQuery;

    if (searchQuery) {
      let termParts = searchQuery.split(" ");

      let exprSearch = Expressions.create({
        operator: ExpressionOperators.OR,
      });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            name: "person.first-name",
            operator: FilterOperators.LIKE,
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            name: "person.last-name",
            operator: FilterOperators.LIKE,
            value: termParts[termParts.length - 1],
          })
        );

        exprSearch.add(nameExpression);
      }

      ["person.first-name", "person.last-name", "person.full-name", "address.city"].forEach(
        (propName) => {
          exprSearch.add(
            Filter.create({
              name: propName,
              operator: FilterOperators.LIKE,
              value: searchQuery,
            })
          );
        }
      );

      expr.add(exprSearch);
    }
    this.filter = expr.serialize();
  }


  @action
  toggle() {
    this.collapsed = !this.collapsed;
  }

  @action
  search(e) {
    this.generateFilter();

    if (e && e.preventDefault) {
      e.preventDefault();
    }
  }

  @action
  showPersonDetail(personInCompany: any) {
    let person = personInCompany.get("person");

    const appearance = {
      label: "Contact detail",
      icon: "user",
      title: "<small>Profile:</small> " + person.get("fullName"),
      size: "medium",
    };
    const context = {
      personId: person.get("id"),
      company: this.args.company,
    };
    this.docker.invokePopup(
      "contact-detail",
      appearance,
      context
    );
  }

  @action
  mergePeople() {
    let appearance = {
      icon: "user",
      title: "Merge people",
      size: "large",
      custom: true,
    };

    let self = this;

    this.docker.invokePopup("people-merge", appearance, {
      people: this.selectedPeople,
      onComplete: function () {
        this.selectedPeople.clear();
        this.reloadData = true;
      },
    });
  }

  @action
  removeRecord(record: any) {
    this.confirmRemoval = record;
  }

  @action
  doRemoveRecord(record: any) {
    record.destroyRecord().then((response) => {
      this.confirmRemoval = null;
      this.initTask.perform();
      this.search();
    });
  }

  @action
  editContact(record: any) {
    const self = this;
    let forCompany = this.args.company;

    if (record === null) {
      record = this.store.createRecord('person');
    }

    this.docker.popupEditPerson(record, {
      company: forCompany,
      onCreate: function (newContact) {
        self.reloadData = true;
        self.initTask.perform();
      }
    });
  }
}
