import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

import Person from 'cing-app/models/person';
import User from 'cing-app/models/user';
import { exportable } from 'cing-app/utils/exportable';

const modelValidations = buildValidations({
  emailAddress: [
    validator('presence', true),
    validator('format', { type: 'email' }),
  ],
});

export default class Email extends Abstract.extend(modelValidations) {
  @attr('number', { defaultValue: 0 }) declare emailType: number;
  @exportable()
  @attr('string')
  declare emailAddress: string;
  @attr('date') declare lastSeen?: Date;
  @attr('string') declare personId?: string;
  @attr('string') declare companyId?: string;
  @attr('string') declare addressId?: string;
  @belongsTo('person') declare person: AsyncBelongsTo<Person>;
  @exportable()
  @belongsTo('user')
  declare user: AsyncBelongsTo<User>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    email: Email;
  }
}
