import SmartroomAdapter from './smartroom';

import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class RightType extends SmartroomAdapter {
  pathForType(modelName) {
    return 'righttypes';
  }
}
