import JSONAPISerializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class EnquirySerializer extends JSONAPISerializer {
  attrs = {
    fromName: { serialize: false },
    message: { serialize: false },
    phone: { serialize: false },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    enquiry: EnquirySerializer;
  }
}
