export default class SplitView {
  resizeTimer: any;

  constructor(container: any) {
    // We want two divs that we're dividing
    var splitter = container.querySelector('.splitter');
    var leftSide = container.querySelector('.leftSide');
    var rightSide = container.querySelector('.rightSide');

    if (!splitter) {
      splitter = document.createElement('div');
      splitter.classList.add('splitter');
      container.appendChild(splitter);

      splitter.style.width = '10px';
      splitter.style.left =
        splitter.parentElement.offsetWidth / 2 -
        splitter.offsetWidth / 2 +
        'px';

      //splitter.style.background = 'black';
    }

    splitter.style.left = container.offsetWidth / 2 + 'px';

    if (!leftSide) {
      leftSide = document.createElement('div');
      leftSide.classList.add('leftSide');
      container.appendChild(leftSide);
    }

    leftSide.style.left = 0;
    leftSide.style.top = 0;
    leftSide.style.width = splitter.style.left;

    if (!rightSide) {
      rightSide = document.createElement('div');
      rightSide.classList.add('rightSide');
      container.appendChild(rightSide);
    }

    rightSide.style.left = splitter.offsetLeft + 10 + 'px';
    rightSide.style.top = 0;

    rightSide.style.width =
      'calc(100% - ' + (splitter.offsetLeft + splitter.offsetWidth) + 'px)';
    // rightSide.style.width =
    //   container.offsetWidth - splitter.offsetLeft - 10 + 'px';

    if (container.offsetHeight == 0) {
      container.style.minHeight = container.parentElement.offsetHeight + 'px';
    }

    let mouseIsDown = false;
    let startX: any = null;
    var startY: any = null;
    let globalXCoordinate: any = null;

    // Will not touch
    splitter.addEventListener('mousedown', function (evt: any) {
      evt.preventDefault();
      mouseIsDown = true;
      startX = evt.offsetX;
      startY = evt.offsetY;
    });

    leftSide.addEventListener('mousemove', function (evt: any) {
      evt.preventDefault();
      let left = this.offsetLeft;
      globalXCoordinate = left + evt.offsetX - startX;
    });

    rightSide.addEventListener('mousemove', function (evt: any) {
      evt.preventDefault();
      let left = this.offsetLeft;
      globalXCoordinate = left + evt.offsetX - startX;
    });

    splitter.addEventListener('mousemove', function (evt: any) {
      evt.preventDefault();
      let left = this.offsetLeft;
      globalXCoordinate = left + evt.offsetX - startX;
    });

    document.body.addEventListener('mouseup', function (evt: any) {
      mouseIsDown = false;
    });

    document.addEventListener('mouseup', function (evt: any) {
      mouseIsDown = false;
    });

    document.addEventListener('mousemove', function (evt: any) {
      evt.preventDefault();
      evt.stopPropagation();

      let containerWidth = container.getBoundingClientRect().width;
      let hoveringOnDocument =
        evt.target.nodeName == 'HTML' || evt.target.nodeName == 'BODY';
      let docX = evt.offsetX - container.getBoundingClientRect().x - startX;
      if (mouseIsDown) {
        // When dragging what do we need to do to take care of inner splitter areas?

        if (hoveringOnDocument) {
          if (docX < 0) {
            docX = 0;
          }

          if (docX + splitter.offsetWidth > container.offsetWidth) {
            docX = containerWidth - splitter.offsetWidth;
          }

          splitter.style.left = docX + 'px';
          leftSide.style.width = splitter.offsetLeft + 'px';
          // rightSide.style.width =
          //   container.offsetWidth -
          //   leftSide.offsetWidth -
          //   splitter.offsetWidth +
          //   'px';
          rightSide.style.width =
            'calc(100% - ' +
            (splitter.offsetLeft + splitter.offsetWidth) +
            'px)';

          rightSide.style.left =
            splitter.offsetLeft + splitter.offsetWidth + 'px';
        } else {
          if (globalXCoordinate + splitter.offsetWidth > containerWidth) {
            globalXCoordinate = containerWidth - splitter.offsetWidth;
          }

          if (globalXCoordinate < 0) {
            globalXCoordinate = 0;
          }

          splitter.style.left = globalXCoordinate + 'px';
          leftSide.style.width = splitter.offsetLeft + 'px';
          // rightSide.style.width =
          //   container.offsetWidth -
          //   leftSide.offsetWidth -
          //   splitter.offsetWidth +
          //   'px';
          rightSide.style.width =
            'calc(100% - ' +
            (splitter.offsetLeft + splitter.offsetWidth) +
            'px)';
          rightSide.style.left =
            splitter.offsetLeft + splitter.offsetWidth + 'px';
        }
      }
    });
  }
}
