import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import User from './user';

export default class Action extends Abstract {
  @attr('string') declare type?: string;
  @attr('string') declare status?: string;
  @attr('jsonb') declare actionData?: any;
  @attr('jsonb') declare actionResult?: any;

  @attr('string') declare relatedEntityId?: string;
  @attr('string') declare relatedEntityType?: string;
  @attr('string') declare microApp?: string;
  @attr('string') declare creator?: string;

  @belongsTo('user') declare user: AsyncBelongsTo<User>;
  // @hasMany('action-status-histories') declare actionStatusHistories: AsyncHasMany<ActionStatusHistory>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    action: Action;
  }
}
