import Component from '@glimmer/component';
import { action, get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency'
import { AddressTypes } from '../component';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import Person from 'cing-app/models/person';
import CountrySubdivision from 'cing-app/models/country-subdivision';
import Country from 'cing-app/models/country';

interface AddPersonalAddressArgs {
  person: Person;
  personInCompany: any;
  companies: any;
  address: any;
  onClose: any;
  onUpdate: any;
}

export default class AddPersonalAddress extends Component<AddPersonalAddressArgs> {
  @service store!: Store;

  AddressTypes = AddressTypes;

  @tracked
  companies: any;

  @tracked
  person: Person;

  @tracked
  showValidations = false;

  @tracked
  address: any;

  @tracked
  states?: CountrySubdivision[];

  @tracked
  onUpdate: any;

  @tracked
  onClose: any;

  @tracked
  modalRemoveAddress: any;

  @tracked
  allowDirectCompanyAddressChanges: boolean = false;

  constructor(owner: any, args: AddPersonalAddressArgs) {
    super(owner, args);
    this.companies = this.args.companies;
    this.person = this.args.person;
    this.address = this.args.address;
    this.onUpdate = this.args.onUpdate;
    this.onClose = this.args.onClose;

    this.updateStates.perform();
  }

  get countries() {
    return this.store.peekAll('country').sortBy('name');
  }

  @task
  updateStates = taskFor(async () => {
    let country = await this.address.country;

    if (country) {
      this.states = (await country.countrySubdivisions).sortBy('code').toArray();

      let currentState = await this.address.state;

      if (currentState && !this.states?.includes(currentState)) {
        set(this.address, 'state', null);
      }
    }
  })

  get isCompanyAddress() {
    return get(this.address, 'companyId') && !get(this.address, 'personId');
  }


  @task
  saveAddressTask = taskFor(async (model) => {
    await model.save();

    if (this.onUpdate) {
      this.onUpdate();
    }

    if (this.onClose) {
      this.onClose();
    }
  })


  @task
  removeAddressTask = taskFor(async (model) => {
    await model.destroyRecord();

    if (this.onUpdate) {
      this.onUpdate();
    }

    if (this.onClose) {
      this.onClose();
    }
  })

  @action
  saveAddress(model) {
    this.showValidations = true;
    if (model.get('validations.isValid')) {
      this.saveAddressTask.perform(model);
    }
  }

  @action
  doRemoveAddress() {
    this.removeAddressTask.perform(this.address);
  }

  @action
  onAddressTypeChanged(value) {
    if (value === this.AddressTypes.BUSINESS) {
      this.person.get('company').then((company) => {
        this.address.company = company;
      })
    } else {
      this.address.company = null;
    }
  }

  @action
  cancel() {
    this.address.rollbackAttributes();

    if (this.onClose) {
      this.onClose();
    }
  }
}