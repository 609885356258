import classic from 'ember-classic-decorator';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from "@ember/component";
import EmberObject, { action, computed } from "@ember/object";
// import { task, timeout } from 'ember-concurrency';
import RoleKeeper from 'cing-app/mixins/role-keeper';


@classic
export default class SelectFiles extends Component.extend(RoleKeeper) {
  @inject()
  store;

  @inject()
  config;

  @inject('docker-item')
  docker;

  @alias('context.case')
  selectedCase;

  @alias('context.presetFilter')
  presetFilter;

  selectedDockets = [];
  selectedDocuments = [];

  @action
  confirmSelection() {
    let dockets = this.selectedDockets;
    let documents = this.selectedDocuments;
    if (this.get('context.onSelection')) {
      this.get('context.onSelection')(dockets.length ? dockets : documents);
    }

    this.onClose();
  }
  // clearSelection() {
  //     this.get('selectedDockets').clear();
  //     this.get('selectedDocuments').clear();
  // },
}
