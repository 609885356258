import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';

@classic
export default class ImportRow extends EmberObject {
  FundName = "";
  InvestorContactID = "";
  Company = "";
  ContactName = "";
  Type = "";
  Role = "";
  ContactEmail = "";
  PortalAccess = "";
  ViewerGroup = "";
  InvestorName = "";
  Folders = "";
  SRAccess = false;
  SRProfile = "";
  CapitalCalls = false;
  Distributions = false;
  Tax = false;
  Financials = false;
  Legal = false;
  OtherDocuments = false;
  Communications = false;
  DealCloudID = "";
  InvestorNumber = "";
  InvestorType = "";
  Commitment = "";
  Phones = "";
  DateOfBirth = "";
  Gender = "";
  BIOPage = "";
  Note = "";
  Address1 = "";
  Address2 = "";
  Address3 = "";
  City = "";
  Country = "";
  State = "";
  Zip = "";
}