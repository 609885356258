import Component from "@ember/component";
import { inject } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { computed } from "@ember/object";

import { ProjectTabs, ProjectTabsEnum } from 'cing-app/utils/lookups';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import { isEmpty } from "@ember/utils";

export default Component.extend({
  store: inject(),
  session: inject(),
  ProjectTabsEnum,
  ProjectTabsOptions: Object.entries(ProjectTabsEnum).map((entry) => {
    return {
      id: entry[1],
      name: entry[0]
    }
  }),
  init() {
    this._super();

    let settings = this.get('model.settings');

    if (isEmpty(settings.enabled_tabs)) {
      this.set('model.settings.enabled_tabs', {});
    }
  },
  toggleProjectTab(type) {
    const settingKey = `settings.enabled_tabs.${type}`;
    let value = model.get(settingKey);

    console.log('settings: ', this.get('model.settings'));

    if (!value || isEmpty(value)) {
      model.set(settingKey, true);
    } else {
      model.set(settingKey, false);
    }
  }
})
