import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { Formio } from 'formiojs';

interface FormViewerArgs {
	formDefinition: any;
	initData: any;
	events: { [eventName: string]: (form: any, data: any) => void };
	options: any
}

export default class FormViewer extends Component<FormViewerArgs> {
	@tracked
	form: any;
	@tracked
	formElement?: HTMLElement;

	@task
	initForm = taskFor(async (el: HTMLElement) => {
		let form = await Formio.createForm(el, this.args.formDefinition, this.args.options);

		if (this.args.initData) {
			let submission: { data: any } = { data: this.args.initData };
			form.submission = submission;
		}

		if (this.args.events) {
			for (let eventName in this.args.events) {
				form.on(eventName, (data: any) => {
					this.args.events[eventName](form, data);
				});
			}
		}

		this.form = form;
		this.formElement = el;

		el.addEventListener('keydown', this.formKeyDown);
	});



	willDestroy() {
		super.willDestroy();
		this.formElement?.removeEventListener('keydown', this.formKeyDown);
	}

	@action
	updateForm() {
		let keys1 = Object.keys(this.form.submission.data);
		let keys2 = Object.keys(this.args.initData);
		let keys = [...keys1, ...keys2].uniq().filter(k => k !== 'submit');

		for (let key of keys) {
			if (key.startsWith('__')) {
				continue;
			}
			let component = this.form.getComponent(key);
			if (component) {
				let val = this.args.initData[key] ?? ((component.defaultValue) ?? {});
				component.setValue(val);
			}
		}
	}

	@action
	formKeyDown(evt: KeyboardEvent) {
		if (evt.code === 'Enter') {
			this.form?.emit('customSubmit', this.form.submission.data);
		}
	}
}
