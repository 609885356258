import Field from '../field/component';
import { next } from '@ember/runloop';

export default Field.extend({
  showFormValidations: null,
  type: 'text',
  actions: {
    onInput() {
      next(this, 'sendAction', 'onInput', this.value);
    },
  },
});
