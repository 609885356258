import { attr, belongsTo, AsyncBelongsTo } from '@ember-data/model';
import Abstract from './abstract';
import CountrySubdivision from './country-subdivision';

export default class Court extends Abstract {
  @attr('number') declare idName: number;
  @attr('string') declare name?: string;
  @attr('string') declare shortName?: string;
  @attr('string') declare stateId?: string;
  @attr('string') declare urlEcf: string;
  @attr('string') declare urlInfo: string;
  @attr('string') declare urlRss: string;

  @belongsTo('country-subdivision')
  declare state: AsyncBelongsTo<CountrySubdivision>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    court: Court;
  }
}
