import DS from 'ember-data';

const CommaDelimited = DS.Transform.extend({
  deserialize(serialized?: string) {
    let deserialized: string[] = [];
    if (serialized && serialized.split) {
      deserialized = serialized.split(',');
    }
    return deserialized;
  },

  serialize(deserialized?: string[]) {
    if (deserialized && deserialized.join) {
      return deserialized.join(',');
    }
    return '';
  }
});

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    //@ts-ignore
    'comma-delimited': CommaDelimited;
  }
}

export default CommaDelimited;
