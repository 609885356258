import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
//@ts-ignore
import injectScripts from 'ember-inject-scripts';
import { taskFor } from 'ember-concurrency-ts';

interface EditTemplateArgs {
  appearance: any;
  context: {
    refreshParentGrid: () => void;
    template: any;
  };
}

const multipleScripts = [
  {
    id: 'grapesjs',
    src: '/libraries/grapesjs/grapes.min.js',
    once: true,
  },
  {
    id: 'grapesjs-mjml',
    src: '/libraries/grapesjs-mjml/grapesjs-mjml.min.js',
    once: true,
  },
];

export default class extends Component<EditTemplateArgs> {
  @service store: any;
  @service session: any;
  @service config: any;
  @service seActions: any;
  @tracked default_template;
  @tracked showFormValidations: any;
  @tracked editor: any;

  constructor(owner: unknown, args: EditTemplateArgs) {
    super(owner, args);

    var mjmlTemplate = `
      <mjml>
        <mj-body>
          <mj-section>
            <mj-column>
              <mj-text>My Company</mj-text>
            </mj-column>
          </mj-section>
        </mj-body>
      </mjml>    
    `;

    this.default_template = mjmlTemplate;
  }

  @task
  saveTask = taskFor(async () => {
    let model = this.args.context.template;
    let validations = model.validations;

    if (!validations.isValid) {
      this.showFormValidations = true;
      return;
    } else {
      this.showFormValidations = false;
      await model.save();
      this.args.context.refreshParentGrid();
    }
  });

  @task
  setupGrapes = taskFor(async () => {
    await injectScripts(multipleScripts);
    let model = this.args.context.template;
    let defaultTemplate = this.default_template;
    let seActions = this.seActions;

    //@ts-ignore
    var editor = grapesjs.init({
      fromElement: false,
      container: '.gjs-editor',
      plugins: ['grapesjs-mjml'],
      components: model.mjmlComponents || model.html || defaultTemplate,
      // style: model.mjmlStyles || model.css,
      height: 'auto',
      width: 'auto',
      //panels: { defaults: [] },
      pluginsOpts: {
        'grapesjs-mjml': {
          imagePlaceholderSrc: '/images/image-placeholder.png',
        },
      },
      storageManager: {
        autoload: false,
      },
      colorPicker: {
        appendTo: 'parent',
        offset: { top: 20, left: -175 },
      },
      assetManager: {
        storageType: '',
        storeOnChange: true,
        storeAfterUpload: true,
        upload: 'https://localhost/assets/upload',
        multiUpload: true,
        assets: model.mjmlAssets || [],
        uploadFile: function (e) {
          var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          let fileToUpload = files[0];

          return seActions
            .uploadImage(fileToUpload, '', true, fileToUpload.name)
            .then((result: any) => {
              var assets = [
                {
                  type: 'image',
                  src: result.url,
                },
              ];
              editor.AssetManager.add(assets);
            });
        },
      },
    });

    this.editor = editor;

    /*
    To fix the problem that mj-image is rendered as <mj-image /> instead of <mj-image></mj-image>,
    which will make the next sibling be treated as its child instead of sibling
    */
    let root = editor.DomComponents.getWrapper();
    let searchAndUpdate = function (component, layer = 0) {
      if (component.get('tagName') === 'mj-image') {
        component.set('void', false);
      }

      let children = component.get('components');
      if (children) {
        children.each((child) => searchAndUpdate(child, layer + 1));
      }
    };
    searchAndUpdate(root);
    editor.on('component:add', function (model) {
      model.set('void', false);
    });

    editor.on('storage:start:store', (objectToStore: any) => {
      set(model, 'mjmlComponents', JSON.parse(objectToStore.components));
      set(model, 'html', objectToStore.html);
      set(model, 'mjmlStyles', JSON.parse(objectToStore.styles));
      set(model, 'css', objectToStore.css);
      set(model, 'mjmlAssets', JSON.parse(objectToStore.assets));
    });
  });

  @action
  removeGrapes() {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  @action
  save() {
    this.saveTask.perform();
  }

  @action
  close() {
    this.args.context.template.rollbackAttributes();
    this.editor = null;
    this.args.onClose();
  }
}
