import { helper } from '@ember/component/helper';

export function errorText(params: any/*, hash*/) {
  let fmError = params[0];
  if (fmError) {
    return ((fmError.error.errors && fmError.error.errors.length ? fmError.error.errors[0].title + '; ' + (fmError.error.errors[0].detail || '') : null) || fmError.error.message || 'Error');
  }
  return '';
}

export default helper(errorText);
