import PowerSelectControl from 'ember-bootstrap-power-select/components/bs-form/element/control/power-select';
import { action } from '@ember/object';

import classic from 'ember-classic-decorator';

@classic
export default class PowerSelect extends PowerSelectControl {
  /*
  @action
  handleChange(event) {
    if (this.onChange)
    this.onChange?.(event.target.value);
  }
  */
}
