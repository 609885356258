import Component from '@glimmer/component';
import { DataSourceColumn } from 'smex-ui-table';
import Investor from 'cing-app/models/investor';

interface ColumnActionComponentArgs {
  row: DataSourceColumn<Investor>,
  selectedItems: Investor[],
  editInvestor: (row: Investor) => void,
  removeRecord: (row: Investor) => void
}

export default class ColumnComponent extends Component<ColumnActionComponentArgs> { }