import Transform from '@ember-data/serializer/transform';
import moment from 'moment';

const appTimeZone = 'America/Los_Angeles';

export default Transform.extend({
  deserialize: function (serialized) {
    var type = typeof serialized;

    if (serialized !== '' && type === 'string') {
      let d = moment(serialized, 'YYYY-MM-DD HH:mm:ss').tz(appTimeZone, true);

      if (!d.isValid()) {
        d = moment(serialized, 'YYYY-MM-DD').tz(appTimeZone, true);
      }

      return d.toDate();
    } else {
      return null;
    }
  },
  serialize: function (deserialized) {
    let serialized = null;

    if (
      deserialized &&
      (moment.isMoment(deserialized) ||
        deserialized instanceof Date ||
        moment(deserialized).isValid())
    ) {
      serialized = moment(deserialized)
        .tz(appTimeZone)
        .format('YYYY-MM-DD HH:mm:ss');
    }

    return serialized;
  },
});
