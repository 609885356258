import Component from '@glimmer/component';
import { DataSourceColumn } from 'smex-ui-table';
import { cached } from 'tracked-toolbox';

interface TableTextColumnArgs {
	column: DataSourceColumn<unknown>;
	row: any;
}

export default class TableTextColumn extends Component<TableTextColumnArgs> {
	@cached
	get value() {
		return this.args.column.getValue(this.args.row);
	}
}
