import { action } from '@ember/object';
import Widget from '../component';
import { PhoneTypesEnum } from 'cing-app/utils/lookups';

const PhoneTypes = Object.keys(PhoneTypesEnum).sort();
export default class Phone extends Widget {
  PhoneTypes = PhoneTypes;

  @action
  undelete() {
    this.model.rollbackAttributes();
  }
}
