import Component from '@ember/component';

import {
  SYNC_MISSING,
  SYNC_DELETED,
  SYNC_NAME_CHANGED,
  SYNC_COMPANY_CHANGED,
  SYNC_INVESTOR_CHANGED,
  SYNC_EMAIL_CHANGED,
  SYNC_ROLE_CHANGED,
  SYNC_FOLDERS_CHANGED,
} from '../component';
import classic from 'ember-classic-decorator';

@classic
export default class SyncResultItem extends Component {
  tagName = 'tr';
  SYNC_MISSING = SYNC_MISSING;
  SYNC_DELETED = SYNC_DELETED;
  SYNC_NAME_CHANGED = SYNC_NAME_CHANGED;
  SYNC_COMPANY_CHANGED = SYNC_COMPANY_CHANGED;
  SYNC_INVESTOR_CHANGED = SYNC_INVESTOR_CHANGED;
  SYNC_EMAIL_CHANGED = SYNC_COMPANY_CHANGED;
  SYNC_ROLE_CHANGED = SYNC_ROLE_CHANGED;
  SYNC_FOLDERS_CHANGED = SYNC_FOLDERS_CHANGED;
}