import Component from '@ember/component';
import { action } from '@ember/object';
import classic from 'ember-classic-decorator';

@classic
export default class CreateButtonComponent extends Component {
  tagName = '';

  onCreate() { };

  @action
  onClick() {
    this.onCreate();
    this.select.actions.close();
  }
}
