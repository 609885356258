import Model, { attr } from '@ember-data/model';

export default Model.extend({
  pcapId: attr('string'),
  invFundId: attr('string'),
  invId: attr('number'),
  balAtStmtDate: attr('number'),
  contAtStmtDate: attr('number'),
  transfers: attr('number'),
  intIncome: attr('number'),
  orgCosts: attr('number'),
  doProfOther: attr('number'),
  mgmtFees: attr('string'),
  netInvInc: attr('number'),
  gainOnCarryUnrealized: attr('number'),
  gainOnInvUnrealized: attr('number'),
  totalGainOnInvUnrealized: attr('number'),
  equDueToPartners: attr('number'),
  invDistYtd: attr('number'),
  gpMgtFee: attr('number'),
  partnersCapitalDist: attr('number'),
  partnersSyndCosts: attr('number'),
  balAsStmtDateEstFairVal: attr('number'),
  totalNetContSinceIncep: attr('number'),
  totalNetDistSinceIncep: attr('number')
});
