import { helper } from '@ember/component/helper';

export function formatSize(params) {
  let bytes = params[0];
  if (bytes > 0 && bytes < 1) {
    bytes = 1;
  }

  if (
    bytes === 0 ||
    bytes < 0 ||
    bytes === '' ||
    bytes === null ||
    typeof bytes === 'undefined'
  ) {
    return '0 Bytes';
  }

  const decimals = 2;
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let i = Math.floor(Math.log(bytes) / Math.log(k));
  let result =
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];

  return result;
}

export default helper(formatSize);
