import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency'; 

import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';

import { IsProjectType } from 'cing-app/helpers/is-project-type';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators, 
} from 'cing-app/mixins/filter-builder'; 

interface CrmUsersArgs {
  projectId: any;
  onSelect: any;
}

export default class CrmUsers extends Component<CrmUsersArgs> {
  @service('docker-item') docker!: DockerItemService;
  @service  store!: Store;
 
  @tracked selectedContactEmail = null;  
  @tracked selectContactEmails = null;
  @tracked contacts = [];
  @tracked model = {};
  @tracked project = {}; 
  @tracked lastSearchText = null;

  
  constructor(owner: any, args: any) {
    super(owner, args);
 
    this.initTask.perform();
  }
 

  @task
  *initTask() {  

    let projectId = '3a4f6bee-cae5-470b-9171-6b049f212f2f'; //'b7c0a05b-13e7-4e2e-9977-7e589f877a19'
    this.project = yield this.store.findRecord('project', projectId); // regular project 
  }
 

  @task({
    restartable: true,
  })
  *searchContactsTask(term) {
    yield timeout(300);

    let company = this.model.company; 

    this.lastSearchText = term;

    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    if (company) {
      expr.add(
        Filter.create({
          name: 'companies.id',
          operator: FilterOperators.EQUAL,
          value: company.id,
        })
      );
    }

    if (term) {
      let termParts = term.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            name: 'first-name',
            operator: FilterOperators.LIKE,
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            name: 'last-name',
            operator: FilterOperators.LIKE,
            value: termParts[termParts.length - 1],
          })
        );

        exprSearch.add(nameExpression);
      }

      ['first-name', 'last-name', 'emails.email-address'].forEach(
        (propName) => {
          exprSearch.add(
            Filter.create({
              name: propName,
              operator: FilterOperators.LIKE,
              value: term,
            })
          );
        }
      );

      expr.add(exprSearch);
    }

    this.contacts = yield this.store.query('person', {
      condition: expr.serialize(),
      include: 'emails,company',
      sort: 'last-name',
      page: { size: 50, number: 1 },
    }); 
  }

  @action
  viewPerson(person) {
    this.docker.popupPerson(person);
  } 

  @action
  selectPerson(person) {
    this.model = {
      person: person,
      personId: person ? person.id : null,
      email: null,
      emailId: null,
    };

    if (person) {
      person.emails.then((emails) => {
        emails = emails.toArray();

        if (emails.length > 1) {
          this.selectContactEmails = emails;
          this.selectedContactEmail = emails.firstObject;
          console.log("choose email")
        } else {
          let firstEmail = emails.firstObject;

          if (firstEmail) {
            this.model.email = firstEmail;
            person.emailAddress = firstEmail.emailAddress;
          }

          this.convertToPmResource(person);
        }
      });
    }
  }

  convertToPmResource(person){
    let resource = { 
      userId: person.id,
      resourceType: 'SmartExchangeUser',
      name: person.fullName, 
      company: person.companyName,
      email: person.emailAddress
    };
    
    if(this.args.onSelect) { 
      if(typeof person.company == 'object') {
        person.company.then((c) => {
          resource.company = c?.name;
          console.log("selected ", resource);
          this.args.onSelect(resource);
        });
      } else {
        this.args.onSelect(resource);
      }
    }
  }
 
  @action
  selectContactEmail() {
    this.model.email = this.selectedContactEmail;
    this.selectContactEmails = null; 

    if(this.selectedContactEmail){
      this.model.person.emailAddress = this.selectedContactEmail.emailAddress;
    }
    console.log("selected email ", this.model.person);
    this.convertToPmResource(this.model.person) 
  }

  @action
  createContact() {
    // just to parse the lastSearchText to extract first/last name
    let newPerson = this.store.createRecord('person');
    
    newPerson.parseFromFullName(this.lastSearchText || '');

    this.docker.popupEditPerson(newPerson, {
      onCreate: (person) => {
        this.selectPerson(person);
      },
    });
  } 
}
