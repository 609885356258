import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';//@ts-ignore
import DockerItemService from 'cing-app/pods/docker-item/service';
import ENV from "../../../../config/environment";
import Person from 'cing-app/models/person';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
  CaseFilters, 
  CaseFilterKeys //@ts-ignore
} from "cing-app/mixins/filter-builder";

interface ProjectsArgs{
  person: Person;
}

export default class Projects extends Component<ProjectsArgs>{

  @service store!: Store;

  @service ('docker-item') docker!: DockerItemService;

  @service config;
  
  @tracked
  columns:any;

  @tracked
  condition:any;

  constructor(owner:any,args:ProjectsArgs){
    super(owner,args);
    this.columns = this.getColumns();
    this.generateFilter();
  }

  include = 'project-type';

    /*
    this.set('extraQueryParams', { 
        'fields[projects]': 'date-started,name'
    });
    */


    /*
    let expC = Expressions.create({operator: ExpressionOperators.AND});
    expC.add(Filter.create({
        name: 'interested-party-company-id',
        operator: FilterOperators.EQUAL,
        value: this.get('company.id')
    }));
    let cases = this.get('store').query('case', {
        condition: expC.serialize(),
        include: 'case',
        page: {
            size: 1000,
            number: 1
        }
    });

    this.set('cases', cases);
    */

  getColumns() {
    this.columns = [
      {
        propertyName: 'dateStarted',
        component: "api-table/columns/date",
        title: "Started",
        className: "column-date",
        sortDirection: 'desc',
        sortPrecedence: 0, //@ts-ignore
        dateFormat: ENV.APP.shortDateOnlyFormat,
      },
      {
        propertyName: 'name',
        className: "column-name",
        component: "api-table/columns/project"
      },
      {
        propertyName: 'projectType.name',
        title: "Type",
        isHidden: false,
        className: "column-number",
      },
    ];

    return this.columns;
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.OR });

    expr.add(Filter.create({
      name: 'interested-parties.person-id',
      operator: FilterOperators.EQUAL,
      value: this.args.person.id
    }));

    expr.add(Filter.create({
      name: 'project-lead.id',
      operator: FilterOperators.EQUAL,
      value: this.args.person.id
    }));

    this.condition= expr.serialize();
  }

  @action
  showCaseDetail(record) {
    let appearance = {
      icon: '',
      title: `Case: ${record.get('name')} / #${record.get('displayNumber')}`,
      size: 'large'
    };
    this.docker.invokePopup('case-detail', appearance, { recordId: record.get('id') });
  }
}
