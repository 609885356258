import Component from '@glimmer/component';
import DomainMetadata from "cing-app/models/sending-domain";
import { Owner } from "ember-one-way-controls/.node_modules.ember-try/@glimmer/di";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

interface SendingDomainsDetailDomainItemArgs {
  domain: DomainMetadata
}

export default class SendingDomainsDetailDomainItem extends Component<SendingDomainsDetailDomainItemArgs> {
  @tracked showDns: boolean

  constructor(owner: Owner, args: SendingDomainsDetailDomainItemArgs) {
    super(owner, args);
    this.showDns = false;
  }

  get showDnsText() {
    return this.showDns ? "Hide DNS Instructions" : "Show DNS Instructions";
  }

  @action
  switchDnsView():void {
    this.showDns = !this.showDns;
  }
}
