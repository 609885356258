import RESTSerializer from '@ember-data/serializer/rest';

export default RESTSerializer.extend({
  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
    let modelName = primaryModelClass.modelName;
    payload = {
      [modelName]: payload
    };

    let result = this._super(store, primaryModelClass, payload, id, requestType);
    return result;
  },

  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    let modelName = primaryModelClass.modelName;

    let items = null;

    if (this.responseItemsKey) {
      items = payload[this.responseItemsKey];
    } else if (payload.items) {
      items = payload.items;
    } else {
      items = payload;
    }

    let paging = null;

    if (payload.paging) {
      paging = payload.paging;
    } else {
      paging = {
        page: payload.currentPage,
        size: payload.pageSize,
        totalCount: payload.totalCount,
      }
    }

    let newPayload = {
      [modelName]: items,
      meta: paging
    }

    let skipPayloadProps = ['items', this.responseItemsKey, 'paging']

    for (var prop in payload) {
      if (!skipPayloadProps.includes(prop)) {
        newPayload[prop] = payload[prop];
      }
    }

    let result = this._super(store, primaryModelClass, newPayload, id, requestType);
    return result;
  },

});
