import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';

import _SessionService from 'cing-app/pods/session/service';
import AbPmService, { IPmTaskDocument } from 'cing-app/pods/ab-pm/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import {
  SmartroomFileModel,
  SmartroomFolderModel,
  SmartroomMyInfoModel,
} from 'smex-ui-sr-models';

import { get } from '@ember/object';
import { DataSourceColumn, IDataSource, StaticDataSource } from 'smex-ui-table';

interface AddSmartRoomDocumentArgs {
  context: {
    abLink: any;
    smartRoomId: any;
    task: any;
    onTaskAction: any;
    documentsTableSource: any;
    onSave: any;
  };
}

export default class AddSmartRoomDocument extends Component<AddSmartRoomDocumentArgs> {
  @service seActions: any;
  @service('ab-pm') pmService!: AbPmService;
  @service('docker-item') docker!: DockerItemService;
  @service declare store: Store;

  @tracked saveMessage: string = '';
  @tracked progressCount: string = '';

  @tracked smartRoomSiteInfo: any;
  @tracked smartRoomRootFolderInfo: any;

  @tracked startFolderId: any;
  @tracked rootFolderId: any;

  @tracked selectedSmartRoomFolder: any = null;
  @tracked isLinkedToSmartRoomFolder = false;
  @tracked isTaskModified = false;
  @tracked isDeleting = false;
  @tracked isRemoving = false;
  @tracked isDeletingMultipleItems = false;
  @tracked selectedItem: any = null;
  @tracked foldersOnPath = [];

  @tracked originalItems: any;
  @tracked allItems: any[] = [];
  @tracked dataSource!: StaticDataSource<IPmTaskDocument, IPmTaskDocument>;
  @tracked searchQuery: string = '';
  @tracked smartRoomFilesToDelete = new Map<string, any>();
  pageSize: number = 20;

  @tracked columns: DataSourceColumn<IPmTaskDocument>[] = [
    new DataSourceColumn<IPmTaskDocument>({
      id: 'id',
      label: 'ID',
      hidden: true,
      getValue: (row) => get(row, 'id'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
      options: {
        dasherizedProperty: 'id',
      },
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'file-name',
      label: 'Filename',
      getValue: (row) => get(row, 'fileName'),
      sortingEnabled: true,
      minWidth: 180,
      valueComponent: 'table-filename-column',
      options: {
        tooltip: true,
        getTooltipValue: (row: any) => {
          return row.fileName || '';
        },
        linkTarget: '_blank',
        generateLink: (row: any) => {
          console.log('file row ', row.tasks);
          return row.url;
        },
        onClick: (row: any) => {
          return this.filenameClicked(row);
        },
      },
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'created-by',
      label: 'Uploaded By',
      getValue: (row) => get(row, 'createdBy'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
    }),
    new DataSourceColumn<IPmTaskDocument>({
      id: 'created-date',
      label: 'Uploaded',
      getValue: (row) => get(row, 'createdDate'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
      options: {
        format: {
          style: 'date',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },
      },
    }),
  ];

  constructor(owner: any, args: any) {
    super(owner, args);
  }

  get isBusy() {
    return this.initTask.isRunning || this.saveTask.isRunning;
  }

  reloadDocumentsTable() {
    if (this.args.context.documentsTableSource) {
      if (this.args.context.documentsTableSource.refresh) {
        this.args.context.documentsTableSource.refresh();
      }
    }
  }

  @action init() {
    console.log('init task from task-documents');
    this.initTask.perform();
  }

  @task initTask = taskFor(async () => {
    this.smartRoomSiteInfo = await this.store.findRecord(
      'smartroom/site',
      this.args.context.smartRoomId
    );

    this.rootFolderId = this.smartRoomSiteInfo.topLevelFolderId;
    this.startFolderId = this.args.context.task.folderId;
    if (this.startFolderId) {
      this.isLinkedToSmartRoomFolder = true;
    } else {
      this.startFolderId = this.rootFolderId;
    }

    if (this.rootFolderId) {
      console.log('root folder id = ', this.rootFolderId);
      this.smartRoomRootFolderInfo = await this.store.queryRecord(
        'smartroom/folder',
        {
          id: this.rootFolderId,
          siteId: this.args.context.smartRoomId,
        }
      );

      this.selectedSmartRoomFolder = this.smartRoomRootFolderInfo;
    }

    if (this.startFolderId && this.startFolderId != this.rootFolderId) {
      this.selectedSmartRoomFolder = await this.store.queryRecord(
        'smartroom/folder',
        {
          id: this.startFolderId,
          siteId: this.args.context.smartRoomId,
        }
      );
      console.log('selected folder ', this.selectedSmartRoomFolder);
    }

    console.log('init datasource');
    await this.loadOriginalItems();

    this.dataSource = new StaticDataSource<IPmTaskDocument, IPmTaskDocument>(
      25,
      false,
      'smartroom-docs',
      this.pmService.currentUserEmail,
      this.allItems,
      this.loadDataTask,
      1000,
      this.columns
    );

    this.dataSource.enableSelectAll = false;
    this.dataSource.selectionEnabled = false;
  });

  async loadOriginalItems() {
    this.originalItems = await this.pmService.getTaskDocumentsByType(
      'smartroom',
      this.args.context.task.id,
      1000,
      0,
      '-id',
      this.args.context.abLink
    );

    this.allItems = [];
    this.originalItems.toArray().forEach((taskDoc: any) => {
      this.allItems.pushObject(taskDoc);
    });
  }

  @task loadDataTask = taskFor(async (data: IPmTaskDocument[]) => {
    return data;
  });

  @action onChangeLinkToSmartRoomFolder() {
    console.log('chage link to smartroom folder');
    if (this.isLinkedToSmartRoomFolder) {
      if (this.selectedSmartRoomFolder) {
        this.args.context.task.folderId = this.selectedSmartRoomFolder.id;
        this.args.context.task.folderName = this.selectedSmartRoomFolder.name;
      }
    } else {
      this.startFolderId = this.rootFolderId;
      this.args.context.task.folderId = null;
      this.args.context.task.folderName = null;

      console.log('start folder is now ', this.startFolderId);
    }
    this.isTaskModified = true;
  }

  @action filenameClicked(row: any) {
    let appearance = {
      icon: 'file',
      title: row.fileName,
      size: 'large',
      custom: true,
    };

    const metadata = JSON.parse(row.metadata);
    const siteId = metadata.smartRoomId || this.args.context.smartRoomId;
    const file = {
      id: row.fileId || metadata.fileId,
      folderId: row.folderId,
      version: metadata.versionNo || 1,
      get: null,
    };

    if (!file.folderId) {
      file.folderId = this.pmService.getSmartRoomFolderIdFromPathIds(
        metadata.pathIds
      );
    }

    file.get = (key: string) => {
      return file[key];
    };

    console.log('Opening file ', file);

    this.docker.invokePopup('smartroom/file-viewer', appearance, {
      file: file,
      siteId: siteId,
    });
  }

  @action getActions() {
    let actions = [
      {
        label: 'View',
        icon: 'eye',
        action: this.filenameClicked,
      },
      {
        label: 'Download',
        icon: 'download',
        action: (row: any) => {
          this.downloadFileTask.perform(row);
        },
      },
      {
        label: 'Remove',
        icon: 'times',
        class: 'text-danger',
        action: (row: any) => {
          if (row.id) {
            this.isRemoving = true;
            this.selectedItem = row;
          } else {
            this.allItems.removeObject(row);
            this.dataSource.refresh();
          }
        },
      },
      {
        label: 'Delete',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (row: any) => {
          this.isDeleting = true;
          this.selectedItem = row;
        },
      },
    ];
    return actions;
  }

  @task downloadFileTask = taskFor(async (file: any) => {
    console.log('download file ', file);

    if (file.type == 'smartroom' && file.fileId) {
      await this.pmService.downloadSmartRoomFile(
        this.args.context.smartRoomId,
        file
      );
    } else {
      this.pmService.downloadFile(file.url, file.fileName);
    }
  });

  @action selectSmartRoomItem(item: any) {
    console.log('select sr item  ', item);
    if (item instanceof SmartroomFileModel) {
      var isExisting = false;
      this.saveMessage = '';
      for (var i = 0; i < this.allItems.length; i++) {
        if (this.allItems[i].fileId == item.id) {
          this.saveMessage = 'Selected file is already on the list.';
          isExisting = true;
          break;
        }
      }
      if (!isExisting) {
        item.parent.then((folderInfo: SmartroomFolderModel) => {
          let taskDoc: any = {
            fileId: item.id,
            fileName: `${item.displayString} ${item.name}`,
            folderNumber: folderInfo.displayString,
            folderName: folderInfo.name,
            folderId: folderInfo.id,
            srObject: item,
            metadata: JSON.stringify({
              fileId: item.id,
              folderId: folderInfo.id,
              pathIds: item.pathIds,
              versionNo: item.versionNo,
              smartRoomId: this.args.context.smartRoomId,
            }),
            isNew: true,
          };

          if (!this.isLinkedToSmartRoomFolder) {
            this.isLinkedToSmartRoomFolder = true;
            this.isTaskModified = true;
          }

          this.allItems.pushObject(taskDoc);
          if (this.dataSource && this.dataSource.refresh) {
            this.dataSource.refresh();
          }
        });
      }
    } else if (item instanceof SmartroomFolderModel) {
      this.selectedSmartRoomFolder = item;
      this.isTaskModified = true;
    }
  }

  @task saveTask = taskFor(async () => {
    let docs = this.allItems || [];
    let existingDocsMap = new Map<string, any>();
    let isDocListModified = false;

    console.log('saving task docs ', docs.length);

    // add the new items
    for (var i = 0; i < docs.length; i++) {
      console.log(' adding docs ', i);
      let taskDoc = docs[i];
      if (taskDoc.isNew) {
        try {
          this.saveMessage = `Saving ${taskDoc.fileName} ...`;

          let newDoc = this.pmService.createTaskDocument(
            this.args.context.abLink
          );
          newDoc.taskId = this.args.context.task.id;
          newDoc.type = 'smartroom';
          newDoc.fileId = taskDoc.fileId;
          newDoc.fileName = taskDoc.fileName;
          newDoc.folderId = taskDoc.folderId;
          newDoc.folderNumber = taskDoc.folderNumber;
          newDoc.folderName = String(
            (taskDoc.folderNumber || '') + ' ' + taskDoc.folderName
          ).trim();
          newDoc.metadata = taskDoc.metadata;
          newDoc.email = this.pmService.currentUserEmail;
          newDoc.createdDate = this.pmService.currentDate;
          newDoc.createdBy = this.pmService.currentUserFullName;
          if (taskDoc.folderId) {
            newDoc.folderName = await this.pmService.getFolderPath(
              this.args.context.smartRoomId,
              taskDoc.folderId
            );
          }

          await newDoc.save();

          if (this.args.context.task.documents) {
            this.args.context.task.documents.pushObject(newDoc);
          }

          isDocListModified = true;
        } catch (ex) {
          console.log('Error in adding new doc ' + taskDoc.fileName, ex);
        }
      } else {
        existingDocsMap.set(taskDoc.fileId, taskDoc);
      }
    }

    console.log(
      'identify items to be deleted or removed from ',
      this.originalItems
    );
    // remove the deleted items
    let arr = this.originalItems.toArray();
    for (var i = 0; i < arr.length; i++) {
      let item = arr[i];
      if (!existingDocsMap.has(item.fileId)) {
        console.log('removing ', item.fileId);
        if (item.destroyObject) {
          await item.destroyObject();
        }
        isDocListModified = true;
      }
    }

    console.log(
      'retrieving the items to be deleted ',
      this.smartRoomFilesToDelete
    );
    var srFileIdsToDelete = Array.from(this.smartRoomFilesToDelete.keys());
    console.log('sr file ids to delete ', srFileIdsToDelete);

    //delete the selected smartroom files
    if (srFileIdsToDelete.length) {
      for (var i = 0; i < srFileIdsToDelete.length; i++) {
        let fileId = srFileIdsToDelete[i];
        try {
          console.log('deleting sr file ', fileId);
          await this.pmService.deleteSmartRoomFile(
            this.args.context.smartRoomId,
            fileId
          );
        } catch (ex) {
          console.log('error in deleting sr file ' + fileId, ex);
        }
      }
      isDocListModified = true;
    }

    if (isDocListModified || this.isTaskModified) {
      if (this.isLinkedToSmartRoomFolder && this.selectedSmartRoomFolder) {
        this.args.context.task.folderId = this.selectedSmartRoomFolder.id;
        this.args.context.task.folderName = await this.pmService.getFolderPath(
          this.args.context.smartRoomId,
          this.args.context.task.folderId
        );
      } else {
        this.args.context.task.folderId = null;
        this.args.context.task.folderName = null;
      }

      this.saveMessage = 'Updating task ...';
      this.args.context.task.save();
      this.saveMessage = 'Task documents have been updated.';
      this.reloadDocumentsTable();
      this.smartRoomFilesToDelete.clear();

      setTimeout(() => {
        if (this.args.context.onSave) {
          this.args.context.onSave();
        }
      }, 1000);
    } else {
      console.log('nothing to save');
    }
  });

  @task removeDocumentTask = taskFor(async () => {
    if (this.selectedItem) {
      this.allItems.removeObject(this.selectedItem);
      this.dataSource.refresh();
    }
    this.isRemoving = false;
  });

  @task deleteDocumentTask = taskFor(async () => {
    if (this.selectedItem) {
      // add the item to the list to be deleted
      this.smartRoomFilesToDelete.set(
        this.selectedItem.fileId,
        this.selectedItem
      );
      // remove the item from the ui
      this.allItems.removeObject(this.selectedItem);
      this.dataSource.refresh();
    }
    this.isDeleting = false;
  });
}
