import AppBuilderAdapter from './app-builder';

export default AppBuilderAdapter.extend({
  pathForType: function (modelName) {
    modelName = this._super(modelName);

    modelName += 's';
    /*
    var decamelized = decamelize(modelName);

    let normalized = decamelized.replace('smartroom/', '');

    if (this.pluralizedEndpoint) {
        return pluralize(normalized);
    }    
    
    return normalized;
    */

    return modelName;
  },
});
