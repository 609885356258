import Service from '@ember/service';

import { computed, get } from '@ember/object';
import { timeout, task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { assign } from '@ember/polyfills';
import { camelize } from '@ember/string';
import { InvestorSmartRoomFolders } from 'cing-app/utils/lookups';
import { Promise } from 'rsvp';

import fetch from 'fetch';
export const FolderInvalidChars = /[<>:"\/\\|?*\x00-\x1F]/g;
class SRError extends Error {
  code = null;
  message = 'SmartRoom error occurred.';
  constructor(status, payload = {}) {
    super();
    this.status = status;

    if (payload.code) {
      this.code = payload.code;
    }

    if (payload.message) {
      this.message = payload.message;
    }
  }
}

export const UserStatus = {
  ACTIVE: 1,
  HOLD: 2,
  PENDING: 3,
  DELETED: 4,
};

export const NotificationTypes = {
  NONE: 0,
  SEND_INVITE_EMAIL: 1,
  SEND_INSTANT_ALERT: 2,
};

export const SecurityFilterOptions = {
  All: 0,
  ProfilesWithAnyRights: 1,
  ProfilesWithNoRights: 2,
};

class FolderAlreadyExists extends SRError {}

class CategoryAlreadyExists extends SRError {}

class FolderNotFound extends SRError {}

function decodeHTMLEntities(str) {
  var parser = new DOMParser();
  var dom = parser.parseFromString(str, 'text/html');
  return dom.body.textContent;
}

export {
  FolderInvalidChars,
  FolderAlreadyExists,
  CategoryAlreadyExists,
  FolderNotFound,
  UserStatus,
  NotificationTypes,
  SRError,
};
import classic from 'ember-classic-decorator';

@classic
export default class SmartRoomService extends Service {
  @service store;
  @service session;
  @service serverVariables;

  actionIds = [];

  fetchWrapper(url, method = 'GET', body = null) {
    return fetch(url, {
      method,
      body,
      headers: {
        authorization: `Bearer ${get(
          this.session,
          'data.authenticated.access_token'
        )}`,
        'content-type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        // handle error response
        if (!response.ok) {
          let responseError = null;

          if (json.message) {
            responseError = new Error(responseError);
          } else {
            responseError = new Error(
              'An error occurred while communicating with backend services'
            );
          }

          responseError.payload = json;

          throw responseError;
        }

        return json;
      });
  }

  /**** PUBLIC APIs ****/

  createFolder(
    smartRoomId,
    newFolderName,
    parentFolderId = 0,
    categoryRights = [],
    beforeFolderId = 0
  ) {
    // create the folder (using smartroom actions)
    return this.createFolderTask.perform(
      smartRoomId,
      newFolderName,
      parentFolderId,
      categoryRights,
      beforeFolderId
    );
  }

  createFolderEnforce(smartRoomId, newFolderName, parentFolderId = 0) {
    // create the folder (using smartroom actions)
    return this.createFolderEnforceTask.perform(
      smartRoomId,
      newFolderName,
      parentFolderId
    );
  }

  createInvestorStructure(
    smartRoomId,
    parentId,
    folders = InvestorSmartRoomFolders
  ) {
    let folderStructure = [];

    folders.forEach((folderName) => {
      folderStructure.push({
        path: folderName,
        categoryRights: [],
      });
    });

    return this.createFolderStructure(smartRoomId, folderStructure, parentId);
  }

  updateSmartRoomName(smartRoomId, siteName) {
    return this.updateSmartRoomNameTask.perform(smartRoomId, siteName);
  }

  sendGenericAlert(
    smartRoomId,
    template,
    subject,
    emailFrom = null,
    emailFromAlias = '',
    templateData = [],
    recipients,
    links = [],
    body = null
  ) {
    return this.sendGenericAlertTask.perform(
      smartRoomId,
      template,
      subject,
      emailFrom,
      emailFromAlias,
      templateData,
      recipients,
      links,
      body
    );
  }

  inviteTemplatePreview(
    smartRoomId,
    inviteTemplate,
    userName,
    email = 'user@domain.tld',
    siteName = 'SmartRoom Name'
  ) {
    return this.inviteTemplatePreviewTask.perform(
      smartRoomId,
      inviteTemplate,
      userName,
      email,
      siteName
    );
  }

  genericAlertPreview(
    smartRoomId,
    genericAlertModel,
    userName,
    email = 'user@domain.tld',
    siteName = 'SmartRoom Name'
  ) {
    return this.genericAlertTemplatePreviewTask.perform(
      smartRoomId,
      genericAlertModel,
      userName,
      email,
      siteName
    );
  }

  addUsers(
    smartRoomId,
    users,
    status = UserStatus.HOLD,
    doNotSendInvite = true
  ) {
    return this.createUsersTask.perform(
      smartRoomId,
      users,
      status,
      doNotSendInvite
    );
  }

  updateUsersStatus(
    smartRoomId,
    emails,
    status = UserStatus.HOLD,
    notificationType = NotificationTypes.NONE,
    inviteTemplate = null,
    customSubject = null
  ) {
    return this.updateUsersStatusTask.perform(
      smartRoomId,
      emails,
      status,
      notificationType,
      inviteTemplate,
      customSubject
    );
  }

  addUserOrUpdateStatus(smartRoomId, user, status = UserStatus.HOLD) {
    return this.createUserOrUpdateStatusTask.perform(smartRoomId, user, status);
  }

  addUser(
    smartRoomId,
    email,
    categoryId,
    status = UserStatus.HOLD,
    firstName = '',
    lastName = '',
    doNotSendInvite = true
  ) {
    //return this.createUserTask.perform(smartRoomId, email, categoryId, status, firstName, lastName, doNotSendInvite);
  }

  updateUsers(smartRoomId, parameters) {
    return this.updateUsersTask.perform(smartRoomId, parameters);
  }

  createFolderStructure(smartRoomId, folderStructure, parentFolderId = 0) {
    // create the folder (using smartroom actions)
    return this.createFolderStructureTask.perform(
      smartRoomId,
      folderStructure,
      parentFolderId
    );
  }

  createCategory(smartRoomId, categoryName, topLevelFolderId = 0) {
    // create the folder (using smartroom actions)
    return this.createCategoryTask.perform(
      smartRoomId,
      categoryName,
      topLevelFolderId
    );
  }

  setCategorySecurity(smartRoomId, categoryId, folderSecurities) {
    // create the folder (using smartroom actions)
    return this.setCategorySecurityTask.perform(
      smartRoomId,
      categoryId,
      folderSecurities
    );
  }

  getRightsForFile(smartRoomId, fileId) {
    return this.getRightsForFileTask.perform(smartRoomId, fileId);
  }

  getRightsForFolder(
    smartRoomId,
    folderId,
    filterOption = SecurityFilterOptions.All
  ) {
    return this.getRightsForFolderTask.perform(
      smartRoomId,
      folderId,
      filterOption
    );
  }

  normalizeFolderName(input = '') {
    input = decodeHTMLEntities(input);

    // trim the string, replace all invalid chars with spaces and compress whitespace
    return input.trim().replace(FolderInvalidChars, ' ').replace(/\s+/, ' ');
  }

  getSingleActionResult(actionId) {
    return this.getSingleActionResultTask.perform(actionId);
  }

  /**** END PUBLIC APIs ****/

  createActionDetail(
    actionId,
    record,
    waitForSecurity = false,
    extraData = {}
  ) {
    let actionDetail = {
      store: this.store,
      resultId: null,
      status: null,
      securityCalcCompleted: null,
      comment: null,
      actionId: actionId,
      record: record,
      waitForSecurity: waitForSecurity,
    };

    assign(actionDetail, extraData);

    return actionDetail;
  }

  @task
  *createFolderTask(
    smartRoomId,
    newFolderName,
    parentFolderId,
    categoryRights,
    beforeFolderId
  ) {
    try {
      let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/folders/${parentFolderId}`;

      let queryData = JSON.stringify({
        action: 'add',
        parameters: [
          {
            name: newFolderName,
            subFolders: [],
            parentId: parentFolderId,
            categoryRights: categoryRights,
            beforeFolderId: beforeFolderId,
          },
        ],
      });

      let response = yield this.fetchWrapper(actionsUrl, 'PUT', queryData);

      let completedActions = yield this._waitForActionFromResult.perform(
        response
      );

      let actionResult = completedActions[0];

      let folder = yield this.store.queryRecord('smartroom/folder', {
        siteId: smartRoomId,
        id: actionResult.resultId,
      });

      return folder;
    } catch (e) {
      if (e.payload && e.payload.code === 23005) {
        throw new FolderAlreadyExists(e.status, e.payload);
      } else {
        throw new SRError(e.status, e.payload);
      }
    }
  }

  @task
  *updateSmartRoomNameTask(smartRoomId, siteName) {
    let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${smartRoomId}/settings`;

    let payload = [
      {
        settingId: '107',
        value: siteName,
      },
    ];

    // create or get the folder
    let updateSiteResponse = yield this.fetchWrapper(
      actionsUrl,
      'PUT',
      JSON.stringify(payload)
    );

    return updateSiteResponse;
  }

  @task
  *createFolderEnforceTask(smartRoomId, newFolderName, parentFolderId) {
    let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${smartRoomId}/folders/${parentFolderId}/enforce`;

    let retryCount = 0;
    let staggerTime = 0;
    let folderResponse = null;

    while (retryCount < 5) {
      yield timeout(staggerTime);

      // create or get the folder
      try {
        folderResponse = yield this.fetchWrapper(
          actionsUrl,
          'PUT',
          newFolderName
        );
        break;
      } catch (e) {
        retryCount++;
        staggerTime = Math.round(Math.max(staggerTime, 500) * 1.5);

        folderResponse = null;
      }
    }

    if (!folderResponse) {
      throw new Error(
        `An error occurred while creating SmartRoom folder: ${newFolderName}!`
      );
    }

    let finalResponse = {};

    // the response may returned non-standard properties, convert them
    Object.keys(folderResponse).forEach((prop) => {
      finalResponse[camelize(prop)] = folderResponse[prop];
    });

    let ModelClass = this.store.modelFor('smartroom/folder');
    let serializer = this.store.serializerFor('smartroom/folder');

    let jsonApiPayload = serializer.normalizeResponse(
      this.store,
      ModelClass,
      finalResponse,
      null,
      'findRecord'
    );

    let folder = this.store.push(jsonApiPayload);

    return folder;
  }

  @task
  *createFolderStructureTask(smartRoomId, folderStructure, parentFolderId) {
    let getStructureGuidUrl = `${get(
      this.serverVariables,
      'smartroomApiUrl'
    )}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${smartRoomId}/folders/${parentFolderId}/upload-structure`;
    let uploadSessionsUrl = `${get(
      this.serverVariables,
      'smartroomApiUrl'
    )}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${smartRoomId}/folders/${parentFolderId}/upload-sessions/request`;

    let items = [];

    for (var a = 0; a < folderStructure.length; a++) {
      items.push({
        itemType: 2, // 1 - File, 2 - Folder
        path: folderStructure[a].path,
        sequence: a,
      });
    }

    let queryData = JSON.stringify({
      items,
    });

    let response = yield this.fetchWrapper(
      getStructureGuidUrl,
      'POST',
      queryData
    );

    let structureGuid = response.structureGuid;

    // now create the upload requests (essentially creates the requested folders)
    let uploadRequests = [];

    for (var a = 0; a < folderStructure.length; a++) {
      uploadRequests.push({
        //categoryRights: folderStructure[a].categoryRights,
        relativePath: folderStructure[a].path,
        structureGuid,
      });
    }

    let responseRequests = yield this.fetchWrapper(
      uploadSessionsUrl,
      'POST',
      JSON.stringify(uploadRequests)
    );

    return responseRequests;
    /*
    let actions = [];

    // process all action IDs
    for (var a = 0; a < response.length; a++) {
        actions.push(this.createActionDetail(response[a].actionId, null, false, response[a]));
    }
    */
    //return yield this._checkActionsResult.perform(actions, []);
  }

  @task
  *createCategoryTask(smartRoomId, categoryName, topLevelFolderId) {
    try {
      let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/categories/0`;

      let queryData = JSON.stringify({
        action: 'add',
        parameter: {
          topLevelFolder: topLevelFolderId,
          permissions: [],
          name: categoryName,
        },
      });

      let response = yield this.fetchWrapper(actionsUrl, 'PUT', queryData);

      let completedActions = yield this._waitForActionFromResult.perform(
        response
      );

      let actionResult = completedActions[0];

      if (actionResult.resultId === -1) {
        throw new CategoryAlreadyExists();
      } else {
        let category = yield this.store.queryRecord('smartroom/category', {
          siteId: smartRoomId,
          id: actionResult.resultId,
        });

        return category;
      }
    } catch (e) {
      if (
        (e.payload && e.payload.code === 21009) ||
        e instanceof CategoryAlreadyExists
      ) {
        throw new CategoryAlreadyExists(e.status, e.payload);
      } else {
        throw new SRError(e.status, e.payload);
      }
    }
  }

  @task
  *createUserTask(
    smartRoomId,
    email,
    categoryId,
    status,
    firstName,
    lastName,
    doNotSendInvite
  ) {
    // UNFINISHED
    try {
      let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/users`;

      let queryData = JSON.stringify({
        action: 'add',
        parameters: [
          {
            categoryId: categoryId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            topLevelFolder: topLevelFolderId,
            permissions: [],
            name: categoryName,
          },
        ],
      });

      let response = yield this.fetchWrapper(actionsUrl, 'PUT', queryData);

      let completedActions = yield this._waitForActionFromResult.perform(
        response
      );

      // handle specific response when creating a user
      let userData = response.usersData[0];

      let actionResult = completedActions[0];

      let user = null;

      // get reference to the newly created user
      if (userData.newUser) {
        user = yield this.store.queryRecord('smartroom/user', {
          siteId: smartRoomId,
          id: actionResult.resultId,
        });
      } else {
      }

      return user;
    } catch (e) {
      throw new SRError(e.status, e.payload);
    }
  }

  @task
  *createUsersTask(smartRoomId, users, status, doNotSendInvite) {
    try {
      let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/users`;

      let parameters = [];

      users = users.uniqBy('email');

      for (var a = 0; a < users.length; a++) {
        let userData = users[a];

        parameters.push({
          isQAManager: false,
          qaGroupId: null,
          categoryId: userData.categoryId,
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          companyName: userData.roomCompany,
          status: status,
        });
      }

      let queryData = JSON.stringify({
        action: 'add',
        parameters: parameters,
        doNotSendInvitationEmail: doNotSendInvite,
      });

      let response = yield this.fetchWrapper(actionsUrl, 'POST', queryData);

      // ABOVE CALL RETURNS MORE DATA, MAYBE WE CAN PROCESS THEM?
      /*
          {
              "usersData":[
                  {
                      "masterUserId":742,
                      "email":"igor.dvorsky+1@gmail.com",
                      "actionId":57074,
                      "newUser":true,
                      "inviteStatus":1,
                      "status":2
                  }
              ],
              "usersNotAllowedToUseSmartroom":[

              ],
              "comment":"Request to add site user(s) was successful",
              "date":"2020-04-21T14:48:00.8732521Z",
              "actionIds":[
                  57074
              ]
          }
      */

      let completedActions = yield this._waitForActionFromResult.perform(
        response
      );

      return;
    } catch (e) {
      throw new SRError(e.status, e.payload);
    }
  }

  @task
  *updateUsersStatusTask(
    smartRoomId,
    emails,
    status,
    notificationType,
    inviteTemplate,
    customSubject
  ) {
    try {
      emails = emails.uniq();

      let srUsers = (yield this.store.query('smartroom/user', {
        siteId: smartRoomId,
        pageSize: 1000,
      })).toArray();

      let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/users/status`;

      let parameters = [];

      let subject = null;
      let freeForm = false;
      let customMessage = null;
      let languageId = null;
      let emailFromOption = null;
      let siteRedirectUrlId = null;

      if (inviteTemplate) {
        inviteTemplate = yield this.store.queryRecord(
          'smartroom/userinvitetemplate',
          {
            id: inviteTemplate.id,
            siteId: smartRoomId,
          }
        );

        subject = customSubject ? customSubject : inviteTemplate.subject;
        freeForm = inviteTemplate.freeForm;
        customMessage = inviteTemplate.body;
        languageId = inviteTemplate.languageId;
        emailFromOption = inviteTemplate.emailOption;
        siteRedirectUrlId = inviteTemplate.siteRedirectUrlId;
      }

      for (var a = 0; a < emails.length; a++) {
        let email = emails[a].toLowerCase();

        let srUser = srUsers.find((item) => {
          return item.email && item.email.toLowerCase() === email;
        });

        if (srUser) {
          parameters.push({
            masterUserId: srUser.id,
            status: status,
            customMessage: customMessage,
            emailSubject: subject,
            customLogo: null,
            emailFromOption: emailFromOption,
            email: email,
            languageId: languageId,
            freeForm: freeForm,
            siteRedirectUrlId: siteRedirectUrlId,
          });
        }
      }

      let queryData = JSON.stringify({
        notificationOption: notificationType,
        parameters,
      });

      let response = yield this.fetchWrapper(actionsUrl, 'PUT', queryData);

      //let completedActions = yield this._waitForActionFromResult.perform(response);

      return;
    } catch (e) {
      console.log(e);
      throw new SRError(e.status, e.payload);
    }
  }

  @task
  *getRightsForFileTask(smartRoomId, fileId) {
    try {
      let fileSecuritiesURL = `${get(
        this.serverVariables,
        'smartroomApiUrl'
      )}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/files/${fileId}/securities`;

      let response = yield this.fetchWrapper(fileSecuritiesURL, 'GET');

      return response;
    } catch (e) {
      console.log(e);
      throw new SRError(e.status, e.payload);
    }
  }

  @task
  *getRightsForFolderTask(smartRoomId, folderId, filterOption) {
    try {
      let folderSecuritiesURL = `${get(
        this.serverVariables,
        'smartroomApiUrl'
      )}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/folders/${folderId}/securities?filterOption=${filterOption}`;

      let response = yield this.fetchWrapper(folderSecuritiesURL, 'GET');

      return response.rights;
    } catch (e) {
      console.log(e);
      throw new SRError(e.status, e.payload);
    }
  }

  @task
  *sendGenericAlertTask(
    smartRoomId,
    template,
    subject,
    emailFrom,
    emailFromAlias,
    templateData,
    recipients,
    links,
    body
  ) {
    try {
      let srUsers = (yield this.store.query('smartroom/user', {
        siteId: smartRoomId,
        pageSize: 10000,
      })).toArray();

      recipients = recipients.uniqBy('email');

      recipients.forEach((recipient) => {
        let srUser = srUsers.find((item) => {
          return (
            item.email &&
            item.email.toLowerCase() === recipient.email.toLowerCase()
          );
        });

        if (srUser) {
          recipient.userType = 'SmartRoom';
          recipient.masterUserId = srUser.id;
          recipient.addUserIfNotExists = true;
          recipient.categoryId = srUser.categoryId;
          recipient.userStatus = UserStatus.ACTIVE;
        } else {
          recipient.userType = 'Custom';
          recipient.masterUserId = null;
        }
      });

      let createAlertURL = `${get(
        this.serverVariables,
        'smartroomApiUrl'
      )}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/genericalerts`;

      let alertData = {
        alertSubject: subject,
        templateId: template.id,
        templateData,
        recipients,
        links,
      };

      if (body) {
        alertData.textTemplate = body;
      }

      if (emailFrom) {
        alertData.emailFrom = emailFrom;
        alertData.emailFromAlias = emailFromAlias;
      }

      let response = yield this.fetchWrapper(
        createAlertURL,
        'POST',
        JSON.stringify(alertData)
      );

      return;
    } catch (e) {
      console.log(e);
      throw new SRError(e.status, e.payload);
    }
  }

  @task
  *createUserOrUpdateStatusTask(smartRoomId, userData, status) {
    try {
      let srUsers = (yield this.store.query('smartroom/user', {
        siteId: smartRoomId,
        pageSize: 1000,
      })).toArray();

      let srUser = srUsers.find((item) => {
        return (
          item.email &&
          item.email.toLowerCase() === userData.email.toLowerCase()
        );
      });

      // user exists - only update status
      if (srUser) {
        let parameters = [
          {
            masterUserId: srUser.id,
            categoryId: userData.categoryId,
            status: status,
          },
        ];

        // SmartRoom workaround - first request will update user (but not user.status)
        let updateUserUrl = `${get(
          this.serverVariables,
          'smartroomApiUrl'
        )}/${get(
          this.serverVariables,
          'smartroomApiNamespace'
        )}/sites/${smartRoomId}/users`;

        let response = yield this.fetchWrapper(
          updateUserUrl,
          'PUT',
          JSON.stringify({
            action: 'edit',
            parameters,
          })
        );

        yield this._waitForActionFromResult.perform(response);

        // SmartRoom workaround - second request will update user.status (but not categoryId)
        let updateUserStatusUrl = `${get(
          this.serverVariables,
          'smartroomApiUrl'
        )}/${get(
          this.serverVariables,
          'smartroomApiNamespace'
        )}/sites/${smartRoomId}/users/status`;

        response = yield this.fetchWrapper(
          updateUserStatusUrl,
          'PUT',
          JSON.stringify({
            parameters,
            notificationOption: NotificationTypes.NONE,
          })
        );
      }
      // create the user with desired status
      else {
        yield this.createUsersTask.perform(
          smartRoomId,
          [userData],
          status,
          true
        );
        /*
        let actionUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(this.serverVariables, 'smartroomApiNamespace')}/sites/${smartRoomId}/users/status`;

        let parameters = [{
            isQAManager: false,
            qaGroupId: null,
            categoryId: userData.categoryId,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            status: status
        }];
 
        let queryData = JSON.stringify({
            action: "add",
            parameters: parameters,
            doNotSendInvitationEmail: true
        })
 
        let response = yield this.fetchWrapper(actionUrl, "PUT", queryData);
 
        let completedActions = yield this._waitForActionFromResult.perform(response);
        */
      }
    } catch (e) {
      console.log(e);
      throw new SRError(e.status, e.payload);
    }
  }

  @task
  *updateUsersTask(smartRoomId, parameters) {
    try {
      let actionUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
        this.serverVariables,
        'smartroomApiNamespace'
      )}/sites/${smartRoomId}/users`;

      let queryData = JSON.stringify({
        action: 'edit',
        parameters: parameters,
      });

      let response = yield this.fetchWrapper(actionUrl, 'PUT', queryData);

      let completedActions = yield this._waitForActionFromResult.perform(
        response
      );
    } catch (e) {
      console.log(e);
      throw new SRError(e.status, e.payload);
    }
  }

  @task
  *setCategorySecurityTask(smartRoomId, categoryId, folderSecurities) {
    let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${smartRoomId}/categories/${categoryId}/securities?categoryId=${categoryId}`;

    let queryData = JSON.stringify({
      action: 'folder-rights',
      categoryId: categoryId,
      parameters: folderSecurities,
    });

    let response = yield this.fetchWrapper(actionsUrl, 'PUT', queryData);

    let completedActions = yield this._waitForActionFromResult.perform(
      response
    );

    return completedActions;
  }

  @task
  *genericAlertTemplatePreviewTask(
    smartRoomId,
    genericAlertModel,
    userName,
    email,
    siteName
  ) {
    let url = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${smartRoomId}/alert-templates/preview`;

    let queryData = {
      alertSettingTypeId: genericAlertModel.alertTemplate.alertSettingTypeId,
      customTemplateText: genericAlertModel.alertTemplate.body,
      templateId: genericAlertModel.alertTemplate.id,
      userName: userName,
      email: email,
      siteName: siteName,
      //'siteRedirectUrlId': fullTemplate.siteRedirectUrlId
    };

    let previewHtml = yield this.fetchWrapper(
      url,
      'POST',
      JSON.stringify(queryData)
    );

    return previewHtml;
  }

  @task
  *inviteTemplatePreviewTask(
    smartRoomId,
    inviteTemplate,
    userName,
    email,
    siteName
  ) {
    let fullTemplate = yield this.store.queryRecord(
      'smartroom/userinvitetemplate',
      {
        id: inviteTemplate.id,
        siteId: smartRoomId,
      }
    );

    let url = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/sites/${smartRoomId}/userinvitetemplates/preview`;

    let queryData = {
      customTemplateText: fullTemplate.body,
      userName: userName,
      email: email,
      siteName: siteName,
      freeForm: fullTemplate.freeForm,
      siteRedirectUrlId: fullTemplate.siteRedirectUrlId,
    };

    let previewHtml = yield this.fetchWrapper(
      url,
      'POST',
      JSON.stringify(queryData)
    );

    return previewHtml;
  }

  // SMARTROOM ACTIONS HANDLING
  @task
  *getSingleActionResultTask(actionId) {
    let action = this.createActionDetail(actionId);

    return yield this._checkActionsResult.perform([action], []);
  }

  @task
  *_waitForActionFromResult(response, record, waitForSecurity = false) {
    // abort if actions are not present in response
    if (!response || !response.actionIds || !response.actionIds.length) {
      let error = new Error('Action service: No action in response');
      error.code = 1000;
      throw error;
    }

    let actions = [];
    let completedActions = [];

    let newAction;

    // process all action IDs
    for (var a = 0; a < response.actionIds.length; a++) {
      newAction = this.createActionDetail(response.actionIds[a]);
      // action is already completed (ie no real action was generated)
      if (newAction.actionId === -1) {
        completedActions.push(newAction);
      } else {
        actions.push(newAction);
      }
    }

    return yield this._checkActionsResult.perform(actions, completedActions);
  }

  @task
  *_checkActionsResult(actions, completedActions) {
    // define local vars
    let actionsUrl = `${get(this.serverVariables, 'smartroomApiUrl')}/${get(
      this.serverVariables,
      'smartroomApiNamespace'
    )}/actions/status`;
    let actionsStatus = null;
    let action = null;
    let actionResult = null;

    while (actions.length) {
      // throttle action result checking
      yield timeout(1500);

      try {
        // get the status for all actions
        actionsStatus = yield this.fetchWrapper(
          actionsUrl + `?ids=${actions.mapBy('actionId').join(',')}`
        );

        // process all results
        for (var a = 0; a < actionsStatus.length; a++) {
          actionResult = actionsStatus[a];

          // find the action by actionId from response
          action = actions.findBy('actionId', actionResult.actionId);

          // handle successful action
          if (
            actionResult.securityCalcCompleted &&
            (actionResult.status === 99 ||
              actionResult.status === 90 ||
              actionResult.status === 2)
          ) {
            // copy result
            action.resultId = actionResult.resultId;
            action.status = actionResult.status;
            action.securityCalcCompleted = actionResult.securityCalcCompleted;

            // action is completed, move it to completed actions list
            actions.removeObject(action);
            completedActions.addObject(action);
          }
        }
      } catch (e) {
        console.error(e);
        throw new Error('Retrieving action status failed.');
      }
    }

    // all actions are finished, return the result
    if (!actions.length) {
      return completedActions;
    }
  }
}
