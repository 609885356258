import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames("popup-footer", 'popup-drag')
export default class BottomFooter extends Component {
  @action
  closeClick() {

  }
}
