import Component from '@ember/component';

let getYears = function (startYear) {
  let currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(String(startYear++));
  }
  return years;
};

export default Component.extend({
  tagName: "",
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  years: getYears(),

  actions: {
    changeCenter(unit, calendar, e) {
      let step = e.target.value;

      let newCenter = null;

      let c = calendar.center.clone();

      // 'step' for 'year' is always a number
      if (unit === 'year' || isNaN(step)) {
        newCenter = c[unit](step);
      } else {
        newCenter = c.add(Number(step), unit);
      }

      calendar.actions.changeCenter(newCenter);
      this.sendAction("applyDate", newCenter);
    },
  },

});
