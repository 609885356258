import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import PersonInCompany from 'cing-app/models/person-in-company';
import { task } from 'ember-concurrency'
import { taskFor } from 'ember-concurrency-ts';

interface ColumnEmailsArgs {
  record: PersonInCompany;
}

export default class ColumnEmails extends Component<ColumnEmailsArgs> {

  @tracked
  emails: any;


  constructor(owner: any, args: ColumnEmailsArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    let person = await this.args.record.person;
    let emails = await person.emails;

    let emailAddresses = emails.mapBy('emailAddress');
    this.emails = emailAddresses.join(', ');
  })
}