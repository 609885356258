import { helper as buildHelper } from '@ember/component/helper';
import { CDPChapters } from '../utils/lookups'

export function formatChapterType([value, ...rest]) {
  const type = Object
    .keys(CDPChapters)
    .map(key => CDPChapters[key])
    .filter(type => type.id === value)

  return type.length ? type[0].name : 'N/A';
}

export default buildHelper(formatChapterType);
