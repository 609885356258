import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Faq from 'cing-app/models/faq';
import DockerItemService from 'cing-app/pods/docker-item/service';
import {
  ExpressionOperators,
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import Project from 'cing-app/models/project';
import { get, action, computed } from '@ember/object';
import PortalPageModel from 'cing-app/models/portal-page';
import FaqCategory from 'cing-app/models/faq-category';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';

interface FaqByCategoryInterface {
  categoryId: string;
  categoryTitle: string;
  faqs: Faq[];
}

interface ProjectDetailFaqsArgs {
  project?: Project;
  portalPage?: PortalPageModel;
}

export default class ProjectDetailFaqs extends Component<ProjectDetailFaqsArgs> {
  @service store!: Store;

  @service session: any;

  @service('docker-item') docker!: DockerItemService;

  @tracked
  faqs: Faq[] = [];

  @tracked
  faqsByCategory: FaqByCategoryInterface[] | null = null;

  @tracked
  editFAQ: Faq | null = null;

  @tracked
  faqCategories: FaqCategory[] | null = null;

  @tracked
  selectedCategory: FaqByCategoryInterface | null | undefined = null;

  @tracked
  selectedFaq: Faq | undefined | null = null;

  @tracked
  searchText: string | null = '';

  // @tracked
  // condition: any = null;

  maxFaqsInBlock = 5;

  Faq = Faq;

  constructor(owner: any, args: ProjectDetailFaqsArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @computed('args.project', 'args.portalPage', 'searchText', 'selectedCategory')
  get exportCondition() {
    let exportCondition = this.createCondition();
    if (this.selectedCategory) {
      exportCondition.add(
        Filter.create({
          name: 'category-id',
          operator: FilterOperators.EQUAL,
          value: this.selectedCategory.categoryId,
        })
      );
    }

    return exportCondition.serialize();
  }

  get styleNamespace() {
    return podNames['project-detail/faqs'];
  }

  createCondition() {
    let faqFilter = Expressions.create();
    if (this.args.project) {
      faqFilter.add(
        Filter.create({
          name: 'project-id',
          operator: FilterOperators.EQUAL,
          value: get(this.args.project, 'id'),
        })
      );
    }
    if (this.args.portalPage) {
      faqFilter.add(
        Filter.create({
          name: 'portal-page-id',
          operator: FilterOperators.EQUAL,
          value: get(this.args.portalPage, 'id'),
        })
      );
    }

    if (this.searchText) {
      let searchExpr = Expressions.create({ operator: ExpressionOperators.OR });
      searchExpr.add(
        Filter.create({
          name: 'answer',
          operator: FilterOperators.LIKE,
          value: this.searchText,
        })
      );
      searchExpr.add(
        Filter.create({
          name: 'title',
          operator: FilterOperators.LIKE,
          value: this.searchText,
        })
      );
      faqFilter.add(searchExpr);
    }
    return faqFilter;
  }

  @computed('args.project', 'args.portalPage', 'searchText')
  get condition() {
    return this.createCondition();
  }

  @task
  initTask = taskFor(async () => {
    await this.loadFaqs.perform();
  });

  @task
  loadFaqs = taskFor(async () => {
    let faqs = await this.store.query('faq', {
      condition: this.condition.serialize(),
      sort: 'order',
      page: { size: 1000 },
      include: 'category',
    });

    let faqCategories = await this.store.query('faq-category', {
      sort: 'order',
      page: { size: 1000 },
    });

    this.faqCategories = faqCategories.toArray();

    let faqCategoryIds = this.faqCategories.map((fc) => fc.id);

    this.faqs = faqs?.toArray();

    let faqsByCategory: FaqByCategoryInterface[] = [];

    faqCategories.forEach((fc) => {
      faqsByCategory.pushObject({
        categoryId: fc.id,
        categoryTitle: fc.title,
        faqs: this.faqs.filter((f) => f.categoryId === fc.id),
      });
    });

    let faqsWithUnknownCategory = this.faqs.filter(
      (f) => !faqCategoryIds.includes(f.categoryId)
    );

    if (faqsWithUnknownCategory) {
      faqsByCategory.pushObject({
        categoryId: '',
        categoryTitle: 'Others',
        faqs: faqsWithUnknownCategory,
      });
    }

    if (this.selectedCategory) {
      this.selectedCategory = faqsByCategory.findBy(
        'categoryId',
        this.selectedCategory.categoryId
      );
      this.selectedFaq = this.selectedCategory?.faqs.firstObject;
    } else {
      this.selectedCategory = null;
    }

    this.faqsByCategory = faqsByCategory;
  });

  @action
  refreshFaqs() {
    this.loadFaqs.perform();
  }

  @action
  search(e: Event) {
    e.preventDefault();
    this.refreshFaqs();
  }

  @action
  clearSearch() {
    this.searchText = null;
    this.refreshFaqs();
  }

  @action
  showFaq(faq: Faq, faqCategory: FaqByCategoryInterface) {
    this.selectedFaq = faq;
    this.selectedCategory = faqCategory;
  }
}
