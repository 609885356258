import Model, { attr } from '@ember-data/model';
// import { computed } from '@ember/object';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
});

export default Model.extend(Validations, {
  invFundId: attr('string'),
  eventName: attr('string'),
  eventId: attr('string'),
  noticeDate: attr('abdate2'),
  callPercOfTotal: attr('number'),
  dueDate: attr('abdate2'),
  description: attr('string'),
  amount: attr('number'),
  eventStatus: attr('string'),
  fundId: attr('string'),
  current: attr('string'),
  investorNumber: attr('string'),
  investorName: attr('string'),
  type: attr('string'),
  prefix: attr('string'),
  suffix: attr('string'),
  fileId: attr('number'),
  fileName: attr('string'),
});
