import { attr } from '@ember-data/model';
import Abstract from './abstract';

export default class AlertAnnouncementRecipient extends Abstract {
  @attr('string') declare firstName?: string;
  @attr('string') declare middleName?: string;
  @attr('string') declare lastName?: string;
  @attr('string') declare email?: string;
  @attr('string') declare body?: string;
  @attr('string') declare companyName?: string;
  @attr('date') declare deliveredAt?: Date;
  @attr('jsonb') declare deliveredData?: any;
  @attr('date') declare bouncedAt?: Date;
  @attr('jsonb') declare bouncedData?: any;
  @attr('date') declare openedAt?: Date;
  @attr('date') declare openedData?: Date;
  @attr('string') declare alertAnnouncementId: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'alert-announcement-recipient': AlertAnnouncementRecipient;
  }
}
