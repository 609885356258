import Component from '@glimmer/component';
import { UserStatus } from 'cing-app/pods/smartroom/service';
import { DataSourceColumn } from 'smex-ui-table';
import InterestedParty from 'cing-app/models/interested-party';

interface ColumnSrstatusArgs {
  row: InterestedParty,
  column: DataSourceColumn<any>
}

export default class ColumnSrstatus extends Component<ColumnSrstatusArgs> {
  UserStatus = UserStatus;

  // @computed('column.allSmartRoomUsers.[]', 'column.allSmartRoomUsers.@each.status')
  get srStatus() {
    if (this.args.row.emailId && this.args.column.options.allSmartRoomUsers) {
      let allSmartRoomUsers = this.args.column.options.allSmartRoomUsers.toArray();

      let emailAddress = this.args.row.get('email.emailAddress');
      if (emailAddress) {
        for (var a = 0; a < allSmartRoomUsers.length; a++) {
          let user = allSmartRoomUsers[a];

          if (user.email.toLowerCase() === emailAddress.toLowerCase()) {
            return user.status;
          }
        }
      }
    }

    return null;
  }

}