import Component from '@glimmer/component';
import {
  ExpressionOperators,
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import InterestedParty from 'cing-app/models/interested-party';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { get, action } from '@ember/object';
import Project from 'cing-app/models/project';
import {
  CounterpartyMilestoneTypes,
  TargetRoles,
} from 'cing-app/utils/lookups';
import { ApiDataSource, Paged, DataSourceColumn } from 'smex-ui-table';

interface ProjectDetailTargetsArgs {
  model: Project;
}

const Priorities = { LOW: 'Low', MEDIUM: 'Medium', HIGH: 'High' };
export default class ProjectDetailTargets extends Component<ProjectDetailTargetsArgs> {
  @service
  store!: Store;
  @service
  session!: any;
  @service
  config!: any;

  @tracked
  dataSource!: ApiDataSource<InterestedParty>;

  @tracked
  selectedItems: InterestedParty[] | null = null;

  @tracked
  searchQuery = '';

  @tracked
  selectedMilestone: string | null = null;

  @tracked
  selectedPriority: string | null = null;

  @tracked
  selectedTargetRole: string | null = null;

  milestoneTypes = Object.values(CounterpartyMilestoneTypes);

  priorities = Object.values(Priorities);

  targetRoles = Object.values(TargetRoles);

  constructor(owner: any, args: ProjectDetailTargetsArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  get columns() {
    let columns = [
      new DataSourceColumn<InterestedParty>({
        id: 'company.name',
        label: 'Company',
        getValue: (row) => get(row, 'company.name'),
        valueComponent: 'table-text-column',
        sortingEnabled: true,
        minWidth: 200,
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'extendedData.role',
        label: 'Role',
        getValue: (row) => get(row, 'extendedData.role'),
        valueComponent: 'table-text-column',
        sortingEnabled: true,
        minWidth: 120,
        maxWidth: 120,
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'extendedData.milestone',
        label: 'Milestone',
        getValue: (row) => get(row, 'extendedData.milestone'),
        valueComponent: 'table-text-column',
        sortingEnabled: true,
        minWidth: 150,
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'extendedData.nda-signed-date',
        label: 'NDA',
        getValue: (row) => get(row, 'extendedData.nda-signed-date'),
        valueComponent: 'table-text-column',
        sortingEnabled: true,
        minWidth: 150,
        maxWidth: 150,
        options: {
          format: {
            style: 'date',
            dateStyle: 'medium',
            // style: 'date',
            // day: '2-digit',
            // month: '2-digit',
            // year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'extendedData.priority',
        label: 'Priority',
        getValue: (row) => get(row, 'extendedData.priority'),
        valueComponent: 'table-text-column',
        sortingEnabled: true,
        minWidth: 100,
        maxWidth: 100,
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'extendedData.days-in-current-milestone',
        label: 'Days In Current Milestone',
        getValue: (row) => get(row, 'extendedData.days-in-current-milestone'),
        valueComponent: 'table-text-column',
        sortingEnabled: true,
        minWidth: 100,
      }),
    ];

    return columns;
  }

  get generateFilter() {
    let expr = Expressions.create();
    expr.add(
      Filter.create({
        //@ts-ignore
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.args.model.id,
      })
    );

    expr.add(
      Filter.create({
        //@ts-ignore
        name: 'interested-party-type.name',
        operator: FilterOperators.EQUAL,
        //@ts-ignore
        value: 'Counterparty',
      })
    );

    if (this.searchQuery) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'company.name',
          operator: FilterOperators.LIKE,
          //@ts-ignore
          value: this.searchQuery,
        })
      );
    }

    if (this.selectedTargetRole) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'role',
          operator: FilterOperators.EQUAL,
          //@ts-ignore
          value: this.selectedTargetRole,
        })
      );
    }

    if (this.selectedMilestone) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'milestone',
          oeprator: FilterOperators.EQUAL,
          //@ts-ignore
          value: this.selectedMilestone,
        })
      );
    }

    if (this.selectedPriority) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'priority',
          oeprator: FilterOperators.EQUAL,
          //@ts-ignore
          value: this.selectedPriority,
        })
      );
    }

    return expr.serialize();
  }

  @task
  initTask = taskFor(async () => {
    this.dataSource = new ApiDataSource<InterestedParty>(
      50,
      false,
      'ProjectTargets',
      this.session.authUser.email,
      this.loadData,
      100,
      this.columns,
      this.selectedItems,
      {}
    );
    this.dataSource.selectionEnabled = false;
  });

  @task
  loadData = taskFor(
    async (
      columns: DataSourceColumn<InterestedParty>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });
      let condition = this.generateFilter;

      let query: any = {
        condition: condition,
        include: 'company',
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
      };
      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let ips = await this.store.query('interested-party', query);
      let targets = <Paged<InterestedParty>>ips.toArray();
      targets.meta = {
        totalCount: ips.meta['total-count'],
      };

      return targets;
    }
  );

  @action
  search() {
    this.dataSource.refresh();
  }

  @action
  searchByMilestone(milestone: string) {
    this.selectedMilestone = milestone;
    this.dataSource.refresh();
  }

  @action
  searchByPriority(priority: string) {
    this.selectedPriority = priority;
    this.dataSource.refresh();
  }

  @action
  searchByTargetRole(role: string) {
    this.selectedTargetRole = role;
    this.dataSource.refresh();
  }
}
