import { attr } from '@ember-data/model';
//@ts-ignore
import { fragment } from 'ember-data-model-fragments/attributes';
import Abstract from './abstract';
import ExtendedDataMappingConfig from './extended-data-mapping-config';

export default class ExtendedDataMapping extends Abstract {
  @attr('string')
  declare type: string;
  @attr('string')
  declare entityName: string;
  @attr('string')
  declare source: string;
  // @attr('string')
  // declare extendedData: string;
  @attr('number')
  declare count: number;
  @fragment('extended-data-mapping-config')
  declare config: ExtendedDataMappingConfig;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'extended-data-mapping': ExtendedDataMapping;
  }
}
