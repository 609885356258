import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';
import { action, set } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';

import { inject as service } from '@ember/service';
import SessionService from 'cing-app/pods/session/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import { tracked } from '@glimmer/tracking';
import {
  BaseFile,
  BaseFolder,
  BulkDownload,
  DownloadInfo,
  FolderContainer,
  FolderFileCache,
  ISearchFilter,
  ISearchSorting,
  ReturnType,
  SearchBy,
  SearchIn,
  SortBy,
  Structure,
} from 'smex-ui-sr-filemanager';
import {
  Models,
  MyInfoResource,
  SmartroomFileModel,
  SmartroomFolderModel,
} from 'smex-ui-sr-models';
import Store from '@ember-data/store';
import { getAgreementSessionProperty } from '../access-agreement/component';
import BottomWindows, {
  BottomWindowComponent,
} from 'cing-app/pods/bottom-windows/service';

enum ViewActionTypes {
  NORMAL = 0,
  SAVE_TO_DISK = 1,
  OFFICE_365 = 2,
}

interface IArgs {
  siteId: number;
  categoryId: number;
  startFolderId: number;
  checkAccessAgreement: boolean;
  viewMode: SmartroomFile['viewMode'];
  selectMode: SmartroomFile['selectMode'];
  onSelect?: () => void;
}

export default class SmartroomFile extends Component<IArgs> {
  @service('bottom-windows')
  bottomWindows!: BottomWindows;
  @service('store') store!: Store;
  @service('models') models!: Models;
  @service('folder-file-cache') folderFileCache!: FolderFileCache;
  @service serverVariables!: any;
  @service bulkDownload!: BulkDownload;
  @service session!: SessionService;
  @service dockerItem!: DockerItemService;

  @tracked
  searchSelectedItems: (SmartroomFileModel | SmartroomFolderModel)[] = [];

  myInfoResource!: MyInfoResource;

  @tracked
  error: string | null | object = null;

  @tracked
  initError: Error | null = null;

  @tracked
  structure: Structure | null = null;

  @tracked
  downloadInfo: DownloadInfo | null = null;

  @tracked
  downloadError = '';

  @tracked
  accessAgreement = null;

  @tracked
  viewMode: 'tree' | 'split' | 'files' | 'search' = 'split';

  @tracked
  selectMode: 'folders' | 'files' | 'all' = 'all';
  @tracked
  filter!: ISearchFilter;

  @tracked
  sorting: ISearchSorting = {
    sortBy: SortBy.name,
    sortDesc: false,
  };

  @tracked
  allTags!: string[];

  get paddingEmptyFileInTree() {
    if (this.args.onSelect) {
      return 15;
    }
    if (this.selectMode === 'all' || this.selectMode === 'folders') {
      return 32;
    } else {
      return 15;
    }
  }

  constructor(owner: any, args: IArgs) {
    super(owner, args);

    this.createDefaultFilter();

    if (args.viewMode) {
      this.viewMode = args.viewMode;
    }

    this.structure = null;
    this.downloadInfo = null;

    if (args.selectMode) {
      this.selectMode = args.selectMode;
    }

    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    try {
      if (!this.args.siteId) {
        let err = new Error('Unknown SmartRoom');
        //@ts-ignore
        err.status = '30000';
        throw err;
      }

      this.myInfoResource = new MyInfoResource(
        this.models,
        this.args.siteId.toString()
      );

      await this.session.authenticate('authenticator:torii', 'si', {
        silent: true,
      });

      if (this.args.checkAccessAgreement) {
        let accessAgreement = await this.store.queryRecord(
          'smartroom/access-agreement-view',
          {
            siteId: this.args.siteId,
          }
        );

        if (
          accessAgreement &&
          accessAgreement.get('id') !== '-1' &&
          (accessAgreement.get('requiredAccepts') === -1 ||
            accessAgreement.get('numberOfAccepts') <
              accessAgreement.get('requiredAccepts'))
        ) {
          if (
            !window.sessionStorage.getItem(
              getAgreementSessionProperty(
                this.args.siteId,
                accessAgreement.get('id')
              )
            )
          ) {
            this.accessAgreement = accessAgreement;
          }
        }
      }
      this.allTags = await this.searchTags.perform('');
    } catch (e) {
      console.log('ERROR: ', e);
      this.initError = e;
      return null;
    }
  });

  @action
  uploadStructure(folder: FolderContainer, event: Event) {
    this.dragLeave(event);
    event.preventDefault();
    //@ts-ignore
    const context = {
      event: event,
      siteId: this.args.siteId,
      folderId: folder.model.id,
      folderName: folder.model.name,
    };

    this.bottomWindows.open(
      new BottomWindowComponent('smartroom/upload-progress', context)
    );
  }

  @action
  dragOver(event: Event) {
    event.preventDefault();
    let dropArea = <HTMLDivElement>event.currentTarget;
    dropArea.classList.add('files-drag-over');
  }

  @action
  dragLeave(event: Event) {
    let dropArea = <HTMLDivElement>event.currentTarget;
    dropArea.classList.remove('files-drag-over');
  }

  @action
  clearSelectedItems() {
    this.searchSelectedItems = [];
  }

  @action
  clearText() {
    set(this.filter, 'q', '');
  }

  @action
  clearStructureSelectedItems() {
    if (this.structure) {
      this.structure.topLevelFolder.selectUnselect(false);
    }
  }

  @action
  createDefaultFilter() {
    let returnType: ReturnType;
    switch (this.selectMode) {
      case 'all':
        returnType = ReturnType.both;
        break;
      case 'files':
        returnType = ReturnType.file;
        break;
      case 'folders':
        returnType = ReturnType.folder;
        break;
    }
    this.filter = {
      folderId: '-1',
      q: '',
      searchIn: SearchIn.names,
      searchBy: SearchBy.anyWords,
      excludeFileTypes: '',
      includeFileTypes: '',
      tags: [],
      tagsMatchAll: true,
      returnItemType: returnType,
    };
  }

  @action
  setViewMode(viewMode: this['viewMode']) {
    this.viewMode = viewMode;
  }

  get selectedItems(): object {
    if (this.structure && this.structure.topLevelFolder) {
      return Array.from(this.structure.topLevelFolder.getSelectedItems());
    }

    return [];
  }

  @action
  reloadAfterError(reloadContentIndex: () => void) {
    this.folderFileCache.clear();
    reloadContentIndex();
  }

  @task
  fileClickTask = taskFor(async (file: SmartroomFileModel) => {
    let viewerInfo = null;
    try {
      let host = this.serverVariables.get('smartroomApiUrl');
      let namespace = this.serverVariables.get('smartroomApiNamespace');

      let version = file.versionNo || '-1';
      let url = `${host}/${namespace}/sites/${this.args.siteId}/files/${file.id}/viewerinfo?versionNo=${version}`;
      let viewerInfoResult: Response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${this.session.data.authenticated.access_token}`,
        },
      });

      viewerInfo = await viewerInfoResult.json();
    } catch (err) {
      if (err.payload && err.payload.message) {
        this.error = err.payload.message;
      }
      console.error(err);
      return;
    }

    switch (viewerInfo.viewActionType) {
      case ViewActionTypes.NORMAL:
        {
          let appearance = {
            icon: 'file',
            title: file.name,
            size: 'large',
            custom: true,
          };

          this.dockerItem.invokePopup('smartroom/file-viewer', appearance, {
            file: file,
            siteId: this.args.siteId,
          });
        }
        break;
      case ViewActionTypes.SAVE_TO_DISK:
        this.downloadTask.perform(viewerInfo.url);
        break;
      case ViewActionTypes.OFFICE_365:
        window.open(viewerInfo.url);
        break;
    }

    return;
  });

  @task
  downloadTask = taskFor(async (url: string) => {
    var iframe = $('<iframe/>').appendTo($('#downloadiframes'));
    iframe.attr('src', url);

    await timeout(2000);

    let htmlContents = iframe.contents().find('html').html();
    if (
      htmlContents &&
      htmlContents.toLocaleLowerCase().indexOf('error') !== -1
    ) {
      this.error = 'File does not exist.';
    } else {
      let errorText = $('body>pre', iframe.contents()).text();
      if (errorText) {
        this.error = JSON.parse(errorText);
      }
    }
  });

  @action
  viewFile(file: any) {
    console.log('ARGUMENTS: ', arguments);
    this.fileClickTask.perform(file);
  }

  @action
  cancelDownload() {
    if (!this.structure || !this.downloadInfo) {
      return;
    }

    this.bulkDownload
      .cancel(this.structure.siteId, this.downloadInfo)
      .catch((err) => {
        if (!this.isDestroyed) {
          this.downloadError = err.message || err.payload.message;
        }
      });
  }

  @action
  structureCreated(structure: any) {
    this.structure = structure;
  }

  @action
  searchInputTrim() {
    if (this.filter.q) {
      this.filter.q = this.filter.q.trim();
    }
  }

  @action
  downloadSelectedItems() {
    if (!this.structure) {
      return;
    }

    let searchFileIds = this.searchSelectedItems
      .filter((i) => i instanceof SmartroomFileModel)
      .map((f) => f.id);
    let searchFolderIds = this.searchSelectedItems
      .filter((i) => i instanceof SmartroomFolderModel)
      .map((f) => f.id);
    this.searchSelectedItems = [];

    let selectedItems = <(BaseFile | BaseFolder)[]>(
      Array.from(this.structure.topLevelFolder.getSelectedItems())
    );
    this.structure.topLevelFolder.selectUnselect(false);
    let fileIds = selectedItems
      .filter((f) => f instanceof BaseFile)
      .map((f) => f.id);
    let folderIds = selectedItems
      .filter((f) => f instanceof BaseFolder)
      .map((f) => f.id);
    this.downloadInfo = this.bulkDownload.createBulkDownload(
      this.structure.siteId,
      [...fileIds, ...searchFileIds],
      [...folderIds, ...searchFolderIds]
    );

    if (this.downloadInfo.promise) {
      this.downloadInfo.promise
        .catch((err) => {
          if (!this.isDestroyed) {
            this.downloadError =
              (err.errors ? err.errors[0].title : null) ||
              err.message ||
              err.payload?.message;
          }
        })
        .finally(() => {
          if (!this.isDestroyed) {
            this.downloadInfo = null;
          }
        });
    }
  }

  @action
  tagsChanged(tags: string[]) {
    this.clearStructureSelectedItems();
    this.clearSelectedItems();
    this.filter = {
      ...this.filter,
      tags: tags,
    };

    this.viewMode = 'search';
  }

  @task
  searchTags = taskFor(async (term: string) => {
    if (term) {
      term = term.toLowerCase();
    }

    let tags = await this.store.query(
      this.models.getModelNameSite('smartroom/tag', this.args.siteId),
      {
        pageNo: 1,
        pageSize: 100,
        q: term,
      }
    );
    return tags.map((t) => t.tag);
  });
}
