import Component from '@glimmer/component';
import ProjectTabSettingsContacts from 'cing-app/models/project-tab-settings-contacts';
import ApiDataSource from 'smex-ui-table';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
//@ts-ignore
import { cached } from 'tracked-toolbox';
import { DataSourceColumn } from 'smex-ui-table';
import { ExpressionOperators, Expressions, Filter, FilterOperators } from 'cing-app/mixins/filter-builder';
import Store from '@ember-data/store';
import ProjectTabSettingsContactsField from 'cing-app/models/project-tab-settings-contacts-field';
import { action } from '@ember/object';
import moment from 'moment';
import { dasherize } from '@ember/string';
import DockerItemService from 'cing-app/pods/docker-item/service';
import DataLink from 'cing-app/models/data-link';
import DataLinkView from 'cing-app/models/data-link-view';
import DataLinkContextFilter from 'cing-app/models/data-link-context-filter';
import { get } from '@ember/object';
import DataLinkViewColumn from 'cing-app/models/data-link-view-column';

interface DataLinkViewListArgs {
  dataLink: DataLink;
  dataLinkView: DataLinkView;
  contextObject?: any;
  filters?: object[]
}

export default class DataLinkViewList extends Component<DataLinkViewListArgs> {
  @service
  session: any;
  @service('store')
  store!: Store;
  @service('docker-item')
  docker!: DockerItemService;

  @tracked
  query: any;
  @tracked
  advancedQuery: any;

  @tracked
  filterFormDefinition: any;
  @tracked
  advancedFilterFormDefinition: any;
  @tracked
  filter = {};
  @tracked
  advancedFilter = {};

  @tracked
  advancedFilterOpened = false;
  @tracked
  dataSource: ApiDataSource<any>;

  @cached
  get columns() {
    let cols: DataSourceColumn<any>[] = [];

    let availableColumns = this.args.dataLinkView.columns.sortBy('sortOrder');

    for (let column of availableColumns) {
      cols.pushObject(new DataSourceColumn({
        id: column.propertyName,
        label: column.label,
        getValue: (row: any) => {
          return get(row, column.propertyName);
        },
        hidden: !column.defaultVisible,
        valueComponent: 'table-text-column',
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        sortingEnabled: column.sorting,
        headerClass: this.getAlignHeaderClass(column.textAlign),
        valueClass: this.getAlignClass(column.textAlign) + ' ' + (column.wrapText ? '' : 'text-nowrap'),
        options: {
          maxHeight: column.maxHeight,
          format: column.format
        }
      }))
    }

    return cols;
  }

  constructor(owner: unknown, args: DataLinkViewListArgs) {
    super(owner, args);

    this.dataSource = new ApiDataSource<any>(50, false, this.args.dataLinkView.modelName, this.session.authUser.email, this.loadDataTask, 100, this.columns, null, {});
    this.createFilterDefinition();
  }

  @task
  loadDataTask = taskFor(async (columns, pageSize, pageNumber, options) => {
    let sortColumn = columns.find((col: any) => {
      return col.sort;
    });

    let include: string[] = [];

    columns.forEach((column: DataSourceColumn<any>) => {
      let propertyNamePath = column.id.split('.');

      if (propertyNamePath.length > 1 && propertyNamePath[0] !== 'extendedData' && propertyNamePath[0] !== undefined && !include.includes(propertyNamePath[0])) {
        include.pushObject(propertyNamePath[0]);
      }
    });

    let query: any = {
      page: {
        size: pageSize,
        number: pageNumber
      }
    }

    if (include) {
      query['include'] = include.join(',')
    }

    let condition = Expressions.create({
      operator: ExpressionOperators.AND
    });

    if (this.args.dataLinkView.filters) {
      this.args.dataLinkView.filters.forEach((filter) => {
        condition.add(Filter.create({
          name: dasherize(filter.property),
          operator: filter.operator,
          value: filter.value
        }))
      })
    }

    if (this.args.filters) {
      this.args.filters.forEach((filter: DataLinkContextFilter) => {
        condition.add(Filter.create({
          name: dasherize(filter.property),
          operator: filter.operator,
          value: filter.value
        }))
      })
    }

    if (this.query) {
      condition.add(this.query);
    }
    if (this.advancedQuery) {
      condition.add(this.advancedQuery);
    }

    if (sortColumn) {
      query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortColumn.id}`
    }

    query.condition = condition.serialize();

    let rows = await this.store.query(this.args.dataLinkView.modelName, query);

    let result = rows.toArray();

    //@ts-ignore
    result.meta = {
      totalCount: rows.meta['total-count']
    };

    return result;
  });

  @action
  getActions(/*row:any - if actions depends on row data then it is used*/) {
    let actions = [];

    actions.push(
      {
        label: 'View',
        icon: 'eye',
        action: this.viewRecord
      });

    return actions;

    if (this.layoutDefinition) {
      actions.push(
        {
          label: 'View',
          icon: 'eye',
          action: this.viewRecord
        });
    }
    if (this.formDefinition) {
      actions.push({
        // disabledMessage: 'Sorry',
        label: 'Edit',
        icon: 'pencil-alt',
        action: this.editRecord
      });
      actions.push({
        label: 'Remove',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (item: any) => {
          this.recordToRemove = item;
        }
      });
    } else {
      actions.push({
        label: 'Remove',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (item: any) => {
          this.recordToRemove = item;
        }
      });
    }
    return actions;
  }

  @action
  viewRecord(record: any) {
    let dataLinkModel = this.args.dataLink.models.filter((model) => {
      return model.modelName === this.args.dataLinkView.modelName;
    }).firstObject;

    let detailView = this.args.dataLink.views.filter((view) => {
      return view.type === "detail" && view.modelName === this.args.dataLinkView.modelName;
    }).firstObject;

    const appearance = {
      label: 'Add DataLink',
      icon: '',
      title: 'Add DataLink',
      custom: true,
      size: 'large'
    };

    this.docker.invokePopup('manage-data-links/view/detail', appearance, {
      contextObject: record,
      dataLink: this.args.dataLink,
      dataLinkView: detailView,
      dataLinkModel: dataLinkModel
    });
  }

  createFilterDefinition() {
    let buttons =
    {
      "customClass": "text-right",
      "key": "fieldset",
      "type": "fieldset",
      "label": "",
      "input": false,
      "tableView": false,
      "components": [
        {
          "label": "Search",
          "showValidations": false,
          "customClass": "d-inline-block",
          "tableView": false,
          "type": "button",
          "input": true,
          "saveOnEnter": false,
          "hideOnChildrenHidden": false,
          "action": "event",
          event: 'customSubmit'
        },
        {
          "label": "Clear",
          "showValidations": false,
          "customClass": "d-inline-block",
          "tableView": false,
          "type": "button",
          "input": true,
          "saveOnEnter": false,
          "theme": "secondary",
          "action": "event",
          event: 'customReset'
        }
      ]
    };

    let filterForm = {
      "display": "form",
      "components": []
    };
    let advancedFilterForm = {
      "display": "form",
      "components": [{
        "label": "Text Field",
        "placeholder": "Search Properties",
        "hideLabel": true,
        "tableView": true,
        "key": "__search",
        "type": "textfield",
        "input": true
      }]
    };

    let availableColumns = this.args.dataLinkView.columns.sortBy('displayOrder');

    for (let column of availableColumns) {
      if (column.advancedFilter) {
        let comp = this.createFilterInput(column);
        if (comp.validate) {
          comp.validate.required = false;
        }
        if (comp.type === 'email') {
          comp.type = 'textfield';
        }
        let newTab = {
          "customConditional": `show = (!data.__search || '${column.label}'.toLowerCase().includes(data.__search.toLowerCase()))`,
          "collapsible": true,
          "key": "panel",
          "type": "panel",
          "label": "Advanced Filter",
          "input": false,
          "customClass": "filter-panel",
          "tableView": false,
          "collapsed": true,
          "components": []
        };
        newTab.components.push(comp);
        newTab.title = column.label;
        comp.hideLabel = true;
        advancedFilterForm.components.push(newTab);
      }

      if (column.simpleFilter) {
        // let comp = this.modelMeta?.convertControlTypeToSchema(metaField);
        // comp.label = availableProperty.label ?? metaField.property;
        // if (comp.validate) {
        //   comp.validate.required = false;
        // }
        // if (comp.type === 'email') {
        //   comp.type = 'textfield';
        // }
        let comp = this.createSimpleFilter(column)
        comp.customClass = 'd-inline-block filter-grid';
        //@ts-ignore
        filterForm.components.push(comp);
      }
    }


    if (filterForm.components.length) {
      filterForm.components.push(buttons);
      this.filterFormDefinition = filterForm;
    } else {
      this.filterFormDefinition = undefined;
    }

    if (advancedFilterForm.components.length > 1) {
      advancedFilterForm.components.push(buttons);
      this.advancedFilterFormDefinition = advancedFilterForm;
    } else {
      this.advancedFilterFormDefinition = undefined;
    }
  }

  createSimpleFilter(column: ProjectTabSettingsContactsField): any {
    let propertyName = column.propertyName.replace('.', '___-___');
    switch (column.format?.style) {
      case 'date': return {
        "label": column.label,
        // "format": fieldMeta.formElementFormat?.get('dateFormat')?.replace('ampm', 'a'),
        "tableView": false,
        "enableMinDateInput": false,
        "datePicker": {
          "disableWeekends": false,
          "disableWeekdays": false
        },
        "enableMaxDateInput": false,
        "key": propertyName,
        "type": "datetime",
        "input": true,
        "widget": {
          "type": "calendar",
          "displayInTimezone": "viewer",
          "locale": "en",
          "useLocaleSettings": false,
          "allowInput": true,
          "mode": "single",
          "enableTime": true,
          "noCalendar": false,
          // "format": fieldMeta.formElementFormat?.get('dateFormat')?.replace('ampm', 'a'),
          "hourIncrement": 1,
          "minuteIncrement": 1,
          "time_24hr": false,
          "minDate": null,
          "disableWeekends": false,
          "disableWeekdays": false,
          "maxDate": null
        },
        "validate": {
          "required": false
        },
      };
      case 'general': return {
        "label": column.label,
        "tableView": true,
        "key": propertyName,
        "type": "textfield",
        "input": true,
        "validate": {
          "required": false
        },
      };
      case 'fileSize':
      case 'percent':
      case 'decimal': return {
        label: column.label,
        mask: false,
        spellcheck: true,
        tableView: false,
        delimiter: column.format?.useGrouping ?? false,
        requireDecimal: false,
        inputFormat: "plain",
        key: propertyName,
        type: "number",
        input: true,
        suffix: (column.format?.style === 'percent' ? '%' : undefined),
        decimalLimit: column.format?.maximumFractionDigits,
        "validate": {
          "required": false
        },
      };
      case 'currency': return {
        "label": column.label,
        "mask": false,
        "spellcheck": true,
        "tableView": false,
        "currency": "USD",
        "inputFormat": "plain",
        "key": propertyName,
        "type": "currency",
        "input": true,
        delimiter: column.format?.useGrouping ?? false,
        decimalLimit: column.format?.maximumFractionDigits,
        "validate": {
          "required": false
        },
      };
    }
  }

  @action
  filterChanged(isAdvanced: boolean, form: any, submission: any) {
    if (isAdvanced) {
      this.advancedFilter = submission;
      this.advancedQuery = this.createQuery(this.advancedFilter);
    } else {
      this.filter = submission;
      this.query = this.createQuery(this.filter);
    }

    this.dataSource?.refresh();
  }

  @action
  createQuery(submission: any) {
    console.log("SUBMISSION: ", submission);

    let query = Expressions.create({
      operator: ExpressionOperators.AND
    });

    let availableColumns = this.args.dataLinkView.columns.sortBy('displayOrder');
    for (let _key in submission) {
      let isExtended = _key.includes('extended-data');
      //let key = isExtended ? _key.replace('___', '.') : dasherize(_key);
      let key = _key;
      //let key = dasherize(_key.replace('___-___', '.'));
      if (key === 'submit' || key.startsWith('__')) {
        continue;
      }
      let value = submission[key];

      let queryKey = dasherize(_key.replace('___-___', '.'));

      let formatValue = (val: string) => {
        if (val === null || val === undefined) {
          return val;
        }
        let isDate = availableColumns.find(p => p.propertyName === queryKey)?.format?.style === 'date'

        if (isDate) {
          return moment(val).utc().format('YYYY-MM-DDTHH:mm:ss');
        } else {
          return val
        }
      };

      if (value !== null && value !== undefined && value !== '') {
        if (Array.isArray(value)) {
          for (let val of value) {
            if (val.value === null || val.value === undefined || val.value === '') {
              continue;
            }
            query.add(Filter.create({
              //@ts-ignore
              name: queryKey,
              operator: (isExtended ? 'json' : '') + val.operator,
              //@ts-ignore
              value: formatValue(val.value)
            }));
          }
        } else {
          query.add(Filter.create({
            //@ts-ignore
            name: queryKey,
            operator: (isExtended ? 'json' : '') + (typeof value === 'number' ? FilterOperators.EQUAL : FilterOperators.LIKE),
            //@ts-ignore
            value: formatValue(value)
          }));
        }
      }
    }

    //@ts-ignore
    if (query.expressions.length) {
      return query;
    } else {
      return undefined;
    }
  }

  @action
  clearFilter(/*form: any*/) {
    // form.emit('resetForm');
    this.filter = {};
    this.advancedFilter = {};
    this.query = undefined;
    this.advancedQuery = undefined;
    this.dataSource?.refresh();
  }

  createFilterInput(column: ProjectTabSettingsContactsField): any {
    if (['general', 'custom'].includes(column.format?.style ?? '')) {
      return {
        "label": "Data Grid",
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        "clearOnHide": false,
        "reorder": false,
        "addAnotherPosition": "bottom",
        "layoutFixed": false,
        "enableRowGroups": false,
        "initEmpty": false,
        "tableView": false,
        "customClass": "filter-grid",
        "defaultValue": [
          {}
        ],
        "key": (column.isFromExtended ? 'extended-data___' : '') + column.propertyName,
        "type": "datagrid",
        "input": true,
        "components": [
          {
            hideLabel: true,
            "clearOnHide": false,
            searchEnabled: false,
            "label": "Operator",
            "widget": "html5",
            "tableView": true,
            "data": {
              "values": [
                {
                  "label": "Starts with",
                  "value": FilterOperators.START_WITH
                },
                {
                  "label": "Ends with",
                  "value": FilterOperators.END_WITH
                },
                {
                  "label": "Equal",
                  "value": FilterOperators.EQUAL
                },
                {
                  "label": "Not Equal",
                  "value": FilterOperators.NOT_EQUAL
                },
                {
                  "label": "Contains",
                  "value": FilterOperators.LIKE
                },
                {
                  "label": "Doesn't contain",
                  "value": FilterOperators.NOT_LIKE
                }
              ]
            },
            "selectThreshold": 0.3,
            "validate": {
              "onlyAvailableItems": false
            },
            "key": "operator",
            "type": "select",
            "indexeddb": {
              "filter": {}
            },
            "input": true,
            "defaultValue": FilterOperators.LIKE
          },
          {
            "clearOnHide": false,
            hideLabel: true,
            "label": column.label,
            "tableView": true,
            "key": "value",
            "type": "textfield",
            "input": true
          }
        ]
      };
    } else if (['currency', 'decimal', 'fileSize'].includes(column.format?.style ?? '')) {
      return {
        "label": "Data Grid",
        "clearOnHide": false,
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        "reorder": false,
        "addAnotherPosition": "bottom",
        "layoutFixed": false,
        "enableRowGroups": false,
        "initEmpty": false,
        "tableView": false,
        "customClass": "filter-grid",
        "defaultValue": [
          {}
        ],
        "key": (column.isFromExtended ? 'extended-data___' : '') + column.propertyName,
        "type": "datagrid",
        "input": true,
        "components": [
          {
            "clearOnHide": false,
            searchEnabled: false,
            hideLabel: true,
            "label": "Operator",
            "widget": "html5",
            "tableView": true,
            "data": {
              "values": [
                {
                  "label": "Greater than",
                  "value": FilterOperators.GREATER_THAN_OR_EQUAL
                },
                {
                  "label": "Less than",
                  "value": FilterOperators.LESS_THAN_OR_EQUAL
                },
                {
                  "label": "Equal",
                  "value": FilterOperators.EQUAL
                },
                {
                  "label": "Not Equal",
                  "value": FilterOperators.NOT_EQUAL
                }
              ]
            },
            "selectThreshold": 0.3,
            "validate": {
              "onlyAvailableItems": false
            },
            "key": "operator",
            "type": "select",
            "indexeddb": {
              "filter": {}
            },
            "input": true,
            "defaultValue": FilterOperators.EQUAL
          },
          {
            "clearOnHide": false,
            hideLabel: true,
            "label": column.label,
            "mask": false,
            "spellcheck": true,
            "tableView": false,
            "delimiter": false,
            "requireDecimal": false,
            "inputFormat": "plain",
            "key": "value",
            "type": "number",
            "input": true
          }
        ]
      };
    } else if (['date'].includes(column.format?.style ?? '')) {
      return {
        "label": "Data Grid",
        "clearOnHide": false,
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        "reorder": false,
        "addAnotherPosition": "bottom",
        "layoutFixed": false,
        "enableRowGroups": false,
        "initEmpty": false,
        "tableView": false,
        "customClass": "filter-grid",
        "defaultValue": [
          {}
        ],
        "key": (column.isFromExtended ? 'extended-data___' : '') + column.propertyName,
        "type": "datagrid",
        "input": true,
        "components": [
          {
            "clearOnHide": false,
            searchEnabled: false,
            hideLabel: true,
            "label": "Operator",
            "widget": "html5",
            "tableView": true,
            "data": {
              "values": [
                {
                  "label": "Greater than",
                  "value": FilterOperators.GREATER_THAN_OR_EQUAL
                },
                {
                  "label": "Less than",
                  "value": FilterOperators.LESS_THAN_OR_EQUAL
                },
                {
                  "label": "Equal",
                  "value": FilterOperators.EQUAL
                },
                {
                  "label": "Not Equal",
                  "value": FilterOperators.NOT_EQUAL
                }
              ]
            },
            "selectThreshold": 0.3,
            "validate": {
              "onlyAvailableItems": false
            },
            "key": "operator",
            "type": "select",
            "indexeddb": {
              "filter": {}
            },
            "input": true,
            "defaultValue": FilterOperators.GREATER_THAN_OR_EQUAL
          },
          {
            "clearOnHide": false,
            hideLabel: true,
            "label": column.label,
            // "format": field.formElementFormat?.get('dateFormat')?.replace('ampm', 'a'),
            "mask": false,
            "spellcheck": true,
            "tableView": false,
            "enableMinDateInput": false,
            "datePicker": {
              "disableWeekends": false,
              "disableWeekdays": false
            },
            "enableMaxDateInput": false,
            "key": 'value',
            "type": "datetime",
            "input": true,
            "widget": {
              "type": "calendar",
              "displayInTimezone": "viewer",
              "locale": "en",
              "useLocaleSettings": false,
              "allowInput": true,
              "mode": "single",
              "enableTime": true,
              "noCalendar": false,
              // "format": field.formElementFormat?.get('dateFormat')?.replace('ampm', 'a'),
              "hourIncrement": 1,
              "minuteIncrement": 1,
              "time_24hr": false,
              "minDate": null,
              "disableWeekends": false,
              "disableWeekdays": false,
              "maxDate": null
            },
          }
        ]
      };
    } else {
      // let comp = this.modelMeta?.convertControlTypeToSchema(field);
      // return comp;
      throw new Error('not implemented');
    }
  }

  getAlignClass(textAlign: "L" | "C" | "R") {
    switch (textAlign) {
      default:
      case 'L':
        return 'text-left';
      case 'C':
        return 'text-center';
      case 'R':
        return 'text-right';
    }
  }
  getAlignHeaderClass(textAlign: "L" | "C" | "R") {
    switch (textAlign) {
      default:
      case 'L':
        return 'justify-content-start';
      case 'C':
        return 'justify-content-center';
      case 'R':
        return 'justify-content-end';
    }
  }
}
