import { helper as buildHelper } from '@ember/component/helper';
import reportTypeMap from '../pods/components/case-management/grab-report/report-type-map';

export function grabReportType([value,]) {
  let reportType = value;
  if (reportTypeMap.hasOwnProperty(value)) {
    reportType = reportTypeMap[value];
  }
  return reportType;
}

export default buildHelper(grabReportType);
