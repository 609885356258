import { attr } from '@ember-data/model';
import Abstract from './abstract';

export default class EventLink extends Abstract {
  @attr('string') declare sourceId: string;
  @attr('string') declare targetId: string;
  @attr('string') declare type?: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'event-link': EventLink;
  }
}
