import { action, computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import config from '../../config/environment';
import { ESettings } from 'cing-app/utils/lookups';
import BottomWindows, {
  BottomWindowComponent,
} from '../bottom-windows/service';

export default class DashboardController extends Controller {
  @service('bottom-windows')
  bottomWindows!: BottomWindows;

  @service
  session;

  @service('docker-item')
  docker;

  @service
  userInstances;

  @service
  store;

  @service
  config;

  showAttorneys = false;
  showAttorneyDetail = false;
  showCaseDetail = false;
  ESettings = ESettings;

  init() {
    super.init(...arguments);
    /*
		let mainUrl = this.get('config.mainUrl');

		let body = document.getElementsByTagName("body");

		let instanceClass;

		switch (mainUrl) {
				case "https://demo-pe.smartexchange.com":
						instanceClass = "dealcloud";
						break;
				case "https://demo-client1.smartexchange.com":                
						instanceClass = "greycroft";
						break;                
				case "https://metricpoint.smartexchange.com":                
						instanceClass = "metricpoint";
						break;                                
				case "https://fcp.smartexchange.com":                
						instanceClass = "fcp";
						break;
				case "https://srs-acquiom.smartexchange.com":                
						instanceClass = "srs-acquiom";
						break;                
				default:
						//instanceClass = "srs-acquiom";
						instanceClass = null
		}

		if (instanceClass) {
				body[0].classList.add(instanceClass);
		}
		*/
  }

  @action
  logout() {
    this.get('session').invalidate();
  }

  @action
  login() {
    this.get('session').authenticate('authenticator:torii', 'si');
  }

  @action
  minimizeAll() {
    this.get('docker').minimizeAllPopups();
  }

  @action
  closeWindow(window: BottomWindowComponent) {
    this.bottomWindows.close(window);
  }

  @action
  openInstance(instance) {
    let url = instance.instanceUrl;

    if (url.indexOf('http') === -1) {
      url = 'https://' + url;
    }

    window.open(url, '_blank');
  }

  @computed('session.authUser.email')
  get showDataLinkManagement() {
    try {
      let email = get(this.session, 'authUser.email').toLowerCase();
      if (
        this.session.hasAdminRole &&
        (email.endsWith('bmcgroup.com') || email.endsWith('smartroom.com'))
      ) {
        return true;
      }
    } catch (e) {}

    return false;
  }

  @action
  reloadPortalSettings() {
    this.send('applicationReloadSettings');
  }
}
