import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import {
  ReportStatus,
  GrabReportType,
  ClaimsAgentTypesMap,
} from 'cing-app/utils/lookups';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { later } from '@ember/runloop';

const DIRECT_DOWNLOAD_CLAIMS_AGENTS = [
  //ClaimsAgentTypesMap.PrimeClerk,
  //ClaimsAgentTypesMap.Epiq,
  // Claim Agents who do not allow us to embed/display documents directly
  //ClaimsAgentTypesMap.BMC,
  //ClaimsAgentTypesMap.KCC,
];

@classic
export default class ViewPdf extends Component.extend(FilterBuilder) {
  @inject()
  store;

  @inject('docker-item')
  docker;

  @inject()
  config;

  @inject()
  session;

  @computed('context.docket.case')
  get supportsClaimsAgent() {
    let claimsAgentType = this.get('context.docket.case.claimAgentType');
    // let claimsAgentType = docketCase.get('claimAgentType');
    return claimsAgentType > 0;
  }

  init() {
    super.init();
    this.get('loadNotes').perform();

    if (!this.get('context.docket.fileMetadata')) {
      this.get('downloadDocumentTask').perform();
    }

    this.get('generateDocketURL').perform();
  }

  getViewerURL(fileURL) {
    let { protocol, host } = window.location;
    let timestamp = Date.now();
    return `${protocol}//${host}/viewer/web/viewer.html?file=${encodeURIComponent(
      fileURL
    )}&timestamp=${timestamp}`;
  }

  @task(function* () {
    let docket = this.get('context.docket');

    // if file metadata is present, docket has been downloaded
    if (docket.get('fileMetadata')) {
      this.set('viewerURL', this.getViewerURL(docket.get('contentURL')));
    } else {
      // get the public docket URL
      let docketUrl = docket.get('docketUrl');

      if (docketUrl) {
        let docketCase = yield this.get('context.docket.case');
        let claimsAgentType = docketCase.get('claimAgentType');

        if (DIRECT_DOWNLOAD_CLAIMS_AGENTS.includes(claimsAgentType)) {
          this.set('viewerURL', this.getViewerURL(docketUrl));
        }
      }
    }
  })
  generateDocketURL;

  @task(function* () {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'docket-id',
        operator: FilterOperators.EQUAL,
        value: this.get('context.docket.id'),
      })
    );
    let notes = yield this.get('store').query('docketNote', {
      condition: expr.serialize(),
      sort: '-noteDate',
    });

    this.set('notes', notes);
  })
  loadNotes;

  @task(function* (enforced) {
    let store = this.get('store');
    let report = this.get('downloadReport');
    if (!report) {
      const grabReportAdapter = store.adapterFor('grab-report');

      let report = yield grabReportAdapter.requestDocketDownload(
        this.get('context.docket.id'),
        enforced
      );
      // normalization is necessary due to pluralized type: key in the payload
      // let normal = store.normalize('grab-report', report.data);

      // hack to make 'push' work
      report.data['type'] = 'grab-report';
      report = store.push(report);
      this.set('downloadReport', report);
    }
    yield timeout(500);

    let grabReport = yield store.findRecord(
      'grab-report',
      this.get('downloadReport.id'),
      { reload: true }
    );

    if (grabReport.get('isProcessing')) {
      if (grabReport.get('isMainReady')) {
        this.get('populateViewerTask').perform(grabReport);
      }
      this.get('downloadDocumentTask').perform(enforced);
      return;
    }

    if (grabReport.get('status') === ReportStatus.Completed) {
      this.get('populateViewerTask').perform(grabReport);
    } else {
      throw new Error('Docket download has failed.');
    }
  })
  downloadDocumentTask;

  @task(function* (report) {
    let docket = yield this.get('store').findRecord(
      'docket',
      this.get('context.docket.id'),
      {
        reload: true,
      }
    );

    if (docket.get('contentURL')) {
      if (!this.get('viewerURL')) {
        // the first occurrence of PDF availability = the main document or whole merged document
        this.get('generateDocketURL').perform();
      } else {
        this.send('refreshDocket');
        // another occurrence means the whole merged document was completed
        //this.set('reloadPossible', true);
      }
    }

    this.set('downloadReport', report);
  })
  populateViewerTask;

  @task(function* () {
    let store = this.get('store');
    let report = this.get('deleteReport');
    if (!report) {
      const grabReportAdapter = store.adapterFor('grab-report');

      let report = yield grabReportAdapter.requestDocketDelete(
        this.get('context.docket.id')
      );
      // normalization is necessary due to pluralized type: key in the payload
      // let normal = store.normalize('grab-report', report.data);

      // hack to make 'push' work
      report.data['type'] = 'grab-report';
      report = store.push(report);
      this.set('deleteReport', report);
    }
    yield timeout(500);

    let grabReport = yield store.findRecord(
      'grab-report',
      this.get('deleteReport.id'),
      { reload: true }
    );

    if (grabReport.get('isProcessing')) {
      this.get('deleteDocumentTask').perform();
      return;
    }

    if (grabReport.get('status') === ReportStatus.Completed) {
      this.set('deleteReport', null);
      yield this.get('context.docket').reload();
      this.sendAction('onClose', false);
    } else {
      throw new Error('Docket deletion has failed.');
    }
  })
  deleteDocumentTask;

  @action
  edit(record) {
    let appearance = {
      icon: '',
      title: `Edit note for Docket #${record.get('docket.docketNumber')}`,
      size: 'sm',
      customPopup: true,
    };

    this.get('docker').invokePopup('manage-docket/notes/add', appearance, {
      docket: record.get('docket'),
      model: record,
    });
  }

  @action
  doRemoveRecord(record) {
    record.destroyRecord().then((response) => {
      this.set('confirmRemoval', null);
    });
  }

  @action
  add() {
    let docket = this.get('context.docket');
    let appearance = {
      icon: '',
      title: `Add note for Docket #${docket.get('docketNumber')}`,
      size: 'sm',
      customPopup: true,
    };
    let model = this.get('store').createRecord('docket-note', {
      title: null,
      description: '',
      noteDate: new Date(),
      visible: true,
      docketId: docket.get('id'),
    });

    this.get('docker').invokePopup('manage-docket/notes/add', appearance, {
      docket: docket,
      model: model,
      parent: this,
    });
  }

  @action
  refresh() {
    this.get('loadNotes').perform();
  }

  @action
  saveNote() {
    let note = this.get('store').createRecord('docket-note', {
      docketId: this.get('docket.id'),
      title: this.get('currentTitle'),
      description: this.get('currentDescription'),
      noteDate: new Date(),
    });
    note.save().then(() => {
      this.set('isEditing', false);
      this.set('currentTitle', null);
      this.set('currentDescription', null);
      this.set('doRefresh', true);
    });
  }

  @action
  refreshDocket() {
    this.set('reloadPossible', false);
    this.get('generateDocketURL').perform();
  }

  @action
  deleteDocket() {
    this.get('deleteDocumentTask').perform(true);
  }

  @action
  enforceDocket(viaEcf) {
    this.get('deleteDocumentTask')
      .perform(false)
      .then(() => {
        this.get('downloadDocumentTask').perform(
          viaEcf ? { enforceDownloadFromEcfOnly: true } : null
        );
      });
  }
}
