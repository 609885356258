import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { alias, readOnly, or, and, notEmpty, not } from '@ember/object/computed';

import Component from '@ember/component';
import { defineProperty } from '@ember/object';

@classic
@tagName('')
export default class Submit extends Component {
  model = null;
  disabled = false;
  block = false;
}