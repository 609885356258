import Component from '@glimmer/component';
import { action } from '@ember/object';

interface IArgs {
  checked: boolean;
  options: unknown[];
  transparent: boolean;
  color: string;
  onChange?: (newValue: boolean) => void;
}

export default class CheckboxActions extends Component<IArgs>{
  @action
  onClick() {
    if (this.args.onChange) {
      this.args.onChange(!this.args.checked);
    }
  }

  @action
  actionClick(actionInfo: any, dropdown: any) {
    dropdown.actions.close();
    actionInfo.action();
  }
};
