import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import AppBuilder, { ABModelMeta } from 'cing-app/pods/appbuilder/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { FormColumn, FormOption, FormRow, ICurrencyFormat, INumberFormat, IPropertiesGroup, SmexUIFormDesignerDefinition } from 'smex-ui-form-designer';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { all } from 'ember-concurrency';
import { action } from '@ember/object';
import { Formio } from 'formiojs';
import AppbuilderLink from 'cing-app/models/appbuilder-link';

interface FormEditorArgs {
  header: string;
  footer: string;
  onClose: () => void;
  context: {
    object: any;
    ABLink: AppbuilderLink;
  }
}

export default class FormEditor extends Component<FormEditorArgs> {
  @service('store')
  store!: Store;
  @service('appbuilder')
  appbuilder!: AppBuilder;
  @tracked
  modelMeta?: ABModelMeta;
  @tracked
  builder: any;
  @tracked
  builderElement?: HTMLElement;

  constructor(owner: unknown, args: FormEditorArgs) {
    super(owner, args);
    this.loadData.perform();
  }

  @action
  initForm(el: HTMLElement) {
    this.builderElement = el;
    this.createEditor();
  }

  createEditor() {
    if (this.modelMeta) {
      let components = this.modelMeta.getFormDesignerProperties();
      let fd = (this.args.context.ABLink.forms ?? {})[this.args.context.object.objectName];
      let defaultFD = { components: this.modelMeta.fields.map(f => this.modelMeta?.convertControlTypeToSchema(f)) };
      defaultFD.components.push(this.modelMeta.getSaveCancelButton());
      Formio.builder(this.builderElement, fd || defaultFD, {
        builder: {
          custom: {
            title: this.args.context.object.objectName,
            weight: 10,
            components: components
          }
        }
      })
        .then((editor: any) => {
          this.builder = editor;
        });
    }
  }

  @task
  removeForm = taskFor(async () => {
    if (this.args.context.ABLink.forms) {
      delete this.args.context.ABLink.forms[this.args.context.object.objectName];
    }
    this.args.context.ABLink.notifyPropertyChange('forms');
    await this.args.context.ABLink.save();
    this.args.onClose();
  });

  @task
  loadData = taskFor(async () => {
    // let abCase = await this.args.context.ABLink.get('ABCase');

    try {
      this.modelMeta = this.appbuilder.getModelMeta(this.args.context.ABLink, this.args.context.object.objectName);
    }
    catch (e) {
      console.log("ERROR: ", e);
    }
  })



  @task
  saveForm = taskFor(async () => {
    await this.saveFormDefinitionToProjectTab.perform()
    // await all([
    //   this.modelMeta?.saveFormDefinition.perform(this.formDefinition, this.store),
    //   this.saveFormDefinitionToProjectTab.perform()
    // ]);
    this.args.onClose();
  });

  @task
  saveFormDefinitionToProjectTab = taskFor(async () => {
    if (this.args.context.ABLink.forms) {
      this.args.context.ABLink.forms[this.args.context.object.objectName] = this.builder.form;
      // this.args.context.projectTab.settings.FormDefinitions = this.args.context.projectTab.settings.FormDefinitions;
      this.args.context.ABLink.notifyPropertyChange('forms');
      // this.args.context.projectTab.settings = this.args.context.projectTab.settings;
    } else {
      this.args.context.ABLink.forms = {
        [this.args.context.object.objectName]: this.builder.form
      };
    }

    await this.args.context.ABLink.save();
  });

}
