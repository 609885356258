import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import { tracked } from '@glimmer/tracking';
import _SessionService from 'cing-app/pods/session/service';
import RoleKeeper from 'cing-app/mixins/role-keeper';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { CaseAssociationTypes } from 'cing-app/utils/lookups';
import Company from 'cing-app/models/company';
import Person from 'cing-app/models/person';
import Email from 'cing-app/models/email';
import Phone from 'cing-app/models/phone';
import Address from 'cing-app/models/address';
import User from 'cing-app/models/user';
import PersonInCompany from 'cing-app/models/person-in-company';

interface ContactDetailArgs {
  context: {
    personId: string;
    company: Company;
  };
  onClose: any;
}

export default class ContactDetail extends Component<ContactDetailArgs> {
  @service config: any;

  @service store!: Store;

  @service('docker-item') docker!: DockerItemService;

  @service session!: _SessionService;

  @tracked
  company: Company;

  @tracked
  person: Person;

  @tracked
  user: User;

  @tracked
  personId: any;

  @tracked
  personInCompany: PersonInCompany;

  @tracked
  address: Address;

  @tracked
  emails: Email[];

  @tracked
  mostRecentEmail: object;

  @tracked
  phones: Phone[];

  @tracked
  parties: any[] = [];

  get vCardLink() {
    let url = `${this.config.APP.api.host}/${this.config.APP.api.namespace}/people/${this.personId}/vcard?getPublic=true`;
    return url;
  }

  constructor(owner: any, args: ContactDetailArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    this.personId = this.args.context.personId;
    this.company = this.args.context.company;
    // first get person's detail including cases and emails

    this.person = await this.store.findRecord('person', this.personId, {
      reload: true,
      include: 'company,emails,phones,user.groups',
    });

    // if company was not passed, retrieve it from person
    if (!this.company) {
      this.company = await this.person.get('company');
    }

    this.user = await this.person.get('user');

    this.personInCompany = await this.person.get('personInCompanies');

    this.address = await this.personInCompany.get('address');

    /// first get all emails for the person
    this.emails = await this.person.get('emails').sortBy('lastSeen');

    this.mostRecentEmail = this.emails.get('lastObject');

    this.emails.removeObject(this.mostRecentEmail);

    this.phones = await this.person.get('phones').sortBy('lastSeen');
  });

  @task
  getInterestedPartiesForCase = taskFor(async (personId, attorneyCase) => {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'personId',
        operator: FilterOperators.EQUAL,
        value: this.args.context.personId,
      })
    );

    expr.add(
      Filter.create({
        name: 'caseId',
        operator: FilterOperators.EQUAL,
        value: attorneyCase.get('id'),
      })
    );

    let interestedParties = await this.store.query('interested-party', {
      condition: expr.serialize(),
    });

    let interestedParty = interestedParties.get('firstObject');

    if (interestedParty) {
      expr = Expressions.create({ operator: ExpressionOperators.AND });
      expr.add(
        Filter.create({
          name: 'representingPartyId',
          operator: FilterOperators.EQUAL,
          value: interestedParty.id,
        })
      );

      this.parties = await this.store.query('party-map', {
        condition: expr.serialize(),
        include: 'interested-party',
        page: {
          size: 1000,
          number: 1,
        },
      });
    }
    return this.parties;
  });

  @action
  showCaseDetail(caseId, activeTab, auxFilter) {
    let caseRecord = this.store.peekRecord('case', caseId);

    let appearance = {
      icon: '',
      title: 'Case: %@ / #%@'.fmt(
        caseRecord.get('name'),
        caseRecord.get('number')
      ),
      size: 'large',
    };

    this.docker.invokePopup('case-detail', appearance, {
      recordId: caseId,
      tab: activeTab,
      tabFilter: auxFilter,
    });
  }

  @action
  showRepresentedParties(attorney, attCase) {
    let appearance = {
      icon: 'group',
      title: 'Represented parties',
      size: 'medium',
    };
    this.docker.invokePopup('attorney-case-represented-parties', appearance, {
      attorney,
      attCase,
    });
  }

  @action
  showLawFirmDetail(company) {
    this.docker.popupCompany(company);
  }

  @action
  editPersonProfile() {
    let self = this;

    this.docker.popupEditPerson(this.person, {
      onRemove() {
        if (self.args.onClose) {
          self.args.onClose();
        }
      },
    });
  }

  @action
  viewAs() {
    window.open(
      `${this.session.portalUrl}view-as?viewAsUser=${encodeURIComponent(
        this.user.email
      )}`
    );
  }
}
