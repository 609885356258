import classic from 'ember-classic-decorator';
import { attributeBindings, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
@classic
@tagName('span')
@attributeBindings('title')
export default class NoWrapWithTitle extends Component {
  @service
  store;

  @service('docker-item')
  docker;

  @computed
  get title() {
    return this.get('record').get(this.get('column.propertyName'))
  }
}

