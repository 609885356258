import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('file-icon')
export default class FileIcon extends Component {
  fileName = null;
  favorite = undefined;

  @computed('fileName')
  get icon() {
    var fileName, fileExtension, fileIcon;
    fileName = this.get("fileName");
    if (!fileName) {
      return "fa fa-fw fa-file-o";
    }
    fileExtension = fileName.replace(/^.*\./, '').toLowerCase();

    switch (fileExtension) {
      case "jpg":
      case "png":
      case "gif":
      case "bmp":
        fileIcon = "icon-Image";
        break;
      case "pdf":
        fileIcon = "icon-Pdf";
        break;
      case "doc":
      case "rtf":
      case "odt":
      case "txt":
        fileIcon = "icon-Word";
        break;
      case "docx":
        fileIcon = "icon-WordX";
        break;
      case "ppt":
      case "pptm":
        fileIcon = "icon-PowerPoint";
        break;
      case "ppsx":
      case "pptx":
        fileIcon = "icon-PowerPointX";
        break;
      case "xls":
      case "xlsm":
      case "csv":
        fileIcon = "icon-Excel";
        break;
      case "xlsx":
        fileIcon = "icon-ExcelX";
        break;
      case "zip":
        fileIcon = "icon-Zip";
        break;
      default:
        fileIcon = "icon-File";
    }

    return "fa fa-fw " + fileIcon;
  }

  set icon(value) {
    return value;
  }
}
