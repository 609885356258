import JSONSerializer from '@ember-data/serializer/json';
import classic from 'ember-classic-decorator';

@classic
export default class DealCloudLpCommunicationContactSerializer extends JSONSerializer {
  attrs = {
    entityId: { key: 'id' },
    companyAffiliations: { key: 'company-affilitions' },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'deal-cloud-lp-communication-contact': DealCloudLpCommunicationContactSerializer;
  }
}
