import { attr } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

const FundStatusValidations = buildValidations({
  name: validator('presence', true),
});

export default class FundStatus extends Abstract.extend(FundStatusValidations) {
  @attr('string') declare name: string;
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'fund-status': FundStatus;
  }
}
