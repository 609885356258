import { isDate } from 'lodash';
import { assert } from '@ember/debug';
import moment from 'moment';

enum QueryOperators {
  AND = 'AND',
  OR = 'OR',
}

enum FilterOperators {
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  LIKE = 'like',
  START_WITH = 'startwith',
  LESS_THAN = 'lt',
  GREATER_THAN = 'gt',
  LESS_THAN_OR_EQUAL = 'le',
  GREATER_THAN_OR_EQUAL = 'ge',
  RANGE = 'range',
  NULL = 'null',
  NOT_NULL = 'notnull',
  IN = 'in',
  NOT_IN = 'notin',
  MASK = 'mask',
  FREETEXT = 'freetxt',
  CONTAINS = 'contns',

  JSON_EQUAL = 'jsoneq',
  JSON_NOT_EQUAL = 'jsonne',
  JSON_LIKE = 'jsonlike',
  JSON_NOT_LIKE = 'jsonnotlike',
  JSON_START_WITH = 'jsonstartwith',
  JSON_LESS_THAN = 'jsonlt',
  JSON_GREATER_THAN = 'jsongt',
  JSON_LESS_THAN_OR_EQUAL = 'jsonle',
  JSON_GREATER_THAN_OR_EQUAL = 'jsonge',
  JSON_RANGE = 'jsonrange',
  JSON_NULL = 'jsonnull',
  JSON_NOT_NULL = 'jsonnotnull',
  JSON_IN = 'jsonin',
  JSON_NOT_IN = 'jsonnotin',
  JSON_MASK = 'jsonmask',
  JSON_MASK_EQUAL = 'jsonmaskequal',
  JSON_NOT_MASK = 'jsonnotmask',
}

class BaseQuery {
  operator: QueryOperators | FilterOperators;
  expressions: BaseQuery[];

  constructor(operator: QueryOperators | FilterOperators) {
    this.operator = operator;
    this.expressions = [];
  }

  toJSON(): void | object {}
}

class Query extends BaseQuery {
  constructor(operatorOrExpressions?: QueryOperators | Filter[]) {
    if (Array.isArray(operatorOrExpressions)) {
      super(QueryOperators.AND);
      this.expressions = operatorOrExpressions;
    } else {
      super(operatorOrExpressions || QueryOperators.AND);
      assert('Operator must be set', this.operator);
      this.expressions = [];
    }
  }

  add(expr: BaseQuery) {
    assert(
      'Expression must be instance of Expression',
      expr instanceof Filter || expr instanceof Query
    );
    this.expressions.pushObject(expr);
  }

  toJSON() {
    let expressions: object[] = [];

    this.expressions.forEach((expr) => {
      let exprJSON = expr.toJSON();
      if (exprJSON) {
        expressions.push(exprJSON);
      }
    });

    if (expressions.length) {
      return {
        o: this.operator,
        expr: expressions,
      };
    }
  }
  serialize() {
    return JSON.stringify(this.toJSON());
  }
}

class Filter extends BaseQuery {
  name: string;
  value: any;

  constructor(name: string, operator: FilterOperators, value?: any) {
    super(operator || FilterOperators.EQUAL);
    this.name = name;
    this.value = value;
  }

  toJSON() {
    let filterObject = {
      name: this.name,
      op: this.operator,
    };

    // skip value for special operators
    if (
      this.operator !== FilterOperators.NULL &&
      this.operator !== FilterOperators.NOT_NULL
    ) {
      filterObject.val = this.preprocessValue(this.value);
    }

    return filterObject;
  }

  preprocessValue(value: any) {
    if (typeof value === 'string' || value instanceof String) {
      value = value.trim();
    }

    return value;
  }
}

class RangeFilter extends Filter {
  value1: any;
  value2: any;

  constructor(
    name: string,
    operator: FilterOperators,
    value1: any,
    value2: any
  ) {
    super(name, operator);
    this.value1 = value1;
    this.value2 = value2;
  }

  toJSON() {
    let value1 = this.preprocessValue(this.value1);
    let value2 = this.preprocessValue(this.value2);

    return {
      name: this.name,
      op: this.operator,
      val: `${value1};${value2}`,
    };
  }
}

class DateRangeFilter extends Filter {
  dateFormat: string;

  constructor(
    name: string,
    operator: FilterOperators,
    value: any,
    dateFormat: string
  ) {
    super(name, operator, value);
    this.dateFormat = dateFormat;
  }

  preprocessValue(value: any) {
    let dateFormat = this.dateFormat;

    assert(
      `Value is not a date: ${value}`,
      isDate(value) || moment.isMoment(value)
    );
    assert('Date format is not set', dateFormat);

    if (!moment.isMoment(value)) {
      value = moment(value);
    }

    return value.format(dateFormat);
  }
}

export {
  Query,
  QueryOperators,
  FilterOperators,
  Filter,
  RangeFilter,
  DateRangeFilter,
};
