import Component from '@glimmer/component';
import {
  CustomContactsFilter,
  CustomContactsFilterMap
} from 'cing-app/utils/lookups';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ContactsFilterComponent extends Component {
  @service store;

  CustomContactsFilterMap = CustomContactsFilterMap;
  CustomContactsFilters = Object.entries(CustomContactsFilter).map((item) => {
    return {
      name: item[0],
      value: item[1]
    }
  });

  CustomContactsFilterOptions = Object.values(CustomContactsFilter);

  constructor() {
    super(...arguments);
  }

  get partyTypeIds() {
    return this.args.partyTypes ? this.args.partyTypes.mapBy('id') : [];
  }

  get partyRoleIds() {
    return this.args.partyRoles ? this.args.partyRoles.mapBy('id') : [];
  }
}