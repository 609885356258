import Component from '@glimmer/component';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import ObjectModel from 'cing-app/models/appbuilder-link/object';
import DockerItemService from "cing-app/pods/docker-item/service";
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';

interface ManageProjectTabAbProjectConfigureViewArgs {
	abLink: AppbuilderLink;
	parentObjectName?: string;
}

export enum RelatedObjectType {
	oneToOne,
	oneToN,
	nToOne
}

export interface IRelationship {
	object: ObjectModel;
	relationType: RelatedObjectType;
}

export interface IObjectTree {
	object: ObjectModel;
	relationships: IRelationship[];
}

export default class ManageProjectTabAbProjectConfigureView extends Component<ManageProjectTabAbProjectConfigureViewArgs> {
	@service('docker-item')
	docker!: DockerItemService;

	getObjectTree() {
		let formExcelSheets = this.args.abLink.ABCase.get('formExcelSheets').toArray().filter(r => r.parentSheet !== 'None');
		//@ts-ignore
		let objects = <ObjectModel[]>this.args.ABLink.ABCase.get('project.objects').toArray();

		for (let obj of objects) {
			let oneToN = formExcelSheets.filter(fes => fes.parentObjSheetName === obj.objectName);
			let oneToOne = formExcelSheets.filter(fes => fes.objSheetName === obj.objectName);
		}
	}


	@action
	editForm(object: any) {
		const appearance = {
			id: 'form-editor' + object.objectName + guidFor(object),
			label: 'Edit Form',
			icon: '',
			title: '<small>Edit Form:</small> ' + object.objectName,
			size: 'large',
			status: 'full',
			custom: true
		};
		this.docker.invokePopup('form-editor', appearance, {
			object,
			ABLink: this.args.abLink
		});
	}

	@action
	editTableView(object: any) {
		const appearance = {
			id: 'table-view-editor' + object.objectName + guidFor(object),
			label: 'Edit Table View',
			icon: '',
			title: '<small>Edit Table View:</small> ' + object.objectName,
			size: 'large',
			status: 'full',
			custom: true
		};
		this.docker.invokePopup('table-view-editor', appearance, {
			object,
			ABLink: this.args.abLink
		});
	}

	@action
	editLayout(object: any) {
		const appearance = {
			id: 'layout-editor' + object.objectName + guidFor(object),
			label: 'Edit View',
			icon: '',
			title: '<small>Edit View:</small> ' + object.objectName,
			size: 'large',
			status: 'full',
			custom: true
		};
		this.docker.invokePopup('layout-editor', appearance, {
			object,
			ABLink: this.args.abLink
		});
	}

	@action
	createForm(object: any) {
		const appearance = {
			id: 'form-editor' + object.objectName + guidFor(object),
			label: 'Edit Form',
			icon: '',
			title: '<small>Edit Form:</small> ' + object.objectName,
			size: 'large',
			status: 'full',
			custom: true
		};
		this.docker.invokePopup('form-editor', appearance, {
			object,
			ABLink: this.args.abLink
		});
	}

	@action
	createTableView(object: any) {
		const appearance = {
			id: 'form-editor' + object.objectName + guidFor(object),
			label: 'Edit Table View',
			icon: '',
			title: '<small>Edit Table View:</small> ' + object.objectName,
			size: 'large',
			status: 'full',
			custom: true
		};
		this.docker.invokePopup('table-view-editor', appearance, {
			object,
			ABLink: this.args.abLink
		});
	}

	@action
	createLayout(object: any) {
		const appearance = {
			id: 'layout-editor' + object.objectName + guidFor(object),
			label: 'Edit View',
			icon: '',
			title: '<small>Edit View:</small> ' + object.objectName,
			size: 'large',
			status: 'full',
			custom: true
		};
		this.docker.invokePopup('layout-editor', appearance, {
			object,
			ABLink: this.args.abLink
		});
	}


}
