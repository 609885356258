import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import Docket from './docket';
import InterestedParty from './interested-party';

export default class DocketInterestedParty extends Abstract {
  @attr('number') declare docketId: number;
  @attr('number') declare interestedPartyId: number;

  @belongsTo('docket') declare docket: AsyncBelongsTo<Docket>;
  @belongsTo('interested-party')
  declare interestedParty: AsyncBelongsTo<InterestedParty>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'docket-interested-party': DocketInterestedParty;
  }
}
