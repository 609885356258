import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';

export default Component.extend({
  store: service(),
  editEnabled: true,
  viewEnabled: true,
  tileEnabled: true,
  init() {
    this._super();
    if (!this.get(`form.model.${this.field}`)) {
      this.set(`form.model.${this.field}`, this.store.createFragment('project-tab-settings-field-item'));
    }
  },
  actions: {
    toggleProperty(propertyName) {
      let fieldName = `${this.field}.${propertyName}`;
      if (get(this.form.model, fieldName)) {
        set(this.form.model, fieldName, false);
      } else {
        set(this.form.model, fieldName, true);
      }
    },
  }
})