define("cing-app/classes/fileContainer", ["exports", "smex-ui-sr-filemanager/classes/fileContainer"], function (_exports, _fileContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BaseFile", {
    enumerable: true,
    get: function () {
      return _fileContainer.BaseFile;
    }
  });
  Object.defineProperty(_exports, "EmptyFile", {
    enumerable: true,
    get: function () {
      return _fileContainer.EmptyFile;
    }
  });
  Object.defineProperty(_exports, "FileContainer", {
    enumerable: true,
    get: function () {
      return _fileContainer.FileContainer;
    }
  });
});
