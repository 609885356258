import Serializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class CaseDirectSerializer extends Serializer {
  modelNameFromPayloadKey(key: string) {
    let modelName = `appbuilder/${super.modelNameFromPayloadKey(key)}`;
    return modelName;
  }

  payloadKeyFromModelName(modelName: string) {
    let mn = modelName.replace(/^appbuilder\//, '');
    return super.payloadKeyFromModelName(mn);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'appbuilder/case': CaseDirectSerializer;
  }
}
