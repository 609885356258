// import Ember from 'ember';
import { belongsTo, attr, AsyncBelongsTo } from '@ember-data/model';
import Abstract from './abstract';
import InterestedParty from './interested-party';

export default class PartyMap extends Abstract {
  @attr('boolean') declare isLeadAttorney?: boolean;
  @attr('date') declare associated?: Date;
  @attr('date') declare terminated?: Date;
  @attr('string') declare interestedPartyId: string;
  @attr('string') declare representingPartyId?: string;
  @belongsTo('interested-party')
  declare interestedParty: AsyncBelongsTo<InterestedParty>;
  @belongsTo('interested-party')
  declare representingParty: AsyncBelongsTo<InterestedParty>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'party-map': PartyMap;
  }
}
