import Model, { attr } from '@ember-data/model';

export default Model.extend({
  alertSettingTypeId: attr('number'),
  body: attr('string'),
  dateCreated: attr('date'),
  subject: attr('string'),
  siteRedirectUrlId: attr('string'),
  emailOption: attr('number'),
  isDefaultForNonSRM: attr('boolean'),
  languageId: attr('number'),
  templateTitle: attr('string')
})