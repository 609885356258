import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';

import _SessionService from 'cing-app/pods/session/service';
import AbPmService from 'cing-app/pods/ab-pm/service';
import DockerItemService from 'cing-app/pods/docker-item/service';

import { DataSourceColumn, IDataSource, StaticDataSource } from 'smex-ui-table';

interface AbPmTaskResourcesArgs {
  abLink: any;
  smartRoomId: any;
  task: any;
  onTaskAction: any;
  onChange: any;
  saveOnSelect: any;
  parentContext: any;
}

interface TaskResource {
  id: number;
  resourceType: string;
  resourceId: string;
  name: string;
  role: string;
  email: string;
}

export default class AbPmTaskResources extends Component<AbPmTaskResourcesArgs> {
  @service store!: Store;
  @service('ab-pm') pmService!: AbPmService;
  @service('docker-item') docker!: DockerItemService;

  @tracked isDeleting: boolean = false;
  @tracked selectedResource: any;

  @tracked currentList: TaskResource[] = [];
  @tracked originalResourcesMap = new Map<string, any>();

  @tracked dataSource!: IDataSource<TaskResource>;
  pageSize: number = 999999;
  searchPopup: any;

  columns: DataSourceColumn<TaskResource>[] = [
    new DataSourceColumn<TaskResource>({
      id: 'id',
      label: 'ID',
      hidden: true,
      getValue: (row) => row.id,
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
    }),
    new DataSourceColumn<TaskResource>({
      id: 'name',
      label: 'Name',
      getValue: (row) => row.name,
      sortingEnabled: true,
      minWidth: 180,
      valueComponent: 'table-link-column',
      options: {
        onClick: (row: any) => {
          return this.viewResourceTask.perform(row);
        },
      },
    }),
    new DataSourceColumn<TaskResource>({
      id: 'email',
      label: 'Email',
      getValue: (row) => row.email,
      sortingEnabled: true,
      minWidth: 180,
      valueComponent: 'table-link-column',
      options: {
        onClick: (row: any) => {
          return this.viewResourceTask.perform(row);
        },
      },
    }),
  ];

  constructor(owner: any, args: any) {
    super(owner, args);

    if (this.args.parentContext) {
      this.args.parentContext.taskResourcesContext = this;
    }
  }

  @action init() {
    this.initDataSourceTask.perform();
  }

  @task initDataSourceTask = taskFor(async () => {
    this.args.task.resources = this.args.task.resources || [];

    if (this.args.saveOnSelect) {
      //this is for direct add/edit/remove resources
      //list is automatically updated
      this.currentList = this.args.task.resources;
    } else {
      //this is for edit/add task
      //the list will only be updated on save
      this.currentList = [];
      this.args.task.resources.forEach((item: any) => {
        this.currentList.pushObject(item);
      });
    }

    this.originalResourcesMap = new Map<string, any>();
    this.args.task.resources.forEach((item: any) => {
      this.originalResourcesMap.set(item.id, item);
    });

    this.dataSource = new StaticDataSource<TaskResource, TaskResource>(
      25,
      false,
      'task-resources',
      this.pmService.currentUserEmail,
      this.currentList,
      this.loadDataTask,
      this.pageSize,
      this.columns
    );
  });

  @action getActions() {
    let actions = [
      {
        label: 'Remove',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (row: any) => {
          this.isDeleting = true;
          this.selectedResource = row;
        },
      },
    ];
    return actions;
  }

  @task loadDataTask = taskFor(
    async (data: TaskResource[], columns: DataSourceColumn<TaskResource>[]) => {
      return data;
    }
  );

  @action addNewResource() {
    let appearance = {
      icon: 'users',
      title: `Select Resource`,
      custom: true,
    };
    let ctx = {
      abLink: this.args.abLink,
      smartRoomId: this.args.smartRoomId,
      task: this.args.task,
      onSelectResource: this.onSelectResource,
    };
    this.searchPopup = this.docker.invokePopup(
      'ab-pm/task-resources/search-popup',
      appearance,
      ctx
    );
  }

  @action onSelectResource(resource: any) {
    console.log('selected resource', resource);

    if (resource) {
      let item = Object.assign({}, resource);
      if (resource.firstName) {
        item.name = `${resource.firstName} ${resource.lastName}`;
      }

      var duplicate: any = null;
      if (resource.email) {
        duplicate = this.currentList.findBy('email', resource.email);
        console.log('duplicate email ' + resource.email + ' => ', duplicate);
      } else {
        duplicate = this.currentList.findBy('name', resource.name);
        console.log('duplication name for ' + item.name + ' =>', duplicate);
      }

      if (duplicate) {
        return 'Selected resource is already on the list.';
      }

      this.currentList.pushObject(item);

      if (this.args.onChange) {
        this.args.onChange(this.currentList, this.originalResourcesMap);
      }

      this.dataSource.refresh();

      if (this.args.saveOnSelect) {
        this.docker.removePopup(this.searchPopup);
        this.saveResourcesTask.perform();
      }
    }
    return '';
  }

  @action removeResource() {
    this.currentList.removeObject(this.selectedResource);
    if (this.args.onChange) {
      this.args.onChange(this.currentList, this.originalResourcesMap);
    }
    this.isDeleting = false;
    this.dataSource.refresh();

    if (this.args.saveOnSelect) {
      this.saveResourcesTask.perform();
    }
  }

  async saveResources() {
    let currentTask = this.args.task;
    let isModified = false;

    let resourceNames = [];
    let selectedResourcesMap = new Map<string, any>();
    if (this.currentList && this.currentList.length > 0) {
      let firstResource = this.currentList.firstObject || {
        id: 0,
        email: '',
        name: '',
      };

      currentTask.userId = firstResource.userId;
      currentTask.resourceId = firstResource.resourceId || firstResource.id;
      currentTask.email = firstResource.email;

      for (var i = 0; i < this.currentList.length; i++) {
        let r: any = this.currentList[i];
        if (r.id) {
          // this existing resources is still selected
          selectedResourcesMap.set(r.id, r);
        } else {
          // this is a new resource so we need to create this record
          console.log(`Adding ${r.name} ...`);

          let taskResource = this.pmService.createNewTaskResourceRecord(
            this.args.abLink
          );
          taskResource.taskId = currentTask.id;
          taskResource.userId = r.userId;
          taskResource.resourceId = r.resourceId;
          taskResource.resourceType = r.resourceType;
          taskResource.email = r.email;
          taskResource.name = r.name;
          taskResource.role = r.role;
          taskResource.createdDate = this.pmService.currentDate;
          taskResource.createdBy = this.pmService.currentUserEmail;

          await taskResource.save();
          //update the id so that when the save is clicked again it will not create another recored
          r.id = taskResource.id;
          if (this.args.task.resources && this.args.task.resources.pushObject) {
            this.args.task.resources.pushObject(taskResource);
          }

          isModified = true;
        }
        resourceNames.push(r.name);
      }

      currentTask.resource = resourceNames.join(', ');
    } else {
      currentTask.userId = null;
      currentTask.resourceId = null;
      currentTask.resource = null;
      currentTask.email = null;
      if (this.originalResourcesMap.size > 0) {
        console.log('Clearing resources...');
      }
      isModified = true;
    }

    //delete the resources that are not in the original list
    this.originalResourcesMap.forEach((item: any) => {
      if (!selectedResourcesMap.has(item.id)) {
        console.log(`Removing ${item.name} ...`);

        if (this.args.task.resources && this.args.task.resources.removeObject) {
          this.args.task.resources.removeObject(item);
        }

        if (item.destroyRecord) {
          item.destroyRecord();
        }

        isModified = true;
      }
    });

    if (isModified) {
      await currentTask.save();
      console.log('task has been updated');
      if (this.args.onTaskAction) {
        this.args.onTaskAction('task-updated', this.args.task, this.args.task);
      }

      //reset the original resource map
      this.originalResourcesMap.clear();
      this.currentList.forEach((item: any) => {
        this.originalResourcesMap.set(item.id, item);
      });
    }
  }

  @task saveResourcesTask = taskFor(async () => {
    await this.saveResources();
  });

  @task viewResourceTask = taskFor(async (user: any) => {
    var person = await this.pmService.getUserInfo(user, this.args.smartRoomId);
    if (person) {
      this.docker.popupPerson(person, person?.company);
    }
  });
}
