import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
//@ts-ignore
import { buildValidations } from 'ember-cp-validations';
import Abstract from './abstract';
import DebtorProfile from './debtor-profile';

const CDPRepresentativeSignature = buildValidations({});
/*
public Guid CompanyProfileId { get; set; }
public string RepresentativeName { get; set; }
public string RepresentativeTitle { get; set; }
public DateTime? RepresentativeDate { get; set; }
}
*/

export default class CdpRepresentativeSignature extends Abstract.extend(
  CDPRepresentativeSignature
) {
  @attr('string') declare companyProfileId: string;
  @attr('string') declare representativeName?: string;
  @attr('string') declare representativeTitle?: string;
  @attr('date') declare representativeDate?: Date;
  //@ts-ignore - mixin
  @belongsTo('debtor-profile')
  declare companyProfile: AsyncBelongsTo<DebtorProfile>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cdp-representative-signature': CdpRepresentativeSignature;
  }
}
