import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dasherize } from '@ember/string';
//@ts-ignore
import podNames from "ember-component-css/pod-names";
import { action } from '@ember/object';

type ModelProperty = {
	property: string,
	isFromExtended: boolean,
	belongsTo: string | null,
};

interface AvailablePropertyArgs {
	model: ModelProperty;
	nested: boolean;
	showHidden: boolean;
	onClick: () => void;
	modelLookups?: Map<string, ModelProperty[]>;
}

export default class AvailableProperty extends Component<AvailablePropertyArgs> {
	@tracked
	expanded: boolean = false;

	constructor(owner: any, args: AvailablePropertyArgs) {
		super(owner, args);
	}

	get styleNamespace() {
		return podNames["manage-data-links/detail/models/edit"];
	}

	get modelProperties() {
		let props = this.args.modelLookups?.get(dasherize(this.args.model.belongsTo))?.sortBy('property')

		if (this.args.showHidden) {
			return props;
		} else {
			return props?.filter((item) => {
				return (item.property !== 'id' && item.property.indexOf('-id') === -1 && dasherize(item.property).indexOf('-id') === -1);
			})
		}
	}

	@action
	toggleExpand() {
		this.expanded = !this.expanded;
	}
}
