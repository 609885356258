import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment';
import { action } from '@ember/object';

//@ts-ignore
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AppEventsService from 'cing-app/pods/app-events/service';
import SessionService from 'cing-app/pods/session/service';
import {
	Expressions,
	ExpressionOperators,
	Filter,
	FilterOperators,
} from 'cing-app/mixins/filter-builder';
//@ts-ignore
import podNames from "ember-component-css/pod-names";
import { dasherize } from "@ember/string";
import DataLink from 'cing-app/models/data-link';
import DataLinkView from 'cing-app/models/data-link-view';
import { task } from 'ember-concurrency'
import { taskFor } from 'ember-concurrency-ts';

interface ManageDataLinkViewArgs {
	contextObject?: any,
	dataLinkId: string,
	dataLinkView: string,
	filters: object[],
}

export default class ManageDataLinks extends Component<ManageDataLinkViewArgs> {
	@service()
	config: any;

	@service()
	store!: Store;

	@service()
	cookies: any;

	@service()
	appEvents!: AppEventsService;

	@service('docker-item')
	docker!: DockerItemService;

	@service()
	session!: SessionService;

	@tracked
	dataLink!: DataLink;

	@tracked
	dataLinkView!: DataLinkView

	get styleNamespace() {
		return podNames["manage-data-links/view"];
	}

	constructor(owner: any, args: ManageDataLinkViewArgs) {
		super(owner, args);

		this.initTask.perform();
	}

	@task
	initTask = taskFor(async (): Promise<any> => {
		this.dataLink = await this.store.findRecord('data-link', this.args.dataLinkId);

		if (this.dataLink) {
			let dataLinkView: DataLinkView | undefined = this.dataLink.views.findBy('name', this.args.dataLinkView);

			if (dataLinkView) {
				this.dataLinkView = dataLinkView;
			}
		}

		/*
		let filters = this.args.filters;

		let query = Expressions.create();

		if (filters) {
			filters.forEach((filter: any) => {
				query.add(Filter.create({
					name: filter.property,
					operator: filter.operator,
					value: filter.value

				}))
			})
		}
		*/

	})
}
