import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import FilterBuilder from 'cing-app/mixins/filter-builder';

@classic
export default class ColumnCase extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  @action
  showCase(tab) {
    let record = this.get('record');
    console.log("CASE ID: ", record.get('id'));
    let appearance = {
      icon: '',
      title: `Project: ${record.get('case.name')}`,
      size: 'large',
      id: record.get('id')
    };
    this.get('docker').invokePopup('case-detail', appearance, {
      recordId: record.get('case.id'),
      tab: tab
    });
  }

  @action
  openDocket() {
    this.send("showCase", "docket");
  }

  @action
  openCalendar() {
    this.send("showCase", "calendar");
  }
}

