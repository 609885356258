import Component from '@glimmer/component';
import SendingDomain from "cing-app/models/sending-domain";
import { Owner } from "ember-one-way-controls/.node_modules.ember-try/@glimmer/di";
import { action, computed } from "@ember/object";
import { inject as service } from "@ember/service";
import ActionsService from "cing-app/pods/se-actions/service";
import { tracked } from "@glimmer/tracking";
import Store from "@ember-data/store";
import { task } from "ember-concurrency";
import { taskFor } from "ember-concurrency-ts";

interface SendingDomainsDetailArgs {
  context: {
    model: SendingDomain;
    reloadData: Function;
  };
  onClose: Function;
}

export default class SendingDomainsDetail extends Component<SendingDomainsDetailArgs> {
  @service('seActions') seActions!: ActionsService;
  @tracked verifyAction?: any;
  @service store!: Store;
  @tracked domain!: SendingDomain;
  @tracked domainEnabled?: boolean;
  @tracked confirmRemoval?: SendingDomain | null;
  @tracked discoverActionResult: any;

  constructor(owner: Owner, args: SendingDomainsDetailArgs) {
    super(owner, args);
    this.domain = args.context.model;
    this.discoverDomainTask.perform();
  }

  @action
  verifyDomain(): void {
    this.verifyAndUpdateDomain.perform();
  }

  @task({ drop: true })
  verifyAndUpdateDomain = taskFor(async () => {
    this.verifyAction = this.seActions.domainVerify(this.domain.id);
    await this.verifyAction;
    this.domain = await this.store.findRecord('sending-domain', this.domain.id);
  });

  @task
  discoverDomainTask = taskFor(async () => {
    let discoverResult = await this.seActions.domainsExists(
      this.domain.provider,
      this.domain.sendingDomain,
      this.domain.trackingDomain,
      this.domain.bounceDomain
    );
    this.discoverActionResult = discoverResult.actionResult;
  });

  @action
  changeEnabledStatus(): void {
    this.saveDomainTask.perform();
  }

  @task({ drop: true })
  saveDomainTask = taskFor(async () => {
    await this.domain.save();
  });

  @action deleteDomain(): void {
    this.confirmRemoval = this.domain;
  }

  @task
  doDeleteDomain = taskFor(async () => {
    await this.confirmRemoval?.destroyRecord();

    this.confirmRemoval = null;

    this.args.context.reloadData();

    if (this.args.onClose) {
      this.args.onClose();
    }
  });
}