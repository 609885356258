import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';

@classic
@tagName("nav")
@classNames("navbar navbar-default navbar-static-top m-b-0")
export default class TopNavbar extends Component {
  @service
  session;

  @service('docker-item')
  docker;

  @service
  config;

  init() {
    if ($('body').hasClass('content-wrapper')) {
      this.set('sideBarVisible', false);
    }
    else {
      this.set('sideBarVisible', true);
    }
    super.init(...arguments);
  }

  @computed('session.isAuthenticated')
  get userProfile() {
    console.log(this.get('session.data.authenticated.access_token'));
    return {};
  }

  @action
  toggleSideBar() {
    $('body').toggleClass('content-wrapper');
    this.set('sideBarVisible', !this.get('sideBarVisible'));
  }

  @action
  logout() {
    this.get('session').invalidate().then(() => {
      /* DISABLED UNTIL LOGOUT IS HANDLED CORRECTLY ON AUTH SERVER

      let params = [
          `post_logout_redirect_uri=${encodeURIComponent(this.get('config.auth_logoutUrl'))}`,
          `id_token_hint=${this.get('config._id_token')}`
      ];
      
      let logOutURL = `${this.get('config.auth_baseUrl')}/connect/endsession?${params.join("&")}`;
      window.location = logOutURL;
      */
    })
  }

  @action
  login() {
    //const appearance = {label: '', icon: '', title: 'Log In', customPopup: true};
    //this.get('docker').invokePopup('user-login', appearance);

    this.get('session').authenticate('authenticator:torii', 'si');
  }
}