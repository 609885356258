import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, timeout } from 'ember-concurrency';
import { task, restartableTask } from 'ember-concurrency';
import {
  AclPermissionType,
  CrmImportSteps,
  ProjectTabMap,
  EReportStatus,
} from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import { htmlSafe } from '@ember/string';
import TableTheme from '../abstract-import/table-theme';

const PARTY_MODES = {
  SIMPLE: 'simple',
  BATCH: 'batch',
};

function FixInvestorNumber(serialized) {
  if (serialized && /^\d+\.+\d+$/.test(serialized)) {
    return parseInt(serialized, 10);
  } else {
    return serialized;
  }
}

import { guidFor } from '@ember/object/internals';
import classic from 'ember-classic-decorator';

@classic
export default class ImportXLSInvestors extends Component {
  @service store;
  @service session;
  @service config;
  @service('docker-item') docker;
  @service smartroom;
  @service seActions;

  context = null;
  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  searchName = null;
  searchEmail = null;
  partyType = null;
  selectedInvestors = null;
  importAction = null;
  importRows = null;

  companySearchPage = 1;
  CrmImportSteps;
  EReportStatus;
  pageSize = 20;
  currentStep = 1;
  defaultFolderStructure = null;

  init() {
    super.init(...arguments);
    this.set('selectedItems', []);
    this.initTask.perform();
    this.set(
      'defaultFolderStructure',
      'Capital Calls\nDistributions\nFinancials\nLegal Documents\nOther Documents\nTax Information'
    );
  }

  @computed
  get themeInstance() {
    return TableTheme.create();
  }

  @alias('context.project') project;

  @alias('context.onComplete') onComplete;

  @task
  *initTask() {
    // get the associated fund
    this.set('fund', yield this.get('project.fund'));

    let investorTypes = yield this.store.query('investor-type', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
    this.set('investorTypes', investorTypes.toArray());

    let interestedPartyRoles = yield this.store.query('interested-party-role', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
    this.set('interestedPartyRoles', interestedPartyRoles.toArray());

    let interestedPartyTypes = yield this.store.query('interested-party-type', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
    this.set('interestedPartyTypes', interestedPartyTypes.toArray());

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: AclPermissionType.ProjectRead,
      })
    );

    let viewerGroups = yield this.store.query('acl-role', {
      page: {
        size: 1000,
      },
      condition: vgQuery.serialize(),
      sort: 'description',
    });
    this.set('viewerGroups', viewerGroups.toArray());

    if (this.project && this.fund) {
      let investorQuery = Expressions.create({});
      investorQuery.add(
        Filter.create({
          name: 'fund-id',
          operator: FilterOperators.EQUAL,
          value: this.get('context.project.fundId'),
        })
      );
      investorQuery.add(
        Filter.create({
          name: 'company-id',
          operator: FilterOperators.NOT_NULL,
        })
      );

      let investors = yield this.store.query('investor', {
        page: {
          size: 1000,
        },
        include: 'company',
        condition: investorQuery.serialize(),
        sort: 'company.name',
      });
      this.set('investors', investors.toArray());
    }

    let srConnection = this.context.srConnection;

    if (srConnection) {
      this.setProperties({
        smartRoomId: srConnection.SRSiteId,
        smartRoomFolderId: srConnection.SRFolderId,
      });
    }
  }

  @task
  *prepareImportReviewTask() {
    let columnsInImport = this.get('importAction.actionResult.Columns') || [];

    let columns = this.get('columns');

    for (var a = 0; a < columns.length; a++) {
      let column = columns[a];
      let columnName = columns[a].propertyName;

      if (columnName && columnName.startsWith('rowData.')) {
        columnName = columnName.slice(8);

        if (columnsInImport && columnsInImport.indexOf(columnName) == -1) {
          column.isHidden = true;
        }
      }
    }

    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'action-id',
        operator: FilterOperators.EQUAL,
        value: this.importAction.id,
      })
    );

    let importRows = yield this.store.query('crm-import', {
      condition: expr.serialize(),
      page: {
        size: 10000,
      },
      sort: 'import-order',
    });

    this.set('importRows', importRows.toArray());
  }

  @computed
  get columns() {
    return [
      {
        title: '',
        component: 'manage-interested-party/abstract-import/column-actions',
        className: 'column-actions',
        selectedItems: this.selectedItems,
      },
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
      {
        title: '',
        component:
          'manage-interested-party/abstract-import/column-match-status',
      },
      {
        propertyName: 'rowData.InvestorName',
        component: 'manage-interested-party/abstract-import/column-investor',
        createModelName: 'investor',
        options: this.investors,
        title: 'Investor',
      },
      {
        propertyName: 'rowData.Company',
        //component: "manage-interested-party/abstract-import/column-company",
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Company',
      },
      {
        propertyName: 'rowData.ContactName',
        component: 'manage-interested-party/abstract-import/column-person',
        title: 'Contact Name',
      },
      {
        propertyName: 'rowData.Type',
        component: 'manage-interested-party/abstract-import/column-select',
        createModelName: 'interested-party-type',
        options: this.interestedPartyTypes,
        title: 'Type',
      },
      {
        propertyName: 'rowData.Role',
        //component: "manage-interested-party/abstract-import/column-editable",
        options: this.interestedPartyRoles,
        component: 'manage-interested-party/abstract-import/column-select',
        createModelName: 'interested-party-role',
        title: 'Role',
      },
      {
        propertyName: 'rowData.ContactEmail',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Contact Email',
      },
      {
        propertyName: 'rowData.PortalAccess',
        component: 'api-table/columns/toggle',
        title: 'Portal Access',
      },
      {
        propertyName: 'rowData.ViewerGroup',
        component: 'manage-interested-party/abstract-import/column-view-group',
        createModelName: 'acl-role',
        options: this.viewerGroups,
        title: 'Access Role',
      },
      {
        propertyName: 'rowData.SRAccess',
        component: 'api-table/columns/toggle',
        title: 'SR Access',
      },
      {
        propertyName: 'rowData.SRProfile',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'SR Profile',
      },
      {
        propertyName: 'rowData.CapitalCalls',
        component: 'api-table/columns/toggle',
        title: 'Capital Calls',
      },
      {
        propertyName: 'rowData.Distributions',
        component: 'api-table/columns/toggle',
        title: 'Distributions',
      },
      {
        propertyName: 'rowData.Tax',
        component: 'api-table/columns/toggle',
        title: 'Tax',
      },
      {
        propertyName: 'rowData.Financials',
        component: 'api-table/columns/toggle',
        title: 'Financials',
      },
      {
        propertyName: 'rowData.Legal',
        component: 'api-table/columns/toggle',
        title: 'Legal',
      },
      {
        propertyName: 'rowData.OtherDocuments',
        component: 'api-table/columns/toggle',
        title: 'Other Documents',
      },
      {
        propertyName: 'rowData.Communications',
        component: 'api-table/columns/toggle',
        title: 'Communications',
      },
      {
        propertyName: 'rowData.InvestorContactId',
        title: 'Investor Contact ID',
        component: 'manage-interested-party/abstract-import/column-editable',
      },
      {
        propertyName: 'rowData.DealCloudContactId',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'DealCloud Contact ID',
      },
      {
        propertyName: 'rowData.DealCloudInvestorId',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'DealCloud Investor ID',
      },
      {
        propertyName: 'rowData.DealCloudFundId',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'DealCloud Fund ID',
      },
      {
        propertyName: 'rowData.InvestorFundId',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Inv. Fund Id',
      },
      {
        propertyName: 'rowData.InvestorNumber',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Inv. Number',
      },
      {
        propertyName: 'rowData.InvestorType',
        component: 'manage-interested-party/abstract-import/column-select',
        createModelName: 'investor-type',
        options: this.investorTypes,
        title: 'Investor Type',
      },
      {
        propertyName: 'rowData.Commitment',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Commitment',
      },
    ];
  }

  @task
  *processTask() {
    yield this.addInvestorsTask.perform();
    yield this.createFolderStructureTask.perform();

    if (this.onComplete) {
      this.onComplete();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @task
  *addInvestorsTask() {
    yield this.seActions.importPartiesStepTwo(this.importAction);
  }

  @task
  *createFolderStructureTask() {
    let allTasks = [];

    for (var a = 0; a < this.importRows.length; a++) {
      allTasks.push(
        this.createInvestorStructure.perform(this.importRows[a].rowData)
      );
    }

    yield all(allTasks);
  }

  @task({
    maxConcurrency: 4,
    enqueue: true,
  })
  *createInvestorStructure(investorData) {
    console.log('ROW DATA: ', investorData);
    let fund = this.fund;

    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: fund.id,
      })
    );

    if (investorData.InvestorNumber) {
      query.add(
        Filter.create({
          name: 'investor-number',
          operator: FilterOperators.EQUAL,
          value: investorData.InvestorNumber,
        })
      );
    } else if (investorData.InvestorName) {
      query.add(
        Filter.create({
          name: 'company.name',
          operator: FilterOperators.EQUAL,
          value: investorData.InvestorName,
        })
      );
    }

    let investor = (yield this.store.query('investor', {
      condition: query.serialize(),
      include: 'company',
      page: {
        size: 1,
        number: 1,
      },
    })).firstObject;

    console.log('INVESTOR: ', investor);

    if (!investor) {
      console.log(
        'SKIP STRUCTURE, CAN NOT FIND INVESTOR RECORD for: ',
        investorData
      );
      return;
    }

    // fail if smartRoomId is not set
    if (this.smartRoomId === null) {
      throw new Error('There is no SmartRoom associated with the project.');
    }

    let company = yield investor.get('company');

    // this is the investor name (investors are linked to company entities)
    let investorName = company.get('name');

    console.log('COMPANY: ', company, ' INVESTOR: ', investorName);

    // folder doesn't exist, create it
    let investorFolder = yield this.smartroom.createFolderEnforce(
      this.smartRoomId,
      this.smartroom.normalizeFolderName(company.name),
      this.smartRoomFolderId
    );

    console.log('INVESTOR FOLDER: ', investorFolder);

    investor.set('smartRoomFolderId', investorFolder.id);
    yield investor.save();

    let folders = this.defaultFolderStructure.split('\n');

    // create the investor structure
    let result = yield this.smartroom.createInvestorStructure(
      this.smartRoomId,
      investorFolder.id,
      folders
    );
  }

  @action
  selectInvestors(investors) {
    this.set('currentStep', 2);
  }

  @action
  process() {
    this.get('processTask').perform();
  }

  @action
  onFileImported(importAction) {
    // set the import action and proceed to import review
    this.set('importAction', importAction);

    this.prepareImportReviewTask.perform();
  }

  @action
  downloadSample() {
    window.location.assign(
      this.config.get('mainUrl') +
        '/sample_data/smartexchange_investors_import.xlsx'
    );
  }
}
