import Component from '@ember/component';
import { computed, set, get, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
} from 'cing-app/pods/smartroom/service';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import classic from 'ember-classic-decorator';

import { SYNC_API } from 'cing-app/pods/user-instances/service';

@classic
export default class SearchCRM extends Component {
  @service('docker-item') docker;
  @service store;
  @service userInstances;
  searchType = 'dealcloud';

  init() {
    super.init();
  }

  @task({
    restartable: true,
  })
  *searchCompaniesTask(term) {
    yield timeout(500);

    if (!term) {
      return null;
    }

    if (get(this.userInstances, 'dealCloudVersion') === SYNC_API.V1) {
      return yield this.get('store').query('deal-cloud-company', {
        'filter[name]': term,
        page: { size: 100, number: 1 },
      });
    } else {
      let query = Expressions.create();
      query.add(
        Filter.create({
          name: 'name',
          operator: FilterOperators.LIKE,
          value: term,
        })
      );

      return yield this.get('store').query('sync-company', {
        condition: query.serialize(),
        page: { size: 100, number: 1 },
      });
    }
  }

  @task({
    restartable: true,
  })
  *searchContactsTask(term) {
    yield timeout(500);
    if (!term) {
      return null;
    }

    //let query = Expressions.create({ operator: ExpressionOperators.OR });
    let query = Expressions.create({ operator: ExpressionOperators.AND });

    /*
    query.add(Filter.create({
      name: "company.name",
      operator: FilterOperators.LIKE,
      value: term
    }));
    */

    query.add(
      Filter.create({
        name: 'fullName',
        operator: FilterOperators.LIKE,
        value: term,
      })
    );

    if (get(this.userInstances, 'dealCloudVersion') === SYNC_API.V1) {
      return yield this.get('store').query('deal-cloud-contact', {
        'filter[full-name]': term,
        page: { size: 100, number: 1 },
      });
    } else if (get(this.userInstances, 'dealCloudVersion') === SYNC_API.V2) {
      let query = Expressions.create({ operator: ExpressionOperators.OR });

      query.add(
        Filter.create({
          name: 'fullName',
          operator: FilterOperators.LIKE,
          value: term,
        })
      );

      query.add(
        Filter.create({
          name: 'email',
          operator: FilterOperators.LIKE,
          value: term,
        })
      );

      return yield this.get('store').query('sync-contact', {
        condition: query.serialize(),
        include: 'company',
        page: { size: 100, number: 1 },
      });
    }
  }

  @action
  viewPerson(person) {
    this.docker.popupPerson(person);
  }

  @action
  viewCompany(company) {
    this.docker.popupCompany(company);
  }

  @action
  selectPerson(person) {
    this.set('selectedPerson', person);

    if (this.onSelectPerson) {
      this.onSelectPerson(person);
    }
  }

  @action
  selectCompany(company) {
    this.set('selectedCompany', company);

    if (this.onSelectCompany) {
      this.onSelectCompany(company);
    }
  }
}
