import Component from '@glimmer/component';
import PersonInCompany from 'cing-app/models/person-in-company';

interface ColumnComponentArgs{
  personInCompany: PersonInCompany;
}

export default class ColumnComponent extends Component<ColumnComponentArgs> {
  get personInCompany(){
    return this.args.personInCompany;
  }
 }