import classic from 'ember-classic-decorator';
import ApplicationSerializer from './application';

@classic
export default class EventSerializer extends ApplicationSerializer {
  attrs = {
    createTime: { serialize: false },
    modifyTime: { serialize: false },
    type: { serialize: false },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    event: EventSerializer;
  }
}
