import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { action, computed, set, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { CategoryResource, MyInfoResource } from 'smex-ui-sr-models';
import { cached } from 'tracked-toolbox';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

import {
  AclPermissionType,
  AclPermissionTypeEnum,
  PartyType,
} from 'cing-app/utils/lookups';
import {
  InvestorSmartRoomFolders,
  CaseProjectUrlMap,
  ProjectTabMap,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
  NotificationTypes,
} from 'cing-app/pods/smartroom/service';
import classic from 'ember-classic-decorator';
import { Models } from 'smex-ui-sr-models';
import { tracked } from '@glimmer/tracking';

@classic
export default class InvestorPartyFlow extends Component {
  @service('models') models!: Models;
  @service('docker-item') docker;
  @service store;
  @service smartroom;
  @alias('project.fund') fund;

  @cached
  get myInfoResource() {
    return new MyInfoResource(this.models, this.smartRoomId);
  }

  @cached
  get categoryResource() {
    return new CategoryResource(
      this.store,
      this.models,
      this.smartRoomId,
      this.model.srProfile
    );
  }

  UserStatus = UserStatus;
  InvestorSmartRoomFolders;
  newSmartRoomUserStatus = UserStatus.HOLD;

  partyFoldersType = 1;
  investor = null;
  investorFolder = null;
  investorCategory = null;
  contactFolders = null;
  investorFolders = null;
  availableFolders = null;

  init() {
    super.init(...arguments);

    this.initTask.perform();
  }

  @task
  *initTask() {
    // register the flow component to the parent
    if (this.registerFlow) {
      this.registerFlow(this);
    }

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: AclPermissionType.ProjectTabRead,
      })
    );

    this.store
      .query('acl-role', {
        page: {
          size: 1000,
        },
        condition: vgQuery.serialize(),
        sort: 'description',
      })
      .then((viewerGroups) => {
        this.set('viewerGroups', viewerGroups);
      });

    let srFolders = this.model.srFolders;

    if (
      srFolders &&
      srFolders.length &&
      (srFolders[0].toLowerCase() === 'all access' ||
        srFolders[0].toLowerCase() === 'all')
    ) {
      this.set('partyFoldersType', 0);
    }

    this.set('smartRoomProfiles', yield this.smartRoomProfilesTask.perform());

    // get the related investor record
    this.set('investor', yield this.get('model.investor'));

    // get the investor folder
    this.set('investorFolder', yield this.getInvestorFolderTask.perform());

    // get the investor folder
    this.set('investorCategory', yield this.getInvestorCategoryTask.perform());

    this.contactFoldersTask.perform();
    this.availableFoldersTask.perform();

    yield this.getSmartRoomUserTask.perform();
  }

  @task
  *selectCRMContactTask(person) {
    this.set('investorCategory', null);
    this.model.set('srProfile', null);

    this.model.setProperties({
      company: get(person, 'company'),
      companyId: get(person, 'companyId'),
    });

    if (this.model.srAccess) {
      this.getSmartRoomUserTask.perform();
    }
  }

  @task
  *selectSmartRoomContactTask(srUser) {
    this.set('investorCategory', null);
    this.model.set('srProfile', null);

    if (this.model.srAccess) {
      this.getSmartRoomUserTask.perform();
    }
  }

  @task
  *selectDealCloudContactTask(person) {
    this.set('investorCategory', null);
    this.model.set('srProfile', null);

    if (this.model.srAccess) {
      this.getSmartRoomUserTask.perform();
    }
  }

  // called from parent
  @task
  *selectCRMCompanyTask(company) {
    this.setProperties({
      newInvestor: null,
      investor: null,
      investorFolder: null,
      contactFolders: null,
      investorFolders: null,
      availableFolders: null,
    });

    this.model.setProperties({
      investor: null,
      investorId: null,
      company: get(this.model, 'person.company'),
      companyId: get(this.model, 'person.companyId'),
    });

    let fund = yield this.get('fund');

    let investor = null;

    if (company.id) {
      let query = Expressions.create({});
      query.add(
        Filter.create({
          name: 'fund-id',
          operator: FilterOperators.EQUAL,
          value: fund.id,
        })
      );

      query.add(
        Filter.create({
          name: 'company-id',
          operator: FilterOperators.EQUAL,
          value: company.id,
        })
      );

      try {
        investor = (yield this.store.query('investor', {
          condition: query.serialize(),
          page: {
            size: 1,
          },
        })).firstObject;
      } catch (e) {
        console.log(e);
      }

      if (!investor) {
        investor = this.store.createRecord('investor', {
          company: company,
          fund: fund,
        });

        this.set('newInvestor', investor);
      }
    }

    this.set('investor', investor);

    this.model.set('investor', investor);

    let investorFolder = yield this.getInvestorFolderTask.perform();
    this.set('investorFolder', investorFolder);

    yield this.investorFoldersTask.perform();
    yield this.availableFoldersTask.perform();
  }

  @task
  *selectSmartRoomCompanyTask(company) {
    this.setProperties({
      newInvestor: null,
      investor: null,
      investorFolder: null,
      contactFolders: null,
      investorFolders: null,
      availableFolders: null,
    });

    this.model.setProperties({
      investor: null,
      investorId: null,
    });

    let fund = yield this.get('fund');

    let query = Expressions.create({});
    query.add(
      Filter.create({
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: fund.id,
      })
    );

    if (company.id) {
      query.add(
        Filter.create({
          name: 'company.id',
          operator: FilterOperators.EQUAL,
          value: company.id,
        })
      );
    } else {
      query.add(
        Filter.create({
          name: 'company.name',
          operator: FilterOperators.EQUAL,
          value: company.name,
        })
      );
    }

    let investor = null;

    try {
      investor = (yield this.store.query('investor', {
        condition: query.serialize(),
        page: {
          size: 1,
        },
      })).firstObject;
    } catch (e) {
      console.log(e);
    }

    if (!investor) {
      investor = this.store.createRecord('investor', {
        company: company,
        fund: fund,
      });

      this.set('newInvestor', investor);
    }

    this.set('investor', investor);

    this.model.set('investor', investor);

    let investorFolder = yield this.getInvestorFolderTask.perform();
    this.set('investorFolder', investorFolder);

    yield this.investorFoldersTask.perform();
    yield this.availableFoldersTask.perform();
  }

  @task
  *selectDealCloudCompanyTask(dcCompany) {
    this.setProperties({
      newInvestor: null,
      investor: null,
      investorFolder: null,
      investorCategory: null,
      investorFolders: null,
      contactFolders: null,
      availableFolders: null,
    });

    this.model.setProperties({
      investor: null,
      investorId: null,
      srProfile: null,
      srAccess: null,
    });

    let fund = yield this.get('fund');

    let companyQuery = Expressions.create({});

    companyQuery.add(
      Filter.create({
        name: 'name',
        operator: FilterOperators.EQUAL,
        value: dcCompany.name,
      })
    );

    let company = (yield this.store.query('company', {
      condition: companyQuery.serialize(),
      page: {
        size: 1,
      },
    })).firstObject;

    let investor = null;

    if (!company) {
      company = this.store.createRecord('company', {
        name: dcCompany.name,
      });

      this.set('newCompany', company);

      investor = this.store.createRecord('investor', {
        company: company,
        fund: fund,
      });

      this.set('newInvestor', investor);
    } else {
      let query = Expressions.create({});
      query.add(
        Filter.create({
          name: 'fund-id',
          operator: FilterOperators.EQUAL,
          value: fund.id,
        })
      );

      query.add(
        Filter.create({
          name: 'company.name',
          operator: FilterOperators.EQUAL,
          value: dcCompany.name,
        })
      );

      investor = (yield this.store.query('investor', {
        condition: query.serialize(),
        page: {
          size: 1,
        },
      })).firstObject;

      if (!investor) {
        investor = this.store.createRecord('investor', {
          company: company,
          fund: fund,
        });

        this.set('newInvestor', investor);
      }
    }

    this.set('investor', investor);

    this.model.set('investor', investor);

    let investorFolder = yield this.getInvestorFolderTask.perform();
    this.set('investorFolder', investorFolder);

    yield this.investorFoldersTask.perform();
    yield this.availableFoldersTask.perform();
  }

  @task
  *saveTask() {
    if (this.newInvestor) {
      yield this.newInvestor.save();
      this.model.set('investor', this.newInvestor);
    }

    yield this.model.save();

    if (this.model.srAccess) {
      // create investor category if it's not set
      if (!this.investorCategory) {
        yield this.createInvestorCategoryTask.perform();
      }

      if (this.investorFolder && !this.investorCategory.siteOwner) {
        // apply security rights for all folders in path
        let folderPathItems = this.investorFolder.pathIds.split('/');

        let folderSecurities = [];

        // apply security for investor folder (view, print, save)
        folderSecurities.push({
          itemId: this.investorFolder.id,
          rightId: 32,
        });

        // apply security for parent folders (up to SmartRoom root folder)
        folderPathItems.forEach((folderId) => {
          if (folderId) {
            folderSecurities.push({
              itemId: folderId,
              rightId: 32,
            });
          }
        });

        // apply security to investor subfolders
        let partyFolders = this.model.get('srFolders');

        if (partyFolders && partyFolders.length) {
          if (
            partyFolders[0].toLowerCase() === 'all access' ||
            partyFolders[0].toLowerCase() === 'all'
          ) {
            // apply security to investor subfolders
            for (var a = 0; a < this.availableFolders.length; a++) {
              let iFolder = this.availableFolders[a];

              folderSecurities.push({
                itemId: iFolder.id,
                rightId: 5,
              });
            }
          } else {
            // apply security to investor subfolders
            for (var a = 0; a < this.availableFolders.length; a++) {
              let iFolder = this.availableFolders[a];

              folderSecurities.push({
                itemId: iFolder.id,
                rightId: this.contactFolders.includes(iFolder) ? 5 : 26,
              });
            }
          }
        }

        yield this.smartroom.setCategorySecurity(
          this.smartRoomId,
          this.investorCategory.id,
          folderSecurities
        );

        yield this.contactFoldersTask.perform();
      }

      if (this.investorCategory) {
        yield this.updateSmartRoomUserStatus.perform();
      }
    }
  }

  @task
  *getInvestorFolderTask() {
    if (this.investor && this.investor.smartRoomFolderId) {
      try {
        return yield this.get('store').queryRecord('smartroom/folder', {
          siteId: this.smartRoomId,
          id: this.investor.smartRoomFolderId,
        });
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  @task
  *getInvestorCategoryTask() {
    let category = null;

    if (this.model.srProfile) {
      try {
        category = yield this.get('store').queryRecord('smartroom/category', {
          id: this.model.srProfile,
          siteId: this.smartRoomId,
        });
      } catch (e) {
        console.log('Category does not existing.');
      }
    }

    return category;
  }

  @task
  *smartRoomProfilesTask() {
    if (this.smartRoomId) {
      return (yield this.store.query('smartroom/category', {
        siteId: this.smartRoomId,
      }))
        .toArray()
        .sortBy('categoryName');
    }

    return [];
  }

  @task
  *investorFoldersTask() {
    this.set('contactFolders', []);

    if (this.investorFolder && this.model.srProfile) {
      let categorySubFolders = (yield this.get('store').query(
        'smartroom/folder',
        {
          siteId: this.smartRoomId,
          parentId: this.investorFolder.id,
          pageSize: 100,
          pageNo: 1,
          filterType: 3,
          categoryId: this.get('model.srProfile'),
        }
      )).toArray();

      this.set('contactFolders', categorySubFolders);
    }
  }

  @task
  *contactFoldersTask() {
    this.set('contactFolders', []);

    if (this.investorFolder) {
      let categorySubFolders = (yield this.get('store').query(
        'smartroom/folder',
        {
          siteId: this.smartRoomId,
          parentId: this.investorFolder.id,
          pageSize: 100,
          pageNo: 1,
          filterType: this.get('model.srProfile') ? 3 : 0,
          categoryId: this.get('model.srProfile') || 0,
        }
      )).toArray();

      if (this.defaultAllAccessFolders && this.defaultAllAccessFolders.length) {
        for (var a = this.defaultAllAccessFolders.length - 1; a >= 0; a--) {
          let aFolder = this.defaultAllAccessFolders[a];
          try {
            let folder = yield this.get('store').queryRecord(
              'smartroom/folder',
              {
                id: aFolder.id,
                siteId: this.smartRoomId,
                categoryId: this.get('model.srProfile') || 0,
                filterType: this.get('model.srProfile') ? 3 : 0,
              }
            );

            categorySubFolders.unshift(folder);
          } catch (e) {
            console.log(
              'Folder: ',
              aFolder.name,
              ' is not available for the security profile.'
            );
          }
        }
      }

      this.set('contactFolders', categorySubFolders);
    }
  }

  @task
  *availableFoldersTask() {
    this.set('availableFolders', []);

    let subFolders = [];

    if (this.investorFolder) {
      subFolders = (yield this.get('store').query('smartroom/folder', {
        siteId: this.smartRoomId,
        parentId: this.investorFolder.id,
        pageSize: 100,
        pageNo: 1,
      })).toArray();

      this.set('investorFolders', subFolders);
    }

    if (this.defaultAllAccessFolders && this.defaultAllAccessFolders.length) {
      subFolders = [].concat(this.defaultAllAccessFolders, subFolders);
    }

    this.set('availableFolders', subFolders);
  }

  @task({
    restartable: true,
  })
  *searchInvestorsTask(term) {
    yield timeout(300);

    //API call
    let expr = Expressions.create();

    if (term) {
      expr.add(
        Filter.create({
          name: 'company.name',
          operator: FilterOperators.LIKE,
          value: term,
        })
      );
    }

    expr.add(
      Filter.create({
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: this.get('project.fundId'),
      })
    );

    return yield this.get('store').query('investor', {
      condition: expr.serialize(),
      include: 'company',
      sort: 'company.name',
      page: { size: 1000, number: 1 },
    });
  }

  @task
  *createInvestorStructureTask() {
    // fail if smartRoomId is not set
    if (this.smartRoomId === null) {
      throw new Error('There is no SmartRoom associated with the project.');
    }

    let company = yield this.investor.get('company');

    // this is the investor name (investors are linked to company entities)
    let investorName = company.get('name');

    // folder doesn't exist, create it
    if (!this.investorFolder) {
      this.set(
        'investorFolder',
        yield this.smartroom.createFolderEnforce(
          this.smartRoomId,
          this.smartroom.normalizeFolderName(company.name),
          this.smartRoomFolderId
        )
      );

      this.investor.set('smartRoomFolderId', this.investorFolder.id);
      yield this.investor.save();
    }

    // create the investor structure
    let result = yield this.smartroom.createInvestorStructure(
      this.smartRoomId,
      this.investorFolder.id
    );

    yield this.contactFoldersTask.perform();
    yield this.availableFoldersTask.perform();
  }

  @task
  *createInvestorCategoryTask() {
    let categoryName =
      this.newSecurityProfileName ||
      (yield this.model.get('email.emailAddress')).toLowerCase();

    let category = null;
    try {
      category = yield this.smartroom.createCategory(
        this.smartRoomId,
        categoryName
      );

      this.smartRoomProfiles.pushObject(category);
    } catch (e) {
      // try to match to existing profile since SmartRoom says it already exists
      if (e instanceof CategoryAlreadyExists) {
        let securityProfiles = yield this.store.query('smartroom/category', {
          siteId: this.smartRoomId,
        });

        category = securityProfiles.find((item) => {
          return item.categoryName.toLowerCase() === categoryName;
        });
      } else {
        throw e;
      }
    }

    // if no category was found, throw an error
    if (!category) {
      throw new Error('Security profile could not be created');
    }

    this.set('model.srProfile', category.id);
    this.set('investorCategory', category);

    this.model.save();
    set(this, 'confirmCreateSecurityProfile', null);

    return category;
  }

  @task
  *updateSmartRoomUserStatus() {
    let emailAddress = (yield this.model.get(
      'email.emailAddress'
    )).toLowerCase();

    if (this.model.srProfile && emailAddress) {
      let person = yield this.model.get('person');

      yield this.smartroom.addUserOrUpdateStatus(
        this.smartRoomId,
        {
          email: emailAddress,
          categoryId: this.model.srProfile,
          firstName: person ? person.firstName : null,
          lastName: person ? person.lastName : null,
        },
        this.newSmartRoomUserStatus
      );
    }
  }

  @task
  *doAddFolderTask(model) {
    let folder = yield this.smartroom.createFolder(
      this.smartRoomId,
      model.get('name'),
      model.parentId
    );

    this.set('confirmAddFolder', null);
    this.availableFolders.pushObject(folder);
    this.contactFolders.pushObject(folder);
  }

  @task
  *getSmartRoomUserTask() {
    this.set('investorCategory', null);
    this.set('smartRoomUser', null);
    this.model.set('srProfile', null);

    let emailAddress = yield this.model.get('email.emailAddress');

    if (this.model.srAccess && this.smartRoomId && emailAddress) {
      let users = yield this.store.query('smartroom/user', {
        siteId: this.smartRoomId,
        searchKey: emailAddress,
      });

      let user = users.find((item) => {
        if (item.email.toLowerCase() === emailAddress.toLowerCase()) {
          return true;
        }
      });

      this.set('smartRoomUser', user);

      if (user) {
        this.set('newSmartRoomUserStatus', user.status);
        let investorCategory = this.smartRoomProfiles.findBy(
          'id',
          this.smartRoomUser.categoryId.toString()
        );
        this.set('investorCategory', investorCategory);
        this.model.set('srProfile', investorCategory.id);
      } else {
        let emailAddress = this.model.get('email.emailAddress');

        if (emailAddress) {
          let investorCategory = this.smartRoomProfiles.findBy(
            'categoryName',
            emailAddress
          );

          if (investorCategory) {
            this.set('investorCategory', investorCategory);
            this.model.set('srProfile', investorCategory.id);
          }
        }
      }
    }
  }

  @action
  onSmartRoomAccessToggle(value) {
    this.getSmartRoomUserTask.perform();
  }

  @action
  changeSRProfile(categoryId) {
    let category = this.smartRoomProfiles.findBy('id', categoryId);

    if (category) {
      this.model.set('srProfile', category.id);
      this.getInvestorCategoryTask.perform().then((category) => {
        if (category) {
          this.set('investorCategory', category);
          this.model.set('srProfile', category.id);
        } else {
          this.set('investorCategory', null);
          this.model.set('srProfile', null);
        }

        this.investorFoldersTask.perform();
        this.availableFoldersTask.perform();
      });
    } else {
      this.set('investorCategory', null);
      this.model.set('srProfile', null);

      this.investorFoldersTask.perform();
      this.availableFoldersTask.perform();
    }
  }

  @action
  addFolder() {
    this.set(
      'confirmAddFolder',
      this.store.createRecord('smartroom/folder', {
        parentId: this.investorFolder.id,
      })
    );
  }

  @action
  doAddFolder() {
    let model = this.confirmAddFolder;

    if (model.get('validations.isValid')) {
      this.doAddFolderTask.perform(model);
    }
  }

  @action
  selectContactFolders(folders) {
    this.set('contactFolders', folders);
    this.model.set('srFolders', folders.mapBy('name'));

    let availableFolders = this.availableFolders;

    let allAccess = true;

    availableFolders.forEach((item) => {
      if (!folders.includes(item)) {
        allAccess = false;
      }
    });

    // handle special cases
    if (allAccess) {
      this.model.set('srFolders', ['All Access']);
    } else {
      this.model.set('srFolders', folders.mapBy('name'));
    }
  }

  @action
  selectPartyFoldersType(type) {
    if (type === 0) {
      this.model.set('srFolders', ['All Access']);
    } else {
      if (this.contactFolders) {
        this.model.set('srFolders', this.contactFolders.mapBy('name'));
      } else {
        this.model.set('srFolders', []);
      }
    }
  }

  @action
  createInvestorStructure() {
    this.createInvestorStructureTask.perform();
  }

  @action
  doCreateSecurityProfile() {
    this.createInvestorCategoryTask.perform();
  }

  @action
  createSecurityProfile() {
    set(
      this,
      'newSecurityProfileName',
      get(this.model, 'email.emailAddress').toLowerCase()
    );
    set(this, 'confirmCreateSecurityProfile', true);
  }

  @action
  editCategorySecurity() {
    let category = this.investorCategory;
    let appearance = {
      icon: '',
      title: `Security Profile: ${category.categoryName}`,
      size: 'large',
      custom: true,
    };
    this.get('docker').invokePopup('smartroom/category-security', appearance, {
      siteId: this.smartRoomId,
      categoryId: category.id,
    });
  }
}
