import { attr } from '@ember-data/model';
//@ts-ignore
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
import DataLinkModelColumn from './data-link-model-column';

export default class DataLinkModel extends Fragment {
  @attr('string') declare modelName: string;
  @attr('string') declare name: string;
  @fragment('data-link-model-data-source') declare dataSource: object;
  @fragmentArray('data-link-model-column')
  declare columns: DataLinkModelColumn[];
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-link-model': DataLinkModel;
  }
}
