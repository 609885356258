import Component from '@ember/component';
import { computed, action, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { EReportStatus, ProjectTabMap } from 'cing-app/utils/lookups';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import TableTheme from '../abstract-import/table-theme';

const PARTY_MODES = {
  SIMPLE: 'simple',
  BATCH: 'batch',
};

function FixInvestorNumber(serialized) {
  if (serialized && /^\d+\.+\d+$/.test(serialized)) {
    return parseInt(serialized, 10);
  } else {
    return serialized;
  }
}

import { guidFor } from '@ember/object/internals';
import classic from 'ember-classic-decorator';

@classic
export default class ImportDCInvestors extends Component {
  @service store;
  @service config;
  @service session;
  @service smartroom;
  @service seActions;
  @service('docker-item') docker;

  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  searchName = null;
  pageSize = 20;
  selectedFund = null;
  currentStep = 1;
  defaultFolderStructure = null;

  @computed
  get themeInstance() {
    return TableTheme.create();
  }

  @alias('context.project') project;

  @alias('context.onComplete') onComplete;

  init() {
    super.init(...arguments);
    set(this, 'selectedItems', []);
    this.initTask.perform();
    set(
      this,
      'defaultFolderStructure',
      'Capital Calls\nDistributions\nFinancials\nLegal Documents\nOther Documents\nTax Information'
    );
  }

  @task
  *initTask() {
    let interestedPartyRoles = yield this.store.query('interested-party-role', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });

    set(this, 'interestedPartyRoles', interestedPartyRoles.toArray());

    let funds = yield this.store.query('deal-cloud-fund', {
      page: {
        size: 100,
      },
    });

    let srConnection = this.context.srConnection;

    if (srConnection) {
      this.setProperties({
        smartRoomId: srConnection.SRSiteId,
        smartRoomFolderId: srConnection.SRFolderId,
      });
    }

    let projectFund = yield get(this.project, 'fund');

    set(this, 'fund', projectFund);
    set(this, 'dealCloudFunds', funds);

    let projectDealCloudFund = get(this.project, 'dealCloudFund');

    // pre-select the fund that's been associated with the project
    if (projectDealCloudFund) {
      this.selectFund(
        this.dealCloudFunds.findBy('id', projectDealCloudFund.id)
      );
    }
  }

  @task({
    restartable: true,
  })
  *updateInvestorFilter() {
    let filter = {};

    if (this.selectedFund) {
      filter['fund-ids'] = this.selectedFund.id;
    }

    if (this.searchName) {
      filter['name'] = this.searchName;
    }

    set(this, 'filter', filter);

    //this.set('reloadData', true);
    let investors = yield this.store.query('deal-cloud-investor', {
      filter: filter,
      page: {
        size: 1000,
        number: 1,
      },
    });

    let defaultPartyRole = this.interestedPartyRoles.findBy('name', 'Investor');

    let investorFundIdPrefix = get(this.project, 'investorFundIdPrefix');

    investors.forEach((investor) => {
      let investorNumbers = get(investor, 'investorNumbers');
      let investorNumber = null;
      let invesetorFundId = null;

      if (this.selectedFund && investorNumbers && investorNumbers.length) {
        let fundNameParts = this.selectedFund.name.split(' ');
        let fundNumber = fundNameParts[fundNameParts.length - 1];

        for (var a = 0; a < investorNumbers.length; a++) {
          if (investorNumbers[a].name.endsWith(' ' + fundNumber)) {
            investorNumber = FixInvestorNumber(investorNumbers[a].number);
          }
        }
      }
      if (!investor.role) {
        set(investor, 'role', defaultPartyRole.name);
      }

      set(
        investor,
        'investorFundId',
        investorFundIdPrefix
          ? investorFundIdPrefix + investorNumber
          : investorNumber
      );
      set(investor, 'investorNumber', investorNumber);
    });

    investors = investors.sortBy('name');
    set(this, 'investors', investors);
  }

  @computed
  get companies() {
    return this.store.findAll('deal-cloud-company');
  }

  @computed
  get columns() {
    let columns = [
      {
        title: 'Investor Name',
        propertyName: 'name',
        component: 'manage-interested-party/import-dc-investors/column-name',
        sortDisabled: true,
      },
      {
        title: 'Role',
        propertyName: 'role',
        sortDisabled: true,
        component: 'manage-interested-party/import-dc-investors/column-select',
        options: this.interestedPartyRoles,
        createModelName: 'interested-party-role',
      },
      {
        title: 'Investor Number',
        propertyName: 'investorNumber',
        sortDisabled: true,
      },
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
    ];

    return columns;
  }

  @task
  *processTask() {
    yield this.addInvestorsTask.perform();
    yield this.createFolderStructureTask.perform();

    if (this.onComplete) {
      this.onComplete();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @task
  *addInvestorsTask() {
    let investorsForImport = [];

    this.selectedInvestors.forEach((investor) => {
      investorsForImport.push({
        InvestorName: investor.name,
        DealCloudInvestorId: investor.id,
        InvestorFundId: investor.investorFundId,
        InvestorNumber: investor.investorNumber,
        Role: investor.role,
      });
    });

    let importAction = yield this.seActions.importPartiesStepOne(
      new Blob([JSON.stringify(investorsForImport)], {
        type: 'application/json',
      }),
      this.project.id
    );

    set(this, 'addInvestorsAction', importAction);

    yield this.seActions.importPartiesStepTwo(importAction);
  }

  @task
  *createFolderStructureTask() {
    let allTasks = [];

    for (var a = 0; a < this.selectedInvestors.length; a++) {
      allTasks.push(
        this.createInvestorStructure.perform(this.selectedInvestors[a])
      );
    }

    yield all(allTasks);
  }

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  *createInvestorStructure(investorData) {
    let fund = this.fund;

    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: fund.id,
      })
    );

    if (investorData.investorNumber) {
      query.add(
        Filter.create({
          name: 'investor-number',
          operator: FilterOperators.EQUAL,
          value: investorData.investorNumber,
        })
      );
    } else if (investorData.name) {
      query.add(
        Filter.create({
          name: 'company.name',
          operator: FilterOperators.EQUAL,
          value: investorData.name,
        })
      );
    }

    let investor = (yield this.store.query('investor', {
      condition: query.serialize(),
      include: 'company',
      page: {
        size: 1,
        number: 1,
      },
    })).firstObject;

    if (!investor) {
      console.log(
        'SKIP STRUCTURE, CAN NOT FIND INVESTOR RECORD for: ',
        investorData
      );
      return;
    }

    // fail if smartRoomId is not set
    if (this.smartRoomId === null) {
      throw new Error('There is no SmartRoom associated with the project.');
    }

    let company = yield get(investor, 'company');

    // folder doesn't exist, create it
    let investorFolder = yield this.smartroom.createFolderEnforce(
      this.smartRoomId,
      this.smartroom.normalizeFolderName(company.name),
      this.smartRoomFolderId
    );

    set(investor, 'smartRoomFolderId', investorFolder.id);
    yield investor.save();

    let folders = this.defaultFolderStructure.split('\n');

    // create the investor structure
    let result = yield this.smartroom.createInvestorStructure(
      this.smartRoomId,
      investorFolder.id,
      folders
    );
  }
  @action
  selectInvestors() {
    set(this, 'selectedInvestors', this.selectedItems);
    set(this, 'currentStep', 2);
  }

  @action
  search() {
    this.updateInvestorFilter.perform();
  }

  @action
  searchInvestors(term) {
    return this.searchInvestorsTask.perform(term);
  }

  @action
  selectFund(fund) {
    set(this, 'selectedFund', fund);

    this.updateInvestorFilter.perform();
  }

  @action
  process() {
    this.processTask.perform();
  }
}
