import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
});

export default Model.extend(Validations, {
  invFundId: attr('string'),
  eventId: attr('string'),
  noticeDate: attr('abdate2'),
  dueDate: attr('abdate2'),
  description: attr('string'),
  distPercOfCommitment: attr('number'),
  amount: attr('number'),
  eventName: attr('string'),
  eventStatus: attr('string'),
  fundId: attr('string'),
  current: attr('string'),
  investorNumber: attr('string'),
  investorName: attr('string'),
  prefix: attr('string'),
  suffix: attr('string'),
  type: attr('string'),
  fileId: attr('string'),
  fileName: attr('string'),
});

