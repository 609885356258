import Component from '@glimmer/component';
import User from 'cing-app/models/user';

interface ColumnRolesArgs {
  record: User;
  column: any;
}
export default class ColumnRoles extends Component<ColumnRolesArgs> {

  get roles() {
    return this.args.record[this.args.column.propertyName];
  }
}