
/**
 * NAICS codes (2-4 digit codes)
 */
let naicsCodes = [
        {
          "code": "11",
          "name": "Agriculture, Forestry, Fishing and Hunting"
        },
        {
          "code": "111",
          "name": "Crop Production"
        },
        {
          "code": "1111",
          "name": "Oilseed and Grain Farming"
        },
        {
          "code": "11111",
          "name": "Soybean Farming"
        },
        {
          "code": "111110",
          "name": "Soybean Farming"
        },
        {
          "code": "11112",
          "name": "Oilseed (except Soybean) Farming"
        },
        {
          "code": "111120",
          "name": "Oilseed (except Soybean) Farming"
        },
        {
          "code": "11113",
          "name": "Dry Pea and Bean Farming"
        },
        {
          "code": "111130",
          "name": "Dry Pea and Bean Farming"
        },
        {
          "code": "11114",
          "name": "Wheat Farming"
        },
        {
          "code": "111140",
          "name": "Wheat Farming"
        },
        {
          "code": "11115",
          "name": "Corn Farming"
        },
        {
          "code": "111150",
          "name": "Corn Farming"
        },
        {
          "code": "11116",
          "name": "Rice Farming"
        },
        {
          "code": "111160",
          "name": "Rice Farming"
        },
        {
          "code": "11119",
          "name": "Other Grain Farming"
        },
        {
          "code": "111191",
          "name": "Oilseed and Grain Combination Farming"
        },
        {
          "code": "111199",
          "name": "All Other Grain Farming"
        },
        {
          "code": "1112",
          "name": "Vegetable and Melon Farming"
        },
        {
          "code": "11121",
          "name": "Vegetable and Melon Farming"
        },
        {
          "code": "111211",
          "name": "Potato Farming"
        },
        {
          "code": "111219",
          "name": "Other Vegetable (except Potato) and Melon Farming"
        },
        {
          "code": "1113",
          "name": "Fruit and Tree Nut Farming"
        },
        {
          "code": "11131",
          "name": "Orange Groves"
        },
        {
          "code": "111310",
          "name": "Orange Groves"
        },
        {
          "code": "11132",
          "name": "Citrus (except Orange) Groves"
        },
        {
          "code": "111320",
          "name": "Citrus (except Orange) Groves"
        },
        {
          "code": "11133",
          "name": "Noncitrus Fruit and Tree Nut Farming"
        },
        {
          "code": "111331",
          "name": "Apple Orchards"
        },
        {
          "code": "111332",
          "name": "Grape Vineyards"
        },
        {
          "code": "111333",
          "name": "Strawberry Farming"
        },
        {
          "code": "111334",
          "name": "Berry (except Strawberry) Farming"
        },
        {
          "code": "111335",
          "name": "Tree Nut Farming"
        },
        {
          "code": "111336",
          "name": "Fruit and Tree Nut Combination Farming"
        },
        {
          "code": "111339",
          "name": "Other Noncitrus Fruit Farming"
        },
        {
          "code": "1114",
          "name": "Greenhouse, Nursery, and Floriculture Production"
        },
        {
          "code": "11141",
          "name": "Food Crops Grown Under Cover"
        },
        {
          "code": "111411",
          "name": "Mushroom Production"
        },
        {
          "code": "111419",
          "name": "Other Food Crops Grown Under Cover"
        },
        {
          "code": "11142",
          "name": "Nursery and Floriculture Production"
        },
        {
          "code": "111421",
          "name": "Nursery and Tree Production"
        },
        {
          "code": "111422",
          "name": "Floriculture Production"
        },
        {
          "code": "1119",
          "name": "Other Crop Farming"
        },
        {
          "code": "11191",
          "name": "Tobacco Farming"
        },
        {
          "code": "111910",
          "name": "Tobacco Farming"
        },
        {
          "code": "11192",
          "name": "Cotton Farming"
        },
        {
          "code": "111920",
          "name": "Cotton Farming"
        },
        {
          "code": "11193",
          "name": "Sugarcane Farming"
        },
        {
          "code": "111930",
          "name": "Sugarcane Farming"
        },
        {
          "code": "11194",
          "name": "Hay Farming"
        },
        {
          "code": "111940",
          "name": "Hay Farming"
        },
        {
          "code": "11199",
          "name": "All Other Crop Farming"
        },
        {
          "code": "111991",
          "name": "Sugar Beet Farming"
        },
        {
          "code": "111992",
          "name": "Peanut Farming"
        },
        {
          "code": "111998",
          "name": "All Other Miscellaneous Crop Farming"
        },
        {
          "code": "112",
          "name": "Animal Production and Aquaculture"
        },
        {
          "code": "1121",
          "name": "Cattle Ranching and Farming"
        },
        {
          "code": "11211",
          "name": "Beef Cattle Ranching and Farming, including Feedlots"
        },
        {
          "code": "112111",
          "name": "Beef Cattle Ranching and Farming"
        },
        {
          "code": "112112",
          "name": "Cattle Feedlots"
        },
        {
          "code": "11212",
          "name": "Dairy Cattle and Milk Production"
        },
        {
          "code": "112120",
          "name": "Dairy Cattle and Milk Production"
        },
        {
          "code": "11213",
          "name": "Dual-Purpose Cattle Ranching and Farming"
        },
        {
          "code": "112130",
          "name": "Dual-Purpose Cattle Ranching and Farming"
        },
        {
          "code": "1122",
          "name": "Hog and Pig Farming"
        },
        {
          "code": "11221",
          "name": "Hog and Pig Farming"
        },
        {
          "code": "112210",
          "name": "Hog and Pig Farming"
        },
        {
          "code": "1123",
          "name": "Poultry and Egg Production"
        },
        {
          "code": "11231",
          "name": "Chicken Egg Production"
        },
        {
          "code": "112310",
          "name": "Chicken Egg Production"
        },
        {
          "code": "11232",
          "name": "Broilers and Other Meat Type Chicken Production"
        },
        {
          "code": "112320",
          "name": "Broilers and Other Meat Type Chicken Production"
        },
        {
          "code": "11233",
          "name": "Turkey Production"
        },
        {
          "code": "112330",
          "name": "Turkey Production"
        },
        {
          "code": "11234",
          "name": "Poultry Hatcheries"
        },
        {
          "code": "112340",
          "name": "Poultry Hatcheries"
        },
        {
          "code": "11239",
          "name": "Other Poultry Production"
        },
        {
          "code": "112390",
          "name": "Other Poultry Production"
        },
        {
          "code": "1124",
          "name": "Sheep and Goat Farming"
        },
        {
          "code": "11241",
          "name": "Sheep Farming"
        },
        {
          "code": "112410",
          "name": "Sheep Farming"
        },
        {
          "code": "11242",
          "name": "Goat Farming"
        },
        {
          "code": "112420",
          "name": "Goat Farming"
        },
        {
          "code": "1125",
          "name": "Aquaculture"
        },
        {
          "code": "11251",
          "name": "Aquaculture"
        },
        {
          "code": "112511",
          "name": "Finfish Farming and Fish Hatcheries"
        },
        {
          "code": "112512",
          "name": "Shellfish Farming"
        },
        {
          "code": "112519",
          "name": "Other Aquaculture"
        },
        {
          "code": "1129",
          "name": "Other Animal Production"
        },
        {
          "code": "11291",
          "name": "Apiculture"
        },
        {
          "code": "112910",
          "name": "Apiculture"
        },
        {
          "code": "11292",
          "name": "Horses and Other Equine Production"
        },
        {
          "code": "112920",
          "name": "Horses and Other Equine Production"
        },
        {
          "code": "11293",
          "name": "Fur-Bearing Animal and Rabbit Production"
        },
        {
          "code": "112930",
          "name": "Fur-Bearing Animal and Rabbit Production"
        },
        {
          "code": "11299",
          "name": "All Other Animal Production"
        },
        {
          "code": "112990",
          "name": "All Other Animal Production"
        },
        {
          "code": "113",
          "name": "Forestry and Logging"
        },
        {
          "code": "1131",
          "name": "Timber Tract Operations"
        },
        {
          "code": "11311",
          "name": "Timber Tract Operations"
        },
        {
          "code": "113110",
          "name": "Timber Tract Operations"
        },
        {
          "code": "1132",
          "name": "Forest Nurseries and Gathering of Forest Products"
        },
        {
          "code": "11321",
          "name": "Forest Nurseries and Gathering of Forest Products"
        },
        {
          "code": "113210",
          "name": "Forest Nurseries and Gathering of Forest Products"
        },
        {
          "code": "1133",
          "name": "Logging"
        },
        {
          "code": "11331",
          "name": "Logging"
        },
        {
          "code": "113310",
          "name": "Logging"
        },
        {
          "code": "114",
          "name": "Fishing, Hunting and Trapping"
        },
        {
          "code": "1141",
          "name": "Fishing"
        },
        {
          "code": "11411",
          "name": "Fishing"
        },
        {
          "code": "114111",
          "name": "Finfish Fishing"
        },
        {
          "code": "114112",
          "name": "Shellfish Fishing"
        },
        {
          "code": "114119",
          "name": "Other Marine Fishing"
        },
        {
          "code": "1142",
          "name": "Hunting and Trapping"
        },
        {
          "code": "11421",
          "name": "Hunting and Trapping"
        },
        {
          "code": "114210",
          "name": "Hunting and Trapping"
        },
        {
          "code": "115",
          "name": "Support Activities for Agriculture and Forestry"
        },
        {
          "code": "1151",
          "name": "Support Activities for Crop Production"
        },
        {
          "code": "11511",
          "name": "Support Activities for Crop Production"
        },
        {
          "code": "115111",
          "name": "Cotton Ginning"
        },
        {
          "code": "115112",
          "name": "Soil Preparation, Planting, and Cultivating"
        },
        {
          "code": "115113",
          "name": "Crop Harvesting, Primarily by Machine"
        },
        {
          "code": "115114",
          "name": "Postharvest Crop Activities (except Cotton Ginning)"
        },
        {
          "code": "115115",
          "name": "Farm Labor Contractors and Crew Leaders"
        },
        {
          "code": "115116",
          "name": "Farm Management Services"
        },
        {
          "code": "1152",
          "name": "Support Activities for Animal Production"
        },
        {
          "code": "11521",
          "name": "Support Activities for Animal Production"
        },
        {
          "code": "115210",
          "name": "Support Activities for Animal Production"
        },
        {
          "code": "1153",
          "name": "Support Activities for Forestry"
        },
        {
          "code": "11531",
          "name": "Support Activities for Forestry"
        },
        {
          "code": "115310",
          "name": "Support Activities for Forestry"
        },
        {
          "code": "21",
          "name": "Mining, Quarrying, and Oil and Gas Extraction"
        },
        {
          "code": "211",
          "name": "Oil and Gas Extraction"
        },
        {
          "code": "2111",
          "name": "Oil and Gas Extraction"
        },
        {
          "code": "21112",
          "name": "Crude Petroleum Extraction"
        },
        {
          "code": "211120",
          "name": "Crude Petroleum Extraction"
        },
        {
          "code": "21113",
          "name": "Natural Gas Extraction"
        },
        {
          "code": "211130",
          "name": "Natural Gas Extraction"
        },
        {
          "code": "212",
          "name": "Mining (except Oil and Gas)"
        },
        {
          "code": "2121",
          "name": "Coal Mining"
        },
        {
          "code": "21211",
          "name": "Coal Mining"
        },
        {
          "code": "212111",
          "name": "Bituminous Coal and Lignite Surface Mining"
        },
        {
          "code": "212112",
          "name": "Bituminous Coal Underground Mining"
        },
        {
          "code": "212113",
          "name": "Anthracite Mining"
        },
        {
          "code": "2122",
          "name": "Metal Ore Mining"
        },
        {
          "code": "21221",
          "name": "Iron Ore Mining"
        },
        {
          "code": "212210",
          "name": "Iron Ore Mining"
        },
        {
          "code": "21222",
          "name": "Gold Ore and Silver Ore Mining"
        },
        {
          "code": "212221",
          "name": "Gold Ore Mining"
        },
        {
          "code": "212222",
          "name": "Silver Ore Mining"
        },
        {
          "code": "21223",
          "name": "Copper, Nickel, Lead, and Zinc Mining"
        },
        {
          "code": "212230",
          "name": "Copper, Nickel, Lead, and Zinc Mining"
        },
        {
          "code": "21229",
          "name": "Other Metal Ore Mining"
        },
        {
          "code": "212291",
          "name": "Uranium-Radium-Vanadium Ore Mining"
        },
        {
          "code": "212299",
          "name": "All Other Metal Ore Mining"
        },
        {
          "code": "2123",
          "name": "Nonmetallic Mineral Mining and Quarrying"
        },
        {
          "code": "21231",
          "name": "Stone Mining and Quarrying"
        },
        {
          "code": "212311",
          "name": "Dimension Stone Mining and Quarrying"
        },
        {
          "code": "212312",
          "name": "Crushed and Broken Limestone Mining and Quarrying"
        },
        {
          "code": "212313",
          "name": "Crushed and Broken Granite Mining and Quarrying"
        },
        {
          "code": "212319",
          "name": "Other Crushed and Broken Stone Mining and Quarrying"
        },
        {
          "code": "21232",
          "name": "Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying"
        },
        {
          "code": "212321",
          "name": "Construction Sand and Gravel Mining"
        },
        {
          "code": "212322",
          "name": "Industrial Sand Mining"
        },
        {
          "code": "212324",
          "name": "Kaolin and Ball Clay Mining"
        },
        {
          "code": "212325",
          "name": "Clay and Ceramic and Refractory Minerals Mining"
        },
        {
          "code": "21239",
          "name": "Other Nonmetallic Mineral Mining and Quarrying"
        },
        {
          "code": "212391",
          "name": "Potash, Soda, and Borate Mineral Mining"
        },
        {
          "code": "212392",
          "name": "Phosphate Rock Mining"
        },
        {
          "code": "212393",
          "name": "Other Chemical and Fertilizer Mineral Mining"
        },
        {
          "code": "212399",
          "name": "All Other Nonmetallic Mineral Mining"
        },
        {
          "code": "213",
          "name": "Support Activities for Mining"
        },
        {
          "code": "2131",
          "name": "Support Activities for Mining"
        },
        {
          "code": "21311",
          "name": "Support Activities for Mining"
        },
        {
          "code": "213111",
          "name": "Drilling Oil and Gas Wells"
        },
        {
          "code": "213112",
          "name": "Support Activities for Oil and Gas Operations"
        },
        {
          "code": "213113",
          "name": "Support Activities for Coal Mining"
        },
        {
          "code": "213114",
          "name": "Support Activities for Metal Mining"
        },
        {
          "code": "213115",
          "name": "Support Activities for Nonmetallic Minerals (except Fuels) Mining"
        },
        {
          "code": "22",
          "name": "Utilities"
        },
        {
          "code": "221",
          "name": "Utilities"
        },
        {
          "code": "2211",
          "name": "Electric Power Generation, Transmission and Distribution"
        },
        {
          "code": "22111",
          "name": "Electric Power Generation"
        },
        {
          "code": "221111",
          "name": "Hydroelectric Power Generation"
        },
        {
          "code": "221112",
          "name": "Fossil Fuel Electric Power Generation"
        },
        {
          "code": "221113",
          "name": "Nuclear Electric Power Generation"
        },
        {
          "code": "221114",
          "name": "Solar Electric Power Generation"
        },
        {
          "code": "221115",
          "name": "Wind Electric Power Generation"
        },
        {
          "code": "221116",
          "name": "Geothermal Electric Power Generation"
        },
        {
          "code": "221117",
          "name": "Biomass Electric Power Generation"
        },
        {
          "code": "221118",
          "name": "Other Electric Power Generation"
        },
        {
          "code": "22112",
          "name": "Electric Power Transmission, Control, and Distribution"
        },
        {
          "code": "221121",
          "name": "Electric Bulk Power Transmission and Control"
        },
        {
          "code": "221122",
          "name": "Electric Power Distribution"
        },
        {
          "code": "2212",
          "name": "Natural Gas Distribution"
        },
        {
          "code": "22121",
          "name": "Natural Gas Distribution"
        },
        {
          "code": "221210",
          "name": "Natural Gas Distribution"
        },
        {
          "code": "2213",
          "name": "Water, Sewage and Other Systems"
        },
        {
          "code": "22131",
          "name": "Water Supply and Irrigation Systems"
        },
        {
          "code": "221310",
          "name": "Water Supply and Irrigation Systems"
        },
        {
          "code": "22132",
          "name": "Sewage Treatment Facilities"
        },
        {
          "code": "221320",
          "name": "Sewage Treatment Facilities"
        },
        {
          "code": "22133",
          "name": "Steam and Air-Conditioning Supply"
        },
        {
          "code": "221330",
          "name": "Steam and Air-Conditioning Supply"
        },
        {
          "code": "23",
          "name": "Construction"
        },
        {
          "code": "236",
          "name": "Construction of Buildings"
        },
        {
          "code": "2361",
          "name": "Residential Building Construction"
        },
        {
          "code": "23611",
          "name": "Residential Building Construction"
        },
        {
          "code": "236115",
          "name": "New Single-Family Housing Construction (except For-Sale Builders)"
        },
        {
          "code": "236116",
          "name": "New Multifamily Housing Construction (except For-Sale Builders)"
        },
        {
          "code": "236117",
          "name": "New Housing For-Sale Builders"
        },
        {
          "code": "236118",
          "name": "Residential Remodelers"
        },
        {
          "code": "2362",
          "name": "Nonresidential Building Construction"
        },
        {
          "code": "23621",
          "name": "Industrial Building Construction"
        },
        {
          "code": "236210",
          "name": "Industrial Building Construction"
        },
        {
          "code": "23622",
          "name": "Commercial and Institutional Building Construction"
        },
        {
          "code": "236220",
          "name": "Commercial and Institutional Building Construction"
        },
        {
          "code": "237",
          "name": "Heavy and Civil Engineering Construction"
        },
        {
          "code": "2371",
          "name": "Utility System Construction"
        },
        {
          "code": "23711",
          "name": "Water and Sewer Line and Related Structures Construction"
        },
        {
          "code": "237110",
          "name": "Water and Sewer Line and Related Structures Construction"
        },
        {
          "code": "23712",
          "name": "Oil and Gas Pipeline and Related Structures Construction"
        },
        {
          "code": "237120",
          "name": "Oil and Gas Pipeline and Related Structures Construction"
        },
        {
          "code": "23713",
          "name": "Power and Communication Line and Related Structures Construction"
        },
        {
          "code": "237130",
          "name": "Power and Communication Line and Related Structures Construction"
        },
        {
          "code": "2372",
          "name": "Land Subdivision"
        },
        {
          "code": "23721",
          "name": "Land Subdivision"
        },
        {
          "code": "237210",
          "name": "Land Subdivision"
        },
        {
          "code": "2373",
          "name": "Highway, Street, and Bridge Construction"
        },
        {
          "code": "23731",
          "name": "Highway, Street, and Bridge Construction"
        },
        {
          "code": "237310",
          "name": "Highway, Street, and Bridge Construction"
        },
        {
          "code": "2379",
          "name": "Other Heavy and Civil Engineering Construction"
        },
        {
          "code": "23799",
          "name": "Other Heavy and Civil Engineering Construction"
        },
        {
          "code": "237990",
          "name": "Other Heavy and Civil Engineering Construction"
        },
        {
          "code": "238",
          "name": "Specialty Trade Contractors"
        },
        {
          "code": "2381",
          "name": "Foundation, Structure, and Building Exterior Contractors"
        },
        {
          "code": "23811",
          "name": "Poured Concrete Foundation and Structure Contractors"
        },
        {
          "code": "238110",
          "name": "Poured Concrete Foundation and Structure Contractors"
        },
        {
          "code": "23812",
          "name": "Structural Steel and Precast Concrete Contractors"
        },
        {
          "code": "238120",
          "name": "Structural Steel and Precast Concrete Contractors"
        },
        {
          "code": "23813",
          "name": "Framing Contractors"
        },
        {
          "code": "238130",
          "name": "Framing Contractors"
        },
        {
          "code": "23814",
          "name": "Masonry Contractors"
        },
        {
          "code": "238140",
          "name": "Masonry Contractors"
        },
        {
          "code": "23815",
          "name": "Glass and Glazing Contractors"
        },
        {
          "code": "238150",
          "name": "Glass and Glazing Contractors"
        },
        {
          "code": "23816",
          "name": "Roofing Contractors"
        },
        {
          "code": "238160",
          "name": "Roofing Contractors"
        },
        {
          "code": "23817",
          "name": "Siding Contractors"
        },
        {
          "code": "238170",
          "name": "Siding Contractors"
        },
        {
          "code": "23819",
          "name": "Other Foundation, Structure, and Building Exterior Contractors"
        },
        {
          "code": "238190",
          "name": "Other Foundation, Structure, and Building Exterior Contractors"
        },
        {
          "code": "2382",
          "name": "Building Equipment Contractors"
        },
        {
          "code": "23821",
          "name": "Electrical Contractors and Other Wiring Installation Contractors"
        },
        {
          "code": "238210",
          "name": "Electrical Contractors and Other Wiring Installation Contractors"
        },
        {
          "code": "23822",
          "name": "Plumbing, Heating, and Air-Conditioning Contractors"
        },
        {
          "code": "238220",
          "name": "Plumbing, Heating, and Air-Conditioning Contractors"
        },
        {
          "code": "23829",
          "name": "Other Building Equipment Contractors"
        },
        {
          "code": "238290",
          "name": "Other Building Equipment Contractors"
        },
        {
          "code": "2383",
          "name": "Building Finishing Contractors"
        },
        {
          "code": "23831",
          "name": "Drywall and Insulation Contractors"
        },
        {
          "code": "238310",
          "name": "Drywall and Insulation Contractors"
        },
        {
          "code": "23832",
          "name": "Painting and Wall Covering Contractors"
        },
        {
          "code": "238320",
          "name": "Painting and Wall Covering Contractors"
        },
        {
          "code": "23833",
          "name": "Flooring Contractors"
        },
        {
          "code": "238330",
          "name": "Flooring Contractors"
        },
        {
          "code": "23834",
          "name": "Tile and Terrazzo Contractors"
        },
        {
          "code": "238340",
          "name": "Tile and Terrazzo Contractors"
        },
        {
          "code": "23835",
          "name": "Finish Carpentry Contractors"
        },
        {
          "code": "238350",
          "name": "Finish Carpentry Contractors"
        },
        {
          "code": "23839",
          "name": "Other Building Finishing Contractors"
        },
        {
          "code": "238390",
          "name": "Other Building Finishing Contractors"
        },
        {
          "code": "2389",
          "name": "Other Specialty Trade Contractors"
        },
        {
          "code": "23891",
          "name": "Site Preparation Contractors"
        },
        {
          "code": "238910",
          "name": "Site Preparation Contractors"
        },
        {
          "code": "23899",
          "name": "All Other Specialty Trade Contractors"
        },
        {
          "code": "238990",
          "name": "All Other Specialty Trade Contractors"
        },
        {
          "code": "31-33",
          "name": "Manufacturing"
        },
        {
          "code": "311",
          "name": "Food Manufacturing"
        },
        {
          "code": "3111",
          "name": "Animal Food Manufacturing"
        },
        {
          "code": "31111",
          "name": "Animal Food Manufacturing"
        },
        {
          "code": "311111",
          "name": "Dog and Cat Food Manufacturing"
        },
        {
          "code": "311119",
          "name": "Other Animal Food Manufacturing"
        },
        {
          "code": "3112",
          "name": "Grain and Oilseed Milling"
        },
        {
          "code": "31121",
          "name": "Flour Milling and Malt Manufacturing"
        },
        {
          "code": "311211",
          "name": "Flour Milling"
        },
        {
          "code": "311212",
          "name": "Rice Milling"
        },
        {
          "code": "311213",
          "name": "Malt Manufacturing"
        },
        {
          "code": "31122",
          "name": "Starch and Vegetable Fats and Oils Manufacturing"
        },
        {
          "code": "311221",
          "name": "Wet Corn Milling"
        },
        {
          "code": "311224",
          "name": "Soybean and Other Oilseed Processing"
        },
        {
          "code": "311225",
          "name": "Fats and Oils Refining and Blending"
        },
        {
          "code": "31123",
          "name": "Breakfast Cereal Manufacturing"
        },
        {
          "code": "311230",
          "name": "Breakfast Cereal Manufacturing"
        },
        {
          "code": "3113",
          "name": "Sugar and Confectionery Product Manufacturing"
        },
        {
          "code": "31131",
          "name": "Sugar Manufacturing"
        },
        {
          "code": "311313",
          "name": "Beet Sugar Manufacturing"
        },
        {
          "code": "311314",
          "name": "Cane Sugar Manufacturing"
        },
        {
          "code": "31134",
          "name": "Nonchocolate Confectionery Manufacturing"
        },
        {
          "code": "311340",
          "name": "Nonchocolate Confectionery Manufacturing"
        },
        {
          "code": "31135",
          "name": "Chocolate and Confectionery Manufacturing"
        },
        {
          "code": "311351",
          "name": "Chocolate and Confectionery Manufacturing from Cacao Beans"
        },
        {
          "code": "311352",
          "name": "Confectionery Manufacturing from Purchased Chocolate"
        },
        {
          "code": "3114",
          "name": "Fruit and Vegetable Preserving and Specialty Food Manufacturing"
        },
        {
          "code": "31141",
          "name": "Frozen Food Manufacturing"
        },
        {
          "code": "311411",
          "name": "Frozen Fruit, Juice, and Vegetable Manufacturing"
        },
        {
          "code": "311412",
          "name": "Frozen Specialty Food Manufacturing"
        },
        {
          "code": "31142",
          "name": "Fruit and Vegetable Canning, Pickling, and Drying"
        },
        {
          "code": "311421",
          "name": "Fruit and Vegetable Canning"
        },
        {
          "code": "311422",
          "name": "Specialty Canning"
        },
        {
          "code": "311423",
          "name": "Dried and Dehydrated Food Manufacturing"
        },
        {
          "code": "3115",
          "name": "Dairy Product Manufacturing"
        },
        {
          "code": "31151",
          "name": "Dairy Product (except Frozen) Manufacturing"
        },
        {
          "code": "311511",
          "name": "Fluid Milk Manufacturing"
        },
        {
          "code": "311512",
          "name": "Creamery Butter Manufacturing"
        },
        {
          "code": "311513",
          "name": "Cheese Manufacturing"
        },
        {
          "code": "311514",
          "name": "Dry, Condensed, and Evaporated Dairy Product Manufacturing"
        },
        {
          "code": "31152",
          "name": "Ice Cream and Frozen Dessert Manufacturing"
        },
        {
          "code": "311520",
          "name": "Ice Cream and Frozen Dessert Manufacturing"
        },
        {
          "code": "3116",
          "name": "Animal Slaughtering and Processing"
        },
        {
          "code": "31161",
          "name": "Animal Slaughtering and Processing"
        },
        {
          "code": "311611",
          "name": "Animal (except Poultry) Slaughtering"
        },
        {
          "code": "311612",
          "name": "Meat Processed from Carcasses"
        },
        {
          "code": "311613",
          "name": "Rendering and Meat Byproduct Processing"
        },
        {
          "code": "311615",
          "name": "Poultry Processing"
        },
        {
          "code": "3117",
          "name": "Seafood Product Preparation and Packaging"
        },
        {
          "code": "31171",
          "name": "Seafood Product Preparation and Packaging"
        },
        {
          "code": "311710",
          "name": "Seafood Product Preparation and Packaging"
        },
        {
          "code": "3118",
          "name": "Bakeries and Tortilla Manufacturing"
        },
        {
          "code": "31181",
          "name": "Bread and Bakery Product Manufacturing"
        },
        {
          "code": "311811",
          "name": "Retail Bakeries"
        },
        {
          "code": "311812",
          "name": "Commercial Bakeries"
        },
        {
          "code": "311813",
          "name": "Frozen Cakes, Pies, and Other Pastries Manufacturing"
        },
        {
          "code": "31182",
          "name": "Cookie, Cracker, and Pasta Manufacturing"
        },
        {
          "code": "311821",
          "name": "Cookie and Cracker Manufacturing"
        },
        {
          "code": "311824",
          "name": "Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour"
        },
        {
          "code": "31183",
          "name": "Tortilla Manufacturing"
        },
        {
          "code": "311830",
          "name": "Tortilla Manufacturing"
        },
        {
          "code": "3119",
          "name": "Other Food Manufacturing"
        },
        {
          "code": "31191",
          "name": "Snack Food Manufacturing"
        },
        {
          "code": "311911",
          "name": "Roasted Nuts and Peanut Butter Manufacturing"
        },
        {
          "code": "311919",
          "name": "Other Snack Food Manufacturing"
        },
        {
          "code": "31192",
          "name": "Coffee and Tea Manufacturing"
        },
        {
          "code": "311920",
          "name": "Coffee and Tea Manufacturing"
        },
        {
          "code": "31193",
          "name": "Flavoring Syrup and Concentrate Manufacturing"
        },
        {
          "code": "311930",
          "name": "Flavoring Syrup and Concentrate Manufacturing"
        },
        {
          "code": "31194",
          "name": "Seasoning and Dressing Manufacturing"
        },
        {
          "code": "311941",
          "name": "Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing"
        },
        {
          "code": "311942",
          "name": "Spice and Extract Manufacturing"
        },
        {
          "code": "31199",
          "name": "All Other Food Manufacturing"
        },
        {
          "code": "311991",
          "name": "Perishable Prepared Food Manufacturing"
        },
        {
          "code": "311999",
          "name": "All Other Miscellaneous Food Manufacturing"
        },
        {
          "code": "312",
          "name": "Beverage and Tobacco Product Manufacturing"
        },
        {
          "code": "3121",
          "name": "Beverage Manufacturing"
        },
        {
          "code": "31211",
          "name": "Soft Drink and Ice Manufacturing"
        },
        {
          "code": "312111",
          "name": "Soft Drink Manufacturing"
        },
        {
          "code": "312112",
          "name": "Bottled Water Manufacturing"
        },
        {
          "code": "312113",
          "name": "Ice Manufacturing"
        },
        {
          "code": "31212",
          "name": "Breweries"
        },
        {
          "code": "312120",
          "name": "Breweries"
        },
        {
          "code": "31213",
          "name": "Wineries"
        },
        {
          "code": "312130",
          "name": "Wineries"
        },
        {
          "code": "31214",
          "name": "Distilleries"
        },
        {
          "code": "312140",
          "name": "Distilleries"
        },
        {
          "code": "3122",
          "name": "Tobacco Manufacturing"
        },
        {
          "code": "31223",
          "name": "Tobacco Manufacturing"
        },
        {
          "code": "312230",
          "name": "Tobacco Manufacturing"
        },
        {
          "code": "313",
          "name": "Textile Mills"
        },
        {
          "code": "3131",
          "name": "Fiber, Yarn, and Thread Mills"
        },
        {
          "code": "31311",
          "name": "Fiber, Yarn, and Thread Mills"
        },
        {
          "code": "313110",
          "name": "Fiber, Yarn, and Thread Mills"
        },
        {
          "code": "3132",
          "name": "Fabric Mills"
        },
        {
          "code": "31321",
          "name": "Broadwoven Fabric Mills"
        },
        {
          "code": "313210",
          "name": "Broadwoven Fabric Mills"
        },
        {
          "code": "31322",
          "name": "Narrow Fabric Mills and Schiffli Machine Embroidery"
        },
        {
          "code": "313220",
          "name": "Narrow Fabric Mills and Schiffli Machine Embroidery"
        },
        {
          "code": "31323",
          "name": "Nonwoven Fabric Mills"
        },
        {
          "code": "313230",
          "name": "Nonwoven Fabric Mills"
        },
        {
          "code": "31324",
          "name": "Knit Fabric Mills"
        },
        {
          "code": "313240",
          "name": "Knit Fabric Mills"
        },
        {
          "code": "3133",
          "name": "Textile and Fabric Finishing and Fabric Coating Mills"
        },
        {
          "code": "31331",
          "name": "Textile and Fabric Finishing Mills"
        },
        {
          "code": "313310",
          "name": "Textile and Fabric Finishing Mills"
        },
        {
          "code": "31332",
          "name": "Fabric Coating Mills"
        },
        {
          "code": "313320",
          "name": "Fabric Coating Mills"
        },
        {
          "code": "314",
          "name": "Textile Product Mills"
        },
        {
          "code": "3141",
          "name": "Textile Furnishings Mills"
        },
        {
          "code": "31411",
          "name": "Carpet and Rug Mills"
        },
        {
          "code": "314110",
          "name": "Carpet and Rug Mills"
        },
        {
          "code": "31412",
          "name": "Curtain and Linen Mills"
        },
        {
          "code": "314120",
          "name": "Curtain and Linen Mills"
        },
        {
          "code": "3149",
          "name": "Other Textile Product Mills"
        },
        {
          "code": "31491",
          "name": "Textile Bag and Canvas Mills"
        },
        {
          "code": "314910",
          "name": "Textile Bag and Canvas Mills"
        },
        {
          "code": "31499",
          "name": "All Other Textile Product Mills"
        },
        {
          "code": "314994",
          "name": "Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills"
        },
        {
          "code": "314999",
          "name": "All Other Miscellaneous Textile Product Mills"
        },
        {
          "code": "315",
          "name": "Apparel Manufacturing"
        },
        {
          "code": "3151",
          "name": "Apparel Knitting Mills"
        },
        {
          "code": "31511",
          "name": "Hosiery and Sock Mills"
        },
        {
          "code": "315110",
          "name": "Hosiery and Sock Mills"
        },
        {
          "code": "31519",
          "name": "Other Apparel Knitting Mills"
        },
        {
          "code": "315190",
          "name": "Other Apparel Knitting Mills"
        },
        {
          "code": "3152",
          "name": "Cut and Sew Apparel Manufacturing"
        },
        {
          "code": "31521",
          "name": "Cut and Sew Apparel Contractors"
        },
        {
          "code": "315210",
          "name": "Cut and Sew Apparel Contractors"
        },
        {
          "code": "31522",
          "name": "Men’s and Boys’ Cut and Sew Apparel Manufacturing"
        },
        {
          "code": "315220",
          "name": "Men’s and Boys’ Cut and Sew Apparel Manufacturing"
        },
        {
          "code": "31524",
          "name": "Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing"
        },
        {
          "code": "315240",
          "name": "Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing"
        },
        {
          "code": "31528",
          "name": "Other Cut and Sew Apparel Manufacturing"
        },
        {
          "code": "315280",
          "name": "Other Cut and Sew Apparel Manufacturing"
        },
        {
          "code": "3159",
          "name": "Apparel Accessories and Other Apparel Manufacturing"
        },
        {
          "code": "31599",
          "name": "Apparel Accessories and Other Apparel Manufacturing"
        },
        {
          "code": "315990",
          "name": "Apparel Accessories and Other Apparel Manufacturing"
        },
        {
          "code": "316",
          "name": "Leather and Allied Product Manufacturing"
        },
        {
          "code": "3161",
          "name": "Leather and Hide Tanning and Finishing"
        },
        {
          "code": "31611",
          "name": "Leather and Hide Tanning and Finishing"
        },
        {
          "code": "316110",
          "name": "Leather and Hide Tanning and Finishing"
        },
        {
          "code": "3162",
          "name": "Footwear Manufacturing"
        },
        {
          "code": "31621",
          "name": "Footwear Manufacturing"
        },
        {
          "code": "316210",
          "name": "Footwear Manufacturing"
        },
        {
          "code": "3169",
          "name": "Other Leather and Allied Product Manufacturing"
        },
        {
          "code": "31699",
          "name": "Other Leather and Allied Product Manufacturing"
        },
        {
          "code": "316992",
          "name": "Women's Handbag and Purse Manufacturing"
        },
        {
          "code": "316998",
          "name": "All Other Leather Good and Allied Product Manufacturing"
        },
        {
          "code": "321",
          "name": "Wood Product Manufacturing"
        },
        {
          "code": "3211",
          "name": "Sawmills and Wood Preservation"
        },
        {
          "code": "32111",
          "name": "Sawmills and Wood Preservation"
        },
        {
          "code": "321113",
          "name": "Sawmills"
        },
        {
          "code": "321114",
          "name": "Wood Preservation"
        },
        {
          "code": "3212",
          "name": "Veneer, Plywood, and Engineered Wood Product Manufacturing"
        },
        {
          "code": "32121",
          "name": "Veneer, Plywood, and Engineered Wood Product Manufacturing"
        },
        {
          "code": "321211",
          "name": "Hardwood Veneer and Plywood Manufacturing"
        },
        {
          "code": "321212",
          "name": "Softwood Veneer and Plywood Manufacturing"
        },
        {
          "code": "321213",
          "name": "Engineered Wood Member (except Truss) Manufacturing"
        },
        {
          "code": "321214",
          "name": "Truss Manufacturing"
        },
        {
          "code": "321219",
          "name": "Reconstituted Wood Product Manufacturing"
        },
        {
          "code": "3219",
          "name": "Other Wood Product Manufacturing"
        },
        {
          "code": "32191",
          "name": "Millwork"
        },
        {
          "code": "321911",
          "name": "Wood Window and Door Manufacturing"
        },
        {
          "code": "321912",
          "name": "Cut Stock, Resawing Lumber, and Planing"
        },
        {
          "code": "321918",
          "name": "Other Millwork (including Flooring)"
        },
        {
          "code": "32192",
          "name": "Wood Container and Pallet Manufacturing"
        },
        {
          "code": "321920",
          "name": "Wood Container and Pallet Manufacturing"
        },
        {
          "code": "32199",
          "name": "All Other Wood Product Manufacturing"
        },
        {
          "code": "321991",
          "name": "Manufactured Home (Mobile Home) Manufacturing"
        },
        {
          "code": "321992",
          "name": "Prefabricated Wood Building Manufacturing"
        },
        {
          "code": "321999",
          "name": "All Other Miscellaneous Wood Product Manufacturing"
        },
        {
          "code": "322",
          "name": "Paper Manufacturing"
        },
        {
          "code": "3221",
          "name": "Pulp, Paper, and Paperboard Mills"
        },
        {
          "code": "32211",
          "name": "Pulp Mills"
        },
        {
          "code": "322110",
          "name": "Pulp Mills"
        },
        {
          "code": "32212",
          "name": "Paper Mills"
        },
        {
          "code": "322121",
          "name": "Paper (except Newsprint) Mills"
        },
        {
          "code": "322122",
          "name": "Newsprint Mills"
        },
        {
          "code": "32213",
          "name": "Paperboard Mills"
        },
        {
          "code": "322130",
          "name": "Paperboard Mills"
        },
        {
          "code": "3222",
          "name": "Converted Paper Product Manufacturing"
        },
        {
          "code": "32221",
          "name": "Paperboard Container Manufacturing"
        },
        {
          "code": "322211",
          "name": "Corrugated and Solid Fiber Box Manufacturing"
        },
        {
          "code": "322212",
          "name": "Folding Paperboard Box Manufacturing"
        },
        {
          "code": "322219",
          "name": "Other Paperboard Container Manufacturing"
        },
        {
          "code": "32222",
          "name": "Paper Bag and Coated and Treated Paper Manufacturing"
        },
        {
          "code": "322220",
          "name": "Paper Bag and Coated and Treated Paper Manufacturing"
        },
        {
          "code": "32223",
          "name": "Stationery Product Manufacturing"
        },
        {
          "code": "322230",
          "name": "Stationery Product Manufacturing"
        },
        {
          "code": "32229",
          "name": "Other Converted Paper Product Manufacturing"
        },
        {
          "code": "322291",
          "name": "Sanitary Paper Product Manufacturing"
        },
        {
          "code": "322299",
          "name": "All Other Converted Paper Product Manufacturing"
        },
        {
          "code": "323",
          "name": "Printing and Related Support Activities"
        },
        {
          "code": "3231",
          "name": "Printing and Related Support Activities"
        },
        {
          "code": "32311",
          "name": "Printing"
        },
        {
          "code": "323111",
          "name": "Commercial Printing (except Screen and Books)"
        },
        {
          "code": "323113",
          "name": "Commercial Screen Printing"
        },
        {
          "code": "323117",
          "name": "Books Printing"
        },
        {
          "code": "32312",
          "name": "Support Activities for Printing"
        },
        {
          "code": "323120",
          "name": "Support Activities for Printing"
        },
        {
          "code": "324",
          "name": "Petroleum and Coal Products Manufacturing"
        },
        {
          "code": "3241",
          "name": "Petroleum and Coal Products Manufacturing"
        },
        {
          "code": "32411",
          "name": "Petroleum Refineries"
        },
        {
          "code": "324110",
          "name": "Petroleum Refineries"
        },
        {
          "code": "32412",
          "name": "Asphalt Paving, Roofing, and Saturated Materials Manufacturing"
        },
        {
          "code": "324121",
          "name": "Asphalt Paving Mixture and Block Manufacturing"
        },
        {
          "code": "324122",
          "name": "Asphalt Shingle and Coating Materials Manufacturing"
        },
        {
          "code": "32419",
          "name": "Other Petroleum and Coal Products Manufacturing"
        },
        {
          "code": "324191",
          "name": "Petroleum Lubricating Oil and Grease Manufacturing"
        },
        {
          "code": "324199",
          "name": "All Other Petroleum and Coal Products Manufacturing"
        },
        {
          "code": "325",
          "name": "Chemical Manufacturing"
        },
        {
          "code": "3251",
          "name": "Basic Chemical Manufacturing"
        },
        {
          "code": "32511",
          "name": "Petrochemical Manufacturing"
        },
        {
          "code": "325110",
          "name": "Petrochemical Manufacturing"
        },
        {
          "code": "32512",
          "name": "Industrial Gas Manufacturing"
        },
        {
          "code": "325120",
          "name": "Industrial Gas Manufacturing"
        },
        {
          "code": "32513",
          "name": "Synthetic Dye and Pigment Manufacturing"
        },
        {
          "code": "325130",
          "name": "Synthetic Dye and Pigment Manufacturing"
        },
        {
          "code": "32518",
          "name": "Other Basic Inorganic Chemical Manufacturing"
        },
        {
          "code": "325180",
          "name": "Other Basic Inorganic Chemical Manufacturing"
        },
        {
          "code": "32519",
          "name": "Other Basic Organic Chemical Manufacturing"
        },
        {
          "code": "325193",
          "name": "Ethyl Alcohol Manufacturing"
        },
        {
          "code": "325194",
          "name": "Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing"
        },
        {
          "code": "325199",
          "name": "All Other Basic Organic Chemical Manufacturing"
        },
        {
          "code": "3252",
          "name": "Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing"
        },
        {
          "code": "32521",
          "name": "Resin and Synthetic Rubber Manufacturing"
        },
        {
          "code": "325211",
          "name": "Plastics Material and Resin Manufacturing"
        },
        {
          "code": "325212",
          "name": "Synthetic Rubber Manufacturing"
        },
        {
          "code": "32522",
          "name": "Artificial and Synthetic Fibers and Filaments Manufacturing"
        },
        {
          "code": "325220",
          "name": "Artificial and Synthetic Fibers and Filaments Manufacturing"
        },
        {
          "code": "3253",
          "name": "Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing"
        },
        {
          "code": "32531",
          "name": "Fertilizer Manufacturing"
        },
        {
          "code": "325311",
          "name": "Nitrogenous Fertilizer Manufacturing"
        },
        {
          "code": "325312",
          "name": "Phosphatic Fertilizer Manufacturing"
        },
        {
          "code": "325314",
          "name": "Fertilizer (Mixing Only) Manufacturing"
        },
        {
          "code": "32532",
          "name": "Pesticide and Other Agricultural Chemical Manufacturing"
        },
        {
          "code": "325320",
          "name": "Pesticide and Other Agricultural Chemical Manufacturing"
        },
        {
          "code": "3254",
          "name": "Pharmaceutical and Medicine Manufacturing"
        },
        {
          "code": "32541",
          "name": "Pharmaceutical and Medicine Manufacturing"
        },
        {
          "code": "325411",
          "name": "Medicinal and Botanical Manufacturing"
        },
        {
          "code": "325412",
          "name": "Pharmaceutical Preparation Manufacturing"
        },
        {
          "code": "325413",
          "name": "In-Vitro Diagnostic Substance Manufacturing"
        },
        {
          "code": "325414",
          "name": "Biological Product (except Diagnostic) Manufacturing"
        },
        {
          "code": "3255",
          "name": "Paint, Coating, and Adhesive Manufacturing"
        },
        {
          "code": "32551",
          "name": "Paint and Coating Manufacturing"
        },
        {
          "code": "325510",
          "name": "Paint and Coating Manufacturing"
        },
        {
          "code": "32552",
          "name": "Adhesive Manufacturing"
        },
        {
          "code": "325520",
          "name": "Adhesive Manufacturing"
        },
        {
          "code": "3256",
          "name": "Soap, Cleaning Compound, and Toilet Preparation Manufacturing"
        },
        {
          "code": "32561",
          "name": "Soap and Cleaning Compound Manufacturing"
        },
        {
          "code": "325611",
          "name": "Soap and Other Detergent Manufacturing"
        },
        {
          "code": "325612",
          "name": "Polish and Other Sanitation Good Manufacturing"
        },
        {
          "code": "325613",
          "name": "Surface Active Agent Manufacturing"
        },
        {
          "code": "32562",
          "name": "Toilet Preparation Manufacturing"
        },
        {
          "code": "325620",
          "name": "Toilet Preparation Manufacturing"
        },
        {
          "code": "3259",
          "name": "Other Chemical Product and Preparation Manufacturing"
        },
        {
          "code": "32591",
          "name": "Printing Ink Manufacturing"
        },
        {
          "code": "325910",
          "name": "Printing Ink Manufacturing"
        },
        {
          "code": "32592",
          "name": "Explosives Manufacturing"
        },
        {
          "code": "325920",
          "name": "Explosives Manufacturing"
        },
        {
          "code": "32599",
          "name": "All Other Chemical Product and Preparation Manufacturing"
        },
        {
          "code": "325991",
          "name": "Custom Compounding of Purchased Resins"
        },
        {
          "code": "325992",
          "name": "Photographic Film, Paper, Plate, and Chemical Manufacturing"
        },
        {
          "code": "325998",
          "name": "All Other Miscellaneous Chemical Product and Preparation Manufacturing"
        },
        {
          "code": "326",
          "name": "Plastics and Rubber Products Manufacturing"
        },
        {
          "code": "3261",
          "name": "Plastics Product Manufacturing"
        },
        {
          "code": "32611",
          "name": "Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing"
        },
        {
          "code": "326111",
          "name": "Plastics Bag and Pouch Manufacturing"
        },
        {
          "code": "326112",
          "name": "Plastics Packaging Film and Sheet (including Laminated) Manufacturing"
        },
        {
          "code": "326113",
          "name": "Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing"
        },
        {
          "code": "32612",
          "name": "Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing"
        },
        {
          "code": "326121",
          "name": "Unlaminated Plastics Profile Shape Manufacturing"
        },
        {
          "code": "326122",
          "name": "Plastics Pipe and Pipe Fitting Manufacturing"
        },
        {
          "code": "32613",
          "name": "Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing"
        },
        {
          "code": "326130",
          "name": "Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing"
        },
        {
          "code": "32614",
          "name": "Polystyrene Foam Product Manufacturing"
        },
        {
          "code": "326140",
          "name": "Polystyrene Foam Product Manufacturing"
        },
        {
          "code": "32615",
          "name": "Urethane and Other Foam Product (except Polystyrene) Manufacturing"
        },
        {
          "code": "326150",
          "name": "Urethane and Other Foam Product (except Polystyrene) Manufacturing"
        },
        {
          "code": "32616",
          "name": "Plastics Bottle Manufacturing"
        },
        {
          "code": "326160",
          "name": "Plastics Bottle Manufacturing"
        },
        {
          "code": "32619",
          "name": "Other Plastics Product Manufacturing"
        },
        {
          "code": "326191",
          "name": "Plastics Plumbing Fixture Manufacturing"
        },
        {
          "code": "326199",
          "name": "All Other Plastics Product Manufacturing"
        },
        {
          "code": "3262",
          "name": "Rubber Product Manufacturing"
        },
        {
          "code": "32621",
          "name": "Tire Manufacturing"
        },
        {
          "code": "326211",
          "name": "Tire Manufacturing (except Retreading)"
        },
        {
          "code": "326212",
          "name": "Tire Retreading"
        },
        {
          "code": "32622",
          "name": "Rubber and Plastics Hoses and Belting Manufacturing"
        },
        {
          "code": "326220",
          "name": "Rubber and Plastics Hoses and Belting Manufacturing"
        },
        {
          "code": "32629",
          "name": "Other Rubber Product Manufacturing"
        },
        {
          "code": "326291",
          "name": "Rubber Product Manufacturing for Mechanical Use"
        },
        {
          "code": "326299",
          "name": "All Other Rubber Product Manufacturing"
        },
        {
          "code": "327",
          "name": "Nonmetallic Mineral Product Manufacturing"
        },
        {
          "code": "3271",
          "name": "Clay Product and Refractory Manufacturing"
        },
        {
          "code": "32711",
          "name": "Pottery, Ceramics, and Plumbing Fixture Manufacturing"
        },
        {
          "code": "327110",
          "name": "Pottery, Ceramics, and Plumbing Fixture Manufacturing"
        },
        {
          "code": "32712",
          "name": "Clay Building Material and Refractories Manufacturing"
        },
        {
          "code": "327120",
          "name": "Clay Building Material and Refractories Manufacturing"
        },
        {
          "code": "3272",
          "name": "Glass and Glass Product Manufacturing"
        },
        {
          "code": "32721",
          "name": "Glass and Glass Product Manufacturing"
        },
        {
          "code": "327211",
          "name": "Flat Glass Manufacturing"
        },
        {
          "code": "327212",
          "name": "Other Pressed and Blown Glass and Glassware Manufacturing"
        },
        {
          "code": "327213",
          "name": "Glass Container Manufacturing"
        },
        {
          "code": "327215",
          "name": "Glass Product Manufacturing Made of Purchased Glass"
        },
        {
          "code": "3273",
          "name": "Cement and Concrete Product Manufacturing"
        },
        {
          "code": "32731",
          "name": "Cement Manufacturing"
        },
        {
          "code": "327310",
          "name": "Cement Manufacturing"
        },
        {
          "code": "32732",
          "name": "Ready-Mix Concrete Manufacturing"
        },
        {
          "code": "327320",
          "name": "Ready-Mix Concrete Manufacturing"
        },
        {
          "code": "32733",
          "name": "Concrete Pipe, Brick, and Block Manufacturing"
        },
        {
          "code": "327331",
          "name": "Concrete Block and Brick Manufacturing"
        },
        {
          "code": "327332",
          "name": "Concrete Pipe Manufacturing"
        },
        {
          "code": "32739",
          "name": "Other Concrete Product Manufacturing"
        },
        {
          "code": "327390",
          "name": "Other Concrete Product Manufacturing"
        },
        {
          "code": "3274",
          "name": "Lime and Gypsum Product Manufacturing"
        },
        {
          "code": "32741",
          "name": "Lime Manufacturing"
        },
        {
          "code": "327410",
          "name": "Lime Manufacturing"
        },
        {
          "code": "32742",
          "name": "Gypsum Product Manufacturing"
        },
        {
          "code": "327420",
          "name": "Gypsum Product Manufacturing"
        },
        {
          "code": "3279",
          "name": "Other Nonmetallic Mineral Product Manufacturing"
        },
        {
          "code": "32791",
          "name": "Abrasive Product Manufacturing"
        },
        {
          "code": "327910",
          "name": "Abrasive Product Manufacturing"
        },
        {
          "code": "32799",
          "name": "All Other Nonmetallic Mineral Product Manufacturing"
        },
        {
          "code": "327991",
          "name": "Cut Stone and Stone Product Manufacturing"
        },
        {
          "code": "327992",
          "name": "Ground or Treated Mineral and Earth Manufacturing"
        },
        {
          "code": "327993",
          "name": "Mineral Wool Manufacturing"
        },
        {
          "code": "327999",
          "name": "All Other Miscellaneous Nonmetallic Mineral Product Manufacturing"
        },
        {
          "code": "331",
          "name": "Primary Metal Manufacturing"
        },
        {
          "code": "3311",
          "name": "Iron and Steel Mills and Ferroalloy Manufacturing"
        },
        {
          "code": "33111",
          "name": "Iron and Steel Mills and Ferroalloy Manufacturing"
        },
        {
          "code": "331110",
          "name": "Iron and Steel Mills and Ferroalloy Manufacturing"
        },
        {
          "code": "3312",
          "name": "Steel Product Manufacturing from Purchased Steel"
        },
        {
          "code": "33121",
          "name": "Iron and Steel Pipe and Tube Manufacturing from Purchased Steel"
        },
        {
          "code": "331210",
          "name": "Iron and Steel Pipe and Tube Manufacturing from Purchased Steel"
        },
        {
          "code": "33122",
          "name": "Rolling and Drawing of Purchased Steel"
        },
        {
          "code": "331221",
          "name": "Rolled Steel Shape Manufacturing"
        },
        {
          "code": "331222",
          "name": "Steel Wire Drawing"
        },
        {
          "code": "3313",
          "name": "Alumina and Aluminum Production and Processing"
        },
        {
          "code": "33131",
          "name": "Alumina and Aluminum Production and Processing"
        },
        {
          "code": "331313",
          "name": "Alumina Refining and Primary Aluminum Production"
        },
        {
          "code": "331314",
          "name": "Secondary Smelting and Alloying of Aluminum"
        },
        {
          "code": "331315",
          "name": "Aluminum Sheet, Plate, and Foil Manufacturing"
        },
        {
          "code": "331318",
          "name": "Other Aluminum Rolling, Drawing, and Extruding"
        },
        {
          "code": "3314",
          "name": "Nonferrous Metal (except Aluminum) Production and Processing"
        },
        {
          "code": "33141",
          "name": "Nonferrous Metal (except Aluminum) Smelting and Refining"
        },
        {
          "code": "331410",
          "name": "Nonferrous Metal (except Aluminum) Smelting and Refining"
        },
        {
          "code": "33142",
          "name": "Copper Rolling, Drawing, Extruding, and Alloying"
        },
        {
          "code": "331420",
          "name": "Copper Rolling, Drawing, Extruding, and Alloying"
        },
        {
          "code": "33149",
          "name": "Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying"
        },
        {
          "code": "331491",
          "name": "Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding"
        },
        {
          "code": "331492",
          "name": "Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)"
        },
        {
          "code": "3315",
          "name": "Foundries"
        },
        {
          "code": "33151",
          "name": "Ferrous Metal Foundries"
        },
        {
          "code": "331511",
          "name": "Iron Foundries"
        },
        {
          "code": "331512",
          "name": "Steel Investment Foundries"
        },
        {
          "code": "331513",
          "name": "Steel Foundries (except Investment)"
        },
        {
          "code": "33152",
          "name": "Nonferrous Metal Foundries"
        },
        {
          "code": "331523",
          "name": "Nonferrous Metal Die-Casting Foundries"
        },
        {
          "code": "331524",
          "name": "Aluminum Foundries (except Die-Casting)"
        },
        {
          "code": "331529",
          "name": "Other Nonferrous Metal Foundries (except Die-Casting)"
        },
        {
          "code": "332",
          "name": "Fabricated Metal Product Manufacturing"
        },
        {
          "code": "3321",
          "name": "Forging and Stamping"
        },
        {
          "code": "33211",
          "name": "Forging and Stamping"
        },
        {
          "code": "332111",
          "name": "Iron and Steel Forging"
        },
        {
          "code": "332112",
          "name": "Nonferrous Forging"
        },
        {
          "code": "332114",
          "name": "Custom Roll Forming"
        },
        {
          "code": "332117",
          "name": "Powder Metallurgy Part Manufacturing"
        },
        {
          "code": "332119",
          "name": "Metal Crown, Closure, and Other Metal Stamping (except Automotive)"
        },
        {
          "code": "3322",
          "name": "Cutlery and Handtool Manufacturing"
        },
        {
          "code": "33221",
          "name": "Cutlery and Handtool Manufacturing"
        },
        {
          "code": "332215",
          "name": "Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing"
        },
        {
          "code": "332216",
          "name": "Saw Blade and Handtool Manufacturing"
        },
        {
          "code": "3323",
          "name": "Architectural and Structural Metals Manufacturing"
        },
        {
          "code": "33231",
          "name": "Plate Work and Fabricated Structural Product Manufacturing"
        },
        {
          "code": "332311",
          "name": "Prefabricated Metal Building and Component Manufacturing"
        },
        {
          "code": "332312",
          "name": "Fabricated Structural Metal Manufacturing"
        },
        {
          "code": "332313",
          "name": "Plate Work Manufacturing"
        },
        {
          "code": "33232",
          "name": "Ornamental and Architectural Metal Products Manufacturing"
        },
        {
          "code": "332321",
          "name": "Metal Window and Door Manufacturing"
        },
        {
          "code": "332322",
          "name": "Sheet Metal Work Manufacturing"
        },
        {
          "code": "332323",
          "name": "Ornamental and Architectural Metal Work Manufacturing"
        },
        {
          "code": "3324",
          "name": "Boiler, Tank, and Shipping Container Manufacturing"
        },
        {
          "code": "33241",
          "name": "Power Boiler and Heat Exchanger Manufacturing"
        },
        {
          "code": "332410",
          "name": "Power Boiler and Heat Exchanger Manufacturing"
        },
        {
          "code": "33242",
          "name": "Metal Tank (Heavy Gauge) Manufacturing"
        },
        {
          "code": "332420",
          "name": "Metal Tank (Heavy Gauge) Manufacturing"
        },
        {
          "code": "33243",
          "name": "Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing"
        },
        {
          "code": "332431",
          "name": "Metal Can Manufacturing"
        },
        {
          "code": "332439",
          "name": "Other Metal Container Manufacturing"
        },
        {
          "code": "3325",
          "name": "Hardware Manufacturing"
        },
        {
          "code": "33251",
          "name": "Hardware Manufacturing"
        },
        {
          "code": "332510",
          "name": "Hardware Manufacturing"
        },
        {
          "code": "3326",
          "name": "Spring and Wire Product Manufacturing"
        },
        {
          "code": "33261",
          "name": "Spring and Wire Product Manufacturing"
        },
        {
          "code": "332613",
          "name": "Spring Manufacturing"
        },
        {
          "code": "332618",
          "name": "Other Fabricated Wire Product Manufacturing"
        },
        {
          "code": "3327",
          "name": "Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing"
        },
        {
          "code": "33271",
          "name": "Machine Shops"
        },
        {
          "code": "332710",
          "name": "Machine Shops"
        },
        {
          "code": "33272",
          "name": "Turned Product and Screw, Nut, and Bolt Manufacturing"
        },
        {
          "code": "332721",
          "name": "Precision Turned Product Manufacturing"
        },
        {
          "code": "332722",
          "name": "Bolt, Nut, Screw, Rivet, and Washer Manufacturing"
        },
        {
          "code": "3328",
          "name": "Coating, Engraving, Heat Treating, and Allied Activities"
        },
        {
          "code": "33281",
          "name": "Coating, Engraving, Heat Treating, and Allied Activities"
        },
        {
          "code": "332811",
          "name": "Metal Heat Treating"
        },
        {
          "code": "332812",
          "name": "Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers"
        },
        {
          "code": "332813",
          "name": "Electroplating, Plating, Polishing, Anodizing, and Coloring"
        },
        {
          "code": "3329",
          "name": "Other Fabricated Metal Product Manufacturing"
        },
        {
          "code": "33291",
          "name": "Metal Valve Manufacturing"
        },
        {
          "code": "332911",
          "name": "Industrial Valve Manufacturing"
        },
        {
          "code": "332912",
          "name": "Fluid Power Valve and Hose Fitting Manufacturing"
        },
        {
          "code": "332913",
          "name": "Plumbing Fixture Fitting and Trim Manufacturing"
        },
        {
          "code": "332919",
          "name": "Other Metal Valve and Pipe Fitting Manufacturing"
        },
        {
          "code": "33299",
          "name": "All Other Fabricated Metal Product Manufacturing"
        },
        {
          "code": "332991",
          "name": "Ball and Roller Bearing Manufacturing"
        },
        {
          "code": "332992",
          "name": "Small Arms Ammunition Manufacturing"
        },
        {
          "code": "332993",
          "name": "Ammunition (except Small Arms) Manufacturing"
        },
        {
          "code": "332994",
          "name": "Small Arms, Ordnance, and Ordnance Accessories Manufacturing"
        },
        {
          "code": "332996",
          "name": "Fabricated Pipe and Pipe Fitting Manufacturing"
        },
        {
          "code": "332999",
          "name": "All Other Miscellaneous Fabricated Metal Product Manufacturing"
        },
        {
          "code": "333",
          "name": "Machinery Manufacturing"
        },
        {
          "code": "3331",
          "name": "Agriculture, Construction, and Mining Machinery Manufacturing"
        },
        {
          "code": "33311",
          "name": "Agricultural Implement Manufacturing"
        },
        {
          "code": "333111",
          "name": "Farm Machinery and Equipment Manufacturing"
        },
        {
          "code": "333112",
          "name": "Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing"
        },
        {
          "code": "33312",
          "name": "Construction Machinery Manufacturing"
        },
        {
          "code": "333120",
          "name": "Construction Machinery Manufacturing"
        },
        {
          "code": "33313",
          "name": "Mining and Oil and Gas Field Machinery Manufacturing"
        },
        {
          "code": "333131",
          "name": "Mining Machinery and Equipment Manufacturing"
        },
        {
          "code": "333132",
          "name": "Oil and Gas Field Machinery and Equipment Manufacturing"
        },
        {
          "code": "3332",
          "name": "Industrial Machinery Manufacturing"
        },
        {
          "code": "33324",
          "name": "Industrial Machinery Manufacturing"
        },
        {
          "code": "333241",
          "name": "Food Product Machinery Manufacturing"
        },
        {
          "code": "333242",
          "name": "Semiconductor Machinery Manufacturing"
        },
        {
          "code": "333243",
          "name": "Sawmill, Woodworking, and Paper Machinery Manufacturing"
        },
        {
          "code": "333244",
          "name": "Printing Machinery and Equipment Manufacturing"
        },
        {
          "code": "333249",
          "name": "Other Industrial Machinery Manufacturing"
        },
        {
          "code": "3333",
          "name": "Commercial and Service Industry Machinery Manufacturing"
        },
        {
          "code": "33331",
          "name": "Commercial and Service Industry Machinery Manufacturing"
        },
        {
          "code": "333314",
          "name": "Optical Instrument and Lens Manufacturing"
        },
        {
          "code": "333316",
          "name": "Photographic and Photocopying Equipment Manufacturing"
        },
        {
          "code": "333318",
          "name": "Other Commercial and Service Industry Machinery Manufacturing"
        },
        {
          "code": "3334",
          "name": "Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing"
        },
        {
          "code": "33341",
          "name": "Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing"
        },
        {
          "code": "333413",
          "name": "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing"
        },
        {
          "code": "333414",
          "name": "Heating Equipment (except Warm Air Furnaces) Manufacturing"
        },
        {
          "code": "333415",
          "name": "Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing"
        },
        {
          "code": "3335",
          "name": "Metalworking Machinery Manufacturing"
        },
        {
          "code": "33351",
          "name": "Metalworking Machinery Manufacturing"
        },
        {
          "code": "333511",
          "name": "Industrial Mold Manufacturing"
        },
        {
          "code": "333514",
          "name": "Special Die and Tool, Die Set, Jig, and Fixture Manufacturing"
        },
        {
          "code": "333515",
          "name": "Cutting Tool and Machine Tool Accessory Manufacturing"
        },
        {
          "code": "333517",
          "name": "Machine Tool Manufacturing"
        },
        {
          "code": "333519",
          "name": "Rolling Mill and Other Metalworking Machinery Manufacturing"
        },
        {
          "code": "3336",
          "name": "Engine, Turbine, and Power Transmission Equipment Manufacturing"
        },
        {
          "code": "33361",
          "name": "Engine, Turbine, and Power Transmission Equipment Manufacturing"
        },
        {
          "code": "333611",
          "name": "Turbine and Turbine Generator Set Units Manufacturing"
        },
        {
          "code": "333612",
          "name": "Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing"
        },
        {
          "code": "333613",
          "name": "Mechanical Power Transmission Equipment Manufacturing"
        },
        {
          "code": "333618",
          "name": "Other Engine Equipment Manufacturing"
        },
        {
          "code": "3339",
          "name": "Other General Purpose Machinery Manufacturing"
        },
        {
          "code": "33391",
          "name": "Pump and Compressor Manufacturing"
        },
        {
          "code": "333912",
          "name": "Air and Gas Compressor Manufacturing"
        },
        {
          "code": "333914",
          "name": "Measuring, Dispensing, and Other Pumping Equipment Manufacturing"
        },
        {
          "code": "33392",
          "name": "Material Handling Equipment Manufacturing"
        },
        {
          "code": "333921",
          "name": "Elevator and Moving Stairway Manufacturing"
        },
        {
          "code": "333922",
          "name": "Conveyor and Conveying Equipment Manufacturing"
        },
        {
          "code": "333923",
          "name": "Overhead Traveling Crane, Hoist, and Monorail System Manufacturing"
        },
        {
          "code": "333924",
          "name": "Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing"
        },
        {
          "code": "33399",
          "name": "All Other General Purpose Machinery Manufacturing"
        },
        {
          "code": "333991",
          "name": "Power-Driven Handtool Manufacturing"
        },
        {
          "code": "333992",
          "name": "Welding and Soldering Equipment Manufacturing"
        },
        {
          "code": "333993",
          "name": "Packaging Machinery Manufacturing"
        },
        {
          "code": "333994",
          "name": "Industrial Process Furnace and Oven Manufacturing"
        },
        {
          "code": "333995",
          "name": "Fluid Power Cylinder and Actuator Manufacturing"
        },
        {
          "code": "333996",
          "name": "Fluid Power Pump and Motor Manufacturing"
        },
        {
          "code": "333997",
          "name": "Scale and Balance Manufacturing"
        },
        {
          "code": "333999",
          "name": "All Other Miscellaneous General Purpose Machinery Manufacturing"
        },
        {
          "code": "334",
          "name": "Computer and Electronic Product Manufacturing"
        },
        {
          "code": "3341",
          "name": "Computer and Peripheral Equipment Manufacturing"
        },
        {
          "code": "33411",
          "name": "Computer and Peripheral Equipment Manufacturing"
        },
        {
          "code": "334111",
          "name": "Electronic Computer Manufacturing"
        },
        {
          "code": "334112",
          "name": "Computer Storage Device Manufacturing"
        },
        {
          "code": "334118",
          "name": "Computer Terminal and Other Computer Peripheral Equipment Manufacturing"
        },
        {
          "code": "3342",
          "name": "Communications Equipment Manufacturing"
        },
        {
          "code": "33421",
          "name": "Telephone Apparatus Manufacturing"
        },
        {
          "code": "334210",
          "name": "Telephone Apparatus Manufacturing"
        },
        {
          "code": "33422",
          "name": "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"
        },
        {
          "code": "334220",
          "name": "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"
        },
        {
          "code": "33429",
          "name": "Other Communications Equipment Manufacturing"
        },
        {
          "code": "334290",
          "name": "Other Communications Equipment Manufacturing"
        },
        {
          "code": "3343",
          "name": "Audio and Video Equipment Manufacturing"
        },
        {
          "code": "33431",
          "name": "Audio and Video Equipment Manufacturing"
        },
        {
          "code": "334310",
          "name": "Audio and Video Equipment Manufacturing"
        },
        {
          "code": "3344",
          "name": "Semiconductor and Other Electronic Component Manufacturing"
        },
        {
          "code": "33441",
          "name": "Semiconductor and Other Electronic Component Manufacturing"
        },
        {
          "code": "334412",
          "name": "Bare Printed Circuit Board Manufacturing"
        },
        {
          "code": "334413",
          "name": "Semiconductor and Related Device Manufacturing"
        },
        {
          "code": "334416",
          "name": "Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing"
        },
        {
          "code": "334417",
          "name": "Electronic Connector Manufacturing"
        },
        {
          "code": "334418",
          "name": "Printed Circuit Assembly (Electronic Assembly) Manufacturing"
        },
        {
          "code": "334419",
          "name": "Other Electronic Component Manufacturing"
        },
        {
          "code": "3345",
          "name": "Navigational, Measuring, Electromedical, and Control Instruments Manufacturing"
        },
        {
          "code": "33451",
          "name": "Navigational, Measuring, Electromedical, and Control Instruments Manufacturing"
        },
        {
          "code": "334510",
          "name": "Electromedical and Electrotherapeutic Apparatus Manufacturing"
        },
        {
          "code": "334511",
          "name": "Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing"
        },
        {
          "code": "334512",
          "name": "Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use"
        },
        {
          "code": "334513",
          "name": "Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables"
        },
        {
          "code": "334514",
          "name": "Totalizing Fluid Meter and Counting Device Manufacturing"
        },
        {
          "code": "334515",
          "name": "Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals"
        },
        {
          "code": "334516",
          "name": "Analytical Laboratory Instrument Manufacturing"
        },
        {
          "code": "334517",
          "name": "Irradiation Apparatus Manufacturing"
        },
        {
          "code": "334519",
          "name": "Other Measuring and Controlling Device Manufacturing"
        },
        {
          "code": "3346",
          "name": "Manufacturing and Reproducing Magnetic and Optical Media"
        },
        {
          "code": "33461",
          "name": "Manufacturing and Reproducing Magnetic and Optical Media"
        },
        {
          "code": "334613",
          "name": "Blank Magnetic and Optical Recording Media Manufacturing"
        },
        {
          "code": "334614",
          "name": "Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing"
        },
        {
          "code": "335",
          "name": "Electrical Equipment, Appliance, and Component Manufacturing"
        },
        {
          "code": "3351",
          "name": "Electric Lighting Equipment Manufacturing"
        },
        {
          "code": "33511",
          "name": "Electric Lamp Bulb and Part Manufacturing"
        },
        {
          "code": "335110",
          "name": "Electric Lamp Bulb and Part Manufacturing"
        },
        {
          "code": "33512",
          "name": "Lighting Fixture Manufacturing"
        },
        {
          "code": "335121",
          "name": "Residential Electric Lighting Fixture Manufacturing"
        },
        {
          "code": "335122",
          "name": "Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing"
        },
        {
          "code": "335129",
          "name": "Other Lighting Equipment Manufacturing"
        },
        {
          "code": "3352",
          "name": "Household Appliance Manufacturing"
        },
        {
          "code": "33521",
          "name": "Small Electrical Appliance Manufacturing"
        },
        {
          "code": "335210",
          "name": "Small Electrical Appliance Manufacturing"
        },
        {
          "code": "33522",
          "name": "Major Household Appliance Manufacturing"
        },
        {
          "code": "335220",
          "name": "Major Household Appliance Manufacturing"
        },
        {
          "code": "3353",
          "name": "Electrical Equipment Manufacturing"
        },
        {
          "code": "33531",
          "name": "Electrical Equipment Manufacturing"
        },
        {
          "code": "335311",
          "name": "Power, Distribution, and Specialty Transformer Manufacturing"
        },
        {
          "code": "335312",
          "name": "Motor and Generator Manufacturing"
        },
        {
          "code": "335313",
          "name": "Switchgear and Switchboard Apparatus Manufacturing"
        },
        {
          "code": "335314",
          "name": "Relay and Industrial Control Manufacturing"
        },
        {
          "code": "3359",
          "name": "Other Electrical Equipment and Component Manufacturing"
        },
        {
          "code": "33591",
          "name": "Battery Manufacturing"
        },
        {
          "code": "335911",
          "name": "Storage Battery Manufacturing"
        },
        {
          "code": "335912",
          "name": "Primary Battery Manufacturing"
        },
        {
          "code": "33592",
          "name": "Communication and Energy Wire and Cable Manufacturing"
        },
        {
          "code": "335921",
          "name": "Fiber Optic Cable Manufacturing"
        },
        {
          "code": "335929",
          "name": "Other Communication and Energy Wire Manufacturing"
        },
        {
          "code": "33593",
          "name": "Wiring Device Manufacturing"
        },
        {
          "code": "335931",
          "name": "Current-Carrying Wiring Device Manufacturing"
        },
        {
          "code": "335932",
          "name": "Noncurrent-Carrying Wiring Device Manufacturing"
        },
        {
          "code": "33599",
          "name": "All Other Electrical Equipment and Component Manufacturing"
        },
        {
          "code": "335991",
          "name": "Carbon and Graphite Product Manufacturing"
        },
        {
          "code": "335999",
          "name": "All Other Miscellaneous Electrical Equipment and Component Manufacturing"
        },
        {
          "code": "336",
          "name": "Transportation Equipment Manufacturing"
        },
        {
          "code": "3361",
          "name": "Motor Vehicle Manufacturing"
        },
        {
          "code": "33611",
          "name": "Automobile and Light Duty Motor Vehicle Manufacturing"
        },
        {
          "code": "336111",
          "name": "Automobile Manufacturing"
        },
        {
          "code": "336112",
          "name": "Light Truck and Utility Vehicle Manufacturing"
        },
        {
          "code": "33612",
          "name": "Heavy Duty Truck Manufacturing"
        },
        {
          "code": "336120",
          "name": "Heavy Duty Truck Manufacturing"
        },
        {
          "code": "3362",
          "name": "Motor Vehicle Body and Trailer Manufacturing"
        },
        {
          "code": "33621",
          "name": "Motor Vehicle Body and Trailer Manufacturing"
        },
        {
          "code": "336211",
          "name": "Motor Vehicle Body Manufacturing"
        },
        {
          "code": "336212",
          "name": "Truck Trailer Manufacturing"
        },
        {
          "code": "336213",
          "name": "Motor Home Manufacturing"
        },
        {
          "code": "336214",
          "name": "Travel Trailer and Camper Manufacturing"
        },
        {
          "code": "3363",
          "name": "Motor Vehicle Parts Manufacturing"
        },
        {
          "code": "33631",
          "name": "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing"
        },
        {
          "code": "336310",
          "name": "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing"
        },
        {
          "code": "33632",
          "name": "Motor Vehicle Electrical and Electronic Equipment Manufacturing"
        },
        {
          "code": "336320",
          "name": "Motor Vehicle Electrical and Electronic Equipment Manufacturing"
        },
        {
          "code": "33633",
          "name": "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"
        },
        {
          "code": "336330",
          "name": "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"
        },
        {
          "code": "33634",
          "name": "Motor Vehicle Brake System Manufacturing"
        },
        {
          "code": "336340",
          "name": "Motor Vehicle Brake System Manufacturing"
        },
        {
          "code": "33635",
          "name": "Motor Vehicle Transmission and Power Train Parts Manufacturing"
        },
        {
          "code": "336350",
          "name": "Motor Vehicle Transmission and Power Train Parts Manufacturing"
        },
        {
          "code": "33636",
          "name": "Motor Vehicle Seating and Interior Trim Manufacturing"
        },
        {
          "code": "336360",
          "name": "Motor Vehicle Seating and Interior Trim Manufacturing"
        },
        {
          "code": "33637",
          "name": "Motor Vehicle Metal Stamping"
        },
        {
          "code": "336370",
          "name": "Motor Vehicle Metal Stamping"
        },
        {
          "code": "33639",
          "name": "Other Motor Vehicle Parts Manufacturing"
        },
        {
          "code": "336390",
          "name": "Other Motor Vehicle Parts Manufacturing"
        },
        {
          "code": "3364",
          "name": "Aerospace Product and Parts Manufacturing"
        },
        {
          "code": "33641",
          "name": "Aerospace Product and Parts Manufacturing"
        },
        {
          "code": "336411",
          "name": "Aircraft Manufacturing"
        },
        {
          "code": "336412",
          "name": "Aircraft Engine and Engine Parts Manufacturing"
        },
        {
          "code": "336413",
          "name": "Other Aircraft Parts and Auxiliary Equipment Manufacturing"
        },
        {
          "code": "336414",
          "name": "Guided Missile and Space Vehicle Manufacturing"
        },
        {
          "code": "336415",
          "name": "Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing"
        },
        {
          "code": "336419",
          "name": "Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing"
        },
        {
          "code": "3365",
          "name": "Railroad Rolling Stock Manufacturing"
        },
        {
          "code": "33651",
          "name": "Railroad Rolling Stock Manufacturing"
        },
        {
          "code": "336510",
          "name": "Railroad Rolling Stock Manufacturing"
        },
        {
          "code": "3366",
          "name": "Ship and Boat Building"
        },
        {
          "code": "33661",
          "name": "Ship and Boat Building"
        },
        {
          "code": "336611",
          "name": "Ship Building and Repairing"
        },
        {
          "code": "336612",
          "name": "Boat Building"
        },
        {
          "code": "3369",
          "name": "Other Transportation Equipment Manufacturing"
        },
        {
          "code": "33699",
          "name": "Other Transportation Equipment Manufacturing"
        },
        {
          "code": "336991",
          "name": "Motorcycle, Bicycle, and Parts Manufacturing"
        },
        {
          "code": "336992",
          "name": "Military Armored Vehicle, Tank, and Tank Component Manufacturing"
        },
        {
          "code": "336999",
          "name": "All Other Transportation Equipment Manufacturing"
        },
        {
          "code": "337",
          "name": "Furniture and Related Product Manufacturing"
        },
        {
          "code": "3371",
          "name": "Household and Institutional Furniture and Kitchen Cabinet Manufacturing"
        },
        {
          "code": "33711",
          "name": "Wood Kitchen Cabinet and Countertop Manufacturing"
        },
        {
          "code": "337110",
          "name": "Wood Kitchen Cabinet and Countertop Manufacturing"
        },
        {
          "code": "33712",
          "name": "Household and Institutional Furniture Manufacturing"
        },
        {
          "code": "337121",
          "name": "Upholstered Household Furniture Manufacturing"
        },
        {
          "code": "337122",
          "name": "Nonupholstered Wood Household Furniture Manufacturing"
        },
        {
          "code": "337124",
          "name": "Metal Household Furniture Manufacturing"
        },
        {
          "code": "337125",
          "name": "Household Furniture (except Wood and Metal) Manufacturing"
        },
        {
          "code": "337127",
          "name": "Institutional Furniture Manufacturing"
        },
        {
          "code": "3372",
          "name": "Office Furniture (including Fixtures) Manufacturing"
        },
        {
          "code": "33721",
          "name": "Office Furniture (including Fixtures) Manufacturing"
        },
        {
          "code": "337211",
          "name": "Wood Office Furniture Manufacturing"
        },
        {
          "code": "337212",
          "name": "Custom Architectural Woodwork and Millwork Manufacturing"
        },
        {
          "code": "337214",
          "name": "Office Furniture (except Wood) Manufacturing"
        },
        {
          "code": "337215",
          "name": "Showcase, Partition, Shelving, and Locker Manufacturing"
        },
        {
          "code": "3379",
          "name": "Other Furniture Related Product Manufacturing"
        },
        {
          "code": "33791",
          "name": "Mattress Manufacturing"
        },
        {
          "code": "337910",
          "name": "Mattress Manufacturing"
        },
        {
          "code": "33792",
          "name": "Blind and Shade Manufacturing"
        },
        {
          "code": "337920",
          "name": "Blind and Shade Manufacturing"
        },
        {
          "code": "339",
          "name": "Miscellaneous Manufacturing"
        },
        {
          "code": "3391",
          "name": "Medical Equipment and Supplies Manufacturing"
        },
        {
          "code": "33911",
          "name": "Medical Equipment and Supplies Manufacturing"
        },
        {
          "code": "339112",
          "name": "Surgical and Medical Instrument Manufacturing"
        },
        {
          "code": "339113",
          "name": "Surgical Appliance and Supplies Manufacturing"
        },
        {
          "code": "339114",
          "name": "Dental Equipment and Supplies Manufacturing"
        },
        {
          "code": "339115",
          "name": "Ophthalmic Goods Manufacturing"
        },
        {
          "code": "339116",
          "name": "Dental Laboratories"
        },
        {
          "code": "3399",
          "name": "Other Miscellaneous Manufacturing"
        },
        {
          "code": "33991",
          "name": "Jewelry and Silverware Manufacturing"
        },
        {
          "code": "339910",
          "name": "Jewelry and Silverware Manufacturing"
        },
        {
          "code": "33992",
          "name": "Sporting and Athletic Goods Manufacturing"
        },
        {
          "code": "339920",
          "name": "Sporting and Athletic Goods Manufacturing"
        },
        {
          "code": "33993",
          "name": "Doll, Toy, and Game Manufacturing"
        },
        {
          "code": "339930",
          "name": "Doll, Toy, and Game Manufacturing"
        },
        {
          "code": "33994",
          "name": "Office Supplies (except Paper) Manufacturing"
        },
        {
          "code": "339940",
          "name": "Office Supplies (except Paper) Manufacturing"
        },
        {
          "code": "33995",
          "name": "Sign Manufacturing"
        },
        {
          "code": "339950",
          "name": "Sign Manufacturing"
        },
        {
          "code": "33999",
          "name": "All Other Miscellaneous Manufacturing"
        },
        {
          "code": "339991",
          "name": "Gasket, Packing, and Sealing Device Manufacturing"
        },
        {
          "code": "339992",
          "name": "Musical Instrument Manufacturing"
        },
        {
          "code": "339993",
          "name": "Fastener, Button, Needle, and Pin Manufacturing"
        },
        {
          "code": "339994",
          "name": "Broom, Brush, and Mop Manufacturing"
        },
        {
          "code": "339995",
          "name": "Burial Casket Manufacturing"
        },
        {
          "code": "339999",
          "name": "All Other Miscellaneous Manufacturing"
        },
        {
          "code": "42",
          "name": "Wholesale Trade"
        },
        {
          "code": "423",
          "name": "Merchant Wholesalers, Durable Goods"
        },
        {
          "code": "4231",
          "name": "Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers"
        },
        {
          "code": "42311",
          "name": "Automobile and Other Motor Vehicle Merchant Wholesalers"
        },
        {
          "code": "423110",
          "name": "Automobile and Other Motor Vehicle Merchant Wholesalers"
        },
        {
          "code": "42312",
          "name": "Motor Vehicle Supplies and New Parts Merchant Wholesalers"
        },
        {
          "code": "423120",
          "name": "Motor Vehicle Supplies and New Parts Merchant Wholesalers"
        },
        {
          "code": "42313",
          "name": "Tire and Tube Merchant Wholesalers"
        },
        {
          "code": "423130",
          "name": "Tire and Tube Merchant Wholesalers"
        },
        {
          "code": "42314",
          "name": "Motor Vehicle Parts (Used) Merchant Wholesalers"
        },
        {
          "code": "423140",
          "name": "Motor Vehicle Parts (Used) Merchant Wholesalers"
        },
        {
          "code": "4232",
          "name": "Furniture and Home Furnishing Merchant Wholesalers"
        },
        {
          "code": "42321",
          "name": "Furniture Merchant Wholesalers"
        },
        {
          "code": "423210",
          "name": "Furniture Merchant Wholesalers"
        },
        {
          "code": "42322",
          "name": "Home Furnishing Merchant Wholesalers"
        },
        {
          "code": "423220",
          "name": "Home Furnishing Merchant Wholesalers"
        },
        {
          "code": "4233",
          "name": "Lumber and Other Construction Materials Merchant Wholesalers"
        },
        {
          "code": "42331",
          "name": "Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers"
        },
        {
          "code": "423310",
          "name": "Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers"
        },
        {
          "code": "42332",
          "name": "Brick, Stone, and Related Construction Material Merchant Wholesalers"
        },
        {
          "code": "423320",
          "name": "Brick, Stone, and Related Construction Material Merchant Wholesalers"
        },
        {
          "code": "42333",
          "name": "Roofing, Siding, and Insulation Material Merchant Wholesalers"
        },
        {
          "code": "423330",
          "name": "Roofing, Siding, and Insulation Material Merchant Wholesalers"
        },
        {
          "code": "42339",
          "name": "Other Construction Material Merchant Wholesalers"
        },
        {
          "code": "423390",
          "name": "Other Construction Material Merchant Wholesalers"
        },
        {
          "code": "4234",
          "name": "Professional and Commercial Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "42341",
          "name": "Photographic Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "423410",
          "name": "Photographic Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "42342",
          "name": "Office Equipment Merchant Wholesalers"
        },
        {
          "code": "423420",
          "name": "Office Equipment Merchant Wholesalers"
        },
        {
          "code": "42343",
          "name": "Computer and Computer Peripheral Equipment and Software Merchant Wholesalers"
        },
        {
          "code": "423430",
          "name": "Computer and Computer Peripheral Equipment and Software Merchant Wholesalers"
        },
        {
          "code": "42344",
          "name": "Other Commercial Equipment Merchant Wholesalers"
        },
        {
          "code": "423440",
          "name": "Other Commercial Equipment Merchant Wholesalers"
        },
        {
          "code": "42345",
          "name": "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "423450",
          "name": "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "42346",
          "name": "Ophthalmic Goods Merchant Wholesalers"
        },
        {
          "code": "423460",
          "name": "Ophthalmic Goods Merchant Wholesalers"
        },
        {
          "code": "42349",
          "name": "Other Professional Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "423490",
          "name": "Other Professional Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "4235",
          "name": "Metal and Mineral (except Petroleum) Merchant Wholesalers"
        },
        {
          "code": "42351",
          "name": "Metal Service Centers and Other Metal Merchant Wholesalers"
        },
        {
          "code": "423510",
          "name": "Metal Service Centers and Other Metal Merchant Wholesalers"
        },
        {
          "code": "42352",
          "name": "Coal and Other Mineral and Ore Merchant Wholesalers"
        },
        {
          "code": "423520",
          "name": "Coal and Other Mineral and Ore Merchant Wholesalers"
        },
        {
          "code": "4236",
          "name": "Household Appliances and Electrical and Electronic Goods Merchant Wholesalers"
        },
        {
          "code": "42361",
          "name": "Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers"
        },
        {
          "code": "423610",
          "name": "Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers"
        },
        {
          "code": "42362",
          "name": "Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers"
        },
        {
          "code": "423620",
          "name": "Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers"
        },
        {
          "code": "42369",
          "name": "Other Electronic Parts and Equipment Merchant Wholesalers"
        },
        {
          "code": "423690",
          "name": "Other Electronic Parts and Equipment Merchant Wholesalers"
        },
        {
          "code": "4237",
          "name": "Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "42371",
          "name": "Hardware Merchant Wholesalers"
        },
        {
          "code": "423710",
          "name": "Hardware Merchant Wholesalers"
        },
        {
          "code": "42372",
          "name": "Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers"
        },
        {
          "code": "423720",
          "name": "Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers"
        },
        {
          "code": "42373",
          "name": "Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "423730",
          "name": "Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "42374",
          "name": "Refrigeration Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "423740",
          "name": "Refrigeration Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "4238",
          "name": "Machinery, Equipment, and Supplies Merchant Wholesalers"
        },
        {
          "code": "42381",
          "name": "Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers"
        },
        {
          "code": "423810",
          "name": "Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers"
        },
        {
          "code": "42382",
          "name": "Farm and Garden Machinery and Equipment Merchant Wholesalers"
        },
        {
          "code": "423820",
          "name": "Farm and Garden Machinery and Equipment Merchant Wholesalers"
        },
        {
          "code": "42383",
          "name": "Industrial Machinery and Equipment Merchant Wholesalers"
        },
        {
          "code": "423830",
          "name": "Industrial Machinery and Equipment Merchant Wholesalers"
        },
        {
          "code": "42384",
          "name": "Industrial Supplies Merchant Wholesalers"
        },
        {
          "code": "423840",
          "name": "Industrial Supplies Merchant Wholesalers"
        },
        {
          "code": "42385",
          "name": "Service Establishment Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "423850",
          "name": "Service Establishment Equipment and Supplies Merchant Wholesalers"
        },
        {
          "code": "42386",
          "name": "Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers"
        },
        {
          "code": "423860",
          "name": "Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers"
        },
        {
          "code": "4239",
          "name": "Miscellaneous Durable Goods Merchant Wholesalers"
        },
        {
          "code": "42391",
          "name": "Sporting and Recreational Goods and Supplies Merchant Wholesalers"
        },
        {
          "code": "423910",
          "name": "Sporting and Recreational Goods and Supplies Merchant Wholesalers"
        },
        {
          "code": "42392",
          "name": "Toy and Hobby Goods and Supplies Merchant Wholesalers"
        },
        {
          "code": "423920",
          "name": "Toy and Hobby Goods and Supplies Merchant Wholesalers"
        },
        {
          "code": "42393",
          "name": "Recyclable Material Merchant Wholesalers"
        },
        {
          "code": "423930",
          "name": "Recyclable Material Merchant Wholesalers"
        },
        {
          "code": "42394",
          "name": "Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers"
        },
        {
          "code": "423940",
          "name": "Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers"
        },
        {
          "code": "42399",
          "name": "Other Miscellaneous Durable Goods Merchant Wholesalers"
        },
        {
          "code": "423990",
          "name": "Other Miscellaneous Durable Goods Merchant Wholesalers"
        },
        {
          "code": "424",
          "name": "Merchant Wholesalers, Nondurable Goods"
        },
        {
          "code": "4241",
          "name": "Paper and Paper Product Merchant Wholesalers"
        },
        {
          "code": "42411",
          "name": "Printing and Writing Paper Merchant Wholesalers"
        },
        {
          "code": "424110",
          "name": "Printing and Writing Paper Merchant Wholesalers"
        },
        {
          "code": "42412",
          "name": "Stationery and Office Supplies Merchant Wholesalers"
        },
        {
          "code": "424120",
          "name": "Stationery and Office Supplies Merchant Wholesalers"
        },
        {
          "code": "42413",
          "name": "Industrial and Personal Service Paper Merchant Wholesalers"
        },
        {
          "code": "424130",
          "name": "Industrial and Personal Service Paper Merchant Wholesalers"
        },
        {
          "code": "4242",
          "name": "Drugs and Druggists' Sundries Merchant Wholesalers"
        },
        {
          "code": "42421",
          "name": "Drugs and Druggists' Sundries Merchant Wholesalers"
        },
        {
          "code": "424210",
          "name": "Drugs and Druggists' Sundries Merchant Wholesalers"
        },
        {
          "code": "4243",
          "name": "Apparel, Piece Goods, and Notions Merchant Wholesalers"
        },
        {
          "code": "42431",
          "name": "Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers"
        },
        {
          "code": "424310",
          "name": "Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers"
        },
        {
          "code": "42432",
          "name": "Men's and Boys' Clothing and Furnishings Merchant Wholesalers"
        },
        {
          "code": "424320",
          "name": "Men's and Boys' Clothing and Furnishings Merchant Wholesalers"
        },
        {
          "code": "42433",
          "name": "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers"
        },
        {
          "code": "424330",
          "name": "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers"
        },
        {
          "code": "42434",
          "name": "Footwear Merchant Wholesalers"
        },
        {
          "code": "424340",
          "name": "Footwear Merchant Wholesalers"
        },
        {
          "code": "4244",
          "name": "Grocery and Related Product Merchant Wholesalers"
        },
        {
          "code": "42441",
          "name": "General Line Grocery Merchant Wholesalers"
        },
        {
          "code": "424410",
          "name": "General Line Grocery Merchant Wholesalers"
        },
        {
          "code": "42442",
          "name": "Packaged Frozen Food Merchant Wholesalers"
        },
        {
          "code": "424420",
          "name": "Packaged Frozen Food Merchant Wholesalers"
        },
        {
          "code": "42443",
          "name": "Dairy Product (except Dried or Canned) Merchant Wholesalers"
        },
        {
          "code": "424430",
          "name": "Dairy Product (except Dried or Canned) Merchant Wholesalers"
        },
        {
          "code": "42444",
          "name": "Poultry and Poultry Product Merchant Wholesalers"
        },
        {
          "code": "424440",
          "name": "Poultry and Poultry Product Merchant Wholesalers"
        },
        {
          "code": "42445",
          "name": "Confectionery Merchant Wholesalers"
        },
        {
          "code": "424450",
          "name": "Confectionery Merchant Wholesalers"
        },
        {
          "code": "42446",
          "name": "Fish and Seafood Merchant Wholesalers"
        },
        {
          "code": "424460",
          "name": "Fish and Seafood Merchant Wholesalers"
        },
        {
          "code": "42447",
          "name": "Meat and Meat Product Merchant Wholesalers"
        },
        {
          "code": "424470",
          "name": "Meat and Meat Product Merchant Wholesalers"
        },
        {
          "code": "42448",
          "name": "Fresh Fruit and Vegetable Merchant Wholesalers"
        },
        {
          "code": "424480",
          "name": "Fresh Fruit and Vegetable Merchant Wholesalers"
        },
        {
          "code": "42449",
          "name": "Other Grocery and Related Products Merchant Wholesalers"
        },
        {
          "code": "424490",
          "name": "Other Grocery and Related Products Merchant Wholesalers"
        },
        {
          "code": "4245",
          "name": "Farm Product Raw Material Merchant Wholesalers"
        },
        {
          "code": "42451",
          "name": "Grain and Field Bean Merchant Wholesalers"
        },
        {
          "code": "424510",
          "name": "Grain and Field Bean Merchant Wholesalers"
        },
        {
          "code": "42452",
          "name": "Livestock Merchant Wholesalers"
        },
        {
          "code": "424520",
          "name": "Livestock Merchant Wholesalers"
        },
        {
          "code": "42459",
          "name": "Other Farm Product Raw Material Merchant Wholesalers"
        },
        {
          "code": "424590",
          "name": "Other Farm Product Raw Material Merchant Wholesalers"
        },
        {
          "code": "4246",
          "name": "Chemical and Allied Products Merchant Wholesalers"
        },
        {
          "code": "42461",
          "name": "Plastics Materials and Basic Forms and Shapes Merchant Wholesalers"
        },
        {
          "code": "424610",
          "name": "Plastics Materials and Basic Forms and Shapes Merchant Wholesalers"
        },
        {
          "code": "42469",
          "name": "Other Chemical and Allied Products Merchant Wholesalers"
        },
        {
          "code": "424690",
          "name": "Other Chemical and Allied Products Merchant Wholesalers"
        },
        {
          "code": "4247",
          "name": "Petroleum and Petroleum Products Merchant Wholesalers"
        },
        {
          "code": "42471",
          "name": "Petroleum Bulk Stations and Terminals"
        },
        {
          "code": "424710",
          "name": "Petroleum Bulk Stations and Terminals"
        },
        {
          "code": "42472",
          "name": "Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)"
        },
        {
          "code": "424720",
          "name": "Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)"
        },
        {
          "code": "4248",
          "name": "Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers"
        },
        {
          "code": "42481",
          "name": "Beer and Ale Merchant Wholesalers"
        },
        {
          "code": "424810",
          "name": "Beer and Ale Merchant Wholesalers"
        },
        {
          "code": "42482",
          "name": "Wine and Distilled Alcoholic Beverage Merchant Wholesalers"
        },
        {
          "code": "424820",
          "name": "Wine and Distilled Alcoholic Beverage Merchant Wholesalers"
        },
        {
          "code": "4249",
          "name": "Miscellaneous Nondurable Goods Merchant Wholesalers"
        },
        {
          "code": "42491",
          "name": "Farm Supplies Merchant Wholesalers"
        },
        {
          "code": "424910",
          "name": "Farm Supplies Merchant Wholesalers"
        },
        {
          "code": "42492",
          "name": "Book, Periodical, and Newspaper Merchant Wholesalers"
        },
        {
          "code": "424920",
          "name": "Book, Periodical, and Newspaper Merchant Wholesalers"
        },
        {
          "code": "42493",
          "name": "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
        },
        {
          "code": "424930",
          "name": "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
        },
        {
          "code": "42494",
          "name": "Tobacco and Tobacco Product Merchant Wholesalers"
        },
        {
          "code": "424940",
          "name": "Tobacco and Tobacco Product Merchant Wholesalers"
        },
        {
          "code": "42495",
          "name": "Paint, Varnish, and Supplies Merchant Wholesalers"
        },
        {
          "code": "424950",
          "name": "Paint, Varnish, and Supplies Merchant Wholesalers"
        },
        {
          "code": "42499",
          "name": "Other Miscellaneous Nondurable Goods Merchant Wholesalers"
        },
        {
          "code": "424990",
          "name": "Other Miscellaneous Nondurable Goods Merchant Wholesalers"
        },
        {
          "code": "425",
          "name": "Wholesale Electronic Markets and Agents and Brokers"
        },
        {
          "code": "4251",
          "name": "Wholesale Electronic Markets and Agents and Brokers"
        },
        {
          "code": "42511",
          "name": "Business to Business Electronic Markets"
        },
        {
          "code": "425110",
          "name": "Business to Business Electronic Markets"
        },
        {
          "code": "42512",
          "name": "Wholesale Trade Agents and Brokers"
        },
        {
          "code": "425120",
          "name": "Wholesale Trade Agents and Brokers"
        },
        {
          "code": "44-45",
          "name": "Retail Trade"
        },
        {
          "code": "441",
          "name": "Motor Vehicle and Parts Dealers"
        },
        {
          "code": "4411",
          "name": "Automobile Dealers"
        },
        {
          "code": "44111",
          "name": "New Car Dealers"
        },
        {
          "code": "441110",
          "name": "New Car Dealers"
        },
        {
          "code": "44112",
          "name": "Used Car Dealers"
        },
        {
          "code": "441120",
          "name": "Used Car Dealers"
        },
        {
          "code": "4412",
          "name": "Other Motor Vehicle Dealers"
        },
        {
          "code": "44121",
          "name": "Recreational Vehicle Dealers"
        },
        {
          "code": "441210",
          "name": "Recreational Vehicle Dealers"
        },
        {
          "code": "44122",
          "name": "Motorcycle, Boat, and Other Motor Vehicle Dealers"
        },
        {
          "code": "441222",
          "name": "Boat Dealers"
        },
        {
          "code": "441228",
          "name": "Motorcycle, ATV, and All Other Motor Vehicle Dealers"
        },
        {
          "code": "4413",
          "name": "Automotive Parts, Accessories, and Tire Stores"
        },
        {
          "code": "44131",
          "name": "Automotive Parts and Accessories Stores"
        },
        {
          "code": "441310",
          "name": "Automotive Parts and Accessories Stores"
        },
        {
          "code": "44132",
          "name": "Tire Dealers"
        },
        {
          "code": "441320",
          "name": "Tire Dealers"
        },
        {
          "code": "442",
          "name": "Furniture and Home Furnishings Stores"
        },
        {
          "code": "4421",
          "name": "Furniture Stores"
        },
        {
          "code": "44211",
          "name": "Furniture Stores"
        },
        {
          "code": "442110",
          "name": "Furniture Stores"
        },
        {
          "code": "4422",
          "name": "Home Furnishings Stores"
        },
        {
          "code": "44221",
          "name": "Floor Covering Stores"
        },
        {
          "code": "442210",
          "name": "Floor Covering Stores"
        },
        {
          "code": "44229",
          "name": "Other Home Furnishings Stores"
        },
        {
          "code": "442291",
          "name": "Window Treatment Stores"
        },
        {
          "code": "442299",
          "name": "All Other Home Furnishings Stores"
        },
        {
          "code": "443",
          "name": "Electronics and Appliance Stores"
        },
        {
          "code": "4431",
          "name": "Electronics and Appliance Stores"
        },
        {
          "code": "44314",
          "name": "Electronics and Appliance Stores"
        },
        {
          "code": "443141",
          "name": "Household Appliance Stores"
        },
        {
          "code": "443142",
          "name": "Electronics Stores"
        },
        {
          "code": "444",
          "name": "Building Material and Garden Equipment and Supplies Dealers"
        },
        {
          "code": "4441",
          "name": "Building Material and Supplies Dealers"
        },
        {
          "code": "44411",
          "name": "Home Centers"
        },
        {
          "code": "444110",
          "name": "Home Centers"
        },
        {
          "code": "44412",
          "name": "Paint and Wallpaper Stores"
        },
        {
          "code": "444120",
          "name": "Paint and Wallpaper Stores"
        },
        {
          "code": "44413",
          "name": "Hardware Stores"
        },
        {
          "code": "444130",
          "name": "Hardware Stores"
        },
        {
          "code": "44419",
          "name": "Other Building Material Dealers"
        },
        {
          "code": "444190",
          "name": "Other Building Material Dealers"
        },
        {
          "code": "4442",
          "name": "Lawn and Garden Equipment and Supplies Stores"
        },
        {
          "code": "44421",
          "name": "Outdoor Power Equipment Stores"
        },
        {
          "code": "444210",
          "name": "Outdoor Power Equipment Stores"
        },
        {
          "code": "44422",
          "name": "Nursery, Garden Center, and Farm Supply Stores"
        },
        {
          "code": "444220",
          "name": "Nursery, Garden Center, and Farm Supply Stores"
        },
        {
          "code": "445",
          "name": "Food and Beverage Stores"
        },
        {
          "code": "4451",
          "name": "Grocery Stores"
        },
        {
          "code": "44511",
          "name": "Supermarkets and Other Grocery (except Convenience) Stores"
        },
        {
          "code": "445110",
          "name": "Supermarkets and Other Grocery (except Convenience) Stores"
        },
        {
          "code": "44512",
          "name": "Convenience Stores"
        },
        {
          "code": "445120",
          "name": "Convenience Stores"
        },
        {
          "code": "4452",
          "name": "Specialty Food Stores"
        },
        {
          "code": "44521",
          "name": "Meat Markets"
        },
        {
          "code": "445210",
          "name": "Meat Markets"
        },
        {
          "code": "44522",
          "name": "Fish and Seafood Markets"
        },
        {
          "code": "445220",
          "name": "Fish and Seafood Markets"
        },
        {
          "code": "44523",
          "name": "Fruit and Vegetable Markets"
        },
        {
          "code": "445230",
          "name": "Fruit and Vegetable Markets"
        },
        {
          "code": "44529",
          "name": "Other Specialty Food Stores"
        },
        {
          "code": "445291",
          "name": "Baked Goods Stores"
        },
        {
          "code": "445292",
          "name": "Confectionery and Nut Stores"
        },
        {
          "code": "445299",
          "name": "All Other Specialty Food Stores"
        },
        {
          "code": "4453",
          "name": "Beer, Wine, and Liquor Stores"
        },
        {
          "code": "44531",
          "name": "Beer, Wine, and Liquor Stores"
        },
        {
          "code": "445310",
          "name": "Beer, Wine, and Liquor Stores"
        },
        {
          "code": "446",
          "name": "Health and Personal Care Stores"
        },
        {
          "code": "4461",
          "name": "Health and Personal Care Stores"
        },
        {
          "code": "44611",
          "name": "Pharmacies and Drug Stores"
        },
        {
          "code": "446110",
          "name": "Pharmacies and Drug Stores"
        },
        {
          "code": "44612",
          "name": "Cosmetics, Beauty Supplies, and Perfume Stores"
        },
        {
          "code": "446120",
          "name": "Cosmetics, Beauty Supplies, and Perfume Stores"
        },
        {
          "code": "44613",
          "name": "Optical Goods Stores"
        },
        {
          "code": "446130",
          "name": "Optical Goods Stores"
        },
        {
          "code": "44619",
          "name": "Other Health and Personal Care Stores"
        },
        {
          "code": "446191",
          "name": "Food (Health) Supplement Stores"
        },
        {
          "code": "446199",
          "name": "All Other Health and Personal Care Stores"
        },
        {
          "code": "447",
          "name": "Gasoline Stations"
        },
        {
          "code": "4471",
          "name": "Gasoline Stations"
        },
        {
          "code": "44711",
          "name": "Gasoline Stations with Convenience Stores"
        },
        {
          "code": "447110",
          "name": "Gasoline Stations with Convenience Stores"
        },
        {
          "code": "44719",
          "name": "Other Gasoline Stations"
        },
        {
          "code": "447190",
          "name": "Other Gasoline Stations"
        },
        {
          "code": "448",
          "name": "Clothing and Clothing Accessories Stores"
        },
        {
          "code": "4481",
          "name": "Clothing Stores"
        },
        {
          "code": "44811",
          "name": "Men's Clothing Stores"
        },
        {
          "code": "448110",
          "name": "Men's Clothing Stores"
        },
        {
          "code": "44812",
          "name": "Women's Clothing Stores"
        },
        {
          "code": "448120",
          "name": "Women's Clothing Stores"
        },
        {
          "code": "44813",
          "name": "Children's and Infants' Clothing Stores"
        },
        {
          "code": "448130",
          "name": "Children's and Infants' Clothing Stores"
        },
        {
          "code": "44814",
          "name": "Family Clothing Stores"
        },
        {
          "code": "448140",
          "name": "Family Clothing Stores"
        },
        {
          "code": "44815",
          "name": "Clothing Accessories Stores"
        },
        {
          "code": "448150",
          "name": "Clothing Accessories Stores"
        },
        {
          "code": "44819",
          "name": "Other Clothing Stores"
        },
        {
          "code": "448190",
          "name": "Other Clothing Stores"
        },
        {
          "code": "4482",
          "name": "Shoe Stores"
        },
        {
          "code": "44821",
          "name": "Shoe Stores"
        },
        {
          "code": "448210",
          "name": "Shoe Stores"
        },
        {
          "code": "4483",
          "name": "Jewelry, Luggage, and Leather Goods Stores"
        },
        {
          "code": "44831",
          "name": "Jewelry Stores"
        },
        {
          "code": "448310",
          "name": "Jewelry Stores"
        },
        {
          "code": "44832",
          "name": "Luggage and Leather Goods Stores"
        },
        {
          "code": "448320",
          "name": "Luggage and Leather Goods Stores"
        },
        {
          "code": "451",
          "name": "Sporting Goods, Hobby, Musical Instrument, and Book Stores"
        },
        {
          "code": "4511",
          "name": "Sporting Goods, Hobby, and Musical Instrument Stores"
        },
        {
          "code": "45111",
          "name": "Sporting Goods Stores"
        },
        {
          "code": "451110",
          "name": "Sporting Goods Stores"
        },
        {
          "code": "45112",
          "name": "Hobby, Toy, and Game Stores"
        },
        {
          "code": "451120",
          "name": "Hobby, Toy, and Game Stores"
        },
        {
          "code": "45113",
          "name": "Sewing, Needlework, and Piece Goods Stores"
        },
        {
          "code": "451130",
          "name": "Sewing, Needlework, and Piece Goods Stores"
        },
        {
          "code": "45114",
          "name": "Musical Instrument and Supplies Stores"
        },
        {
          "code": "451140",
          "name": "Musical Instrument and Supplies Stores"
        },
        {
          "code": "4512",
          "name": "Book Stores and News Dealers"
        },
        {
          "code": "45121",
          "name": "Book Stores and News Dealers"
        },
        {
          "code": "451211",
          "name": "Book Stores"
        },
        {
          "code": "451212",
          "name": "News Dealers and Newsstands"
        },
        {
          "code": "452",
          "name": "General Merchandise Stores"
        },
        {
          "code": "4522",
          "name": "Department Stores"
        },
        {
          "code": "45221",
          "name": "Department Stores"
        },
        {
          "code": "452210",
          "name": "Department Stores"
        },
        {
          "code": "4523",
          "name": "General Merchandise Stores, including Warehouse Clubs and Supercenters"
        },
        {
          "code": "45231",
          "name": "General Merchandise Stores, including Warehouse Clubs and Supercenters"
        },
        {
          "code": "452311",
          "name": "Warehouse Clubs and Supercenters"
        },
        {
          "code": "452319",
          "name": "All Other General Merchandise Stores"
        },
        {
          "code": "453",
          "name": "Miscellaneous Store Retailers"
        },
        {
          "code": "4531",
          "name": "Florists"
        },
        {
          "code": "45311",
          "name": "Florists"
        },
        {
          "code": "453110",
          "name": "Florists"
        },
        {
          "code": "4532",
          "name": "Office Supplies, Stationery, and Gift Stores"
        },
        {
          "code": "45321",
          "name": "Office Supplies and Stationery Stores"
        },
        {
          "code": "453210",
          "name": "Office Supplies and Stationery Stores"
        },
        {
          "code": "45322",
          "name": "Gift, Novelty, and Souvenir Stores"
        },
        {
          "code": "453220",
          "name": "Gift, Novelty, and Souvenir Stores"
        },
        {
          "code": "4533",
          "name": "Used Merchandise Stores"
        },
        {
          "code": "45331",
          "name": "Used Merchandise Stores"
        },
        {
          "code": "453310",
          "name": "Used Merchandise Stores"
        },
        {
          "code": "4539",
          "name": "Other Miscellaneous Store Retailers"
        },
        {
          "code": "45391",
          "name": "Pet and Pet Supplies Stores"
        },
        {
          "code": "453910",
          "name": "Pet and Pet Supplies Stores"
        },
        {
          "code": "45392",
          "name": "Art Dealers"
        },
        {
          "code": "453920",
          "name": "Art Dealers"
        },
        {
          "code": "45393",
          "name": "Manufactured (Mobile) Home Dealers"
        },
        {
          "code": "453930",
          "name": "Manufactured (Mobile) Home Dealers"
        },
        {
          "code": "45399",
          "name": "All Other Miscellaneous Store Retailers"
        },
        {
          "code": "453991",
          "name": "Tobacco Stores"
        },
        {
          "code": "453998",
          "name": "All Other Miscellaneous Store Retailers (except Tobacco Stores)"
        },
        {
          "code": "454",
          "name": "Nonstore Retailers"
        },
        {
          "code": "4541",
          "name": "Electronic Shopping and Mail-Order Houses"
        },
        {
          "code": "45411",
          "name": "Electronic Shopping and Mail-Order Houses"
        },
        {
          "code": "454110",
          "name": "Electronic Shopping and Mail-Order Houses"
        },
        {
          "code": "4542",
          "name": "Vending Machine Operators"
        },
        {
          "code": "45421",
          "name": "Vending Machine Operators"
        },
        {
          "code": "454210",
          "name": "Vending Machine Operators"
        },
        {
          "code": "4543",
          "name": "Direct Selling Establishments"
        },
        {
          "code": "45431",
          "name": "Fuel Dealers"
        },
        {
          "code": "454310",
          "name": "Fuel Dealers"
        },
        {
          "code": "45439",
          "name": "Other Direct Selling Establishments"
        },
        {
          "code": "454390",
          "name": "Other Direct Selling Establishments"
        },
        {
          "code": "48-49",
          "name": "Transportation and Warehousing"
        },
        {
          "code": "481",
          "name": "Air Transportation"
        },
        {
          "code": "4811",
          "name": "Scheduled Air Transportation"
        },
        {
          "code": "48111",
          "name": "Scheduled Air Transportation"
        },
        {
          "code": "481111",
          "name": "Scheduled Passenger Air Transportation"
        },
        {
          "code": "481112",
          "name": "Scheduled Freight Air Transportation"
        },
        {
          "code": "4812",
          "name": "Nonscheduled Air Transportation"
        },
        {
          "code": "48121",
          "name": "Nonscheduled Air Transportation"
        },
        {
          "code": "481211",
          "name": "Nonscheduled Chartered Passenger Air Transportation"
        },
        {
          "code": "481212",
          "name": "Nonscheduled Chartered Freight Air Transportation"
        },
        {
          "code": "481219",
          "name": "Other Nonscheduled Air Transportation"
        },
        {
          "code": "482",
          "name": "Rail Transportation"
        },
        {
          "code": "4821",
          "name": "Rail Transportation"
        },
        {
          "code": "48211",
          "name": "Rail Transportation"
        },
        {
          "code": "482111",
          "name": "Line-Haul Railroads"
        },
        {
          "code": "482112",
          "name": "Short Line Railroads"
        },
        {
          "code": "483",
          "name": "Water Transportation"
        },
        {
          "code": "4831",
          "name": "Deep Sea, Coastal, and Great Lakes Water Transportation"
        },
        {
          "code": "48311",
          "name": "Deep Sea, Coastal, and Great Lakes Water Transportation"
        },
        {
          "code": "483111",
          "name": "Deep Sea Freight Transportation"
        },
        {
          "code": "483112",
          "name": "Deep Sea Passenger Transportation"
        },
        {
          "code": "483113",
          "name": "Coastal and Great Lakes Freight Transportation"
        },
        {
          "code": "483114",
          "name": "Coastal and Great Lakes Passenger Transportation"
        },
        {
          "code": "4832",
          "name": "Inland Water Transportation"
        },
        {
          "code": "48321",
          "name": "Inland Water Transportation"
        },
        {
          "code": "483211",
          "name": "Inland Water Freight Transportation"
        },
        {
          "code": "483212",
          "name": "Inland Water Passenger Transportation"
        },
        {
          "code": "484",
          "name": "Truck Transportation"
        },
        {
          "code": "4841",
          "name": "General Freight Trucking"
        },
        {
          "code": "48411",
          "name": "General Freight Trucking, Local"
        },
        {
          "code": "484110",
          "name": "General Freight Trucking, Local"
        },
        {
          "code": "48412",
          "name": "General Freight Trucking, Long-Distance"
        },
        {
          "code": "484121",
          "name": "General Freight Trucking, Long-Distance, Truckload"
        },
        {
          "code": "484122",
          "name": "General Freight Trucking, Long-Distance, Less Than Truckload"
        },
        {
          "code": "4842",
          "name": "Specialized Freight Trucking"
        },
        {
          "code": "48421",
          "name": "Used Household and Office Goods Moving"
        },
        {
          "code": "484210",
          "name": "Used Household and Office Goods Moving"
        },
        {
          "code": "48422",
          "name": "Specialized Freight (except Used Goods) Trucking, Local"
        },
        {
          "code": "484220",
          "name": "Specialized Freight (except Used Goods) Trucking, Local"
        },
        {
          "code": "48423",
          "name": "Specialized Freight (except Used Goods) Trucking, Long-Distance"
        },
        {
          "code": "484230",
          "name": "Specialized Freight (except Used Goods) Trucking, Long-Distance"
        },
        {
          "code": "485",
          "name": "Transit and Ground Passenger Transportation"
        },
        {
          "code": "4851",
          "name": "Urban Transit Systems"
        },
        {
          "code": "48511",
          "name": "Urban Transit Systems"
        },
        {
          "code": "485111",
          "name": "Mixed Mode Transit Systems"
        },
        {
          "code": "485112",
          "name": "Commuter Rail Systems"
        },
        {
          "code": "485113",
          "name": "Bus and Other Motor Vehicle Transit Systems"
        },
        {
          "code": "485119",
          "name": "Other Urban Transit Systems"
        },
        {
          "code": "4852",
          "name": "Interurban and Rural Bus Transportation"
        },
        {
          "code": "48521",
          "name": "Interurban and Rural Bus Transportation"
        },
        {
          "code": "485210",
          "name": "Interurban and Rural Bus Transportation"
        },
        {
          "code": "4853",
          "name": "Taxi and Limousine Service"
        },
        {
          "code": "48531",
          "name": "Taxi Service"
        },
        {
          "code": "485310",
          "name": "Taxi Service"
        },
        {
          "code": "48532",
          "name": "Limousine Service"
        },
        {
          "code": "485320",
          "name": "Limousine Service"
        },
        {
          "code": "4854",
          "name": "School and Employee Bus Transportation"
        },
        {
          "code": "48541",
          "name": "School and Employee Bus Transportation"
        },
        {
          "code": "485410",
          "name": "School and Employee Bus Transportation"
        },
        {
          "code": "4855",
          "name": "Charter Bus Industry"
        },
        {
          "code": "48551",
          "name": "Charter Bus Industry"
        },
        {
          "code": "485510",
          "name": "Charter Bus Industry"
        },
        {
          "code": "4859",
          "name": "Other Transit and Ground Passenger Transportation"
        },
        {
          "code": "48599",
          "name": "Other Transit and Ground Passenger Transportation"
        },
        {
          "code": "485991",
          "name": "Special Needs Transportation"
        },
        {
          "code": "485999",
          "name": "All Other Transit and Ground Passenger Transportation"
        },
        {
          "code": "486",
          "name": "Pipeline Transportation"
        },
        {
          "code": "4861",
          "name": "Pipeline Transportation of Crude Oil"
        },
        {
          "code": "48611",
          "name": "Pipeline Transportation of Crude Oil"
        },
        {
          "code": "486110",
          "name": "Pipeline Transportation of Crude Oil"
        },
        {
          "code": "4862",
          "name": "Pipeline Transportation of Natural Gas"
        },
        {
          "code": "48621",
          "name": "Pipeline Transportation of Natural Gas"
        },
        {
          "code": "486210",
          "name": "Pipeline Transportation of Natural Gas"
        },
        {
          "code": "4869",
          "name": "Other Pipeline Transportation"
        },
        {
          "code": "48691",
          "name": "Pipeline Transportation of Refined Petroleum Products"
        },
        {
          "code": "486910",
          "name": "Pipeline Transportation of Refined Petroleum Products"
        },
        {
          "code": "48699",
          "name": "All Other Pipeline Transportation"
        },
        {
          "code": "486990",
          "name": "All Other Pipeline Transportation"
        },
        {
          "code": "487",
          "name": "Scenic and Sightseeing Transportation"
        },
        {
          "code": "4871",
          "name": "Scenic and Sightseeing Transportation, Land"
        },
        {
          "code": "48711",
          "name": "Scenic and Sightseeing Transportation, Land"
        },
        {
          "code": "487110",
          "name": "Scenic and Sightseeing Transportation, Land"
        },
        {
          "code": "4872",
          "name": "Scenic and Sightseeing Transportation, Water"
        },
        {
          "code": "48721",
          "name": "Scenic and Sightseeing Transportation, Water"
        },
        {
          "code": "487210",
          "name": "Scenic and Sightseeing Transportation, Water"
        },
        {
          "code": "4879",
          "name": "Scenic and Sightseeing Transportation, Other"
        },
        {
          "code": "48799",
          "name": "Scenic and Sightseeing Transportation, Other"
        },
        {
          "code": "487990",
          "name": "Scenic and Sightseeing Transportation, Other"
        },
        {
          "code": "488",
          "name": "Support Activities for Transportation"
        },
        {
          "code": "4881",
          "name": "Support Activities for Air Transportation"
        },
        {
          "code": "48811",
          "name": "Airport Operations"
        },
        {
          "code": "488111",
          "name": "Air Traffic Control"
        },
        {
          "code": "488119",
          "name": "Other Airport Operations"
        },
        {
          "code": "48819",
          "name": "Other Support Activities for Air Transportation"
        },
        {
          "code": "488190",
          "name": "Other Support Activities for Air Transportation"
        },
        {
          "code": "4882",
          "name": "Support Activities for Rail Transportation"
        },
        {
          "code": "48821",
          "name": "Support Activities for Rail Transportation"
        },
        {
          "code": "488210",
          "name": "Support Activities for Rail Transportation"
        },
        {
          "code": "4883",
          "name": "Support Activities for Water Transportation"
        },
        {
          "code": "48831",
          "name": "Port and Harbor Operations"
        },
        {
          "code": "488310",
          "name": "Port and Harbor Operations"
        },
        {
          "code": "48832",
          "name": "Marine Cargo Handling"
        },
        {
          "code": "488320",
          "name": "Marine Cargo Handling"
        },
        {
          "code": "48833",
          "name": "Navigational Services to Shipping"
        },
        {
          "code": "488330",
          "name": "Navigational Services to Shipping"
        },
        {
          "code": "48839",
          "name": "Other Support Activities for Water Transportation"
        },
        {
          "code": "488390",
          "name": "Other Support Activities for Water Transportation"
        },
        {
          "code": "4884",
          "name": "Support Activities for Road Transportation"
        },
        {
          "code": "48841",
          "name": "Motor Vehicle Towing"
        },
        {
          "code": "488410",
          "name": "Motor Vehicle Towing"
        },
        {
          "code": "48849",
          "name": "Other Support Activities for Road Transportation"
        },
        {
          "code": "488490",
          "name": "Other Support Activities for Road Transportation"
        },
        {
          "code": "4885",
          "name": "Freight Transportation Arrangement"
        },
        {
          "code": "48851",
          "name": "Freight Transportation Arrangement"
        },
        {
          "code": "488510",
          "name": "Freight Transportation Arrangement"
        },
        {
          "code": "4889",
          "name": "Other Support Activities for Transportation"
        },
        {
          "code": "48899",
          "name": "Other Support Activities for Transportation"
        },
        {
          "code": "488991",
          "name": "Packing and Crating"
        },
        {
          "code": "488999",
          "name": "All Other Support Activities for Transportation"
        },
        {
          "code": "491",
          "name": "Postal Service"
        },
        {
          "code": "4911",
          "name": "Postal Service"
        },
        {
          "code": "49111",
          "name": "Postal Service"
        },
        {
          "code": "491110",
          "name": "Postal Service"
        },
        {
          "code": "492",
          "name": "Couriers and Messengers"
        },
        {
          "code": "4921",
          "name": "Couriers and Express Delivery Services"
        },
        {
          "code": "49211",
          "name": "Couriers and Express Delivery Services"
        },
        {
          "code": "492110",
          "name": "Couriers and Express Delivery Services"
        },
        {
          "code": "4922",
          "name": "Local Messengers and Local Delivery"
        },
        {
          "code": "49221",
          "name": "Local Messengers and Local Delivery"
        },
        {
          "code": "492210",
          "name": "Local Messengers and Local Delivery"
        },
        {
          "code": "493",
          "name": "Warehousing and Storage"
        },
        {
          "code": "4931",
          "name": "Warehousing and Storage"
        },
        {
          "code": "49311",
          "name": "General Warehousing and Storage"
        },
        {
          "code": "493110",
          "name": "General Warehousing and Storage"
        },
        {
          "code": "49312",
          "name": "Refrigerated Warehousing and Storage"
        },
        {
          "code": "493120",
          "name": "Refrigerated Warehousing and Storage"
        },
        {
          "code": "49313",
          "name": "Farm Product Warehousing and Storage"
        },
        {
          "code": "493130",
          "name": "Farm Product Warehousing and Storage"
        },
        {
          "code": "49319",
          "name": "Other Warehousing and Storage"
        },
        {
          "code": "493190",
          "name": "Other Warehousing and Storage"
        },
        {
          "code": "51",
          "name": "Information"
        },
        {
          "code": "511",
          "name": "Publishing Industries (except Internet)"
        },
        {
          "code": "5111",
          "name": "Newspaper, Periodical, Book, and Directory Publishers"
        },
        {
          "code": "51111",
          "name": "Newspaper Publishers"
        },
        {
          "code": "511110",
          "name": "Newspaper Publishers"
        },
        {
          "code": "51112",
          "name": "Periodical Publishers"
        },
        {
          "code": "511120",
          "name": "Periodical Publishers"
        },
        {
          "code": "51113",
          "name": "Book Publishers"
        },
        {
          "code": "511130",
          "name": "Book Publishers"
        },
        {
          "code": "51114",
          "name": "Directory and Mailing List Publishers"
        },
        {
          "code": "511140",
          "name": "Directory and Mailing List Publishers"
        },
        {
          "code": "51119",
          "name": "Other Publishers"
        },
        {
          "code": "511191",
          "name": "Greeting Card Publishers"
        },
        {
          "code": "511199",
          "name": "All Other Publishers"
        },
        {
          "code": "5112",
          "name": "Software Publishers"
        },
        {
          "code": "51121",
          "name": "Software Publishers"
        },
        {
          "code": "511210",
          "name": "Software Publishers"
        },
        {
          "code": "512",
          "name": "Motion Picture and Sound Recording Industries"
        },
        {
          "code": "5121",
          "name": "Motion Picture and Video Industries"
        },
        {
          "code": "51211",
          "name": "Motion Picture and Video Production"
        },
        {
          "code": "512110",
          "name": "Motion Picture and Video Production"
        },
        {
          "code": "51212",
          "name": "Motion Picture and Video Distribution"
        },
        {
          "code": "512120",
          "name": "Motion Picture and Video Distribution"
        },
        {
          "code": "51213",
          "name": "Motion Picture and Video Exhibition"
        },
        {
          "code": "512131",
          "name": "Motion Picture Theaters (except Drive-Ins)"
        },
        {
          "code": "512132",
          "name": "Drive-In Motion Picture Theaters"
        },
        {
          "code": "51219",
          "name": "Postproduction Services and Other Motion Picture and Video Industries"
        },
        {
          "code": "512191",
          "name": "Teleproduction and Other Postproduction Services"
        },
        {
          "code": "512199",
          "name": "Other Motion Picture and Video Industries"
        },
        {
          "code": "5122",
          "name": "Sound Recording Industries"
        },
        {
          "code": "51223",
          "name": "Music Publishers"
        },
        {
          "code": "512230",
          "name": "Music Publishers"
        },
        {
          "code": "51224",
          "name": "Sound Recording Studios"
        },
        {
          "code": "512240",
          "name": "Sound Recording Studios"
        },
        {
          "code": "51225",
          "name": "Record Production and Distribution"
        },
        {
          "code": "512250",
          "name": "Record Production and Distribution"
        },
        {
          "code": "51229",
          "name": "Other Sound Recording Industries"
        },
        {
          "code": "512290",
          "name": "Other Sound Recording Industries"
        },
        {
          "code": "515",
          "name": "Broadcasting (except Internet)"
        },
        {
          "code": "5151",
          "name": "Radio and Television Broadcasting"
        },
        {
          "code": "51511",
          "name": "Radio Broadcasting"
        },
        {
          "code": "515111",
          "name": "Radio Networks"
        },
        {
          "code": "515112",
          "name": "Radio Stations"
        },
        {
          "code": "51512",
          "name": "Television Broadcasting"
        },
        {
          "code": "515120",
          "name": "Television Broadcasting"
        },
        {
          "code": "5152",
          "name": "Cable and Other Subscription Programming"
        },
        {
          "code": "51521",
          "name": "Cable and Other Subscription Programming"
        },
        {
          "code": "515210",
          "name": "Cable and Other Subscription Programming"
        },
        {
          "code": "517",
          "name": "Telecommunications"
        },
        {
          "code": "5173",
          "name": "Wired and Wireless Telecommunications Carriers"
        },
        {
          "code": "51731",
          "name": "Wired and Wireless Telecommunications Carriers"
        },
        {
          "code": "517311",
          "name": "Wired Telecommunications Carriers"
        },
        {
          "code": "517312",
          "name": "Wireless Telecommunications Carriers (except Satellite)"
        },
        {
          "code": "5174",
          "name": "Satellite Telecommunications"
        },
        {
          "code": "51741",
          "name": "Satellite Telecommunications"
        },
        {
          "code": "517410",
          "name": "Satellite Telecommunications"
        },
        {
          "code": "5179",
          "name": "Other Telecommunications"
        },
        {
          "code": "51791",
          "name": "Other Telecommunications"
        },
        {
          "code": "517911",
          "name": "Telecommunications Resellers"
        },
        {
          "code": "517919",
          "name": "All Other Telecommunications"
        },
        {
          "code": "518",
          "name": "Data Processing, Hosting, and Related Services"
        },
        {
          "code": "5182",
          "name": "Data Processing, Hosting, and Related Services"
        },
        {
          "code": "51821",
          "name": "Data Processing, Hosting, and Related Services"
        },
        {
          "code": "518210",
          "name": "Data Processing, Hosting, and Related Services"
        },
        {
          "code": "519",
          "name": "Other Information Services"
        },
        {
          "code": "5191",
          "name": "Other Information Services"
        },
        {
          "code": "51911",
          "name": "News Syndicates"
        },
        {
          "code": "519110",
          "name": "News Syndicates"
        },
        {
          "code": "51912",
          "name": "Libraries and Archives"
        },
        {
          "code": "519120",
          "name": "Libraries and Archives"
        },
        {
          "code": "51913",
          "name": "Internet Publishing and Broadcasting and Web Search Portals"
        },
        {
          "code": "519130",
          "name": "Internet Publishing and Broadcasting and Web Search Portals"
        },
        {
          "code": "51919",
          "name": "All Other Information Services"
        },
        {
          "code": "519190",
          "name": "All Other Information Services"
        },
        {
          "code": "52",
          "name": "Finance and Insurance"
        },
        {
          "code": "521",
          "name": "Monetary Authorities-Central Bank"
        },
        {
          "code": "5211",
          "name": "Monetary Authorities-Central Bank"
        },
        {
          "code": "52111",
          "name": "Monetary Authorities-Central Bank"
        },
        {
          "code": "521110",
          "name": "Monetary Authorities-Central Bank"
        },
        {
          "code": "522",
          "name": "Credit Intermediation and Related Activities"
        },
        {
          "code": "5221",
          "name": "Depository Credit Intermediation"
        },
        {
          "code": "52211",
          "name": "Commercial Banking"
        },
        {
          "code": "522110",
          "name": "Commercial Banking"
        },
        {
          "code": "52212",
          "name": "Savings Institutions"
        },
        {
          "code": "522120",
          "name": "Savings Institutions"
        },
        {
          "code": "52213",
          "name": "Credit Unions"
        },
        {
          "code": "522130",
          "name": "Credit Unions"
        },
        {
          "code": "52219",
          "name": "Other Depository Credit Intermediation"
        },
        {
          "code": "522190",
          "name": "Other Depository Credit Intermediation"
        },
        {
          "code": "5222",
          "name": "Nondepository Credit Intermediation"
        },
        {
          "code": "52221",
          "name": "Credit Card Issuing"
        },
        {
          "code": "522210",
          "name": "Credit Card Issuing"
        },
        {
          "code": "52222",
          "name": "Sales Financing"
        },
        {
          "code": "522220",
          "name": "Sales Financing"
        },
        {
          "code": "52229",
          "name": "Other Nondepository Credit Intermediation"
        },
        {
          "code": "522291",
          "name": "Consumer Lending"
        },
        {
          "code": "522292",
          "name": "Real Estate Credit"
        },
        {
          "code": "522293",
          "name": "International Trade Financing"
        },
        {
          "code": "522294",
          "name": "Secondary Market Financing"
        },
        {
          "code": "522298",
          "name": "All Other Nondepository Credit Intermediation"
        },
        {
          "code": "5223",
          "name": "Activities Related to Credit Intermediation"
        },
        {
          "code": "52231",
          "name": "Mortgage and Nonmortgage Loan Brokers"
        },
        {
          "code": "522310",
          "name": "Mortgage and Nonmortgage Loan Brokers"
        },
        {
          "code": "52232",
          "name": "Financial Transactions Processing, Reserve, and Clearinghouse Activities"
        },
        {
          "code": "522320",
          "name": "Financial Transactions Processing, Reserve, and Clearinghouse Activities"
        },
        {
          "code": "52239",
          "name": "Other Activities Related to Credit Intermediation"
        },
        {
          "code": "522390",
          "name": "Other Activities Related to Credit Intermediation"
        },
        {
          "code": "523",
          "name": "Securities, Commodity Contracts, and Other Financial Investments and Related Activities"
        },
        {
          "code": "5231",
          "name": "Securities and Commodity Contracts Intermediation and Brokerage"
        },
        {
          "code": "52311",
          "name": "Investment Banking and Securities Dealing"
        },
        {
          "code": "523110",
          "name": "Investment Banking and Securities Dealing"
        },
        {
          "code": "52312",
          "name": "Securities Brokerage"
        },
        {
          "code": "523120",
          "name": "Securities Brokerage"
        },
        {
          "code": "52313",
          "name": "Commodity Contracts Dealing"
        },
        {
          "code": "523130",
          "name": "Commodity Contracts Dealing"
        },
        {
          "code": "52314",
          "name": "Commodity Contracts Brokerage"
        },
        {
          "code": "523140",
          "name": "Commodity Contracts Brokerage"
        },
        {
          "code": "5232",
          "name": "Securities and Commodity Exchanges"
        },
        {
          "code": "52321",
          "name": "Securities and Commodity Exchanges"
        },
        {
          "code": "523210",
          "name": "Securities and Commodity Exchanges"
        },
        {
          "code": "5239",
          "name": "Other Financial Investment Activities"
        },
        {
          "code": "52391",
          "name": "Miscellaneous Intermediation"
        },
        {
          "code": "523910",
          "name": "Miscellaneous Intermediation"
        },
        {
          "code": "52392",
          "name": "Portfolio Management"
        },
        {
          "code": "523920",
          "name": "Portfolio Management"
        },
        {
          "code": "52393",
          "name": "Investment Advice"
        },
        {
          "code": "523930",
          "name": "Investment Advice"
        },
        {
          "code": "52399",
          "name": "All Other Financial Investment Activities"
        },
        {
          "code": "523991",
          "name": "Trust, Fiduciary, and Custody Activities"
        },
        {
          "code": "523999",
          "name": "Miscellaneous Financial Investment Activities"
        },
        {
          "code": "524",
          "name": "Insurance Carriers and Related Activities"
        },
        {
          "code": "5241",
          "name": "Insurance Carriers"
        },
        {
          "code": "52411",
          "name": "Direct Life, Health, and Medical Insurance Carriers"
        },
        {
          "code": "524113",
          "name": "Direct Life Insurance Carriers"
        },
        {
          "code": "524114",
          "name": "Direct Health and Medical Insurance Carriers"
        },
        {
          "code": "52412",
          "name": "Direct Insurance (except Life, Health, and Medical) Carriers"
        },
        {
          "code": "524126",
          "name": "Direct Property and Casualty Insurance Carriers"
        },
        {
          "code": "524127",
          "name": "Direct Title Insurance Carriers"
        },
        {
          "code": "524128",
          "name": "Other Direct Insurance (except Life, Health, and Medical) Carriers"
        },
        {
          "code": "52413",
          "name": "Reinsurance Carriers"
        },
        {
          "code": "524130",
          "name": "Reinsurance Carriers"
        },
        {
          "code": "5242",
          "name": "Agencies, Brokerages, and Other Insurance Related Activities"
        },
        {
          "code": "52421",
          "name": "Insurance Agencies and Brokerages"
        },
        {
          "code": "524210",
          "name": "Insurance Agencies and Brokerages"
        },
        {
          "code": "52429",
          "name": "Other Insurance Related Activities"
        },
        {
          "code": "524291",
          "name": "Claims Adjusting"
        },
        {
          "code": "524292",
          "name": "Third Party Administration of Insurance and Pension Funds"
        },
        {
          "code": "524298",
          "name": "All Other Insurance Related Activities"
        },
        {
          "code": "525",
          "name": "Funds, Trusts, and Other Financial Vehicles"
        },
        {
          "code": "5251",
          "name": "Insurance and Employee Benefit Funds"
        },
        {
          "code": "52511",
          "name": "Pension Funds"
        },
        {
          "code": "525110",
          "name": "Pension Funds"
        },
        {
          "code": "52512",
          "name": "Health and Welfare Funds"
        },
        {
          "code": "525120",
          "name": "Health and Welfare Funds"
        },
        {
          "code": "52519",
          "name": "Other Insurance Funds"
        },
        {
          "code": "525190",
          "name": "Other Insurance Funds"
        },
        {
          "code": "5259",
          "name": "Other Investment Pools and Funds"
        },
        {
          "code": "52591",
          "name": "Open-End Investment Funds"
        },
        {
          "code": "525910",
          "name": "Open-End Investment Funds"
        },
        {
          "code": "52592",
          "name": "Trusts, Estates, and Agency Accounts"
        },
        {
          "code": "525920",
          "name": "Trusts, Estates, and Agency Accounts"
        },
        {
          "code": "52599",
          "name": "Other Financial Vehicles"
        },
        {
          "code": "525990",
          "name": "Other Financial Vehicles"
        },
        {
          "code": "53",
          "name": "Real Estate and Rental and Leasing"
        },
        {
          "code": "531",
          "name": "Real Estate"
        },
        {
          "code": "5311",
          "name": "Lessors of Real Estate"
        },
        {
          "code": "53111",
          "name": "Lessors of Residential Buildings and Dwellings"
        },
        {
          "code": "531110",
          "name": "Lessors of Residential Buildings and Dwellings"
        },
        {
          "code": "53112",
          "name": "Lessors of Nonresidential Buildings (except Miniwarehouses)"
        },
        {
          "code": "531120",
          "name": "Lessors of Nonresidential Buildings (except Miniwarehouses)"
        },
        {
          "code": "53113",
          "name": "Lessors of Miniwarehouses and Self-Storage Units"
        },
        {
          "code": "531130",
          "name": "Lessors of Miniwarehouses and Self-Storage Units"
        },
        {
          "code": "53119",
          "name": "Lessors of Other Real Estate Property"
        },
        {
          "code": "531190",
          "name": "Lessors of Other Real Estate Property"
        },
        {
          "code": "5312",
          "name": "Offices of Real Estate Agents and Brokers"
        },
        {
          "code": "53121",
          "name": "Offices of Real Estate Agents and Brokers"
        },
        {
          "code": "531210",
          "name": "Offices of Real Estate Agents and Brokers"
        },
        {
          "code": "5313",
          "name": "Activities Related to Real Estate"
        },
        {
          "code": "53131",
          "name": "Real Estate Property Managers"
        },
        {
          "code": "531311",
          "name": "Residential Property Managers"
        },
        {
          "code": "531312",
          "name": "Nonresidential Property Managers"
        },
        {
          "code": "53132",
          "name": "Offices of Real Estate Appraisers"
        },
        {
          "code": "531320",
          "name": "Offices of Real Estate Appraisers"
        },
        {
          "code": "53139",
          "name": "Other Activities Related to Real Estate"
        },
        {
          "code": "531390",
          "name": "Other Activities Related to Real Estate"
        },
        {
          "code": "532",
          "name": "Rental and Leasing Services"
        },
        {
          "code": "5321",
          "name": "Automotive Equipment Rental and Leasing"
        },
        {
          "code": "53211",
          "name": "Passenger Car Rental and Leasing"
        },
        {
          "code": "532111",
          "name": "Passenger Car Rental"
        },
        {
          "code": "532112",
          "name": "Passenger Car Leasing"
        },
        {
          "code": "53212",
          "name": "Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing"
        },
        {
          "code": "532120",
          "name": "Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing"
        },
        {
          "code": "5322",
          "name": "Consumer Goods Rental"
        },
        {
          "code": "53221",
          "name": "Consumer Electronics and Appliances Rental"
        },
        {
          "code": "532210",
          "name": "Consumer Electronics and Appliances Rental"
        },
        {
          "code": "53228",
          "name": "Other Consumer Goods Rental"
        },
        {
          "code": "532281",
          "name": "Formal Wear and Costume Rental"
        },
        {
          "code": "532282",
          "name": "Video Tape and Disc Rental"
        },
        {
          "code": "532283",
          "name": "Home Health Equipment Rental"
        },
        {
          "code": "532284",
          "name": "Recreational Goods Rental"
        },
        {
          "code": "532289",
          "name": "All Other Consumer Goods Rental"
        },
        {
          "code": "5323",
          "name": "General Rental Centers"
        },
        {
          "code": "53231",
          "name": "General Rental Centers"
        },
        {
          "code": "532310",
          "name": "General Rental Centers"
        },
        {
          "code": "5324",
          "name": "Commercial and Industrial Machinery and Equipment Rental and Leasing"
        },
        {
          "code": "53241",
          "name": "Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing"
        },
        {
          "code": "532411",
          "name": "Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing"
        },
        {
          "code": "532412",
          "name": "Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing"
        },
        {
          "code": "53242",
          "name": "Office Machinery and Equipment Rental and Leasing"
        },
        {
          "code": "532420",
          "name": "Office Machinery and Equipment Rental and Leasing"
        },
        {
          "code": "53249",
          "name": "Other Commercial and Industrial Machinery and Equipment Rental and Leasing"
        },
        {
          "code": "532490",
          "name": "Other Commercial and Industrial Machinery and Equipment Rental and Leasing"
        },
        {
          "code": "533",
          "name": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
        },
        {
          "code": "5331",
          "name": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
        },
        {
          "code": "53311",
          "name": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
        },
        {
          "code": "533110",
          "name": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
        },
        {
          "code": "54",
          "name": "Professional, Scientific, and Technical Services"
        },
        {
          "code": "541",
          "name": "Professional, Scientific, and Technical Services"
        },
        {
          "code": "5411",
          "name": "Legal Services"
        },
        {
          "code": "54111",
          "name": "Offices of Lawyers"
        },
        {
          "code": "541110",
          "name": "Offices of Lawyers"
        },
        {
          "code": "54112",
          "name": "Offices of Notaries"
        },
        {
          "code": "541120",
          "name": "Offices of Notaries"
        },
        {
          "code": "54119",
          "name": "Other Legal Services"
        },
        {
          "code": "541191",
          "name": "Title Abstract and Settlement Offices"
        },
        {
          "code": "541199",
          "name": "All Other Legal Services"
        },
        {
          "code": "5412",
          "name": "Accounting, Tax Preparation, Bookkeeping, and Payroll Services"
        },
        {
          "code": "54121",
          "name": "Accounting, Tax Preparation, Bookkeeping, and Payroll Services"
        },
        {
          "code": "541211",
          "name": "Offices of Certified Public Accountants"
        },
        {
          "code": "541213",
          "name": "Tax Preparation Services"
        },
        {
          "code": "541214",
          "name": "Payroll Services"
        },
        {
          "code": "541219",
          "name": "Other Accounting Services"
        },
        {
          "code": "5413",
          "name": "Architectural, Engineering, and Related Services"
        },
        {
          "code": "54131",
          "name": "Architectural Services"
        },
        {
          "code": "541310",
          "name": "Architectural Services"
        },
        {
          "code": "54132",
          "name": "Landscape Architectural Services"
        },
        {
          "code": "541320",
          "name": "Landscape Architectural Services"
        },
        {
          "code": "54133",
          "name": "Engineering Services"
        },
        {
          "code": "541330",
          "name": "Engineering Services"
        },
        {
          "code": "54134",
          "name": "Drafting Services"
        },
        {
          "code": "541340",
          "name": "Drafting Services"
        },
        {
          "code": "54135",
          "name": "Building Inspection Services"
        },
        {
          "code": "541350",
          "name": "Building Inspection Services"
        },
        {
          "code": "54136",
          "name": "Geophysical Surveying and Mapping Services"
        },
        {
          "code": "541360",
          "name": "Geophysical Surveying and Mapping Services"
        },
        {
          "code": "54137",
          "name": "Surveying and Mapping (except Geophysical) Services"
        },
        {
          "code": "541370",
          "name": "Surveying and Mapping (except Geophysical) Services"
        },
        {
          "code": "54138",
          "name": "Testing Laboratories"
        },
        {
          "code": "541380",
          "name": "Testing Laboratories"
        },
        {
          "code": "5414",
          "name": "Specialized Design Services"
        },
        {
          "code": "54141",
          "name": "Interior Design Services"
        },
        {
          "code": "541410",
          "name": "Interior Design Services"
        },
        {
          "code": "54142",
          "name": "Industrial Design Services"
        },
        {
          "code": "541420",
          "name": "Industrial Design Services"
        },
        {
          "code": "54143",
          "name": "Graphic Design Services"
        },
        {
          "code": "541430",
          "name": "Graphic Design Services"
        },
        {
          "code": "54149",
          "name": "Other Specialized Design Services"
        },
        {
          "code": "541490",
          "name": "Other Specialized Design Services"
        },
        {
          "code": "5415",
          "name": "Computer Systems Design and Related Services"
        },
        {
          "code": "54151",
          "name": "Computer Systems Design and Related Services"
        },
        {
          "code": "541511",
          "name": "Custom Computer Programming Services"
        },
        {
          "code": "541512",
          "name": "Computer Systems Design Services"
        },
        {
          "code": "541513",
          "name": "Computer Facilities Management Services"
        },
        {
          "code": "541519",
          "name": "Other Computer Related Services"
        },
        {
          "code": "5416",
          "name": "Management, Scientific, and Technical Consulting Services"
        },
        {
          "code": "54161",
          "name": "Management Consulting Services"
        },
        {
          "code": "541611",
          "name": "Administrative Management and General Management Consulting Services"
        },
        {
          "code": "541612",
          "name": "Human Resources Consulting Services"
        },
        {
          "code": "541613",
          "name": "Marketing Consulting Services"
        },
        {
          "code": "541614",
          "name": "Process, Physical Distribution, and Logistics Consulting Services"
        },
        {
          "code": "541618",
          "name": "Other Management Consulting Services"
        },
        {
          "code": "54162",
          "name": "Environmental Consulting Services"
        },
        {
          "code": "541620",
          "name": "Environmental Consulting Services"
        },
        {
          "code": "54169",
          "name": "Other Scientific and Technical Consulting Services"
        },
        {
          "code": "541690",
          "name": "Other Scientific and Technical Consulting Services"
        },
        {
          "code": "5417",
          "name": "Scientific Research and Development Services"
        },
        {
          "code": "54171",
          "name": "Research and Development in the Physical, Engineering, and Life Sciences"
        },
        {
          "code": "541713",
          "name": "Research and Development in Nanotechnology"
        },
        {
          "code": "541714",
          "name": "Research and Development in Biotechnology (except Nanobiotechnology)"
        },
        {
          "code": "541715",
          "name": "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)"
        },
        {
          "code": "54172",
          "name": "Research and Development in the Social Sciences and Humanities"
        },
        {
          "code": "541720",
          "name": "Research and Development in the Social Sciences and Humanities"
        },
        {
          "code": "5418",
          "name": "Advertising, Public Relations, and Related Services"
        },
        {
          "code": "54181",
          "name": "Advertising Agencies"
        },
        {
          "code": "541810",
          "name": "Advertising Agencies"
        },
        {
          "code": "54182",
          "name": "Public Relations Agencies"
        },
        {
          "code": "541820",
          "name": "Public Relations Agencies"
        },
        {
          "code": "54183",
          "name": "Media Buying Agencies"
        },
        {
          "code": "541830",
          "name": "Media Buying Agencies"
        },
        {
          "code": "54184",
          "name": "Media Representatives"
        },
        {
          "code": "541840",
          "name": "Media Representatives"
        },
        {
          "code": "54185",
          "name": "Outdoor Advertising"
        },
        {
          "code": "541850",
          "name": "Outdoor Advertising"
        },
        {
          "code": "54186",
          "name": "Direct Mail Advertising"
        },
        {
          "code": "541860",
          "name": "Direct Mail Advertising"
        },
        {
          "code": "54187",
          "name": "Advertising Material Distribution Services"
        },
        {
          "code": "541870",
          "name": "Advertising Material Distribution Services"
        },
        {
          "code": "54189",
          "name": "Other Services Related to Advertising"
        },
        {
          "code": "541890",
          "name": "Other Services Related to Advertising"
        },
        {
          "code": "5419",
          "name": "Other Professional, Scientific, and Technical Services"
        },
        {
          "code": "54191",
          "name": "Marketing Research and Public Opinion Polling"
        },
        {
          "code": "541910",
          "name": "Marketing Research and Public Opinion Polling"
        },
        {
          "code": "54192",
          "name": "Photographic Services"
        },
        {
          "code": "541921",
          "name": "Photography Studios, Portrait"
        },
        {
          "code": "541922",
          "name": "Commercial Photography"
        },
        {
          "code": "54193",
          "name": "Translation and Interpretation Services"
        },
        {
          "code": "541930",
          "name": "Translation and Interpretation Services"
        },
        {
          "code": "54194",
          "name": "Veterinary Services"
        },
        {
          "code": "541940",
          "name": "Veterinary Services"
        },
        {
          "code": "54199",
          "name": "All Other Professional, Scientific, and Technical Services"
        },
        {
          "code": "541990",
          "name": "All Other Professional, Scientific, and Technical Services"
        },
        {
          "code": "55",
          "name": "Management of Companies and Enterprises"
        },
        {
          "code": "551",
          "name": "Management of Companies and Enterprises"
        },
        {
          "code": "5511",
          "name": "Management of Companies and Enterprises"
        },
        {
          "code": "55111",
          "name": "Management of Companies and Enterprises"
        },
        {
          "code": "551111",
          "name": "Offices of Bank Holding Companies"
        },
        {
          "code": "551112",
          "name": "Offices of Other Holding Companies"
        },
        {
          "code": "551114",
          "name": "Corporate, Subsidiary, and Regional Managing Offices"
        },
        {
          "code": "56",
          "name": "Administrative and Support and Waste Management and Remediation Services"
        },
        {
          "code": "561",
          "name": "Administrative and Support Services"
        },
        {
          "code": "5611",
          "name": "Office Administrative Services"
        },
        {
          "code": "56111",
          "name": "Office Administrative Services"
        },
        {
          "code": "561110",
          "name": "Office Administrative Services"
        },
        {
          "code": "5612",
          "name": "Facilities Support Services"
        },
        {
          "code": "56121",
          "name": "Facilities Support Services"
        },
        {
          "code": "561210",
          "name": "Facilities Support Services"
        },
        {
          "code": "5613",
          "name": "Employment Services"
        },
        {
          "code": "56131",
          "name": "Employment Placement Agencies and Executive Search Services"
        },
        {
          "code": "561311",
          "name": "Employment Placement Agencies"
        },
        {
          "code": "561312",
          "name": "Executive Search Services"
        },
        {
          "code": "56132",
          "name": "Temporary Help Services"
        },
        {
          "code": "561320",
          "name": "Temporary Help Services"
        },
        {
          "code": "56133",
          "name": "Professional Employer Organizations"
        },
        {
          "code": "561330",
          "name": "Professional Employer Organizations"
        },
        {
          "code": "5614",
          "name": "Business Support Services"
        },
        {
          "code": "56141",
          "name": "Document Preparation Services"
        },
        {
          "code": "561410",
          "name": "Document Preparation Services"
        },
        {
          "code": "56142",
          "name": "Telephone Call Centers"
        },
        {
          "code": "561421",
          "name": "Telephone Answering Services"
        },
        {
          "code": "561422",
          "name": "Telemarketing Bureaus and Other Contact Centers"
        },
        {
          "code": "56143",
          "name": "Business Service Centers"
        },
        {
          "code": "561431",
          "name": "Private Mail Centers"
        },
        {
          "code": "561439",
          "name": "Other Business Service Centers (including Copy Shops)"
        },
        {
          "code": "56144",
          "name": "Collection Agencies"
        },
        {
          "code": "561440",
          "name": "Collection Agencies"
        },
        {
          "code": "56145",
          "name": "Credit Bureaus"
        },
        {
          "code": "561450",
          "name": "Credit Bureaus"
        },
        {
          "code": "56149",
          "name": "Other Business Support Services"
        },
        {
          "code": "561491",
          "name": "Repossession Services"
        },
        {
          "code": "561492",
          "name": "Court Reporting and Stenotype Services"
        },
        {
          "code": "561499",
          "name": "All Other Business Support Services"
        },
        {
          "code": "5615",
          "name": "Travel Arrangement and Reservation Services"
        },
        {
          "code": "56151",
          "name": "Travel Agencies"
        },
        {
          "code": "561510",
          "name": "Travel Agencies"
        },
        {
          "code": "56152",
          "name": "Tour Operators"
        },
        {
          "code": "561520",
          "name": "Tour Operators"
        },
        {
          "code": "56159",
          "name": "Other Travel Arrangement and Reservation Services"
        },
        {
          "code": "561591",
          "name": "Convention and Visitors Bureaus"
        },
        {
          "code": "561599",
          "name": "All Other Travel Arrangement and Reservation Services"
        },
        {
          "code": "5616",
          "name": "Investigation and Security Services"
        },
        {
          "code": "56161",
          "name": "Investigation, Guard, and Armored Car Services"
        },
        {
          "code": "561611",
          "name": "Investigation Services"
        },
        {
          "code": "561612",
          "name": "Security Guards and Patrol Services"
        },
        {
          "code": "561613",
          "name": "Armored Car Services"
        },
        {
          "code": "56162",
          "name": "Security Systems Services"
        },
        {
          "code": "561621",
          "name": "Security Systems Services (except Locksmiths)"
        },
        {
          "code": "561622",
          "name": "Locksmiths"
        },
        {
          "code": "5617",
          "name": "Services to Buildings and Dwellings"
        },
        {
          "code": "56171",
          "name": "Exterminating and Pest Control Services"
        },
        {
          "code": "561710",
          "name": "Exterminating and Pest Control Services"
        },
        {
          "code": "56172",
          "name": "Janitorial Services"
        },
        {
          "code": "561720",
          "name": "Janitorial Services"
        },
        {
          "code": "56173",
          "name": "Landscaping Services"
        },
        {
          "code": "561730",
          "name": "Landscaping Services"
        },
        {
          "code": "56174",
          "name": "Carpet and Upholstery Cleaning Services"
        },
        {
          "code": "561740",
          "name": "Carpet and Upholstery Cleaning Services"
        },
        {
          "code": "56179",
          "name": "Other Services to Buildings and Dwellings"
        },
        {
          "code": "561790",
          "name": "Other Services to Buildings and Dwellings"
        },
        {
          "code": "5619",
          "name": "Other Support Services"
        },
        {
          "code": "56191",
          "name": "Packaging and Labeling Services"
        },
        {
          "code": "561910",
          "name": "Packaging and Labeling Services"
        },
        {
          "code": "56192",
          "name": "Convention and Trade Show Organizers"
        },
        {
          "code": "561920",
          "name": "Convention and Trade Show Organizers"
        },
        {
          "code": "56199",
          "name": "All Other Support Services"
        },
        {
          "code": "561990",
          "name": "All Other Support Services"
        },
        {
          "code": "562",
          "name": "Waste Management and Remediation Services"
        },
        {
          "code": "5621",
          "name": "Waste Collection"
        },
        {
          "code": "56211",
          "name": "Waste Collection"
        },
        {
          "code": "562111",
          "name": "Solid Waste Collection"
        },
        {
          "code": "562112",
          "name": "Hazardous Waste Collection"
        },
        {
          "code": "562119",
          "name": "Other Waste Collection"
        },
        {
          "code": "5622",
          "name": "Waste Treatment and Disposal"
        },
        {
          "code": "56221",
          "name": "Waste Treatment and Disposal"
        },
        {
          "code": "562211",
          "name": "Hazardous Waste Treatment and Disposal"
        },
        {
          "code": "562212",
          "name": "Solid Waste Landfill"
        },
        {
          "code": "562213",
          "name": "Solid Waste Combustors and Incinerators"
        },
        {
          "code": "562219",
          "name": "Other Nonhazardous Waste Treatment and Disposal"
        },
        {
          "code": "5629",
          "name": "Remediation and Other Waste Management Services"
        },
        {
          "code": "56291",
          "name": "Remediation Services"
        },
        {
          "code": "562910",
          "name": "Remediation Services"
        },
        {
          "code": "56292",
          "name": "Materials Recovery Facilities"
        },
        {
          "code": "562920",
          "name": "Materials Recovery Facilities"
        },
        {
          "code": "56299",
          "name": "All Other Waste Management Services"
        },
        {
          "code": "562991",
          "name": "Septic Tank and Related Services"
        },
        {
          "code": "562998",
          "name": "All Other Miscellaneous Waste Management Services"
        },
        {
          "code": "61",
          "name": "Educational Services"
        },
        {
          "code": "611",
          "name": "Educational Services"
        },
        {
          "code": "6111",
          "name": "Elementary and Secondary Schools"
        },
        {
          "code": "61111",
          "name": "Elementary and Secondary Schools"
        },
        {
          "code": "611110",
          "name": "Elementary and Secondary Schools"
        },
        {
          "code": "6112",
          "name": "Junior Colleges"
        },
        {
          "code": "61121",
          "name": "Junior Colleges"
        },
        {
          "code": "611210",
          "name": "Junior Colleges"
        },
        {
          "code": "6113",
          "name": "Colleges, Universities, and Professional Schools"
        },
        {
          "code": "61131",
          "name": "Colleges, Universities, and Professional Schools"
        },
        {
          "code": "611310",
          "name": "Colleges, Universities, and Professional Schools"
        },
        {
          "code": "6114",
          "name": "Business Schools and Computer and Management Training"
        },
        {
          "code": "61141",
          "name": "Business and Secretarial Schools"
        },
        {
          "code": "611410",
          "name": "Business and Secretarial Schools"
        },
        {
          "code": "61142",
          "name": "Computer Training"
        },
        {
          "code": "611420",
          "name": "Computer Training"
        },
        {
          "code": "61143",
          "name": "Professional and Management Development Training"
        },
        {
          "code": "611430",
          "name": "Professional and Management Development Training"
        },
        {
          "code": "6115",
          "name": "Technical and Trade Schools"
        },
        {
          "code": "61151",
          "name": "Technical and Trade Schools"
        },
        {
          "code": "611511",
          "name": "Cosmetology and Barber Schools"
        },
        {
          "code": "611512",
          "name": "Flight Training"
        },
        {
          "code": "611513",
          "name": "Apprenticeship Training"
        },
        {
          "code": "611519",
          "name": "Other Technical and Trade Schools"
        },
        {
          "code": "6116",
          "name": "Other Schools and Instruction"
        },
        {
          "code": "61161",
          "name": "Fine Arts Schools"
        },
        {
          "code": "611610",
          "name": "Fine Arts Schools"
        },
        {
          "code": "61162",
          "name": "Sports and Recreation Instruction"
        },
        {
          "code": "611620",
          "name": "Sports and Recreation Instruction"
        },
        {
          "code": "61163",
          "name": "Language Schools"
        },
        {
          "code": "611630",
          "name": "Language Schools"
        },
        {
          "code": "61169",
          "name": "All Other Schools and Instruction"
        },
        {
          "code": "611691",
          "name": "Exam Preparation and Tutoring"
        },
        {
          "code": "611692",
          "name": "Automobile Driving Schools"
        },
        {
          "code": "611699",
          "name": "All Other Miscellaneous Schools and Instruction"
        },
        {
          "code": "6117",
          "name": "Educational Support Services"
        },
        {
          "code": "61171",
          "name": "Educational Support Services"
        },
        {
          "code": "611710",
          "name": "Educational Support Services"
        },
        {
          "code": "62",
          "name": "Health Care and Social Assistance"
        },
        {
          "code": "621",
          "name": "Ambulatory Health Care Services"
        },
        {
          "code": "6211",
          "name": "Offices of Physicians"
        },
        {
          "code": "62111",
          "name": "Offices of Physicians"
        },
        {
          "code": "621111",
          "name": "Offices of Physicians (except Mental Health Specialists)"
        },
        {
          "code": "621112",
          "name": "Offices of Physicians, Mental Health Specialists"
        },
        {
          "code": "6212",
          "name": "Offices of Dentists"
        },
        {
          "code": "62121",
          "name": "Offices of Dentists"
        },
        {
          "code": "621210",
          "name": "Offices of Dentists"
        },
        {
          "code": "6213",
          "name": "Offices of Other Health Practitioners"
        },
        {
          "code": "62131",
          "name": "Offices of Chiropractors"
        },
        {
          "code": "621310",
          "name": "Offices of Chiropractors"
        },
        {
          "code": "62132",
          "name": "Offices of Optometrists"
        },
        {
          "code": "621320",
          "name": "Offices of Optometrists"
        },
        {
          "code": "62133",
          "name": "Offices of Mental Health Practitioners (except Physicians)"
        },
        {
          "code": "621330",
          "name": "Offices of Mental Health Practitioners (except Physicians)"
        },
        {
          "code": "62134",
          "name": "Offices of Physical, Occupational and Speech Therapists, and Audiologists"
        },
        {
          "code": "621340",
          "name": "Offices of Physical, Occupational and Speech Therapists, and Audiologists"
        },
        {
          "code": "62139",
          "name": "Offices of All Other Health Practitioners"
        },
        {
          "code": "621391",
          "name": "Offices of Podiatrists"
        },
        {
          "code": "621399",
          "name": "Offices of All Other Miscellaneous Health Practitioners"
        },
        {
          "code": "6214",
          "name": "Outpatient Care Centers"
        },
        {
          "code": "62141",
          "name": "Family Planning Centers"
        },
        {
          "code": "621410",
          "name": "Family Planning Centers"
        },
        {
          "code": "62142",
          "name": "Outpatient Mental Health and Substance Abuse Centers"
        },
        {
          "code": "621420",
          "name": "Outpatient Mental Health and Substance Abuse Centers"
        },
        {
          "code": "62149",
          "name": "Other Outpatient Care Centers"
        },
        {
          "code": "621491",
          "name": "HMO Medical Centers"
        },
        {
          "code": "621492",
          "name": "Kidney Dialysis Centers"
        },
        {
          "code": "621493",
          "name": "Freestanding Ambulatory Surgical and Emergency Centers"
        },
        {
          "code": "621498",
          "name": "All Other Outpatient Care Centers"
        },
        {
          "code": "6215",
          "name": "Medical and Diagnostic Laboratories"
        },
        {
          "code": "62151",
          "name": "Medical and Diagnostic Laboratories"
        },
        {
          "code": "621511",
          "name": "Medical Laboratories"
        },
        {
          "code": "621512",
          "name": "Diagnostic Imaging Centers"
        },
        {
          "code": "6216",
          "name": "Home Health Care Services"
        },
        {
          "code": "62161",
          "name": "Home Health Care Services"
        },
        {
          "code": "621610",
          "name": "Home Health Care Services"
        },
        {
          "code": "6219",
          "name": "Other Ambulatory Health Care Services"
        },
        {
          "code": "62191",
          "name": "Ambulance Services"
        },
        {
          "code": "621910",
          "name": "Ambulance Services"
        },
        {
          "code": "62199",
          "name": "All Other Ambulatory Health Care Services"
        },
        {
          "code": "621991",
          "name": "Blood and Organ Banks"
        },
        {
          "code": "621999",
          "name": "All Other Miscellaneous Ambulatory Health Care Services"
        },
        {
          "code": "622",
          "name": "Hospitals"
        },
        {
          "code": "6221",
          "name": "General Medical and Surgical Hospitals"
        },
        {
          "code": "62211",
          "name": "General Medical and Surgical Hospitals"
        },
        {
          "code": "622110",
          "name": "General Medical and Surgical Hospitals"
        },
        {
          "code": "6222",
          "name": "Psychiatric and Substance Abuse Hospitals"
        },
        {
          "code": "62221",
          "name": "Psychiatric and Substance Abuse Hospitals"
        },
        {
          "code": "622210",
          "name": "Psychiatric and Substance Abuse Hospitals"
        },
        {
          "code": "6223",
          "name": "Specialty (except Psychiatric and Substance Abuse) Hospitals"
        },
        {
          "code": "62231",
          "name": "Specialty (except Psychiatric and Substance Abuse) Hospitals"
        },
        {
          "code": "622310",
          "name": "Specialty (except Psychiatric and Substance Abuse) Hospitals"
        },
        {
          "code": "623",
          "name": "Nursing and Residential Care Facilities"
        },
        {
          "code": "6231",
          "name": "Nursing Care Facilities (Skilled Nursing Facilities)"
        },
        {
          "code": "62311",
          "name": "Nursing Care Facilities (Skilled Nursing Facilities)"
        },
        {
          "code": "623110",
          "name": "Nursing Care Facilities (Skilled Nursing Facilities)"
        },
        {
          "code": "6232",
          "name": "Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities"
        },
        {
          "code": "62321",
          "name": "Residential Intellectual and Developmental Disability Facilities"
        },
        {
          "code": "623210",
          "name": "Residential Intellectual and Developmental Disability Facilities"
        },
        {
          "code": "62322",
          "name": "Residential Mental Health and Substance Abuse Facilities"
        },
        {
          "code": "623220",
          "name": "Residential Mental Health and Substance Abuse Facilities"
        },
        {
          "code": "6233",
          "name": "Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly"
        },
        {
          "code": "62331",
          "name": "Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly"
        },
        {
          "code": "623311",
          "name": "Continuing Care Retirement Communities"
        },
        {
          "code": "623312",
          "name": "Assisted Living Facilities for the Elderly"
        },
        {
          "code": "6239",
          "name": "Other Residential Care Facilities"
        },
        {
          "code": "62399",
          "name": "Other Residential Care Facilities"
        },
        {
          "code": "623990",
          "name": "Other Residential Care Facilities"
        },
        {
          "code": "624",
          "name": "Social Assistance"
        },
        {
          "code": "6241",
          "name": "Individual and Family Services"
        },
        {
          "code": "62411",
          "name": "Child and Youth Services"
        },
        {
          "code": "624110",
          "name": "Child and Youth Services"
        },
        {
          "code": "62412",
          "name": "Services for the Elderly and Persons with Disabilities"
        },
        {
          "code": "624120",
          "name": "Services for the Elderly and Persons with Disabilities"
        },
        {
          "code": "62419",
          "name": "Other Individual and Family Services"
        },
        {
          "code": "624190",
          "name": "Other Individual and Family Services"
        },
        {
          "code": "6242",
          "name": "Community Food and Housing, and Emergency and Other Relief Services"
        },
        {
          "code": "62421",
          "name": "Community Food Services"
        },
        {
          "code": "624210",
          "name": "Community Food Services"
        },
        {
          "code": "62422",
          "name": "Community Housing Services"
        },
        {
          "code": "624221",
          "name": "Temporary Shelters"
        },
        {
          "code": "624229",
          "name": "Other Community Housing Services"
        },
        {
          "code": "62423",
          "name": "Emergency and Other Relief Services"
        },
        {
          "code": "624230",
          "name": "Emergency and Other Relief Services"
        },
        {
          "code": "6243",
          "name": "Vocational Rehabilitation Services"
        },
        {
          "code": "62431",
          "name": "Vocational Rehabilitation Services"
        },
        {
          "code": "624310",
          "name": "Vocational Rehabilitation Services"
        },
        {
          "code": "6244",
          "name": "Child Day Care Services"
        },
        {
          "code": "62441",
          "name": "Child Day Care Services"
        },
        {
          "code": "624410",
          "name": "Child Day Care Services"
        },
        {
          "code": "71",
          "name": "Arts, Entertainment, and Recreation"
        },
        {
          "code": "711",
          "name": "Performing Arts, Spectator Sports, and Related Industries"
        },
        {
          "code": "7111",
          "name": "Performing Arts Companies"
        },
        {
          "code": "71111",
          "name": "Theater Companies and Dinner Theaters"
        },
        {
          "code": "711110",
          "name": "Theater Companies and Dinner Theaters"
        },
        {
          "code": "71112",
          "name": "Dance Companies"
        },
        {
          "code": "711120",
          "name": "Dance Companies"
        },
        {
          "code": "71113",
          "name": "Musical Groups and Artists"
        },
        {
          "code": "711130",
          "name": "Musical Groups and Artists"
        },
        {
          "code": "71119",
          "name": "Other Performing Arts Companies"
        },
        {
          "code": "711190",
          "name": "Other Performing Arts Companies"
        },
        {
          "code": "7112",
          "name": "Spectator Sports"
        },
        {
          "code": "71121",
          "name": "Spectator Sports"
        },
        {
          "code": "711211",
          "name": "Sports Teams and Clubs"
        },
        {
          "code": "711212",
          "name": "Racetracks"
        },
        {
          "code": "711219",
          "name": "Other Spectator Sports"
        },
        {
          "code": "7113",
          "name": "Promoters of Performing Arts, Sports, and Similar Events"
        },
        {
          "code": "71131",
          "name": "Promoters of Performing Arts, Sports, and Similar Events with Facilities"
        },
        {
          "code": "711310",
          "name": "Promoters of Performing Arts, Sports, and Similar Events with Facilities"
        },
        {
          "code": "71132",
          "name": "Promoters of Performing Arts, Sports, and Similar Events without Facilities"
        },
        {
          "code": "711320",
          "name": "Promoters of Performing Arts, Sports, and Similar Events without Facilities"
        },
        {
          "code": "7114",
          "name": "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"
        },
        {
          "code": "71141",
          "name": "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"
        },
        {
          "code": "711410",
          "name": "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"
        },
        {
          "code": "7115",
          "name": "Independent Artists, Writers, and Performers"
        },
        {
          "code": "71151",
          "name": "Independent Artists, Writers, and Performers"
        },
        {
          "code": "711510",
          "name": "Independent Artists, Writers, and Performers"
        },
        {
          "code": "712",
          "name": "Museums, Historical Sites, and Similar Institutions"
        },
        {
          "code": "7121",
          "name": "Museums, Historical Sites, and Similar Institutions"
        },
        {
          "code": "71211",
          "name": "Museums"
        },
        {
          "code": "712110",
          "name": "Museums"
        },
        {
          "code": "71212",
          "name": "Historical Sites"
        },
        {
          "code": "712120",
          "name": "Historical Sites"
        },
        {
          "code": "71213",
          "name": "Zoos and Botanical Gardens"
        },
        {
          "code": "712130",
          "name": "Zoos and Botanical Gardens"
        },
        {
          "code": "71219",
          "name": "Nature Parks and Other Similar Institutions"
        },
        {
          "code": "712190",
          "name": "Nature Parks and Other Similar Institutions"
        },
        {
          "code": "713",
          "name": "Amusement, Gambling, and Recreation Industries"
        },
        {
          "code": "7131",
          "name": "Amusement Parks and Arcades"
        },
        {
          "code": "71311",
          "name": "Amusement and Theme Parks"
        },
        {
          "code": "713110",
          "name": "Amusement and Theme Parks"
        },
        {
          "code": "71312",
          "name": "Amusement Arcades"
        },
        {
          "code": "713120",
          "name": "Amusement Arcades"
        },
        {
          "code": "7132",
          "name": "Gambling Industries"
        },
        {
          "code": "71321",
          "name": "Casinos (except Casino Hotels)"
        },
        {
          "code": "713210",
          "name": "Casinos (except Casino Hotels)"
        },
        {
          "code": "71329",
          "name": "Other Gambling Industries"
        },
        {
          "code": "713290",
          "name": "Other Gambling Industries"
        },
        {
          "code": "7139",
          "name": "Other Amusement and Recreation Industries"
        },
        {
          "code": "71391",
          "name": "Golf Courses and Country Clubs"
        },
        {
          "code": "713910",
          "name": "Golf Courses and Country Clubs"
        },
        {
          "code": "71392",
          "name": "Skiing Facilities"
        },
        {
          "code": "713920",
          "name": "Skiing Facilities"
        },
        {
          "code": "71393",
          "name": "Marinas"
        },
        {
          "code": "713930",
          "name": "Marinas"
        },
        {
          "code": "71394",
          "name": "Fitness and Recreational Sports Centers"
        },
        {
          "code": "713940",
          "name": "Fitness and Recreational Sports Centers"
        },
        {
          "code": "71395",
          "name": "Bowling Centers"
        },
        {
          "code": "713950",
          "name": "Bowling Centers"
        },
        {
          "code": "71399",
          "name": "All Other Amusement and Recreation Industries"
        },
        {
          "code": "713990",
          "name": "All Other Amusement and Recreation Industries"
        },
        {
          "code": "72",
          "name": "Accommodation and Food Services"
        },
        {
          "code": "721",
          "name": "Accommodation"
        },
        {
          "code": "7211",
          "name": "Traveler Accommodation"
        },
        {
          "code": "72111",
          "name": "Hotels (except Casino Hotels) and Motels"
        },
        {
          "code": "721110",
          "name": "Hotels (except Casino Hotels) and Motels"
        },
        {
          "code": "72112",
          "name": "Casino Hotels"
        },
        {
          "code": "721120",
          "name": "Casino Hotels"
        },
        {
          "code": "72119",
          "name": "Other Traveler Accommodation"
        },
        {
          "code": "721191",
          "name": "Bed-and-Breakfast Inns"
        },
        {
          "code": "721199",
          "name": "All Other Traveler Accommodation"
        },
        {
          "code": "7212",
          "name": "RV (Recreational Vehicle) Parks and Recreational Camps"
        },
        {
          "code": "72121",
          "name": "RV (Recreational Vehicle) Parks and Recreational Camps"
        },
        {
          "code": "721211",
          "name": "RV (Recreational Vehicle) Parks and Campgrounds"
        },
        {
          "code": "721214",
          "name": "Recreational and Vacation Camps (except Campgrounds)"
        },
        {
          "code": "7213",
          "name": "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
        },
        {
          "code": "72131",
          "name": "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
        },
        {
          "code": "721310",
          "name": "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
        },
        {
          "code": "722",
          "name": "Food Services and Drinking Places"
        },
        {
          "code": "7223",
          "name": "Special Food Services"
        },
        {
          "code": "72231",
          "name": "Food Service Contractors"
        },
        {
          "code": "722310",
          "name": "Food Service Contractors"
        },
        {
          "code": "72232",
          "name": "Caterers"
        },
        {
          "code": "722320",
          "name": "Caterers"
        },
        {
          "code": "72233",
          "name": "Mobile Food Services"
        },
        {
          "code": "722330",
          "name": "Mobile Food Services"
        },
        {
          "code": "7224",
          "name": "Drinking Places (Alcoholic Beverages)"
        },
        {
          "code": "72241",
          "name": "Drinking Places (Alcoholic Beverages)"
        },
        {
          "code": "722410",
          "name": "Drinking Places (Alcoholic Beverages)"
        },
        {
          "code": "7225",
          "name": "Restaurants and Other Eating Places"
        },
        {
          "code": "72251",
          "name": "Restaurants and Other Eating Places"
        },
        {
          "code": "722511",
          "name": "Full-Service Restaurants"
        },
        {
          "code": "722513",
          "name": "Limited-Service Restaurants"
        },
        {
          "code": "722514",
          "name": "Cafeterias, Grill Buffets, and Buffets"
        },
        {
          "code": "722515",
          "name": "Snack and Nonalcoholic Beverage Bars"
        },
        {
          "code": "81",
          "name": "Other Services (except Public Administration)"
        },
        {
          "code": "811",
          "name": "Repair and Maintenance"
        },
        {
          "code": "8111",
          "name": "Automotive Repair and Maintenance"
        },
        {
          "code": "81111",
          "name": "Automotive Mechanical and Electrical Repair and Maintenance"
        },
        {
          "code": "811111",
          "name": "General Automotive Repair"
        },
        {
          "code": "811112",
          "name": "Automotive Exhaust System Repair"
        },
        {
          "code": "811113",
          "name": "Automotive Transmission Repair"
        },
        {
          "code": "811118",
          "name": "Other Automotive Mechanical and Electrical Repair and Maintenance"
        },
        {
          "code": "81112",
          "name": "Automotive Body, Paint, Interior, and Glass Repair"
        },
        {
          "code": "811121",
          "name": "Automotive Body, Paint, and Interior Repair and Maintenance"
        },
        {
          "code": "811122",
          "name": "Automotive Glass Replacement Shops"
        },
        {
          "code": "81119",
          "name": "Other Automotive Repair and Maintenance"
        },
        {
          "code": "811191",
          "name": "Automotive Oil Change and Lubrication Shops"
        },
        {
          "code": "811192",
          "name": "Car Washes"
        },
        {
          "code": "811198",
          "name": "All Other Automotive Repair and Maintenance"
        },
        {
          "code": "8112",
          "name": "Electronic and Precision Equipment Repair and Maintenance"
        },
        {
          "code": "81121",
          "name": "Electronic and Precision Equipment Repair and Maintenance"
        },
        {
          "code": "811211",
          "name": "Consumer Electronics Repair and Maintenance"
        },
        {
          "code": "811212",
          "name": "Computer and Office Machine Repair and Maintenance"
        },
        {
          "code": "811213",
          "name": "Communication Equipment Repair and Maintenance"
        },
        {
          "code": "811219",
          "name": "Other Electronic and Precision Equipment Repair and Maintenance"
        },
        {
          "code": "8113",
          "name": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
        },
        {
          "code": "81131",
          "name": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
        },
        {
          "code": "811310",
          "name": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
        },
        {
          "code": "8114",
          "name": "Personal and Household Goods Repair and Maintenance"
        },
        {
          "code": "81141",
          "name": "Home and Garden Equipment and Appliance Repair and Maintenance"
        },
        {
          "code": "811411",
          "name": "Home and Garden Equipment Repair and Maintenance"
        },
        {
          "code": "811412",
          "name": "Appliance Repair and Maintenance"
        },
        {
          "code": "81142",
          "name": "Reupholstery and Furniture Repair"
        },
        {
          "code": "811420",
          "name": "Reupholstery and Furniture Repair"
        },
        {
          "code": "81143",
          "name": "Footwear and Leather Goods Repair"
        },
        {
          "code": "811430",
          "name": "Footwear and Leather Goods Repair"
        },
        {
          "code": "81149",
          "name": "Other Personal and Household Goods Repair and Maintenance"
        },
        {
          "code": "811490",
          "name": "Other Personal and Household Goods Repair and Maintenance"
        },
        {
          "code": "812",
          "name": "Personal and Laundry Services"
        },
        {
          "code": "8121",
          "name": "Personal Care Services"
        },
        {
          "code": "81211",
          "name": "Hair, Nail, and Skin Care Services"
        },
        {
          "code": "812111",
          "name": "Barber Shops"
        },
        {
          "code": "812112",
          "name": "Beauty Salons"
        },
        {
          "code": "812113",
          "name": "Nail Salons"
        },
        {
          "code": "81219",
          "name": "Other Personal Care Services"
        },
        {
          "code": "812191",
          "name": "Diet and Weight Reducing Centers"
        },
        {
          "code": "812199",
          "name": "Other Personal Care Services"
        },
        {
          "code": "8122",
          "name": "Death Care Services"
        },
        {
          "code": "81221",
          "name": "Funeral Homes and Funeral Services"
        },
        {
          "code": "812210",
          "name": "Funeral Homes and Funeral Services"
        },
        {
          "code": "81222",
          "name": "Cemeteries and Crematories"
        },
        {
          "code": "812220",
          "name": "Cemeteries and Crematories"
        },
        {
          "code": "8123",
          "name": "Drycleaning and Laundry Services"
        },
        {
          "code": "81231",
          "name": "Coin-Operated Laundries and Drycleaners"
        },
        {
          "code": "812310",
          "name": "Coin-Operated Laundries and Drycleaners"
        },
        {
          "code": "81232",
          "name": "Drycleaning and Laundry Services (except Coin-Operated)"
        },
        {
          "code": "812320",
          "name": "Drycleaning and Laundry Services (except Coin-Operated)"
        },
        {
          "code": "81233",
          "name": "Linen and Uniform Supply"
        },
        {
          "code": "812331",
          "name": "Linen Supply"
        },
        {
          "code": "812332",
          "name": "Industrial Launderers"
        },
        {
          "code": "8129",
          "name": "Other Personal Services"
        },
        {
          "code": "81291",
          "name": "Pet Care (except Veterinary) Services"
        },
        {
          "code": "812910",
          "name": "Pet Care (except Veterinary) Services"
        },
        {
          "code": "81292",
          "name": "Photofinishing"
        },
        {
          "code": "812921",
          "name": "Photofinishing Laboratories (except One-Hour)"
        },
        {
          "code": "812922",
          "name": "One-Hour Photofinishing"
        },
        {
          "code": "81293",
          "name": "Parking Lots and Garages"
        },
        {
          "code": "812930",
          "name": "Parking Lots and Garages"
        },
        {
          "code": "81299",
          "name": "All Other Personal Services"
        },
        {
          "code": "812990",
          "name": "All Other Personal Services"
        },
        {
          "code": "813",
          "name": "Religious, Grantmaking, Civic, Professional, and Similar Organizations"
        },
        {
          "code": "8131",
          "name": "Religious Organizations"
        },
        {
          "code": "81311",
          "name": "Religious Organizations"
        },
        {
          "code": "813110",
          "name": "Religious Organizations"
        },
        {
          "code": "8132",
          "name": "Grantmaking and Giving Services"
        },
        {
          "code": "81321",
          "name": "Grantmaking and Giving Services"
        },
        {
          "code": "813211",
          "name": "Grantmaking Foundations"
        },
        {
          "code": "813212",
          "name": "Voluntary Health Organizations"
        },
        {
          "code": "813219",
          "name": "Other Grantmaking and Giving Services"
        },
        {
          "code": "8133",
          "name": "Social Advocacy Organizations"
        },
        {
          "code": "81331",
          "name": "Social Advocacy Organizations"
        },
        {
          "code": "813311",
          "name": "Human Rights Organizations"
        },
        {
          "code": "813312",
          "name": "Environment, Conservation and Wildlife Organizations"
        },
        {
          "code": "813319",
          "name": "Other Social Advocacy Organizations"
        },
        {
          "code": "8134",
          "name": "Civic and Social Organizations"
        },
        {
          "code": "81341",
          "name": "Civic and Social Organizations"
        },
        {
          "code": "813410",
          "name": "Civic and Social Organizations"
        },
        {
          "code": "8139",
          "name": "Business, Professional, Labor, Political, and Similar Organizations"
        },
        {
          "code": "81391",
          "name": "Business Associations"
        },
        {
          "code": "813910",
          "name": "Business Associations"
        },
        {
          "code": "81392",
          "name": "Professional Organizations"
        },
        {
          "code": "813920",
          "name": "Professional Organizations"
        },
        {
          "code": "81393",
          "name": "Labor Unions and Similar Labor Organizations"
        },
        {
          "code": "813930",
          "name": "Labor Unions and Similar Labor Organizations"
        },
        {
          "code": "81394",
          "name": "Political Organizations"
        },
        {
          "code": "813940",
          "name": "Political Organizations"
        },
        {
          "code": "81399",
          "name": "Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)"
        },
        {
          "code": "813990",
          "name": "Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)"
        },
        {
          "code": "814",
          "name": "Private Households"
        },
        {
          "code": "8141",
          "name": "Private Households"
        },
        {
          "code": "81411",
          "name": "Private Households"
        },
        {
          "code": "814110",
          "name": "Private Households"
        },
        {
          "code": "92",
          "name": "Public Administration"
        },
        {
          "code": "921",
          "name": "Executive, Legislative, and Other General Government Support"
        },
        {
          "code": "9211",
          "name": "Executive, Legislative, and Other General Government Support"
        },
        {
          "code": "92111",
          "name": "Executive Offices"
        },
        {
          "code": "921110",
          "name": "Executive Offices"
        },
        {
          "code": "92112",
          "name": "Legislative Bodies"
        },
        {
          "code": "921120",
          "name": "Legislative Bodies"
        },
        {
          "code": "92113",
          "name": "Public Finance Activities"
        },
        {
          "code": "921130",
          "name": "Public Finance Activities"
        },
        {
          "code": "92114",
          "name": "Executive and Legislative Offices, Combined"
        },
        {
          "code": "921140",
          "name": "Executive and Legislative Offices, Combined"
        },
        {
          "code": "92115",
          "name": "American Indian and Alaska Native Tribal Governments"
        },
        {
          "code": "921150",
          "name": "American Indian and Alaska Native Tribal Governments"
        },
        {
          "code": "92119",
          "name": "Other General Government Support"
        },
        {
          "code": "921190",
          "name": "Other General Government Support"
        },
        {
          "code": "922",
          "name": "Justice, Public Order, and Safety Activities"
        },
        {
          "code": "9221",
          "name": "Justice, Public Order, and Safety Activities"
        },
        {
          "code": "92211",
          "name": "Courts"
        },
        {
          "code": "922110",
          "name": "Courts"
        },
        {
          "code": "92212",
          "name": "Police Protection"
        },
        {
          "code": "922120",
          "name": "Police Protection"
        },
        {
          "code": "92213",
          "name": "Legal Counsel and Prosecution"
        },
        {
          "code": "922130",
          "name": "Legal Counsel and Prosecution"
        },
        {
          "code": "92214",
          "name": "Correctional Institutions"
        },
        {
          "code": "922140",
          "name": "Correctional Institutions"
        },
        {
          "code": "92215",
          "name": "Parole Offices and Probation Offices"
        },
        {
          "code": "922150",
          "name": "Parole Offices and Probation Offices"
        },
        {
          "code": "92216",
          "name": "Fire Protection"
        },
        {
          "code": "922160",
          "name": "Fire Protection"
        },
        {
          "code": "92219",
          "name": "Other Justice, Public Order, and Safety Activities"
        },
        {
          "code": "922190",
          "name": "Other Justice, Public Order, and Safety Activities"
        },
        {
          "code": "923",
          "name": "Administration of Human Resource Programs"
        },
        {
          "code": "9231",
          "name": "Administration of Human Resource Programs"
        },
        {
          "code": "92311",
          "name": "Administration of Education Programs"
        },
        {
          "code": "923110",
          "name": "Administration of Education Programs"
        },
        {
          "code": "92312",
          "name": "Administration of Public Health Programs"
        },
        {
          "code": "923120",
          "name": "Administration of Public Health Programs"
        },
        {
          "code": "92313",
          "name": "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
        },
        {
          "code": "923130",
          "name": "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
        },
        {
          "code": "92314",
          "name": "Administration of Veterans' Affairs"
        },
        {
          "code": "923140",
          "name": "Administration of Veterans' Affairs"
        },
        {
          "code": "924",
          "name": "Administration of Environmental Quality Programs"
        },
        {
          "code": "9241",
          "name": "Administration of Environmental Quality Programs"
        },
        {
          "code": "92411",
          "name": "Administration of Air and Water Resource and Solid Waste Management Programs"
        },
        {
          "code": "924110",
          "name": "Administration of Air and Water Resource and Solid Waste Management Programs"
        },
        {
          "code": "92412",
          "name": "Administration of Conservation Programs"
        },
        {
          "code": "924120",
          "name": "Administration of Conservation Programs"
        },
        {
          "code": "925",
          "name": "Administration of Housing Programs, Urban Planning, and Community Development"
        },
        {
          "code": "9251",
          "name": "Administration of Housing Programs, Urban Planning, and Community Development"
        },
        {
          "code": "92511",
          "name": "Administration of Housing Programs"
        },
        {
          "code": "925110",
          "name": "Administration of Housing Programs"
        },
        {
          "code": "92512",
          "name": "Administration of Urban Planning and Community and Rural Development"
        },
        {
          "code": "925120",
          "name": "Administration of Urban Planning and Community and Rural Development"
        },
        {
          "code": "926",
          "name": "Administration of Economic Programs"
        },
        {
          "code": "9261",
          "name": "Administration of Economic Programs"
        },
        {
          "code": "92611",
          "name": "Administration of General Economic Programs"
        },
        {
          "code": "926110",
          "name": "Administration of General Economic Programs"
        },
        {
          "code": "92612",
          "name": "Regulation and Administration of Transportation Programs"
        },
        {
          "code": "926120",
          "name": "Regulation and Administration of Transportation Programs"
        },
        {
          "code": "92613",
          "name": "Regulation and Administration of Communications, Electric, Gas, and Other Utilities"
        },
        {
          "code": "926130",
          "name": "Regulation and Administration of Communications, Electric, Gas, and Other Utilities"
        },
        {
          "code": "92614",
          "name": "Regulation of Agricultural Marketing and Commodities"
        },
        {
          "code": "926140",
          "name": "Regulation of Agricultural Marketing and Commodities"
        },
        {
          "code": "92615",
          "name": "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors"
        },
        {
          "code": "926150",
          "name": "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors"
        },
        {
          "code": "927",
          "name": "Space Research and Technology"
        },
        {
          "code": "9271",
          "name": "Space Research and Technology"
        },
        {
          "code": "92711",
          "name": "Space Research and Technology"
        },
        {
          "code": "927110",
          "name": "Space Research and Technology"
        },
        {
          "code": "928",
          "name": "National Security and International Affairs"
        },
        {
          "code": "9281",
          "name": "National Security and International Affairs"
        },
        {
          "code": "92811",
          "name": "National Security"
        },
        {
          "code": "928110",
          "name": "National Security"
        },
        {
          "code": "92812",
          "name": "International Affairs"
        },
        {
          "code": "928120",
          "name": "International Affairs"
        }
]

/*
naicsCodes.forEach((item) => {
    item.name = item['code'] + ' - ' + item['name'];
});
*/

export default naicsCodes