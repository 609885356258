import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class JsonbListTransform extends Transform {
  deserialize(serialized: any) {
    if (!serialized || !Array.isArray(serialized)) {
      return [];
    }

    return serialized;
  }

  serialize(deserialized: any) {
    return deserialized;
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'jsonb-list': JsonbListTransform;
  }
}
