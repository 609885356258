import DS from 'ember-data';

const CommaDelimitedObject = DS.Transform.extend({
  deserialize(serialized: any) {
    if (serialized) {
      let keys = serialized.split(',');
      if (keys.length) {
        let result: any = {};
        for (let key of keys) {
          result[key] = true;
        }
        return result;
      }
    }

    return undefined;
  },

  serialize(deserialized: any) {
    if (deserialized) {
      let result = [];
      for (let key in deserialized) {
        if (deserialized[key] === true) {
          result.push(key);
        }
      }
      if (result.length) {
        return result.join(',');
      }
    }
    return '';
  }
});

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    //@ts-ignore
    'comma-delimited-object': CommaDelimitedObject;
  }
}

export default CommaDelimitedObject;
