import { helper } from "@ember/component/helper";
import Ember from 'ember';

const ProjectTypes = {
  "fund": ["fund", "mandate", "advisory", "co-invest", "secondary"],
  "fund-raise": ["fund raise", "fundraise"]
}

export function IsProjectType([type, checkType]) {
  try {
    return ProjectTypes[type].includes(checkType.toLowerCase());
  } catch (e) {
    return false;
  }
}

export default helper(IsProjectType);