import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from "@ember/component";

@classic
export default class Remove extends Component {
  @service
  appEvents;

  @service
  store;

  @action
  doRemoveFromWatchList() {
    this.get('watchList').destroyRecord().then(() => {
      this.sendAction('success', null);
      this.get('appEvents').publish('watchlistChanged');
    });
  }
}