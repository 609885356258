import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default Controller.extend({
  session: service(),
  docker: service('docker-item'),
  router: service('router'),
  actions: {
    login() {
      this.get('router').transitionTo('login');
      //this.get('session').authenticate('authenticator:torii', 'si');
    },
    signup() {
      this.transitionToRoute('signup');
    }
  }
});
