import { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import AclPermission from 'cing-app/models/acl-permission';
import AclRolePermission from 'cing-app/models/acl-role-permission';
// import TSMixin from 'cing-app/mixins/ts-mixin';
import { exportable } from 'cing-app/utils/exportable';

const AclRoleValidations = buildValidations({
  //'type': validator('presence', true),
  name: validator('presence', true),
  //'description': validator('presence', true),
});

export default class AclRole extends Abstract.extend(AclRoleValidations) {
  @exportable()
  @attr('string')
  declare name: string;
  @attr('string') declare description?: string;

  //@ts-ignore
  @hasMany('acl-role-permission')
  declare aclRolePermissions: AsyncHasMany<AclRolePermission>;
  //@ts-ignore
  @hasMany('acl-permission')
  declare aclPermissions: AsyncHasMany<AclPermission>;
  //aclEntityRoles: DS.hasMany('acl-entity-role'),
  //aclUserRoles: DS.hasMany('acl-user-role') // not implemented yet!
}

// TSMixin(AclRole, [AclRoleValidations]);

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'acl-role': AclRole;
  }
}
