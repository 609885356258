import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
import { ABColumnFormat } from './appbuilder-link-column';

export default class ProjectTabSettingsContactsField extends Fragment {
  @attr('string')
  declare propertyName: string;
  @attr('boolean')
  declare isFromExtended: boolean;
  @attr('string')
  declare label: string;
  @attr('boolean', { defaultValue: false })
  declare defaultVisible: boolean;
  @attr('boolean', { defaultValue: false })
  declare simpleFilter: boolean;
  @attr('boolean', { defaultValue: false })
  declare advancedFilter: boolean;
  @attr('boolean', { defaultValue: false })
  declare enableCharts: boolean;
  @attr('boolean', { defaultValue: false })
  declare sorting: boolean;
  @attr('number')
  declare sortOrder?: number;
  @attr('boolean')
  declare defaultAscending?: boolean;
  @attr('string', { defaultValue: 'L' })
  declare textAlign: 'R' | 'C' | 'L';
  @attr('number')
  declare edisplayOrder: number;
  @attr('number', { defaultValue: 100 })
  declare minWidth: number;
  @attr('number')
  declare maxWidth?: number;
  @attr('number')
  declare maxHeight?: number;
  @attr('boolean', { defaultValue: true })
  declare wrapText: boolean;
  @attr('ab-format')
  declare format?: ABColumnFormat;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-tab-settings-contacts-field': ProjectTabSettingsContactsField;
  }
}
