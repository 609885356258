define("cing-app/routes/si-external-login", ["exports", "ember-smartidentity-auth/routes/external-login"], function (_exports, _externalLogin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _externalLogin.default;
    }
  });
});
