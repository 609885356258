import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Widget from '../component';

export default class Email extends Widget {

  @action
  undelete() {
    this.model.rollbackAttributes();
  }
}