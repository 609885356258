import Component from '@ember/component';
import { computed, action, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { EReportStatus, ProjectTabMap } from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
  SecurityFilterOptions,
} from 'cing-app/pods/smartroom/service';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import TableTheme from '../abstract-import/table-theme';
import contact from 'cing-app/adapters/ab/investors/contact';

const PARTY_MODES = {
  SIMPLE: 'simple',
  BATCH: 'batch',
};
import classic from 'ember-classic-decorator';

@classic
export default class ImportSmartRoomContacts extends Component {
  @service store;
  @service config;
  @service session;
  @service smartroom;
  @service seActions;
  @service('docker-item') docker;

  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  searchName = null;
  searchEmail = null;
  currentStep = 1;
  pageSize = 10;
  importAction = null;
  addUsersToSmartRoom = false;
  SmartRoomUserStatus = UserStatus;
  inviteUsersToSmartRoom = false;
  defaultUserStatus = UserStatus.HOLD;
  importByTopLevelFolder = false;

  @computed
  get themeInstance() {
    return TableTheme.create();
  }

  @alias('context.project') project;

  @alias('context.onComplete') onComplete;

  init() {
    super.init(...arguments);
    this.set('selectedItems', []);
    this.initTask.perform();
  }

  @task
  *initTask() {
    let interestedPartyRoles = yield this.store.query('interested-party-role', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
    this.set('interestedPartyRoles', interestedPartyRoles.toArray());

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: AclPermissionType.ProjectTabRead,
      })
    );

    let viewerGroups = (yield this.store.query('acl-role', {
      page: {
        size: 1000,
      },
      condition: vgQuery.serialize(),
      sort: 'description',
    })).toArray();

    this.set('viewerGroups', viewerGroups);

    let srConnection = this.context.srConnection;

    if (srConnection) {
      this.setProperties({
        smartRoomId: srConnection.SRSiteId,
        smartRoomFolderId: srConnection.SRFolderId,
        defaultAllAccessFolders: yield srConnection.allAccessSmartRoomFolders,
      });
    }

    let projectFund = yield this.get('context.project.fund');
    this.set('fund', projectFund);

    this.searchContactsTask.perform();
  }

  @task({
    restartable: true,
  })
  *searchContactsTask(term) {
    yield timeout(500);

    let smartRoomUsers = yield this.store.query('smartroom/user', {
      siteId: this.smartRoomId,
      for: 'manageuser',
      searchKey: term ? term : '',
      pageSize: 10000,
    });

    if (this.importByTopLevelFolder) {
      let securityProfilesWithAccess = yield this.smartroom.getRightsForFolder(
        this.smartRoomId,
        this.smartRoomFolderId,
        SecurityFilterOptions.ProfilesWithAnyRights
      );

      smartRoomUsers = smartRoomUsers.filter((user) => {
        return securityProfilesWithAccess.findBy(
          'categoryId',
          get(user, 'categoryId')
        );
      });
    }

    this.set('smartRoomUsers', smartRoomUsers);
  }

  guessDefaultCompany(lpCom) {
    let email = lpCom.email;
    let domain = email.toLowerCase().split('@')[1];
    let investorName = lpCom.get('limitedPartner.name');
    let companies = lpCom.get('contact.companyAffiliations');

    if (domain && companies) {
      let matches = [];

      // first try to match by company name and domain
      for (var a = 0; a < companies.length; a++) {
        let companyNameParts = companies[a].name.toLowerCase().split(' ');

        let match = {
          company: companies[a].name,
          count: 0,
          matchParts: [],
        };

        matches.push(match);

        for (var i = 0; i < companyNameParts.length; i++) {
          if (
            companyNameParts[i].length > 2 &&
            domain.indexOf(companyNameParts[i]) > -1
          ) {
            match.count++;
            match.matchParts.push(companyNameParts[i]);
          }
        }
      }

      if (matches.length) {
        matches = matches.sortBy('count');

        let highestMatch = matches[matches.length - 1];

        if (highestMatch && highestMatch.count) {
          return highestMatch.company;
          //return highestMatch.company + "E: (" + highestMatch.count + ")" + " - " + highestMatch.matchParts.join(',');
        }
      }

      let contactName = lpCom.get('contact.name').toLowerCase();

      // next try to match by contact name and company
      for (var a = 0; a < companies.length; a++) {
        let companyNameParts = companies[a].name.toLowerCase().split(' ');

        let match = {
          company: companies[a].name,
          count: 0,
          matchParts: [],
        };

        matches.push(match);

        for (var i = 0; i < companyNameParts.length; i++) {
          if (
            companyNameParts[i].length > 2 &&
            contactName.indexOf(companyNameParts[i]) > -1
          ) {
            match.count++;
            match.matchParts.push(companyNameParts[i]);
          }
        }
      }

      if (matches.length) {
        matches = matches.sortBy('count');

        let highestMatch = matches[matches.length - 1];

        if (highestMatch && highestMatch.count) {
          return highestMatch.company;
          //return highestMatch.company + "C: (" + highestMatch.count + ")" + " - " + highestMatch.matchParts.join(',');
        }
      }
    }

    /*
    if (companies) {
        return companies.firstObject.name;
    }
    */
  }

  @computed
  get companies() {
    return this.store.findAll('deal-cloud-company');
  }

  @computed
  get columns() {
    let self = this;

    let columns = [
      {
        title: 'Company Name',
        propertyName: 'companyName',
        component: 'api-table/columns/html',
        className: 'column-company',
      },
      {
        title: 'Name',
        propertyName: 'name',
        className: 'column-contact',
      },
      {
        title: 'Email',
        propertyName: 'email',
        component: 'api-table/columns/html',
        className: 'column-email',
      },
      {
        title: 'Security Profile',
        propertyName: 'categoryName',
        className: 'column-role',
      },
      {
        title: 'SR Status',
        propertyName: 'status',
        component:
          'manage-interested-party/import-smartroom-contacts/column-status',
      },
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
    ];

    return columns;
  }

  @task
  *processTask() {
    yield this.addContactsTask.perform();

    //let addedParties = yield this.applySecurityRights.perform();
    //yield this.get('addUsersTask').perform(addedParties);

    if (this.get('context.onComplete')) {
      this.get('context.onComplete')();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @task
  *addContactsTask() {
    let importRows = [];

    for (var a = 0; a < this.selectedItems.length; a++) {
      let item = this.selectedItems[a];
      let rowData = {
        ContactName: item.get('name'),
        Company: item.get('companyName'),
        SRProfile: item.get('categoryId'),
        ContactEmail: item.get('email'),
        SRAccess: true,
      };

      importRows.pushObject(rowData);
    }

    let importAction = yield this.seActions.importPartiesStepOne(
      new Blob([JSON.stringify(importRows)], { type: 'application/json' }),
      this.get('context.project.id')
    );

    yield this.seActions.importPartiesStepTwo(importAction);
  }

  @action
  search() {
    this.selectedItems.clear();
    this.searchContactsTask.perform(this.searchName);
  }

  @action
  selectContacts() {
    this.selectContactsTask.perform();
  }

  @action
  process() {
    this.processTask.perform();
  }
}
