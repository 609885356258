define("cing-app/components/sorted-header-button", ["exports", "smex-ui-sr-filemanager/components/sorted-header-button"], function (_exports, _sortedHeaderButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _sortedHeaderButton.default;
    }
  });
});
