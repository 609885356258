import { inject } from '@ember/service';
import Component from "@glimmer/component";
import DockerItemService from 'cing-app/pods/docker-item/service';
import EmberObject, { action, get } from '@ember/object';
import Person from 'cing-app/models/person';
import User from 'cing-app/models/user';
import Company from 'cing-app/models/company';
import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import { task } from "ember-concurrency";
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import UserGroup from 'cing-app/models/user-group';
import Store from '@ember-data/store';
import { timeout } from 'ember-concurrency';

import { validator, buildValidations } from "ember-cp-validations";
import { getOwner } from "@ember/application";
import { reads } from '@ember/object/computed';
import classic from 'ember-classic-decorator';

enum CreateUserModeOptions {
  None,
  NewUser,
  UserExists,
  LinkToExistingContact,
  UserCreated
}

const Validations = buildValidations({
  email: {
    validators: [
      validator("presence", true),
      validator('format', {
        type: 'email'
      }),
    ]
  },

  fullName: {
    disabled: reads('model.disableFullNameValidation'),
    validators: [
      validator("presence", true),
      validator("length", {
        min: 1,
        max: 200
      })
    ]
  },
});

@classic
class Form extends EmberObject.extend(Validations) {
  @tracked
  disableFullNameValidation: Boolean = false;

  @tracked
  email: string = "";

  @tracked
  userGroup?: UserGroup;

  @tracked
  fullName: string = "";

  @tracked
  company?: Company;
}

interface AddArgs {
  context: any;
  onClose: () => void;
  footer: any;
  header: any;
}

export default class Add extends Component<AddArgs> {
  @inject()
  store!: Store;

  CreateUserModeOptions = CreateUserModeOptions;

  @inject('docker-item') docker!: DockerItemService;

  @tracked
  userGroups?: UserGroup[];

  @tracked
  user?: User;

  @tracked
  existingUser?: User;

  @tracked
  person?: Person;

  @tracked
  createUserMode: CreateUserModeOptions = CreateUserModeOptions.None;

  @tracked
  lastSearchCompanyText?: string;

  form: Form;

  constructor(owner: any, args: AddArgs) {
    super(owner, args);
    // @ts-ignore
    this.form = Form.create(getOwner(this).ownerInjection());
    this.prepareAddUserDataTask.perform();
  }

  @task
  prepareAddUserDataTask = taskFor(async () => {
    this.userGroups = (await this.store.query('user-group', {
      page: {
        size: 1000
      }
    })).toArray();
  })

  @task({ restartable: true })
  findExistingUserTask = taskFor(async () => {
    await timeout(400);

    let existingUserQuery = Expressions.create();

    existingUserQuery.add(Filter.create({
      name: 'email',
      operator: FilterOperators.EQUAL,
      value: this.form.email
    }));

    let user = (await this.store.query('user', {
      condition: existingUserQuery.serialize(),
      include: 'groups,person.company,person.emails',
      page: {
        size: 1
      }
    })).firstObject;

    this.existingUser = user;

    if (!this.existingUser) {
      this.person = undefined;
      let existingPersonQuery = Expressions.create();

      existingPersonQuery.add(Filter.create({
        name: 'emails.emailAddress',
        operator: FilterOperators.EQUAL,
        value: this.form.email
      }));

      let person = (await this.store.query('person', {
        condition: existingPersonQuery.serialize(),
        include: 'company',
        page: {
          size: 1
        }
      })).firstObject;

      this.person = person;
    }

    if (this.existingUser) {
      this.createUserMode = CreateUserModeOptions.UserExists;
    } else if (this.person) {
      this.createUserMode = CreateUserModeOptions.LinkToExistingContact;
      this.form.disableFullNameValidation = true;
    } else {
      this.createUserMode = CreateUserModeOptions.NewUser;
      this.form.disableFullNameValidation = false;
    }
  })

  @task
  linkNewUserWithPerson = taskFor(async () => {
    if (!this.person || !get(this.form, 'validations.isValid')) {
      return;
    }

    let user = this.store.createRecord('user', {
      personId: this.person.id,
      email: this.form.email,
      person: this.person
    })

    if (this.form.userGroup) {
      user.groups.pushObject(this.form.userGroup);
    }

    await user.save();

    this.person = undefined;
    this.user = user;
    this.createUserMode = CreateUserModeOptions.UserCreated;
  })

  @task
  addNewUserTask = taskFor(async () => {
    if (!get(this.form, 'validations.isValid')) {
      return;
    }

    // first store the person in CRM
    let person: Person = this.store.createRecord('person', {
      fullName: this.form.fullName
    });

    // populate first/last/middle/prefix/suffix etc.
    person.parseFromFullName(this.form.fullName);

    await person.save();

    // now add email to CRM
    let personEmail = this.store.createRecord('email', {
      emailAddress: this.form.email,
      person: person,
      personId: person.id
    })

    await personEmail.save();

    // affiliate contact with a company if requested
    if (this.form.company) {
      let personInCompany = this.store.createRecord('person-in-company', {
        person: person,
        personId: person.id,
        company: this.form.company,
        companyId: this.form.company.id,
      })

      await personInCompany.save();
    }

    // now create the actual user account
    let user = this.store.createRecord('user', {
      email: this.form.email,
      personId: person.id,
      person: person
    });

    if (this.form.userGroup) {
      user.groups.pushObject(this.form.userGroup)
    }

    await user.save();

    this.createUserMode = CreateUserModeOptions.UserCreated;
    this.user = user;
  })

  @action
  searchUser() {
    this.createUserMode = CreateUserModeOptions.None;
    this.user = undefined;
    this.person = undefined;

    if (get(this.form, 'validations.attrs.email.isValid')) {
      return this.findExistingUserTask.perform();
    }
    else {
      this.findExistingUserTask.cancelAll({ resetState: true });
    }

    return null;
  }

  @action
  showCompanyDetail(company: Company) {
    this.docker.popupCompany(company);
  }

  @action
  showPersonDetail(person: Person) {
    const appearance = {
      icon: 'user',
      title: '<small>Profile:</small> ' + get(person, 'fullName'),
      size: 'large'
    };
    const context = { personId: person.get('id'), company: get(person, 'company') };
    this.docker.invokePopup('contact-detail', appearance, context);
  }

  @task({ restartable: true })
  searchCompaniesTask = taskFor(async (term) => {
    await timeout(300);

    if (!term) {
      this.lastSearchCompanyText = undefined;
      return null;
    }

    this.lastSearchCompanyText = term;

    let expr = Expressions.create();

    expr.add(Filter.create({
      name: 'name',
      operator: FilterOperators.LIKE,
      value: term
    }));

    expr.add(Filter.create({
      name: 'master-id',
      operator: FilterOperators.NULL
    }));

    return await this.store.query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 50, number: 1 }
    })
  })

  @task
  createCompany = taskFor(async () => {
    let newCompany = this.store.createRecord('company', {
      name: this.lastSearchCompanyText
    })
    await newCompany.save();
    this.form.company = newCompany;
  })

  @action
  sendAlert(user: User) {
    let items = [];
    items.unshift(user);

    const appearance = {
      label: 'Send Notification',
      icon: '',
      title: 'Send Notification',
      custom: true,
      size: 'large'
    };

    this.docker.invokePopup('send-alert-smex', appearance, {
      users: items,
    });
  }
}