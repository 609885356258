import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DataSourceColumn } from 'smex-ui-table';

interface TableLinkColumnArgs {
  column: DataSourceColumn<unknown>;
  row: any;
}

export default class TableLinkColumn extends Component<TableLinkColumnArgs> {

  get value() {
    return this.args.column.getValue(this.args.row);
  }

  get href() {
    if (this.args.column.options.generateLink) {
      return this.args.column.options.generateLink(this.args.row);
    } else {
      return '#';
    }
  }

  @action
  onClick(evt: Event) {
    if (this.args.column.options.onClick) {
      evt.stopPropagation();
      evt.preventDefault();

      this.args.column.options.onClick(this.args.row);
    }
  }
}
