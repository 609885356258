import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { inject } from '@ember/service';
import Component from "@ember/component";
import {
  AclEntityType,
  AclPermissionType
} from "cing-app/utils/lookups";

let AdminPermissionsPerType = {};
AdminPermissionsPerType[AclEntityType.Project] = AclPermissionType.ProjectUpdate

@classic
@tagName('')
export default class AdminSwitch extends Component {
  enabled = false; // should be passed in

  @inject()
  session;

  didReceiveAttrs() {
    super.didReceiveAttrs();
    if (this.model) {
      this.set('enabled', this.model.adminEnabled);
    }
  }

  @computed('model')
  get isEntityAdmin() {
    if (!this.session.hasAdminRole) {
      if (this.entityType && AdminPermissionsPerType[this.entityType]) {
        return this.session.hasModelPermission.perform(
          this.model,
          this.entityType,
          this.model.id,
          AdminPermissionsPerType[this.entityType]
        )
      }
    } else {
      return true;
    }
  }

  @action
  enable() {
    this.set('enabled', true);
    if (this.model) {
      this.set('model.adminEnabled', true);
    }
  }

  @action
  disable() {
    this.set('enabled', false);

    if (this.model) {
      this.set('model.adminEnabled', false);
    }
  }
}