import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';

export default class ProjectTabSettingsContactsFilter extends Fragment {
  @attr('string')
  declare filterType: string;
  @attr('string')
  declare filterValue: string;

  get arrayValue() {
    if (this.filterValue) {
      return this.filterValue.split('<|>');
    }

    return [];
  }

  set arrayValue(value: any[]) {
    this.filterValue = value.join('<|>');
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-tab-settings-contacts-filter': ProjectTabSettingsContactsFilter;
  }
}
