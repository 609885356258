import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import SessionService from 'ember-smartidentity-auth/services/session';
import jwtDecode from 'ember-jwt-decode';
import { task } from 'ember-concurrency';
import { ESettings } from 'cing-app/utils/lookups';
@classic
export default class _SessionService extends SessionService {
  @service
  store;

  @service
  config;

  hasAdminRole = false;

  @computed('session.content.authenticated.id_token')
  get tokenData() {
    try {
      return jwtDecode(this.get('session.content.authenticated.id_token'));
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  isAdmin = false;

  toggleAdmin() {
    if (this.get('hasAdminRole')) {
      this.toggleProperty('isAdmin');
    }

    this.set('isAdmin', false);
  }

  @task(function* (user, entityType, entityId, permissionType) {
    if (!user) {
      return false;
    }

    let roleAssignments = (yield get(user, 'aclRoleAssignments'))
      .toArray()
      .filter((item) => {
        return (
          item.get('resourceType') === entityType &&
          item.get('resourceId') === entityId
        );
      });

    for (var a = 0; a < roleAssignments.length; a++) {
      let permissions = yield get(roleAssignments[a], 'aclRole.aclPermissions');

      if (permissions.findBy('permissionTypeId', permissionType)) {
        console.log(
          'PERMISSION FOUND: Entity Type: ',
          entityType,
          ' Entity Id: ',
          entityId,
          ' Permission Type: ',
          permissionType
        );
        return true;
      }
    }

    return false;
  })
  hasPermission;

  @task(function* (model, entityType, entityId, permissionType) {
    if (!model) {
      return false;
    }

    let roleAssignments = (yield get(model, 'aclRoleAssignments')).toArray();

    for (var a = 0; a < roleAssignments.length; a++) {
      let permissions = yield get(roleAssignments[a], 'aclPermissions');

      if (permissions.findBy('permissionTypeId', permissionType)) {
        return true;
      }
    }

    return false;
  })
  hasModelPermission;

  @computed(`config.settings.${ESettings.PortalStyle}`)
  get portalUrl() {
    if (
      get(this.config, `settings.${ESettings.PortalStyle}`) === 'Auction Portal'
    ) {
      return get(this.config, 'auctionPortalUrl');
    } else if (
      get(this.config, `settings.${ESettings.PortalStyle}`) === 'Company Portal'
    ) {
      return get(this.config, 'companyPortalUrl');
    } else {
      return get(this.config, 'investorPortalUrl');
    }
  }

  @computed('user')
  get hasAdminRoless() {
    let user = this.get('user');
    /*
    if (user) {

    }
    */
    /*
    let tokenData = this.get('tokenData');

    if (tokenData) {
        let 
    }
    */
    /*
    let roles = this.get('tokenData.role');

    if (roles) {
        if (roles.indexOf('Admin') !== -1) {
            return true;
        }
    }

    return false;
    */
  }

  @computed('authUser.email')
  get isBMCUser() {
    let email = (get(this, 'authUser.email') || '').toLowerCase();

    if (
      email &&
      (email.endsWith('bmcgroup.com') || email.endsWith('smartroom.com'))
    ) {
      return true;
    }

    return false;
  }

  init() {
    super.init();
  }
}
