import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import { buildValidations } from 'ember-cp-validations';
import User from './user';
import Person from './person';
import Abstract from './abstract';

const UserToPersonValidations = buildValidations({});

export default class UserToPerson extends Abstract.extend(
  UserToPersonValidations
) {
  @attr('string') declare userId: string;
  @attr('string') declare personId: string;
  @attr('number') declare flag: number;
  @belongsTo('user') declare user: AsyncBelongsTo<User>;
  @belongsTo('person') declare person: AsyncBelongsTo<Person>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-to-person': UserToPerson;
  }
}
