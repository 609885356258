import Component from '@ember/component';
import { task } from 'ember-concurrency';
import EmberObject, { get, set, computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import classic from 'ember-classic-decorator';
import { validator, buildValidations } from 'ember-cp-validations';
import { getOwner } from "@ember/application";
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  smartRoomName: [
    validator("presence", true),
  ]
});

@classic
class SmartRoomNameModel extends EmberObject.extend(Validations) {
  @tracked
  smartRoomName = "";
}

@classic
export default class CreateSmartRoom extends Component {
  @service seActions;
  @service session;
  @service store;
  @service smartroom;

  @tracked
  showValidations = false;

  smartRoomName = SmartRoomNameModel;

  init() {
    super.init();
    this.smartRoomName = SmartRoomNameModel.create(getOwner(this).ownerInjection());
  }

  @task
  *createSmartRoomTask() {
    try {
      let result = yield this.seActions.createSmartRoom(
        this.session.user.email,
        1
      );

      if (this.smartRoomName.smartRoomName) {
        yield this.smartroom.updateSmartRoomName(
          result.Sites[0].SiteId,
          this.smartRoomName.smartRoomName
        );
      }

      let smartRoom = yield this.store.findRecord(
        'smartroom/site',
        result.Sites[0].SiteId
      );

      if (smartRoom) {
        let nowDate = new Date();
        smartRoom.dateCreated = moment(nowDate).format('YYYY-MM-DD')
      }

      if (this.selectSmartRoom) {
        this.selectSmartRoom(smartRoom);
      }
    } catch (e) {
      throw e;
    }
  }

  @action
  createSmartRoom() {
    this.showValidations = true;

    if (this.smartRoomName.get('validations.isValid')) {
      this.smartRoomName.smartRoomName = this.smartRoomName.smartRoomName.trim();
      if (this.smartRoomName.smartRoomName) {
        this.createSmartRoomTask.perform();
      }
    }
  }
}