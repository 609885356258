import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { inject as service } from '@ember/service';
/*global tinymce*/

import Component from '@ember/component';
import '@ember/object';
import { assign } from '@ember/polyfills';
import { run, later, next } from '@ember/runloop';

@classic
@tagName('textarea')
export default class HtmlEditor extends Component {
	value = null;
	editor = null;

	@service
	seActions;

	onBlur() { }

	onChange() {
		let editor = this.editor;
		if (editor) {
			let value = this.value;
			editor.setContent(value);
		}
	}

	@observes('value')
	valueChanged() {
		let { editor, value } = this;
		if (editor && editor.getContent() !== value) {
			editor.setContent(value || '');
		}
	}

	didInsertElement() {
		super.didInsertElement(...arguments);
		let self = this;

		next(() => {
			let elementId = this.elementId;

			let customOptions = {
				selector: '#' + elementId,
				init_instance_callback: (editor) => {
					this.set('editor', editor);
					if (this.onEditorCreated) {
						this.onEditorCreated(editor);
					}

					editor.on('blur', () => {
						this.onBlur();
					});

					editor.on('change keyup keydown keypress mousedown', () => {
						let newValue = editor.getContent();
						let value = this.value;
						if (value !== newValue) {
							this.onChange(newValue);
						}
					});

					if (this.value) {
						editor.setContent(this.value);
					}
				},
				urlconverter_callback: function (url, node, on_save, name) {
					return url;
				},

				automatic_uploads: true,

				images_upload_handler: function (blobInfo, success, failure, progress) {
					return self.seActions.uploadImage(blobInfo.blob(), "", true, blobInfo.filename()).then(
						(uploadResult) => {
							success(uploadResult.url)
						},
						(err) => {
							failure(err);
						}
					)
				}
			};

			if (this.onEditorInit) {
				customOptions.setup = (editor) => {
					this.onEditorInit(editor);
				}
			}

			let options = this.options;

			let resultOptions = assign({}, options, customOptions);
			if (this.height) {
				resultOptions.height = this.height;
			}
			tinymce.init(resultOptions);
		});
	}

	willDestroyElement() {
		super.willDestroyElement(...arguments);

		let editor = this.editor;
		if (editor) {
			editor.off('change keyup keydown keypress mousedown');
			editor.destroy();
		}
	}
}
