import { A } from '@ember/array';
import Component from '@glimmer/component';
import { get, set } from '@ember/object';
import { all } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { SYNC_API } from 'cing-app/pods/user-instances/service';

import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import {
  AclEntityType,
  AclPermissionType,
  ProjectTabMap,
  ConnectionTypes,
} from 'cing-app/utils/lookups';
import { UserStatus, NotificationTypes } from 'cing-app/pods/smartroom/service';
import { IsProjectType } from 'cing-app/helpers/is-project-type';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import Project from 'cing-app/models/project';
import ProjectTabModel from 'cing-app/models/project-tab';
import InterestedParty from 'cing-app/models/interested-party';
import InterestedPartyRole from 'cing-app/models/interested-party-role';
import InterestedPartyType from 'cing-app/models/interested-party-type';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';
import AclRole from 'cing-app/models/acl-role';
import Investor from 'cing-app/models/investor';
import Person from 'cing-app/models/person';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import InterestedPartyStatus from 'cing-app/models/interested-party-status';

const SHOW_AS_OPTION_1 = 1;
const SHOW_AS_OPTION_2 = 2;
const SHOW_AS_OPTION_3 = 3;

const SHOW_AS_OPTIONS = [
  {
    value: SHOW_AS_OPTION_1,
    name: 'Last, First Middle',
  },
  {
    value: SHOW_AS_OPTION_2,
    name: 'First Middle Last',
  },
  {
    value: SHOW_AS_OPTION_3,
    name: 'First Last',
  },
];

interface PartiesArgs {
  model: Project;
  presetFilter: any;
}

export default class Parties extends Component<PartiesArgs> {
  @service store!: Store;
  @service abModels: any;
  @service smartroomCache: any;
  @service smartroom: any;
  @service abStore: any;
  @service session: any;
  @service config: any;
  @service userInstances: any;
  // SHOW_AS_OPTIONS;
  @service('docker-item') docker: any;
  @service('export-data') exportData: any;
  @tracked
  pageSize: number = 30;
  @tracked
  confirmRemoval: InterestedParty | null = null;

  @tracked
  smartRoomId: number | null = null;

  @tracked
  smartRoomFolderId: number | null = null;
  @tracked
  smartRoomTab!: ProjectTabModel;

  @tracked
  defaultAllAccessFolders: null = null;

  @tracked
  allSmartRoomUsers: SmartroomUser[];

  // @tracked
  // allSmartRoomCategories: null = null;

  @tracked
  SmartRoomUserStatus = UserStatus;

  @tracked
  defaultUserStatus: number = UserStatus.HOLD;

  @tracked
  setPortalAccessEnabled: boolean = false;

  @tracked
  setPortalAccessViewerGroup: null | AclRole = null;

  @tracked
  setPortalAccessViewerGroupEnabled: boolean = false;

  @tracked
  setSRAccessManagementEnabled = false;

  @tracked
  selectedInviteTemplate: null = null;

  @tracked
  showAllParties = false;

  @tracked
  interestedPartyRoles!: InterestedPartyRole[];

  @tracked
  interestedPartyTypes!: InterestedPartyType[];

  @tracked
  interestedPartyStatuses!: InterestedPartyStatus[];

  @tracked
  viewerGroups!: AclRole[];

  @tracked
  setProperties: any;

  @tracked
  srConnection?: AppbuilderLink;

  SYNC_API = SYNC_API;

  NotificationTypes = NotificationTypes;
  AclEntityType = AclEntityType;
  AclPermissionType = AclPermissionType;

  @tracked
  includes = [
    'company',
    'person',
    'interested-party-role',
    'interested-party-type',
    'interested-party-status',
    'viewer-group',
    'email.user.groups',
    'address',
    'investor.company',
  ];

  @tracked
  selectedItems: InterestedParty[];

  @tracked
  dataSource!: ApiDataSource<InterestedParty>;

  @tracked
  selectedViewerGroups: AclRole[];

  @tracked
  selectedRoles!: InterestedPartyRole[];

  @tracked
  selectedTypes!: InterestedPartyType[];

  @tracked
  selectedStatuses!: InterestedPartyStatus[];

  @tracked
  searchQuery = '';

  @tracked
  condition?: string;

  @tracked
  showAsOption = SHOW_AS_OPTIONS[3];

  @tracked
  updateFolderRights: InterestedParty | null = null;

  @tracked
  confirmChangeSmartRoomStatus: InterestedParty | null = null;

  @tracked
  setPortalAccess: InterestedParty | null = null;

  @tracked
  setRole: InterestedParty | null = null;

  @tracked
  setSRAccess: InterestedParty | null = null;

  InterestedParty = InterestedParty;

  // get partyRoleIds() {
  //   let roles = [];

  //   for (let prop in PartyRoleTypes) {
  //     roles.push(PartyRoleTypes[prop]);
  //   }

  //   return roles;
  // }

  // get partyRoles() {
  //   let roles = {};

  //   for (let prop in PartyRole) {
  //     roles[PartyRole[prop]] = prop;
  //   }

  //   return roles;
  // }

  get styleNamespace() {
    return podNames['project-detail/interested-parties/parties'];
  }

  constructor(owner: any, args: PartiesArgs) {
    super(owner, args);
    this.allSmartRoomUsers = [];
    // this.allSmartRoomCategories = [];
    this.showAsOption = SHOW_AS_OPTIONS[0];
    this.selectedItems = [];
    this.initTask.perform();

    this.selectedViewerGroups = [];
    this.selectedRoles = [];
    this.selectedTypes = [];
    this.selectedStatuses = [];
  }

  @task
  initTask = taskFor(async () => {
    let gridId = 'interested-parties';
    await this._initSmartRoom.perform();

    if (this.srConnection) {
      await this._initSmartRoomSync.perform();
    }

    this.store
      .query('interested-party-role', { page: { size: 1000 }, sort: 'name' })
      .then((partyRoles) => {
        this.interestedPartyRoles = partyRoles.toArray();
      });

    this.store
      .query('interested-party-type', { page: { size: 1000 }, sort: 'name' })
      .then((partyTypes) => {
        this.interestedPartyTypes = partyTypes.toArray();
      });

    this.store
      .query('interested-party-status', { page: { size: 1000 }, sort: 'name' })
      .then((partyStatus) => {
        this.interestedPartyStatuses = partyStatus.toArray();
      });

    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        //@ts-ignore
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        //@ts-ignore
        value: AclPermissionType.ProjectTabRead,
      })
    );

    this.store
      .query('acl-role', {
        page: {
          size: 1000,
        },
        condition: vgQuery.serialize(),
        sort: 'description',
      })
      .then((viewerGroups) => {
        this.viewerGroups = viewerGroups.toArray();
      });

    this.generateFilter();
    // this.set('interestedPartyFilter', Ember.A());

    // diferent dataSource gridId because there is diferent order and columns for fund and non fund projects
    if (IsProjectType(['fund', get(this.args.model, 'projectType.name')])) {
      gridId += '.fund';
    }

    this.dataSource = new ApiDataSource<InterestedParty>(
      40,
      false,
      gridId,
      this.session.authUser.email,
      this.loadDataTask,
      this.pageSize,
      this.columnsUI,
      this.selectedItems,
      {}
    );
  });

  @task
  _initSmartRoom = taskFor(async () => {
    try {
      let project = this.args.model;
      this.srConnection = (await project.get('dataConnections')).findBy(
        'type',
        ConnectionTypes.SMARTROOM
      );
    } catch (e) {
      console.log('SmartRoom not attached to the project.');
    }
  });

  @task
  _initSmartRoomSync = taskFor(async () => {
    try {
      let defaultAccessFolders = [];

      await this.session.authenticate('authenticator:torii', 'si', {
        silent: true,
      });

      try {
        defaultAccessFolders = await this.srConnection
          ?.allAccessSmartRoomFolders;
      } catch (e) {
        alert(
          'All access folders are misconfigured for this project. Please correct them on the Documents Tab.'
        );
        console.error('default access folders could not be loaded');
      }

      this.defaultAllAccessFolders = defaultAccessFolders;

      await this.reloadSmartRoomUsers.perform();
    } catch (e) {
      console.error(e);
      console.error('SmartRoom not attached to the project.');
    }
  });

  get columnsUI() {
    let columns: DataSourceColumn<InterestedParty>[] = [
      new DataSourceColumn<InterestedParty>({
        id: 'person.fullName',
        label: 'Contact Name',
        getValue: (row) => this.nameView(row),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-link-column',
        options: { onClick: this.showAttorneyDetail },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'company.name',
        label: 'Company',
        getValue: (row) => get(row, 'company.name'),
        sortingEnabled: true,
        minWidth: 200,
        valueComponent: 'table-link-column',
        options: { onClick: this.showCompanyDetail },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'email.emailAddress',
        label: 'Email',
        getValue: (row) => get(row, 'email.emailAddress'),
        sortingEnabled: true,
        minWidth: 220,
        valueClass: 'text-break',
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'interestedPartyType.name',
        label: 'Affiliation Type',
        getValue: (row) => get(row, 'interestedPartyType.name'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'interestedPartyRole.name',
        label: 'Affiliation Role',
        getValue: (row) => get(row, 'interestedPartyRole.name'),
        sortingEnabled: true,
        minWidth: 120,
        // valueComponent: 'project-detail/interested-parties/parties/column-role',
        valueComponent: 'table-text-column',
        // options: {
        //   showRepresenting: this.showRepresenting,
        //   showRepresentedBy: this.showRepresentedBy
        // }
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'interestedPartyStatus.name',
        label: 'Status',
        getValue: (row) => get(row, 'interestedPartyStatus.name'),
        sortingEnabled: true,
        minWidth: 120,
        // valueComponent: 'project-detail/interested-parties/parties/column-role',
        valueComponent: 'table-text-column',
        // options: {
        //   showRepresenting: this.showRepresenting,
        //   showRepresentedBy: this.showRepresentedBy
        // }
      }),
    ];

    if (get(this.userInstances, 'dealCloudEnabled')) {
      columns.push(
        new DataSourceColumn<InterestedParty>({
          id: 'person.dealCloudId',
          label: 'DC',
          getValue: (row) => get(row, 'person.dealCloudId'),
          minWidth: 25,
          maxWidth: 25,
          valueComponent:
            'project-detail/interested-parties/parties/column-dc-status',
        })
      );
    }

    columns.push(
      new DataSourceColumn<InterestedParty>({
        id: 'email.user.groups',
        label: 'SE User Access',
        getValue: (row) => row,
        minWidth: 100,
        valueComponent:
          'project-detail/interested-parties/parties/column-user-group',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'email.user.groups.name',
        label: 'SE User Group',
        getValue: (row) => row,
        sortingEnabled: true,
        minWidth: 150,
        valueComponent:
          'project-detail/interested-parties/parties/column-user-group-name',
        // hidden: true,
        options: {
          tooltipText:
            'This group defines access to portal level pages and functions',
        },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'portalAccess',
        label: 'SE Project Access',
        getValue: (row) => row,
        sortingEnabled: true,
        minWidth: 100,
        valueComponent:
          'project-detail/interested-parties/parties/column-sraccess',
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'viewerGroup.name',
        label: 'SE Project Access Role',
        getValue: (row) => get(row, 'viewerGroup.name'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          tooltipEnabled: true,
          tooltipText:
            'This controls which project level tabs and functions this user has',
        },
      })
    );

    if (this.srConnection) {
      columns.push(
        new DataSourceColumn<InterestedParty>({
          id: 'srAccess',
          label: 'SR Access',
          getValue: (row) => row,
          minWidth: 100,
          valueComponent:
            'project-detail/interested-parties/parties/column-srstatus',
          options: {
            project: this.args.model,
            allSmartRoomUsers: this.allSmartRoomUsers,
          },
        }),
        new DataSourceColumn<InterestedParty>({
          id: 'srProfile',
          label: 'SR Security Profile',
          getValue: (row) => row,
          minWidth: 140,
          // hidden: true,
          valueComponent:
            'project-detail/interested-parties/parties/column-srprofile',
          valueClass: 'text-break',
          options: {
            project: this.args.model,
            allSmartRoomUsers: this.allSmartRoomUsers,
            tooltipText:
              'This controls which documents and permissions this user has to the linked SmartRoom',
          },
        })
      );
    }

    columns.push(
      new DataSourceColumn<InterestedParty>({
        id: 'email.user.lastInviteDate',
        label: 'Last SE Notification',
        getValue: (row) => get(row, 'email.user.lastInviteDate'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<InterestedParty>({
        id: 'email.user.lastAccess',
        label: 'Last SE Access',
        getValue: (row) => get(row, 'email.user.lastAccess'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      })
    );
    if (this.srConnection) {
      columns.push(
        new DataSourceColumn<InterestedParty>({
          id: 'lastSrLogin',
          label: 'Last SR Login',
          getValue: (row) => this.lastSrLogin(row),
          minWidth: 100,
          valueComponent: 'table-text-column',
          options: {
            format: {
              style: 'date',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            },
          },
        })
      );
    }
    columns.push(
      new DataSourceColumn<InterestedParty>({
        id: 'createTime',
        label: 'Contact Added',
        getValue: (row) => get(row, 'createTime'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      })
    );

    if (IsProjectType(['fund', get(this.args.model, 'projectType.name')])) {
      columns.push(
        new DataSourceColumn<InterestedParty>({
          id: 'sr-folders',
          label: 'Folders',
          getValue: (row) => get(row, 'srFoldersAbbr'),
          sortingEnabled: true,
          minWidth: 200,
          valueComponent: 'table-text-column',
        })
      );
    }

    // columns.pushObjects([
    //   {
    //     title: "",
    //     component: "project-detail/interested-parties/parties/column-actions",
    //     className: 'column-actions',
    //     selectedItems: this.selectedItems,
    //     sendAlert: this.sendAlert,
    //   }
    // ]);

    // this is very shaky logic, but was requested
    if (IsProjectType(['fund', get(this.args.model, 'projectType.name')])) {
      columns.unshift(
        new DataSourceColumn<InterestedParty>({
          id: 'investor.company.name',
          label: 'Investor',
          getValue: (row) => row,
          minWidth: 200,
          sortingEnabled: true,
          sort: 'asc',
          valueComponent:
            'project-detail/interested-parties/parties/column-investor',
          options: {
            onClick: this.showInvestorDetail,
            project: this.args.model,
          },
        })
        // new DataSourceColumn<InterestedParty>({
        //   id: 'company.name',
        //   label: 'Company',
        //   getValue: row => get(row, 'company.name'),
        //   sortingEnabled: true,
        //   minWidth: 200,
        //   valueComponent: 'table-link-column',
        //   options: { onClick: this.showCompanyDetail }
        // }),
      );
    } else {
      // columns.unshift(
      //   new DataSourceColumn<InterestedParty>({
      //     id: 'company.name',
      //     label: 'Company',
      //     getValue: row => get(row, 'company.name'),
      //     minWidth: 200,
      //     valueComponent: 'table-link-column',
      //     sortingEnabled: true,
      //     options: { onClick: this.showCompanyDetail }
      //   }),
      // );
    }

    // columns.push({
    //   component: "api-table/select-row-checkbox",
    //   useFilter: false,
    //   mayBeHidden: true,
    //   title: "Select",
    //   className: "column-checkbox",
    //   componentForSortCell: "api-table/select-all-rows-checkbox"
    // });

    return columns;
  }

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<InterestedParty>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      this.condition = this.generateFilter();

      let query: any = {
        condition: this.condition,
        include: this.includes.join(','),
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
      };
      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let ips = await this.store.query('interested-party', query);
      let interestedParties = <Paged<InterestedParty>>ips.toArray();
      interestedParties.meta = {
        totalCount: ips.meta['total-count'],
      };

      return interestedParties;
    }
  );

  // columns: computed('session.isAdmin', 'smartRoomId', 'model.projectType.name', function () {
  // get columns() {
  //   let columns: any = [
  //     {
  //       propertyName: 'person.fullName',
  //       title: "Contact Name",
  //       className: "column-person-name",
  //       sortedBy: "person.lastName",
  //       component: "project-detail/interested-parties/parties/column-attorney"
  //     },
  //     {
  //       propertyName: 'email.emailAddress',
  //       title: "Email",
  //       //sortedBy: "email.emailAddress",
  //       disableSort: true,
  //     },
  //     {
  //       propertyName: 'interestedPartyType.name',
  //       title: "Type",
  //       className: 'column-role',
  //     },
  //     {
  //       propertyName: 'interestedPartyRole.name',
  //       title: "Role",
  //       className: 'column-role',
  //       component: "project-detail/interested-parties/parties/column-role"
  //     },
  //     {
  //       propertyName: 'viewerGroup.name',
  //       title: "Access Role",
  //       className: 'column-role',
  //     }
  //   ];

  //   if (get(this.userInstances, 'dealCloudEnabled')) {
  //     columns.pushObject(
  //       {
  //         propertyName: 'person.dealCloudId',
  //         title: "DC",
  //         className: 'column-dc',
  //         disableSort: true,
  //         component: "project-detail/interested-parties/parties/column-dc-status",
  //       }
  //     )
  //   }

  //   columns.pushObjects([

  //     {
  //       propertyName: 'email.user.groups',
  //       title: "User Group",
  //       className: 'column-role',
  //       disableSort: true,
  //       component: "project-detail/interested-parties/parties/column-user-group"
  //     },
  //     {
  //       propertyName: 'email.user.groups',
  //       title: "User Group Name",
  //       className: 'column-role',
  //       disableSort: true,
  //       isHidden: true,
  //       component: "project-detail/interested-parties/parties/column-user-group-name"
  //     },
  //     {
  //       propertyName: 'portalAccess',
  //       title: "Portal Status",
  //       className: 'column-role',
  //       component: "project-detail/interested-parties/parties/column-sraccess"
  //     },
  //   ]);

  //   if (this.smartRoomId) {
  //     columns.pushObjects([
  //       {
  //         propertyName: 'srAccess',
  //         title: "SR Status",
  //         className: 'column-role',
  //         project: this.args.model,
  //         disableSort: true,
  //         component: "project-detail/interested-parties/parties/column-srstatus",
  //         allSmartRoomUsers: this.allSmartRoomUsers
  //       },
  //       {
  //         propertyName: 'srProfile',
  //         title: "Security Profile",
  //         className: 'column-role',
  //         project: this.args.model,
  //         disableSort: true,
  //         isHidden: true,
  //         component: "project-detail/interested-parties/parties/column-srprofile",
  //         allSmartRoomUsers: this.allSmartRoomUsers
  //       }
  //     ]);
  //   }

  //   columns.pushObjects([
  //     {
  //       propertyName: 'email.user.lastInviteDate',
  //       title: "Last Invite",
  //       className: 'column-role',
  //       component: "api-table/columns/date"
  //     },
  //     {
  //       propertyName: 'email.user.lastAccess',
  //       title: "Last Access",
  //       className: 'column-role',
  //       component: "api-table/columns/date"
  //     },
  //     {
  //       propertyName: 'createTime',
  //       title: "Contact Added",
  //       className: 'column-role',
  //       component: "api-table/columns/date"
  //     },
  //   ]);

  //   if (IsProjectType(['fund', get(this.args.model, 'projectType.name')])) {
  //     columns.pushObjects([
  //       {
  //         propertyName: 'srFoldersAbbr',
  //         title: "Folders",
  //         className: 'column-role',
  //       },
  //     ]);
  //   }
  //   columns.pushObjects([
  //     {
  //       title: "",
  //       component: "project-detail/interested-parties/parties/column-actions",
  //       className: 'column-actions',
  //       selectedItems: this.selectedItems,
  //       sendAlert: this.sendAlert,
  //     }
  //   ]);
  //   // this is very shaky logic, but was requested
  //   if (IsProjectType(['fund', get(this.args.model, 'projectType.name')])) {
  //     columns.unshift({
  //       component: "project-detail/interested-parties/parties/column-party-name",
  //       title: "Company",
  //       propertyName: 'company.name',
  //       className: "column-company-name",
  //     });
  //     columns.unshift({
  //       title: "Investor",
  //       propertyName: 'investor.company.name',
  //       className: "column-company-name",
  //       sortDirection: 'asc',
  //       sortPrecedence: 0,
  //       project: this.args.model,
  //       component: "project-detail/interested-parties/parties/column-investor",
  //     });

  //   } else {
  //     columns.unshift({
  //       component: "project-detail/interested-parties/parties/column-party-name",
  //       title: "Company",
  //       propertyName: 'company.name',
  //       className: "column-company-name",
  //     })
  //   }
  //   columns.push({
  //     component: "api-table/select-row-checkbox",
  //     useFilter: false,
  //     mayBeHidden: true,
  //     title: "Select",
  //     className: "column-checkbox",
  //     componentForSortCell: "api-table/select-all-rows-checkbox"
  //   });

  //   return columns;
  // }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        //@ts-ignore
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.args.model.id,
      })
    );

    /*
    expr.add(Filter.create({
        name: 'email-id',
        operator: FilterOperators.NOT_NULL,
    }));
    */

    if (!this.showAllParties) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'person-id',
          operator: FilterOperators.NOT_NULL,
        })
      );
    }

    // let preset = this.args.presetFilter;
    // if (preset.get('attorney')) {
    //   let attorneyCases = preset.get('attorney.attorneyCases').toArray();
    //   // iterate over all relevant parties
    //   let overallParties = A([]);
    //   attorneyCases.forEach((ac:Case) => {
    //     let rp = ac.get('representingParties');
    //     overallParties = overallParties.concat(rp.mapBy('interestedParty.id'));
    //   });
    //   expr.add(Filter.create({
    //     //@ts-ignore
    //     name: 'id',
    //     operator: FilterOperators.IN,
    //     //@ts-ignore
    //     value: overallParties.uniq()
    //   }));
    // }

    if (this.selectedRoles.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'role-id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.selectedRoles.mapBy('id'),
        })
      );
    }

    if (this.selectedTypes.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'type-id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.selectedTypes.mapBy('id'),
        })
      );
    }

    if (this.selectedStatuses.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'status-id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.selectedStatuses.mapBy('id'),
        })
      );
    }

    if (this.selectedViewerGroups.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'viewer-group-id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.selectedViewerGroups.mapBy('id'),
        })
      );
    }

    let searchQuery = this.searchQuery;
    if (searchQuery) {
      let termParts = searchQuery.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            //@ts-ignore
            name: 'person.firstName',
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            //@ts-ignore
            name: 'person.lastName',
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: termParts[termParts.length - 1],
          })
        );

        exprSearch.add(nameExpression);
      }

      [
        'investor.company.name',
        'party-name',
        'address.city',
        'person.last-name',
        'person.first-name',
        'email.email-address',
        'company.name',
      ].forEach((propName) => {
        exprSearch.add(
          Filter.create({
            //@ts-ignore
            name: propName,
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: searchQuery,
          })
        );
      });

      expr.add(exprSearch);
    }

    //    this.condition = expr.serialize();

    return expr.serialize();
  }

  // include: computed('includes.[]', function () {
  get include() {
    return this.includes.join(',');
  }

  // // startDate: computed('startRaw', function () {
  // get startDate() {
  //   if (this.get('startRaw')) {
  //     return moment(this.get('startRaw')).startOf('day');
  //   }
  //   return null;
  // }

  // // endDate: computed('endRaw', function () {
  // get endDate() {
  //   if (this.get('endRaw')) {
  //     return moment(this.get('endRaw')).endOf('day');
  //   }
  //   return null;
  // }

  @task
  updateRoleTask = taskFor(async (party) => {
    // move the reference party to the beginning
    this.selectedItems.removeObject(party);
    this.selectedItems.unshiftObject(party);

    let items = this.selectedItems.toArray();

    // get the role properties from reference item
    let changes = party.getProperties('roleId', 'isImportant', 'roleText');

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      if (item) {
        item.setProperties(changes);
        await item.save();
      }
    }

    this.setRole = null;
  });

  @task
  updatePortalAccessTask = taskFor(async (party) => {
    // move the reference party to the beginning
    this.selectedItems.removeObject(party);
    this.selectedItems.unshiftObject(party);

    let items = this.selectedItems.toArray();

    let itemsToSave = [];

    let setPortalAccessEnabled = this.setPortalAccessEnabled;
    let setPortalAccessViewerGroupEnabled =
      this.setPortalAccessViewerGroupEnabled;
    let setPortalAccessViewerGroup = this.setPortalAccessViewerGroup;

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      if (item) {
        item.portalAccess = setPortalAccessEnabled;

        if (
          setPortalAccessViewerGroupEnabled &&
          setPortalAccessViewerGroup &&
          setPortalAccessViewerGroup.id
        ) {
          item.viewerGroupId = setPortalAccessViewerGroup.id;
          item.viewerGroup = setPortalAccessViewerGroup;
        }
      }

      itemsToSave.push(this.updatePartyRecordTask.perform(item));
    }

    await all(itemsToSave);

    this.setPortalAccessEnabled = false;
    this.setPortalAccessViewerGroup = null;
    this.setPortalAccessViewerGroupEnabled = false;

    this.setPortalAccess = null;
  });

  @task
  updateSRAccessTask = taskFor(async (party) => {
    // move the reference party to the beginning
    this.selectedItems.removeObject(party);
    this.selectedItems.unshiftObject(party);

    let items = this.selectedItems.toArray();
    let itemsToSave = [];

    let setSRAccessManagementEnabled = this.setSRAccessManagementEnabled;

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      if (item) {
        item.srAccess = setSRAccessManagementEnabled;
        itemsToSave.push(this.updatePartyRecordTask.perform(item));
      }
    }

    await all(itemsToSave);

    this.setSRAccessManagementEnabled = false;
    this.setSRAccess = null;
  });

  @task
  removeRecordTask = taskFor(async (party) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      itemsToSave.push(this.removeRecordForPartyTask.perform(items[a]));
    }

    await all(itemsToSave);

    // we need to clear selectedItems upon removing!!!
    this.selectedItems.clear();

    this.confirmRemoval = null;
    this.search();
  });

  @task({ enqueue: true, maxConcurrency: 4 })
  removeRecordForPartyTask = taskFor(async (party) => {
    await this.removeFolderRightsForParty.perform(party);
    await party.destroyRecord();
    // }).enqueue().maxConcurrency(4),
  });

  @task({ enqueue: true, maxConcurrency: 4 })
  updatePartyRecordTask = taskFor(async (party) => {
    await party.save();
    // }).enqueue().maxConcurrency(4),
  });

  @task
  removeFolderRightsForParty = taskFor(async (party) => {
    let investor = await party.get('investor');

    if (investor && investor.smartRoomFolderId && this.srConnection) {
      let folderSecurities = [];

      // apply security for investor folder (view, print, save)
      folderSecurities.push({
        itemId: investor.smartRoomFolderId,
        rightId: null,
      });

      try {
        await this.smartroom.setCategorySecurity(
          this.srConnection.SRSiteId,
          party.srProfile,
          folderSecurities
        );
      } catch (e) {
        console.error('Could not modify SmartRoom rights for this contact.');
        console.error(e);
      }
    }
  });

  @task
  updateSRFolderRightsTask = taskFor(async (party) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      itemsToSave.push(this.updateSRFolderRightsForPartyTask.perform(items[a]));
    }

    await all(itemsToSave);

    this.selectedItems.clear();

    this.updateFolderRights = null;
  });

  @task({ enqueue: true, maxConcurrency: 4 })
  updateSRFolderRightsForPartyTask = taskFor(async (party) => {
    try {
      let investor = await party.get('investor');

      let investorFolder = await this.store.queryRecord('smartroom/folder', {
        siteId: this.srConnection?.SRSiteId,
        id: investor.smartRoomFolderId,
      });

      if (investorFolder) {
        let investorSubFolders = (
          await this.store.query('smartroom/folder', {
            siteId: this.srConnection?.SRSiteId,
            parentId: investorFolder.id,
          })
        ).toArray();

        // apply security rights for all folders in path
        let folderPathItems = investorFolder.pathIds.split('/');

        let folderSecurities = [];

        // apply security for investor folder (view, print, save)
        folderSecurities.push({
          itemId: investorFolder.id,
          rightId: 32,
        });

        // apply security for parent folders (up to SmartRoom root folder)
        folderPathItems.forEach((folderId) => {
          if (folderId) {
            folderSecurities.push({
              itemId: folderId,
              rightId: 32,
            });
          }
        });

        // apply security to investor subfolders
        let partyFolders = party.get('srFolders');

        if (partyFolders && partyFolders.length) {
          if (
            partyFolders[0].toLowerCase() === 'all access' ||
            partyFolders[0].toLowerCase() === 'all'
          ) {
            for (var a = 0; a < investorSubFolders.length; a++) {
              let iFolder = investorSubFolders[a];

              folderSecurities.push({
                itemId: iFolder.id,
                rightId: 5,
              });
            }

            for (var a = 0; a < this.defaultAllAccessFolders.length; a++) {
              let dFolder = this.defaultAllAccessFolders[a];

              folderSecurities.push({
                itemId: dFolder.id,
                rightId: 5,
              });
            }
          } else {
            for (var a = 0; a < investorSubFolders.length; a++) {
              let iFolder = investorSubFolders[a];

              folderSecurities.push({
                itemId: iFolder.id,
                rightId: partyFolders.includes(iFolder.get('name')) ? 5 : 26,
              });
            }
          }
        }

        await this.smartroom.setCategorySecurity(
          this.srConnection?.SRSiteId,
          party.srProfile,
          folderSecurities
        );
      }
    } catch (e) {
      alert(`Could not apply folder rights for this party ${party.partyName}`);
    }
    // }).enqueue().maxConcurrency(4),
  });

  @task
  doChangeSmartRoomStatusTask = taskFor(async (party) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let project = this.args.model;

    let emails = items.mapBy('email.emailAddress');

    if (this.srConnection) {
      await this.smartroom.updateUsersStatus(
        this.srConnection.SRSiteId,
        emails,
        this.defaultUserStatus,
        this.NotificationTypes.NONE
      );
    }

    this.defaultUserStatus = UserStatus.HOLD;
    this.confirmChangeSmartRoomStatus = null;

    await this.reloadSmartRoomUsers.perform();

    /*
    if (smartRoomTab) {
        this.setProperties({
            smartRoomId: smartRoomTab.settings.SmartRoomId,
            smartRoomFolderId: smartRoomTab.settings.SmartRoomFolderId
        });
    }        
    */
  });

  @task
  reloadSmartRoomUsers = taskFor(async () => {
    if (this.srConnection) {
      try {
        let allUsers = await this.store.query('smartroom/user', {
          siteId: this.srConnection.SRSiteId,
          pageNo: 1,
          pageSize: 1000,
        });

        this.allSmartRoomUsers.clear();

        this.allSmartRoomUsers.pushObjects(allUsers.toArray());
      } catch (e) {
        // alert("Could not communicate with linked SmartRoom - you may need to be added as a SmartRoom manager or the room does not exist anymore.")
        alert(
          'In order to retrieve SmartRoom status for contacts, you must be a SmartRoom Manager on an active linked Smartroom'
        );
        return;
      }
    }
  });

  @task
  createNotificationTask = taskFor(async (parties) => {
    let users: { email: string; person: Person }[] = [];

    for (var a = 0; a < parties.length; a++) {
      let person = await get(parties[a], 'person');
      let email = await get(parties[a], 'email');

      if (person && email) {
        let user = await get(email, 'user');

        if (user) {
          users.pushObject(user);
        } else {
          users.pushObject({
            email: email.emailAddress,
            person: person,
          });
        }
      }
    }

    users = users.compact();

    const appearance = {
      label: 'Send Notification',
      icon: '',
      title: 'Send Notification',
      custom: true,
      size: 'large',
    };

    this.docker.invokePopup('send-alert-smex', appearance, {
      users: users,
    });

    this.selectedItems.clear();
  });

  // actions: {
  @action
  addInterestedParty() {
    let self = this;
    let appearance = {
      icon: '',
      title: `Add Contact`,
      size: 'medium',
      custom: true,
    };

    let interestedParty = this.store.createRecord('interested-party', {
      project: this.args.model,
    });

    this.docker.invokePopup('manage-interested-party/add-party', appearance, {
      project: self.args.model,
      srConnection: this.srConnection,
      interestedParty,
      closeOnCreate: true,
      onCreate: () => {
        this.reloadSmartRoomUsers.perform();
        this.dataSource.refresh();
      },
    });

    this.setRole = null;
  }

  @action
  editInterestedParty(row: InterestedParty) {
    let personName = get(row, 'person.fullName');
    let companyName = get(row, 'company.name');
    let emailAddress = get(row, 'email.emailAddress');
    let investorName = get(row, 'investor.company.name');

    let partyName: string = '';

    if (personName) {
      partyName = personName;
    } else if (companyName) {
      partyName = companyName;
    } else if (emailAddress) {
      partyName = emailAddress;
    } else if (investorName) {
      partyName = investorName;
    }

    let self = this;
    let appearance = {
      icon: '',
      title: `Edit contact: ${partyName}`,
      size: 'medium',
      custom: true,
    };
    this.docker.invokePopup('manage-interested-party/add-party', appearance, {
      interestedParty: row,
      srConnection: this.srConnection,
      project: self.args.model,
      onUpdate: function () {
        self.reloadSmartRoomUsers.perform();
      },
    });
  }

  @action
  bulkImportFromCRM() {
    this.docker.popupImportContactsToProjectFromCRM(this.args.model, {
      srConnection: this.srConnection,
      onComplete: () => {
        this.dataSource.refresh();
      },
    });
  }

  @action
  importContactsFromDealCloudLPComm() {
    this.docker.popupImportContactsToProjectFromDealCloudLPComm(
      this.args.model,
      {
        srConnection: this.srConnection,
        onComplete: () => {
          this.dataSource.refresh();
        },
      }
    );
  }

  @action
  importContactsFromDealCloud() {
    this.docker.popupImportContactsToProjectFromDealCloud(this.args.model, {
      srConnection: this.srConnection,
      onComplete: () => {
        this.dataSource.refresh();
      },
    });
  }

  @action
  importContactsFromExcel() {
    this.docker.popupImportContactsFromExcel(this.args.model, {
      srConnection: this.srConnection,
      onComplete: () => {
        this.dataSource.refresh();
      },
    });
  }

  // @action
  // openPopup(component, appearance, context, header, footer) {
  //   this.docker.invokePopup(component, appearance, context, header, footer);
  // }

  @action
  setRoleAction(party: InterestedParty) {
    if (!this.selectedItems) {
      this.selectedItems = [party];
    }

    this.setRole = party;
  }

  @action
  cancelSetRole() {
    this.setRole = null;
  }

  @action
  doUpdateRole(party: InterestedParty) {
    this.updateRoleTask.perform(party);
  }

  @action
  setPortalAccessAction(party: InterestedParty) {
    this.setPortalAccessEnabled = false;
    this.setPortalAccessViewerGroup = null;
    this.setPortalAccessViewerGroupEnabled = false;

    if (!this.selectedItems) {
      this.selectedItems = [party];
    }

    this.setPortalAccess = party;
  }

  @action
  cancelSetPortalAccess() {
    this.setPortalAccess = null;
  }

  @action
  doUpdatePortalAccess(party: InterestedParty) {
    this.updatePortalAccessTask.perform(party);
  }

  @action
  setSRAccessAction(party: InterestedParty) {
    if (!this.selectedItems) {
      this.selectedItems = [party];
    }

    this.setSRAccess = party;
  }

  @action
  cancelSetSRAccess() {
    this.setSRAccess = null;
  }

  @action
  doUpdateSRAccess(party: InterestedParty) {
    this.updateSRAccessTask.perform(party);
  }

  // @action
  // showPartyDetail(row: InterestedParty) {
  //   if (row.get('companyId')) {
  //     row.get('company').then((company: Company) => {
  //       this.showCompanyDetail(company);
  //     });
  //   } else if (row.get('personId')) {
  //     this.showAttorneyDetail(row);
  //   } else {
  //     alert('There is no CRM record associated with this party.');
  //   }
  // }

  @action
  updateFolderRightsAction(party: InterestedParty) {
    if (!this.selectedItems) {
      this.selectedItems = [party];
    }

    this.updateFolderRights = party;
  }

  @action
  cancelUpdateFolderRights() {
    this.updateFolderRights = null;
  }

  @action
  doUpdateFolderRights(party: InterestedParty) {
    this.updateSRFolderRightsTask.perform(party);
  }

  @action
  showAttorneyDetail(row: InterestedParty) {
    this.docker.popupPerson(row.get('person'), row.get('company'));
  }

  @action
  showCompanyDetail(row: InterestedParty) {
    this.docker.popupCompany(row.company);
  }

  // @action
  // showRepresenting(row: InterestedParty) {
  //   let representedByTitle = [];

  //   if (get(row, 'person.fullName')) {
  //     representedByTitle.push(get(row, 'person.fullName'))
  //   }

  //   if (get(row, 'company.name')) {
  //     representedByTitle.push(get(row, 'company.name'))
  //   }

  //   const appearance = {
  //     icon: '',
  //     title: 'Parties represented by: ' + representedByTitle.join(" / ")
  //   };

  //   const context = {
  //     interestedPartyId: get(row, 'id'),
  //     personId: get(row, 'person.id'),
  //     companyId: get(row, 'company.id'),
  //     projectId: this.args.model.id,
  //     refresh: () => {
  //       this.search();
  //     }
  //   };

  //   this.docker.invokePopup('project-detail/interested-parties/parties/representing', appearance, context);
  // }

  // @action
  // showRepresentedBy(row: InterestedParty) {
  //   let representedByTitle = [];

  //   if (row.get('person.fullName')) {
  //     representedByTitle.push(get(row, 'person.fullName'))
  //   }

  //   if (row.get('company.name')) {
  //     representedByTitle.push(get(row, 'company.name'))
  //   }

  //   const appearance = {
  //     icon: '',
  //     title: 'Parties representing: ' + representedByTitle.join(" / ")
  //   };

  //   const context = {
  //     interestedPartyId: get(row, 'id'),
  //     personId: get(row, 'person.id'),
  //     companyId: get(row, 'company.id'),
  //     projectId: this.args.model.id,
  //     refresh: () => {
  //       this.search();
  //     }
  //   };
  //   this.docker.invokePopup('project-detail/interested-parties/parties/represented-by', appearance, context);
  // }

  @action
  showInvestorDetail(row: InterestedParty) {
    get(row, 'investor').then((investor: Investor) => {
      const appearance = {
        icon: '',
        title: 'Investor detail: ' + investor.get('company.name'),
        custom: true,
      };

      const context = {
        project: this.args.model,
        investor: investor,
        srConnection: this.srConnection,
      };
      this.docker.invokePopup('investor-detail', appearance, context);
    });
  }

  // @action
  // onTableUpdated(e) {
  //   this.selectedItems = e.selectedItems;
  // }

  @action
  filterByAttorney() {
    console.log('FILTER BY ATTORNEY: ', ...arguments);
  }

  // @action
  // datePropertyChanged(dateProperty) {
  //   console.log("DATE PROPERTY VALUE: ", dateProperty);
  // }

  @action
  removeRecord(row: InterestedParty) {
    this.confirmRemoval = row;
  }

  @action
  cancelRemoveRecord() {
    this.confirmRemoval = null;
  }

  @action
  doRemoveRecord(row: InterestedParty) {
    this.removeRecordTask.perform(row);
  }

  // date range picker
  // @action
  // setDateRange(start, end, picker) {
  //   this.setProperties({ 'startRaw': start, 'endRaw': end });
  // }

  @action
  hideDatePicker() {}

  @action
  cancelDatePicker() {
    this.setProperties({ startRaw: null, endRaw: null });
  }

  @action
  search() {
    this.dataSource.refresh();
    // this.generateFilter();
  }

  @action
  searchByRole(selected: InterestedPartyRole[]) {
    this.selectedRoles = selected;
    this.dataSource.refresh();
  }

  @action
  searchByType(selected: InterestedPartyType[]) {
    this.selectedTypes = selected;
    this.dataSource.refresh();
  }

  @action
  searchByStatus(selected: InterestedPartyStatus[]) {
    this.selectedStatuses = selected;
    this.dataSource.refresh();
  }

  @action
  searchByViewerGroup(selected: AclRole[]) {
    this.selectedViewerGroups = selected;
    this.dataSource.refresh();
  }

  // @action
  // removePresetFilter(key) {
  //   this.set(`presetFilter.${key}`, null);
  //   this.generateFilter();
  // }

  @action
  viewAs(ip: InterestedParty) {
    window.open(
      `${this.session.portalUrl}view-as?viewAsPortfolio=${
        this.args.model.id
      }&viewAsUser=${encodeURIComponent(
        get(ip, 'email.emailAddress')
      )}&viewAsProfile=${get(ip, 'srProfile')}`
    );
  }

  @action
  importContactFundSearch() {
    this.docker.popupImportContactFundSearch(this.args.model, {
      srConnection: this.srConnection,
      onComplete: () => {
        this.dataSource.refresh();
      },
    });
  }

  @action
  importInvestorsFromDealCloud() {
    this.docker.popupImportInvestorsFromDealCloud(this.args.model, {
      srConnection: this.srConnection,
      onComplete: () => {
        this.dataSource.refresh();
      },
    });
  }

  @action
  importInvestorsFromExcel() {
    this.docker.popupImportInvestorsFromExcel(this.args.model, {
      srConnection: this.srConnection,
      onComplete: () => {
        this.dataSource.refresh();
      },
    });
  }

  @action
  importInvestorsContactsFromExcel() {
    this.docker.popupImportInvestorsContactsFromExcel(this.args.model, {
      srConnection: this.srConnection,
      onComplete: () => {
        this.dataSource.refresh();
      },
    });
  }

  @action
  importContactsFromSmartRoom() {
    this.docker.popupImportContactsFromSmartRoom(this.args.model, {
      srConnection: this.srConnection,
      onComplete: () => {
        this.dataSource.refresh();
      },
    });
  }

  @action
  dealCloudSync() {
    if (this.userInstances.get('dealCloudVersion') === SYNC_API.V1) {
      this.docker.popupDealCloudLPCommSync(this.args.model, {
        srConnection: this.srConnection,
        onComplete: () => {
          this.dataSource.refresh();
        },
      });
    } else {
      this.docker.popupDealCloudContactSync(this.args.model, {
        srConnection: this.srConnection,
        onComplete: () => {
          this.dataSource.refresh();
        },
      });
    }
  }

  @action
  inviteUser(row: InterestedParty) {
    let items = this.selectedItems.toArray();

    items.removeObject(row);
    items.unshiftObject(row);

    let context = {
      onComplete: () => {
        this.reloadSmartRoomUsers.perform();
        this.dataSource.refresh();
      },
    };

    this.docker.popupInviteUsers(
      this.srConnection?.SRSiteId,
      this.args.model,
      items,
      context
    );
  }

  @action
  createAlert(row: InterestedParty) {
    let items = this.selectedItems.toArray();

    items.removeObject(row);
    items.unshiftObject(row);

    let context = {
      onComplete: () => {},
    };

    this.docker.popupSendAlert(
      this.srConnection?.SRSiteId,
      this.args.model,
      items,
      context
    );
  }

  @action
  createNotification(row: InterestedParty) {
    let items = this.selectedItems.toArray();

    items.removeObject(row);
    items.unshiftObject(row);

    this.createNotificationTask.perform(items);
  }

  @action
  changeSmartRoomStatus(row: InterestedParty) {
    this.confirmChangeSmartRoomStatus = row;
  }

  @action
  cancelChangeSmartRoomStatus() {
    this.confirmChangeSmartRoomStatus = null;
  }

  @action
  doChangeSmartRoomStatus(row: InterestedParty) {
    this.doChangeSmartRoomStatusTask.perform(row);
  }

  @action
  manageIPAccess(row: InterestedParty) {
    this.docker.popupManageAccess(
      row,
      this.AclEntityType.InterestedParty,
      get(row, 'person.fullName') || get(row, 'company.name'),
      [this.AclPermissionType.InterestedPartyRead]
    );
  }

  @action
  nameView(row: InterestedParty) {
    let person = get(row, 'person');

    if (person.get('id')) {
      if (this.showAsOption?.value === SHOW_AS_OPTION_1) {
        return `${person.get('lastName') + ', ' || ''} ${
          person.get('firstName') || ''
        } ${person.get('middleName') || ''}`;
      } else if (this.showAsOption?.value === SHOW_AS_OPTION_2) {
        let middle = '';

        if (person.get('middleName')) {
          middle = `${person.get('middleName')}`;
        }

        return `${person.get('firstName') || ''} ${middle} ${
          person.get('lastName') || ''
        }`;
      } else {
        return `${person.get('firstName') || ''} ${
          person.get('lastName') || ''
        }`;
      }
    } else {
      return '';
    }
  }

  @action
  export() {
    this.exportData.export(InterestedParty, 'person.full-name');
  }

  lastSrLogin(row: InterestedParty) {
    if (row.emailId && this.allSmartRoomUsers) {
      let allSmartRoomUsers = this.allSmartRoomUsers.toArray();

      let emailAddress = row.get('email.emailAddress');
      if (emailAddress) {
        for (var a = 0; a < allSmartRoomUsers.length; a++) {
          let user = allSmartRoomUsers[a];

          if (user.email.toLowerCase() === emailAddress.toLowerCase()) {
            return user.lastLogin;
          }
        }
      }
    }

    return null;
  }

  // @action
  // getActionsSingle() {
  //   let actions = [];

  //   actions.push(
  //     {
  //       label: 'Edit',
  //       icon: 'edit',
  //       action: this.editInterestedParty
  //     },
  //     {
  //       label: 'Investor Portal',
  //       icon: 'external-link-alt',
  //       action: this.viewAs
  //     },
  //     {
  //       label: 'Send Alert (SR)',
  //       icon: 'bell',
  //       action: this.createAlert
  //     },
  //     {
  //       label: 'Send Notification (SE)',
  //       icon: 'bell',
  //       action: this.createNotification
  //     },
  //     {
  //       label: 'Remove',
  //       icon: 'user',
  //       class: 'text-danger',
  //       action: this.removeRecord
  //     },
  //     {
  //       label: 'Update Folder Rights',
  //       icon: 'folder',
  //       class: 'text-danger',
  //       action: this.updateFolderRightsAction
  //     },
  //     {
  //       label: 'Manage Access',
  //       icon: 'shield-alt',
  //       class: 'text-danger',
  //       action: this.manageIPAccess
  //     },

  //   )

  //   return actions;
  // }
  // @action
  // getActionsMulti() {
  //   let actions = [];

  //   actions.push(
  //     {
  //       label: 'Send Alert (SR)',
  //       icon: 'bell',
  //       action: this.createAlert
  //     },
  //     {
  //       label: 'Send Notification (SE)',
  //       icon: 'bell',
  //       action: this.createNotification
  //     },
  //     {
  //       label: 'Change SmartRoom Status',
  //       icon: 'shield-alt',
  //       action: this.changeSmartRoomStatus
  //     },
  //     {
  //       label: 'SmartRoom Access Management',
  //       icon: 'file',
  //       action: this.setSRAccessAction
  //     },
  //     {
  //       label: 'Portal Access',
  //       icon: 'eye',
  //       action: this.setPortalAccessAction
  //     },
  //     {
  //       label: 'Remove',
  //       icon: 'user',
  //       class: 'text-danger',
  //       action: this.removeRecord
  //     },
  //     {
  //       label: 'Update Folder Rights',
  //       icon: 'folder',
  //       class: 'text-danger',
  //       action: this.updateFolderRightsAction
  //     },

  //   )

  //   return actions;
  // }
  // @action
  // sendAlertSR() {

  // }
}
