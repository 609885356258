import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AppBuilderService from 'cing-app/pods/appbuilder/service';

interface AbPmTaskDiscussionsArgs {
  abLink: any;
  smartRoomId: any;
  task: any;
  onTaskAction: any;
}

export default class AbPmTaskDiscussions extends Component<AbPmTaskDiscussionsArgs> {
  @service store!: Store;

  @service('docker-item') docker!: DockerItemService;

  @service session!: _SessionService;

  @service('appbuilder') appbuilder!: AppBuilderService;

  @tracked isCollapsed: boolean = false;
  @tracked isDeleting: boolean = false;
  @tracked selectedMessage: any;
  @tracked newMessage: string;

  constructor(owner: any, args: any) {
    super(owner, args);
  }

  @action toggleDiscussions() {
    this.isCollapsed = !this.isCollapsed;
  }

  @action showDeleteMessageDialog(msg: any) {
    this.isDeleting = true;
    this.selectedMessage = msg;
  }

  @action deleteMessage() {
    this.deleteMessageTask.perform();
  }

  @task deleteMessageTask = taskFor(async () => {
    this.args.task.discussions.removeObject(this.selectedMessage);
    this.selectedMessage.destroyRecord();

    this.args.onTaskAction(
      'delete-message',
      this.args.task,
      this.selectedMessage
    );

    this.isDeleting = false;
  });

  @task sendDiscussion = taskFor(async () => {
    if (this.newMessage) {
      let modelMeta = this.appbuilder.getModelMeta(
        this.args.abLink,
        'discussions'
      );
      let discussion = await this.store.createRecord(modelMeta.modelPath, {});
      discussion.message = this.newMessage;
      discussion.email = this.session.tokenData.email;
      discussion.taskId = this.args.task.taskId;
      discussion.createdDate = new Date().toISOString();
      discussion.createdBy = `${this.session.tokenData.firstname} ${this.session.tokenData.lastname}`;

      let discussions = this.args.task.discussions;
      discussions.pushObject(discussion);

      await discussion.save();
    }

    this.args.task.save();
    this.newMessage = '';

    this.args.onTaskAction('save-task', this.args.task);
  });
}
