import { helper } from '@ember/component/helper';
import { SmartroomMyInfoModel, SmartroomRightTypeModel } from 'smex-ui-sr-models';

export function uploadAllowed(params: [], hash: { myInfo: SmartroomMyInfoModel, right?: SmartroomRightTypeModel }) {
	if (!hash.myInfo || !hash.right) {
		return false;
	}

	return hash.myInfo.uploadDocuments && hash.right.modify;
}

export default helper(uploadAllowed);
