import Component from '@glimmer/component';
import { computed, action, get } from '@ember/object';
import { inject as service } from '@ember/service';

import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

import { all, task, TaskInstance, timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import Faq from 'cing-app/models/faq';
import Project from 'cing-app/models/project';
import FaqCategory from 'cing-app/models/faq-category';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import DockerItemService from 'cing-app/pods/docker-item/service';
import PortalPageModel from 'cing-app/models/portal-page';
import ArrayProxy from '@ember/array/proxy';

interface FaqsArgs {
  project?: Project;
  portalPage?: PortalPageModel;
}

interface FaqByCategoryInterface {
  categoryId: string;
  categoryTitle: string;
  faqs: Faq[];
}

export default class Faqs extends Component<FaqsArgs> {
  @service store!: Store;

  @service session: any;

  @service('docker-item') docker!: DockerItemService;

  @tracked
  faqs: Faq[] = [];

  @tracked
  faqsByCategory: FaqByCategoryInterface[] | null = null;

  @tracked
  editFAQ: Faq | null = null;

  @tracked
  faqCategories: FaqCategory[] | null = null;

  @tracked
  selectedCategory: FaqByCategoryInterface | undefined | null = null;

  get canCreateFaq(): boolean {
    if (this.args.portalPage && !this.args.portalPage.id) return false;

    return true;
  }

  constructor(owner: any, args: FaqsArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    await this.loadFaqs.perform();
  });

  @task
  loadFaqs = taskFor(async () => {
    let faqs: Faq[] | ArrayProxy<Faq> = [];
    let faqFilter = Expressions.create();
    if (this.args.project) {
      faqFilter.add(
        Filter.create({
          name: 'project-id',
          operator: FilterOperators.EQUAL,
          value: get(this.args.project, 'id'),
        })
      );
    }

    if (this.args.portalPage?.id) {
      faqFilter.add(
        Filter.create({
          name: 'portal-page-id',
          operator: FilterOperators.EQUAL,
          value: get(this.args.portalPage, 'id'),
        })
      );
    }

    if (this.canCreateFaq) {
      faqs = await this.store.query('faq', {
        condition: faqFilter.serialize(),
        sort: 'order',
        page: { size: 1000 },
      });
    }

    let faqCategories = await this.store.query('faq-category', {
      sort: 'order',
      page: { size: 1000 },
    });

    this.faqCategories = faqCategories.toArray();

    let faqCategoryIds = this.faqCategories.map((fc) => fc.id);

    this.faqs = faqs?.toArray();

    let faqsByCategory: FaqByCategoryInterface[] = [];

    faqCategories.forEach((fc) => {
      faqsByCategory.push({
        categoryId: fc.id,
        categoryTitle: fc.title,
        faqs: this.faqs.filter((f) => f.categoryId === fc.id),
      });
    });

    let faqsWithUnknownCategory = this.faqs.filter(
      (f) => !faqCategoryIds.includes(f.categoryId)
    );

    if (faqsWithUnknownCategory) {
      faqsByCategory.push({
        categoryId: '',
        categoryTitle: 'Others',
        faqs: faqsWithUnknownCategory,
      });
    }
    this.selectedCategory = faqsByCategory[0];

    this.faqsByCategory = faqsByCategory;
  });

  get styleNamespace() {
    return podNames['manage-project/tab/faqs'];
  }

  @action
  moveToEmptyCategory(obj: Faq, ops: any) {
    console.log(ops.target?.categoryId);
  }

  @action
  sortEndAction() {
    this.saveFaqOrders.perform();
  }

  @action
  refreshFaqs() {
    this.loadFaqs.perform();
  }

  @task({ restartable: true, maxConcurrency: 1 })
  saveFaqOrders = taskFor(async () => {
    let order = 0;
    let itemsToSave: TaskInstance<void>[] = [];

    this.selectedCategory?.faqs.forEach((item) => {
      item.set('order', order++);
      itemsToSave.push(this.saveItem.perform(item));
    });

    await all(itemsToSave);
  });

  @task({ maxConcurrency: 4, enqueue: true })
  saveItem = taskFor(async (i) => {
    await i.save();
  });

  @action
  addFaq() {
    if (this.args.project) {
      let faq = this.store.createRecord('faq', {
        title: 'New FAQ',
        projectId: this.args.project.id,
        categoryId: this.selectedCategory?.categoryId,
      });
      this.editFaq(faq);
    }
    if (this.args.portalPage) {
      let faq = this.store.createRecord('faq', {
        title: 'New FAQ',
        portalPageId: this.args.portalPage.id,
        categoryId: this.selectedCategory?.categoryId,
      });
      this.editFaq(faq);
    }
  }

  @action
  editFaq(faq: Faq) {
    const appearance = {
      label: 'Edit FAQ',
      icon: '',
      size: 'large',
      title: `Edit FAQ: ${faq.get('title')}`,
      custom: true,
    };

    const context = {
      model: faq,
      onRemove: () => {
        this.initTask.perform();
      },
      onClose: () => {
        this.initTask.perform();
      },
    };

    this.docker.invokePopup(
      'manage-project/tab/faqs/faq-edit',
      appearance,
      context
    );
  }
}
