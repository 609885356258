import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment';
import { action } from '@ember/object';

//@ts-ignore
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AppEventsService from 'cing-app/pods/app-events/service';
import SessionService from 'cing-app/pods/session/service';
import {
	Expressions,
	Filter,
	FilterOperators,
} from 'cing-app/mixins/filter-builder';
//@ts-ignore
import podNames from "ember-component-css/pod-names";
import { dasherize } from "@ember/string";
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import DataLinkModel from 'cing-app/models/data-link-model';
import DataLinkModelColumn from 'cing-app/models/data-link-model-column';
//@ts-ignore
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import DataLink from 'cing-app/models/data-link';
import DataLinkView from 'cing-app/models/data-link-view';
import DataLinkViewColumn from 'cing-app/models/data-link-view-column';
import { set } from '@ember/object';
import injectScripts from 'ember-inject-scripts';

import ModelPropertyPlugin from './model-property-plugin/index';

interface ManageDataLinkViewEditListArgs {
	context?: {
		model: DataLinkView;
		dataLink: DataLink
	};
	onClose?: () => void;
	header?: any;
	footer?: any;
}

type ModelProperty = { property: string, isFromExtended: boolean };

export default class DataLinkDetail extends Component<ManageDataLinkViewEditListArgs> {
	@service
	config: any;

	@service
	store!: Store;

	@service
	cookies: any;

	@service
	appEvents!: AppEventsService;

	@service('docker-item')
	docker!: DockerItemService;

	@service
	session!: SessionService;

	@tracked
	error: any

	@tracked
	editProp?: DataLinkViewColumn;

	@tracked
	editor?: any;

	@tracked grapesEditor = null;
	@tracked grapesElement!: HTMLElement;

	@alias('args.context.model') model!: DataLinkView;
	@alias('args.context.dataLink') dataLink!: DataLink;

	get styleNamespace() {
		return podNames["manage-data-links/detail/views/edit-detail"];
	}

	constructor(owner: any, args: ManageDataLinkViewEditListArgs) {
		super(owner, args);
		this.initTask.perform();
	}


	get modelProperties() {
		let dataLinkModel: DataLinkModel | undefined = this.dataLink.models.findBy('modelName', this.model.modelName);

		return dataLinkModel?.columns;
	}

	@task
	initTask = taskFor(async (): Promise<void> => {
		await injectScripts([{
			id: 'grapesjs',
			src: '/libraries/grapesjs/grapes.min.js',
			once: true
		}]);

		/*
		await injectScripts([{
			id: 'grapesjs-blocks-basic',
			src: '/libraries/grapesjs-blocks-basic/grapesjs-blocks-basic.min.js',
			once: true
		}]);
		*/

		await injectScripts([{
			id: 'grapesjs-blocks-bootstrap4',
			src: '/libraries/grapesjs-bootstrap4/grapesjs-blocks-bootstrap4.min.js',
			once: true
		}]);

		ModelPropertyPlugin();

		if (!this.model.layout) {
			set(this.model, 'layout', this.store.createFragment('data-link-view-layout', {}));
		}
		//this.loadExtendedData.perform();
	});

	@action
	update() {
		this.updateTask.perform(this.dataLink);
	}

	@task
	updateTask = taskFor(async (model: DataLink): Promise<DataLink> => {
		console.log("MODEL: ", model);
		await model.save();

		return model;
	});

	@action
	changeProp(propName: 'maxWidth' | 'minWidth' | 'maxHeight', column: DataLinkViewColumn, evt: Event) {
		let text = (<HTMLInputElement>evt.target).value;
		let value = (text ? parseInt(text) : null);
		if (value !== null && Number.isInteger(value)) {
			column[propName] = value;
		} else {
			//@ts-ignore
			column[propName] = null;
		}
	}

	@action
	editPropertyFormat(column: DataLinkViewColumn) {
		this.editProp = column;
	}

	@action
	updatePropertyFormat(column: DataLinkViewColumn) {
		if (this.editProp) {
			this.editProp = undefined;
		}
	}


	@action
	reorderItems(columns: DataLinkViewColumn[]) {
		columns.forEach((col, i) => {
			if (col.displayOrder !== i) {
				col.displayOrder = i;
			}
		});
	}

	@action
	addProperty(prop: DataLinkModelColumn) {
		try {
			this.editor.execCommand('mceInsertContent', false, `{{model.${prop.propertyName}}}`);

			/*
			//@ts-ignore
			let newColumn = this.store.createFragment('data-link-view-column', <DataLinkViewColumn>{
				propertyName: prop.propertyName,
				label: prop.label,
				format: prop.format,
				isFromExtended: prop.isFromExtended
			})

			this.model.columns.pushObject(newColumn);

			console.log("COLUMNS: ", this.model.columns);
			*/
		} catch (e) {
			console.log("E: ", e)
		}
	}

	@action
	removeProperty(column: DataLinkViewColumn) {
		this.model.columns.removeObject(column);
	}

	@action
	onEditorInit(editor: any) {
		this.editor = editor;
	}


	@action
	setupGrapes(element: HTMLElement) {
		this.grapesElement = element || this.grapesElement;

		this.cleanupGrapes();

		var editor = grapesjs.init({
			fromElement: false,
			container: this.grapesElement.getElementsByClassName('gjs-editor')[0],
			blockManager: {
				appendTo: this.grapesElement.getElementsByClassName('blocks-container')[0]
			},
			selectorManager: {
				componentFirst: true,
			},
			//plugins: ["model-property", "grapesjs-blocks-bootstrap4"],
			plugins: ["model-property", "grapesjs-blocks-bootstrap4"],
			//plugins: ["grapesjs-blocks-basic", "model-property"],
			components: this.model.layout.components || this.model.layout.html,
			style: this.model.layout.style || this.model.layout.css,
			height: 'auto',
			width: 'auto',
			//panels: { defaults: [] },
			pluginsOpts: {
				'model-property': {
					properties: this.modelProperties
				},
				'grapesjs-blocks-bootstrap4': {
					blocks: {
						//default: false,
						text: false,
						//paragraph: false,
					}
				}
			},
			storageManager: {
				autoload: false,
			},
			colorPicker: { appendTo: 'parent', offset: { top: 20, left: -175 } },
			canvas: {
				styles: [
					'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css'
				],
				scripts: [
					'https://code.jquery.com/jquery-3.3.1.slim.min.js',
					'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js',
					'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js'
				],
			}
			/*
			assetManager: {
				storageType: '',
				storeOnChange: true,
				storeAfterUpload: true,
				upload: 'https://localhost/assets/upload',
				multiUpload: true,
				assets: model.templateAssets || [],
				uploadFile: function (e) {
					var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
					let fileToUpload = files[0];

					return seActions.uploadImage(fileToUpload, "", true, fileToUpload.name).then((result) => {
						var assets = [{
							'type': 'image',
							'src': result.url
						}];
						editor.AssetManager.add(assets);
					});
				},
			}
			*/
		});

		this.grapesEditor = editor;

		editor.on('storage:start:store', (objectToStore) => {
			set(this.model, 'layout.components', JSON.parse(objectToStore.components));
			set(this.model, 'layout.html', objectToStore.html);
			set(this.model, 'layout.style', JSON.parse(objectToStore.styles));
			set(this.model, 'layout.css', objectToStore.css);
			set(this.model, 'layout.assets', JSON.parse(objectToStore.assets));
		});
	}

	@action
	cleanupGrapes() {
		if (this.grapesEditor) {
			this.grapesEditor.destroy();
		}
	}
}
