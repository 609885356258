import Component from '@glimmer/component';
import { get, set, action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, timeout, task } from 'ember-concurrency';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import Project from 'cing-app/models/project';
import { taskFor } from 'ember-concurrency-ts';
import {
  PartyRoleTypes,
  PartyRole,
  ECrmImportStatus,
  ProjectTabMap,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
} from 'cing-app/pods/smartroom/service';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import { AclPermissionType } from 'cing-app/utils/lookups';
import TableTheme from '../abstract-import/table-theme';
import ImportRow from '../abstract-import/import-row';

interface ImportCrmContactsArgs {
  header: any;
  footer: any;
  context: any;
  onClose: any;
}

export default class ImportCrmContacts extends Component<ImportCrmContactsArgs> {
  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];

  @service store!: Store;

  @service session!: any;

  @service smartroom: any;

  @service config;

  @service seActions;

  @service('docker-item')
  docker: any;

  @tracked
  smartRoomId: null = null;

  @tracked
  smartRoomFolderId: null = null;

  @tracked
  newParties: [] = [];

  @tracked
  importRows: any;

  @tracked
  selectedItems: [] = [];

  @tracked
  interestedPartyRoles: any;

  @tracked
  interestedPartyTypes: any;

  @tracked
  importAction: any;

  @tracked
  setRole: any;

  @tracked
  project: any;

  interestedParty = null;
  partyType = null;
  companySearchPage = 1;

  constructor(owner: any, args: ImportCrmContactsArgs) {
    super(owner, args);
    this.project = this.args.context.project;
    this.initTask.perform();
  }

  get themeInstance() {
    return TableTheme.create();
  }

  get partyRoleIds() {
    let roles = [];

    for (let prop in PartyRoleTypes) {
      roles.push(PartyRoleTypes[prop]);
    }

    return roles;
  }

  get partyRoles() {
    let roles = {};

    for (let prop in PartyRole) {
      roles[PartyRole[prop]] = prop;
    }

    return roles;
  }

  @task
  initTask = taskFor(async () => {
    let srConnection = this.context.srConnection;

    if (srConnection) {
      this.smartRoomId = srConnection.SRSiteId;
      this.smartRoomFolderId = srConnection.SRFolderId;
    }

    this.importRows = [];

    let interestedPartyRoles = await this.store.query('interested-party-role', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });

    this.interestedPartyRoles = interestedPartyRoles.toArray();

    let interestedPartyTypes = await this.store.query('interested-party-type', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });

    this.interestedPartyTypes = interestedPartyTypes.toArray();
  });

  get columns() {
    let columns = [
      {
        title: '',
        component: 'manage-interested-party/abstract-import/column-actions',
        className: 'column-actions',
        selectedItems: this.selectedItems,
      },
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
      {
        propertyName: 'rowData.Company',
        //component: "manage-interested-party/abstract-import/column-company",
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Affiliation',
      },
      {
        propertyName: 'rowData.ContactName',
        component: 'manage-interested-party/abstract-import/column-person',
        title: 'Contact Name',
      },
      {
        propertyName: 'rowData.ContactEmail',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Contact Email',
      },
      {
        propertyName: 'rowData.Type',
        component: 'manage-interested-party/abstract-import/column-select',
        createModelName: 'interested-party-type',
        options: this.interestedPartyTypes,
        title: 'Type',
      },
      {
        propertyName: 'rowData.Role',
        //component: "manage-interested-party/abstract-import/column-editable",
        options: this.interestedPartyRoles,
        component: 'manage-interested-party/abstract-import/column-select',
        createModelName: 'interested-party-role',
        title: 'Role',
      },
    ];
    return columns;
  }

  @task
  addPartiesToProjectTask = taskFor(async () => {
    await this.finalizeImportTask.perform();

    if (this.args.context.onComplete) {
      this.args.context.onComplete();
    }

    if (this.args.onClose) {
      this.args.onClose();
    }
  });

  @task
  createSecurityProfilesAndApplyRights = taskFor(async () => {
    let query = Expressions.create();

    query.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.project.id,
      })
    );

    query.add(
      Filter.create({
        name: 'email-id',
        operator: FilterOperators.NOT_NULL,
      })
    );

    let emails = [];

    this.importRows.forEach((item) => {
      if (item.rowData.ContactEmail) {
        emails.push(item.rowData.ContactEmail);
      }
    });

    if (emails.length) {
      query.add(
        Filter.create({
          name: 'email.emailAddress',
          operator: FilterOperators.IN,
          value: emails,
        })
      );

      let parties = (
        await this.store.query('interested-party', {
          condition: query.serialize(),
          include: 'email,investor',
          page: {
            size: 1000,
            number: 1,
          },
        })
      ).toArray();

      let allTasks = [];

      for (var a = 0; a < parties.length; a++) {
        allTasks.push(this.createInvestorCategoryTask.perform(parties[a]));
      }

      await all(allTasks);
    }
  });

  @task({ maxConcurrency: 4, enqueue: true })
  createInvestorCategoryTask = taskFor(async (party) => {
    let emailAddress = (await get(party, 'email.emailAddress')).toLowerCase();
    let category = null;

    try {
      category = await this.smartroom.createCategory(
        this.smartRoomId,
        emailAddress
      );
    } catch (e) {
      // try to match to existing profile since SmartRoom says it already exists
      if (e instanceof CategoryAlreadyExists) {
        let securityProfiles = await this.store.query('smartroom/category', {
          siteId: this.smartRoomId,
        });

        category = securityProfiles.find((item) => {
          return item.categoryName.toLowerCase() === emailAddress;
        });
      } else {
        throw e;
      }
    }

    // if no category was found, throw an error
    if (!category) {
      throw new Error('Security profile could not be created');
    }

    party.srProfile = category.id;
    party.save();

    let investor = await get(party, 'investor');

    let investorFolder = await this.store.queryRecord('smartroom/folder', {
      siteId: this.smartRoomId,
      id: investor.smartRoomFolderId,
    });

    // apply security rights for all folders in path
    let folderPathItems = investorFolder.pathIds.split('/');

    let folderSecurities = [];

    // apply security for investor folder (view, print, save)
    folderSecurities.push({
      itemId: investorFolder.id,
      rightId: 32,
    });

    // apply security for parent folders (up to SmartRoom root folder)
    folderPathItems.forEach((folderId) => {
      if (folderId) {
        folderSecurities.push({
          itemId: folderId,
          rightId: 32,
        });
      }
    });

    await this.smartroom.setCategorySecurity(
      this.smartRoomId,
      category.id,
      folderSecurities
    );
  });

  @task
  prepareContactsReview = taskFor(async () => {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    expr.add(
      Filter.create({
        name: 'action-id',
        operator: FilterOperators.EQUAL,
        value: this.importAction.id,
      })
    );

    let importRows = (
      await this.store.query('crm-import', {
        condition: expr.serialize(),
        page: {
          size: 10000,
        },
        sort: 'import-order',
      })
    ).toArray();

    let columnsInImport = [];

    importRows.forEach((item) => {
      let keys = Object.keys(item.get('rowData').toJSON());
      keys.forEach((key) => {
        if (
          !columnsInImport.includes(key) &&
          get(item, `rowData.${key}`) !== null &&
          get(item, `rowData.${key}`) !== undefined
        ) {
          columnsInImport.push(key);
        }
      });
    });

    let columns = this.columns;

    for (var a = 0; a < columns.length; a++) {
      let column = columns[a];
      let columnName = columns[a].propertyName;

      if (columnName && columnName.startsWith('rowData.')) {
        columnName = columnName.slice(8);

        if (columnsInImport && columnsInImport.indexOf(columnName) == -1) {
          column.isHidden = true;
        }
      }
    }

    this.importRows = importRows;
    return columns;
  });

  @task
  finalizeImportTask = taskFor(async () => {
    let allTasks = [];

    for (var a = 0; a < this.importRows.length; a++) {
      let importRow = this.importRows[a];

      if (importRow.hasDirtyAttributes) {
        allTasks.push(this.updateCrmRecord.perform(this.importRows[a]));
      }
    }

    await all(allTasks);

    await this.seActions.importPartiesStepTwo(this.importAction);
  });

  @task({ maxConcurrency: 4, enqueue: true })
  savePartyRecord = taskFor(async (partyRecord) => {
    await partyRecord.save();
  });

  @task
  checkPartyDuplicity = taskFor(async (partyToAdd) => {
    this.newParties.filter((party) => {
      if (partyToAdd.personId) {
        throw new Error(
          'Contact ' +
            get(partyToAdd, 'person.fullName') +
            ' is already present in the list!'
        );
      }

      if (!partyToAdd.personId && partyToAdd.companyId) {
        throw new Error(
          'Contact ' +
            get(partyToAdd, 'company.name') +
            ' is already present in the list!'
        );
      }
    });

    let query = Expressions.create();

    query.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.project.id,
      })
    );

    let partyDescription = [];

    if (partyToAdd.personId) {
      query.add(
        Filter.create({
          name: 'person-id',
          operator: FilterOperators.EQUAL,
          value: partyToAdd.personId,
        })
      );

      partyDescription.push(get(partyToAdd, 'person.fullName'));
    }

    if (partyToAdd.companyId) {
      query.add(
        Filter.create({
          name: 'company-id',
          operator: FilterOperators.EQUAL,
          value: partyToAdd.companyId,
        })
      );

      partyDescription.push(get(partyToAdd, 'company.name'));
    }

    let existingParty = await this.store.query('interested-party', {
      condition: query.serialize(),
      page: {
        size: 1,
      },
    });

    if (existingParty.firstObject) {
      throw new Error(
        'Contact ' + partyDescription.join(', ') + ' is already added!'
      );
    }
  });

  @task
  addImportRowsTask = taskFor(async (parties) => {
    if (!Array.isArray(parties)) {
      parties = [parties];
    }

    for (var a = 0; a < parties.length; a++) {
      let party = parties[a];

      let company = await get(party, 'company');
      let person = await get(party, 'person');
      let email = await get(party, 'email');

      let rowData = this.store.createFragment('crm-import-row', {
        Company: company ? company.name : null,
        //CompanyId: (company? company.id: null),
        ContactName: person ? person.fullName : null,
        //PersonId: (person ? person.id: null),
        ContactEmail: email ? email.emailAddress : null,
      });

      this.importRows.pushObject({
        rowData: rowData,
      });
    }

    await this.createImportAction.perform();
  });

  @task
  createImportAction = taskFor(async () => {
    if (!this.importRows || !this.importRows.length) {
      return;
    }

    let importRows = this.importRows;
    let jsonData = [];

    for (var a = 0; a < importRows.length; a++) {
      jsonData.push(importRows[a].rowData);
    }

    let importAction = await this.seActions.importPartiesStepOne(
      new Blob([JSON.stringify(jsonData)], { type: 'application/json' }),
      this.project.id
    );

    this.importAction = importAction;

    await this.prepareContactsReview.perform();
  });

  @task
  approveContactsImportTask = taskFor(async () => {
    let items = this.selectedItems.toArray();
    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      set(item, 'importStatus', ECrmImportStatus.Approved);
      itemsToSave.push(this.updateCrmRecord.perform(item));
    }

    await all(itemsToSave);
  });

  @task
  rejectContactsImportTask = taskFor(async () => {
    let items = this.selectedItems.toArray();
    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      set(item, 'importStatus', ECrmImportStatus.Rejected);
      itemsToSave.push(this.updateCrmRecord.perform(item));
    }

    await all(itemsToSave);
  });

  @task
  updateRoleTask = taskFor(async (party) => {
    this.selectedItems.removeObject(party);
    this.selectedItems.unshiftObject(party);

    let items = this.selectedItems.toArray();
    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      set(item, 'rowData.Role', get(party, 'rowData.Role'));
      itemsToSave.push(this.updateCrmRecord.perform(item));
    }

    await all(itemsToSave);
    this.setRole = null;
  });

  @task({ enqueue: true, maxConcurrency: 4 })
  updateCrmRecord = taskFor(async (crmImport) => {
    await crmImport.save();
  });

  @action
  showCompanyDetail(company) {
    const appearance = {
      label: 'Company detail',
      icon: '',
      title: 'Company: ' + company.get('name'),
    };

    const context = { companyId: company.get('id') };
    this.docker.invokePopup('company-detail', appearance, context);
  }

  @action
  addPartiesToProject() {
    this.addPartiesToProjectTask.perform();
  }

  @action
  addImportRows(parties) {
    this.addImportRowsTask.perform(parties);
  }

  @action
  removeParty(party) {
    this.newParties.removeObject(party);
  }

  @action
  approveContactsImport() {
    this.approveContactsImportTask.perform();
  }

  @action
  rejectContactsImport() {
    this.rejectContactsImportTask.perform();
  }

  @action
  cancelSetRole() {
    this.setRole = false;
  }

  @action
  doUpdateRole(party) {
    this.updateRoleTask.perform(party);
  }

  @action
  updateRole(party) {
    if (!this.selectedItems) {
      this.selectedItems = [party];
    }
    this.setRole = party;
  }
}
