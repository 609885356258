import {
  attr,
  hasMany,
  belongsTo,
  AsyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
const modelValidations = buildValidations({
  fullName: [validator('presence', true)],
  publicProfileUrl: [
    validator('format', {
      type: 'url',
      allowBlank: true,
    }),
  ],
});
import { exportable } from 'cing-app/utils/exportable';
import { ContactTypeMap } from 'cing-app/utils/lookups';

import ParseFullName from 'cing-app/utils/parse-full-name';
import Email from 'cing-app/models/email';
import Phone from 'cing-app/models/phone';
import Address from 'cing-app/models/address';
import Company from 'cing-app/models/company';
import PersonInCompany from 'cing-app/models/person-in-company';
import User from 'cing-app/models/user';
export default class Person extends Abstract.extend(modelValidations) {
  @exportable()
  @attr('string', { defaultValue: '' })
  declare firstName?: string;
  @exportable()
  @attr('string', { defaultValue: '' })
  declare lastName?: string;
  @exportable()
  @attr('string', { defaultValue: '' })
  declare middleName?: string;
  @exportable()
  @attr('number', { defaultValue: 1 })
  declare contactType?: number;
  @exportable()
  @attr('string', { defaultValue: '' })
  declare fullName: string;
  // master: DS.belongsTo('attorney', {inverse: 'master'}),
  @attr('boolean') declare deleted?: boolean;
  @exportable()
  @attr('string')
  declare prefix?: string;
  @exportable()
  @attr('string')
  declare nickname?: string;
  @exportable()
  @attr('string')
  declare photo?: string;
  @exportable()
  @attr('string')
  declare background?: string;
  @exportable()
  @attr('string')
  declare suffix?: string;
  @exportable()
  @attr('string')
  declare publicProfileUrl?: string;
  @exportable()
  @attr('number')
  declare gender?: number;
  @hasMany('email') declare emails: AsyncHasMany<Email>;
  @exportable()
  @attr('string')
  declare dealCloudId: string;
  @exportable()
  @attr('string')
  declare note: string;
  @exportable()
  @attr('date')
  declare birthDate: Date;
  @hasMany('phone') declare phones: AsyncHasMany<Phone>;
  @hasMany('address') declare addresses: AsyncHasMany<Address>;
  @belongsTo('company', { inverse: null })
  declare company: AsyncBelongsTo<Company>;
  @hasMany('company', { inverse: null })
  declare companies: AsyncHasMany<Company>;
  @hasMany('person-in-company')
  declare personInCompanies: AsyncHasMany<PersonInCompany>;
  //@ts-ignore
  @belongsTo('user') declare user: AsyncBelongsTo<User>;

  // locationByLawFirm(lawFirmId) {
  //   let lawFirm = null;

  //   let attorneyInLawFirms = this.get('attorneyInLawFirms').then(function (attorneyInLawFirms) {
  //     attorneyInLawFirms.forEach(function (lawFirm) {
  //       console.log('LAW FIRM: ', lawFirm)
  //     })
  //   });
  // },

  parseFromFullName(fullName = '') {
    fullName = fullName.trim();

    let nameParts = ParseFullName(fullName, undefined, true, false, false);

    this.setProperties({
      prefix: nameParts.title,
      firstName: nameParts.first,
      middleName: nameParts.middle,
      lastName: nameParts.last,
      suffix: nameParts.suffix,
    });
  }

  updateFullName() {
    if (this.contactType !== ContactTypeMap.ENTITY) {
      this.set('fullName', this.createFullName());
    }
  }

  createFullName() {
    let nameParts = [];

    if (this.prefix) {
      nameParts.push(this.prefix);
    }
    if (this.firstName) {
      nameParts.push(this.firstName);
    }
    if (this.middleName) {
      nameParts.push(this.middleName);
    }
    if (this.lastName) {
      nameParts.push(this.lastName);
    }
    if (this.suffix) {
      nameParts.push(this.suffix);
    }

    return nameParts.join(' ');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    person: Person;
  }
}
