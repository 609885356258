import { tracked } from '@glimmer/tracking';
import { getProperties } from '@ember/object';
import { addMethod, array, object, setLocale } from 'yup';

export default class YupValidations {
  context = null;
  schema: any = null;
  shape = null;

  @tracked error = null;
  @tracked isValidating = false;
  @tracked isValid: undefined | boolean = undefined;

  constructor(context, shape) {
    this.context = context;
    this.shape = shape;
    this.schema = object().shape(shape);
  }

  get fieldErrors() {
    return this.error?.inner.reduce((acc, err) => {
      const key = err.path;

      if (!acc[key]) {
        acc[key] = [err];
      } else {
        acc[key].push(err);
      }

      return acc;
    }, {});
  }

  async validate() {
    this.isValidating = true;
    try {
      await this.schema.validate(this.#validationProperties(), {
        abortEarly: false,
        context: this.#validationProperties(),
      });

      this.error = null;
      this.isValid = true;
      this.isValidating = false;
      return true;
    } catch (error) {
      this.error = error;
      this.isValid = false;
      this.isValidating = false;
      return false;
    }
  }

  #validationProperties() {
    return getProperties(this.context, ...Object.keys(this.shape));
  }
}
// available locale on:
// https://github.com/jquense/yup/blob/master/src/locale.ts
setLocale({
  mixed: {
    default: `Field is invalid`,
    required: `Field is required`,
    oneOf: 'This field must be one of the following values: ${values}',
    notOneOf: 'This field must not be one of the following values: ${values}',
    defined: `This field must be defined.`,
  },
  string: {
    email: `This field must be a valid email.`,
  },
});

function extendYup() {
  addMethod(object, 'relationship', function () {
    return this.test(function (value) {
      return value.validations.validate();
    });
  });

  addMethod(array, 'relationship', function () {
    return this.transform(
      (_value, originalValue) => originalValue?.toArray() || []
    ).test(async function (value) {
      const validationsPassed = await Promise.all(
        value.map(({ validations }) => {
          return validations.validate();
        })
      );

      return validationsPassed.every((validation) => validation === true);
    });
  });
}

extendYup();
