import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { defineProperty } from '@ember/object';

@classic
export default class ColumnEditable extends Component {
  init() {
    super.init();
    let propertyName = this.get('column.propertyName');

    defineProperty(this, 'value', alias(`record.${propertyName}`));
  }

  click(e) {
    return false;
  }
}