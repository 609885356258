import Component from '@glimmer/component';
import User from 'cing-app/models/user';

interface ColumnComponentArgs {
  record: User;
  column: any;
}

export default class ColumnComponent extends Component<ColumnComponentArgs>  {

  get getGroup() {
    return this.args.record[this.args.column.propertyName];
  }

}