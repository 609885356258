import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Models from 'smex-ui-sr-models/services/models';
import { pluralize } from 'ember-inflector';
import classic from 'ember-classic-decorator';

@classic
export default class AbDataAdapter extends JSONAPIAdapter {
  @service session;
  @service serverVariables;

  get headers() {
    let headers: Object = {};

    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';

    return headers;
  }

  @alias('serverVariables.appBuilderApiHostname') host!: string;
  @alias('serverVariables.appBuilderApiNamespace') namespace!: string;

  pathForType(modelName: string) {
    let match = modelName.match(/^appbuilder\/(.*)$/);
    if (match) {
      return `${pluralize(match[1])}`;
    } else {
      throw Error('Unknown model name: ' + modelName);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'appbuilder/ab-data-adapter': AbDataAdapter;
  }
}
