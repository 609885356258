import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  siteName: attr('string'),
  encryptionTypeId: attr('number'), // 0 - None, 1 - WithSite, 2 - WithSiteAndBinary, 3 - WithSiteAndClient
  topLevelFolderId: attr('number'),
  integratorKey: attr('string'),
  siteOwner: attr('boolean'),
  packageId: attr('number'),
  siteStatusType: attr('number'),
  slug: attr('string'),
  lastAccessDate: attr('string', { ro: true }),
  lastAccessDateInUtc: attr('string', { ro: true }),
  customization: attr('json', { ro: true }),
  timeZone: attr('string'),
  timeZoneIana: attr('string'),
  lastAccessDateInUtc_Date: computed('lastAccessDateInUtc', function () {
    let lastAccessDateInUtc = this.lastAccessDateInUtc;
    let date = new Date(lastAccessDateInUtc);
    return date;
  }),

  locked: computed('siteStatusType', function () {
    return this.siteStatusType === 2 || this.siteStatusType === 3;
  }),
  lockedDescription: computed('siteStatusType', function () {
    switch (this.siteStatusType) {
      case 2:
      case 3: return "Site is locked";
      default: return "";
    }
  })
});
