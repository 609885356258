import contexts from './bootstrap-contexts';
import { capitalize } from '@ember/string';
import DataLinkModelColumn from 'cing-app/models/data-link-model-column';
import DataLinkModel from 'cing-app/models/data-link-model';

export const ModelPropertyBlock = (bm: any, modelProperty: DataLinkModelColumn) => {
	bm.add(`model-property-${modelProperty.propertyName}`, {
		label: `
            <div>${modelProperty.label}</div>
        `,
		category: 'Properties',
		content: {
			type: `model-property`,
			content: `{{model.${modelProperty.propertyName}}}`,
			modelProperty: modelProperty.propertyName,
			modelPropertyLabel: modelProperty.label,
		}
	});
};

export default (domc) => {
	const textType = domc.getType('text');
	const textModel = textType.model;
	const textView = textType.view;

	domc.addType('model-property', {
		model: textModel.extend({
			defaults: Object.assign({}, textModel.prototype.defaults, {
				'custom-name': 'Model Property',
				tagName: 'span',
				modelProperty: null,
				modelPropertyLabel: null,
				copyable: true,
				removable: true,
				editable: true,

				classes: ['model-property'],
				traits: [
					{
						type: 'class_select',
						options: [
							{ value: '', name: 'None' },
							...contexts.map(function (v) { return { value: 'text-' + v, name: capitalize(v) } })
						],
						label: 'Context'
					}
				].concat(textModel.prototype.defaults.traits)
			})
		}, {
			isComponent(el: HTMLElement) {
				if (el && el.classList && el.classList.contains('model-property')) {
					return { type: 'model-property' };
				}
			}
		}),
		view: textView.extend({
			onRender({ el, model }) {
				el.textContent = `[${model.attributes.modelPropertyLabel}]`;
				el.className += " text-muted";
			}
		})
	});
}