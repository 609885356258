import { validator, buildValidations } from 'ember-cp-validations';
import { computed, get, set } from '@ember/object';
import { exportable } from 'cing-app/utils/exportable';
import {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import Abstract from './abstract';
import ProjectType from './project-type';
import ProjectStatus from './project-status';
import ProjectPriority from './project-priority';
import ProjectStage from './project-stage';
import Person from './person';
import CaseProjectUrlModel from './case-project-url';
import ProjectTabModel from './project-tab';
import Company from './company';
import Case from './case';
import Fund from './fund';
import InterestedParty from './interested-party';
import Event from './event';
import DebtorProfile from './debtor-profile';
import AppbuilderLink from './appbuilder-link';

const ProjectValidations = buildValidations({
  progressPercentage: validator('number', {
    allowBlank: true,
    allowString: true,
    integer: true,
    gte: 0,
    lte: 100,
  }),
  rating: validator('number', {
    allowBlank: true,
    allowString: true,
    integer: true,
  }),
});

export default class Project extends Abstract.extend(ProjectValidations) {
  @exportable()
  @attr('string')
  declare name?: string;
  @exportable()
  @attr('string')
  declare description?: string;
  @exportable()
  @attr('date')
  declare dateStarted?: Date;
  @exportable()
  @attr('date')
  declare dateCompleted?: Date;
  @attr('string')
  declare companyId?: string;
  @attr('string')
  declare caseId?: string;
  @attr('string')
  declare fundId?: string;
  @attr('string')
  declare companyProfileId?: string;
  @exportable()
  @attr('string')
  declare userId?: string;
  @attr('jsonb') declare settings?: any;
  @exportable()
  @attr('number')
  declare rating?: number;
  @exportable()
  @attr('number')
  declare progressPercentage?: number;
  @exportable()
  @attr('string')
  declare progress?: string;
  @exportable()
  @attr('number')
  declare sortOrder?: number;
  @attr('string')
  declare projectLeadId?: string;
  @attr('string')
  declare projectManagerId?: string;
  @exportable()
  @belongsTo('project-type')
  declare projectType: AsyncBelongsTo<ProjectType>;
  @exportable()
  @belongsTo('project-status')
  declare projectStatus: AsyncBelongsTo<ProjectStatus>;
  @exportable()
  @belongsTo('project-priority')
  declare projectPriority: AsyncBelongsTo<ProjectPriority>;
  @exportable()
  @belongsTo('project-stage')
  declare projectStage: AsyncBelongsTo<ProjectStage>;
  @exportable()
  @belongsTo('person')
  declare projectLead: AsyncBelongsTo<Person>;
  @belongsTo('person')
  declare projectManager: AsyncBelongsTo<Person>;

  @hasMany('case-project-url')
  declare projectUrls: AsyncHasMany<CaseProjectUrlModel>;
  @hasMany('project-tab')
  declare projectTabs: AsyncHasMany<ProjectTabModel>;
  @hasMany('project-tab', { inverse: null })
  declare projectTabsByViewGroup: AsyncHasMany<ProjectTabModel>;
  @belongsTo('company') declare company: AsyncBelongsTo<Company>;
  @belongsTo('case') declare case: AsyncBelongsTo<Case>;
  @belongsTo('fund') declare fund: AsyncBelongsTo<Fund>;
  @hasMany('interestedParty')
  declare interestedParties: AsyncHasMany<InterestedParty>;
  @hasMany('event', { async: true, inverse: null })
  declare events: AsyncHasMany<Event>;
  @hasMany('appbuilder-link', { async: true, inverse: null })
  declare dataConnections: AsyncHasMany<AppbuilderLink>;
  @belongsTo('debtor-profile')
  declare companyProfile: AsyncBelongsTo<DebtorProfile>;
  @exportable()
  @hasMany('company', { inverse: null })
  declare companies: AsyncHasMany<Company>;

  @computed('settings.ab_project')
  get abProject() {
    let abProject = null;
    if (this.settings && this.settings.ab_project) {
      let projectAndFund = this.settings.ab_project.split(':');
      abProject = projectAndFund[0].toLowerCase();
    }

    return abProject;
  }

  @computed('settings.ab_fund')
  get abFund() {
    return this.settings && this.settings.ab_fund
      ? this.settings.ab_fund
      : null;
  }

  @computed('settings.deal_cloud_fund_id')
  get dealCloudFund() {
    if (this.settings && this.settings.deal_cloud_fund) {
      return this.settings.deal_cloud_fund;
    }
    return {
      name: '',
      id: '',
    };
  }
  set dealCloudFund(dcFund) {
    if (!this.settings) {
      set(this, 'settings', {});
    }

    set(this.settings, 'deal_cloud_fund', {
      name: dcFund ? dcFund.name : null,
      id: dcFund ? dcFund.id : null,
    });
  }

  @computed('settings.investor_fund_id_prefix')
  get investorFundIdPrefix() {
    if (this.settings && this.settings.investor_fund_id_prefix) {
      return this.settings.investor_fund_id_prefix;
    }

    return null;
  }
  set investorFundIdPrefix(value) {
    if (!this.settings) {
      set(this, 'settings', {});
    }

    set(this.settings, 'investor_fund_id_prefix', value);
  }

  @computed('settings.logo')
  get logo() {
    if (this.settings && this.settings.logo) {
      return this.settings.logo;
    }

    return null;
  }
  set logo(value) {
    if (!this.settings) {
      set(this, 'settings', {});
    }

    set(this.settings, 'logo', value);
  }

  @computed('settings.tile_image')
  get tile_image() {
    if (this.settings && this.settings.tile_image) {
      return this.settings.tile_image;
    }

    return null;
  }
  set tile_image(value) {
    if (!this.settings) {
      set(this, 'settings', {});
    }

    set(this.settings, 'tile_image', value);
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    project: Project;
  }
}
