import {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { SourceType } from 'cing-app/utils/lookups';
import { inject as service } from '@ember/service';
import sanitizeHtml from 'ember-cli-sanitize-html';
import DocketType from './docket-type';
import DocketNote from './docket-note';
import Case from './case';
import Abstract from './abstract';

export default class DocketAttachment extends Abstract.extend(
  computedEnumMixin('source', 'sourceType', SourceType)
) {
  @service config: any;

  @attr('number') declare docketNumber?: number;
  @attr('string') declare docketUrl?: string;
  @attr('string') declare pacerUrl?: string;
  @attr('date') declare filingDate: Date;
  @attr('date') declare publishedDate?: Date;
  @attr('string') declare description?: string;
  @attr('string') declare title?: string;
  @attr('string') declare filedByAttorney?: string;
  @attr('number') declare numberOfPages?: number;
  @attr('number') declare numberOfDocuments?: number;
  @attr('string') declare caseId: string;
  @attr('number') declare sourceType?: number;
  @attr('string') declare parentId?: string;
  @belongsTo('docketType') declare docketType: AsyncBelongsTo<DocketType>;
  @hasMany('docketNote') declare docketNotes: AsyncHasMany<DocketNote>;
  @attr('string') declare fileMetadata?: string;
  @attr('string') declare fileMetadataDate?: string;
  @belongsTo('case') declare 'case': AsyncBelongsTo<Case>;

  get publicTitle() {
    if (this.get('title')) {
      return this.get('title');
    } else {
      if (this.get('description')) {
        return sanitizeHtml(this.get('description'), { allowedTags: [] });
      } else if (this.get('docketType.name')) {
        return this.get('docketType.name');
      }
    }
  }

  get contentURL() {
    if (this.get('fileMetadata')) {
      return `${this.get('config.APP.api.host')}/${this.get(
        'config.APP.api.namespace'
      )}/dockets/content/${this.get('id')}`;
    }
    return null;
  }

  get downloadURL() {
    if (!this.get('fileMetadata')) {
      return `${this.get('config.APP.api.host')}/${this.get(
        'config.APP.api.namespace'
      )}/dockets/download/${this.get('id')}`;
    }
    return null;
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'coket-attachment': DocketAttachment;
  }
}
