import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class DcInvestorNumber extends Transform {
  deserialize(serialized: any) {
    if (serialized && /^\d+\.+\d+$/.test(serialized)) {
      return parseInt(serialized, 10);
    } else {
      return serialized;
    }
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'dc-investor-number': DcInvestorNumber;
  }
}
