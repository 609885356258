import Route from '@ember/routing/route';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { Query, Filter, FilterOperators } from 'cing-app/utils/query-builder';
import { ConnectionTypes, ABConnectionTypes } from 'cing-app/utils/lookups';
export default class DashboardSmartrooms extends Route {
  @service
  declare store: Store;

  model() {
    let query = new Query([
      new Filter('type', FilterOperators.EQUAL, ConnectionTypes.APPBUILDER),
      new Filter(
        'ab-type',
        FilterOperators.EQUAL,
        ABConnectionTypes.SMARTROOM_STATS
      ),
      new Filter('project-id', FilterOperators.NULL),
    ]);

    return this.store
      .query('appbuilder-link', {
        condition: query.serialize(),
        page: {
          size: 1,
        },
      })
      .then((results) => {
        return results.firstObject;
      });
  }
}
