import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { get, action } from '@ember/object';

@classic
@classNames('custom-control custom-control-inline custom-checkbox')
export default class CheckboxWithLabel extends Component {
  positionalParams = ['checked', 'text'];
  checked = false;

  click(e) {
    if (!this.get('disabled')) {
      this.send('toggleChecked');
    }

    e.preventDefault();
    return false;
  }

  @action
  toggleChecked() {
    this.set('checked', !this.get('checked'));

    this.sendAction('update');
  }
}
