import { inject } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment';
import { action } from '@ember/object';

//@ts-ignore
import Bootstrap4Theme from 'ember-models-table/themes/bootstrap4';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import Project from 'cing-app/models/project';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AppEventsService from 'cing-app/pods/app-events/service';
import _SessionService from 'cing-app/pods/session/service';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import { dasherize } from '@ember/string';
import ExportDataService from 'cing-app/pods/export-data/service';
import { ApiDataSource, DataSourceColumn, Paged } from 'smex-ui-table';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { get } from '@ember/object';
import Person from 'cing-app/models/person';
import Company from 'cing-app/models/company';

interface ProjectListArgs {
  context?: {
    mandatorySerchTerm: boolean;
  };
  onClose?: () => void;
  header?: any;
  footer?: any;
}

let ApiFooterTheme = Bootstrap4Theme.extend({
  table: 'table table-bordered table-hover table-sm',
  buttonDefault: 'btn btn-link',
  paginationInternalWrapper: '',
  paginationWrapperNumeric: '',
  paginationWrapperDefault: '',
  pageSizeWrapper: '',
  components: {
    'columns-dropdown': 'api-table/columns-dropdown',
  },
});

export default class ProjectList extends Component<ProjectListArgs> {
  @inject()
  config: any;

  get apiFooterTheme() {
    return ApiFooterTheme.create();
  }

  @inject()
  store!: Store;

  @inject
  exportData!: ExportDataService;

  @inject()
  cookies: any;

  @inject()
  appEvents!: AppEventsService;

  @inject('docker-item')
  docker!: DockerItemService;

  @inject()
  session!: _SessionService;

  restoreSearch = false;

  @tracked
  includes = [
    'case',
    'company',
    'company-profile',
    'project-type',
    'project-status',
    'fund',
    'project-lead',
    'project-priority',
  ];
  // ProjectFilters = ProjectFilters;

  // @alias('context.searchAndSelect')
  // searchAndSelect;

  @tracked
  canQuery = true;

  @tracked
  reloadData = false;

  @tracked
  filterText = '';

  @tracked
  condition = '';

  @tracked
  extraQueryParams = null;

  @tracked
  filterProperty = '';

  @tracked
  filterValue = '';

  @tracked
  dataSource!: ApiDataSource<Project>;

  get styleNamespace() {
    return podNames['project-list'];
  }

  constructor(owner: any, args: ProjectListArgs) {
    super(owner, args);

    this.dataSource = new ApiDataSource<Project>(
      100,
      false,
      'Projects',
      this.session.authUser.email,
      this.loadProjectsTask,
      100,
      this.columns,
      null,
      {}
    );
    this.dataSource.selectionEnabled = false;

    this.appEvents.on('watchlistChanged', this.dataSource.refresh);
    this.appEvents.on('projectsByTypeChanged', this.filterByProjectType);
  }

  willDestroy() {
    super.willDestroy();
    this.appEvents.off('watchlistChanged', this.dataSource.refresh);
    this.appEvents.off('projectsByTypeChanged', this.filterByProjectType);
  }

  @action
  filterByProjectType(filterKey: string, value: string) {
    this.filterProperty = dasherize(filterKey) + '.id';
    this.filterValue = value;
    this.search(this.filterText);
  }

  get columns() {
    let columns = [
      new DataSourceColumn<Project>({
        id: 'name',
        label: 'Name',
        getValue: (row) => row.name,
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        options: { onClick: this.showProjectDetail },
      }),
      new DataSourceColumn<Project>({
        id: 'company.name',
        label: 'Company',
        getValue: (row) => get(row, 'company.name'),
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        options: { onClick: this.showCompanyDetail },
      }),
      new DataSourceColumn<Project>({
        id: 'project-type.name',
        label: 'Type',
        getValue: (row) => get(row, 'projectType.name'),
        valueComponent: 'table-text-column',
        minWidth: 150,
        sortingEnabled: true,
      }),
      new DataSourceColumn<Project>({
        id: 'project-lead.full-name',
        label: 'Project Lead',
        getValue: (row) => get(row, 'projectLead.fullName'),
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        options: { onClick: this.showDetailProjectLead },
      }),
      new DataSourceColumn<Project>({
        id: 'project-priority.name',
        label: 'Priority',
        getValue: (row) => get(row, 'projectPriority.name'),
        valueComponent: 'table-text-column',
        minWidth: 80,
        sortingEnabled: true,
      }),
      new DataSourceColumn<Project>({
        id: 'project-status.name',
        label: 'Status',
        getValue: (row) => get(row, 'projectStatus.name'),
        valueComponent: 'table-text-column',
        minWidth: 130,
        sortingEnabled: true,
      }),
      new DataSourceColumn<Project>({
        id: 'rating',
        label: 'Rating',
        getValue: (row) => get(row, 'rating'),
        valueComponent: 'table-text-column',
        minWidth: 80,
        sortingEnabled: true,
      }),
      new DataSourceColumn<Project>({
        id: 'progress-percentage',
        label: 'Progress %',
        getValue: (row) => get(row, 'progressPercentage'),
        valueComponent: 'project-list/column-progress',
        minWidth: 150,
        sortingEnabled: true,
      }),
      new DataSourceColumn<Project>({
        id: 'date-started',
        label: 'Date started',
        getValue: (row) => get(row, 'dateStarted'),
        valueComponent: 'table-text-column',
        minWidth: 120,
        sortingEnabled: true,
        sort: 'desc',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<Project>({
        id: 'modify-time',
        label: 'Modify time',
        getValue: (row) => get(row, 'modifyTime'),
        valueComponent: 'table-text-column',
        minWidth: 180,
        sortingEnabled: true,
        hidden: true,
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          },
        },
      }),
    ];

    return columns;
  }

  @task
  loadProjectsTask = taskFor(
    async (
      columns: DataSourceColumn<Project>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let query: any = {
        condition: this.condition,
        include: this.include,
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
      };

      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let projects = await this.store.query('project', query);

      let pProjects = <Paged<Project>>projects.toArray();
      pProjects.meta = {
        totalCount: projects.meta['total-count'],
      };
      return pProjects;
    }
  );

  get include() {
    return this.includes.join(',');
  }

  @action
  generateFilter() {
    let resultQuery = Expressions.create({ operator: ExpressionOperators.AND });
    let settingsQuery = Expressions.create({
      operator: ExpressionOperators.OR,
    });

    if (this.filterText) {
      settingsQuery.add(
        Filter.create({
          //@ts-ignore
          name: 'name',
          operator: FilterOperators.LIKE,
          //@ts-ignore
          value: this.filterText,
        })
      );

      settingsQuery.add(
        Filter.create({
          //@ts-ignore
          name: 'company.name',
          operator: FilterOperators.LIKE,
          //@ts-ignore
          value: this.filterText,
        })
      );

      resultQuery.add(settingsQuery);
    }

    if (this.filterProperty && this.filterValue) {
      resultQuery.add(
        Filter.create({
          //@ts-ignore
          name: this.filterProperty,
          operator: FilterOperators.EQUAL,
          //@ts-ignore
          value: this.filterValue,
        })
      );
    }
    if (this.filterProperty && this.filterValue === null) {
      resultQuery.add(
        Filter.create({
          //@ts-ignore
          name: this.filterProperty,
          operator: FilterOperators.NULL,
          //@ts-ignore
          //value: this.filterValue
        })
      );
    }

    this.condition = resultQuery.serialize();
  }

  @action
  addProject() {
    let project = this.store.createRecord('project', {
      name: 'Project ' + moment().format('YYYY-MM-DD'),
      dateStarted: moment().format('YYYY-MM-DDTHH:mm:ss'),
    });

    const appearance = {
      label: 'Create project',
      icon: '',
      size: 'large',
      title: 'Create project',
      custom: true,
    };

    const context = {
      project: project,
      onAdd: () => {
        this.dataSource.refresh();
      },
      tab: 'details',
    };
    this.docker.invokePopup('manage-project', appearance, context);
  }

  // @action
  // async exportProjects() {
  //   this.exportData.export('project', Project, 'name', this.generateFilter);
  // }

  @action
  search(newText: string) {
    this.filterText = newText;
    this.generateFilter();
    this.dataSource.refresh();
  }

  @action
  showProjectDetail(row: Project) {
    const appearance = {
      label: 'Project',
      icon: '',
      title: '<small>Project:</small> ' + get(row, 'name'),
      size: 'large',
      status: 'full',
      custom: true,
    };
    this.docker.invokePopup('project-detail', appearance, {
      project: row,
    });
  }

  @action
  showCompanyDetail(row: Project) {
    this.docker.popupCompany(row.company);
  }

  @action
  showDetailProjectLead(row: Project) {
    row.get('projectLead').then((projectLead: Person) => {
      if (!this.isDestroyed) {
        projectLead.get('company').then((company: Company) => {
          if (!this.isDestroyed) {
            this.docker.popupPerson(projectLead, company);
          }
        });
      }
    });
  }
}
