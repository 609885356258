import classic from 'ember-classic-decorator';
import ApplicationAdapter from './application';

@classic
export default class DocketFolderAdapter extends ApplicationAdapter {
  urlForQuery(query: any, modelName: any) {
    // let path = this._super(...arguments);
    let path = super.urlForQuery(query, modelName);

    if (query.byRating) {
      delete query.byRating;

      let folderId = 'null';
      if (query.folderId) {
        folderId = query.folderId;
        delete query.folderId;
      }

      return path + '/by-rating/' + folderId;
    }

    return path;
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'docket-folder': DocketFolderAdapter;
  }
}
