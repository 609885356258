import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import FaqCategory from 'cing-app/models/faq-category';

interface FaqCategoriesArgs {}

@classic
export default class FaqCategories extends Component<FaqCategoriesArgs> {
  @service
  store!: Store;

  @service('docker-item')
  docker!: DockerItemService;

  @tracked
  faqCategories!: FaqCategory[];

  constructor(owner: any, args: FaqCategoriesArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    this.faqCategories = [];
    let categories = (
      await this.store.query('faq-category', {
        page: {
          size: 1000,
        },
        sort: 'order',
      })
    ).toArray();
    this.faqCategories = categories;
  });

  @task
  saveCategoriesOrderTask = taskFor(async () => {
    let categories: FaqCategory[] = this.faqCategories;

    for (var a = 0; a < categories.length; a++) {
      let category = categories[a];
      if (category) {
        category.order = a;
        await category.save();
      }
    }
  });

  @action
  categoriesSorted() {
    this.saveCategoriesOrderTask.perform();
  }

  @action
  editFaqCategory(category: FaqCategory) {
    const appearance = {
      label: 'Edit FAQ Categories',
      icon: '',
      size: 'large',
      title: `Edit FAQ Category: ${category.get('title')}`,
      custom: true,
    };

    let self = this;

    const context = {
      model: category,
      onRemove: function () {
        self.initTask.perform();
      },
      onClose: function () {
        self.initTask.perform();
      },
    };

    this.docker.invokePopup(
      'portal-settings/faq-categories/faq-category-edit',
      appearance,
      context
    );
  }

  @action
  addFaqCategory() {
    let order = 1;
    let lastPage = this.faqCategories.lastObject;
    if (lastPage && lastPage.order) {
      order = lastPage.order + 1;
    }
    let category = this.store.createRecord('faq-category', {
      order: order,
      title: 'New FAQ category ' + order,
    });
    this.faqCategories.pushObject(category);
    this.editFaqCategory(category);
  }
}
