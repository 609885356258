import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import DockerItemService from 'cing-app/pods/docker-item/service';

import AbPmService from 'cing-app/pods/ab-pm/service';
import SplitView from './split-view';

interface AbPmArgs {
  context: {
    abLinkId: string;
    smartRoomId: any;
  };
}

export default class AbPm extends Component<AbPmArgs> {
  @service store!: Store;

  @service('docker-item') docker!: DockerItemService;

  @service('ab-pm') pmService!: AbPmService;

  @tracked selectedView = 'task-list';
  @tracked taskRoot: any = {};
  @tracked selectedTask: any = null;
  @tracked selectedTaskIndex: number = -1;
  @tracked selectedTaskParent: any = null;
  @tracked isDeletingTask: boolean = false;
  @tracked checkedTasks = [];

  @tracked ABLink: any;
  @tracked smartRoomId: any;

  addTaskPopup: any;
  editTaskPopup: any;

  constructor(owner: any, args: any) {
    super(owner, args);

    //this.smartRoomId = args.context.smartRoomId || 8;

    /* start -- debuging logic */
    /*
    if (/(localhost|dev1)/i.test(document.location.href)) {
      this.smartRoomId = this.pmService.testSmartRoomId;
    }

    let sessionSrId = sessionStorage.getItem('pm_sr_id');
    if (sessionSrId) {
      this.smartRoomId = sessionSrId;
    }
    */
    /* end -- debuging logic */

    this.initTask.perform(args.context.abLinkId);
  }

  @action changeView(event: any) {
    this.selectedView = event.target.value;
  }

  @task initTask = taskFor(async (abLinkId: string) => {
    console.log('Retrieving ABLink ', abLinkId);
    try {
      this.ABLink = await this.pmService.getABLink(abLinkId);
      this.smartRoomId = this.ABLink.SRSiteId;
      await this.pmService.preloadLookupItems(this.ABLink);
    } catch (ex) {
      console.log('ABPM Error: ', ex);
    }
  });

  get styleNamespace() {
    return podNames['ab-pm'];
  }

  clearCheckedTasks() {
    this.checkedTasks.forEach((ct: any) => {
      ct.isChecked = false;
    });

    this.checkedTasks.clear();
  }

  @action performTaskAction(taskAction: any, task: any, options: any) {
    console.log(`action=${taskAction} target task: `, task);

    if (taskAction == 'change-view') {
      this.selectedView = task;
    } else if (taskAction == 'tree-ready') {
      this.taskRoot = task;
      if (task.children) {
        this.selectedTask = task.children.firstObject;
        this.selectedTask.isSelected = true;
      }
    } else if (taskAction == 'refresh-root') {
      if (this.taskRoot && this.taskRoot.update) {
        this.taskRoot.update();
      }
      this.selectedTask = null;
    } else if (taskAction == 'edit-task') {
      this.selectedTask = task;
      this.launchEditTaskDialog({
        action: taskAction,
        title: 'Edit Task',
        task: task,
      });
    } else if (taskAction == 'task-updated') {
      if (this.selectedTask && this.selectedTask.refresh) {
        this.selectedTask.refresh();
      }
      if (this.editTaskPopup) {
        this.docker.removePopup(this.editTaskPopup);
      }
    } else if (taskAction == 'add-root-task') {
      this.launchAddTaskDialog({
        action: taskAction,
        title: 'Add New Task',
        parentTask: this.taskRoot,
        task: options,
      });
    } else if (taskAction == 'add-task') {
      this.launchAddTaskDialog({
        action: taskAction,
        title: 'Add Sibling Task',
        parentTask: task,
        task: options,
      });
    } else if (taskAction == 'add-sub-task') {
      this.launchAddTaskDialog({
        action: taskAction,
        title: 'Add Subtask',
        parentTask: task,
        task: options,
      });
    } else if (taskAction == 'task-added') {
      this.closeAddTaskDialog();
    } else if (taskAction == 'clear-selection') {
      this.clearCheckedTasks();
    } else if (taskAction == 'select-task') {
      this.clearCheckedTasks();
      if (this.selectedTask) {
        this.selectedTask.isSelected = false;
        this.selectedTask.isChecked = false;
      }
      this.selectedTask = task;
    } else if (taskAction == 'check-task') {
      if (!this.selectedTask) {
        this.selectedTask = task;
      }

      var t = this.checkedTasks.findBy('id', task.id);
      if (!t) {
        this.checkedTasks.pushObject(task);
      }

      console.log('checkedtasks ', this.checkedTasks);
    } else if (taskAction == 'uncheck-task') {
      var t = this.checkedTasks.findBy('id', task.id);
      if (t) {
        this.checkedTasks.removeObject(t);
      }

      console.log('checkedtasks ', this.checkedTasks);
    } else if (taskAction == 'delete-task') {
      this.selectedTask = task;
      this.selectedTaskParent = options.parentTask;
      this.selectedTaskIndex = options.index;
      this.isDeletingTask = true;
    } else if (taskAction == 'save-task') {
      // noop
    } else if (taskAction == 'attach-files') {
      this.selectedTask = task;
      this.launchAttachmentsDialog();
    } else if (taskAction == 'edit-resources') {
      this.selectedTask = task;
      this.launchResourcesDialog();
    } else if (taskAction == 'indent-task') {
    } else if (taskAction == 'outdent-task') {
    }
  }

  @action deleteTask() {
    if (this.selectedTask) {
      this.selectedTask.parentTask.children.removeObject(this.selectedTask);
      this.selectedTask.destroyRecord();
    }
    if (this.selectedTaskParent) {
      // decrement the number of subtasks
      if (this.selectedTaskParent.subtasks) {
        this.selectedTaskParent.subtasks--;
        if (this.selectedTaskParent.save) {
          this.selectedTaskParent.save();
        }
      }

      let siblings = this.selectedTaskParent.children;
      if (siblings && this.selectedTaskIndex > 0) {
        //console.log('parent task ', this.selectedTaskParent);
        if (siblings.objectAt) {
          this.selectedTask = siblings.objectAt(this.selectedTaskIndex - 1);
          //console.log('object at ' + this.selectedTaskIndex, this.selectedTask);
        } else {
          this.selectedTask = siblings[this.selectedTaskIndex - 1];
        }
        //console.log('new selected task ', this.selectedTask);
      } else {
        this.selectedTask = this.selectedTaskParent;
      }
    }

    this.isDeletingTask = false;
  }

  launchAttachmentsDialog() {
    let appearance = {
      icon: 'paperclip',
      title: `Documents - ${this.selectedTask.task}`,
      size: 'medium',
      custom: true,
    };
    let ctx = {
      abLink: this.ABLink,
      smartRoomId: this.smartRoomId,
      task: this.selectedTask,
      onTaskAction: this.performTaskAction,
    };
    this.docker.invokePopup('ab-pm/task-documents/popup', appearance, ctx);
  }

  launchResourcesDialog() {
    let appearance = {
      icon: 'users',
      title: `Resources - ${this.selectedTask.task}`,
      size: 'medium',
      custom: true,
    };
    let ctx = {
      abLink: this.ABLink,
      smartRoomId: this.smartRoomId,
      task: this.selectedTask,
      saveOnSelect: true,
      onTaskAction: this.performTaskAction,
    };
    this.docker.invokePopup('ab-pm/task-resources/popup', appearance, ctx);
  }

  launchEditTaskDialog(args: any) {
    let appearance = {
      icon: 'edit',
      title: args.title,
      size: 'large',
      custom: true,
    };
    let ctx = {
      abLink: this.ABLink,
      smartRoomId: this.smartRoomId,
      parentTask: args.parentTask,
      task: args.task,
      action: args.action,
      onTaskAction: this.performTaskAction,
    };

    this.editTaskPopup = this.docker.invokePopup(
      'ab-pm/task-edit',
      appearance,
      ctx
    );
  }

  launchAddTaskDialog(args: any) {
    let appearance = {
      icon: 'plus',
      title: args.title,
      size: 'large',
      custom: true,
    };
    let ctx = {
      abLink: this.ABLink,
      smartRoomId: this.smartRoomId,
      parentTask: args.parentTask,
      task: args.task,
      action: args.action,
      onTaskAction: this.performTaskAction,
    };
    this.addTaskPopup = this.docker.invokePopup(
      'ab-pm/task-add',
      appearance,
      ctx
    );
  }

  closeAddTaskDialog() {
    this.docker.removePopup(this.addTaskPopup);
  }

  @action initSplitView(element: any) {
    console.log('element splitview ', element);
    let sv = new SplitView(element);
  }

  @action openTest() {
    let appearance = {
      icon: 'upload',
      title: 'Export Index',
      custom: true,
    };
    let ctx = {
      abLink: this.ABLink,
      smartRoomId: this.smartRoomId,
      onTaskAction: this.performTaskAction,
    };
    this.docker.invokePopup('ab-pm/atest', appearance, ctx);
  }
}
