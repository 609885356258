import $ from 'jquery';
import { computed, setProperties, set, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { isBlank, isNone } from '@ember/utils';
import { run } from '@ember/runloop';
import { warn, assert } from '@ember/debug';
import { task } from 'ember-concurrency';
import ModelsTable from 'ember-models-table/components/models-table-server-paginated';
import Bootstrap4Theme from 'ember-models-table/themes/bootstrap4';

const { extend } = $;

let Theme = Bootstrap4Theme.extend({
  table: 'table table-hover table-striped table-sm',
  input: 'form-control form-control-sm',
  buttonDefault: 'btn btn-sm btn-secondary',
  'sort-asc': 'sort-up',
  'sort-desc': 'sort-down',
  'sort-icon-prefix': 'fas',
  'column-visible': 'check-square',
  'column-hidden': 'square',
  'column-icon-prefix': 'far',
  'nav-first': 'angle-double-left',
  'nav-first-prefix': 'fas',
  'nav-prev': 'angle-left',
  'nav-prev-prefix': 'fas',
  'nav-next': 'angle-right',
  'nav-next-prefix': 'fas',
  'nav-last': 'angle-double-right',
  'nav-last-prefix': 'fas',
  'caret': 'caret',
  'expand-row': 'fas fa-plus',
  'expand-all-rows': 'fas fa-plus',
  'collapse-row': 'fas fa-minus',
  'collapse-all-rows': 'fas fa-minus',
  'select-all-rows': 'far fa-check-square',
  'deselect-all-rows': 'far fa-square',
  'select-row': 'check-square',
  'deselect-row': 'square',
  tfooterWrapper: 'table-footer container-fluid clearfix',
  components: {
    'columns-dropdown': 'api-table/columns-dropdown',
    'pagination-simple': 'api-table/pagination-simple',
    'row-sorting-cell': 'api-table/row-sorting-cell',
  }
});

export {
  Theme
}

export default ModelsTable.extend({
  showGlobalFilter: false,
  useFilteringByColumns: false,
  pageSizeValues: [10, 20, 50, 100, 1000],
  debounceDataLoadTime: 50,
  columnsAreUpdateable: true,
  pageSize: 20,
  selectRowOnClick: false,
  store: null,

  themeInstance: computed(function () {
    return Theme.create();
  }),

  metaPagesCountProperty: 'total-pages',
  metaItemsCountProperty: 'total-count',

  filterQueryParameters: computed(function () {
    return {
      globalFilter: 'condition',
      sort: 'sort',
      sortDirection: 'sortDirection',
      page: 'page[number]',
      pageSize: 'page[size]'
    };
  }),
  init() {
    this._super(...arguments);
    set(this, 'filteredContent', get(this, 'data'));
    this.updateColumns();
  },
  observedProperties: computed(function () {
    return ['currentPageNumber', 'sortProperties.[]', 'pageSize', 'extraQueryParams', 'filter.@each', 'condition'];
  }),
  didUpdateAttrs() {
    //this.updateColumns();
  },
  didReceiveAttrs() {
    //set(this, 'filteredContent', get(this, 'data'));
  },
  _addPropertyObserver() {
    run.debounce(this, this._loadData, get(this, 'debounceDataLoadTime'));
  },

  pagesCount: computed('filteredContent.meta', function () {
    let pagesCountProperty = get(this, 'metaPagesCountProperty');
    let meta = get(this, 'filteredContent.meta') || {};

    let serverPageCount = get(meta, pagesCountProperty);

    if (serverPageCount >= 0) {
      return serverPageCount;
    } else {
      let totalCount = get(meta, get(this, 'metaItemsCountProperty'));
      let pageSize = get(this, 'pageSize');

      return Math.ceil(totalCount / pageSize);
    }
  }),
  _loadData: function () {
    if (this.onReload) {
      this.onReload();
    }

    let data = get(this, 'data');

    if (get(data, 'canQuery') === false) {
      return false;
    }

    let currentPageNumber = get(this, 'currentPageNumber');
    let pageSize = get(this, 'pageSize');
    let sortProperties = get(this, 'sortProperties');

    let query = Object.assign({}, get(data, 'query'));

    let store = get(data, 'store');
    let modelName = get(data, 'modelName');

    // Add pagination information
    query[get(this, 'filterQueryParameters.page')] = currentPageNumber;
    query[get(this, 'filterQueryParameters.pageSize')] = pageSize;

    let extraQueryParams = get(this, 'extraQueryParams');

    for (let key in extraQueryParams) {
      query[key] = extraQueryParams[key];
    }

    // Add sorting information
    if (sortProperties && get(sortProperties, 'length')) {
      if (get(this, 'multipleColumnsSorting')) {
        query = this.multipleColumnsSortingWrapper(query, sortProperties);
      }
      else {
        if (sortProperties[0]) {
          let [sortBy, sortDirection] = sortProperties[0].split(':');
          query = this.singleColumnSortingWrapper(query, sortBy, sortDirection.toUpperCase());
        }
      }
    } else {
      delete query[[get(this, 'filterQueryParameters.sort')]];
      delete query[[get(this, 'filterQueryParameters.sortDirection')]];
    }

    let globalFilter = get(this, 'filterQueryParameters.globalFilter');

    // add filters
    let filter = get(this, 'filter');
    let condition = get(this, 'condition');

    if (condition) {
      query[globalFilter] = condition;
    } else {
      delete query[globalFilter];
    }

    if (filter) {
      query['filter'] = filter;
    } else {
      delete query['filter'];
    }

    setProperties(this, { isLoading: true, isError: false });
    return this.doQuery(store, modelName, query)
      .then(() => {
        if (!this.isDestroyed) {
          setProperties(this, { isLoading: false, isError: false })
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        if (!this.isDestroyed) {
          setProperties(this, { isLoading: false, isError: true })
        }
      });
  },

  doQuery(store, modelName, query) {
    return store.query(modelName, query).then((newData) => {
      set(this, 'filteredContent', newData);
      run.scheduleOnce('afterRender', this, function () {
        this.sendAction("onDataUpdated", newData);
      });
    });
  },

  getAllRecordsTask: task(function* () {
    let data = get(this, 'data');
    let currentPageNumber = 1;
    let pageSize = 1000;
    let dataAvailable = true;
    let sortProperties = get(this, 'sortProperties');
    let store = get(data, 'store');
    let modelName = get(data, 'modelName');

    let allItems = [];

    while (dataAvailable) {
      let query = Object.assign({}, get(data, 'query'));

      // Add pagination information
      query[get(this, 'filterQueryParameters.page')] = currentPageNumber;
      query[get(this, 'filterQueryParameters.pageSize')] = pageSize;

      let extraQueryParams = get(this, 'extraQueryParams');

      for (let key in extraQueryParams) {
        query[key] = extraQueryParams[key];
      }

      // Add sorting information
      if (sortProperties && get(sortProperties, 'length')) {
        if (get(this, 'multipleColumnsSorting')) {
          query = this.multipleColumnsSortingWrapper(query, sortProperties);
        }
        else {
          if (sortProperties[0]) {
            let [sortBy, sortDirection] = sortProperties[0].split(':');
            query = this.singleColumnSortingWrapper(query, sortBy, sortDirection.toUpperCase());
          }
        }
      } else {
        delete query[[get(this, 'filterQueryParameters.sort')]];
        delete query[[get(this, 'filterQueryParameters.sortDirection')]];
      }

      let globalFilter = get(this, 'filterQueryParameters.globalFilter');


      // add filters
      let filter = get(this, 'filter');
      let condition = get(this, 'condition');

      if (condition) {
        query[globalFilter] = condition;
      } else {
        delete query[globalFilter];
      }

      if (filter) {
        query['filter'] = filter;
      } else {
        delete query['filter'];
      }

      let items = yield store.query(modelName, query);

      if (items.length) {
        allItems = allItems.concat(items.toArray());
        currentPageNumber++;
      } else {
        dataAvailable = false;
      }
    }

    get(this, 'selectedItems').clear();
    get(this, 'selectedItems').pushObjects(allItems);
  }),

  sortMap: computed(function () {
    return {
      none: 'desc',
      asc: 'desc',
      desc: 'asc'
    };
  }),


  singleColumnSortingWrapper(query, sortBy, sortDirection) {
    if (sortDirection === "DESC") {
      query[get(this, 'filterQueryParameters.sort')] = "-" + sortBy;
    } else {
      query[get(this, 'filterQueryParameters.sort')] = sortBy;
    }

    return query;
  },
  onFilterChange: function () {
    this.set('currentPageNumber', 1);
  }.observes('condition', 'extraQueryParams'),
  onDataChange: function () {
    if (this.reloadData) {
      run.debounce(this, this._loadData, get(this, 'debounceDataLoadTime'));
      run.scheduleOnce('afterRender', this, function () {
        this.set('reloadData', false);
      })
    }
  }.observes('reloadData'),
  didInsertElement() {
    this._loadData();
    //run.debounce(this, this._loadData, get(this, 'debounceDataLoadTime'));
  },
  actions: {
    toggleAllSelection: function (filter) {
      if (get(this, 'selectedItems.length')) {
        get(this, 'selectedItems').clear();
        return false;
      } else {
        return this.getAllRecordsTask.perform().then(() => {
          this.userInteractionObserver();
          return true;
        });
      }
      /*
      var selectedItems = get(this, 'selectedItems');
      var data = get(this, 'filteredContent');
      var allSelectedBefore = get(selectedItems, 'length') === get(data, 'length');

      get(this, 'selectedItems').clear();

      if (!allSelectedBefore) {
          let items = this.visibleContent;

          if (filter) {
              items = filter(items);
          }

          get(this, 'selectedItems').pushObjects(items.toArray());
      }
      this.userInteractionObserver();

      return !allSelectedBefore;
      */
    },
    updatePageNumber(pageNumber) {
      set(this, 'currentPageNumber', pageNumber);
    }
  }
});
