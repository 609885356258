import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import Ember from 'ember';
import moment from 'moment';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { task } from 'ember-concurrency';

export default Component.extend(FilterBuilder, {
  store: service(),
  docker: service('docker-item'),
  config: service(),
  session: service(),
  sortAsc: false,
  sortColumn: 'dateStart',
  classNames: ['d-flex', 'flex-column', 'flex-grow-1'],
  appEvents: service(),
  query: computed('startDate', 'endDate', 'filteredProjects', function () {
    let startDate = this.get('startDate');
    let endDate = this.get('endDate');
    // compose filter object by supplied properties
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      DateRangeFilter.create({
        dateFormat: this.get('config.APP.serverDateFormat'),
        name: 'dateStart',
        value1: startDate,
        value2: endDate,
      })
    );
    let projectIds = this.get('filteredProjects');
    if (projectIds.length) {
      expr.add(
        Filter.create({
          name: 'project-id',
          operator: FilterOperators.IN,
          value: projectIds,
        })
      );
    }

    return {
      page: { number: 0, size: 10000 },
      sort: 'dateStart',
      include: 'project,type,pacer-events',
      condition: expr.serialize(),
    };
  }),
  queryMeta: { totalCount: 0 },
  startDate: computed('startRaw', function () {
    return moment(this.getWithDefault('startRaw', moment())).startOf('day');
  }),
  endDate: computed('endRaw', function () {
    if (!this.endRaw) {
      return moment(this.getWithDefault('endRaw', moment()))
        .add(4, 'week')
        .endOf('day');
    } else {
      return moment(this.endRaw).endOf('day');
    }
  }),
  ranges: computed('startDate', 'endDate', function () {
    const presets = {
      'Last week': [moment().subtract(1, 'weeks'), moment()],
      'Last month': [moment().subtract(1, 'months'), moment()],
      'Last year': [moment().subtract(1, 'years'), moment()],
    };
    return {
      earliest: moment().subtract(1, 'years'),
      latest: moment(),
      presets: presets,
    };
  }),
  dateFormat: computed('config.APP.shortDateTimeFormat', function () {
    return this.get('config.APP.shortDateTimeFormat');
  }),
  init() {
    this._super(...arguments);
    this.get('loadData').perform();
    this.get('appEvents').subscribe('watchlistChanged', this, 'refresh');
  },
  willDestroyElement() {
    this._super(...arguments);

    this.get('appEvents').unsubscribe('watchlistChanged', this, 'refresh');
  },
  refresh() {
    this.get('loadData').perform();
  },
  loadData: task(function* (add = false) {
    const store = this.get('store');
    let projectIds = [];

    if (this.get('session.isAuthenticated')) {
      //const watchList = yield store.findAll('watchList');
      const watchList = yield store.query('watch-list', {
        'page[size]': 1000,
      });
      this.set('watchList', watchList);
      projectIds = watchList.mapBy('projectId');
    }
    if (this.get('selectedProjects.length')) {
      projectIds = this.get('selectedProjects').mapBy('projectId');
    }

    this.set('filteredProjects', projectIds);
    let query = this.get('query');

    const events = yield store.query('event', query);
    const meta = events.get('meta');

    if (add) {
      this.get('events').pushObjects(events.toArray());
    } else {
      this.set('events', events.toArray());
    }

    this.setProperties({
      'queryMeta.totalCount': meta['total-count'] ? meta['total-count'] : 0,
    });
  }).drop(),
  actions: {
    selectWatchListCase(selection) {
      this.set('selectedProjects', selection);
      this.get('loadData').perform();
    },
    onScrolledToBottom() {
      /*
      const pSize = this.get('query.page.size'),
          pNr = this.get('query.page.number'),
          total = this.get('queryMeta').totalCount;
      if (pSize * (pNr + 1) < total) {
          this.incrementProperty('query.page.number');
          this.get('loadData').perform(true);
      }
      */
    },
    openCaseForEvent(event) {
      let appearance = {
        icon: '',
        title: `Case: ${event.get('case.name')} / #${event.get(
          'case.displayNumber'
        )}`,
        size: 'large',
        id: event.get('case.id'),
      };
      this.get('docker').invokePopup('case-detail', appearance, {
        recordId: event.get('caseId'),
      });
    },
    onSortColumn(column) {
      const sortCurrent = this.get('query.sort');
      const sortAsc = sortCurrent.substr(0, 1) !== '-';
      let sortFinal = '';
      if (column === sortCurrent.replace('-', '')) {
        sortFinal = (sortAsc ? '-' : '') + column;
      } else {
        sortFinal = column;
      }

      this.setProperties({
        'query.page.number': 0,
        sortAsc: !sortAsc,
        sortColumn: column,
        'query.sort': sortFinal,
      });

      this.get('loadData').perform();
    },
    setDateRange(start, end) {
      this.setProperties({ startRaw: start, endRaw: end });
      this.get('loadData').perform();
    },
    hideDatePicker() { },
    cancelDatePicker() { },
    showEventDetail(event) {
      Ember.Logger.debug('showEventDetail()', event.get('id'));
      const appearance = {
        label: 'Event detail',
        icon: 'calendar',
        title: event.get('title'),
      };
      const context = {
        event: event,
        loadData: this.get('loadData'),
      };
      this.get('docker').invokePopup('event-detail', appearance, context);
    },
    onInfo(event) {
      Ember.Logger.debug('onInfo: ', event);
    },
    onCalendar(event) {
      Ember.Logger.debug('onCalendar: ', event);
    },
    onGroup(event) {
      Ember.Logger.debug('onGroup: ', event);
    },
  },
});
