import { classNames } from '@ember-decorators/component';
import { inject } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import { task } from 'ember-concurrency';
import { AclPermissionType } from 'cing-app/utils/lookups';
import Store from '@ember-data/store';
import UserInstancesService from 'cing-app/pods/user-instances/service';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import UserGroup from 'cing-app/models/user-group';
import AclRole from 'cing-app/models/acl-role';

interface AddArgs {
  header: any;
  footer: any;
  context: any;
  onClose: () => void;
}

@classNames('d-flex', 'flex-column', 'flex-grow-1')
export default class Add extends Component<AddArgs> {
  @inject()
  store!: Store;

  @inject()
  userInstances!: UserInstancesService;

  constructor(own: any, args: AddArgs) {
    super(own, args);

    this.initTask.perform();
  }

  @tracked
  aclRoles: AclRole[] = [];

  @tracked
  newGroupModel!: UserGroup;

  @tracked
  showFormValidations = false;

  @tracked
  userGroupExist: boolean = false;

  @task
  initTask = taskFor(async () => {
    // this.set('addNewGroup', true);

    let query = Expressions.create();
    query.add(
      Filter.create({
        //@ts-ignore
        name: 'acl-role-permissions.acl-permission.permission-type-id',
        operator: FilterOperators.IN,
        //@ts-ignore
        value: [
          AclPermissionType.ProjectTabRead,
          AclPermissionType.ProjectRead,
          AclPermissionType.ProjectUpdate,
          AclPermissionType.PortalPageRead,
          AclPermissionType.Admin,
          AclPermissionType.CompanyRead,
          AclPermissionType.InterestedPartyRead,
          AclPermissionType.FaqRead,
          AclPermissionType.FaqUpdate,
        ],
      })
    );

    let aclRoles = await this.store.query('acl-role', {
      condition: query.serialize(),
      page: {
        size: 1000,
      },
      sort: 'name',
    });

    this.aclRoles = aclRoles.toArray();

    this.newGroupModel = this.store.createRecord('user-group');
  });

  @task
  addNewGroupTask = taskFor(async (model) => {
    // let store = this.store;

    if (model.isNew) {
      let modelName = model.name;

      let userGroupQuery = Expressions.create();

      userGroupQuery.add(
        Filter.create({
          name: 'name',
          operator: FilterOperators.EQUAL,
          value: modelName,
        })
      );

      let userGroupExist = (
        await this.store.query('user-group', {
          condition: userGroupQuery.serialize(),
          page: {
            size: 2,
          },
        })
      ).firstObject;

      if (userGroupExist) {
        this.userGroupExist = true;
        return;
      }
    }

    await this.newGroupModel.save();

    let roles = model.roles;

    if (roles && roles.length) {
      for (var a = 0; a < roles.length; a++) {
        let groupRole = this.store.createRecord('acl-role-assignment', {
          roleId: roles[a].id,
          principalId: this.newGroupModel.id,
          principalType: 2,
          //@ts-ignore
          resourceId: this.userInstances.current.instanceId,
          resourceType: 255,
        });

        await groupRole.save();
      }
    }

    if (this.args.context.onUpdate) {
      this.args.context.onUpdate();
    }

    if (this.args.onClose) {
      this.args.onClose();
    }
  });

  @action
  confirmAddNewGroup(model: UserGroup) {
    this.showFormValidations = true;
    if (model.validations.isValid) {
      model.name = model.name.trim();
      if (model.name) {
        this.addNewGroupTask.perform(model);
      }
    }
  }
}
