import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { get } from '@ember/object';
import { dasherize } from '@ember/string';
import { DailyStat } from '../../component';
import { inject as service } from '@ember/service';
import AppBuilderService, {
  ABModelMeta,
} from 'cing-app/pods/appbuilder/service';
import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';
import AppBuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import _SessionService from 'cing-app/pods/session/service';
import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

interface UserLoginsArgs {
  appbuilderLink: AppBuilderLink;
  dailyStat: DailyStat;
}

export interface UserLogin {
  ngexportUserLoginsReportId?: number;
  ngexportReportId?: number;
  logonSitesId?: number;
  siteId?: number;
  siteName?: string;
  masterUserId?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  userCompanyName?: string;
  siteOwner?: string;
  userSiteCompany?: string;
  categoryId?: number;
  categoryName?: string;
  longestLogin?: string;
  source?: string;
  loginIp?: string;
  loginDateTime?: string;
  lastActivityDate?: string;
  duration?: string;
  deletedUser?: string;
  dateRetrieved?: string;
  dateExported?: string;
}

export default class UserLogins extends Component<UserLoginsArgs> {
  @service('appbuilder')
  appbuilder!: AppBuilderService;

  @service
  store!: Store;

  @service
  session!: _SessionService;

  //Summary
  @tracked
  summaryCollapsed: boolean = false;

  modelMeta: ABModelMeta;
  dataSource!: ApiDataSource<UserLogin>;

  @tracked
  selectedItems: UserLogin[];

  //Common
  pageSize = 20;

  get columnsUI() {
    let columns: DataSourceColumn<UserLogin>[] = [
      new DataSourceColumn<UserLogin>({
        id: 'logon-sites-id',
        label: 'Logon Sites Id',
        hidden: true,
        getValue: (row) => get(row, 'logonSitesId'),
        sortingEnabled: true,
        minWidth: 100,
        headerClass: 'justify-content-center',
        valueClass: 'text-center',
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'site-id',
        label: 'Site Id',
        hidden: true,
        getValue: (row) => get(row, 'siteId'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'site-name',
        label: 'Site Name',
        hidden: true,
        getValue: (row) => get(row, 'siteName'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'master-user-id',
        label: 'Master UserId',
        hidden: true,
        getValue: (row) => get(row, 'masterUserId'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'email',
        label: 'Email',
        getValue: (row) => get(row, 'email'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'first-name',
        label: 'First Name',
        getValue: (row) => get(row, 'firstName'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'last-name',
        label: 'Last Name',
        getValue: (row) => get(row, 'lastName'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'user-company-name',
        label: 'User Company',
        getValue: (row) => get(row, 'userCompanyName'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'site-owner',
        label: 'Site Owner',
        getValue: (row) => get(row, 'siteOwner'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),

      new DataSourceColumn<UserLogin>({
        id: 'user-site-company',
        label: 'Room Company',
        getValue: (row) => get(row, 'userSiteCompany'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'category-id',
        label: 'Category Id',
        hidden: true,
        getValue: (row) => get(row, 'categoryId'),
        sortingEnabled: true,
        minWidth: 100,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'category-name',
        label: 'Category',
        getValue: (row) => get(row, 'categoryName'),
        sortingEnabled: true,
        minWidth: 150,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'longest-login',
        label: 'Longest Login',
        getValue: (row) => get(row, 'longestLogin'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<UserLogin>({
        id: 'source',
        label: 'Source',
        hidden: true,
        getValue: (row) => get(row, 'source'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<UserLogin>({
        id: 'login-ip',
        label: 'Login Ip',
        getValue: (row) => get(row, 'loginIp'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
    ];
    return columns;
  }

  constructor(owner: any, args: any) {
    super(owner, args);

    this.modelMeta = this.appbuilder.getModelMeta(
      this.args.appbuilderLink,
      'user-logins'
    );

    this.selectedItems = [];

    this.dataSource = new ApiDataSource<UserLogin>(
      25,
      false,
      this.modelMeta.modelPath,
      this.session.authUser.email,
      this.loadDataTask,
      this.pageSize,
      this.columnsUI,
      this.selectedItems,
      {}
    );

    this.dataSource.selectionEnabled = false;
  }

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<UserLogin>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let filterQuery = Expressions.create();
      filterQuery.add(
        Filter.create({
          name: 'site-id',
          operator: FilterOperators.EQUAL,
          value: this.args.dailyStat.siteId,
        })
      );

      let query: any = {
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
        condition: filterQuery.serialize(),
      };

      if (sortColumn) {
        let sortName =
          sortColumn.options?.sortValue || dasherize(sortColumn.id);
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      // let abList = await this.store.query(
      //   this.modelMeta.modelPath,
      //   query
      // );

      let userLoginsList = await this.store.query(
        this.modelMeta.modelPath,
        query
      );

      let userLogins = <Paged<UserLogin>>userLoginsList.toArray();
      userLogins.meta = {
        totalCount: userLoginsList.meta['total-count'],
      };

      return userLogins;
    }
  );
}
