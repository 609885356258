import classic from 'ember-classic-decorator';
import { classNameBindings } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@ember/component';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators
} from 'cing-app/mixins/filter-builder';
import { task } from 'ember-concurrency';
import { EventType } from "cing-app/utils/lookups";
import moment from 'moment';

@classic
@classNameBindings('isEmpty:is-empty')
export default class HighlightDates extends Component {
  @inject()
  config;

  @inject()
  store;

  @inject('docker-item')
  docker;

  confirmRemoval = null;

  @task(function* () {
    let expEd = Expressions.create({ operator: ExpressionOperators.AND });
    expEd.add(Filter.create({
      name: 'project-id',
      operator: FilterOperators.EQUAL,
      value: this.project.id
    }));
    expEd.add(Filter.create({
      name: 'highlight',
      operator: FilterOperators.EQUAL,
      value: true
    }));

    let events = yield this.get('store').query('event', {
      condition: expEd.serialize(),
      page: {
        size: 1000,
        number: 1
      },
      sort: 'dateStart',
      include: ['type', 'pacer-events'],
    });

    this.set('events', events);
  })
  loadData;

  @computed('events.length')
  get isEmpty() {
    return !this.get('events.length');
  }

  @action
  doRemoveRecord(record) {
    record.destroyRecord().then(() => {
      this.set('confirmRemoval', null);
      this.get('loadData').perform();
      if (this.onUpdate) {
        this.onUpdate();
      }
    });
  }

  @action
  add() {
    let appearance = {
      icon: '',
      title: `Add event for project #${this.get('project.name')}`,
      size: 'large',
      custom: true,
    };

    let self = this;

    this.get('docker').invokePopup('manage-events/generic-add', appearance, {
      project: this.get('project'),
      event: this.get('store').createRecord('event', {
        projectId: this.get('project.id'),
        dateStart: moment(),
        typeId: EventType.CaseEvent,
        highlight: true,
        alwaysShow: true,
      }),
      onAdd: function () {
        self.get('loadData').perform();
      }
    });
  }

  @action
  edit(event) {
    let appearance = {
      icon: '',
      title: `Edit event for case #${this.get('project.name')}`,
      size: 'large',
      custom: true,
    };

    let self = this;

    this.get('docker').invokePopup('manage-events/generic-add', appearance, {
      project: this.get('project'),
      event: event,
      onAdd: function () {
        self.get('loadData').perform();
      }
    });
  }
}
