import Component from '@ember/component';

import {
  SYNC_NEW,
  SYNC_DELETED,
  SYNC_MISSING_INVESTOR_NUMBER,
  SYNC_INVESTOR_CHANGED,
  SYNC_FOLDERS_CHANGED,
  SYNC_INVESTOR_NUMBER_CHANGED,
  SYNC_NAME_CHANGED,
  SYNC_ROLE_CHANGED
} from '../component';
import classic from 'ember-classic-decorator';

@classic
export default class SyncResultItem extends Component {
  tagName = 'tr';
  SYNC_NEW = SYNC_NEW;
  SYNC_DELETED = SYNC_DELETED;
  SYNC_MISSING_INVESTOR_NUMBER = SYNC_MISSING_INVESTOR_NUMBER;
  SYNC_INVESTOR_CHANGED = SYNC_INVESTOR_CHANGED;
  SYNC_FOLDERS_CHANGED = SYNC_FOLDERS_CHANGED;
  SYNC_INVESTOR_NUMBER_CHANGED = SYNC_INVESTOR_NUMBER_CHANGED;
  SYNC_NAME_CHANGED = SYNC_NAME_CHANGED;
  SYNC_ROLE_CHANGED = SYNC_ROLE_CHANGED;
}