import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@classNames('popups-container')
export default class Layout extends Component {
  @service('docker-item')
  docker;

  @computed('docker.popups.@each')
  get popups() {
    return this.get('docker.popups');
  }

  @action
  onDock(popup) {
    this.get('docker').minimizePopup(popup);
  }

  @action
  onFocus(popup) {
    this.get('docker').focusPopup(popup);
  }

  @action
  onClose(popup) {
    this.get('docker').removePopup(popup);
  }
}
