import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ApplicationAdapter from '../../application';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';

export default JSONAPIAdapter.extend(DataAdapterMixin, {
  config: service(),
  serverVariables: service('server-variables'),

  host: alias('config.APP.api.host'),
  namespace: alias('config.APP.api.namespace'),

  //host: alias('serverVariables.appBuilderApiUrl'),
  //namespace: alias('serverVariables.appBuilderApiNamespace'),

  ajaxOptions(url, type, options = {}) {
    options.contentType = 'application/json';
    return this._super(url, type, options);
  },

  headers: computed('session.data.authenticated.access_token', function () {
    let headers = {};
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';

    return headers;
  }),
});
