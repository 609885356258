import Component from '@glimmer/component';
import { all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { get, set, action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import {
  ProjectTabMap,
  CaseProjectUrlOpenModeMap,
  ConnectionTypes,
} from 'cing-app/utils/lookups';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import Project from 'cing-app/models/project';
import ProjectTab from 'cing-app/models/project-tab';
import ProjectType from 'cing-app/models/project-type';
import ArrayProxy from '@ember/array/proxy';
import {
  Query,
  QueryOperators,
  FilterOperators,
  Filter,
} from 'cing-app/utils/query-builder';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

interface ManageProjectArgs {
  onClose: () => void;
  header: any;
  footer: any;
  context: {
    project: Project;
    onAdd: () => void;
  };
}

export default class ManageProject extends Component<ManageProjectArgs> {
  @service store;
  @service('docker-item') docker;
  @service session;
  @service serverVariables;
  @service userInstances;

  @tracked
  enableSmartRoom: boolean = false;
  @tracked
  createExternalUrlTab: boolean = true;
  @tracked
  srConnection?: AppbuilderLink;
  @tracked
  srConnections?: ArrayProxy<AppbuilderLink>;

  @tracked
  smartRoomTab?: ProjectTab | null;

  @tracked
  projectTypes!: ArrayProxy<ProjectType>;

  @tracked
  model!: Project;

  constructor(owner: unknown, args: ManageProjectArgs) {
    super(owner, args);
    this.model = this.args.context.project;

    this.initTask.perform();
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  @task
  initTask = taskFor(async () => {
    await this.session.authenticate('authenticator:torii', 'si', {
      silent: true,
    });

    set(this.model, 'settings', {});
    set(this.model, 'dateStarted', moment().toDate());

    this.projectTypes = await this.store.query('project-type', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
  });

  @task
  saveTask = taskFor(async () => {
    let newFund = this.store.createRecord('fund', {
      publicDescription: '',
    });

    this.model.setProperties({
      fund: newFund,
    });

    await (await get(this.model, 'fund')).save();
    await this.model.save();

    let projectId = this.model.id;
    let sortOrder = 1;

    let allTasks = [];

    // create default tab
    let detailsTab = this.store.createRecord('project-tab', {
      projectId: projectId,
      enabled: true,
      type: ProjectTabMap.DETAILS,
      sortOrder: sortOrder++,
    });

    allTasks.push(detailsTab.save());

    if (this.model.companyId) {
      let companyProfileTab = this.store.createRecord('project-tab', {
        projectId: projectId,
        enabled: true,
        type: ProjectTabMap.COMPANY_PROFILE,
        sortOrder: sortOrder++,
      });

      allTasks.push(companyProfileTab.save());
    }

    let partiesTab = this.store.createRecord('project-tab', {
      projectId: projectId,
      enabled: true,
      type: ProjectTabMap.PARTIES,
      sortOrder: sortOrder++,
    });

    allTasks.push(partiesTab.save());

    if (this.enableSmartRoom && this.srConnection) {
      await this.srConnection.save();
      set(this.smartRoomTab, 'projectId', projectId);
      set(this.smartRoomTab, 'sortOrder', sortOrder++);

      allTasks.push(this.smartRoomTab?.save());

      let externalSmartRoomTab = this.store.createRecord('project-tab', {
        projectId: projectId,
        enabled: true,
        sortOrder: sortOrder++,
        title: 'SmartRoom',
        type: ProjectTabMap.EXTERNAL_URL,
        content:
          this.serverVariables.get('smartroomAppUrl') +
          '#/' +
          this.srConnection.SRSlug,
      });

      externalSmartRoomTab.set(
        'settings.EUOpenMode',
        CaseProjectUrlOpenModeMap.NEW_WINDOW
      );

      allTasks.push(externalSmartRoomTab.save());
    }

    let calendarTab = this.store.createRecord('project-tab', {
      projectId: projectId,
      enabled: true,
      type: ProjectTabMap.CALENDAR,
      sortOrder: sortOrder++,
    });

    allTasks.push(calendarTab.save());

    await all(allTasks);

    if (this.args.context.onAdd) {
      this.args.context.onAdd();
    }

    if (this.args.onClose) {
      this.args.onClose();

      const appearance = {
        label: 'Project',
        icon: '',
        title: '<small>Project:</small> ' + this.model.name,
        size: 'large',
        status: 'full',
        custom: true,
      };
      this.docker.invokePopup('project-detail', appearance, {
        project: this.model,
      });
    }
  });

  @task
  addProjectTypeTask = taskFor(async (newTypeName, options) => {
    let newType = this.store.createRecord('project-type', {
      name: newTypeName,
    });

    await newType.save();

    let types = await this.store.query('project-type', {
      page: {
        size: 1000,
        sort: 'name',
      },
    });

    set(this, 'projectTypes', types);
    set(this.model, 'type', newType);
  });

  @action
  hideProjectTypeCreateOnSame(term: string) {
    let existingOption = get(this, 'projectTypes').findBy('name', term);
    return !existingOption;
  }

  @action
  removeType(type: ProjectType) {
    type.destroyRecord();
  }

  @action
  createProjectType(newTypeName: string, options: ProjectType[]) {
    this.addProjectTypeTask.perform(newTypeName, options);
  }

  @action
  save() {
    this.saveTask.perform();
  }

  @action
  onSmartRoomEnabled(enabled: boolean) {
    if (enabled) {
      let smartRoomTab = this.store.createRecord('project-tab', {
        enabled: true,
        type: ProjectTabMap.SMARTROOM,
      });

      this.smartRoomTab = smartRoomTab;
    } else {
      if (this.smartRoomTab) {
        this.smartRoomTab.destroyRecord();
      }

      this.smartRoomTab = null;
    }
  }

  @task
  _createSRConnection = taskFor(async () => {
    let connection = this.store.createRecord('appbuilder-link', {
      project: this.model,
      type: ConnectionTypes.SMARTROOM,
    });

    await connection.save();

    this.selectSRConnection(connection);

    await this.loadSRConnections();
  });

  @action
  createSRConnection() {
    this._createSRConnection.perform();
  }

  @action
  async loadSRConnections() {
    let query = new Query();
    query.add(
      new Filter('type', FilterOperators.EQUAL, ConnectionTypes.SMARTROOM)
    );

    query.add(new Filter('project-id', FilterOperators.NULL));

    this.srConnections = await this.store.query('appbuilder-link', {
      condition: query.serialize(),
    });
  }

  @action
  selectSRConnection(connection: AppbuilderLink) {
    this.srConnection = connection;

    if (connection) {
      set(connection, 'project', this.model);
    }

    set(this.smartRoomTab, 'dataConnection', connection);
  }
}
