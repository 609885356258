import { action } from '@ember/object';
//@ts-ignore
import BsFormElement from 'ember-bootstrap/components/bs-form/element';
import arg from 'ember-bootstrap/utils/decorators/arg';
import { get } from '@ember/object';
interface ValidatedBsFormElementArgs {
  model: any;
  property: string;
}
import { cached } from 'tracked-toolbox';

export default class ValidatedBsFormElement extends BsFormElement<ValidatedBsFormElementArgs> {
  declare args: ValidatedBsFormElementArgs;
  // '__ember-bootstrap_subclass' = true;

  get validations() {
    return get(this.args.model, 'validations');
  }
  get elementProperty() {
    return this.args.property;
  }

  get hasValidator() {
    return this.validations?.schema?.fields[this.elementProperty];
  }

  get hasErrors() {
    return this.errors.length;
  }

  get errors() {
    if (
      this.validations?.fieldErrors &&
      this.validations?.fieldErrors[this.args.property]
    ) {
      let eItems = this.validations.fieldErrors[this.args.property];
      return eItems.map((e: any) => e.message);
    } else {
      return [];
    }
  }

  get warnings() {
    return undefined;
  }

  @action
  async showValidationOnHandler(event: any) {
    await this.validations?.validate();
    // run original implementation provided by Ember Bootstrap
    super.showValidationOnHandler(event);
  }

  @action
  doChange(value) {
    super.doChange(value);
    this.validations?.validate();
  }
}
