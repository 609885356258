import Component from '@glimmer/component';
import PersonInCompany from 'cing-app/models/person-in-company';

interface ColumnComponentArgs {
  column: any;
  record: PersonInCompany;
}

export default class ColumnComponent extends Component<ColumnComponentArgs> {

}