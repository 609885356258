import { attr } from '@ember-data/model';
import Abstract from './abstract';
import { validator, buildValidations } from 'ember-cp-validations';

const FundTypeValidations = buildValidations({
  name: validator('presence', true),
});

export default class FundType extends Abstract.extend(FundTypeValidations) {
  @attr('string') declare name: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'fund-type': FundType;
  }
}
