import Service from '@ember/service';

import { computed, get, set } from '@ember/object';
import { timeout, task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import classic from 'ember-classic-decorator';

export const SYNC_API = {
  V1: "v1",
  V2: "v2"
}
@classic
export default class UserInstancesService extends Service {
  @service config;
  @service store;
  @service session;

  start() {
    if (this.session.isAuthenticated) {
      this.startTask.perform();
    } else {
      /*
      this.session.on('authenticationSucceeded', this, function() {
          this.checkDealCloudSupport.perform();
      });
      */
    }
  }

  @computed('current')
  get smartRoomProvisioningEnabled() {
    if (this.current) {
      return this.current.smartRoomProvisioningEnabled;
    }

    return false;
  }

  @computed('current')
  get dealCloudEnabled() {
    if (this.current) {
      return this.current.dealCloudEnabled;
    }

    return false;
  }

  @computed('current')
  get abInstances(): { name: String, url: string }[] {
    if (this.current) {
      return this.current.abInstances;
    }

    return [];
  }

  @computed('current')
  get dealCloudVersion() {
    let syncVersion = SYNC_API.V1;

    if (get(this.current, 'syncDescription.Version') === SYNC_API.V2) {
      syncVersion = SYNC_API.V2;
    }

    return syncVersion;
  }

  @computed('current')
  get syncDescription() {
    return get(this.current, 'syncDescription');
  }

  /**** END PUBLIC APIs ****/

  @task
  *startTask() {
    try {
      let userInstances = yield this.store.query('user-instance', {
        page: {
          number: 1,
          size: 100
        }
      });

      this.set('instances', userInstances.toArray());

      let smexInstance = userInstances.findBy('instanceUrl', new URL(get(this.config, 'APP.api.host')).hostname);
      set(this, 'current', smexInstance);
    } catch (e) {
      console.error('Could not determine SmartExchange instances!')
      console.log(e);
    }
  }
}
