import RESTAdapter from '@ember-data/adapter/rest';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import { decamelize } from '@ember/string';
import { pluralize } from 'ember-inflector';

export default RESTAdapter.extend(DataAdapterMixin, {
  serverVariables: inject(),
  host: alias('serverVariables.smartroomApiUrl'),
  namespace: alias('serverVariables.smartroomApiNamespace'),
  pluralizedEndpoint: true,

  pathForType: function (modelName) {
    var decamelized = decamelize(modelName);

    let normalized = decamelized.replace('smartroom/', '');

    if (this.pluralizedEndpoint) {
      return pluralize(normalized);
    }

    return normalized;
  },

  normalizeModelName(modelName) {
    return modelName.replace('smartroom/', '');
  },

  headers: computed('session.data.authenticated.access_token', function () {
    let headers = {};
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }),
  buildBaseURL(query, modelName) {
    let host = this.get('host');
    let prefix = this.urlPrefix();

    let siteId = query.siteId;
    delete query.siteId;

    let url = [];
    url.push(`sites/${siteId}`);

    url.push(this.pathForType(modelName));

    if (prefix) {
      url.unshift(prefix);
    }

    url = url.join('/');

    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }
    return url;
  },

  urlForQuery(query, modelName) {
    return this.buildBaseURL(query, modelName);
  },

  urlForQueryRecord(query, modelName) {
    let url = this.buildBaseURL(query, modelName);

    let id = query.id;
    delete query.id;

    if (id) {
      url += `/${encodeURIComponent(id)}`;
    }

    return url;
  },
});
