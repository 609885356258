import Component from '@ember/component';
import { action, get, set, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { all, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
  EReportStatus,
  ProjectTabMap,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
} from 'cing-app/pods/smartroom/service';
import { data } from 'jquery';
import { isEmpty } from '@ember/utils';

export const SYNC_NEW = 1;
export const SYNC_DELETED = 2;
export const SYNC_MISSING_INVESTOR_NUMBER = 3;
export const SYNC_INVESTOR_CHANGED = 4;
export const SYNC_FOLDERS_CHANGED = 5;
export const SYNC_INVESTOR_NUMBER_CHANGED = 6;
export const SYNC_NAME_CHANGED = 7;
export const SYNC_ROLE_CHANGED = 8;

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  a = a.sort();
  b = b.sort();

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

function normalizeString(str) {
  if (!str) {
    return str;
  }

  let outStr = JSON.stringify(str)
    .replace(/[^0-9a-z]/gi, ' ')
    .replace(/\s+/g, ' ')
    .toLowerCase();
  return outStr;
}

class SyncItem {
  doSync = false;
  lpComm = null;
  party = null;
  investor = null;
  dcInvestor = null;
  srUser = null;
  srCategory = null;
  results = null;

  _lpComms = null;
  _parties = null;
  _investors = null;
  _dcInvestors = null;
  _srUsers = null;
  _srCategories = null;

  constructor(lpComms, parties, investors, dcInvestors, srUsers, srCategories) {
    this._lpComms = lpComms;
    this._parties = parties;
    this._investors = investors;
    this._dcInvestors = dcInvestors;
    this._srUsers = srUsers;
    this._srCategories = srCategories;
    this.results = [];
  }

  fromLPComm(lpComm) {
    // save the LP comm first
    set(this, 'lpComm', lpComm);
    set(this, 'dcInvestor', lpComm.limitedPartner);
    /*
    // find the investor
    // investor number is missing
    } else {
        this.results.pushObject(SYNC_MISSING_INVESTOR_NUMBER);
        return;
    }
    */

    // NOW PERFORM ANALYSIS
    let party = null;

    // get normalized LP contact name
    let lpContactname = normalizeString(get(lpComm, 'contact.name'));

    // try to get the party by Email address (if present on lpComm)
    if (lpComm.email) {
      // try to get the party first
      party = this._parties.find((p) => {
        return (
          get(p, 'email.emailAddress') &&
          get(p, 'email.emailAddress').toLowerCase() ===
            lpComm.email.toLowerCase() &&
          get(p, 'investor.investorNumber') === lpComm.investorNumber
        );
      });

      // try to detect if Investor Number has changed
      if (!party) {
        party = this._parties.find((p) => {
          return (
            get(p, 'email.emailAddress') &&
            get(p, 'email.emailAddress').toLowerCase() ===
              lpComm.email.toLowerCase() &&
            lpComm.limitedPartner &&
            get(p, 'investor.company.name') === lpComm.limitedPartner.name
          );
        });
      }
      // otherwise try to match the party by name (and investor number or limited partner name)
    } else {
      party = this._parties.find((p) => {
        return (
          normalizeString(get(p, 'person.fullName')) === lpContactname &&
          get(p, 'investor.investorNumber') === lpComm.investorNumber
        );
      });

      if (!party) {
        party = this._parties.find((p) => {
          return (
            normalizeString(get(p, 'person.fullName')) === lpContactname &&
            lpComm.limitedPartner &&
            get(p, 'investor.company.name') === lpComm.limitedPartner.name
          );
        });
      }
    }

    if (
      party &&
      lpComm.investorNumber !== party.get('investor.investorNumber')
    ) {
      this.results.pushObject(SYNC_INVESTOR_NUMBER_CHANGED);
    }

    // LP COMM IS NOT PRESENT - MARK AS NEW RECORD
    if (!party) {
      set(this, 'doSync', true);
      this.results.pushObject(SYNC_NEW);
      return;
    }

    set(this, 'party', party);

    // CHECK FOLDER ACCESS
    let comms = this.lpComm.communications.mapBy('name');

    let folders = get(party, 'srFolders');

    if (!arraysEqual(comms, folders)) {
      this.results.pushObject(SYNC_FOLDERS_CHANGED);
    }

    // CHECK CONTACT NAME
    let partyName = normalizeString(get(party, 'person.fullName'));

    if (partyName !== lpContactname) {
      this.results.pushObject(SYNC_NAME_CHANGED);
    }

    // CHECK ROLE
    let partyRole = normalizeString(
      get(party, 'interestedPartyRole.name') || ''
    );
    let lpRole = normalizeString(get(lpComm, 'role') || '');

    if (partyRole !== lpRole) {
      this.results.pushObject(SYNC_ROLE_CHANGED);
    }

    // GET SR USER/CATEGORY
    let srUser = this._srUsers.find((srUser) => {
      let partyEmail = get(party, 'email.emailAddress') || get(lpComm, 'email');
      let srUserEmail = srUser.email;

      if (
        partyEmail &&
        partyEmail.toLowerCase() === srUserEmail.toLowerCase()
      ) {
        return true;
      }
    });

    set(this, 'srUser', srUser);

    if (srUser) {
      set(
        this,
        'srCategory',
        this._srCategories.findBy('id', srUser.categoryId.toString())
      );
    }

    if (get(this, 'results.length')) {
      set(this, 'doSync', true);
    }
  }

  fromParty(party) {
    // save the party first
    set(this, 'party', party);

    // find the investor
    if (get(party, 'investor.investorNumber')) {
      set(this, 'investor', get(party, 'investor'));
    }

    this.results.pushObject(SYNC_DELETED);

    /*
    // NOW PERFORM ANALYSIS
    // try to get the party first
    let lpComm = this._lpComms.find((l) => {
        return ((get(party, 'email.emailAddress') && get(party, 'email.emailAddress').toLowerCase() === l.email.toLowerCase()) 
                && (get(p, 'investor.investorNumber') === lpComm.investorNumber));
    });

    // LP COMM IS NOT PRESENT - MARK AS NEW RECORD
    if (!party) {
        this.results.pushObject(SYNC_NEW);
        return;
    }

    // CHECK FOLDER ACCESS
    let comms = this.lpComm.communications.mapBy('name');

    // get party folders
    let folders = get(party, "srFolders");

    if (!arraysEqual(comms, folders)) {
        this.results.pushObject(SYNC_FOLDERS_CHANGED);
    }
    */

    if (get(this, 'results.length')) {
      set(this, 'doSync', true);
    }
  }

  matchParty(party) {
    let isIgor =
      get(party, 'email.emailAddress') === 'idvorsky@bmcgroup.com'
        ? true
        : false;

    /*
    if (isIgor) {
        console.log("PARTY 1: ", party, ' PARTY: 2: ', this.party, ' INV N1: ', get(party, 'investor.investorNumber'), ' INV N2: ', this.lpComm.investorNumber);
    }
    */

    if (
      party === this.party &&
      (get(party, 'investor.investorNumber') === this.lpComm.investorNumber ||
        normalizeString(get(party, 'investor.company.name')) ===
          normalizeString(this.lpComm.limitedPartner.name))
    ) {
      return true;
    }

    return false;
  }
}
import classic from 'ember-classic-decorator';

@classic
export default class SyncDCContacts extends Component {
  @service smartroom;
  @service store;
  @service config;
  @service abModels;
  @service seActions;

  @alias('context.project') project;

  fund = null;
  dcFund = null;

  investors = null;
  parties = null;
  dcInvestors = null;
  dcLPComms = null;
  srUsers = null;
  srCategories = null;
  allSelected = false;

  showChangesOnly = true;

  init() {
    super.init(...arguments);
    this.initTask.perform();
  }

  @task
  *initTask() {
    set(this, 'syncItems', []);
    set(this, 'fund', yield get(this.project, 'fund'));

    if (!this.fund) {
      throw Error('DealCloud sync is only available for projects with Fund');
    }

    // retrieve the DC fund associated with the project
    set(this, 'dcFund', get(this.project, 'dealCloudFund'));

    // retrieve the available interested party roles
    let interestedPartyRoles = yield this.store.query('interested-party-role', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
    set(this, 'interestedPartyRoles', interestedPartyRoles.toArray());

    // retrieve the available viewer groups
    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: AclPermissionType.ProjectTabRead,
      })
    );

    /*
    let viewerGroups = (yield this.store.query('acl-role', {
        page: {
            size: 1000
        },
        condition: vgQuery.serialize(),
        sort: 'description'            
    })).toArray();

    set(this, 'viewerGroups', viewerGroups);
    */

    // get the smartroomid, top level folder and default all access folders
    let srConnection = this.context.srConnection;

    if (srConnection) {
      this.setProperties({
        smartRoomId: srConnection.SRSiteId,
        smartRoomFolderId: srConnection.SRFolderId,
        defaultAllAccessFolders: yield srConnection.allAccessSmartRoomFolders,
      });
    }

    // get all the Fund Investors from DC
    set(this, 'dcInvestors', yield this.getDCInvestors.perform());

    // get all the Fund LP Comms from DC
    set(this, 'dcLPComms', yield this.getDCLPComms.perform());

    if (this.smartRoomId) {
      // get all the SmartRoom users
      set(this, 'srUsers', yield this.getSRUsers.perform());

      // get all the SmartRoom users
      set(this, 'srCategories', yield this.getSRCategories.perform());
    }

    // get all interested parties
    set(this, 'parties', yield this.getParties.perform());

    // get all investors
    set(this, 'investors', yield this.getInvestors.perform());

    yield this.analyze.perform();
  }

  @computed('project.abProject')
  get investorModelName() {
    let abProject = get(this.project, 'abProject');

    if (abProject) {
      return this.abModels.getModelName(this.project, 'investor');
      //return this.abModels.getModelName(abProject, 'investor');
    }
  }

  @computed('project.abProject')
  get capitalCallModelName() {
    let abProject = get(this.project, 'abProject');

    if (abProject) {
      return this.abModels.getModelName(this.project, 'capital-call');
    }
  }

  @computed('project.abProject')
  get distributionModelName() {
    let abProject = get(this.project, 'abProject');

    if (abProject) {
      return this.abModels.getModelName(this.project, 'distribution');
    }
  }

  @computed('syncItems.[]', 'showChangesOnly')
  get filteredSyncItems() {
    if (this.syncItems && this.syncItems.length) {
      if (this.showChangesOnly) {
        return this.syncItems.filter((item) => {
          return get(item, 'results.length');
        });
      } else {
        return this.syncItems;
      }
    }
  }

  @computed('filteredSyncItems.@each.doSync')
  get selectedItems() {
    if (this.filteredSyncItems && this.filteredSyncItems.length) {
      return this.filteredSyncItems.filter((item) => {
        return item.doSync;
      });
    }

    return [];
  }

  @task
  *getDCInvestors() {
    let data = yield this.store.query('deal-cloud-investor', {
      filter: {
        'fund-ids': `${this.dcFund.id}`,
      },
      page: {
        size: 10000,
        number: 1,
      },
    });

    return data.toArray();
  }

  @task
  *getDCLPComms() {
    let data = yield this.store.query('deal-cloud-lp-communication', {
      filter: {
        'fund-ids': `${this.dcFund.id}`,
      },
      page: {
        size: 10000,
        number: 1,
      },
    });

    return data.toArray();
  }

  @task
  *getSRUsers() {
    let data = yield this.store.query('smartroom/user', {
      siteId: this.smartRoomId,
      pageNo: 1,
      pageSize: 10000,
    });

    return data.toArray();
  }

  @task
  *getSRCategories() {
    let data = yield this.store.query('smartroom/category', {
      siteId: this.smartRoomId,
      pageNo: 1,
      pageSize: 10000,
    });

    return data.toArray();
  }

  @task
  *getParties() {
    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.project.id,
      })
    );
    query.add(
      Filter.create({
        name: 'person-id',
        operator: FilterOperators.NOT_NULL,
      })
    );

    let data = yield this.store.query('interested-party', {
      condition: query.serialize(),
      include:
        'email,investor,person,interested-party-role,interested-party-type,viewer-group',
      page: {
        size: 1000,
        number: 1,
      },
    });

    return data.toArray();
  }

  @task
  *getInvestors() {
    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: this.fund.id,
      })
    );

    let data = yield this.store.query('investor', {
      condition: query.serialize(),
      include: 'company,type',
      page: {
        size: 1000,
        number: 1,
      },
    });

    return data.toArray();
  }

  createSyncItem() {
    return new SyncItem(
      this.lpComms,
      this.parties,
      this.investors,
      this.dcInvestors,
      this.srUsers,
      this.srCategories
    );
  }

  findSyncItemForParty(party) {
    let matchingSyncItem = null;

    for (var a = 0; a < this.syncItems.length; a++) {
      let syncItem = this.syncItems[a];

      if (syncItem.matchParty(party)) {
        matchingSyncItem = syncItem;
        break;
      }
    }

    if (!matchingSyncItem) {
      let syncItem = this.createSyncItem();

      syncItem.fromParty(party);

      return syncItem;
    }
  }

  @task
  *analyze() {
    // first create sync result items from all the LP comms
    this.dcLPComms.forEach((lpComm) => {
      let s = this.createSyncItem();
      s.fromLPComm(lpComm);
      this.syncItems.pushObject(s);
    });

    let partySyncItems = [];

    this.parties.forEach((party) => {
      let s = this.findSyncItemForParty(party);

      if (s) {
        partySyncItems.pushObject(s);
      }
    });

    let syncItems = this.syncItems.concat(partySyncItems);

    syncItems = syncItems.sortBy('dcInvestor.name');

    set(this, 'syncItems', syncItems);
  }

  @task
  *doSyncTask() {
    let allTasks = [];
    let syncItem = null;

    let newItems = this.filteredSyncItems.filter((item) => {
      return item.doSync && item.results.includes(SYNC_NEW);
    });

    if (newItems.length) {
      yield this.syncNewItemsTask.perform(newItems);
      let addedParties = yield this.getAddedPartiesTask.perform(newItems);

      yield this.createSmartRoomSecurityProfilesTask.perform(addedParties);

      yield this.createSmartRoomUsersTask.perform(addedParties);
    }

    let syncItems = this.filteredSyncItems.filter((item) => {
      return (
        !item.results.includes(SYNC_NEW) &&
        item.doSync &&
        item.results &&
        item.results.length
      );
    });

    for (var a = 0; a < syncItems.length; a++) {
      syncItem = syncItems[a];
      allTasks.pushObject(this.syncItemTask.perform(syncItem));
    }

    yield all(allTasks);

    if (this.context.onComplete) {
      this.context.onComplete();
    }
  }

  @task
  *syncNewItemsTask(syncItems) {
    let importRows = [];

    let userGroupQuery = Expressions.create();
    userGroupQuery.add(
      Filter.create({
        name: 'instance-acl-role-assignments.acl-role.acl-permissions.permission-type-id',
        operator: FilterOperators.NOT_IN,
        value: AclPermissionType.Admin,
      })
    );

    let userGroup = (yield this.store.query('user-group', {
      condition: userGroupQuery.serialize(),
      page: {
        size: 1,
        number: 1,
      },
    })).firstObject;

    for (var a = 0; a < syncItems.length; a++) {
      let item = syncItems[a];
      let rowData = {
        InvestorName: get(item, 'lpComm.limitedPartner.name'),
        InvestorNumber: get(item, 'lpComm.investorNumber'),
        InvestorFundId:
          (get(this.project, 'investorFundIdPrefix') || '') +
          get(item, 'lpComm.investorNumber'),
        DealCloudInvestorId: get(item, 'lpComm.limitedPartner.id'),
        ContactName: get(item, 'lpComm.contact.name'),
        DealCloudContactId: get(item, 'lpComm.contact.entityId'),
        Communications: get(item, 'lpComm.communications')
          .mapBy('name')
          .join(','),
        UserGroup:
          get(item, 'lpComm.email') && userGroup ? userGroup.name : null,
        Role: get(item, 'lpComm.role'),
        SRAccess: get(item, 'lpComm.email') ? true : false,
        PortalAccess: get(item, 'lpComm.email') ? true : false,
        ViewerGroup: 'LP',
        //Company: item.get('company'),
        ContactEmail: get(item, 'lpComm.email'),
      };

      importRows.pushObject(rowData);
    }

    let importAction = yield this.seActions.importPartiesStepOne(
      new Blob([JSON.stringify(importRows)], { type: 'application/json' }),
      this.project.id
    );

    yield this.seActions.importPartiesStepTwo(importAction);
  }

  @task
  *getAddedPartiesTask(syncItems) {
    // get list of all added parties
    let addedPartiesQuery = Expressions.create({});
    addedPartiesQuery.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.project.id,
      })
    );
    addedPartiesQuery.add(
      Filter.create({
        name: 'person.deal-cloud-id',
        operator: FilterOperators.IN,
        value: syncItems.mapBy('lpComm.contact.entityId'),
      })
    );
    /*
    addedPartiesQuery.add(Filter.create({
      name: 'email.email-address',
      operator: FilterOperators.IN,
      value: syncItems.mapBy('lpComm.email'),
    }));
    */

    // get all added parties
    return (yield this.store.query('interested-party', {
      condition: addedPartiesQuery.serialize(),
      include: 'email,investor,person',
      page: {
        size: 1000,
        number: 1,
      },
    })).toArray();
  }

  @task
  *createSmartRoomSecurityProfilesTask(addedParties) {
    let allTasks = [];

    for (var a = 0; a < addedParties.length; a++) {
      allTasks.push(
        this.createSmartRoomSecurityProfileForContact.perform(addedParties[a])
      );
    }

    yield all(allTasks);
  }

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  *createSmartRoomSecurityProfileForContact(party) {
    let emailAddress = yield party.get('email.emailAddress');

    if (!emailAddress) {
      return;
    }

    emailAddress = emailAddress.toLowerCase();

    let category = null;
    try {
      category = yield this.smartroom.createCategory(
        this.smartRoomId,
        emailAddress
      );
    } catch (e) {
      // try to match to existing profile since SmartRoom says it already exists
      if (e instanceof CategoryAlreadyExists) {
        let securityProfiles = yield this.store.query('smartroom/category', {
          siteId: this.smartRoomId,
        });

        category = securityProfiles.find((item) => {
          return item.categoryName.toLowerCase() === emailAddress;
        });
      } else {
        throw e;
      }
    }

    // if no category was found, throw an error
    if (!category) {
      throw new Error('Security profile could not be created');
    }

    party.set('srProfile', category.id);

    party.save();

    let investor = yield party.get('investor');

    let investorFolder = null;

    if (investor.smartRoomFolderId) {
      try {
        investorFolder = yield this.get('store').queryRecord(
          'smartroom/folder',
          {
            siteId: this.smartRoomId,
            id: investor.smartRoomFolderId,
          }
        );
      } catch (e) {
        console.log('Could not get Investor folder', e);
      }
    }

    if (!investorFolder) {
      investorFolder = yield this.createInvestorFolderTask.perform(investor);
      set(investor, 'smartRoomFolderId', investorFolder.id);
      yield investor.save();
    }

    if (investorFolder) {
      yield this.updateContactFoldersTask.perform(party, investorFolder);
    }
  }

  @task
  *createInvestorFolderTask(investor) {
    // fail if smartRoomId is not set
    if (this.smartRoomId === null) {
      throw new Error('There is no SmartRoom associated with the project.');
    }

    let company = yield investor.get('company');

    // this is the investor name (investors are linked to company entities)
    let investorName = company.get('name');

    // folder doesn't exist, create it
    let investorFolder = yield this.smartroom.createFolderEnforce(
      this.smartRoomId,
      this.smartroom.normalizeFolderName(company.name),
      this.smartRoomFolderId
    );

    investor.set('smartRoomFolderId', investorFolder.id);
    yield investor.save();

    // create the investor structure
    let result = yield this.smartroom.createInvestorStructure(
      this.smartRoomId,
      investorFolder.id
    );

    return investorFolder;
  }

  @task
  *updateContactFoldersTask(party, investorFolder) {
    let investor = yield get(party, 'investor');

    let investorSubFolders = (yield this.get('store').query(
      'smartroom/folder',
      {
        siteId: this.smartRoomId,
        parentId: investorFolder.id,
      }
    )).toArray();

    // apply security rights for all folders in path
    let folderPathItems = investorFolder.pathIds.split('/');

    let folderSecurities = [];

    // apply security for investor folder (view, print, save)
    folderSecurities.push({
      itemId: investorFolder.id,
      rightId: 32,
    });

    // apply security for parent folders (up to SmartRoom root folder)
    folderPathItems.forEach((folderId) => {
      if (folderId) {
        folderSecurities.push({
          itemId: folderId,
          rightId: 32,
        });
      }
    });

    // apply security to investor subfolders
    let partyFolders = party.get('srFolders');

    if (partyFolders && partyFolders.length) {
      if (
        partyFolders[0].toLowerCase() === 'all access' ||
        partyFolders[0].toLowerCase() === 'all'
      ) {
        for (var a = 0; a < investorSubFolders.length; a++) {
          let iFolder = investorSubFolders[a];

          folderSecurities.push({
            itemId: iFolder.id,
            rightId: 5,
          });
        }

        for (var a = 0; a < this.defaultAllAccessFolders.length; a++) {
          let dFolder = this.defaultAllAccessFolders[a];

          folderSecurities.push({
            itemId: dFolder.id,
            rightId: 5,
          });
        }
      } else {
        for (var a = 0; a < investorSubFolders.length; a++) {
          let iFolder = investorSubFolders[a];

          folderSecurities.push({
            itemId: iFolder.id,
            rightId: partyFolders.includes(iFolder.get('name')) ? 5 : 26,
          });
        }
      }
    }

    yield this.smartroom.setCategorySecurity(
      this.smartRoomId,
      party.srProfile,
      folderSecurities
    );
  }

  @task
  *createSmartRoomUsersTask(addedParties) {
    let usersData = [];

    for (var a = 0; a < addedParties.length; a++) {
      let party = addedParties[a];
      let emailAddress = yield party.get('email.emailAddress');

      if (!emailAddress) {
        continue;
      }

      emailAddress = emailAddress.toLowerCase();
      let person = yield party.get('person');

      if (party.srProfile && emailAddress) {
        usersData.push({
          categoryId: party.srProfile,
          email: emailAddress,
          firstName: person ? person.firstName : '',
          lastName: person ? person.lastName : '',
          companyName: null,
        });
      }
    }

    if (usersData.length) {
      // create the users (by default they will have status 'hold' and invites won't be sent)
      yield this.smartroom.addUsers(
        this.smartRoomId,
        usersData,
        this.defaultUserStatus
      );
    }
  }

  @task({
    maxConcurrency: 4,
    enqueue: true,
  })
  *syncItemTask(syncItem) {
    let isDeleted = syncItem.results.includes(SYNC_DELETED);

    if (isDeleted) {
      yield this.syncItem_delete.perform(syncItem);
    } else {
      for (var a = 0; a < syncItem.results.length; a++) {
        let result = syncItem.results[a];

        if (result === SYNC_FOLDERS_CHANGED) {
          yield this.syncItem_updateFolders.perform(syncItem);
        } else if (result === SYNC_INVESTOR_NUMBER_CHANGED) {
          yield this.syncItem_updateInvestorNumber.perform(syncItem);
        } else if (result === SYNC_NAME_CHANGED) {
          yield this.syncItem_updateContactName.perform(syncItem);
        } else if (result === SYNC_ROLE_CHANGED) {
          yield this.syncItem_updateRole.perform(syncItem);
        }
      }
    }
  }

  @task
  *syncItem_delete(syncItem) {
    let party = syncItem.party;

    let investor = yield party.get('investor');

    if (investor && investor.smartRoomFolderId && this.smartRoomId) {
      let folderSecurities = [];

      // apply security for investor folder (view, print, save)
      folderSecurities.push({
        itemId: investor.smartRoomFolderId,
        rightId: null,
      });

      try {
        yield this.smartroom.setCategorySecurity(
          this.smartRoomId,
          party.srProfile,
          folderSecurities
        );
      } catch (e) {
        console.error('Could not modify SmartRoom rights for this contact.');
        console.log(e);
      }
    }

    yield party.destroyRecord();
  }

  @task
  *syncItem_updateInvestorNumber(syncItem) {
    // update SmartExchange investor number
    let investor = yield get(syncItem, 'party.investor');

    // remember the original investor number
    let originalInvestorNumber = investor.investorNumber;
    let originalInvestorFundId = investor.investorFundId;

    let newInvestorNumber = get(syncItem, 'lpComm.investorNumber');
    let newInvestorFundId =
      (get(this.project, 'investorFundIdPrefix') || '') + newInvestorNumber;

    set(investor, 'investorNumber', newInvestorNumber);
    set(investor, 'investorFundId', newInvestorFundId);

    yield investor.save();

    // update app builder investor number
    let abInvestor = (yield this.store.query(this.investorModelName, {
      filter: {
        'inv-fund-id': 'eq:' + originalInvestorFundId,
      },
    })).firstObject;

    if (abInvestor) {
      set(abInvestor, 'fundInvestorNumber', newInvestorNumber);
      set(abInvestor, 'invFundId', newInvestorFundId);

      yield abInvestor.save();

      // update the invFundId on all related capital calls
      let capitalCalls = (yield this.store.query(this.capitalCallModelName, {
        filter: {
          'inv-fund-id': 'eq:' + originalInvestorFundId,
        },
      })).toArray();

      let distributions = (yield this.store.query(this.distributionModelName, {
        filter: {
          'inv-fund-id': 'eq:' + originalInvestorFundId,
        },
      })).toArray();

      let allTransactions = capitalCalls.concat(distributions);

      let allTasks = [];
      let tr = null;

      // update inv-fund-id on all related transactions in AB project
      for (var a = 0; a < allTransactions.length; a++) {
        tr = allTransactions[a];
        set(tr, 'invFundId', newInvestorFundId);
        set(tr, 'investorNumber', newInvestorNumber);

        allTasks.pushObject(this.updateRecord.perform(tr));
      }

      yield allTasks;
    }
  }

  @task
  *syncItem_updateContactName(syncItem) {
    // update SmartExchange investor number
    let person = yield get(syncItem, 'party.person');
    let party = syncItem.party;

    if (person) {
      let fullName = get(syncItem, 'lpComm.contact.name');
      person.parseFromFullName(fullName);
      set(person, 'fullName', fullName);
      yield person.save();

      if (party) {
        set(party, 'name', fullName);
        yield party.save();
      }
    }
  }

  @task
  *syncItem_updateRole(syncItem) {
    let role = yield get(syncItem, 'lpComm.role');
    let party = syncItem.party;

    if (party) {
      // check if the new role already exists
      let newRole = this.interestedPartyRoles.findBy('name', role);

      if (!newRole) {
        newRole = this.store.createRecord('interested-party-role', {
          name: role,
        });

        yield newRole.save();
      }

      set(party, 'interestedPartyRole', newRole);

      yield party.save();
    }
  }

  @task
  *syncItem_updateFolders(syncItem) {
    let party = syncItem.party;
    let investor = yield party.get('investor');

    let investorFolder = null;

    if (investor.smartRoomFolderId) {
      try {
        investorFolder = yield this.get('store').queryRecord(
          'smartroom/folder',
          {
            siteId: this.smartRoomId,
            id: investor.smartRoomFolderId,
          }
        );
      } catch (e) {
        console.log('Could not get Investor Folder', e);
      }
    }

    if (!investorFolder) {
      investorFolder = yield this.createInvestorFolderTask.perform(investor);
      set(investor, 'smartRoomFolderId', investorFolder.id);
      yield investor.save();
    }

    set(
      party,
      'srFolders',
      get(syncItem, 'lpComm.communications').mapBy('name')
    );

    yield party.save();

    if (investorFolder) {
      yield this.updateContactFoldersTask.perform(party, investorFolder);
    }
  }

  @task({
    maxConcurrency: 4,
    enqueue: true,
  })
  *updateRecord(record) {
    yield record.save();
  }

  @action
  toggleAll() {
    this.toggleProperty('allSelected');

    this.syncItems.forEach((item) => {
      set(item, 'doSync', this.allSelected);
    });
  }

  @action
  doSync() {
    this.doSyncTask.perform();
  }
}
