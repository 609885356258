import Component from '@glimmer/component';
//@ts-ignore
import { set, setProperties } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import {
  ConnectionTypes,
  ConnectionTypesMap,
  ABConnectionTypes,
} from 'cing-app/utils/lookups';
import { action } from '@ember/object';

import { inject as service } from '@ember/service';
import {
  Query,
  Filter,
  FilterOperators,
  QueryOperators,
} from 'cing-app/utils/query-builder';

import { task, timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface AppbuilderLinksEditArgs {
  model: AppbuilderLink;
  onSave: () => void;
}

//const ConnectionTypeOptions = Object.entries(ConnectionTypes)

const ConnectionTypeOptions: any = [];

for (const [key, value] of Object.entries(ConnectionTypesMap)) {
  ConnectionTypeOptions.pushObject({
    name: key,
    id: value,
  });
}

export default class ManageUsers extends Component<AppbuilderLinksEditArgs> {
  ConnectionTypes = ConnectionTypes;
  ConnectionTypeOptions = ConnectionTypeOptions;
  ABConnectionTypes = ABConnectionTypes;
  ABConnectionTypeOptions = Object.values(ABConnectionTypes);

  @service('user-instances') userInstances: any;
  @service('store') declare store: Store;

  @tracked
  ABCase: any;

  @tracked
  ABProjects: any;

  @tracked
  model?: AppbuilderLink;

  get styleNamespace() {
    return podNames['manage-connections/appbuilder-links/edit'];
  }

  constructor(owner: any, args: AppbuilderLinksEditArgs) {
    super(owner, args);

    if (this.model?.isNew) {
      if (this.userInstances.abInstances.length === 1) {
        this.selectABInstance(this.userInstances.abInstances[0]);
      }
    }

    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    this.model = await this.args.model;

    if (!this.model.isNew && this.model.ABCaseId && this.model.ABInstanceName) {
      let selectedAbInstance = this.userInstances.abInstances.findBy(
        'name',
        this.model.ABInstanceName
      );

      this.ABCase = (
        await this.store.query('appbuilder/case', {
          condition: new Query([
            new Filter('id', FilterOperators.EQUAL, this.model.ABCaseId),
          ]).serialize(),
          page: {
            size: 1,
          },
          abInstanceUrl: selectedAbInstance.url,
        })
      ).firstObject;
    }
  });

  @task({ restartable: true })
  _searchABProjects = taskFor(async (term: string) => {
    await timeout(400);

    let query = new Query(QueryOperators.OR);

    query.add(new Filter('display-name', FilterOperators.LIKE, term));

    query.add(
      new Filter('project-template.template-name', FilterOperators.LIKE, term)
    );

    if (Number.isInteger(Number(term))) {
      query.add(new Filter('id', FilterOperators.EQUAL, term));
    }

    let selectedAbInstance = this.userInstances.abInstances.findBy(
      'name',
      this.model.ABInstanceName
    );

    this.ABProjects = await this.store.query('appbuilder/case', {
      condition: query.serialize(),
      abInstanceUrl: selectedAbInstance.url,
      page: {
        size: 100,
      },
      sort: '-id',
    });

    return this.ABProjects;
  });

  @action
  async searchABProjects(term: string) {
    return this._searchABProjects.perform(term);
  }

  @action
  selectABInstance(abInstance: any) {
    set(this.model, 'ABInstanceName', abInstance?.name);
  }

  @action
  selectABProject(ABCase: any) {
    this.ABCase = ABCase;

    setProperties(this.model, {
      ABCaseId: ABCase?.id,
      ABDisplayName: ABCase?.displayName,
    });
  }

  @action
  addFilter() {
    this.model.ABFilters.pushObject({
      name: '',
      value: '',
    });
  }

  @action
  removeFilter(filter) {
    this.model.ABFilters.removeObject(filter);
  }
}
