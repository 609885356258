import Model, { attr } from '@ember-data/model';

export default class FieldProperty extends Model {
  @attr("number") fieldId?: number;
  @attr("string") propertyName?: string;
  @attr("string") propertyType?: string;
  @attr("string") value?: string;

  @attr("boolean") isVisible?: boolean;
  @attr("boolean") isDisabled?: boolean;

  @attr('date') createTime!: Date;
  @attr('date') modifyTime!: Date;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/field-property': FieldProperty;
  }
}
