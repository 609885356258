import Service from '@ember/service';

import { get, set } from '@ember/object';
import { task } from 'ember-concurrency';
import { timeout, waitForProperty } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  ActionTypeMap,
  ActionStatusMap,
  ActionRelatedEntityType,
} from 'cing-app/utils/lookups';
import {
  Expressions,
  FilterOperators,
  Filter,
} from 'cing-app/mixins/filter-builder';
import fetch from 'fetch';

let ErrorActionStates = [
  ActionStatusMap.Dropped,
  ActionStatusMap.Error,
  ActionStatusMap.Expired,
  ActionStatusMap.Terminated,
  ActionStatusMap.Unauthorized,
  ActionStatusMap.InvalidData,
  ActionStatusMap.NotAvailable,
];
//let FinalActionStates = [ActionStatusMap.Dropped, ActionStatusMap.Error, ActionStatusMap.Expired, ActionStatusMap.Finished, ActionStatusMap.Terminated, ActionStatusMap.Unauthorized];
let ACTION_EXECUTION_TIMEOUT = 60 * 60 * 1000; // 1 hour (in ms)
import classic from 'ember-classic-decorator';

import { assign } from '@ember/polyfills';

@classic
export default class ActionsService extends Service {
  @service store;
  @service session;
  @service config;
  @service serverVariables;

  _runningActions = [];
  _completedActions = [];

  /**** PUBLIC APIs ****/

  waitForExistingAction(action) {
    return this._waitForAction.perform(action);
  }

  sendEmail(
    email,
    splitByRecipients = true,
    relatedEntityType = null,
    relatedEntityId = null
  ) {
    return this.sendEmailTask.perform(
      email,
      splitByRecipients,
      relatedEntityType,
      relatedEntityId
    );
  }

  sendAlert(alertModel) {
    return this.sendAlertTask.perform(alertModel);
  }

  domainVerify(domainId) {
    return this.domainVerifyTask.perform(domainId);
  }

  domainExists(provider, domainName) {
    return this.domainExistsTask.perform(provider, domainName, null, null);
  }

  domainsExists(provider, domainName, trackingName, bounceName) {
    return this.domainExistsTask.perform(
      provider,
      domainName,
      trackingName,
      bounceName
    );
  }

  domainCreate(domainModel) {
    return this.domainCreateTask.perform(domainModel);
  }

  getVerifiedDomains() {
    return this.getVerifiedDomainsTask.perform();
  }

  uploadImage(
    file,
    path = '',
    isPublic = true,
    fileName = null,
    fileType = null
  ) {
    return this.createUploadImageTask.perform(
      file,
      path,
      isPublic,
      fileName,
      fileType
    );
  }

  createSmartRoom(owner, count) {
    return this.createSmartRoomTask.perform(owner, count);
  }

  importPartiesStepOne(partiesFile, projectId = null) {
    return this.importPartiesStepOneTask.perform(partiesFile, projectId);
  }

  importPartiesStepTwo(action) {
    return this.importPartiesStepTwoTask.perform(action);
  }

  exportData(
    entityType,
    fileName,
    sortBy,
    condition,
    attributes,
    columnFormats = [],
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return this.exportDataTask.perform(
      entityType,
      fileName,
      sortBy,
      condition,
      attributes,
      columnFormats,
      fileType
    );
  }

  crmAction(type, caseId, context) {
    return this.crmActionTask.perform(type, caseId, context);
  }

  /**** END PUBLIC APIs ****/

  @task
  *sendEmailTask(email, splitByRecipients, relatedEntityType, relatedEntityId) {
    let action = this.store.createRecord('action', {
      type: ActionTypeMap.SendEmail,
      relatedEntityId: relatedEntityId,
      relatedEntityType: relatedEntityType,
      actionData: JSON.parse(JSON.stringify(email)),
    });

    yield action.save();

    // now wait for the binary to finish processing
    yield this._waitForAction.perform(action, [ActionStatusMap.Finished]);

    return action;
  }

  @task
  *sendAlertTask(alertModel) {
    let action = this.store.createRecord('action', {
      type: ActionTypeMap.Alert,
      actionData: {
        Alert: {
          TemplateId: null,
          TemplateData: alertModel.templateData,
          FromAddress: alertModel.fromAddress || null,
          TrackDelivery: true,
          TrackOpens: true,
          TrackBounces: true,
          TrackClicks: false,
          CreateUsers: true,
          LoginRedirectUrl: alertModel.alertTemplate.loginRedirectUrl,
          Subject: alertModel.subject,
          Recipients: alertModel.recipients,
          Attachments: alertModel.attachments,
          Values: alertModel.values,
        },
      },
    });

    yield action.save();

    // now wait for the binary to finish processing
    yield this._waitForAction.perform(action, [
      ActionStatusMap.Sending,
      ActionStatusMap.Finished,
    ]);

    return action;
  }

  @task
  *domainVerifyTask(domainId) {
    let action = this.store.createRecord('action', {
      type: ActionTypeMap.Domain,
      actionData: {
        Id: domainId,
        Operation: 'verify',
      },
    });

    yield action.save();

    // now wait for the binary to finish processing
    return this._waitForAction.perform(action, [ActionStatusMap.Finished]);
  }

  @task
  *getVerifiedDomainsTask() {
    let action = this.store.createRecord('action', {
      type: ActionTypeMap.DomainList,
      actionData: {
        Operation: 'list',
      },
    });

    yield action.save();

    // now wait for the binary to finish processing
    yield this._waitForAction.perform(action, [
      ActionStatusMap.Sending,
      ActionStatusMap.Finished,
    ]);

    return action;
  }

  @task
  *createUploadImageTask(file, path, isPublic, fileName, fileType) {
    let action = this.store.createRecord('action', {
      type: ActionTypeMap.UploadImage,
      actionData: {
        path: path,
        IsPublic: isPublic,
      },
    });

    yield action.save();

    // create upload action first
    yield this._waitForAction.perform(action, [
      ActionStatusMap.WaitingForUpload,
    ]);

    // upload the binary now
    yield this._uploadActionBinary.perform(action, file, fileName, fileType);

    // now wait for the binary to finish processing
    yield this._waitForAction.perform(action, [ActionStatusMap.Finished]);

    let actionResult = action.actionResult;

    // return the URL for uploaded image
    return {
      url: actionResult.Labels.url,
      storageFileName: actionResult.StorageFileName,
      originalFileName: actionResult.OriginalFileName,
      mimeType: actionResult.MimeType,
      size: actionResult.FileSize,
    };
  }

  @task
  *exportDataTask(
    entityType,
    fileName,
    sortBy,
    condition,
    attributes,
    columnFormats,
    fileType
  ) {
    if (condition && typeof condition === 'string') {
      condition = JSON.parse(condition);
    }

    let action = this.store.createRecord('action', {
      type: ActionTypeMap.DataExport,
      actionData: {
        EntityType: entityType,
        FileName: fileName,
        Filter: condition ? condition : {},
        FileType: fileType,
        SortBy: sortBy,
        Attributes: attributes,
        ColumnFormats: columnFormats,
      },
    });

    yield action.save();

    // now wait for the export to generate
    yield this._waitForAction.perform(action, [ActionStatusMap.Finished]);

    try {
      location.assign(action.actionResult.Labels.url);
    } catch {
      alert(
        `Exporting ${entityType} has failed. Please contact our support staff.`
      );
    }
    return action;
  }

  @task
  *importPartiesStepOneTask(partiesFile, projectId) {
    let action = this.store.createRecord('action', {
      type: ActionTypeMap.ImportPartiesToProject,
      actionData: {
        ProjectId: projectId,
      },
    });

    yield action.save();

    // create upload action first
    yield this._waitForAction.perform(action, ActionStatusMap.WaitingForUpload);

    // upload the binary now
    yield this._uploadActionBinary.perform(action, partiesFile);

    // now wait for the binary to finish processing
    yield this._waitForAction.perform(
      action,
      ActionStatusMap.ImportReadyForReview
    );

    return action;
  }

  @task
  *importPartiesStepTwoTask(action) {
    let url = `${this.config.get('APP.api.host')}/${this.config.get(
      'APP.api.namespace'
    )}/actions/next-step`;

    yield fetch(url, {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'content-type': 'application/vnd.api+json',
        authorization: `Bearer ${get(
          this.session,
          'data.authenticated.access_token'
        )}`,
      },
      body: JSON.stringify({
        data: {
          type: 'actions',
          id: action.id,
          attributes: {},
        },
      }),
    });

    // create upload action first
    yield this._waitForAction.perform(action, ActionStatusMap.Finished);

    // upload the binary now
    //yield this._uploadActionBinary.perform(action, partiesFile);

    // now wait for the binary to finish processing
    //yield this._waitForAction.perform(action, ActionStatusMap.ImportReadyForReview);

    //let actionResult = action.actionResult;

    return action;
  }

  @task
  *createSmartRoomTask(owner, count) {
    //console.log("ARGUMENTS: ", ...arguments);

    let action = this.store.createRecord('action', {
      type: ActionTypeMap.CreateSmartRoom,
      actionData: {
        Owner: owner,
        NumberOfSites: count,
      },
    });

    yield action.save();

    // create upload action first
    yield this._waitForAction.perform(action, ActionStatusMap.Finished);

    let actionResult = action.actionResult;

    return actionResult;
  }

  @task
  *_waitForAction(action, waitForStatus = [ActionStatusMap.Finished]) {
    let actionContainer = this._runningActions.findBy('actionId', action.id);

    if (!actionContainer) {
      actionContainer = {
        actionId: action.id,
        action: action,
        waitForStatus: waitForStatus,
        completed: false,
        startTime: new Date(),
      };

      this._runningActions.pushObject(actionContainer);
    }

    // startup action result checking if not already running
    if (!this._checkActionsResult.isRunning) {
      this._checkActionsResult.perform();
    }

    yield waitForProperty(actionContainer, 'completed', true);

    if (actionContainer.error) {
      throw actionContainer.error;
    }

    return action;
  }

  @task
  *_checkActionsResult() {
    yield timeout(500);

    if (this._runningActions.length === 0) {
      return;
    }

    let actionIds = this._runningActions.mapBy('actionId');

    let actionsQuery = Expressions.create();
    actionsQuery.add(
      Filter.create({
        name: 'id',
        operator: FilterOperators.IN,
        value: actionIds,
      })
    );

    let actions = yield this.store.query('action', {
      condition: actionsQuery.serialize(),
      page: {
        size: 1000,
        number: 1,
      },
    });

    actions.forEach((action) => {
      this._checkSingleActionResult(action);
    });

    yield this._checkActionsResult.perform();
  }

  _checkSingleActionResult(action) {
    let actionContainer = this._runningActions.findBy('actionId', action.id);

    var now = new Date();

    if (now - actionContainer.startTime > ACTION_EXECUTION_TIMEOUT) {
      this._runningActions.removeObject(actionContainer);

      let error = new Error('Action is taking too long to complete.');
      error.action = action;

      actionContainer.error = error;

      set(actionContainer, 'completed', true);
      return;
    }

    if (ErrorActionStates.includes(action.status)) {
      this._runningActions.removeObject(actionContainer);

      let errorMessage =
        get(action, 'actionResult.exception.Message') ||
        get(action, 'actionResult.exception') ||
        get(action, 'actionResult.error') ||
        'Action could not be completed. Please contact our support staff to resolve the issue.';

      let error = new Error(errorMessage);
      error.action = action;

      actionContainer.error = error;

      set(actionContainer, 'completed', true);
      return;
    }

    if (actionContainer.waitForStatus.includes(action.status)) {
      this._runningActions.removeObject(actionContainer);
      set(actionContainer, 'completed', true);
    }
  }

  @task
  *_uploadActionBinary(action, file, fileName, fileType) {
    let url = `${this.config.get('APP.api.host')}/${this.config.get(
      'APP.api.namespace'
    )}/actions/upload-binary`;

    // handle upload via File Upload object
    if (file.upload) {
      let response = yield file.upload(url, {
        fileKey: 'File',
        headers: {
          Authorization:
            'Bearer ' + this.get('session.data.authenticated.access_token'),
        },
        data: {
          ActionId: action.id,
        },
      });
    } else {
      let formData = new FormData();
      formData.append('ActionId', action.id);

      if (fileName) {
        formData.append('File', file, fileName);
      } else {
        formData.append('File', file);
      }

      yield fetch(url, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${get(
            this.session,
            'data.authenticated.access_token'
          )}`,
        },
        body: formData,
      });
    }
  }

  @task
  *domainExistsTask(provider, domainName, trackingDomain, bounceDomain) {
    let action = this.store.createRecord('action', {
      type: ActionTypeMap.Domain,
      actionData: {
        Provider: provider,
        SendingDomain: domainName,
        Operation: 'exists',
      },
    });
    if (trackingDomain) {
      action.actionData.TrackingDomain = trackingDomain;
    }
    if (bounceDomain) {
      action.actionData.BounceDomain = bounceDomain;
    }
    yield action.save();

    // now wait for the binary to finish processing
    return this._waitForAction.perform(action, [ActionStatusMap.Finished]);
  }

  @task
  *domainCreateTask(domainModel) {
    let action = this.store.createRecord('action', {
      type: ActionTypeMap.Domain,
      actionData: {
        Provider: domainModel.provider,
        SendingDomain: domainModel.sendingDomain,
        TrackingDomain: domainModel.trackingDomain,
        BounceDomain: domainModel.bounceDomain,
        Operation: 'create',
      },
    });

    yield action.save();

    // now wait for the binary to finish processing
    return this._waitForAction.perform(action, [ActionStatusMap.Finished]);
  }

  @task
  *crmActionTask(type, caseId, context) {
    let actionData = {
      CrmActionType: type,
      CaseID: caseId,
    };

    assign(actionData, context);

    let action = this.store.createRecord('action', {
      type: ActionTypeMap.ActionCRM,
      relatedEntityId: caseId,
      relatedEntityType: caseId ? ActionRelatedEntityType.Case : null,
      actionData,
    });

    yield action.save();

    return this._waitForAction.perform(action, [ActionStatusMap.Finished]);
  }
}
