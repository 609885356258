import ApplicationSerializer from './application';
import { dasherize, camelize } from '@ember/string';
import Model from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class AppbuilderLinkColumnSerializer extends ApplicationSerializer {
  attrs = {
    ABLink: { key: 'appbuilder-link' },
    maxWidth: { key: 'width' },
  };

  serialize(snapshot: any, options: any) {
    //let serialized = this._super(snapshot, options);
    let serialized: any = super.serialize(snapshot, options);

    serialized.data.attributes['property-name'] = dasherize(
      serialized.data.attributes['property-name']
    );
    return serialized;
  }

  normalize(typeClass: Model, hash: any) {
    hash.attributes['property-name'] = camelize(
      hash.attributes['property-name']
    );
    hash.attributes['min-width'] = hash.attributes['min-width'] ?? 100;
    hash.attributes['wrap-text'] = hash.attributes['wrap-text'] ?? true;
    return super.normalize(typeClass, hash);
    // return this._super(typeClass, hash);
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'appbuilder-link-column': AppbuilderLinkColumnSerializer;
  }
}
