import { next } from '@ember/runloop';
import { isArray } from '@ember/array';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import _ from 'lodash';
import FilterBuilder from 'cing-app/mixins/filter-builder';

export default Component.extend(FilterBuilder, {
  store: service(),
  docker: service('docker-item'),

  allExpanded: false,
  creatingNode: false,
  nestedTree: undefined,
  selectedNode: null,

  init: function () {
    this._super(...arguments);
    this.set('isLoading', true);

    // create root node that serves as drop zone container for all sub-nodes
    let root = this.get('store').createRecord('docket-folder', {
      name: "All documents",
      description: "",
      parentId: null,
      case: this.get('relatedCase'),
      order: 0, // will not be saved ever
      visible: true
    });
    this.set('nestedTree', root);
    // make root node selected automatically
    this.send('select', root);

    let query = {
      condition: this.get('condition'),
      include: 'case',
      sort: 'order'
    };
    this.get('store').query("docket-folder", query).then(data => {
      if (!isEmpty(data)) {
        this.set('flatTree', data.toArray());
        let parent;
        let findParent = function (parentId, children) {
          if (parentId) {
            children.find((child) => {
              if (child.get('id') === parentId) {
                parent = child;
              }
              else {
                // go examine node's children if it has any
                let descendants = child.get('children');
                if (!isEmpty(descendants)) {
                  findParent(parentId, descendants);
                }
              }
              // stop looking further when match was found
              return parent !== undefined;
            });
          }
          else {
            parent = root;
          }
        };

        let i = 0, length = data.get('length'), done = Array(length).fill(false);
        // items may be processed in loop up to two times until all parent->children relations are resolved
        while (done.some((x) => {
          return x === false;
        })) {
          let item = data.objectAt(i);

          // seek recursively through all nested nodes
          parent = undefined;
          findParent(item.get('parentId'), root.get('children'));
          if (parent && !done[i]) {
            parent.get('children').addObject(item);
            done[i] = true;
          }
          // move to next item with rollover
          i = (i + 1) % length;
        }
      }
      this.set('isLoading', false);
    });
  },
  didRender() {
    this._super(...arguments);
    this.$('.form-control').focus();
  },

  expandTree(tree, expanded, caller) {
    if (!isArray(tree)) {
      tree.set('isExpanded', expanded);
      tree = tree.get('children');
    }
    tree.forEach((item) => {
      item.set('isExpanded', expanded);
      if (item.get('children')) caller.expandTree(item.get('children'), expanded, caller);
    });
  },
  reorderTree(tree) {
    let stats = [];
    let processTreeNode = function (depth, ownIndex, node, renumberMap) {
      let descendants = node.get('children');
      if (!isEmpty(descendants)) {
        if (!renumberMap) {
          // just analyzing and gathering statistics
          stats.push({ depth: depth, index: ownIndex, count: descendants.get('length') });
        }
        descendants.forEach((child, i) => {
          if (renumberMap) {
            // increment offset at given depth for later nodes
            child.set('order', ++renumberMap[depth]);
            // parent might have changed due to drag & drop
            child.set('parentId', node.get('id'));
          }
          processTreeNode(depth + 1, i, child, renumberMap);
        });
      }
    };

    processTreeNode(0, 0, tree);
    // build offset map by populated level depths
    let map = [0];
    _.each(_.groupBy(stats, 'depth'), (level, i) => {
      let parental = map[i];
      // add total count of nodes at this level to those that sit lower in hierarchy
      map.push(parental + _.sumBy(level, 'count'));
    });
    processTreeNode(0, 0, tree, map);
  },

  actions: {
    toggleExpansion() {
      this.toggleProperty('allExpanded');
      this.expandTree(this.get('nestedTree'), this.get('allExpanded'), this);
    },
    createNode() {
      this.toggleProperty('creatingNode');
      this.set('newNodeName', "");
    },
    appendNode(parent) {
      let node = this.get('store').createRecord('docket-folder', {
        name: this.get('newNodeName'),
        description: "",
        parentId: parent ? parent.get('id') : null,
        case: this.get('relatedCase'),
        order: 0, // will get renumbered before saving
        visible: true
      });
      let root = this.get('nestedTree');
      let tree = parent ? parent.get('children') : root;
      tree.addObject(node);
      this.reorderTree(root);
      node.save();
      // add new record also to flat array that will be used for saving
      this.get('flatTree').addObject(node);

      this.set('creatingNode', false);
      if (parent) {
        parent.set('isExpanded', true);
      }
    },
    select(node) {
      let old = this.get('selectedNode');
      if (old) {
        old.set('isSelected', false);
      }
      node.set('isSelected', true);
      this.set('selectedNode', node);

      if (this.attrs.onFolderSelected) {
        next(
          this,
          function () {
            this.attrs.onFolderSelected(node);
          }
        );
      }
    },
    dragEnd({ sourceList, sourceIndex, targetList, targetIndex }) {
      if (sourceList === targetList && sourceIndex === targetIndex)
        return;

      const item = sourceList.objectAt(sourceIndex);

      sourceList.removeAt(sourceIndex);
      targetList.insertAt(targetIndex, item);
    },
    saveTree() {
      let root = this.get('nestedTree');
      this.reorderTree(root);

      this.get('flatTree').forEach((item) => {
        item.save();
      });
    }
  }
});
