import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment';
import { action } from '@ember/object';

//@ts-ignore
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AppEventsService from 'cing-app/pods/app-events/service';
import SessionService from 'cing-app/pods/session/service';
import {
	Expressions,
	ExpressionOperators,
	Filter,
	FilterOperators,
} from 'cing-app/mixins/filter-builder';
//@ts-ignore
import podNames from "ember-component-css/pod-names";
import { dasherize } from "@ember/string";
import DataLink from 'cing-app/models/data-link';

interface ManageDataLinksArgs {
	context?: {
		dataLinkId: number;
	};
	onClose?: () => void;
	header?: any;
	footer?: any;
}

export default class ManageDataLinks extends Component<ManageDataLinksArgs> {
	@service()
	config: any;

	@service()
	store!: Store;

	@service()
	cookies: any;

	@service()
	appEvents!: AppEventsService;

	@service('docker-item')
	docker!: DockerItemService;

	@service()
	session!: SessionService;

	@tracked
	condition!: string;

	get styleNamespace() {
		return podNames["manage-data-links"];
	}

	constructor(owner: any, args: ManageDataLinksArgs) {
		super(owner, args);

		this.generateCondition();
	}

	get columns() {

		let columns = [
			{
				title: 'Name',
				propertyName: 'name',
			},
			{
				title: '',
				component: 'manage-data-links/column-actions',
				className: "column-actions",
				edit: this.editDataLink,
				remove: this.removeDataLink,
			}
		];

		return columns;
	}

	@action
	generateCondition() {
		let query = Expressions.create();

		this.condition = query.serialize();
	}

	@action
	addDataLink() {
		const appearance = {
			label: 'Add DataLink',
			icon: '',
			title: 'Add DataLink',
			custom: true,
			size: 'large'
		};


		this.docker.invokePopup('manage-data-links/detail', appearance, {
			model: this.store.createRecord('data-link', {}),
			onUpdated: this.generateCondition,
		});
	}


	@action
	editDataLink(model: DataLink) {
		const appearance = {
			label: 'Edit DataLink',
			icon: '',
			title: 'Edit DataLink',
			custom: true,
			size: 'large'
		};


		this.docker.invokePopup('manage-data-links/detail', appearance, {
			model: model,
			onUpdated: this.generateCondition,
		});
	}

	@action
	removeDataLink(model: DataLink) {
		model.destroyRecord();
	}
}
