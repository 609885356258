import { attr } from '@ember-data/model';
import Abstract from './abstract';
import { exportable } from 'cing-app/utils/exportable';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

const ProjectTypeValidations = buildValidations({
  name: validator('presence', true),
});

export default class ProjectType extends Abstract.extend(
  ProjectTypeValidations
) {
  @exportable()
  @attr('string')
  declare name: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-type': ProjectType;
  }
}
