import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';

import AbPmService from 'cing-app/pods/ab-pm/service';

interface AbPmTaskTreeArgs {
  abLink: any;
  smartRoomId: any;
  onTaskAction: any;
  selectedTask: any;
  checkedTasks: [];
}

export default class AbPmTaskTree extends Component<AbPmTaskTreeArgs> {
  @service('ab-pm') pmService!: AbPmService;
  @tracked searchQuery: string = '';

  @tracked columns = {
    wbs: { name: 'wbs', label: 'Task#', isVisible: true },
    resource: { name: 'resource', label: 'Resources', isVisible: true },
    type: { name: 'type', label: 'Type', isVisible: false },
    category: { name: 'category', label: 'Category', isVisible: false },
    priority: { name: 'priority', label: 'Priority', isVisible: true },
    startdate: { name: 'startdate', label: 'Start Date', isVisible: false },
    enddate: { name: 'enddate', label: 'End Date', isVisible: false },
    status: { name: 'status', label: 'Status', isVisible: true },
    duedate: { name: 'duedate', label: 'Due Date', isVisible: false },
    duration: { name: 'duration', label: 'Duration', isVisible: false },
    progress: { name: 'progress', label: '%Completed', isVisible: true },
  };

  @tracked allNodes: any = [];
  @tracked rootTask: any = {};
  @tracked isRefreshing: boolean = false;
  @tracked isAllExpanded: boolean = false;

  get columnsArray() {
    var keys = Object.keys(this.columns);
    var arr: any = [];

    keys.forEach((k: any) => {
      let col = this.columns[k];
      arr.push(col);
    });

    return arr;
  }

  constructor(owner: any, args: any) {
    super(owner, args);

    console.log('task tree constructor');
    this.loadTasks.perform();
  }

  @task loadTasks = taskFor(async () => {
    let tree = await this.pmService.loadTaskTree(this.args.abLink);
    this.rootTask = tree.root;
    this.allNodes = tree.nodes;

    if (this.args.onTaskAction) {
      this.triggerAction('tree-ready', this.rootTask);
    }

    this.isRefreshing = false;
  });

  @action triggerAction(
    taskAction: any,
    task: any = null,
    options: any = null
  ) {
    if (taskAction == 'indent-task') {
      this.indentTask.perform(task);
    } else if (taskAction == 'outdent-task') {
      this.outdentTask.perform(task);
    }

    if (this.args.onTaskAction) {
      this.args.onTaskAction(taskAction, task, options);
    }
  }

  @action changeView(event: any) {
    this.triggerAction('change-view', event.target.value);
  }

  @action expandAll() {
    this.isAllExpanded = true;
    this.triggerAction('expand-all');
  }

  @action collapseAll() {
    this.isAllExpanded = false;
    this.triggerAction('collapse-all');
  }

  @task indentTask = taskFor(async (task: any) => {
    await this.pmService.indentTask(task, this.args.abLink);
  });

  @task outdentTask = taskFor(async (task: any) => {
    await this.pmService.outdentTask(task, this.args.abLink);
  });

  @action search() {}

  @action clearSearch() {}

  @action refreshTasks() {
    this.isRefreshing = true;
    this.loadTasks.perform();
    this.triggerAction('refresh');
  }

  @action addTopLevelTask() {
    this.triggerAction('add-root-task', this.rootTask);
  }
}
