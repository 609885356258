import moment from 'moment';
import { v1 } from 'ember-uuid';

import Service, { inject as service } from '@ember/service';

//@ts-ignore;
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import AppBuilderService from 'cing-app/pods/appbuilder/service';
import AppbuilderLink from 'cing-app/models/appbuilder-link';

import {
  Query,
  FilterOperators,
  Filter,
  QueryOperators,
} from 'cing-app/utils/query-builder';

export const AbPmLookups = {
  TaskTypes: 'task-types',
  TaskCategories: 'task-categories',
  TaskStatuses: 'task-statuses',
  TaskPriorities: 'task-priorities',
};

const AbPmObjectNames = {
  tasks: 'tasks',
  documents: 'documents',
  resources: 'task-resources',
  discussions: 'discussions',
  taskTypes: 'task-types',
  taskCategories: 'task-categories',
  taskPriorities: 'task-priorities',
  taskStatuses: 'task-statuses',
};

export interface IPmTaskDocument {
  id: number;
  resourceId: any;
  taskId: any;
  category: string;
  wbs: any;
  task: any;
  description: string;
  fileId: string;
  folderId: string;
  folderNumber: string;
  folderName: string;
  fileName: string;
  type: string;
  url: string;
  metadata: string;
  createdBy: string;
  createdDate: string;
  tasks: any;
}

export interface IPmTask {
  id: number;
  ordinal: number;
  index: number;
  subtasks: number;
  wbs: string;
  taskId: any;
  parent: any;
  taskTypeId: any;
  type: string;
  taskCategoryId: any;
  category: string;
  taskStatusId: any;
  status: string;
  taskPriorityId: any;
  priority: string;
  task: string;
  summary: string;
  createdDate: any;
  createdBy: string;
  resourceId: any;
  resource: string;
  email: string;
  startDate: any;
  endDate: any;
  dueDate: any;
  duration: any;
  progress: number;
  folderId: any;
  folderName: any;
  folderNumber: any;
  save: void;
  documents: any;
  resources: any;
}

export default class AbPm extends Service {
  @service declare store: Store;
  @service declare session: _SessionService;
  @service declare appbuilder: AppBuilderService;
  @service serverVariables!: any;

  testSmartRoomId = '2001280';

  _lookupCache = new Map<string, any>();

  get currentDate() {
    return moment.utc().format('YYYY-MM-DD');
  }

  get currentUser() {
    let currentUser = this.session.tokenData || {};
    return currentUser;
  }
  get currentUserEmail() {
    let currentUser = this.session.tokenData || {};
    let email = currentUser.email?.toLowerCase();
    return email;
  }

  get currentUserFullName() {
    let currentUser = this.session.tokenData || {};
    return `${currentUser.firstname} ${currentUser.lastname}`;
  }

  async getABLink(abLinkId: string) {
    let result = await this.store.findRecord('appbuilder-link', abLinkId, {
      reload: true,
    });

    return result;
  }

  async getTasksByIds(taskIds: any, abLink: AppbuilderLink) {
    console.log('taskIds ', taskIds);
    let uniqueTaskIds = [...new Set(taskIds)];
    console.log('unique taskIds ', uniqueTaskIds);

    let condition = new Query([
      new Filter('id', FilterOperators.IN, uniqueTaskIds),
    ]);

    let params = {
      page: {
        size: uniqueTaskIds.length + 1,
        number: 1,
      },
      condition: condition.serialize(),
      sort: 'ordinal',
      include: [
        AbPmObjectNames.resources,
        AbPmObjectNames.taskTypes,
        AbPmObjectNames.taskCategories,
        AbPmObjectNames.taskPriorities,
        AbPmObjectNames.taskStatuses,
      ],
    };

    let modelMeta = this.appbuilder.getModelMeta(abLink, AbPmObjectNames.tasks);

    let tasks = await this.store.query(modelMeta.modelPath, params);

    return tasks;
  }

  async getTaskById(taskId: any, abLink: AppbuilderLink) {
    let condition = new Query([
      new Filter('id', FilterOperators.EQUAL, taskId),
    ]);

    let params = {
      page: {
        size: 1,
        number: 1,
      },
      condition: condition.serialize(),
      sort: 'ordinal',
      include: [
        AbPmObjectNames.discussions,
        AbPmObjectNames.documents,
        AbPmObjectNames.resources,
        AbPmObjectNames.taskTypes,
        AbPmObjectNames.taskCategories,
        AbPmObjectNames.taskPriorities,
        AbPmObjectNames.taskStatuses,
      ],
    };

    let modelMeta = this.appbuilder.getModelMeta(abLink, AbPmObjectNames.tasks);

    let tasks = await this.store.query(modelMeta.modelPath, params);

    return tasks?.firstObject;
  }

  async loadTaskTree(abLink: AppbuilderLink) {
    let params = {
      page: {
        size: 999999,
        number: 1,
      },
      sort: 'ordinal',
      include: [
        AbPmObjectNames.discussions,
        AbPmObjectNames.documents,
        AbPmObjectNames.resources,
        AbPmObjectNames.taskTypes,
        AbPmObjectNames.taskCategories,
        AbPmObjectNames.taskPriorities,
        AbPmObjectNames.taskStatuses,
      ],
    };

    let modelMeta = this.appbuilder.getModelMeta(abLink, AbPmObjectNames.tasks);

    let tasks = await this.store.query(modelMeta.modelPath, params);
    let root = {
      level: 0,
      id: 0,
      parent: null,
      task: '<root>',
      number: 0,
      ordinal: 0,
      wbs: null,
      children: [],
    };

    let arr = tasks.toArray();
    // for (var i = 0; i < arr.length; i++) {
    //   let task = arr[i];
    //   console.log('resources', task.taskResources);
    //   let resources = await task.taskResources;
    //   console.log('resources2 ', resources);
    // }

    this.assembleTreeNodes(root, arr);

    return {
      root: root,
      nodes: tasks,
    };
  }

  assembleTreeNodes(parentTask: any, taskTable: any) {
    var children = [];
    var unAssigned: any = [];
    for (var i = 0; i < taskTable.length; i++) {
      var child = taskTable[i];

      if (child.taskResources) {
        child.resources = child.taskResources.toArray();
      } else {
        child.resources = [];
      }

      if (child.documents) {
        child.documents = child.documents.toArray();
      } else {
        child.documents = [];
      }

      if (child.parent == parentTask.id) {
        children.push(child);
      } else {
        unAssigned.push(child);
      }
    }

    parentTask.children = children.sortBy('ordinal');
    parentTask.isChildrenLoaded = true;
    //console.log(`${parentTask.task} children = ${children.length}`);
    //console.log('unassigned length =>', unAssigned.length);

    this.setupTaskRelationships(parentTask);

    parentTask.children.forEach((childTask: any) => {
      this.assembleTreeNodes(childTask, unAssigned);
    });
  }

  setupTaskRelationships(parentTask: any) {
    let arr = parentTask.children || [];

    parentTask.children = arr;
    parentTask.level = parentTask.level || 0;

    if (arr && arr.length) {
      let maxIndex = arr.length - 1;

      parentTask.firstTask = arr[0];
      parentTask.lastTask = arr[maxIndex];

      for (var i = 0; i < arr.length; i++) {
        let t = arr[i];

        t.index = i;
        t.parentTask = parentTask;
        t.level = parentTask.level + 1;

        if (parentTask.level > 0) {
          t.number = parentTask.number + '.' + String(i + 1);
        } else {
          t.number = String(i + 1);
        }
        t.wbs = t.number;

        if (i > 0) {
          t.previousTask = arr[i - 1];
        }

        if (i < maxIndex) {
          t.nextTask = arr[i];
        }
      }
    }
  }

  async loadTasks(parentTask: any, abLink: AppbuilderLink) {
    let parentId = parentTask.id || parentTask.taskId || 0;

    console.log('loading children task for  =>', parentTask.task);

    let condition = new Query([
      new Filter('parent', FilterOperators.EQUAL, parentId),
    ]);

    let params = {
      page: {
        size: 999,
        number: 1,
      },
      condition: condition.serialize(),
      sort: 'ordinal',
      include: [
        AbPmObjectNames.discussions,
        AbPmObjectNames.documents,
        AbPmObjectNames.resources,
        AbPmObjectNames.taskTypes,
        AbPmObjectNames.taskCategories,
        AbPmObjectNames.taskPriorities,
        AbPmObjectNames.taskStatuses,
      ],
    };

    let modelMeta = this.appbuilder.getModelMeta(abLink, AbPmObjectNames.tasks);

    let tasks = await this.store.query(modelMeta.modelPath, params);
    let arr = tasks.toArray();

    if (parentTask.children) {
      parentTask.children.clear();
      parentTask.children.addObjects(arr);
    } else {
      parentTask.children = arr;
    }

    this.setupTaskRelationships(parentTask);

    return parentTask.children;
  }

  async renumberTasks(task: any, abLink: AppbuilderLink) {
    //always reload the children
    await this.loadTasks(task, abLink);

    task.ordinal = task.ordinal || 0;

    console.log(`Renumbering task => ${task.task}`);

    for (var i = 0; i < task.children?.length; i++) {
      let child = task.children[i];
      if (child) {
        if (task.parent > 0) {
          child.number = task.number + '.' + String(i + 1);
        } else {
          child.number = String(i + 1);
        }
        child.wbs = child.number;
        child.ordinal = i;

        console.log(`child task: ${child.task} new number => ${child.number}`);
        await child.save();

        await this.renumberTasks(child, abLink);
      }
    }

    //update the number of subtasks
    task.subtasks = task.children.length;
    if (task.save) {
      //root task does not have save
      await task.save();
    }
  }

  async deleteTask(
    task: any,
    abLink: AppbuilderLink,
    renumberTasksAfter = true
  ) {
    if (task.parentTask && task.parentTask.children) {
      task.parentTask.children.removeObject(task);
    }

    if (task.destroyRecord) {
      await task.destroyRecord();
    }

    if (renumberTasksAfter !== false) {
      //await this.renumberTasks(task.parentTask, abLink);
      //await this.renumberTasks(task, abLink);
    }
  }

  taskIsDecendantOf(task: any, ancestor: any) {
    let result = false;
    if (ancestor) {
      let parent = task.parent;
      while (parent) {
        if (parent.id == ancestor.id) {
          result = true;
          break;
        }
        parent = parent.parent;
      }
    }

    return result;
  }

  async moveTask(
    task: any,
    newParent: any,
    index: number,
    abLink: AppbuilderLink
  ) {
    await this.loadTasks(task, abLink);

    //change the task's parent
    let currentParent = task.parentTask;
    if (index == -1 || index === undefined || index === null) {
      index = task.children.length;
    }

    console.log(
      `moving task: ${task.task} to ${newParent.task} at pos: ${index}`
    );

    task.ordinal = index;
    task.parent = newParent.id;
    await task.save();

    await this.loadTasks(newParent, abLink);
    //increment the ordinal of the succeeding tasks for renumbering
    for (var i = index; i < newParent.children.length; i++) {
      let nextTask = newParent.children[i];
      if (nextTask) {
        nextTask.ordinal = i + 2;
        await nextTask.save();
        console.log(
          `moving next task: ${nextTask.task} to pos: ${nextTask.ordinal}`
        );
      }
    }

    await this.renumberTasks(currentParent, abLink);

    if (!this.taskIsDecendantOf(newParent, currentParent)) {
      await this.renumberTasks(newParent, abLink);
    }

    if (
      !this.taskIsDecendantOf(task, newParent) &&
      !this.taskIsDecendantOf(task, currentParent)
    ) {
      await this.renumberTasks(task, abLink);
    }

    //this should be done after save
    task.parentTask = newParent;

    currentParent.children?.removeObject(task);
  }

  async indentTask(task: any, abLink: AppbuilderLink) {
    //indent will move the current task after the last child of the previous task
    if (task.previousTask) {
      console.log(`Indenting ${task.task}`);
      let newParent = task.previousTask;
      let insertAtIndex = -1; // last
      let currentParent = task.parentTask;

      await this.moveTask(task, newParent, insertAtIndex, abLink);

      if (currentParent.children && currentParent.children.removeObject) {
        currentParent.children.removeObject(task);
      }
    } else {
      console.log('Cannot indent task no previous task found.');
    }
  }

  async outdentTask(task: any, abLink: AppbuilderLink) {
    //outdent will move the current task as after its parent
    if (task.parentTask?.parentTask) {
      console.log(`Outdenting ${task.task}`);

      //change the task's parent
      let currentParent = task.parentTask;
      let insertAtIndex = currentParent.ordinal;

      let newParent = task.parentTask.parentTask;

      await this.moveTask(task, newParent, insertAtIndex, abLink);

      if (currentParent.children && currentParent.children.removeObject) {
        currentParent.children.removeObject(task);
      }
    } else {
      console.log('Cannot outdent task no parent.parent task found.');
    }
  }

  async preloadLookupItems(ablink: AppbuilderLink) {
    let lookups: any = {};

    lookups.taskCategories = await this.loadLookupItems(
      AbPmLookups.TaskCategories,
      ablink
    );
    lookups.taskPriorities = await this.loadLookupItems(
      AbPmLookups.TaskPriorities,
      ablink
    );
    lookups.taskStatuses = await this.loadLookupItems(
      AbPmLookups.TaskStatuses,
      ablink
    );
    lookups.taskTypes = await this.loadLookupItems(
      AbPmLookups.TaskTypes,
      ablink
    );

    return lookups;
  }

  async loadLookupItems(name: string, abLink: AppbuilderLink) {
    let data: any = this._lookupCache.get(name);

    if (!data || !data.length) {
      let modelMeta = this.appbuilder.getModelMeta(abLink, name);
      data = await this.store.query(modelMeta.modelPath, {});

      this._lookupCache.set(name, data);
    }

    return data;
  }

  createNewLookupRecord(name: string, abLink: AppbuilderLink) {
    let modelMeta = this.appbuilder.getModelMeta(abLink, name);
    let newLookupRecord = this.store.createRecord(modelMeta.modelPath, {});
    return newLookupRecord;
  }

  createNewTaskResourceRecord(abLink: AppbuilderLink) {
    let modelMeta = this.appbuilder.getModelMeta(
      abLink,
      AbPmObjectNames.resources
    );

    let newTaskResource = this.store.createRecord(modelMeta.modelPath, {});

    console.log('new task resource ', newTaskResource);
    return newTaskResource;
  }

  createNewTaskRecord(abLink: AppbuilderLink) {
    let modelMeta = this.appbuilder.getModelMeta(abLink, AbPmObjectNames.tasks);

    let newTask = this.store.createRecord(modelMeta.modelPath, {});
    return newTask;
  }

  async populateTaskWBS(
    newTask: any,
    parentTask: any,
    targetTask: any,
    action: string
  ) {
    let refreshIsNeeded = false;

    //start populating the subtasks
    let subtasks = parentTask.children;
    let numberOfSubtasks = 1;
    if (subtasks) {
      numberOfSubtasks = subtasks.length + 1;
    }

    console.log('parent sub tasks ', numberOfSubtasks);

    let parentWbs = parentTask.number || parentTask.wbs || '';

    if (action == 'add-task' && targetTask) {
      //new task will be added before the task
      let startIndex = targetTask.index || 0;
      newTask.ordinal = startIndex + 1;
      if (parentTask.id == 0 || parentTask.level == 0) {
        newTask.wbs = newTask.ordinal;
      } else {
        newTask.wbs = `${parentWbs}.${newTask.ordinal}`;
      }
      newTask.number = newTask.wbs;

      console.log(`parent items => ${subtasks.length}`);
      if (subtasks.length) {
        let items = subtasks.toArray();
        for (var i = startIndex; i < items.length; i++) {
          let childTask = items[i];
          if (childTask) {
            childTask.ordinal = i + 2;
            if (parentTask.id == 0 || parentTask.level == 0) {
              childTask.wbs = childTask.ordinal;
            } else {
              childTask.wbs = `${parentWbs}.${childTask.ordinal}`;
            }
            childTask.number = childTask.wbs;

            console.log(`updating ${childTask.task} wbs => ${childTask.wbs}`);

            await childTask.save();
          }
        }
        refreshIsNeeded = true;
      }
    } else {
      // we are adding new task at the bottom
      newTask.ordinal = numberOfSubtasks;

      if (parentTask.id == 0 || parentTask.level == 0) {
        //we are adding from the root so the wbs will always be the ordinal
        newTask.wbs = String(newTask.ordinal);
      } else {
        newTask.wbs = `${parentWbs}.${newTask.ordinal}`;
      }
      newTask.number = newTask.wbs;
    }
    console.log(`wbs for new task ${newTask.task} ==> ${newTask.wbs}`);

    return refreshIsNeeded;
  }

  createTaskDocument(abLink: AppbuilderLink) {
    let modelMeta = this.appbuilder.getModelMeta(
      abLink,
      AbPmObjectNames.documents
    );
    let newDocument = this.store.createRecord(modelMeta.modelPath, {});

    return newDocument;
  }

  async getDocumentList(
    searchKeyword: string,
    pageSize: number,
    pageIndex: number,
    sortBy: string,
    abLink: AppbuilderLink
  ) {
    searchKeyword = String(searchKeyword || '').trim();
    let filterQuery = new Query(QueryOperators.AND);
    if (searchKeyword != '') {
      filterQuery.add(
        new Filter('file-name', FilterOperators.LIKE, searchKeyword)
      );
    }

    let query: any = {
      page: {
        size: pageSize,
        number: pageIndex + 1,
      },
      sort: sortBy,
      include: ['tasks'],
      condition: filterQuery.serialize(),
    };

    let modelMeta = this.appbuilder.getModelMeta(
      abLink,
      AbPmObjectNames.documents
    );
    let documents = await this.store.query(modelMeta.modelPath, query);

    let result = Object.assign({}, query);

    result.expr = filterQuery;
    result.modelName = modelMeta.modelPath;
    result.result = documents;

    return result;
  }

  async getTaskDocuments(
    taskId: any,
    pageSize: number,
    pageIndex: number,
    sortBy: string,
    abLink: AppbuilderLink
  ) {
    let condition = new Query([
      new Filter('task-id', FilterOperators.EQUAL, taskId),
    ]);

    let query: any = {
      page: {
        size: pageSize,
        number: pageIndex + 1,
      },
      condition: condition.serialize(),
      sort: sortBy,
    };

    let modelMeta = this.appbuilder.getModelMeta(
      abLink,
      AbPmObjectNames.documents
    );
    let documents = await this.store.query(modelMeta.modelPath, query);

    console.log('task docs ', documents);
    return documents;
  }

  async getTaskDocumentsByType(
    type: any,
    taskId: any,
    pageSize: number,
    pageIndex: number,
    sortBy: string,
    abLink: AppbuilderLink
  ) {
    let filterQuery = new Query(QueryOperators.AND);
    filterQuery.add(new Filter('type', FilterOperators.EQUAL, type));

    if (taskId) {
      filterQuery.add(new Filter('task-id', FilterOperators.EQUAL, taskId));
    }

    let query: any = {
      page: {
        size: pageSize,
        number: pageIndex + 1,
      },
      condition: filterQuery.serialize(),
      sort: sortBy,
    };

    let modelMeta = this.appbuilder.getModelMeta(
      abLink,
      AbPmObjectNames.documents
    );
    let documents = await this.store.query(modelMeta.modelPath, query);

    console.log(' docs by type', documents);
    return documents;
  }

  async getUserInfo(user: any, smartRoomId: any) {
    let condition = new Query([
      new Filter('id', FilterOperators.EQUAL, user.userId),
    ]);

    var contacts = null;
    if (/smartroom/i.test(user.type)) {
      contacts = await this.store.query('smartroom/user', {
        siteId: smartRoomId,
        condition: condition.serialize(),
        for: 'manageuser',
      });
    } else {
      contacts = await this.store.query('person', {
        condition: condition.serialize(),
        include: 'emails,company',
        sort: 'last-name',
        page: { size: 50, number: 1 },
      });
    }

    return contacts?.firstObject;
  }

  async getTaskResources(
    taskId: any,
    pageSize: number,
    pageIndex: number,
    sortBy: string,
    abLink: AppbuilderLink,
    smartRoomId: any
  ) {
    let condition = new Query([
      new Filter('task-id', FilterOperators.EQUAL, taskId),
    ]);

    let query: any = {
      page: {
        size: pageSize,
        number: pageIndex + 1,
      },
      condition: condition.serialize(),
      sort: sortBy,
    };

    let modelMeta = this.appbuilder.getModelMeta(
      abLink,
      AbPmObjectNames.resources
    );

    let taskResources = await this.store.query(modelMeta.modelPath, query);
    let result = [];
    let arr = taskResources.toArray();
    if (arr.length) {
      for (var i = 0; i < arr.length; i++) {
        let user = arr[i];
        let userInfo = await this.getUserInfo(user, smartRoomId);
        if (userInfo) {
          user.name =
            userInfo.name ||
            userInfo.fullName ||
            `${userInfo.firstName || ''} ${userInfo.lastName || ''}`;
          user.email = userInfo.email || userInfo.emailAddress;

          result.push(user);
        }
      }
    }

    return result;
  }

  computeTaskStatusFromDates(task: any, startDate: any, endDate: any) {
    let today = moment.utc();

    task.startDate = startDate;
    task.endDate = endDate;

    if (!task.startDate && !task.endDate) {
      task.status = 'Unscheduled';
      task.duration = '';
    } else {
      let start = moment(task.startDate);
      let end = moment(task.endDate);
      /*
        If user creates a Task with no dates, then the Status is Unscheduled.
        If Start Date > Today, then Status = Not Started
        If Start Date > Today and %Completed > 0 then Status = Ahead of Schedule
        If Start Date >= Today and % Completed > 0, then Status is On Schedule 
      */
      if (task.startDate && this.isValidDate(start)) {
        if (start.isAfter(today)) {
          console.log('after today');
          task.status = 'Not Started';
          if (task.progress > 0) {
            task.status = 'Ahead of Schedule';
          }
        }
      }
      /* 
        If End Date < Today and %Completed <> 100, then Status is Behind Schedule
        If End Date <= Today and %Completed =100, then Status is Completed
      */

      if (task.endDate && this.isValidDate(end)) {
        if (end.isBefore(today) && task.progress != 100) {
          task.status = 'Behind Schedule';
        }

        if (task.progress > 0) {
          if (today.isBefore(endDate)) {
            task.status = 'On Schedule';
          }

          if (this.isValidDate(start) && start.isAfter(today)) {
            task.status = 'Ahead of Schedule';
          }

          if (end.isSameOrBefore(today) && task.progress == 100) {
            task.status = 'Completed';
          }
        }
        if (this.isValidDate(start)) {
          let days: any = end.diff(start, 'days') || 0;
          task.duration = days;
        }
      }
    }
  }

  isValidDate(strDate: any) {
    let result = true;
    if (strDate && strDate != '') {
      let d = moment(strDate);
      if (d && d.isValid()) {
        let mm = d.month();
        let dd = d.date();
        let yyyy = d.year();

        result = !isNaN(mm) && !isNaN(dd) && !isNaN(yyyy) && yyyy <= 9999;
      }
    }

    return result;
  }

  validateTaskFields(task: any, parentTask: any, isTaskIncluded: boolean) {
    //validate task
    if (isTaskIncluded) {
      if (String(task.task).trim() == '') {
        return 'Task is required.';
      }
    }

    if (task.startDate && !this.isValidDate(task.startDate)) {
      return 'Start Date is not valid';
    }
    if (task.endDate && !this.isValidDate(task.endDate)) {
      return 'End Date is not valid';
    }
    if (task.dueDate && !this.isValidDate(task.dueDate)) {
      return 'Due Date is not valid';
    }

    if (task.startDate) {
      let dateStart = moment(task.startDate);
      let dateStartIsValid = dateStart.isValid();

      // validate startdate agains parent task start date
      if (dateStartIsValid && parentTask && parentTask.startDate) {
        let dateStartOfParent = moment(parentTask.startDate);
        if (
          dateStartOfParent.isValid() &&
          dateStart.isBefore(dateStartOfParent)
        ) {
          return (
            "Start Date cannot be before the parent task's start date " +
            dateStartOfParent.format('MM/DD/YYYY')
          );
        }
      }

      if (dateStartIsValid && task.endDate) {
        let dateEnd = moment(task.endDate);
        let dateEndIsValid = dateEnd.isValid();

        // validate start date against end date
        if (dateEndIsValid && dateStart.isAfter(dateEnd)) {
          return 'Start Date cannot be after end date';
        }

        //validate end date against start date
        if (dateEndIsValid && dateEnd.isBefore(dateStart)) {
          return 'End Date cannot be before start date';
        }
      }
    }

    if (task.progress) {
      if (isNaN(task.progress) || task.progress > 100) {
        return '% Completed must be a number from 0 to 100 only';
      }
    }

    return '';
  }

  getSmartRoomFolderIdFromPathIds(pathIds: string) {
    let result = null;
    if (pathIds) {
      let cleanIds = pathIds.trim();
      if (!cleanIds.endsWith('/')) {
        cleanIds = cleanIds + '/';
      }
      let parts = cleanIds.split('/');
      parts.pop(); // disregard the last slash
      result = parts.pop();
    }
    return result;
  }

  async getFolderPath(smartRoomSiteId: any, folderId: any) {
    let host = this.serverVariables.get('smartroomApiUrl');
    let namespace = this.serverVariables.get('smartroomApiNamespace');
    let url = `${host}/${namespace}/sites/${smartRoomSiteId}/folders/${folderId}/metadata`;

    let infoResult: Response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.session.data.authenticated.access_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    let result = await infoResult.json();

    return result?.folderPath;
  }

  async deleteSmartRoomFile(smartRoomSiteId: any, fileId: any) {
    try {
      let srFile = await this.store.queryRecord('smartroom/file', {
        id: fileId,
        siteId: smartRoomSiteId,
      });
      if (srFile && srFile.id) {
        //srFile.destroyRecord(); //does not work

        let host = this.serverVariables.get('smartroomApiUrl');
        let namespace = this.serverVariables.get('smartroomApiNamespace');

        let url = `${host}/${namespace}/sites/${smartRoomSiteId}/items`;
        let payload = {
          action: 'remove',
          parameter: {
            files: [{ itemId: fileId, tags: [] }],
          },
        };
        let deleteResult: Response = await fetch(url, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${this.session.data.authenticated.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        let result = await deleteResult.json();
        return result;
      } else {
        console.log('sr file not found ', fileId);
      }
    } catch (e) {
      console.log('SR File ' + fileId + ' was not deleted ', e);
    }
  }

  async downloadSmartRoomFile(smartRoomSiteId: any, file: any) {
    //GET https://dev.smartroom.com/api/client/v1/sites/2001280/files/101376159/saveinfo?versionNo=1//{"binaryNotFound":false,"saveFormatType":1,"fileIsPasswordProtected":false,"fileIsCorrupted":false,"fileIsTruncated":false,"saveRedacted":false,"fileRedaction":null}
    //POST https://dev.smartroom.com/api/client/v1/sites/2001280/token-exportbinary
    // {clientToken:guid}
    // {"token":"aa6ac8d9-dfe8-4d87-92e1-cf67dadd7ad2","expiryDate":"2022-06-29T05:55:08.043417Z","expiryUtc":"2022-06-29T05:55:08.043417Z"}
    //https://dev.smartroom.com/api/client/v1/sites/2001280/files/101376159/binary/export?versionNo=1&raw=true&userKey=&exportToken=aa6ac8d9-dfe8-4d87-92e1-cf67dadd7ad2&clientToken=edf28a6e-29cd-4e28-959e-2246de950c73

    let version = 1;
    if (file.metadata) {
      if (typeof file.metadata == 'string') {
        let metadata = JSON.parse(file.metadata) || {};
        version = metadata.version || 1;
      } else {
        version = file.metadata.version || 1;
      }
    }

    let host = this.serverVariables.get('smartroomApiUrl');
    let namespace = this.serverVariables.get('smartroomApiNamespace');
    let baseUrl = `${host}/${namespace}/sites/${smartRoomSiteId}`;

    console.log('getting save info ', baseUrl);

    let saveInfoRes: Response = await fetch(
      `${baseUrl}/files/${file.fileId}/saveinfo?version=${version}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.session.data.authenticated.access_token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    let saveInfo = await saveInfoRes.json();
    if (saveInfo) {
      let payload = { clientToken: v1() };
      console.log('getting binary info ...');
      let exportBinaryRes: Response = await fetch(
        `${baseUrl}/token-exportbinary`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.session.data.authenticated.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      let binaryInfo = await exportBinaryRes.json();
      if (binaryInfo && binaryInfo.token) {
        let fileUrl = `${baseUrl}/files/${file.fileId}/binary/export?version=${version}&exportToken=${binaryInfo.token}&clientToken=${payload.clientToken}`;

        console.log('downloading binary from ', fileUrl);

        this.downloadFile(fileUrl, '');
      } else {
        console.log('could not load binary info.');
      }
    } else {
      console.log('could not load save info.');
    }
  }

  downloadFile(url: any, fileName: any) {
    console.log('downloading file from url ', url);

    if (fileName) {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = fileName;
      link.style.display = 'none';
      //document.body.appendChild(link);
      link.click();
    } else {
      let div = document.createElement('div');
      div.id = 'dd_' + v1();
      div.style.display = 'none';

      document.body.appendChild(div);

      div.innerHTML = `<iframe src="${url}"></iframe>`;
    }
  }
} //end of class

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'ab-pm': AbPm;
  }
}
