import { attr } from '@ember-data/model';
import Abstract from './abstract';

//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

const AlertTemplateValidations = buildValidations({
  name: [validator('presence', true), validator('length', { max: 250 })],
  subject: [validator('presence', true), validator('length', { max: 250 })],
  fromAddress: [validator('format', { type: 'email' })],
  loginRedirectUrl: [validator('format', { type: 'url', allowBlank: true })],
});

//@ts-ignore
export default class AlertTemplate extends Abstract.extend(
  AlertTemplateValidations
) {
  @attr('string') subject?: string;
  @attr('string') name?: string;
  @attr('string') html?: string; // in case we start from HTML
  @attr('jsonb') mjmlComponents?: any; // MJML
  @attr('string') css?: string; // in case we start from CSS
  @attr('jsonb') mjmlStyles?: any; // MJML
  @attr('jsonb') mjmlAssets?: any; // MJML
  @attr('string') loginRedirectUrl?: string;
  @attr('string') fromAddress?: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'alert-template': AlertTemplate;
  }
}
