import Component from '@glimmer/component';
import { action, computed, get, set } from '@ember/object';
import { Owner } from 'ember-one-way-controls/.node_modules.ember-try/@glimmer/di';
import ProjectTabModel from 'cing-app/models/project-tab';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import UserInstancesService from 'cing-app/pods/user-instances/service';
import { ESettings } from 'cing-app/utils/lookups';
import classic from 'ember-classic-decorator';
import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

interface ManageProjectTabInquiriesArgs {
  context: {
    model: ProjectTabModel;
  };
}

const PortalSettingsList = Object.keys(ESettings);

@classic
export default class ManageProjectTabInquiries extends Component<ManageProjectTabInquiriesArgs> {
  @tracked selectedSectionId?: String;
  @service config: any;
  @service
  declare userInstances: UserInstancesService;
  @service('docker-item') docker;
  @service store;

  constructor(owner: Owner, args: ManageProjectTabInquiriesArgs) {
    super(owner, args);
    if (!this.args.context.model.settings.InquirySections) {
      this.args.context.model.settings.InquirySections = [];
    }
  }

  @action
  addSection() {
    let ids = this.args.context.model.settings.InquirySections.map((x) =>
      parseInt(x.id)
    ).filter((value) => Number.isFinite(value));
    let maxId = !this.args.context.model.settings.InquirySections.length
      ? 1
      : Math.max.apply(null, ids) + 1;
    this.args.context.model.settings.InquirySections.push({
      id: maxId.toString(),
      title: 'New Section ' + maxId.toString(),
      content: 'Content of New Section ' + maxId.toString(),
    });
    this.selectedSectionId = maxId.toString();
    this.args.context.model.settings.notifyPropertyChange('InquirySections');
  }

  @action
  removeSection(section) {
    this.args.context.model.settings.InquirySections =
      this.args.context.model.settings.InquirySections.filter(
        (x) => x.id !== section.id
      );
    if (!this.args.context.model.settings.InquirySections.length) {
      this.selectedSectionId = undefined;
    } else {
      let ids = this.args.context.model.settings.InquirySections.map((x) =>
        parseInt(x.id)
      ).filter((value) => Number.isFinite(value));
      let maxId = Math.max.apply(null, ids);
      this.selectedSectionId = maxId.toString();
    }
    this.args.context.model.settings.notifyPropertyChange('InquirySections');
  }

  @action
  changeInquirySection(section: any) {
    this.selectedSectionId = section.id;
  }

  @action
  configureSupportEmail() {
    const appearance = {
      label: 'Edit Portal Page',
      icon: '',
      size: 'large',
      title: `Settings`,
      custom: true,
    };

    this.docker.invokePopup('portal-settings', appearance, {
      reloadSettings: this.reloadSettings,
    });
  }

  @action
  async reloadSettings() {
    //this.sendAction('reloadPortalSettings');
    let PortalSettingsListIds = [];
    for (var a = 0; a < PortalSettingsList.length; a++) {
      PortalSettingsListIds.push(ESettings[PortalSettingsList[a]]);
    }

    // get the existing settings from API
    let settingsQuery = Expressions.create();
    settingsQuery.add(
      Filter.create({
        name: 'key',
        operator: FilterOperators.IN,
        value: PortalSettingsListIds,
      })
    );

    let existingSettings = await this.store.query('setting', {
      condition: settingsQuery.serialize(),
      page: {
        size: 100,
      },
      sort: '-modify-time',
    });

    // get unique list of settings
    let settings = {};

    existingSettings.forEach((setting) => {
      settings[setting.key] = setting.value;
    });

    set(this.config, 'settings', settings);
  }

  @computed('selectedSectionId')
  get selectedSection() {
    return this.args.context.model.settings.InquirySections.find(
      ({ id }) => id === this.selectedSectionId
    );
  }

  get supportEmail() {
    let settings = get(this.config, 'settings');
    return settings[ESettings.SupportEmail];
  }
}
