import { alias } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject } from '@ember/service';
import { computed } from "@ember/object";

export default Mixin.create({
  session: inject(),
  isAdmin: alias('session.isAdmin'),

  // init() {
  //     this._super(...arguments);
  // },

});
