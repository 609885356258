
export const SEParagraphBlock = (bm: any) => {
	bm.add('se-paragraph', {
		label: `<i class="fa fa-paragraph"></i>
            <div>SE Paragraph</div>
        `,
		category: 'Basic',
		content: {
			type: `se-paragraph`,
		}
	});
};

export default (domc) => {
	const textType = domc.getType('text');
	const textModel = textType.model;
	const textView = textType.view;

	domc.addType('se-paragraph', {
		model: textModel.extend({
			defaults: Object.assign({}, textModel.prototype.defaults, {
				'custom-name': 'Paragraph',
				tagName: 'p',
				droppable: true,
				editable: false,
				movable: true,
				propagate: [],
				components: [{
					type: 'text',
					tagName: 'span',
					content: 'Paragraph',
					editable: true
				}],
				traits: [
					{
						type: 'class_select',
						options: [
							{ value: '', name: 'No' },
							{ value: 'lead', name: 'Yes' }
						],
						label: 'Lead?'
					}
				].concat(textModel.prototype.defaults.traits),
				classes: ['se-paragraph'],
			})
		}, {
			isComponent(el: HTMLElement) {
				if (el && el.tagName && el.tagName === 'P') {
					return { type: 'se-paragraph' };
				}
				/*
				if (el && el.classList && el.classList.contains('se-paragraph')) {
					return { type: 'se-paragraph' };
				}
				*/
			}
		}),
		view: textView
	});
}