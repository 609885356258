import { attr } from '@ember-data/model';
import Abstract from './abstract';

export default class InvestorType extends Abstract {
  @attr('string') declare name: String;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'investor-type': InvestorType;
  }
}
