import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNameBindings, tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@tagName('li')
@classNameBindings('item.focused:focused', 'item.undocked:undocked:docked')
export default class Item extends Component {
  @service('docker-item')
  docker;

  click() {
    this.send('focus');
  }

  @action
  restore() {
    const popup = this.get('item');
    if (!popup.get('undocked')) {
      this.get('docker').restorePopup(popup);
    }
  }

  @action
  minimize() {
    const popup = this.get('item');
    if (popup.get('undocked')) {
      this.get('docker').minimizePopup(popup);
    }
  }

  @action
  focus() {
    const popup = this.get('item');

    if (!popup.get('undocked')) {
      this.get('docker').restorePopup(popup);
    }

    this.get('docker').focusPopup(popup);
  }

  @action
  close() {
    const popup = this.get('item');
    this.get('docker').removePopup(popup);
  }
}
