import Component from "@ember/component";
import { inject } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { computed, get, set } from "@ember/object";

import { FundType, FundStatus } from 'cing-app/utils/lookups';

import { SYNC_API } from 'cing-app/pods/user-instances/service';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';

export default Component.extend({
  FundTypes: Object.keys(FundType),
  FundStatuses: Object.keys(FundStatus),
  store: inject(),
  session: inject(),
  userInstances: inject(),
  SYNC_API: SYNC_API,
  init() {
    this._super();
    this.initTask.perform();
  },
  initTask: task(function* () {
    let fund = yield this.model.get('fund');

    if (!fund) {
      let newFund = this.store.createRecord("fund", {
        publicDescription: "Investment Fund",
      });

      this.model.setProperties({
        fund: newFund
      })
    }
  }),
  projectTypes: computed(function () {
    return this.store.query('project-type', {
      page: {
        size: 1000
      },
      sort: 'name'
    })
  }),
  projectStatuses: computed(function () {
    return this.store.query('project-status', {
      page: {
        size: 1000
      },
      sort: 'name'
    })
  }),
  projectPriorities: computed(function () {
    return this.store.query('project-priority', {
      page: {
        size: 1000
      },
      sort: 'name'
    })
  }),
  fundTypes: computed(function () {
    return this.store.query('fund-type', {
      page: {
        size: 1000
      },
      sort: 'name'
    })
  }),
  fundStatuses: computed(function () {
    return this.store.query('fund-status', {
      page: {
        size: 1000
      },
      sort: 'name'
    })
  }),
  dealCloudFunds: computed(function () {
    if (get(this.userInstances, 'dealCloudVersion') === SYNC_API.V1) {
      return this.store.query('deal-cloud-fund', {});
    } else if (get(this.userInstances, 'dealCloudVersion') === SYNC_API.V2) {
      return this.store.query('sync-fund', {});
    }
  }),
  stopPropagation(e) {
    e.stopPropagation();
  },
  searchCompaniesTask: task(function* (term) {
    yield timeout(300);

    if (!term) {
      return null;
    }

    //API call
    let expr = Expressions.create();

    expr.add(Filter.create({
      name: 'name',
      operator: FilterOperators.LIKE,
      value: term
    }));

    return yield this.get('store').query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 50, number: 1 }
    })
  }).restartable(),
  addProjectTypeTask: task(function* (newTypeName, options) {
    let newType = this.store.createRecord('project-type', {
      name: newTypeName
    });

    yield newType.save();

    let types = yield this.store.query('project-type', {
      page: {
        size: 1000,
        sort: 'name'
      }
    });

    this.set('projectTypes', types);
    this.set('model.type', newType);
  }),
  addProjectStatusTask: task(function* (newTypeName, options) {
    let newStatus = this.store.createRecord('project-status', {
      name: newTypeName
    });

    yield newStatus.save();

    let statuses = yield this.store.query('project-status', {
      page: {
        size: 1000,
        sort: 'name'
      }
    });

    this.set('projectStatuses', statuses);
    this.set('model.projectStatus', newStatus);
  }),
  addProjectPriorityTask: task(function* (newTypeName, options) {
    let newPriority = this.store.createRecord('project-priority', {
      name: newTypeName
    });

    yield newPriority.save();

    let priorities = yield this.store.query('project-priority', {
      page: {
        size: 1000,
        sort: 'name'
      }
    });

    this.set('projectPriorities', priorities);
    this.set('model.projectPriority', newPriority);
  }),
  addFundStatusTask: task(function* (newTypeName, options) {
    let newStatus = this.store.createRecord('fund-status', {
      name: newTypeName
    });

    yield newStatus.save();

    let statuses = yield this.store.query('fund-status', {
      page: {
        size: 1000,
        sort: 'name'
      }
    });

    this.set('fundStatuses', statuses);
    this.set('model.fund.fundStatus', newStatus);
  }),
  addFundTypeTask: task(function* (newTypeName, options) {
    let newType = this.store.createRecord('fund-type', {
      name: newTypeName
    });

    yield newType.save();

    let types = yield this.store.query('fund-type', {
      page: {
        size: 1000,
        sort: 'name'
      }
    });

    this.set('fundTypes', types);
    this.set('model.fund.fundType', newType);
  }),
  addCompanyTask: task(function* (newTypeName, options) {
    let newCompany = this.store.createRecord('company', {
      name: newTypeName
    });

    yield newCompany.save();

    this.set('model.company', newCompany);
  }),
  addProjectLeadTask: task(function* (fullName, options) {
    let person = this.store.createRecord("person");
    person.parseFromFullName(fullName);

    yield person.save();

    this.set('model.projectLead', person);
  }),
  addProjectManagerTask: task(function* (fullName, options) {
    let person = this.store.createRecord("person");
    person.parseFromFullName(fullName);

    yield person.save();

    this.set('model.projectManager', person);
  }),
  searchPersonsTask: task(function* (term) {
    yield timeout(300);
    if (!term) {
      return null;
    }
    try {

      let expr = Expressions.create({ operator: ExpressionOperators.AND });

      let termParts = term.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({ operator: ExpressionOperators.AND })

        nameExpression.add(Filter.create({
          name: 'firstName',
          operator: FilterOperators.START_WITH,
          value: termParts[0]
        }));

        nameExpression.add(Filter.create({
          name: 'lastName',
          operator: FilterOperators.START_WITH,
          value: termParts[termParts.length - 1]
        }));

        exprSearch.add(nameExpression);
      }

      ['firstName', 'lastName'].forEach((propName) => {
        exprSearch.add(Filter.create({
          name: propName,
          operator: FilterOperators.START_WITH,
          value: term
        }));
      });

      expr.add(exprSearch);

      return yield this.store.query('person', {
        condition: expr.serialize(),
        include: 'company,emails',
        sort: 'last-name',
        page: { size: 50, number: 1 }
      });
    } catch (e) {
      console.log(e);
    }
  }).restartable(),
  actions: {
    hideProjectTypeCreateOnSame(term) {
      let existingOption = this.get('projectTypes').findBy('name', term);
      return !existingOption;
    },
    hideProjectStatusCreateOnSame(term) {
      let existingOption = this.get('projectStatuses').findBy('name', term);
      return !existingOption;
    },
    hideProjectPriorityCreateOnSame(term) {
      let existingOption = this.get('projectPriorities').findBy('name', term);
      return !existingOption;
    },
    hideFundStatusCreateOnSame(term) {
      let existingOption = this.get('fundStatuses').findBy('name', term);
      return !existingOption;
    },
    hideFundTypeCreateOnSame(term) {
      let existingOption = this.get('fundTypes').findBy('name', term);
      return !existingOption;
    },
    removeType(type) {
      type.destroyRecord();
    },
    removeStatus(status) {
      status.destroyRecord();
    },
    removeFundType(type) {
      type.destroyRecord();
    },
    removeFundStatus(status) {
      status.destroyRecord();
    },
    removePriority(priority) {
      priority.destroyRecord();
    },
    createFundStatus(newTypeName, options) {
      this.addFundStatusTask.perform(newTypeName, options);
    },
    createFundType(newTypeName, options) {
      this.addFundTypeTask.perform(newTypeName, options);
    },
    createProjectType(newTypeName, options) {
      this.addProjectTypeTask.perform(newTypeName, options);
    },
    createProjectStatus(newTypeName, options) {
      this.addProjectStatusTask.perform(newTypeName, options);
    },
    createProjectPriority(newTypeName, options) {
      this.addProjectPriorityTask.perform(newTypeName, options);
    },
    createCompany(newTypeName, options) {
      this.addCompanyTask.perform(newTypeName, options);
    },
    createProjectLead(newTypeName, options) {
      this.addProjectLeadTask.perform(newTypeName, options);
    },
    createProjectManager(newTypeName, options) {
      this.addProjectManagerTask.perform(newTypeName, options);
    },
  }
})
