import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import { action } from "@ember/object";
import { task } from 'ember-concurrency';

import {
	Expressions,
	ExpressionOperators,
	Filter,
	FilterOperators,
} from 'cing-app/mixins/filter-builder';

import { tracked } from "@glimmer/tracking";
import { taskFor } from "ember-concurrency-ts";
import AppbuilderLink from "cing-app/models/appbuilder-link";
import Case from "cing-app/models/appbuilder-link/case";
import AppBuilder from "cing-app/pods/appbuilder/service";
import UserInstancesService from "cing-app/pods/user-instances/service";
import DockerItemService from "cing-app/pods/docker-item/service";

interface IArgs {
	model: any, //tab
	project: any,
	tab: any, //parent component

}
export default class AbProject extends Component<IArgs> {
	@service('appbuilder')
	appbuilder!: AppBuilder;
	@service store: any;
	@service session: any;
	@service serverVariables: any;
	@service('docker-item')
	docker!: DockerItemService;
	//@ts-ignore
	@service('user-instances')
	userInstances!: UserInstancesService;

	@tracked
	selectedAbInstance?: { name: string, url: string }
	@tracked
	abProjectFilter = '';
	@tracked
	reloadData = false;
	@tracked
	include = 'project-template';
	@tracked
	ABLink?: AppbuilderLink;
	@tracked
	condition: any;
	@tracked
	showConfirmUnlink = false;
	@tracked
	error: any;

	constructor(owner: unknown, args: IArgs) {
		super(owner, args);
		this.loadABLink.perform();
	}

	@task
	loadABLink = taskFor(async () => {
		try {
			if (this.args.model.settings.ABLinkId) {
				this.ABLink = await this.store.findRecord('appbuilder-link', this.args.model.settings.ABLinkId, { include: 'appbuilder-link-columns' });
			}
		}
		catch (err) {
			this.error = err;
		}
	});

	get columns() {
		let onSelectCase = (abCase: Case) => this.selectABCase.perform(abCase);

		let columns = [
			{
				propertyName: 'id',
				title: 'ID',
				isHidden: false
			},
			{
				propertyName: 'displayName',
				title: 'Display Name',
				sortedBy: 'case.display-name',
			},
			{
				propertyName: 'projectTemplate.templateName',
				title: 'Template',
			},
			{
				propertyName: 'owner',
				title: 'Owner',
			},
			{
				propertyName: 'createTime',
				title: 'Created',
				sortedBy: 'create-time',
				component: 'api-table/columns/date',
				dateFormat: "MM/DD/YYYY hh:mma",
				sortPrecedence: 0,
				sortDirection: "desc"
			},
			{
				title: 'test',
				component: 'manage-project/tab/ab-project/column-select',
				onSelect: onSelectCase
			},
		];

		return columns;
	}


	@task
	selectABCase = taskFor(async (abmCase) => {
		try {
			let abLink = <AppbuilderLink>this.store.createRecord('appbuilder-link', {
				ABCaseId: abmCase.id,
				ABObjectName: 'empty',
				ABInstanceName: this.selectedAbInstance?.name
			});

			await abLink.save();

			let abCase = <Case>await this.store.findRecord(this.appbuilder.getAbmModelName('appbuilder-link/case', abLink.id), abmCase.id, { include: 'project.objects,project-template,form-excel-sheets,form.form-elements.search-view-layout,form.form-elements.form-element-format,form.form-elements.answer-options' });
			let project = await abCase.project;
			if (project) {
				let objects = await project.objects;
				abLink.ABObjectName = objects.firstObject?.objectName ?? '';
				await abLink.save();
				this.args.model.settings.ABLinkId = abLink?.id;
			}

			await abLink.save();
			this.ABLink = abLink;

		}
		catch (err) {
			this.error = err;
		}
	});

	@task
	unlinkProject = taskFor(async () => {
		this.showConfirmUnlink = false;
		this.ABLink?.destroyRecord();
		this.ABLink = undefined;
		this.args.model.settings.ABLinkId = null;
		this.args.model.save();
	})

	@action
	searchProjects() {

	}

	@action
	searchAbProject(evt: Event) {
		evt.preventDefault();
		let expression = Expressions.create({
			operator: ExpressionOperators.OR
		});
		expression.add(Filter.create({
			//@ts-ignore
			name: 'display-name',
			operator: FilterOperators.LIKE,
			//@ts-ignore
			value: this.abProjectFilter
		}));
		expression.add(Filter.create({
			//@ts-ignore
			name: 'project-template.template-name',
			operator: FilterOperators.LIKE,
			//@ts-ignore
			value: this.abProjectFilter
		}));

		let parsedNumber = Number(this.abProjectFilter);

		if (Number.isInteger(parsedNumber)) {
			expression.add(Filter.create({
				//@ts-ignore
				name: 'id',
				operator: FilterOperators.EQUAL,
				//@ts-ignore
				value: this.abProjectFilter
			}));
		}

		this.condition = expression.serialize();
	}
}
