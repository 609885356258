import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('popup-header', 'primary-color', 'popup-drag')
export default class TopHeader extends Component {
  doubleClick() {
    if (this.get('status') === "full") {
      this.attrs.restore();
    } else {
      this.attrs.maximize();
    }
  }

  init() {
    super.init();

    let title = this.get('title');
    if (title === undefined || typeof (title) === "string") {
      this.set('finalTitle', title);
    }
    else {
      title.perform().then(computedTitle => {
        this.set('finalTitle', computedTitle);
      });
    }
  }
}
