export enum DataLinkModels {
	InterestedParty = 'interested-party'
}

export let InternalModels: Array<object> = [
	{
		id: DataLinkModels.InterestedParty,
		name: 'Interested Party',
		modelName: 'interested-party'
	}
]