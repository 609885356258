import Model, { attr } from '@ember-data/model';

export default class SyncSearchInvestor extends Model {
  @attr('string') declare commitment?: string;
  @attr('string') declare fundId?: string;
  @attr('string') declare companyId?: string;
  @attr('string') declare investorNumber?: string;
  @attr('string') declare investorFundId?: any;
  @attr('string') declare investorType?: any;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sync-search-investor': SyncSearchInvestor;
  }
}
