import JSONSerializer from '@ember-data/serializer/json';
import classic from 'ember-classic-decorator';

@classic
export default class DealCloudLpCommunicationFundSerializer extends JSONSerializer {
  attrs = {
    entityId: { key: 'id' },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'deal-cloud-lp-communication-fund': DealCloudLpCommunicationFundSerializer;
  }
}
