import { attr } from '@ember-data/model';
import Abstract from './abstract';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { AclPermissionTypeEnum } from 'cing-app/utils/lookups';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

const AclPermissionValidations = buildValidations({
  'description': validator('presence', true),
  'permissionTypeId': validator('presence', true),
});

export default class AclPermission extends Abstract.extend(
  AclPermissionValidations,
  computedEnumMixin(
    'permissionType', 'permissionTypeId', AclPermissionTypeEnum,
  )) {
  @attr('number') declare permissionTypeId: number;
  @attr('string') declare description: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'acl-permission': AclPermission;
  }
}

