import Component from '@glimmer/component';
import { FolderContainer } from 'smex-ui-sr-filemanager';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  Models,
  MyInfoResource,
  SmartroomMyInfoModel,
} from 'smex-ui-sr-models';
import { cached } from 'tracked-toolbox';

interface IArgs {
  folder?: FolderContainer;
  siteId: string;
  viewMode: string;
}

export default class SmartroomListHeader extends Component<IArgs> {
  @service('models') models!: Models;

  @cached
  get myInfoResource() {
    return new MyInfoResource(this.models, this.args.siteId);
  }
}
