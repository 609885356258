import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import Component from '@ember/component';

@classic
export default class Stack extends Component {
  @service('docker-item')
  docker;

  closeAllConfirm = false;

  @computed('docker.popups.@each')
  get stack() {
    return this.get('docker.popups');
  }

  stackSorting = ['_order:asc'];

  @sort('stack', 'stackSorting')
  stackOrdered;

  @computed('docker.popups.@each.undocked')
  get nrOfMinimizedPopups() {
    return this.get('docker.popups').filter(popup => !popup.undocked).get('length');
  }

  @computed('stack.@each', 'nrOfMinimizedPopups')
  get allMinimized() {
    return (this.get('stack.length') === this.get('nrOfMinimizedPopups'))
  }

  @action
  minimizeAll() {
    this.get('docker').minimizeAllPopups();
  }

  @action
  restoreAll() {
    this.get('docker').restoreAllPopups();
  }

  @action
  closeAllQuery() {
    this.set('closeAllConfirm', true);
  }

  @action
  cancelCloseAll() {
    this.set('closeAllConfirm', false);
  }

  @action
  closeAll() {
    this.get('docker').closeAllPopups();
    this.set('closeAllConfirm', false);
  }
}
