import { modifier } from 'ember-modifier';

export function iframeContent(iframe, params, hash) {
	let html = (params && params.length ? params[0] : null) || '';
	let removeLinks = (params && params.length > 1 ? params[1].removeLinks : false);

	iframe.style.height = '0px';
	let doc = iframe.contentDocument;
	doc.open();
	doc.write(html);
	doc.close();

	let resize = () => {
		const b = iframe.contentDocument.body;
		if (b) {
			const resizeDiv = b.querySelector('.resize-div');
			resizeDiv.style.height = '0px';
			resizeDiv.style.overflow = 'auto';
			let newHeight = `${resizeDiv.scrollHeight}px`;
			resizeDiv.style.height = 'auto';
			resizeDiv.style.overflow = 'hidden';

			if (newHeight !== iframe.style.height) {
				iframe.style.height = newHeight;
			}
		}
	}

	if (hash.initResize) {
		resize();
	}
	let id;
	if (hash.autoResize) {
		id = setInterval(resize, 300);
	}

	if (removeLinks) {
		let links = doc.querySelectorAll('a');
		for (let link of links) {
			link.removeAttribute('href');
		}
	}

	return () => {
		if (id !== undefined) {
			clearInterval(id);
		}
	};
}

export default modifier(iframeContent);
