import {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import Abstract from './abstract';

import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import {
  CaseStatus,
  CaseType,
  ChapterMap,
  CaseAssociationType,
  SourceType,
  ClaimsAgentTypes,
  ClaimsAgentImage,
} from 'cing-app/utils/lookups';

//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import Company from './company';
import Judge from './judge';
import Court from './court';
import Docket from './docket';
import CaseProjectUrlModel from './case-project-url';
import InterestedParty from './interested-party';
import DebtorProfile from './debtor-profile';

const CaseValidations = buildValidations({
  name: validator('presence', true),
  displayNumber: validator('presence', true),
  number: validator('presence', true),
  dateFiled: validator('date'),
  chapterName: validator('presence', true), // computed enum
  association: validator('presence', true), // computed enum
  typeName: validator('presence', true), // computed enum
});

export default class Case extends Abstract.extend(
  CaseValidations,
  computedEnumMixin('status', 'statusId', CaseStatus),
  computedEnumMixin('typeName', 'type', CaseType),
  computedEnumMixin('chapterName', 'chapter', ChapterMap),
  //@ts-ignore - .extend() Expecte 0-4 arguments, but got 7
  computedEnumMixin('association', 'associationType', CaseAssociationType),
  computedEnumMixin('source', 'sourceType', SourceType),
  computedEnumMixin('claimsAgent', 'claimAgentType', ClaimsAgentTypes)
) {
  @attr('string') declare name: string;
  @attr('string') declare number: string;
  @attr('string') declare displayNumber: string;
  @attr('string') declare description?: string;
  @attr('number') declare associatedCaseCount?: number;
  @attr('number') declare highestDocketNumber?: number;
  @attr('number') declare lowestDocketNumber?: number;
  @attr('date') declare lastRssUpdate?: Date;
  @attr('number') declare recentDocketCount?: number;
  @attr('string') declare customDescription?: string;
  @attr('string') declare manualDescription?: string;
  @attr('boolean') declare showCustomDescription?: boolean;
  @attr('string') declare dateFiled?: string;
  @attr('string') declare dateLastFiling?: string;
  @attr('string') declare datePlanConfirmed?: string;
  @attr('string') declare deadlineForClaims?: string;
  @attr('number') declare chapter?: number;
  @attr('string') declare courtId: string;
  @attr('string') declare flags?: string;
  @attr('boolean') declare asset?: boolean;
  @attr('number') declare associationType?: number;
  @attr('number') declare type: number;
  @attr('boolean') declare voluntaryPetition?: boolean;
  //@ts-ignore - mixin
  @belongsTo('case', { inverse: 'masterCase' })
  declare masterCase: AsyncBelongsTo<Case>;
  //@ts-ignore - mixin
  @belongsTo('case') declare parentCase: AsyncBelongsTo<Case>;
  @attr('string') declare parentCaseId?: string;
  @attr('string') declare debtorPerson?: string;
  //@ts-ignore - mixin
  @belongsTo('company') declare debtorCompany: AsyncBelongsTo<Company>;
  @belongsTo('judge') declare judge: AsyncBelongsTo<Judge>;
  @belongsTo('court') declare court: AsyncBelongsTo<Court>;
  @attr('number') declare statusId?: number;
  @attr('number') declare sourceType?: number;
  @attr('string') declare watchListId?: string;
  @attr('string') declare caseUrl?: string;

  @attr('number') declare claimAgentType?: number;
  @attr('string') declare claimAgentUrl?: string;
  @attr('boolean') declare isPublic?: boolean;

  //@ts-ignore - mixin
  @hasMany('docket') declare docket: AsyncHasMany<Docket>;
  //@ts-ignore - mixin
  @hasMany('case-project-url')
  declare projectUrls: AsyncHasMany<CaseProjectUrlModel>;
  //@ts-ignore - mixin
  @hasMany('interestedParty')
  declare interestedParties: AsyncHasMany<InterestedParty>;
  //@ts-ignore - mixin
  @belongsTo('debtorProfile')
  declare debtorProfile: AsyncBelongsTo<DebtorProfile>;
  //debtorProfile: DS.belongsTo('debtorProfile', {async: false}),

  get claimAgentImage() {
    return ClaimsAgentImage(this.claimAgentType);
  }

  get viewDescription() {
    if (this.showCustomDescription) {
      return this.customDescription;
    } else {
      return this.description;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    case: Case;
  }
}
