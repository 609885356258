import Component from '@glimmer/component';
//@ts-ignore
import { set, setProperties } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import { ConnectionTypes, ConnectionTypesMap } from 'cing-app/utils/lookups';
import { action } from '@ember/object';

import { inject as service } from '@ember/service';

import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import Project from 'cing-app/models/project';
import ArrayProxy from '@ember/array/proxy';
interface AppbuilderLinksEditArgs {
  model: AppbuilderLink;
  footer: any;
  header: any;
  onSave: () => void;
  onClose: () => void;
}

const ConnectionTypeOptions: any = [];

for (const [key, value] of Object.entries(ConnectionTypesMap)) {
  ConnectionTypeOptions.pushObject({
    name: key,
    id: value,
  });
}

export default class ManageUsers extends Component<AppbuilderLinksEditArgs> {
  ConnectionTypes = ConnectionTypes;
  ConnectionTypeOptions = ConnectionTypeOptions;

  @service('user-instances') userInstances: any;
  @service('store') declare store: Store;

  @tracked
  ABCase: any;

  @tracked
  ABProjects: any;

  @tracked
  projects!: ArrayProxy<Project>;

  get styleNamespace() {
    return podNames['manage-connections/appbuilder-links/edit'];
  }

  constructor(owner: any, args: AppbuilderLinksEditArgs) {
    super(owner, args);

    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    this.projects = await this.store.query('project', {
      page: {
        size: 1000,
      },
      include: 'project-type,project-status',

      sort: 'name',
    });
  });
}
