import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import AppBuilderService from 'cing-app/pods/appbuilder/service';
import AppBuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import podNames from 'ember-component-css/pod-names';
import UserProfile from 'cing-app/pods/components/top-navbar/user-profile/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
interface AbDailystatsDailyStatDetailArgs {
  context: {
    appBuilderLink: AppBuilderLink;
    userProfile: UserProfile;
  };
}

export default class AbDailystatsDailyStatDetail extends Component<AbDailystatsDailyStatDetailArgs> {
  @service('appbuilder')
  appbuilder!: AppBuilderService;

  @service
  store!: Store;

  @service
  session!: _SessionService;

  get styleNamespace() {
    return podNames['ab-dailystats/detail'];
  }

  constructor(owner: any, args: any) {
    super(owner, args);
  }

  get acceptTerms(): boolean {
    if (this.args.context.userProfile.acceptTerms.Yes) {
      return true;
    } else {
      return false;
    }
  }

  @task
  saveProfile = taskFor(async () => {
    await this.args.context.userProfile.save();
  });

  @action
  save() {
    this.saveProfile.perform();
  }
}
