import Component from '@glimmer/component';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { ConnectionTypes } from 'cing-app/utils/lookups';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface AppbuilderLinksEditArgs {
  header: any;
  footer: any;
  onClose: () => void;
  context: {
    model: AppbuilderLink;
    onSave: () => void;
  };
}

export default class ManageUsers extends Component<AppbuilderLinksEditArgs> {
  ConnectionTypes = ConnectionTypes;

  get styleNamespace() {
    return podNames['manage-connections/appbuilder-links/edit'];
  }

  constructor(owner: any, args: AppbuilderLinksEditArgs) {
    super(owner, args);

    this.args.context.model.validations.validate();
  }

  @task
  saveTask = taskFor(async () => {
    await this.args.context.model.save();

    this.args.onClose?.();
  });

  @action
  save() {
    return this.saveTask.perform();
  }

  @action
  close() {
    this.args.context.model.rollbackAttributes();
    this.args.onClose?.();
  }
}
