import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class Detail extends Component {
  hideContacts = true;
  hideCapitalCalls = true;
  hideDistributions = true;
  hidePaymentMethods = true;
  hidePcapData = true;

  @alias('context.abModel') abModel;
  @alias('context.model') model;
  @alias('context.project') project;
  @service('docker-item') docker;

  @action
  edit(record) {
    let appearance = {
      icon: '',
      title: `Investor ${this.abModel.investorName}`,
      //size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'ab-management/investors/investor-detail',
      appearance,
      {
        project: this.project,
        model: this.abModel,
      }
    );
  }
}
