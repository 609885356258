import Component from '@glimmer/component';
import UserGroup from 'cing-app/models/user-group';

interface ColumnRolesArgs {
  record: UserGroup;
  column: any;
}

export default class ColumnRoles extends Component<ColumnRolesArgs> {
  get roles() {
    return this.args.record[this.args.column.propertyName]
  }
}