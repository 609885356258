import { attr } from '@ember-data/model';
//@ts-ignore
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
import DataLinkViewColumn from './data-link-view-column';
import DataLinkViewLayout from './data-link-view-layout';
import DataLinkContextFilter from './data-link-context-filter';
export default class DataLinkView extends Fragment {
  @attr('string') declare name: string;
  @attr('string') declare modelName: string;
  @attr('string') declare type: string;
  @fragmentArray('data-link-view-column') declare columns: DataLinkViewColumn[];
  @fragment('data-link-view-layout') declare layout: DataLinkViewLayout;
  @fragmentArray('data-link-context-filter')
  declare filters: DataLinkContextFilter[];
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-link-view': DataLinkView;
  }
}
