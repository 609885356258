import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DailyStat } from '../component';
import { inject as service } from '@ember/service';
import AppBuilderService from 'cing-app/pods/appbuilder/service';
import AppBuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import _SessionService from 'cing-app/pods/session/service';
import podNames from 'ember-component-css/pod-names';

interface AbDailystatsDailyStatDetailArgs {
  context: {
    appBuilderLink: AppBuilderLink;
    dailyStat: DailyStat;
  };
}

export default class AbDailystatsDailyStatDetail extends Component<AbDailystatsDailyStatDetailArgs> {
  @service('appbuilder')
  appbuilder!: AppBuilderService;

  @service
  store!: Store;

  @service
  session!: _SessionService;

  //Summary
  @tracked
  summaryCollapsed: boolean = false;

  @tracked
  userLoginsCollapsed: boolean = true;
  @tracked
  userAlertsCollapsed: boolean = true;
  @tracked
  dataHistoriesCollapsed: boolean = true;
  @tracked
  documentActivitiesCollapsed: boolean = true;
  @tracked
  smartBillsCollapsed: boolean = true;

  get styleNamespace() {
    return podNames['ab-dailystats/detail'];
  }

  constructor(owner: any, args: any) {
    super(owner, args);
  }

  @action
  toggleSummary() {
    this.summaryCollapsed = !this.summaryCollapsed;

    if (this.summaryCollapsed == false) {
      this.userLoginsCollapsed = true;
      this.userAlertsCollapsed = true;
      this.dataHistoriesCollapsed = true;
      this.documentActivitiesCollapsed = true;
      this.smartBillsCollapsed = true;
    }
  }

  @action
  toggleUserLogins() {
    this.userLoginsCollapsed = !this.userLoginsCollapsed;

    if (this.userLoginsCollapsed == false) {
      this.summaryCollapsed = true;
      this.userAlertsCollapsed = true;
      this.dataHistoriesCollapsed = true;
      this.documentActivitiesCollapsed = true;
      this.smartBillsCollapsed = true;
    }
  }

  @action
  toggleUserAlerts() {
    this.userAlertsCollapsed = !this.userAlertsCollapsed;

    if (this.userAlertsCollapsed == false) {
      this.summaryCollapsed = true;
      this.userLoginsCollapsed = true;
      this.dataHistoriesCollapsed = true;
      this.documentActivitiesCollapsed = true;
      this.smartBillsCollapsed = true;
    }
  }

  @action
  toggleDataHistories() {
    this.dataHistoriesCollapsed = !this.dataHistoriesCollapsed;

    if (this.dataHistoriesCollapsed == false) {
      this.userLoginsCollapsed = true;
      this.userAlertsCollapsed = true;
      this.documentActivitiesCollapsed = true;
      this.smartBillsCollapsed = true;
      this.summaryCollapsed = true;
    }
  }

  @action
  toggleDocumentActivities() {
    this.documentActivitiesCollapsed = !this.documentActivitiesCollapsed;

    if (this.documentActivitiesCollapsed == false) {
      this.summaryCollapsed = true;
      this.userLoginsCollapsed = true;
      this.userAlertsCollapsed = true;
      this.dataHistoriesCollapsed = true;
      this.smartBillsCollapsed = true;
    }
  }

  @action
  toggleSmartBills() {
    this.smartBillsCollapsed = !this.smartBillsCollapsed;

    if (this.smartBillsCollapsed == false) {
      this.summaryCollapsed = true;
      this.userLoginsCollapsed = true;
      this.userAlertsCollapsed = true;
      this.dataHistoriesCollapsed = true;
      this.documentActivitiesCollapsed = true;
    }
  }
}
