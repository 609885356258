import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('cing-dropdown')
export default class SettingsDropdown extends Component {
  @action
  toggle() {
    const componentEl = this.$();
    const contentEl = componentEl.find('div').first();
    contentEl.toggleClass('visible');
  }
}
