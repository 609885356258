import classic from 'ember-classic-decorator';
import ApplicationSerializer from './application';

@classic
export default class AppbuilderLinkSerializer extends ApplicationSerializer {
  attrs = {
    ABInstanceName: { key: 'ab-instance-name' },
    ABCaseId: { key: 'ab-case-id' },
    ABCaseName: { key: 'ab-case-name' },
    ABDisplayName: { key: 'ab-display-name' },
    ABObjectName: { key: 'ab-object-name' },
    ABFilters: { key: 'ab-filters' },
    ABType: { key: 'ab-type' },
    ABAccessMapping: { key: 'ab-access-mapping' },
    SRSiteId: { key: 'sr-site-id' },
    SRName: { key: 'sr-name' },
    SRSlug: { key: 'sr-slug' },
    SRViewMode: { key: 'sr-view-mode' },
    SRAccessAgreements: { key: 'sr-access-agreements' },
    SRAllAccessFolders: { key: 'sr-all-access-folders' },
    SRIncludeInStats: { key: 'sr-include-in-stats' },
    SRFolderId: { key: 'sr-folder-id' },
    SRFolderName: { key: 'sr-folder-name' },
    columns: { key: 'appbuilder-link-columns' },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'appbuilder-link': AppbuilderLinkSerializer;
  }
}
