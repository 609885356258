import { attr } from '@ember-data/model';
//@ts-ignore
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
import { ValueFormat } from './data-link';

export default class DataLinkModelColumn extends Fragment {
  @attr('string') declare propertyName: string;
  @attr('boolean') declare isFromExtended: boolean;
  @attr('string') declare label: string;
  @attr('value-format', {
    defaultValue() {
      return new ValueFormat({});
    },
  })
  declare format: ValueFormat;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-link-model-column': DataLinkModelColumn;
  }
}
