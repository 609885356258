import JSONAPISerializer from '@ember-data/serializer/json-api';
import { guidFor } from '@ember/object/internals';
import classic from 'ember-classic-decorator';
import Store from '@ember-data/store';
import Model from '@ember-data/model';
@classic
export default class UserInstanceSerializer extends JSONAPISerializer {
  normalizeArrayResponse(
    store: Store,
    primaryModelClass: Model,
    payload: any,
    id: string | number,
    requestType: string
  ) {
    let modelName = primaryModelClass.modelName;

    for (var a = 0; a < payload.data.length; a++) {
      payload.data[a].id = guidFor(payload.data[a]);
    }

    return super.normalizeArrayResponse(
      store,
      primaryModelClass,
      payload,
      id,
      requestType
    );
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'user-instance': UserInstanceSerializer;
  }
}
