import Service, { inject as service } from '@ember/service';

import { Promise, resolve } from 'rsvp';

import { getOwner } from '@ember/application';

import { task } from 'ember-concurrency';

export default Service.extend({
  store: service(),
  config: service(),
  _usersCache: {
  },
  _profilesCache: {
  },
  cacheLifetime: 1000 * 60 * 5,
  init() {
    this._super();
  },
  findUser(siteId, email) {
    return this._usersCache[siteId].items.findBy('email', email);
  },
  getUsers(siteId, forceRefresh = false, cacheLifetime = this.cacheLifetime) {
    return new Promise((resolve, reject) => {
      if (this.needsToUpdate(this._usersCache, siteId, cacheLifetime)) {
        this.refreshUsers.perform(this._usersCache, siteId, cacheLifetime).then(
          () => {
            resolve(this._usersCache[siteId].items);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        resolve(this._usersCache[siteId].items);
      }
    });
  },
  getProfiles(siteId, forceRefresh = false, cacheLifetime = this.cacheLifetime) {
    return new Promise((resolve, reject) => {
      if (this.needsToUpdate(this._profilesCache, siteId, cacheLifetime)) {
        this.refreshProfiles.perform(this._profilesCache, siteId, cacheLifetime).then(
          () => {
            resolve(this._profilesCache[siteId].items);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        resolve(this._profilesCache[siteId].items);
      }
    });
  },
  needsToUpdate(cache, siteId, updateInterval) {
    if (!cache[siteId]) {
      cache[siteId] = {
        lastUpdate: null,
        items: []
      }
    }

    if (cache[siteId].lastUpdate === null || getTime() - cache[siteId].lastUpdate > updateInterval) {
      return true;
    }
  },
  refreshUsers: task(function* (cache, siteId, cacheLifetime) {
    if (!this.needsToUpdate(cache, siteId, cacheLifetime)) {
      return;
    }
    let allUsers = [];
    let hasMoreUsers = true;
    let pageNo = 1;
    let pageSize = 2;

    while (hasMoreUsers) {
      let users = yield this.store.query('smartroom/user', {
        siteId: siteId,
        for: 'manageuser',
        pageNo: pageNo,
        pageSize: pageSize
      });

      allUsers.pushObjects(users.toArray());

      if (pageNo >= users.meta.totalPages) {
        hasMoreUsers = false;
      } else {
        pageNo++;
      }
    }

    this._usersCache[siteId].items = allUsers;
  }).enqueue(),
  refreshProfiles: task(function* (cache, siteId, cacheLifetime) {
    if (!this.needsToUpdate(cache, siteId, cacheLifetime)) {
      return;
    }

    let profiles = yield this.store.query('smartroom/category', {
      siteId: siteId,
    });

    this._profilesCache[siteId].items = profiles.toArray();
  })
});