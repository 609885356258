import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { task, all } from 'ember-concurrency';
import {
  PortalPageMap,
  PortalPage,
  AclPermissionType,
  AclEntityType,
  ProjectTabMap,
  ProjectTab,
  CaseProjectUrlOpenMode,
} from 'cing-app/utils/lookups';
import {
  Expressions,
  FilterOperators,
  Filter,
} from 'cing-app/mixins/filter-builder';
import EmberObject, { computed } from '@ember/object';

const CaseProjectUrlModes = Object.keys(CaseProjectUrlOpenMode);

export default Component.extend({
  model: alias('context.page'),
  store: service(),
  docker: service('docker-item'),
  CaseProjectUrlModes,
  PortalPageMap,
  PortalPageOptions: Object.keys(PortalPage),
  onSaveSubTask: null,
  init() {
    this._super();
    this.initTask.perform();
  },
  initTask: task(function* () {
    let tasks = [];

    let types = [
      this.store.query('project-type', {
        page: {
          size: 1000,
        },
      }),
      this.store.query('project-status', {
        page: {
          size: 1000,
        },
      }),
      this.store.query('fund-type', {
        page: {
          size: 1000,
        },
      }),
      this.store.query('fund-status', {
        page: {
          size: 1000,
        },
      }),
    ];

    yield all(types);

    this.set('projectTypes', types[0]);
    this.set('projectStatuses', types[1]);
    this.set('fundTypes', types[2]);
    this.set('fundStatuses', types[3]);

    this.set('tabContext', {
      model: this.model,
    });
    /*
    let vgQuery = Expressions.create({});
    vgQuery.add(Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.EQUAL,
        value: AclPermissionType.PortalPageRead
    }));

    let roles = (yield this.store.query('acl-role', {
        page: {
            size: 1000
        },
        condition: vgQuery.serialize(),
        sort: 'description'            
    })).toArray();

    let entityRoles = [];

    if (this.get('model.id')) {
        let entityRolesQuery = Expressions.create({});
        entityRolesQuery.add(Filter.create({
            name: 'entity-id',
            operator: FilterOperators.EQUAL,
            value: this.get('model.id')
        }));
        
        entityRolesQuery.add(Filter.create({
            name: 'entity-type',
            operator: FilterOperators.EQUAL,
            value: AclEntityType.PortalPage
        }));
 
        entityRolesQuery.add(Filter.create({
            name: 'role-id',
            operator: FilterOperators.IN,
            value: roles.mapBy('id')
        }));
 
        entityRoles = (yield this.store.query('acl-entity-role', {
            page: {
                size: 1000
            },
            condition: entityRolesQuery.serialize(),
        })).toArray();
    }

    let viewerGroups = [];

    for (var a = 0; a < roles.length; a++) {
        let role = roles[a];
        let entityRole = entityRoles.findBy('roleId', role.get('id'));

        viewerGroups.pushObject(ViewerGroup.create({
            enabled: entityRole ? true: false,
            entityRole: entityRole,
            role: role
        }))
    }

    console.log("VIEWER GROUPS: ", viewerGroups);

    this.set('viewerGroups', viewerGroups);
    */
  }),
  saveTask: task(function* () {
    //console.log("SAVE TASK: ", )
    let slug = this.model.get('slug');
    let title = this.model.get('title');

    if (!slug && title) {
      slug = title.toLowerCase().replace(/[^A-Za-z0-9]|\s+|\r?\n|\r/gim, '-');

      this.set('model.slug', slug);
    }
    try {
      yield this.model.save();
      if (this.onSaveSubTask) {
        yield this.onSaveSubTask();
      }
    } catch (e) {
      e.errorContext = 'portal_pages';
      throw e;
    }
  }),
  removePageTask: task(function* () {
    yield this.model.destroyRecord();

    if (this.context && this.context.onRemove) {
      this.context.onRemove();
    }

    if (this.onClose) {
      this.onClose();
    }
  }),
  actions: {
    save() {
      this.saveTask.perform();
    },
    close() {
      if (this.context.onClose) {
        this.context.onClose();
      }
      if (this.onClose) {
        this.onClose();
      }
    },
    remove() {},
    doRemovePage() {
      this.removePageTask.perform();
    },
    addProjectFilter() {
      if (!this.model.get('settings.projectTypeFilters')) {
        this.model.set('settings.projectTypeFilters', []);
      }
      this.model
        .get('settings.projectTypeFilters')
        .pushObject(this.store.createFragment('portal-page-settings-item'), {});
    },
    removeProjectFilter(filter) {
      this.model.get('settings.projectTypeFilters').removeObject(filter);
    },
    addProjectStatusSection() {
      if (!this.model.get('settings.projectSectionFilters')) {
        this.model.set('settings.projectSectionFilters', []);
      }
      this.model
        .get('settings.projectSectionFilters')
        .pushObject(
          this.store.createFragment('portal-page-settings-section-item'),
          {}
        );
    },
    removeProjectStatusSection(filter) {
      this.model.get('settings.projectSectionFilters').removeObject(filter);
    },
    manageAccess(page) {
      this.docker.popupManageAccess(
        page,
        AclEntityType.PortalPage,
        page.title,
        [AclPermissionType.PortalPageRead]
      );
    },
  },
});
