import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Template from './project-template';

export default class CaseDirect extends Model {
  @attr('string') name!: string;
  @attr('string') displayName!: string;
  @attr('string') customCaseId!: string;
  @attr('string') publishUrl!: string;
  @attr('date') publishDate!: Date;
  @attr('boolean') isPublished!: boolean;
  //@ts-ignore
  @attr('boolean') isDeleted!: boolean;
  @attr('string') owner!: string;
  @attr('number') categoryId!: number;
  @attr('boolean') hasGantt?: boolean;
  @attr('number') projectId!: number;
  @attr('date') startDate?: Date;
  @attr('date') endDate?: Date;
  @attr('string') projectOverview!: string;
  @attr('boolean') isHidden!: boolean;
  @attr('string') caseProjectType!: string;
  @attr('number') parentCaseid?: number;
  @attr('boolean') runLpCalcs?: boolean;
  @attr('date') createTime!: Date;
  @attr('date') modifyTime!: Date;

  @belongsTo('appbuilder/project-template') projectTemplate!: AsyncBelongsTo<Template>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder/case': CaseDirect;
  }
}
