import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import Company from './company';
import Person from './person';
import Address from './address';
import { PersonInCompanyFlags } from 'cing-app/utils/lookups';

const Validations = buildValidations({
  company: [
    validator('presence', {
      presence: true,
      debounce: 10,
    }),
  ],
  person: [
    validator('presence', {
      presence: true,
      debounce: 10,
    }),
  ],
});

export default class PersonInCompany extends Abstract.extend(Validations) {
  @attr('string') declare companyId: string;
  @attr('string') declare personId: string;
  @attr('string') declare addressId?: string;
  @attr('string') declare role?: string; // new
  @attr('string') declare department?: string; // new
  @attr('string') declare title?: string;
  @attr('number') declare flags?: number;
  @belongsTo('company') declare company: AsyncBelongsTo<Company>;
  @belongsTo('company', { inverse: null })
  declare masterCompany: AsyncBelongsTo<Company>;
  @belongsTo('person') declare person: AsyncBelongsTo<Person>;
  @belongsTo('address') declare address: AsyncBelongsTo<Address>;

  get affiliationFlag() {
    if (this.flags && this.flags & PersonInCompanyFlags.FORMER) {
      return 'Prior';
    } else {
      return 'Current';
    }
  }

  get primaryFlag() {
    if (this.flags && this.flags & PersonInCompanyFlags.PRIMARY) {
      return 'Primary';
    } else {
      return '';
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'person-in-company': PersonInCompany;
  }
}
