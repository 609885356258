import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import Component from '@ember/component';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import _ from 'lodash';
import { PartyRole } from "cing-app/utils/lookups";

@classic
export default class Representing extends Component.extend(FilterBuilder) {
  @service
  store;

  @service
  session;

  @service('docker-item')
  docker;

  confirmRemoval = false;
  includes = ['interested-party'];
  searchQuery = '';

  init() {
    super.init(...arguments);

    this.set('columns', this.getColumns());
    this.set('interestedPartyFilter', A());
    this.generateFilter();
  }

  getColumns() {

    let columns = [
      {
        component: "case-detail/interested-parties/column-company-name",
        propertyName: 'interestedParty.partyName',
        title: "Party Name",
        className: "column-company-name",
        sortedBy: "partyName",
        //component: "case-detail/interested-parties/column-attorney"
      },
      {
        propertyName: 'interestedParty.person.fullName',
        title: "Name",
        className: "column-person-name",
        sortedBy: "person.lastName",
        component: "case-detail/interested-parties/column-attorney"
      },
      {
        propertyName: 'interestedParty.email.emailAddress',
        title: "E-mail",
        className: "column-email",
        sortedBy: "email.emailAddress",
        //component: "case-detail/interested-parties/column-attorney"
      },
      {
        propertyName: 'interestedParty.address.city',
        title: "City",
        className: 'column-city',
        sortedBy: "address.city"
      },
    ];

    if (this.get('session.isAdmin')) {
      columns.push({
        propertyName: '',
        title: "",
        className: 'column-remove',
        component: "api-table/columns/remove"
      });
    }

    return columns;
  }

  @observes('session.isAdmin')
  onAdmin() {
    if (this.get('session.isAdmin')) {
      this.set('columns', this.getColumns());
    }
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(Filter.create({
      name: 'representingPartyId',
      operator: FilterOperators.EQUAL,
      value: this.get('context.interestedPartyId')
    }));

    this.set('filter', expr.serialize());
  }

  @computed('includes.[]')
  get include() {
    return this.get('includes').join(",");
  }

  @task(function* (parties) {
    let party = null;

    for (let a = 0; a < parties.length; a++) {
      party = parties[a];
      let pm = this.get('store').createRecord('partyMap', {
        'projectId': this.get('context.projectId'),
        'interestedPartyId': party.get('id'),
        'representingPartyId': this.get('context.interestedPartyId'),
      });

      yield pm.save();
    }

    this.generateFilter();

    if (this.get('context.refresh')) {
      this.get('context.refresh')();
    }
  })
  createPartyMap;

  @action
  openPopup(component, appearance, context, header, footer) {
    this.get('docker').invokePopup(component, appearance, context, header, footer);
  }

  @action
  addRepresentingParty() {
    let self = this;
    let docker = this.get('docker');
    const appearance = {
      icon: '',
      title: 'Search for party'
    };

    const context = {
      projectId: this.get('context.projectId'),
      applySelection: function (selectedParties) {
        self.get('createPartyMap').perform(selectedParties);
      }
    };

    docker.invokePopup('case-detail/interested-parties/representing/add', appearance, context);
  }

  @action
  removeRecord(record) {
    this.set('confirmRemoval', record);
  }

  @action
  cancelRemoveRecord(record) {
    this.set('confirmRemoval', null);
  }

  @action
  doRemoveRecord(record) {
    record.destroyRecord().then((response) => {
      this.set('confirmRemoval', null);
      if (this.get('context.refresh')) {
        this.get('context.refresh')();
      }
    });
  }

  @action
  showAttorneyDetail(record) {
    record.get('representingParty.person').then((person) => {
      const appearance = {
        icon: '',
        title: 'Attorney: ' + person.get('fullName')
      };
      const context = { personId: record.get('id') };
      this.get('docker').invokePopup('contact-detail', appearance, context);
    });
  }

  @action
  showCompanyDetail(record) {
    let docker = this.get('docker');
    record.get('interestedParty').then((party) => {
      party.get('company').then((company) => {
        if (company) {
          const appearance = {
            label: 'Company detail',
            icon: '',
            title: 'Company: ' + company.get('name')
          };
          docker.invokePopup('company-detail', appearance, { companyId: company.get('id') });
        } else {
          alert('There is no company record associated with this party.');
        }
      });
    });
  }
}




