import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class CapitalCall extends Component {
  @alias('context.model') model;
  @alias('context.project') project;
  @service store;

  @task
  *saveTask(model) {
    if (model.get('validations.isValid')) {
      yield model.save();

      if (this.context.onComplete) {
        this.context.onComplete();
      }
    }
  }

  @task
  *removeTask(model) {
    yield model.destroyRecord();

    if (this.context.onComplete) {
      this.context.onComplete();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @action
  save() {
    this.saveTask.perform(this.get('model'));
  }

  @action
  remove() {
    this.removeTask.perform(this.get('model'));
  }
}
