import Model, { attr } from '@ember-data/model';

export default class Currency extends Model {
  @attr('string') currencyCode!: string;
  @attr('string') currencyDesc!: string;
  @attr('string') nation!: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/currency': Currency;
  }
}