import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import DocketConsolidatedType from './docket-consolidated-type';

export default class DocketType extends Abstract {
  @attr('string') declare name?: string;
  // docketTypeGroup: DS.belongsTo('docket-type-group'),
  @belongsTo('docket-consolidated-type')
  declare docketConsolidatedType: AsyncBelongsTo<DocketConsolidatedType>;
  @attr('string') declare docketConsolidatedTypeId?: string;
  @attr('number') declare docketCount?: number;
  @attr('boolean') declare deleted?: boolean;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'docket-type': DocketType;
  }
}
