import Model, { attr } from '@ember-data/model';

export default Model.extend({
  alertSettingTypeId: attr('number'),
  customLogo: attr('string'),
  dateCreated: attr('date'),
  emailOption: attr('number'),
  freeForm: attr('boolean'),
  isDefault: attr('boolean'),
  isDefaultForNonSRM: attr('boolean'),
  siteId: attr('number'),
  siteRedirectUrlId: attr('number'),
  subject: attr('string'),
  templateTitle: attr('string'),
  templateTypeId: attr('number')
})