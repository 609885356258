import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import AppBuilder, { ABModelMeta } from 'cing-app/pods/appbuilder/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action, set } from '@ember/object';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import AppbuilderLinkColumn, { ABColumnFormat } from 'cing-app/models/appbuilder-link-column';
import { all } from 'ember-concurrency';
import BottomWindows from 'cing-app/pods/bottom-windows/service';

// export interface ITableViewDefinitionProperty {
//   property: string;
//   label: string;
//   filtered: boolean;
//   advancedFilter: boolean;
//   sorted: boolean;
//   defaultVisible: boolean;
//   showInChart: boolean;
//   align: 'R' | 'L' | 'C';
// }
// export interface ITableViewDefinition {
//   properties: ITableViewDefinitionProperty[];
// }

interface TableViewEditorArgs {
	header: string;
	footer: string;
	onClose: () => void;
	context: {
		object: any;
		ABLink: AppbuilderLink;
	}
}

export default class TableViewEditor extends Component<TableViewEditorArgs> {
	@service('bottom-windows')
	bottomWindows!: BottomWindows;
	@service('docker-item')
	docker: any;
	@service('store')
	store!: Store;
	@service('appbuilder')
	appbuilder!: AppBuilder;
	@tracked
	modelMeta: ABModelMeta;

	constructor(owner: unknown, args: TableViewEditorArgs) {
		super(owner, args);
		this.modelMeta = this.appbuilder.getModelMeta(this.args.context.ABLink, this.args.context.object.objectName);

		this.createDefaultOnFirstTime.perform();
	}

	willDestroy() {
		let columns = this.args.context.ABLink.columns.filter(c => c.objectName === this.args.context.object.objectName);
		columns.forEach(c => c.rollbackAttributes());
		super.willDestroy();
	}

	@task
	createDefaultOnFirstTime = taskFor(async () => {
		if (this.columns.length === 0) {
			for (let field of this.modelMeta.fields.sortBy('formElement.sequenceNumber')) {
				if (field.formElement) {
					let searchViewLayout = await field.formElement.searchViewLayout;
					if (searchViewLayout?.isVisible) {
						this.addProperty.perform(field.property);
					}
				}
			}
		}
	});

	@action
	changeProp(propName: 'maxWidth' | 'minWidth' | 'maxHeight', column: AppbuilderLinkColumn, evt: Event) {
		let text = (<HTMLInputElement>evt.target).value;
		let value = (text ? parseInt(text) : null);
		if (Number.isInteger(value)) {
			//@ts-ignore
			set(column, propName, value);
		} else {
			//@ts-ignore
			set(column, propName, null);
		}
	}

	@action
	reorderItems(columns: AppbuilderLinkColumn[]) {
		columns.forEach((col, i) => {
			if (col.displayOrder !== i) {
				col.displayOrder = i;
			}
		});

		// columns.forEach(col => {
		// 	col.save();
		// });
	}

	@task
	addProperty = taskFor(async (prop: string) => {
		let formElement = this.modelMeta?.fields.find(f => f.property === prop)?.formElement;
		if (formElement) {
			let newColumn = this.store.createRecord('appbuilder-link-column', <AppbuilderLinkColumn>{
				objectName: this.args.context.object.objectName,
				propertyName: prop,
				label: formElement.question ?? '',
				displayOrder: this.args.context.ABLink.columns.length,
				sorting: true,
				advancedFilter: true,
				defaultVisible: true,
				format: ABColumnFormat.formElementToAbColumnFormat(formElement)
			})
			let searchViewLayout = await formElement.searchViewLayout;
			if (searchViewLayout) {
				newColumn.textAlign = <'R' | 'L' | 'C'>searchViewLayout.pmAlignment ?? 'L';
				newColumn.enableCharts = searchViewLayout.isGraph;
			}

			this.args.context.ABLink?.columns.pushObject(newColumn);
			// await newColumn.save();
		}
	})

	@action
	showFormatEditor(column: AppbuilderLinkColumn) {
		let appearance = {
			icon: 'paragraph',
			title: 'Edit Format',
			size: 'medium',
			custom: true
		};

		this.docker.invokePopup('table-view-editor/format', appearance, {
			column
		});
	}

	@action
	removeProperty(column: AppbuilderLinkColumn) {
		column.deleteRecord();
	}

	@task
	removeForm = taskFor(async () => {
		let columnsToRemove = this.args.context.ABLink.columns.filter(c => c.objectName === this.args.context.object.objectName) ?? [];
		if (columnsToRemove.length) {
			await all(columnsToRemove.map(c => c.destroyRecord()));
		}
		this.args.onClose();
	});

	get columns() {
		return this.args.context.ABLink.columns.filter(c => c.objectName === this.args.context.object.objectName && !c.isDeleted).sortBy('displayOrder');
	}

	// @task
	// saveColumn = taskFor(async (column: AppbuilderLinkColumn) => {
	// 	if (column.validations.isValid) {
	// 		await column.save();
	// 	}
	// });

	@task
	saveTask = taskFor(async () => {
		let columns = this.args.context.ABLink.columns.filter(c => c.objectName === this.args.context.object.objectName);
		let invalidColumn = columns.some(c => c.validations.isInvalid);

		if (invalidColumn) {
			return;
		}

		try {
			await all(columns.map(c => c.save()));
			this.bottomWindows.success('Table view was successfully saved.', {
				autoClear: true
			});
			this.args.onClose();
		}
		catch (err) {
			let message = '';
			if (err.errors && Array.isArray(err.errors)) {
				let e = err.errors[0];
				message = e.title;
			} else {
				message = err.toString();
			}
			if (message) {
				this.bottomWindows.danger(message);
			}
		}
	});
}
