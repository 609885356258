import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';

import _SessionService from 'cing-app/pods/session/service';
import AbPmService, { IPmTaskDocument } from 'cing-app/pods/ab-pm/service';

import { get } from '@ember/object';
import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';

interface AddSmartExchangeDocumentArgs {
  context: {
    abLink: any;
    task: any;
    onTaskAction: any;
    onSave: any;
    smartRoomId: any;
    documentsTableSource: any;
  };
}

export default class AddSmartExchangeDocument extends Component<AddSmartExchangeDocumentArgs> {
  @service seActions: any;
  @service('ab-pm') pmService!: AbPmService;
  @service declare store: Store;

  @tracked saveMessage: string = '';
  @tracked progressCount: string = '';
  @tracked isDragOver = false;
  @tracked isCollapsed = false;
  @tracked isUploading = false;

  @tracked smartRoomSiteInfo: any;
  @tracked smartRoomRootFolderInfo: any;

  @tracked startFolderId: any;
  @tracked dropZoneArea: any;

  @tracked selectedLocalFiles = [];
  @tracked isLinkedToSmartRoomFolder = false;
  @tracked isTaskModified = false;
  @tracked isShowAllChecked = false;
  @tracked isLoading = true;

  @tracked isRemoving: boolean = false;
  @tracked isRemovingMultipleItems: boolean = false;
  @tracked isDeleting: boolean = false;
  @tracked isDeletingMultipleItems: boolean = false;
  @tracked selectedDocument: any;
  @tracked selectedItems: any;

  @tracked dataSource!: ApiDataSource<IPmTaskDocument>;
  @tracked searchQuery: string = '';
  pageSize: number = 20;

  @tracked columns: DataSourceColumn<IPmTaskDocument>[] = [
    new DataSourceColumn<IPmTaskDocument>({
      id: 'id',
      label: 'ID',
      hidden: true,
      getValue: (row) => get(row, 'id'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
      options: {
        dasherizedProperty: 'id',
      },
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'task.wbs',
      label: 'Task #',
      getValue: (row) => get(row.task, 'wbs'),
      sortingEnabled: true,
      minWidth: 60,
      valueComponent: 'table-text-column',
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'task.task',
      label: 'Task',
      getValue: (row) => get(row.task, 'task'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'file-name',
      label: 'Filename',
      getValue: (row) => get(row, 'fileName'),
      sortingEnabled: true,
      minWidth: 180,
      valueComponent: 'table-filename-column',
      options: {
        tooltip: true,
        getTooltipValue: (row: any) => {
          return row.fileName || '';
        },
        linkTarget: '_blank',
        generateLink: (row: any) => {
          console.log('file row ', row.tasks);
          return row.url;
        },
      },
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'created-by',
      label: 'Uploaded By',
      getValue: (row) => get(row, 'createdBy'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
    }),
    new DataSourceColumn<IPmTaskDocument>({
      id: 'created-date',
      label: 'Uploaded',
      getValue: (row) => get(row, 'createdDate'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
      options: {
        format: {
          style: 'date',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },
      },
    }),
  ];

  constructor(owner: any, args: any) {
    super(owner, args);

    this.initTask.perform();
  }

  reloadDocumentsTable() {
    if (this.args.context.documentsTableSource) {
      if (this.args.context.documentsTableSource.refresh) {
        this.args.context.documentsTableSource.refresh();
      }
    }
    if (this.dataSource) {
      this.dataSource.refresh();
    }
  }

  @action init() {
    console.log('init task from task-documents');
    this.initTask.perform();
  }

  @task initTask = taskFor(async () => {
    this.smartRoomSiteInfo = await this.store.findRecord(
      'smartroom/site',
      this.args.context.smartRoomId
    );

    let rootFolderId = this.smartRoomSiteInfo.topLevelFolderId;
    this.startFolderId = this.args.context.task.folderId;
    if (this.startFolderId) {
      this.isLinkedToSmartRoomFolder = true;
    } else {
      this.startFolderId = rootFolderId;
    }

    if (rootFolderId) {
      this.smartRoomRootFolderInfo = await this.store.queryRecord(
        'smartroom/folder',
        {
          id: rootFolderId,
          siteId: this.args.context.smartRoomId,
        }
      );
    }

    this.selectedItems = [];

    console.log('init datasource');

    this.dataSource = new ApiDataSource<IPmTaskDocument>(
      25,
      false,
      'sx-documents',
      this.pmService.currentUserEmail,
      this.loadDataTask,
      this.pageSize,
      this.columns,
      this.selectedItems,
      {}
    );

    this.dataSource.enableSelectAll = false;
    this.dataSource.selectionEnabled = false;
  });

  @task loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<IPmTaskDocument>[],
      pageSize: number,
      pageIndex: number
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let sortBy = 'id';

      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        sortBy = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      console.log('loading data...');
      let selectedTaskId = this.args.context.task.id;
      if (this.isShowAllChecked) {
        selectedTaskId = null;
        console.log('Retrieving all task documents');
      }

      let docs = await this.pmService.getTaskDocumentsByType(
        'smartexchange',
        selectedTaskId,
        pageSize,
        pageIndex,
        sortBy,
        this.args.context.abLink
      );

      let totalRows = docs.meta['total-count'];
      let docsArr = docs.toArray();

      if (this.isShowAllChecked) {
        let taskIds = docsArr.map((t: any) => t.taskId);
        let tasks = await this.pmService.getTasksByIds(
          taskIds,
          this.args.context.abLink
        );
        let taskMap = new Map<String, any>();

        tasks.forEach((t) => {
          taskMap.set(t.taskId, t);
        });

        console.log('task map:', taskMap);
        docsArr.forEach((td: any) => {
          let taskId = td?.taskId;
          td.task = taskMap.get(taskId) || {};
        });
      } else {
        docsArr.forEach((d: any) => {
          d.task = this.args.context.task;
        });
      }

      let result = <Paged<IPmTaskDocument>>docsArr;
      result.meta = {
        totalCount: totalRows,
      };

      console.log('result ', result);

      return result;
    }
  );

  @action showAllChange() {
    setTimeout(() => {
      this.dataSource.refresh();
    }, 40);
  }

  @action initDropZoneArea(dz: Element) {
    this.dropZoneArea = dz;
  }

  @action
  dragOver(event: any) {
    event.preventDefault();
    this.isDragOver = true;
    this.dropZoneArea.classList.add('dz');
  }

  @action
  dragEnter(event: any) {
    this.isDragOver = true;
    event.preventDefault();
    this.dropZoneArea.classList.add('dz');
  }

  @action
  dragLeave(event: any) {
    this.isDragOver = false;
    event.preventDefault();
    this.dropZoneArea.classList.remove('dz');
  }

  @action
  dragEnd(event: any) {
    this.isDragOver = false;
    this.dropZoneArea.classList.remove('dz');
  }

  @action
  drop(event: any) {
    if (this.isDragOver) {
      this.isDragOver = false;
      event.preventDefault();
      this.dropZoneArea.classList.remove('dz');

      this.selectedLocalFiles = [];
      // note: event.dataTransfer.files is a FileList object
      // we need to convert it to a regular array
      let droppedFiles: [] = event.dataTransfer.files;
      for (var i = 0; i < droppedFiles.length; i++) {
        this.selectedLocalFiles.pushObject(droppedFiles[i]);
      }
    }
  }

  @action selectFile(event: any) {
    // note: fileInput.files is a FileList object
    // we need to convert it to a regular array
    let browsedFiles: [] = event.target.files;
    this.selectedLocalFiles = [];
    for (var i = 0; i < browsedFiles.length; i++) {
      this.selectedLocalFiles.pushObject(browsedFiles[i]);
    }
  }

  @action removeLocalFile(file: any, index: number) {
    if (this.selectedLocalFiles.removeObject) {
      this.selectedLocalFiles.removeObject(file);
    } else if (this.selectedLocalFiles) {
      this.selectedLocalFiles.splice(index, 1);
    }
  }

  @task(function* () {
    let files = this.selectedLocalFiles;

    if (files && files.length) {
      let numberOfFiles = files.length;
      for (var i = 0; i < numberOfFiles; i++) {
        let file = files[i];
        try {
          let count = `(${i + 1} of ${numberOfFiles})`;
          this.saveMessage = `Uploading ${count} - ${file.name} ...`;

          yield this.uploadFileTask.perform(file);
        } catch (ex) {
          this.saveMessage = `Error in uploading ${file.name} ...`;
        }
      }

      this.saveMessage = 'Updating task ...';
      this.args.context.task.save();
      this.saveMessage = 'Task documents have been updated.';
      this.reloadDocumentsTable();

      setTimeout(() => {
        this.selectedLocalFiles = [];

        if (this.dropZoneArea && this.dropZoneArea.querySelector) {
          let form = this.dropZoneArea.querySelector('form');
          if (form && form.reset) {
            form.reset();
          }
        }

        if (this.args.context.onSave) {
          this.args.context.onSave();
        }
      }, 1000);
    }
  })
  uploadFiles;

  @task uploadFileTask = taskFor(async (file: any) => {
    console.log('uploading file =>', file.name);

    let uploadResult = await this.seActions.uploadImage(
      file,
      '',
      true,
      file.name
    );
    console.log('uploadResult ', uploadResult);

    if (uploadResult && uploadResult.url) {
      let doc = this.pmService.createTaskDocument(this.args.context.abLink);
      doc.type = 'smartexchange';
      doc.fileName = file.name;
      doc.filename = file.name;
      doc.url = uploadResult.url;
      doc.metadata = JSON.stringify(uploadResult);
      doc.taskId = this.args.context.task.id;
      doc.email = this.pmService.currentUserEmail;
      doc.createdDate = this.pmService.currentDate;
      doc.createdBy = this.pmService.currentUserFullName;

      let documents = this.args.context.task.documents;
      if (documents) {
        documents.pushObject(doc);
      }

      await doc.save();
    }
  });

  @task saveTask = taskFor(async () => {
    try {
      await this.uploadFiles.perform();
    } catch (ex) {
      // noop
    }
  });

  @action search() {
    this.dataSource.refresh();
  }

  @action refresh() {
    this.dataSource.refresh();
  }

  @action filenameClicked(row: any) {
    if (row && row.url) {
      window.open(row.url, '_blank');
    }
  }

  @action getActions() {
    let actions = [
      {
        label: 'View',
        icon: 'eye',
        action: this.filenameClicked,
      },
      {
        label: 'Download',
        icon: 'download',
        action: (row: any) => {
          this.downloadFileTask.perform(row);
        },
      },
      {
        label: 'Delete',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (row: any) => {
          this.isDeleting = true;
          this.isDeletingMultipleItems = false;
          this.selectedDocument = row;
        },
      },
    ];
    return actions;
  }

  @task downloadFileTask = taskFor(async (file: any) => {
    console.log('download file ', file);

    if (file.type == 'smartroom' && file.fileId) {
      await this.pmService.downloadSmartRoomFile(
        this.args.context.smartRoomId,
        file
      );
    } else {
      this.pmService.downloadFile(file.url, file.fileName);
    }
  });

  @action launchUploadDialog() {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.style.display = 'none';
    document.body.appendChild(input);
    input.onchange = (event: any) => {
      this.uploadFiles.perform(input);
    };
    input.click();
  }

  @action toggleDocuments() {
    this.isCollapsed = !this.isCollapsed;
  }

  @action deleteSelected() {
    this.isDeleting = true;
    this.isDeletingMultipleItems = true;
  }

  @action downloadSelected() {
    this.dataSource.selectedItems?.forEach((item: any) => {
      this.downloadFileTask.perform(item);
    });
  }

  @task removeDocumentTask = taskFor(async () => {
    let itemsToDelete: any = [this.selectedDocument];
    if (this.isDeletingMultipleItems) {
      itemsToDelete = this.dataSource.selectedItems;
    }

    itemsToDelete.forEach((item: any) => {
      try {
        if (item) {
          if (item.destroyRecord) {
            item.destroyRecord();
          }
        }
      } catch (ex: any) {
        console.log('delete failed', ex);
      }
    });

    setTimeout(() => {
      this.dataSource.selectedItems?.clear();
      this.dataSource.refresh();
      this.isRemoving = false;
    }, 800);
  });

  @task deleteDocumentTask = taskFor(async () => {
    let itemsToDelete: any = [this.selectedDocument];
    if (this.isDeletingMultipleItems) {
      itemsToDelete = this.dataSource.selectedItems;
    }

    for (var i = 0; i < itemsToDelete.length; i++) {
      let item = itemsToDelete[i];
      try {
        if (item) {
          if (item.fileId) {
            //delete the smartroom record for this file
            await this.pmService.deleteSmartRoomFile(
              this.args.context.smartRoomId,
              item.fileId
            );
          }

          //delete the record in pm
          if (item.destroyRecord) {
            item.destroyRecord();
          }
        }
      } catch (ex) {
        console.log('delete failed', ex);
      }
    }

    setTimeout(() => {
      this.dataSource.selectedItems?.clear();
      this.dataSource.refresh();
      this.isDeleting = false;
    }, 800);
  });
}
