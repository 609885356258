import classic from 'ember-classic-decorator';
import $ from 'jquery';
import { setProperties, set, get, computed } from '@ember/object';
import { isBlank, isNone } from '@ember/utils';
import { run } from '@ember/runloop';
import { warn, assert } from '@ember/debug';
import ColumnsDropdown from 'ember-models-table/components/models-table/columns-dropdown';

const { extend } = $;

@classic
export default class _ColumnsDropdown extends ColumnsDropdown { }
