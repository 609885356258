import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
//@ts-ignore
import { cached } from 'tracked-toolbox';
import { ExpressionOperators, Expressions, Filter, FilterOperators } from 'cing-app/mixins/filter-builder';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import moment from 'moment';
import DataLink from 'cing-app/models/data-link';
import DataLinkView from 'cing-app/models/data-link-view';
import DataLinkContextFilter from 'cing-app/models/data-link-context-filter';
import { get } from '@ember/object';

import { hbs } from 'ember-cli-htmlbars';
import DataLinkViewColumn from 'cing-app/models/data-link-view-column';
import DataLinkModel from 'cing-app/models/data-link-model';
import DataLinkModelColumn from 'cing-app/models/data-link-model-column';
import { alias } from '@ember/object/computed';

interface DataLinkViewDetailArgs {
  context: {
    dataLink: DataLink;
    dataLinkView: DataLinkView;
    dataLinkModel: DataLinkModel;
    contextObject?: any;
  }
}

import Ember from 'ember';

const { TEMPLATES, HTMLBars } = Ember;

export default class DataLinkViewList extends Component<DataLinkViewDetailArgs> {
  @service
  session: any;
  @service('store')
  store!: Store;

  @tracked
  dynamicView: any;

  @alias('args.context.contextObject') model!: any;

  constructor(owner: unknown, args: DataLinkViewDetailArgs) {
    super(owner, args);

    //this.dynamicView = hbs(this.args.context.dataLinkView.layout.html, {});
    //@ts-ignore
    //let template = Handlebars.compile(this.args.context.dataLinkView.layout.html ?? '');


    this.dynamicView = Ember.HTMLBars.compile(this.args.context.dataLinkView.layout.html ?? '');

    /*
    let rawModel = this.args.context.contextObject;//model property is used in eval!! do not remove this line.
    let model: any = {};

    this.args.context.dataLinkModel.columns.forEach((column: DataLinkModelColumn) => {
      //console.log("VCOLUMN: ", column);
      model[column.propertyName] = (column.format ? column.format.formatValueHtml(get(rawModel, column.propertyName)) : get(rawModel, column.propertyName))
    });

    let jsonModel = (model.toJSON ? model.toJSON() : model);
    let jsonRawModel = (rawModel.toJSON ? rawModel.toJSON() : rawModel);

    let templateData: any = {
      model: jsonModel,
      rawModel: jsonRawModel
    };

    console.log("TEMPLATE DATA: ", templateData);
    */
    /*
    for (let prop of this.args.layout?.properties ?? []) {
      try {
        templateData[prop.propName] = eval(prop.code);
      }
      catch (err) {
        //ignore error for V1
      }
    }
    */

    /*
    this.dynamicView = template(templateData, {
      allowProtoMethodsByDefault: true,
      allowProtoPropertiesByDefault: true
    })
    */

    //this.dynamicView = hbs(String(this.args.context.dataLinkView.layout.html));
  }
}
