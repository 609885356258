import classic from 'ember-classic-decorator';
import Store from '@ember-data/store';

@classic
export default class AbStoreService extends Store {
  adapterFor(modelName) {
    modelName = 'ab/investors/' + modelName.split('/')[2];
    return super.adapterFor(modelName);
  }

  serializerFor(modelName) {
    modelName = 'ab/investors/' + modelName.split('/')[2];
    return super.serializerFor(modelName);
  }
  /*
  modelFor(modelName) {
      if (DEBUG) {
          assertDestroyedStoreOnly(this, 'modelFor');
      }
      assert(`You need to pass a model name to the store's modelFor method`, isPresent(modelName));
      assert(
          `Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`,
          typeof modelName === 'string'
      );
  
      let maybeFactory = this._modelFactoryFor(modelName);
  
      // for factorFor factory/class split
      return maybeFactory.class ? maybeFactory.class : maybeFactory;
  },
  */
}