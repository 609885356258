import Service, { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { camelize, dasherize } from '@ember/string';
import { singularize } from 'ember-inflector';
import Case from 'cing-app/models/appbuilder-link/case';
import FormElement, {
  ABInputType,
} from 'cing-app/models/appbuilder-link/form-element';
import FormElementFormat from 'cing-app/models/appbuilder-link/form-element-format';
import FormExcelSheet from 'cing-app/models/appbuilder-link/form-excel-sheet';

// source serializers
import AppbuilderLinkSerializer from '../../serializers/appbuilder-link-serializer';
import AppbuilderLinkAdapter from '../../adapters/appbuilder-link/appbuilder-link-adapter';

import { getOwner } from '@ember/application';

import type Store from '@ember-data/store';
import SearchViewLayout from 'cing-app/models/appbuilder-link/search-view-layout';
import AnswerOption from 'cing-app/models/appbuilder-link/answer-option';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { all, TaskInstance } from 'ember-concurrency';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import CaseSerializer from 'cing-app/serializers/appbuilder-link/case';
import CaseAdapter from 'cing-app/adapters/appbuilder-link/case';
import ProjectSerializer from 'cing-app/serializers/appbuilder-link/project';
import ProjectAdapter from 'cing-app/adapters/appbuilder-link/project';
import Project from 'cing-app/models/appbuilder-link/project';
import CategorySerializer from 'cing-app/serializers/appbuilder-link/category';
import CategoryAdapter from 'cing-app/adapters/appbuilder-link/category';
import Category from 'cing-app/models/appbuilder-link/category';
import ProjectTemplateSerializer from 'cing-app/serializers/appbuilder-link/project-template';
import TemplateAdapter from 'cing-app/adapters/appbuilder-link/project-template';
import Template from 'cing-app/models/appbuilder-link/project-template';
import FormSerializer from 'cing-app/serializers/appbuilder-link/form';
import FormAdapter from 'cing-app/adapters/appbuilder-link/form';
import FormModel from 'cing-app/models/appbuilder-link/form';
import CurrencySerializer from 'cing-app/serializers/appbuilder-link/currency';
import CurrencyAdapter from 'cing-app/adapters/appbuilder-link/currency';
import Currency from 'cing-app/models/appbuilder-link/currency';
import SearchViewLayoutSerializer from 'cing-app/serializers/appbuilder-link/search-view-layout';
import SearchViewLayoutAdapter from 'cing-app/adapters/appbuilder-link/search-view-layout';
import FormExcelSheetSerializer from 'cing-app/serializers/appbuilder-link/form-excel-sheet';
import FormExcelSheetAdapter from 'cing-app/adapters/appbuilder-link/form-excel-sheet';
import ObjectSerializer from 'cing-app/serializers/appbuilder-link/object';
import ObjectAdapter from 'cing-app/adapters/appbuilder-link/object';
import ObjectModel from 'cing-app/models/appbuilder-link/object';
import AnswerOptionSerializer from 'cing-app/serializers/appbuilder-link/answer-option';
import AnswerOptionAdapter from 'cing-app/adapters/appbuilder-link/answer-option';
import FieldPropertySerializer from 'cing-app/serializers/appbuilder-link/field-property';
import FieldPropertyAdapter from 'cing-app/adapters/appbuilder-link/field-property';
import FieldProperty from 'cing-app/models/appbuilder-link/field-property';
import FormElementSerializer from 'cing-app/serializers/appbuilder-link/form-element';
import FormElementAdapter from 'cing-app/adapters/appbuilder-link/form-element';
import FormElementRepeaterSerializer from 'cing-app/serializers/appbuilder-link/form-element-repeater';
import FormElementRepeaterAdapter from 'cing-app/adapters/appbuilder-link/form-element-repeater';
import FormElementRepeater from 'cing-app/models/appbuilder-link/form-element-repeater';
import FormElementFormatSerializer from 'cing-app/serializers/appbuilder-link/form-element-format';
import FormElementFormatAdapter from 'cing-app/adapters/appbuilder-link/form-element-format';
import FieldSerializer from 'cing-app/serializers/appbuilder-link/field';
import FieldAdapter from 'cing-app/adapters/appbuilder-link/field';
import Field from 'cing-app/models/appbuilder-link/field';

import { Query, Filter, FilterOperators } from 'cing-app/utils/query-builder';

export enum PropertyType {
  Attribute,
  BelongsTo,
  HasMany,
}

export class ABModelFieldMeta {
  property: string;
  type: PropertyType;
  formElement: FormElement | undefined;
  formElementFormat: FormElementFormat | undefined;
  searchViewLayout: SearchViewLayout | undefined;

  constructor(
    property: string,
    type: PropertyType,
    formElement: FormElement | undefined
  ) {
    this.property = property;
    this.type = type;
    this.formElement = formElement;
    if (formElement) {
      this.formElementFormat = get(formElement, 'formElementFormat');
      this.searchViewLayout = get(formElement, 'searchViewLayout');
    }
  }
}

export class ABModelMeta {
  modelName: string;
  modelPath: string;
  formExcelSheet: FormExcelSheet;
  modelClass: Model;
  fields: Array<ABModelFieldMeta>;
  formElements: Array<FormElement>;

  constructor(
    modelName: string,
    modelPath: string,
    modelClass: Model,
    formExcelSheet: FormExcelSheet,
    formElements: Array<FormElement>
  ) {
    this.modelName = modelName;
    this.modelPath = modelPath;
    this.modelClass = modelClass;
    this.formExcelSheet = formExcelSheet;
    this.formElements = formElements;

    this.fields = [];

    if (this.formElements) {
      get(modelClass, 'attributes').forEach((meta, name: string) => {
        this.fields.pushObject(
          new ABModelFieldMeta(
            name,
            PropertyType.Attribute,
            formElements.findBy('objFieldName', dasherize(name))
          )
        );
      });
    }

    //console.log("FIELDS: ", this.fields);
  }

  // convertControlType(controlType: ABInputType | undefined): FormColumn["type"] {
  //   switch (controlType) {
  //     case "number": return 'number';
  //     case "autoincrement": return 'static';
  //     case "text": return 'text';
  //     case "textarea": return 'textarea';
  //     case "email": return 'email';
  //     case "date": return 'datetime';
  //     case "amount": return 'amount';
  //     case "tel": return 'tel';
  //     case "ssn": return 'ssn';
  //     case "url": return 'url';
  //     case "select": return 'power-select';
  //     case "radio": return 'radio';
  //     case "checkboxes": return 'checkboxes';
  //     case "multipleSelect": return 'power-select-multiple';
  //     case "calculatedfield": return 'static';
  //     default: return 'text';
  //   }
  // }
  convertControlTypeToIcon(fieldMeta: ABModelFieldMeta): string {
    switch (fieldMeta.formElement?.controlType) {
      case 'number':
        return fieldMeta.formElementFormat?.get('dataType') === 'percentage'
          ? 'percent'
          : 'hashtag';
      case 'autoincrement':
        return 'hashtag';
      case 'text':
        return 'terminal';
      case 'textarea':
        return 'font';
      case 'email':
        return 'at';
      case 'date':
        return 'calendar';
      case 'amount':
        return 'usd';
      case 'tel':
        return 'phone-square';
      case 'ssn':
        return 'shield';
      case 'url':
        return 'link';
      case 'select':
        return 'list';
      case 'radio':
        return 'dot-circle-o';
      case 'checkboxes':
        return 'check-square';
      case 'multipleSelect':
        return 'list';
      case 'calculatedfield':
        return 'hashtag';
      default:
        return 'terminal';
    }
  }

  getSaveCancelButton() {
    return {
      label: 'Columns',
      columns: [
        {
          components: [
            {
              label: 'Save',
              showValidations: false,
              disableOnInvalid: true,
              tableView: false,
              key: 'save',
              type: 'button',
              input: true,
              hideOnChildrenHidden: false,
            },
          ],
          width: 0,
          offset: 0,
          push: 0,
          pull: 0,
          size: 'md',
        },
        {
          components: [
            {
              label: 'Cancel',
              action: 'event',
              showValidations: false,
              theme: 'default',
              tableView: false,
              key: 'cancel',
              type: 'button',
              event: 'reset',
              input: true,
              hideOnChildrenHidden: false,
            },
          ],
          width: 0,
          offset: 0,
          push: 0,
          pull: 0,
          size: 'md',
        },
      ],
      key: 'columns',
      type: 'columns',
      input: false,
      tableView: false,
    };
  }

  convertControlTypeToSchema(fieldMeta: ABModelFieldMeta): any {
    let key = camelize(fieldMeta.formElement?.objFieldName || '');
    switch (fieldMeta.formElement?.controlType) {
      case 'number':
        return {
          label: fieldMeta.formElement?.question,
          mask: false,
          spellcheck: true,
          tableView: false,
          delimiter: fieldMeta.formElementFormat?.get('useSeparator') ?? false,
          requireDecimal: false,
          inputFormat: 'plain',
          key: key,
          type: 'number',
          input: true,
          suffix:
            fieldMeta.formElementFormat.get('dataType') === 'percentage'
              ? '%'
              : undefined,
          decimalLimit: fieldMeta.formElementFormat.get('decimalPlaces'),
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'autoincrement':
        return {
          label: fieldMeta.formElement?.question,
          disabled: true,
          tableView: true,
          key: fieldMeta.formElement?.objFieldName,
          type: 'textfield',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'text':
        return {
          label: fieldMeta.formElement?.question,
          tableView: true,
          key: key,
          type: 'textfield',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'textarea':
        return {
          label: fieldMeta.formElement?.question,
          autoExpand: false,
          tableView: true,
          key: key,
          type: 'textarea',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'email':
        return {
          label: fieldMeta.formElement?.question,
          tableView: true,
          key: key,
          type: 'email',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'date':
        return {
          label: fieldMeta.formElement?.question,
          format: fieldMeta.formElementFormat
            ?.get('dateFormat')
            ?.replace('ampm', 'a'),
          tableView: false,
          enableMinDateInput: false,
          datePicker: {
            disableWeekends: false,
            disableWeekdays: false,
          },
          enableMaxDateInput: false,
          key: key,
          type: 'datetime',
          input: true,
          widget: {
            type: 'calendar',
            displayInTimezone: 'viewer',
            locale: 'en',
            useLocaleSettings: false,
            allowInput: true,
            mode: 'single',
            enableTime: true,
            noCalendar: false,
            format: fieldMeta.formElementFormat
              ?.get('dateFormat')
              ?.replace('ampm', 'a'),
            hourIncrement: 1,
            minuteIncrement: 1,
            time_24hr: false,
            minDate: null,
            disableWeekends: false,
            disableWeekdays: false,
            maxDate: null,
          },
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'amount':
        return {
          label: fieldMeta.formElement?.question,
          mask: false,
          spellcheck: true,
          tableView: false,
          currency: 'USD',
          inputFormat: 'plain',
          key: key,
          type: 'currency',
          input: true,
          delimiter: fieldMeta.formElementFormat?.get('useSeparator') ?? false,
          decimalLimit: fieldMeta.formElementFormat?.get('decimalPlaces'),
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'tel':
        return {
          label: fieldMeta.formElement?.question,
          tableView: true,
          key: key,
          type: 'phoneNumber',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'ssn':
        return {
          label: fieldMeta.formElement?.question,
          inputMask: '999-99-9999',
          tableView: true,
          key: key,
          type: 'textfield',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'url':
        return {
          label: fieldMeta.formElement?.question,
          tableView: true,
          key: key,
          type: 'url',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      case 'select':
        return {
          label: fieldMeta.formElement?.question,
          widget: 'choicesjs',
          tableView: true,
          data: {
            values: fieldMeta.formElement?.answerOptions
              .sortBy('sequenceNumber')
              .map((o) => ({ label: o.optionText, value: o.optionText })),
          },
          selectThreshold: 0.3,
          validate: {
            required: fieldMeta.formElement?.isRequired,
            onlyAvailableItems: false,
          },
          key: key,
          type: 'select',
          indexeddb: {
            filter: {},
          },
          input: true,
        };
      case 'radio':
        return {
          label: fieldMeta.formElement?.question,
          optionsLabelPosition: 'right',
          inline: false,
          tableView: false,
          values: fieldMeta.formElement?.answerOptions
            .sortBy('sequenceNumber')
            .map((o) => ({
              label: o.optionText,
              value: o.optionText,
              shortcut: '',
            })),
          validate: {
            required: fieldMeta.formElement?.isRequired,
            onlyAvailableItems: false,
          },
          key: key,
          type: 'radio',
          input: true,
        };
      case 'checkboxes':
        return {
          label: fieldMeta.formElement?.question,
          optionsLabelPosition: 'right',
          tableView: false,
          values: fieldMeta.formElement?.answerOptions
            .sortBy('sequenceNumber')
            .map((o) => ({
              label: o.optionText,
              value: o.optionText,
              shortcut: '',
            })),
          validate: {
            required: fieldMeta.formElement?.isRequired,
            onlyAvailableItems: false,
          },
          key: key,
          type: 'selectboxes',
          input: true,
          inputType: 'checkbox',
        };
      case 'multipleSelect':
        return {
          label: fieldMeta.formElement?.question,
          widget: 'choicesjs',
          tableView: true,
          multiple: true,
          data: {
            values: fieldMeta.formElement?.answerOptions.map((o) => ({
              label: o.optionText,
              value: o.optionText,
            })),
          },
          selectThreshold: 0.3,
          validate: {
            required: fieldMeta.formElement?.isRequired,
            onlyAvailableItems: false,
          },
          key: key,
          type: 'select',
          indexeddb: {
            filter: {},
          },
          input: true,
        };
      case 'calculatedfield':
        return {
          label: fieldMeta.formElement?.question,
          disabled: true,
          tableView: true,
          key: key,
          type: 'textfield',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
      default:
        return {
          label: fieldMeta.formElement?.question,
          tableView: true,
          key: key,
          type: 'textfield',
          input: true,
          validate: {
            required: fieldMeta.formElement?.isRequired,
          },
        };
    }
  }

  getFormDesignerProperties(availableProperties?: string[]) {
    let components: any = {};
    let availableFields = availableProperties
      ? this.fields.filter((f) =>
          availableProperties.find((ap) => ap === f.property)
        )
      : this.fields;
    for (let f of availableFields) {
      if (f.formElement) {
        let key = f.formElement.objFieldName;
        components[key] = {
          title: f.formElement.question,
          key: key,
          icon: this.convertControlTypeToIcon(f),
          schema: this.convertControlTypeToSchema(f),
        };
      }
    }
    return components;
  }

  formElementFormattingToFormatting(
    fe: FormElement | undefined
  ): ICurrencyFormat | INumberFormat | undefined {
    if (fe && fe.get('formElementFormat')) {
      if (fe.controlType === 'amount') {
        let currency = 'USD';

        return {
          currency: currency,
          decimalDigits: fe.get('formElementFormat').get('decimalPlaces'),
          negativeRed: true,
          accounting: true,
          signDisplay: 'auto',
          groupSeparator:
            fe.get('formElementFormat').get('useSeparator') ?? true,
        };
      } else if (fe.controlType === 'number') {
        return {
          decimalDigits: fe.get('formElementFormat').get('decimalPlaces'),
          negativeRed: true,
          signDisplay: 'auto',
          groupSeparator:
            fe.get('formElementFormat').get('useSeparator') ?? true,
        };
      }
    }

    return undefined;
  }

  //Update formElement differences in formDefinition from appbuilder
  updateFormDesignerDefinition(formDefinition: SmexUIFormDesignerDefinition) {
    for (let row of <FormRow[]>formDefinition.rows) {
      for (let col of row.columns) {
        let field = this.fields.find(
          (f) => camelize(f.formElement?.objFieldName || '') === col.property
        );
        if (field && field.formElement) {
          let fe = field.formElement;
          col.label = fe.question;
          col.help = fe.helpText;
          col.required = fe.isRequired;
          col.options = fe.answerOptions
            .sortBy('sequenceNumber')
            .map(
              (o: AnswerOption) => new FormOption(o.optionText, o.optionText)
            );
          col.formatting = this.formElementFormattingToFormatting(fe);
        }
      }
    }
  }

  //save part of formDefinition to AppBuilder
  @task
  saveFormDefinition = taskFor(
    async (formDefinition: SmexUIFormDesignerDefinition, store: Store) => {
      let promises: TaskInstance<void>[] = [];
      for (let row of <FormRow[]>formDefinition.rows) {
        for (let formColumn of row.columns) {
          let field = this.fields.find(
            (f) =>
              camelize(f.formElement?.objFieldName || '') ===
              formColumn.property
          );
          let fe: FormElement;
          if (field && field.formElement) {
            fe = field.formElement;
            field.formElement.question = formColumn.label;
            field.formElement.helpText = formColumn.help;
            field.formElement.isRequired = formColumn.required ?? false;
          } else {
            continue;
            fe = store.createRecord('appbuilder/form-element', {});
            await fe.save();
          }
          let newOptions: AnswerOption[] =
            formColumn.options?.map((o, i) => {
              let option = fe.answerOptions.find(
                (ao) => ao.optionText === o.label
              );
              if (option) {
                option.optionText = o.label;
                option.sequenceNumber = i + 1;
                option.formElementId = fe.id;
              } else {
                option = store.createRecord('appbuilder/answer-option', {
                  optionText: o.label,
                  sequenceNumber: i + 1,
                  formElementId: fe.id,
                });
              }
              return option;
            }) ?? [];

          fe.answerOptions = newOptions;
          promises.push(this.saveFormElement.perform(fe));
        }
      }
      await all(promises);
    }
  );

  @task
  saveFormElement = taskFor(async (fe: FormElement) => {
    await all(fe.answerOptions.map((o) => o.save()));
    await fe.save();
  });
}

export default class AppBuilder extends Service {
  @service declare store: Store;
  @service declare config: Config;

  registeredModels = new Set<string>();
  _cache = new Map<string, ABModelMeta[]>();

  getModelMeta(
    abLink: AppbuilderLink,
    abObjectName: string,
    modelsOnly: boolean = false
  ) {
    // convert the object name to Ember store convention
    let modelName = singularize(dasherize(abObjectName));

    // dynamically register the model if needed
    // NOTE: consider using store methods here (Store.modelFor etc.)
    let metaObjects = this._cache.get(abLink.id);
    if (!metaObjects || !metaObjects.find((m) => m.modelName === modelName)) {
      this._registerABProject(abLink);
    }

    // return the full model name (path) for the specified project and object
    return <ABModelMeta>(
      (<ABModelMeta[]>this._cache.get(abLink.id)).find(
        (m) => m.modelName === modelName
      )
    );
  }

  _registerABProject(abLink: AppbuilderLink) {
    // get the excel sheets (ie 'objects') for this project
    let abCase = abLink.ABCase;
    let excelSheets = abCase.get('formExcelSheets').toArray();

    // construct the full model path
    let baseModelPath: string = `appbuilder-links/${abLink.id}/ab`;
    // let baseModelPath: string = `appbuilder/${projectName}`;
    // let baseModelPath: string = `appbuilder/data/${projectName}`;

    // dynamically create store models for all objects in a case (project)
    for (var a = 0; a < excelSheets.length; a++) {
      let sheet = excelSheets[a];
      if (sheet) {
        this._registerABModel(
          abLink.id,
          abCase,
          sheet,
          baseModelPath,
          excelSheets
        );
      }
    }
  }

  _registerABModel(
    abLinkId: string,
    abCase: Case,
    modelExcelSheet: FormExcelSheet,
    baseModelPath: string,
    excelSheets: Array<FormExcelSheet>
  ) {
    // retrieve the name of the object from the excel sheet
    let objectName: string = modelExcelSheet.objSheetName;

    // create model name for store
    let modelName: string = singularize(dasherize(objectName));

    // full model path
    let modelPath: string = `${baseModelPath}/${modelName}`;

    // list of form elements (fields) for this object
    let formElements: Array<FormElement> = get(
      abCase,
      'form.formElements'
    )?.filterBy('objName', objectName);

    let modelAttributes: object = {};

    if (formElements) {
      // determine attributes for the dynamic model
      modelAttributes = this.getModelAttributes(
        modelExcelSheet,
        formElements,
        excelSheets,
        baseModelPath
      );
    }

    // create the model class
    //@ts-ignore
    let modelClass: Model = Model.extend(modelAttributes);

    // register the model in store
    getOwner(this.store).register(
      `adapter:${modelPath}`,
      AppbuilderLinkAdapter.extend()
    );
    getOwner(this.store).register(`model:${modelPath}`, modelClass);
    //@ts-ignore
    getOwner(this.store).register(
      `serializer:${modelPath}`,
      AppbuilderLinkSerializer.extend()
    );

    // create meta object which puts everything together
    let modelMeta = new ABModelMeta(
      modelName,
      modelPath,
      modelClass,
      modelExcelSheet,
      formElements
    );

    // this serves as a cache
    let cachedMetaObjects = this._cache.get(abLinkId);
    if (!cachedMetaObjects) {
      cachedMetaObjects = [];
      this._cache.set(abLinkId, cachedMetaObjects);
    }

    cachedMetaObjects.pushObject(modelMeta);
  }

  /** 
    Generate POJO with normalized Model attribute names and relationships for
    the specified object (excel sheet).
  */
  getModelAttributes(
    modelExcelSheet: Model,
    formElements: Array<FormElement>,
    excelSheets: Array<FormExcelSheet>,
    baseModelPath: string
  ) {
    // initiatilize return variable with model attributes
    let modelAttributes: any = {};

    // process all form elements and generate attributes
    formElements.forEach((field) => {
      switch (field.controlType) {
        case 'multipleSelect':
          modelAttributes[camelize(get(field, 'objFieldName'))] =
            attr('comma-delimited');
          break;
        case 'checkboxes':
          modelAttributes[camelize(get(field, 'objFieldName'))] = attr(
            'comma-delimited-object'
          );
          break;
        case 'number':
        case 'amount':
          modelAttributes[camelize(get(field, 'objFieldName'))] =
            attr('number');
          break;
        default:
          modelAttributes[camelize(get(field, 'objFieldName'))] =
            attr('string');
          break;
      }
    });

    // workaround for "ID" attribute, it must be removed from attributes as
    // Ember will complain otherwise
    if (modelAttributes['id']) {
      delete modelAttributes['id'];
    }

    // get all the relationships
    let relationships = excelSheets.filterBy(
      'parentObjSheetName',
      get(modelExcelSheet, 'objSheetName')
    );

    relationships.forEach((rel) => {
      modelAttributes[camelize(get(rel, `objSheetName`))] = hasMany(
        `${baseModelPath}/${singularize(get(rel, `objSheetName`))}`
      );
    });

    return modelAttributes;
  }

  getAbmModelName(modelName: AbmModel, abLinkId: string): string {
    let matches = modelName.match(/appbuilder-link\/(.+)/);
    let fullModelName = '';
    if (matches) {
      //appbuilder-link/baseModelName
      let baseModelName = matches[1];
      fullModelName = `appbuilder-links/${abLinkId}/abm/${baseModelName}`;
    } else {
      //appbuilder-link
      fullModelName = modelName;
    }
    if (!this.registeredModels.has(fullModelName)) {
      this.registerAbmModel(abLinkId, modelName, fullModelName);
    }

    return fullModelName;
  }

  registerAbmModel(
    abLinkId: string,
    modelName: AbmModel,
    fullModelName: string
  ) {
    this.registeredModels.add(fullModelName);
    let model = abmModels[modelName];
    getOwner(this.store).register(`adapter:${fullModelName}`, model.adapter);
    getOwner(this.store).register(
      `model:${fullModelName}`,
      model.model(abLinkId, this)
    );
    //@ts-ignore
    getOwner(this.store).register(
      `serializer:${fullModelName}`,
      model.serializer
    );
  }

  buildFilters(abLink: AppbuilderLink, query: Query) {
    let filters: { name: string; value: any }[] = abLink.ABFilters;

    filters?.forEach((filter) => {
      if (filter.name) {
        query.add(new Filter(filter.name, FilterOperators.EQUAL, filter.value));
      }
    });
  }
}

type AbmModel =
  | 'appbuilder-link/case'
  | 'appbuilder-link/project'
  | 'appbuilder-link/category'
  | 'appbuilder-link/project-template'
  | 'appbuilder-link/form'
  | 'appbuilder-link/currency'
  | 'appbuilder-link/search-view-layout'
  | 'appbuilder-link/form-excel-sheet'
  | 'appbuilder-link/object'
  | 'appbuilder-link/answer-option'
  | 'appbuilder-link/field-property'
  | 'appbuilder-link/form-element'
  | 'appbuilder-link/form-element-repeater'
  | 'appbuilder-link/form-element-format'
  | 'appbuilder-link/field';

const abmModels = {
  'appbuilder-link/case': {
    serializer: CaseSerializer.extend(),
    adapter: CaseAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      Case.createModel(abLinkId, appbuilder),
  },
  'appbuilder-link/project': {
    serializer: ProjectSerializer.extend(),
    adapter: ProjectAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      Project.createModel(abLinkId, appbuilder),
  },
  'appbuilder-link/category': {
    serializer: CategorySerializer.extend(),
    adapter: CategoryAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) => Category.extend(),
  },
  'appbuilder-link/project-template': {
    serializer: ProjectTemplateSerializer.extend(),
    adapter: TemplateAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) => Template.extend(),
  },
  'appbuilder-link/form': {
    serializer: FormSerializer.extend(),
    adapter: FormAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      FormModel.createModel(abLinkId, appbuilder),
  },
  'appbuilder-link/currency': {
    serializer: CurrencySerializer.extend(),
    adapter: CurrencyAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) => Currency.extend(),
  },
  'appbuilder-link/search-view-layout': {
    serializer: SearchViewLayoutSerializer.extend(),
    adapter: SearchViewLayoutAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      SearchViewLayout.extend(),
  },
  'appbuilder-link/form-excel-sheet': {
    serializer: FormExcelSheetSerializer.extend(),
    adapter: FormExcelSheetAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      FormExcelSheet.extend(),
  },
  'appbuilder-link/object': {
    serializer: ObjectSerializer.extend(),
    adapter: ObjectAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      ObjectModel.createModel(abLinkId, appbuilder),
  },
  'appbuilder-link/answer-option': {
    serializer: AnswerOptionSerializer.extend(),
    adapter: AnswerOptionAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) => AnswerOption.extend(),
  },
  'appbuilder-link/field-property': {
    serializer: FieldPropertySerializer.extend(),
    adapter: FieldPropertyAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) => FieldProperty.extend(),
  },
  'appbuilder-link/form-element': {
    serializer: FormElementSerializer.extend(),
    adapter: FormElementAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      FormElement.createModel(abLinkId, appbuilder),
  },
  'appbuilder-link/form-element-repeater': {
    serializer: FormElementRepeaterSerializer.extend(),
    adapter: FormElementRepeaterAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      FormElementRepeater.createModel(abLinkId, appbuilder),
  },
  'appbuilder-link/form-element-format': {
    serializer: FormElementFormatSerializer.extend(),
    adapter: FormElementFormatAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      FormElementFormat.extend(),
  },
  'appbuilder-link/field': {
    serializer: FieldSerializer.extend(),
    adapter: FieldAdapter.extend(),
    model: (abLinkId: string, appbuilder: AppBuilder) =>
      Field.createModel(abLinkId, appbuilder),
  },
};

declare module '@ember/service' {
  interface Registry {
    appbuilder: AppBuilder;
  }
}
