import { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import Abstract from './abstract';

//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import AclRoleAssignment from './acl-role-assignment';

const UserGroupValidations = buildValidations({
  name: validator('presence', true),
});

export default class UserGroup extends Abstract.extend(UserGroupValidations) {
  @attr('string') declare name: string;
  @hasMany('acl-role-assignment')
  declare aclRoleAssignments: AsyncHasMany<AclRoleAssignment>;
  @hasMany('acl-role-assignment', { inverse: null })
  declare instanceAclRoleAssignments: AsyncHasMany<AclRoleAssignment>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-group': UserGroup;
  }
}
