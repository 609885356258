import classic from 'ember-classic-decorator';
import { action, computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';
import RoleKeeper from 'cing-app/mixins/role-keeper';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import AppBuilderService from 'cing-app/pods/appbuilder/service';
import { ConnectionTypes, ABConnectionTypes } from 'cing-app/utils/lookups';

import {
  Query,
  QueryOperators,
  Filter,
  FilterOperators,
} from 'cing-app/utils/query-builder';

import {
  EEventType,
  EEventCalculationType,
} from 'cing-app/pods/components/ab-management/investors/event-detail/events';

const InvestorDataOptions = [
  {
    name: 'Call Amount/Investors',
    eventType: EEventType.CapitalCall,
    calculationType: EEventCalculationType.EquallyDivided,
  },
  {
    name: 'Call Amount/Ownership',
    eventType: EEventType.CapitalCall,
    calculationType: EEventCalculationType.ByOwnership,
  },
  {
    name: 'Call Commitment X %',
    eventType: EEventType.CapitalCall,
    calculationType: EEventCalculationType.PercentageOfCommitted,
  },
  {
    name: 'Dist Amount/Investors',
    eventType: EEventType.Distribution,
    calculationType: EEventCalculationType.DistributionSameAmount,
  },
  {
    name: 'Dist Amount/Ownership',
    eventType: EEventType.Distribution,
    calculationType: EEventCalculationType.EquallyDivided,
  },
  {
    name: 'Dist Commitment X %',
    eventType: EEventType.Distribution,
    calculationType: EEventCalculationType.ByOwnership,
  },
];

const SearchFields = [
  { name: 'Inv. Name', value: 'investor-name' },
  { name: 'Inv. Number', value: 'fund-investor-number' },
  { name: 'Inv. Fund Id', value: 'inv-fund-id' },
];

@classic
export default class Investors extends Component {
  InvestorDataOptions = InvestorDataOptions;
  SearchFields = SearchFields;

  @service('store')
  store!: Store;

  @service('ab-models')
  abModels;

  @service
  appbuilder!: AppBuilderService;

  @service('docker-item')
  docker!;

  confirmRemoval = null;
  included = 'capital-call';
  showTotals = true;
  selectedItems = null;
  searchQuery = null;

  investorModelMeta = null;

  abLink!: AppbuilderLink;

  @computed('investorModelMeta')
  get investorModelName() {
    return this.investorModelMeta?.modelPath;
  }

  init() {
    super.init(...arguments);

    this.set('searchField', SearchFields[0]);
    this.set('columns', this.getColumns());

    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    this.abLink = await this.projectTab.dataConnection;

    if (this.abLink) {
      await this.abLink.reload();
      // we need to reload the AB link to fetch related AB models
      this.investorModelMeta = this.appbuilder.getModelMeta(
        this.abLink,
        'investors',
        true
      );
    }

    // this.set('interestedPartyFilter', Ember.A());
    this.generateFilter();
  });

  getColumns() {
    let columns = [
      {
        propertyName: 'invFundId',
        sortedBy: 'inv-fund-id',
        component: 'project-detail/investors/column-show-detail',
        title: 'Inv. Fund Id',
      },
      {
        propertyName: 'fundInvestorNumber',
        sortedBy: 'inv-fund-id',
        component: 'project-detail/investors/column-show-detail',
        isHidden: true,
      },
      {
        propertyName: 'invType',
        sortedBy: 'inv-type',
        component: 'project-detail/investors/column-show-detail',
        title: 'Inv. Type',
        isHidden: true,
      },
      {
        propertyName: 'fundId',
        sortedBy: 'fund-id',
        component: 'project-detail/investors/column-show-detail',
        isHidden: true,
      },
      {
        propertyName: 'fundName',
        sortedBy: 'fund-name',
        component: 'project-detail/investors/column-show-detail',
        isHidden: true,
      },
      {
        propertyName: 'investorName',
        className: 'column-company-name',
        sortedBy: 'investor-name',
        component: 'project-detail/investors/column-show-detail',
        sortPrecedence: 0,
        sortDirection: 'asc',
      },
      {
        propertyName: 'invType',
        className: 'column-investor',
        sortedBy: 'inv-type',
        component: 'project-detail/investors/column-show-detail',
      },
      {
        propertyName: 'ownership',
        component: 'api-table/columns/percentage',
        className: 'column-ownership',
      },
      {
        propertyName: 'commitment',
        component: 'api-table/columns/amount',
        className: 'column-amount',
      },
      {
        propertyName: 'callsTotal',
        component: 'api-table/columns/amount',
        sortedBy: 'calls-total',
        className: 'column-amount',
      },
      /*
      {
          propertyName: 'callsTotalPerc',
          component: "api-table/columns/percentage",
          sortedBy: 'calls-total-perc',
          className: "column-amount"
      },
      {
          propertyName: 'callsPrior',
          component: "api-table/columns/amount",
          sortedBy: 'calls-prior',
          className: "column-amount",
          isHidden: true
      },
      {
          propertyName: 'callsPriorPerc',
          component: "api-table/columns/percentage",
          sortedBy: 'calls-prior-perc',
          className: "column-amount",
          isHidden: true                
      },
      */
      {
        propertyName: 'callCurrent',
        component: 'api-table/columns/amount',
        sortedBy: 'call-current',
        className: 'column-amount',
      },
      /*
      {
          propertyName: 'callCurrentPerc',
          component: "api-table/columns/percentage",
          sortedBy: 'call-current-perc',
          className: "column-amount"
      },
      */
      {
        propertyName: 'remainingTotal',
        component: 'api-table/columns/amount',
        sortedBy: 'remaining-total',
        className: 'column-amount',
      },
      /*
      {
          propertyName: 'remainingPrior',
          component: "api-table/columns/amount",
          sortedBy: 'remaining-prior',
          className: "column-amount",
          isHidden: true                
      },
      */
      {
        propertyName: 'distTotal',
        component: 'api-table/columns/amount',
        sortedBy: 'dist-total',
        className: 'column-amount',
      },
      /*
      {
          propertyName: 'distTotalPerc',
          component: "api-table/columns/percentage",
          sortedBy: 'dist-total-perc',
          className: "column-amount"
      },
      {
          propertyName: 'distPrior',
          component: "api-table/columns/amount",
          sortedBy: 'dist-prior',
          className: "column-amount",
          isHidden: true                
      },
      {
          propertyName: 'distPriorPerc',
          component: "api-table/columns/percentage",
          sortedBy: 'dist-prior-perc',
          className: "column-amount",
          isHidden: true                
      },
      */
      {
        propertyName: 'distCurrent',
        component: 'api-table/columns/amount',
        sortedBy: 'dist-current',
        className: 'column-amount',
      },
      /*
      {
          propertyName: 'distCurrentPerc',
          component: "api-table/columns/percentage",
          sortedBy: 'dist-current-perc',
          className: "column-amount"
      },
      */
      {
        propertyName: 'recallableCapital',
        component: 'api-table/columns/amount',
        sortedBy: 'recallable-capital',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'currentEqu',
        component: 'api-table/columns/amount',
        sortedBy: 'current-equ',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'priorEqu',
        component: 'api-table/columns/amount',
        sortedBy: 'prior-equ',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'totalEqu',
        component: 'api-table/columns/amount',
        sortedBy: 'total-equ',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'totalMgtFee',
        component: 'api-table/columns/amount',
        sortedBy: 'total-mgt-fee',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'priMgtFee',
        component: 'api-table/columns/amount',
        sortedBy: 'pri-mgt-fee',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'currMgtFee',
        component: 'api-table/columns/amount',
        sortedBy: 'curr-mgt-fee',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'equIntPaidCurrent',
        component: 'api-table/columns/amount',
        sortedBy: 'equ-int-paid-current',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'equIntPaidPri',
        component: 'api-table/columns/amount',
        sortedBy: 'equ-int-paid-pri',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'equIntPaid',
        component: 'api-table/columns/amount',
        sortedBy: 'equ-int-paid',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'equIntRecCurnt',
        component: 'api-table/columns/amount',
        sortedBy: 'equ-int-rec-curnt',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'equIntRecPri',
        component: 'api-table/columns/amount',
        sortedBy: 'equ-int-rec-pri',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: 'totalEquIntRec',
        component: 'api-table/columns/amount',
        sortedBy: 'total-equ-int-rec',
        className: 'column-amount',
        isHidden: true,
      },
      {
        propertyName: '',
        component: 'project-detail/investors/column-actions',
      },
    ];

    return columns;
  }

  generateFilter() {
    let searchQuery = this.searchQuery;

    let query = new Query();

    this.appbuilder.buildFilters(this.abLink, query);

    if (searchQuery) {
      query.add(
        new Filter(this.searchField.value, FilterOperators.LIKE, searchQuery)
      );
    }

    this.set('condition', query.serialize());
  }

  @action
  openPopup(component, appearance, context, header, footer) {
    this.get('docker').invokePopup(
      component,
      appearance,
      context,
      header,
      footer
    );
  }

  @action
  showInvestorDetail(record) {
    let appearance = {
      icon: '',
      title: `${this.project.name}`,
      size: 'large',
      custom: true,
    };

    let query = new Query();
    query.add(
      new Filter('fund-id', FilterOperators.EQUAL, this.project.fundId)
    );

    query.add(
      new Filter('investor-number', FilterOperators.EQUAL, record.invFundId)
    );

    this.store
      .query('investor', {
        condition: query.serialize(),
        page: {
          size: 1,
        },
      })
      .then((investors) => {
        this.docker.invokePopup('project-detail/investors/detail', appearance, {
          project: this.project,
          abModel: record,
          model: investors.firstObject,
        });
      });
  }

  @action
  editInvestor(record) {
    let appearance = {
      icon: '',
      title: `Edit investor ${record.investorName}`,
      //size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'ab-management/investors/investor-detail',
      appearance,
      {
        project: this.project,
        model: record,
      }
    );
  }

  @action
  createInvestor() {
    let appearance = {
      icon: '',
      title: `Add investor`,
      //size: 'large',
      custom: true,
    };

    let record = this.store.createRecord(this.investorModelName, {
      fundId: this.project.abFund,
    });

    let self = this;
    this.docker.invokePopup(
      'ab-management/investors/investor-detail',
      appearance,
      {
        project: this.project,
        model: record,
        onComplete: function () {
          self.set('reloadData', true);
        },
      }
    );
  }

  @action
  onTableUpdated(e) {
    console.log('SELECTED ITEMS: ', e.selectedItems);
    this.set('selectedItems', e.selectedItems);
  }

  @action
  search() {
    this.generateFilter();
  }

  @action
  calculateTotals(data) {
    let totals = {
      commitment: 0,
      remainingTotal: 0,
      callsTotal: 0,
      distTotal: 0,
      callCurrent: 0,
      callsPrior: 0,
      remainingPrior: 0,
      distPrior: 0,
      distCurrent: 0,
    };

    let totalKeys = Object.keys(totals);

    data.forEach((item) => {
      totalKeys.forEach((key) => {
        totals[key] += Math.round(item.get(key) * 100);
      });
    });

    totalKeys.forEach((key) => {
      totals[key] = totals[key] / 100;
    });

    this.set('totals', totals);
  }

  @action
  onSelectInvestorOperation(context) {
    let appearance = {
      icon: '',
      title: context.name,
      //size: 'large',
      custom: true,
    };

    let record = this.abStore.createRecord(
      this.abModels.getModelName(this.get('project.abProject'), 'event-detail'),
      {
        eventType: context.eventType,
        calculationType: context.calculationType,
        eventStatus: 'Payment Pending',
        noticeDate: moment().startOf('day').toDate(),
        dueDate: moment().add(14, 'days').endOf('day').toDate(),
      }
    );

    let self = this;

    this.docker.invokePopup(
      'ab-management/investors/event-detail',
      appearance,
      {
        model: record,
        project: this.get('project'),
        onComplete: function () {
          self.set('reloadData', true);
        },
      }
    );
  }

  @action
  showContacts(investor) {
    let appearance = {
      icon: '',
      title: 'Investor Contacts for ' + investor.companyName,
      //size: 'large',
      custom: true,
    };

    this.docker.invokePopup('project-detail/investors/contacts', appearance, {
      project: this.project,
      investor: investor,
    });
  }

  @action
  showDistributions(investor) {
    let appearance = {
      icon: '',
      title: 'Distributions for ' + investor.companyName,
      //size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'project-detail/investors/distributions',
      appearance,
      {
        investor: investor,
        project: this.project,
      }
    );
  }

  @action
  showCapitalCalls(investor) {
    let appearance = {
      icon: '',
      title: 'Capital Calls for ' + investor.companyName,
      //size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'project-detail/investors/capital-calls',
      appearance,
      {
        investor: investor,
        project: this.project,
      }
    );
  }
}
