//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
//@ts-ignore
import { fragment } from 'ember-data-model-fragments/attributes';
import ProjectTabSettingsFieldItem from './project-tab-settings-field-item';

export default class ProjectTabSettingsProjectFields extends Fragment {
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: true },
  })
  declare name: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare description: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare dateStarted: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare dateCompleted: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare company: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare rating: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare progressPercentage: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare progress: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: false, tile: false },
  })
  declare sortOrder: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare projectType: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare projectStatus: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare projectPriority: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare projectLead: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare projectManager: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare companyProfile: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare abProject: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare investorFundIdPrefix: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare dealCloudFund: ProjectTabSettingsFieldItem;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-tab-settings-project-fields': ProjectTabSettingsProjectFields;
  }
}
