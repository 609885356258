import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, set, action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import { IsProjectType } from 'cing-app/helpers/is-project-type';
import TableTheme from 'cing-app/pods/components/manage-interested-party/abstract-import/table-theme';
import Project from 'cing-app/models/project';

export default class FormComponent extends Component {
  @service store;
  @service('docker-item') docker;

  @tracked interestedParties;

  @tracked expanded = false;

  @tracked project = null;

  constructor() {
    super(...arguments);
    this.themeInstance = TableTheme.create();
    this.loadRelatedContacts.perform();
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    if (this.args.project) {
      this.project = this.args.project;
    } else {
      this.project = await this.args.event.project;
    }
  });

  @computed('args.project.projectType.name')
  get columns() {
    let columns = [
      {
        propertyName: 'person.fullName',
        title: 'Contact Name',
        className: 'column-person-name',
        sortedBy: 'person.lastName',
        component: 'project-detail/interested-parties/parties/column-attorney',
      },
      {
        propertyName: 'email.emailAddress',
        title: 'Email',
        //sortedBy: "email.emailAddress",
        disableSort: true,
      },
      {
        propertyName: 'interestedPartyType.name',
        title: 'Type',
        className: 'column-role',
      },
      {
        propertyName: 'interestedPartyRole.name',
        title: 'Role',
        className: 'column-role',
        component: 'project-detail/project-management/form/column-role',
      },
      {
        propertyName: 'viewerGroup.name',
        title: 'Access Role',
        className: 'column-role',
      },
    ];

    if (IsProjectType(['fund', get(this.project, 'projectType.name')])) {
      columns.unshift({
        component:
          'project-detail/interested-parties/parties/column-party-name',
        title: 'Company',
        propertyName: 'company.name',
        className: 'column-company-name',
        //isHidden: true,
      });
      columns.unshift({
        title: 'Investor',
        propertyName: 'investor.company.name',
        className: 'column-company-name',
        sortDirection: 'asc',
        sortPrecedence: 0,
        project: this.project,
        component: 'project-detail/project-management/form/column-investor',
      });
    } else {
      columns.unshift({
        component:
          'project-detail/interested-parties/parties/column-party-name',
        title: 'Affiliation',
        propertyName: 'company.name',
        className: 'column-company-name',
      });
    }

    return columns;
  }

  @action
  toggleExpanded() {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  @task
  saveRelatedContacts = taskFor(async (parties) => {
    let ips = await get(this.args.event, 'interestedParties');
    ips.pushObjects(parties);

    // await this.args.event.save();
    // await this.loadRelatedContacts.perform();
  });

  @task
  loadRelatedContacts = taskFor(async () => {
    this.interestedParties = await this.args.event
      .hasMany('interestedParties')
      .reload({
        adapterOptions: {
          //include: 'person,email,company,interested-party-type,interested-party-role,investor,viewer-group'
          //include: 'person'
        },
      });
  });

  @action
  uploadDocument() {}

  @action
  addRelatedContacts(parties) {
    this.saveRelatedContacts.perform(parties);
  }

  @action
  selectRelatedContacts() {
    let project = this.project;
    let appearance = {
      icon: '',
      title: `Add related to: ${project.name}`,
      size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      'project-detail/project-management/form/select-contacts',
      appearance,
      {
        project: project,
        parentInfo: 'Add related contacts',
        onSelection: this.addRelatedContacts,
      }
    );
  }
}
