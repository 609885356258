import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import { assign } from "@ember/polyfills";
import Field from '../field/component';

@classic
export default class Html extends Field {
  showFormValidations = null;

  @inject()
  config;

  defaultOptions = null;

  init() {
    super.init();
    let options = this.defaultOptions;

    if (!options) {
      options = assign({}, this.get('config.editorOptions'));
    }

    this.set('value', this.get('model.' + this.property));

    assign(options, {
      height: this.height || '100%',
    });

    this.set('editorOptions', options);
  }
}
