import { AsyncBelongsTo, AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import Model, { validator, buildValidations } from 'ember-cp-validations';
import AclRole from './acl-role';
import User from './user';
import UserGroup from './user-group';

const AclEntityRoleValidations = buildValidations({
  'resourceId': validator('presence', true),
  'resourceType': validator('presence', true),
  'principalId': validator('presence', true),
  'principalType': validator('presence', true),
  'roleId': validator('presence', true),
});

export default class AclRoleAssignment extends Abstract.extend(AclEntityRoleValidations) {
  @attr('string') declare resourceId: string;
  @attr('number') declare resourceType: number;
  @attr('string') declare principalId: string;
  @attr('string') declare principalType: string;

  //userId: DS.attr('string'),

  @attr('string') declare roleId: string;

  //@ts-ignore - mixin
  @belongsTo('acl-role') declare aclRole: AsyncBelongsTo<AclRole>;
  //@ts-ignore - mixin
  @hasMany('acl-permission') declare aclPermissions: AsyncHasMany<AclRole>;
  //@ts-ignore - mixin
  @belongsTo('user') declare user: AsyncBelongsTo<User>;
  //@ts-ignore - mixin
  @belongsTo('user-group') declare group: AsyncBelongsTo<UserGroup>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'acl-role-assignment': AclRoleAssignment;
  }
}
