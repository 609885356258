import Component from '@glimmer/component';
import ProjectTabSettingsContacts from 'cing-app/models/project-tab-settings-contacts';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
//@ts-ignore
import { cached } from 'tracked-toolbox';
import { DataSourceColumn, ApiDataSource } from 'smex-ui-table';
import {
  ExpressionOperators,
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import Store from '@ember-data/store';
import ProjectTabSettingsContactsField from 'cing-app/models/project-tab-settings-contacts-field';
import { action } from '@ember/object';
import moment from 'moment';
import { dasherize } from '@ember/string';

interface TableViewViewerContactArgs {
  projectTab: any;
  project: any;
}

export default class TableViewViewerContact extends Component<TableViewViewerContactArgs> {
  @service
  session: any;
  @service('store')
  store!: Store;

  @tracked
  query: any;
  @tracked
  advancedQuery: any;

  @tracked
  filterFormDefinition: any;
  @tracked
  advancedFilterFormDefinition: any;
  @tracked
  filter = {};
  @tracked
  advancedFilter = {};

  @tracked
  advancedFilterOpened = false;
  @tracked
  dataSource: ApiDataSource<any>;

  get contactSettings(): ProjectTabSettingsContacts {
    return this.args.projectTab.settings.customContacts;
  }

  @cached
  get columns() {
    let cols: DataSourceColumn<any>[] = [];

    let availableColumns = this.contactSettings.columns.sortBy('sortOrder');

    for (let column of availableColumns) {
      cols.pushObject(
        new DataSourceColumn({
          id: column.propertyName,
          label: column.label,
          getValue: (row: any) => {
            let val = column.isFromExtended
              ? row.extendedData[column.propertyName]
              : row[column.propertyName];
            return val;
          },
          hidden: !column.defaultVisible,
          valueComponent: 'table-text-column',
          minWidth: column.minWidth,
          maxWidth: column.maxWidth,
          sortingEnabled: column.sorting,
          headerClass: this.getAlignHeaderClass(column.textAlign),
          valueClass:
            this.getAlignClass(column.textAlign) +
            ' ' +
            (column.wrapText ? '' : 'text-nowrap'),
          options: {
            maxHeight: column.maxHeight,
            format: column.format,
          },
        })
      );
    }

    return cols;
  }

  constructor(owner: unknown, args: TableViewViewerContactArgs) {
    super(owner, args);

    this.dataSource = new ApiDataSource<any>(
      50,
      false,
      'interested-parties',
      this.session.authUser.email,
      this.loadDataTask,
      100,
      this.columns,
      null,
      {}
    );
    this.createFilterDefinition();
  }

  @task
  loadDataTask = taskFor(async (columns, pageSize, pageNumber, options) => {
    let sortColumn = columns.find((col: any) => {
      return col.sort;
    });

    let query: any = {
      include: 'project',
      page: {
        size: pageSize,
        number: pageNumber,
      },
    };

    let condition = Expressions.create({
      operator: ExpressionOperators.AND,
    });

    condition.add(
      Filter.create({
        //@ts-ignore
        name: 'project.id',
        operator: FilterOperators.EQUAL,
        value: this.args.project.id,
      })
    );

    if (this.query) {
      condition.add(this.query);
    }
    if (this.advancedQuery) {
      condition.add(this.advancedQuery);
    }

    if (sortColumn) {
      query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${
        sortColumn.id
      }`;
    }

    query.condition = condition.serialize();

    let rows = await this.store.query('interested-party', query);

    let result = rows.toArray();

    //@ts-ignore
    result.meta = {
      totalCount: rows.meta['total-count'],
    };

    return result;
  });

  createFilterDefinition() {
    let buttons = {
      customClass: 'text-right',
      key: 'fieldset',
      type: 'fieldset',
      label: '',
      input: false,
      tableView: false,
      components: [
        {
          label: 'Search',
          showValidations: false,
          customClass: 'd-inline-block',
          tableView: false,
          type: 'button',
          input: true,
          saveOnEnter: false,
          hideOnChildrenHidden: false,
          action: 'event',
          event: 'customSubmit',
        },
        {
          label: 'Clear',
          showValidations: false,
          customClass: 'd-inline-block',
          tableView: false,
          type: 'button',
          input: true,
          saveOnEnter: false,
          theme: 'secondary',
          action: 'event',
          event: 'customReset',
        },
      ],
    };

    let filterForm = {
      display: 'form',
      components: [],
    };
    let advancedFilterForm = {
      display: 'form',
      components: [
        {
          label: 'Text Field',
          placeholder: 'Search Properties',
          hideLabel: true,
          tableView: true,
          key: '__search',
          type: 'textfield',
          input: true,
        },
      ],
    };

    let availableColumns = this.contactSettings.columns.sortBy('sortOrder');

    for (let column of availableColumns) {
      if (column.advancedFilter) {
        let comp = this.createFilterInput(column);
        if (comp.validate) {
          comp.validate.required = false;
        }
        if (comp.type === 'email') {
          comp.type = 'textfield';
        }
        let newTab = {
          customConditional: `show = (!data.__search || '${column.label}'.toLowerCase().includes(data.__search.toLowerCase()))`,
          collapsible: true,
          key: 'panel',
          type: 'panel',
          label: 'Advanced Filter',
          input: false,
          customClass: 'filter-panel',
          tableView: false,
          collapsed: true,
          components: [],
        };
        newTab.components.push(comp);
        newTab.title = column.label;
        comp.hideLabel = true;
        advancedFilterForm.components.push(newTab);
      }

      if (column.simpleFilter) {
        // let comp = this.modelMeta?.convertControlTypeToSchema(metaField);
        // comp.label = availableProperty.label ?? metaField.property;
        // if (comp.validate) {
        //   comp.validate.required = false;
        // }
        // if (comp.type === 'email') {
        //   comp.type = 'textfield';
        // }
        let comp = this.createSimpleFilter(column);
        comp.customClass = 'd-inline-block filter-grid';
        //@ts-ignore
        filterForm.components.push(comp);
      }
    }

    if (filterForm.components.length) {
      filterForm.components.push(buttons);
      this.filterFormDefinition = filterForm;
    } else {
      this.filterFormDefinition = undefined;
    }

    if (advancedFilterForm.components.length > 1) {
      advancedFilterForm.components.push(buttons);
      this.advancedFilterFormDefinition = advancedFilterForm;
    } else {
      this.advancedFilterFormDefinition = undefined;
    }
  }

  createSimpleFilter(column: ProjectTabSettingsContactsField): any {
    switch (column.format?.style) {
      case 'date':
        return {
          label: column.label,
          // "format": fieldMeta.formElementFormat?.get('dateFormat')?.replace('ampm', 'a'),
          tableView: false,
          enableMinDateInput: false,
          datePicker: {
            disableWeekends: false,
            disableWeekdays: false,
          },
          enableMaxDateInput: false,
          key:
            (column.isFromExtended ? 'extended-data___' : '') +
            column.propertyName,
          type: 'datetime',
          input: true,
          widget: {
            type: 'calendar',
            displayInTimezone: 'viewer',
            locale: 'en',
            useLocaleSettings: false,
            allowInput: true,
            mode: 'single',
            enableTime: true,
            noCalendar: false,
            // "format": fieldMeta.formElementFormat?.get('dateFormat')?.replace('ampm', 'a'),
            hourIncrement: 1,
            minuteIncrement: 1,
            time_24hr: false,
            minDate: null,
            disableWeekends: false,
            disableWeekdays: false,
            maxDate: null,
          },
          validate: {
            required: false,
          },
        };
      case 'general':
        return {
          label: column.label,
          tableView: true,
          key:
            (column.isFromExtended ? 'extended-data___' : '') +
            column.propertyName,
          type: 'textfield',
          input: true,
          validate: {
            required: false,
          },
        };
      case 'fileSize':
      case 'percent':
      case 'decimal':
        return {
          label: column.label,
          mask: false,
          spellcheck: true,
          tableView: false,
          delimiter: column.format?.useGrouping ?? false,
          requireDecimal: false,
          inputFormat: 'plain',
          key:
            (column.isFromExtended ? 'extended-data___' : '') +
            column.propertyName,
          type: 'number',
          input: true,
          suffix: column.format?.style === 'percent' ? '%' : undefined,
          decimalLimit: column.format?.maximumFractionDigits,
          validate: {
            required: false,
          },
        };
      case 'currency':
        return {
          label: column.label,
          mask: false,
          spellcheck: true,
          tableView: false,
          currency: 'USD',
          inputFormat: 'plain',
          key:
            (column.isFromExtended ? 'extended-data___' : '') +
            column.propertyName,
          type: 'currency',
          input: true,
          delimiter: column.format?.useGrouping ?? false,
          decimalLimit: column.format?.maximumFractionDigits,
          validate: {
            required: false,
          },
        };
    }
  }

  @action
  filterChanged(isAdvanced: boolean, form: any, submission: any) {
    if (isAdvanced) {
      this.advancedFilter = submission;
      this.advancedQuery = this.createQuery(this.advancedFilter);
    } else {
      this.filter = submission;
      this.query = this.createQuery(this.filter);
    }

    this.dataSource?.refresh();
  }

  @action
  createQuery(submission: any) {
    let query = Expressions.create({
      operator: ExpressionOperators.AND,
    });

    let availableColumns = this.contactSettings.columns.sortBy('sortOrder');
    for (let _key in submission) {
      let isExtended = _key.includes('___');
      let key = isExtended ? _key.replace('___', '.') : dasherize(_key);
      if (key === 'submit' || key.startsWith('__')) {
        continue;
      }
      let value = submission[_key];

      let formatValue = (val: string) => {
        if (val === null || val === undefined) {
          return val;
        }
        let isDate =
          availableColumns.find((p) => p.propertyName === key)?.format
            ?.style === 'date';

        if (isDate) {
          return moment(val).utc().format('YYYY-MM-DDTHH:mm:ss');
        } else {
          return val;
        }
      };

      if (value !== null && value !== undefined && value !== '') {
        if (Array.isArray(value)) {
          for (let val of value) {
            if (
              val.value === null ||
              val.value === undefined ||
              val.value === ''
            ) {
              continue;
            }
            query.add(
              Filter.create({
                //@ts-ignore
                name: key,
                operator: (isExtended ? 'json' : '') + val.operator,
                //@ts-ignore
                value: formatValue(val.value),
              })
            );
          }
        } else {
          query.add(
            Filter.create({
              //@ts-ignore
              name: key,
              operator:
                (isExtended ? 'json' : '') +
                (typeof value === 'number'
                  ? FilterOperators.EQUAL
                  : FilterOperators.LIKE),
              //@ts-ignore
              value: formatValue(value),
            })
          );
        }
      }
    }

    //@ts-ignore
    if (query.expressions.length) {
      return query;
    } else {
      return undefined;
    }
  }

  @action
  clearFilter(/*form: any*/) {
    // form.emit('resetForm');
    this.filter = {};
    this.advancedFilter = {};
    this.query = undefined;
    this.advancedQuery = undefined;
    this.dataSource?.refresh();
  }

  createFilterInput(column: ProjectTabSettingsContactsField): any {
    if (['general', 'custom'].includes(column.format?.style ?? '')) {
      return {
        label: 'Data Grid',
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        clearOnHide: false,
        reorder: false,
        addAnotherPosition: 'bottom',
        layoutFixed: false,
        enableRowGroups: false,
        initEmpty: false,
        tableView: false,
        customClass: 'filter-grid',
        defaultValue: [{}],
        key:
          (column.isFromExtended ? 'extended-data___' : '') +
          column.propertyName,
        type: 'datagrid',
        input: true,
        components: [
          {
            hideLabel: true,
            clearOnHide: false,
            searchEnabled: false,
            label: 'Operator',
            widget: 'html5',
            tableView: true,
            data: {
              values: [
                {
                  label: 'Starts with',
                  value: FilterOperators.START_WITH,
                },
                {
                  label: 'Ends with',
                  value: FilterOperators.END_WITH,
                },
                {
                  label: 'Equal',
                  value: FilterOperators.EQUAL,
                },
                {
                  label: 'Not Equal',
                  value: FilterOperators.NOT_EQUAL,
                },
                {
                  label: 'Contains',
                  value: FilterOperators.LIKE,
                },
                {
                  label: "Doesn't contain",
                  value: FilterOperators.NOT_LIKE,
                },
              ],
            },
            selectThreshold: 0.3,
            validate: {
              onlyAvailableItems: false,
            },
            key: 'operator',
            type: 'select',
            indexeddb: {
              filter: {},
            },
            input: true,
            defaultValue: FilterOperators.LIKE,
          },
          {
            clearOnHide: false,
            hideLabel: true,
            label: column.label,
            tableView: true,
            key: 'value',
            type: 'textfield',
            input: true,
          },
        ],
      };
    } else if (
      ['currency', 'decimal', 'fileSize'].includes(column.format?.style ?? '')
    ) {
      return {
        label: 'Data Grid',
        clearOnHide: false,
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        reorder: false,
        addAnotherPosition: 'bottom',
        layoutFixed: false,
        enableRowGroups: false,
        initEmpty: false,
        tableView: false,
        customClass: 'filter-grid',
        defaultValue: [{}],
        key:
          (column.isFromExtended ? 'extended-data___' : '') +
          column.propertyName,
        type: 'datagrid',
        input: true,
        components: [
          {
            clearOnHide: false,
            searchEnabled: false,
            hideLabel: true,
            label: 'Operator',
            widget: 'html5',
            tableView: true,
            data: {
              values: [
                {
                  label: 'Greater than',
                  value: FilterOperators.GREATER_THAN_OR_EQUAL,
                },
                {
                  label: 'Less than',
                  value: FilterOperators.LESS_THAN_OR_EQUAL,
                },
                {
                  label: 'Equal',
                  value: FilterOperators.EQUAL,
                },
                {
                  label: 'Not Equal',
                  value: FilterOperators.NOT_EQUAL,
                },
              ],
            },
            selectThreshold: 0.3,
            validate: {
              onlyAvailableItems: false,
            },
            key: 'operator',
            type: 'select',
            indexeddb: {
              filter: {},
            },
            input: true,
            defaultValue: FilterOperators.EQUAL,
          },
          {
            clearOnHide: false,
            hideLabel: true,
            label: column.label,
            mask: false,
            spellcheck: true,
            tableView: false,
            delimiter: false,
            requireDecimal: false,
            inputFormat: 'plain',
            key: 'value',
            type: 'number',
            input: true,
          },
        ],
      };
    } else if (['date'].includes(column.format?.style ?? '')) {
      return {
        label: 'Data Grid',
        clearOnHide: false,
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        reorder: false,
        addAnotherPosition: 'bottom',
        layoutFixed: false,
        enableRowGroups: false,
        initEmpty: false,
        tableView: false,
        customClass: 'filter-grid',
        defaultValue: [{}],
        key:
          (column.isFromExtended ? 'extended-data___' : '') +
          column.propertyName,
        type: 'datagrid',
        input: true,
        components: [
          {
            clearOnHide: false,
            searchEnabled: false,
            hideLabel: true,
            label: 'Operator',
            widget: 'html5',
            tableView: true,
            data: {
              values: [
                {
                  label: 'Greater than',
                  value: FilterOperators.GREATER_THAN_OR_EQUAL,
                },
                {
                  label: 'Less than',
                  value: FilterOperators.LESS_THAN_OR_EQUAL,
                },
                {
                  label: 'Equal',
                  value: FilterOperators.EQUAL,
                },
                {
                  label: 'Not Equal',
                  value: FilterOperators.NOT_EQUAL,
                },
              ],
            },
            selectThreshold: 0.3,
            validate: {
              onlyAvailableItems: false,
            },
            key: 'operator',
            type: 'select',
            indexeddb: {
              filter: {},
            },
            input: true,
            defaultValue: FilterOperators.GREATER_THAN_OR_EQUAL,
          },
          {
            clearOnHide: false,
            hideLabel: true,
            label: column.label,
            // "format": field.formElementFormat?.get('dateFormat')?.replace('ampm', 'a'),
            mask: false,
            spellcheck: true,
            tableView: false,
            enableMinDateInput: false,
            datePicker: {
              disableWeekends: false,
              disableWeekdays: false,
            },
            enableMaxDateInput: false,
            key: 'value',
            type: 'datetime',
            input: true,
            widget: {
              type: 'calendar',
              displayInTimezone: 'viewer',
              locale: 'en',
              useLocaleSettings: false,
              allowInput: true,
              mode: 'single',
              enableTime: true,
              noCalendar: false,
              // "format": field.formElementFormat?.get('dateFormat')?.replace('ampm', 'a'),
              hourIncrement: 1,
              minuteIncrement: 1,
              time_24hr: false,
              minDate: null,
              disableWeekends: false,
              disableWeekdays: false,
              maxDate: null,
            },
          },
        ],
      };
    } else {
      // let comp = this.modelMeta?.convertControlTypeToSchema(field);
      // return comp;
      throw new Error('not implemented');
    }
  }

  getAlignClass(textAlign: 'L' | 'C' | 'R') {
    switch (textAlign) {
      default:
      case 'L':
        return 'text-left';
      case 'C':
        return 'text-center';
      case 'R':
        return 'text-right';
    }
  }
  getAlignHeaderClass(textAlign: 'L' | 'C' | 'R') {
    switch (textAlign) {
      default:
      case 'L':
        return 'justify-content-start';
      case 'C':
        return 'justify-content-center';
      case 'R':
        return 'justify-content-end';
    }
  }
}
