import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

import User from 'cing-app/models/user';
import UserGroup from 'cing-app/models/user-group';

const UserGroupValidations = buildValidations({
  'name': validator('presence', true),
});

export default class AclUserInGroup extends Abstract.extend(UserGroupValidations) {
  @attr('string') declare userId: string;
  @attr('string') declare userGroupId: string;
  //@ts-ignore - mixin
  @belongsTo('user') declare user: AsyncBelongsTo<User>;
  //@ts-ignore - mixin
  @belongsTo('user-group') declare userGroup: AsyncBelongsTo<UserGroup>;
}


// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'acl-user-in-group': AclUserInGroup;
  }
}
