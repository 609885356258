import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import { computed } from '@ember/object';

const FolderValidations = buildValidations({
  name: [
    validator('presence', true),
    validator('length', {
      min: 2,
      max: 255,
    }),
    validator('format', {
      regex: /^[^<>:"\/\\|?*\x00-\x1F]+$/g,
      message: 'Folder name contains invalid characters (<>:"/|?*)',
    }),
  ],
});

export default Model.extend(FolderValidations, {
  name: attr('string'),
  parentId: attr('number'),
  displayString: attr('string'),
  path: attr('string'),
  pathIds: attr('string'),
  numberOfDirectSubFiles: attr('number'),
  numberOfDirectSubFolders: attr('number'),
});
