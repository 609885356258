import Model, { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import AclRoleAssignment from './acl-role-assignment';

//export default Model.extend(HasManyQuery.ModelMixin, {
export default class Abstract extends Model {
  @attr('date') declare createTime?: Date;
  @attr('date') declare modifyTime?: Date;
  @attr('jsonb') declare extendedData?: object;
  //@ts-ignore - mixin
  @hasMany('acl-role-assignment') declare aclRoleAssignments: AsyncHasMany<AclRoleAssignment>;
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'abstract': Abstract;
  }
}