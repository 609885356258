import Component from '@glimmer/component';
import { computed, get } from '@ember/object';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency';
import Store from '@ember-data/store';
import {
  ConnectionTypes,
  SmartRoomContentViewModes,
} from 'cing-app/utils/lookups';
import { inject as service } from '@ember/service';
import {
  Query,
  FilterOperators,
  Filter,
  QueryOperators,
} from 'cing-app/utils/query-builder';
import { tracked } from '@glimmer/tracking';
import { ApiDataSource, DataSourceColumn, Paged } from 'smex-ui-table';
import { action } from '@ember/object';
import SessionService from 'ember-smartidentity-auth/services/session';

interface SmartRoomArgs {}

export default class SmartRooms extends Component<SmartRoomArgs> {
  @service('store') declare store: Store;
  @service('session') declare session: typeof SessionService;
  @service('docker-item') docker: any;

  @tracked connections!: AppbuilderLink[];

  @tracked dataSource!: ApiDataSource<AppbuilderLink>;

  @tracked removeConnection?: AppbuilderLink | null;

  get styleNamespace() {
    return podNames['manage-connections/smart-rooms'];
  }

  constructor(owner: any, args: SmartRoomArgs) {
    super(owner, args);

    this.dataSource = new ApiDataSource<AppbuilderLink>(
      31,
      false,
      'SmartRooms',
      this.session.authUser.email,
      this.loadConnections,
      100,
      this.columns,
      null,
      {}
    );
  }

  get columns() {
    let columns = [
      new DataSourceColumn<AppbuilderLink>({
        id: 'id',
        label: 'GUID',
        getValue: (row) => row.id,
        hidden: true,
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        options: { onClick: this.showDetail },
      }),

      new DataSourceColumn<AppbuilderLink>({
        id: 'sr-site-id',
        label: 'Site ID',
        getValue: (row) => row.SRSiteId,
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        options: { onClick: this.showDetail },
      }),
      new DataSourceColumn<AppbuilderLink>({
        id: 'sr-slug',
        label: 'Slug',
        getValue: (row) => row.SRSlug,
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        sort: 'desc',
        options: { onClick: this.showDetail },
      }),
      new DataSourceColumn<AppbuilderLink>({
        id: 'sr-name',
        label: 'Name',
        getValue: (row) => row.SRName,
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        options: { onClick: this.showDetail },
      }),
      new DataSourceColumn<AppbuilderLink>({
        id: 'sr-folder-name',
        label: 'Folder',
        getValue: (row) => row.SRFolderName,
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        options: { onClick: this.showDetail },
      }),
      new DataSourceColumn<AppbuilderLink>({
        id: 'project.name',
        label: 'Project',
        getValue: (row) => get(row, 'project.name'),
        valueComponent: 'table-link-column',
        minWidth: 180,
        sortingEnabled: true,
        options: { onClick: this.showProjectDetail },
      }),
      new DataSourceColumn<AppbuilderLink>({
        id: 'create-time',
        label: 'Created',
        getValue: (row) => get(row, 'createTime'),
        sortingEnabled: true,
        hidden: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<AppbuilderLink>({
        id: 'modify-time',
        label: 'Modified',
        getValue: (row) => get(row, 'modifyTime'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
    ];

    return columns;
  }

  @task
  loadConnections = taskFor(
    async (
      columns: DataSourceColumn<AppbuilderLink>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let condition = new Query(QueryOperators.OR);

      condition.add(
        new Filter('type', FilterOperators.EQUAL, ConnectionTypes.SMARTROOM)
      );
      condition.add(
        new Query([
          new Filter('type', FilterOperators.EQUAL, ConnectionTypes.APPBUILDER),
          new Filter('SRSiteId', FilterOperators.NOT_NULL),
        ])
      );

      let query: any = {
        condition: condition.serialize(),
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
        include: 'project',
      };

      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let connections = await this.store.query('appbuilder-link', query);

      let pConnections = <Paged<AppbuilderLink>>connections.toArray();

      pConnections.meta = {
        totalCount: connections.meta['total-count'],
      };

      return pConnections;
    }
  );

  @action
  edit(row: AppbuilderLink) {
    let self = this;
    let appearance = {
      icon: '',
      title: `Edit connection: ${row.id}`,
      size: 'medium',
      custom: true,
    };
    this.docker.invokePopup('manage-connections/add-link', appearance, {
      model: row,
    });
  }

  @task
  removeConnectionTask = taskFor(async (row) => {
    await row.destroyRecord();
    this.dataSource.refresh();
    this.removeConnection = null;
  });

  @action
  doRemoveConnection(row: AppbuilderLink) {
    this.removeConnectionTask.perform(row);
  }

  @action
  showDetail(connection: AppbuilderLink) {
    this.edit(connection);
  }

  @action
  showProjectDetail(connection) {}

  @action
  addLink() {
    let appearance = {
      icon: '',
      title: `Add connection:`,
      size: 'medium',
      custom: true,
    };
    this.docker.invokePopup('manage-connections/add-link', appearance, {
      model: this.store.createRecord('appbuilder-link', {
        type: ConnectionTypes.SMARTROOM,
        SRViewMode: SmartRoomContentViewModes.files,
      }),
    });
  }
}
