import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
//@ts-ignore
import DockerItemService from 'cing-app/pods/docker-item/service';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
  CaseFilters,
  CaseFilterKeys//@ts-ignore
} from "cing-app/mixins/filter-builder";
import { tracked } from '@glimmer/tracking';
import ENV from "../../../../config/environment";

interface ProjectsArgs {
  company: any;
  projectsCount: any;
}

export default class Projects extends Component<ProjectsArgs>{
  @service() store!: Store;
  @service('docker-item') docker!: DockerItemService;
  @service() config: any;

  @tracked
  collapsed: boolean = true;

  @tracked
  columns: any;

  @tracked
  projectsCount: any = this.args.projectsCount;

  @tracked
  condition: any = '';

  include = 'project-type';

  constructor(owner: any, args: ProjectsArgs) {
    super(owner, args);
    this.columns = this.getColumns();
    this.generateFilter();
  }

  getColumns() {
    let columns = [
      {
        propertyName: 'dateStarted',
        component: "api-table/columns/date",
        title: "Started",
        className: "column-date",
        sortDirection: 'desc',
        sortPrecedence: 0,
        dateFormat: ENV.APP.shortDateOnlyFormat,
      },
      {
        propertyName: 'name',
        className: "column-name",
        component: "api-table/columns/project"
      },
      {
        propertyName: 'projectType.name',
        title: "Type",
        isHidden: false,
        className: "column-number",
      },
    ];

    return columns;
  }

  generateFilter() {
    let expr = Expressions.create();

    expr.add(Filter.create({
      name: 'interested-parties.company-id',
      operator: FilterOperators.EQUAL,
      value: this.args.company.id,
    }));
    this.condition = expr.serialize();
  }

  @action
  toggle() {
    this.collapsed = !this.collapsed;
  }

  @action
  showCaseDetail(record: any) {
    let appearance = {
      icon: '',
      title: `Case: ${record.get('name')} / #${record.get('displayNumber')}`,
      size: 'large'
    };
    this.docker.invokePopup('case-detail', appearance, { recordId: record.get('id') });
  }
}
