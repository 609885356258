import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import FaqCategory from 'cing-app/models/faq-category';
import { tracked } from '@glimmer/tracking';
import Faq from 'cing-app/models/faq';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

interface FaqEditArgs {
  context: any;
  onClose: () => void;
}

export default class FaqEdit extends Component<FaqEditArgs> {
  @service store!: Store;

  @tracked
  model: Faq;

  @tracked
  categories: FaqCategory[] | null = null;

  @tracked
  originalCategoryId: string | null;

  @tracked
  originalFaqOrder: number | null | undefined;

  @tracked
  lastOrderNumber: number | null = null;

  // get withoutSaveFaq(): boolean {
  //   if (this.args.context.onSave) return true;

  //   return false;
  // }

  constructor(owner: any, args: FaqEditArgs) {
    super(owner, args);
    this.model = this.args.context.model;
    this.originalCategoryId = this.model.categoryId || null;
    this.originalFaqOrder = this.model.order ?? null;
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    let categories = await this.store.query('faq-category', {
      sort: 'order',
      page: { size: 1000 },
    });
    this.categories = categories.toArray();
    console.log(
      `ORIG category: ${this.originalCategoryId}, order: ${this.originalFaqOrder}`
    );
  });

  @task
  saveTask = taskFor(async (model) => {
    let newOrderNumber: null | number = null;
    if (model.categoryId !== this.originalCategoryId) {
      // change order if the original category changed
      newOrderNumber = await this.getLastOrderNumber.perform(model.categoryId);
    }

    if (typeof model.order !== 'number' && typeof newOrderNumber !== 'number') {
      // create order if it does not exist
      newOrderNumber = await this.getLastOrderNumber.perform(model.categoryId);
    }

    if (typeof newOrderNumber === 'number') {
      model.order = newOrderNumber;
    }

    console.log(`SAVE: category: ${model.categoryId}, order: ${model.order}`);
    await model.save();
  });

  @task
  removeTask = taskFor(async (model) => {
    await model.destroyRecord();

    if (this.args.context.onRemove) {
      this.args.context.onRemove();
    }
    this.args.onClose();
  });

  @task
  getLastOrderNumber = taskFor(async (catId: number | null) => {
    if (!catId) {
      return 0;
    }

    let faqFilter = Expressions.create();
    let projectId = this.model.projectId;
    let pageId = this.model.portalPageId;
    let orderNumber: null | number | undefined = 0;

    if (projectId) {
      faqFilter.add(
        Filter.create({
          name: 'project-id',
          operator: FilterOperators.EQUAL,
          value: projectId,
        })
      );
    } else if (pageId) {
      faqFilter.add(
        Filter.create({
          name: 'portal-page-id',
          operator: FilterOperators.EQUAL,
          value: pageId,
        })
      );
    }

    faqFilter.add(
      Filter.create({
        name: 'category-id',
        operator: FilterOperators.EQUAL,
        value: catId,
      })
    );

    let faqs = await this.store.query('faq', {
      condition: faqFilter.serialize(),
      page: { size: 1 },
      sort: '-order',
    });

    let lastFaq = faqs?.get('lastObject');

    if (lastFaq) {
      orderNumber = typeof lastFaq.order === 'number' ? lastFaq.order + 1 : 0;
    }
    console.log(`newOrderNumber: ${orderNumber}`);
    return orderNumber || 0;
  });

  @action
  save(model: FaqCategory) {
    if (model.validations.isValid) {
      this.saveTask.perform(model);
    }
  }

  @action
  remove(model: FaqCategory) {
    this.removeTask.perform(model);
  }

  close() {
    if (this.args.context.onClose) {
      this.args.context.onClose();
    }
    this.args.onClose();
  }

  @action
  onClose() {
    if (this.model.hasDirtyAttributes) {
      this.model.rollbackAttributes();
    }
    this.close();
  }
}
