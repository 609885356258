import RESTAdapter from '@ember-data/adapter/rest';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class ApplicationRemoteAdapter extends RESTAdapter {
  @service() config: any;
  @alias('config.APP.api.remoteHost') host: any;
  @alias('config.APP.api.namespace') namespace: any;

  // methodForRequest({ requestType }) {
  //     if (requestType === 'updateRecord') {
  //         return 'PUT';
  //     }
  //
  //     return this._super(...arguments);
  // },
}
// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'application-remote': ApplicationRemoteAdapter;
  }
}
