import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  AclPermissionType,
} from 'cing-app/utils/lookups';

import {
  Expressions,
  Filter,
  FilterOperators,
} from "cing-app/mixins/filter-builder";
import classic from 'ember-classic-decorator';
import Store from '@ember-data/store';
import UserInstancesService from 'cing-app/pods/user-instances/service';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import AclRoleAssignment from 'cing-app/models/acl-role-assignment';
import AclRole from 'cing-app/models/acl-role';


interface ManageUserDetailArgs {
  header: any;
  footer: any;
  context: any;
  onClose: () => void;
}
@classic
export default class ManageUserDetail extends Component<ManageUserDetailArgs> {
  // @alias('context.model') model;
  @service store!: Store;
  @service userInstances!: UserInstancesService;

  @tracked
  allAclRoles: AclRole[] = [];

  @tracked
  aclRoles!: AclRole[];

  get model() {
    return this.args.context.model;
  }

  constructor(owner: any, args: ManageUserDetailArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    let query = Expressions.create();
    query.add(Filter.create({
      //@ts-ignore
      name: 'acl-role-permissions.acl-permission.permission-type-id',
      operator: FilterOperators.IN,
      //@ts-ignore
      value: [AclPermissionType.Admin, AclPermissionType.PortalPageRead]
    }));

    let aclRoles = await this.store.query('acl-role', {
      condition: query.serialize(),
      page: {
        size: 1000
      },
      sort: 'name'
    });

    this.allAclRoles = aclRoles.toArray();

    await this.updateAvailableAclRoles.perform();
  });

  get disableInteraction() {
    return this.removeRoleAssignmentTask.isRunning || this.addRoleAssignmentTask.isRunning || this.saveGroupTask.isRunning;
  }

  @task
  updateAvailableAclRoles = taskFor(async () => {
    let filteredAclRoles = this.allAclRoles.slice();
    //let userAclRoles = (yield this.model.hasMany('aclUserRoles').reload()).toArray();
    let roleAssignments = (await this.model.hasMany('instanceAclRoleAssignments').reload()).toArray();

    for (var a = 0; a < roleAssignments.length; a++) {
      let aclRoleAssignment = roleAssignments[a];

      filteredAclRoles.removeObject(await get(aclRoleAssignment, 'aclRole'));
    }

    this.aclRoles = filteredAclRoles;
  });

  @task
  addRoleAssignmentTask = taskFor(async (aclRole) => {
    let group = this.model;

    let roleAssignment = this.store.createRecord("acl-role-assignment", {
      principalType: 2,
      principalId: group.id,
      roleId: aclRole.id,
      //@ts-ignore
      resourceId: this.userInstances.current.instanceId,
      resourceType: 255,
    });

    await roleAssignment.save();
    await this.updateAvailableAclRoles.perform();

    if (this.args.context.onUpdate) {
      this.args.context.onUpdate();
    }
  });

  @task
  removeRoleAssignmentTask = taskFor(async (aclUserRole) => {
    await aclUserRole.destroyRecord();

    await this.updateAvailableAclRoles.perform();
  });

  @task
  saveGroupTask = taskFor(async () => {
    await this.model.save();
  });

  @action
  removeRoleAssignment(roleAssignment: AclRoleAssignment) {
    if (this.disableInteraction) {
      return;
    }

    this.removeRoleAssignmentTask.perform(roleAssignment);
  }

  @action
  addRoleAssignment(aclRole: AclRole) {
    if (this.disableInteraction) {
      return;
    }

    this.addRoleAssignmentTask.perform(aclRole);
  }

  @action
  save() {
    if (this.disableInteraction) {
      return;
    }

    this.saveGroupTask.perform();
  }

  @action
  cancel() {
    this.model.rollbackAttributes();

    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}