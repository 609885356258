import Component from '@glimmer/component';
import { computed } from '@ember/object';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import { ConnectionTypes } from 'cing-app/utils/lookups';
import AppbuilderLink from 'cing-app/models/appbuilder-link';

interface ManageConnectionsArgs {
  footer: any;
}

export default class ManageUsers extends Component<ManageConnectionsArgs> {
  get styleNamespace() {
    return podNames['manage-connections'];
  }
}
