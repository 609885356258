import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import FilterBuilder from 'cing-app/mixins/filter-builder';

@classic
export default class EventOverview extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  @action
  showJudgeDetail(judge) {
    const appearance = { label: 'Judge detail', icon: '', title: 'Judge detail' };
    const context = { judgeId: judge.get('id') };
    this.get('docker').invokePopup('judge-detail', appearance, context);
  }
}
