import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { get } from '@ember/object';
import { dasherize } from '@ember/string';
import { DailyStat } from '../../component';
import { inject as service } from '@ember/service';
import AppBuilderService, {
  ABModelMeta,
} from 'cing-app/pods/appbuilder/service';
import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';
import AppBuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import _SessionService from 'cing-app/pods/session/service';
import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

interface DocumentActivityArgs {
  appbuilderLink: AppBuilderLink;
  dailyStat: DailyStat;
}

export interface DocumentActivity {
  username?: string;
  activityDate?: string;
  dateRetrieved?: string;
  dateExported?: number;
  displayString?: number;
  fileName?: number;
  path?: number;
  activityType?: number;
  userCompany?: number;
  categoryName?: number;
  deleted?: number;
  oldPath?: number;
  roomCompany?: number;
  siteId?: number;
}

export default class DataHistories extends Component<DocumentActivityArgs> {
  @service('appbuilder')
  appbuilder!: AppBuilderService;

  @service
  store!: Store;

  @service
  session!: _SessionService;

  //Summary
  @tracked
  summaryCollapsed: boolean = false;

  modelMeta: ABModelMeta;
  dataSource!: ApiDataSource<DocumentActivity>;

  @tracked
  selectedItems: DocumentActivity[];

  //Common
  pageSize = 20;

  get columnsUI() {
    let columns: DataSourceColumn<DocumentActivity>[] = [
      new DataSourceColumn<DocumentActivity>({
        id: 'username',
        label: 'Username',
        getValue: (row) => get(row, 'username'),
        sortingEnabled: true,
        minWidth: 150,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'activity-date',
        label: 'Activity Date',
        getValue: (row) => get(row, 'activityDate'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'date-retrieved',
        label: 'Date Retrieved',
        getValue: (row) => get(row, 'dateRetrieved'),
        sortingEnabled: true,
        minWidth: 120,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'date-exported',
        label: 'Date Exported',
        getValue: (row) => get(row, 'dateExported'),
        sortingEnabled: true,
        minWidth: 120,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'display-string',
        label: 'Display String',
        getValue: (row) => get(row, 'displayString'),
        sortingEnabled: true,
        minWidth: 110,
        headerClass: 'justify-content-center',
        valueClass: 'text-center',
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'file-name',
        label: 'FileName',
        getValue: (row) => get(row, 'fileName'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'path',
        label: 'Path',
        getValue: (row) => get(row, 'path'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'activity-type',
        label: 'Activity Type',
        getValue: (row) => get(row, 'activityType'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'user-company',
        label: 'User Company',
        getValue: (row) => get(row, 'userCompany'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'category-name',
        label: 'Category Name',
        getValue: (row) => get(row, 'categoryName'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'deleted',
        label: 'Deleted',
        getValue: (row) => get(row, 'deleted'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'old-path',
        label: 'Old Path',
        getValue: (row) => get(row, 'oldPath'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DocumentActivity>({
        id: 'room-company',
        label: 'Room Company',
        getValue: (row) => get(row, 'roomCompany'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
    ];
    return columns;
  }

  constructor(owner: any, args: any) {
    super(owner, args);

    this.modelMeta = this.appbuilder.getModelMeta(
      this.args.appbuilderLink,
      'document-activities'
    );

    this.selectedItems = [];

    this.dataSource = new ApiDataSource<DocumentActivity>(
      25,
      false,
      this.modelMeta.modelPath,
      this.session.authUser.email,
      this.loadDataTask,
      this.pageSize,
      this.columnsUI,
      this.selectedItems,
      {}
    );

    this.dataSource.selectionEnabled = false;
  }

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<DocumentActivity>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let filterQuery = Expressions.create();
      filterQuery.add(
        Filter.create({
          name: 'site-id',
          operator: FilterOperators.EQUAL,
          value: this.args.dailyStat.siteId,
        })
      );

      let query: any = {
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
        condition: filterQuery.serialize(),
      };

      if (sortColumn) {
        let sortName =
          sortColumn.options?.sortValue || dasherize(sortColumn.id);
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let documentActivityList = await this.store.query(
        this.modelMeta.modelPath,
        query
      );

      let dataDocumentActivities = <Paged<DocumentActivity>>(
        documentActivityList.toArray()
      );
      dataDocumentActivities.meta = {
        totalCount: documentActivityList.meta['total-count'],
      };

      return dataDocumentActivities;
    }
  );
}
