define("cing-app/helpers/is-even", ["exports", "smex-ui-table/helpers/is-even"], function (_exports, _isEven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isEven.default;
    }
  });
  Object.defineProperty(_exports, "isEven", {
    enumerable: true,
    get: function () {
      return _isEven.isEven;
    }
  });
});
