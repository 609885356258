import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';

export default class PortalPageSettingsItem extends Fragment {
  @attr('string', { defaultValue: '' }) declare title?: string;
  @attr('string', { defaultValue: '' }) declare key?: string;
  @attr('string', { defaultValue: '' }) declare value?: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
export default interface ModelRegistry {
  'portal-page-settings-item': PortalPageSettingsItem;
}
