import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import SmartroomMyInfoModel from 'smex-ui-sr-models/addon/models/smartroom/my-info';
import { inject as service } from '@ember/service';
import { Models, MyInfoResource } from 'smex-ui-sr-models';
import { cached } from 'tracked-toolbox';

interface SmartroomTreeHeaderArgs {
  siteId: string
}

export default class SmartroomTreeHeader extends Component<SmartroomTreeHeaderArgs> {
  @service('models') models!: Models;

  @cached
  get myInfoResource() {
    return new MyInfoResource(this.models, this.args.siteId);
  }
}
