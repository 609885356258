import Model, { attr } from '@ember-data/model';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

const SendAlertTemplateValidations = buildValidations({
  subject: [validator('presence', true)],
  recipients: [validator('presence', true)],
  templateData: [validator('presence', true)]
});

//@ts-ignore
export default class SendAlert extends Model.extend(SendAlertTemplateValidations) {
  @attr('string') subject!: string;
  @attr('string') templateData?: string;
  @attr('string') fromAddress?: string;
  @attr() alertTemplate: any;
  @attr() recipients: any;
  @attr() attachments: any;
  @attr() values: any;
  @attr('boolean') trackDelivery?: boolean;
  @attr('boolean') trackOpens?: boolean;
  @attr('boolean') trackBounces?: boolean;
  @attr('boolean') trackClicks?: boolean;
  @attr('boolean') createUsers?: boolean;
  @attr('string') loginRedirectUrl?: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'send-alert': SendAlert;
  }
}