import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import fetch from 'fetch';

@classic
export default class FileViewer extends Component {
  @inject()
  session;

  @inject()
  serverVariables;

  @inject()
  store;

  @inject()
  cookies;

  @inject('docker-item')
  docker;

  init() {
    super.init(...arguments);

    this.initTask.perform();
  }

  @task(function* () {
    let file = this.get('context.file');
    let fileId = this.get('context.fileId');
    let siteId = this.get('context.siteId');

    if (!file && fileId) {
      try {
        file = yield this.store.queryRecord('smartroom/file', {
          id: fileId,
          siteId: siteId
        })
      } catch (e) {
        this.set('fileNotFound', true);
        return;
      }
    }

    let host = this.get('serverVariables.smartroomApiUrl');
    let html5ViewerUrl = this.get('serverVariables.smartroomViewerUrl');
    let namespace = this.get('serverVariables.smartroomApiNamespace');

    var queryURL = `${host}/${namespace}/sites/${siteId}/files/${file.id}/view-token`;

    let response = yield (yield fetch(queryURL, {
      headers: {
        'Authorization': `Bearer ${this.get('session.data.authenticated.access_token')}`
      }
    })).json();
    let token = response.token;

    let fileUrl = `${html5ViewerUrl}?viewtoken=${token}&siteid=${siteId}&folderid=${file.get('folderId')}&fileid=${file.get('id')}&versionno=${file.get('version')}`;

    this.set('fileUrl', fileUrl);
  })
  initTask;
}
