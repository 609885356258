import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, set, action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { EventType } from 'cing-app/utils/lookups';

export default class FormComponent extends Component {
  @service store;

  @tracked
  confirmRemove = false;

  @tracked
  showFormErrors = false;

  constructor() {
    super(...arguments);

    let typeId = get(this, 'args.context.model.typeId');
    let selectedTab = 'call';

    if (typeId === EventType.Meeting) {
      selectedTab = 'meeting';
    } else if (typeId === EventType.Appointment) {
      selectedTab = 'appointment';
    } else if (typeId === EventType.Task) {
      selectedTab = 'task';
    } else if (typeId === EventType.Request) {
      selectedTab = 'request';
    }

    set(this, 'selectedTab', selectedTab);
  }

  @task
  *saveTask() {
    if (this.args.context) {
      this.showFormErrors = true;
      let model = yield get(this.args.context, 'model');
      if (model.get('validations.isValid')) {
        if (this.args.context && !this.args.context.doNotSaveOnUpdate) {
          yield model.save();
        }
        if (this.args.context && this.args.context.onUpdate) {
          this.args.context.onUpdate(get(this.args.context, 'model'));
        }
        this.args.onClose();
      }
    }
  }

  @task
  *removeTask() {
    if (this.args.context && this.args.context.onDelete) {
      this.args.context.onDelete(get(this.args.context, 'model.id'));
      this.args.onClose();
    } else {
      yield get(this.args.context, 'model').destroyRecord();
      if (this.args.context.onUpdate) {
        this.args.context.onUpdate();
      }
      this.args.onClose();
    }
  }

  @action
  save() {
    this.saveTask.perform();
  }

  @action
  remove() {
    this.removeTask.perform();
  }
}
