import JSONAPISerializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class DocketSerializer extends JSONAPISerializer {
  attrs = {
    docketNumber: { serialize: false },
    docketUrl: { serialize: false },
    filingDate: { serialize: false },
    createTime: { serialize: false },
    numberOfPages: { serialize: false },
    numberOfDocuments: { serialize: false },
    modifyTime: { serialize: false },
    case: { serialize: false },
    caseId: { serialize: false },
    attachments: { serialize: false },
    relatedDockets: { serialize: false },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    docket: DocketSerializer;
  }
}
