import { isEmpty } from '@ember/utils';
import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class JsonaTransform extends Transform {
  deserialize(serialized: any) {
    // if json object was passed, use it
    if (typeof serialized === 'object') {
      return serialized;
    }

    // otherwise try to parse it
    try {
      return JSON.parse(serialized);
    } catch (e) {
      if (typeof serialized === 'string') {
        return [serialized];
      } else {
        return [];
      }
    }
  }

  serialize(deserialized: any) {
    if (isEmpty(deserialized)) {
      return JSON.stringify([]);
    } else {
      return JSON.stringify(deserialized);
    }
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    jsona: JsonaTransform;
  }
}
