import Model, { AsyncBelongsTo, AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import Project from './project';
import Category from './category';
import Template from './project-template';
import Form from './form';
import Currency from './currency';
import SearchViewLayout from './search-view-layout';
import FormExcelSheet from './form-excel-sheet';
import AppBuilder from 'cing-app/pods/appbuilder/service';

export default class Case extends Model {
  @attr('string') name!: string;
  @attr('string') displayName!: string;
  @attr('string') customCaseId!: string;
  @attr('string') publishUrl!: string;
  @attr('date') publishDate!: Date;
  @attr('boolean') isPublished!: boolean;
  //@ts-ignore
  @attr('boolean') isDeleted!: boolean;
  @attr('string') owner!: string;
  @attr('number') categoryId!: number;
  @attr('boolean') hasGantt?: boolean;
  @attr('number') projectId!: number;
  @attr('date') startDate?: Date;
  @attr('date') endDate?: Date;
  @attr('string') projectOverview!: string;
  @attr('boolean') isHidden!: boolean;
  @attr('string') caseProjectType!: string;
  @attr('number') parentCaseid?: number;
  @attr('boolean') runLpCalcs?: boolean;
  @attr('date') createTime!: Date;
  @attr('date') modifyTime!: Date;
  @belongsTo('appbuilder-link/project') project?: AsyncBelongsTo<Project>;
  @belongsTo('appbuilder-link/category') category?: AsyncBelongsTo<Category>;
  @belongsTo('appbuilder-link/project-template') projectTemplate?: AsyncBelongsTo<Template>;
  @belongsTo('appbuilder-link/form') form!: AsyncBelongsTo<Form>;
  @hasMany('appbuilder-link/currency') currencies?: AsyncHasMany<Currency>;
  //@hasMany('appbuilder/culture') cultures?: Project;
  @hasMany('appbuilder-link/search-view-layout') searchViewLayouts!: AsyncHasMany<SearchViewLayout>;
  @hasMany('appbuilder-link/form-excel-sheet') formExcelSheets!: AsyncHasMany<FormExcelSheet>;

  static createModel(abLinkId: string, appbuilder: AppBuilder) {
    let model = Case.extend({
      project: belongsTo(appbuilder.getAbmModelName('appbuilder-link/project', abLinkId)),
      category: belongsTo(appbuilder.getAbmModelName('appbuilder-link/category', abLinkId)),
      projectTempalte: belongsTo(appbuilder.getAbmModelName('appbuilder-link/project-template', abLinkId)),
      form: belongsTo(appbuilder.getAbmModelName('appbuilder-link/form', abLinkId)),
      currencies: hasMany(appbuilder.getAbmModelName('appbuilder-link/currency', abLinkId)),
      searchViewLayouts: hasMany(appbuilder.getAbmModelName('appbuilder-link/search-view-layout', abLinkId)),
      formExcelSheets: hasMany(appbuilder.getAbmModelName('appbuilder-link/form-excel-sheet', abLinkId)),
    });
    return model;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/case': Case;
  }
}
