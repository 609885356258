import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from "@ember/component";
import { task } from "ember-concurrency";
import { later } from "@ember/runloop";
import { ReportStatus } from "cing-app/utils/lookups";

@classic
export default class PeopleMerge extends Component {
  @alias('context.people')
  people;

  @inject()
  config;

  @inject()
  store;

  @task(function* () {
    const grabReportAdapter = this.get('store').adapterFor('grab-report');

    let masterPerson = this.get('masterPerson');
    let people = [];

    this.get('people').forEach((personInCompany) => {
      if (personInCompany.get('person') !== masterPerson) {
        people.push({
          personId: personInCompany.get('personId'),
          mergeType: 1
        })
      }
    });

    let report = yield grabReportAdapter.requestPeopleMerge(
      this.get('masterPerson.id'),
      people
    );

    report.data['type'] = 'grab-report';

    this.set('mergePeopleAction', this.get('store').push(report));

    this.send('getMergePeopleStatus', this.get('mergePeopleAction'))
  })
  mergePeople;

  @task(function* (report) {
    let store = this.get('store');

    let downloadReport = yield store.findRecord('grab-report', report.get('id'), {
      reload: true
    });

    let reportStatus = downloadReport.get('status');

    if (downloadReport.get('isProcessing')) {
      later(this, function () {
        this.send('getMergePeopleStatus', report);
      }, 1000);
    } else if (reportStatus >= ReportStatus.Dropped) {
      throw Error('Merge task did not complete successfully.');
    } else {
      if (this.get('context.onComplete')) {
        this.get('context.onComplete')();
      }

      this.sendAction('onClose');
    }
  })
  mergePeopleStatusTask;

  @action
  onMainCompanySelected() {

  }

  @action
  getMergePeopleStatus(report) {
    this.get('mergePeopleStatusTask').perform(report);
  }
}