import Component from '@ember/component';

import { all, timeout, task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { computed, get, set } from '@ember/object';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import classic from 'ember-classic-decorator';
import investor from 'cing-app/adapters/ab/investors/investor';
import { isEmpty } from '@ember/utils';
import { ProjectTabMap } from 'cing-app/utils/lookups';
@classic
export default class InvestorComponent extends Component {
  @service store;
  @service('docker-item') docker;

  @alias('context.investor') investor;
  @alias('context.project') project;
  @alias('context.investor.investorFundId') investorFundId;
  @alias('context.investor.commitment') commitment;
  @alias('context.investor.smartRoomFolderId') smartRoomFolderId;

  init() {
    super.init(...arguments);
    this.initTask.perform();
  }

  @computed('investor.investorNumber')
  get investorNumber() {
    return this.get('investor.investorNumber');
  }
  set investorNumber(newValue) {
    let investorFundIdPrefix = this.get('project.investorFundIdPrefix');

    this.set('investor.investorNumber', newValue);

    if (!isEmpty(newValue)) {
      this.set(
        'investor.investorFundId',
        investorFundIdPrefix ? investorFundIdPrefix + newValue : newValue
      );
    } else {
      this.set('investor.investorFundId', '');
    }

    return this.get('investor.investorNumber');
  }

  @computed('investor.smartRoomFolderId', 'smartRoomId')
  get smartRoomFolder() {
    let smartRoomFolderId = get(this.investor, 'smartRoomFolderId');

    if (this.smartRoomId && smartRoomFolderId) {
      return this.store.queryRecord('smartroom/folder', {
        id: smartRoomFolderId,
        siteId: this.smartRoomId,
      });
    }
  }

  @task
  *initTask() {
    set(this, 'company', yield get(this.investor, 'company'));

    let smartRoomTab = (yield this.get('project.projectTabs')).findBy(
      'type',
      ProjectTabMap.SMARTROOM
    );

    if (smartRoomTab) {
      this.setProperties({
        smartRoomId: smartRoomTab.settings.SmartRoomId,
        fundSmartRoomFolderId: smartRoomTab.settings.SmartRoomFolderId,
      });
    }
  }

  @task
  *saveTask() {
    set(this.investor, 'company-id', get(this, 'company.id'));
    set(this.investor, 'company', this.company);
    yield (yield this.investor).save();

    if (this.context.onCreate) {
      this.context.onCreate();
    }
  }

  @task
  *removeTask() {
    yield (yield this.investor).destroyRecord();
    if (this.onClose) {
      this.onClose();
    }
    if (this.context.onDelete) {
      this.context.onDelete();
    }
  }

  @task({
    restartable: true,
  })
  *searchCompaniesTask(term) {
    yield timeout(300);

    if (!term) {
      this.set('lastSearchCompanyText', null);
      return null;
    }

    this.set('lastSearchCompanyText', term);

    let expr = Expressions.create();

    expr.add(
      Filter.create({
        name: 'name',
        operator: FilterOperators.LIKE,
        value: term,
      })
    );

    expr.add(
      Filter.create({
        name: 'master-id',
        operator: FilterOperators.NULL,
      })
    );

    return yield this.get('store').query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 50, number: 1 },
    });
  }

  @action
  createCompany() {
    // let self = this;
    let appearance = {
      icon: 'edit',
      title: `<small>Company: </small>Add new`,
      size: 'medium',
      custom: true,
    };

    let newCompany = this.get('store').createRecord('company', {
      name: this.lastSearchCompanyText,
    });

    let context = {
      company: newCompany,
      closeOnCreate: true,

      onCreate: (company) => {
        set(this.investor, 'company', company);
      },
    };

    this.get('docker').invokePopup('manage-company', appearance, context);
  }

  @action
  editContact(record) {
    const self = this;
    let forCompany = this.get('company');

    this.docker.popupEditPerson(record, {
      company: forCompany,
      onCreate: function (newContact) {
        self.get('contactsComponent').set('reloadData', true);
      },
    });
  }

  @action
  editAddress(record) {
    let self = this;
    let forCompany = this.get('company');
    let title = record
      ? `Edit Address for Company: ${forCompany.get('name')}`
      : `Add Address for Company: ${forCompany.get('name')}`;
    let appearance = {
      icon: '',
      title: title,
      size: 'sm',
      customPopup: true,
    };
    let context = {
      address: record,
      company: forCompany,
      closeOnCreate: true,
      onCreate: function (newAddress) {
        self.get('locations').pushObject(newAddress);
      },
    };

    this.docker.invokePopup('manage-address', appearance, context);
  }
  @action
  doRemoveInvestor() {
    this.removeTask.perform();
  }

  @action
  registerChild(name, instance) {
    this.set(name, instance);
  }

  @action
  save() {
    this.saveTask.perform();
  }

  @action
  viewCompany(company) {
    this.docker.popupCompany(company);
  }

  @action
  updateInvestorFundId() {
    let invNumber = get(this.investor, 'investorNumber');

    set(this, 'investorNumber', invNumber);
  }

  @action
  onSelectFolder(folder) {
    set(this, 'selectSmartRoomFolder', false);
    set(this.investor, 'smartRoomFolderId', folder.id);
  }

  @action
  close() {
    this.investor.rollbackAttributes();
    if (this.onClose) {
      this.onClose();
    }
  }
}
