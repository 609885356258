import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from "@ember/component";
import EmberObject, { action, computed } from "@ember/object";
import { EventType } from 'cing-app/utils/lookups';
import moment from 'moment';
import { task } from "ember-concurrency";

@classic
@classNames('d-flex', 'flex-column', 'flex-grow-1')
export default class GenericAdd extends Component {
  @inject()
  store;

  @inject()
  config;

  @inject('docker-item')
  docker;

  EventType = EventType;

  @alias('context.specificEventTypeId')
  specificEventType;

  showValidations = false;

  init() {
    super.init(...arguments);

    let model = this.get('context.event');

    if (!model) {
      model = this.store.createRecord('event', {
        caseId: this.get('context.project.caseId'),
        projectId: this.get('context.project.id'),
        dateStart: moment(),
        typeId: this.specificEventType,
        alwaysShow: true,
      });
    } else {
      model.set('alwaysShow', !model.get('displayDateStart'));

      if (model.get('typeId') === EventType.Announcement) {
        model.set('showInCalendar', !!model.get('dateStart'));
      }
    }

    this.set('model', model);

    if (model.get('displayDateStart')) {
      model.set('displayDateStartTime', moment(model.get('displayDateStart')).toDate());
    }

    if (model.get('displayDateEnd')) {
      model.set('displayDateEndTime', moment(model.get('displayDateEnd')).toDate());
    }
  }

  mergeDateTime(model, propName) {
    let datePart = model.get(propName), timePart = model.get(propName + 'Time');
    if (datePart && timePart) {
      let dt = moment(datePart).format('YYYY-MM-DD') + ' ' + moment(timePart).format('HH:mm');
      model.set(propName, moment(dt).toDate());
    }
  }

  postProcessEvent(model) {
    if (!model.get('alwaysShow')) {
      this.mergeDateTime(model, 'displayDateStart');
      this.mergeDateTime(model, 'displayDateEnd');
    } else {
      model.set('displayDateStart', null);
      model.set('displayDateEnd', null);
    }
    if ((model.get('typeId') === EventType.Announcement) && !model.get('showInCalendar')) {
      model.set('dateStart', null);
    }
  }

  @task(function* (model) {
    this.postProcessEvent(model);

    yield model.save();

    if (this.get('context.onAdd')) {
      this.get('context.onAdd')();
    }

    if (this.get('context.onUpdate')) {
      this.get('context.onUpdate')();
    }

    this.sendAction('onClose');
  })
  saveTask;

  @action
  save() {
    this.model.title = this.model.get('title').trim();

    this.set('showValidations', true);

    if (!this.model.get('validations.isValid')) {
      return;
    }

    this.saveTask.perform(this.model);
  }

  @action
  selectPacerEvent(pacerEvent) {
    //this.set('model.docketId', pacer);
  }

  @action
  selectRelatedPacerEvent() {
    let selectedCase = this.get('context.project.case');

    let appearance = {
      icon: '',
      title: `Search PACER Events for case #${selectedCase.get('displayNumber')}`,
      size: 'medium'
    };
    let self = this;
    this.docker.invokePopup(
      'manage-events/select-related-pacer-event',
      appearance,
      {
        case: selectedCase,
        onSelect: function (pacerEvent) {
          self.send("selectPacerEvent", pacerEvent);
        }
      }
    );
  }

  @action
  showDocket(record, tab) {
    let appearance = {
      icon: '',
      title: record.get('popupTitle'),
      size: 'medium'
    };
    this.docker.invokePopup('manage-docket', appearance, {
      docket: record,
      tab: tab
    });
  }

  @action
  addInterestedParty() {
    let self = this;
    let partyCase = this.get('context.case');

    let appearance = {
      icon: '',
      title: `Parties related to case: ${partyCase.get('displayNumber')}`,
      size: 'medium',
      customPopup: true
    };

    self.get('docker').invokePopup('manage-docket/interested-parties/add', appearance, {
      partyCase: partyCase,
      parentInfo: `${(self.get('context.event.title')) || ('')} event`,
      onSelection: function (selectedItems) {
        self.send("addParties", selectedItems);
      },
    });
  }

  @action
  addRelatedDocument() {
    let self = this;
    let partyCase = this.get('context.case');

    let appearance = {
      icon: '',
      title: `Select document from case: ${partyCase.get('name')}`,
      size: 'large',
      custom: true
    };

    self.get('docker').invokePopup('manage-events/select-files', appearance, {
      case: partyCase,
      presetFilter: EmberObject.create(),
      onSelection: function (selectedItems) {
        self.send("addDocuments", selectedItems);
      },
    });
  }

  @action
  addParties(parties) {
    this.get('context.event.interestedParties').then((existingParties) => {
      existingParties.pushObjects(parties);

      this.get('context.event').save().then(() => {
      });
    });
  }

  @action
  viewDocket(docket) {
    let appearance = {
      icon: '',
      title: docket.get('popupTitle'),
      size: 'extra-large',
      customPopup: true
    };
    this.docker.invokePopup('view-pdf', appearance, {
      docket: docket,
    });
  }

  @action
  addDocuments(documents) {
    this.get('context.event.documents').then((existingDocuments) => {
      existingDocuments.pushObjects(documents);

      if (!this.get('context.event.isNew')) {
        this.get('context.event').save().then(() => {
        });
      }
    });
  }
}
