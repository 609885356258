import { attr } from '@ember-data/model';
//@ts-ignore - mixin
import Fragment from 'ember-data-model-fragments/fragment';

export default class CrmImportRow extends Fragment {
  @attr('string') declare FundName: string;
  @attr('string') declare InvestorContactId: string;
  @attr('string') declare Company: string;
  @attr('string') declare CompanyId: string;
  @attr('string') declare ContactName: string;
  @attr('string') declare PersonId: string;
  @attr('string') declare Type: string;
  @attr('string') declare Role: string;
  @attr('string') declare ContactEmail: string;
  @attr('string') declare PortalAccess: string;
  @attr('string') declare ViewerGroup: string;
  @attr('string') declare InvestorName: string;
  @attr('string') declare InvestorFundId: string;
  @attr('string') declare Folders: string;
  @attr('boolean') declare SRAccess: boolean;
  @attr('string') declare SRProfile: string;
  @attr('jsona') declare Communications: any;
  @attr('string') declare DealCloudContactId: string;
  @attr('string') declare DealCloudInvestorId: string;
  @attr('string') declare DealCloudCompanyId: string;
  @attr('boolean') declare CreateUser: boolean;
  @attr('string') declare UserGroup: string;
  @attr('string') declare DealCloudFundId: string;
  @attr('string') declare InvestorNumber: string;
  @attr('string') declare InvestorType: string;
  @attr('string') declare Commitment: string;
  @attr('string') declare ProjectId: string;
  @attr('string') declare Phones: string;
  @attr('date') declare DateOfBirth: Date;
  @attr('string') declare Gender: string;
  @attr('string') declare BIOPage: string;
  @attr('string') declare Note: string;
  @attr('string') declare Address1: string;
  @attr('string') declare Address2: string;
  @attr('string') declare Address3: string;
  @attr('string') declare City: string;
  @attr('string') declare Country: string;
  @attr('string') declare State: string;
  @attr('string') declare Zip: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'crm-import-row': CrmImportRow;
  }
}
