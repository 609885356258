import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import UserInstancesService from 'cing-app/pods/user-instances/service';
import AppBuilderService, {
  ABModelMeta,
} from 'cing-app/pods/appbuilder/service';
import { taskFor } from 'ember-concurrency-ts';
import {
  ApiDataSource,
  DataSourceColumn,
  ERecordCountPosition,
  Paged,
} from 'smex-ui-table';
import AppBuilderLink from 'cing-app/models/appbuilder-link';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import { pluralize } from 'ember-inflector';
import {
  ExpressionOperators,
  Expressions,
} from 'cing-app/mixins/filter-builder';

interface AbDailystatsArgs {
  context: {
    appBuilderLinkId: string;
  };
}

export interface DailyStat {
  id?: number;
  currenMb?: number;
  currentPages?: number;
  dateCreated?: string;
  decomDateRequest?: string;
  decommissionedDate?: string;
  lastLogin?: string;
  lastUpload?: string;
  lastView?: string;
  peakMb?: number;
  peakMbdate?: string;
  peakPageDate?: string;
  peakPages?: number;
  siteId?: number;
  siteName?: string;
  status?: string;
  slug?: string;
  projectName?: string;
}

export default class AbDailystats extends Component<AbDailystatsArgs> {
  @service
  config: any;

  @service
  store!: Store;

  @service('docker-item')
  docker!: DockerItemService;

  @service
  session!: _SessionService;

  @service
  serverVariables!: any;

  @service
  declare userInstances: UserInstancesService;

  @service('appbuilder')
  appbuilder!: AppBuilderService;

  @tracked
  records = [];

  @tracked
  selectedItems: DailyStat[];

  @tracked
  dataSource!: ApiDataSource<DailyStat>;

  @tracked
  errorMsg = '';

  @tracked
  createSmartRoom: boolean = false;

  @alias('config.APP.api.host')
  host!: string;

  @alias('config.APP.api.namespace')
  namespace!: string;

  condition?: string;

  query?: any;

  appBuilderLinkId: string = '';

  pageSize = 100;

  abLink!: AppBuilderLink;

  modelMeta!: ABModelMeta;

  ERecordCountPosition = ERecordCountPosition;

  constructor(owner: any, args: any) {
    super(owner, args);

    this.selectedItems = [];

    this.appBuilderLinkId = this.args.context.appBuilderLinkId;

    this.prepareDailyStat.perform();
  }

  get styleNamespace() {
    return podNames['ab-dailystats'];
  }

  get columnsUI() {
    let columns: DataSourceColumn<DailyStat>[] = [
      new DataSourceColumn<DailyStat>({
        id: 'open-room',
        label: 'Link',
        getValue: (row) => {
          let roomUrl = `${get(this.serverVariables, 'smartroomAppUrl')}#/${get(
            row,
            'slug'
          )}`;

          return roomUrl;
        },
        sortingEnabled: false,
        minWidth: 50,
        maxWidth: 50,
        valueClass: 'text-center',
        valueComponent: 'ab-dailystats/column-link',
        options: {
          dasherizedProperty: 'slug',
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'site-id',
        label: 'Site Id',
        getValue: (row) => get(row, 'siteId'),
        hidden: true,
        sortingEnabled: true,
        minWidth: 80,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'site-id',
        },
      }),
      // new DataSourceColumn<DailyStat>({
      //   id: 'project-name',
      //   label: 'Project Name',
      //   getValue: (row) => get(row, 'projectName'),
      //   sortingEnabled: true,
      //   minWidth: 200,
      //   valueComponent: 'table-text-column',
      // }),
      new DataSourceColumn<DailyStat>({
        id: 'site-name',
        label: 'Site Name',
        getValue: (row) => get(row, 'siteName'),
        sortingEnabled: true,
        minWidth: 200,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'site-name',
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'slug',
        label: 'Slug',
        getValue: (row) => get(row, 'slug'),
        sortingEnabled: true,
        minWidth: 200,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'slug',
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'status',
        label: 'Status',
        getValue: (row) => get(row, 'status'),
        sortingEnabled: true,
        minWidth: 130,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'status',
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'date-created',
        label: 'Date Created',
        getValue: (row) => get(row, 'dateCreated'),
        sortingEnabled: true,
        minWidth: 110,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'date-created',
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'current-pages',
        label: 'Current Pages',
        getValue: (row) => get(row, 'currentPages'),
        sortingEnabled: true,
        minWidth: 130,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'current-pages',
          format: {
            style: 'decimal',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'curren-mb',
        label: 'Current Size',
        getValue: (row) => get(row, 'currenMb'),
        sortingEnabled: true,
        minWidth: 120,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'curren-mb',
          format: {
            style: 'fileSize',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'last-upload',
        label: 'Last Upload',
        getValue: (row) => get(row, 'lastUpload'),
        sortingEnabled: true,
        minWidth: 100,
        headerClass: 'justify-content-center',
        valueClass: 'text-center',
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'last-upload',
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'last-view',
        label: 'Last View',
        getValue: (row) => get(row, 'lastView'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'last-view',
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'last-login',
        label: 'Last Login',
        getValue: (row) => get(row, 'lastLogin'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'last-login',
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'peak-page-date',
        label: 'Peak Pages Date',
        getValue: (row) => get(row, 'peakPageDate'),
        sortingEnabled: true,
        minWidth: 125,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'peak-page-date',
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'peak-pages',
        label: 'Peak Pages',
        getValue: (row) => get(row, 'peakPages'),
        sortingEnabled: true,
        minWidth: 100,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'peak-pages',
          format: {
            style: 'decimal',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'peak-mbdate',
        label: 'Peak Size Date',
        getValue: (row) => get(row, 'peakMbdate'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'peak-mbdate',
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DailyStat>({
        id: 'peak-mb',
        label: 'Peak Size',
        getValue: (row) => get(row, 'peakMb'),
        sortingEnabled: true,
        minWidth: 100,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          dasherizedProperty: 'peak-mb',
          format: {
            style: 'fileSize',
          },
        },
      }),
    ];
    return columns;
  }

  @task
  prepareDailyStat = taskFor(async () => {
    try {
      this.abLink = await this.store.findRecord(
        'appbuilder-link',
        this.appBuilderLinkId,
        {
          reload: true,
        }
      );
      this.modelMeta = this.appbuilder.getModelMeta(
        this.abLink,
        'list-of-smart-rooms'
      );

      this.dataSource = new ApiDataSource<DailyStat>(
        25,
        false,
        this.modelMeta.modelPath,
        this.session.authUser.email,
        this.loadDataTask,
        this.pageSize,
        this.columnsUI,
        this.selectedItems,
        {}
      );
      this.dataSource.onRowClick = this.showDailyStatDetail;
      this.dataSource.selectionEnabled = false;
    } catch (error) {
      this.errorMsg = error;
    }
  });

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<DailyStat>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let query: any = {
        condition: this.condition,
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
      };

      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      this.query = query;

      let abList = await this.store.query(this.modelMeta.modelPath, query);
      let dailyStats = <Paged<DailyStat>>abList.toArray();
      dailyStats.meta = {
        totalCount: abList.meta['total-count'],
      };

      return dailyStats;
    }
  );

  @action
  showDailyStatDetail(row: DailyStat) {
    const appearance = {
      icon: '',
      title: `Daily Stat detail: ${row.siteName}`,
      custom: true,
      size: 'extra-large',
    };

    const context = {
      dailyStat: row,
      appBuilderLink: this.abLink,
    };

    this.docker.invokePopup('ab-dailystats/detail', appearance, context);

    //this.loadUserLogins.perform(row);
  }

  @action
  async onCreateSmartRoom(smartRoom) {
    let newSRStatRecord = this.store.createRecord(this.modelMeta.modelPath, {
      siteId: smartRoom.id,
      siteName: smartRoom.siteName,
      slug: smartRoom.slug,
      status: 'Active',
      dateCreated: smartRoom.dateCreated,
    });

    await newSRStatRecord.save();

    this.dataSource.refresh();
  }

  @action
  updateCondition(condition: string) {
    this.condition = condition;

    this.dataSource.refresh();
  }

  @action
  export(fallbackFileName = null) {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.responseType = 'arraybuffer';

    let exportUrl = this.createExportUrl();

    xhr.open('GET', exportUrl);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${this.session.data.authenticated.access_token}`
    );

    xhr.onload = function () {
      if (this.status === 200) {
        let filename = '';
        var disposition = xhr.getResponseHeader('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
        }

        if (!filename && fallbackFileName) {
          filename = fallbackFileName;
        }

        var type = xhr.getResponseHeader('Content-Type');

        var blob = new Blob([this.response], { type: type });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement('a');
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
              window.location = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location = downloadUrl;
          }

          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      }
    };

    xhr.send();
  }

  createExportUrl() {
    let query: any = {
      // 'page[size]': 10000,
      // 'page[number]': 1
      fields: this.dataSource?.columns
        .map((c) => `${c.options.dasherizedProperty}::${c.label}`)
        .join(','),
    };

    // let condition = Expressions.create({
    //   operator: ExpressionOperators.AND,
    // });

    // if (this.condition) {
    //   condition.add(this.condition);
    // }

    query.condition = this.condition;

    let url = `${this.host}/${this.namespace}/${pluralize(
      this.modelMeta?.modelPath.replace('appbuilder/', '')
    )}/excel?`;

    return url + new URLSearchParams(query).toString();
  }
}
