import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { SmartRoomUpload, UploadInfo, UploadAborter } from 'smex-ui-sr-services';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import DockerItemService from 'cing-app/pods/docker-item/service';


interface SmartroomUploadProgressArgs {
	context: {
		event: Event;
		siteId: string;
		folderId: string;
		folderName: string;
	},
	onClose: () => void;
}

export default class SmartroomUploadProgress extends Component<SmartroomUploadProgressArgs> {
	//@ts-ignore
	@service('docker-item')
	docker!: DockerItemService;
	@service("smart-room-upload")
	upload!: SmartRoomUpload;
	@tracked
	confirmCancel?: (a: boolean) => void;

	aborter = new UploadAborter();
	@tracked
	bottomWindowShowed = true;
	@tracked
	showConfirmReplace = true;

	//tracking upload status
	@tracked
	filesCount = 0;
	@tracked
	foldersCount = 0;
	@tracked
	speed = 0;
	@tracked
	uploaded = 0;
	@tracked
	progress = 0;
	@tracked
	uploadedFiles = 0;
	@tracked
	estimatedTime = 0;
	@tracked
	existingFileNames?: string[];

	@tracked
	uploadDone = false;
	@tracked
	popup: any;

	@tracked
	uploadInfo?: UploadInfo;
	@tracked
	_event?: Event;
	@tracked
	fileInput?: HTMLInputElement;

	@tracked
	errorProgress = 0;
	@tracked
	errorFilesCount = 0;
	// @tracked
	// errorFiles: UploadFileInfo[] = [];

	constructor(owner: unknown, args: SmartroomUploadProgressArgs) {
		super(owner, args);

		//@ts-ignore
		let dataTransfer = <DataTransfer>this.args.context.event.dataTransfer;
		if (dataTransfer) {
			this.readFilesFromHDD.perform();
		} else {
			this.filesSelected();
		}
	}

	@action
	filesSelected() {
		let input = <HTMLInputElement>this.args.context.event.target;

		if (input.files && input.files.length) {
			this.uploadInfo = this.upload.getUploadStructureFromFiles(input.files)
			this.filesCount = this.uploadInfo.files.length;
			this.foldersCount = this.uploadInfo.foldersCount;
		}
		input.value = '';
		this.validateUpload.perform();
	}

	@task
	readFilesFromHDD = taskFor(async () => {
		try {
			let { uploadInfo, promise } = this.upload.getUploadStructureFromItems(this.args.context.event, this.aborter);
			this.uploadInfo = uploadInfo;
			this.reportProgress.perform();
			await promise;
			this.validateUpload.perform();
		}
		finally {
			this.reportProgress.cancelAll();
			if (this.uploadInfo) {
				this.filesCount = this.uploadInfo.files.length;
				this.foldersCount = this.uploadInfo.foldersCount;
			}
		}
	});

	@task
	reportProgress = taskFor(async () => {
		while (true) {
			await timeout(500);
			if (this.uploadInfo) {
				this.filesCount = this.uploadInfo.files.length;
				this.foldersCount = this.uploadInfo.foldersCount;
			}
		}
	});

	@tracked
	message = '';

	@task
	validateUpload = taskFor(async () => {
		try {
			if (this.uploadInfo) {
				this.message = 'Validating items'
				let errors: string[] | null = await this.upload.validateItemsToUpload(this.uploadInfo.itemsToUpload, this.args.context.siteId, this.args.context.folderId);
				if (errors && errors.length) {
					this.existingFileNames = errors;
				} else {
					this.startUpload.perform();
				}
			}
		}
		finally {
			this.message = '';
		}
	});

	@action
	beforeUnload(event: Event) {
		event.preventDefault();
		//@ts-ignore
		return event.returnValue = 'Documents are uploading, are you sure you want to close the browser?';
	}

	@task
	startUpload = taskFor(async () => {
		try {
			window.addEventListener('beforeunload', this.beforeUnload, { capture: true });
			this.existingFileNames = undefined;
			this.reportUploadProgress.perform();
			if (this.uploadInfo) {
				this.message = 'Preparing storage'
				let { structureGuid } = await this.upload.sendUploadStructure(this.uploadInfo, this.args.context.siteId, this.args.context.folderId);
				this.message = '';
				await this.upload.loadSessionIds.perform(this.uploadInfo, this.args.context.siteId, this.args.context.folderId, structureGuid);
				this.uploadDone = true;

				if (this.uploadInfo.errorFilesCount === 0 && this.uploadInfo.errors.length === 0) {
					this.closeAfterSuccess.perform();
				}
			}
		}
		catch (err) {
			//ignore error because all errors are in uploadInfo
		}
		finally {
			window.removeEventListener('beforeunload', this.beforeUnload, { capture: true });
			this.message = '';
			this.reportUploadProgress.cancelAll();
			if (this.uploadInfo) {
				this.speed = this.uploadInfo.getSpeed();
				this.uploaded = this.uploadInfo.uploaded;
				this.progress = this.uploadInfo.getProgress();
				this.uploadedFiles = this.uploadInfo.uploadedFiles;
				this.estimatedTime = this.uploadInfo.getEstimatedTime();
				if (this.errorFilesCount !== this.uploadInfo.errorFilesCount) {
					this.errorFilesCount = this.uploadInfo.errorFilesCount;
					this.errorProgress = this.uploadInfo.errorFilesSize / this.uploadInfo.size * 100;
					// this.errorFiles = this.uploadInfo.files.filter(f => f.errorMessage);
				}
			}
		}
	});

	@task
	closeAfterSuccess = taskFor(async () => {
		await timeout(5000);
		if (!this.popup) {
			this.close();
		}
	});

	@task
	reportUploadProgress = taskFor(async () => {
		while (true) {
			if (this.uploadInfo) {
				this.speed = this.uploadInfo.getSpeed();
				this.uploaded = this.uploadInfo.uploaded;
				this.progress = this.uploadInfo.getProgress();
				this.uploadedFiles = this.uploadInfo.uploadedFiles;
				this.estimatedTime = this.uploadInfo.getEstimatedTime();
				if (this.errorFilesCount !== this.uploadInfo.errorFilesCount) {
					this.errorFilesCount = this.uploadInfo.errorFilesCount;
					this.errorProgress = this.uploadInfo.errorFilesSize / this.uploadInfo.size * 100;
					// this.errorFiles = this.uploadInfo.files.filter(f => f.errorMessage);
				}
			}

			await timeout(500);
		}
	});

	@action
	showFiles() {
		this.bottomWindowShowed = false;
		const appearance = {
			label: 'Uploading Files',
			icon: 'upload',
			size: 'large',
			title: '<small>Uploading Files to:</small> ' + this.args.context.folderName,
			custom: true
		};
		const context = {
			uploadInfo: this.uploadInfo,
			onClose: () => {
				if (!this.isDestroyed) {
					this.bottomWindowShowed = true;
					this.popup = undefined;
				}
			}
		};
		this.popup = this.docker.invokePopup('smartroom/upload-progress/detail', appearance, context);
	}

	@action
	abortUpload() {
		this.aborter.abort();
		this.startUpload.cancelAll();
		this.close();
	}

	@action
	close() {
		this.docker.removePopup(this.popup);
		this.args.onClose();
	}

	@action
	uploadStructure(event: Event) {
		event.preventDefault();
		this._event = event;
		this.readFilesFromHDD.perform();
	}

	@action
	dropAreaClicked(event: Event) {
		event.preventDefault();

		if (this.fileInput) {
			this.fileInput.click();
		}
	}
}
