import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency'
import { next } from '@ember/runloop';
import { taskFor } from 'ember-concurrency-ts';
import { async } from 'rsvp';
import { tracked } from '@glimmer/tracking';

interface WidgetsArgs {
  model:any;
  remove:any;
}

export default class Widgets extends Component<WidgetsArgs> {
  canSave = true;
  standardControl = 'input';

  @tracked
  model: any;

  @tracked
  remove:any

  @tracked
  registerRelationHandler:any;


  constructor(owner: any, args: WidgetsArgs){
    super(owner,args);
    this.model = this.args.model;
    this.remove = this.args.remove;
 
    let fragment = this.fragment;

    if (fragment) {
      this.set('elementId', `dp-form-fragment${fragment}`);
    }
  }

  @task
  relationHandler = taskFor(async(type)=>{
    if(type === 'saveStart' && this.saveStartTask){
      await this.saveStartTask.perform();
    }
    if(type === 'saveEnd' && this.save.saveEndTask){
      await this.saveEndTask.perform();
    }
  })

  @action
  onSave() {
    this.model.save();
  }

  @action
  onCancel() {
    this.model.rollbackAttributes();
  }

  @action
  onUpdate() {
    next(() => {
      this.$(this.standardControl).first().focus();
    })
  }
}
