import classic from 'ember-classic-decorator';
import { inject } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-smartidentity-auth/mixins/auth-mixin';
import { task } from 'ember-concurrency';

import { CaseProjectUrlMap } from "cing-app/utils/lookups";

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

@classic
export default class CaseRoute extends Route {
  @inject()
  store;

  model(params) {
    return this.get("getCase").perform(params.case_number);
  }

  @task(function* (caseNumber) {

    let caseByProjectURL = yield this.get('getCaseByProjectURL').perform(caseNumber);

    if (caseByProjectURL) {
      return caseByProjectURL;
    } else {
      let expr = Expressions.create();
      expr.add(Filter.create({
        name: 'parentCaseId',
        operator: FilterOperators.NULL
      }));

      let caseQuery = Expressions.create({ operator: ExpressionOperators.OR });

      caseQuery.add(Filter.create({
        name: 'name',
        operator: FilterOperators.EQUAL,
        value: caseNumber
      }));

      expr.add(caseQuery);

      return (yield this.get('store').query('case', {
        condition: expr.serialize(),
        include: 'debtor-company,court,debtor-profile,project-urls'
        //'page[size]': 1
      })).get('firstObject');
    }
  })
  getCase;

  @task(function* (caseNumber) {
    let expr = Expressions.create();
    expr.add(Filter.create({
      name: 'url',
      operator: FilterOperators.EQUAL,
      value: caseNumber
    }));

    expr.add(Filter.create({
      name: 'type',
      operator: FilterOperators.EQUAL,
      value: CaseProjectUrlMap.PUBLISHED_CI_URL
    }));

    let caseProjectURL = (yield this.get('store').query('case-project-url', {
      condition: expr.serialize(),
      include: 'case'
    })).get('firstObject');

    if (caseProjectURL) {
      return yield caseProjectURL.get('case');
    }
  })
  getCaseByProjectURL;
}
