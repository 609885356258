import Component from '@ember/component';
import { get, computed } from '@ember/object';
export default Component.extend({
  tagName: '',

  toggleAllIsRunning: false,
  actions: {
    toggleAllSelection() {
      this.set('toggleAllIsRunning', true);
      try {
        let allSelected = this.toggleAllSelection().then((result) => {
          console.log("RESULT: ", result);
          this.set('toggleAllIsRunning', false);
          this.set('isAllSelected', result);
        })
      } catch (e) {
        this.set('toggleAllIsRunning', false);
        this.set('isAllSelected', false);
      }
    }
  }
});
