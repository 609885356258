import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';

export default class DataLinkContextFilter extends Fragment {
  // NOTE: for now, each view is connected to a model, so this should not be needed
  //@attr('string')
  //modelName!: string;
  @attr('boolean', { defaultValue: false }) declare system: boolean;
  @attr('string') declare property: string;
  @attr('string') declare operator: string;
  @attr declare value: any;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-link-context-filter': DataLinkContextFilter;
  }
}
