import Component from '@ember/component';
import {
  PortalPageProjectFilter,
  PortalPageProjectFilterMap
} from 'cing-app/utils/lookups';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  store: service(),

  init() {
    this._super(...arguments);
  },

  PortalPageProjectFilterMap,
  ProjectFilters: Object.entries(PortalPageProjectFilter).map((item) => {
    return {
      name: item[0],
      value: item[1]
    }
  }),
  ProjectFilterOptions: Object.values(PortalPageProjectFilter),

  projectTypeIds: computed('projectTypes', function () {
    if (this.projectTypes && this.projectTypes.length) {
      return this.projectTypes.mapBy('id');
    }
  }),

  projectStatusIds: computed('projectStatuses', function () {
    if (this.projectStatuses && this.projectStatuses.length) {
      return this.projectStatuses.mapBy('id');
    }
  }),

  fundTypeIds: computed('fundTypes', function () {
    if (this.fundTypes && this.fundTypes.length) {
      return this.fundTypes.mapBy('id');
    }
  }),

  fundStatusIds: computed('fundStatuses', function () {
    if (this.fundStatuses && this.fundStatuses.length) {
      return this.fundStatuses.mapBy('id');
    }
  })
})