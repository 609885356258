import JSONAPISerializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class AlertTemplateSerializer extends JSONAPISerializer {
  attrs = {};
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'alert-template': AlertTemplateSerializer;
  }
}
