import JSONSerializer from '@ember-data/serializer/json';
import classic from 'ember-classic-decorator';
import Model from '@ember-data/model';

@classic
export default class ProjectTabSettingsSerializer extends JSONSerializer {
  normalize(typeClass: Model, hash: any) {
    // let normalized = this._super(typeClass, hash);
    let normalized = super.normalize(typeClass, hash);

    return normalized;
  }

  serialize(snapshot: any, options: any) {
    // let serialized = this._super(snapshot, options);
    let serialized = super.serialize(snapshot, options);

    //save appbuilder-link to snapshot

    return serialized;
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'project-tab-settings': ProjectTabSettingsSerializer;
  }
}
