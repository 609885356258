import classic from 'ember-classic-decorator';
import ApplicationSerializer from './application';

@classic
export default class DealCloudContactSerializer extends ApplicationSerializer {
  /*
  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      for (var a = 0; a < payload.data.length; a++) {
          this.normalizeItem(payload.data[a]);
      }

      return this._super(...arguments);
  },
  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      this.normalizeItem(payload.data);
      return this._super(...arguments);
  },
  normalizeItem(item) {
      item.id = Math.floor((Math.random() * 50000)) + "";
  }
  */
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'deal-cloud-contact': DealCloudContactSerializer;
  }
}
