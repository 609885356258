import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';
import _ from 'lodash';
import { CaseStatus } from 'cing-app/utils/lookups';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import BootstrapTheme from 'ember-models-table/themes/bootstrap4';

@classic
export default class Watchlist extends Component {
  @inject()
  store;

  @inject()
  config;

  @inject()
  appEvents;

  include = 'project,project.project-type,project.fund,project.project-status';
  searchQuery = '';

  columns = [
    {
      title: 'Project',
      className: "column-case-name",
      propertyName: 'project.name',
      component: 'main-dashboard/watchlist/column-project',
      sortedBy: 'project.name',
      sortPrecedence: 0,
      sortDirection: "asc",
    },
    {
      title: 'Type',
      propertyName: 'project.projectType.name',
      className: "column-status",
    },
    {
      title: 'Status',
      propertyName: 'project.projectStatus.name',
      component: 'main-dashboard/watchlist/column-status',
      className: "column-status",
    },
    /*
    {
        propertyName: 'case.recentDocketCount',
        title: 'Recent Docs',
        className: "column-dockets-count text-center",
    },        
    */
    {
      propertyName: 'project.dateStarted',
      title: 'Start Date',
      className: "text-right column-date",
      component: "api-table/columns/date",
      dateFormat: "MM/DD/YYYY",
    },
  ];

  init() {
    super.init(...arguments);
    this.set('tableTheme', BootstrapTheme.create({
      table: 'table table-hover table-sm',
      paginationWrapperDefault: 'col-sm-12'
    }));

    this.get('appEvents').subscribe('watchlistChanged', this, 'watchListChanged');

    this.generateFilter();
  }

  watchListChanged() {
    this.set('reloadData', true);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);

    this.get('appEvents').unsubscribe('watchlistChanged', this, 'generateFilter');
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    let searchQuery = this.get('searchQuery');
    if (searchQuery) {
      let searchQueryExpr = Expressions.create({ operator: ExpressionOperators.OR });
      ['project.name'].forEach((propName) => {
        searchQueryExpr.add(Filter.create({
          name: propName,
          operator: FilterOperators.LIKE,
          value: searchQuery
        }));
      });
      expr.add(searchQueryExpr);
    }
    /*
    expr.add(DateRangeFilter.create({
        dateFormat: this.get('config.APP.serverDateFormat'),
        name: 'docket-published-date',
        value1: moment().subtract(7,'d').startOf('day'),
        value2: moment().endOf('day')
    }));         
    */

    expr.add(Filter.create({
      name: 'project.deleted',
      operator: FilterOperators.EQUAL,
      value: false
    }))

    if (expr.get('expressions.length')) {
      this.set('filter', expr.serialize());
    } else {
      this.set('filter', undefined);
    }
  }

  @action
  search() {
    this.generateFilter();
  }

  @action
  clearSearch() {
    this.set('searchQuery', null);
    this.generateFilter();
  }
}
