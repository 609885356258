import Serializer from '../appbuilder-link-serializer';
import classic from 'ember-classic-decorator';

@classic
export default class CaseSerializer extends Serializer {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'appbuilder-link/case': CaseSerializer;
  }
}
