import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get, set, action, computed } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { observer } from '@ember/object';

import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

const SHOW_AS_OPTION_1 = 1;
const SHOW_AS_OPTION_2 = 2;
const SHOW_AS_OPTION_3 = 3;

const SHOW_AS_OPTIONS = [
  {
    value: SHOW_AS_OPTION_1,
    name: 'Last, First Middle',
  },
  {
    value: SHOW_AS_OPTION_2,
    name: 'First Middle Last',
  },
  {
    value: SHOW_AS_OPTION_3,
    name: 'First Last',
  },
];

interface CompanyContactsArgs {
  project: any;
  company: any;
  onSelect: any;
  onCancel: any;
}

export default class CompanyContacts extends Component<CompanyContactsArgs> {
  @service store!: Store;

  @service('docker-item')
  docker: any;

  @service config;

  @service session!: any;

  @tracked
  showAsOption: any = null;

  collapsed: true;

  includes = 'company,address,person';

  @tracked
  selectedCompanyTypes: any = null;

  @tracked
  confirmRemoval: any = null;

  @tracked
  searchQuery: any = '';

  @tracked
  filter = '';

  @tracked
  selectedPeople: any = [];

  @tracked
  columns: any;

  @tracked
  company;

  @tracked
  options: any;

  constructor(owner: any, args: CompanyContactsArgs) {
    super(owner, args);
    this.showAsOption = SHOW_AS_OPTIONS[0];
    this.options = SHOW_AS_OPTIONS;
    this.company = this.args.company;
    // this.sendAction('register', 'contactsComponent', this);
    this.columns = this.getColumns();
    this.generateFilter();
  }

  getColumns() {
    let columns = [
      {
        propertyName: 'person.fullName',
        sortedBy: 'person.firstName',
        title: 'Name',
        sortPrecedence: 0,
        component: 'company-detail/contacts/column-name',
        className: 'column-name',
        openDetail: this.showPersonDetail,
      },
      {
        propertyName: 'address.city',
        title: 'City',
        className: 'column-city',
      },
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        //mayBeHidden: true,
        //isHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
    ];

    return columns;
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'company-id',
        operator: FilterOperators.EQUAL,
        value: this.args.company.get('id'),
      })
    );
    expr.add(
      Filter.create({
        name: 'flags',
        operator: FilterOperators.NULL,
        //value:
      })
    );

    const searchQuery = this.searchQuery;

    if (searchQuery) {
      let termParts = searchQuery.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            name: 'person.firstName',
            operator: FilterOperators.LIKE,
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            name: 'person.lastName',
            operator: FilterOperators.LIKE,
            value: termParts[termParts.length - 1],
          })
        );

        exprSearch.add(nameExpression);
      }

      ['person.firstName', 'person.lastName', 'address.city'].forEach(
        (propName) => {
          exprSearch.add(
            Filter.create({
              name: propName,
              operator: FilterOperators.LIKE,
              value: searchQuery,
            })
          );
        }
      );

      expr.add(exprSearch);
    }

    this.filter = expr.serialize();
  }

  @task
  importPartiesTask = taskFor(async () => {
    let parties = [];
    for (let a = 0; a < this.selectedPeople.length; a++) {
      let personInCompany = this.selectedPeople[a];
      let person = await personInCompany.get('person');
      let address = await personInCompany.get('address');
      let emails = await person.get('emails');

      parties.pushObject(
        this.store.createRecord('interested-party', {
          person: person,
          personId: person.id,
          project: this.args.project,
          projectId: this.args.project.id,
          company: this.company,
          companyId: this.company.id,
          address: address,
          addressId: address ? address.id : null,
          email: emails.firstObject,
        })
      );
    }
    if (this.args.onSelect) {
      this.args.onSelect(parties);
    }
  });

  @action
  search(e) {
    e.preventDefault();
    this.generateFilter();
  }

  @action
  showPersonDetail(personInCompany) {
    let person = personInCompany.get('person');

    const appearance = {
      label: 'Contact detail',
      icon: 'user',
      title: '<small>Profile:</small> ' + person.get('fullName'),
      size: 'large',
    };
    const context = {
      personId: person.get('id'),
      company: this.company,
    };
    this.docker.invokePopup('contact-detail', appearance, context);
  }

  @action
  mergePeople() {
    let appearance = {
      icon: 'user',
      title: 'Merge people',
      size: 'large',
      custom: true,
    };

    let self = this;

    this.docker.invokePopup('people-merge', appearance, {
      people: this.selectedPeople,
      onComplete: function () {
        self.selectedPeople.clear();
        self.set('reloadData', true);
      },
    });
  }

  @action
  removeRecord(record) {
    this.confirmRemoval = record;
  }

  @action
  doRemoveRecord(record) {
    record.destroyRecord().then((response) => {
      this.confirmRemoval = null;
      this.search;
    });
  }

  @action
  importParties() {
    this.importPartiesTask.perform();
  }
}
