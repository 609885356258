import Component from '@glimmer/component';

interface ColumnComponentArgs {
  companyProfile:any;
}

export default class ColumnComponent extends Component<ColumnComponentArgs> {
  get companyProfile() {
    return this.args.companyProfile;
  }
}