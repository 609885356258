import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { defineProperty, action } from '@ember/object';

@classic
export default class ColumnPerson extends Component {
  @service('docker-item')
  docker;

  @service
  store;

  init() {
    super.init();
    let propertyName = this.get('column.propertyName');

    defineProperty(this, 'value', alias(`record.${propertyName}`));
  }

  click(e) {
    return false;
  }

  @action
  showPerson(personId) {
    this.store.findRecord('person', personId).then((person) => {
      this.docker.popupPerson(person);
    });
  }
}
