import { helper } from '@ember/component/helper';

export function formatCurrency([value, currency = 'USD']) {
  if (value === null || value === undefined) {
    value = 0;
  }

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    //notation: "compact", 
    //compactDisplay: "short"
  });

  return formatter.format(value);
}

export default helper(formatCurrency);