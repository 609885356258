import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';

import moment from 'moment';
import AbPmService, { AbPmLookups } from 'cing-app/pods/ab-pm/service';
import DockerItemService from 'cing-app/pods/docker-item/service';

import { SmartroomFolderModel } from 'smex-ui-sr-models';

interface AbPmTaskAddArgs {
  context: {
    abLink: any;
    smartRoomId: any;
    parentTask: any;
    task: any;
    action: string;
    onTaskAction: any;
  };
}

interface PmTask {
  id: number;
  ordinal: number;
  index: number;
  subtasks: number;
  wbs: string | null;
  taskId: number | null;
  parent: number | null;
  taskTypeId: number | null;
  type: string | null;
  taskCategoryId: number | null;
  category: string | null;
  taskStatusId: number | null;
  status: string | null;
  taskPriorityId: number | null;
  priority: string | null;
  task: string;
  summary: string | null;
  folderId: string | null;
  folderNumber: string | null;
  folderPath: string | null;
  folderName: string | null;
  createdDate: string | null;
  createdBy: string | null;
  resourceId: number | null;
  resource: string | null;
  email: string | null;
  startDate: string | null;
  endDate: string | null;
  dueDate: string | null;
  duration: any;
  progress: number | null;
}

export default class AbPmTaskAdd extends Component<AbPmTaskAddArgs> {
  @service('ab-pm') pmService!: AbPmService;
  @service('docker-item') docker!: DockerItemService;

  @tracked newTask: PmTask = {
    id: 0,
    ordinal: 0,
    index: 0,
    subtasks: 0,
    wbs: '',
    taskId: 0,
    parent: 0,
    taskTypeId: 0,
    type: '',
    taskCategoryId: 0,
    category: '',
    taskStatusId: 0,
    status: '',
    taskPriorityId: 0,
    priority: '',
    task: '',
    summary: '',
    folderName: '',
    createdDate: '',
    createdBy: '',
    resourceId: 0,
    resource: '',
    email: '',
    startDate: null,
    endDate: null,
    dueDate: null,
    duration: '',
    progress: 0,
  };

  @tracked taskTypes: any;
  @tracked taskCategories: any;
  @tracked taskPriorities: any;
  @tracked taskStatusesLookup: any;
  @tracked taskResources: any;
  @tracked selectedResources!: [];
  @tracked saveMessage: string = '';
  @tracked isError: boolean = false;

  //we use a tracked field for date input
  //because the ui will not update on the model updates
  @tracked startDate: any;
  @tracked endDate: any;

  @tracked isSmartRoomIndexVisible: boolean = false;
  @tracked smartRoomFolderName: any;
  @tracked smartRoomIndexPopup: any;

  constructor(owner: any, args: any) {
    super(owner, args);

    //console.log('task-add constructor ', args);

    this.initTask.perform();
  }

  @action focusTaskInput(element: any) {
    setTimeout(() => {
      try {
        element.focus();
      } catch (ex) {}
    }, 400);
  }

  @task initTask = taskFor(async () => {
    let lookups = await this.pmService.preloadLookupItems(
      this.args.context.abLink
    );

    this.taskTypes = lookups.taskTypes;
    this.taskCategories = lookups.taskCategories;
    this.taskPriorities = lookups.taskPriorities;
    this.taskStatusesLookup = lookups.taskStatuses;

    this.createNewRecord();

    if (
      this.args.context.parentTask &&
      this.args.context.parentTask.id &&
      !this.args.context.parentTask.children
    ) {
      this.pmService.loadTasks(
        this.args.context.parentTask,
        this.args.context.abLink
      );
    }
  });

  createNewRecord() {
    this.startDate = '';
    this.endDate = '';

    this.newTask = this.pmService.createNewTaskRecord(this.args.context.abLink);
    this.newTask.parent = this.args.context.parentTask.id;
    this.newTask.task = '';
    this.newTask.summary = '';

    this.newTask.taskCategoryId = this.args.context.parentTask.taskCategoryId;
    this.newTask.category = this.args.context.parentTask.category;

    this.newTask.startDate = this.args.context.parentTask.startDate;
    if (this.newTask.startDate) {
      this.startDate = moment(this.newTask.startDate).format('YYYY-MM-DD');
    }
    this.newTask.createdDate = this.pmService.currentDate;
    this.newTask.createdBy = this.pmService.currentUserFullName;

    this.newTask.type = 'Task';
    this.newTask.taskTypeId = this.taskTypes.find(
      ({ name }) => name == this.newTask.type
    )?.id;

    this.newTask.priority = 'Medium';
    this.newTask.taskPriorityId = this.taskPriorities.find(
      ({ name }) => name == this.newTask.priority
    )?.id;

    this.newTask.status = 'Unscheduled';
    this.newTask.taskStatusId = this.taskStatusesLookup.find(
      ({ name }) => name == this.newTask.status
    )?.id;
  }

  get isBusy() {
    return this.initTask.isRunning || this.saveTask.isRunning;
  }

  @action dateChanged(event: any) {
    //console.log('event', event);
    this.saveMessage = '';
    let oldStatus = this.newTask.status;

    this.pmService.computeTaskStatusFromDates(
      this.newTask,
      this.startDate,
      this.endDate
    );

    if (oldStatus != this.newTask.status) {
      this.newTask.taskStatusId = this.taskStatusesLookup.find(
        ({ name }) => name == this.newTask.status
      )?.id;
    }

    this.isError = false;
    let validationError = this.pmService.validateTaskFields(
      this.newTask,
      this.args.context.parentTask,
      false
    );
    if (validationError) {
      this.isError = true;
      this.saveMessage = validationError;
    }
  }

  @action durationChanged() {
    this.saveMessage = '';
    let duration = parseInt(this.newTask.duration);

    if (this.startDate) {
      let newEndDate = moment(this.startDate)
        .add(duration, 'days')
        .format('YYYY-MM-DD');

      this.endDate = newEndDate;
      this.newTask.endDate = newEndDate;
    } else if (this.endDate) {
      duration = parseInt(this.newTask.duration) * -1;
      let newStartDate = moment(this.endDate)
        .add(duration, 'days')
        .format('YYYY-MM-DD');

      this.startDate = newStartDate;
      this.newTask.startDate = newStartDate;
    }

    this.pmService.computeTaskStatusFromDates(
      this.newTask,
      this.startDate,
      this.endDate
    );
  }

  @action taskChanged() {
    this.saveMessage = '';
    this.isError = false;
    let validationError = this.pmService.validateTaskFields(
      this.newTask,
      this.args.context.parentTask,
      true
    );
    if (validationError) {
      this.isError = true;
      this.saveMessage = validationError;
      return;
    }
  }

  @action onResourceChange(resources: []) {
    this.selectedResources = resources;
  }

  @action save(e: Event) {
    this.saveTask.perform();

    if (e && e.preventDefault) {
      e.preventDefault();
    }

    return false;
  }

  @task saveTask = taskFor(async () => {
    try {
      this.saveMessage = 'Validating task...';

      this.isError = false;
      let validationError = this.pmService.validateTaskFields(
        this.newTask,
        this.args.context.parentTask,
        true
      );
      if (validationError) {
        this.isError = true;
        this.saveMessage = validationError;
        return;
      }

      if (this.newTask.startDate) {
        if (moment(this.newTask.startDate).isValid()) {
          this.newTask.startDate = moment(this.newTask.startDate).format(
            'YYYY-MM-DD'
          );
        } else {
          this.newTask.startDate = null;
        }
      }

      if (this.newTask.endDate) {
        if (moment(this.endDate).isValid()) {
          this.newTask.endDate = moment(this.newTask.endDate).format(
            'YYYY-MM-DD'
          );
        } else {
          this.newTask.endDate = null;
        }
      }
      if (this.newTask.dueDate) {
        if (moment(this.newTask.dueDate).isValid()) {
          this.newTask.dueDate = moment(this.newTask.dueDate).format(
            'YYYY-MM-DD'
          );
        } else {
          this.newTask.dueDate = null;
        }
      }
      if (!this.newTask.startDate || this.newTask.startDate == '') {
        this.newTask.startDate = null;
      }
      if (!this.newTask.endDate || this.newTask.endDate == '') {
        this.newTask.endDate = null;
      }
      if (!this.newTask.dueDate || this.newTask.dueDate == '') {
        this.newTask.dueDate = null;
      }

      if (
        !this.newTask.folderName ||
        String(this.newTask.folderName || '').trim() == ''
      ) {
        this.newTask.folderName = this.newTask.folderName;
      }

      this.saveMessage = 'Setting lookup properties...';
      if (this.newTask.taskTypeId) {
        this.newTask.type = this.taskTypes.find(
          (t: any) => t && t.id == this.newTask.taskTypeId
        )?.name;
      }
      if (this.newTask.taskPriorityId) {
        this.newTask.priority = this.taskPriorities.find(
          (p: any) => p && p.id == this.newTask.taskPriorityId
        )?.name;
      }
      if (this.newTask.taskStatusId) {
        var statusId = this.newTask.taskStatusId;
        let st = this.taskStatusesLookup.find((s: any) => {
          return s?.id == statusId;
        });
        if (st) {
          this.newTask.status = st.name;
        }
      }

      if (this.newTask.taskCategoryId && this.taskCategories) {
        this.newTask.category = this.taskCategories.find(
          (c: any) => c && c.id == this.newTask.taskCategoryId
        )?.name;
      }

      if (this.selectedResources && this.selectedResources.length > 0) {
        let firstResource = this.selectedResources.firstObject || {
          id: 0,
          email: '',
          name: '',
        };
        if (firstResource) {
          this.newTask.resourceId = firstResource.id;
          this.newTask.resource = firstResource.name;
          this.newTask.email = firstResource.email;
        }
      }

      this.saveMessage = 'Populating task wbs...';
      await this.pmService.populateTaskWBS(
        this.newTask,
        this.args.context.parentTask,
        this.args.context.task,
        this.args.context.action
      );

      this.saveMessage = 'Saving task record...';
      await this.newTask.save();

      //update the taskId field of the task
      this.saveMessage = 'Setting task id ....';
      this.newTask.taskId = this.newTask.id;
      await this.newTask.save();

      let resourceNames = [];
      if (this.selectedResources && this.selectedResources.length > 0) {
        this.saveMessage = 'Saving task resources ...';
        for (let i = 0; i < this.selectedResources.length; i++) {
          let r = this.selectedResources[i];
          let taskResource = this.pmService.createNewTaskResourceRecord(
            this.args.context.abLink
          );
          taskResource.taskId = this.newTask.id;
          taskResource.resourceId = r.resourceId;
          taskResource.resourceType = r.resourceType;
          taskResource.email = r.email;
          taskResource.name = r.name;
          taskResource.role = r.role;
          taskResource.createdDate = this.pmService.currentDate;
          taskResource.createdBy = this.pmService.currentUserEmail;

          await taskResource.save();

          resourceNames.push(r.name);
        }
      }
      this.newTask.resource = resourceNames.join(', ');

      await this.pmService.loadTasks(
        this.args.context.parentTask,
        this.args.context.abLink
      );

      this.saveMessage = "Updating the parent's number of subtasks...";
      this.args.context.parentTask.subtasks =
        this.args.context.parentTask.children.length;

      if (!this.args.context.parentTask.taskId) {
        this.args.context.parentTask.taskId = this.args.context.parentTask.id;
      }

      if (this.args.context.parentTask.save) {
        // if we are adding task from the root level,
        // the root task does not have save() method becuase it is not a store object
        await this.args.context.parentTask.save();
      } else {
        this.args.context.onTaskAction('refresh-root');
      }
      this.saveMessage = "Parent's number of subtasks is now updated.";
      this.args.context.parentTask.expanded = true;

      this.saveMessage = 'New task has been added.';
      setTimeout(() => {
        this.saveMessage = '';

        this.args.context.onTaskAction('task-added', this.newTask, {
          action: this.args.context.action,
          target: this.args.context.task,
        });
      }, 200);
    } catch (ex) {
      console.log('add error =>', ex);
    }
  });

  @action hideCreateOptionOnSameTypeName(term: string) {
    let existingOption = this.taskTypes.find(({ name }) => name === term);
    return !existingOption;
  }

  @action selectTaskType(taskType: any) {
    if (taskType) {
      this.newTask.taskTypeId = taskType.id;
    }
  }

  @action createTaskType(taskTypeName: string) {
    this.createTaskTypeTask.perform(taskTypeName);
  }

  @task createTaskTypeTask = taskFor(async (newTaskTypeName: string) => {
    if (newTaskTypeName?.trim() != '') {
      let newTaskType = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskTypes,
        this.args.context.abLink
      );
      newTaskType.name = newTaskTypeName;
      newTaskType.createdDate = this.pmService.currentDate;
      newTaskType.createdBy = this.pmService.currentUserEmail;

      await newTaskType.save();

      newTaskType.taskTypeId = newTaskType.id;
      await newTaskType.save();

      this.newTask.taskTypeId = newTaskType.id;
      this.newTask.type = newTaskTypeName;

      this.taskTypes.update();
    }
  });

  @action hideCreateOptionOnSameCategoryName(term: string) {
    let existingOption = this.taskCategories.find(({ name }) => name === term);
    return !existingOption;
  }

  @action selectTaskCategory(taskCategory: any) {
    if (taskCategory) {
      this.newTask.taskCategoryId = taskCategory.id;
      this.newTask.category = taskCategory.name;
    }
  }

  @action createTaskCategory(categoryName: string) {
    this.createTaskCategoryTask.perform(categoryName);
  }

  @task createTaskCategoryTask = taskFor(async (newCategoryName: string) => {
    if (newCategoryName?.trim() != '') {
      let newCategory = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskCategories,
        this.args.context.abLink
      );

      newCategory.name = newCategoryName;
      newCategory.createdDate = this.pmService.currentDate;
      newCategory.createdBy = this.pmService.currentUserEmail;

      await newCategory.save();

      newCategory.taskCategoryId = newCategory.id;
      await newCategory.save();

      this.newTask.taskCategoryId = newCategory.id;
      this.newTask.category = newCategoryName;

      this.taskCategories.update();
    }
  });

  @action hideCreateOptionOnSamePriorityName(term: string) {
    let existingOption = this.taskPriorities.find(({ name }) => name === term);
    return !existingOption;
  }

  @action selectTaskPriority(taskPriority: any) {
    if (taskPriority) {
      this.newTask.taskPriorityId = taskPriority.id;
      this.newTask.priority = taskPriority.name;
    }
  }

  @action createTaskPriority(priorityName: string) {
    this.createTaskPriorityTask.perform(priorityName);
  }

  @task createTaskPriorityTask = taskFor(async (newPriorityName: string) => {
    if (newPriorityName?.trim() != '') {
      let newPriority = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskPriorities,
        this.args.context.abLink
      );

      newPriority.name = newPriorityName;
      newPriority.createdDate = this.pmService.currentDate;
      newPriority.createdBy = this.pmService.currentUserEmail;
      await newPriority.save();

      newPriority.taskPriorityId = newPriority.id;
      await newPriority.save();

      this.newTask.taskPriorityId = newPriority.id;
      this.newTask.priority = newPriority;

      this.taskPriorities.update();
    }
  });

  @action hideCreateOptionOnSameStatusName(term: string) {
    let existingOption = this.taskStatusesLookup.find(
      ({ name }) => name === term
    );
    return !existingOption;
  }

  @action selectTaskStatus(taskStatus: any) {
    if (taskStatus) {
      this.newTask.taskStatusId = taskStatus.id;
      this.newTask.status = taskStatus.name;
    }
  }

  @action createTaskStatus(statusName: string) {
    this.createTaskStatusTask.perform(statusName);
  }

  @task createTaskStatusTask = taskFor(async (newStatusName: string) => {
    if (newStatusName?.trim() != '') {
      let newStatus = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskStatuses,
        this.args.context.abLink
      );

      newStatus.name = newStatusName;
      newStatus.createdDate = this.pmService.currentDate;
      newStatus.createdBy = this.pmService.currentUserEmail;
      await newStatus.save();

      newStatus.taskStatusId = newStatus.id;
      await newStatus.save();

      this.newTask.taskStatusId = newStatus.id;
      this.newTask.status = newStatus;

      this.taskStatusesLookup.update();
    }
  });

  @action selectTaskCreatedBy(masterResource: any) {
    if (masterResource) {
      this.newTask.createdBy = masterResource.email;
    }
    //this.newTask.createdBy = masterResource.name;
  }

  @action async onSmartRoomFolderSelected(folder: SmartroomFolderModel) {
    if (folder) {
      this.newTask.folderId = folder.id;
      this.newTask.folderNumber = folder.displayString;
      this.newTask.folderName = await this.pmService.getFolderPath(
        this.args.context.smartRoomId,
        folder.id
      );

      this.smartRoomFolderName = this.newTask.folderName;
    } else {
      this.newTask.folderId = null;
      this.newTask.folderNumber = null;
      this.newTask.folderName = null;
      this.smartRoomFolderName = null;
    }
    this.docker.removePopup(this.smartRoomIndexPopup);
  }

  @action showSmartRoomIndex() {
    let appearance = {
      icon: 'folder',
      title: `Select SmartRoom Folder`,
      custom: true,
    };
    let ctx = {
      abLink: this.args.context.abLink,
      smartRoomId: this.args.context.smartRoomId,
      onSelect: this.onSmartRoomFolderSelected,
    };

    this.smartRoomIndexPopup = this.docker.invokePopup(
      'ab-pm/select-sr-folder',
      appearance,
      ctx
    );
  }
}
