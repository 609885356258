import Model, { attr } from '@ember-data/model';

export default class FormExcelSheet extends Model {
  @attr('number') caseId!: number;
  @attr('number') formId!: number;
  @attr('string') sheetName!: string;
  @attr('string') parentSheet!: string;
  @attr('string') parentColumn!: string;
  @attr('boolean') isIncluded!: boolean;
  @attr('number') startIndex!: number;
  @attr('number') endIndex!: number;
  @attr('string') objSheetName!: string;
  @attr('string') parentObjSheetName!: string;
  @attr('string') objParentcolumn!: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/form-excel-sheet': FormExcelSheet;
  }
}