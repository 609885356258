import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import {
  CaseProjectUrl,
  CaseProjectUrlMap,
  CaseProjectUrlOpenMode,
} from 'cing-app/utils/lookups';

//@ts-ignore;
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import Case from './case';
import Abstract from './abstract';

const ProjectUrlValidations = buildValidations({
  typeName: [validator('presence', true)],
  url: [
    validator('presence', true),
    /*
    validator('format', {
        type: 'url'
    })
    */
  ],
});

export default class CaseProjectUrlModel extends Abstract.extend(
  ProjectUrlValidations,
  computedEnumMixin('typeName', 'type', CaseProjectUrl),
  computedEnumMixin('openModeName', 'openMode', CaseProjectUrlOpenMode)
) {
  @service store!: Store;
  @attr('string') declare caseId?: string;
  @attr('string') declare projectId?: string;
  @attr('number') declare type: number;
  @attr('string') declare url: string;
  @attr('string') declare title?: string;
  @attr('number') declare openMode?: number;
  @attr('boolean', { defaultValue: true }) declare isPublic: boolean;
  //@ts-ignore - mixin
  @belongsTo('case') declare case: AsyncBelongsTo<Case>;
  get smartRoomId() {
    if (this.type == CaseProjectUrlMap.SMARTROOM_ID && this.url) {
      let parts = this.url.split(':');
      return parts[0];
    } else {
      return null;
    }
  }
  get smartRoomFolderId() {
    if (this.type == CaseProjectUrlMap.SMARTROOM_ID && this.url) {
      let parts = this.get('url').split(':');
      if (parts.length > 1) {
        return parts[1];
      }
    }
  }
  get smartRoom() {
    if (this.smartRoomId) {
      return this.store.findRecord('smartroom/site', this.smartRoomId);
    } else {
      return null;
    }
  }
  get smartRoomFolder() {
    if (this.smartRoomFolderId) {
      return this.store.queryRecord('smartroom/folder', {
        id: this.smartRoomFolderId,
        siteId: this.smartRoomId,
      });
    }
    return null;
  }
  /*
  newWindowUrl: computed('openMode', 'url', function() {
      if (this.get('openMode') == CaseProjectUrlOpenModeMap.NEW_WINDOW && this.get('url')) {
          let parts = this.get('url').split(':');
          return parts[0];
      }
  }),
  newWindowOptions: computed('openMode', 'url', function() {
      if (this.get('openMode') == CaseProjectUrlOpenModeMap.NEW_WINDOW && this.get('url')) {
          let parts = this.get('url').split(':');

          if (parts.length > 1) {
              return parts[1];
          }
      }
  })        
  */
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'case-project-url': CaseProjectUrlModel;
  }
}
