import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
export default class ColumnCompany extends Component {
  @service('docker-item')
  docker;

  @service
  store;

  click(e) {
    return false;
  }

  @action
  showCompany(companyId) {
    this.store.findRecord('company', companyId).then((company) => {
      this.docker.popupCompany(company);
    });
  }
}