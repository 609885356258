import classic from 'ember-classic-decorator';
import ApplicationSerializer from './application';

@classic
export default class PersonSerializer extends ApplicationSerializer {
  attrs = {
    personInCompanies: { serialize: true },
    phones: { serialize: true },
    emails: { serialize: true },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    person: PersonSerializer;
  }
}
