import { SmartroomConfig as BaseConfig } from 'smex-ui-sr-config'
import { inject as service } from '@ember/service';

export default class SmartroomConfig extends BaseConfig {
  @service('session') session: any;
  @service('server-variables') serverVariables: any;

  get token() {
    return this.session.data.authenticated.access_token;
  }

  get host() {
    return this.serverVariables.get('smartroom-api-url');
  }

  get namespace() {
    return this.serverVariables.get('smartroom-api-namespace');
  }

  get signalRUrl() {
    return this.serverVariables.get('smartroom-signalr-url');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'smartroom-config': SmartroomConfig;
  }
}
