import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DockerItemService from 'cing-app/pods/docker-item/service';
import podNames from 'ember-component-css/pod-names';

interface CrmSearchArgs {
  context: {
    tabId?: string;
    searchQuery?: string;
  };
}

export default class CrmSearch extends Component<CrmSearchArgs> {
  @service('docker-item')
  docker!: DockerItemService;

  @tracked
  tabId = 'search_by_company';

  constructor(owner: unknown, args: CrmSearchArgs) {
    super(owner, args);

    if (this.args.context && this.args.context.tabId) {
      this.tabId = this.args.context.tabId;
    }
  }

  get styleNamespace() {
    return podNames['crm-search'];
  }

  @action
  showAttorneyDetail(record: any) {
    const appearance = {
      icon: 'user',
      title: '<small>Profile:</small> ' + record.get('fullName'),
      size: 'large',
    };
    const context = {
      personId: record.get('id'),
      company: record.get('company'),
    };
    this.docker.invokePopup('contact-detail', appearance, context);
  }

  @action
  showCompanyDetail(record: any) {
    this.docker.popupCompany(record.get('company'));
  }

  @action
  import() {
    this.docker.popupImportContactsToCRM({
      /*
			onComplete: () => {
					this.set('reloadData', true);
			} 
			*/
    });
  }
}
