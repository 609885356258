import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Component from "@ember/component";

const SHOW_AS_OPTION_1 = 1;
const SHOW_AS_OPTION_2 = 2;
const SHOW_AS_OPTION_3 = 3;

@classic
export default class ColumnAttorney extends Component {
  init() {
    super.init();
  }

  click() {
    this.sendAction("showPersonDetail", this.get('record'));
  }

  @computed(
    "record.person.{firstName,lastName,middleName}",
    "column.data.showAsOption"
  )
  get nameView() {
    let person = this.get('record.person');

    if (person.get('id')) {
      if (this.get('column.data.showAsOption.value') === SHOW_AS_OPTION_1) {
        return `${person.get('lastName') + ', ' || ''} ${person.get('firstName') || ''} ${person.get('middleName') || ''}`
      } else if (this.get('column.data.showAsOption.value') === SHOW_AS_OPTION_2) {
        let middle = '';

        if (person.get('middleName')) {
          middle = `${person.get('middleName')}`;
        }

        return `${person.get('firstName') || ''} ${middle} ${person.get('lastName') || ''}`
      } else {
        return `${person.get('firstName') || ''} ${person.get('lastName') || ''}`
      }
    }
  }
}