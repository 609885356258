import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { get, action } from '@ember/object';
export default Component.extend({
  tagName: '',
  actions: {
    clickOnRow(record, event) {
      this.sendAction('onSelectOne', record);
    }
  }
})
