import { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { DocketRating } from 'cing-app/utils/lookups';
import { validator, buildValidations } from 'ember-cp-validations';
import Abstract from './abstract';
import DocketTypeGroup from './docket-type-group';

const Validations = buildValidations({
  name: validator('presence', true),
});

export default class DocketConsolidatedType extends Abstract.extend(
  Validations,
  computedEnumMixin('ratingName', 'rating', DocketRating)
) {
  @attr('string') declare name: string;
  @attr('string') declare description?: string;
  @attr('number') declare rating?: string;
  @hasMany('docket-type-group')
  declare docketTypeGroups: AsyncHasMany<DocketTypeGroup>;
  @attr('boolean') declare deleted?: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'docket-consolidated-type': DocketConsolidatedType;
  }
}
