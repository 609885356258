import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import EmberObject, { action } from '@ember/object';
const StatTypes = ['project-type', 'project-status', 'project-lead', 'project-priority'];
import { ProjectFilterNames } from "cing-app/utils/lookups";

@classic
@classNames('d-flex', 'flex-column', 'flex-grow-1')
export default class ProjectCharts extends Component {
  StatTypes = StatTypes;

  @service
  appEvents;

  selectedStatType = 'project-type';

  init() {
    super.init();
    this.set("enabledFilters", []);
    //this.get('appEvents').subscribe('projectsByTypeChanged', this, 'filterByProjectType');        
  }

  @action
  setProjectsFilter(filterKey, event) {
    let dataPoint = event.dataPoint;

    let filterValue = dataPoint.exploded ? dataPoint.id : undefined;

    let filter = this.enabledFilters.findBy('key', filterKey);

    if (filterValue !== undefined) {
      if (!filter) {
        filter = this.enabledFilters.pushObject(EmberObject.create({
          chart: event.chart,
          key: filterKey,
          name: ProjectFilterNames[filterKey],
        }));
      }

      if (filter.dataPoint && filter.dataPoint !== dataPoint) {
        filter.dataPoint.exploded = false;
      }

      filter.set('dataPoint', dataPoint);

      this.appEvents.publish("projectsByTypeChanged", filterKey, filterValue);
    } else if (filterValue === undefined && dataPoint === filter.dataPoint) {
      this.enabledFilters.removeObject(filter);
      this.appEvents.publish("projectsByTypeChanged", filterKey, filterValue);
    }

  }

  @action
  removeFilter(filter) {
    if (filter.dataPoint) {
      filter.dataPoint.exploded = false;
      filter.chart.render();
    }

    this.enabledFilters.removeObject(filter);
    this.appEvents.publish("projectsByTypeChanged", filter.key, undefined);
  }
}