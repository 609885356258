import {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import Abstract from './abstract';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
// import { CompanyType } from 'cing-app/utils/lookups';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import Email from './email';
import Phone from './phone';
import Address from './address';
import CompanyDomain from './company-domain';
import DebtorProfile from './debtor-profile';
import CompanyAmount from './company-amount';
import CompanyAttribute from './company-attribute';
import Person from './person';
import CompanyType from './company-type';
import { exportable } from 'cing-app/utils/exportable';

const modelValidations = buildValidations({
  name: [validator('presence', true)],
});

export default class Company extends Abstract.extend(modelValidations) {
  @exportable()
  @attr('string')
  declare name: string;
  @attr('number') declare companyTypeAbbreviationId?: number;
  //@ts-ignore - mixin
  @belongsTo('company') declare masterCompany: AsyncBelongsTo<Company>;
  @exportable()
  @attr('string', { defaultValue: '' })
  declare note?: string;
  @attr('string') declare companyProfileId?: string;
  @exportable()
  @attr('string')
  declare description?: string;
  @attr('string') declare masterId?: string;
  // @attr('number') declare type?: number;
  @attr('string') declare dealCloudId?: string;

  @exportable()
  @belongsTo('company-type')
  declare companyType: AsyncBelongsTo<CompanyType>;

  //@ts-ignore - mixin
  @hasMany('email') declare emails: AsyncHasMany<Email>;
  //@ts-ignore - mixin
  @hasMany('phone') declare phones: AsyncHasMany<Phone>;
  //@ts-ignore - mixin
  @hasMany('address') declare addresses: AsyncHasMany<Address>;
  @hasMany('company-domain')
  declare companyDomains: AsyncHasMany<CompanyDomain>;
  @exportable()
  @belongsTo('person', { inverse: null })
  declare primaryContact: AsyncBelongsTo<Person>;

  //@ts-ignore - mixin
  @exportable()
  @belongsTo('debtor-profile')
  declare companyProfile: AsyncBelongsTo<DebtorProfile>;
  @hasMany('company-amount') declare amounts: AsyncHasMany<CompanyAmount>;
  @hasMany('company-attribute')
  declare attributes: AsyncHasMany<CompanyAttribute>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    company: Company;
  }
}
