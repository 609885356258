import Ember from 'ember';
import AuthenticatedRouteMixin from 'ember-smartidentity-auth/mixins/auth-mixin';
import { AclPermissionType } from 'cing-app/utils/lookups';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { ConnectionTypes, ABConnectionTypes } from 'cing-app/utils/lookups';

import {
  Query,
  QueryOperators,
  Filter,
  FilterOperators,
} from 'cing-app/utils/query-builder';

import { inject as service } from '@ember/service';
import { hasData } from 'jquery';
import classic from 'ember-classic-decorator';
import { fetch } from 'fetch';

export default class DashboardRoute extends Route.extend(
  AuthenticatedRouteMixin
) {
  @service session;
  @service serverVariables;
  @service userInstances;

  model() {
    return this.modelTask.perform();
  }

  @task
  findPermission = taskFor(async (roleAssignments, permissionType) => {
    let adminRole = null;

    for (var a = 0; a < roleAssignments.length; a++) {
      let assignment = roleAssignments[a];
      let uRole = await get(assignment, 'aclRole');

      if (uRole) {
        let permissions = await get(uRole, 'aclPermissions');

        let adminPermission = permissions.findBy(
          'permissionTypeId',
          permissionType
        );

        if (adminPermission) {
          adminRole = uRole;
        }
      }
    }

    return adminRole;
  });

  @task
  modelTask = taskFor(async () => {
    this.userInstances.start();

    let pmConnectionQuery = new Query([
      new Filter('type', FilterOperators.EQUAL, ConnectionTypes.APPBUILDER),
      new Filter(
        'ab-type',
        FilterOperators.EQUAL,
        ABConnectionTypes.PROJECT_MANAGEMENT
      ),
      new Filter('project-id', FilterOperators.NULL),
    ]);

    let pmConnection = (
      await this.store.query('appbuilder-link', {
        condition: pmConnectionQuery.serialize(),
        page: {
          size: 1,
        },
      })
    ).firstObject;

    // first get the user
    let user = await this.store.findRecord('user', 'me', {
      include:
        'acl-role-assignments.acl-role.acl-permissions,instance-acl-role-assignments.acl-role.acl-permissions,groups.instance-acl-role-assignments.acl-role.acl-permissions',
    });

    let identityResponse = await fetch(
      `${this.get('serverVariables.authApiUrl')}/api/users/me`,
      {
        headers: {
          Authorization: `Bearer ${this.get(
            'session.data.authenticated.access_token'
          )}`,
        },
      }
    );

    set(this.session, 'authUser', await identityResponse.json());

    // fetch all countries (essentially caching them locally)
    this.store.query('country', {
      page: {
        size: 500,
      },
    });

    set(this.session, 'user', user);

    // check if there's an admin role/permission assigned directly to user
    let adminRole = await this.findPermission.perform(
      (await get(user, 'instanceAclRoleAssignments')).toArray(),
      AclPermissionType.Admin
    );

    // if there's no admin permission directly on user, check if there's one in one of his groups
    if (!adminRole) {
      // container for storing all group role assignments
      let groupRoleAssignments = [];

      // get all user's groups
      let userGroups = (await get(user, 'groups')).toArray();

      // popuplate container with all group role assignments
      for (var a = 0; a < userGroups.length; a++) {
        groupRoleAssignments.pushObjects(
          (await get(userGroups[a], 'instanceAclRoleAssignments')).toArray()
        );
      }

      // now check if one of the role has admin permission
      adminRole = await this.findPermission.perform(
        groupRoleAssignments,
        AclPermissionType.Admin
      );
    }

    // if admin permission was found, set the global session admin flag
    if (adminRole) {
      set(this.session, 'hasAdminRole', true);
    }

    return {
      adminRole,
      user,
      pmConnection,
    };
  });
}
