import { attr } from '@ember-data/model';
import Abstract from './abstract';
import { exportable } from 'cing-app/utils/exportable';
export default class InterestedPartyRole extends Abstract {
  @exportable()
  @attr('string')
  declare name: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'interested-party-role': InterestedPartyRole;
  }
}
