import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('nestedItem')
@classNameBindings('item.isSelected:tree-highlight')
export default class NestedItem extends Component {
  click() {
    this.attrs.selectAction(this.get('item'));
    return false;
  }

  item = undefined;
  dragEndAction = undefined;
  group = 'nested group';
}
