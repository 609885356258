import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  AclPermissionType,
} from 'cing-app/utils/lookups';

import {
  Expressions,
  Filter,
  FilterOperators,
} from "cing-app/mixins/filter-builder";
import { taskFor } from 'ember-concurrency-ts';

import Store from '@ember-data/store';
import UserInstancesService from 'cing-app/pods/user-instances/service';
import { tracked } from '@glimmer/tracking';
import AclRole from 'cing-app/models/acl-role';
import UserGroup from 'cing-app/models/user-group';
import AclRoleAssignment from 'cing-app/models/acl-role-assignment';

interface ManageUserDetailArgs {
  footer: any;
  header: any;
  context: any;
}

export default class ManageUserDetail extends Component<ManageUserDetailArgs> {
  @service store!: Store;
  @service userInstances!: UserInstancesService;

  @tracked
  hideAdvancedSettings = true;


  @tracked
  allAclRoles!: AclRole[];

  @tracked
  aclRoles: AclRole[] = [];

  @tracked
  allUserGroups!: UserGroup[];

  @tracked
  userGroups: UserGroup[] = [];

  get model() {
    return this.args.context.model;
  }


  constructor(owner: any, args: ManageUserDetailArgs) {
    super(owner, args);
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    let query = Expressions.create();
    query.add(Filter.create({
      //@ts-ignore
      name: 'acl-role-permissions.acl-permission.permission-type-id',
      operator: FilterOperators.IN,
      //@ts-ignore
      value: [AclPermissionType.Admin, AclPermissionType.PortalPageRead]
    }));

    let aclRoles = await this.store.query('acl-role', {
      condition: query.serialize(),
      page: {
        size: 1000
      },
      sort: 'description'
    });

    this.allAclRoles = aclRoles.toArray();

    await this.updateAvailableAclRoles.perform();

    query = Expressions.create();

    let userGroups = await this.store.query('user-group', {
      condition: query.serialize(),
      page: {
        size: 1000
      },
      sort: 'name'
    });

    this.allUserGroups = userGroups.toArray();

    await this.updateAvailableUserGroups.perform();
  })

  get disableInteraction() {
    return this.removeUserRoleAssignmentTask.isRunning || this.addUserRoleAssignmentTask.isRunning || this.addUserToGroupTask.isRunning || this.removeUserFromGroupTask.isRunning;
  }

  @task
  updateAvailableAclRoles = taskFor(async () => {
    let filteredAclRoles = this.allAclRoles;
    //let userAclRoles = (yield this.model.hasMany('aclUserRoles').reload()).toArray();
    let userRoleAssignments = (await this.args.context.model.hasMany('instanceAclRoleAssignments').reload()).toArray();

    for (var a = 0; a < userRoleAssignments.length; a++) {
      let userAclRoleAssignment = userRoleAssignments[a];

      filteredAclRoles.removeObject(await get(userAclRoleAssignment, 'aclRole'));
    }

    this.aclRoles = filteredAclRoles;
  })

  @task
  updateAvailableUserGroups = taskFor(async () => {
    let filtereduserGroups = this.allUserGroups;
    let userGroups = (await this.model.hasMany('groups').reload()).toArray();

    for (var a = 0; a < userGroups.length; a++) {
      filtereduserGroups.removeObject(userGroups[a]);
    }

    this.userGroups = filtereduserGroups;
  })

  @task
  addUserRoleAssignmentTask = taskFor(async (aclRole: AclRole) => {
    let user = this.model;

    let aclUserRole = this.store.createRecord("acl-role-assignment", {
      principalType: 1,
      principalId: user.id,
      roleId: aclRole.id,
      resourceId: this.userInstances.current.instanceId,
      resourceType: 255,
    });

    await aclUserRole.save();
    await this.updateAvailableAclRoles.perform();

    if (this.args.context.onUpdate) {
      this.args.context.onUpdate();
    }
  })

  @task
  removeUserRoleAssignmentTask = taskFor(async (aclUserRole: AclRoleAssignment) => {
    await aclUserRole.destroyRecord();

    await this.updateAvailableAclRoles.perform();
  })

  @task
  addUserToGroupTask = taskFor(async (userGroup) => {
    let user = this.model;

    let userInGroup = this.store.createRecord("user-in-group", {
      user: user,
      userGroup: userGroup,
    });

    await userInGroup.save();
    await this.updateAvailableUserGroups.perform();

    if (this.args.context.onUpdate) {
      this.args.context.onUpdate();
    }
  })

  @task
  removeUserFromGroupTask = taskFor(async (userGroup) => {
    await userGroup.destroyRecord();
    await this.updateAvailableUserGroups.perform();
  })

  @action
  removeUserRoleAssignment(roleAssignment: AclRoleAssignment) {
    if (this.disableInteraction) {
      return;
    }

    this.removeUserRoleAssignmentTask.perform(roleAssignment);
  }

  @action
  addUserRoleAssignment(aclRole: AclRole) {
    if (this.disableInteraction) {
      return;
    }

    this.addUserRoleAssignmentTask.perform(aclRole);
  }

  @action
  removeUserFromGroup(userGroup: UserGroup) {
    if (this.disableInteraction) {
      return;
    }

    this.removeUserFromGroupTask.perform(userGroup);
  }

  @action
  addUserToGroup(userGroup: UserGroup) {
    if (this.disableInteraction) {
      return;
    }

    this.addUserToGroupTask.perform(userGroup);
  }

  @action
  toggleAdvancedSettings() {
    this.hideAdvancedSettings = !this.hideAdvancedSettings;
  }
}