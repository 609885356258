import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { Models, SmartroomRightTypeAdapter } from 'smex-ui-sr-models';
import { FolderFileCache } from 'smex-ui-sr-filemanager';
import { tracked } from '@glimmer/tracking';

interface SmartroomCategorySecurityArgs {
  dockerItem: {
    context: {
      siteId: string;
      categoryId: string;
    }
  }
}

export default class SmartroomCategorySecurity extends Component<SmartroomCategorySecurityArgs> {
  @service('store') store!: Store;
  @service('models') models!: Models;
  @service('folder-file-cache') folderFileCache!: FolderFileCache;

  @tracked
  showSaveConfirm = false;

  constructor(owner: any, args: any) {
    super(owner, args);
    this.folderFileCache.clear();
  }

  @action
  rollbackChanges() {
    let allFiles = this.store.peekAll(this.models.getModelNameSiteCat('smartroom/file', this.args.dockerItem.context.siteId, this.args.dockerItem.context.categoryId));
    allFiles.forEach(f => {
      f.rollbackRights();
    });

    let allFolders = this.store.peekAll(this.models.getModelNameSiteCat('smartroom/folder', this.args.dockerItem.context.siteId, this.args.dockerItem.context.categoryId));
    allFolders.forEach(f => {
      f.rollbackRights();
    });
  }

  @task
  saveRights = taskFor(async () => {
    let modelName = this.models.getModelNameSite('smartroom/right-type', this.args.dockerItem.context.siteId);
    let rightAdapter = <SmartroomRightTypeAdapter>this.store.adapterFor(modelName);


    let updatedFiles = this.store.peekAll(this.models.getModelNameSiteCat('smartroom/file', this.args.dockerItem.context.siteId, this.args.dockerItem.context.categoryId))
      .filter(f => f.updatedGivenRight !== f.givenRight);
    let updatedFolders = this.store.peekAll(this.models.getModelNameSiteCat('smartroom/folder', this.args.dockerItem.context.siteId, this.args.dockerItem.context.categoryId))
      .filter(f => f.updatedGivenRight !== f.givenRight);

    await rightAdapter.updateSecurities(updatedFiles, updatedFolders);
    this.showSaveConfirm = false;
  });
}
