import { alias } from '@ember/object/computed';
import Component from "@ember/component";
import EmberObject, { computed } from "@ember/object";
import { inject } from "@ember/service";
import { task, timeout, all } from "ember-concurrency";
import {
  EReportStatus,
  CrmImportSteps,
  ECrmImportStatus
} from 'cing-app/utils/lookups';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

import AbstractImport from "../abstract-import/component";
import TableTheme from "../abstract-import/table-theme";
import ImportRow from "../abstract-import/import-row";

export default AbstractImport.extend({
  store: inject(),
  config: inject(),
  session: inject(),
  docker: inject('docker-item'),
  fileQueue: inject(),
  CrmImportSteps,
  EReportStatus,

  importAction: null,
  currentStep: null,
  importFile: null,
  selectedItems: null,
  include: null,

  themeInstance: computed(function () {
    return TableTheme.create();
  }),

  init() {
    this._super(...arguments);
    this.set('selectedItems', []);
    this.initTask.perform();
    // debug
    /*
    this.store.findRecord('grab-report', '44684b58-726d-446f-8e39-cc9afa5b42f0').then((report) => {
        this.set('importAction', report);
        this.set('currentStep', CrmImportSteps.ReviewContacts);
    });
    */

    this.set('currentStep', CrmImportSteps.UploadImportFile);
  },
  initTask: task(function* () {
    yield this.initAbstract.perform();
  }),

  crmPreviewFilter: computed('currentStep', 'importAction.status', function () {
    if (this.currentStep == CrmImportSteps.ReviewContacts && (!this.importAction || this.get('importAction.status') !== EReportStatus.CrmImportReadyForReview)) {
      throw Error('CRM Preview could not be generated');
    }

    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(Filter.create({
      name: 'grab-request-id',
      operator: FilterOperators.EQUAL,
      value: this.get('importAction.id')
    }));

    return expr.serialize();
  }),

  prepareContactsReview: task(function* () {
    this.set('currentStep', CrmImportSteps.ReviewContacts);

    let columnsInImport = this.get('importAction.note.Columns') ? JSON.parse(this.get('importAction.note.Columns')) : [];

    let columns = this.get('columns');

    for (var a = 0; a < columns.length; a++) {
      let column = columns[a];
      let columnName = columns[a].propertyName;

      if (columnName && columnName.startsWith('rowData.')) {
        columnName = columnName.slice(8);

        if (columnsInImport && columnsInImport.indexOf(columnName) == -1) {
          column.isHidden = true;
        }
      }
    }

    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(Filter.create({
      name: 'grab-request-id',
      operator: FilterOperators.EQUAL,
      value: this.get('importAction.id')
    }));

    let importRows = yield this.store.query('crm-import', {
      condition: expr.serialize(),
      page: {
        size: 10000
      },
      sort: 'import-order'
    })

    this.set('importRows', importRows.toArray());

  }),

  finalizeImportTask: task(function* (finalizeStarted = false) {
    this.set('currentStep', CrmImportSteps.FinalizeImport);

    let allTasks = [];

    for (var a = 0; a < this.importRows.length; a++) {
      let importRow = this.importRows[a];

      if (importRow.hasDirtyAttributes) {
        allTasks.push(this.updateCrmRecord.perform(this.importRows[a]))
      }
    }

    yield all(allTasks);

    const store = this.store;
    const config = this.config;

    let action = this.importAction;

    if (!finalizeStarted && action.get('status') === EReportStatus.CrmImportReadyForReview) {
      let url = `${config.get('APP.api.host')}/${config.get('APP.api.namespace')}/imports/finalize/${action.id}`;

      yield this.ajax.request(url);
      finalizeStarted = true;
    }

    yield timeout(500);

    action = yield store.findRecord('grab-report', action.id, { reload: true });

    if (action.get('isProcessing') || (finalizeStarted && action.status == EReportStatus.CrmImportReadyForReview)) {
      this.finalizeImportTask.perform(finalizeStarted);
      return;
    }

    this.set('currentStep', CrmImportSteps.Result);

    if (this.context.onComplete) {
      console.log("Invoking onComplete callback");
      this.context.onComplete();
    }

    if (this.onClose) {
      this.onClose();
    }
  }),

  approveContactsImportTask: task(function* () {
    let items = this.get('selectedItems').toArray();

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      item.set('importStatus', ECrmImportStatus.Approved);
      itemsToSave.push(this.updateCrmRecord.perform(item));
    }

    yield all(itemsToSave);
  }),

  rejectContactsImportTask: task(function* () {
    let items = this.get('selectedItems').toArray();

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      item.set('importStatus', ECrmImportStatus.Rejected);
      itemsToSave.push(this.updateCrmRecord.perform(item));
    }

    yield all(itemsToSave);
  }),

  updateRoleTask: task(function* (party) {
    this.get('selectedItems').removeObject(party);
    this.get('selectedItems').unshiftObject(party);

    let items = this.get('selectedItems').toArray();

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      item.set('rowData.Role', party.get('rowData.Role'));
      itemsToSave.push(this.updateCrmRecord.perform(item));
    }

    yield all(itemsToSave);

    this.set("setRole", null);
  }),
  removeContactTask: task(function* (party) {
    this.get('selectedItems').removeObject(party);
    this.get('selectedItems').unshiftObject(party);

    let items = this.get('selectedItems').toArray();

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      this.importRows.removeObject(item);
      itemsToSave.push(item.destroyRecord());
    }

    yield all(itemsToSave);

    if (!this.importRows.length) {
      this.set('importAction', null);
      this.set('importFile', null);
      this.set('currentStep', CrmImportSteps.UploadImportFile);
    }
  }),
  updateCrmRecord: task(function* (crmImport) {
    yield crmImport.save();
  }).enqueue().maxConcurrency(4),

  actions: {
    cancel() {
      let queue = this.fileQueue.find('import_parties');

      if (this.importFile) {
        queue.remove(this.importFile);
      }

      this.set('importFile', null);

      if (this.onCancel) {
        this.onCancel();
      }
    },
    onImportFileUploaded(action) {

    },
    selectImportFile(file) {
      this.set('importFile', file);
    },
    removeImportFile(queue, file) {
      queue.remove(file);
    },
    uploadImportFile() {
      this.uploadFileTask.perform(this.importFile);
    },
    downloadSample() {
      window.location.assign(this.config.get('mainUrl') + '/sample_data/smartexchange_parties_import_sample.xlsx');
    },
    approveContactsImport() {
      this.approveContactsImportTask.perform();
    },
    rejectContactsImport() {
      this.rejectContactsImportTask.perform();
    },
    cancelSetRole() {
      this.set('setRole', false);
    },
    doUpdateRole(party) {
      this.get('updateRoleTask').perform(party);
    },
    updateRole(party) {
      if (!this.get('selectedItems')) {
        this.set('selectedItems', [party]);
      }

      this.set("setRole", party);
    },
    removeContacts(party) {
      this.get('removeContactTask').perform(party);
    },
    onFileImported(importAction) {
      // set the import action and proceed to import review
      this.set('importAction', importAction);

      this.prepareContactsReview.perform();
    }
  }
});
