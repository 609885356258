import classic from 'ember-classic-decorator';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import {
  AclPermissionType,
  AclEntityType,
  ProjectTabMap,
  ProjectTab,
  CaseProjectUrlOpenMode,
} from 'cing-app/utils/lookups';
import {
  Expressions,
  FilterOperators,
  Filter,
} from 'cing-app/mixins/filter-builder';
import { isEmpty } from '@ember/utils';
import EmberObject, { computed, get } from '@ember/object';
import { ABConnectionTypes, ConnectionTypes } from 'cing-app/utils/lookups';

const CaseProjectUrlModes = Object.keys(CaseProjectUrlOpenMode);

@classic
class ViewerGroup extends EmberObject {
  enabled = false;
  role = null;
  entityRole = null;
}

export default Component.extend({
  store: inject(),
  serverVariables: inject(),
  session: inject(),
  docker: inject('docker-item'),
  CaseProjectUrlModes,
  ABConnectionTypes,
  ConnectionTypes,
  ProjectTabMap,
  //ProjectTabOptions: Object.keys(ProjectTab).sort(),
  model: alias('context.model'),
  project: alias('context.project'),
  config: inject(),
  onSaveSubTask: null,

  init() {
    this._super();

    this.initTask.perform();
    this.set('tabContext', {
      model: this.model,
    });

    let settings = this.get('project.settings');

    let ProjectTabOptions = Object.keys(ProjectTab).sort();

    if (!get(this.session, 'isBMCUser')) {
      ProjectTabOptions.removeObject('AB Project');
    }

    this.ProjectTabOptions = ProjectTabOptions;

    if (isEmpty(settings.public_tabs)) {
      this.set('project.settings.public_tabs', {});
    }
  },
  initTask: task(function* () {
    let vgQuery = Expressions.create({});
    vgQuery.add(
      Filter.create({
        name: 'acl-permissions.permission-type-id',
        operator: FilterOperators.IN,
        value: [
          AclPermissionType.ProjectTabRead,
          AclPermissionType.ProjectRead,
        ],
      })
    );

    let roles = (yield this.store.query('acl-role', {
      page: {
        size: 1000,
      },
      condition: vgQuery.serialize(),
      include: 'acl-permissions',
      sort: 'description',
    })).toArray();

    let tabRoles = [];
    let projectRoles = [];

    // filter out tab and project roles
    for (var a = 0; a < roles.length; a++) {
      let role = roles[a];

      let permissions = yield role.get('aclPermissions');

      if (
        permissions.findBy('permissionTypeId', AclPermissionType.ProjectTabRead)
      ) {
        tabRoles.push(role);
      }

      if (
        permissions.findBy('permissionTypeId', AclPermissionType.ProjectRead)
      ) {
        projectRoles.push(role);
      }
    }

    let tabEntityRoles = [];

    if (tabRoles.length && this.get('model.id')) {
      let entityRolesQuery = Expressions.create({});
      entityRolesQuery.add(
        Filter.create({
          name: 'resource-id',
          operator: FilterOperators.EQUAL,
          value: this.get('model.id'),
        })
      );

      entityRolesQuery.add(
        Filter.create({
          name: 'resource-type',
          operator: FilterOperators.EQUAL,
          value: AclEntityType.ProjectTab,
        })
      );

      entityRolesQuery.add(
        Filter.create({
          name: 'role-id',
          operator: FilterOperators.IN,
          value: tabRoles.mapBy('id'),
        })
      );

      entityRolesQuery.add(
        Filter.create({
          name: 'principal-id',
          operator: FilterOperators.NULL,
        })
      );

      tabEntityRoles = (yield this.store.query('acl-role-assignment', {
        page: {
          size: 1000,
        },
        condition: entityRolesQuery.serialize(),
      })).toArray();
    }

    let viewerGroups = [];

    for (var a = 0; a < tabRoles.length; a++) {
      let role = tabRoles[a];
      let entityRole = tabEntityRoles.findBy('roleId', role.get('id'));

      viewerGroups.pushObject(
        ViewerGroup.create({
          enabled: entityRole ? true : false,
          entityRole: entityRole,
          role: role,
        })
      );
    }

    this.set('viewerGroups', viewerGroups);
  }),
  saveTask: task(function* () {
    // add the default App Builder instance URL if abProject is set and no instance URL defined
    if (
      this.get('project.abProject') &&
      !this.get('project.settings.ab_instance_url')
    ) {
      this.set(
        'project.settings.ab_instance_url',
        this.serverVariables.get('appBuilderApiUrl')
      );
    }

    yield this.get('model').save();

    yield this.get('project').save();

    if (this.project.fundId) {
      yield (yield this.get('project.fund')).save();
    }

    if (this.context.onSave) {
      this.context.onSave();
    }

    if (this.viewerGroups && this.viewerGroups.length) {
      for (var a = 0; a < this.viewerGroups.length; a++) {
        let vg = this.viewerGroups[a];

        if (vg.enabled && !vg.entityRole) {
          let entityRole = this.store.createRecord('acl-role-assignment', {
            roleId: vg.get('role.id'),
            resourceType: AclEntityType.ProjectTab,
            resourceId: this.get('model.id'),
          });

          yield entityRole.save();
        } else if (!vg.enabled && vg.entityRole) {
          yield vg.entityRole.destroyRecord();
          vg.set('entityRole', null);
        }
      }
    }

    if (this.onSaveSubTask) {
      yield this.onSaveSubTask();
    }

    yield this.trigger('onSave', this.model, this.project, this.context.onSave);

    yield this.initTask.perform();
  }),
  removeTask: task(function* () {
    yield this.get('model').destroyRecord();

    this.set('confirmRemoval', null);

    if (this.context.onSave) {
      this.context.onSave();
    }

    if (this.onClose) {
      this.onClose();
    }
  }),
  actions: {
    save() {
      this.get('saveTask').perform();
    },
    doRemove() {
      this.get('removeTask').perform();
    },
    editCompany(record) {
      // let self = this;
      let appearance = {
        icon: '',
        title: `Edit Company: ${record.get('name')}`,
        size: 'large',
        custom: true,
      };
      let context = {
        company: record,
      };
      this.get('docker').invokePopup('manage-company', appearance, context);
    },
    toggleProjectTab(type) {
      const settingKey = `settings.public_tabs.${type}`;
      let value = this.get('project').get(settingKey);

      if (!value || isEmpty(value)) {
        this.get('project').set(settingKey, true);
      } else {
        this.get('project').set(settingKey, false);
      }
    },
  },
});
