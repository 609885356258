import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import AuthenticatedRouteMixin from 'ember-smartidentity-auth/mixins/auth-mixin';
import classic from 'ember-classic-decorator';

@classic
export default class ProjectTabRoute extends Route.extend(AuthenticatedRouteMixin) {
  @service store;
  @service router;

  model(params, transition) {
    let m = {
      project: this.modelFor('project').project,
      tab: null
    };

    if (params.tab) {
      m.tab = Number(params.tab);
    }

    return m;
  }
}