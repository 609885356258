import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import { USStates } from 'cing-app/utils/us-states-lookup';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { AddressTypesEnum } from 'cing-app/utils/lookups';
import { get } from '@ember/object';

//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

import Company from './company';
import Person from './person';
import CountrySubdivision from './country-subdivision';
import Country from './country';
import DebtorProfile from './debtor-profile';

let StatesEnum: any = {};
let StatesAbbrEnum: any = {};

USStates.forEach((state) => {
  StatesEnum[state.name] = state.id;
  StatesAbbrEnum[state.abbr] = state.id;
});

const modelValidations = buildValidations({
  street: [validator('presence', true)],
});

export default class Address extends Abstract.extend(
  modelValidations,
  computedEnumMixin('addressType', 'addressTypeId', AddressTypesEnum)
) {
  // companyProfile: DS.belongsTo('debtorProfile'),
  @attr('number', { defaultValue: 1 }) declare addressTypeId: number;
  @attr('string') declare stateId?: string;

  //county: DS.attr('string'),
  //place: DS.attr('string'),
  //extra: DS.attr('string'),
  //pobox: DS.attr('string'),
  //line1: DS.attr('string'),
  //line2: DS.attr('string'),
  @attr('string') declare street?: string;
  @attr('string') declare city?: string;
  @attr('string') declare region?: string;
  @attr('string') declare continent?: string;
  @attr('string') declare usGeography?: string;
  @attr('string') declare usGeographyPreferences?: string;
  @attr('string') declare usMetroArea?: string;
  @attr('string') declare postalCode?: string;
  @attr('string') declare companyProfileId?: string;
  @attr('string') declare globalPostalCode?: string;
  @attr('string') declare countryId?: string;
  @attr('string') declare companyId?: string;
  @attr('string') declare personId?: string;
  @attr('string') declare userId?: string;
  @attr('boolean') declare validated?: string;
  @attr('string') declare dealCloudId?: string;
  @attr('string') declare masterId?: string;
  //@ts-ignore - mixin
  @belongsTo('company') declare company: AsyncBelongsTo<Company>;
  //@ts-ignore - mixin
  @belongsTo('company', { inverse: null })
  declare masterCompany: AsyncBelongsTo<Company>;
  //@ts-ignore - mixin
  @belongsTo('person') declare person: AsyncBelongsTo<Person>;
  @belongsTo('country-subdivision')
  declare state: AsyncBelongsTo<CountrySubdivision>;
  @belongsTo('country') declare country: AsyncBelongsTo<Country>;
  @belongsTo('debtor-profile')
  declare companyProfile: AsyncBelongsTo<DebtorProfile>;
  @belongsTo('address') declare master: AsyncBelongsTo<Address>;

  get formatted() {
    let stateAbbr = get(this, 'state.code');
    let addressParts = [];
    let { street, city, postalCode } = this;

    if (street) {
      addressParts.push(street);
    }

    if (city) {
      addressParts.push(city);
    }

    if (postalCode && stateAbbr) {
      addressParts.push(stateAbbr + ' ' + postalCode);
    } else if (stateAbbr) {
      addressParts.push(stateAbbr);
    } else {
      addressParts.push(postalCode);
    }
    return addressParts.join(', ');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    address: Address;
  }
}
