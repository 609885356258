import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';

import AbPmService from 'cing-app/pods/ab-pm/service';

interface AbPmTaskDetailArgs {
  abLink: any;
  smartRoomId: any;
  task: any;
  onTaskAction: any;
}

export default class AbPmTaskDetail extends Component<AbPmTaskDetailArgs> {
  @service('ab-pm') pmService!: AbPmService;

  @tracked isOverviewCollapsed = false;

  constructor(owner: any, args: any) {
    super(owner, args);

    console.log('task-detail constructor');
  }

  @action toggleOverview() {
    this.isOverviewCollapsed = !this.isOverviewCollapsed;
  }

  @action refreshTask() {
    this.args.onTaskAction('refresh-task', this.args.task);
  }

  @action editTask() {
    this.args.onTaskAction('edit-task', this.args.task, this.args.task);
  }
}
