import Model, { attr } from '@ember-data/model';

export default class Category extends Model {
  @attr('string') categoryName!: string;
  @attr('date') createTime!: Date;
  @attr('date') modifyTime!: Date;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/category': Category;
  }
}
