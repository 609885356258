import { ValueFormat } from 'cing-app/models/data-link';
import DS from 'ember-data';

const ValueFormatTransform = DS.Transform.extend({
	deserialize(serialized: ValueFormat) {
		return new ValueFormat(serialized);
	},

	serialize(deserialized: ValueFormat) {
		return deserialized;
	}
});

declare module 'ember-data/types/registries/transform' {
	export default interface TransformRegistry {
		//@ts-ignore
		'value-format': ValueFormatTransform;
	}
}

export default ValueFormatTransform;
