import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject } from '@ember/service';
import Component from "@ember/component";
import { task } from 'ember-concurrency';
import {
  ProjectStatusMap,
  ProjectStatus,
  ProjectFilters
} from "cing-app/utils/lookups";

@classic
export default class ProjectPriority extends Component {
  @inject()
  store;

  chartType = 'doughnut';

  init() {
    super.init();
  }

  @task(function* () {
    let projects = yield this.get('store').query('project', {
      'page[size]': 1000,
      'include': 'project-priority'
    });

    this.set('chartData', this.generateChartData(projects));

    this.$(".chart-container").CanvasJSChart(this.get('chartData'));
  })
  loadProjectsTask;

  didInsertElement() {
    this.get('loadProjectsTask').perform();
  }

  calculatePercentage(dataPoint, total) {
    for (var i = 0; i < dataPoint.length; i++) {
      dataPoint[i].percentage = Math.round(((dataPoint[i].y / total) * 100).toFixed(2));
    }
  }

  generateChartData(projects) {
    /*
    let projectCounts = Object.entries(ProjectStatus).map((entry) => {
        return {
            'label': entry[0],
            'id': entry[1],
            'y': 0,
            'percentage': 0
        }
    });
    */

    let projectCounts = [];

    projects.forEach((project) => {
      var id = project.get('projectPriority.id') || null;
      let stat = projectCounts.findBy('id', id);

      if (!stat) {
        stat = {
          'label': project.get('projectPriority.name') || "Unknown",
          'id': id,
          'y': 0,
          'percentage': 0
        };

        projectCounts.pushObject(stat);
      }

      if (stat) {
        stat.y++;
      }
    });

    projectCounts = projectCounts.filter((pc) => {
      return pc.y;
    });

    this.calculatePercentage(projectCounts, projects.get('length'));

    let options = {
      height: 260,
      animationEnabled: true,
      theme: "light2", //"light1", "light2", "dark1", "dark2"
      data: [
        {
          type: this.get('chartType'),
          neckHeight: '25%',
          indexLabel: "{label} ({y})",
          click: (e) => {
            if (this.setProjectsFilter) {
              this.setProjectsFilter(ProjectFilters.PROJECT_PRIORITY, e);
            }
          },
          dataPoints: projectCounts
        }
      ]
    }

    return options;
  }

  @action
  refresh() {
    this.get('loadProjectsTask').perform();
  }

  @action
  setChartType(chartType) {
    this.set('chartType', chartType);
    this.get('loadProjectsTask').perform();
  }
}