import Docket from './docket';

export default class DocketNestedDocument extends Docket { }


declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'docket-nested-document': DocketNestedDocument;
  }
}
