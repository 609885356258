import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import _ from 'lodash';
import { PartyRole } from "cing-app/utils/lookups";

@classic
export default class Add extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  includes = ['company', 'person', 'email', 'address'];
  searchQuery = '';

  columns = [
    {
      component: "api-table/columns/company",
      propertyName: 'company.name',
      title: "Company Name",
      className: "column-company-name",
      sortedBy: "company.name",
      //component: "case-detail/interested-parties/column-attorney"
    },
    {
      propertyName: 'person.fullName',
      title: "Name",
      className: "column-person-name",
      sortedBy: "person.lastName",
      component: "case-detail/interested-parties/column-attorney"
    },
    {
      propertyName: 'email.emailAddress',
      title: "E-mail",
      className: "column-email",
      sortedBy: "email.emailAddress",
      //component: "case-detail/interested-parties/column-attorney"
    },
    {
      propertyName: 'address.city',
      title: "City",
      className: 'column-city',
      sortedBy: "address.city"
    },
    {
      component: "api-table/select-row-checkbox",
      useFilter: false,
      mayBeHidden: true,
      isHidden: false,
      title: "Select",
      className: "column-checkbox",
      componentForSortCell: "api-table/select-all-rows-checkbox"
    },
  ];

  init() {
    super.init(...arguments);
    this.generateFilter();
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(Filter.create({
      name: 'caseId',
      operator: FilterOperators.EQUAL,
      value: this.get('context.caseId')
    }));

    let searchQuery = this.get('searchQuery');
    if (searchQuery) {
      let searchQueryExpr = Expressions.create({ operator: ExpressionOperators.OR });
      ['address.city', 'email.emailAddress', 'person.lastName', 'company.name'].forEach((propName) => {
        searchQueryExpr.add(Filter.create({
          name: propName,
          operator: FilterOperators.LIKE,
          value: searchQuery
        }));
      });
      expr.add(searchQueryExpr);
    }

    this.set('filter', expr.serialize());
  }

  @computed('includes.[]')
  get include() {
    return this.get('includes').join(",");
  }

  @action
  displayChangedAction(e) {
    console.log("UPDATED: ", e);
    this.set('selectedItems', e.selectedItems);
  }

  @action
  applySelection() {
    if (this.get('context.applySelection')) {
      this.get('context.applySelection')(this.get('selectedItems'));
    }

    this.attrs.onClose();
  }

  @action
  search() {
    this.generateFilter();
  }
}




