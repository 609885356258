import Component from '@glimmer/component';
import { Owner } from "ember-one-way-controls/.node_modules.ember-try/@glimmer/di";

interface CheckArgs {
  record: any;
  column: any;
}

export default class Check extends Component<CheckArgs> {
  constructor(owner: Owner, args: CheckArgs) {
    super(owner, args);
  }

  get check(): boolean {
    return this.args.record[this.args.column.propertyName];
  }
}
