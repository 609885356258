//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
//@ts-ignore
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import ProjectTabSettingsContactsField from './project-tab-settings-contacts-field';

export default class ProjectTabSettingsContacts extends Fragment {
  @fragmentArray('project-tab-settings-contacts-filter')
  declare filters: any[];
  @fragmentArray('project-tab-settings-contacts-field')
  declare columns: ProjectTabSettingsContactsField[];
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-tab-settings-contacts': ProjectTabSettingsContacts;
  }
}
