import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
//@ts-ignore
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { CaseProjectUrlOpenMode } from 'cing-app/utils/lookups';
import PortalPageSettingsItem from './portal-page-settings-item';
import PortalPageSettingsSectionItem from './portal-page-settings-section-item';
import PortalPageSettingsSmartroom from './portal-page-settings-smartroom';

export default class PortalPageSettings extends Fragment.extend(
  computedEnumMixin('EUOpenModeName', 'EUOpenMode', CaseProjectUrlOpenMode)
) {
  @fragmentArray('portal-page-settings-item')
  declare projectTypeFilters: PortalPageSettingsItem[];
  @fragmentArray('portal-page-settings-section-item')
  declare projectSectionFilters: PortalPageSettingsSectionItem[];
  @fragment('portal-page-settings-smartroom', { defaultValue: {} })
  declare smartRoom: PortalPageSettingsSmartroom;
  @attr('number') declare EUOpenMode?: number;
  @attr('string') declare EULink?: string;
  @attr('jsona') declare InquirySections?: any;
  @attr('string') declare connectionId?: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'portal-page-settings': PortalPageSettings;
  }
}
