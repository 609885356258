import Component from '@ember/component';
import { computed, action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { all, timeout } from 'ember-concurrency';
import { EReportStatus } from 'cing-app/utils/lookups';
import classic from 'ember-classic-decorator';

@classic
export default class UploadExcel extends Component {
  classNameBindings = ['isDragOver:drag-over'];
  isDragOver = false;

  @service store;
  @service config;
  @service session;
  @service seActions;

  @task
  *uploadImportFileTask(uploadFile) {
    // initiate parties import
    let importAction = yield this.seActions.importPartiesStepOne(
      uploadFile,
      this.project ? this.project.id : null
    );

    set(this, 'importAction', importAction);

    if (this.onComplete) {
      this.onComplete(importAction);
    }
  }

  @action
  dragOver(event) {
    event.preventDefault();
    this.set('isDragOver', true);
  }

  @action
  dragEnter(event) {
    this.set('isDragOver', true);
    event.preventDefault();
  }

  @action
  dragLeave(event) {
    this.set('isDragOver', false);
    event.preventDefault();
  }

  @action
  dragEnd(event) {
    this.set('isDragOver', false);
  }

  @action
  drop(event) {
    if (this.isDragOver) {
      this.set('isDragOver', false);
      event.preventDefault();

      var files = event.dataTransfer.files;

      if (files.length > 0) {
        let fileInput = this.element.querySelector('input[type="file"]');
        fileInput.files = files;

        this.selectImportFile(files[0]);
      }
    }
  }

  @action
  removeImportFile(queue, file) {
    queue.remove(file);
  }

  @action
  selectImportFile(file) {
    this.set('importFile', file);
    this.uploadImportFileTask.perform(file);
  }

  @action
  onSelectFile(event) {
    this.selectImportFile(event.target.files[0]);
  }

  @action
  clear() {
    let fileInput = this.element.querySelector('input[type="file"]');
    fileInput.files = null;
    fileInput.value = null;
    this.set('importFile', null);
  }
}
