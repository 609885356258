import Component from '@glimmer/component';
import InterestedParty from 'cing-app/models/interested-party';
import { DataSourceColumn } from 'smex-ui-table';

interface ColumnComponentArgs {
  row: InterestedParty,
  column: DataSourceColumn<any>
}


export default class ColumnComponent extends Component<ColumnComponentArgs> { }