import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import BottomWindows from 'cing-app/pods/bottom-windows/service';
import ProjectTabSettingsContacts from 'cing-app/models/project-tab-settings-contacts';
//@ts-ignore
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import { dasherize } from '@ember/string';
import ProjectTabSettingsContactsField from 'cing-app/models/project-tab-settings-contacts-field';
import { ABColumnFormat } from 'cing-app/models/appbuilder-link-column';
import { tracked } from '@glimmer/tracking';
import { Expressions, Filter, FilterOperators } from 'cing-app/mixins/filter-builder';

interface TableViewEditorArgs {
  contactSettings: ProjectTabSettingsContacts;
  onSave: () => void;
  tab: any;
}

type ExtendedProperty = { property: string, isFromExtended: boolean };

export default class TableViewEditor extends Component<TableViewEditorArgs> {
  @service('bottom-windows')
  bottomWindows!: BottomWindows;
  @service('docker-item')
  docker: any;
  @service('store')
  store!: Store;
  @tracked
  error: any;

  @tracked
  properties: ExtendedProperty[];


  constructor(owner: unknown, args: TableViewEditorArgs) {
    super(owner, args);

    let modelClass = this.store.modelFor('interested-party');
    this.properties = Array.from(modelClass.attributes.keys()).filter(a => a !== 'extendedData').map((property: string) => ({ property, isFromExtended: false }));
    this.loadExtendedData.perform();
  }

  @task
  loadExtendedData = taskFor(async () => {
    let expr = Expressions.create();
    expr.add(Filter.create({
      //@ts-ignore
      name: 'entity-name',
      operator: FilterOperators.EQUAL,
      //@ts-ignore
      value: 'InterestedParty'
    }));

    let extendedDataMappings = await this.store.query('extended-data-mapping', {
      condition: expr.serialize(),
      page: {
        size: 1,
        number: 1
      },
    });

    let extendedProperties = extendedDataMappings.firstObject?.config.dynamicProperties.map(dp => ({ property: dp.propertyName, isFromExtended: true })) ?? [];

    this.properties = [...this.properties, ...extendedProperties];

  });

  willDestroy() {
    //@ts-ignore
    // this.args.contactSettings.rollback();
    super.willDestroy();
  }

  @action
  changeProp(propName: 'maxWidth' | 'minWidth' | 'maxHeight', column: ProjectTabSettingsContactsField, evt: Event) {
    let text = (<HTMLInputElement>evt.target).value;
    let value = (text ? parseInt(text) : null);
    if (value !== null && Number.isInteger(value)) {
      column[propName] = value;
    } else {
      //@ts-ignore
      column[propName] = null;
    }
  }

  @action
  reorderItems(columns: ProjectTabSettingsContactsField[]) {
    columns.forEach((col, i) => {
      if (col.displayOrder !== i) {
        col.displayOrder = i;
      }
    });
  }

  @task
  addProperty = taskFor(async (prop: ExtendedProperty) => {
    //@ts-ignore
    let args = this.args;
    let displayOrder = args.contactSettings.columns.length;
    //@ts-ignore
    let newColumn = this.store.createFragment('project-tab-settings-contacts-field', <ProjectTabSettingsContactsField>{
      propertyName: prop.property,
      isFromExtended: prop.isFromExtended,
      label: humanize([dasherize(prop.property)]),
      displayOrder: displayOrder,
      sorting: true,
      advancedFilter: true,
      defaultVisible: true,
      format: new ABColumnFormat({
        style: 'general'
      })
    })

    args.contactSettings.columns.pushObject(newColumn);
  })

  @action
  showFormatEditor(column: ProjectTabSettingsContactsField) {
    let appearance = {
      icon: 'paragraph',
      title: 'Edit Format',
      size: 'medium',
      custom: true
    };

    this.docker.invokePopup('table-view-editor/format', appearance, {
      column
    });
  }

  @action
  removeProperty(column: ProjectTabSettingsContactsField) {
    this.args.contactSettings.columns.removeObject(column);
  }
}
