//@ts-ignore
import BsForm from 'ember-bootstrap/components/bs-form';
import classic from 'ember-classic-decorator';
import { get } from '@ember/object';
@classic
export default class ValidatedBsFrom extends BsForm {
  declare model: any;

  get hasValidator() {
    return !!get(this.model, 'validations.validate');
  }

  async validate(model: any) {
    await get(model, 'validations').validate();
    if (!get(model, 'validations.isValid')) {
      throw new Error();
    }
  }
}
