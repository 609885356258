import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from "@ember/component";
import { task } from 'ember-concurrency';

@classic
export default class FaqEdit extends Component {
  @task(function* (model) {
    model.set('projectId', this.get('project.id'));
    yield model.save();

    this.sendAction("refresh");
    this.sendAction("onClose");
  })
  saveTask;

  @task(function* (model) {
    yield model.destroyRecord();

    this.sendAction("refresh");
    this.sendAction("onClose");
  })
  removeTask;

  @action
  save(model) {
    this.get("saveTask").perform(model);
  }

  @action
  remove(model) {
    this.get("removeTask").perform(model);
  }
}