import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import FilterBuilder from 'cing-app/mixins/filter-builder';

@classic
@tagName('a')
export default class Case extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  click() {
    this.get('store').findRecord('case', this.get('record.id')).then((record) => {
      console.log(this.get('record'));
      console.log(record);
      console.log("CASE ID: ", record.get('id'));
      let appearance = {
        icon: '',
        title: `Project: ${record.get('name')}`,
        size: 'large',
        id: record.get('id')
      };
      this.get('docker').invokePopup('case-detail', appearance, { recordId: record.get('id') });
    })
  }
}

