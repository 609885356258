import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DockerItemService from 'cing-app/pods/docker-item/service';
import Project from 'cing-app/models/project';
import Person from 'cing-app/models/person';
import Company from 'cing-app/models/company';

interface ColumnProjectLeadArgs {
  record: Project;
  column: any;
}
export default class ColumnProjectLead extends Component<ColumnProjectLeadArgs> {
  @service('docker-item')
  docker!: DockerItemService;

  get projectLeadName() {
    return this.args.record.get(this.args.column.propertyName);
  }

  @action
  showDetail(e: Event) {
    e.preventDefault();
    this.args.record.get('projectLead').then((projectLead: Person) => {
      if (!this.isDestroyed) {
        projectLead.get('company').then((company: Company) => {
          if (!this.isDestroyed) {
            this.docker.popupPerson(projectLead, company);
          }
        });
      }
    })
  }
}