import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, observer } from '@ember/object';
import { lastValue, task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import { alias } from '@ember/object/computed';
import { pluralize } from 'ember-inflector';
import moment from 'moment';

//@ts-ignore
import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';
import { capitalize, camelize } from '@ember/string';
import AppBuilder, {
  ABModelFieldMeta,
  ABModelMeta,
} from 'cing-app/pods/appbuilder/service';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';
import Case from 'cing-app/models/appbuilder-link/case';
import tableCommon from 'cing-app/mixins/table-common';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { cached } from 'tracked-toolbox';
import DockerItemService from 'cing-app/pods/docker-item/service';

interface IArgs {
  projectTab: any;
  project: any;
  objectName?: string;
  ABLink?: AppbuilderLink;
  parentModel?: any;
  key?: string;
  parentSheetName?: string;
}

export default class AbProject extends Component<IArgs> {
  @service
  config: any;
  @service serverVariables: any;
  @alias('config.APP.api.host') host!: string;
  @alias('config.APP.api.namespace') namespace!: string;
  @service('store')
  store!: Store;
  @service('appbuilder')
  appbuilder!: AppBuilder;
  //@ts-ignore
  @service session;
  @service('docker-item')
  docker!: DockerItemService;
  @tracked
  error: any;
  @tracked
  filterFormDefinition: any;
  @tracked
  advancedFilterFormDefinition: any;
  @tracked
  filter = {};
  @tracked
  advancedFilter = {};

  @tracked
  advancedFilterOpened = false;

  @tracked
  recordToRemove: any;
  @tracked
  query: any;
  @tracked
  advancedQuery: any;

  @tracked selectedRows: any;
  @tracked dataSource?: ApiDataSource<any>;
  // @tracked
  // formDefinition?: SmexUIFormDesignerDefinition;
  @tracked
  modelMeta?: ABModelMeta;
  @tracked
  exportUrl?: string;
  @tracked
  ABLink?: AppbuilderLink;

  get objectName() {
    return this.args.objectName || this.ABLink?.ABObjectName || '';
  }

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);

    this.selectedRows = [];
    this.initTask.perform();
  }

  @action
  openCrmContact(email: string) {
    this.docker.openSearchCrmByContact('search_by_contact', email);
  }

  createFilterDefinition() {
    if (!this.modelMeta) {
      return;
    }

    let buttons = {
      customClass: 'text-right',
      key: 'fieldset',
      type: 'fieldset',
      label: '',
      input: false,
      tableView: false,
      components: [
        {
          label: 'Search',
          showValidations: false,
          customClass: 'd-inline-block',
          tableView: false,
          type: 'button',
          input: true,
          saveOnEnter: false,
          hideOnChildrenHidden: false,
          action: 'event',
          event: 'customSubmit',
        },
        {
          label: 'Clear',
          showValidations: false,
          customClass: 'd-inline-block',
          tableView: false,
          type: 'button',
          input: true,
          saveOnEnter: false,
          theme: 'secondary',
          action: 'event',
          event: 'customReset',
        },
      ],
    };

    let filterForm = {
      display: 'form',
      components: [],
    };
    let advancedFilterForm = {
      display: 'form',
      components: [
        {
          label: 'Text Field',
          placeholder: 'Search Properties',
          hideLabel: true,
          tableView: true,
          key: '__search',
          type: 'textfield',
          input: true,
        },
      ],
    };

    let availableProperties =
      this.ABLink?.columns.filter((c) => c.objectName === this.objectName) ??
      [];

    for (let availableProperty of availableProperties) {
      let metaField = this.modelMeta.fields.find(
        (field: ABModelFieldMeta) =>
          availableProperty.propertyName === field.property
      );
      if (metaField) {
        if (availableProperty.advancedFilter) {
          let comp = this.createFilterInput(metaField, availableProperty);
          if (comp.validate) {
            comp.validate.required = false;
          }
          if (comp.type === 'email') {
            comp.type = 'textfield';
          }
          let newTab = {
            customConditional: `show = (!data.__search || '${
              availableProperty.label ?? metaField.property
            }'.toLowerCase().includes(data.__search.toLowerCase()))`,
            collapsible: true,
            key: 'panel',
            type: 'panel',
            label: 'Advanced Filter',
            input: false,
            customClass: 'filter-panel',
            tableView: false,
            collapsed: true,
            components: [],
          };
          newTab.components.push(comp);
          newTab.title = availableProperty.label ?? metaField.property;
          comp.hideLabel = true;
          advancedFilterForm.components.push(newTab);
        }

        if (availableProperty.simpleFilter) {
          let comp = this.modelMeta?.convertControlTypeToSchema(metaField);
          comp.label = availableProperty.label ?? metaField.property;
          if (comp.validate) {
            comp.validate.required = false;
          }
          if (comp.type === 'email') {
            comp.type = 'textfield';
          }
          filterForm.components.push(comp);
          comp.customClass = 'd-inline-block filter-grid';
        }
      }
    }

    if (filterForm.components.length) {
      filterForm.components.push(buttons);
      this.filterFormDefinition = filterForm;
    } else {
      this.filterFormDefinition = undefined;
    }

    if (advancedFilterForm.components.length > 1) {
      advancedFilterForm.components.push(buttons);
      this.advancedFilterFormDefinition = advancedFilterForm;
    } else {
      this.advancedFilterFormDefinition = undefined;
    }
  }

  createFilterInput(
    field: ABModelFieldMeta,
    prop: ITableViewDefinitionProperty
  ) {
    if (
      ['text', 'textarea', 'email', 'tel', 'ssn', 'url'].includes(
        field.formElement?.controlType
      )
    ) {
      return {
        label: 'Data Grid',
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        clearOnHide: false,
        reorder: false,
        addAnotherPosition: 'bottom',
        layoutFixed: false,
        enableRowGroups: false,
        initEmpty: false,
        tableView: false,
        customClass: 'filter-grid',
        defaultValue: [{}],
        key: field.property,
        type: 'datagrid',
        input: true,
        components: [
          {
            hideLabel: true,
            clearOnHide: false,
            searchEnabled: false,
            label: 'Operator',
            widget: 'html5',
            tableView: true,
            data: {
              values: [
                {
                  label: 'Starts with',
                  value: FilterOperators.START_WITH,
                },
                {
                  label: 'Ends with',
                  value: FilterOperators.END_WITH,
                },
                {
                  label: 'Equal',
                  value: FilterOperators.EQUAL,
                },
                {
                  label: 'Not Equal',
                  value: FilterOperators.NOT_EQUAL,
                },
                {
                  label: 'Contains',
                  value: FilterOperators.LIKE,
                },
                {
                  label: "Doesn't contain",
                  value: FilterOperators.NOT_LIKE,
                },
              ],
            },
            selectThreshold: 0.3,
            validate: {
              onlyAvailableItems: false,
            },
            key: 'operator',
            type: 'select',
            indexeddb: {
              filter: {},
            },
            input: true,
            defaultValue: FilterOperators.LIKE,
          },
          {
            clearOnHide: false,
            hideLabel: true,
            label: prop.label ?? field.property,
            tableView: true,
            key: 'value',
            type: 'textfield',
            input: true,
          },
        ],
      };
    } else if (
      ['amount', 'number', 'autoincrement'].includes(
        field.formElement?.controlType
      )
    ) {
      return {
        label: 'Data Grid',
        clearOnHide: false,
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        reorder: false,
        addAnotherPosition: 'bottom',
        layoutFixed: false,
        enableRowGroups: false,
        initEmpty: false,
        tableView: false,
        customClass: 'filter-grid',
        defaultValue: [{}],
        key: field.property,
        type: 'datagrid',
        input: true,
        components: [
          {
            clearOnHide: false,
            searchEnabled: false,
            hideLabel: true,
            label: 'Operator',
            widget: 'html5',
            tableView: true,
            data: {
              values: [
                {
                  label: 'Greater than',
                  value: FilterOperators.GREATER_THAN_OR_EQUAL,
                },
                {
                  label: 'Less than',
                  value: FilterOperators.LESS_THAN_OR_EQUAL,
                },
                {
                  label: 'Equal',
                  value: FilterOperators.EQUAL,
                },
                {
                  label: 'Not Equal',
                  value: FilterOperators.NOT_EQUAL,
                },
              ],
            },
            selectThreshold: 0.3,
            validate: {
              onlyAvailableItems: false,
            },
            key: 'operator',
            type: 'select',
            indexeddb: {
              filter: {},
            },
            input: true,
            defaultValue: FilterOperators.EQUAL,
          },
          {
            clearOnHide: false,
            hideLabel: true,
            label: prop.label ?? field.property,
            mask: false,
            spellcheck: true,
            tableView: false,
            delimiter: false,
            requireDecimal: false,
            inputFormat: 'plain',
            key: 'value',
            type: 'number',
            input: true,
          },
        ],
      };
    } else if (['date'].includes(field.formElement?.controlType)) {
      return {
        label: 'Data Grid',
        clearOnHide: false,
        // "conditionalAddButton": "show=value.length<4",
        hideLabel: true,
        reorder: false,
        addAnotherPosition: 'bottom',
        layoutFixed: false,
        enableRowGroups: false,
        initEmpty: false,
        tableView: false,
        customClass: 'filter-grid',
        defaultValue: [{}],
        key: field.property,
        type: 'datagrid',
        input: true,
        components: [
          {
            clearOnHide: false,
            searchEnabled: false,
            hideLabel: true,
            label: 'Operator',
            widget: 'html5',
            tableView: true,
            data: {
              values: [
                {
                  label: 'Greater than',
                  value: FilterOperators.GREATER_THAN_OR_EQUAL,
                },
                {
                  label: 'Less than',
                  value: FilterOperators.LESS_THAN_OR_EQUAL,
                },
                {
                  label: 'Equal',
                  value: FilterOperators.EQUAL,
                },
                {
                  label: 'Not Equal',
                  value: FilterOperators.NOT_EQUAL,
                },
              ],
            },
            selectThreshold: 0.3,
            validate: {
              onlyAvailableItems: false,
            },
            key: 'operator',
            type: 'select',
            indexeddb: {
              filter: {},
            },
            input: true,
            defaultValue: FilterOperators.GREATER_THAN_OR_EQUAL,
          },
          {
            clearOnHide: false,
            hideLabel: true,
            label: prop.label ?? field.property,
            format: field.formElementFormat
              ?.get('dateFormat')
              ?.replace('ampm', 'a'),
            mask: false,
            spellcheck: true,
            tableView: false,
            enableMinDateInput: false,
            datePicker: {
              disableWeekends: false,
              disableWeekdays: false,
            },
            enableMaxDateInput: false,
            key: 'value',
            type: 'datetime',
            input: true,
            widget: {
              type: 'calendar',
              displayInTimezone: 'viewer',
              locale: 'en',
              useLocaleSettings: false,
              allowInput: true,
              mode: 'single',
              enableTime: true,
              noCalendar: false,
              format: field.formElementFormat
                ?.get('dateFormat')
                ?.replace('ampm', 'a'),
              hourIncrement: 1,
              minuteIncrement: 1,
              time_24hr: false,
              minDate: null,
              disableWeekends: false,
              disableWeekdays: false,
              maxDate: null,
            },
          },
        ],
      };
    } else {
      let comp = this.modelMeta?.convertControlTypeToSchema(field);
      // if(comp.type==='select'){

      // }
      return comp;
    }
  }

  @action
  filterChanged(isAdvanced: boolean, form: any, submission: any) {
    if (isAdvanced) {
      this.advancedFilter = submission;
      this.advancedQuery = this.createQuery(this.advancedFilter);
    } else {
      this.filter = submission;
      this.query = this.createQuery(this.filter);
    }

    this.createExportUrl();
    this.dataSource?.refresh();
  }

  @action
  createQuery(submission: any) {
    let query = Expressions.create({
      operator: ExpressionOperators.AND,
    });
    let availableProperties =
      this.ABLink?.columns.filter((c) => c.objectName === this.objectName) ??
      [];
    for (let key in submission) {
      if (key === 'submit' || key.startsWith('__')) {
        continue;
      }
      let value = submission[key];

      let formatValue = (val: string) => {
        if (!val) {
          return val;
        }
        let isDate =
          availableProperties.find(
            (p) => p.propertyName === key && p.objectName === this.objectName
          )?.format?.style === 'date';

        if (isDate) {
          return moment(val).utc().format('YYYY-MM-DDTHH:mm:ss');
        } else {
          return val;
        }
      };

      if (value) {
        if (Array.isArray(value)) {
          for (let val of value) {
            if (!val.value) {
              continue;
            }
            query.add(
              Filter.create({
                name: dasherize(key),
                operator: val.operator,
                value: formatValue(val.value),
              })
            );
          }
        } else {
          query.add(
            Filter.create({
              name: dasherize(key),
              operator:
                typeof value === 'number'
                  ? FilterOperators.EQUAL
                  : FilterOperators.LIKE,
              value: formatValue(value),
            })
          );
        }
      }
    }

    if (query.expressions.length) {
      return query;
    } else {
      return undefined;
    }
  }

  @action
  clearFilter(/*form: any*/) {
    // form.emit('resetForm');
    this.filter = {};
    this.advancedFilter = {};
    this.query = undefined;
    this.advancedQuery = undefined;
    this.dataSource?.refresh();
  }

  @task
  initTask = taskFor(async () => {
    let tab = this.args.projectTab;

    if (tab.settings.ABLinkId) {
      try {
        if (this.args.ABLink) {
          this.ABLink = this.args.ABLink;
        } else {
          this.ABLink = await this.store.findRecord(
            'appbuilder-link',
            tab.settings.ABLinkId,
            { include: 'appbuilder-link-columns', reload: true }
          );
        }

        this.modelMeta = this.appbuilder.getModelMeta(
          this.ABLink,
          this.objectName
        );
        this.dataSource = new ApiDataSource(
          50,
          false,
          this.modelMeta.modelPath,
          this.session.authUser.email,
          this.loadDataTask,
          100,
          this.columns,
          null,
          {
            modelName: this.modelMeta.modelPath,
            store: this.store,
          }
        );

        if (this.layoutDefinition) {
          this.dataSource.onRowClick = this.viewRecord;
        }

        this.createFilterDefinition();

        this.createExportUrl();
      } catch (e) {
        this.error = e;
      }
    }
    if (!tab.settings.ABLinkId) {
      this.error = 'AB project does not exist.';
    }
  });

  createExportUrl() {
    // let sortColumn = columns.find((col) => {
    //   return col.sort;
    // });

    let query: any = {
      // 'page[size]': 10000,
      // 'page[number]': 1
      fields: this.dataSource?.columns
        .map((c) => `${c.options.dasherizedProperty}::${c.label}`)
        .join(','),
    };

    let condition = Expressions.create({
      operator: ExpressionOperators.AND,
    });
    if (this.query) {
      condition.add(this.query);
    }
    if (this.advancedQuery) {
      condition.add(this.advancedQuery);
    }

    // if (sortColumn) {
    //   query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${dasherize(sortColumn.id)}`
    // }
    if (this.args.parentModel && this.args.key && this.args.parentSheetName) {
      let sheet = this.ABLink?.ABCase.formExcelSheets.find(
        (s) => s.objSheetName === this.args.parentSheetName
      );
      // query[`filter[${this.args.key}]`] = 'eq:' + this.args.parentModel[sheet.objParentcolumn];
      condition.add(
        Filter.create({
          name: this.args.key,
          operator: FilterOperators.EQUAL,
          value: this.args.parentModel[camelize(sheet.objParentcolumn)],
        })
      );
    }

    query.condition = condition.serialize();
    let url = `${this.host}/${this.namespace}/${pluralize(
      this.modelMeta?.modelPath.replace('appbuilder/', '')
    )}/excel?`;
    // for (let key in query) {
    //   url.searchParams.append(key, query[key]);
    // }
    // url.searchParams.append('condition', condition.serialize());

    return url + new URLSearchParams(query).toString();
  }

  @action
  export(fallbackFileName = null) {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.responseType = 'arraybuffer';

    let exportUrl = this.createExportUrl();

    xhr.open('GET', exportUrl);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${this.session.data.authenticated.access_token}`
    );

    xhr.onload = function () {
      if (this.status === 200) {
        let filename = '';
        var disposition = xhr.getResponseHeader('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
        }

        if (!filename && fallbackFileName) {
          filename = fallbackFileName;
        }

        var type = xhr.getResponseHeader('Content-Type');

        var blob = new Blob([this.response], { type: type });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement('a');
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
              window.location = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location = downloadUrl;
          }

          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      }
    };

    xhr.send();

    /*
    var iframe = $("<iframe/>").appendTo($("#downloadiframes"));
    iframe.attr('src',exportUrl);
    */
  }

  @task
  loadDataTask = taskFor(async (columns, pageSize, pageNumber, options) => {
    let sortColumn = columns.find((col) => {
      return col.sort;
    });

    let query: any = {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    };

    let condition = Expressions.create({
      operator: ExpressionOperators.AND,
    });
    if (this.query) {
      condition.add(this.query);
    }
    if (this.advancedQuery) {
      condition.add(this.advancedQuery);
    }

    if (sortColumn) {
      query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${dasherize(
        sortColumn.id
      )}`;
    }
    if (this.args.parentModel && this.args.key && this.args.parentSheetName) {
      let sheet = this.ABLink?.ABCase.formExcelSheets.find(
        (s) => s.objSheetName === this.args.parentSheetName
      );
      // query[`filter[${this.args.key}]`] = 'eq:' + this.args.parentModel[sheet.objParentcolumn];
      condition.add(
        Filter.create({
          name: this.args.key,
          operator: FilterOperators.EQUAL,
          value: this.args.parentModel[camelize(sheet.objParentcolumn)],
        })
      );
    }
    query.condition = condition.serialize();

    let rows = await this.store.query(options.modelName, query);

    let result = rows.toArray();

    result.meta = {
      totalCount: rows.meta['total-count'],
    };

    return result;
  });

  @cached
  get columns() {
    if (!this.modelMeta) {
      return undefined;
    }

    let cols: DataSourceColumn<any>[] = [];

    let availableProperties =
      this.ABLink?.columns
        .sortBy('displayOrder')
        .filter((c) => c.objectName === this.objectName) ?? [];

    for (let availableProperty of availableProperties) {
      let metaField = <ABModelFieldMeta>(
        this.modelMeta.fields.find(
          (field: ABModelFieldMeta) =>
            availableProperty.propertyName === field.property
        )
      );
      cols.pushObject(
        new DataSourceColumn({
          id: metaField.property,
          label: availableProperty.label || metaField.property,
          getValue: (row: any) => {
            let val = row[metaField.property];
            if (metaField.formElement?.controlType === 'checkboxes') {
              if (val) {
                let result = [];
                for (let key in val) {
                  if (val[key] === true) {
                    result.push(key);
                  }
                }
                if (result.length) {
                  return result.join(', ');
                }
              }
            } else if (
              metaField.formElement?.controlType === 'multipleSelect'
            ) {
              if (val) {
                return val.join(', ');
              }
            } else {
              // if (availableProperty.format) {
              // 	return availableProperty.format.formatValue(val);
              // } else {
              return val;
              // }
            }
          },
          hidden: !availableProperty.defaultVisible,
          valueComponent: 'table-text-column',
          minWidth: availableProperty.minWidth,
          maxWidth: availableProperty.maxWidth,
          sortingEnabled: availableProperty.sorting,
          headerClass: this.getAlignHeaderClass(availableProperty.textAlign),
          valueClass:
            this.getAlignClass(availableProperty.textAlign) +
            ' ' +
            (availableProperty.wrapText ? '' : 'text-nowrap'),
          options: {
            dasherizedProperty: metaField.formElement?.objFieldName,
            maxHeight: availableProperty.maxHeight,
            format: availableProperty.format,
          },
        })
      );
    }

    return cols;
  }

  getAlignClass(textAlign: 'L' | 'C' | 'R') {
    switch (textAlign) {
      default:
      case 'L':
        return 'text-left';
      case 'C':
        return 'text-center';
      case 'R':
        return 'text-right';
    }
  }
  getAlignHeaderClass(textAlign: 'L' | 'C' | 'R') {
    switch (textAlign) {
      default:
      case 'L':
        return 'justify-content-start';
      case 'C':
        return 'justify-content-center';
      case 'R':
        return 'justify-content-end';
    }
  }

  @action
  fdUpdated() {
    this.dataSource?.refresh();
  }

  @action
  columnsUpdated() {
    this.initTask.perform();
  }

  // colsUpdated = observer('ABLink.columns.length,ABLink.columns.@each.{label,defaultVisible,simpleFilter,advancedFilter,sorting,textAlign,displayOrder}', function () {
  //   this.initTask.perform();
  // });

  get formDefinition() {
    return (this.ABLink?.forms ?? {})[this.objectName];
  }

  get layoutDefinition() {
    return (this.ABLink?.views ?? {})[this.objectName];
  }

  @action
  getActions(/*row:any - if actions depends on row data then it is used*/) {
    let actions = [];

    if (this.layoutDefinition) {
      actions.push({
        label: 'View',
        icon: 'eye',
        action: this.viewRecord,
      });
    }
    if (this.formDefinition) {
      actions.push({
        // disabledMessage: 'Sorry',
        label: 'Edit',
        icon: 'pencil-alt',
        action: this.editRecord,
      });
      actions.push({
        label: 'Remove',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (item: any) => {
          this.recordToRemove = item;
        },
      });
    } else {
      actions.push({
        label: 'Remove',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (item: any) => {
          this.recordToRemove = item;
        },
      });
    }
    return actions;
  }

  @task
  removeRecord = taskFor(async () => {
    try {
      this.error = undefined;
      await this.recordToRemove.destroyRecord();
      this.recordToRemove = undefined;
      this.dataSource?.refresh();
    } catch (err) {
      this.error = err;
    }
  });

  @action
  editRecord(row: any) {
    let appearance = {
      id: 'project-detail/ab-project/form' + this.objectName + row.get('id'),
      title: '<small>Edit:</small> ' + row.get('id'),
      size: 'medium',
      custom: true,
    };

    this.docker.invokePopup('project-detail/ab-project/form', appearance, {
      model: row,
      form: this.formDefinition,
    });
  }

  @action
  viewRecord(row: any) {
    let appearance = {
      id: 'project-detail/ab-project/view' + this.objectName + row.get('id'),
      title: '<small>View:</small> ' + row.get('id'),
      size: 'large',
      custom: true,
    };

    let formDefinition = this.formDefinition;
    let layoutDefinition = this.layoutDefinition;
    this.docker.invokePopup('project-detail/ab-project/view', appearance, {
      projectTab: this.args.projectTab,
      project: this.args.project,
      ABLink: this.ABLink,
      model: row,
      objectName: this.objectName,
      modelMeta: this.modelMeta,
      layout: layoutDefinition,
      onEditClick: formDefinition
        ? () => {
            this.editRecord(row);
          }
        : undefined,
    });
  }

  @action
  addRecord() {
    let modelClass = this.modelMeta?.modelPath;
    if (modelClass) {
      let newRecord = this.store.createRecord(modelClass);

      let appearance = {
        id: 'project-detail/ab-project/form' + this.objectName,
        title: '<small>Create Record</small>',
        size: 'medium',
        custom: true,
      };

      this.docker.invokePopup('project-detail/ab-project/form', appearance, {
        model: newRecord,
        form: this.formDefinition,
        onSave: () => {
          this.dataSource?.refresh();
        },
      });
    }
  }
}
