import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { set, action } from '@ember/object';
import { task } from 'ember-concurrency';
import { SmexUIFormDesignerDefinition } from 'smex-ui-form-designer';
import { taskFor } from 'ember-concurrency-ts';
import { Formio } from 'formiojs';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';

interface IArgs {
  onClose: () => void;
  context: {
    form: any;
    model: any;
    onSave?: () => void;
  }
}

export default class FormComponent extends Component<IArgs> {
  @service('store')
  store!: Store;
  @tracked
  error: any;
  @tracked
  form;

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);
  }

  @task
  initForm = taskFor(async (el: HTMLElement) => {
    let form = await Formio.createForm(el, this.args.context.form);
    let submission: { data: any } = { data: {} };
    let modelClass = this.store.modelFor(this.args.context.model.constructor.modelName);

    for (let key of modelClass.attributes.keys()) {
      submission.data[key] = this.args.context.model[key];
    }
    form.submission = submission;
    form.on('submit', (submission: any) => {
      for (let key in submission.data) {
        if (key !== 'save' && key !== 'cancel') {
          this.args.context.model[key] = submission.data[key];
        }
      }
      return this.saveTask.perform();
    });
    form.on('reset', (data: any) => {
      this.args.onClose();
    });
    this.form = form;
  });

  @action
  changeModel(value: any, model: any, property: string) {
    set(model, property, value);
  }

  @action
  emitSubmit() {
    this.form.emit('submit');
  }

  @task
  saveTask = taskFor(async () => {
    try {
      this.error = '';
      await this.args.context.model.save();
      if (this.args.context.onSave) {
        this.args.context.onSave();
      }
      this.args.onClose();
    }
    catch (err) {
      throw new Error(`Status: ${err.errors[0].status}; ${err.errors[0].title}`);
    }
  });

  @action
  close() {
    this.args.context.model.rollbackAttributes();
    this.args.onClose();
  }

}