import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
//@ts-ignore
import _SessionService from 'cing-app/pods/session/service';

interface LocationsArgs {
  company: any;
  locations: any;
  editAddress: any;
}

export default class Locations extends Component<LocationsArgs> {
  @service store!: Store;
  @service config: any;
  @service session!: _SessionService;

  @tracked
  collapsed: boolean = true;

  @tracked
  confirmRemoval: any = null;

  @action
  toggle() {
    this.collapsed = !this.collapsed;
  }

  @action
  doRemoveRecord(record: any) {
    record.destroyRecord().then((response: any) => {
      this.confirmRemoval = null;
      this.args.locations.removeObject(record);
    });
  }
}
