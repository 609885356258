import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import User from './user';
import UserGroup from './user-group';

export default class UserInGroup extends Abstract {
  @attr('string') declare userId: string;
  @attr('string') declare userGroupId: string;
  @belongsTo('user') declare user: AsyncBelongsTo<User>;
  @belongsTo('user-group') declare userGroup: AsyncBelongsTo<UserGroup>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-in-group': UserInGroup;
  }
}
