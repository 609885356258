import DS from 'ember-data';
import { FormRow, SmexUIFormDesignerDefinition } from 'smex-ui-form-designer';

export type FormDefinitions = { [objectName: string]: SmexUIFormDesignerDefinition };
const Formdefinitions = DS.Transform.extend({
  deserialize(json: any) {
    let fds: FormDefinitions = {};

    for (let objectName in json) {
      let fd = <SmexUIFormDesignerDefinition>json[objectName];
      fd.rows = fd.rows.map(r => new FormRow(r));
      fds[objectName] = fd;
    }

    return fds;
  },

  serialize(fds: FormDefinitions) {
    return JSON.parse(JSON.stringify(fds));
  }
});

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    //@ts-ignore
    'formdefinitions': Formdefinitions;
  }
}

export default Formdefinitions;
