import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import {
  GenericAlertTypes,
  GenericAlertContentType,
  GenericAlertDocumentLinkType
} from 'cing-app/utils/lookups';

const CreateGenericAlertValidations = buildValidations({
  subject: validator('presence', true),
  template: validator('presence', true),
  alertTemplate: validator('presence', true),
  //message: validator('presence', true),
  documentLinkType: validator('presence', true)
})

export default Model.extend(CreateGenericAlertValidations, {
  subject: attr('string'),
  emailFrom: attr('string'),
  emailFromAlias: attr('string'),
  // reference to the notification template record
  template: attr(),
  // reference to the alert template record
  alertTemplate: attr(),
  redirectUrlId: attr('number'),
  message: attr('string'),
  templateText: attr('string'),
  event: attr(),
  attachDocuments: attr('boolean', { defaultValue: true }),
  includeRecipientsWithNoDocument: attr('boolean', { defaultValue: false }),
  documentLinkType: attr('number', { defaultValue: null }),
})