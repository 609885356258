import Component from '@glimmer/component';
import PersonInCompany from 'cing-app/models/person-in-company';

const SHOW_AS_OPTION_1 = 1;
const SHOW_AS_OPTION_2 = 2;
const SHOW_AS_OPTION_3 = 3;

interface ColumnNameArgs {
  record: PersonInCompany;
  column: any;
}

export default class ColumnName extends Component<ColumnNameArgs> {

  constructor(owner: any, args: ColumnNameArgs) {
    super(owner, args);
  }

  // @computed(
  //   "record.person.{firstName,lastName,middleName}",
  //   "column.data.showAsOption"
  // )

  get nameView() {
    let person = this.args.record.person;

    if (this.args.column.data.showAsOption.value === SHOW_AS_OPTION_1) {
      return `${person.get('lastName') + ', ' || ''} ${person.get('firstName') || ''} ${person.get('middleName') || ''}`
    } else if (this.args.column.data.showAsOption.value === SHOW_AS_OPTION_2) {
      let middle = '';

      if (person.get('middleName')) {
        middle = `${person.get('middleName')}`;
      }

      return `${person.get('firstName') || ''} ${middle} ${person.get('lastName') || ''}`
    } else {
      return `${person.get('firstName') || ''} ${person.get('lastName') || ''}`
    }
  }
}