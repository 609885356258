import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { task, all } from 'ember-concurrency';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import { ProjectTabMap, ConnectionTypes } from 'cing-app/utils/lookups';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import Project from 'cing-app/models/project';
import Company from 'cing-app/models/company';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';
import Investor from 'cing-app/models/investor';
import ProjectTabModel from 'cing-app/models/project-tab';
import DockerItemService from 'cing-app/pods/docker-item/service';
interface InterestedPartiesInvestorsArgs {
  model: Project;
}
export default class InterestedPartiesInvestors extends Component<InterestedPartiesInvestorsArgs> {
  @service
  store!: Store;

  @service
  session: any;

  @tracked
  dataSource!: ApiDataSource<Investor>;

  @service('docker-item')
  docker!: DockerItemService;

  pageSize = 100;

  @service('ab-models')
  abModels: any;

  @tracked
  confirmRemoval: null | Investor = null;

  @tracked
  defaultAllAccessFolders: any = null;

  includes = ['company'];

  @tracked
  selectedItems: Investor[];

  @tracked
  reloadData: boolean = false;

  @tracked
  searchQuery = '';

  @tracked
  srConnection?: AppbuilderLink;

  constructor(owner: any, args: InterestedPartiesInvestorsArgs) {
    super(owner, args);
    this.selectedItems = [];
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    await this._initSmartRoom.perform();

    this.dataSource = new ApiDataSource<Investor>(
      40,
      false,
      'projectContactsInvestors',
      this.session.authUser.email,
      this.loadDataTask,
      this.pageSize,
      this.columnsUI,
      this.selectedItems,
      {}
    );
  });

  @task
  _initSmartRoom = taskFor(async () => {
    try {
      let project = this.args.model;
      this.srConnection = (await project.get('dataConnections')).findBy(
        'type',
        ConnectionTypes.SMARTROOM
      );
    } catch (e) {
      console.log('SmartRoom not attached to the project.');
    }
  });

  get columnsUI() {
    let columns: DataSourceColumn<Investor>[] = [
      new DataSourceColumn<Investor>({
        id: 'company.name',
        label: 'Investor',
        getValue: (row) => row,
        sortingEnabled: true,
        sort: 'asc',
        minWidth: 180,
        valueComponent:
          'project-detail/interested-parties/investors/column-investor',
        options: { onClick: this.showInvestorDetail, project: this.args.model },
      }),
      new DataSourceColumn<Investor>({
        id: 'investorFundId',
        label: 'Investor Fund Id',
        getValue: (row) => get(row, 'investorFundId'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<Investor>({
        id: 'investorNumber',
        label: 'Investor Number',
        getValue: (row) => get(row, 'investorNumber'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<Investor>({
        id: 'commitment',
        label: 'Commitment',
        getValue: (row) => get(row, 'commitment') || 0,
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
        hidden: true,
        options: {
          format: { style: 'currency' },
        },
      }),
      new DataSourceColumn<Investor>({
        id: 'smartRoomFolderId',
        label: 'SR Folder',
        getValue: (row) => get(row, 'smartRoomFolderId'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        hidden: true,
      }),
      new DataSourceColumn<Investor>({
        id: 'createTime',
        label: 'Investor Added',
        getValue: (row) => get(row, 'createTime'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
    ];
    return columns;
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        //@ts-ignore
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: get(this.args.model, 'fundId'),
      })
    );

    let searchQuery = this.searchQuery;
    if (searchQuery) {
      let termParts = searchQuery.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            //@ts-ignore
            name: 'company.name',
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: termParts[0],
          })
        );

        exprSearch.add(nameExpression);
      }

      ['company.name'].forEach((propName) => {
        exprSearch.add(
          Filter.create({
            //@ts-ignore
            name: propName,
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: searchQuery,
          })
        );
      });

      expr.add(exprSearch);
    }

    return expr.serialize();
  }

  get include() {
    return this.includes.join(',');
  }

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<Investor>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let query: any = {
        condition: this.generateFilter(),
        include: this.includes.join(','),
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
      };
      if (sortColumn) {
        let sortName = sortColumn.options?.sortValue || sortColumn.id;
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let invs = await this.store.query('investor', query);
      let investors = <Paged<Investor>>invs.toArray();
      investors.meta = {
        totalCount: invs.meta['total-count'],
      };

      return investors;
    }
  );

  @task
  removeRecordTask = taskFor(async (party: Investor) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      itemsToSave.push(this.removeRecordForPartyTask.perform(items[a]));
    }

    await all(itemsToSave);

    // we need to clear selectedItems upon removing!!!
    this.selectedItems.clear();

    this.confirmRemoval = null;
    this.dataSource.refresh();
  });

  @task({ enqueue: true, maxConcurrency: 4 })
  removeRecordForPartyTask = taskFor(async (investor) => {
    await investor.destroyRecord();
  });

  @task({ enqueue: true, maxConcurrency: 4 })
  updatePartyRecordTask = taskFor(async (investor) => {
    await investor.save();
  });

  @action
  addInvestor() {
    let appearance = {
      icon: '',
      title: `Add Investor`,
      size: 'medium',
      custom: true,
    };

    let investor = this.store.createRecord('investor', {
      fund: get(this.args.model, 'fund'),
      fundId: get(this.args.model, 'fundId'),
    });

    this.docker.invokePopup('investor-detail', appearance, {
      project: this.args.model,
      srConnection: this.srConnection,
      investor,
      onCreate: () => this.dataSource.refresh(),
    });
  }

  @action
  editInvestor(row: Investor) {
    let appearance = {
      icon: '',
      title: `Edit Investor: ${get(row, 'company.name')}`,
      size: 'medium',
      custom: true,
    };
    this.docker.invokePopup('investor-detail', appearance, {
      investor: row,
      project: this.args.model,
      srConnection: this.srConnection,
      onDelete: () => this.dataSource.refresh(),
    });
  }

  @action
  showCompanyDetail(company: Company) {
    this.docker.popupCompany(company);
  }

  @action
  async showInvestorDetail(row: Investor) {
    let abProject = get(this.args.model, 'abProject');

    if (abProject) {
      let abModelName = this.abModels.getModelName(this.args.model, 'investor');

      let query = Expressions.create();
      query.add(
        Filter.create({
          name: 'inv-fund-id',
          operator: FilterOperators.EQUAL,
          value: row.investorFundId,
        })
      );

      let abInvestor = (
        await this.store.query(abModelName, {
          condition: query.serialize(),
          page: {
            size: 1,
          },
        })
      ).firstObject;

      if (abInvestor) {
        let appearance = {
          icon: '',
          title: `${abInvestor.investorName}`,
          size: 'large',
          custom: true,
        };

        this.docker.invokePopup('project-detail/investors/detail', appearance, {
          project: this.args.model,
          abModel: abInvestor,
          model: row,
        });
      } else {
        alert(
          'Investor detail could not be found. Please check the Investor Fund Id.'
        );
      }
    }
  }

  @action
  removeRecord(row: Investor) {
    this.confirmRemoval = row;
  }

  @action
  cancelRemoveRecord() {
    this.confirmRemoval = null;
  }

  @action
  doRemoveRecord(row: Investor) {
    this.removeRecordTask.perform(row);
  }

  @action
  search() {
    this.dataSource.refresh();
  }

  @action
  importInvestorsFromDealCloud() {
    this.docker.popupImportInvestorsFromDealCloud(this.args.model, {
      onComplete: () => {
        this.reloadData = true;
      },
    });
  }

  @action
  importInvestorsFromExcel() {
    this.docker.popupImportInvestorsFromExcel(this.args.model, {
      onComplete: () => {
        this.reloadData = true;
      },
    });
  }
}
