import Model, { attr } from '@ember-data/model';

import DcInvestorNumber from 'cing-app/transforms/dc-investor-number';

export default class DealCloudInvestor extends Model {
  @attr('string') declare name: string;
  @attr('dc-investor-number') declare investorNumber: DcInvestorNumber;
  @attr() declare investorNumbers: any;
  @attr() declare role: any; // this is LOCAL ONLY attribute
  @attr() declare investorFundId: any; // this is LOCAL ONLY attribute
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'deal-cloud-investor': DealCloudInvestor;
  }
}
