import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import AclRole from './acl-role';
import AclPermission from './acl-permission';

const AclRolePermissionValidations = buildValidations({
  'roleId': validator('presence', true),
  'permissionId': validator('presence', true),
});

export default class AclRolePermission extends Abstract.extend(AclRolePermissionValidations) {
  @attr('string') declare roleId: string;
  @attr('string') declare permissionId: string;

  //@ts-ignore
  @belongsTo('acl-role') declare aclRole: AsyncBelongsTo<AclRole>;
  //@ts-ignore
  @belongsTo('acl-permission') declare aclPermission: AsyncBelongsTo<AclPermission>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'acl-role-permission': AclRolePermission;
  }
}
