import { attr } from '@ember-data/model';
//@ts-ignore
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
import { ValueFormat } from './data-link';

export default class DataLinkViewColumn extends Fragment {
  // NOTE: for now, each view is connected to a model, so this should not be needed
  //@attr('string')
  //modelName!: string;
  @attr('string') declare propertyName: string;
  @attr('string') declare label: string;
  @attr('boolean', { defaultValue: true }) declare defaultVisible: boolean;
  @attr('boolean', { defaultValue: false }) declare simpleFilter: boolean;
  @attr('boolean', { defaultValue: false }) declare advancedFilter: boolean;
  @attr('boolean', { defaultValue: false }) declare enableCharts: boolean;
  @attr('boolean', { defaultValue: false }) declare sorting: boolean;
  @attr('number') declare sortOrder?: number;
  @attr('boolean') declare defaultAscending?: boolean;
  @attr('string', { defaultValue: 'L' }) declare textAlign: 'R' | 'C' | 'L';
  @attr('number') declare displayOrder: number;
  @attr('number', { defaultValue: 100 }) declare minWidth: number;
  @attr('number') declare maxWidth?: number;
  @attr('number') declare maxHeight?: number;
  @attr('boolean', { defaultValue: true }) declare wrapText: boolean;
  @attr('value-format') declare format?: ValueFormat;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-link-view-column': DataLinkViewColumn;
  }
}
