import Component from '@glimmer/component';
import { action, set, get } from '@ember/object';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { ConnectionTypes, ABConnectionTypes } from 'cing-app/utils/lookups';
import { Query, FilterOperators, Filter } from 'cing-app/utils/query-builder';
import PortalPage from 'cing-app/models/portal-page';
import ProjectTab from 'cing-app/models/project-tab';
import ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import { cached } from 'tracked-toolbox';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency';
interface AbConnectionArgs {
  ABType: any;
  type: any;
  model: ProjectTab;
  tab: any;
}

export default class ManageUsers extends Component<AbConnectionArgs> {
  @service declare store: Store;

  @tracked connections?: ArrayProxy<AppbuilderLink>;

  ABConnectionTypes = ABConnectionTypes;
  ConnectionTypes = ConnectionTypes;

  constructor(owner: any, args: AbConnectionArgs) {
    super(owner, args);
    this.loadConnections.perform();
  }

  @task
  loadConnections = taskFor(async () => {
    let query = new Query();
    query.add(
      new Filter('type', FilterOperators.EQUAL, ConnectionTypes.SMARTROOM)
    );

    this.connections = await this.store.query('appbuilder-link', {
      condition: query.serialize(),
    });
  });

  @action
  selectConnection(connection: AppbuilderLink) {
    set(this.args.model, 'dataConnection', connection);
  }

  @task
  saveTask = taskFor(async () => {
    await this.connection?.save();
  });

  @task
  _createConnection = taskFor(async () => {
    let connection = this.store.createRecord('appbuilder-link', {
      type: ConnectionTypes.SMARTROOM,
    });

    await connection.save();

    this.selectConnection(connection);

    await this.args.model.save();

    await this.loadConnections.perform();
  });

  @action
  save() {
    return this.saveTask.perform();
  }

  @action
  createConnection() {
    this._createConnection.perform();
  }

  @action
  onInsert() {
    this.args.model.onSaveSubTask = this.save;
  }

  @action
  onDestroy() {
    this.args.model.onSaveSubTask = null;
  }
}
