import { task, timeout } from 'ember-concurrency';

import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  withAutoSave: false,
  horizontal: false,
  model: null,
  init() {
    this._super();
  },
  actions: {
    submit() {
      if (this.model.validate) {
        this.model.validate().then(({ model, validations }) => {
          if (!validations.get('isValid')) {
            console.log(validations.get('errors'), validations.get('messages'));
          }
          this.set('showValidations', true);
          this.sendAction("onSubmit", model, validations);
        }).catch((errors) => {
          console.log(errors);
        });
      } else {
        this.set('showValidations', true);
        this.sendAction("onSubmit", this.model);
      }
    }
  }
});