import Component from '@glimmer/component';
import { action } from '@ember/object';
import { EmptyFile, EmptyFolder, Structure } from 'smex-ui-sr-filemanager';


interface IArgs {
  item: EmptyFile | EmptyFolder;
  structure: Structure;
  level?: number;
  paddingLeft: number;
}

export default class SmartroomFile extends Component<IArgs>{
  @action
  selectUnselect() {
    this.args.item.selectUnselect(!(this.args.item.selected || this.args.item.selectedParent));
  }
};
