import Component from "@ember/component";
import classic from "ember-classic-decorator";
import { inject } from "@ember/service";
import { task } from "ember-concurrency";

@classic
export default class ColumnAction extends Component {
    @inject()
    store;

    @inject("docker-item")
    docker;

    init() {
        super.init(...arguments);
        let alertDetail = this.get("dockerItem.context");
        this.initTask.perform(alertDetail);
    }

    @task(function* (alertDetail) {
        this.set("alertDetail", alertDetail);
    })
    initTask;
}
