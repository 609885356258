import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { SmartRoomFolder } from 'smex-ui-sr-services';
import { SmartroomFolderModel } from 'smex-ui-sr-models';
import { action } from '@ember/object';
//@ts-ignore
import { validatePresence } from 'ember-changeset-validations/validators';
import { all } from 'ember-concurrency';
import BottomWindows from 'cing-app/pods/bottom-windows/service';

class FolderInfo {
  @tracked
  name = '';
  @tracked
  error = '';
  @tracked
  isDone = false;

  get errorMessage() {
    let requiredError = !this.name ? 'Folder Name is Required!' : '';
    let maxLengthError =
      this.name?.length > 250
        ? 'Folder Name is too long (maximum is 250 characters)'
        : '';
    return this.error || requiredError || maxLengthError;
  }

  constructor(name?: string) {
    if (name) {
      this.name = name;
    }
  }
}

interface SmartroomCreateFolderArgs {
  header: any;
  footer: any;
  context: {
    siteId: string;
    parentFolder: SmartroomFolderModel;
  };
  onClose: (a: false) => void;
}

export default class SmartroomCreateFolder extends Component<SmartroomCreateFolderArgs> {
  @service('bottom-windows')
  bottomWindows!: BottomWindows;
  @service('smart-room-folder')
  smartroomFolder!: SmartRoomFolder;
  @service
  notifications: any;

  @tracked
  folders: FolderInfo[];
  @tracked
  error = '';

  constructor(owner: any, args: SmartroomCreateFolderArgs) {
    super(owner, args);

    this.folders = [new FolderInfo()];
  }

  get inputsAreValid() {
    let notDoneFolders = this.folders.filter(
      (f, i) => !f.isDone && i !== this.folders.length - 1
    );
    let errorCount = notDoneFolders.filter((f) => f.errorMessage).length;
    return notDoneFolders.length && errorCount === 0;
  }

  @action
  changeFolderName(folder: FolderInfo, evt: Event) {
    folder.error = '';
    let name = (<HTMLInputElement>evt.target).value;
    folder.name = name;

    if (folder === this.folders[this.folders.length - 1]) {
      //if editing last folder then add new one
      this.folders.pushObject(new FolderInfo());
    }
  }

  @action
  onPaste(folder: FolderInfo, evt: Event) {
    evt.preventDefault();

    //@ts-ignore
    let text: string = (evt.clipboardData || window.clipboardData).getData(
      'text'
    );
    let lines = text.split(/\n/).filter((l) => l.trim());
    if (lines.length > 0) {
      folder.name = lines[0] || '';

      for (let i = 1; i < lines.length; i++) {
        this.folders.pushObject(new FolderInfo(lines[i]));
      }
      this.folders.pushObject(new FolderInfo());
    }
  }

  @action
  removeFolder(folder: FolderInfo) {
    this.folders.removeObject(folder);
  }

  @task
  createFolders = taskFor(async (evt: Event) => {
    evt.preventDefault();
    if (!this.inputsAreValid) {
      return;
    }

    let foldersToCreate = this.folders.filter(
      (f, i) => !f.isDone && i !== this.folders.length - 1
    );
    let promises = foldersToCreate.map((f) => this.createFolder.perform(f));
    await all(promises);

    let successFolders = foldersToCreate.filter((f) => !f.error);
    let errorFolders = foldersToCreate.filter((f) => f.error);

    if (successFolders.length) {
      this.bottomWindows.success(
        `${successFolders.length > 1 ? 'Folders' : 'Folder'} "${successFolders
          .map((f) => f.name)
          .join(', ')}" ${successFolders.length > 1 ? 'were' : 'was'} created.`
      );
    }
    if (errorFolders.length) {
      this.bottomWindows.danger(
        `${errorFolders.length > 1 ? 'Folders' : 'Folder'} "${errorFolders
          .map((f) => f.name)
          .join(', ')}" ${
          errorFolders.length > 1 ? 'were' : 'was'
        } not created.`
      );
    } else {
      this.args.onClose(false);
    }
  });

  @action
  focusInput(el: HTMLElement) {
    if (this.folders.length === 1) {
      el.focus();
    }
  }

  @task({
    maxConcurrency: 4,
    enqueue: true,
  })
  createFolder = taskFor(async (folder: FolderInfo) => {
    try {
      folder.error = '';
      await this.smartroomFolder.createFolder.perform(
        this.args.context.siteId,
        this.args.context.parentFolder.id,
        folder.name
      );
      folder.isDone = true;
    } catch (err) {
      if (err.errors && Array.isArray(err.errors)) {
        let e = err.errors[0];
        folder.error = e.title;
      } else {
        folder.error = err.toString();
      }
    }
  });
}
