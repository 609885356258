import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@classNames("col-sm-12")
export default class AddWidget extends Component {
  widgets = null;

  @service
  store;
}
