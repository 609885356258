import Component from '@ember/component';
import { get, set, computed, action } from "@ember/object";
import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class SearchContent extends Component {
  @service store;

  searchBy = 1;

  init() {
    super.init();
    //set(this, 'selectedContent', []);
    this.buildSearchParams();
  }

  pageSize = 20;

  @computed
  get columns() {
    return [
      {
        component: 'api-table/select-row-checkbox',
        componentForSortCell: "api-table/select-all-rows-checkbox"
      },
      {
        propertyName: 'displayString',
        title: "#",
        disableSorting: true
      },
      {
        propertyName: 'itemName',
        sortPrecedenc: 0,
        sortDirection: 'asc'
      },
      {
        propertyName: 'path',
        disableSorting: true
      },
      {
        propertyName: 'bytes',
        title: "Size",
        component: 'api-table/columns/bytes'
      },
      {
        propertyName: 'dateCreated',
        component: 'api-table/columns/date',
        dateFormat: 'MM/DD/YYYY hh:mm a'
      },
      {
        propertyName: 'dateModified',
        component: 'api-table/columns/date',
        dateFormat: 'MM/DD/YYYY hh:mm a'
      }
    ]
  }

  buildSearchParams() {
    let searchParams = {
      excludeFileTypes: "",
      folderId: -1,
      includeFileTypes: "",
      q: this.searchTerm,
      returnItemType: 1,
      searchBy: this.searchBy,
      searchIn: 1,
      sortBy: "1",
      sortDesc: false,
      tags: [],
      tagsMatchAll: true,
    };

    set(this, "searchParams", searchParams);
  }

  singleColumnSortingWrapper(query, sortBy, sortDirection) {
    query[get(this, 'filterQueryParameters.sort')] = sortBy;
    query[get(this, 'filterQueryParameters.sortDirection')] = sortDirection === 'DESC' ? true : false;

    return query;
  }

  @action
  search() {
    this.buildSearchParams();
  }
}
