import Component from '@ember/component';
import {
  PortalPageProjectFilter,
  PortalPageProjectFilterMap
} from 'cing-app/utils/lookups';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  store: service(),

  init() {
    this._super(...arguments);
  },
  PortalPageProjectFilterMap,
  ProjectFilters: Object.entries(PortalPageProjectFilter).map((item) => {
    return {
      name: item[0],
      value: item[1]
    }
  }),
  ProjectFilterOptions: Object.values(PortalPageProjectFilter),
})