import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
//@ts-ignore
import { buildValidations } from 'ember-cp-validations';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { USStates } from 'cing-app/utils/us-states-lookup';
import Attorney from './attorney';
import DebtorProfile from './debtor-profile';
import Company from './company';
import Abstract from './abstract';

const CDPAttorneySignatureValidations = buildValidations({});

let StatesEnum: any = {};

USStates.forEach((state) => {
  StatesEnum[state.name] = state.id;
});

export default class CdpAttorneySignature extends Abstract.extend(
  CDPAttorneySignatureValidations,
  computedEnumMixin('barState', 'barStateId', StatesEnum)
) {
  @attr('string') declare companyProfileId: string;
  @attr('string') declare attorneyId: string;
  @attr('string') declare companyId?: string;

  @attr('string') declare attorneyDate: string;
  @attr('string') declare signatureAttorneyForDebtor?: string;

  @attr('string') declare printedName?: string;
  @attr('string') declare barNumber?: string;
  @attr('number') declare barStateId?: number;

  @belongsTo('debtorProfile')
  declare companyProfile: AsyncBelongsTo<DebtorProfile>;
  @belongsTo('attorney') declare attorney: AsyncBelongsTo<Attorney>;
  @belongsTo('company') declare company: AsyncBelongsTo<Company>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'cdp-attorney-signature': CdpAttorneySignature;
  }
}
