import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject } from '@ember/service';
import Component from "@ember/component";
import { task } from "ember-concurrency";

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';

@classic
@classNames('d-flex', 'flex-row')
export default class EventDetail extends Component {
  @inject()
  config;

  @inject()
  store;

  @inject('docker-item')
  docker;

  expanded = false;

  @action
  showDocket(record) {
    let appearance = {
      icon: '',
      title: `Docket: #${record.get('docketNumber')}`,
      size: 'medium'
    };
    this.docker.invokePopup(
      'manage-docket',
      appearance,
      {
        docket: record,
      }
    );
  }

  @action
  viewPDF(docket) {
    if (docket.get('fileMetadata.MimeType') === "application/pdf") {
      let appearance = {
        icon: '',
        title: docket.get('popupTitle'),
        size: 'extra-large',
        customPopup: true
      };

      this.docker.invokePopup('view-pdf', appearance, {
        docket: docket,
      });
    } else {
      var iframe = $("<iframe/>").appendTo($("#downloadiframes"));
      iframe.attr('src', docket.get('contentURL'));
    }
  }

  @action
  expandEvent(event) {
    if (!this.expanded) {
      this.set('expanded', true);
    } else {
      this.set('expanded', false);
    }
  }
}