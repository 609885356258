import Component from '@glimmer/component';
import { ConnectionTypes, ConnectionTypesMap } from 'cing-app/utils/lookups';
import { action } from '@ember/object';
import { set } from '@ember/object';
import AppbuilderLink from 'cing-app/models/appbuilder-link';

import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import Project from 'cing-app/models/project';
import ArrayProxy from '@ember/array/proxy';
interface SmartroomArgs {
  model: AppbuilderLink;
  onSave: () => void;
}

const ConnectionTypeOptions: any = [];

for (const [key, value] of Object.entries(ConnectionTypesMap)) {
  ConnectionTypeOptions.pushObject({
    name: key,
    id: value,
  });
}

export default class SmartRoom extends Component<SmartroomArgs> {
  ConnectionTypes = ConnectionTypes;
  ConnectionTypeOptions = ConnectionTypeOptions;

  @service('store') declare store: Store;

  @tracked
  projects!: ArrayProxy<Project>;

  constructor(owner: unknown, args: SmartroomArgs) {
    super(owner, args);

    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    this.projects = await this.store.query('project', {
      page: {
        size: 1000,
      },
      include: 'project-type,project-status',

      sort: 'name',
    });
  });
}
