import Component from '@glimmer/component';
import { action } from '@ember/object';

interface AbPmTaskMenuArgs {
  parentTask: any;
  task: any;
  index: number;
  onTaskAction: any;
  css: string;
}

export default class AbPmTaskMenu extends Component<AbPmTaskMenuArgs> {
  @action editTask() {
    this.args.onTaskAction('edit-task', this.args.task, this.args.task);
  }

  @action addTask() {
    this.args.onTaskAction('add-task', this.args.parentTask, this.args.task);
  }

  @action addSubtask() {
    this.args.onTaskAction('add-sub-task', this.args.task, this.args.task);
  }

  @action indentTask() {
    this.args.onTaskAction('indent-task', this.args.task, this.args.task);
  }

  @action outdentTask() {
    this.args.onTaskAction('outdent-task', this.args.task, this.args.task);
  }

  @action moveTask() {
    this.args.onTaskAction('move-task', this.args.task, this.args.task);
  }

  @action copyTask() {
    this.args.onTaskAction('copy-task', this.args.task, this.args.task);
  }

  @action deleteTask() {
    let options = {
      parentTask: this.args.parentTask,
      index: this.args.index,
    };
    this.args.onTaskAction('delete-task', this.args.task, options);
  }
}
