import Component from '@glimmer/component';
import { DataSourceColumn } from 'smex-ui-table';
import { cached } from 'tracked-toolbox';
import { action } from '@ember/object';

interface ColumnLinkArgs {
  column: DataSourceColumn<unknown>;
  row: any;
}

export default class ColumnLink extends Component<ColumnLinkArgs> {
  @cached
  get value() {
    return this.args.column.getValue(this.args.row);
  }

  @action
  openLink(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    window.open(this.value, '_blank');
  }
}
