import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';
import _ from 'lodash';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import { EventType } from 'cing-app/utils/lookups';

@classic
@classNames('d-flex', 'flex-column', 'flex-grow-1')
export default class Calendar extends Component.extend(FilterBuilder) {
  @service
  config;

  @service
  store;

  @service
  session;

  @service('docker-item')
  docker;

  // date_property: 'dueSetDate',
  // include: 'case,event-type,hearing-judge,docket',
  // columns: [
  //     {
  //         propertyName: 'dueSetDate',
  //         className: "text-right column-date-filed",
  //         component: "api-table/columns/event",
  //         dateFormat: "MM/DD/YYYY",
  //         sortPrecedence: 0,
  //         sortDirection: "desc"
  //     },
  //     {
  //         propertyName: 'filed',
  //         className: "text-right column-date-filed",
  //         component: "api-table/columns/date",
  //         dateFormat: "MM/DD/YYYY"
  //     },
  //     {propertyName: 'eventType.name', className: "column-name", title: "Event"},
  //     {propertyName: 'hearingJudge.fullName', className: "column-name", title: "Hearing judge"},
  // ],
  //
  // startDate: Ember.computed('startRaw', function() {
  //     if (this.get('startRaw')) {
  //         return moment(this.get('startRaw')).startOf('day');
  //     }
  //     return null;
  // }),
  //
  // endDate: Ember.computed('endRaw', function() {
  //     if (this.get('endRaw')) {
  //         return moment(this.get('endRaw')).endOf('day');
  //     }
  //     return null;
  // }),

  init() {
    super.init(...arguments);

    //this.generateFilter();
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(Filter.create({
      name: 'caseId',
      operator: FilterOperators.EQUAL,
      value: this.get('context.id')
    }));
    expr.add(Filter.create({
      name: 'typeId',
      operator: FilterOperators.NOT_IN,
      value: [EventType.Milestone]
    }));
    /*
    expr.add(Filter.create({
        name: 'dateStart',
        operator: FilterOperators.NOT_NULL,
    }));
    */

    this.set('filter', expr);
  }

  @action
  addEvent() {
    const appearance = {
      label: 'Add Event',
      icon: '',
      size: 'large',
      title: `Add Event for: ${this.get('model.name')}`,
      custom: true
    };

    let self = this;

    const context = {
      project: this.model,
      model: this.store.createRecord('event', {
        caseId: this.model.get('caseId'),
        showInCalendar: true,
        showInProjectManagement: false,
        projectId: this.model.id,
        dateStart: moment(),
        typeId: EventType.CaseEvent,
        alwaysShow: true,
      }),

      onUpdate: function () {
        if (self.eventView) {
          self.eventView.reload();
        }
      }
    };

    this.get('docker').invokePopup('project-detail/project-management/form', appearance, context);

    //this.docker.invokePopup('manage-events/generic-add', appearance, context);
  }

  @action
  showEventDetail(event) {
    let self = this;
    this.showEvent(event, function () {
      if (self.eventView) {
        self.eventView.reload();
      }
    })
  }

  @action
  registerEventView(view) {
    this.set('eventView', view);
  }
}
