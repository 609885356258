import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { get } from '@ember/object';
import { dasherize } from '@ember/string';
import { DailyStat } from '../../component';
import { inject as service } from '@ember/service';
import AppBuilderService, {
  ABModelMeta,
} from 'cing-app/pods/appbuilder/service';
import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';
import AppBuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import _SessionService from 'cing-app/pods/session/service';
import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

interface SmartBillArgs {
  appbuilderLink: AppBuilderLink;
  dailyStat: DailyStat;
}

export interface SmartBill {
  slug?: string;
  bDRNo?: number;
  sageBillingID?: number;
  mSAID?: number;
  mSAName?: string;
  bDRNo2?: number;
  bmcProjectName?: string;
  billingCompany?: string;
  status?: string;
  saDate?: string;
  billCycle?: string;
  billCycleType?: string;
  rateType?: string;
  renewalRateType?: string;
  decomDate?: string;
  currency?: string;
  vAT?: string;
  terms?: string;
  discount?: string;
  siteId?: number;
}

export default class DataHistories extends Component<SmartBillArgs> {
  @service('appbuilder')
  appbuilder!: AppBuilderService;

  @service
  store!: Store;

  @service
  session!: _SessionService;

  //Summary
  @tracked
  summaryCollapsed: boolean = false;

  modelMeta: ABModelMeta;
  dataSource!: ApiDataSource<SmartBill>;

  @tracked
  selectedItems: SmartBill[];

  //Common
  pageSize = 20;

  get columnsUI() {
    let columns: DataSourceColumn<SmartBill>[] = [
      new DataSourceColumn<SmartBill>({
        id: 'slug',
        label: 'Slug',
        hidden: true,
        getValue: (row) => get(row, 'slug'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<SmartBill>({
        id: 'billing-company',
        label: 'Billing Company',
        getValue: (row) => get(row, 'billingCompany'),
        sortingEnabled: true,
        minWidth: 150,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<SmartBill>({
        id: 'status',
        label: 'Status',
        getValue: (row) => get(row, 'status'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<SmartBill>({
        id: 'sa-date',
        label: 'SA Date',
        getValue: (row) => get(row, 'saDate'),
        sortingEnabled: true,
        minWidth: 100,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<SmartBill>({
        id: 'bill-cycle',
        label: 'Bill Cycle',
        getValue: (row) => get(row, 'billCycle'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<SmartBill>({
        id: 'bill-cycle-type',
        label: 'Bill Cycle Type',
        getValue: (row) => get(row, 'billCycleType'),
        sortingEnabled: true,
        minWidth: 160,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<SmartBill>({
        id: 'rate-type',
        label: 'Rate Type',
        getValue: (row) => get(row, 'rateType'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<SmartBill>({
        id: 'renewal-rate-type',
        label: 'Renewal Rate Type',
        getValue: (row) => get(row, 'renewalRateType'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<SmartBill>({
        id: 'site-id',
        label: 'Site Id',
        hidden: true,
        getValue: (row) => get(row, 'siteId'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
    ];
    return columns;
  }

  constructor(owner: any, args: any) {
    super(owner, args);

    this.modelMeta = this.appbuilder.getModelMeta(
      this.args.appbuilderLink,
      'smart-bills'
    );

    this.selectedItems = [];

    this.dataSource = new ApiDataSource<SmartBill>(
      25,
      false,
      this.modelMeta.modelPath,
      this.session.authUser.email,
      this.loadDataTask,
      this.pageSize,
      this.columnsUI,
      this.selectedItems,
      {}
    );

    this.dataSource.selectionEnabled = false;
  }

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<SmartBill>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let filterQuery = Expressions.create();
      filterQuery.add(
        Filter.create({
          name: 'site-id',
          operator: FilterOperators.EQUAL,
          value: this.args.dailyStat.siteId,
        })
      );

      let query: any = {
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
        condition: filterQuery.serialize(),
      };

      if (sortColumn) {
        let sortName =
          sortColumn.options?.sortValue || dasherize(sortColumn.id);
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let smartBillList = await this.store.query(
        this.modelMeta.modelPath,
        query
      );

      let dataSmartBills = <Paged<SmartBill>>smartBillList.toArray();

      dataSmartBills.meta = {
        totalCount: smartBillList.meta['total-count'],
      };

      return dataSmartBills;
    }
  );
}
