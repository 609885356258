import { inject as service } from "@ember/service";
import Store from "@ember-data/store";
import SendingDomain from "cing-app/models/sending-domain";
import { tracked } from "@glimmer/tracking";
import classic from 'ember-classic-decorator';
import Field from '../field/component';
import { action } from '@ember/object';
import { taskFor } from "ember-concurrency-ts";
import { task } from "ember-concurrency";

@classic
export default class Email extends Field {
  @service store!: Store;
  @tracked selectedDomain!: SendingDomain;
  @tracked fromAddress?: string;
  showFormValidations = null;
  type = 'text';
  @tracked domains = [];

  init() {
    super.init(...arguments);
    this.getDomainsTask.perform();
  }

  @task
  getDomainsTask = taskFor(async () => {
    this.domains = await this.store.query('sending-domain', {
      page: {
        size: 1000
      }
    })
    if (this.value) {
      let addressParts = this.value.split('@');
      if (addressParts.length === 2) {
        this.fromAddress = addressParts[0];
        if (this.domains.length) {
          this.selectedDomain = this.domains.find(domain => domain.sendingDomain === addressParts[1])
        } else {
          this.value = '';
        }
      }
    } else {
      this.selectedDomain = this.domains.get(0);
    }
  });


  @action
  setValue() {
    if (this.selectedDomain) {
      let fromAddress = this.fromAddress + "@" + this.selectedDomain.sendingDomain;
      this.value = fromAddress;
    }
  }

  @action
  onInputCallback(e) {
    if (this.onInput) {
      this.onInput(e.target.value);
    }
  }
}
