import classic from 'ember-classic-decorator';
import Field from '../field/component';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';

let getYears = function (startYear) {
  var currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 1980;

  while (startYear <= currentYear) {
    years.push(String(startYear++));
  }

  return years;
}

@classic
export default class Text extends Field {
  @service config;

  showFormValidations = null;
  type = 'text';

  @action
  updateTime(newTime) {
    newTime = moment(newTime);
    let currentDate = moment(get(this, 'value'));

    currentDate.set({
      hour: newTime.hour(),
      minute: newTime.minute(),
      second: newTime.second()
    })

    set(this, 'value', currentDate.toDate());
  }
}
