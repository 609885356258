import JSONAPISerializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class InterestedPartySerializer extends JSONAPISerializer {
  attrs = {
    company: { serialize: true },
    address: { serialize: true },
    person: { serialize: true },
    email: { serialize: true },
    investor: { serialize: true },
    representing: { serialize: false },
    representedBy: { serialize: false },
    createTime: { serialize: false },
    modifyTime: { serialize: false },
    flags: { serialize: false },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'interested-party': InterestedPartySerializer;
  }
}
