import { helper } from "@ember/component/helper";
import Ember from 'ember';
import { htmlSafe } from "@ember/string";

export function LineBreaks(text) {
  text = Ember.Handlebars.Utils.escapeExpression(text);
  text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
  return text;
}

function LineBreaksSafe(text) {
  return new htmlSafe(LineBreaks(text));
}


export default helper(LineBreaksSafe);