import classic from 'ember-classic-decorator';
import { A } from '@ember/array';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';

@classic
export default class SettingsRoute extends Route {
  @task(function* () {
    try {
      let records = yield this.get('store').findAll('case');
      return records.toArray();
    } catch (err) {
      // handle failure if you want to
      //throw err;
      return A([]);
    }
  })
  modelTask;

  model() {
    return this.get('modelTask').perform();

    // return new Ember.RSVP.Promise((resolve, reject) => {
    //   this.get('store').findAll('case').then(
    //     (result) => {
    //       resolve(result.toArray());
    //     },
    //     (err) => {
    //       reject(err);
    //     }
    //   );
    // });
  }
}
