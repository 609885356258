import classic from 'ember-classic-decorator';
import { action, computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';
import Component from '@ember/component';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import _ from 'lodash';
import { PartyRole } from 'cing-app/utils/lookups';
import { IsProjectType } from 'cing-app/helpers/is-project-type';

@classic
export default class SelectContacts extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  confirmRemoval = null;
  includes = [
    'company',
    'person',
    'investor.company',
    'interested-party-role',
    'interested-party-type',
    'tags',
    'address',
    'email',
    'viewer-group',
  ];
  selectedItems = null;
  searchQuery = '';

  @computed('project.projectType.name')
  get columns() {
    let columns = [
      {
        propertyName: 'person.fullName',
        title: 'Contact Name',
        className: 'column-person-name',
        sortedBy: 'person.lastName',
        component: 'project-detail/interested-parties/parties/column-attorney',
      },
      {
        propertyName: 'email.emailAddress',
        title: 'Email',
        //sortedBy: "email.emailAddress",
        disableSort: true,
      },
      {
        propertyName: 'interestedPartyType.name',
        title: 'Type',
        className: 'column-role',
      },
      {
        propertyName: 'interestedPartyRole.name',
        title: 'Role',
        className: 'column-role',
        component: 'project-detail/project-management/form/column-role',
      },
      {
        propertyName: 'viewerGroup.name',
        title: 'Access Role',
        className: 'column-role',
      },
    ];

    if (IsProjectType(['fund', get(this.project, 'projectType.name')])) {
      columns.unshift({
        component:
          'project-detail/interested-parties/parties/column-party-name',
        title: 'Company',
        propertyName: 'company.name',
        className: 'column-company-name',
        //isHidden: true,
      });
      columns.unshift({
        title: 'Investor',
        propertyName: 'investor.company.name',
        className: 'column-company-name',
        sortDirection: 'asc',
        sortPrecedence: 0,
        project: this.get('model'),
        component: 'project-detail/project-management/form/column-investor',
      });
    } else {
      columns.unshift({
        component:
          'project-detail/interested-parties/parties/column-party-name',
        title: 'Affiliation',
        propertyName: 'company.name',
        className: 'column-company-name',
      });
    }

    columns.unshift({
      component: 'api-table/select-row-checkbox',
      useFilter: false,
      mayBeHidden: false,
      className: 'column-checkbox',
    });

    return columns;
  }

  init() {
    super.init(...arguments);
    this.set('selectedItems', []);

    this.set('interestedPartyFilter', []);
    this.set('selectedPartyTypes', []);
    //this.set('selectedTags', []);

    this.store
      .query('interested-party-type', {
        page: {
          size: 1000,
        },
        sort: 'name',
      })
      .then((result) => {
        this.set('interestedPartyTypes', result.toArray());
      });

    this.generateFilter();
  }

  @alias('context.project')
  project;

  @computed
  get availableTags() {
    return this.store.query('tag', {
      sort: 'title',
      'page[size]': 1000,
    });
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.get('project.id'),
      })
    );

    if (this.get('selectedPartyTypes.length')) {
      expr.add(
        Filter.create({
          name: 'type-id',
          operator: FilterOperators.IN,
          value: this.selectedPartyTypes.mapBy('id'),
        })
      );
    }

    if (this.get('selectedTags.length')) {
      expr.add(
        Filter.create({
          name: 'tags.id',
          operator: FilterOperators.IN,
          value: this.selectedTags.mapBy('id'),
        })
      );
    }

    let searchQuery = this.searchQuery;
    if (searchQuery) {
      let searchQueryExpr = Expressions.create({
        operator: ExpressionOperators.OR,
      });
      [
        'partyName',
        'address.city',
        'email.emailAddress',
        'person.lastName',
        'company.name',
      ].forEach((propName) => {
        searchQueryExpr.add(
          Filter.create({
            name: propName,
            operator: FilterOperators.LIKE,
            value: searchQuery,
          })
        );
      });
      expr.add(searchQueryExpr);
    }

    this.set('filter', expr.serialize());
  }

  @computed('includes.[]')
  get include() {
    return this.includes.join(',');
  }

  @computed('startRaw')
  get startDate() {
    if (this.startRaw) {
      return moment(this.startRaw).startOf('day');
    }
    return null;
  }

  @computed('endRaw')
  get endDate() {
    if (this.endRaw) {
      return moment(this.endRaw).endOf('day');
    }
    return null;
  }

  @action
  confirmSelection() {
    if (this.get('context.onSelection')) {
      this.get('context.onSelection')(this.selectedItems);
    }
    this.sendAction('onClose');
  }

  @action
  addInterestedParty() {
    let appearance = {
      icon: '',
      title: `Add interested party`,
      size: 'medium',
      customPopup: true,
    };
    this.docker.invokePopup('manage-interested-party', appearance, {
      case: this.partyCase,
      closeOnCreate: true,
      onCreate: (newParty) => {
        this.selectedItems.pushObject(newParty);
        this.sendAction('confirmSelection');
      },
    });
  }

  @action
  openPopup(component, appearance, context, header, footer) {
    this.docker.invokePopup(component, appearance, context, header, footer);
  }

  @action
  editInterestedParty(record) {
    let self = this;
    let appearance = {
      icon: '',
      title: `Edit interested party: ${record.get('partyName')}`,
      size: 'medium',
      customPopup: true,
    };
    this.docker.invokePopup('manage-interested-party', appearance, {
      interestedParty: record,
      case: self.get('partyCase'),
    });
  }

  @action
  showPartyDetail(record) {
    if (record.get('companyId')) {
      record.get('company').then((company) => {
        this.showCompanyDetail(company);
      });
    } else if (record.get('personId')) {
      this.showAttorneyDetail(record);
    } else {
      alert('There is no CRM record associated with this party.');
    }
  }

  @action
  showAttorneyDetail(record) {
    console.log('XXX');
    this.docker.popupPerson(record.get('person'), record.get('company'));
  }
  @action
  showCompanyDetail(company) {
    this.docker.popupCompany(company);
  }

  @action
  filterByAttorney() {
    console.log('FILTER BY ATTORNEY: ', ...arguments);
  }

  @action
  datePropertyChanged(dateProperty) {
    console.log('DATE PROPERTY VALUE: ', dateProperty);
  }

  @action
  search() {
    this.generateFilter();
  }

  @action
  removeRecord(record) {
    this.set('confirmRemoval', record);
  }

  @action
  cancelRemoveRecord(record) {
    this.set('confirmRemoval', null);
  }

  @action
  doRemoveRecord(record) {
    record.destroyRecord().then((response) => {
      this.set('confirmRemoval', null);
      this.send('search');
    });
  }

  // date range picker
  @action
  setDateRange(start, end, picker) {
    this.setProperties({ startRaw: start, endRaw: end });
  }

  @action
  hideDatePicker() {}

  @action
  cancelDatePicker() {
    this.setProperties({ startRaw: null, endRaw: null });
  }

  @action
  searchByPartyType(selected) {
    this.set('selectedPartyTypes', selected);
    this.generateFilter();
  }

  @action
  searchByTags(selected) {
    this.set('selectedTags', selected);
    this.generateFilter();
  }
}
