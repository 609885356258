import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { DocumentService, FolderContainer, Structure, SubFolder } from 'smex-ui-sr-filemanager';
import { tracked } from '@glimmer/tracking';
import { Models, MyInfoResource, SmartroomMyInfoModel, SmartroomRightTypeModel } from 'smex-ui-sr-models';
import { cached } from 'tracked-toolbox';
import { SmartRoomUpload } from 'smex-ui-sr-services';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import BottomWindows, { BottomWindowComponent } from 'cing-app/pods/bottom-windows/service';


interface IArgs {
	data: {
		folder: FolderContainer;
		structure: Structure;
		openClose: () => void;
	}
}

export default class SmartroomFolder extends Component<IArgs>{
	//@ts-ignore
	@service('docker-item') docker;
	@service('document-service') documentService!: DocumentService;
	@service('models') models!: Models;
	@service('smart-room-upload') upload!: SmartRoomUpload;
	@service('bottom-windows')
	bottomWindows!: BottomWindows;
	@tracked
	uploadFileInput?: HTMLInputElement;

	/**
	 * Used to show autocorrect right
	 */
	@tracked
	targetRight?: SmartroomRightTypeModel;

	get dataActions() {
		let result = [];
		if (this.args.data.folder.files || this.args.data.folder.folders) {
			result.push({
				label: 'Select All',
				action: () => { this.args.data.folder.selectAllChildren(); }
			});
			result.push({
				label: 'Unselect All',
				action: () => { this.args.data.folder.selectUnselect(false); }
			});
		}
		if (this.args.data.folder.files) {
			result.push({
				label: 'Select Files',
				action: () => { this.args.data.folder.selectFiles(); }
			});
		}
		if (this.args.data.folder.folders) {
			result.push({
				label: 'Select Folders',
				action: () => { this.args.data.folder.selectFolders(); }
			});
		}
		return result;
	}

	// @task
	// uploadStructure1 = taskFor(async (event: Event) => {
	// 	event.preventDefault();
	// 	//@ts-ignore
	// 	let siteId = this.models.getModelInfo(this.args.data.folder.model.constructor.modelName).siteId;
	// 	if (siteId) {
	// 		let uploadInfo = await this.upload.getUploadStructureFromItems(event, (files, folders) => console.log(`Files: ${files}; Folders: ${folders}`));
	// 		let errors = await this.upload.validateItemsToUpload(uploadInfo.itemsToUpload, siteId, this.args.data.folder.id);
	// 		let { structureGuid } = await this.upload.sendUploadStructure(uploadInfo.uploadStructure, siteId, this.args.data.folder.id);
	// 		await this.upload.loadSessionIds.perform(uploadInfo.files, siteId, this.args.data.folder.id, structureGuid);
	// 		// await this.upload.uploadBinaries.perform(uploadInfo.files, siteId);
	// 	}
	// });

	@action
	uploadStructure(event: Event) {
		this.dragLeave(event);
		event.preventDefault();
		//@ts-ignore
		let siteId = this.models.getModelInfo(this.args.data.folder.model.constructor.modelName).siteId;
		const context = {
			event: event,
			siteId: siteId,
			folderId: this.args.data.folder.id,
			folderName: this.args.data.folder.model.name
		};

		this.bottomWindows.open(new BottomWindowComponent('smartroom/upload-progress', context));
	}

	@action
	openCreateFolder(event: Event) {
		event.stopPropagation();
		event.preventDefault();
		//@ts-ignore
		let siteId = this.models.getModelInfo(this.args.data.folder.model.constructor.modelName).siteId;
		const appearance = {
			size: 'sm',
			noMinHeight: true,
			canResize: false,
			label: 'Create Folder',
			icon: 'folder',
			title: `<small>Create Folder In:</small> ${this.args.data.folder.model.name}`,
			custom: true
		};
		const context = {
			siteId: siteId,
			parentFolder: this.args.data.folder.model,
		};

		this.docker.invokePopup('smartroom/create-folder', appearance, context);
	}

	@action
	folderClick(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		this.args.data.openClose();
	}

	@action
	openUpload(event: Event) {
		event.stopPropagation();
		this.uploadFileInput?.click();
	}


	@action
	dragOver(event: Event) {
		event.preventDefault();
		let dropArea = <HTMLDivElement>event.currentTarget;
		dropArea.classList.add('files-drag-over');
	}

	@action
	dragLeave(event: Event) {
		let dropArea = <HTMLDivElement>event.currentTarget;
		dropArea.classList.remove('files-drag-over');
	}

	get noRight() {
		return this.args.data.folder.model.getNoRight();
	}

	@cached
	get myInfoResource() {
		//@ts-ignore
		let siteId = this.models.getModelInfo(this.args.data.folder.model.constructor.modelName).siteId;
		if (siteId) {
			return new MyInfoResource(this.models, siteId);
		}

		return undefined;
	}

	@action
	selectUnselect() {
		let folder = this.args.data.folder;
		folder.selectUnselect(!(folder.selected || (folder instanceof SubFolder && folder.selectedParent) || folder.selectedChild));
	}

	@action
	refresh() {
		this.documentService.reloadFolder(this.args.data.structure, this.args.data.folder);
	}

	@action
	changeRight(right: SmartroomRightTypeModel | null) {
		if (right) {
			if (this.args.data.folder.model.hasMinimumRight) {
				this.args.data.folder.model.updatedGivenRight = right;
			} else {
				this.targetRight = right;
			}
		} else {
			this.args.data.folder.model.updatedGivenRight = right;
		}
	}
};
