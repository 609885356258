import Component from '@glimmer/component';
import SmartroomRightTypeModel from 'smex-ui-sr-models/addon/models/smartroom/right-type';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { Models } from 'smex-ui-sr-models';
import { assert } from '@ember/debug';
import { tracked } from '@glimmer/tracking';

interface SecurityRightSelectArgs {
  selected?: SmartroomRightTypeModel;
  siteId: string;
  showInherit: boolean;
  showPassDown: boolean;
  closeObject: unknown;
}

export default class SecurityRightSelect extends Component<SecurityRightSelectArgs> {
  @service('store') store!: Store;
  @service('models') models!: Models;

  @tracked
  dropdown: any;

  constructor(owner: unknown, args: SecurityRightSelectArgs) {
    super(owner, args);

    assert('@siteId is required in security-right-select', this.args.siteId);
  }

  @action
  close() {
    if (this.dropdown) {
      this.dropdown.actions.close();
    }
  }

  get allRightTypes() {
    let result = this.store.peekAll(this.models.getModelNameSite('smartroom/right-type', this.args.siteId)).toArray();
    if (!this.args.showPassDown) {
      result = result.filter(r => !r.passDown);
    }

    result = result.filter(r => r.available);
    return result;
  }

  @action
  toggle(dropdown: any) {
    dropdown.actions.toggle();
  }
}
