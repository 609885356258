import AppbuilderLinkAdapter from './appbuilder-link-adapter';
import classic from 'ember-classic-decorator';

@classic
export default class FieldPropertyAdapter extends AppbuilderLinkAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'appbuilder-link/field-property': FieldPropertyAdapter;
  }
}
