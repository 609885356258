import ApplicationSerializer from './application';
import classic from 'ember-classic-decorator';

@classic
export default class ExtendedDataMappingSerializer extends ApplicationSerializer {
  // attrs: {
  // 	ABLink: { key: 'appbuilder-link' },
  // 	maxWidth: { key: 'width' }
  // },
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'extended-data-mapping': ExtendedDataMappingSerializer;
  }
}
