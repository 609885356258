import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';

import moment from 'moment';
import AbPmService, { AbPmLookups } from 'cing-app/pods/ab-pm/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import { SmartroomFolderModel } from 'smex-ui-sr-models';

interface BulkUpdateArgs {
  context: {
    abLink: any;
    smartRoomId: any;
    onTaskAction: any;
    tasks: [];
  };
}

export default class BulkUpdate extends Component<BulkUpdateArgs> {
  @service('ab-pm') pmService!: AbPmService;
  @service('docker-item') docker!: DockerItemService;

  @tracked taskTypes: any;
  @tracked taskCategories: any;
  @tracked taskPriorities: any;
  @tracked taskStatuses: any;
  @tracked taskResources: any;
  @tracked selectedResources: [] = [];
  @tracked originalResources: any;
  @tracked saveMessage: string = '';
  @tracked isError: boolean = false;
  @tracked isClean: boolean = true;

  //we use a tracked field for date input
  //because the ui will not update on the model updates
  @tracked startDate: any;
  @tracked endDate: any;
  @tracked dueDate: any;

  @tracked currentTask: any;
  @tracked selectedTaskType: any;
  @tracked selectedTaskCategory: any;
  @tracked selectedTaskPriority: any;
  @tracked selectedTaskStatus: any;
  @tracked selectedSmartRoomFolder: any;
  @tracked selectedSmartRoomFolderPath: any;
  @tracked smartRoomIndexPopup: any;

  @tracked isStatusChecked: boolean = false;
  @tracked isTypeChecked: boolean = false;
  @tracked isCategoryChecked: boolean = false;
  @tracked isPriorityChecked: boolean = false;
  @tracked isStartDateChecked: boolean = false;
  @tracked isEndDateChecked: boolean = false;
  @tracked isDurationChecked: boolean = false;
  @tracked isProgressChecked: boolean = false;
  @tracked isFolderNameChecked: boolean = false;

  @tracked model = {
    status: '',
    type: '',
    category: '',
    priority: '',
    isStartDateValid: false,
    startDate: '',
    isEndDateValid: true,
    endDate: '',
    duration: null,
    progress: null,
    folderId: null,
    folderNumber: null,
    folderName: null,
    resources: null,
  };

  constructor(owner: any, args: any) {
    super(owner, args);

    console.log('task-add constructor ', args);

    this.initTask.perform();
  }

  @task initTask = taskFor(async () => {
    let lookups = await this.pmService.preloadLookupItems(
      this.args.context.abLink
    );

    this.taskTypes = lookups.taskTypes;
    this.taskCategories = lookups.taskCategories;
    this.taskPriorities = lookups.taskPriorities;
    this.taskStatuses = lookups.taskStatuses;
  });

  get isBusy() {
    return this.initTask.isRunning || this.saveTask.isRunning;
  }

  get noChangeDetected() {
    let isDirty =
      !this.isClean &&
      (this.isStatusChecked ||
        this.isCategoryChecked ||
        this.isTypeChecked ||
        this.isPriorityChecked ||
        this.isStartDateChecked ||
        this.isEndDateChecked ||
        this.isDurationChecked ||
        this.isProgressChecked ||
        this.isFolderNameChecked);

    return !isDirty;
  }

  @action statusChange(st: any) {
    this.selectedTaskStatus = st;
    this.fieldChange('status');
  }

  @action typeChange(st: any) {
    this.selectedTaskType = st;
    this.fieldChange('type');
  }

  @action categoryChange(st: any) {
    this.selectedTaskCategory = st;
    this.fieldChange('category');
  }

  @action priorityChange(st: any) {
    this.selectedTaskPriority = st;
    this.fieldChange('priority');
  }

  @action fieldChange(fieldName: string) {
    console.log('field changed => ', fieldName);
    this.isClean = false;
    switch (fieldName) {
      case 'status':
        this.isStatusChecked = true;
        break;
      case 'type':
        this.isTypeChecked = true;
        break;
      case 'category':
        this.isCategoryChecked = true;
        break;
      case 'priority':
        this.isPriorityChecked = true;
        break;
      case 'startDate':
        this.isStartDateChecked = true;
        break;
      case 'endDate':
        this.isEndDateChecked = true;
        break;
      case 'duration':
        this.isDurationChecked = true;
        break;
      case 'progress':
        this.isProgressChecked = true;
        break;
      case 'folderName':
        this.isFolderNameChecked = true;
        break;
    }
  }

  @action save(e: Event) {
    this.saveTask.perform();

    if (e && e.preventDefault) {
      return e.preventDefault();
    }
  }

  @task saveTask = taskFor(async () => {
    console.log('start saving....');
    this.saveMessage = 'Validating task...';
    this.isError = false;
    let validationError = this.validateInputs();
    if (validationError) {
      this.saveMessage = validationError;
      return false;
    }

    var numberOfTasks = this.args.context.tasks.length;
    for (var i = 0; i < numberOfTasks; i++) {
      var task = this.args.context.tasks[i];
      try {
        this.setTaskStatus(task);
        this.setTaskType(task);
        this.setTaskCategory(task);
        this.setTaskPriority(task);
        this.setDates(task);
        this.setFolder(task);

        this.saveMessage = `Saving (${i + 1} of ${numberOfTasks}) - ${
          task.task
        }`;

        if (task.save) {
          console.log(this.saveMessage);
          await task.save();
        } else {
          console.log('Unable to save task, save method not found');
        }

        this.saveMessage = `Task ${task.task} has been updated.`;

        setTimeout(() => {
          this.isClean = true;
          this.saveMessage = 'Selected tasks are now updated.';
        }, 1000);
      } catch (ex) {
        this.message = `Error in saving task ${task.task}`;
        console.log('ex ===================>', ex);
      }
    }
  });

  validateInputs() {
    var dateStart = null;
    var dateEnd = null;

    this.model.isStartDateValid = true;
    this.model.isEndDateValid = true;

    if (this.isStartDateChecked) {
      if (this.model.startDate && moment(this.model.startDate).isValid()) {
        dateStart = moment(this.model.startDate);
        this.model.startDate = dateStart.format('YYYY-MM-DD');
      }

      if (this.model.startDate) {
        if (!this.pmService.isValidDate(this.model.startDate)) {
          this.model.isStartDateValid = false;
          return 'Invalid start date';
        }
      }
    }

    if (this.isEndDateChecked) {
      if (this.model.endDate && moment(this.model.endDate).isValid()) {
        dateEnd = moment(this.model.endDate);
        this.model.endDate = this.endDate.format('YYYY-MM-DD');
      }

      if (this.model.endDate) {
        if (!this.pmService.isValidDate(this.model.endDate)) {
          this.model.isEndDateValid = false;
          return 'Invalid end date';
        }
      }
    }

    if (dateStart && dateEnd) {
      // validate start date against end date
      if (dateStart.isAfter(dateEnd)) {
        this.model.isStartDateValid = false;
        return 'Start Date cannot be after end date';
      }

      //validate end date against start date
      if (dateEnd.isBefore(dateStart)) {
        this.model.isEndDateValid = true;
        return 'End Date cannot be before start date';
      }
    }

    return '';
  }

  setDates(task: any) {
    if (this.isStartDateChecked) {
      task.startDate = this.model.startDate;
    }

    if (this.isEndDateChecked) {
      task.endDate = this.model.endDate;
    }

    if (this.isProgressChecked) {
      task.progress = this.model.progress;
    }

    if (this.isStartDateChecked || this.isEndDateChecked) {
      // we will recalcute the duration and status
      this.pmService.computeTaskStatusFromDates(
        task,
        this.model.startDate,
        this.model.endDate
      );
    } else {
      // duration is not auto calculated here
      if (this.isDurationChecked) {
        task.duration = this.model.duration;

        if (task.duration) {
          if (task.startDate) {
            let newEndDate = moment(task.startDate)
              .add(task.duration, 'days')
              .format('YYYY-MM-DD');

            task.endDate = newEndDate;
          } else if (task.endDate) {
            let duration = parseInt(task.duration) * -1;
            let newStartDate = moment(this.endDate)
              .add(duration, 'days')
              .format('YYYY-MM-DD');

            task.startDate = newStartDate;
          }

          this.pmService.computeTaskStatusFromDates(
            task,
            task.startDate,
            task.endDate
          );
        }
      }

      // status is not auto calculated here
      if (this.isStatusChecked) {
        task.status = this.model.status;
      }
    }
  }

  setTaskStatus(task: any) {
    if (this.isStatusChecked) {
      if (this.selectedTaskStatus) {
        task.taskStatusId = this.selectedTaskStatus.id;
        task.status = this.selectedTaskStatus.name;
      } else {
        task.taskStatusId = null;
        task.status = null;
      }
    }
  }

  setTaskType(task: any) {
    if (this.isTypeChecked) {
      if (this.selectedTaskType) {
        task.taskTypeId = this.selectedTaskType.id;
        task.type = this.selectedTaskType.name;
      } else {
        task.taskTypeId = null;
        task.type = null;
      }
    }
  }

  setTaskCategory(task: any) {
    if (this.isCategoryChecked) {
      if (this.selectedTaskCategory) {
        task.taskCategoryId = this.selectedTaskCategory.id;
        task.category = this.selectedTaskCategory.name;
      } else {
        task.taskCategoryId = null;
        task.category = null;
      }
    }
  }

  setTaskPriority(task: any) {
    if (this.isPriorityChecked) {
      if (this.selectedTaskPriority) {
        task.taskPriorityId = this.selectedTaskPriority.id;
        task.priority = this.selectedTaskPriority.name;
      } else {
        task.taskPriorityId = null;
        task.priority = null;
      }
    }
  }

  setFolder(task: any) {
    if (this.isFolderNameChecked) {
      if (this.selectedSmartRoomFolder) {
        task.folderId = this.selectedSmartRoomFolder.id;
        task.folderNumber = this.selectedSmartRoomFolder.displayString;
        task.folderName = this.selectedSmartRoomFolderPath;
      } else {
        task.folderId = null;
        task.folderNumber = null;
        task.folderName = null;
      }
    }
  }

  @action hideCreateOptionOnSameTypeName(term: string) {
    let existingOption = this.taskTypes.find(({ name }) => name == term);
    return !existingOption;
  }

  @action createTaskType(taskTypeName: string) {
    this.createTaskTypeTask.perform(taskTypeName);
  }

  @task createTaskTypeTask = taskFor(async (newTaskTypeName: string) => {
    if (newTaskTypeName?.trim() != '') {
      let newTaskType = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskTypes,
        this.args.context.abLink
      );
      newTaskType.name = newTaskTypeName;
      newTaskType.createdDate = this.pmService.currentDate;
      newTaskType.createdBy = this.pmService.currentUserEmail;

      await newTaskType.save();

      newTaskType.taskTypeId = newTaskType.id;
      await newTaskType.save();

      this.taskTypes.update();
    }
  });

  @action hideCreateOptionOnSameCategoryName(term: string) {
    let existingOption = this.taskCategories.find(({ name }) => name == term);
    return !existingOption;
  }

  @action createTaskCategory(categoryName: string) {
    this.createTaskCategoryTask.perform(categoryName);
  }

  @task createTaskCategoryTask = taskFor(async (newCategoryName: string) => {
    if (newCategoryName?.trim() != '') {
      let newCategory = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskCategories,
        this.args.context.abLink
      );

      newCategory.name = newCategoryName;
      newCategory.createdDate = this.pmService.currentDate;
      newCategory.createdBy = this.pmService.currentUserEmail;

      await newCategory.save();

      newCategory.taskCategoryId = newCategory.id;
      await newCategory.save();

      this.currentTask.taskCategoryId = newCategory.id;
      this.currentTask.category = newCategoryName;

      this.taskCategories.update();
    }
  });

  @action hideCreateOptionOnSamePriorityName(term: string) {
    let existingOption = this.taskPriorities.find(({ name }) => name == term);
    return !existingOption;
  }

  @action createTaskPriority(priorityName: string) {
    this.createTaskPriorityTask.perform(priorityName);
  }

  @task createTaskPriorityTask = taskFor(async (newPriorityName: string) => {
    if (newPriorityName?.trim() != '') {
      let newPriority = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskPriorities,
        this.args.context.abLink
      );

      newPriority.name = newPriorityName;
      newPriority.createdDate = this.pmService.currentDate;
      newPriority.createdBy = this.pmService.currentUserEmail;
      await newPriority.save();

      newPriority.taskPriorityId = newPriority.id;
      await newPriority.save();

      this.taskPriorities.update();
    }
  });

  @action hideCreateOptionOnSameStatusName(term: string) {
    let existingOption = this.taskStatuses.find(({ name }) => name == term);
    return !existingOption;
  }

  @action createTaskStatus(statusName: string) {
    this.createTaskStatusTask.perform(statusName);
  }

  @task createTaskStatusTask = taskFor(async (newStatusName: string) => {
    if (newStatusName?.trim() != '') {
      let newStatus = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskStatuses,
        this.args.context.abLink
      );

      newStatus.name = newStatusName;
      newStatus.createdDate = this.pmService.currentDate;
      newStatus.createdBy = this.pmService.currentUserEmail;
      await newStatus.save();

      newStatus.taskStatusId = newStatus.id;
      await newStatus.save();

      this.taskStatuses.update();
    }
  });

  @action async onSmartRoomFolderSelected(folder: SmartroomFolderModel) {
    this.selectedSmartRoomFolder = folder;
    if (folder) {
      this.selectedSmartRoomFolderPath = await this.pmService.getFolderPath(
        this.args.context.smartRoomId,
        folder.id
      );
    } else {
      this.selectedSmartRoomFolderPath = null;
    }
    this.fieldChange('folderName');
    this.docker.removePopup(this.smartRoomIndexPopup);
  }

  @action showSmartRoomIndex() {
    let appearance = {
      icon: 'folder',
      title: `Select SmartRoom Folder`,
      custom: true,
    };
    let ctx = {
      abLink: this.args.context.abLink,
      smartRoomId: this.args.context.smartRoomId,
      onSelect: this.onSmartRoomFolderSelected,
    };

    this.smartRoomIndexPopup = this.docker.invokePopup(
      'ab-pm/select-sr-folder',
      appearance,
      ctx
    );
  }
}
