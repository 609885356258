import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
export default class PcapData extends Component {
  @alias('context.investor')
  investor;

  @alias('context.abInvestor')
  abInvestor;

  @alias('context.project')
  project;

  @service('ab-store')
  store;

  @service
  abModels;

  included = '';

  @computed('project.abProject')
  get modelName() {
    let abProject = this.get('project.abProject');

    if (abProject) {
      return this.abModels.getModelName(abProject, 'pcap-investor-data');
    }
  }

  init() {
    super.init(...arguments);
    this.set('columns', this.getColumns());

    this.generateFilter();
  }

  getColumns() {
    let className = 'pcap-col';

    let columns = [
      {
        propertyName: "balAtStmtDate",
        sortedBy: 'bal-at-stmt-date',
        component: 'api-table/columns/amount',
        className,
      },
      {
        propertyName: "contAtStmtDate",
        sortedBy: "cont-at-stmt-date",
        component: 'api-table/columns/amount',
        className,
      },
      {
        propertyName: "transfers",
        sortedBy: "transfers",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "intIncome",
        sortedBy: "int-income",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "orgCosts",
        sortedBy: "org-costs",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "doProfOther",
        sortedBy: "do-prof-other",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "mgmtFees",
        sortedBy: "mgmt-fees",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "netInvInc",
        sortedBy: "net-inv-inc",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "gainOnCarryUnrealized",
        sortedBy: "gain-on-carry-unrealized",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "gainOnInvUnrealized",
        sortedBy: "gain-on-inv-unrealized",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "totalGainOnInvUnrealized",
        sortedBy: "total-gain-on-inv-unrealized",
        component: 'api-table/columns/amount',
        className,
      },
      {
        propertyName: "equDueToPartners",
        sortedBy: "equ-due-to-partners",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "invDistYtd",
        sortedBy: "inv-dist-ytd",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "gpMgtFee",
        sortedBy: "gp-mgt-fee",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "partnersCapitalDist",
        sortedBy: "partners-capital-dist",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "partnersSyndCosts",
        sortedBy: "partners-synd-costs",
        component: 'api-table/columns/amount',
        isHidden: true,
        className,
      },
      {
        propertyName: "balAsStmtDateEstFairVal",
        sortedBy: "bal-as-stmt-date-est-fair-val",
        component: 'api-table/columns/amount',
        className,
      },
      {
        propertyName: "totalNetContSinceIncep",
        sortedBy: "total-net-cont-since-incep",
        component: 'api-table/columns/amount',
        className,
      },
      {
        propertyName: "totalNetDistSinceIncep",
        sortedBy: "total-net-dist-since-incep",
        component: 'api-table/columns/amount',
        className,
      },
    ];

    return columns;
  }

  generateFilter() {
    let filter = {
      'inv-fund-id': 'eq:' + this.get('abInvestor.invFundId')
    }

    this.set('filter', filter);
  }
}




