import classic from 'ember-classic-decorator';
import ApplicationAdapter from './application';

@classic
export default class AlertAnnouncementRecipientAdapter extends ApplicationAdapter {}
// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'alert-announcement-recipient': AlertAnnouncementRecipientAdapter;
  }
}
