import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';

import _SessionService from 'cing-app/pods/session/service';
import AbPmService, { IPmTaskDocument } from 'cing-app/pods/ab-pm/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import { SmartroomFolderModel } from 'smex-ui-sr-models';

interface SelectSrFolderArgs {
  context: {
    abLink: any;
    smartRoomId: any;
    onSelect: any;
  };
}

export default class SelectSrFolder extends Component<SelectSrFolderArgs> {
  @service seActions: any;
  @service('ab-pm') pmService!: AbPmService;
  @service('docker-item') docker!: DockerItemService;
  @service declare store: Store;

  @tracked smartRoomSiteInfo: any;
  @tracked smartRoomRootFolderInfo: any;

  @tracked rootFolderId: any;

  @tracked selectedSmartRoomFolder: any = null;

  constructor(owner: any, args: any) {
    super(owner, args);
  }

  get isBusy() {
    return this.initTask.isRunning;
  }

  @action init() {
    this.initTask.perform();
  }

  @task initTask = taskFor(async () => {
    this.smartRoomSiteInfo = await this.store.findRecord(
      'smartroom/site',
      this.args.context.smartRoomId
    );

    this.rootFolderId = this.smartRoomSiteInfo.topLevelFolderId;
    if (this.rootFolderId) {
      console.log('root folder id = ', this.rootFolderId);
      this.smartRoomRootFolderInfo = await this.store.queryRecord(
        'smartroom/folder',
        {
          id: this.rootFolderId,
          siteId: this.args.context.smartRoomId,
        }
      );

      this.selectedSmartRoomFolder = this.smartRoomRootFolderInfo;
    }
  });

  @action selectSmartRoomItem(item: any) {
    console.log('select sr item  ', item);
    if (item instanceof SmartroomFolderModel) {
      if (this.args.context.onSelect) {
        this.args.context.onSelect(item);
      }
    }
  }
}
