//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
//@ts-ignore
import { fragment } from 'ember-data-model-fragments/attributes';
import ProjectTabSettingsFieldItem from './project-tab-settings-field-item';

export default class ProjectTabSettingsFundFields extends Fragment {
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: true },
  })
  declare publicDescription: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare fundStatus: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare fundType: ProjectTabSettingsFieldItem;

  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare projectedCommitmfent: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare minimumCommitment: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare totalCommitment: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare percentageCommitted: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare capitalCalled: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare capitalDrawn: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare dryPowder: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare investedCapital: ProjectTabSettingsFieldItem;

  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare targetIRR: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare targetMOIC: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare targetSize: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare vintageYear: ProjectTabSettingsFieldItem;

  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: false, tile: false },
  })
  declare assetClass: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: false, tile: false },
  })
  declare managementFees: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: false, tile: false },
  })
  declare carriedInterest: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: false, tile: false },
  })
  declare preferredReturn: ProjectTabSettingsFieldItem;

  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare geography: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare industryFocus: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare fundLifetime: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare investmentPeriod: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare closingDate: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare dateStarted: ProjectTabSettingsFieldItem;

  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare strategy: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare overview: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare exits: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare netIRR: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare amountsAsOfDate: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare lpaDate: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare indicatedCommitment: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare grossIRR: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare netMOIC: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare grossMOIC: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: true, view: true, tile: false },
  })
  declare dpi: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: false, view: false, tile: false },
  })
  declare customText1: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: false, view: false, tile: false },
  })
  declare customText2: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: false, view: false, tile: false },
  })
  declare customText3: ProjectTabSettingsFieldItem;
  @fragment('project-tab-settings-field-item', {
    defaultValue: { title: '', enabled: false, view: false, tile: false },
  })
  declare customText4: ProjectTabSettingsFieldItem;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-tab-settings-fund-fields': ProjectTabSettingsFundFields;
  }
}
