import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
import Component from '@glimmer/component';
import EmberObject, { action, get } from '@ember/object';
import { all, timeout } from 'ember-concurrency';
import { task, restartableTask } from 'ember-concurrency'
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import { tracked } from '@glimmer/tracking';
import {
  Filter,
  Expressions,
  FilterOperators
} from 'cing-app/mixins/filter-builder';
import { GenderType, PersonInCompanyFlags } from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import UserGroup from 'cing-app/models/user-group';
import Person from 'cing-app/models/person';
import Company from 'cing-app/models/company';
import PersonInCompany from 'cing-app/models/person-in-company';
import Phone from 'cing-app/models/phone';
import Email from 'cing-app/models/email';
import Address from 'cing-app/models/address';
import { getOwner } from "@ember/application";

interface ContactFormArgs {
  context: {
    person: Person,
    company: Company
  };
  onClose: any;
  footer: any;
}

export const AddressTypes = {
  PERSONAL: 1,
  BUSINESS: 2
};

const GenderTypeOptions = Object.keys(GenderType).map(k => {
  return { id: GenderType[k], name: k }
});

class AddressContainer {
  type = AddressTypes.PERSONAL;
  address = null;
  personInCompany = null;
  newCompanyName = null;

  constructor(type, address?, personInCompany?, newCompanyName?) {
    this.type = type;
    this.address = address;
    this.personInCompany = personInCompany;
    this.newCompanyName = newCompanyName;
  }
}

@classic
class FullNameModel extends EmberObject {
  @tracked
  prefix: string = "";

  @tracked
  firstName: string = "";

  @tracked
  middleName: string = "";

  @tracked
  lastName: string = "";

  @tracked
  suffix: string = "";
}

export default class ContactForm extends Component<ContactFormArgs> {
  @service store!: Store;

  @service('docker-item') docker!: DockerItemService;

  @tracked
  person: Person;

  @tracked
  phones: Phone[];

  @tracked
  emails: Email[];

  @tracked
  personInCompanies: PersonInCompany[];

  @tracked
  companies: Company[];

  @tracked
  makeRelated: boolean = false;

  @tracked
  addAddress: boolean = false;

  @tracked
  addresses: AddressContainer[];

  @tracked
  showFormErrors: boolean = false;

  @tracked
  modalEditPersonInCompany?: PersonInCompany;

  @tracked
  modalEditName: boolean = false;

  @tracked
  modalEditAddress?: AddressContainer;

  @tracked
  confirmDisassociateUser: boolean = false;

  @tracked
  confirmDelete: boolean = false;

  AddressTypes = AddressTypes;
  USStates = USStates;
  PersonInCompanyFlags = PersonInCompanyFlags;
  GenderTypeOptions = GenderTypeOptions;

  @tracked
  createUserAccount = false;

  @tracked
  newUserAccountGroup?: UserGroup;

  @tracked
  linkUserWithPerson = false;

  fullNameModel: FullNameModel;

  constructor(owner: any, args: ContactFormArgs) {
    super(owner, args);
    this.person = this.args.context.person;
    this.personInCompanies = [];
    this.companies = [];
    this.emails = [];
    this.phones = [];
    this.addresses = [];
    this.fullNameModel = FullNameModel.create(getOwner(this).ownerInjection());

    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    if (!this.person.get('isNew') && this.person.get('id')) {
      this.person = await this.store.findRecord('person', this.person.get('id'), { include: 'company,companies,emails,phones,person-in-company,person-in-companies.company', reload: true });

      this.loadAddressesTask.perform(this.person);

      this.phones = (await this.person.phones).toArray();
      this.emails = (await this.person.emails).toArray();
      this.personInCompanies = (await this.person.personInCompanies).toArray();

      this.companies = (await this.person.companies).uniq();
    }
    else {
      this.makeRelated = true;

      if (this.args.context.company) {
        this.addAddress = true;
      }

      this.phones = [];
      this.emails = [];
      this.personInCompanies = [];
      this.person.updateFullName();

      if (this.args.context.company) {
        this.personInCompanies.pushObject(
          this.store.createRecord('person-in-company', {
            person: this.person,
            company: this.args.context.company,
            companyId: this.args.context.company.id
          })
        );
      }
    }
  })

  @task
  loadAddressesTask = taskFor(async (attorney: Person) => {
    this.addresses = [];

    // get all personal addresses associated with the person
    let exprPA = Expressions.create();
    exprPA.add(Filter.create({
      name: "personId",
      operator: FilterOperators.EQUAL,
      value: attorney.id
    }));

    let personalAddresses = await this.store.query('address', {
      include: 'country,state,company',
      condition: exprPA.serialize(),
      page: {
        size: 100
      }
    });

    personalAddresses.forEach((address) => {
      this.addresses.pushObject(new AddressContainer(
        address.companyId ? AddressTypes.BUSINESS : AddressTypes.PERSONAL,
        address
      ))
    });

    // get all company addresses associated with the person
    let exprCA = Expressions.create();
    exprCA.add(Filter.create({
      name: "personId",
      operator: FilterOperators.EQUAL,
      value: attorney.id
    }));
    exprCA.add(Filter.create({
      name: "addressId",
      operator: FilterOperators.NOT_NULL,
    }));

    let personInCompanies = (await this.store.query('person-in-company', {
      condition: exprCA.serialize(),
      include: 'company,address.country,address.state,address.company',
      page: {
        size: 100
      }
    })).toArray();

    for (var a = 0; a < personInCompanies.length; a++) {
      let pic = personInCompanies[a];

      this.addresses.pushObject(new AddressContainer(
        AddressTypes.BUSINESS,
        await pic.address,
        pic,
      ))
    }
  })

  get userGroups() {
    return this.store.query('user-group', {
      page: {
        size: 1000
      }
    })
  }

  @restartableTask
  findExistingUserTask = taskFor(async (skipTimeout = false) => {
    if (!skipTimeout) {
      await timeout(500);
    }

    let firstEmail = this.emails.firstObject;

    let existingUserQuery = Expressions.create();
    existingUserQuery.add(Filter.create({
      name: 'email',
      operator: FilterOperators.EQUAL,
      value: firstEmail.emailAddress
    }));

    let user = (await this.store.query('user', {
      condition: existingUserQuery.serialize(),
      include: 'groups',
      page: {
        size: 1
      }
    })).firstObject;

    return user;
  })

  get existingUser() {
    if (this.emails.length) {
      return this.findExistingUserTask.perform();
    }
  }

  @task
  saveTask = taskFor(async () => {

    await this.person.save();

    let saveTasks = [];
    let genericError = new Error("Some changes could not be saved. Please review the highlighted fields.");

    for (var a = 0; a < this.phones.length; a++) {
      let phone = this.phones[a];

      if (phone.isDeleted) {
        saveTasks.push(this.persistRecord.perform(phone));
      } else {
        if (!get(phone, 'validations.isValid')) {
          throw genericError;
        }

        if (phone.hasDirtyAttributes) {
          phone.personId = this.person.id;
          phone.person = this.person;
          saveTasks.push(this.persistRecord.perform(phone));
        }
      }
    }

    for (var a = 0; a < this.emails.length; a++) {
      let email = this.emails[a];

      if (email.isDeleted) {
        saveTasks.push(this.persistRecord.perform(email));
      } else {
        if (!get(email, 'validations.isValid')) {
          throw genericError;
        }

        if (email.hasDirtyAttributes) {
          email.personId = this.person.id;
          email.person = this.person;
          saveTasks.push(this.persistRecord.perform(email));
        }
      }
    }

    for (var a = 0; a < this.personInCompanies.length; a++) {
      let pic = this.personInCompanies[a];

      if (pic.isDeleted) {
        saveTasks.push(this.persistRecord.perform(pic));
      } else {
        if (!pic.get('validations.isValid')) {
          throw genericError;
        }

        if (pic.get('hasDirtyAttributes')) {
          pic.personId = this.person.id;
          pic.person = this.person;
          saveTasks.push(this.persistRecord.perform(pic));
        }
      }
    }

    await all(saveTasks);

    let person = await this.store.findRecord('person', this.person.id, {
      reload: true,
      include: 'company,companies,emails,phones,person-in-company,person-in-companies.company'
    });

    if (this.createUserAccount) {
      let userEmail = this.emails.firstObject.emailAddress;

      let userAccount = await this.existingUser;

      if (!userAccount) {
        userAccount = this.store.createRecord('user', {
          personId: person.id,
          email: userEmail,
          person: person
        });

        let userGroups = await userAccount.groups;
        await userAccount.save();

        if (this.newUserAccountGroup && !userGroups.includes(this.newUserAccountGroup)) {
          let userGroup = (await this.store.createRecord('user-in-group', {
            user: userAccount,
            userGroup: this.newUserAccountGroup
          }));

          await userGroup.save();
        }
      } else if (this.linkUserWithPerson) {
        userAccount.personId = person.id;
        userAccount.person = person;
        await userAccount.save();
      }
    }

    this.phones = await person.phones.toArray();
    this.emails = await person.emails.toArray();
    this.personInCompanies = await person.personInCompanies.toArray();
    this.companies = await person.companies.uniq();

    this.showFormErrors = false;

    if (this.args.context.onCreate) {
      await timeout(500);
      this.args.context.onCreate(person);
    }
  })

  @task
  removeContactTask = taskFor(async () => {
    let saveTasks = [];

    for (var a = 0; a < this.phones.length; a++) {
      let phone = this.phones[a];
      phone.deleteRecord();
      saveTasks.push(this.persistRecord.perform(phone));
    }

    for (var a = 0; a < this.emails.length; a++) {
      let email = this.emails[a];
      email.deleteRecord();
      saveTasks.push(this.persistRecord.perform(email));
    }

    for (var a = 0; a < this.personInCompanies.length; a++) {
      let pic = this.personInCompanies[a];
      pic.deleteRecord();
      saveTasks.push(this.persistRecord.perform(pic));
    }

    this.person.deleteRecord();
    saveTasks.push(this.persistRecord.perform(this.person));

    await all(saveTasks);

    if (this.args.context && this.args.context.onRemove) {
      this.args.context.onRemove();
    }
    if (this.args.onClose) {
      this.args.onClose();
    }

  })

  @task
  removePersonInCompanyTask = taskFor(async (personInCompany) => {
    this.modalEditPersonInCompany = undefined;
    this.personInCompanies.removeObject(personInCompany);
    await personInCompany.destroyRecord();
    this.loadAddressesTask.perform(this.person);
  })

  @task({
    maxConcurrency: 3,
    enqueue: true,
  })
  persistRecord = taskFor(async (record) => {
    await record.save();
  })

  @restartableTask
  searchCompaniesTask = taskFor(async (term) => {
    await timeout(300);

    if (!term) {
      return null;
    }

    //API call
    let expr = Expressions.create();

    expr.add(Filter.create({
      name: 'name',
      operator: FilterOperators.LIKE,
      value: term
    }));

    return await this.store.query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 50, number: 1 }
    });
  })

  @restartableTask
  onFullNameChangedTask = taskFor(async (fullName) => {
    await timeout(300);
    this.person.parseFromFullName(fullName)
  });

  @task
  disassociateUserTask = taskFor(async () => {
    let user = await this.person.get('user');
    user.personId = null;
    user.person = null;

    await user.save();

    this.confirmDisassociateUser = false;
  });

  @action
  cancel() {
    if (!this.person.get('isNew')) {
      this.person.rollbackAttributes();
    }
  }

  @action
  save() {
    this.showFormErrors = true;

    if (this.person.get('validations.isValid')) {
      this.saveTask.perform();
    }
  }

  @action
  updateFullNameModel(){
    this.fullNameModel.prefix = this.person.prefix;
    this.fullNameModel.firstName = this.person.firstName;
    this.fullNameModel.middleName = this.person.middleName;
    this.fullNameModel.lastName = this.person.lastName;
    this.fullNameModel.suffix = this.person.suffix;

    this.modalEditName = true;
  }

  @action
  updateFullName() {
    this.modalEditName = false;
    this.person.prefix = this.fullNameModel.prefix;
    this.person.firstName = this.fullNameModel.firstName;
    this.person.middleName = this.fullNameModel.middleName;
    this.person.lastName = this.fullNameModel.lastName;
    this.person.suffix = this.fullNameModel.suffix;
    
    this.person.updateFullName();
  }

  @action
  cancelUpdateFullName() {
    this.modalEditName = false;
  }

  @action
  onFullNameChanged(fullName) {
    this.onFullNameChangedTask.perform(fullName);
  }

  @action
  doRemoveContact() {
    this.removeContactTask.perform();
  }

  @action
  searchCompanies(term) {
    return this.searchCompaniesTask.perform(term);
  }

  @action
  reload() {
    this.initTask.perform();
  }

  @action
  reloadAddresses() {
    this.loadAddressesTask.perform(this.person);
  }

  @action
  addPhone() {
    let phone = this.store.createRecord('phone', {
      person: this.person
    });

    this.phones.pushObject(phone);
  }

  @action
  removePhone(phone) {
    if (phone.isNew) {
      this.phones.removeObject(phone);
    }

    phone.deleteRecord();
  }

  @action
  addPersonInCompany() {
    this.modalEditPersonInCompany = this.store.createRecord('person-in-company', {
      person: this.person
    });
  }

  @action
  addNewPersonInCompany(personInCompany) {
    if (!this.personInCompanies.includes(personInCompany)) {
      this.personInCompanies.pushObject(personInCompany);
    }
    this.modalEditPersonInCompany = undefined;
  }

  @action
  removePersonInCompany(model) {
    this.removePersonInCompanyTask.perform(model);
  }

  @action
  addEmail() {
    let email = this.store.createRecord('email', {
      person: this.person
    });

    this.emails.pushObject(email);
  }

  @action
  removeEmail(email) {
    if (email.isNew) {
      this.emails.removeObject(email);
    }

    email.deleteRecord();
  }

  @action
  showCompanyDetail(company) {
    const appearance = {
      label: 'Company detail',
      icon: '',
      title: 'Company: ' + get(company, 'name')
    };

    const context = { companyId: company.id };
    this.docker.invokePopup('company-detail', appearance, context);
  }

  @action
  addNewAddress() {
    let newAddressContainer = new AddressContainer(
      AddressTypes.PERSONAL,
      this.store.createRecord("address", {
        person: this.person,
        newAddressType: AddressTypes.PERSONAL // this is artificial property used only in the form!
      })
    );

    this.modalEditAddress = newAddressContainer;
  }

  @action
  openBIOPageURL() {
    let url = this.person.get('publicProfileUrl');

    if(!url.match(/^https?:\/\//i)){
      url = 'http://'+ url;
    }
    window.open(url,"_blank");
  }

  @action
  disassociateUser() {
    this.disassociateUserTask.perform();
  }
}
