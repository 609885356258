import classic from 'ember-classic-decorator';
import ApplicationSerializer from './application';

@classic
export default class UserSerializer extends ApplicationSerializer {
  attrs = {
    //email: { serialize: false},
    //flags: { serialize: false},
    lastAccess: { serialize: false },
    modifyTime: { serialize: false },
    createTime: { serialize: false },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    user: UserSerializer;
  }
}
