import Component from '@ember/component';
import { action, set, get, computed } from '@ember/object';
import imageResize from 'image-resize-util/utils/image-resize';
import { debounce } from '@ember/runloop';
import { join } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import classic from 'ember-classic-decorator';
import file from 'cing-app/adapters/smartroom/file';

const NoImageProcessingTypes = ['image/svg+xml'];
const NoImageProcessingExtensions = ['svg'];
@classic
export default class ImageUpload extends Component {
  classNameBindings = ['isDragOver:drag-over'];
  imageUrl = null;
  isDragOver = false;
  maxWidth = 1000;
  photoLabel = 'Image';
  showInputSelector = true;
  allowEdit = true;

  @service seActions;

  init() {
    super.init(...arguments);
    //console.log("CROPPER JS: ", CropperJS);
    /*

    import('cropperjs').then((module) => {
        console.log("CropperJS: ", module);
        this._Cropper = module.default;
        join(() => this._setup());
    });        
    */
  }

  @computed('imageUrl')
  get canProcessImage() {
    try {
      return !NoImageProcessingExtensions.includes(
        this.imageUrl.split('.').pop()
      );
    } catch (e) {
      return false;
    }
  }

  @action
  dragOver(event) {
    event.preventDefault();
    this.set('isDragOver', true);
  }

  @action
  dragEnter(event) {
    this.set('isDragOver', true);
    event.preventDefault();
  }

  @action
  click(event) {
    let fileElement = this.element.querySelector('[type=file]');
    fileElement.click();
    //event.preventDefault();
  }

  @action
  dragLeave(event) {
    this.set('isDragOver', false);
    event.preventDefault();
  }

  @action
  dragEnd(event) {
    this.set('isDragOver', false);
  }

  @action
  drop(event) {
    if (this.isDragOver) {
      this.set('isDragOver', false);
      event.preventDefault();

      var files = event.dataTransfer.files;

      if (files.length > 0) {
        this.selectFile(files[0]);
      }
    }
  }

  selectFile(file) {
    if (NoImageProcessingTypes.includes(file.type)) {
      this.setImageUrlTask.perform(file, file.name);
    } else {
      imageResize(
        file,
        {
          maxWidth: this.maxWidth,
          keepRatio: true,
        },
        { alpha: true }
      ).then((blob) => {
        this.setImageUrlTask.perform(blob, blob.name);
      });
    }
  }

  @task
  *setImageUrlTask(blob, fileName) {
    let uploadResult = yield this.seActions.uploadImage(
      blob,
      '',
      true,
      fileName
    );

    this.set('imageUrl', uploadResult.url);
    this.set('imgNotProvided', false);

    if (this.imageSelected) {
      this.imageSelected(uploadResult.url);
    }
  }

  @action
  onSelectFile(event) {
    this.selectFile(event.target.files[0]);
  }

  @action
  clear() {
    this.set('imageUrl', '');
  }

  @action
  registerCrop(cropper) {
    set(this, 'cropper', cropper);
  }

  @action
  applyEdits() {
    set(this, 'modifyImage', false);

    this.cropper.getCroppedCanvas({}).toBlob((blob) => {
      let fileName = '';

      if (this.imageUrl) {
        fileName = this.imageUrl.split('/').pop();
      }

      this.setImageUrlTask.perform(blob, fileName);
    });
  }

  @action
  cropStyle(aspectRatio) {
    set(this, 'aspectRatio', aspectRatio);
  }
}
