import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
// import Models from 'smex-ui-sr-models/services/models';
// import { pluralize } from 'ember-inflector';
import classic from 'ember-classic-decorator';

@classic
export default class AppbuilderLinkAdapter extends JSONAPIAdapter {
  @service session: any;
  @service serverVariables: any;
  @service config: any;

  get headers() {
    let headers: any = {};

    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';

    return headers;
  }

  @alias('config.APP.api.host') host!: string;
  @alias('config.APP.api.namespace') namespace!: string;

  // @alias('serverVariables.appBuilderApiHostname') host!: string;
  // @alias('serverVariables.appBuilderApiNamespace') namespace!: string;

  // pathForType(modelName: string) {
  //   let match = modelName.match(/^appbuilder\/(.*)$/);
  //   if (match) {
  //     // return `${pluralize(match[1])}`;
  //     return `appbuilder-links/{linkId}/ab/${pluralize(match[1])}`;
  //   } else {
  //     throw new Error('Unknown model name: ' + modelName);
  //   }
  // }

  // buildURL(modelName, id, snapshot, requestType, query) {
  //   let url = super.buildURL(modelName, id, snapshot, requestType, query);
  //   let linkId = query?.appbuilderLinkId ?? snapshot.adapterOptions.appbuilderLinkId;
  //   delete query.appbuilderLinkId;

  //   if (!linkId) {
  //     throw new Error('Appbuilder link id is required in ab-data-adapter');
  //   }
  //   url = url.replace("{linkId}", linkId);
  //   return url;
  // }
}
