import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { defineProperty, action } from '@ember/object';

@classic
@classNames('power-select-sm')
export default class ColumnSelect extends Component {
  @service
  store;

  init() {
    super.init();
    let propertyName = this.get('column.propertyName');
    defineProperty(this, 'value', alias(`record.${propertyName}`));
    let optionLabelProperty = this.get('column.optionLabelProperty') || 'name';
    let selected = this.get('column.options').findBy(optionLabelProperty, this.get(`record.${propertyName}`));

    if (!selected && this.get('column.defaultOption')) {
      selected = this.get('column.defaultOption');
    }

    if (selected) {
      this.send('onSelect', selected);
    }
  }

  @task(function* (newTypeName, options) {
    let optionLabelProperty = this.get('column.optionLabelProperty') || 'name';

    let newType = this.store.createRecord(this.column.createModelName, {
      name: newTypeName
    });

    yield newType.save();

    this.set('selected', newType);
    this.get('column.options').pushObject(newType);
    this.set(`record.${this.column.propertyName}`, newType.get(optionLabelProperty));
  })
  createTask;

  stopPropagation(e) {
    e.stopPropagation();
    return false;
  }

  click(e) {
    return false;
  }

  @action
  hideCreateOnSame(term) {
    let optionLabelProperty = this.get('column.optionLabelProperty') || 'name';
    let existingOption = this.get('column.options').findBy(optionLabelProperty, term);
    return !existingOption;
  }

  @action
  onSelect(option) {
    this.set('selected', option);
    let optionLabelProperty = this.get('column.optionLabelProperty') || 'name';

    if (option) {
      this.set(`record.${this.column.propertyName}`, option.get(optionLabelProperty));
    } else {
      this.set(`record.${this.column.propertyName}`, null);
    }
  }

  @action
  create(newTypeName, options) {
    this.createTask.perform(newTypeName)
  }

  @action
  remove(option) {
    option.destroyRecord().then((result) => {
      this.get('column.options').removeObject(option);
    });
  }
}