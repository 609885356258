import Field from '../field/component';
import { task } from 'ember-concurrency';
import { get, set, computed } from '@ember/object';
import { defineProperty } from '@ember/object';
// import { computed } from '@ember/object';

export default Field.extend({
  showFormValidations: null,
  allowClear: true,
  type: 'text',
  selectedOption: null,
  searchEnabled: false,
  // NOTE: this should default to 'null', but needs refactoring of how select component is used
  valueProperty: 'id',
  init() {
    this._super();
    defineProperty(this, 'selectedOption', computed(`model.${this.property}`, 'options.[]', {
      get() {
        return this._getValue.perform();
      },
      set(key, value) {
        if (value) {
          if (this.valueProperty) {
            set(this.model, this.property, get(value, this.valueProperty));
          } else {
            set(this.model, this.property, value);
          }
        } else {
          set(this.model, this.property, null);
        }

        return this._getValue.perform();
      }
    }))
  },

  _getValue: task(function* () {
    let options = yield this.options;

    let value = yield get(this.model, this.property);

    if (this.valueProperty && options) {
      return options.findBy(this.valueProperty, value);
    } else if (!this.valueProperty) {
      return value;
    }
  }),

  actions: {
    onInput() {
      this.sendAction('onInput', this.selectedOption);
    },
    onSelectChange(selected) {
      set(this, 'selectedOption', selected);

      if (this.onChange) {
        this.onChange(get(this.model, this.property));
      }
    }
  }
});
