import Component from '@ember/component';
import { action, get, set, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { all, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
  EReportStatus,
  ProjectTabMap,
} from 'cing-app/utils/lookups';
import { data } from 'jquery';
import { isEmpty } from '@ember/utils';

export const SYNC_MISSING = 1;
export const SYNC_DELETED = 2;
export const SYNC_NAME_CHANGED = 3;
export const SYNC_COMPANY_CHANGED = 4;
export const SYNC_INVESTOR_CHANGED = 5;
export const SYNC_EMAIL_CHANGED = 6;
export const SYNC_ROLE_CHANGED = 7;
export const SYNC_FOLDERS_CHANGED = 8;

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  a = a.sort();
  b = b.sort();

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

function normalizeString(str) {
  if (!str) {
    return str;
  }

  let outStr = JSON.stringify(str)
    .replace(/[^0-9a-z]/gi, ' ')
    .replace(/\s+/g, ' ')
    .toLowerCase();
  return outStr;
}

class SyncItem {
  doSync = false;
  dcLP = null;
  party = null;
  results = null;

  _dcContacts = null;
  _parties = null;

  constructor(dcContacts, parties) {
    this._dcContacts = dcContacts;
    this._parties = parties;
    this.results = [];
  }

  fromDCList(dcLP) {
    // save the LP comm first
    set(this, 'dcLP', dcLP);

    // NOW PERFORM ANALYSIS
    // try to get the party by either LP DealCloudId
    set(this, 'party', this._parties.findBy('dealCloudId', dcLP.id));

    // NOT PRESENT - MARK AS NEW RECORD
    if (!this.party) {
      set(this, 'doSync', true);
      this.results.pushObject(SYNC_MISSING);
      return;
    }

    // CHECK THE IF THE NAME HAS CHANGED
    // get normalized LP contact name
    let dcContactName = normalizeString(get(dcLP, 'person.fullName'));

    // CHECK IF CONTACT NAME HAS CHANGED
    if (
      normalizeString(get(dcLP, 'person.fullName')) !==
      normalizeString(get(party, 'person.fullName'))
    ) {
      this.results.pushObject(SYNC_NAME_CHANGED);
    }

    // CHECK IF COMPANY NAME HAS CHANGED
    if (
      normalizeString(get(dcLP, 'company.name')) !==
      normalizeString(get(party, 'company.name'))
    ) {
      this.results.pushObject(SYNC_COMPANY_CHANGED);
    }

    // CHECK IF INVESTOR HAS CHANGED
    if (
      normalizeString(get(dcLP, 'investor.name')) !==
      normalizeString(get(party, 'investor.name'))
    ) {
      this.results.pushObject(SYNC_COMPANY_CHANGED);
    }

    // CHECK IF EMAIL HAS CHANGED
    if (
      normalizeString(get(dcLP, 'email')) !==
      normalizeString(get(party, 'email.emailAddress'))
    ) {
      this.results.pushObject(SYNC_EMAIL_CHANGED);
    }

    if (get(this, 'results.length')) {
      set(this, 'doSync', true);
    }
  }

  fromParty(party) {
    // save the party first
    set(this, 'party', party);

    // find the investor
    //if (get(party, 'investor.investorNumber')) {
    //  set(this, 'investor', get(party, 'investor'));
    //}

    this.results.pushObject(SYNC_DELETED);

    if (get(this, 'results.length')) {
      set(this, 'doSync', true);
    }
  }

  matchParty(party) {
    if (party === this.party && get(party, 'dealCloudId') === this.dcLP.id) {
      return true;
    }

    return false;
  }

  _getPartyFromDCLP(dcLP) {
    // try to find the party by matching the deal cloud id
    let party = this._parties.findBy('person.dealCloudId', dcLP.id);
  }
}
import classic from 'ember-classic-decorator';

@classic
export default class SyncDCContacts extends Component {
  @service smartroom;
  @service store;
  @service config;
  @service abModels;
  @service seActions;

  @alias('context.project') project;

  fund = null;
  dcFund = null;

  investors = null;
  parties = null;
  dcInvestors = null;
  dcLPList = null;
  srUsers = null;
  srCategories = null;
  allSelected = false;

  showChangesOnly = true;
  interestedPartyRoles = null;

  init() {
    super.init(...arguments);
    this.initTask.perform();
  }

  @task
  *initTask() {
    set(this, 'syncItems', []);
    set(this, 'fund', yield get(this.project, 'fund'));

    if (!this.fund) {
      throw Error('DealCloud sync is only available for projects with Fund');
    }

    // retrieve the DC fund associated with the project
    set(this, 'dcFund', get(this.project, 'dealCloudFund'));

    // retrieve the available interested party roles
    let interestedPartyRoles = yield this.store.query('interested-party-role', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });

    set(this, 'interestedPartyRoles', interestedPartyRoles.toArray());

    // get the smartroomid, top level folder and default all access folders
    let srConnection = this.context.srConnection;

    if (srConnection) {
      this.setProperties({
        smartRoomId: srConnection.SRSiteId,
        smartRoomFolderId: srConnection.SRFolderId,
        defaultAllAccessFolders: yield srConnection.allAccessSmartRoomFolders,
      });
    }

    try {
      if (this.smartRoomId) {
        // get all the SmartRoom users
        set(this, 'srUsers', yield this.getSRUsers.perform());

        // get all the SmartRoom users
        set(this, 'srCategories', yield this.getSRCategories.perform());
      }

      // get all interested parties
      set(this, 'parties', yield this.getParties.perform());

      // get all investors
      set(this, 'investors', yield this.getInvestors.perform());

      //set(this, "dcContacts", (yield this.getDCParties.perform(this.parties.mapBy('person.dealCloudId'))));
      set(this, 'dcLPList', yield this.getDCLPList.perform());

      yield this.analyze.perform();
    } catch (e) {
      console.error('ERRORS: ', e);
    }
  }

  @computed('syncItems.[]', 'showChangesOnly')
  get filteredSyncItems() {
    if (this.syncItems && this.syncItems.length) {
      if (this.showChangesOnly) {
        return this.syncItems.filter((item) => {
          return get(item, 'results.length');
        });
      } else {
        return this.syncItems;
      }
    }
  }

  @computed('filteredSyncItems.@each.doSync')
  get selectedItems() {
    if (this.filteredSyncItems && this.filteredSyncItems.length) {
      return this.filteredSyncItems.filter((item) => {
        return item.doSync;
      });
    }

    return [];
  }

  @task
  *getParties() {
    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.project.id,
      })
    );
    query.add(
      Filter.create({
        name: 'person-id',
        operator: FilterOperators.NOT_NULL,
      })
    );

    let data = yield this.store.query('interested-party', {
      condition: query.serialize(),
      include: 'email,company,person.person-in-companies,investor',
      page: {
        size: 1000,
        number: 1,
      },
    });

    return data.toArray();
  }

  @task
  *getInvestors() {
    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'fund-id',
        operator: FilterOperators.EQUAL,
        value: this.fund.id,
      })
    );

    let data = yield this.store.query('investor', {
      condition: query.serialize(),
      include: 'company,type',
      page: {
        size: 1000,
        number: 1,
      },
    });

    return data.toArray();
  }

  @task
  *getDCLPList() {
    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'fund',
        operator: FilterOperators.EQUAL,
        value: this.dcFund.id,
      })
    );
    let data = yield this.store.query('sync-contact-fund-search-item', {
      condition: query.serialize(),
      include: 'person,company,investor',
      page: {
        size: 10000,
        number: 1,
      },
    });

    return data.toArray();
  }

  @task
  *getDCParties(dealCloudIds) {
    let getParties = [];

    for (var a = 0; a < dealCloudIds.length; a++) {
      getParties.push(this._getDCParty.perform(dealCloudIds[a]));
    }

    let results = yield all(getParties);

    return results.compact().uniq();
  }

  @task
  *getSRUsers() {
    let data = yield this.store.query('smartroom/user', {
      siteId: this.smartRoomId,
      pageNo: 1,
      pageSize: 10000,
    });

    return data.toArray();
  }

  @task
  *getSRCategories() {
    let data = yield this.store.query('smartroom/category', {
      siteId: this.smartRoomId,
      pageNo: 1,
      pageSize: 10000,
    });

    return data.toArray();
  }

  @task({
    maxConcurrency: 4,
  })
  *_getDCParty(dealCloudId) {
    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'id',
        operator: FilterOperators.EQUAL,
        value: dealCloudId,
      })
    );

    return (yield this.store.query('sync-contact', {
      condition: query.serialize(),
      include: 'company',
      page: {
        size: 1,
        number: 1,
      },
    })).firstObject;
  }

  createSyncItem() {
    return new SyncItem(this.dcContacts, this.parties);
  }

  findSyncItemForParty(party) {
    let matchingSyncItem = null;

    for (var a = 0; a < this.syncItems.length; a++) {
      let syncItem = this.syncItems[a];

      if (syncItem.matchParty(party)) {
        matchingSyncItem = syncItem;
        break;
      }
    }

    if (!matchingSyncItem) {
      let syncItem = this.createSyncItem();

      syncItem.fromParty(party);

      return syncItem;
    }
  }

  @task
  *analyze() {
    // first create sync result items from all the DC contacts
    this.dcLPList.forEach((lpItem) => {
      let s = this.createSyncItem();
      s.fromDCList(lpItem);
      this.syncItems.pushObject(s);
    });

    let partySyncItems = [];

    this.parties.forEach((party) => {
      let s = this.findSyncItemForParty(party);

      if (s) {
        partySyncItems.pushObject(s);
      }
    });

    this.syncItems.pushObjects(partySyncItems);

    set(this, 'syncItems', this.syncItems.sortBy('dcLP.investor.name'));
  }

  @task
  *doSyncTask() {
    let allTasks = [];
    let syncItem = null;

    for (var a = 0; a < this.syncItems.length; a++) {
      syncItem = this.syncItems[a];
      allTasks.pushObject(this.syncItemTask.perform(syncItem));
    }

    yield all(allTasks);

    if (this.context.onComplete) {
      this.context.onComplete();
    }
  }

  @task({
    maxConcurrency: 4,
    enqueue: true,
  })
  *syncItemTask(syncItem) {
    let isDeleted = syncItem.results.includes(SYNC_DELETED);

    if (isDeleted) {
      yield this.syncItem_delete.perform(syncItem);
    } else {
      for (var a = 0; a < syncItem.results.length; a++) {
        let result = syncItem.results[a];

        if (result === SYNC_NAME_CHANGED) {
          yield this.syncItem_updateContactName.perform(syncItem);
        } else if (result === SYNC_COMPANY_CHANGED) {
          yield this.syncItem_updateCompany.perform(syncItem);
        } else if (result === SYNC_EMAIL_CHANGED) {
          yield this.syncItem_updateEmail.perform(syncItem);
        }
      }
    }
  }

  @task
  *syncItem_delete(syncItem) {
    let party = syncItem.party;

    let investor = yield party.get('investor');

    if (investor && investor.smartRoomFolderId && this.smartRoomId) {
      let folderSecurities = [];

      // apply security for investor folder (view, print, save)
      folderSecurities.push({
        itemId: investor.smartRoomFolderId,
        rightId: null,
      });

      try {
        yield this.smartroom.setCategorySecurity(
          this.smartRoomId,
          party.srProfile,
          folderSecurities
        );
      } catch (e) {
        console.error('Could not modify SmartRoom rights for this contact.');
        console.log(e);
      }
    }

    yield party.destroyRecord();
  }

  @task
  *syncItem_updateContactName(syncItem) {
    let person = yield get(syncItem, 'party.person');
    let party = syncItem.party;

    if (person) {
      let fullName = get(syncItem, 'dcContact.fullName');
      set(person, 'fullName', fullName);
      person.parseFromFullName(fullName);

      yield person.save();

      if (party) {
        set(party, 'name', fullName);
        yield party.save();
      }
    }
  }

  @task
  *syncItem_updateEmail(syncItem) {
    let party = syncItem.party;
    let email = yield get(party, 'email');
    let dcEmail = get(syncItem, 'dcContact.email');

    if (email) {
      set(email, 'emailAddress', dcEmail);
      yield email.save();
    } else {
      let query = Expressions.create();
      query.add(
        Filter.create({
          name: 'emailAddress',
          operator: FilterOperators.EQUAL,
          value: dcEmail,
        })
      );

      let newEmail = (yield this.store.query('email', {
        condition: query.serialize(),
        page: {
          size: 1,
          number: 1,
        },
      })).firstObject;

      if (!newEmail) {
        newEmail = this.store.createRecord('email', {
          emailAddress: get(syncItem, 'dcContact.email'),
          personId: get(party, 'person.id'),
        });

        yield newEmail.save();
      }

      set(party, 'email', newEmail);
      yield party.save();
    }
  }

  @task
  *syncItem_updateCompany(syncItem) {
    // update SmartExchange investor number
    let party = syncItem.party;

    let person = yield get(party, 'person');
    let company = yield get(party, 'company');

    let dcCompany = yield get(syncItem, 'dcContact.company');

    let personInCompanies = get(person, 'personInCompanies');

    // remove the old company from contact and party record
    if (company) {
      let pic = personInCompanies.findBy('companyId', company.id);
      if (pic) {
        yield pic.destroyRecord();
      }

      set(party, 'company', null);
      set(party, 'companyId', null);
    }

    if (dcCompany) {
      let query = Expressions.create();
      query.add(
        Filter.create({
          name: 'name',
          operator: FilterOperators.EQUAL,
          value: get(dcCompany, 'name'),
        })
      );

      let newCompany = (yield this.store.query('company', {
        condition: query.serialize(),
        page: {
          size: 1,
          number: 1,
        },
      })).firstObject;

      if (!newCompany) {
        newCompany = this.store.createRecord('company', {
          name: get(dcCompany, 'name'),
        });

        yield newCompany.save();
      }

      let personInCompany = this.store.createRecord('person-in-company', {
        personId: person.id,
        companyId: newCompany.id,
      });

      yield personInCompany.save();

      set(party, 'company', newCompany);
      yield party.save();
    }

    /*
    if (person) {
      let fullName = get(syncItem, 'dcContact.fullName');
      set(person, "fullName", fullName);
      person.parseFromFullName(fullName);

      yield person.save();

      if (party) {
        set(party, 'name', fullName)
        yield party.save();
      }
    }
    */
  }

  @task({
    maxConcurrency: 4,
    enqueue: true,
  })
  *updateRecord(record) {
    yield record.save();
  }

  @action
  toggleAll() {
    this.toggleProperty('allSelected');

    this.syncItems.forEach((item) => {
      set(item, 'doSync', this.allSelected);
    });
  }

  @action
  doSync() {
    this.doSyncTask.perform();
  }
}
