import { helper } from '@ember/component/helper';
import { SmartroomMyInfoModel, SmartroomRightTypeModel } from 'smex-ui-sr-models';

export function createFolderAllowed(params: [], hash: { myInfo: SmartroomMyInfoModel, right?: SmartroomRightTypeModel }) {
	if (!hash.myInfo || !hash.right) {
		return false;
	}

	return hash.myInfo.addFolder && hash.right.save;
}

export default helper(createFolderAllowed);
