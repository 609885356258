import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ILayoutProperty } from '../../layout-viewer/component';
import { action, set } from '@ember/object';

interface LayoutEditorPropertyArgs {
	property?: ILayoutProperty;
	onPropertyClick: (property: ILayoutProperty) => void;
	onRemoveClick: (property: ILayoutProperty) => void;
	onSave: (property: ILayoutProperty) => void;
}

export default class LayoutEditorProperty extends Component<LayoutEditorPropertyArgs> {
	@tracked
	showEditor = false;
	@tracked
	propName = '';
	@tracked
	code = '';


	constructor(owner: unknown, args: LayoutEditorPropertyArgs) {
		super(owner, args);
		if (this.args.property) {
			this.propName = this.args.property.propName;
			this.code = this.args.property.code;
		}
	}

	@action
	submit() {
		if (this.args.onSave) {
			this.args.onSave({
				propName: this.propName,
				code: this.code
			});
			this.propName = '';
			this.code = '';
		} else {
			if (this.args.property) {
				set(this.args.property, 'propName', this.propName);
				set(this.args.property, 'code', this.code);
			}
		}
		this.showEditor = false;
	}

	@action
	cancel() {
		if (this.args.property) {
			this.propName = this.args.property.propName;
			this.code = this.args.property.code;
		} else {
			this.propName = '';
			this.code = '';
		}

		this.showEditor = false;
	}
}
