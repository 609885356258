import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action, set } from '@ember/object';
import { get } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { SmartRoomContentViewModes } from 'cing-app/utils/lookups';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';
import { cached } from 'tracked-toolbox';

import {
  ConnectionTypes,
  ConnectionTypesMap,
  ABConnectionTypes,
} from 'cing-app/utils/lookups';
import AppbuilderLink from 'cing-app/models/appbuilder-link';

interface SmartroomArgs {
  model: AppbuilderLink;
  onSave: () => void;
}

const ConnectionTypeOptions: any = [];

for (const [key, value] of Object.entries(ConnectionTypesMap)) {
  ConnectionTypeOptions.pushObject({
    name: key,
    id: value,
  });
}

export default class SmartRoom extends Component<SmartroomArgs> {
  ConnectionTypes = ConnectionTypes;
  ConnectionTypeOptions = ConnectionTypeOptions;

  SmartRoomContentViewModes = Object.entries(SmartRoomContentViewModes).map(
    ([k, v]) => ({ key: k, value: v })
  );

  @service declare store: Store;
  @service declare session;
  @service declare serverVariables;
  @service declare userInstances;

  @tracked
  showAllSmartRoomFolders = false;

  @tracked
  sites: any;

  @tracked
  createNewSmartRoom: boolean = false;

  @tracked
  selectedSmartRoom: any;

  @tracked
  selectSmartRoomFolder: boolean = false;

  @tracked
  selectedSmartRoomFolder: any;

  @tracked
  allAccessSmartRoomFolders?: any[];

  @tracked
  allAccessSmartRoomFoldersError?: any;

  @tracked
  selectAllAccessSmartRoomFolder: boolean = false;

  @tracked
  model!: AppbuilderLink;

  constructor(owner: any, args: SmartroomArgs) {
    super(owner, args);

    this.initTask.perform();
  }

  @cached
  get selectedSmartRoomFolderDisplayName() {
    if (this.selectedSmartRoomFolder) {
      if (get(this.selectedSmartRoomFolder, 'displayString')) {
        return `${get(this.selectedSmartRoomFolder, 'displayString')} - ${get(
          this.selectedSmartRoomFolder,
          'name'
        )}`;
      } else {
        return get(this.selectedSmartRoomFolder, 'name');
      }
    } else {
      return 'SmartRoom';
    }
  }

  @task
  initTask = taskFor(async () => {
    await this.session.authenticate('authenticator:torii', 'si', {
      silent: true,
    });

    let sites = await this.store.findAll('smartroom/site', { reload: true });

    this.sites = sites;

    this.model = await this.args.model;

    if (this.model.SRSiteId) {
      this.selectedSmartRoom = this.sites.findBy(
        'id',
        this.model.SRSiteId.toString()
      );

      if (this.selectedSmartRoom && this.model.SRFolderId) {
        this.selectedSmartRoomFolder = this.store.queryRecord(
          'smartroom/folder',
          {
            siteId: this.selectedSmartRoom.id,
            id: this.model.SRFolderId,
          }
        );

        try {
          this.allAccessSmartRoomFolders = await this.model
            .allAccessSmartRoomFolders;
        } catch {
          this.allAccessSmartRoomFoldersError = true;
        }
      }
    }

    if (!this.model.SRViewMode) {
      this.model.SRViewMode = 'files';
    }
  });

  @action
  searchSites(term) {
    term = term.toLowerCase();

    return this.sites.filter((item) => {
      if (
        item.siteName.toLowerCase().indexOf(term) > -1 ||
        item.slug.toLowerCase().indexOf(term) > -1
      ) {
        return true;
      }
    });
  }

  @action
  async onRoomChanged(room) {
    if (room === this.selectedSmartRoom) {
      return;
    }

    this.selectedSmartRoom = room;
    this.model.SRSiteId = room?.id;
    this.model.SRName = room?.siteName;
    this.model.SRSlug = room?.slug;

    this.model.SRFolderId = null;
    this.model.SRFolderName = null;
    this.selectedSmartRoomFolder = null;

    this.model.SRAllAccessFolders = [];

    this.allAccessSmartRoomFolders = await this.model.allAccessSmartRoomFolders;
  }

  @action
  onSelectFolder(folder) {
    this.selectSmartRoomFolder = false;
    this.selectedSmartRoomFolder = folder;
    this.model.SRFolderId = folder?.id;
    this.model.SRFolderName = folder?.name;
  }

  @action
  async onSelectAllAccessFolder(folder) {
    //this.set('selectSmartRoomFolder', null);
    let AllAccessSmartRoomFolderIds = this.model.SRAllAccessFolders;

    if (!AllAccessSmartRoomFolderIds) {
      AllAccessSmartRoomFolderIds = [];
    }

    AllAccessSmartRoomFolderIds.pushObject(folder.id);

    AllAccessSmartRoomFolderIds = AllAccessSmartRoomFolderIds.uniq();

    set(this.model, 'SRAllAccessFolders', AllAccessSmartRoomFolderIds);

    this.selectAllAccessSmartRoomFolder = false;

    this.allAccessSmartRoomFolders = await this.model.allAccessSmartRoomFolders;
  }

  @action
  async resetAllAccessFolders() {
    set(this.model, 'SRAllAccessFolders', []);

    this.selectAllAccessSmartRoomFolder = false;

    try {
      this.allAccessSmartRoomFolders = await this.model
        .allAccessSmartRoomFolders;

      this.allAccessSmartRoomFoldersError = false;
    } catch {
      this.allAccessSmartRoomFoldersError = true;
    }
  }

  @action
  selectViewMode(viewMode: object) {
    set(this.model, 'SRViewMode', viewMode.key);
  }

  @action
  removeAllAccessFolder(folder) {
    this.model.SRAllAccessFolders.removeObject(folder.id);
  }
}
