import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { get } from '@ember/object';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import Company from 'cing-app/models/company';
import Address from 'cing-app/models/address';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import CountrySubdivision from 'cing-app/models/country-subdivision';

interface ManageUsersArgs {
  context: {
    company?: Company;
    address?: Address;
    closeOnCreate: Function;
    onClose: Function;
    onCreate: Function;
  };
  personInCompany: any;
  companies: any;
  address: any;
  onClose: any;
  onUpdate: any;
}

export default class ManageAddress extends Component<ManageUsersArgs> {
  @service
  store!: Store;

  @service('docker-item')
  docker!: DockerItemService;

  @tracked
  canSave: boolean = true;

  @tracked
  address: Address;

  @tracked
  tagName: string = '';

  @tracked
  states?: CountrySubdivision[];

  @tracked
  showValidations = false;

  constructor(owner: any, args: ManageUsersArgs) {
    super(owner, args);

    if (args.context.address) {
      this.address = args.context.address;
    } else {
      this.address = this.store.createRecord('address', {
        companyId: this.args.context.company?.id,
      });
    }

    this.updateStates.perform();
  }

  get countries() {
    return this.store.peekAll('country').sortBy('name');
  }

  @task
  updateStates = taskFor(async () => {
    let country = await this.address.country;

    if (country) {
      this.states = (await country.countrySubdivisions)
        .sortBy('code')
        .toArray();

      let currentState = await this.address.state;

      if (currentState && !this.states?.includes(currentState)) {
        this.address.state = null;
      }
    }
  });

  @task
  saveTask = taskFor(async () => {
    await this.address.save();

    if (this.args.context.onCreate) {
      this.args.context.onCreate(this.address);
    }

    if (this.args.context.closeOnCreate && this.args.onClose) {
      this.args.onClose();
    }
  });

  @action
  save() {
    if (this.address.street) {
      this.address.street = this.address.get('street').trim();
    }
    this.showValidations = true;

    if (!this.address.get('validations.isValid')) {
      return;
    }

    this.saveTask.perform();
  }

  @action
  cancel() {
    if (!this.address.isNew) {
      this.address.rollbackAttributes();
      this.address.reload({ adapterOptions: { include: 'country,state' } });
    }

    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}
