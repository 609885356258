import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import ENV from '../../../../config/environment';

interface AlertTemplateArgs {}

export default class AlertTemplates extends Component<AlertTemplateArgs> {
  @service store!: Store;
  //@ts-ignore
  @service('docker-item') docker;

  @tracked
  columns: any[];

  @tracked
  confirmRemoval: any;

  @tracked
  selectedTemplate: any;

  @tracked
  reloadData: boolean = false;

  constructor(owner: unknown, args: AlertTemplateArgs) {
    super(owner, args);
    this.columns = this.getColumns();
  }

  getColumns() {
    let columns = [
      {
        propertyName: 'name',
        title: 'Template Name',
      },
      {
        propertyName: 'subject',
        title: 'Subject',
      },
      {
        propertyName: 'fromAddress',
        title: 'From Address',
      },
      {
        propertyName: 'modifyTime',
        title: 'Update Date',
        component: 'api-table/columns/date',
        //@ts-ignore
        dateFormat: ENV.APP.shortDateTimeFormat,
        sortPrecedence: 0,
        sortDirection: 'desc',
      },
      {
        title: '',
        component: 'portal-settings/alert-templates/column-actions',
        className: 'column-actions',
        editTemplate: this.editTemplate,
        deleteTemplate: this.deleteTemplate,
      },
    ];
    return columns;
  }

  @action
  addTemplate() {
    let template = this.store.createRecord('alert-template');
    const appearance = {
      title: 'Add Notification Template',
      size: 'large',
      id: `alert-template/edit-template${template.id}`,
      custom: true,
    };
    const context = {
      template: template,
      refreshParentGrid: () => {
        if (!this.isDestroyed) {
          this.reloadData = true;
        }
      },
    };
    this.docker.invokePopup(
      'portal-settings/alert-templates/edit-template',
      appearance,
      context
    );
  }

  @action
  editTemplate(record: any) {
    const appearance = {
      title: 'Update Notification Template',
      size: 'large',
      id: `alert-templates/edit-template${record.id}`,
      custom: true,
    };
    const context = {
      template: record,
      refreshParentGrid: () => {
        if (!this.isDestroyed) {
          this.reloadData = true;
        }
      },
    };
    this.docker.invokePopup(
      'portal-settings/alert-templates/edit-template',
      appearance,
      context
    );
  }

  @action
  deleteTemplate(template: any) {
    this.confirmRemoval = template;
  }

  @task
  doDeleteTemplate = taskFor(async () => {
    await this.confirmRemoval.deleteRecord();
    await this.confirmRemoval.save();
    this.confirmRemoval = null;
  });
}
