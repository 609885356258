import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  email: attr('string'),
  userId: attr('number', { ro: true }),
  password: attr('string', { ie: true }),
  passwordOld: attr('string', { ie: true }),
  firstName: attr('string'),
  deleted: attr('boolean'),
  lastName: attr('string'),
  companyId: attr('number', { defaultValue: 0, ro: true }),
  companyName: attr('string'),
  roomCompany: attr('string'),
  selected: false,
  categoryId: attr('number', { ro: true }),
  categoryName: attr('string', { ro: true }),
  categoryStatus: attr('number'),
  dateCreated: attr('rdate', { ro: true }),
  dateInvite: attr('rdate', { ro: true }),
  lastLogin: attr('rdate', { ro: true }),
  lastInvite: attr('rdate', { ro: true }),
  siteOwner: attr('boolean', { ro: true }),
  qaGroupId: attr('number'),
  qaGroupName: attr('string'),
  qaIsManager: attr('boolean'),
  qaIsSeller: attr('boolean'),
  invitedBy: attr('string'),
  invitedByUserId: attr('string'),
  approvedBy: attr('string'),
  approvedByUserId: attr('string'),
  allowedToInvite: attr('boolean'),
  inviteStatus: attr('number'),
  status: attr('number'),

  defaultMFAProvider: attr('string', { ro: true }),
  selectedToolbarStyle: null,
  language: null,

  name: computed('firstName', 'lastName', function () {
    if (!this.firstName && !this.lastName) {
      return null;
    } else {
      return this.firstName + " " + this.lastName;
    }
  }),

  nameOrEmail: computed('firstName', 'lastName', 'email', function () {
    if (!this.firstName && !this.lastName) {
      return this.email;
    } else {
      return this.firstName + " " + this.lastName;
    }
  }),
});
