import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import Case from './case';
import Project from './project';

export default class WatchList extends Abstract {
  @attr('string') declare userId: string;
  @attr('string') declare caseId?: string;
  @attr('string') declare projectId?: string;
  @attr('date') declare lastAccess?: Date;

  @attr('date') declare createTime?: Date;
  @attr('date') declare modifyTime?: Date;

  //user: DS.belongsTo('user'),
  @belongsTo('case') declare case: AsyncBelongsTo<Case>;
  @belongsTo('project') declare project: AsyncBelongsTo<Project>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'watch-list': WatchList;
  }
}
