import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment';

@classic
export default class Event extends Component {
  @action
  updateTime(dateProperty, newTime) {
    newTime = moment(newTime);
    let currentDate = moment(this.get(`model.${dateProperty}`));

    currentDate.set({
      hour: newTime.hour(),
      minute: newTime.minute(),
      second: newTime.second()
    })

    this.set(`model.${dateProperty}`, currentDate.toDate());
  }
}