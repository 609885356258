import Component from '@ember/component';
import { computed, action, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { EReportStatus, ProjectTabMap } from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
} from 'cing-app/pods/smartroom/service';
import {
  Filter,
  Expressions,
  FilterOperators,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';
import TableTheme from '../abstract-import/table-theme';
import contact from 'cing-app/adapters/ab/investors/contact';
import { isEmpty } from '@ember/utils';

const PARTY_MODES = {
  SIMPLE: 'simple',
  BATCH: 'batch',
};

const SEARCH_TYPES = {
  FUND: 'FUND',
  DEAL: 'DEAL',
  ALL: 'ALL',
};

import classic from 'ember-classic-decorator';

@classic
export default class ImportDcLpComm extends Component {
  @service store;
  @service config;
  @service session;
  @service('docker-item') docker;
  @service seActions;
  @service userInstances;

  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  selectedCompanies = null;
  currentStep = 1;
  interestedParty = null;
  pageSize = 25;
  importAction = null;
  importContacts = false;

  importContacts = false;

  @computed
  get userGroups() {
    return this.store.query('user-group', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
  }

  @alias('context.onComplete') onComplete;

  init() {
    super.init(...arguments);
    this.set('selectedCompanies', []);
    this.set('customFilters', {});
    this.initTask.perform();
  }

  @task
  *initTask() {
    this.searchTask.perform();
  }

  @task({
    restartable: true,
  })
  *searchTask() {
    //let filter = {};
    let query = Expressions.create();

    for (let filterName in this.customFilters) {
      let filterValue = this.customFilters[filterName];

      if (filterValue !== null) {
        query.add(
          Filter.create({
            name: filterName,
            operator: FilterOperators.EQUAL,
            value: this.customFilters[filterName],
          })
        );
      }
    }

    /*
      if (this.searchName) {
        query.add(Filter.create({
          name: 'FullName',
          operator: FilterOperators.LIKE,
          value: this.searchName
        }));
        //filter['name'] = 'like:' + this.searchName;
      }

      if (this.searchEmail) {
        query.add(Filter.create({
          name: 'Email',
          operator: FilterOperators.LIKE,
          value: this.searchEmail
        }));

        //filter['email'] = 'like:' + this.searchEmail;
      }
    */

    this.set('condition', query.serialize());
  }

  @computed
  get columns() {
    let self = this;

    let columns = [
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        sortDisabled: true,
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
      {
        title: 'Company Name',
        propertyName: 'name',
        component: 'api-table/columns/html',
        sortDisabled: true,
      },

      {
        title: 'Company Type',
        propertyName: 'description',
        component: 'api-table/columns/html',
        sortDisabled: true,
      },
    ];

    return columns;
  }

  @task
  *processTask() {
    let contacts = [];
    let importRows = [];

    if (this.importContacts) {
      contacts = yield this.getContacts.perform();

      // process regular contacts items
      for (var a = 0; a < contacts.length; a++) {
        let item = contacts[a];
        var email = item.get('email');

        let rowData = {
          ContactName: item.get('fullName'),
          DealCloudContactId: item.get('id'),
          DealCloudCompanyId: item.get('company.id'),
          UserGroup: email
            ? this.selectedUserGroup
              ? this.selectedUserGroup.name
              : null
            : null,
          CreateUser: email ? true : false,
          Company: item.get('company.name'),
          ContactEmail: email,
        };

        importRows.pushObject(rowData);
      }
    } else {
      // process regular contacts items
      for (var a = 0; a < this.selectedCompanies.length; a++) {
        let item = this.selectedCompanies[a];

        let rowData = {
          DealCloudCompanyId: item.get('id'),
          Company: item.get('name'),
        };

        importRows.pushObject(rowData);
      }
    }

    if (importRows.length) {
      let importAction = yield this.seActions.importPartiesStepOne(
        new Blob([JSON.stringify(importRows)], { type: 'application/json' })
      );

      this.set('addContactsAction', importAction);

      yield this.seActions.importPartiesStepTwo(importAction);
    }

    if (this.get('context.onComplete')) {
      this.get('context.onComplete')();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @task
  *getContacts() {
    // get list of all added parties
    let companyIds = this.selectedCompanies.mapBy('id').compact().uniq();

    let currentPageNumber = 1;
    let pageSize = 1000;
    let dataAvailable = true;
    let modelName = 'sync-contact';

    let allItems = [];

    while (dataAvailable) {
      let query = Expressions.create();
      let contacts = yield this.store.query(modelName, {
        include: 'company',
        page: {
          size: 1000,
          number: currentPageNumber,
        },
      });

      if (contacts.length) {
        allItems = allItems.concat(contacts.toArray());
        currentPageNumber++;
      } else {
        dataAvailable = false;
      }
    }

    let contactsToImport = [];

    companyIds.forEach((companyId) => {
      contactsToImport.pushObjects(allItems.filterBy('company.id', companyId));
    });

    return contactsToImport;
  }

  @action
  search(e) {
    if (e) {
      e.preventDefault();
    }

    this.onSearchTypeChanged();

    this.searchTask.perform();
  }

  @action
  process() {
    this.processTask.perform();
  }

  @action
  onSearchTypeChanged() {
    set(this, 'searchItems', []);
    set(this, 'selectedItems', []);
    set(this, 'selectedContacts', []);
  }

  @action
  onFilterUpdate(filterProperty, value) {
    set(this, `customFilters.${filterProperty}`, value);
    this.searchTask.perform();
  }
}
