import EmberObject from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

import {
  ProjectTabMap
} from "cing-app/utils/lookups";
import classic from 'ember-classic-decorator';

@classic
export default class ProjectIndexController extends Controller {
  queryParams = ['tab'];
  ProjectTabMap = ProjectTabMap;
  init() {
    super.init(...arguments);
  }
}