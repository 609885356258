import SmartRoomAdapter from './smartroom';

export default SmartRoomAdapter.extend({
  modelPath: 'users',
  urlForQuery: function (query, modelName) {
    if (query.allUsers) {
      delete query.allUsers;
      return this._super(query, modelName);
    } else {
      return this._super(query, modelName) + '/search';
    }
  },
});
