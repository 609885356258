import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import FilterBuilder from 'cing-app/mixins/filter-builder';

@classic
@tagName('a')
export default class Event extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  click() {
    let record = this.get('record');
    let appearance = {
      icon: '',
      title: `Case: ${record.get('case.name')} / #${record.get('case.number')}`,
      size: 'large'
    };
    this.get('docker').invokePopup('event-overview', appearance, record);
  }
}

