import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { UserStatus, NotificationTypes } from 'cing-app/pods/smartroom/service';
import classic from 'ember-classic-decorator';

@classic
export default class InviteParties extends Component {
  @alias('context.parties') parties;
  @alias('context.project') project;
  @alias('context.onComplete') onComplete;
  @alias('context.smartRoomId') smartRoomId;

  @service store;
  @service smartroom;
  @service config;

  selectedInviteTemplate = null;
  inviteSubject = 'Welcome to Investor Portal';

  init() {
    super.init(...arguments);
  }

  @computed('smartRoomId')
  get inviteTemplates() {
    return this.store.query('smartroom/userinvitetemplate', {
      siteId: this.smartRoomId,
    });
  }

  @task
  *doInviteUsersTask() {
    let emails = this.parties.mapBy('email.emailAddress');

    if (this.smartRoomId) {
      yield this.smartroom.updateUsersStatus(
        this.smartRoomId,
        emails,
        UserStatus.ACTIVE,
        NotificationTypes.SEND_INVITE_EMAIL,
        this.selectedInviteTemplate,
        this.inviteSubject
      );
    }

    if (this.context && this.context.onComplete) {
      this.context.onComplete();
    }
  }

  @task
  *loadTemplateTask(inviteTemplate) {
    // reload the template to get 'body'
    inviteTemplate = yield this.store.queryRecord(
      'smartroom/userinvitetemplate',
      {
        id: inviteTemplate.id,
        siteId: this.smartRoomId,
      }
    );

    this.set('selectedInviteTemplate', inviteTemplate);
    this.set('inviteSubject', inviteTemplate.subject);

    /*
    let previewHtml = yield this.smartroom.inviteTemplatePreview(this.smartRoomId, this.selectedInviteTemplate);      
    this.set('templatePreview', previewHtml);          
    */
  }

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  *savePartyTask(party) {
    yield party.save();
  }

  @task
  *generateTemplatePreviewTask() {
    let previewHtml = yield this.smartroom.inviteTemplatePreview(
      this.smartRoomId,
      this.selectedInviteTemplate
    );
    this.set('templatePreview', previewHtml);
  }

  @action
  doInviteUser(record) {
    this.doInviteUsersTask.perform(record);
  }

  @action
  previewTemplate() {
    this.set('showInvitePreview', true);
    this.generateTemplatePreviewTask.perform();
  }

  @action
  selectTemplate(template) {
    this.set('selectedInviteTemplate', template);
    this.loadTemplateTask.perform(template);
  }
}
