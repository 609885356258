import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import _SessionService from 'cing-app/pods/session/service';
import AbPmService from 'cing-app/pods/ab-pm/service';

import { get } from '@ember/object';
import DockerItemService from 'cing-app/pods/docker-item/service';
import { DataSourceColumn, IDataSource, StaticDataSource } from 'smex-ui-table';

interface AbPmTaskDocumentsArgs {
  abLink: any;
  task: any;
  smartRoomId: any;
  onTaskAction: any;
}

interface TaskDocument {
  id: number;
  category: string;
  createdBy: string;
  createdDate: string;
  description: string;
  email: string;
  fileName: string;
  resourceId: number;
  taskId: Number;
  type: string;
  url: string;
}

export default class AbPmTaskDocuments extends Component<AbPmTaskDocumentsArgs> {
  @service('ab-pm') pmService!: AbPmService;
  @service('docker-item') docker!: DockerItemService;

  @tracked isRemoving: boolean = false;
  @tracked isRemovingMultipleItems: boolean = false;
  @tracked isDeleting: boolean = false;
  @tracked isDeletingMultipleItems: boolean = false;
  @tracked selectedDocument: any;
  @tracked isCollapsed = false;

  @tracked dataSource!: IDataSource<TaskDocument>;
  @tracked allItems: TaskDocument[] = [];
  @tracked selectedItems: any;

  addSmartRoomDocPopup: any;
  addSmartExchangeDocPopup: any;

  pageSize: number = 999999;

  columns: DataSourceColumn<TaskDocument>[] = [
    new DataSourceColumn<TaskDocument>({
      id: 'id',
      label: 'ID',
      hidden: true,
      getValue: (row) => get(row, 'id'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
      options: {
        dasherizedProperty: 'id',
      },
    }),
    new DataSourceColumn<TaskDocument>({
      id: 'folder-name',
      label: '',
      getValue: (row) => '',
      getIcon: (row) => {
        if (row.folderId) {
          return 'info-circle';
        }
        return '';
      },
      getTooltip: (row: any) => get(row, 'folderName'),
      sortingEnabled: true,
      minWidth: 20,
      maxWidth: 20,
      valueComponent: 'table-icon-column',
      options: {
        linkTarget: '_blank',
        className: 'text-info',
        onClick: (row: any) => {
          return this.filenameClicked(row);
        },
      },
    }),
    new DataSourceColumn<TaskDocument>({
      id: 'file-name',
      label: 'Filename',
      getValue: (row) => get(row, 'fileName'),
      sortingEnabled: true,
      minWidth: 180,
      valueComponent: 'table-filename-column',
      options: {
        linkTarget: '_blank',
        generateLink: (row: any) => {
          return row.url;
        },
        onClick: (row: any) => {
          return this.filenameClicked(row);
        },
      },
    }),
    new DataSourceColumn<TaskDocument>({
      id: 'created-by',
      label: 'Uploaded By',
      getValue: (row) => get(row, 'createdBy'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
    }),
    new DataSourceColumn<TaskDocument>({
      id: 'created-date',
      label: 'Uploaded Date',
      getValue: (row) => get(row, 'createdDate'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
      options: {
        format: {
          style: 'date',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },
      },
    }),
  ];

  constructor(owner: any, args: any) {
    super(owner, args);
  }

  @action init() {
    console.log('init task from task-documents');
    this.initDataSourceTask.perform();
  }

  @task initDataSourceTask = taskFor(async () => {
    let taskId = this.args.task.id || this.args.task.taskId;
    if (this.args.task.documents) {
      this.allItems = this.args.task.documents;
    } else {
      if (taskId) {
        this.allItems = await this.loadTaskDocuments(taskId);
      }
    }

    this.dataSource = new StaticDataSource<TaskDocument, TaskDocument>(
      25,
      false,
      'task-documents',
      this.pmService.currentUserEmail,
      this.allItems,
      this.loadDataTask,
      this.pageSize,
      this.columns
    );
  });

  @task loadDataTask = taskFor(
    async (data: TaskDocument[], columns: DataSourceColumn<TaskDocument>[]) => {
      return data;
    }
  );

  async loadTaskDocuments(taskId: string) {
    let taskDocs = await this.pmService.getTaskDocuments(
      taskId,
      this.pageSize,
      0,
      '-id',
      this.args.abLink
    );

    return taskDocs.toArray();
  }

  @action filenameClicked(row: any) {
    console.log('file ', row);
    if (row) {
      if (/smartroom/i.test(row.type)) {
        let appearance = {
          icon: 'file',
          title: row.fileName,
          size: 'large',
          custom: true,
        };

        const metadata = JSON.parse(row.metadata);
        const siteId = metadata.smartRoomId || this.args.smartRoomId;
        const file = {
          id: row.fileId || metadata.fileId,
          folderId: row.folderId,
          version: metadata.versionNo || 1,
          get: null,
        };

        if (!file.folderId) {
          file.folderId = this.pmService.getSmartRoomFolderIdFromPathIds(
            metadata.pathIds
          );
        }

        file.get = (key: string) => {
          return file[key];
        };

        this.docker.invokePopup('smartroom/file-viewer', appearance, {
          file: file,
          siteId: siteId,
        });
      } else {
        if (row.url) {
          window.open(row.url, '_blank');
        }
      }
    }
  }

  @action getActions() {
    let actions = [
      {
        label: 'View',
        icon: 'eye',
        action: this.filenameClicked,
      },
      {
        label: 'Download',
        icon: 'download',
        action: (row: any) => {
          this.downloadFileTask.perform(row);
        },
      },
      {
        label: 'Remove',
        icon: 'times',
        class: 'text-danger',
        action: (row: any) => {
          this.isRemoving = true;
          this.isRemovingMultipleItems = false;
          this.selectedDocument = row;
        },
      },
      {
        label: 'Delete',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (row: any) => {
          this.isDeleting = true;
          this.selectedDocument = row;
        },
      },
    ];
    return actions;
  }

  @task downloadFileTask = taskFor(async (file: any) => {
    if (file.type == 'smartroom' && file.fileId) {
      await this.pmService.downloadSmartRoomFile(this.args.smartRoomId, file);
    } else {
      this.pmService.downloadFile(file.url, file.fileName);
    }
  });

  @action toggleDocuments() {
    this.isCollapsed = !this.isCollapsed;
  }

  @action downloadSelected() {
    this.dataSource.selectedItems?.forEach((item: any) => {
      this.downloadFileTask.perform(item);
    });
  }

  @action removeSelected() {
    this.isRemoving = true;
    this.isRemovingMultipleItems = true;
  }

  @task removeDocumentTask = taskFor(async () => {
    let itemsToRemove: any = [this.selectedDocument];
    if (this.isRemovingMultipleItems) {
      itemsToRemove = this.dataSource.selectedItems;
    }

    for (var i = 0; i < itemsToRemove.length; i++) {
      let item = itemsToRemove[i];
      try {
        this.allItems.removeObject(item);
        //delete the record in pm
        if (item.destroyRecord) {
          item.destroyRecord();
        }
      } catch (ex) {
        console.log('remove failed', ex);
      }
    }

    setTimeout(() => {
      this.dataSource.refresh();
      this.isRemoving = false;
    }, 100);
  });

  @action deleteSelected() {
    this.isDeleting = true;
    this.isDeletingMultipleItems = true;
  }

  @task deleteDocumentTask = taskFor(async () => {
    let itemsToDelete: any = [this.selectedDocument];
    if (this.isDeletingMultipleItems) {
      itemsToDelete = this.dataSource.selectedItems;
    }

    for (var i = 0; i < itemsToDelete.length; i++) {
      let item = itemsToDelete[i];
      try {
        if (item) {
          if (item.type == 'smartroom' && item.fileId) {
            //delete the smartroom record for this file
            await this.pmService.deleteSmartRoomFile(
              this.args.smartRoomId,
              item.fileId
            );
          }

          this.allItems.removeObject(item);

          //delete the record in pm
          if (item.destroyRecord) {
            item.destroyRecord();
          }
        }
      } catch (ex) {
        console.log('delete failed', ex);
      }
    }

    setTimeout(() => {
      this.dataSource.selectedItems?.clear();
      this.dataSource.refresh();
      this.isDeleting = false;
    }, 100);
  });

  @action addSmartRoomDocument() {
    let appearance = {
      icon: 'upload',
      title: `ADD SMARTROOM DOCUMENT`,
      size: 'large',
      custom: true,
    };

    let ctx = {
      abLink: this.args.abLink,
      smartRoomId: this.args.smartRoomId,
      task: this.args.task,
      documentsTableSource: this.dataSource,
      onTaskAction: this.args.onTaskAction,
      onSave: () => {
        if (this.addSmartRoomDocPopup) {
          this.docker.removePopup(this.addSmartRoomDocPopup);
        }
      },
    };

    this.addSmartRoomDocPopup = this.docker.invokePopup(
      'ab-pm/task-documents/add-smartroom-document',
      appearance,
      ctx
    );
  }

  @action addSmartExchangeDocument() {
    let appearance = {
      icon: 'upload',
      title: `ADD SMARTEXCHANGE DOCUMENT`,
      size: 'large',
      custom: true,
    };

    let ctx = {
      abLink: this.args.abLink,
      smartRoomId: this.args.smartRoomId,
      task: this.args.task,
      documentsTableSource: this.dataSource,
      onTaskAction: this.args.onTaskAction,
      onSave: () => {
        if (this.addSmartExchangeDocPopup) {
          this.docker.removePopup(this.addSmartExchangeDocPopup);
        }
      },
    };

    this.addSmartExchangeDocPopup = this.docker.invokePopup(
      'ab-pm/task-documents/add-smartexchange-document',
      appearance,
      ctx
    );
  }
}
