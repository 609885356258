import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import { task } from 'ember-concurrency';
import { EventType } from "cing-app/utils/lookups";

@classic
export default class Milestones extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  confirmRemoval = null;

  @task(function* () {
    let expE = Expressions.create({ operator: ExpressionOperators.AND });
    expE.add(Filter.create({
      name: 'project-id',
      operator: FilterOperators.EQUAL,
      value: this.project.id
    }));
    expE.add(Filter.create({
      name: 'type-id',
      operator: FilterOperators.EQUAL,
      value: EventType.Milestone
    }));

    let milestones = yield this.get('store').query('event', {
      condition: expE.serialize(),
      page: {
        size: 20,
        number: 1
      },
      sort: '-dateStart',
      include: ['type', 'pacer-event'],
    });

    this.set('milestones', milestones);
  })
  loadData;

  @action
  editMilestone(milestone) {
    let project = this.project;

    let appearance = {
      icon: '',
      title: milestone
        ? `Edit milestone for ${project.get('name')}`
        : `Add milestone for ${project.get('name')}`,
      size: 'large',
      custom: true,
    };

    let self = this;

    this.get('docker').invokePopup('manage-events/generic-add', appearance, {
      project: project,
      specificEventTypeId: EventType.Milestone,
      event: milestone,
      onAdd: function () {
        if (self.onUpdate) {
          self.onUpdate();
        }
      }
    });
  }

  @action
  doRemoveRecord(record) {
    record.destroyRecord().then((response) => {
      this.set('confirmRemoval', null);
      this.get('milestones').removeObject(record);
      if (this.onUpdate) {
        this.onUpdate();
      }
    });
  }
}