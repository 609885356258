import Model, { attr } from '@ember-data/model';

export default Model.extend({
  name: attr('string'),
  displayString: attr('string'),
  folderId: attr('string'),
  versionNo: attr('number'),
  size: attr('number'),
  path: attr('string'),
  pathIds: attr('string'),
  bytes: attr('number'),
});
