import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment';
import { action } from '@ember/object';

//@ts-ignore
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';
import Store from '@ember-data/store';
import {
	Expressions,
	Filter,
	FilterOperators,
} from 'cing-app/mixins/filter-builder';
//@ts-ignore
import podNames from "ember-component-css/pod-names";
import { dasherize } from "@ember/string";
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import DataLinkModel from 'cing-app/models/data-link-model';
import DataLinkModelColumn from 'cing-app/models/data-link-model-column';
//@ts-ignore
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import DataLink from 'cing-app/models/data-link';
import DataLinkView from 'cing-app/models/data-link-view';
import DataLinkViewColumn from 'cing-app/models/data-link-view-column';
import DataLinkContextFilter from 'cing-app/models/data-link-context-filter';

interface ManageDataLinkContextFilterEditArgs {
	model: DataLinkContextFilter;
	dataLinkModel?: DataLinkModel;
	dataLink: DataLink
}

export default class DataLinkDetail extends Component<ManageDataLinkContextFilterEditArgs> {
	@service
	config: any;

	@service
	store!: Store;

	@service
	cookies: any;

	@tracked
	selectedProperty?: DataLinkModelColumn;

	@tracked
	isBelongsTo: Boolean = false;

	@tracked
	belongsToOptions: any;

	@alias('args.model') model!: DataLinkContextFilter;
	@alias('args.dataLink') dataLink!: DataLink;
	@alias('args.dataLinkModel') dataLinkModel!: DataLinkModel;

	get styleNamespace() {
		return podNames["manage-data-links/context-filter/edit"];
	}

	constructor(owner: any, args: ManageDataLinkContextFilterEditArgs) {
		super(owner, args);
		this.initTask.perform();
	}

	@task
	initTask = taskFor(async (): Promise<void> => {
		//this.loadExtendedData.perform();
		if (this.model.property) {
			await this.selectProperty(this.dataLinkModel.columns.findBy('propertyName', this.model.property));
		}
	});

	getIsBelongsTo(property: DataLinkModelColumn): Boolean {
		let isBelongsTo: Boolean = false;

		let modelClass = this.store.modelFor(this.dataLinkModel.modelName);

		let relationshipName = property.propertyName;

		try {
			relationshipName = relationshipName.split('.')[0]
		} catch (e) {
			console.log('could not get relationship from property name: ', relationshipName);
		}


		let relationship = modelClass.relationshipsByName.get(relationshipName);

		if (relationship && relationship.kind === "belongsTo") {
			isBelongsTo = true;
		}

		return isBelongsTo;
	}

	@task
	updateBelongsToOptions = taskFor(async (property: DataLinkModelColumn): Promise<void> => {
		let modelClass = this.store.modelFor(this.dataLinkModel.modelName);

		let relationshipName = property.propertyName;

		try {
			relationshipName = relationshipName.split('.')[0]
		} catch (e) {
			console.log('could not get relationship from property name: ', relationshipName);
		}

		let relationship = modelClass.relationshipsByName.get(relationshipName);

		let options = await this.store.query(relationship.type, {
			page: {
				size: 1000,
				number: 1
			}
		});

		this.belongsToOptions = options;
	})

	@action
	selectProperty(property: DataLinkModelColumn) {
		this.selectedProperty = property;

		this.isBelongsTo = this.getIsBelongsTo(property);

		this.model.property = dasherize(this.selectedProperty.propertyName);

		if (!this.isBelongsTo) {
			this.model.operator = this.model.property.startsWith('extended-data') ? FilterOperators.JSON_EQUAL : FilterOperators.EQUAL;
		}
		return this.updateBelongsToOptions.perform(property);
	}

	@action
	selectBelongsToOption(option: any) {
		if (this.selectedProperty) {
			let modelClass = this.store.modelFor(this.dataLinkModel.modelName);

			/*
			let relationshipName = this.selectedProperty.propertyName;

			try {
				relationshipName = relationshipName.split('.')[0]
			} catch (e) {
				console.log('could not get relationship from property name: ', relationshipName);
			}
			*/

			//this.model.property = this.selectedProperty.propertyName;
			this.model.value = option.id;

			this.model.operator = this.model.property.startsWith('extended-data') ? FilterOperators.JSON_EQUAL : FilterOperators.EQUAL;
		}
	}
}
