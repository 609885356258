import Component from '@glimmer/component';
import { Owner } from "ember-one-way-controls/.node_modules.ember-try/@glimmer/di";
import Store from "@ember-data/store";
import { inject as service } from '@ember/service';
import { action } from "@ember/object";
import SendingDomain from "cing-app/models/sending-domain";
import DockerItemService from "cing-app/pods/docker-item/service";
import { tracked } from "@glimmer/tracking";

interface SendingDomainsArgs {}

export default class SendingDomains extends Component<SendingDomainsArgs> {
  @service store!: Store;
  @service('docker-item') docker!: DockerItemService;
  includes: string[] = [];
  @tracked reloadData: boolean = false;

  constructor(owner: Owner, args: SendingDomainsArgs) {
    super(owner, args);
  }

  get columns(): any {
    return [
      {
        propertyName: 'sendingDomain',
        title: 'Domain',
        className: 'column-sending-domain',
        sortedBy: 'sendingDomain',
      },
      {
        propertyName: 'provider',
        title: 'Provider',
        className: 'column-provider',
        sortedBy: 'provider',
      },
      {
        propertyName: 'status',
        title: 'Status',
        className: 'column-status',
        sortedBy: 'status',
        component: 'sending-domains/status',
      },
      {
        propertyName: 'verifiedTime',
        title: 'Date Verified',
        sortedBy: 'verifiedTime',
        component: 'api-table/columns/date',
        className: 'column-date',
        dateFormat: 'MM/DD/YYYY',
      },
      {
        propertyName: 'enabled',
        title: 'Enabled',
        className: 'column-enabled',
        sortedBy: 'enabled',
        component: 'api-table/columns/check',
      },
      {
        propertyName: 'createTime',
        title: 'Date Created',
        sortedBy: 'createTime',
        component: 'api-table/columns/date',
        className: 'column-date',
        dateFormat: 'MM/DD/YYYY',
      },
      {
        propertyName: 'actions',
        title: '',
        isHidden: false,
        className: 'column-actions',
        component: 'sending-domains/column-actions',
        reloadData: () => {
          this.reloadData = true;
        },
        viewDetail: (record) => {
          this.viewDetail(record);
        },
      },
    ];
  }

  @action
  addDomain() {
    const appearance = {
      label: 'Add Sending Domain',
      icon: '',
      title: '<small>Add Sending Domain</small>',
      custom: true,
      size: 'medium',
    };

    this.docker.invokePopup('sending-domains/add', appearance, {
      reloadData: () => {
        this.reloadData = true;
      },
    });
  }

  @action
  viewDetail(record: SendingDomain) {
    let appearance = {
      icon: '',
      title: `Sending Domain: ${record.sendingDomain}`,
      size: 'medium',
      custom: true,
    };

    this.docker.invokePopup('sending-domains/detail', appearance, {
      model: record,
      reloadData: () => {
        this.reloadData = true;
      },
    });
  }
}