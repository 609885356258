import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { buildValidations } from 'ember-cp-validations';
import Project from './project';
import Case from './case';
import { exportable } from 'cing-app/utils/exportable';
import FaqCategory from './faq-category';

const FaqValidations = buildValidations({});
export default class Faq extends Abstract.extend(FaqValidations) {
  @attr('string') declare projectId?: string;

  @exportable()
  @attr('string')
  declare title?: string;

  @exportable()
  @attr('string')
  declare answer?: string;
  @attr('number') declare order?: number;
  @attr('boolean') declare isPublic?: boolean;
  @attr('string') declare categoryId?: string;
  @attr('string') declare portalPageId?: string;
  @belongsTo('project') declare project: AsyncBelongsTo<Project>;
  @belongsTo('case') declare case: AsyncBelongsTo<Case>;

  @exportable()
  @belongsTo('faq-category')
  declare category: AsyncBelongsTo<FaqCategory>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    faq: Faq;
  }
}
