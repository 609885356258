import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { CrmImportAnalyzerStatus } from 'cing-app/utils/lookups';
//@ts-ignore
import { fragment } from 'ember-data-model-fragments/attributes';
import Action from './action';
import CrmImportRow from './crm-import-row';
import Abstract from './abstract';

export default class CrmImport extends Abstract.extend(
  computedEnumMixin(
    'analyzerStatusHuman',
    'analyzerStatus',
    CrmImportAnalyzerStatus
  )
) {
  @attr('number') declare type: number;
  @attr('string') declare grabRequestId?: string;
  @attr('string') declare actionId?: string;
  @attr('number') declare analyzerStatus?: number;
  @attr('number') declare importStatus?: number;
  @attr('number') declare importOrder?: number;
  @fragment('crm-import-row') declare rowData: CrmImportRow;
  //@ts-ignore - mixin
  @belongsTo('action') declare action: AsyncBelongsTo<Action>;
}

/*
{
  "CompanyName":"Carruthers & Roth PA",
  "CompanyId":"0ef43bc4-2657-4d3d-ac33-00498662f2fe",
  "Contact":"Britton  Lewis",
  "PersonId":"3844dffb-9993-470a-b24a-8119a707e1b8",
  "Prefix":null,
  "Suffix":null,
  "ProfessionalSuffix":null,
  "Emails":"bcl@crlaw.com",
  "Phones":null,
  "DateOfBirth":null,
  "Gender":null,
  "BioPage":null,
  "Note":null,
  "Street":"235 North Edgeworth Street\r\nPO Box 540",
  "City":"Greensboro",
  "Country":null,
  "State":null,
  "Zip":"27401",
  "PartyType":null,
  "Role":null,
  "ProjectId":null,
  "EmailsArray":null,
  "PhonessArray":null,
  "CrmImportId":null
}
*/

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'crm-mport': CrmImport;
  }
}
