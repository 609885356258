import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import AuthenticatedRouteMixin from 'ember-smartidentity-auth/mixins/auth-mixin';

export default class ViewAsRoute extends Route.extend(AuthenticatedRouteMixin) {
  @service config;
  model(params) {
    this.set('config.viewAsUser', params.viewAsUser);
    this.set('config.viewAsPortfolio', params.viewAsPortfolio);
    this.set('config.viewAsProfile', params.viewAsProfile);
  }
  afterModel() {
    this.transitionTo("dashboard");
  }
}
