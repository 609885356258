define("cing-app/services/action", ["exports", "smex-ui-sr-models/services/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _action.default;
    }
  });
});
