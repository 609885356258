//simple-drag.js
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Atest extends Component {

    @tracked statusText = "";
    @tracked overDropZone = false;

    @action dragHasStarted() {
        this.statusText = "Drag has started";
        console.log("drag started");
    }

    @action dropItem(dragEvent) {
        dragEvent.preventDefault();
        this.statusText = "Drop Has Completed";
        this.overDropZone = false;

        console.log("drop")
    }

    @action dragOver(dragEvent) {
        dragEvent.preventDefault();
        dragEvent.dataTransfer.dropEffect = "move";

        console.log("over")
    }

    @action dropLeave() {
        this.statusText = "Drag has left drop zone";
        this.overDropZone = false;
        console.log("leave")
    }

    @action dropEnter() {
        this.statusText = "Drag is entered Drop Zone";
        this.overDropZone = true;
        console.log("dropenter")
    }

 }