define("cing-app/components/smex-ui-table", ["exports", "smex-ui-table/components/smex-ui-table"], function (_exports, _smexUiTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Column", {
    enumerable: true,
    get: function () {
      return _smexUiTable.Column;
    }
  });
  Object.defineProperty(_exports, "EHeaderLayout", {
    enumerable: true,
    get: function () {
      return _smexUiTable.EHeaderLayout;
    }
  });
  Object.defineProperty(_exports, "IAction", {
    enumerable: true,
    get: function () {
      return _smexUiTable.IAction;
    }
  });
  Object.defineProperty(_exports, "ISort", {
    enumerable: true,
    get: function () {
      return _smexUiTable.ISort;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _smexUiTable.default;
    }
  });
});
