import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment';

@classic
export default class ColumnDate extends Component {
  @action
  setDate(newDate) {
    this.set(`record.${this.get('column.propertyName')}`, moment(newDate).format('MM/DD/YYYY'));
  }
}