import { attr } from '@ember-data/model';
import Abstract from './abstract';

export default class CountrySubdivision extends Abstract {
  @attr('string') declare oldId?: string;
  @attr('string') declare name?: string;
  @attr('string') declare nameUpperCase?: string;
  @attr('string') declare countrySubdivisionCode?: string;
  @attr('string') declare code?: string;
  @attr('string') declare subdivisionCategory: string;
  @attr('string') declare countryId: string;

  get displayName() {
    // some logic to show state codes for USA, CAN, MEX
    if (
      ['State', 'Province', 'Outlying territory', 'District'].includes(
        this.subdivisionCategory
      )
    ) {
      return this.code;
    } else {
      return this.name;
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'country-subdivision': CountrySubdivision;
  }
}
