import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DataSourceColumn } from 'smex-ui-table';

interface TableIconColumnArgs {
  column: DataSourceColumn<unknown>;
  row: any;
}

export default class TableIconColumn extends Component<TableIconColumnArgs> {
  get value() {
    return this.args.column.getValue(this.args.row);
  }

  get icon() {
    if (this.args.column.getIcon) {
      return this.args.column.getIcon(this.args.row);
    }
    return '';
  }

  get tooltip() {
    if (this.args.column.getTooltip) {
      return this.args.column.getTooltip(this.args.row);
    }
    return '';
  }

  get href() {
    if (this.args.column.options.generateLink) {
      return this.args.column.options.generateLink(this.args.row);
    } else {
      return '#';
    }
  }

  @action
  onClick(evt: Event) {
    if (this.args.column.options.onClick) {
      evt.stopPropagation();
      evt.preventDefault();

      this.args.column.options.onClick(this.args.row);
    }
  }
}
