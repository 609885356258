import {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import Abstract from './abstract';
import AlertAnnouncementRecipient from './alert-announcement-recipient';
import User from './user';

export default class AlertAnnouncement extends Abstract {
  @attr('string') declare from?: string;
  @attr('string') declare subject?: string;
  @attr('jsonb') declare metaData?: any;
  @attr('string') declare templateData?: string;
  @attr('string') declare createdBy?: string;
  @attr('string') declare templateId?: string;
  @attr('boolean') declare createUser?: boolean;
  @attr('string') declare loginRedirect?: string;

  @belongsTo('user') declare user: AsyncBelongsTo<User>;
  @hasMany('alert-announcement-recipients')
  declare alertAnnouncementRecipients: AsyncHasMany<AlertAnnouncementRecipient>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'alert-announcement': AlertAnnouncement;
  }
}
