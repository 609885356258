import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import { buildValidations } from 'ember-cp-validations';
import Company from './company';
import Fund from './fund';
import InvestorType from './investor-type';
import { exportable } from 'cing-app/utils/exportable';

const modelValidations = buildValidations({});

export default class Investor extends Abstract.extend(modelValidations) {
  @attr('string') declare companyId?: string;
  @attr('string') declare fundId?: string;
  @attr('string') declare investorFundId?: string;
  @exportable()
  @attr('string')
  declare investorNumber?: string;
  @exportable()
  @attr('string')
  declare smartRoomFolderId?: string;
  @exportable()
  @attr('number')
  declare commitment?: number;
  @exportable()
  @belongsTo('company', { inverse: null })
  declare company: AsyncBelongsTo<Company>;
  @belongsTo('fund', { inverse: null }) declare fund: AsyncBelongsTo<Fund>;
  @belongsTo('investor-type', { inverse: null })
  declare type: AsyncBelongsTo<InvestorType>;
}
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    investor: Investor;
  }
}
