import JSONAPISerializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class AppbuilderLinkSerializer extends JSONAPISerializer {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/serializer' {
  export default interface AdapterRegistry {
    'appbuilder-link-serializer': AppbuilderLinkSerializer;
  }
}
