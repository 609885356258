import { dasherize } from '@ember/string';
import { oneWay } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
import Table from 'ember-light-table';
import _ from 'lodash';

export default Mixin.create({
  store: service(),

  sort: null,
  page_number: 0,
  page_size: 10,
  page: computed('page_number', 'page_size', function () {
    return {
      number: this.get('page_number'),
      size: this.get('page_size')
    };
  }),

  isLoading: oneWay('fetchRecords.isRunning'),
  canLoadMore: true,
  enableSync: true,
  // model: null,
  meta: null,
  columns: null,
  table: null,

  init() {
    this._super(...arguments);

    let table = new Table(this.get('columns'), this.get('model'), {
      enableSync: this.get('enableSync')
    });
    // was initial sorting column specified?
    let sorting = this.get('sort');
    let ascending = true;
    if (sorting && _.startsWith(sorting, '-')) {
      sorting = sorting.substr(1);
      ascending = false;
    }
    let sortColumn = table.get('allColumns').findBy('valuePath', sorting);
    // Setup initial sort column
    if (sortColumn) {
      sortColumn.set('sorted', true);
      sortColumn.set('ascending', ascending);
    }
    this.set('table', table);
    this.get('fetchRecords').perform(0);
  },

  // fetchRecords: task(function*() {
  //   let records = yield this.get('store').query('case', this.getProperties(['sort', 'page']));
  //   this.get('model').pushObjects(records.toArray());
  //   this.set('meta', records.get('meta'));
  //   this.set('canLoadMore', !Ember.isEmpty(records));
  // }).restartable(),

  actions: {
    onScrolledToBottom() {
      if (this.get('canLoadMore')) {
        this.incrementProperty('page_number');
        this.get('fetchRecords').perform();
      }
    },

    onColumnClick(column) {
      if (column.sorted) {
        this.setProperties({
          canLoadMore: true,
          sort: (!column.ascending ? '-' : '') + dasherize(column.get('valuePath'))
        });
        // this.get('model').clear();
        this.get('fetchRecords').perform();
      }
    }
  }
});
