import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import moment from 'moment';
import RoleKeeper from 'cing-app/mixins/role-keeper';
import { task } from 'ember-concurrency';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

import {
  EEventType,
  EEventCalculationType,
} from 'cing-app/pods/components/ab-management/investors/event-detail/events';

const InvestorDataOptions = [
  {
    name: 'Capital Request Amount Divided Equally',
    eventType: EEventType.CapitalCall,
    calculationType: EEventCalculationType.EquallyDivided,
  },
  {
    name: 'Capital Request Amount Split By Ownership',
    eventType: EEventType.CapitalCall,
    calculationType: EEventCalculationType.ByOwnership,
  },
  {
    name: 'Capital Request as % of Committed Amount',
    eventType: EEventType.CapitalCall,
    calculationType: EEventCalculationType.PercentageOfCommitted,
  },
  {
    name: 'Distribute Same Amount',
    eventType: EEventType.Distribution,
    calculationType: EEventCalculationType.DistributionSameAmount,
  },
  {
    name: 'Distribution Amount Divided Equally',
    eventType: EEventType.Distribution,
    calculationType: EEventCalculationType.EquallyDivided,
  },
  {
    name: 'Distribution Amount Split by Ownership %',
    eventType: EEventType.Distribution,
    calculationType: EEventCalculationType.ByOwnership,
  },
];

@classic
export default class Contacts extends Component.extend(
  FilterBuilder,
  RoleKeeper
) {
  InvestorDataOptions = InvestorDataOptions;

  @alias('context.investor')
  investor;

  @alias('context.abInvestor')
  abInvestor;

  @alias('context.project')
  project;

  include = 'company,person,interested-party-role,interested-party-type,email';

  //store: service('ab-store'),
  @service('store')
  store;

  @service('ab-models')
  abModels;

  @service('docker-item')
  docker;

  confirmRemoval = null;
  selectedItems = null;

  init() {
    super.init(...arguments);
    this.set('columns', this.getColumns());

    this.generateFilter();
  }

  getColumns() {
    let columns = [
      {
        propertyName: 'person.fullName',
        title: 'Contact Name',
        className: 'column-person-name',
        sortedBy: 'person.lastName',
        sortDirection: 'asc',
        sortPrecedence: 0,
        component: 'project-detail/interested-parties/parties/column-attorney',
      },
      {
        propertyName: 'email.emailAddress',
        title: 'Email',
        //sortedBy: "email.emailAddress",
        disableSort: true,
      },
      {
        propertyName: 'interestedPartyType.name',
        title: 'Type',
        className: 'column-role',
      },
      {
        propertyName: 'interestedPartyRole.name',
        title: 'Role',
        className: 'column-role',
      },
      /*
      {
        title: "",
        component: "project-detail/interested-parties/parties/column-actions",
        className: 'column-actions',
        hideEdit: true,
        hideRemove: true,
        hideAlert: true,
        hideUpdateSecurity: true,
        hideManageAccess: true
      }
      */
    ];

    return columns;
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.project.id,
      })
    );

    expr.add(
      Filter.create({
        name: 'email-id',
        operator: FilterOperators.NOT_NULL,
      })
    );

    if (this.investor) {
      expr.add(
        Filter.create({
          name: 'investor-id',
          operator: FilterOperators.EQUAL,
          value: this.investor.id,
        })
      );
    } else {
      expr.add(
        Filter.create({
          name: 'investor.investor-fund-id',
          operator: FilterOperators.EQUAL,
          value: this.abInvestor.invFundId,
        })
      );
    }

    this.set('filter', expr.serialize());
  }

  @action
  onTableUpdated(e) {
    console.log('SELECTED ITEMS: ', e.selectedItems);
    this.set('selectedItems', e.selectedItems);
  }

  @action
  viewAs(ip) {
    window.open(
      `${this.get('config.investorPortalUrl')}view-as?viewAsPortfolio=${
        this.project.id
      }&viewAsUser=${ip.get('email.emailAddress')}&viewAsProfile=${ip.get(
        'srProfile'
      )}`
    );
  }
}
