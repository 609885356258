import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@tagName('a')
export default class PersonLookup extends Component {
  @service
  store;

  @service('docker-item')
  docker;

  click() {
    let record = this.get('record');
    this.get('column.onRowSelect')(record);
  }
}

