import Component from '@glimmer/component';
import { action, set, get } from '@ember/object';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { ConnectionTypes, ABConnectionTypes } from 'cing-app/utils/lookups';
import {
  Query,
  QueryOperators,
  FilterOperators,
  Filter,
} from 'cing-app/utils/query-builder';
import Project from 'cing-app/models/project';
import ProjectTab from 'cing-app/models/project-tab';
import ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import { cached } from 'tracked-toolbox';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency';
interface AbConnectionArgs {
  ABType: any;
  type: any;
  project: Project;
  model: ProjectTab;
  tab: any;
}

export default class ManageUsers extends Component<AbConnectionArgs> {
  @service declare store: Store;

  @tracked connections?: ArrayProxy<AppbuilderLink>;

  ABConnectionTypes = ABConnectionTypes;
  ConnectionTypes = ConnectionTypes;

  constructor(owner: any, args: AbConnectionArgs) {
    super(owner, args);
    this.loadConnections.perform();
  }

  @task
  loadConnections = taskFor(async () => {
    let query = new Query();
    query.add(new Filter('type', FilterOperators.EQUAL, this.args.type));

    if (this.args.ABType) {
      query.add(new Filter('ab-type', FilterOperators.EQUAL, this.args.ABType));
    }

    //let projectQuery = new Query(QueryOperators.OR);

    //projectQuery.add(new Filter('project-id', FilterOperators.NULL));
    query.add(
      new Filter('project-id', FilterOperators.EQUAL, this.args.project.id)
    );

    //query.add(projectQuery);

    this.connections = await this.store.query('appbuilder-link', {
      condition: query.serialize(),
    });
  });

  @action
  selectConnection(connection: AppbuilderLink) {
    if (connection) {
      set(connection, 'project', this.args.project);
    }

    set(this.args.model, 'dataConnection', connection);
  }

  @task
  _createConnection = taskFor(async () => {
    let connection = this.store.createRecord('appbuilder-link', {
      project: this.args.project,
      type: this.args.type,
      ABType: this.args.ABType,
    });

    await connection.save();

    this.selectConnection(connection);

    await this.args.model.save();

    await this.loadConnections.perform();
  });

  @task
  saveTask = taskFor(async () => {
    await (await this.args.model.dataConnection).save();
  });

  @action
  save() {
    return this.saveTask.perform();
  }

  @action
  createConnection() {
    this._createConnection.perform();
  }

  @action
  onInsert() {
    this.args.tab.onSaveSubTask = this.save;
  }

  @action
  onDestroy() {
    this.args.tab.onSaveSubTask = null;
  }
}
