import Component from '@glimmer/component';
import Project from 'cing-app/models/project';
import { DataSourceColumn } from 'smex-ui-table';

interface ColumnComponentArgs {
  row: Project;
  column: DataSourceColumn<unknown>;
}

export default class ColumnComponent extends Component<ColumnComponentArgs> {
  get barValue() {
    return this.args.column.getValue(this.args.row);
  }
}
