import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set, computed, action } from '@ember/object';
import { task } from 'ember-concurrency';
import {
  ProjectTabMap,
  CaseProjectUrlOpenModeMap,
} from 'cing-app/utils/lookups';
import classic from 'ember-classic-decorator';

@classic
export default class Companies extends Component {
  @service store;
  @service session;
  @service serverVariables;
  @service('docker-item') docker;

  createExternalUrlTab = true;

  init() {
    super.init(...arguments);
    try {
      this.reloadCompaniesTask.perform();
    } catch (e) {
      console.log('ERROR: ', e);
    }

    this.tab.on('onSave', this.onSave);
  }

  willDestroyElement() {
    this.tab.off('onSave', this.onSave);
  }

  @task
  *reloadCompaniesTask() {
    let projectCompanies = yield this.project.hasMany('companies').reload({
      adapterOptions: {
        include: 'company-profile',
      },
    });

    set(this, 'projectCompanies', projectCompanies);

    //yield this.session.authenticate('authenticator:torii', 'si', {silent: true});

    //let sites = yield this.store.findAll('smartroom/site', {reload:true});

    //this.set('sites', sites);
  }

  @task
  *removeCompanyTask(company) {
    yield this.project.get('companies').removeObject(company);
    yield this.project.save();
  }

  @task
  *addPortfolioCompanyTask(company) {
    this.project.get('companies').pushObject(company);
    yield this.project.save();
    //this.reloadCompaniesTask.perform();
  }

  @action
  onSave(tab, project, onSave) {
    /*
      if (this.createExternalUrlTab) {
          let sortOrder = 1;

          project.get('projectTabs').then((projectTabs) => {
              let lastTab = projectTabs.toArray().sortBy("sortOrder").lastObject;
              
              if (lastTab && lastTab.sortOrder) {
                  sortOrder = lastTab.sortOrder + 1;
              }

              let smartRoomTab = this.store.createRecord("project-tab", {
                  projectId: project.id,
                  enabled: true,
                  sortOrder: sortOrder,
                  title: "SmartRoom",
                  type: ProjectTabMap.EXTERNAL_URL,
                  content: this.serverVariables.get('smartroomAppUrl') + "#/" + this.selectedSmartRoom.slug
              });

              smartRoomTab.set('settings.EUOpenMode', CaseProjectUrlOpenModeMap.NEW_WINDOW)

              smartRoomTab.save().then(() => {
                  if (onSave) {
                      onSave();
                  }
              });
          })
      }
      */
  }

  @action
  searchCompanies() {
    set(this, 'searchAndSelectCompanies', true);
  }

  @action
  addPortfolioCompany(company) {
    this.addPortfolioCompanyTask.perform(company);
    set(this, 'searchAndSelectCompanies', false);
  }

  @action
  addCompany() {
    // let self = this;
    let record = this.get('store').createRecord('company', {});

    let self = this;

    this.docker.popupEditCompany(record, {
      onCreate: function () {
        self.addPortfolioCompany(record);
      },
    });
  }

  @action
  viewCompany(company) {
    this.docker.popupCompany(company);
  }

  @action
  editCompany(company) {
    let self = this;

    this.docker.popupEditCompany(company, {
      onRemove: function () {
        this.removeCompanyTask.perform();
      },
    });
  }

  @action
  removeCompany(company) {
    this.removeCompanyTask.perform(company);
  }
}
