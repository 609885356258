import Component from '@ember/component';
import { next } from '@ember/runloop';
import { inject } from '@ember/service';
import { AddressTypes, PhoneTypes } from 'cing-app/utils/lookups';
import { task, all, race } from 'ember-concurrency';
import NaicsCodes from 'cing-app/utils/naics-codes';
import { fetch } from 'fetch';
import { computed, get, set } from '@ember/object';
import { countries } from 'currency-codes';

export default Component.extend({
  // CompanyTypes: Object.keys(CompanyType),
  canSave: true,
  router: inject(),
  store: inject(),
  docker: inject('docker-item'),
  config: inject(),
  tagName: '',
  company: null,
  newDomainName: null,
  address: null,
  codes: null,
  companyType: null,
  showValidations: true,

  init() {
    this._super();

    /*
    this.set('editorOptionsDescription',EmberObject.create(this.get('config.APP.editorOptions.description'), {
        placeholderText: 'Optional company description'
    }));
    */

    this.initTask.perform();
  },

  initTask: task(function* () {
    yield this.loadCodes.perform();

    let company = yield this.get('context.company');
    this.set('company', company);

    if (this.company.companyType) {
      this.companyType = yield this.company.companyType;
    }

    let addresses = yield company.hasMany('addresses').reload({ adapterOptions: { include: 'country,state' } });

    let address = addresses.find(a => a.get('addressTypeId') === AddressTypes.Headquarters);
    if (!address) {
      address = this.get('store').createRecord('address', {
        companyId: company.get('id'),
        addressTypeId: AddressTypes.Headquarters
      });
    }
    this.set('address', address);

    let phones = yield company.hasMany('phones').reload();
    let phone = phones.find(p => p.get('typeId') === PhoneTypes.Headquarters);
    if (!phone) {
      phone = this.get('store').createRecord('phone', {
        companyId: company.get('id'),
        typeId: PhoneTypes.Headquarters
      });
    }
    this.set('phone', phone);

    let profile = yield company.get('companyProfile');

    if (!profile) {
      profile = this.get('store').createRecord('debtor-profile', {});
      company.set('companyProfile', profile);
    }
    this.set('companyProfile', profile);

    this.updateStates.perform();
  }),

  stopPropagation(e) {
    e.stopPropagation();
  },

  companyTypes: computed(function () {
    return this.store.query('company-type', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
  }),

  countries: computed(function () {
    // countries are preloaded at application boot!
    return this.store.peekAll("country").sortBy('name').toArray();
  }),

  updateStates: task(function* () {
    let country = yield get(this.address, 'country');

    if (country) {
      set(this, 'states', (yield (get(country, 'countrySubdivisions'))).sortBy('code').toArray());

      let currentState = yield get(this.address, 'state');

      if (currentState && !this.states.includes(currentState)) {
        set(this.address, 'state', null);
      }
    }
  }),

  loadCodes: task(function* () {
    //codes are stored in public directory
    let url = window.location.origin + this.get('config.rootURL') + '/codes-all_json.json';
    let codes = yield (yield fetch(url)).json();
    let currencies = codes.mapBy('AlphabeticCode').uniq().sort()

    currencies.removeObject(null);

    this.set('codes', currencies);
  }),

  saveTask: task(function* (close) {
    if (this.company.hasDirtyAttributes) {
      yield this.company.save();
    }

    let itemsToSave = [];

    this.set('phone.company', this.company);
    this.set('company.companyType', this.companyType);

    if (this.companyType) {
      itemsToSave.push(this.saveItemTask.perform(this.companyType));
    }

    // address (if present) is always saved as we can not track relationship changes on model :-/
    if (this.address) {
      this.set('address.company', this.company);
      itemsToSave.push(this.saveItemTask.perform(this.get('address')));
    }

    if (get(this, 'phone.hasDirtyAttributes')) {
      itemsToSave.push(this.saveItemTask.perform(this.get('phone')));
    }

    if (get(this, 'companyProfile.hasDirtyAttributes')) {
      itemsToSave.push(this.saveItemTask.perform(this.get('companyProfile')));
    }

    let amounts = (yield get(this.company, 'amounts')).toArray();

    for (var a = 0; a < amounts.length; a++) {
      amounts[a].set('company', this.company);
      amounts[a].set('companyId', this.company.id);
      if (amounts[a].hasDirtyAttributes) {
        itemsToSave.push(this.saveItemTask.perform(amounts[a]));
      }
    }

    let attributes = (yield get(this.company, 'attributes')).toArray();

    for (var a = 0; a < attributes.length; a++) {
      attributes[a].set('company', this.company);
      attributes[a].set('companyId', this.company.id);
      if (attributes[a].hasDirtyAttributes) {
        itemsToSave.push(this.saveItemTask.perform(attributes[a]));
      }
    }

    let domains = (yield get(this.company, 'companyDomains')).toArray();

    for (var a = 0; a < domains.length; a++) {
      domains[a].set('company', this.company);
      domains[a].set('companyId', this.company.id);
      if (domains[a].hasDirtyAttributes) {
        itemsToSave.push(this.saveItemTask.perform(domains[a]));
      }
    }

    yield all(itemsToSave);

    // double save
    yield this.company.save();

    if (this.get('context.onCreate')) {
      this.get('context.onCreate')(this.company);
    }

    if ((close || this.get('context.closeOnCreate')) && this.onClose) {
      this.onClose();
    } else {
      yield this.store.findRecord('company', this.company.id, { include: 'addresses.country,addresses.state,phones,company-profile,emails,company-domains,attributes,amounts' })
    }
  }),

  saveItemTask: task(function* (item) {
    yield item.save();
  }).enqueue().maxConcurrency(4),

  addCompanyTypeTask: task(function* (newTypeName, options) {
    let typeCheck = newTypeName.trim();

    // array of all company types that exist
    let checking = options.options;

    // checking if type exist
    for (let i = 0; i < checking.length; i++) {
      if (typeCheck.toLowerCase() === checking[i].name.toLowerCase()) {
        typeCheck = "";
      }
    }

    if (typeCheck) {
      let newType = this.store.createRecord('company-type', {
        name: typeCheck,
      });
      yield newType.save()

      // reload company type
      let types = yield this.store.query('company-type', {
        page: {
          size: 1000,
          sort: 'name',
        },
      });

      set(this, 'companyTypes', types);
    }
  }),

  removeDomainTask: task(function* (domain) {
    yield domain.destroyRecord();
    this.set('confirmDomainRemoval', false);
  }),
  removeCompanyTask: task(function* () {
    let addresses = (yield this.get('company.addresses')).toArray();

    for (var a = 0; a < addresses.length; a++) {
      yield addresses[a].destroyRecord();
    }

    let phones = (yield this.get('company.phones')).toArray();
    for (var a = 0; a < phones.length; a++) {
      yield phones[a].destroyRecord();
    }

    let amounts = (yield this.get('company.amounts')).toArray();
    for (var a = 0; a < amounts.length; a++) {
      yield amounts[a].destroyRecord();
    }

    let attributes = (yield this.get('company.attributes')).toArray();
    for (var a = 0; a < attributes.length; a++) {
      yield attributes[a].destroyRecord();
    }

    let companyDomains = (yield this.get('company.companyDomains')).toArray();
    for (var a = 0; a < companyDomains.length; a++) {
      yield companyDomains[a].destroyRecord();
    }

    if (this.get('companyProfile')) {
      yield this.get('companyProfile').destroyRecord();
    }

    if (this.context.onRemove) {
      this.context.onRemove();
    }

    if (this.onClose) {
      this.onClose();
    }

    if (this.company) {
      yield this.company.destroyRecord();
    }
  }),
  removeAmount: task(function* (amount) {
    yield amount.destroyRecord();
    this.set('confirmAmountRemoval', null);
  }),

  removeAttribute: task(function* (attribute) {
    yield attribute.destroyRecord();
    this.set('confirmAttributeRemoval', null);
  }),


  actions: {
    changeCode(amount, codeData) {
      amount.set('code', codeData);
    },

    createCompanyType(newTypeName, options) {
      this.addCompanyTypeTask.perform(newTypeName, options);
    },

    hideCompanyTypeCreateOnSame(term) {
      let existingOption = get(this, 'companyTypes').findBy('name', term);
      return !existingOption;
    },

    removeType(type) {
      type.destroyRecord();
    },

    addAmount() {
      this.get('company.amounts').then((amounts) => {
        amounts.pushObject(this.get('store').createRecord('company-amount', { code: 'USD' }))
      });
    },
    addAttribute() {
      this.get('company.attributes').then((attributes) => {
        attributes.pushObject(this.get('store').createRecord('company-attribute'))
      });
    },
    addDomain() {
      this.get('company.companyDomains').then((domains) => {
        domains.pushObject(this.get('store').createRecord('company-domain'));
      })
    },
    onNAICSFocusOut() {
      this.get('company.companyProfile').then((profile) => {
        if (!profile.get('industry') && profile.get('naicsCode')) {
          let industry = NaicsCodes.findBy('code', profile.get('naicsCode'));

          if (industry) {
            profile.set('industry', industry.name);
          }
        }
      })
    },
    save(close) {
      if (this.company.name) {
        this.company.name = this.company.name.trim();
      }
      if (!this.company.get('validations.isValid')) {
        return;
      }

      // if address is new
      if (this.address.get('isNew')) {
        //if address have some string
        if (this.address.street !== undefined) {
          this.address.street = this.address.get('street').trim();
        }
        // if adress street doesnot exist
        if (!this.address.street) {
          this.address = null;
        }
      }
      //if address is not new in company and exist
      else {
        if (this.address.street !== undefined) {
          this.address.street = this.address.get('street').trim();
        }
        // if adress street doesnot exist
        if (!this.address.street) {
          this.address.deleteRecord();
        }
      }

      this.get("saveTask").perform(close);
    },
    saveDomains() {
      this.get("saveTask").perform();
    },
    doRemoveDomain(domain) {
      this.get("removeDomainTask").perform(domain);
    },
    doRemoveCompany() {
      this.get("removeCompanyTask").perform();
    },
    cancel() {
    },
    update() {
      next(() => {
        this.$(`text`).focus();
      })
    },
    changeDescription(changeValueFunc, val) {
      changeValueFunc('description', val);
    }
  }
})

