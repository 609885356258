import Component from '@glimmer/component';
import InterestedParty from 'cing-app/models/interested-party';
import { DataSourceColumn } from 'smex-ui-table';

interface ColumnUserGroupArgs {
  row: InterestedParty,
  column: DataSourceColumn<any>

}
export default class ColumnUserGroup extends Component<ColumnUserGroupArgs> {
}