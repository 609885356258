import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Docket from './docket';
import Judge from './judge';
import Event from './event';
import Abstract from './abstract';

export default class PacerEvent extends Abstract {
  @attr('string') declare eventId: string;
  @attr('string') declare judgeId?: string;
  @attr('string') declare docketId?: string;

  @attr('date') declare filed?: Date;
  @attr('string') declare dueSetDate?: String;
  @attr('string') declare dueSetTime?: String;
  @attr('date') declare satisfied?: Date;
  @attr('date') declare terminated?: Date;

  @belongsTo('docket') declare docket: AsyncBelongsTo<Docket>;
  @belongsTo('judge') declare judge: AsyncBelongsTo<Judge>;
  @belongsTo('event') declare event: AsyncBelongsTo<Event>;

  get internalDate() {
    if (this.dueSetTime) {
      return `${this.dueSetDate} ${this.dueSetTime}`;
    } else {
      return null;
    }
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'pacer-event': PacerEvent;
  }
}
