import Component from '@glimmer/component';
import { action, computed, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import {
  GenericAlertDocumentLinkType,
  ProjectTabMap,
  ESettings,
} from 'cing-app/utils/lookups';
import { LineBreaks } from 'cing-app/helpers/linebreaks';
import { Email } from 'cing-app/pods/se-actions/email';
import injectScripts from 'ember-inject-scripts';
import { next } from '@ember/runloop';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

import moment from 'moment';
import classic from 'ember-classic-decorator';
import { taskFor } from 'ember-concurrency-ts';
import { async } from 'rsvp';

export default class SendAlert extends Component {
  @alias('args.context.users') users;
  @alias('args.context.onComplete') onComplete;

  @service store;
  @service config;
  @service abModels;
  @service seActions;
  @tracked confirmDoSendAlert = false;
  @tracked showFormValidations = false;
  @tracked showAlertConfirmation = false;
  @tracked templates = null;
  @tracked model = null;
  @tracked grapesEditor = null;
  @tracked grapesElement = null;

  constructor() {
    super(...arguments);
    this.model = this.store.createRecord('send-alert', {
      recipients: [],
      attachments: [],
      values: {},
    });

    this.initTask.perform();
  }

  @computed('model.fromAddress')
  get fromAddressValid() {
    return String(this.model.fromAddress)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  @task
  *initTask() {
    let multipleScripts = [
      {
        id: 'grapesjs',
        src: '/libraries/grapesjs/grapes.min.js',
        once: true,
      },
      {
        id: 'grapesjs-mjml',
        src: '/libraries/grapesjs-mjml/grapesjs-mjml.min.js',
        once: true,
      },
    ];

    let injectEvents = yield injectScripts(multipleScripts);

    this.templates = yield this.store.query('alert-template', {
      page: {
        size: 1000,
      },
    });
  }

  @task
  *doSendAlertTask() {
    let recipients = [];

    this.users.forEach((user) => {
      recipients.push({
        UserId: user.id,
        Email: user.email,
        FirstName: get(user, 'person.firstName'),
        LastName: get(user, 'person.lastName'),
      });
    });

    set(this.model, 'templateData', get(this.model, 'alertTemplate.html'));
    set(this.model, 'recipients', recipients);

    yield this.seActions.sendAlert(this.model);
  }

  @action
  setupGrapes(element = null) {
    this.grapesElement = element || this.grapesElement;

    this.cleanupGrapes();

    let self = this;
    let model = this.model.alertTemplate;
    let seActions = this.seActions;

    var editor = grapesjs.init({
      fromElement: false,
      container: this.grapesElement,
      plugins: ['grapesjs-mjml'],
      components: model.mjmlComponents || model.html || defaultTemplate,
      //style: model.templateStyles || model.templateCss,
      height: 'auto',
      width: 'auto',
      //panels: { defaults: [] },
      pluginsOpts: {
        'grapesjs-mjml': {
          /* ...options */
        },
      },
      storageManager: {
        autoload: false,
      },
      colorPicker: { appendTo: 'parent', offset: { top: 20, left: -175 } },
      assetManager: {
        storageType: '',
        storeOnChange: true,
        storeAfterUpload: true,
        upload: 'https://localhost/assets/upload',
        multiUpload: true,
        assets: model.mjmlAssets || [],
        uploadFile: function (e) {
          var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          let fileToUpload = files[0];

          return seActions
            .uploadImage(fileToUpload, '', true, fileToUpload.name)
            .then((result) => {
              var assets = [
                {
                  type: 'image',
                  src: result.url,
                },
              ];
              editor.AssetManager.add(assets);
            });
        },
      },
    });

    self.grapesEditor = editor;

    /*
    To fix the problem that mj-image is rendered as <mj-image /> instead of <mj-image></mj-image>,
    which will make the next sibling be treated as its child instead of sibling
    */
    let root = editor.DomComponents.getWrapper();
    let searchAndUpdate = function (component, layer = 0) {
      if (component.get('tagName') === 'mj-image') {
        component.set('void', false);
      }

      let children = component.get('components');
      if (children) {
        children.each((child) => searchAndUpdate(child, layer + 1));
      }
    };
    searchAndUpdate(root);
    editor.on('component:add', function (model) {
      model.set('void', false);
    });

    editor.on('storage:start:store', (objectToStore) => {
      set(model, 'mjmlComponents', objectToStore.mjmlComponents);
      set(model, 'html', objectToStore.html);
      set(model, 'mjmlStyles', objectToStore.styles);
      set(model, 'css', objectToStore.css);
      set(model, 'mjmlAssets', objectToStore.mjmlAssets);
    });
  }

  @action
  cleanupGrapes(element) {
    if (this.grapesEditor) {
      this.grapesEditor = null;
    }
  }

  @action
  doSendAlert(record) {
    this.doSendAlertTask.perform(record);
  }

  @action
  onSelectTemplate(template) {
    if (!template) {
      this.grapesEditor = null;
      return;
    }
    set(this.model, 'subject', template.subject);
    set(this.model, 'fromAddress', template.fromAddress);

    if (this.grapesEditor) {
      this.setupGrapes();
    }
  }

  @action
  confirmAlert() {
    this.doSendAlertTask.cancelAll({ resetState: true });
    this.confirmDoSendAlert = false;
    this.showFormValidations = true;
    this.showAlertConfirmation = true;
  }
}
