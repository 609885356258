import AppBuilderSerializer from './app-builder';

export default AppBuilderSerializer.extend({
  attrs: {
    ownership: { serialize: false },
    callsTotal: { serialize: false },
    callsTotalPerc: { serialize: false },
    callsPrior: { serialize: false },
    callsPriorPerc: { serialize: false },
    callCurrent: { serialize: false },
    callCurrentPerc: { serialize: false },
    remainingTotal: { serialize: false },
    remainingPrior: { serialize: false },
    distTotal: { serialize: false },
    distTotalPerc: { serialize: false },
    distPrior: { serialize: false },
    distPriorPerc: { serialize: false },
    distCurrent: { serialize: false },
    distCurrentPerc: { serialize: false },
    recallableCapital: { serialize: false },
    currentEqu: { serialize: false },
    priorEqu: { serialize: false },
    totalEqu: { serialize: false },
    totalMgtFee: { serialize: false },
    priMgtFee: { serialize: false },
    currMgtFee: { serialize: false },
    equIntPaidCurrent: { serialize: false },
    equIntPaidPri: { serialize: false },
    equIntPaid: { serialize: false },
    equIntRecCurnt: { serialize: false },
    equIntRecPri: { serialize: false },
    totalEquIntRec: { serialize: false },
  },

  /*
  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      for (var a = 0; a < payload.data.length; a++) {
          this.normalizeItem(payload.data[a]);
      }

      return this._super(...arguments);
  },
  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      this.normalizeItem(payload.data);
      return this._super(...arguments);
  },
  normalizeItem(item) {
      item.id = item.attributes['event-id'];
  }
  */
});
