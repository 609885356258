import { attr } from '@ember-data/model';
import Abstract from './abstract';
import { exportable } from 'cing-app/utils/exportable';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';

const ProjectPriorityValidations = buildValidations({
  name: validator('presence', true),
});

export default class ProjectPriority extends Abstract.extend(
  ProjectPriorityValidations
) {
  @exportable()
  @attr('string')
  declare name: string;
  @attr('number', { defaultValue: 0 }) declare order: number;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-priority': ProjectPriority;
  }
}
