import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { EEventType, EEventCalculationType } from './events';
import { task } from 'ember-concurrency';
import moment from 'moment';

@classic
export default class EventDetail extends Component {
  @service('ab-store')
  store;

  @service
  abModels;

  EEventType = EEventType;
  EEventCalculationType = EEventCalculationType;

  @alias('context.model')
  model;

  @alias('context.project')
  project;

  @computed('model.eventType', 'model.calculationType')
  get genericForm() {
    let eventType = this.get('model.eventType');
    let calculationType = this.get('model.calculationType');

    let genericCapitalCallCalculations = [
      EEventCalculationType.EquallyDivided,
      EEventCalculationType.ByOwnership,
      EEventCalculationType.PercentageOfCommitted,
    ];

    let genericDistributionCalculations = [
      EEventCalculationType.DistributionSameAmount,
      EEventCalculationType.EquallyDivided,
      EEventCalculationType.ByOwnership,
    ];

    if (
      (eventType == EEventType.CapitalCall && genericCapitalCallCalculations.includes(calculationType)
        || (eventType == EEventType.Distribution && genericDistributionCalculations.includes(calculationType))
      )
    ) {
      return true;
    }

    return false;
  }

  @task(function* () {
    let eventDate = moment().format('MM-DD-YYYY_HHMMSS');
    let model = this.get('model');

    let eventId = `${eventDate}-${model.eventName.replace(/[^a-zA-Z]/g, "-")}`;

    model.setProperties({
      eventId: eventId,
      fundId: this.project.abFund
    })

    yield this.get('model').save();
    let modelName = this.abModels.getModelName(this.project.abProject, 'event-detail')
    let adapter = this.store.adapterFor(modelName);
    yield adapter.recalculate(modelName, model);

    if (this.context.onComplete) {
      this.context.onComplete();
    }
  })
  saveTask;

  @action
  save() {
    this.set('showValidations', true);
    this.get('model').validate().then(({ model, validations }) => {
      if (validations.get('isValid')) {
        this.saveTask.perform();
      } else {
        console.log(validations.get('errors'))
      }
    }).catch((errors) => {
      console.log(errors);
    });
  }
}