import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
export default class PaymentMethods extends Component {
  @alias('context.investor')
  investor;

  @alias('context.abInvestor')
  abInvestor;

  @alias('context.project')
  project;

  @service('ab-store')
  store;

  @service
  abModels;

  included = '';

  @computed('project.abProject')
  get modelName() {
    let abProject = this.get('project.abProject');

    if (abProject) {
      return this.abModels.getModelName(abProject, 'payment-method');
    }
  }

  init() {
    super.init(...arguments);
    this.set('columns', this.getColumns());

    // this.set('interestedPartyFilter', Ember.A());
    this.generateFilter();
  }

  getColumns() {
    let columns = [
      {
        propertyName: 'preferredPaymentMethod',
        sortedBy: 'preferred-payment-method'
      },
      {
        propertyName: 'paymentType',
        sortedBy: 'payment-type'
      },
      {
        propertyName: 'accountNumber',
        sortedBy: 'account-Number'
      },
      {
        propertyName: 'swiftCode',
        sortedBy: 'swift-code'
      },
      {
        propertyName: 'accountType',
        sortedBy: 'account-type'
      },
      {
        propertyName: 'routing/abaNumber',
        sortedBy: 'routing/aba-number'
      },
      {
        propertyName: 'financialInstitution',
        sortedBy: 'financial-institution'
      },
      {
        propertyName: 'financialInstitutionAddress',
        sortedBy: 'financial-institution-address',
        isHidden: true,
      },
      {
        propertyName: 'beneficiaryName',
        sortedBy: 'beneficiary-name'
      },
      {
        propertyName: 'beneficiaryAddress',
        sortedBy: 'beneficiary-address',
        isHidden: true,
      },
      {
        propertyName: 'furtherCredit',
        sortedBy: 'further-credit',
        isHidden: true,
      },
      {
        propertyName: 'fundingNote',
        sortedBy: 'funding-note',
        isHidden: true,
      },


    ];

    return columns;
  }

  generateFilter() {
    let filter = {
      'inv-fund-id': 'eq:' + this.get('abInvestor.invFundId')
    }

    this.set('filter', filter);
  }
}




