import Component from '@glimmer/component';
import { DataSourceColumn } from 'smex-ui-table';

interface ColumnSrprofileArgs {
  row: InterestedParty,
  column: DataSourceColumn<any>
}

export default class ColumnSrprofile extends Component<ColumnSrprofileArgs> {
  // @computed('column.allSmartRoomUsers.[]', 'column.allSmartRoomUsers.@each.status')
  get categoryName() {
    if (this.args.row.emailId && this.args.column.options.allSmartRoomUsers) {
      let allSmartRoomUsers = this.args.column.options.allSmartRoomUsers.toArray();

      let emailAddress = this.args.row.get('email.emailAddress');
      if (emailAddress) {
        for (var a = 0; a < allSmartRoomUsers.length; a++) {
          let user = allSmartRoomUsers[a];

          if (user.email.toLowerCase() === emailAddress.toLowerCase()) {
            return user.categoryName;
          }
        }
      }
    }

    return null;
  }
}