// import Ember from 'ember';
import {
  attr,
  hasMany,
  belongsTo,
  AsyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import UserGroup from 'cing-app/models/user-group';
import UserInGroup from 'cing-app/models/user-in-group';
import Person from 'cing-app/models/person';
import AclRoleAssignment from './acl-role-assignment';
import { exportable } from 'cing-app/utils/exportable';

const UserValidations = buildValidations({
  email: [validator('presence', true), validator('format', { type: 'email' })],
});

export default class User extends Abstract.extend(UserValidations) {
  @attr('string') declare email: string;
  @attr('number') declare flags?: number;
  @attr('string') declare personId?: string;
  @attr('string') declare pacerUserName?: string;
  @exportable()
  @attr('udate')
  declare lastAccess?: Date;
  @exportable()
  @attr('udate')
  declare lastInviteDate?: Date;
  @attr('string') declare pacerUserPassword?: string;
  @attr('string') declare ecfAlertsEmail?: string;
  @attr('udate') declare createTime?: Date;
  //@ts-ignore
  @hasMany('acl-role-assignment')
  declare aclRoleAssignments: AsyncHasMany<AclRoleAssignment>;
  //@ts-ignore
  @hasMany('acl-role-assignment', { inverse: null })
  declare instanceAclRoleAssignments: AsyncHasMany<AclRoleAssignment>;
  //@ts-ignore   // because mixin is used
  @hasMany('user-group') declare groups: AsyncHasMany<UserGroup>;
  //@ts-ignore
  @hasMany('user-in-group') declare userInGroups: AsyncHasMany<UserInGroup>;
  //@ts-ignore
  @belongsTo('person') declare person!: AsyncBelongsTo<Person>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: User;
  }
}
