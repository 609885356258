import Component from '@glimmer/component';
import {
  UploadFileInfo,
  UploadFolderInfo,
  UploadInfo,
} from 'smex-ui-sr-services';
import { DataSourceColumn, IDataSource, StaticDataSource } from 'smex-ui-table';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { formatSize } from 'cing-app/helpers/format-size';

interface SmartroomUploadProgressDetailArgs {
  header: any;
  footer: any;
  context: {
    uploadInfo: UploadInfo;
    //info to parent about closing
    onClose: () => void;
  };
  //close window
  onClose: (a: false) => void;
}

export default class SmartroomUploadProgressDetail extends Component<SmartroomUploadProgressDetailArgs> {
  //@ts-ignore
  @service('session')
  session: any;
  @tracked
  dataSource: IDataSource<UploadFileInfo | UploadFolderInfo>;
  @tracked
  columns: DataSourceColumn<UploadFileInfo | UploadFolderInfo>[] = [
    new DataSourceColumn<UploadFileInfo | UploadFolderInfo>({
      id: 'name',
      label: 'File Name',
      getValue: (f) =>
        f instanceof UploadFileInfo ? f.file.name : f.relativePath,
      sortingEnabled: true,
      minWidth: 200,
      valueComponent: 'table-text-column',
    }),
    new DataSourceColumn<UploadFileInfo | UploadFolderInfo>({
      id: 'size',
      label: 'Size',
      getValue: (f) =>
        f instanceof UploadFileInfo ? formatSize([f.file.size]) : '',
      sortingEnabled: true,
      minWidth: 100,
      maxWidth: 100,
      valueComponent: 'table-text-column',
      valueClass: 'text-right',
      headerClass: 'justify-content-end',
    }),
    new DataSourceColumn<UploadFileInfo | UploadFolderInfo>({
      id: 'progress',
      label: 'Progress',
      getValue: (f) => f.progress,
      sortingEnabled: false,
      minWidth: 100,
      valueComponent: 'smartroom/upload-progress/detail/progress-column',
    }),
  ];

  constructor(owner: unknown, args: SmartroomUploadProgressDetailArgs) {
    super(owner, args);

    let allItems = [
      ...this.args.context.uploadInfo.folders,
      ...this.args.context.uploadInfo.files,
    ];
    this.dataSource = new StaticDataSource<
      UploadFileInfo | UploadFolderInfo,
      UploadFileInfo | UploadFolderInfo
    >(
      30,
      false,
      'upload-progress-detail',
      this.session.authUser.email,
      allItems,
      this.loadItems,
      1000,
      this.columns
    );
  }

  @task
  loadItems = taskFor(
    async (
      data: UploadFileInfo[],
      columns: DataSourceColumn<UploadFileInfo>[]
    ) => {
      return data;
    }
  );

  @action
  close() {
    this.args.context.onClose();
    this.args.onClose(false);
  }
}
