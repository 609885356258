import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import User from './user';
import Tag from './tag';
import Abstract from './abstract';

const modelValidations = buildValidations({
  title: [validator('presence', true)],
});

export default class InterestedPartyTag extends Abstract.extend(
  modelValidations
) {
  @attr('string') declare tagId: string;
  @attr('string') declare interestedPartyId: string;
  @belongsTo('user') declare userId?: AsyncBelongsTo<User>;
  @belongsTo('tag') declare tag: AsyncBelongsTo<Tag>;
  @attr('date') declare createTime?: Date;
  @attr('date') declare modifyTime?: Date;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'interested-party-tag': InterestedPartyTag;
  }
}
