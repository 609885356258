import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';

import { ApiDataSource } from 'smex-ui-table';

import { pluralize } from 'ember-inflector';
import { alias } from '@ember/object/computed';
import { dasherize } from '@ember/string';

import {
  Expressions,
  ExpressionOperators,
} from 'cing-app/mixins/filter-builder';

interface ExportArgs {
  dataSource: ApiDataSource<any>;
  condition: any;
  modelName: string;  
  fields: string; //if not passed get from datasource
}

export default class Export extends Component<ExportArgs> {
  @service session!: _SessionService;
  @service config: any;
  @alias('config.APP.api.host') host!: string;
  @alias('config.APP.api.namespace') namespace!: string;
  @service store!: Store;

  createExportUrl() {
    let fields = [];
    if(this.args.dataSource?.columns){
        for(var i=0;i<this.args.dataSource?.columns.length;i++){
            let c = this.args.dataSource?.columns[i];

            if(c.id=='tasks.wbs' || c.id=='tasks.task') 
               continue;
            fields.push(`${(c.id)}::${(c.label).replace('#','')}`);
        }
    }

    let query: any = {
      fields:  fields.join(','),
      include: ['tasks']
    };

    console.log('fields: ', fields.join(','))
    /*
    let condition = Expressions.create({
      operator: ExpressionOperators.AND,
    }); 
    condition.add(this.args.condition); 
    query.condition = condition.serialize();
    */
    let url = `${this.host}/${this.namespace}/${pluralize(
      this.args.modelName.replace('appbuilder/', '')
    )}/excel?`;
    return url + new URLSearchParams(query).toString();
  }

  @action
  export(fallbackFileName = null) { 

    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.responseType = 'arraybuffer';

    let exportUrl = this.createExportUrl();

    xhr.open('GET', exportUrl);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${this.session.data.authenticated.access_token}`
    );

    xhr.onload = function () {
      if (this.status === 200) {
        let filename = '';
        var disposition = xhr.getResponseHeader('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
        }

        if (!filename && fallbackFileName) {
          filename = fallbackFileName;
        }

        var type = xhr.getResponseHeader('Content-Type');

        var blob = new Blob([this.response], { type: type });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement('a');
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
              window.location = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location = downloadUrl;
          }

          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      }
    };

    xhr.send();
  }
}
