import Component from '@glimmer/component';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';

interface ManageUsersArgs {
  header: any;
  footer: any;
}

export default class ManageUsers extends Component<ManageUsersArgs> {

  get styleNamespace() {
    return podNames["manage-users"];
  }

}