import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import User from 'cing-app/models/user';
import Abstract from './abstract';

interface DnsRecord {
  Type: string;
  Hostname: string;
  Value: string;
  IsValid: boolean;
}

interface DomainMetadata {
  Domain: string;
  IsValid: boolean;
  DnsRecords: DnsRecord[];
}

interface SendingDomainMetadata {
  SendingDomain: DomainMetadata;
  TrackingDomain: DomainMetadata;
  BounceDomain: DomainMetadata;
}

export const DOMAIN_REGEX =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;

export const SUBDOMAIN_REGEX =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)*[a-z0-9]{0,62}[a-z0-9]$/i;

const SendingDomainValidations = buildValidations({
  sendingDomain: [
    validator('presence', true),
    validator('format', {
      regex: DOMAIN_REGEX,
    }),
  ],
  trackingDomainSub: [
    validator('format', {
      regex: SUBDOMAIN_REGEX,
      allowBlank: true,
    }),
  ],
  bounceDomainSub: [
    validator('format', {
      regex: SUBDOMAIN_REGEX,
      allowBlank: true,
    }),
  ],
});

export default class SendingDomain extends Abstract.extend(
  SendingDomainValidations
) {
  @attr('string') declare sendingDomain: string;
  @attr('string') declare trackingDomain: string;
  @attr('string') declare bounceDomain: string;
  @attr('string') declare status: string;
  @attr('string') declare provider: string;
  @attr('string') declare metadataString: string;
  @attr declare metadata: SendingDomainMetadata;
  @attr('date') declare verifiedTime?: Date;
  @attr('boolean') declare enabled: boolean;
  @attr('string') declare createdBy?: string;
  @attr('date') declare createTime: Date;
  @attr('date') declare modifyTime: Date;

  trackingDomainSub = '';
  bounceDomainSub = '';

  @belongsTo('user') user: AsyncBelongsTo<User>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sending-domain': SendingDomain;
  }
}
