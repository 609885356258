import Component from '@glimmer/component';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import fs from 'filesize';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import data from 'cing-app/utils/timezones/data';

export interface ILayoutProperty {
	propName: string;
	code: string;
}

export interface ILayout {
	style: string;
	template: string;
	properties: ILayoutProperty[];
}

interface LayoutViewerArgs {
	abLink: AppbuilderLink;
	objectName: string;
	layout?: ILayout;
	model: any;
	[key: string]: any;
}

export default class LayoutViewer extends Component<LayoutViewerArgs> {
	@service('store')
	store!: Store;

	constructor(owner: unknown, args: LayoutViewerArgs) {
		super(owner, args);
		window.addEventListener('message', this.onEvent);
	}

	willDestroy() {
		window.removeEventListener('message', this.onEvent);
		super.willDestroy();
	}

	@action
	onEvent(event: MessageEvent) {
		let eventInfo = <{ eventType: string, args: any[] } | any>event.data;
		if (eventInfo.eventType === 'layout-viewer-event') {
			if (typeof this.args[eventInfo.eventName] === 'function') {
				this.args[eventInfo.eventName](...eventInfo.args);
			}
		}
	}


	get layout() {
		//@ts-ignore
		let template = Handlebars.compile(this.args.layout?.template ?? '');

		//@ts-ignore
		let filesize = fs;
		let rawModel = this.args.model;//model property is used in eval!! do not remove this line.
		let model: any = {};

		let modelClass = this.store.modelFor(this.args.model.constructor.modelName);
		//go through all attributes, get column and format it.

		for (let attr of modelClass.attributes.keys()) {
			let c = this.args.abLink.columns.find(c => c.propertyName === attr && c.objectName === this.args.objectName);
			if (c) {
				model[attr] = (c.format ? c.format.formatValueHtml(rawModel[attr]) : rawModel[attr])
			}
		}

		let jsonModel = (model.toJSON ? model.toJSON() : model);
		let jsonRawModel = (rawModel.toJSON ? rawModel.toJSON() : rawModel);

		let templateData: any = {
			model: jsonModel,
			rawModel: jsonRawModel
		};

		for (let prop of this.args.layout?.properties ?? []) {
			try {
				templateData[prop.propName] = eval(prop.code);
			}
			catch (err) {
				//ignore error for V1
			}
		}

		let result = template(templateData);
		let page = `
	<html>
		<head>
			<title></title>
			<link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'/>
			<style>
			${this.args.layout?.style}
			</style>
		</head>
		<body>
		<div class='resize-div'>
			${result}
			</div>
			<script type='text/javascript'>
				function sendEvent(eventName, ...args){
					window.parent.postMessage({
						eventType:'layout-viewer-event',
						eventName,
						args
				  }, '*');
				}
			</script>
			<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
		</body>
	</html>
	`;
		return page;
	}
}
