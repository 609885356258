import Component from '@glimmer/component';
import Case from 'cing-app/models/appbuilder-link/case';
import { ABModelMeta } from 'cing-app/pods/appbuilder/service';
import { ILayout } from 'cing-app/pods/components/layout-viewer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import FormExcelSheet from 'cing-app/models/appbuilder-link/form-excel-sheet';
import AppbuilderLink from 'cing-app/models/appbuilder-link';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
// import Handlebars from 'handlebars';

interface ProjectDetailAbProjectViewArgs {
	onClose: () => void;
	context: {
		projectTab: any;
		project: any;
		ABLink: AppbuilderLink;

		objectName: string,
		modelMeta: ABModelMeta,
		model: any;
		onEditClick: () => void;
	}
}

export default class ProjectDetailAbProjectView extends Component<ProjectDetailAbProjectViewArgs> {
	@service('docker-item')
	docker!: DockerItemService;
	@tracked
	selected = -1;
	@tracked
	detailSelected = -1;
	// @tracked
	// formExcelSheets: FormExcelSheet[] = [];

	get layoutDefinition() {
		return (this.args.context.ABLink.views ?? {})[this.args.context.objectName];
	}

	get excelSheetsWithDefinedViews() {
		return this.formExcelSheets.filter(es => this.args.context.ABLink.columns.any(c => c.objectName === es.objSheetName));
	}

	@action
	openCrmContact(email: string) {
		this.docker.openSearchCrmByContact('search_by_contact', email);
	}



	get formExcelSheets() {
		let abCase = this.args.context.ABLink.ABCase;
		let excelSheets = abCase.formExcelSheets;
		let relationships = excelSheets.filterBy('parentObjSheetName', this.args.context.objectName);
		// this.formExcelSheets = relationships;
		return relationships;
	}



	@action
	selectedTabChanged(selected: number) {
		this.selected = selected;
	}
}
