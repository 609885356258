import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
export default class ColumnProject extends Component {
  @service('docker-item')
  docker;

  @service
  store;

  @action
  showProjectDetail(record) {
    const appearance = {
      label: 'Project',
      icon: '',
      title: '<small>Project:</small> ' + record.get('name'),
      size: 'large',
      status: 'full',
      custom: true
    };
    this.get('docker').invokePopup('project-detail', appearance, { project: record });
  }
}
