import classic from 'ember-classic-decorator';
import Field from '../field/component';
import { set, computed } from '@ember/object';
import { action } from '@ember/object';
@classic
export default class Flag extends Field {
  showFormValidations = null;
  type = 'checkbox';
  flag = null;

  @computed('value')
  get checked() {
    if (this.get('value') & this.flag) {
      return true;
    } else {
      return false;
    }
  }

  @action
  onChange(enabled) {
    let mask = this.get('value') || 0;

    if (mask & this.flag) {
      mask &= ~this.flag;
    } else {
      mask |= this.flag;
    }

    console.log(this.label + " mask: ", mask, " (flag: ", this.flag, ")");

    this.set('value', mask);
  }
}