import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment';
import { action } from '@ember/object';

//@ts-ignore
import { tracked } from '@glimmer/tracking';
import { alias } from '@ember/object/computed';
import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AppEventsService from 'cing-app/pods/app-events/service';
import SessionService from 'cing-app/pods/session/service';
import {
	Expressions,
	Filter,
	FilterOperators,
} from 'cing-app/mixins/filter-builder';
//@ts-ignore
import podNames from "ember-component-css/pod-names";
import { dasherize } from "@ember/string";
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import DataLinkModel from 'cing-app/models/data-link-model';
import DataLinkModelColumn from 'cing-app/models/data-link-model-column';
//@ts-ignore
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import DataLink from 'cing-app/models/data-link';
import DataLinkView from 'cing-app/models/data-link-view';
import DataLinkViewColumn from 'cing-app/models/data-link-view-column';
import DataLinkContextFilter from 'cing-app/models/data-link-context-filter';

interface ManageDataLinkViewEditListArgs {
	context?: {
		model: DataLinkView;
		dataLink: DataLink
	};
	onClose?: () => void;
	header?: any;
	footer?: any;
}

type ModelProperty = { property: string, isFromExtended: boolean };

export default class DataLinkDetail extends Component<ManageDataLinkViewEditListArgs> {
	@service
	config: any;

	@service
	store!: Store;

	@service
	cookies: any;

	@service
	appEvents!: AppEventsService;

	@service('docker-item')
	docker!: DockerItemService;

	@service
	session!: SessionService;

	@tracked
	error: any

	@tracked
	editProp?: DataLinkViewColumn;

	@tracked
	selectedFilter?: DataLinkContextFilter

	@alias('args.context.model') model!: DataLinkView;
	@alias('args.context.dataLink') dataLink!: DataLink;

	@tracked
	dataLinkModel?: DataLinkModel;

	get styleNamespace() {
		return podNames["manage-data-links/detail/views/edit-list"];
	}

	constructor(owner: any, args: ManageDataLinkViewEditListArgs) {
		super(owner, args);
		this.initTask.perform();
	}

	@task
	initTask = taskFor(async (): Promise<void> => {
		this.dataLinkModel = this.dataLink.models.findBy('modelName', this.model.modelName);
	});

	@action
	update() {
		this.updateTask.perform(this.dataLink);
	}

	@task
	updateTask = taskFor(async (model: DataLink): Promise<DataLink> => {
		console.log("MODEL: ", model);
		await model.save();

		return model;
	});

	@action
	changeProp(propName: 'maxWidth' | 'minWidth' | 'maxHeight', column: DataLinkViewColumn, evt: Event) {
		let text = (<HTMLInputElement>evt.target).value;
		let value = (text ? parseInt(text) : null);
		if (value !== null && Number.isInteger(value)) {
			column[propName] = value;
		} else {
			//@ts-ignore
			column[propName] = null;
		}
	}

	@action
	editPropertyFormat(column: DataLinkViewColumn) {
		this.editProp = column;
	}

	@action
	updatePropertyFormat(column: DataLinkViewColumn) {
		if (this.editProp) {
			this.editProp = undefined;
		}
	}


	@action
	reorderItems(columns: DataLinkViewColumn[]) {
		columns.forEach((col, i) => {
			if (col.displayOrder !== i) {
				col.displayOrder = i;
			}
		});
	}

	@action
	addProperty(prop: DataLinkModelColumn) {
		try {
			//@ts-ignore
			let newColumn = this.store.createFragment('data-link-view-column', <DataLinkViewColumn>{
				propertyName: prop.propertyName,
				label: prop.label,
				format: prop.format,
				isFromExtended: prop.isFromExtended
			})

			this.model.columns.pushObject(newColumn);

			console.log("COLUMNS: ", this.model.columns);
		} catch (e) {
			console.log("E: ", e)
		}
	}

	@action
	removeProperty(column: DataLinkViewColumn) {
		this.model.columns.removeObject(column);
	}

	@action
	addFilter() {
		let newFilter: DataLinkContextFilter = this.store.createFragment('data-link-context-filter', {

		})
		this.model.filters.pushObject(newFilter);

		this.selectedFilter = newFilter;
	}

	@action
	editFilter(filter: DataLinkContextFilter) {
		this.selectedFilter = filter;
	}

	@action
	removeFilter(filter: DataLinkContextFilter) {
		this.model.filters.removeObject(filter);
		this.selectedFilter = undefined;
	}

	@action
	updateFilter() {
		this.selectedFilter = undefined;
	}
}
