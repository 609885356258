define("cing-app/services/smart-room-upload", ["exports", "smex-ui-sr-services/services/smart-room-upload"], function (_exports, _smartRoomUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ItemsToUpload", {
    enumerable: true,
    get: function () {
      return _smartRoomUpload.ItemsToUpload;
    }
  });
  Object.defineProperty(_exports, "UploadFileInfo", {
    enumerable: true,
    get: function () {
      return _smartRoomUpload.UploadFileInfo;
    }
  });
  Object.defineProperty(_exports, "UploadInfo", {
    enumerable: true,
    get: function () {
      return _smartRoomUpload.UploadInfo;
    }
  });
  Object.defineProperty(_exports, "UploadStructure", {
    enumerable: true,
    get: function () {
      return _smartRoomUpload.UploadStructure;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _smartRoomUpload.default;
    }
  });
});
