import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
export default class Company extends Component {
  @service('docker-item')
  docker;

  @service
  store;

  @action
  showCompanyDetail(record) {
    let modelName = record.get('constructor.modelName');

    if (modelName === 'company') {
      this.docker.popupCompany(record);
    } else {
      let companyColumn = "company";

      if (modelName === 'case') {
        companyColumn = 'debtorCompany';
      }
      record.get(companyColumn).then((company) => {
        if (!company) {
          let companyId = record.get('interestedParty.companyId')
          company = this.get('store').peekRecord('company', companyId);
        }
        this.docker.popupCompany(company);
      })
    }
  }
}
