import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import Docket from './docket';

export default class DocketNote extends Abstract {
  @attr('string') declare title?: string;
  @attr('string') declare description?: string;
  @attr('date') declare noteDate?: Date;
  @attr('boolean', { defaultValue: false }) declare isPublic?: boolean;
  @attr('string') declare userId?: string;
  @belongsTo('docket') declare docket: AsyncBelongsTo<Docket>;
  @attr('string') declare docketId: string;
  @attr('boolean') declare deleted?: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'docket-note': DocketNote;
  }
}
