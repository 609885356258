import classic from 'ember-classic-decorator';
import { classNames, classNameBindings } from '@ember-decorators/component';
import { alias, readOnly, or, and, notEmpty, not } from '@ember/object/computed';

import Component from '@ember/component';
import { defineProperty, computed, set } from '@ember/object';

@classic
@classNames('validated-input')
@classNameBindings('fieldClasses')
export default class Field extends Component {
  showLabel = true;
  inputColClass = 'col-sm-8';
  labelColClass = 'col-sm-4';
  baseInputClass = 'form-control form-control-sm';
  model = null;
  value = null;
  disabled = false;
  form = null;
  showFormValidations = null;
  type = 'text';
  property = '';
  placeholder = '';
  validation = null;
  showValidations = false;
  didValidate = false;

  @computed('horizontal')
  get fieldClasses() {
    if (this.horizontal) {
      return 'row form-group';
    }

    return 'form-group'
  }

  @(not('validation.isValidating').readOnly())
  notValidating;

  @(notEmpty('validation.warnings').readOnly())
  hasWarnings;

  @(and('validation.isTruelyValid').readOnly())
  isValid;

  @(or('showValidations', 'showFormValidations', 'didValidate').readOnly())
  shouldDisplayValidations;

  @(and('notValidating', 'showErrorMessage', 'validation').readOnly())
  showErrorClass;

  @(and('shouldDisplayValidations', 'validation.isInvalid').readOnly())
  showErrorMessage;

  @(and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly())
  showWarningMessage;

  init() {
    super.init(...arguments);
    let property = this.property;

    defineProperty(
      this,
      'validation',
      readOnly(`model.validations.attrs.${property}`)
    );
    defineProperty(this, 'value', alias(`model.${property}`));
    defineProperty(this, 'showFormValidations', readOnly(`form.showValidations`));
  }

  focusOut() {
    //this._super(...arguments);
    set(this, 'showValidations', true);
    this.sendAction("onFocusOut");
  }
}