import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency'
import { taskFor } from 'ember-concurrency-ts';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';//@ts-ignore
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import {
  CaseAssociationTypes,
  PersonInCompanyFlags
} from 'cing-app/utils/lookups';
import { async } from 'rsvp';

interface HistoryArgs{
  attorney:any;
}

export default class History extends Component<HistoryArgs> {

  @service() store!: Store;

  @tracked
  history:any;


  constructor(owner:any,args:HistoryArgs){
    super(owner,args);
    this.loadHistory.perform();
  }

  @task
  loadHistory = taskFor(async()=>{
    let expr = Expressions.create();
    expr.add(Filter.create({
      name: "personId",
      operator: FilterOperators.EQUAL,
      value: this.args.attorney.id,
    }));

    this.history = await this.store.query('person-in-company', {
      include: ['company', 'address'],
      condition: expr.serialize(),
      sort: '-last-seen'
    });
  })
}