import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { timeout, all } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
  UserStatus,
} from 'cing-app/pods/smartroom/service';
import { USStates } from 'cing-app/utils/us-states-lookup';

import {
  AclPermissionType,
  ProjectTabMap,
  EReportStatus,
  CrmImportSteps,
  ECrmImportStatus,
  ImportGenders,
} from 'cing-app/utils/lookups';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

import TableTheme from '../abstract-import/table-theme';
import classic from 'ember-classic-decorator';

@classic
export default class ImportXlsInvestorsContacts extends Component {
  @service store;
  @service config;
  @service session;
  @service('docker-item') docker;
  @service fileQueue;
  @service seActions;

  CrmImportSteps = CrmImportSteps;
  EReportStatus = EReportStatus;
  importAction = null;
  currentStep = 1;
  importFile = null;
  importRows = null;
  include = null;

  @computed
  get themeInstance() {
    return TableTheme.create();
  }

  @alias('context.onComplete') onComplete;

  init() {
    super.init(...arguments);
    this.set('selectedItems', []);
    this.initTask.perform();
    let queue = this.fileQueue.find('import_parties');

    if (queue && queue.files) {
      queue.files.forEach((file) => {
        queue.remove(file);
      });
    }
  }

  @computed
  get columns() {
    let self = this;

    let columns = [
      {
        title: '',
        component:
          'manage-interested-party/abstract-import/column-match-status',
      },
      {
        propertyName: 'rowData.Company',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Affiliation',
      },
      {
        propertyName: 'rowData.ContactName',
        component: 'manage-interested-party/abstract-import/column-person',
        title: 'Contact Name',
      },
      {
        propertyName: 'rowData.ContactEmail',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Contact Email',
      },
      {
        propertyName: 'rowData.Phones',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Phones',
      },
      {
        propertyName: 'rowData.DateOfBirth',
        component: 'manage-interested-party/abstract-import/column-date',
        title: 'Date Of Birth',
      },
      {
        propertyName: 'rowData.Gender',
        component: 'manage-interested-party/abstract-import/column-gender',
        options: ImportGenders,
        title: 'Gender',
      },
      {
        propertyName: 'rowData.BIOPage',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'BIO Page',
      },
      {
        propertyName: 'rowData.Note',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Note',
      },
      {
        propertyName: 'rowData.Address1',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Address 1',
      },
      {
        propertyName: 'rowData.Address2',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Address 2',
      },
      {
        propertyName: 'rowData.Address3',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Address 3',
      },
      {
        propertyName: 'rowData.City',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'City',
      },
      {
        propertyName: 'rowData.Country',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Country',
      },
      {
        propertyName: 'rowData.State',
        component: 'manage-interested-party/abstract-import/column-state',
        options: USStates,
        title: 'State',
      },
      {
        propertyName: 'rowData.Zip',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'Zip',
      },
      {
        propertyName: 'rowData.DealCloudContactId',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'DealCloud Contact ID',
        isHidden: true,
      },
      {
        propertyName: 'rowData.DealCloudCompanyId',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'DealCloud Company ID',
        isHidden: true,
      },
      {
        propertyName: 'rowData.DealCloudInvestorId',
        component: 'manage-interested-party/abstract-import/column-editable',
        title: 'DealCloud Investor ID',
        isHidden: true,
      },
    ];

    return columns;
  }

  @task
  *initTask() {}

  @task
  *prepareContactsReview() {
    let columnsInImport = this.get('importAction.actionResult.Columns') || [];

    let columns = this.get('columns');

    for (var a = 0; a < columns.length; a++) {
      let column = columns[a];
      let columnName = columns[a].propertyName;

      if (columnName && columnName.startsWith('rowData.')) {
        columnName = columnName.slice(8);

        if (columnsInImport && columnsInImport.indexOf(columnName) == -1) {
          column.isHidden = true;
        }
      }
    }

    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'action-id',
        operator: FilterOperators.EQUAL,
        value: this.get('importAction.id'),
      })
    );

    let importRows = yield this.store.query('crm-import', {
      condition: expr.serialize(),
      page: {
        size: 10000,
      },
      sort: 'import-order',
    });

    this.set('importRows', importRows.toArray());
  }

  @task
  *processTask() {
    yield this.addContactsTask.perform();

    if (this.context.onComplete) {
      console.log('Invoking onComplete callback');
      this.context.onComplete();
    }

    if (this.onClose) {
      this.onClose();
    }
  }

  @task
  *addContactsTask() {
    // first save changes to CRM import rows
    let allTasks = [];

    for (var a = 0; a < this.selectedItems.length; a++) {
      let importRow = this.selectedItems[a];

      if (importRow.hasDirtyAttributes) {
        allTasks.push(this.updateCrmRecord.perform(importRow));
      }
    }

    yield all(allTasks);

    yield this.seActions.importPartiesStepTwo(this.importAction);
  }

  @task
  *approveContactsImportTask() {
    let items = this.get('selectedItems').toArray();

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      item.set('importStatus', ECrmImportStatus.Approved);
      itemsToSave.push(this.updateCrmRecord.perform(item));
    }

    yield all(itemsToSave);
  }

  @task
  *rejectContactsImportTask() {
    let items = this.get('selectedItems').toArray();

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      item.set('importStatus', ECrmImportStatus.Rejected);
      itemsToSave.push(this.updateCrmRecord.perform(item));
    }

    yield all(itemsToSave);
  }

  @task
  *removeContactTask(party) {
    this.get('selectedItems').removeObject(party);
    this.get('selectedItems').unshiftObject(party);

    let items = this.get('selectedItems').toArray();

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      let item = items[a];
      this.importRows.removeObject(item);
      itemsToSave.push(item.destroyRecord());
    }

    yield all(itemsToSave);

    if (!this.importRows.length) {
      this.set('importAction', null);
      this.set('importFile', null);
      this.set('currentStep', CrmImportSteps.UploadImportFile);
    }
  }

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  *updateCrmRecord(crmImport) {
    yield crmImport.save();
  }

  @action
  cancel() {
    let queue = this.fileQueue.find('import_parties');

    if (this.importFile) {
      queue.remove(this.importFile);
    }

    this.set('importFile', null);

    if (this.onCancel) {
      this.onCancel();
    }
  }

  @action
  downloadSample() {
    window.location.assign(
      this.config.get('mainUrl') +
        '/sample_data/smartexchange_contacts_import.xlsx'
    );
  }

  @action
  approveContactsImport() {
    this.approveContactsImportTask.perform();
  }

  @action
  rejectContactsImport() {
    this.rejectContactsImportTask.perform();
  }

  @action
  cancelSetRole() {
    this.set('setRole', false);
  }

  @action
  doUpdateRole(party) {
    this.get('updateRoleTask').perform(party);
  }

  @action
  updateRole(party) {
    if (!this.get('selectedItems')) {
      this.set('selectedItems', [party]);
    }

    this.set('setRole', party);
  }

  @action
  removeContacts(party) {
    this.get('removeContactTask').perform(party);
  }

  @action
  onFileImported(importAction) {
    // set the import action and proceed to import review
    this.set('importAction', importAction);

    this.prepareContactsReview.perform();
  }

  @action
  process() {
    this.processTask.perform();
  }
}
