import JSONAPISerializer from '@ember-data/serializer/json-api';
import { pluralize } from 'ember-inflector';

export default JSONAPISerializer.extend({
  /*
  modelNameFromPayloadKey(key) {
      console.log("KEY", key);
      return this._super(key);
  },
  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload.data.type = primaryModelClass.modelName;        
      return this._super(store, primaryModelClass, payload, id, requestType);
  },
  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      for (let a = 0; a < payload.data.length; a++) {
          //payload.data[a].type = pluralize(primaryModelClass.modelName);
          payload.data[a].type = primaryModelClass.modelName;
          //console.log("TYPE: ", pluralize(primaryModelClass.modelName), payload.data[a]);
      }
      let result = this._super(store, primaryModelClass, payload, id, requestType);
      console.log("PAYLOAD: ", result);
      return result;
  },       
  */
});
