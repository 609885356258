import JSONAPISerializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class CaseSerializer extends JSONAPISerializer {
  attrs = {
    debtorProfile: { serialize: false },
    modifyTime: { serialize: false },
    createTime: { serialize: false },
    dateLastFiling: { serialize: false },
    associatedCaseCount: { serialize: false },
    docketCount: { serialize: false },

    //debtorCompany: { serialize: false},
    //court: {serialize: false},

    // 18.02.2019 TEMPORARY FIX FOR API ERROR, saving judge does not work
    judge: { serialize: false },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    case: CaseSerializer;
  }
}
