import SmartroomAdapter from './smartroom';

import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class SiteAdapter extends SmartroomAdapter {
  @service smartroom;

  urlForFindAll() {
    let host = this.get('host');
    let namespace = this.get('namespace');

    return `${host}/${namespace}/my-sites`;
  }

  getBySlug(slug) {
    let host = this.get('host');
    let namespace = this.get('namespace');

    let url = `${host}/${namespace}/slug/` + slug;

    return this.smartroom.fetchWrapper(url);
  }
}
