import classic from 'ember-classic-decorator';
import Component from "@ember/component";
import { task } from "ember-concurrency";

@classic
export default class ColumnEmails extends Component {
  init() {
    super.init();
    this.initTask.perform();
  }

  @task(function* () {
    let person = yield this.get('record.person');

    if (person) {
      let emails = yield person.get('emails');

      let emailAddresses = emails.mapBy('emailAddress');
      this.set('emails', emailAddresses.join(", "));
    }
  })
  initTask;
}