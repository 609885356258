const CaseStatus = {
  Open: 1,
  Closed: 2,
  Dismissed: 3,
  Reopened: 4,
  Transferred: 5,
};

const EventType = {
  Milestone: 1,
  CaseEvent: 2,
  Announcement: 3,
  Task: 4,

  Call: 20,
  Meeting: 21,
  Appointment: 22,
  Task: 23,
  Request: 24,
};

const EventPriority = {
  Low: 1,
  Medium: 2,
  High: 3,
};

const EReportStatus = {
  Awaiting: 10,
  Dropped: 20,
  InProgress: 30,
  Unauthorized: 40,
  Expired: 50,
  Terminated: 70,
  Error: 80,
  Finished: 90,

  CrmImportReadyForReview: 201,
};

const ReportStatus = {
  Awaiting: EReportStatus.Awaiting,
  Dropped: EReportStatus.Dropped,
  'In progress': EReportStatus.InProgress,
  Unauthorized: EReportStatus.Unauthorized,
  Expired: EReportStatus.Expired,
  Terminated: EReportStatus.Terminated,
  Error: EReportStatus.Error,
  Finished: EReportStatus.Finished,
};

const EntityTypeMap = {
  DOCKET: 1,
  CASE: 2,
  PERSON: 3,
  ADDRESS: 4,
};

const EntityType = {
  Docket: EntityTypeMap.DOCKET,
  Case: EntityTypeMap.CASE,
  Person: EntityTypeMap.PERSON,
  Address: EntityTypeMap.ADDRESS,
};

const DocketRatingMap = {
  GENERAL: 1,
  PROCEDURAL: 2,
  ADMINISTRATIVE: 3,
  IMPORTANT: 4,
};

const DocketRating = {
  General: DocketRatingMap.GENERAL,
  Procedural: DocketRatingMap.PROCEDURAL,
  Administrative: DocketRatingMap.ADMINISTRATIVE,
  Important: DocketRatingMap.IMPORTANT,
};

const CaseProjectUrlMap = {
  EXTERNAL_URL: 1,
  HIGHLIGHT_URL: 2,
  PUBLISHED_CI_URL: 3,
  SMARTROOM_ID: 4,
  CLAIMS_LIST: 100,
};

const CaseProjectUrl = {
  'External Url': CaseProjectUrlMap.EXTERNAL_URL,
  'Highlight Url': CaseProjectUrlMap.HIGHLIGHT_URL,
  'Published Url': CaseProjectUrlMap.PUBLISHED_CI_URL,
  SmartRoom: CaseProjectUrlMap.SMARTROOM_ID,
};

const CaseTypeMap = {
  NONE: null,
  BUSINESS: 1,
  INDIVIDUAL: 2,
  OTHER: 3,
};

const CaseProjectUrlOpenModeMap = {
  IFRAME: 1,
  REPLACE_PARENT: 2,
  NEW_TAB: 3,
  NEW_WINDOW: 4,
};

const CaseProjectUrlOpenMode = {
  IFrame: CaseProjectUrlOpenModeMap.IFRAME,
  'Replace Parent': CaseProjectUrlOpenModeMap.REPLACE_PARENT,
  'New Tab': CaseProjectUrlOpenModeMap.NEW_TAB,
  'New Window': CaseProjectUrlOpenModeMap.NEW_WINDOW,
};

const CaseType = {
  '': CaseTypeMap.NONE,
  Business: CaseTypeMap.BUSINESS,
  Individual: CaseTypeMap.INDIVIDUAL,
  Other: CaseTypeMap.OTHER,
};

const CaseAssociationTypes = {
  NONE: null,
  ADVERSARY: 1,
  LEAD_CASE: 2,
  JOINTLY_ADMINISTERED: 3,
};

const CaseAssociationType = {
  ' ': CaseAssociationTypes.NONE,
  Adversary: CaseAssociationTypes.ADVERSARY,
  'Lead Case': CaseAssociationTypes.LEAD_CASE,
  'Jointly Admin.': CaseAssociationTypes.JOINTLY_ADMINISTERED,
};

const ActionTypeMap = {
  ImportPartiesToProject: 'ImportPartiesToProject',
  UploadImage: 'UploadImage',
  CreateSmartRoom: 'CreateSmartRoom',
  SearchRoomFile: 'SearchRoomFile',
  UspsValidate: 'UspsValidate',
  SendEmail: 'SendEmail',
  Alert: 'Alert',
  DomainList: 'DomainList',
  DataExport: 'DataExport',

  GrabReport: 'GrabReport',
  ReprocessReport: 'ReprocessReport',
  FreeLookEmail: 'FreeLookEmail',
  DownloadDocket: 'DownloadDocket',
  DeleteDocket: 'DeleteDocket',
  ReprocessResultOfAction: 'ReprocessResultOfAction',
  SetClaimAgent: 'SetClaimAgent',
  ActionCRM: 'ActionCRM',
  ActionOnCase: 'ActionOnCase',
  CustomDocketUpload: 'CustomDocketUpload',
  CustomFileUpload: 'CustomFileUpload',
  Domain: 'Domain',
};

const ActionStatusMap = {
  Continue: 'Continue',
  Awaiting: 'Awaiting',
  Dropped: 'Dropped',
  Sending: 'Sending',
  InProgress: 'InProgress',
  Unauthorized: 'Unauthorized',
  Expired: 'Expired',
  Terminated: 'Terminated',
  Error: 'Error',
  Finished: 'Finished',
  InvalidData: 'InvalidData',
  NotAvailable: 'NotAvailable',
  Timeout3rdParty: 'Timeout3rdParty',

  // CING specific statuses
  Grabbing: 'Grabbing',
  Grabbed: 'Grabbed',
  Saving: 'Saving',
  Parsed: 'Parsed',
  Parsing: 'Parsing',
  Saved: 'Saved',
  Analyzing: 'Analyzing',

  DocketDetailsRetrieving: 'DocketDetailsRetrieving',
  DocketDetailsRetrieved: 'DocketDetailsRetrieved',
  PreparingForSplit: 'PreparingForSplit',
  SplittedPdf: 'SplittedPdf',
  PreparingForCombine: 'PreparingForCombine',
  CombinedPdfs: 'CombinedPdfs',
  MainDocketReady: 'MainDocketReady',
  DownloadingList: 'DownloadingList',
  DownloadedList: 'DownloadedList',

  // SmartExchange specific statuses
  ProcessingFile: 'ProcessingFile',
  FileConverted: 'FileConverted',
  WaitingForUpload: 'WaitingForUpload',
  ImportReadyForReview: 'ImportReadyForReview',
  PreparingImportPreview: 'PreparingImportPreview',
};

const GrabReportTypeMap = {
  Attorneys: 1,
  DeadlineHearings: 2,
  Dockets: 3,
  AssociatedCases: 4,
  Emails: 5,
  FreeLook: 6,

  AttorneysReprocess: 11,
  DeadlineHearingsReprocess: 12,
  DocketsReprocess: 13,
  AssociatedCasesReprocess: 14,

  DocketContent: 100,
  DocketFoldersRebuild: 501,
};

const GrabReportType = {
  Attorneys: GrabReportTypeMap.Attorneys,
  'Attorneys (R)': GrabReportTypeMap.AttorneysReprocess,
  Deadlines: GrabReportTypeMap.DeadlineHearings,
  'Deadlines (R)': GrabReportTypeMap.DeadlineHearingsReprocess,
  Docket: GrabReportTypeMap.Dockets,
  'Docket (R)': GrabReportTypeMap.DocketsReprocess,
  Associated: GrabReportTypeMap.AssociatedCases,
  'Associated (R)': GrabReportTypeMap.AssociatedCasesReprocess,
  //'Emails': GrabReportTypeMap.Emails,
  'Free Look': GrabReportTypeMap.FreeLook,
  'Document Download': GrabReportTypeMap.DocketContent,
  'Rebuild Folders': GrabReportTypeMap.DocketFoldersRebuild,
};

const GrabReportSourceType = {
  ECF: 1,
  ClaimsAgent: 2,
};

const CompanyTypeMap = {
  LawFirm: 1,
  PortfolioCompany: 10,
};

const CompanyType = {
  'Law Firm': CompanyTypeMap.LawFirm,
  'Portfolio Company': CompanyTypeMap.PortfolioCompany,
};

const CompanyTypeList = Object.keys(CompanyType).map((k) => {
  return {
    id: CompanyType[k],
    name: k,
  };
});

const SourceType = {
  Report: 1,
  RSS: 2,
  Manual: 3,
};

const AlertTypeMap = {
  CaseFiled: 1,
  DocketFiled: 2,
};
const AlertType = {
  'Case Filed': AlertTypeMap.CaseFiled,
  'Docket Filed': AlertTypeMap.DocketFiled,
};
const AlertTypeList = Object.keys(AlertType).map((k) => {
  return {
    id: AlertType[k],
    name: k,
  };
});

const AlertTriggerTypeMap = {
  CaseFiled: 1,
  CasePlanFiled: 2,
  CaseUpdated: 3,
  DocketFiled: 20,
};
const AlertTriggerType = {
  'Case Filed': AlertTriggerTypeMap.CaseFiled,
  'Case Plan Filed': AlertTriggerTypeMap.CasePlanFiled,
  'Case Updated': AlertTriggerTypeMap.CaseUpdated,
  'Docket Filed': AlertTriggerTypeMap.DocketFiled,
};
const AlertTriggerTypeList = Object.keys(AlertTriggerType).map((k) => {
  return {
    id: AlertTriggerType[k],
    name: k,
  };
});

const AlertRecipientType = {
  User: 1,
  Email: 2,
};

const AlertStatus = {
  Open: 0,
  Ready: 1,
  Dispatched: 2,
  Processed: 5,
  NotSent: 9,
  Sent: 10,
  Error: 20,
};

const AlertDelivery = {
  Email: 1,
  OnScreen: 2,
};

const AlertFrequencyMap = {
  Instant: 1,
  Hourly: 2,
  Daily: 3,
  Weekly: 4,
  Monthly: 5,
  OneTime: 6,
};
const AlertFrequency = {
  Instant: AlertFrequencyMap.Instant,
  Hourly: AlertFrequencyMap.Hourly,
  Daily: AlertFrequencyMap.Daily,
  Weekly: AlertFrequencyMap.Weekly,
  Monthly: AlertFrequencyMap.Monthly,
  'One time only': AlertFrequencyMap.OneTime,
};
const AlertFrequencyList = Object.keys(AlertFrequency).map((k) => {
  return {
    id: AlertFrequency[k],
    name: k,
  };
});

const AlertParameterTypeMap = {
  From: 1,
  To: 2,
  Subject: 3,
  Message: 4,
  CaseIdFilter: 10,
  CaseCourtFilter: 11,
  CaseNameFilter: 12,
  CaseChapterFilter: 13,
  CaseTypeFilter: 14,
  //DocketCaseFilter, = CaseIdFilter
  DocketDescriptionFilter: 15,
  DocketConsolidatedTypeFilter: 16,
  JSON: 17,
};
const AlertParameterType = {
  From: AlertParameterTypeMap.From,
  To: AlertParameterTypeMap.To,
  Subject: AlertParameterTypeMap.Subject,
  Message: AlertParameterTypeMap.Message,
  'Case Id Filter': AlertParameterTypeMap.CaseIdFilter,
  'Case Court Filter': AlertParameterTypeMap.CaseCourtFilter,
  'Case Name Filter': AlertParameterTypeMap.CaseNameFilter,
  'Case Chapter Filter': AlertParameterTypeMap.CaseChapterFilter,
  'Case Type Filter': AlertParameterTypeMap.CaseTypeFilter,
  //DocketCaseFilter, = CaseIdFilter
  'Docket Description Filter': AlertParameterTypeMap.DocketDescriptionFilter,
  'Docket Consolidated Type Filter':
    AlertParameterTypeMap.DocketConsolidatedTypeFilter,
  JSON: AlertParameterTypeMap.JSON,
};

const PartyRoleTypes = {
  INTERESTED_PARTY: 1,
  RETAINED_PROFESSIONAL: 2,
  //LARGEST_UNSECURED: 3,
  //REPRESENTING_INTERESTED_PARTY: 4,
};

const PartyRole = {
  Participant: PartyRoleTypes.INTERESTED_PARTY,
  'Working Group': PartyRoleTypes.RETAINED_PROFESSIONAL,
  //"Largest Unsecured": PartyRoleTypes.LARGEST_UNSECURED,
  //"Representing Interested Party": PartyRoleTypes.REPRESENTING_INTERESTED_PARTY,
};

const PartyTypes = {
  CREDITOR: 1,
  DEBTOR: 2,
  PETITIONING_CREDITOR: 3,
  CREDITOR_COMMITTEE: 4,
  US_TRUSTEE: 5,
  CLAIMS_AND_NOTICING_AGENT: 6,
  CLAIMS_AGENT: 7,
};

const PartyType = {
  Creditor: PartyTypes.CREDITOR,
  Debtor: PartyTypes.DEBTOR,
  'Petitioning Creditor': PartyTypes.PETITIONING_CREDITOR,
  'Creditor Committee': PartyTypes.CREDITOR_COMMITTEE,
  'US Trustee': PartyTypes.US_TRUSTEE,
  'Claims and Noticing Agent': PartyTypes.CLAIMS_AND_NOTICING_AGENT,
  'Claims Agent': PartyTypes.CLAIMS_AGENT,
};

const NoticeTypes = {
  NOTICE_BY_EMAIL: 0,
  UNABLE_TO_NOTICE_BY_EMAIL: 1,
  NOTICES_TURNED_OFF: 2,
};

const Notice = {
  'Yes/E-mail': NoticeTypes.NOTICE_BY_EMAIL,
  No: NoticeTypes.UNABLE_TO_NOTICE_BY_EMAIL,
  Off: NoticeTypes.NOTICES_TURNED_OFF,
};

const DebtorAssetsTypes = [
  {
    id: 1,
    name: '$0-$50,000',
    short: '<$50k',
    low: '$0',
    high: '$50,000',
  },
  {
    id: 2,
    name: '$50,001 to $100,000',
    short: '<$100k',
    low: '$50,001',
    high: '$100,000',
  },
  {
    id: 3,
    name: '$100,001 to $500,000',
    short: '<$500k',
    low: '$100,001',
    high: '$500,000',
  },
  {
    id: 4,
    name: '$500,001 to $1 million',
    short: '<$1m',
    low: '$500,001',
    high: '$1 million',
  },
  {
    id: 5,
    name: '$1,000,001 to $10 million',
    short: '<$10m',
    low: '$1,000,001',
    high: '$10 million',
  },
  {
    id: 6,
    name: '$10,000,001 to $50 million',
    short: '<$50m',
    low: '$10,000,001',
    high: '$50 million',
  },
  {
    id: 7,
    name: '$50,000,001 to $100 million',
    short: '<$100m',
    low: '$50,000,001',
    high: '$100 million',
  },
  {
    id: 8,
    name: '$100,000,001 to $500 million',
    short: '<$500m',
    low: '$100,000,001',
    high: '$500 million',
  },
  {
    id: 9,
    name: '$500,000,001 to $1 billion',
    short: '<$1b',
    low: '$500,000,001',
    high: '$1 billion',
  },
  {
    id: 10,
    name: '$1 billion to $10 billion',
    short: '<$10b',
    low: '$1 billion',
    high: '$10 billion',
  },
  {
    id: 11,
    name: '$10 billion to $50 billion',
    short: '<$50',
    low: '$10 billion',
    high: '$50 billion',
  },
  {
    id: 12,
    name: 'More than $50 billion',
    short: '>$50',
    low: '$50 billion',
    high: null,
  },
];

const CreditorsTypes = [
  {
    id: 1,
    name: '1 to 49',
    low: '1',
    high: '49',
  },
  {
    id: 2,
    name: '50 to 99',
    low: '50',
    high: '99',
  },
  {
    id: 3,
    name: '100 to 199',
    low: '100',
    high: '199',
  },
  {
    id: 4,
    name: '200 to 999',
    low: '200',
    high: '999',
  },
  {
    id: 5,
    name: '1,000 to 5,000',
    low: '1,000',
    high: '5,000',
  },
  {
    id: 6,
    name: '5,001 to 10,000',
    low: '5,001',
    high: '10,000',
  },
  {
    id: 7,
    name: '10,001 to 25,000',
    low: '10,001',
    high: '25,000',
  },
  {
    id: 8,
    name: '25,001 to 50,000',
    low: '25,001',
    high: '50,000',
  },
  {
    id: 9,
    name: '50,001 to 100,000',
    low: '50,001',
    high: '100,000',
  },
  {
    id: 10,
    name: 'Over 100,000',
    low: '100,000',
    high: null,
  },
];

const LiabilitiesTypes = [
  {
    id: 1,
    name: '$0-$50,000',
    short: '<$50k',
    low: '$0',
    high: '$50,000',
  },
  {
    id: 2,
    name: '$50,001 to $100,000',
    short: '<$100k',
    low: '$50,001',
    high: '$100,000',
  },
  {
    id: 3,
    name: '$100,001 to $500,000',
    short: '<$500k',
    low: '$100,001',
    high: '$500,000',
  },
  {
    id: 4,
    name: '$500,001 to $1 million',
    short: '<$1m',
    low: '$500,001',
    high: '$1 million',
  },
  {
    id: 5,
    name: '$1,000,001 to $10 million',
    short: '<$10m',
    low: '$1,000,001',
    high: '$10 million',
  },
  {
    id: 6,
    name: '$10,000,001 to $50 million',
    short: '<$50m',
    low: '$10,000,001',
    high: '$50 million',
  },
  {
    id: 7,
    name: '$50,000,001 to $100 million',
    short: '<$100m',
    low: '$50,000,001',
    high: '$100 million',
  },
  {
    id: 8,
    name: '$100,000,001 to $500 million',
    short: '<$500m',
    low: '$100,000,001',
    high: '$500 million',
  },
  {
    id: 9,
    name: '$500,000,001 to $1 billion',
    short: '<$1b',
    low: '$500,000,001',
    high: '$1 billion',
  },
  {
    id: 10,
    name: '$1 billion to $10 billion',
    short: '<$10b',
    low: '$1 billion',
    high: '$10 billion',
  },
  {
    id: 11,
    name: '$10 billion to $50 billion',
    short: '<$50',
    low: '$10 billion',
    high: '$50 billion',
  },
  {
    id: 12,
    name: 'More than $50 billion',
    short: '>$50',
    low: '$50 billion',
    high: null,
  },
];

const CDPCaseTypes = {
  PRIOR: 1,
  PENDING: 2,
};

const CDPCaseTypeEnum = {
  Prior: CDPCaseTypes.PRIOR,
  Pending: CDPCaseTypes.PENDING,
};

const AddressTypes = {
  Standard: 1,
  POBox: 2,
  MailingAddress: 3,
  PrincipalAssetAddress: 4,
  PrincipalPlaceOfBusiness: 5,
  Headquarters: 6,
};

const AddressTypesEnum = {
  Address: AddressTypes.Standard,
  'P.O.Box': AddressTypes.POBox,
  'Mailing Address': AddressTypes.MailingAddress,
  'Principal Assets Address': AddressTypes.PrincipalAssetAddress,
  'Principal Place of Business': AddressTypes.PrincipalPlaceOfBusiness,
  Headquarters: AddressTypes.Headquarters,
};

const PhoneTypes = {
  Preferred: 0,
  Work: 1,
  Home: 2,
  Voice: 3,
  Fax: 4,
  Message: 5,
  Cell: 6,
  Pager: 7,
  Bbs: 8,
  Modem: 9,
  Car: 10,
  Isdn: 11,
  Video: 12,
  Personal: 13,
  Headquarters: 14,
  Mobile: 15,
  Businessphone: 16,
};

const PhoneTypesEnum = {
  Headquarters: PhoneTypes.Headquarters,
  //'Preferred': PhoneTypes.Preferred,
  Work: PhoneTypes.Work,
  Home: PhoneTypes.Home,
  //'Voice': PhoneTypes.Voice,
  Fax: PhoneTypes.Fax,
  //'Message': PhoneTypes.Message,
  //'Cell': PhoneTypes.Cell,
  //'Pager': PhoneTypes.Pager,
  //'Bbs': PhoneTypes.Bbs,
  //'Modem': PhoneTypes.Modem,
  //'Car': PhoneTypes.Car,
  //'Isdn': PhoneTypes.Isdn,
  //'Video': PhoneTypes.Video,
  Personal: PhoneTypes.Personal,
  Mobile: PhoneTypes.Mobile,
  Business: PhoneTypes.Businessphone,
};

const EmailTypes = {
  Unknown: 0,
  IsLoginEmail: 1 << 1,
  NamedEmail: 1 << 2,
  FirmEmail: 1 << 3,
  Private: 1 << 4,
  Primary: 1 << 10,
};

const DebtorTypes = {
  Corporation: 1 << 1, // 2
  Partnership: 1 << 2, // 4
  Other: 1 << 3, // 8
};

const DebtorTypesEnum = {
  Corporation: DebtorTypes.Corporation,
  Partnership: DebtorTypes.Partnership,
  Other: DebtorTypes.Other,
};

const DebtorBusinessTypeALookup = {
  NoneOfTheAbove: 0,
  HealthCareBusiness: 1,
  SingleAssetRealEstate: 2,
  Railroad: 3,
  Stockbroker: 4,
  CommodityBroker: 5,
  ClearingBank: 6,
};

const DebtorBusinessTypeA = {
  HealthCareBusiness: {
    id: DebtorBusinessTypeALookup.HealthCareBusiness,
    name: 'Health Care Business',
  },
  SingleAssetRealEstate: {
    id: DebtorBusinessTypeALookup.SingleAssetRealEstate,
    name: 'Single Asset Real Estate',
  },
  Railroad: {
    id: DebtorBusinessTypeALookup.Railroad,
    name: 'Railroad',
  },
  Stockbroker: {
    id: DebtorBusinessTypeALookup.Stockbroker,
    name: 'Stockbroker',
  },
  CommodityBroker: {
    id: DebtorBusinessTypeALookup.CommodityBroker,
    name: 'Commodity Broker',
  },
  ClearingBank: {
    id: DebtorBusinessTypeALookup.ClearingBank,
    name: 'Clearing Bank',
  },
  NoneOfTheAbove: {
    id: DebtorBusinessTypeALookup.NoneOfTheAbove,
    name: 'None of the above',
  },
};

/*
const DebtorBusinessTypeB = {
    TaxExemptEntity:    { id: 1, name: 'Tax-exempt entity (as described in 26 U.S.C. § 501)' },
    InvestmentCompany:  { id: 2, name: 'Investment company, including hedge fund or pooled investment vehicle (as defined in 15 U.S.C.§ 80a-3)' },
    InvestmentAdvisor:  { id: 4, name: 'Investment advisor (as defined in 15 U.S.C. § 80b-2(a)(11))' },
}
*/

const DebtorBusinessTypeBIntMap = {
  TaxExemptEntity: 1 << 0,
  InvestmentCompany: 1 << 1,
  InvestmentAdvisor: 1 << 2,
};

const DebtorBusinessTypeB = {
  TaxExemptEntity: {
    id: DebtorBusinessTypeBIntMap.TaxExemptEntity,
    name: 'Tax-exempt entity',
  },
  InvestmentCompany: {
    id: DebtorBusinessTypeBIntMap.InvestmentCompany,
    name: 'Investment company',
  },
  InvestmentAdvisor: {
    id: DebtorBusinessTypeBIntMap.InvestmentAdvisor,
    name: 'Investment advisor',
  },
};

const CDPChapterTypeIntMap = {
  Chapter7: 1,
  Chapter9: 2,
  Chapter11: 3,
  Chapter12: 4,
};

const CDPChapterTypes = {
  Chapter7: 1,
  Chapter9: 2,
  Chapter11: 3,
  Chapter12: 4,
};

const CDPChapters = {
  Chapter7: {
    id: CDPChapterTypes.Chapter7,
    name: 'Chapter 7',
  },
  Chapter9: {
    id: CDPChapterTypes.Chapter9,
    name: 'Chapter 9',
  },
  Chapter11: {
    id: CDPChapterTypes.Chapter11,
    name: 'Chapter 11',
  },
  Chapter12: {
    id: CDPChapterTypes.Chapter12,
    name: 'Chapter 12',
  },
};

const ChapterMap = {
  Chapter7: 7,
  Chapter9: 9,
  Chapter11: 11,
  Chapter13: 13,
  Chapter15: 15,
};

const Chapters = [
  {
    id: 7,
    name: 'Chapter 7',
  },
  {
    id: 9,
    name: 'Chapter 9',
  },
  {
    id: 11,
    name: 'Chapter 11',
  },
  {
    id: 13,
    name: 'Chapter 13',
  },
  {
    id: 15,
    name: 'Chapter 15',
  },
];

const Chapter11SubType = {
  Noncontingent: {
    id: 1 << 1,
    name: "Debtor's aggregate noncontingent liquidated debts",
    label: 'Small Debt',
  },
  SmallBusiness: {
    id: 1 << 2,
    name: 'The debtor is a small business debtor',
    label: 'Small Business',
  },
  PlanFiled: {
    id: 1 << 3,
    name: 'A plan is being filed with this petition',
    label: 'Plan',
  },
  PlanAcceptance: {
    id: 1 << 4,
    name: 'Acceptances of the plan were solicited prepetition from one or more classes of creditors',
    label: 'Pre-Pack',
  }, // pre-pack
  Periodic: {
    id: 1 << 5,
    name: 'The debtor is required to file periodic reports with the Securities and Exchange Commission',
    label: 'Public Company',
  },
  ShellCompany: {
    id: 1 << 6,
    name: 'The debtor is a shell company',
    label: 'Shell Company',
  },
};

const ChapterTypeMap = {
  Corporate: 1,
  Individual: 2,
};

const ChapterTypes = [
  {
    id: ChapterTypeMap.Corporate,
    name: 'Corporate',
  },
  {
    id: ChapterTypeMap.Individual,
    name: 'Individual',
  },
];

const CorporateChapterTypeMap = {
  Public: 1,
  NonPublic: 2,
  SmallBusiness: 3,
  PrePack: 4,
};

const CorporateChapterTypes = [
  {
    id: CorporateChapterTypeMap.Public,
    name: 'Public',
  },
  {
    id: CorporateChapterTypeMap.NonPublic,
    name: 'Non Public',
  },
  {
    id: CorporateChapterTypeMap.SmallBusiness,
    name: 'Small Business',
    bit: Chapter11SubType.SmallBusiness.id,
  },
  {
    id: CorporateChapterTypeMap.PrePack,
    name: 'Pre-pack',
    bit: Chapter11SubType.PlanAcceptance.id,
  },
];

const ReasonFiledInDistrictTypeIntMap = {
  Domicile180Days: 1 << 1,
  HasAffiliateCase: 1 << 2,
};

const ReasonFiledInDistrictType = {
  Domicile180Days: {
    id: ReasonFiledInDistrictTypeIntMap.Domicile180Days,
    name: 'Debtor has had its domicile, principal place of business, or principal assets in this district for 180 days immediately preceding the date of this petition or for a longer part of such 180 days than in any other district.',
  },
  HasAffiliateCase: {
    id: ReasonFiledInDistrictTypeIntMap.HasAffiliateCase,
    name: "A bankruptcy case concerning debtor's affiliate, general partner, or partnership is pending in this district.",
  },
};

const EstimationOfFundsTypeIntMap = {
  Available: 1,
  NotAvailable: 2,
};

const EstimationOfFundsType = {
  Available: {
    id: EstimationOfFundsTypeIntMap.Available,
    name: 'Funds will be available for distribution to unsecured creditors',
  },
  NotAvailable: {
    id: EstimationOfFundsTypeIntMap.NotAvailable,
    name: 'After any administrative expenses are paid, no funds will be available for distribution to unsecured creditors',
  },
};

const SetClaimProvidersMap = {
  None: null,
  Unsupported: 0,
  PrimeClerk: 1,
  KCC: 2,
  Epiq: 3,
  BMC: 4,
  Stretto: 5,
};

const SetClaimProviders = {
  None: SetClaimProvidersMap.None,
  Unsupported: SetClaimProvidersMap.Unsupported,
  'Prime Clerk': SetClaimProvidersMap.PrimeClerk,
  KCC: SetClaimProvidersMap.KCC,
  Epiq: SetClaimProvidersMap.Epiq,
  BMC: SetClaimProvidersMap.BMC,
  Stretto: SetClaimProvidersMap.Stretto,
};

const ClaimsAgentTypesMap = {
  PrimeClerk: 1,
  KCC: 2,
  Epiq: 3,
  BMC: 4,
  Stretto: 5,
};

const ClaimsAgentTypes = {
  'Prime Clerk': ClaimsAgentTypesMap.PrimeClerk,
  KCC: ClaimsAgentTypesMap.KCC,
  Epiq: ClaimsAgentTypesMap.Epiq,
  'BMC Group': ClaimsAgentTypesMap.BMC,
  Stretto: ClaimsAgentTypesMap.Stretto,
};

const ClaimsAgentImage = function (id) {
  switch (id) {
    case ClaimsAgentTypesMap.PrimeClerk:
      return '/images/claims_agents/1.png';
    case ClaimsAgentTypesMap.KCC:
      return '/images/claims_agents/2.png';
    case ClaimsAgentTypesMap.Epiq:
      return '/images/claims_agents/3.png';
    case ClaimsAgentTypesMap.BMC:
      return '/images/claims_agents/4.png';
  }
};

const PersonInCompanyFlags = {
  FORMER: 1 << 1,
  PRIMARY: 1 << 2,
};

const DocketGroups = [
  'Abandonment',
  'Adversary Proceedings',
  'Appeals',
  'Case Status',
  'Claims',
  'Complaints',
  'Contracts/Leases',
  'Correspondence',
  "Creditors' Committee",
  'Depositions',
  'Dismiss Case',
  'Distribution',
  'Employ/Retain/Appoint',
  'First Day Motions/Orders',
  'First Days',
  'Joinders',
  'Motions',
  'Noticing',
  'Objections',
  'Orders',
  'Orders/Stipulations',
  'Ordes',
  'Plan',
  'Plan',
  'Proceduaral',
  'Proposed Orders',
  'Relief from Stay',
  'Reports',
  'Response/Reply',
  'Sale',
  'Schedules',
];

const DocketSubGroups = [
  'Affidavits/Declarations',
  'Appeals',
  'Appearances',
  'Certifications',
  'Claims',
  'Close/Convert/Dismiss Case',
  'Complaints',
  'Correspondence',
  'Deadlines',
  'Deficiencies',
  'Depositions',
  'Dismiss Case',
  'Distribution',
  'Employ/Retain/Appoint',
  'Fees/Compensation',
  'Final Decree',
  'Financial Reports',
  'Hearings',
  'Interested Parties',
  'Joinders',
  'Joint Administration',
  'Mailing Lists',
  'Motions',
  'Objections',
  'Operating Reports',
  'Orders',
  'Orders/Stipulations',
  'Petitions',
  'Proof of Service',
  'Proposed Orders',
  'Redact/Seal',
  'Relief from Stay',
  'Reports',
  'Response/Reply',
  'Sale',
  'Schedules',
  'Solicitation',
  'Status Reports',
  'Transcripts/Minutes',
  'Withdrawn Documents',
];

const CaseFilters = {
  CASE_TYPE: 'caseType',
  INCLUDE_ASSOCIATED_CASES: 'include_associated_cases',
  SELECTED_CHAPTERS: 'selectedChapters',
  SELECTED_CHAPTER_TYPES: 'selectedChapterTypes',
  SELECTED_COURTS: 'selectedCourts',
  SELECTED_INDUSTRY_TYPES: 'selectedIndustryTypes',
  INDUSTRY: 'industry',
  FLAG_MEGA: 'flagMega',
  HAS_CLAIMS_AGENT: 'hasClaimsAgent',
  FUNDS_AVAILABLE: 'fundsAvailable',
  JOINT_ADMIN: 'jointAdmin',
  SCHEDULES: 'schedules',
  ASSET_TYPE_START: 'assetTypeStart',
  ASSET_TYPE_END: 'assetTypeEnd',
  DEBT_TYPE_START: 'debtTypeStart',
  DEBT_TYPE_END: 'debtTypeEnd',
  CREDITOR_TYPE_START: 'creditorTypeStart',
  CREDITOR_TYPE_END: 'creditorTypeEnd',
  CHAPTER_TYPE_INDIVIDUAL: 'chapterTypeIndividual',
  CHAPTER_TYPE_CORPORATE: 'chapterTypeCorporate',
  WATCHLIST_ONLY: 'watchlist_only',
  SEARCH_QUERY: 'searchQuery',
  DATE_PROPERTY: 'date_property',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

const CaseFilterKeys = Object.values(CaseFilters);

const ProjectFilters = {
  PROJECT_TYPE: 'projectType',
  PROJECT_PRIORITY: 'projectPriority',
  PROJECT_STATUS: 'projectStatus',
  PROJECT_LEAD: 'projectLead',
  INDUSTRY: 'industry',
  WATCHLIST_ONLY: 'watchlist_only',
  SEARCH_QUERY: 'searchQuery',
  DATE_PROPERTY: 'date_property',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

let ProjectFilterNames = {};
ProjectFilterNames[ProjectFilters.PROJECT_TYPE] = 'Type';
ProjectFilterNames[ProjectFilters.PROJECT_PRIORITY] = 'Priority';
ProjectFilterNames[ProjectFilters.PROJECT_STATUS] = 'Status';
ProjectFilterNames[ProjectFilters.PROJECT_LEAD] = 'Lead';

const ProjectFilterKeys = Object.values(ProjectFilters);

const ProjectTabMap = {
  DETAILS: 1,
  COMPANY_PROFILE: 2,
  INQUIRIES: 3,
  CALENDAR: 4,
  PARTIES: 5,
  INVESTORS: 6,
  EXTERNAL_URL: 7,
  SMARTROOM: 8,
  INVESTMENT_DETAIL: 9,
  TRANSACTIONS_HISTORY: 10,
  PORTFOLIO_COMPANIES: 11,
  AB_PROJECT: 12,
  PROJECT_MANAGEMENT_LITE: 13,
  CUSTOM_VIEW: 14,
  TARGETS: 15,
  PROJECT_MANAGEMENT: 16,
  FAQS: 17,
  CUSTOM: 100,
};

const ProjectTabIcons = [];
ProjectTabIcons[ProjectTabMap.DETAILS] = 'info-circle';
ProjectTabIcons[ProjectTabMap.COMPANY_PROFILE] = 'industry';
ProjectTabIcons[ProjectTabMap.INQUIRIES] = 'envelope';
ProjectTabIcons[ProjectTabMap.CALENDAR] = 'calendar-alt';
ProjectTabIcons[ProjectTabMap.PARTIES] = 'info-circle';
ProjectTabIcons[ProjectTabMap.INVESTORS] = 'users';
ProjectTabIcons[ProjectTabMap.EXTERNAL_URL] = 'external-link-alt';
ProjectTabIcons[ProjectTabMap.SMARTROOM] = 'folder';
ProjectTabIcons[ProjectTabMap.CUSTOM] = '';
ProjectTabIcons[ProjectTabMap.PORTFOLIO_COMPANIES] = '';
ProjectTabIcons[ProjectTabMap.AB_PROJECT] = 'database';
ProjectTabIcons[ProjectTabMap.CUSTOM_VIEW] = '';
ProjectTabIcons[ProjectTabMap.PROJECT_MANAGEMENT] = 'tasks';
ProjectTabIcons[ProjectTabMap.PROJECT_MANAGEMENT_LITE] = 'tasks';
ProjectTabIcons[ProjectTabMap.TARGETS] = 'info-circle';
ProjectTabIcons[ProjectTabMap.FAQS] = 'question-circle';

const ProjectTab = {
  Details: ProjectTabMap.DETAILS,
  'Company Profile': ProjectTabMap.COMPANY_PROFILE,
  Investors: ProjectTabMap.INVESTORS,
  Contacts: ProjectTabMap.PARTIES,
  Calendar: ProjectTabMap.CALENDAR,
  'External Url': ProjectTabMap.EXTERNAL_URL,
  Documents: ProjectTabMap.SMARTROOM,
  Inquiries: ProjectTabMap.INQUIRIES,
  'Investment Detail': ProjectTabMap.INVESTMENT_DETAIL,
  'Transactions History': ProjectTabMap.TRANSACTIONS_HISTORY,
  'Portfolio Companies': ProjectTabMap.PORTFOLIO_COMPANIES,
  'Project Management': ProjectTabMap.PROJECT_MANAGEMENT,
  'Project Management (Lite)': ProjectTabMap.PROJECT_MANAGEMENT_LITE,
  'AB Project': ProjectTabMap.AB_PROJECT,
  Targets: ProjectTabMap.TARGETS,
  'Custom View': ProjectTabMap.CUSTOM_VIEW,
  FAQs: ProjectTabMap.FAQS,
  Custom: ProjectTabMap.CUSTOM,
};

const ProjectTypeMap = {
  NONE: null,
  CLINICAL_TRIAL: 1,
  DIVESTURE: 2,
  DRUG_STUDY: 3,
  JOINT_VENTURE: 4,
  LEGAL: 5,
  LICENSING: 6,
  PORTFOLIO_COMPANY: 7,
  POST_MERGER_INTEGRATION: 8,
  RESEARCH_AND_DEVELOPMENT: 9,
  TARGET_ACQUISITION: 10,
  SELL_SIDE: 11,
  BUY_SIDE: 12,
  TARGET: 13,
  PORTFOLIO_EXIT: 14,
};

const ProjectType = {
  '': ProjectTypeMap.NONE,
  'Buy Side': ProjectTypeMap.BUY_SIDE,
  'Clinical Trial': ProjectTypeMap.CLINICAL_TRIAL,
  Divesture: ProjectTypeMap.DIVESTURE,
  'Drug Study': ProjectTypeMap.DRUG_STUDY,
  'Joint Venture': ProjectTypeMap.JOINT_VENTURE,
  Legal: ProjectTypeMap.LEGAL,
  Licensing: ProjectTypeMap.LICENSING,
  'Portfolio Company': ProjectTypeMap.PORTFOLIO_COMPANY,
  'Portfolio Exit': ProjectTypeMap.PORTFOLIO_EXIT,
  'Post-Merger Integration': ProjectTypeMap.POST_MERGER_INTEGRATION,
  'Research and Development': ProjectTypeMap.RESEARCH_AND_DEVELOPMENT,
  'Sell Side': ProjectTypeMap.SELL_SIDE,
  Target: ProjectTypeMap.TARGET,
  'Target Acquisition': ProjectTypeMap.TARGET_ACQUISITION,
};

const PortalPageMap = {
  TEXT: 1,
  PORTFOLIO_LIST: 2,
  PORTFOLIO_OVERVIEW: 3,
  //PROJECT_LIST: 3,
  //PROJECT_DETAIL: 4,
  EXTERNAL_URL: 5,
  SMARTROOM: 6,
  INQUIRIES: 7,
  FAQS: 8,
};

const PortalPage = {
  'Text Page': PortalPageMap.TEXT,
  'Portfolio List (for Style 1)': PortalPageMap.PORTFOLIO_LIST,
  'Overview (for Style 2)': PortalPageMap.PORTFOLIO_OVERVIEW,
  Documents: PortalPageMap.SMARTROOM,
  //"Project List": PortalPageMap.PROJECT_LIST,
  //"Project Detail (coming soon)": PortalPageMap.PROJECT_DETAIL,
  'External URL': PortalPageMap.EXTERNAL_URL,
  Inquiries: PortalPageMap.INQUIRIES,
  FAQs: PortalPageMap.FAQS,
};

const PortalPageProjectFilterMap = {
  TYPE: '1',
  STATUS: '5',
  FUND_TYPE: '2',
  CONTACT_FILTER: '3',
  FUND_STATUS: '4',
};

const PortalPageProjectFilter = {
  'Project - Type': PortalPageProjectFilterMap.TYPE,
  'Project - Status': PortalPageProjectFilterMap.STATUS,
  'Fund - Type': PortalPageProjectFilterMap.FUND_TYPE,
  'Fund - Status': PortalPageProjectFilterMap.FUND_STATUS,
};

const CustomContactsFilterMap = {
  TYPE: 'type',
  ROLE: 'role',
};

const CustomContactsFilter = {
  'Party - Type': CustomContactsFilterMap.TYPE,
  'Party - Role': CustomContactsFilterMap.ROLE,
};

const FieldFormat = {
  STRING: 'string',
  DATE: 'date',
  DATETIME: 'datetime',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  CUSTOM: 'custom',
};

const InterestedPartyFlagsMap = {
  NDA_SIGNED: 1 << 1,
};

const FundTypeMap = {
  LARGE_CAP_BUYOUT: 1,
};

const FundType = {
  'Large Cap Buyout': FundTypeMap.LARGE_CAP_BUYOUT,
};

const FundStatusMap = {
  CLOSED: 1,
  FUNDRAISING: 2,
};

const FundStatus = {
  Closed: FundStatusMap.CLOSED,
  Fundraising: FundStatusMap.FUNDRAISING,
};

const ProjectStatusMap = {
  DRAFT: 0,
  READY: 1,
  PLANNING: 2,
  EVALUATION: 3,
  NEGOTIATION: 4,
  DUE_DILIGENCE: 5,
  PURCHASE: 6,
  FINANCING: 7,
  IMPLEMENTATION: 8,
  TERMINATED: 9,
  COMPLETED: 10,
  UNDER_REVIEW: 11,
  IOI_SUBMITTED: 12,
  MANAGEMENT_PRESENTATION: 13,
  LETTER_OF_INTENT_SUBMITTED: 14,
  CLOSED: 15,
  PASSED: 16,
  LOST: 17,
};

const ProjectStatus = {
  Closed: ProjectStatusMap.CLOSED,
  Completed: ProjectStatusMap.COMPLETED,
  Draft: ProjectStatusMap.DRAFT,
  'Due Diligence': ProjectStatusMap.DUE_DILIGENCE,
  Evaluation: ProjectStatusMap.EVALUATION,
  Financing: ProjectStatusMap.FINANCING,
  'IOI Submitted': ProjectStatusMap.IOI_SUBMITTED,
  Implementation: ProjectStatusMap.IMPLEMENTATION,
  'Letter of Intent Submited': ProjectStatusMap.LETTER_OF_INTENT_SUBMITTED,
  Lost: ProjectStatusMap.LOST,
  'Managment Presentation': ProjectStatusMap.MANAGEMENT_PRESENTATION,
  Negotiation: ProjectStatusMap.NEGOTIATION,
  Passed: ProjectStatusMap.PASSED,
  Planning: ProjectStatusMap.PLANNING,
  Purchase: ProjectStatusMap.PURCHASE,
  Ready: ProjectStatusMap.READY,
  Terminated: ProjectStatusMap.TERMINATED,
  'Under Review': ProjectStatusMap.UNDER_REVIEW,
};

const ContactTypeMap = {
  NONE: null,
  INDIVIDUAL: 1,
  ENTITY: 2,
};

const ContactTypes = {
  '': ContactTypeMap.NONE,
  Individual: ContactTypeMap.INDIVIDUAL,
  Entity: ContactTypeMap.ENTITY,
};

const GenderTypeMap = {
  Female: 0,
  Male: 1,
  NotApplicable: 2,
  Other: 3,
  Unknown: 4,
};

const GenderType = {
  Female: GenderTypeMap.Female,
  Male: GenderTypeMap.Male,
  'Not Applicable': GenderTypeMap.NotApplicable,
  Other: GenderTypeMap.Other,
  Unknown: GenderTypeMap.Unknown,
};

const ImportGenders = [
  { name: 'Female', value: 'F' },
  { name: 'Male', value: 'M' },
  { name: 'Not Applicable', value: 'NA' },
  { name: 'Other', value: 'O' },
  { name: 'Unknown', value: 'U' },
];

const AclPermissionType = {
  InstanceAccess: 1, // NOT USED ANYMORE - keeping for historic reasons only
  Admin: 2,
  SuperAdmin: 3,

  CaseRead: 10121,
  CaseCreate: 10122,
  CaseUpdate: 10123,
  CaseDelete: 10124,

  FaqRead: 10191,
  FaqCreate: 10192,
  FaqUpdate: 10193,
  FaqDelete: 10194,

  CompanyRead: 10221,
  CompanyCreate: 10222,
  CompanyUpdate: 10223,
  CompanyDelete: 10224,

  AddressRead: 10241,
  AddressCreate: 10242,
  AddressUpdate: 10243,
  AddressDelete: 10244,

  ProjectRead: 10271,
  ProjectCreate: 10272,
  ProjectUpdate: 10273,
  ProjectDelete: 10274,

  ProjectTabRead: 10301,
  ProjectTabCreate: 10302,
  ProjectTabUpdate: 10303,
  ProjectTabDelete: 10304,

  PortalPageRead: 10331,
  PortalPageCreate: 10332,
  PortalPageUpdate: 10333,
  PortalPageDelete: 10334,

  InterestedPartyRead: 10361,
  InterestedPartyCreate: 10362,
  InterestedPartyUpdate: 10363,
  InterestedPartyDelete: 10364,
};

const AclPermissionTypeEnum = {
  'Global Access': AclPermissionType.InstanceAccess,
  Admin: AclPermissionType.Admin,

  'List Cases': AclPermissionType.CaseRead,
  'Create New Case': AclPermissionType.CaseCreate,
  'Modify Case': AclPermissionType.CaseUpdate,
  'Delete Case': AclPermissionType.CaseDelete,

  'FAQ - View': AclPermissionType.FaqRead,
  'FAQ - Create': AclPermissionType.FaqCreate,
  'FAQ - Update': AclPermissionType.FaqUpdate,
  'FAQ - Delete': AclPermissionType.FaqDelete,

  'Company - View': AclPermissionType.CompanyRead,
  'Company - Create': AclPermissionType.CompanyCreate,
  'Company - Update': AclPermissionType.CompanyUpdate,
  'Company - Delete': AclPermissionType.CompanyDelete,

  'Project - View': AclPermissionType.ProjectRead,
  'Project - Create': AclPermissionType.ProjectCreate,
  'Project - Update': AclPermissionType.ProjectUpdate,
  'Project - Delete': AclPermissionType.ProjectDelete,

  'Project Tab - View': AclPermissionType.ProjectTabRead,
  'Project Tab - Create': AclPermissionType.ProjectTabCreate,
  'Project Tab - Update': AclPermissionType.ProjectTabUpdate,
  'Project Tab - Delete': AclPermissionType.ProjectTabDelete,

  'Portal Page - View': AclPermissionType.PortalPageRead,
  'Portal Page - Create': AclPermissionType.PortalPageCreate,
  'Portal Page - Update': AclPermissionType.PortalPageUpdate,
  'Portal Page - Delete': AclPermissionType.PortalPageDelete,

  'Interested Party - View': AclPermissionType.InterestedPartyRead,
  'Interested Party - Create': AclPermissionType.InterestedPartyCreate,
  'Interested Party - Update': AclPermissionType.InterestedPartyUpdate,
  'Interested Party - Delete': AclPermissionType.InterestedPartyDelete,
};

const AclRoleType = {
  PerRole: 1,
  PerEntity: 2,
};

// not sure what this one is for yet - TBD
const AclRoleRole = {
  Anonymous: 1,
  Individual: 2,
  Entity: 3,
  Admin: 4,
};

const AclEntityType = {
  Docket: 1,
  Case: 2,
  Person: 3,
  Address: 4,
  Company: 5,
  Email: 6,
  Phone: 7,
  Event: 8,

  InterestedParty: 17,
  Project: 19,
  ProjectPriority: 20,
  ProjectStatus: 21,
  ProjectTab: 22,
  ProjectType: 23,
  Fund: 24,
  PortalPage: 25,
};

const ECrmImportStatus = {
  Error: 0,
  Approved: 1,
  Rejected: 2,
  Processed: 3,
};

const CrmImportStatus = {
  Error: ECrmImportStatus.Error,
  Approved: ECrmImportStatus.Approved,
  Rejected: ECrmImportStatus.Rejected,
  Processed: ECrmImportStatus.Processed,
};

const CrmImportSteps = {
  UploadImportFile: 1,
  ReviewContacts: 2,
  FinalizeImport: 3,
  Result: 4,
};

const ECrmImportAnalyzerStatus = {
  InvalidData: 0,
  NoMatch: 1,
  Match: 2,
  NeedsReview: 3,
};

const CrmImportAnalyzerStatus = {
  'Invalid Data': ECrmImportAnalyzerStatus.InvalidData,
  'No Match': ECrmImportAnalyzerStatus.NoMatch,
  Match: ECrmImportAnalyzerStatus.Match,
  'Needs Review': ECrmImportAnalyzerStatus.NeedsReview,
};

const ESettings = {
  PortalLogo: 101,
  PortalLoginButtonColor: 102,
  PortalLoginBackgroundColor: 113,
  PortalName: 103,
  PortalLabel: 104,
  PortalEnableSidePanel: 105,
  PortalSidePanelHtml: 106,
  PortalSidePanelImage: 107,
  PortalSidePanelImageSize: 108,
  PortalSidePanelImageAlign: 109,
  PortalStyle: 110,
  DefaultProjectViewerGroup: 111,
  AllowDashboardForNonAdmins: 112,
  SupportEmail: 114,
  SupportEmailName: 115,
  PortalCSS: 200,
  PortalStyle2ShowPortfolioCompanies: 2001,
  PortalStyle2ShowPortfolioOverview: 2002,
  PortalStyle2ShowProjectThumbnails: 2003,
  PortalStyle2ShowRecentActivity: 2004,
  PortalStyle2NumberOfProjectColumns: 2005,
  PortalStyle2ThumbnailStyle: 2006,
  PortalStyle2TileButtonPosition: 2007,
  ExtendedProfileABLink: 3001,
  ExtendedProjectDetailABLink: 3002,
  SmartRoomsProjectABLink: 3003,
  UserProfilesProjectABLink: 3004,
  ProjectManagementABLink: 3005,
  AlertTemplatesTemporary: 9999999,
};

const InvestorSmartRoomFolders = [
  'Capital Calls',
  'Distributions',
  'Financials',
  'Legal Documents',
  'Other Documents',
  'Tax Information',
];

const GenericAlertDocumentLinkType = {
  NONE: 0,
  NAME_ONLY: 1,
  LINK_TO_INVESTOR_TAB: 2,
  LINK_TO_DOCUMENTS_TAB: 3,
  LINK_TO_DOCUMENT: 4,
};

const EmailProviders = {
  SparkPost: 'SparkPost',
  SendGrid: 'SendGrid',
};

const EmailProviderDomains = {
  SparkPost: 'sparkpost.com',
  SendGrid: 'sendgrid.com',
};

const CounterpartyMilestoneTypes = {
  universe: '1 - Universe',
  contacted: '2 - Contacted',
  dialogue: '3 - Dialogue',
  managementMeetings: '4 - Management Meetings',
  confidentialDialogue: '4.1 - Confidential Dialogue / Management Meeting',
  preTsDiligence: '5 - Pre TS Diligence',
  termsDiscussion: '6 - Terms Discussion',
  termSheetSigned: '7 - Term Sheet Signed',
  postTsDiligence: '8 - Post TS Diligence',
  documentation: '9 - Documentation',
  // closed: '10 - Closed'
};

const TargetRoles = {
  leadInvestor: 'Lead Investor',
  coInvestor: 'Co-Investor',
  existingInvestor: 'Existing Investor',
  issueManager: 'Issue Manager',
  placementAgent: 'Placement Agent',
  referringAgent: 'Referring Agent',
  singaporeIssuerCounsel: 'Singapore Issuer Counsel',
  singaporeSyndicateCounsel: 'Singapore Syndicate Counsel',
  usIssuerCounsel: 'US Issuer Counsel',
  auditor: 'Auditor',
  valuation: 'Valuation',
  vendor: 'Vendor',
  acquirer: 'Acquirer',
  buySideTarget: 'Buy-side Target',
  commercialPartner: 'Commercial Partner',
  other: 'Other',
};

const SmartRoomContentViewModes = {
  files: 'File List',
  split: 'Split View',
  tree: 'Folder Tree',
};

const ActionRelatedEntityType = {
  Case: 'Case',
  Request: 'Request',
};

const ActionCRMTypeMap = {
  MergeCompanies: 'MergeCompanies',
  MergePeople: 'MergePeople',
  MoveCompanyDomain: 'MoveCompanyDomain',
};

const ActionCRMMergeType = {
  MergeToMaster: 1,
  Delete: 2,
};

const ConnectionTypes = {
  APPBUILDER: 1,
  SMARTROOM: 2,
  DEALCLOUD: 3,
};

const ConnectionTypesMap = {
  SmartApp: ConnectionTypes.APPBUILDER,
  SmartRoom: ConnectionTypes.SMARTROOM,
};

const ABConnectionTypes = {
  PROJECT_MANAGEMENT: 'Project Management',
  INVESTOR_DATA: 'Investor Data',
  SMARTROOM_STATS: 'SmartRoom Stats',
  USER_REQUESTS: 'User Requests',
  //CLAIMS_DATA: 'Claims Data',
  //WIRE_INSTRUCTIONS: 'Wire Instructions',
  OTHER: 'Custom',
};

export {
  ConnectionTypes,
  ConnectionTypesMap,
  ABConnectionTypes,
  PortalPageMap,
  PortalPage,
  PortalPageProjectFilterMap,
  PortalPageProjectFilter,
  InvestorSmartRoomFolders,
  AclPermissionType,
  AclPermissionTypeEnum,
  AclEntityType,
  AclRoleType,
  AclRoleRole,
  GenderType,
  GenderTypeMap,
  ImportGenders,
  CrmImportSteps,
  ECrmImportStatus,
  CrmImportStatus,
  ECrmImportAnalyzerStatus,
  CrmImportAnalyzerStatus,
  ESettings,
  CaseStatus,
  ClaimsAgentImage,
  EventType,
  EventPriority,
  EReportStatus,
  ReportStatus,
  CaseTypeMap,
  CaseType,
  PersonInCompanyFlags,
  CaseAssociationType,
  CaseAssociationTypes,
  CaseProjectUrlMap,
  CaseProjectUrl,
  CaseProjectUrlOpenModeMap,
  CaseProjectUrlOpenMode,
  SourceType,
  PartyRoleTypes,
  PartyRole,
  PartyTypes,
  PartyType,
  NoticeTypes,
  Notice,
  AddressTypes,
  AddressTypesEnum,
  PhoneTypes,
  PhoneTypesEnum,
  EmailTypes,
  DebtorTypes,
  DebtorTypesEnum,
  DebtorBusinessTypeALookup,
  DebtorBusinessTypeA,
  DebtorBusinessTypeB,
  DebtorBusinessTypeBIntMap,
  CDPChapterTypes,
  CDPChapterTypeIntMap,
  CDPChapters,
  CDPCaseTypes,
  CDPCaseTypeEnum,
  Chapters,
  ChapterMap,
  ChapterTypes,
  ChapterTypeMap,
  CompanyType,
  CompanyTypeMap,
  CompanyTypeList,
  EntityType,
  EntityTypeMap,
  DocketRatingMap,
  DocketRating,
  ClaimsAgentTypesMap,
  ClaimsAgentTypes,
  CorporateChapterTypes,
  CorporateChapterTypeMap,
  Chapter11SubType,
  ActionTypeMap,
  ActionStatusMap,
  GrabReportType,
  GrabReportTypeMap,
  GrabReportSourceType,
  ReasonFiledInDistrictType,
  ReasonFiledInDistrictTypeIntMap,
  EstimationOfFundsType,
  EstimationOfFundsTypeIntMap,
  DebtorAssetsTypes,
  CreditorsTypes,
  LiabilitiesTypes,
  DocketGroups,
  DocketSubGroups,
  CaseFilters,
  CaseFilterKeys,
  ProjectFilters,
  ProjectFilterNames,
  ProjectFilterKeys,
  ProjectTypeMap,
  ProjectType,
  ProjectStatusMap,
  ProjectStatus,
  SetClaimProviders,
  SetClaimProvidersMap,
  AlertTypeMap,
  AlertType,
  AlertTypeList,
  AlertTriggerTypeMap,
  AlertTriggerType,
  AlertTriggerTypeList,
  AlertParameterTypeMap,
  AlertParameterType,
  AlertRecipientType,
  AlertStatus,
  AlertFrequencyMap,
  AlertFrequency,
  AlertFrequencyList,
  AlertDelivery,
  ProjectTabMap,
  ProjectTab,
  ProjectTabIcons,
  CustomContactsFilterMap,
  CustomContactsFilter,
  FieldFormat,
  FundTypeMap,
  FundType,
  FundStatusMap,
  FundStatus,
  ContactTypeMap,
  GenericAlertDocumentLinkType,
  EmailProviders,
  EmailProviderDomains,
  CounterpartyMilestoneTypes,
  TargetRoles,
  SmartRoomContentViewModes,
  ActionRelatedEntityType,
  ActionCRMTypeMap,
  ActionCRMMergeType,
};
