import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency'

import Store from '@ember-data/store';
import DockerItemService from 'cing-app/pods/docker-item/service';

import { humanize } from 'ember-cli-string-helpers/helpers/humanize';
import { dasherize } from 'ember-cli-string-helpers/helpers/dasherize';
import DataLink from 'cing-app/models/data-link';
import DataLinkView from 'cing-app/models/data-link-view';
import { FilterOperators } from 'cing-app/mixins/filter-builder';

interface ManageDataLinksArgs {
	model?: any;
	project?: any;
	context?: {
		dataLinkId: number;
	};
	onClose?: () => void;
	header?: any;
	footer?: any;
}


export default class Contacts extends Component<ManageDataLinksArgs> {
	@service store!: Store;
	@service config: any;
	@service('docker-item') docker!: DockerItemService;

	@tracked dataLinks!: DataLink[];
	@tracked dataLink?: DataLink;
	@tracked dataLinkView?: DataLinkView;

	constructor(owner: any, args: ManageDataLinksArgs) {
		super(owner, args);

		this.initTask.perform();
	}

	@task
	initTask = taskFor(async (): Promise<void> => {
		let dataLinks = await this.store.query('data-link', {
			page: {
				size: 1000
			},
			sort: 'name'
		});

		this.dataLinks = dataLinks.toArray();

		let selectedDataLinkId = get(this.args.model, 'settings.dataLinkId');
		if (selectedDataLinkId) {
			this.dataLink = dataLinks.findBy('id', selectedDataLinkId);

			if (this.dataLink) {
				let dataLinkView: string = get(this.args.model, 'settings.dataLinkView');
				this.dataLinkView = this.dataLink.views.findBy('name', dataLinkView)
			}
		}
	});

	@task
	*saveTask() {
		yield this.args.model.save();
	}

	@action
	save() {
		this.saveTask.perform();
	}

	@action
	addFilter() {
		/*
		if (!get(this.args.model, 'settings.customContacts.filters')) {
			set(this.args.model, 'settings.customContacts.filters', []);
		}
		*/

		get(this.args.model, 'settings.customContacts.filters').pushObject(this.store.createFragment('project-tab-settings-contacts-filter', {

		}))
	}

	@action
	removeFilter(filter) {
		get(this.args.model, 'settings.customContacts.filters').removeObject(filter);
	}

	@action
	addFieldToListView(attribute) {
		get(this.args.model, 'settings.customContacts.fields').pushObject(this.store.createFragment('project-tab-settings-contacts-field', {
			name: attribute,
			title: humanize([dasherize([attribute])])
		}));
	}

	@action
	removeFieldFromListView(field) {
		get(this.args.model, 'settings.customContacts.fields').removeObject(field);
	}

	@action
	selectDataLink(model: DataLink) {
		if (this.dataLink && this.dataLink !== model) {
			set(this.args.model, 'settings.dataLinkFilters', []);
		}

		this.dataLink = model;
		set(this.args.model, 'settings.dataLinkId', model.id);

		get(this.args.model, 'settings.dataLinkFilters').pushObject(this.store.createFragment('data-link-context-filter', {
			system: true,
			property: 'project-id',
			operator: FilterOperators.EQUAL,
			value: this.args.project.id
		}));
	}

	@action
	selectDataLinkView(view: DataLinkView) {
		this.dataLinkView = view;
		set(this.args.model, 'settings.dataLinkView', view.name)
	}

	@action
	removeContextFilter() {

	}

	@action
	updateContextFilter() {

	}
}