import Component from '@glimmer/component';
import { ABColumnFormat } from 'cing-app/models/appbuilder-link-column';
//@ts-ignore
import { cached } from 'tracked-toolbox';

interface FormattedValueArgs {
  format: ABColumnFormat;
  options: any;
  value: any;
}

export default class FormattedValue extends Component<FormattedValueArgs> {
  @cached
  get format() {
    let f = Object.assign({}, this.args.format);
    f = Object.assign(f, this.args.options);
    return new ABColumnFormat(f);
  }

  @cached
  get value() {
    return this.format.formatValue(this.args.value);
  }
}
