import {
  attr,
  hasMany,
  belongsTo,
  AsyncHasMany,
  AsyncBelongsTo,
} from '@ember-data/model';
import Abstract from './abstract';
import Case from './case';
import DocketToFolder from './docket-to-folder';

export default class DocketFolder extends Abstract {
  @attr('string') declare name?: string;
  @attr('string') declare description?: string;
  @attr('string') declare parentId?: string;
  @attr('number') declare order: number;
  @attr('boolean') declare visible: boolean;
  @attr('boolean') declare deleted?: boolean;

  @hasMany('docket-folder', { inverse: null })
  declare children: AsyncHasMany<DocketFolder>; // associations are managed manually
  @hasMany('docket-to-folder')
  declare docketToFolders: AsyncHasMany<DocketToFolder>; // associations are managed manually
  @belongsTo('case') declare case: AsyncBelongsTo<Case>;

  get isVisible() {
    return this.get('visible');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'docket-folder': DocketFolder;
  }
}
