import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject } from '@ember/service';
import { alias } from '@ember/object/computed';
import { assign } from "@ember/polyfills";
import Field from '../field/component';

@classic
export default class Json extends Field {
  showFormValidations = null;

  @inject()
  config;

  defaultOptions = null;

  init() {
    super.init();
    this.set('value', this.get('model.' + this.property));
  }
}
