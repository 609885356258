import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { get } from '@ember/object';
import { dasherize } from '@ember/string';
import { DailyStat } from '../../component';
import { inject as service } from '@ember/service';
import AppBuilderService, {
  ABModelMeta,
} from 'cing-app/pods/appbuilder/service';
import { DataSourceColumn, Paged, ApiDataSource } from 'smex-ui-table';
import AppBuilderLink from 'cing-app/models/appbuilder-link';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import _SessionService from 'cing-app/pods/session/service';
import {
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

interface DataHistoriesArgs {
  appbuilderLink: AppBuilderLink;
  dailyStat: DailyStat;
}

export interface DataHistory {
  host?: string;
  friendly?: string;
  statsDate?: string;
  ngexportReportsDailyStatsChangeId?: number;
  finalPageCountToday?: number;
  finalSizeToday?: number;
  mb?: number;
  gb?: number;
  siteId?: number;
  finalSizeTodayMb?: number;
  finalSizeTodayGb?: number;
}

export default class DataHistories extends Component<DataHistoriesArgs> {
  @service('appbuilder')
  appbuilder!: AppBuilderService;

  @service
  store!: Store;

  @service
  session!: _SessionService;

  //Summary
  @tracked
  summaryCollapsed: boolean = false;

  modelMeta: ABModelMeta;
  dataSource!: ApiDataSource<DataHistory>;

  @tracked
  selectedItems: DataHistory[];

  //Common
  pageSize = 20;

  get columnsUI() {
    let columns: DataSourceColumn<DataHistory>[] = [
      new DataSourceColumn<DataHistory>({
        id: 'host',
        label: 'Host',
        getValue: (row) => get(row, 'host'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DataHistory>({
        id: 'friendly',
        label: 'Friendly',
        getValue: (row) => get(row, 'friendly'),
        sortingEnabled: true,
        minWidth: 180,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DataHistory>({
        id: 'stats-date',
        label: 'Stats Date',
        getValue: (row) => get(row, 'statsDate'),
        sortingEnabled: true,
        minWidth: 120,
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'date',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        },
      }),
      new DataSourceColumn<DataHistory>({
        id: 'final-page-count-today',
        label: 'Pages Today',
        getValue: (row) => get(row, 'finalPageCountToday'),
        sortingEnabled: true,
        minWidth: 130,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'decimal',
          },
        },
      }),
      new DataSourceColumn<DataHistory>({
        id: 'final-size-today',
        label: 'Size Today',
        getValue: (row) => get(row, 'finalSizeToday'),
        sortingEnabled: true,
        minWidth: 130,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'fileSize',
          },
        },
      }),
      new DataSourceColumn<DataHistory>({
        id: 'mb',
        label: 'MB',
        getValue: (row) => get(row, 'mb'),
        sortingEnabled: true,
        minWidth: 100,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'decimal',
          },
        },
      }),
      new DataSourceColumn<DataHistory>({
        id: 'gb',
        label: 'GB',
        getValue: (row) => get(row, 'gb'),
        sortingEnabled: true,
        minWidth: 100,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'decimal',
          },
        },
      }),
      new DataSourceColumn<DataHistory>({
        id: 'site-id',
        label: 'Site Id',
        hidden: true,
        getValue: (row) => get(row, 'siteId'),
        sortingEnabled: true,
        minWidth: 100,
        valueComponent: 'table-text-column',
      }),
      new DataSourceColumn<DataHistory>({
        id: 'final-size-today-mb',
        label: 'Final Size Today MB',
        getValue: (row) => get(row, 'finalSizeTodayMb'),
        sortingEnabled: true,
        minWidth: 180,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'decimal',
          },
        },
      }),
      new DataSourceColumn<DataHistory>({
        id: 'final-size-today-gb',
        label: 'Final Size Today GB',
        getValue: (row) => get(row, 'finalSizeTodayGb'),
        sortingEnabled: true,
        minWidth: 180,
        headerClass: 'justify-content-end',
        valueClass: 'text-right',
        valueComponent: 'table-text-column',
        options: {
          format: {
            style: 'decimal',
          },
        },
      }),
    ];
    return columns;
  }

  constructor(owner: any, args: any) {
    super(owner, args);

    this.modelMeta = this.appbuilder.getModelMeta(
      this.args.appbuilderLink,
      'data-histories'
    );

    this.selectedItems = [];

    this.dataSource = new ApiDataSource<DataHistory>(
      25,
      false,
      this.modelMeta.modelPath,
      this.session.authUser.email,
      this.loadDataTask,
      this.pageSize,
      this.columnsUI,
      this.selectedItems,
      {}
    );

    this.dataSource.selectionEnabled = false;
  }

  @task
  loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<DataHistory>[],
      pageSize: number,
      pageIndex: number,
      options: any
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      let filterQuery = Expressions.create();
      filterQuery.add(
        Filter.create({
          name: 'site-id',
          operator: FilterOperators.EQUAL,
          value: this.args.dailyStat.siteId,
        })
      );

      let query: any = {
        page: {
          size: pageSize,
          number: pageIndex + 1,
        },
        condition: filterQuery.serialize(),
      };

      if (sortColumn) {
        let sortName =
          sortColumn.options?.sortValue || dasherize(sortColumn.id);
        query['sort'] = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let dataHistoryList = await this.store.query(
        this.modelMeta.modelPath,
        query
      );

      let dataHistories = <Paged<DataHistory>>dataHistoryList.toArray();
      dataHistories.meta = {
        totalCount: dataHistoryList.meta['total-count'],
      };

      return dataHistories;
    }
  );
}
