import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
export default class AddRole extends Component {
  @action
  save() {
    if (this.model.get('validations.isValid')) {
      this.model.save();
      if (this.onClose) {
        this.onClose();
      }
    }
  }
}