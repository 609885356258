import EmberRouter from '@ember/routing/router';
import config from 'cing-app/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('dashboard', function () {
    this.route('contacts');
    this.route('projects');
    this.route('smartrooms');
    this.route('connections');
    this.route('userprofiles');
  });
  this.route('cases');
  this.route('calendar');
  this.route('settings');
  this.route('case', { path: '/case/:case_number' });
  this.route('project', { path: '/project/:project_id' }, function () {
    this.route('project-tab', { path: '/project-tab/:project_tab_id' });
  });
  this.route('login');
  this.route('web', { path: '/web' }, function () {
    this.route('viewer', { path: '/viewer' });
  });
  this.route('signup');
  this.route('signup-success');
  this.route('view-as');
});
