import { attr } from '@ember-data/model';
//@ts-ignore
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';

export default class DataLinkRelationship extends Fragment {
  @attr('string') declare name: string;
  @attr('string') declare type: string;
  @attr('string') declare sourceModelName: string;
  @attr('string') declare sourcePropertyName: string;
  @attr('string') declare targetModelName: string;
  @attr('string') declare targetPropertyName: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-link-relationship': DataLinkRelationship;
  }
}
