import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import _SessionService from 'cing-app/pods/session/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import AbPmService, { IPmTaskDocument } from 'cing-app/pods/ab-pm/service';

import { get } from '@ember/object';
import { dasherize } from '@ember/string';

import { DataSourceColumn, ApiDataSource, Paged } from 'smex-ui-table';

interface AbPmDocumentListArgs {
  abLink: any;
  onTaskAction: any;
  smartRoomId: any;
}

export default class AbPmDocumentList extends Component<AbPmDocumentListArgs> {
  @service seActions: any;
  @service('docker-item') docker!: DockerItemService;
  @service('ab-pm') pmService!: AbPmService;

  @tracked isRemoving: boolean = false;
  @tracked isRemovingMultipleItems: boolean = false;
  @tracked isDeleting: boolean = false;
  @tracked isDeletingMultipleItems: boolean = false;
  @tracked selectedDocument: any;
  @tracked selectedTask: any;

  @tracked uploadStatus: string = '';
  @tracked isDragOver = false;
  @tracked isCollapsed = false;
  @tracked isUploading = false;
  @tracked isEditingTask = false;
  @tracked searchQuery: string = '';
  @tracked dataSource!: ApiDataSource<IPmTaskDocument>;
  @tracked condition: string = '';
  @tracked modelName: string = '';

  showInputSelector = true;
  selectedItems: any;
  pageSize: number = 20;

  @tracked columns: DataSourceColumn<IPmTaskDocument>[] = [
    new DataSourceColumn<IPmTaskDocument>({
      id: 'task-id',
      label: 'ID',
      hidden: true,
      getValue: (row) => get(row, 'id'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'tasks.wbs',
      label: 'Task #',
      getValue: (row) => get(row, 'tasks.wbs'),
      sortingEnabled: true,
      minWidth: 60,
      maxWidth: 80,
      valueComponent: 'table-text-column',
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'folder-number',
      label: 'Folder #',
      getValue: (row) => get(row, 'folderNumber'),
      getIcon: (row) => {
        if (row.folderId) {
          return 'info-circle';
        }
        return '';
      },
      getTooltip: (row: any) => get(row, 'folderName'),
      sortingEnabled: true,
      minWidth: 80,
      maxWidth: 100,
      valueComponent: 'table-icon-column',
      options: {
        className: 'text-info',
        linkTarget: '_blank',
        generateLink: (row: any) => {
          console.log('file row ', row.tasks);
          return row.url;
        },
      },
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'tasks.task',
      label: 'Task',
      getValue: (row) => get(row, 'tasks.task'),
      sortingEnabled: true,
      minWidth: 190,
      valueComponent: 'table-text-column',
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'file-name',
      label: 'Filename',
      getValue: (row) => get(row, 'fileName'),
      sortingEnabled: true,
      minWidth: 180,
      valueComponent: 'table-filename-column',
      options: {
        tooltip: true,
        getTooltipValue: (row: any) => {
          return row.fileName || '';
        },
        linkTarget: '_blank',
        generateLink: (row: any) => {
          console.log('file row ', row.tasks);
          return row.url;
        },
        onClick: (row: any) => {
          return this.filenameClicked(row);
        },
      },
    }),

    new DataSourceColumn<IPmTaskDocument>({
      id: 'created-by',
      label: 'Uploaded By',
      getValue: (row) => get(row, 'createdBy'),
      sortingEnabled: true,
      minWidth: 100,
      valueComponent: 'table-text-column',
    }),
    new DataSourceColumn<IPmTaskDocument>({
      id: 'created-date',
      label: 'Uploaded',
      getValue: (row) => get(row, 'createdDate'),
      sortingEnabled: true,
      minWidth: 100,
      maxWidth: 120,
      valueComponent: 'table-text-column',
      options: {
        format: {
          style: 'date',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },
      },
    }),
  ];

  constructor(owner: any, args: any) {
    super(owner, args);
  }

  @action changeView(event: any) {
    if (this.args.onTaskAction) {
      this.args.onTaskAction('change-view', event.target.value);
    }
  }

  @action init() {
    this.selectedItems = [];

    this.dataSource = new ApiDataSource<IPmTaskDocument>(
      25,
      false,
      'task-documents',
      this.pmService.currentUserEmail,
      this.loadDataTask,
      this.pageSize,
      this.columns,
      this.selectedItems,
      {}
    );

    this.dataSource.enableSelectAll = true;
    this.dataSource.selectionEnabled = true;
    this.dataSource.restoreDefaultColumns();
  }

  @action search() {
    this.dataSource.refresh();
  }

  @action refresh() {
    this.dataSource.refresh();
  }

  @action filenameClicked(row: any) {
    console.log('file ', row);
    if (row) {
      if (/smartroom/i.test(row.type)) {
        let appearance = {
          icon: 'file',
          title: row.fileName,
          size: 'large',
          custom: true,
        };

        const metadata = JSON.parse(row.metadata);
        const siteId = metadata.smartRoomId || this.args.smartRoomId;
        const file = {
          id: row.fileId || metadata.fileId,
          folderId: row.folderId,
          version: metadata.versionNo || 1,
          get: null,
        };

        if (!file.folderId) {
          file.folderId = this.pmService.getSmartRoomFolderIdFromPathIds(
            metadata.pathIds
          );
        }

        file.get = (key: string) => {
          return file[key];
        };

        this.docker.invokePopup('smartroom/file-viewer', appearance, {
          file: file,
          siteId: siteId,
        });
      } else {
        if (row.url) {
          window.open(row.url, '_blank');
        }
      }
    }
  }

  @action getActions() {
    let actions = [
      {
        label: 'View',
        icon: 'eye',
        action: this.filenameClicked,
      },
      {
        label: 'Download',
        icon: 'download',
        action: (row: any) => {
          this.downloadFileTask.perform(row);
        },
      },
      {
        label: 'Remove',
        icon: 'times',
        class: 'text-danger',
        action: (row: any) => {
          this.isRemoving = true;
          this.isRemovingMultipleItems = false;
          this.selectedDocument = row;
        },
      },
      {
        label: 'Delete',
        icon: 'trash-alt',
        class: 'text-danger',
        action: (row: any) => {
          this.isDeleting = true;
          this.isDeletingMultipleItems = false;
          this.selectedDocument = row;
        },
      },
    ];
    return actions;
  }

  @task downloadFileTask = taskFor(async (file: any) => {
    console.log('download file ', file);

    if (file.type == 'smartroom' && file.fileId) {
      await this.pmService.downloadSmartRoomFile(this.args.smartRoomId, file);
    } else {
      this.pmService.downloadFile(file.url, file.fileName);
    }
  });

  @task loadDataTask = taskFor(
    async (
      columns: DataSourceColumn<IPmTaskDocument>[],
      pageSize: number,
      pageIndex: number
    ) => {
      let sortColumn = columns.find((col: any) => {
        return col.sort;
      });

      console.log('COLUMNS ', columns);
      console.log('sortcolumn', sortColumn);

      let sortBy = 'id';

      if (sortColumn) {
        let sortName =
          sortColumn.options?.sortValue || dasherize(sortColumn.id);
        sortBy = `${sortColumn.sort === 'desc' ? '-' : ''}${sortName}`;
      }

      let query = await this.pmService.getDocumentList(
        this.searchQuery,
        pageSize,
        pageIndex,
        sortBy,
        this.args.abLink
      );

      //used in export
      this.modelName = query.modelName;
      this.condition = query.expr;

      let documents = query.result;

      let taskDocuments = <Paged<IPmTaskDocument>>documents.toArray();

      //console.log("docsssss =>>>>>>>", documents.toArray())

      let taskIds = taskDocuments.map((t) => t.taskId);
      let tasks = await this.pmService.getTasksByIds(taskIds, this.args.abLink);
      let taskMap = new Map<String, any>();
      tasks.forEach((t) => {
        taskMap.set(t.taskId, t);
      });

      console.log('task map:', taskMap);
      taskDocuments.forEach((td: any) => {
        let taskId = td?.taskId;
        td.tasks = taskMap.get(taskId) || {};
      });

      taskDocuments.meta = {
        totalCount: documents.meta['total-count'],
      };

      return taskDocuments;
    }
  );

  @action launchUploadDialog() {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.style.display = 'none';
    document.body.appendChild(input);
    input.onchange = (event: any) => {
      this.uploadFiles.perform(input);
    };
    input.click();
  }

  @action toggleDocuments() {
    this.isCollapsed = !this.isCollapsed;
  }

  @action removeSelected() {
    this.isRemoving = true;
    this.isRemovingMultipleItems = true;
  }

  @action downloadSelected() {
    this.dataSource.selectedItems?.forEach((item: any) => {
      this.downloadFile(item.url, item.fileName);
    });
  }

  @task removeDocumentTask = taskFor(async () => {
    let itemsToRemove: any = [this.selectedDocument];
    if (this.isRemovingMultipleItems) {
      itemsToRemove = this.dataSource.selectedItems;
    }

    for (var i = 0; i < itemsToRemove.length; i++) {
      let item = itemsToRemove[i];
      try {
        if (item) {
          //remove the record in pm
          if (item.destroyRecord) {
            item.destroyRecord();
          }
        }
      } catch (ex: any) {
        console.log('delete failed', ex);
      }
    }

    setTimeout(() => {
      this.dataSource.selectedItems?.clear();
      this.dataSource.refresh();
      this.isRemoving = false;
    }, 100);
  });

  @task deleteDocumentTask = taskFor(async () => {
    let itemsToDelete: any = [this.selectedDocument];
    if (this.isDeletingMultipleItems) {
      itemsToDelete = this.dataSource.selectedItems;
    }

    for (var i = 0; i < itemsToDelete.length; i++) {
      let item = itemsToDelete[i];
      try {
        if (item) {
          if (item.type == 'smartroom' && item.fileId) {
            //delete the smartroom record for this file
            await this.pmService.deleteSmartRoomFile(
              this.args.smartRoomId,
              item.fileId
            );
          }

          //delete the record in pm
          if (item.destroyRecord) {
            item.destroyRecord();
          }
        }
      } catch (ex: any) {
        console.log('delete failed', ex);
      }
    }

    setTimeout(() => {
      this.dataSource.selectedItems?.clear();
      this.dataSource.refresh();
      this.isDeleting = false;
    }, 100);
  });

  @task(function* (fileInput: any) {
    let files = fileInput.files || [];
    if (files && files.length) {
      let numberOfFiles = files.length;
      this.isUploading = true;
      for (var i = 0; i < numberOfFiles; i++) {
        let file = files[i];
        let count = `(${i + 1} of ${numberOfFiles})`;
        this.uploadStatus = `Uploading ${count} - ${file.name} ...`;
        yield this.uploadFileTask.perform(file);
      }

      this.uploadStatus = 'Upload completed';

      this.dataSource.refresh();

      setTimeout(() => {
        this.uploadStatus = '';

        // if (fileInput.parentNode) {
        //   fileInput.removeChild(fileInput);
        // }

        this.isUploading = false;
      }, 2000);
    }
  })
  uploadFiles;

  @task uploadFileTask = taskFor(async (file: any) => {
    console.log('uploading file =>', file.name);

    let uploadResult = await this.seActions.uploadImage(
      file,
      '',
      true,
      file.name
    );
    console.log('uploadResult ', uploadResult);

    if (uploadResult && uploadResult.url) {
      let doc = this.pmService.createTaskDocument(this.args.abLink);
      doc.fileName = file.name;
      doc.filename = file.name;
      doc.url = uploadResult.url;
      doc.metadata = JSON.stringify(uploadResult);
      doc.taskId = this.selectedTask.id;
      doc.email = this.pmService.currentUserEmail;
      doc.createdDate = this.pmService.currentDate;
      doc.createdBy = this.pmService.currentUserFullName;

      let documents = this.selectedTask.documents;
      documents.pushObject(doc);

      await doc.save();
    }
  });
}
