import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-smartidentity-auth/mixins/auth-mixin';
import { task } from 'ember-concurrency';
import { AclPermissionType } from 'cing-app/utils/lookups';
import { get, set } from '@ember/object';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

import classic from 'ember-classic-decorator';

@classic
export default class ProjectRoute extends Route.extend(
  AuthenticatedRouteMixin
) {
  @service store;
  @service session;
  @service serverVariables;
  @service userInstances;

  model(params, transition) {
    return this.getModelTask.perform(params);
  }

  @task
  *findPermission(roleAssignments, permissionType) {
    let adminRole = null;

    for (var a = 0; a < roleAssignments.length; a++) {
      let assignment = roleAssignments[a];
      let uRole = yield get(assignment, 'aclRole');

      if (uRole) {
        let permissions = yield get(uRole, 'aclPermissions');
        console.log('Permissions: ', permissions, uRole);

        let adminPermission = permissions.findBy(
          'permissionTypeId',
          permissionType
        );

        if (adminPermission) {
          adminRole = uRole;
        }
      }
    }

    return adminRole;
  }

  @task
  *getModelTask(params) {
    let project = yield this.store.findRecord('project', params.project_id, {
      include:
        'project-tabs,project-type,case,project-urls,company,fund.fund-status,fund.fund-type',
    });

    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'email',
        operator: FilterOperators.EQUAL,
        value: get(this.session, 'tokenData.email'),
      })
    );

    this.userInstances.start();

    // first get the user
    let user = yield this.store.findRecord('user', 'me', {
      include:
        'instance-acl-role-assignments.acl-role.acl-permissions,groups.instance-acl-role-assignments.acl-role.acl-permissions',
    });

    let identityResponse = yield fetch(
      `${this.get('serverVariables.authApiUrl')}/api/users/me`,
      {
        headers: {
          Authorization: `Bearer ${this.get(
            'session.data.authenticated.access_token'
          )}`,
        },
      }
    );

    set(this.session, 'authUser', yield identityResponse.json());

    console.log('AUTH USER: ', this.session.authUser);

    set(this.session, 'user', user);

    // check if there's an admin role/permission assigned directly to user
    let adminRole = yield this.findPermission.perform(
      (yield get(user, 'instanceAclRoleAssignments')).toArray(),
      AclPermissionType.Admin
    );

    // if there's no admin permission directly on user, check if there's one in one of his groups
    if (!adminRole) {
      // container for storing all group role assignments
      let groupRoleAssignments = [];

      // get all user's groups
      let userGroups = (yield get(user, 'groups')).toArray();

      // popuplate container with all group role assignments
      for (var a = 0; a < userGroups.length; a++) {
        groupRoleAssignments.pushObjects(
          (yield get(userGroups[a], 'instanceAclRoleAssignments')).toArray()
        );
      }

      // now check if one of the role has admin permission
      adminRole = yield this.findPermission.perform(
        groupRoleAssignments,
        AclPermissionType.Admin
      );
    }

    // if admin permission was found, set the global session admin flag
    if (adminRole) {
      set(this.session, 'hasAdminRole', true);
    }

    return {
      adminRole,
      user,
      project,
    };
  }
}
