import { helper as buildHelper } from '@ember/component/helper';
import { Chapter11SubType } from '../utils/lookups'

export function formatChapter11SubType([value, ...rest]) {
  const type = Object
    .keys(Chapter11SubType)
    .map(key => Chapter11SubType[key])
    .filter(type => type.id === value)

  return type.length ? type[0].name : 'N/A';
}

export default buildHelper(formatChapter11SubType);
