define("cing-app/components/smex-ui-table/header-columns/cell", ["exports", "smex-ui-table/components/smex-ui-table/header-columns/cell"], function (_exports, _cell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cell.default;
    }
  });
});
