import Model, { attr } from '@ember-data/model';
import DcInvestorNumber from 'cing-app/transforms/dc-investor-number';
//@ts-ignore
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import DealCloudLpCommunicationCommunication from './deal-cloud-lp-communication-communication';
import DealCloudLpCommunicationContact from './deal-cloud-lp-communication-contact';
import DealCloudLpCommunicationFund from './deal-cloud-lp-communication-fund';
import DealCloudLpCommunicationLimitedPartner from './deal-cloud-lp-communication-limited-partner';

export default class DealCloudLpCommunication extends Model {
  @attr('string') declare role: string;
  @attr('string') declare email: string;
  @attr('dc-investor-number') declare investorNumber: DcInvestorNumber;
  @fragment('deal-cloud-lp-communication-fund')
  declare fund: DealCloudLpCommunicationFund;
  @fragment('deal-cloud-lp-communication-contact')
  declare contact: DealCloudLpCommunicationContact;
  @fragment('deal-cloud-lp-communication-limited-partner')
  declare limitedPartner: DealCloudLpCommunicationLimitedPartner;
  @fragmentArray('deal-cloud-lp-communication-communication')
  declare communications: DealCloudLpCommunicationCommunication;
  @attr('string') declare viewerGroup: string; // internal use only
  @attr('string') declare company: string; // internal use only
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'deal-cloud-lp-communication': DealCloudLpCommunication;
  }
}
