import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from '@ember/component';
import { ECrmImportStatus } from 'cing-app/utils/lookups';

@classic
export default class ColumnImportStatus extends Component {
  ECrmImportStatus = ECrmImportStatus;

  @action
  toggleImportStatus(record) {
    if (record.importStatus === ECrmImportStatus.Approved) {
      record.set('importStatus', ECrmImportStatus.Rejected);
    } else {
      record.set('importStatus', ECrmImportStatus.Approved);
    }

    record.save();
  }
}