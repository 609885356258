import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';

@classic
export default class FindCompany extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  include = 'addresses,emails';

  // start - filter properties
  searchQuery = '';

  // end - filter properties

  @computed
  get columns() {
    let self = this;
    return [
      {
        propertyName: 'name',
        className: "column-name",
        component: "api-table/columns/company-lookup",
        onRowSelect: function (record) {
          self.get('context.onFound')(record);
          self.attrs.onClose();
        }
      },
      {
        propertyName: 'modifyTime',
        className: "text-right column-datetime",
        component: "api-table/columns/date",
        isHidden: true,
        dateFormat: "MM/DD/YYYY hh:mm a"
      },
    ]
  }

  init() {
    super.init(...arguments);

    this.generateFilter();
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    let searchQuery = this.searchQuery;
    if (searchQuery) {
      expr.add(Filter.create({
        name: 'name',
        operator: FilterOperators.LIKE,
        value: searchQuery
      }));
    }

    this.set('filter', expr.serialize());
  }

  @action
  search() {
    this.generateFilter();
  }
}
