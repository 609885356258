import Model, { AsyncBelongsTo, AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import Form from './form';
import FormElementRepeater from './form-element-repeater';
import FormElementFormat from './form-element-format';
import SearchViewLayout from './search-view-layout';
import AnswerOption from './answer-option';
import AppBuilder from 'cing-app/pods/appbuilder/service';

export type ABInputType =
  'autoincrement' |
  'text' |
  'textarea' |
  'email' |
  'date' |
  'amount' |
  'number' |
  'tel' |
  'ssn' |
  'url' |
  'select' |
  'radio' |
  'checkboxes' |
  'multipleSelect' |
  'calculatedfield'
  ;
export default class FormElement extends Model {
  @attr("number") elementMasterId?: number;
  @attr("number") formId!: number;
  @attr("number") tabId?: number;
  @attr("number") groupId?: number;
  @attr("number") repeaterId?: number;
  @attr("number") categoryFilterId?: number;
  @attr("string") question?: string;
  @attr("number") typeAnswerId?: number;
  @attr("string") typeTaxonomy?: string;
  @attr("boolean") isRequired!: boolean;
  @attr("boolean") isSearchable!: boolean;
  @attr("boolean") isSearchColumn!: boolean;
  @attr("boolean") isHiddenOnload!: boolean;
  @attr("boolean") isForInternalUser!: boolean;
  @attr("number") sequenceNumber!: number;
  @attr("string") dataType!: string;
  @attr("string") helpText?: string;
  @attr('date') createTime!: Date;
  @attr('date') modifyTime!: Date;
  @attr("number") createUser?: number;
  @attr("number") modifyUser?: number;
  @attr("boolean") isEncrypted!: false;
  @attr("string") questionId!: string;
  @attr("string") searchColumnName!: string;
  @attr("string") searchColumnNameId?: string;
  @attr("number") helpTextId?: number;
  @attr("boolean") isCalculationField!: boolean;
  @attr("boolean") isKeyColumn!: boolean;
  @attr("number") ddId?: number;
  @attr("number") ddElementId?: number;
  @attr("string") excelElementId?: string;
  @attr("boolean") isLocationControl!: boolean;
  @attr("boolean") isGraph!: boolean;
  @attr("boolean") sendEmailOnSubmission!: boolean;
  @attr("boolean") readonlyModeOnClaimsearch!: boolean;
  @attr("string") detectedType?: string;
  @attr("string") objName!: string;
  @attr("string") objFieldName!: string;
  @attr("string") suffix?: string;
  @attr("boolean") addToClone?: boolean;

  @belongsTo('appbuilder-link/form') form!: AsyncBelongsTo<Form>;
  @belongsTo('appbuilder-link/form-element-repeater') formElementRepeater?: AsyncBelongsTo<FormElementRepeater>;
  @belongsTo('appbuilder-link/form-element-format') formElementFormat!: AsyncBelongsTo<FormElementFormat>;
  @belongsTo('appbuilder-link/search-view-layout') searchViewLayout!: AsyncBelongsTo<SearchViewLayout>;
  @hasMany('appbuilder-link/answer-option') answerOptions!: AsyncHasMany<AnswerOption>;

  //included 1:1 from app builder source code -> from DuHa
  get controlType(): ABInputType {
    if (!this.detectedType) {
      return 'text'
    }
    let controltype: ABInputType = 'text';

    if (this.detectedType.toLowerCase() == "auto increment" || this.detectedType.toLowerCase() == "autoincrement") {
      controltype = "autoincrement";
    }
    if (this.detectedType.toLowerCase() == "text input" || this.detectedType.toLowerCase() == "textbox") {
      controltype = "text";
    }
    else if (this.detectedType.toLowerCase() == "text area" || this.detectedType.toLowerCase() == "textarea") {
      controltype = "textarea";
    }
    else if (this.detectedType.toLowerCase() == "email input" || this.detectedType.toLowerCase() == "email") {
      controltype = "email";
    }
    else if (this.detectedType.toLowerCase() == "date" || this.detectedType.toLowerCase() == "date") {
      controltype = "date";
    }
    else if (this.detectedType.toLowerCase() == "amount input" || this.detectedType.toLowerCase() == "amount") {
      controltype = "amount";
    }
    else if (this.detectedType.toLowerCase() == "number input" || this.detectedType.toLowerCase() == "number") {
      controltype = "number";
    }
    else if (this.detectedType.toLowerCase() == "tel input" || this.detectedType.toLowerCase() == "tel") {
      controltype = "tel";
    }
    else if (this.detectedType.toLowerCase() == "ssn input" || this.detectedType.toLowerCase() == "ssn") {
      controltype = "ssn";
    }
    else if (this.detectedType.toLowerCase() == "url input" || this.detectedType.toLowerCase() == "url") {
      controltype = "url";
    }
    else if (this.detectedType.toLowerCase() == "select") {
      controltype = "select";
    }
    else if (this.detectedType.toLowerCase() == "radio" || this.detectedType.toLowerCase() == "MultipleRadio") {
      controltype = "radio";
    }
    else if (this.detectedType.toLowerCase() == "checkbox" || this.detectedType.toLowerCase() == "MultipleCheckboxes") {
      controltype = "checkboxes";
    }
    else if (this.detectedType.toLowerCase() == "multiselect" || this.detectedType.toLowerCase() == "MultiSelect") {
      controltype = "multipleSelect";
    }
    else if (this.detectedType.toLowerCase() == "calculatedfield") {
      controltype = "calculatedfield";
    }

    return controltype;
  }

  static createModel(abLinkId: string, appbuilder: AppBuilder) {
    let model = FormElement.extend({
      form: belongsTo(appbuilder.getAbmModelName('appbuilder-link/form', abLinkId)),
      formElementRepeater: belongsTo(appbuilder.getAbmModelName('appbuilder-link/form-element-repeater', abLinkId)),
      formElementFormat: belongsTo(appbuilder.getAbmModelName('appbuilder-link/form-element-format', abLinkId)),
      searchViewLayout: belongsTo(appbuilder.getAbmModelName('appbuilder-link/search-view-layout', abLinkId)),
      answersOptions: hasMany(appbuilder.getAbmModelName('appbuilder-link/answer-option', abLinkId)),
    });
    return model;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/form-element': FormElement;
  }
}


