import Model, { AsyncBelongsTo, AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import AppBuilder from 'cing-app/pods/appbuilder/service';
import Case from './case';
import FormElement from './form-element';
import FormElementRepeater from './form-element-repeater';
import FormExcelSheet from './form-excel-sheet';

export default class FormModel extends Model {
  @attr("number") parentId?: number;
  @attr("number") caseId!: number;
  @attr("number") answerTypeId?: number;
  @attr("string") name!: string;
  @attr("string") formCaption?: string;
  @attr("string") templateData?: string;
  @attr("number") createUser?: number;
  @attr("number") modifyUser?: number;
  @attr('date') createTime!: Date;
  @attr('date') modifyTime!: Date;
  //@ts-ignore
  @attr("jsonb") jsonData!: object;
  @attr("string") formType?: string;
  @attr("string") nameId?: string;
  @attr("string") formTemplatePath?: string;
  @attr("string") claimaintTemplateData?: string;
  @attr("boolean") isCaptchaEnabled!: boolean;
  @attr("boolean") isFileuploadEnabled!: boolean;
  @attr() supportedFiles?: string;
  @attr("boolean") isSmartRoomEnabled!: boolean;

  //@ts-ignore
  @belongsTo('appbuilder-link/case') case!: AsyncBelongsTo<Case>;
  @hasMany('appbuilder-link/form-element') formElements?: AsyncHasMany<FormElement>;
  @hasMany('appbuilder-link/form-element-repeater') formElementRepeaters?: AsyncHasMany<FormElementRepeater>;
  @hasMany('appbuilder-link/form-excel-sheet') formExcelSheets?: AsyncHasMany<FormExcelSheet>;

  static createModel(abLinkId: string, appbuilder: AppBuilder) {
    let model = FormModel.extend({
      case: belongsTo(appbuilder.getAbmModelName('appbuilder-link/case', abLinkId)),
      formElements: hasMany(appbuilder.getAbmModelName('appbuilder-link/form-element', abLinkId)),
      formElementRepeaters: hasMany(appbuilder.getAbmModelName('appbuilder-link/form-element-repeater', abLinkId)),
      formExcelSheets: hasMany(appbuilder.getAbmModelName('appbuilder-link/form-excel-sheet', abLinkId)),
    });
    return model;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/form': FormModel;
  }
}


