import { isEmpty } from '@ember/utils';
import Transform from '@ember-data/serializer/transform';

export default Transform.extend({
  deserialize(serialized) {
    try {
      return JSON.parse(serialized);
    } catch (e) {
      return {};
    }
    /*
    if (isEmpty(serialized)) {
        return {};
    } else {
        return JSON.parse(serialized);
    }
    */
  },

  serialize(deserialized) {
    if (isEmpty(deserialized)) {
      return JSON.stringify({});
    } else {
      return JSON.stringify(deserialized);
    }
  },
});
