import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import AppbuilderLink from './appbuilder-link';
import { buildValidations, validator } from 'ember-cp-validations';
import FormElement, { ABInputType } from './appbuilder-link/form-element';
import moment from 'moment';
import Abstract from './abstract';
// import { computed } from '@ember/object';
import { formatSize } from 'cing-app/helpers/format-size';

const Validations = buildValidations({
  minWidth: [
    validator('presence', {
      presence: true,
      // message: 'Min Width or Width is required!',
      // disabled: computed('model.width', function () {
      // 	return !!this.model.width;
      // }).volatile()
    }),
    validator('number', {
      integer: true,
      positive: true,
    }),
  ],
  width: [
    // validator('presence', {
    // 	presence: true,
    // message: 'Min Width or Width is required!',
    // disabled: computed('model.minWidth', function () {
    // 	return !!this.model.minWidth;
    // }).volatile()
    // }),
    validator('number', {
      integer: true,
      positive: true,
    }),
  ],
  maxHeight: validator('number', {
    integer: true,
    positive: true,
  }),
});

export class ABColumnFormat {
  style:
    | 'general'
    | 'decimal'
    | 'currency'
    | 'percent'
    | 'unit'
    | 'fileSize'
    | 'date'
    | 'custom' = 'general';

  //number properties
  unit?: string;
  unitDisplay?: 'long' | 'short' | 'narrow' = 'narrow';
  useGrouping?: boolean = true;

  signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
  maximumFractionDigits?: number;

  currency: string = 'USD';
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name' = 'symbol';
  currencySign?: 'accounting' | 'standard' = 'standard';

  negativeRed?: boolean;

  //date time properties
  dateStyle?: 'full' | 'long' | 'medium' | 'short';
  timeStyle?: 'full' | 'long' | 'medium' | 'short';

  //
  customFormat?: string;

  constructor(
    data: any /*{ [key in keyof ABColumnFormat]: ABColumnFormat[key] }*/
  ) {
    Object.assign(this, data);
  }

  static aBInputTypeToStyle(inputType: ABInputType): ABColumnFormat['style'] {
    switch (inputType) {
      case 'text':
      case 'autoincrement':
      case 'textarea':
      case 'email':
      case 'tel':
      case 'url':
      case 'select':
      case 'radio':
      case 'checkboxes':
      case 'multipleSelect':
      case 'calculatedfield':
        return 'general';
      case 'number':
        return 'decimal';
      case 'date':
        return 'date';
      case 'amount':
        return 'currency';
      case 'ssn':
        return 'custom';
    }
  }

  escapeRegExp(string?: string) {
    if (!string) {
      return '';
    }
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  static formElementToAbColumnFormat(
    element: FormElement
  ): ABColumnFormat | undefined {
    return new ABColumnFormat({
      style: ABColumnFormat.aBInputTypeToStyle(element.controlType),
      //@ts-ignore
      maximumFractionDigits: element.get('formElementFormat.decimalPlaces'),
      //@ts-ignore
      useGrouping: element.get('formElementFormat.useSeparator'),
    });
  }

  formatValue(value: any, language?: string): string {
    if (!language) {
      //@ts-ignore
      language = window.navigator.userLanguage || window.navigator.language;
    }

    if (value === undefined || value === null) {
      return '';
    }

    switch (this.style) {
      case 'general':
        return value;
      case 'custom':
        if (!this.customFormat) {
          return value;
        }

        let replacement = this.customFormat ?? '';
        let i = 1;

        while (true) {
          let ia = replacement.indexOf('a');
          let i9 = replacement.indexOf('9');
          let iStar = replacement.indexOf('*');

          let replaced = replacement;
          if (
            ia !== -1 &&
            (ia < i9 || i9 === -1) &&
            (ia < iStar || iStar === -1)
          ) {
            replaced = replacement.replace(/a+/, '$' + i++);
          } else if (
            i9 !== -1 &&
            (i9 < ia || ia === -1) &&
            (i9 < iStar || iStar === -1)
          ) {
            replaced = replacement.replace(/9+/, '$' + i++);
          } else if (iStar !== -1) {
            replaced = replacement.replace(/[*]+/, '$' + i++);
          }

          if (replaced === replacement) {
            break;
          } else {
            replacement = replaced;
          }
        }

        let pattern =
          this.customFormat
            ?.replace(/[*]/g, 's')
            .replace(/9/g, 'e')
            .replace(/a/g, 'b')
            .replace(/[^seb]/g, 'r') ?? '';
        while (true) {
          let res9 = pattern.match(/(e+)/);
          if (res9 && res9[0]) {
            pattern = pattern.replace(/e+/, `([0-9]{${res9[0].length}})`);
          } else {
            let resa = pattern.match(/(b+)/);
            if (resa && resa[0]) {
              pattern = pattern.replace(/b+/, `([a-zA-Z]{${resa[0].length}})`);
            } else {
              let resStar = pattern.match(/(s+)/);
              if (resStar && resStar[0]) {
                pattern = pattern.replace(
                  /s+/,
                  `([0-9a-zA-Z]{${resStar[0].length}})`
                );
              } else {
                break;
              }
            }
          }
        }
        pattern = pattern.replace(/r/g, '');
        // pattern = pattern.replace('9+', '[0-9]+');
        // pattern = pattern.replace('a+', '[a-zA-Z]+');
        // pattern = pattern.replace('*+', '[0-9a-zA-Z]+');

        return value.toString().replace(new RegExp(pattern), replacement);
      case 'date':
        if (typeof value === 'string') {
          if (moment.utc(value).isValid()) {
            value = moment.utc(value).toDate();
          } else {
            return '';
          }
        }
        //@ts-ignore
        return new Intl.DateTimeFormat(language, this).format(value);
      case 'fileSize':
        return formatSize([value]);
      default:
        let val = parseFloat(value);
        if (Number.isNaN(val)) {
          return '';
        }
        return new Intl.NumberFormat(language, this).format(val);
    }
  }

  formatValueHtml(value: any) {
    let formatted = this.formatValue(value);
    if (
      !Number.isNaN(parseInt(value)) &&
      this.negativeRed &&
      parseInt(value) < 0
    ) {
      let div = document.createElement('div');
      div.innerText = formatted;
      return `<span class='formatted-value_negative-red'>${div.innerHTML}</span>`;
    } else {
      return formatted;
    }
  }
}

export default class AppbuilderLinkColumn extends Abstract.extend(
  Validations,
  {}
) {
  @belongsTo('appbuilder-link', { async: false })
  declare ABLink: AsyncBelongsTo<AppbuilderLink>;
  @attr('string')
  declare objectName: string;
  @attr('string')
  declare propertyName: string;
  @attr('string')
  declare label: string;
  @attr('boolean', { defaultValue: false })
  declare defaultVisible: boolean;
  @attr('boolean', { defaultValue: false })
  declare simpleFilter: boolean;
  @attr('boolean', { defaultValue: false })
  declare advancedFilter: boolean;
  @attr('boolean', { defaultValue: false })
  declare enableCharts: boolean;
  @attr('boolean', { defaultValue: false })
  declare sorting: boolean;
  @attr('number')
  declare sortOrder?: number;
  @attr('boolean')
  declare defaultAscending?: boolean;
  @attr('string', { defaultValue: 'L' })
  declare textAlign: 'R' | 'C' | 'L';
  @attr('number')
  declare displayOrder: number;
  @attr('number', { defaultValue: 100 })
  declare minWidth: number;
  @attr('number')
  declare maxWidth?: number;
  @attr('number')
  declare maxHeight?: number;
  @attr('boolean', { defaultValue: true })
  declare wrapText: boolean;
  @attr('ab-format')
  declare format?: ABColumnFormat;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link-column': AppbuilderLinkColumn;
  }
}
