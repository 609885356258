import classic from 'ember-classic-decorator';
import { action, get, set } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { AclPermissionType } from 'cing-app/utils/lookups';

@classic
@classNames('power-select-sm')
export default class ColumnViewGroup extends Component {
  @service
  store;

  init() {
    super.init();
    let selected = this.column.options.findBy('name', get(this.record, this.column.propertyName));
    set(this, 'selected', selected);
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  click(e) {
    return false;
  }

  @action
  hideCreateOnSame(term) {
    let existingOption = this.column.options.findBy('name', term);
    return !existingOption;
  }

  @action
  onSelect(option) {
    set(this, 'selected', option);

    if (option) {
      set(this.record, this.column.propertyName, option.name);
    } else {
      this.set(`record.${this.column.propertyName}`, null);
    }
  }

  @action
  create(newTypeName, options) {
    this.createTask.perform(newTypeName)
  }

  @action
  remove(option) {
    option.destroyRecord().then((result) => {
      this.get('column.options').removeObject(option);
    });
  }
}