import Component from '@ember/component';
import { computed, set, get, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import {
  AclPermissionType,
  AclPermissionTypeEnum,
} from 'cing-app/utils/lookups';
import {
  CategoryAlreadyExists,
  FolderAlreadyExists,
} from 'cing-app/pods/smartroom/service';
import { IsProjectType } from 'cing-app/helpers/is-project-type';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import classic from 'ember-classic-decorator';

@classic
export default class SearchCRM extends Component {
  @service('docker-item') docker;
  @service store;
  searchType = 'dealcloud';
  selectedContactEmail = null;
  selectedCompany = null;

  init() {
    super.init();
    this.set('contacts', []);

    this.initTask.perform();
  }

  @task
  *initTask() {
    let selectedCompany = null;

    if (IsProjectType(['fund', get(this.project, 'projectType.name')])) {
      selectedCompany = yield get(this.model, 'investor.company');
    } else {
      selectedCompany = yield get(this.model, 'company');
    }

    this.set('selectedCompany', selectedCompany);
  }

  @task({
    restartable: true,
  })
  *searchCompaniesTask(term) {
    yield timeout(300);

    if (!term) {
      this.set('lastSearchCompanyText', null);
      return null;
    }

    this.set('lastSearchCompanyText', term);

    let expr = Expressions.create();

    expr.add(
      Filter.create({
        name: 'name',
        operator: FilterOperators.LIKE,
        value: term,
      })
    );

    expr.add(
      Filter.create({
        name: 'master-id',
        operator: FilterOperators.NULL,
      })
    );

    return yield this.get('store').query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 50, number: 1 },
    });
  }

  @task({
    restartable: true,
  })
  *searchContactsTask(term) {
    yield timeout(300);

    let company = yield this.get('model.company');

    if (!term && !company) {
      this.set('lastSearchText', null);
      return null;
    }

    this.set('lastSearchText', term);

    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    if (company) {
      expr.add(
        Filter.create({
          name: 'companies.id',
          operator: FilterOperators.EQUAL,
          value: company.get('id'),
        })
      );
    }

    if (term) {
      let termParts = term.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            name: 'first-name',
            operator: FilterOperators.LIKE,
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            name: 'last-name',
            operator: FilterOperators.LIKE,
            value: termParts[termParts.length - 1],
          })
        );

        exprSearch.add(nameExpression);
      }

      ['first-name', 'last-name', 'emails.email-address'].forEach(
        (propName) => {
          exprSearch.add(
            Filter.create({
              name: propName,
              operator: FilterOperators.LIKE,
              value: term,
            })
          );
        }
      );

      expr.add(exprSearch);
    }

    let contacts = yield this.get('store').query('person', {
      condition: expr.serialize(),
      include: 'emails,company',
      sort: 'last-name',
      page: { size: 50, number: 1 },
    });

    this.set('contacts', contacts);
  }

  @action
  viewPerson(person) {
    this.docker.popupPerson(person);
  }

  @action
  viewCompany(company) {
    this.docker.popupCompany(company);
  }

  @action
  selectPerson(person) {
    this.model.setProperties({
      person: person,
      personId: person ? person.id : null,
      email: null,
      emailId: null,
    });

    if (person) {
      person.get('emails').then((emails) => {
        emails = emails.toArray();

        if (emails.length > 1) {
          this.set('selectContactEmails', emails);
          this.set('selectedContactEmail', emails.firstObject);
        } else {
          let firstEmail = emails.firstObject;

          if (firstEmail) {
            this.set('model.email', firstEmail);
          }

          if (this.onSelectPerson) {
            this.onSelectPerson(person);
          }

          let company = get(person, 'company');

          if (company) {
            this.selectCompany.perform(company);
          }
        }
      });
    }
  }

  @task
  *selectCompany(comp) {
    let company = yield comp;
    this.set('selectedCompany', company);

    if (this.onSelectCompany) {
      this.onSelectCompany(company);
    }
  }

  @action
  selectContactEmail() {
    this.model.set('email', this.selectedContactEmail);
    this.set('selectContactEmails', null);
    this.onSelectPerson(this.model.person);
  }

  @action
  createContact() {
    // just to parse the lastSearchText to extract first/last name
    let newPerson = this.store.createRecord('person');
    newPerson.parseFromFullName(this.lastSearchText);

    this.docker.popupEditPerson(newPerson, {
      onCreate: (person) => {
        this.selectPerson(person);
      },
    });
  }

  @action
  createCompany() {
    // let self = this;
    let appearance = {
      icon: 'edit',
      title: `<small>Company: </small>Add new`,
      size: 'medium',
      custom: true,
    };

    let newCompany = this.get('store').createRecord('company', {
      name: this.lastSearchCompanyText,
    });

    let context = {
      company: newCompany,
      closeOnCreate: true,

      onCreate: (company) => {
        this.selectCompany.perform(company);
      },
    };

    this.get('docker').invokePopup('manage-company', appearance, context);
  }

  @action
  showInvestorDetail(investor) {
    if (investor) {
      const appearance = {
        icon: '',
        title: 'Investor detail: ' + investor.get('company.name'),
        custom: true,
      };

      const context = {
        project: this.project,
        investor: investor,
      };
      this.get('docker').invokePopup('investor-detail', appearance, context);
    } else {
      alert('This contact is not associated with an');
    }
  }
}
