import Component from '@glimmer/component';

interface SendingDomainsStatusArgs {
  status: string,
  check: boolean,
  record: any,
  date: Date,
}

export default class SendingDomainsStatus extends Component<SendingDomainsStatusArgs> {}
