import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

@classic
export default class AccessAgreementViewTs extends Model {
  @attr('string')
  templateTitle;

  @attr('number', { defaultValue: -1 })
  requiredAccepts; // -1 always show, 0 - never show

  @attr('number', { ro: true })
  numberOfAccepts;

  @attr('number')
  templateId;

  @attr('number')
  siteId;

  @attr('number')
  templateTypeId;

  /*
  @hasMany('category', { async: true })
  categories;
  */

  @attr()
  textContent;

  @attr('boolean')
  editable;

  @attr('rdate')
  dateCreated;

  @attr('boolean')
  isDefault;

  //languages           : hasMany('language',{async:true}),

  /*
  @computed('categories')
  get categoryNames() {
    return this.categories.map(c => { return c.get('categoryName'); }).join(', ');
  }
  */
}
