import Service, { inject as service } from '@ember/service';

// source supported AB models
import Investor from '../../models/ab/investors/investor';
import EventDetail from '../../models/ab/investors/event-detail';
import Contact from '../../models/ab/investors/contact';
import Distribution from '../../models/ab/investors/distribution';
import CapitalCall from '../../models/ab/investors/capital-call';
import PaymentMethod from '../../models/ab/investors/payment-method';
import PcapInvestorData from '../../models/ab/investors/pcap-investor-data';
// source serializers
import ABSerializer from '../../serializers/ab/investors/app-builder';
import ABAdapter from '../../adapters/ab/investors/app-builder';

import { getOwner } from '@ember/application';

const AB_MODELS = {
  'investor': {
    model: Investor,
    serializer: ABSerializer,
    adapter: ABAdapter,
  },
  'event-detail': {
    model: EventDetail,
    serializer: ABSerializer,
    adapter: ABAdapter,
  },
  'contact': {
    model: Contact,
    serializer: ABSerializer,
    adapter: ABAdapter,
  },
  'distribution': {
    model: Distribution,
    serializer: ABSerializer,
    adapter: ABAdapter,
  },
  'capital-call': {
    model: CapitalCall,
    serializer: ABSerializer,
    adapter: ABAdapter,
  },
  'payment-method': {
    model: PaymentMethod,
    serializer: ABSerializer,
    adapter: ABAdapter,
  },
  'pcap-investor-data': {
    model: PcapInvestorData,
    serializer: ABSerializer,
    adapter: ABAdapter,
  }
}

export default Service.extend({
  store: service(),
  config: service(),
  _cache: {},

  init() {
    this._super();
  },
  getModelName(project, modelName) {
    let abModelName = null;
    let projectId = project.id;

    if (!this._cache[projectId] || !this._cache[projectId][modelName]) {
      abModelName = this._registerABModel(projectId, modelName);
    }

    try {
      abModelName = this._cache[projectId][modelName];
    } catch (e) {
      console.error(e);
      throw new Error("AB Model for : " + projectId + "/" + modelName + " is not available.");
    }

    console.log("getModelName: ", this._cache);

    return abModelName;
  },
  getModelNameOld(abProject, modelName) {
    let abModelName = null;

    if (!this._cache[abProject] || !this._cache[abProject][modelName]) {
      abModelName = this._registerABModel(abProject, modelName);
    }

    try {
      abModelName = this._cache[abProject][modelName];
    } catch (e) {
      console.error(e);
      throw new Error("AB Model for : " + abProject + "/" + modelName + " is not available.");
    }

    console.log("getModelName: ", this._cache);

    return abModelName;
  },

  _getABModelName(project, model) {
    return `projects/${project}/ab/${model}`;
  },

  _getABModelNameOld(project, model) {
    return `cases/${project}/ab/${model}`;
  },

  _registerABModel(abProject, modelName) {
    let abModel = this._getABModelName(abProject, modelName);

    getOwner(this.store).register(`adapter:${abModel}`, (AB_MODELS[modelName].adapter).extend({}));
    getOwner(this.store).register(`serializer:${abModel}`, (AB_MODELS[modelName].serializer).extend({}));
    getOwner(this.store).register(`model:${abModel}`, (AB_MODELS[modelName].model).extend({}));

    if (!this._cache[abProject]) {
      this._cache[abProject] = {};
    }

    this._cache[abProject][modelName] = abModel;
  }
});