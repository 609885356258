import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import DocketConsolidatedType from './docket-consolidated-type';

export default class DocketTypeGroup extends Abstract {
  @attr('string') declare name?: string;
  @attr('string') declare description?: string;
  @attr('string') declare parentId?: string;
  @belongsTo('docket-type-group', { inverse: null })
  declare parent: AsyncBelongsTo<DocketTypeGroup>;
  @attr('number') declare order: number;
  @attr('boolean') declare visible: boolean;
  @attr('boolean') declare deleted?: boolean;
  // docketType: DS.hasMany('docket-type', {inverse: null}),
  @belongsTo('docket-consolidated-type', { inverse: null })
  declare docketConsolidatedType: AsyncBelongsTo<DocketConsolidatedType>;
  get hasParent() {
    return !!this.get('parentId');
  }
  get displayName() {
    let store = this.get('store');

    let n = '<strong>' + this.name + '</strong>';

    if (this.parentId != null) {
      let parent = store.peekRecord('docket-type-group', this.parentId);

      if (parent) {
        n = '<strong>' + parent.get('name') + '</strong> > ' + n;
      } else {
        n = '<strong>' + this.get('parent')?.get('name') + '</strong> > ' + n;
      }
    }
    return n;
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'docket-type-group': DocketTypeGroup;
  }
}
