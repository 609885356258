import Abstract from './abstract';
import { attr } from '@ember-data/model';
//@ts-ignore
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import DataLinkModel from './data-link-model';
import DataLinkView from './data-link-view';
import DataLinkForm from './data-link-form';
import DataLinkRelationship from './data-link-relationship';

export class ValueFormat {
  style:
    | 'general'
    | 'decimal'
    | 'currency'
    | 'percent'
    | 'unit'
    | 'fileSize'
    | 'date'
    | 'custom' = 'general';

  //number properties
  unit?: string;
  unitDisplay?: 'long' | 'short' | 'narrow' = 'narrow';
  useGrouping?: boolean = true;

  signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
  maximumFractionDigits?: number;

  currency: string = 'USD';
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name' = 'symbol';
  currencySign?: 'accounting' | 'standard' = 'standard';

  negativeRed?: boolean;

  //date time properties
  dateStyle?: 'full' | 'long' | 'medium' | 'short';
  timeStyle?: 'full' | 'long' | 'medium' | 'short';

  //
  customFormat?: string;

  constructor(
    data: any /*{ [key in keyof ABColumnFormat]: ABColumnFormat[key] }*/
  ) {
    Object.assign(this, data);
  }

  escapeRegExp(string?: string) {
    if (!string) {
      return '';
    }
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  formatValue(value: any, language?: string): string {
    if (!language) {
      //@ts-ignore
      language = window.navigator.userLanguage || window.navigator.language;
    }

    if (value === undefined || value === null) {
      return '';
    }

    switch (this.style) {
      case 'general':
        return value;
      case 'custom':
        if (!this.customFormat) {
          return value;
        }

        let replacement = this.customFormat ?? '';
        let i = 1;

        while (true) {
          let ia = replacement.indexOf('a');
          let i9 = replacement.indexOf('9');
          let iStar = replacement.indexOf('*');

          let replaced = replacement;
          if (
            ia !== -1 &&
            (ia < i9 || i9 === -1) &&
            (ia < iStar || iStar === -1)
          ) {
            replaced = replacement.replace(/a+/, '$' + i++);
          } else if (
            i9 !== -1 &&
            (i9 < ia || ia === -1) &&
            (i9 < iStar || iStar === -1)
          ) {
            replaced = replacement.replace(/9+/, '$' + i++);
          } else if (iStar !== -1) {
            replaced = replacement.replace(/[*]+/, '$' + i++);
          }

          if (replaced === replacement) {
            break;
          } else {
            replacement = replaced;
          }
        }

        let pattern =
          this.customFormat
            ?.replace(/[*]/g, 's')
            .replace(/9/g, 'e')
            .replace(/a/g, 'b')
            .replace(/[^seb]/g, 'r') ?? '';
        while (true) {
          let res9 = pattern.match(/(e+)/);
          if (res9 && res9[0]) {
            pattern = pattern.replace(/e+/, `([0-9]{${res9[0].length}})`);
          } else {
            let resa = pattern.match(/(b+)/);
            if (resa && resa[0]) {
              pattern = pattern.replace(/b+/, `([a-zA-Z]{${resa[0].length}})`);
            } else {
              let resStar = pattern.match(/(s+)/);
              if (resStar && resStar[0]) {
                pattern = pattern.replace(
                  /s+/,
                  `([0-9a-zA-Z]{${resStar[0].length}})`
                );
              } else {
                break;
              }
            }
          }
        }
        pattern = pattern.replace(/r/g, '');
        // pattern = pattern.replace('9+', '[0-9]+');
        // pattern = pattern.replace('a+', '[a-zA-Z]+');
        // pattern = pattern.replace('*+', '[0-9a-zA-Z]+');

        return value.toString().replace(new RegExp(pattern), replacement);
      case 'date':
        if (typeof value === 'string') {
          if (moment.utc(value).isValid()) {
            value = moment.utc(value).toDate();
          } else {
            return '';
          }
        }
        //@ts-ignore
        return new Intl.DateTimeFormat(language, this).format(value);
      case 'fileSize':
        let v = parseFloat(value);
        if (Number.isNaN(v)) {
          return '';
        }
        let f = Object.assign({}, this);
        f.style = 'unit';
        v = v / 8;

        if (v > Math.pow(1024, 5)) {
          v = v / Math.pow(1024, 5);
          f.unit = 'petabyte';
        } else if (v > Math.pow(1024, 4)) {
          v = v / Math.pow(1024, 4);
          f.unit = 'terabyte';
        } else if (v > Math.pow(1024, 3)) {
          v = v / Math.pow(1024, 3);
          f.unit = 'gigabyte';
        } else if (v > Math.pow(1024, 2)) {
          v = v / Math.pow(1024, 2);
          f.unit = 'megabyte';
        } else if (v > 1024) {
          v = v / 1024;
          f.unit = 'kilobyte';
        } else {
          f.unit = 'byte';
        }

        return new Intl.NumberFormat(language, f).format(v);
      default:
        let val = parseFloat(value);
        if (Number.isNaN(val)) {
          return '';
        }
        return new Intl.NumberFormat(language, this).format(val);
    }
  }

  formatValueHtml(value: any) {
    let formatted = this.formatValue(value);
    if (
      !Number.isNaN(parseInt(value)) &&
      this.negativeRed &&
      parseInt(value) < 0
    ) {
      let div = document.createElement('div');
      div.innerText = formatted;
      return `<span class='formatted-value_negative-red'>${div.innerHTML}</span>`;
    } else {
      return formatted;
    }
  }
}

export default class DataLink extends Abstract {
  @attr('string') declare name: string;
  @fragmentArray('data-link-model') declare models: DataLinkModel[];
  @fragmentArray('data-link-relationship')
  declare relationships: DataLinkRelationship[];
  @fragmentArray('data-link-view') declare views: DataLinkView[];
  @fragmentArray('data-link-form') declare forms: DataLinkForm[];
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'data-link': DataLink;
  }
}
