import Model, { attr } from '@ember-data/model';

export default Model.extend({
  categoryName: attr('string'),
  topLevelFolder: attr('string'),
  stageId: attr('number'),
  simpleRightTypeId: attr('number'),
  siteOwner: attr('boolean'),
  suspended: attr('boolean')
});
