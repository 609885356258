define("cing-app/components/test-test", ["exports", "ember-smartidentity-auth/components/test-test"], function (_exports, _testTest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _testTest.default;
    }
  });
});
