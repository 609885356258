import Component from '@glimmer/component';
import { InternalModels } from '../../models';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency'
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import DataLink from 'cing-app/models/data-link';
import DataLinkModel from 'cing-app/models/data-link-model';

import DockerItemService from '../../../../docker-item/service';

interface DataLinkModelsArgs {
	model: DataLink;
	onUpdate?: any
}

export default class Models extends Component<DataLinkModelsArgs> {
	InternalModels = InternalModels;


	constructor(owner: any, args: DataLinkModelsArgs) {
		super(owner, args);
	}

	@tracked selectedNewModel!: object | null;
	@tracked showAddNewModel: boolean = false;

	@service declare store: Store;
	@service('docker-item') declare docker: DockerItemService;

	@action
	selectNewModel(newModel: object) {
		this.selectedNewModel = newModel;
	}

	@action
	addNewModel() {
		this.selectedNewModel = null;
		this.showAddNewModel = true;
	}

	@action
	cancelNewModel() {
		this.selectedNewModel = null;
		this.showAddNewModel = false;
	}

	@action
	editModel(model: DataLinkModel) {
		const appearance = {
			label: 'Edit DataLink Model',
			icon: '',
			title: 'Edit DataLink Model',
			custom: true,
			size: 'large'
		};

		this.docker.invokePopup('manage-data-links/detail/models/edit', appearance, {
			model: model,
			dataLink: this.args.model
		});
	}

	@action
	removeModel(model: DataLinkModel) {
		this.args.model.models.removeObject(model);
	}

	@task
	addNewModelTask = taskFor(async (): Promise<any> => {
		if (this.selectedNewModel) {
			//@ts-ignore
			let modelFragment = this.store.createFragment('data-link-model', {
				modelName: this.selectedNewModel.modelName,
				name: this.selectedNewModel.name
			});

			this.args.model.models.pushObject(modelFragment);
		}
		this.showAddNewModel = false;
	});
}