import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import { all, task } from 'ember-concurrency';
// import {
//   AclPermissionType,
//   AclEntityType,
//   AclRoleType
// } from 'cing-app/utils/lookups';
// import { validator, buildValidations } from 'ember-cp-validations';
// import { getOwner } from "@ember/application";
import DockerItemService from 'cing-app/pods/docker-item/service';
import Router from 'cing-app/router';
import _SessionService from 'cing-app/pods/session/service';
import Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import Person from 'cing-app/models/person';
import { taskFor } from 'ember-concurrency-ts';
//@ts-ignore
import podNames from 'ember-component-css/pod-names';
import UserGroup from 'cing-app/models/user-group';
import User from 'cing-app/models/user';

interface ManageUsersArgs {
  tagName: string | null;
  footer: any;
}

// const NewUserValidations = buildValidations({
//   email: [
//     validator('presence', true),
//     validator('format', { type: 'email' })
//   ],
//   role: validator('presence', true),
// });

// class NewUser extends EmberObject.extend(NewUserValidations) { }

export default class ManageUsers extends Component {
  classNames = ['d-flex', 'flex-column', 'flex-grow-1'];
  include =
    'instance-acl-role-assignments.acl-role.acl-permissions,groups,person.company,user-in-groups.user-group';
  @service store!: Store;
  @service config: any;
  @service session!: _SessionService;
  @service router!: Router;
  @service('docker-item') docker!: DockerItemService;

  @tracked
  selectedItems: User[];

  @tracked
  searchTerm = '';

  @tracked
  confirmGroupChange: null | UserGroup = null;

  @tracked
  userGroups: UserGroup[] | null = null;

  @tracked
  searchGroups: UserGroup[] | null = null;

  @tracked
  reloadData = false;

  @tracked
  confirmRemoval: User | null = null;

  @tracked
  selectedGroups: UserGroup[];

  // @tracked
  // confirmAddRole;

  @tracked
  filter = '';

  @tracked
  columns: object[];

  constructor(owner: any, args: ManageUsersArgs) {
    super(owner, args);

    //this.store.query('user-to-person', {});
    this.selectedItems = [];
    this.selectedGroups = [];

    // set(this, 'columns', this.getColumns());
    this.generateFilter();

    // this.store.query('acl-role', {
    //   //'filter[type]': 'eq:' + AclRoleType.PerEntity,
    //   page: {
    //     size: 1000
    //   },
    //   sort: 'description'
    // }).then((aclRoles) => {
    //   set(this, 'aclRoles', aclRoles);
    // });

    this.store
      .query('user-group', {
        //'filter[type]': 'eq:' + AclRoleType.PerEntity,
        page: {
          size: 1000,
        },
        sort: 'name',
      })
      .then((groups) => {
        this.userGroups = groups.toArray();
      });

    this.columns = this.getColumns();
  }

  getColumns() {
    let columns = [
      {
        title: 'Contact Name',
        propertyName: 'person',
        component: 'manage-users/users/column-person',
        sortedBy: 'person.last-name',
        openPersonDetail: this.openPersonDetail,
      },
      {
        title: 'Company',
        propertyName: 'person',
        sortedBy: 'person.company.name',
        component: 'manage-users/users/column-company',
        openCompanyDetail: this.openCompanyDetail,
      },
      {
        propertyName: 'email',
        title: 'E-mail',
        sortPrecedence: 0,
        sortDirection: 'asc',
        className: 'text-break',
      },
      {
        propertyName: 'instanceAclRoleAssignments',
        title: 'User Roles',
        disableSorting: true,
        component: 'manage-users/users/column-roles',
      },
      {
        propertyName: 'groups',
        title: 'User Groups',
        disableSorting: true,
        component: 'manage-users/users/column-groups',
      },
      {
        propertyName: 'createTime',
        title: 'Create Time',
        component: 'api-table/columns/date',
        className: 'column-datetime',
        dateFormat: 'YYYY/MM/DD hh:mm a',
      },
      {
        propertyName: 'lastInviteDate',
        title: 'Last Notification Sent',
        component: 'api-table/columns/date',
        className: 'column-datetime',
        dateFormat: 'YYYY/MM/DD hh:mm a',
      },
      {
        propertyName: 'lastAccess',
        title: 'Last Access',
        component: 'api-table/columns/date',
        className: 'column-datetime',
        dateFormat: 'YYYY/MM/DD hh:mm a',
      },
      {
        title: '',
        component: 'manage-users/users/column-actions',
        className: 'column-actions',
        selectedItems: this.selectedItems,
        editUser: this.editUser,
        removeUser: this.removeUser,
        addGroup: this.openUserGroupDetail,
        sendAlert: this.sendAlert,
        viewAs: this.viewAs,
        viewAsSmartExchange: this.viewAsSmartExchange,
      },
      {
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        mayBeHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      },
    ];

    return columns;
  }

  @action
  openUserGroupDetail(user: User) {
    this.selectedGroups = [];
    this.confirmGroupChange = user;

    // array of selected users
    let users = this.selectedItems.toArray();

    // adding user that is not selected
    users.removeObject(user);
    users.unshiftObject(user);

    // for every user perform
    for (let i = 0; i < users.length; i++) {
      let existingGroups = users[i].groups.toArray();

      // for every user group
      for (let i = 0; i < existingGroups.length; i++) {
        if (!this.selectedGroups.includes(existingGroups[i])) {
          this.selectedGroups.push(existingGroups[i]);
        }
      }
    }
  }

  @action
  openCompanyDetail(person: Person) {
    this.docker.popupCompany(person.get('company'));
  }

  @action
  openPersonDetail(person: Person) {
    const appearance = {
      icon: 'user',
      title: '<small>Profile:</small> ' + person.get('fullName'),
      size: 'large',
    };
    const context = {
      personId: person.get('id'),
      company: person.get('company'),
    };
    this.docker.invokePopup('contact-detail', appearance, context);
  }

  get styleNamespace() {
    return podNames['manage-users/users'];
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    let searchTerm = this.searchTerm;
    if (searchTerm) {
      /*
      expr.add(Filter.create({
          name: 'email',
          operator: FilterOperators.LIKE,
          value: searchTerm
      }));
      */

      let termParts = searchTerm.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            //@ts-ignore
            name: 'person.first-name',
            operator: FilterOperators.START_WITH,
            //@ts-ignore
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            //@ts-ignore
            name: 'person.last-name',
            operator: FilterOperators.START_WITH,
            //@ts-ignore
            value: termParts[termParts.length - 1],
          })
        );

        exprSearch.add(nameExpression);
      }

      ['person.first-name', 'person.last-name', 'email'].forEach((propName) => {
        exprSearch.add(
          Filter.create({
            //@ts-ignore
            name: propName,
            operator: FilterOperators.LIKE,
            //@ts-ignore
            value: searchTerm,
          })
        );
      });

      expr.add(exprSearch);
    }

    // if (this.selectedRoles && this.selectedRoles.length) {
    //   expr.add(Filter.create({
    //     //@ts-ignore
    //     name: 'acl-user-roles.acl-role.id',
    //     operator: FilterOperators.IN,
    //     value: this.selectedRoles.mapBy('id')
    //   }))
    // }

    if (this.searchGroups && this.searchGroups.length) {
      expr.add(
        Filter.create({
          //@ts-ignore
          name: 'groups.id',
          operator: FilterOperators.IN,
          //@ts-ignore
          value: this.searchGroups.mapBy('id'),
        })
      );
    }

    this.filter = expr.serialize();
  }

  // @task
  // *prepareAddUserDataTask(user) {
  //   set(this, 'addNewUser', true);

  //   let aclRoles = yield this.store.query('acl-role', {
  //     'filter[type]': 'eq:' + AclRoleType.PerEntity,
  //     page: {
  //       size: 1000
  //     }
  //   });

  //   set(this, 'aclRoles', aclRoles);

  //   let modelData = {};

  //   if (user) {
  //     modelData.email = get(user, 'email')
  //   }

  //   set(this, 'newUserModel', NewUser.create(
  //     getOwner(this).ownerInjection(), // official workaround for container owner
  //     modelData
  //   ));
  // }

  @task
  removeRecordTask = taskFor(async (party: User) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      itemsToSave.push(this.removeRecordForPartyTask.perform(<User>items[a]));
    }

    await all(itemsToSave);

    // we need to clear selectedItems upon removing!!!
    this.selectedItems.clear();

    this.confirmRemoval = null;
    this.reloadData = true;
  });

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  removeRecordForPartyTask = taskFor(async (party: User) => {
    await party.destroyRecord();
  });

  @task
  assignUserGroupsTask = taskFor(async (party: User) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      itemsToSave.push(this.addUserToGroupTask.perform(items[a]));
    }

    await all(itemsToSave);

    // we need to clear selectedItems upon removing!!!
    this.selectedItems.clear();

    this.confirmGroupChange = null;
    this.reloadData = true;
    this.selectedGroups = null;
  });

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  addUserToGroupTask = taskFor(async (user) => {
    let existingGroups = await user.groups;

    if (this.selectedGroups && this.selectedGroups.length) {
      for (var a = 0; a < this.selectedGroups.length; a++) {
        let group = this.selectedGroups[a];

        if (!existingGroups.includes(group)) {
          let newUserInGroup = this.store.createRecord('user-in-group', {
            user: user,
            userGroup: group,
          });

          await newUserInGroup.save();
        }
      }
    }
  });

  @task
  removeUserGroupsTask = taskFor(async (party: User) => {
    let items = this.selectedItems.toArray();

    items.removeObject(party);
    items.unshiftObject(party);

    let itemsToSave = [];

    for (var a = 0; a < items.length; a++) {
      itemsToSave.push(this.removeUserFromGroupTask.perform(<User>items[a]));
    }

    await all(itemsToSave);

    // we need to clear selectedItems upon removing!!!
    this.selectedItems.clear();

    this.confirmGroupChange = null;
    this.reloadData = true;
    this.selectedGroups = null;
  });

  @task({
    enqueue: true,
    maxConcurrency: 4,
  })
  removeUserFromGroupTask = taskFor(async (user: User) => {
    let existingGroups = (await user.groups).toArray();
    let userInGroups = (await user.userInGroups).toArray();

    console.log('EXISTING GROUPS: ', existingGroups);

    if (this.selectedGroups && this.selectedGroups.length) {
      for (var a = 0; a < this.selectedGroups.length; a++) {
        let group = <UserGroup>this.selectedGroups[a];

        if (existingGroups.includes(group)) {
          let userInGroup = null;

          for (var b = 0; b < userInGroups.length; b++) {
            if (userInGroups[b].userGroupId === group.id) {
              userInGroup = userInGroups[b];
              break;
            }
          }

          if (userInGroup) {
            await userInGroup.destroyRecord();
          }
        }
      }
    }
  });

  @action
  removeUser(record: User) {
    this.confirmRemoval = record;
  }

  @action
  cancelRemoveUser() {
    this.confirmRemoval = null;
  }

  @action
  doRemoveUser(record: User) {
    this.removeRecordTask.perform(record);
  }

  @action
  editUser(user: User) {
    const appearance = {
      label: `Update permissions for ${user.email}`,
      icon: '',
      title: `Update permissions for ${user.email}`,
      custom: true,
      size: 'medium',
    };

    this.docker.invokePopup('manage-users/users/detail', appearance, {
      model: user,
      onUpdate: () => {
        this.reloadData = true;
      },
    });
  }

  @action
  addUser() {
    const appearance = {
      label: 'Add New User',
      icon: '',
      title: '<small>New User</small>',
      custom: true,
      size: 'medium',
    };

    this.docker.invokePopup('manage-users/users/add', appearance, {
      onUpdate: () => {
        this.reloadData = true;
      },
    });
  }

  // @action
  // addRole() {
  //   this.confirmAddRole = this.store.createRecord('acl-role', {});
  // }

  // @action
  // onTableAction(changes) {
  //   if (changes.selectedItems.length) {
  //     this.prepareAddUserDataTask.perform(changes.selectedItems[0]);
  //   }
  // }

  @action
  search(e: Event) {
    e.preventDefault();
    this.generateFilter();
  }

  // @action
  // searchByRole(selectedRoles) {
  //   set(this, 'selectedRoles', selectedRoles);
  //   this.generateFilter();
  // }

  @action
  searchByGroup(groups: UserGroup[]) {
    this.searchGroups = groups;
    this.generateFilter();
  }

  @action
  sendAlert(user: User) {
    let items = this.selectedItems.toArray().concat();

    items.removeObject(user);
    items.unshiftObject(user);

    const appearance = {
      label: 'Send Notification',
      icon: '',
      title: 'Send Notification',
      custom: true,
      size: 'large',
    };

    this.docker.invokePopup('send-alert-smex', appearance, {
      users: items,
    });

    this.selectedItems.clear();
  }

  @action
  viewAs(user: User) {
    window.open(
      `${this.session.portalUrl}view-as?viewAsUser=${encodeURIComponent(
        user.email
      )}`
    );
  }

  @action
  viewAsSmartExchange(user: User) {
    let viewAsUrl = `${get(
      this.config,
      'mainUrl'
    )}/view-as?viewAsUser=${encodeURIComponent(user.email)}`;
    window.open(viewAsUrl);
  }

  @action
  assignGroups(record: User) {
    this.assignUserGroupsTask.perform(record);
  }

  @action
  removeGroups(record: User) {
    this.removeUserGroupsTask.perform(record);
  }

  @action
  cancelGroupChange() {
    this.confirmGroupChange = null;
    this.selectedGroups = [];
  }
}
