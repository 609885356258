import { attr } from '@ember-data/model';
import DS from 'ember-data';

export default class AnswerOption extends DS.Model.extend({

}) {
  @attr('string')
  optionText!: string;
  @attr('number')
  sequenceNumber!: number;
  @attr('string')
  formElementId!: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/answer-option': AnswerOption;
  }
}
