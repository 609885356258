import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from "@ember/component";

@classic
export default class ProfessionalsGroup extends Component {
  @service('docker-item')
  docker;

  collapsed = true;

  @action
  showAttorneyDetail(record) {
    this.docker.popupPerson(record, record.get('company'));
  }

  @action
  showCompanyDetail(company) {
    this.docker.popupCompany(company);
  }
}