import AppBuilderAdapter from './app-builder';
import { dasherize } from '@ember/string';

export default AppBuilderAdapter.extend({
  recalculate(modelName, event) {
    let url = this.buildURL(modelName) + '/recalculate';
    let serialized = event.serialize();

    return this.ajax(url, 'POST', {
      data: serialized.data.attributes,
    });
  },
});
