import {
  AsyncBelongsTo,
  AsyncHasMany,
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { not, alias, readOnly } from '@ember/object/computed';
import Abstract from './abstract';
import moment from 'moment';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
import { EventType } from 'cing-app/utils/lookups';
import EventStatus from './event-status';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import EventTypeModel from './event-type';
import Case from './case';
import User from './user';
import Project from './project';
import PacerEvent from './pacer-event';
import InterestedParty from './interested-party';
import Docket from './docket';
import { computed } from '@ember/object';

const Validations = buildValidations({
  title: [
    validator('presence', {
      presence: true,
      disabled: computed('model.typeId', function () {
        if (this.model.typeId === EventType.Request) {
          return true;
        }
        return false;
      }),
    }),
    validator('length', {
      min: 1,
      max: 1000,
      disabled: computed('model.typeId', function () {
        if (this.model.typeId === EventType.Request) {
          return true;
        }
        return false;
      }),
    }),
  ],
  description: [validator('length', { max: 10000 })],
  dateStart: validator('presence', {
    presence: true,
    disabled: 'model.showInCalendar',
  }),
  dateEnd: validator('presence', {
    presence: true,
    disabled: computed(
      'model.showInProjectManagement',
      'model.typeId',
      function () {
        if (this.model.typeId === EventType.Request) {
          return true;
        } else if (this.model.showInProjectManagement) {
          return false;
        } else {
          return true;
        }
      }
    ),
  }),
  displayDateStart: [
    validator('presence', {
      presence: true,
      disabled: alias('model.alwaysShow'),
    }),
    validator('date', {
      onOrBefore: readOnly('model.displayDateEnd'),
      errorFormat: 'MMMM Do YYYY',
    }),
  ],
  displayDateEnd: [
    validator('date', {
      onOrAfter: readOnly('model.displayDateStart'),
      errorFormat: 'MMMM Do YYYY',
    }),
  ],
});

export default class Event extends Abstract.extend(
  Validations,
  computedEnumMixin('typeName', 'typeId', EventType)
) {
  @attr('boolean') declare allDay: boolean;
  @attr('string') declare assignedUserId?: string;
  @attr('string') declare caseId?: string;
  @attr('date') declare dateEnd?: Date;
  @attr('date') declare dateStart: Date;
  @attr('date') declare dateDue?: Date;
  @attr('string') declare description?: string;
  @attr('date') declare displayDateEnd?: Date;
  @attr('date') declare displayDateStart: Date;
  @attr('string') declare eventApprovalStatusId?: string;
  @attr('string') declare eventCategoryId?: string;
  @attr('string') declare eventStatusId?: string;
  @attr('string') declare findings?: string;
  @attr('boolean') declare highlight?: boolean;
  @attr('string') declare location?: boolean;
  @attr('string') declare notes?: string;
  @attr('string') declare originator?: string;
  @attr('string') declare parentId?: string;
  @attr('number') declare priority?: number;
  @attr('number') declare progress: number;
  @attr('string') declare projectId?: string;
  @attr('string') declare remediation?: string;
  @attr('string') declare request?: string;
  @attr('string') declare response?: string;
  @attr('boolean') declare showInCalendar: boolean;
  @attr('boolean') declare showInProjectManagement?: boolean;
  @attr('string') declare title: string;
  @attr('number') declare typeId?: number;
  @attr('string') declare wbs?: string;
  @attr('number') declare sortOrder?: number;
  @attr('string') declare target?: string;
  //type: belongsTo('event-type'),
  @belongsTo('event-status') declare eventStatus: AsyncBelongsTo<EventStatus>;
  @belongsTo('event-type') type!: AsyncBelongsTo<EventTypeModel>;
  @belongsTo('case') declare case: AsyncBelongsTo<Case>;
  @belongsTo('user') declare assignedUser: AsyncBelongsTo<User>;
  @belongsTo('project') declare project: AsyncBelongsTo<Project>;
  @hasMany('pacer-event') declare pacerEvents: AsyncHasMany<PacerEvent>;
  @hasMany('interested-party')
  declare interestedParties: AsyncHasMany<InterestedParty>;
  @hasMany('docket') declare documents: AsyncHasMany<Docket>;

  _alwaysShow = true;

  get alwaysShow() {
    return this._alwaysShow || this.displayDateStart;
  }

  set alwaysShow(value) {
    this._alwaysShow = value ? true : false;
  }

  get isMidnight() {
    let dt = this.dateStart;

    if (dt) {
      let dtMoment = moment(dt);
      return (
        dtMoment.hours() === 0 &&
        dtMoment.minutes() === 0 &&
        dtMoment.seconds() === 0
      );
    } else {
      return false;
    }
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    event: Event;
  }
}
