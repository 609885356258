import { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import Abstract from './abstract';
import Case from './case';
import Court from './court';

export default class Judge extends Abstract {
  @attr('string') declare firstName?: string;
  @attr('string') declare lastName?: string;
  @attr('string') declare middleName?: string;

  get fullName() {
    return `${this.firstName} ${this.middleName} ${this.lastName}`;
  }

  @hasMany('case') declare assignedCases: AsyncHasMany<Case>;
  @hasMany('court') declare courts: AsyncHasMany<Court>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    judge: Judge;
  }
}
