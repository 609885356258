import AppBuilderSerializer from './app-builder';

export default AppBuilderSerializer.extend({
  /*
  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      for (var a = 0; a < payload.data.length; a++) {
          this.normalizeItem(payload.data[a]);
      }

      return this._super(...arguments);
  },
  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      this.normalizeItem(payload.data);
      return this._super(...arguments);
  },
  normalizeItem(item) {
      console.log(item);
      item.id = item.attributes.eventId;
  }
  */
});
