import Model, { attr } from '@ember-data/model';

export default Model.extend({
  invFundId: attr('string'),
  invId: attr('string'),
  fundId: attr('string'),
  contactName: attr('string'),
  fundName: attr('string'),
  emailAddress: attr('string'),

  communications: attr('string'),
  capitalCalls: attr('string'),
  distributions: attr('string'),
  tax: attr('string'),
  financials: attr('string'),
  legal: attr('string'),
  otherDocuments: attr('string'),
});

