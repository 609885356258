import { inject as service } from '@ember/service';
import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import Store from '@ember-data/store';

export default class PortalPageSettingsSmartroom extends Fragment {
  @service
  store!: Store;

  @tracked
  AllAccessSmartRoomFolderIds!: any[];

  @attr('string') declare SmartRoomId?: string;
  @attr('string') declare SmartRoomFolderId?: string;
  @attr('boolean', { defaultValue: true })
  declare SmartRoomCheckAccesssAgreement?: boolean;
  @attr('string') declare SmartRoomContentViewMode?: string;

  get smartRoom() {
    if (this.SmartRoomId) {
      return this.store.findRecord('smartroom/site', this.SmartRoomId);
    } else {
      return null;
    }
  }

  get smartRoomFolder() {
    if (this.SmartRoomFolderId) {
      return this.store.queryRecord('smartroom/folder', {
        id: this.SmartRoomFolderId,
        siteId: this.SmartRoomId,
      });
    } else {
      return null;
    }
  }

  @task getSmartRoomFolders = taskFor(async () => {
    let AllAccessSmartRoomFolderIds = this.AllAccessSmartRoomFolderIds;
    let folders = [];

    for (var a = 0; a < AllAccessSmartRoomFolderIds.length; a++) {
      folders.push(
        await this.store.queryRecord('smartroom/folder', {
          id: AllAccessSmartRoomFolderIds[a],
          siteId: this.SmartRoomId,
        })
      );
    }

    return folders;
  });
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
export default interface ModelRegistry {
  'portal-page-settings-smartroom': PortalPageSettingsSmartroom;
}
