import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set, computed, action } from '@ember/object';
import { task } from 'ember-concurrency';

import classic from 'ember-classic-decorator';

@classic
export default class PortfolioCompanies extends Component {
  @service('docker-item') docker;

  init() {
    super.init(...arguments);

    this.reloadCompaniesTask.perform();
  }

  @task
  *reloadCompaniesTask() {
    let projectCompanies = yield this.model.hasMany('companies').reload({
      adapterOptions: {
        include: 'company-profile',
      },
    });

    set(this, 'projectCompanies', projectCompanies);
  }

  @action
  viewCompany(company) {
    this.docker.popupCompany(company);
  }
}
