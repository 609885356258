import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Abstract from './abstract';
import { computedEnumMixin } from 'cing-app/mixins/computed-enum';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import { ProjectTab } from 'cing-app/utils/lookups';
//@ts-ignore
import { fragment } from 'ember-data-model-fragments/attributes';
import Project from './project';
import ProjectTabSettings from './project-tab-settings';
import AppbuilderLink from './appbuilder-link';

const ProjectTabValidations = buildValidations({
  type: validator('presence', true),
  project: validator('presence', true),
});

export default class ProjectTabModel extends Abstract.extend(
  // on SX abstract, on T500portal Model
  ProjectTabValidations,
  computedEnumMixin('typeName', 'type', ProjectTab)
) {
  @attr('string') declare title?: string;
  @attr('string') declare projectId: string;
  @attr('boolean') declare enabled: boolean;
  @attr('number') declare sortOrder?: number;
  @attr('number') declare type: number;
  @attr('string') declare content?: string;
  @attr('string') declare dataConnectionId?: string;
  @belongsTo('project') declare project: AsyncBelongsTo<Project>;
  @belongsTo('appbuilder-link')
  declare dataConnection: AsyncBelongsTo<AppbuilderLink>;
  @fragment('project-tab-settings', { defaultValue: {} })
  declare settings: ProjectTabSettings;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-tab': ProjectTabModel;
  }
}
