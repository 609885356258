import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';

export default class RightType extends Model.extend({
  canSeeItem: attr('boolean'),
  modify: attr('boolean'),
  view: attr('boolean'),
  print: attr('boolean'),
  save: attr('boolean'),
  printRestricted: attr('boolean'),
  saveRestricted: attr('boolean'),
  viewRestricted: attr('boolean'),
  passDown: attr('boolean'),
  rightNumber: attr('number'),
  available: attr('boolean'),
  name: attr('string'),

  allRights: ['canSeeItem', 'modify', 'view', 'print', 'save', 'printRestricted', 'viewRestricted', 'saveRestricted', 'passDown'],

  title: computed('name', function () {
    return this.name;
  }),

  addDescription: function (property, description) {
    if (this.get(property)) {
      this.set('_actions.' + property, '<span class="text-success">' + description + '</span>');
    }
  },

  description: computed('_actions.{}', function () {
    var actions = this._actions;

    var list = [];

    for (var prop in actions) {
      list.push(actions[prop]);
    }

    return '<h6>Rights configuration</h6>' + list.join('<br/>');

  }),

  init: function () {
    this._super(...arguments);
    this.set("_actions", {});
    //this.set("_disabledActions", {});

    this.addDescription('canSeeItem', 'can see item');
    this.addDescription('modify', 'modify');
    this.addDescription('view', 'view');
    this.addDescription('save', 'save');
    this.addDescription('print', 'print');
    this.addDescription('viewRestricted', 'view restricted');
    this.addDescription('printRestricted', 'print restricted');
    this.addDescription('saveRestricted', 'save restricted');
    this.addDescription('passDown', 'pass down');
    this.addDescription('rightNumber', 'right number');
  },

  disableAll: function () {
    for (var a = 0; a < this.allRights.length; a++) {
      this.set(this.allRights[a], false);
    }
  }
}) { };


declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'right-type': RightType;
  }
}