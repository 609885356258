import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class IframComponent extends Component {
  init() {
    super.init();
  }
  didInsertElement() {
    let loadingHtml = "<h3 style='font-size: 25px; text-align: center; padding-top: 50px; width: 100%; font-family: Arial, Verdana; font-weight: 300; opacity: 0.5;'>Loading</h3>";
    let iframe = this.$("iframe");
    iframe.contents().find('html').html(loadingHtml);
    iframe.attr('src', this.get('projectTab.content'));
  }
}