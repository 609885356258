import { action, set } from "@ember/object";
import Component from "@glimmer/component";
import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
} from "cing-app/mixins/filter-builder";
import { tracked } from "@glimmer/tracking";
import { inject as service } from "@ember/service";
import Store from "@ember-data/store";
import ENV from "../../../../config/environment";

interface DeliveryReportArgs {
  appearance: any;
  context: any;
}

const DateFilterDelivered: number = 1;
const DateFilterOpened: number = 2;
const DateFilterBounced: number = 3;


const DateFilterOptions: Array<object> = [
  {
    id: DateFilterDelivered,
    name: "Delivered",
    property: 'delivered-at'
  },
  {
    id: DateFilterOpened,
    name: "Opened",
    property: 'opened-at'
  },
  {
    id: DateFilterBounced,
    name: "Bounced",
    property: 'bounced-at'
  }
]

export default class ManageAlertsDetail extends Component<DeliveryReportArgs> {
  @service()
  store!: Store;

  //@ts-ignore
  @service("docker-item") docker;

  @tracked
  searchTerm?: string;

  @tracked
  condition: any;

  @tracked
  dateFilter?: Array<object>;

  DateFilterOptions = DateFilterOptions;

  get alertDetail() {
    return this.args.context;
  }

  constructor(owner: unknown, args: DeliveryReportArgs) {
    super(owner, args);

    this.generateFilter();
  }

  get columns() {
    let self = this;

    let columns = [
      {
        propertyName: "firstName",
        title: "First Name",
      },
      {
        propertyName: "lastName",
        title: "Last Name",
      },
      {
        propertyName: "email",
        title: "Email",
      },
      {
        propertyName: "companyName",
        title: "Company",
      },
      {
        propertyName: "createTime",
        title: "Sent",
        component: "api-table/columns/date",
        dateFormat: ENV.APP.shortDateTimeFormat,
      },
      {
        propertyName: "deliveredAt",
        title: "Delivered",
        component: "api-table/columns/date",
        dateFormat: ENV.APP.shortDateTimeFormat,
        sortPrecedence: 0,
        sortDirection: "desc",
        className: 'text-success'
      },
      {
        propertyName: "openedAt",
        title: "Opened",
        component: "api-table/columns/date",
        dateFormat: ENV.APP.shortDateTimeFormat,
        className: 'text-info'
      },
      {
        propertyName: "bouncedAt",
        title: "Bounced",
        component: "api-table/columns/date",
        dateFormat: ENV.APP.shortDateTimeFormat,
        className: 'text-danger'
      },
      {
        propertyName: "bouncedData",
        title: "Bounce Reason",
        className: 'text-danger',
        component: 'alerts-report/delivery-report/column-bounce-reason',
        isHidden: true
      },
      {
        propertyName: "body",
        title: "",
        viewBody: (record) => {
          self.viewBody(record);
        },
        component: 'alerts-report/delivery-report/column-view-body',
      },
    ];
    return columns;
  }

  generateFilter() {
    let exprSearch = Expressions.create({
      operator: ExpressionOperators.AND,
    });

    let alertDetailId = this.alertDetail.id;

    exprSearch.add(
      Filter.create({
        name: "alert-announcement-id",
        operator: FilterOperators.EQUAL,
        value: alertDetailId,
      })
    );
    if (this.searchTerm) {
      let searchTermExpr = Expressions.create({ operator: ExpressionOperators.OR });

      searchTermExpr.add(
        Filter.create({
          name: "email",
          operator: FilterOperators.LIKE,
          value: this.searchTerm,
        })
      );
      searchTermExpr.add(
        Filter.create({
          name: "last-name",
          operator: FilterOperators.LIKE,
          value: this.searchTerm,
        })
      );
      searchTermExpr.add(
        Filter.create({
          name: "first-name",
          operator: FilterOperators.LIKE,
          value: this.searchTerm,
        })
      );

      exprSearch.add(searchTermExpr);
    }

    if (this.dateFilter && this.dateFilter.length) {
      let dateFilterExpr = Expressions.create({ operator: ExpressionOperators.OR });

      this.dateFilter.forEach((dateFilter) => {
        dateFilterExpr.add(
          Filter.create({
            name: dateFilter.property,
            operator: FilterOperators.NOT_NULL
          })
        )
      })

      exprSearch.add(dateFilterExpr);
    }

    this.condition = exprSearch.serialize();
  }

  @action
  search() {
    this.generateFilter();
  }

  @action
  updateDateFilter(dateFilter) {
    this.dateFilter = dateFilter;
    this.generateFilter();
  }

  @action
  viewBody(record) {
    const appearance = {
      label: "Notification",
      title: `<small>Notification for </small> ${record.get(
        "email"
      )}`,
      id: `alerts-report/delivery-report${record.id}/view-body`,
      size: 'large',
      custom: true,
    };

    this.docker.invokePopup(
      "alerts-report/delivery-report/view-body",
      appearance,
      record
    );
  }
}
