import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import { EmailProviders, EmailProviderDomains } from 'cing-app/utils/lookups';
import { inject as service } from '@ember/service';
import ActionsService from 'cing-app/pods/se-actions/service';
import SendingDomain, { DOMAIN_REGEX } from 'cing-app/models/sending-domain';
import Store from '@ember-data/store';
import {
  ExpressionOperators,
  Expressions,
  Filter,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';

interface SendingDomainsAddArgs {
  context: any;
  onClose: () => void;
  footer: any;
  header: any;
}

export default class SendingDomainsAdd extends Component<SendingDomainsAddArgs> {
  @tracked showFormValidations = false;
  @tracked discover: {
    provider: string;
    domain: string;
  };
  @tracked domainValidationError?: string;
  @service('seActions') seActions!: ActionsService;
  @tracked discoverActionResult: any;
  @tracked createActionResult: any;
  @tracked newDomain: SendingDomain;
  @service() store!: Store;
  @tracked verifyAction?: any;
  @tracked existsActionResult: any;
  @tracked verifyActionResult: any;
  domainExists?: boolean;
  EmailProviders = EmailProviders;

  constructor(owner: any, args: SendingDomainsAddArgs) {
    super(owner, args);
    this.discover = { provider: EmailProviders.SparkPost, domain: '' };
    this.newDomain = this.store.createRecord('sending-domain');
  }

  @action
  async discoverDomain() {
    if (this.isDomainValid(this.discover.domain)) {
      await this.setDomainExists.perform(this.discover.domain);
      if (this.domainExists) {
        this.domainValidationError = 'This domain has been already created';
      } else {
        this.domainValidationError = undefined;
        await this.discoverDomainTask.perform();
      }
    } else {
      this.domainValidationError = 'Invalid domain name';
    }

    if (this.discoverActionResult.BounceDomain) {
      this.newDomain.set(
        'bounceDomainSub',
        this.discoverActionResult.BounceDomain.Domain.replace(
          '.' + this.discover.domain,
          ''
        )
      );
    }

    if (this.discoverActionResult.TrackingDomain) {
      this.newDomain.set(
        'trackingDomainSub',
        this.discoverActionResult.TrackingDomain.Domain.replace(
          '.' + this.discover.domain,
          ''
        )
      );
    }

    this.newDomain.sendingDomain = this.discover.domain;
    this.newDomain.provider = this.discover.provider;
  }

  @action
  async addNewDomain() {
    this.addNewDomainTask.perform();
  }

  @task
  addNewDomainTask = taskFor(async () => {
    if (this.newDomain.trackingDomainSub) {
      this.newDomain.trackingDomain = `${this.newDomain.trackingDomainSub}.${this.newDomain.sendingDomain}`;
    }

    if (this.newDomain.bounceDomainSub) {
      this.newDomain.bounceDomain = `${this.newDomain.bounceDomainSub}.${this.newDomain.sendingDomain}`;
    }

    if (this.newDomain.validations.isValid) {
      let createResult = await this.seActions.domainCreate(this.newDomain);
      this.createActionResult = createResult.actionResult;
      this.args.context.reloadData();
    }
  });

  @task
  discoverDomainTask = taskFor(async () => {
    let discoverResult = await this.seActions.domainExists(
      this.discover.provider,
      this.discover.domain
    );
    this.discoverActionResult = discoverResult.actionResult;
  });

  @computed('newDomain.provider', 'discover.provider')
  get selectedProviderLink() {
    return (
      EmailProviderDomains[this.newDomain.provider] ||
      EmailProviderDomains[this.discover.provider]
    );
  }

  @action
  verifyDomain(): void {
    this.verifyDomainTask.perform();
  }

  @task({ drop: true })
  verifyDomainTask = taskFor(async () => {
    this.verifyAction = this.seActions.domainVerify(
      this.createActionResult.Local.Id
    );
    this.verifyActionResult = await this.verifyAction.actionResult;
  });

  isDomainValid(domain: string): boolean {
    return DOMAIN_REGEX.test(domain);
  }

  @task({ drop: true })
  setDomainExists = taskFor(async (domain: string) => {
    let condition = Expressions.create({ operator: ExpressionOperators.AND });
    condition.add(
      Filter.create({
        name: 'sending-domain',
        operator: FilterOperators.EQUAL,
        value: domain,
      })
    );

    let domains = await this.store.query('sending-domain', {
      condition: condition.serialize(),
      page: {
        size: 1000,
        number: 1,
      },
    });

    this.domainExists = domains.toArray().length > 0;
  });
}
