import Model, { attr } from '@ember-data/model';

export default class SearchViewLayout extends Model {
  @attr('number') caseId!: number;
  @attr('number') formElementId?: number;
  @attr('string') columnWidth?: string;
  @attr('boolean') isVisible!: boolean;
  @attr('number') sequenceNumber?: number;
  @attr('boolean') isSummation!: boolean;
  @attr('boolean') isSummationOnTop!: boolean;
  @attr('boolean') svIsColVisible!: boolean;
  @attr('boolean') svIsBulkUpdate!: boolean;
  @attr('boolean') isGraph!: boolean;
  @attr('boolean') svIsSearchable!: boolean;
  @attr('boolean') pmIsColVisible!: boolean;
  @attr('boolean') pmIsBulkUpdate!: boolean;
  @attr('boolean') pmIsSearchable!: boolean;
  @attr('string') pmColumnWidth?: string;
  @attr('boolean') svIsTotals!: boolean;
  @attr('string') svSize?: string;
  @attr('string') pmSize?: string;
  @attr('string') svAlignment?: string;
  @attr('string') pmAlignment?: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/search-view-layout': SearchViewLayout;
  }
}


