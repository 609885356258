import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { debug } from '@ember/debug';
import moment from 'moment';

let getYears = function (startYear) {
  var currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 1980;

  while (startYear <= currentYear) {
    years.push(String(startYear++));
  }

  return years;
}

@classic
export default class DatePicker extends Component {
  @service
  config;

  selectedDate = null;

  @action
  clear() {
    this.set('selectedDate', null);
    this.sendAction("onChange", null);
  }

  @action
  changeCenter(unit, calendar, e) {
    let newCenter = moment(calendar.center).clone()[unit](e.target.value);
    calendar.actions.changeCenter(newCenter);
  }

  @action
  selectDate(selectedDate) {
    this.set('selectedDate', selectedDate[0]);
    this.sendAction("onChange", selectedDate[0]);
  }

  @action
  onStartDateClosed(dates, currentdatestring, picker) {
    if (picker.altInput.value) {
      picker.setDate(picker.altInput.value, true, picker.config.altFormat);
    }
  }
}
