import classic from 'ember-classic-decorator';
import Field from '../field/component';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';

let getYears = function (startYear) {
  var currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 1980;

  while (startYear <= currentYear) {
    years.push(String(startYear++));
  }

  return years;
}

@classic
export default class Text extends Field {
  @service config;

  showFormValidations = null;
  type = 'text';

  @action
  clear() {
    set(this, 'value', null);

    if (this.onChange) {
      this.onChange(null);
    }
  }

  @action
  changeCenter(unit, calendar, e) {
    let newCenter = moment(calendar.center).clone()[unit](e.target.value);
    calendar.actions.changeCenter(newCenter);
  }

  @action
  selectDate(selectedDate) {
    set(this, 'value', selectedDate[0]);

    if (this.onChange) {
      this.onChange(selectedDate[0]);
    }
  }

  @action
  onStartDateClosed(dates, currentdatestring, picker) {
    if (picker.altInput.value) {
      picker.setDate(picker.altInput.value, true, picker.config.altFormat);
    }
  }
}
