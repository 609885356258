import Component from "@glimmer/component";
import { action, get, set } from "@ember/object";
import { inject as service } from "@ember/service";
import Store from "@ember-data/store";
import {
  Expressions,
  FilterOperators,
  ExpressionOperators,
  Filter,
} from "cing-app/mixins/filter-builder";
import { tracked } from "@glimmer/tracking";
import ENV from "../../../config/environment";

interface ManageAlertsArgs { }

export default class ManageAlerts extends Component<ManageAlertsArgs> {
  @service store!: Store;
  //@ts-ignore
  @service("docker-item") docker;

  @tracked
  searchTermCreatedBy?: string;

  @tracked
  searchTermFrom?: string;

  @tracked
  searchTermSubject?: string;

  @tracked
  columns: any[];

  @tracked
  condition: any;

  constructor(owner: unknown, args: ManageAlertsArgs) {
    super(owner, args);
    this.columns = this.getColumns();
  }

  include = "user.person";

  getColumns() {
    let columns = [
      {
        propertyName: "createTime",
        title: "Date Sent",
        component: "api-table/columns/date",
        dateFormat: ENV.APP.shortDateTimeFormat,
        sortPrecedence: 0,
        sortDirection: "desc",
      },
      {
        propertyName: "user.person.fullName",
        component: "alerts-report/column-created-by",
        title: "Created By",
      },
      {
        propertyName: "from",
        title: "From Address",
      },
      {
        title: "Subject",
        propertyName: "subject",
      },
      {
        title: "",
        component: "alerts-report/column-actions",
        className: "column-action",
        deliveryReport: this.deliveryReport,
        alertDetail: this.alertDetail,
      },
    ];
    return columns;
  }

  generateFilter() {
    let expr = Expressions.create({
      operator: ExpressionOperators.AND,
    });

    if (
      this.searchTermFrom ||
      this.searchTermCreatedBy ||
      this.searchTermSubject
    ) {
      let expresion = Expressions.create({
        operator: ExpressionOperators.OR,
      });

      expresion.add(
        Filter.create({
          name: "user.email",
          operator: FilterOperators.LIKE,
          value: this.searchTermCreatedBy,
        })
      );

      expresion.add(
        Filter.create({
          name: "user.person.fullName",
          operator: FilterOperators.LIKE,
          value: this.searchTermCreatedBy,
        })
      );

      expr.add(
        Filter.create({
          name: "subject",
          operator: FilterOperators.LIKE,
          value: this.searchTermSubject,
        })
      );
      expr.add(
        Filter.create({
          name: "from",
          operator: FilterOperators.LIKE,
          value: this.searchTermFrom,
        })
      );

      expr.add(expresion);
    }
    this.condition = expr.serialize();
  }

  @action
  search() {
    this.generateFilter();
  }

  @action
  deliveryReport(record) {
    const appearance = {
      label: "Notification",
      title: `<small>Delivery Report for notification</small> ${record.get(
        "subject"
      )}`,
      id: `alerts-report/delivery-report${record.id}`,
      size: 'large',
      custom: true,
    };
    ;
    this.docker.invokePopup(
      "alerts-report/delivery-report",
      appearance,
      record
    );
  }

  @action
  alertDetail(record) {
    const appearance = {
      label: "Notification",
      title: `<small>Preview for notification</small> ${record.get(
        "subject"
      )}`,
      custom: true,
      size: 'large',
      id: `alerts-report/alert-detail${record.id}`,
    };

    this.docker.invokePopup(
      "alerts-report/alert-detail",
      appearance,
      record
    );
  }
}
