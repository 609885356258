import { attr } from '@ember-data/model';
import Abstract from './abstract';
//@ts-ignore
import { validator, buildValidations } from 'ember-cp-validations';
import { exportable } from 'cing-app/utils/exportable';

const ProjectStatusValidations = buildValidations({
  name: validator('presence', true),
});

export default class ProjectStatus extends Abstract.extend(
  ProjectStatusValidations
) {
  @exportable()
  @attr('string')
  declare name: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'project-status': ProjectStatus;
  }
}
