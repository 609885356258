import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import FaqCategory from 'cing-app/models/faq-category';
import { tracked } from '@glimmer/tracking';

interface FaqCategoryEditArgs {
  context: any;
  onClose: () => void;
}

export default class FaqCategoryEdit extends Component<FaqCategoryEditArgs> {
  @tracked
  model: FaqCategory;

  constructor(owner: any, args: FaqCategoryEditArgs) {
    super(owner, args);
    this.model = this.args.context.model;
  }

  @task
  saveTask = taskFor(async (model) => {
    await model.save();
  });

  @task
  removeTask = taskFor(async (model) => {
    await model.destroyRecord();

    if (this.args.context.onRemove) {
      this.args.context.onRemove();
    }
    this.args.onClose();
  });

  @action
  save(model: FaqCategory) {
    if (model.validations.isValid) {
      this.saveTask.perform(model);
    }
  }

  @action
  remove(model: FaqCategory) {
    this.removeTask.perform(model);
  }

  @action
  onClose() {
    if (this.model.hasDirtyAttributes) {
      this.model.rollbackAttributes();
    }
    if (this.args.context.onClose) {
      this.args.context.onClose();
    }
    this.args.onClose();
  }
}
