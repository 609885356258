import { isEmpty } from '@ember/utils';
import Transform from '@ember-data/serializer/transform';

export default Transform.extend({
  deserialize(serialized) {
    if (isEmpty(serialized)) {
      return [];
    }

    return serialized;
  },

  serialize(deserialized) {
    if (isEmpty(deserialized)) {
      return [];
    }

    return deserialized;
  },
});
