import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface AbPmUserInitialsArgs {
    name: string
}

export default class AbPmUserInitials extends Component<AbPmUserInitialsArgs> {
    @tracked initials: string;

    constructor(owner:any, args:any) {
        super(owner, args); 

        this.initials = this.getInitials(args.name).toUpperCase();
    }

    getInitials(str:string){
        let ss = String(str).replace(/\s+/g, ' ').split(' ');
        let letter1: string = '';
        let letter2: string = '';
        if(ss.length > 1){
            letter1 = String(ss[0] || '')?.charAt(0);
            letter2 = String(ss[1] || '')?.charAt(0);
        } else {
            letter1 = String(ss[0] || '')?.charAt(0);
            letter2 = String(ss[0] || '')?.charAt(1);
        }
        return letter1 + letter2;
    }    
}
