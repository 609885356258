import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, set, action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { EventPriority } from 'cing-app/utils/lookups';
import { inject as service } from '@ember/service';

let EventPriorityOptions = [];

import {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';

for (const [name, value] of Object.entries(EventPriority)) {
  EventPriorityOptions.pushObject({
    name: name,
    id: value,
  });
}

export default class FormComponent extends Component {
  @service store;

  EventPriorityOptions = EventPriorityOptions;

  constructor() {
    super(...arguments);
    this.initTask.perform();
  }

  @task
  *initTask() {
    if (this.args.eventType) {
      console.log('UPDATING TYPE: ', this.args.eventType);
      set(this, 'args.model.typeId', this.args.eventType);
    }
  }

  @computed
  get eventStatuses() {
    return this.store.query('event-status', {
      page: {
        size: 1000,
      },
      sort: 'name',
    });
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  @task
  *addEventStatusTask(newTypeName, options) {
    let newStatus = this.store.createRecord('event-status', {
      name: newTypeName,
    });

    yield newStatus.save();

    let statuses = yield this.store.query('event-status', {
      page: {
        size: 1000,
        sort: 'name',
      },
    });

    set(this, 'eventStatuses', statuses);
    set(this, 'args.context.model.eventStatus', newStatus);
  }

  @action
  createEventStatus(newTypeName, options) {
    this.addEventStatusTask.perform(newTypeName, options);
  }

  @action
  removeEventStatus(status) {
    status.destroyRecord();
  }

  @action
  hideEventStatusCreateOnSame(term) {
    let existingOption = get(this, 'eventStatuses').findBy('name', term);
    return !existingOption;
  }

  @action
  searchUsers(term) {
    let query = Expressions.create();
    query.add(
      Filter.create({
        name: 'email',
        operator: FilterOperators.LIKE,
        value: term,
      })
    );

    return this.store.query('user', {
      condition: query.serialize(),
      page: {
        size: 20,
        number: 1,
      },
      sort: 'email',
      include: 'person',
    });
  }
}
