import classic from 'ember-classic-decorator';
import Field from '../field/component';
import { set, action, computed } from '@ember/object';

@classic
export default class Checkbox extends Field {
  showFormValidations = null;
  type = 'checkbox';

  @computed('value')
  get checkboxValue() {
    if (this.get('value') === "true" || this.get('value') === true) {
      return true;
    } else {
      return false;
    }
  }

  set checkboxValue(value) {
    this.set('value', value);

    if (this.get('value') === "true" || this.get('value') === true) {
      return true;
    } else {
      return false;
    }
  }

  @action
  toggleChecked() {
    if (this.checkboxValue) {
      this.set('checkboxValue', false);
    } else {
      this.set('checkboxValue', true);
    }

    if (this.onChange) {
      this.onChange(this.checkboxValue);
    }
  }
}