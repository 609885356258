import { action } from '@ember/object';
import Component from '@glimmer/component';
import { DataSourceColumn } from 'smex-ui-table';

interface TableFileNameColumnArgs {
  column: DataSourceColumn<unknown>;
  row: any;
}

export default class TableFileNameColumn extends Component<TableFileNameColumnArgs> {
  get value() {
    return this.args.column.getValue(this.args.row);
  }

  get href() {
    if (this.args.column.options.generateLink) {
      return this.args.column.options.generateLink(this.args.row);
    } else if (this.args.row.url) {
      return this.args.row.url;
    }
    return '#';
  }

  get target() {
    let target =
      this.args.column.options.linkTarget || this.args.column.options.target;
    if (!target && this.args.row.url) {
      target = '_blank';
    }

    return target;
  }

  get tooltipValue() {
    if (this.args.column.options.getTooltipValue) {
      return this.args.column.options.getTooltipValue(this.args.row);
    } 
    return '';
  }
  get showIcon() {
    return this.args.column.options.hideIcon !== false;
  }

  @action
  onClick(evt: Event) {
    if (this.args.column.options.onClick) {
      evt.stopPropagation();
      evt.preventDefault();

      this.args.column.options.onClick(this.args.row);
    }
  }
}
