import Controller from '@ember/controller';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { ESettings } from 'cing-app/utils/lookups';

export default class UserProfilesController extends Controller {
  @service config: any;

  ESettings = ESettings;

  get appBuilderLinkId() {
    let appBuilderLinkId = get(
      this.config,
      `settings.${ESettings.UserProfilesProjectABLink}`
    );
    console.log('UP AP Link:', appBuilderLinkId, this.config);
    return appBuilderLinkId;
  }
}
