import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import SyncCompany from './sync-company';
import SyncContact from './sync-contact';

export default class SyncContactFundSearchItem extends Model {
  @attr('string') declare email?: string;
  @attr('string') declare role?: string;
  @attr('string') declare communications?: string;
  @belongsTo('sync-contact') declare person: AsyncBelongsTo<SyncContact>;
  @belongsTo('sync-company') declare company: AsyncBelongsTo<SyncCompany>;
  @belongsTo('sync-company') declare investor: AsyncBelongsTo<SyncCompany>;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sync-contact-fund-search-item': SyncContactFundSearchItem;
  }
}
