import EmberObject from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

import {
  ProjectTabMap
} from "cing-app/utils/lookups";
import classic from 'ember-classic-decorator';

@classic
export default class ProjectTabController extends Controller {
  ProjectTabMap = ProjectTabMap;
  init() {
    super.init(...arguments);

    this.set('presetFilters', {
      docket: EmberObject.create(),
      calendar: EmberObject.create(),
      parties: EmberObject.create(),
    });
  }
}