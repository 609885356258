import JSONAPISerializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class ActionSerializer extends JSONAPISerializer {
  attrs = {
    actionData: 'data',
    actionResult: 'result',

    createTime: { serialize: false },
    modifyTime: { serialize: false },
  };
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    action: ActionSerializer;
  }
}
