import Component from '@glimmer/component';
import AppbuilderLinkColumn, { ABColumnFormat } from 'cing-app/models/appbuilder-link-column';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';
import { data as currencies } from 'currency-codes';
import ProjectTabSettingsContactsField from 'cing-app/models/project-tab-settings-contacts-field';

interface TableViewEditorFormatArgs {
  header: any;
  footer: any;
  onClose: (a: boolean) => void;
  context: {
    column: AppbuilderLinkColumn | ProjectTabSettingsContactsField
  }
}

export default class TableViewEditorFormat extends Component<TableViewEditorFormatArgs> {
  @tracked
  format: ABColumnFormat;
  currencies = currencies;

  get date() {
    return new Date();
  }

  options = [
    { value: 'general', label: 'General' },
    { value: 'decimal', label: 'Number' },
    { value: 'currency', label: 'Currency' },
    { value: 'percent', label: 'Percent' },
    { value: 'fileSize', label: 'File Size' },
    { value: 'date', label: 'Date' },
    { value: 'custom', label: 'Custom' },
  ];

  customOptions = [
    { value: '***-**-****', label: 'SSN' },
    { label: 'Custom Format' },
  ];

  dateOptions = [
    {
      dateStyle: 'full',
      timeStyle: undefined
    },
    {
      dateStyle: 'long',
      timeStyle: undefined
    },
    {
      dateStyle: 'medium',
      timeStyle: undefined
    },
    {
      dateStyle: undefined,
      timeStyle: undefined
    },
    {
      dateStyle: 'short',
      timeStyle: undefined
    },
  ];

  timeOptions = [
    {
      timeStyle: undefined,
      dateStyle: undefined
    },
    {
      timeStyle: 'long',
      dateStyle: undefined
    },
    {
      timeStyle: 'medium',
      dateStyle: undefined
    },
    {
      timeStyle: 'short',
      dateStyle: undefined
    },
  ];

  numberOptions = [
    {
      negativeRed: false,
      signDisplay: 'auto',
    },
    {
      negativeRed: true,
      signDisplay: 'never',
    }
  ];

  currencyFormatOptions = [
    {
      currencyDisplay: 'symbol',
    },
    {
      currencyDisplay: 'narrowSymbol',
    },
    {
      currencyDisplay: 'code',
    },
    {
      currencyDisplay: 'name',
    },
  ];

  sizeOptions = [
    {
      unitDisplay: 'narrow',
    },
    {
      unitDisplay: 'short',
    },
    {
      unitDisplay: 'long',
    },
  ];

  currencyOptions = [
    {
      negativeRed: false,
      signDisplay: 'auto',
      currencySign: 'standard'
    },
    {
      negativeRed: true,
      signDisplay: 'never',
      currencySign: 'standard'
    },
    {
      negativeRed: false,
      signDisplay: 'auto',
      currencySign: 'accounting'
    },
    {
      negativeRed: true,
      signDisplay: 'auto',
      currencySign: 'accounting'
    }
  ];

  get selectedNumberOption() {
    return this.numberOptions.find(o => o.signDisplay === this.format.signDisplay && o.negativeRed === this.format.negativeRed) ?? this.numberOptions[0];
  }
  get selectedCurrencyOption() {
    return this.currencyOptions.find(o => o.signDisplay === this.format.signDisplay &&
      o.negativeRed === this.format.negativeRed &&
      o.currencySign === this.format.currencySign) ?? this.currencyOptions[0];
  }

  get customFormatString() {
    return this.selectedFormat?.value ?? this.format.customFormat;
  }

  get selectedFormat() {
    return this.customOptions.find((c: any) => c.value === this.format.customFormat) ?? this.customOptions[this.customOptions.length - 1];
  }

  get selectedCurrency() {
    return this.currencies.find((c: any) => c.code === this.format.currency);
  }
  get selectedCurrencyFormat() {
    return this.currencyFormatOptions.find((c: any) => c.currencyDisplay === this.format.currencyDisplay);
  }

  get selectedSizeOption() {
    return this.sizeOptions.find((c: any) => c.unitDisplay === this.format.unitDisplay);
  }
  get selectedDateOption() {
    return this.dateOptions.find((c: any) => c.dateStyle === this.format.dateStyle);
  }
  get selectedTimeOption() {
    return this.timeOptions.find((c: any) => c.timeStyle === this.format.timeStyle);
  }

  @action
  changeSelectedNumber(option: any) {
    set(this.format, 'negativeRed', option.negativeRed);
    set(this.format, 'signDisplay', option.signDisplay);
    this.format = this.format;
  }
  @action
  changeDateOption(option: any) {
    set(this.format, 'dateStyle', option.dateStyle);
    this.format = this.format;
  }
  @action
  changeFormat(option: any) {
    set(this.format, 'customFormat', option.value);
    this.format = this.format;
  }
  @action
  changeCustomFormat(val: any) {
    set(this.format, 'customFormat', val);
    this.format = this.format;
  }
  @action
  changeTimeOption(option: any) {
    set(this.format, 'timeStyle', option.timeStyle);
    this.format = this.format;
  }
  @action
  changeCurrencyOption(option: any) {
    set(this.format, 'negativeRed', option.negativeRed);
    set(this.format, 'signDisplay', option.signDisplay);
    set(this.format, 'currencySign', option.currencySign);
    this.format = this.format;
  }
  @action
  changeSizeOption(option: any) {
    set(this.format, 'unitDisplay', option.unitDisplay);
    this.format = this.format;
  }

  @action
  changeStyle(style: ABColumnFormat['style']) {
    this.format = new ABColumnFormat({
      style,
      maximumFractionDigits: (style === 'fileSize' ? 1 : undefined)
    });
  }

  @action
  changeCurrency(currency: any) {
    set(this.format, 'currency', currency.code);
    this.format = this.format;
  }

  @action
  changeCurrencyFormat(currency: any) {
    set(this.format, 'currencyDisplay', currency.currencyDisplay);
    this.format = this.format;
  }

  @action
  changeDelimiter() {
    set(this.format, 'useGrouping', !this.format.useGrouping);
    this.format = this.format;
  }

  @action
  changeFractionDigits(digits: string) {
    let n: number | undefined = parseInt(digits);
    if (Number.isNaN(n)) {
      n = undefined;
    }
    set(this.format, 'maximumFractionDigits', n);
    this.format = this.format;
  }

  constructor(owner: unknown, args: TableViewEditorFormatArgs) {
    super(owner, args);

    if (this.args.context.column.format) {
      this.format = new ABColumnFormat(this.args.context.column.format);
    } else {
      this.format = new ABColumnFormat({});
    }

  }

  @task
  saveFormat = taskFor(async () => {
    let oldFormat = this.args.context.column.format;
    try {
      this.args.context.column.format = this.format;
      // await this.args.context.column.save();
      this.args.onClose(false);
    }
    catch (err) {
      this.args.context.column.format = oldFormat;
      //show error
    }
  });
}
