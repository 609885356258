import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@ember/component';
import moment from 'moment';
import _ from 'lodash';
import { CaseStatus } from 'cing-app/utils/lookups';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  DateRangeFilter
} from 'cing-app/mixins/filter-builder';
import BootstrapTheme from 'ember-models-table/themes/bootstrap4';

@classic
export default class Projects extends Component {
  @inject()
  store;

  @inject()
  config;

  @inject()
  appEvents;

  include = 'case,company';
  searchQuery = '';

  columns = [
    {
      propertyName: 'Name',
      title: 'Name',
      className: "column-project-name",
      //component: 'main-dashboard/watchlist/column-case',
      sortPrecedence: 0,
      sortDirection: "asc",
    },
    {
      propertyName: 'company.name',
      title: 'Company',
      className: "column-dockets-count text-center",
    },
    {
      propertyName: 'status',
      title: 'Status',
    },
    /*
    {
        propertyName: 'dateStarted',
        title: 'Date Filed',
        className: "text-right column-date",
        component: "api-table/columns/date",
        dateFormat: "MM/DD/YYYY",
    },
    */
  ];

  init() {
    super.init(...arguments);
    this.set('tableTheme', BootstrapTheme.create({
      table: 'table table-hover table-sm',
      paginationWrapperDefault: 'col-sm-12'
    }));

    this.generateFilter();
  }

  generateFilter() {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    let searchQuery = this.get('searchQuery');
    if (searchQuery) {
      let searchQueryExpr = Expressions.create({ operator: ExpressionOperators.OR });
      ['name', 'company.name', 'case.name', 'case.number', 'case.displayNumber'].forEach((propName) => {
        searchQueryExpr.add(Filter.create({
          name: propName,
          operator: FilterOperators.LIKE,
          value: searchQuery
        }));
      });
      expr.add(searchQueryExpr);
    }

    if (expr.get('expressions.length')) {
      this.set('filter', expr.serialize());
    } else {
      this.set('filter', undefined);
    }
  }

  @action
  search() {
    this.generateFilter();
  }

  @action
  clearSearch() {
    this.set('searchQuery', null);
    this.generateFilter();
  }
}
