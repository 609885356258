import Component from '@glimmer/component';
import { get, set, action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import Store from '@ember-data/store';
import InterestedParty from 'cing-app/models/interested-party';
import { taskFor } from 'ember-concurrency-ts';
import {
  PartyRoleTypes,
  PartyRole,
  PartyTypes,
  PartyType,
  EmailTypes,
  EntityTypeMap,
} from 'cing-app/utils/lookups';
import { USStates } from 'cing-app/utils/us-states-lookup';
import {
  Filter,
  Expressions,
  ExpressionOperators,
  FilterOperators,
} from 'cing-app/mixins/filter-builder';
import { htmlSafe } from '@ember/string';
import { isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

interface EntryArgs {
  context: any;
  small: any;
  createParty: any;
}

export default class Entry extends Component<EntryArgs> {
  @service store!: Store;

  @service session!: any;

  @service('docker-item')
  docker: any;

  @tracked
  interestedParty: InterestedParty = null;

  @tracked
  partyType = null;

  @tracked
  newPerson: any;

  @tracked
  newAddress: any;

  @tracked
  companySearchPage = 1;

  @tracked
  selectCompanyContacts: any;

  @tracked
  selectedCompany: any;

  @tracked
  selectedPerson: any;

  @tracked
  selectedEmail: any;

  @tracked
  project: any;

  @tracked
  createParty: any;

  get partyRoleIds() {
    let roles = [];

    for (let prop in PartyRoleTypes) {
      roles.push(PartyRoleTypes[prop]);
    }

    return roles;
  }

  get partyRoles() {
    let roles = {};

    for (let prop in PartyRole) {
      roles[PartyRole[prop]] = prop;
    }

    return roles;
  }

  constructor(owner: any, args: EntryArgs) {
    super(owner, args);
    this.project = this.args.context.project;
    this.createParty = this.args.createParty;
    this.initTask.perform();
  }

  @task
  initTask = taskFor(async () => {
    let interestedParty = this.project.interestedParty;

    if (!interestedParty) {
      interestedParty = this.store.createRecord('interested-party', {
        case: this.project.case,
        caseId: this.project.case.id,
        projectId: this.project.projectId,
        project: this.project,
        isCompany: true,
        typeId: PartyRoleTypes.INTERESTED_PARTY,
      });
    } else {
      interestedParty = await this.store.findRecord(
        'interested-party',
        interestedParty.get('id'),
        {
          reload: true,
          include: 'email,person,company,address,interested-party-role',
        }
      );
    }

    this.interestedParty = interestedParty;
  });

  @task({ restartable: true })
  searchCompaniesTask = taskFor(async (term) => {
    await timeout(150);

    if (!term) {
      return null;
    }

    let expr = Expressions.create();

    expr.add(
      Filter.create({
        name: 'name',
        operator: FilterOperators.START_WITH,
        value: term,
      })
    );

    return await this.store.query('company', {
      condition: expr.serialize(),
      sort: 'name',
      page: { size: 50, number: 1 },
    });
  });

  @task({ restartable: true })
  searchPersonsTask = taskFor(async (term) => {
    if (!term) {
      return null;
    }
    try {
      let expr = Expressions.create({ operator: ExpressionOperators.AND });

      if (this.selectedCompany && this.selectedCompany.get('id')) {
        expr.add(
          Filter.create({
            name: 'company.id',
            operator: FilterOperators.EQUAL,
            value: this.selectedCompany.get('id'),
          })
        );
      }

      let termParts = term.split(' ');

      let exprSearch = Expressions.create({ operator: ExpressionOperators.OR });

      if (termParts.length > 1) {
        let nameExpression = Expressions.create({
          operator: ExpressionOperators.AND,
        });

        nameExpression.add(
          Filter.create({
            name: 'firstName',
            operator: FilterOperators.START_WITH,
            value: termParts[0],
          })
        );

        nameExpression.add(
          Filter.create({
            name: 'lastName',
            operator: FilterOperators.START_WITH,
            value: termParts[termParts.length - 1],
          })
        );

        exprSearch.add(nameExpression);
      }

      ['firstName', 'lastName'].forEach((propName) => {
        exprSearch.add(
          Filter.create({
            name: propName,
            operator: FilterOperators.START_WITH,
            value: term,
          })
        );
      });

      expr.add(exprSearch);

      return await this.store.query('person', {
        condition: expr.serialize(),
        include: 'company,emails',
        sort: 'lastName',
        page: { size: 50, number: 1 },
      });
    } catch (e) {
      console.log(e);
    }
  });

  @task
  onSelectPersonTask = taskFor(async (person) => {
    if (!person) {
      return;
    }

    let company = await person.get('company');
    let email = (await person.get('emails')).firstObject;

    let party = this.store.createRecord('interested-party', {
      person: person,
      personId: person ? person.id : null,
      project: this.project,
      projectId: this.project.id,
      company: company,
      companyId: company ? company.id : null,
      email: email,
      emailId: email ? email.id : null,
    });

    if (this.args.createParty) {
      this.args.createParty(party);
    }
  });

  @task({ restartable: true })
  searchEmailsTask = taskFor(async (term) => {
    if (!term) {
      return null;
    }

    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    if (this.selectedPerson && this.selectedPerson.get('id')) {
      expr.add(
        Filter.create({
          name: 'person.id',
          operator: FilterOperators.EQUAL,
          value: this.selectedPerson.get('id'),
        })
      );
    }

    expr.add(
      Filter.create({
        name: 'emailAddress',
        operator: FilterOperators.START_WITH,
        value: term,
      })
    );

    return await this.store.query('email', {
      condition: expr.serialize(),
      include: 'person,person.company',
      sort: 'emailAddress',
      page: { size: 50, number: 1 },
    });
  });

  @task
  onSelectEmailTask = taskFor(async (email) => {
    if (!email) {
      return;
    }

    let person = await email.get('person');
    let company = await person.get('company');

    let party = this.store.createRecord('interested-party', {
      person: person,
      personId: person ? person.id : null,
      project: this.project,
      projectId: this.project.id,
      company: company,
      companyId: company ? company.id : null,
      email: email,
      emailId: email ? email.id : null,
    });

    if (this.args.createParty) {
      this.args.createParty(party);
    }
  });

  @task
  saveTask = taskFor(async () => {
    let interestedParty = this.interestedParty;

    let company = await interestedParty.get('company');
    let person = await interestedParty.get('person');

    if (!person) {
      person = this.newPerson;
    }

    let email = await interestedParty.get('email');
    let address = await interestedParty.get('address');

    if (!address) {
      address = this.newAddress;
    }

    if (company) {
      if (company.get('isNew') || company.get('hasDirtyAttributes')) {
        await company.save();
      }

      interestedParty.setProperties({
        companyId: company.id,
        company: company,
      });
    }

    if (address) {
      if (address.get('isNew') || address.get('hasDirtyAttributes')) {
        await address.save();
      }

      interestedParty.setProperties({
        addressId: address.id,
        address: address,
      });
    }

    if (email) {
      if (email.get('isNew') || email.get('hasDirtyAttributes')) {
        await email.save();
      }

      interestedParty.setProperties({
        emailId: email.id,
        email: email,
      });
    }

    if (person) {
      if (person.get('isNew') || person.get('hasDirtyAttributes')) {
        await person.save();
      }

      interestedParty.setProperties({
        personId: person.id,
        person: person,
      });
    }

    if (person && company) {
      let expr = Expressions.create();

      expr.add(
        Filter.create({
          name: 'person-id',
          operator: FilterOperators.EQUAL,
          value: person.get('id'),
        })
      );
      expr.add(
        Filter.create({
          name: 'company-id',
          operator: FilterOperators.EQUAL,
          value: company.get('id'),
        })
      );

      let personInCompany = (
        await this.store.query('person-in-company', {
          condition: expr.serialize(),
          sort: '-createTime',
        })
      ).get('firstObject');

      if (!personInCompany) {
        personInCompany = this.store.createRecord('person-in-company', {
          personId: person.get('id'),
          companyId: company.get('id'),
          addressId: address ? address.get('id') : null,
        });

        await personInCompany.save();
      }
    }

    await interestedParty.save();

    this.newPerson = null;
    this.newAddress = null;

    if (this.args.context.get('onCreate')) {
      this.args.context.get('onCreate')(interestedParty);
    }
  });

  @action
  focusComesFromOutside(e) {
    let blurredEl = e.relatedTarget;
    if (isBlank(blurredEl)) {
      return false;
    }
    return !blurredEl.classList.contains('ember-power-select-search-input');
  }

  @action
  handleDropdownFocus(select, e) {
    if (this.focusComesFromOutside(e)) {
      select.actions.open();
    }
  }

  @action
  showCompanyDetail(company) {
    const appearance = {
      label: 'Company detail',
      icon: '',
      title: 'Company: ' + company.get('name'),
    };

    const context = { companyId: company.get('id') };
    this.docker.invokePopup('company-detail', appearance, context);
  }

  @action
  save() {
    this.saveTask.perform();
  }

  @action
  onSelectContacts(contacts) {
    this.selectCompanyContacts = null;

    if (this.args.createParty) {
      this.args.createParty(contacts);
    }
  }

  @action
  searchCompanies(term) {
    return this.searchCompaniesTask.perform(term);
  }

  @action
  selectCompany(company) {
    this.selectCompanyContacts = company;
  }

  @action
  createCompany(companyName) {
    let company = this.store.createRecord('company', {
      name: companyName,
    });

    return company.save().then((result) => {
      this.selectCompany = result;
    });
  }

  @action
  hideCreateForCompany(searchTerm, options) {
    return !options.findBy('name', searchTerm);
  }

  @action
  searchPersons(term) {
    return this.searchPersonsTask.perform(term);
  }

  @action
  createPerson(fullName) {
    let person = this.store.createRecord('person');
    person.parseFromFullName(fullName);

    return person.save().then((result) => {
      this.onSelectPerson(result);
    });
  }

  @action
  hideCreateForPerson(searchTerm, options) {
    return !options.findBy('fullName', searchTerm);
  }

  @action
  onSelectPerson(person) {
    this.onSelectPersonTask.perform(person);
  }

  @action
  searchEmails(term) {
    return this.searchEmailsTask.perform(term);
  }

  @action
  createEmail(emailAddress) {
    let email = this.store.createRecord('email', {
      emailAddress: emailAddress,
    });

    return email.save().then((result) => {
      this.selectedEmail = result;
    });
  }

  @action
  hideCreateForEmail(searchTerm, options) {
    return !options.findBy('emailAddress', searchTerm);
  }

  @action
  onSelectEmail(email) {
    this.onSelectEmailTask.perform(email);
  }
}
