import Component from '@glimmer/component';

interface ColumnComponentArgs {
  companyProfile:any;
  hqAddress:any;
  hqPhone:any;
}

export default class ColumnComponent extends Component<ColumnComponentArgs> {
  get companyProfile() {
    return this.args.companyProfile;
  }
  get hqPhone(){
    return this.args.hqPhone;
  }
  get hqAddress(){
    return this.args.hqAddress;
  }
 }