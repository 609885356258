import Component from '@ember/component';
import { get, set, action } from '@ember/object';
import classic from 'ember-classic-decorator';

@classic
export default class Companies extends Component {
  init() {
    super.init(...arguments);

    let companies = get(this.record, this.column.propertyName);

    if (companies.length) {
      set(this, 'selected', companies.firstObject);
      this.onSelect(companies.firstObject);
    }
  }

  @action
  onSelect(company) {
    set(this.record, 'company', company);
    return false;
  }
}