import Model, { attr } from '@ember-data/model';

export default Model.extend({
  templateTitle: attr('string'),
  emailOption: attr('number'),
  dateCreated: attr('date'),
  isDefault: attr('boolean'),
  siteId: attr('number'),
  subject: attr('string'),
  customLogo: attr('string'),
  freeForm: attr('boolean'),
  alertSettingTypeId: attr('number'),
  siteRedirectUrlId: attr('number'),
  body: attr('string'),
  languageId: attr('number')
})