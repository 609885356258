import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { AclPermissionType } from 'cing-app/utils/lookups';

@classic
@classNames('power-select-sm')
export default class ColumnGender extends Component {
  @service
  store;

  init() {
    super.init();
    let selected = this.get('column.options').findBy('value', this.get(`record.${this.column.propertyName}`));
    this.set('selected', selected);
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  click(e) {
    return false;
  }

  @action
  onSelect(option) {
    this.set('selected', option);

    if (option) {
      this.set(`record.${this.column.propertyName}`, option.value);
    } else {
      this.set(`record.${this.column.propertyName}`, null);
    }
  }
}