import Component from '@glimmer/component';
import { ESettings } from 'cing-app/utils/lookups';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';

interface apiError {
  title?: string;
  status?: number | string;
  detail?: string;
}
interface SmartroomFileMnagerErrorsArgs {
  error?: Error & {
    error?: { errors?: apiError[] };
    errors?: apiError[];
    status?: number;
  };
  onReload?: () => void;
}

export default class SmartroomFileManagerErrors extends Component<SmartroomFileMnagerErrorsArgs> {
  @service config!: any;

  ESettings = ESettings;

  get supportEmail() {
    let settings = get(this.config, 'settings');
    return settings[this.ESettings.SupportEmail];
  }

  constructor(owner: any, args: SmartroomFileMnagerErrorsArgs) {
    super(owner, args);
    console.log('ERROR: ', this.args.error);
  }

  get isPermissionError(): boolean {
    const permissionStatuses = [
      '403', // forbidden
      '404',
      '30000', // View as user does not have permission to access
      '30001', // Unknown SmartRoom
      '21003',
      '25016', //User status is on hold
      '25017',
      '25018',
    ];
    if (this.errorStatus) {
      const status = this.errorStatus + '';
      return permissionStatuses.includes(status);
    }

    return false;
  }

  get errorStatus() {
    if (this.args.error?.status) {
      return this.args.error.status;
    }

    let apiError = this.args.error?.error?.errors?.firstObject;
    if (!apiError) apiError = this.args.error?.errors?.firstObject;

    if (apiError) {
      return apiError.status || null;
    }

    return null;
  }

  get errorMessage(): string {
    let apiError = null;
    if (!this.args.error) return '';

    const { error, errors, message } = this.args.error;

    if (errors) {
      apiError = errors && errors.firstObject;
    } else if (error) {
      apiError = error.errors && error.errors.firstObject;
    }

    // api error
    if (apiError) {
      return apiError.detail
        ? `${apiError.title}; ${apiError.detail}`
        : `${apiError.title}`;
    }

    return message || 'There was an error while communicating with SmartRoom';
  }
}
