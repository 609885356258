import { attr } from '@ember-data/model';
//@ts-ignore
import Fragment from 'ember-data-model-fragments/fragment';

export default class ExtendedDataMappingConfigProperty extends Fragment {
  @attr('string')
  declare propertyName: string;
  @attr('string')
  declare displayName: string;
  @attr('string')
  declare systemTypeName: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'extended-data-mapping-config-property': ExtendedDataMappingConfigProperty;
  }
}
