import Component from '@ember/component';
import { get } from '@ember/object';
export default Component.extend({
  tagName: '',
  actions: {
    clickOnRow(index, record, event) {
      this.clickOnRow(index, record);
      event.stopPropagation();
    }
  }
});
