import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Store from '@ember-data/store';

export default class Application extends JSONAPIAdapter {
  @service() config: any;
  @service session: any;
  // host: alias('config.APP.api.host'),
  // namespace: alias('config.APP.api.namespace'),
  elementId = 'wrapper';

  get host() {
    return this.config.get('APP.api.host');
  }

  get namespace() {
    return this.config.get('APP.api.namespace');
  }

  methodForRequest({ requestType }) {
    if (requestType === 'updateRecord') {
      return 'PUT';
    }

    // return this._super(...arguments);
    return super.methodForRequest({ requestType });
  }

  findRecord(store: Store, type: any, id: string, snapshot: any) {
    let url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
    let query = this.buildQuery(snapshot);

    if (snapshot.adapterOptions) {
      if (snapshot.adapterOptions.include) {
        query['include'] = snapshot.adapterOptions.include;
      }
    }

    return this.ajax(url, 'GET', { data: query });
  }

  findHasMany(store: Store, snapshot: any, url: string, relationship: any) {
    let id = snapshot.id;
    let type = snapshot.modelName;

    let adapterOptions = snapshot.adapterOptions;

    let queryParams = {};

    if (adapterOptions) {
      if (adapterOptions.include) {
        queryParams['include'] = adapterOptions.include;
      }
      if (adapterOptions.condition) {
        queryParams['condition'] = adapterOptions.condition;
      }
    }

    url = this.urlPrefix(url, this.buildURL(type, id, snapshot, 'findHasMany'));

    return this.ajax(url, 'GET', {
      data: queryParams,
    });
  }

  buildURL(modelName: any, id: any, snapshot: any, requestType: string) {
    // let url = this._super(...arguments);
    let url = super.buildURL(modelName, id, snapshot, requestType);

    if (this.config.get('viewAsUser')) {
      let modifyUrl = new URL(url);
      modifyUrl.searchParams.append('viewas', this.config.get('viewAsUser'));

      url = modifyUrl.toString();
    }

    return url;
  }

  @computed('session.{data.authenticated.access_token,isAuthenticated}')
  get headers() {
    let headers = {};
    if (this.session.isAuthenticated) {
      // OAuth 2
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }
}
