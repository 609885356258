import Router from '../router';

export default {
    name: 'setup-config',
    after: 'setup-server-vars',
    initialize(app) {
        let { container = app } = app;
        let config = container.lookup('service:config');
        let siSessionKey = config.get('ember-smartidentity-auth.sessionKey');

        if (siSessionKey) {
            let sessionStore = container.lookup('session-store:application');
            sessionStore.set('key', siSessionKey);
        }

        // register 'externalLogin' route in app's router
        Router.map(function () {
            this.route('si-external-login');
        });

        /*
        config.setProperties('ember-simple-auth', {
            authenticationRoute: config.get('authenticationRoute'),
            routeIfAlreadyAuthenticated: config.get('routeIfAlreadyAuthenticated'),
            routeAfterAuthentication: config.get('routeAfterAuthentication'),
        });
        */

        config.setProperties({
            'torii.providers.si-oauth2.apiKey': config.get('ember-smartidentity-auth.apiKey'),
            'torii.providers.si-oauth2.scope': config.get('ember-smartidentity-auth.scope'),
            'torii.providers.si-oauth2.baseUrl': config.get('ember-smartidentity-auth.smartIdentityServer') + '/connect/authorize',
            'torii.providers.si-oauth2.remoteServiceName': 'iframe'
        });
    }
};
