import Component from '@ember/component';
import { inject } from '@ember/service';
import { task } from 'ember-concurrency';
import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { observer } from '@ember/object';
import Company from 'cing-app/models/company';

export default Component.extend(FilterBuilder, {
  searchAndSelect: false,
  store: inject(),
  session: inject(),
  exportData: inject(),
  docker: inject('docker-item'),
  config: inject(),
  pageSize: 100,
  selectedCompanyTypes: null,
  CompanyTypeList: [],
  Company: Company,
  includes: 'company-domains,company-type',
  searchQuery: '',
  searchOptions: {
    name: true,
    'company-domains.domain': true,
  },
  init() {
    this._super(...arguments);
    this.set('selectedCompanies', []);
    this.set('columns', this.getColumns());
    this.generateFilter();
    this.initTask.perform();
  },
  initTask: task(function* () {
    this.CompanyTypeList = this.store.query('company-type', { page: { size: 1000 }, sort: 'name' });
  }),
  getColumns() {
    let columns = [
      {
        propertyName: 'name',
        component: 'api-table/columns/company',
        sortDirection: 'asc',
        sortPrecedence: 0,
        className: 'column-company-name',
      },
      {
        propertyName: 'companyDomains',
        title: 'Domains',
        component: 'crm-search/by-company/column-domains',
        disableSorting: true,
        className: 'column-company-domains',
      },
      {
        propertyName: 'companyType.name',
        title: 'Type',
        className: 'column-company-type',
        sortedBy: 'companyType.name',
      },
    ];

    if (this.get('session.isAdmin')) {
      columns.push({
        component: 'api-table/select-row-checkbox',
        useFilter: false,
        //mayBeHidden: true,
        //isHidden: true,
        title: 'Select',
        className: 'column-checkbox',
        componentForSortCell: 'api-table/select-all-rows-checkbox',
      });
    }

    if (this.get('searchAndSelect')) {
      columns.push({
        component: 'api-table/select-one',
        useFilter: false,
        title: 'Select',
        className: 'column-select-one',
      });
    }

    return columns;
  },
  updateColumns: observer('session.isAdmin', function () {
    this.set('columns', this.getColumns());
  }),
  generateFilter: function () {
    let expr = Expressions.create({ operator: ExpressionOperators.AND });

    const searchQuery = this.get('searchQuery');

    if (this.selectedCompanyTypes?.length) {
      expr.add(
        Filter.create({
          name: 'company-type.id',
          operator: FilterOperators.IN,
          value: this.selectedCompanyTypes.mapBy('id'),
        })
      );
    }

    const searchOptions = this.get('searchOptions');
    if (searchQuery) {
      let searchQueryExpr = Expressions.create({
        operator: ExpressionOperators.OR,
      });
      for (let propName in searchOptions) {
        if (searchOptions[propName]) {
          searchQueryExpr.add(
            Filter.create({
              name: propName,
              operator: FilterOperators.LIKE,
              value: searchQuery,
            })
          );
        }
      }
      expr.add(searchQueryExpr);
    }

    expr.add(
      Filter.create({
        name: 'masterId',
        operator: FilterOperators.NULL,
      })
    );

    this.set('filter', expr.serialize());
  },
  actions: {
    onSelectOne(company) {
      this.sendAction('onSelect', company);
    },
    mergeCompanies() {
      let appearance = {
        icon: 'company',
        title: 'Merge companies',
        size: 'large',
        custom: true,
      };

      let self = this;

      this.get('docker').invokePopup('company-merge', appearance, {
        companies: this.get('selectedCompanies'),
        onComplete: function () {
          self.get('selectedCompanies').clear();
          self.set('reloadData', true);
        },
      });
    },
    search() {
      this.generateFilter();
    },
    addTypeFilter(selectedTypes) {
      this.set('selectedCompanyTypes', selectedTypes);
      this.generateFilter();
    },
    addCompany() {
      // let self = this;
      let record = this.get('store').createRecord('company', {});

      let self = this;

      this.docker.popupEditCompany(record, {
        onCreate: function () {
          self.set('reloadData', true);
        },
      });
    },
  },
});
