import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Store from '@ember-data/store';
import _SessionService from 'cing-app/pods/session/service';
import DockerItemService from 'cing-app/pods/docker-item/service';

import AbPmService from 'cing-app/pods/ab-pm/service';

interface SearchPopupArgs {
  context: {
    smartRoomId: any;
    onSelectResource: any;
  };
}

export default class SearchPopup extends Component<SearchPopupArgs> {
  @service store!: Store;

  @service('docker-item') docker!: DockerItemService;

  @service('ab-pm') pmService!: AbPmService;

  @tracked sources = [
    { name: 'SmartExchange', id: 'se', disabled: false },
    { name: 'SmartRoom', id: 'sr', disabled: false },
    { name: 'Outlook', id: 'mail', disabled: true },
    { name: 'DealCloud', id: 'dc', disabled: true },
    { name: 'SalesForce', id: 'sf', disabled: true },
    { name: 'Other CRM', id: 'crm', disabled: true },
  ];

  @tracked selectedSource: any;
  @tracked selectedUser: any;
  @tracked errorMessage: string = '';

  constructor(owner: any, args: any) {
    super(owner, args);

    this.selectedSource = this.sources.firstObject;
  }

  @action onSourceChange(value: any) {
    this.selectedSource = value;
  }

  @action onSelect(user: any) {
    this.selectedUser = user;
  }

  @action selectUser() {
    if (this.args.context.onSelectResource) {
      this.errorMessage = this.args.context.onSelectResource(this.selectedUser);
    }
  }
}
