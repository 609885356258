import JSONAPISerializer from '@ember-data/serializer/json-api';
import classic from 'ember-classic-decorator';

@classic
export default class CrmImportSerializer extends JSONAPISerializer {
  attrs = {
    rowData: { key: 'data' },
  };
  /*
  serialize(snapshot, options) {
      var json = this._super(...arguments);
      
      //console.log("JSON: ", json.data);
      //json.data.attributes.data = JSON.stringify(json.data.attributes.data);
  
      return json;
  },
  */
  /*
  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload.data.attributes['data'] = JSON.parse(payload.data.attributes['data']);
      return this._super(store, primaryModelClass, payload, id, requestType);
  },
  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      for (let a = 0; a < payload.data.length; a++) {
          payload.data[a].attributes['data'] = JSON.parse(payload.data[a].attributes['data']);
      }
      
      let result = this._super(store, primaryModelClass, payload, id, requestType);
      return result;
  },
  */
}
// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'crm-import': CrmImportSerializer;
  }
}
