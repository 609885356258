import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';

import moment from 'moment';
import AbPmService, { AbPmLookups } from 'cing-app/pods/ab-pm/service';
import DockerItemService from 'cing-app/pods/docker-item/service';
import { SmartroomFolderModel } from 'smex-ui-sr-models';

interface AbPmTaskEditArgs {
  context: {
    abLink: any;
    smartRoomId: any;
    parentTask: any;
    task: any;
    action: string;
    onTaskAction: any;
  };
}

export default class AbPmTaskEdit extends Component<AbPmTaskEditArgs> {
  @service('ab-pm') pmService!: AbPmService;
  @service('docker-item') docker!: DockerItemService;

  @tracked taskTypes: any;
  @tracked taskCategories: any;
  @tracked taskPriorities: any;
  @tracked taskStatuses: any;
  @tracked saveMessage: string = '';
  @tracked isError: boolean = false;

  //we use a tracked field for date input
  //because the ui will not update on the model updates
  @tracked startDate: any;
  @tracked endDate: any;
  @tracked dueDate: any;

  @tracked currentTask: any;
  @tracked selectedTaskType: any;
  @tracked selectedTaskCategory: any;
  @tracked selectedTaskPriority: any;
  @tracked selectedTaskStatus: any;

  @tracked smartRoomFolderName: any;
  @tracked smartRoomIndexPopup: any;

  // this is set on task-resources component
  taskResourcesContext: any;

  constructor(owner: any, args: any) {
    super(owner, args);

    this.initTask.perform();
  }

  setTaskField(destination: any, source: any) {
    //destination.id = source.id;
    destination.ordinal = source.ordinal;
    destination.index = source.index;
    destination.subtasks = source.subtasks;
    destination.wbs = source.wbs;
    destination.taskId = source.taskId;
    destination.parent = source.parent;
    destination.taskTypeId = source.taskTypeId;
    destination.type = source.type;
    destination.taskCategoryId = source.taskCategoryId;
    destination.category = source.category;
    destination.taskStatusId = source.taskStatusId;
    destination.status = source.status;
    destination.taskPriorityId = source.taskPriorityId;
    destination.priority = source.priority;
    destination.task = source.task;
    destination.summary = source.summary;
    destination.createdDate = source.createdDate;
    destination.createdBy = source.createdBy;
    destination.resourceId = source.resourceId;
    destination.resource = source.resource;
    destination.email = source.email;
    destination.startDate = source.startDate;
    destination.endDate = source.endDate;
    destination.dueDate = source.dueDate;
    destination.duration = source.duration;
    destination.progress = source.progress;
    destination.folderId = source.folderId;
    destination.folderName = source.folderName;
    destination.folderNumber = source.folderNumber;
  }

  @task initTask = taskFor(async () => {
    let lookups = await this.pmService.preloadLookupItems(
      this.args.context.abLink
    );

    this.taskTypes = lookups.taskTypes;
    this.taskCategories = lookups.taskCategories;
    this.taskPriorities = lookups.taskPriorities;
    this.taskStatuses = lookups.taskStatuses;

    this.currentTask = {};
    this.setTaskField(this.currentTask, this.args.context.task);
    this.currentTask.id = this.args.context.task.id;
    this.currentTask.taskId = this.args.context.task.id;

    this.smartRoomFolderName = this.currentTask.folderName;

    //NOTE: for the powerselect option to be selected,
    // the binded value must be a string
    if (this.currentTask.taskTypeId) {
      this.selectedTaskType = this.taskTypes.findBy(
        'id',
        String(this.currentTask.taskTypeId)
      );
    }

    if (this.currentTask.taskCategoryId) {
      this.selectedTaskCategory = this.taskCategories.findBy(
        'id',
        String(this.currentTask.taskCategoryId)
      );
    }

    if (this.currentTask.taskPriorityId) {
      this.selectedTaskPriority = this.taskPriorities.findBy(
        'id',
        String(this.currentTask.taskPriorityId)
      );
    }

    if (this.currentTask.taskStatusId) {
      this.selectedTaskStatus = this.taskStatuses.findBy(
        'id',
        String(this.currentTask.taskStatusId)
      );
    }

    if (this.currentTask.startDate) {
      this.startDate = moment(this.currentTask.startDate).format('YYYY-MM-DD');
    }

    if (this.currentTask.endDate) {
      this.endDate = moment(this.currentTask.endDate).format('YYYY-MM-DD');
    }

    if (this.currentTask.dueDate) {
      this.dueDate = moment(this.currentTask.dueDate).format('YYYY-MM-DD');
    }

    console.log('edit task is ', this.currentTask);
  });

  get isBusy() {
    return this.initTask.isRunning || this.saveTask.isRunning;
  }

  @action dateChanged(event: any) {
    this.saveMessage = '';
    let oldStatus = this.currentTask.status;
    console.log('date changed ', event.target);
    if (event.target && event.target.name == 'startDate') {
      let duration = parseInt(this.currentTask.duration);
      if (duration && !isNaN(duration)) {
        // auto calculate end date
        this.endDate = moment(this.startDate)
          .add(duration, 'days')
          .format('YYYY-MM-DD');
      } else if (this.endDate && this.startDate) {
        // auto calculate duration
        let starts = moment(this.startDate);
        let ends = moment(this.endDate);
        if (ends.isValid() && starts.isValid()) {
          this.currentTask.duration = ends.diff(starts, 'days');
        }
      }
    }

    this.pmService.computeTaskStatusFromDates(
      this.currentTask,
      this.startDate,
      this.endDate
    );

    // we need to get the status id if status changed
    if (oldStatus != this.currentTask.status) {
      this.selectedTaskStatus = this.taskStatuses.findBy(
        'name',
        String(this.currentTask.status)
      );

      if (this.selectedTaskStatus) {
        this.currentTask.taskStatusId = this.selectedTaskStatus.id;
      }
    }

    this.isError = false;
    let validationError = this.pmService.validateTaskFields(
      this.currentTask,
      this.args.context.parentTask,
      false
    );
    if (validationError) {
      this.isError = true;
      this.saveMessage = validationError;
      return;
    }
  }

  @action durationChanged() {
    this.saveMessage = '';

    let duration = parseInt(this.currentTask.duration);

    if (this.startDate) {
      let newEndDate = moment(this.startDate)
        .add(duration, 'days')
        .format('YYYY-MM-DD');

      this.endDate = newEndDate;
      this.currentTask.endDate = newEndDate;
    } else if (this.endDate) {
      duration = parseInt(this.currentTask.duration) * -1;
      let newStartDate = moment(this.endDate)
        .add(duration, 'days')
        .format('YYYY-MM-DD');

      this.startDate = newStartDate;
      this.currentTask.startDate = newStartDate;
    }

    let oldStatus = this.currentTask.status;
    this.pmService.computeTaskStatusFromDates(
      this.currentTask,
      this.startDate,
      this.endDate
    );

    // we need to get the status id if status changed
    if (oldStatus != this.currentTask.status) {
      this.selectedTaskStatus = this.taskStatuses.findBy(
        'name',
        String(this.currentTask.status)
      );

      if (this.selectedTaskStatus) {
        this.currentTask.taskStatusId = this.selectedTaskStatus.id;
      }
    }
  }

  @action taskChanged() {
    this.saveMessage = '';

    this.isError = false;
    let validationError = this.pmService.validateTaskFields(
      this.currentTask,
      this.args.context.parentTask,
      true
    );
    if (validationError) {
      this.isError = true;
      this.saveMessage = validationError;
      return;
    }
  }

  @action onResourceChange() {
    this.saveMessage = '';
    //newResources: any, originalResources: any
    console.log('tr args ', arguments);
  }

  @action save(e: Event) {
    this.saveTask.perform();

    if (e && e.preventDefault) {
      e.preventDefault();
    }
  }

  @task saveTask = taskFor(async () => {
    this.saveMessage = 'Validating task...';
    console.log('test');
    this.isError = false;

    if (this.startDate && moment(this.startDate).isValid()) {
      this.currentTask.startDate = moment(this.startDate).format('YYYY-MM-DD');
    } else {
      this.currentTask.startDate = null;
    }
    if (this.endDate && moment(this.endDate).isValid()) {
      this.currentTask.endDate = moment(this.endDate).format('YYYY-MM-DD');
    } else {
      this.currentTask.endDate = null;
    }
    if (this.dueDate && moment(this.dueDate).isValid()) {
      this.currentTask.dueDate = moment(this.dueDate).format('YYYY-MM-DD');
    } else {
      this.currentTask.dueDate = null;
    }

    let validationError = this.pmService.validateTaskFields(
      this.currentTask,
      this.args.context.parentTask,
      true
    );
    if (validationError) {
      this.isError = true;
      this.saveMessage = validationError;
      return;
    }

    if (!this.currentTask.startDate || this.currentTask.startDate == '') {
      this.currentTask.startDate = null;
    }
    if (!this.currentTask.endDate || this.currentTask.endDate == '') {
      this.currentTask.endDate = null;
    }
    if (!this.currentTask.dueDate || this.currentTask.dueDate == '') {
      this.currentTask.dueDate = null;
    }
    if (
      !this.currentTask.folderName ||
      String(this.currentTask.folderName || '').trim() == ''
    ) {
      this.currentTask.folderName = this.currentTask.folderName;
    }

    try {
      this.saveMessage = 'Saving task record...';
      let taskToUpdate = this.args.context.task;
      this.setTaskField(taskToUpdate, this.currentTask);

      console.log('taskToUpdate ', taskToUpdate);
      if (taskToUpdate && taskToUpdate.save) {
        try {
          await taskToUpdate.save();
        } catch (ex) {
          console.log('ex ', ex);
        }
      }

      this.saveMessage = 'Saving resources';
      if (
        this.taskResourcesContext &&
        this.taskResourcesContext.saveResources
      ) {
        await this.taskResourcesContext.saveResources();
      }

      this.saveMessage = 'Task has been updated.';

      setTimeout(() => {
        this.saveMessage = '';

        this.args.context.onTaskAction('task-updated', taskToUpdate, {
          action: this.args.context.action,
          target: taskToUpdate,
        });
      }, 1000);
    } catch (ex) {
      console.log('Error in saving task ', ex);
    }
  });

  @action hideCreateOptionOnSameTypeName(term: string) {
    let existingOption = this.taskTypes.find(({ name }) => name == term);
    return !existingOption;
  }

  @action selectTaskType(taskType: any) {
    if (taskType) {
      this.currentTask.taskTypeId = taskType.id;
      this.currentTask.type = taskType.name;
    }
    this.selectedTaskType = taskType;
  }

  @action createTaskType(taskTypeName: string) {
    this.createTaskTypeTask.perform(taskTypeName);
  }

  @task createTaskTypeTask = taskFor(async (newTaskTypeName: string) => {
    if (newTaskTypeName?.trim() != '') {
      let newTaskType = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskTypes,
        this.args.context.abLink
      );
      newTaskType.name = newTaskTypeName;
      newTaskType.createdDate = this.pmService.currentDate;
      newTaskType.createdBy = this.pmService.currentUserEmail;

      await newTaskType.save();

      newTaskType.taskTypeId = newTaskType.id;
      await newTaskType.save();

      this.currentTask.taskTypeId = newTaskType.id;
      this.currentTask.type = newTaskTypeName;

      this.taskTypes.update();
    }
  });

  @action hideCreateOptionOnSameCategoryName(term: string) {
    let existingOption = this.taskCategories.find(({ name }) => name == term);
    return !existingOption;
  }

  @action selectTaskCategory(taskCategory: any) {
    if (taskCategory) {
      this.currentTask.taskCategoryId = taskCategory.id;
      this.currentTask.category = taskCategory.name;
    }
    this.selectedTaskCategory = taskCategory;
  }

  @action createTaskCategory(categoryName: string) {
    this.createTaskCategoryTask.perform(categoryName);
  }

  @task createTaskCategoryTask = taskFor(async (newCategoryName: string) => {
    if (newCategoryName?.trim() != '') {
      let newCategory = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskCategories,
        this.args.context.abLink
      );

      newCategory.name = newCategoryName;
      newCategory.createdDate = this.pmService.currentDate;
      newCategory.createdBy = this.pmService.currentUserEmail;

      await newCategory.save();

      newCategory.taskCategoryId = newCategory.id;
      await newCategory.save();

      this.currentTask.taskCategoryId = newCategory.id;
      this.currentTask.category = newCategoryName;

      this.taskCategories.update();
    }
  });

  @action hideCreateOptionOnSamePriorityName(term: string) {
    let existingOption = this.taskPriorities.find(({ name }) => name == term);
    return !existingOption;
  }

  @action selectTaskPriority(taskPriority: any) {
    if (taskPriority) {
      this.currentTask.taskPriorityId = taskPriority.id;
      this.currentTask.priority = taskPriority.name;
    }
    this.selectedTaskPriority = taskPriority;
  }

  @action createTaskPriority(priorityName: string) {
    this.createTaskPriorityTask.perform(priorityName);
  }

  @task createTaskPriorityTask = taskFor(async (newPriorityName: string) => {
    if (newPriorityName?.trim() != '') {
      let newPriority = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskPriorities,
        this.args.context.abLink
      );

      newPriority.name = newPriorityName;
      newPriority.createdDate = this.pmService.currentDate;
      newPriority.createdBy = this.pmService.currentUserEmail;
      await newPriority.save();

      newPriority.taskPriorityId = newPriority.id;
      await newPriority.save();

      this.currentTask.taskPriorityId = newPriority.id;
      this.currentTask.priority = newPriority;

      this.taskPriorities.update();
    }
  });

  @action hideCreateOptionOnSameStatusName(term: string) {
    let existingOption = this.taskStatuses.find(({ name }) => name == term);
    return !existingOption;
  }

  @action selectTaskStatus(taskStatus: any) {
    if (taskStatus) {
      this.currentTask.taskStatusId = taskStatus.id;
      this.currentTask.status = taskStatus.name;
    }
    this.selectedTaskStatus = taskStatus;
  }

  @action createTaskStatus(statusName: string) {
    this.createTaskStatusTask.perform(statusName);
  }

  @task createTaskStatusTask = taskFor(async (newStatusName: string) => {
    if (newStatusName?.trim() != '') {
      let newStatus = this.pmService.createNewLookupRecord(
        AbPmLookups.TaskStatuses,
        this.args.context.abLink
      );

      newStatus.name = newStatusName;
      newStatus.createdDate = this.pmService.currentDate;
      newStatus.createdBy = this.pmService.currentUserEmail;
      await newStatus.save();

      newStatus.taskStatusId = newStatus.id;
      await newStatus.save();

      this.currentTask.taskStatusId = newStatus.id;
      this.currentTask.status = newStatus;

      this.taskStatuses.update();
    }
  });

  @action selectTaskCreatedBy(masterResource: any) {
    if (masterResource) {
      this.currentTask.createdBy = masterResource.email;
      //this.currentTask.createdBy = masterResource.name;
    }
  }

  @action async onSmartRoomFolderSelected(folder: SmartroomFolderModel) {
    if (folder) {
      this.currentTask.folderId = folder.id;
      this.currentTask.folderNumber = folder.displayString;

      this.currentTask.folderName = await this.pmService.getFolderPath(
        this.args.context.smartRoomId,
        folder.id
      );

      this.smartRoomFolderName = this.currentTask.folderName;
    } else {
      this.currentTask.folderId = null;
      this.currentTask.folderNumber = null;
      this.currentTask.folderName = null;
      this.smartRoomFolderName = null;
    }
    this.docker.removePopup(this.smartRoomIndexPopup);
  }

  @action showSmartRoomIndex() {
    let appearance = {
      icon: 'folder',
      title: `Select SmartRoom Folder`,
      custom: true,
    };
    let ctx = {
      abLink: this.args.context.abLink,
      smartRoomId: this.args.context.smartRoomId,
      onSelect: this.onSmartRoomFolderSelected,
    };

    this.smartRoomIndexPopup = this.docker.invokePopup(
      'ab-pm/select-sr-folder',
      appearance,
      ctx
    );
  }
}
