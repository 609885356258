import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import Component from '@ember/component';
import moment from 'moment';
import _ from 'lodash';
import { CaseStatus } from 'cing-app/utils/lookups';
import EmberObject, { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import FilterBuilder from 'cing-app/mixins/filter-builder';
import { validator, buildValidations } from 'ember-cp-validations';
import jwtDecode from 'ember-jwt-decode';
import fetch from 'fetch';

const ProfileValidations = buildValidations({
  firstName: validator('presence', true),
  lastName: validator('presence', true),
  addressLine1: validator('presence', true),
  city: validator('presence', true),
  country: validator('presence', true),
  zip: validator('presence', true),
});

@classic
class Profile extends EmberObject.extend(ProfileValidations) {}

@classic
export default class MainDashboard extends Component.extend(FilterBuilder) {
  @service
  store;

  @service('docker-item')
  docker;

  @service
  session;

  @service
  config;

  @service('appbuilder') appbuilder;

  didInsertElement() {
    super.didInsertElement();
    //this._openDebug();
  }

  init() {
    super.init();
    //this.get('loadUserProfile').perform();
  }

  @computed('session.data.authenticated.token')
  get jwtData() {
    return jwtDecode(this.get('session.data.authenticated.token'));
  }

  @computed('session.data.authenticated.token')
  get profileEndpointUrl() {
    return `${this.get('config.authApi_baseUrl')}/api/profiles/${this.get(
      'jwtData.sub'
    )}`;
  }

  @task(function* () {
    let profile = (yield fetch(this.get('profileEndpointUrl'), {
      headers: {
        Authorization: `Bearer ${this.get(
          'session.data.authenticated.access_token'
        )}`,
      },
    })).json();

    let profileData = {};

    if (profile && profile.individual) {
      let address = null;

      if (profile.individual.addresses && profile.individual.addresses.length) {
        address = profile.individual.addresses[0];
      }

      profileData = {
        firstName: profile.individual.firstname,
        lastName: profile.individual.lastname,
        middleName: profile.individual.middleName,
        timeZone: profile.individual.timeZone,
      };

      if (address) {
        profileData.addressLine1 = address.addressLine1;
        profileData.addressLine2 = address.addressLine2;
        profileData.city = address.city;
        profileData.zip = address.zip;
        profileData.country = address.country;
        profileData.state = address.state;
      }
    }

    let profileModel = Profile.create(
      getOwner(this).ownerInjection(),
      profileData
    );

    if (!profileModel.get('validations.isValid')) {
      let appearance = {
        icon: 'user',
        title: 'Complete your profile',
        size: 'medium',
        custom: true,
      };
      this.get('docker').invokePopup('user-profile', appearance, {
        newUser: true,
      });
    }
  })
  loadUserProfile;

  _openDebug() {
    // let alert = null;
    // let appearance = {
    //   icon: 'edit',
    //   title: `<small>Daily Stat: </small>${alert ? alert.get('name') : 'New'}`,
    //   size: 'large',
    //   custom: true,
    // };
    // let context = {
    //   appBuilderLinkId: '8615ab9a-63ab-4bf9-a165-adca99c560fe',
    // };
    // this.get('docker').invokePopup('ab-dailystats', appearance, context);
    /*
    let alert = null;

    let appearance = {
        icon: 'edit',
        title: `<small>Alert: </small>${alert ? alert.get('name') : 'New'}`,
        size: 'large',
        custom: true
    };
    let context = {
        record: null,
    };

    this.get('docker').invokePopup('user-profile', appearance, context);
    */
    /*
    let appearance = {
        icon: '',
        title: `Case: `,
        size: 'large'
    };
    // a1b8483c-ebb3-4bc3-8080-1a8dbb9d333a  toys are us
    this.get('docker').invokePopup('case-management', appearance, {caseId: 'ddadf523-85d1-41f9-8416-e5304d673a21'});        
    */
    /*
    this.get('docker').invokePopup('crm-search', appearance, {
        recordId: 'a1b8483c-ebb3-4bc3-8080-1a8dbb9d333a'
    });
    */
    /*
     const caseId = '9034e0d1-dc6b-4df2-8543-bfb8d475fcd6';
     let appearance = {
         icon: '',
         title: `Edit profile`,
         size: 'large',
         status: 'full',
         custom: true
     }
     this.get('docker').invokePopup('debtor-profile', appearance, { caseId })
     */
    /*
     // let self = this;
         let appearance = {
             icon: '',
             title: `Company`,
             size: 'large',
             customPopup: false
         };
         let context = {
             companyId: '94dbc21c-883c-4d6f-b1c0-181b64b8f930'
         };
         this.get('docker').invokePopup('company-detail', appearance, context);
         */
    //this.get('showIPDebugTask').perform();
    //this.addCompany();
    //this.addContact();
    //this.get('showCaseDebugTask').perform();
    //this.get('importInvestors').perform();
    //this.get('lpCommSync').perform();
    //this.get('showProjectDetailTask').perform();
    //this.get('showDataLink').perform();
    //this.get('showManageDataLinks').perform();
    //this.initializeABDemo.perform();
    this.addConnectionLink.perform();
  }

  @task(function* () {
    let project = yield this.store.findRecord(
      'project',
      '875f504f-911b-4e71-8ee4-88e4d85f1b6b'
    );
    //875f504f-911b-4e71-8ee4-88e4d85f1b6b?include=case%2Cproject-urls%2Cproject-tabs%2Cfund%2Ccompany%2Cfund.fund-status%2Cfund.fund-type

    this.get('docker').popupProjectImportInvestors(project, {
      onComplete: () => {
        this.set('reloadData', true);
      },
    });
  })
  importInvestors;

  @task(function* () {
    let ABLink = yield this.store.findRecord(
      'appbuilder-link',
      'e01f6a10-6bca-4de7-87e1-5fedc1b9a051'
    );

    let modelMeta = this.appbuilder.getModelMeta(ABLink, 'notice-details');

    let noticeDetails = yield this.store.query(modelMeta.modelPath, {});
  })
  initializeABDemo;

  addContact() {
    // let self = this;
    let appearance = {
      icon: 'edit',
      title: `<small>Contact: </small>Add new`,
      size: 'medium',
      custom: true,
    };
    let context = {
      personId: null,
      // address: this.get('address'),
      // recentEmail: this.get('mostRecentEmail'),
      // recentPhone: this.get('mostRecentPhone'),
    };

    this.get('docker').invokePopup('contact-detail/form', appearance, context);
  }

  addCompany() {
    // let self = this;
    let record = this.get('store').createRecord('company', {});
    let appearance = {
      icon: 'edit',
      title: `<small>Company: </small>Add New`,
      size: 'medium',
      custom: true,
    };

    let self = this;

    let context = {
      company: record,
      onCreate: function () {
        self.set('reloadData', true);
      },
    };
    this.get('docker').invokePopup('manage-company', appearance, context);
  }

  @task(function* () {
    //let selectedCase = yield this.get('store').findRecord('case', '64170dfb-8e5e-442d-8ef8-f3d2c38a7850');
    //let project = yield this.store.findRecord('project', 'b37c5958-f907-4f3d-a8ff-111314f349b7'); // fund project
    let project = yield this.store.findRecord(
      'project',
      'b7c0a05b-13e7-4e2e-9977-7e589f877a19'
    ); // regular project

    let appearance = {
      icon: '',
      title: `Add Contact`,
      size: 'medium',
      custom: true,
    };

    let interestedParty = this.store.createRecord('interested-party', {
      project: project,
    });

    this.get('docker').invokePopup(
      'manage-interested-party/add-party',
      appearance,
      {
        project: project,
        interestedParty,
        closeOnCreate: true,
        onCreate: function () {
          console.log('Party Added', interestedParty);
          /*
        self.reloadSmartRoomUsers.perform();
        self.set('reloadData', true);
        */
        },
      }
    );
  })
  showIPDebugTask;

  @task(function* () {
    let project = yield this.store.findRecord(
      'project',
      'a2a3d7e8-adec-4db7-a9cd-a028cf34c653'
    ); // fund project
    //let project = yield this.store.findRecord('project', 'b7c0a05b-13e7-4e2e-9977-7e589f877a19'); // regular project

    let appearance = {
      icon: '',
      title: `Add Contact`,
      size: 'large',
      custom: true,
    };

    this.get('docker').popupDealCloudLPCommSync(project);
  })
  lpCommSync;

  @task(function* () {
    /*
    let row = yield this.get('store').findRecord('case', '6de6ab01-4a70-41c1-a0fb-79ebc32353f7');

    let appearance = {
        icon: '',
        title: `Case: ${row.get('name')} / #${row.get('displayNumber')}`,
        size: 'large',
        id: row.get('id')
    };
    this.get('docker').invokePopup('case-detail', appearance, {
        recordId: row.get('id'),
        tab: 'parties'
    });
    */
  })
  showCaseDebugTask;

  @task(function* () {
    let record = yield this.store.findRecord(
      'project',
      '31721b43-1f4c-4022-b7f4-4cbf7719beb1'
    );

    const appearance = {
      label: 'Project',
      icon: '',
      title: '<small>Project:</small> ' + record.get('name'),
      size: 'large',
      status: 'full',
      custom: true,
    };
    this.get('docker').invokePopup('project-detail', appearance, {
      project: record,
    });
  })
  showProjectDetailTask;

  @task(function* () {
    const appearance = {
      label: 'Data Links',
      icon: '',
      title: '<small>Data Links</small>',
      size: 'large',
      custom: true,
    };
    this.get('docker').invokePopup('manage-data-links', appearance);
  })
  showManageDataLinks;

  @task(function* () {
    let project = yield this.store.findRecord(
      'project',
      'a9f9bb44-c098-41d1-9d1a-ecadced3622b'
    );

    const appearance = {
      label: 'Add Link',
      icon: '',
      size: 'sm',
      custom: true,
    };

    let model = this.store.createRecord('appbuilder-link', {
      project: project,
      type: 1,
    });

    this.get('docker').invokePopup('manage-connections/add-link', appearance, {
      model: model,
    });
  })
  addConnectionLink;
}
