import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import classic from 'ember-classic-decorator';

@classic
export default class AbAdapter extends JSONAPIAdapter {
  @service session;
  @service serverVariables;

  get headers() {
    let headers: Object = {};

    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    // headers['Accept'] = 'application/json';
    // headers['Content-Type'] = 'application/json';

    return headers;
  }

  // @alias('serverVariables.appBuilderApiHostname') host!: string;
  // @alias('serverVariables.appBuilderApiNamespace') namespace!: string;

  host = '{abInstanceUrl}';

  pathForType(modelName: string) {
    let match = modelName.match(/^appbuilder\/(.*)$/);
    if (match) {
      return `${pluralize(match[1])}`;
    } else {
      throw Error('Unknown model name: ' + modelName);
    }
  }

  buildURL(modelName, id, snapshot, requestType, query) {
    let url = super.buildURL(modelName, id, snapshot, requestType, query);
    let abInstanceUrl =
      query?.abInstanceUrl ?? snapshot.adapterOptions.abInstanceUrl;
    if (!abInstanceUrl) {
      throw new Error(
        'abInstanceUrl is required in direct endpoints to appbuilder'
      );
    }
    if (query?.abInstanceUrl) {
      delete query.abInstanceUrl;
    }
    let withBaseUrl = url.replace('{abInstanceUrl}', abInstanceUrl);
    return withBaseUrl;
  }
}
