import { SmartroomConfig } from 'smex-ui-sr-config';

export default {
  name: 'setup-server-vars',
  initialize(app) {
    let { container = app } = app;

    let serverVariablesService = container.lookup('service:server-variables');
    let config = container.lookup('service:config');
    let smartroomConfig = container.lookup('service:smartroom-config');

    // setup SmartIdentity
    config.set(
      'ember-smartidentity-auth.smartIdentityServer',
      serverVariablesService.get('authBaseUrl')
    );
    config.set(
      'ember-smartidentity-auth.apiKey',
      serverVariablesService.get('authClientId')
    );
    config.set(
      'ember-smartidentity-auth.scope',
      serverVariablesService.get('authScope')
    );

    let mainURL =
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '');

    let customClients = [
      'fcp.smartexchange.com',
      'third500-qa.smartexchange.com',
      'third500.smartexchange.com',
    ];

    // on production, the URL where the app and portal is hosted
    // is completely dynamic and based on the base URL
    if (config.get('environment') !== 'development') {
      if (customClients.includes(location.hostname)) {
        config.set('ember-smartidentity-auth.apiKey', location.hostname);
      }

      config.setProperties({
        mainUrl: mainURL,
        investorPortalUrl: `${mainURL}/portal/`,
        auctionPortalUrl: `${mainURL}/auction-portal/`,
        companyPortalUrl: `${mainURL}/company-portal/`,
        'APP.api.host': mainURL,
        'APP.api.namespace': serverVariablesService.get('apiNamespace'),
      });
    }
  },
};
