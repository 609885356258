import Model, { attr } from '@ember-data/model';

export default class DealCloudContact extends Model {
  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('string') declare fullName: string;
  @attr('string') declare email: string;
  @attr() declare companies: any;
  @attr('string') declare officePhone: string;
  @attr('string') declare homePhone: string;
  @attr('string') declare mobilePhone: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'deal-cloud-contact': DealCloudContact;
  }
}
