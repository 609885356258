import Model, { attr } from '@ember-data/model';

export default class FormElementFormat extends Model {
  @attr("number") formElementId?: number;
  @attr("string") dataType?: string;
  @attr("number") decimalPlaces?: number;
  @attr("boolean") useSeparator!: boolean;
  @attr("string") negativeNumberFormat?: string;
  @attr("boolean") showCurrency!: boolean;
  @attr("string") currencyType?: string;
  @attr("string") dateFormat?: string;
  @attr("string") customField?: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'appbuilder-link/form-element-format': FormElementFormat;
  }
}