import { sort } from '@ember/object/computed';
import Component from '@ember/component';
import RoleKeeper from 'cing-app/mixins/role-keeper';
import { inject } from '@ember/service';

import FilterBuilder, {
  Expressions,
  ExpressionOperators,
  Filter,
  FilterOperators,
  RangeFilter,
  DateRangeFilter,
} from 'cing-app/mixins/filter-builder';
import { task, timeout, all } from 'ember-concurrency';
import {
  PartyRoleTypes,
  EventType,
  AclEntityType,
  AclPermissionType,
  CaseAssociationTypes,
  CaseProjectUrlMap,
  CaseProjectUrlOpenModeMap,
  ProjectTabs,
  ProjectTabIcons,
  ProjectTabMap,
} from 'cing-app/utils/lookups';
import EmberObject, { computed } from '@ember/object';

let ViewActionTypes = {
  NORMAL: 0,
  SAVE_TO_DISK: 1,
  OFFICE_365: 2,
};

//import CapitolCallTable from 'cing-app/models/ab/investors/capital-call';
//import DistributionTable from 'cing-app/models/ab/investors/distribution';
//import DocumentTable from 'cing-app/models/ab/investors/event-detail';
//import EmailTable from 'cing-app/models/ab/investors/investor';

export default Component.extend(FilterBuilder, RoleKeeper, {
  bulkDownload: inject('bulk-download'),
  config: inject(),
  store: inject(),
  appEvents: inject(),
  serverVariables: inject(),
  session: inject(),
  abModels: inject(),
  docker: inject('docker-item'),
  confirmAddToWatchList: null,
  CaseProjectUrlOpenModeMap,
  ProjectTabIcons,
  ProjectTabs,
  ProjectTabMap,
  AclEntityType,
  AclPermissionType,
  claims: {
    listURL: 'https://formbuilder2.bmcgroup.com/Search/SearchData?caseId=7437',
    addUrl: 'https://formbuilder2.bmcgroup.com/Route/Index/B10%201',
  },

  presetFilters: null,

  init() {
    this._super();

    this.set('presetFilters', {
      docket: EmberObject.create(),
      calendar: EmberObject.create(),
      parties: EmberObject.create(),
      investors: EmberObject.create(),
    });

    this.get('initTask').perform();
  },

  initTask: task(function* () {
    let store = this.get('store');

    // reload the project model including all the relations
    let model = yield store.findRecord(
      'project',
      this.get('context.project.id'),
      {
        include:
          'case,project-urls,project-tabs.data-connection,company,fund.fund-status,fund.fund-type,acl-role-assignments.acl-permissions,data-connections',
      }
    );

    this.set('model', model);

    // get the attached model case
    let modelCase = yield model.get('case');
    this.set('case', modelCase);

    let projectTabs = yield model.get('projectTabs');

    this.set('projectTabs', projectTabs.sortBy('sortOrder'));

    let tabToActivate = this.get('projectTabs.firstObject.id');

    if (this.context.preselectTab) {
      for (var a = 0; a < this.projectTabs.length; a++) {
        let item = this.projectTabs[a];
        let type = item.type;

        if (type === this.context.preselectTab) {
          tabToActivate = item.id;
          break;
        }
      }
    } else if (!this.model.adminEnabled) {
      for (var a = 0; a < this.projectTabs.length; a++) {
        let item = this.projectTabs[a];
        let type = item.type;

        if (
          type !== ProjectTabMap.INVESTMENT_DETAIL &&
          type !== ProjectTabMap.TRANSACTIONS_HISTORY
        ) {
          tabToActivate = item.id;
          break;
        }
      }
    }

    this.set('tabOnOpen', tabToActivate);

    this.get('checkWatchList').perform();

    if (tabToActivate) {
      this.updateSelectedTab(tabToActivate);
    }
  }),
  saveTabsTask: task(function* () {
    let tabs = this.projectTabs.toArray();

    for (var a = 0; a < tabs.length; a++) {
      tabs[a].set('sortOrder', a);
      yield tabs[a].save();
    }
  }),
  reloadDates: task(function* () {
    let expE = Expressions.create({ operator: ExpressionOperators.AND });
    expE.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.model.id,
      })
    );

    let expTypeAndHighlight = Expressions.create({
      operator: ExpressionOperators.OR,
    });

    expTypeAndHighlight.add(
      Filter.create({
        name: 'type-id',
        operator: FilterOperators.IN,
        value: [EventType.Milestone, EventType.Announcement],
      })
    );

    expTypeAndHighlight.add(
      Filter.create({
        name: 'highlight',
        operator: FilterOperators.EQUAL,
        value: true,
      })
    );

    expE.add(expTypeAndHighlight);

    let milestonesAndAnnouncements = yield this.get('store').query('event', {
      condition: expE.serialize(),
      page: {
        size: 100,
        number: 1,
      },
      sort: '-dateStart',
      include: 'type,pacer-events',
    });

    // get milestones
    this.set(
      'milestones',
      milestonesAndAnnouncements.filterBy('typeId', EventType.Milestone)
    );

    // get announcements
    this.set(
      'announcements',
      milestonesAndAnnouncements.filterBy('typeId', EventType.Announcement)
    );
    this.set(
      'highlightDates',
      milestonesAndAnnouncements.filterBy('highlight', true)
    );
  }),
  reloadTabsTask: task(function* () {
    let tabs = yield this.model.hasMany('projectTabs').reload();
    this.set('projectTabs', tabs.sortBy('sortOrder'));

    if (
      !this.projectTabs.includes(this.selectedTab) &&
      this.projectTabs.length
    ) {
      this.updateSelectedTab(this.projectTabs.firstObject.id);
    }
  }),
  externalUrls: computed(
    'model.projectUrls.@each.type',
    'session.isAdmin',
    function () {
      let urls = this.get('model.projectUrls').filter((item) => {
        if (!this.get('isAdmin')) {
          if (
            item.get('type') == CaseProjectUrlMap.EXTERNAL_URL &&
            item.get('isPublic')
          ) {
            return true;
          }
        } else {
          if (item.get('type') == CaseProjectUrlMap.EXTERNAL_URL) {
            return true;
          }
        }
      });

      return urls;
    }
  ),

  smartrooms: computed('model.projectUrls.@each.type', function () {
    let urls = this.get('model.projectUrls').filter(
      (item) => item.get('type') == CaseProjectUrlMap.SMARTROOM_ID
    );

    return urls;
  }),

  highlightUrls: computed('model.projectUrls.@each.type', function () {
    let urls = this.get('model.projectUrls').filter((item) => {
      if (!this.get('isAdmin')) {
        if (
          item.get('type') == CaseProjectUrlMap.HIGHLIGHT_URL &&
          item.get('isPublic')
        ) {
          return true;
        }
      } else {
        if (item.get('type') == CaseProjectUrlMap.HIGHLIGHT_URL) {
          return true;
        }
      }
    });

    return urls;
  }),

  removeProjectTask: task(function* () {
    yield this.model.destroyRecord();

    if (this.onClose) {
      this.onClose();
    }
  }),

  actions: {
    manageAccess(project) {
      this.docker.popupManageAccess(
        project,
        AclEntityType.Project,
        project.name,
        [AclPermissionType.ProjectRead]
      );
    },
    onEventUpdate() {
      this.get('reloadDates').perform();
    },
    addedToWatchList(watchList) {
      this.set('watchList', watchList);
      this.set('confirmAddToWatchList', null);
    },
    removedFromWatchList() {
      this.set('watchList', null);
      this.set('confirmRemoveFromWatchList', null);
    },
    openManagement(project) {
      const appearance = {
        label: 'Management',
        icon: '',
        size: 'large',
        title: `Management for: ${project.get('name')}`,
        custom: true,
      };
      const context = {
        project: project,
      };
      this.get('docker').invokePopup('manage-project', appearance, context);
    },
    addTab(project) {
      const appearance = {
        label: 'Project tab',
        icon: '',
        size: 'large',
        title: `Add tab for: ${project.get('name')}`,
        custom: true,
      };

      let sortOrder = 1;

      project.get('projectTabs').then((projectTabs) => {
        let lastTab = projectTabs.toArray().sortBy('sortOrder').lastObject;

        if (lastTab && lastTab.sortOrder) {
          sortOrder = lastTab.sortOrder + 1;
        }

        let self = this;

        const context = {
          onSave: function () {
            self.reloadTabsTask.perform();
          },
          project: project,
          model: this.store.createRecord('project-tab', {
            projectId: project.id,
            enabled: true,
            sortOrder: sortOrder,
          }),
        };
        this.get('docker').invokePopup(
          'manage-project/tab',
          appearance,
          context
        );
      });
    },
    editTab(projectTab) {
      const appearance = {
        id: 'manage-project/tab' + projectTab.id,
        label: 'Project tab',
        icon: '',
        size: 'large',
        title: `Edit tab ${
          projectTab.get('title') || projectTab.get('typeName')
        }`,
        custom: true,
      };
      const context = {
        onSave: () => {
          this.reloadTabsTask.perform();
        },
        project: this.model,
        model: projectTab,
      };

      this.get('docker').invokePopup('manage-project/tab', appearance, context);
    },
    removeTab(projectTab) {
      projectTab.destroyRecord().then(() => {
        this.reloadTabsTask.perform();
      });
    },
    tabsSorted() {
      this.saveTabsTask.perform();
    },
    openProjectTabURL(projectTab) {
      let openMode =
        projectTab.settings && projectTab.settings.EUOpenMode
          ? projectTab.settings.EUOpenMode
          : CaseProjectUrlOpenModeMap.NEW_TAB;

      let target = '_blank';

      if (openMode == CaseProjectUrlOpenModeMap.REPLACE_PARENT) {
        target = '_self';
      } else if (openMode == CaseProjectUrlOpenModeMap.NEW_WINDOW) {
        target = projectTab.get('id');
      }

      let options = null;

      let outerWidth = Math.round((screen.width / 100) * 70);
      let outerHeight = Math.round((screen.height / 100) * 70);

      let left = Math.round((screen.width - outerWidth) / 2);
      let top = Math.round((screen.height - outerHeight) / 2);

      let proxy = this.get(target);

      if (proxy) {
        proxy.focus();
      } else {
        let windowUrl = projectTab.get('content');

        if (openMode === CaseProjectUrlOpenModeMap.REPLACE_PARENT) {
          window.open(windowUrl, target);
        } else if (
          openMode === CaseProjectUrlOpenModeMap.NEW_WINDOW ||
          openMode === CaseProjectUrlOpenModeMap.NEW_TAB
        ) {
          if (openMode == CaseProjectUrlOpenModeMap.NEW_WINDOW) {
            options = `toolbar=no,location=no,menubar=no,left=${left},top=${top},titlebar=no,status=no,width=${outerWidth},height=${outerHeight}`;
          }

          let targetWindow = window.open(
            projectTab.get('content'),
            target,
            options
          );

          function focusWindow() {
            targetWindow.focus();
          }

          $(window).on('focus', focusWindow);
        }
      }
    },
    onTabChanged(newTab, oldTab) {
      this.updateSelectedTab(newTab);
    },
    openEventDetail(event, onUpdate) {
      let title = event.get('title');

      if (!title) {
        let eventTypes = Object.keys(EventType);
        title = eventTypes.find((key) => EventType[key] == event.typeId);
      }

      const appearance = {
        label: 'Event detail',
        icon: 'calendar',
        title: title,
      };
      const context = { event: event, onUpdate: onUpdate };
      this.get('docker').invokePopup('event-detail', appearance, context);
    },

    showCompanyDetail(company) {
      console.log('COMPANY: ', company);
      this.docker.popupCompany(company);
    },
    doRemoveProject() {
      this.removeProjectTask.perform();
    },
    copyTabLink() {
      let mainUrl = this.config.get('mainUrl');

      let tabUrl = `${mainUrl}/project/${this.model.id}/project-tab/${this.selectedTab.id}`;
      return tabUrl;
    },
    copyProjectLink() {
      let mainUrl = this.config.get('mainUrl');

      let tabUrl = `${mainUrl}/project/${this.model.id}`;
      return tabUrl;
    },
    copyTabSuccess() {
      this.copyTabSuccessTask.perform();
    },
    copyProjectSuccess() {
      this.copyProjectSuccessTask.perform();
    },
  },

  updateSelectedTab(newTab) {
    this.set('selectedTab', this.store.peekRecord('project-tab', newTab));

    switch (this.get('selectedTab.type')) {
      case ProjectTabMap.DETAILS:
        this.get('loadDetailsData').perform();
        break;
    }
  },

  loadDetailsData: task(function* () {
    let store = this.get('store');

    let tasks = [];

    // load professionals
    let expIP = Expressions.create({ operator: ExpressionOperators.AND });
    expIP.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.model.id,
      })
    );
    expIP.add(
      Filter.create({
        name: 'is-important',
        operator: FilterOperators.EQUAL,
        value: true,
      })
    );

    tasks.push(
      store.query('interested-party', {
        condition: expIP.serialize(),
        include: 'person,company,email,interested-party-role',
        page: {
          size: 1000,
          number: 1,
        },
        sort: 'partyName',
      })
    );

    let expE = Expressions.create({ operator: ExpressionOperators.AND });
    expE.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: this.model.id,
      })
    );

    let expTypeAndHighlight = Expressions.create({
      operator: ExpressionOperators.OR,
    });

    expTypeAndHighlight.add(
      Filter.create({
        name: 'type-id',
        operator: FilterOperators.IN,
        value: [EventType.Milestone, EventType.Announcement],
      })
    );

    expTypeAndHighlight.add(
      Filter.create({
        name: 'highlight',
        operator: FilterOperators.EQUAL,
        value: true,
      })
    );

    expE.add(expTypeAndHighlight);

    tasks.push(
      store.query('event', {
        condition: expE.serialize(),
        page: {
          size: 100,
          number: 1,
        },
        sort: '-dateStart',
        include: 'type,pacer-events',
      })
    );

    let expC = Expressions.create({ operator: ExpressionOperators.AND });

    //expC1 = Expressions.create({operator: ExpressionOperators.OR})

    let results = yield all(tasks);

    let milestonesAndAnnouncements = [];
    let associatedCases = [];

    //this.set('retainedProfessionals', results[0]);
    milestonesAndAnnouncements = results[1];

    // get important retained professionals
    this.set('importantProfessionals', results[0]);

    //this.set('professionalsByCompany', yield this.groupProfessionalsByCompany.perform(this.get('retainedProfessionals').toArray()));
    this.set(
      'importantProfessionalsByCompany',
      yield this.groupProfessionalsByCompany.perform(
        this.get('importantProfessionals').toArray()
      )
    );

    // get milestones
    this.set(
      'milestones',
      milestonesAndAnnouncements.filterBy('typeId', EventType.Milestone)
    );

    // get announcements
    this.set(
      'announcements',
      milestonesAndAnnouncements.filterBy('typeId', EventType.Announcement)
    );
    this.set(
      'highlightDates',
      milestonesAndAnnouncements.filterBy('highlight', true)
    );

    let expEd = Expressions.create({ operator: ExpressionOperators.AND });

    expEd.add(
      Filter.create({
        name: 'case-id',
        operator: FilterOperators.EQUAL,
        value: this.model.caseId,
      })
    );

    expEd.add(
      Filter.create({
        name: 'highlight',
        operator: FilterOperators.EQUAL,
        value: true,
      })
    );
  }),

  groupProfessionalsByCompany: task(function* (list) {
    let professionalsByCompany = [];

    for (var a = 0; a < list.length; a++) {
      let p = list[a];

      let professionalRole = p.get('roleText');
      let companyContainer = null;

      let company = yield p.get('company');
      let companyName = company.name;

      let person = yield p.get('person');

      if (p.get('isCompany')) {
        let companyContainers = professionalsByCompany.filter((ep) => {
          if (
            ep.companyName === companyName &&
            ep.get('roleText') == professionalRole
          ) {
            return true;
          }
        });

        companyContainer = companyContainers.get('firstObject');
      }

      if (!companyContainer) {
        companyContainer = {
          partyName: p.get('partyName'),
          isCompany: p.get('isCompany'),
          person: person,
          company: company,
          companyName: companyName,
          role: professionalRole,
          professionals: [],
        };

        professionalsByCompany.push(companyContainer);
      }

      companyContainer.professionals.push(p);
    }

    return professionalsByCompany;
  }),
  copyTabSuccessTask: task(function* () {
    yield timeout(1500);
  }),
  copyProjectSuccessTask: task(function* () {
    yield timeout(1500);
  }),
  checkWatchList: task(function* () {
    const store = this.get('store');
    const projectId = this.get('model.id');

    let expr = Expressions.create({ operator: ExpressionOperators.AND });
    expr.add(
      Filter.create({
        name: 'project-id',
        operator: FilterOperators.EQUAL,
        value: projectId,
      })
    );
    let watchList = yield store.query('watchList', {
      condition: expr.serialize(),
    });

    if (watchList) {
      this.set('watchList', watchList.get('firstObject'));
    } else {
      this.set('watchList', false);
    }
  }),

  sortProperties: ['docketNumber:desc'],
  orderedDocket: sort('record.docket', 'sortProperties'),
});
